/* eslint-disable react-hooks/exhaustive-deps */
import "./CandidateLanding.css";
import Addonboard from "../../Assets/Addonboard.svg";
import rotTraingle from "../../Assets/rotTraingle.svg";
import circleCan from "../../Assets/circleCan.svg";
import CanOuter from "../../Assets/CanOuter.svg";
import AddOrange from "../../Assets/AddOrange.svg";
import CirclePink from "../../Assets/CirclePink.svg";
import Aesslogo from "../../Assets/Aesslogo.svg";
import circlelilPur from "../../Assets/circlelilPur.svg";
import circleLittle from "../../Assets/circleLittle.svg";
import CircTraipur from "../../Assets/CircTraipur.svg";
import TraingSky from "../../Assets/TraingSky.svg";
import OnboardingSidePanelBackground from "../../Assets/OnboardingSidePanelBackground.svg";
import EmployeeSidePanelImg from "../../Assets/EmployeeSidePanelImg.svg";
import EmployeeSidePanelImgActive from "../../Assets/EmployeeSidePanelImgActive.svg";
import OnboardingSidePanelLine from "../../Assets/OnboardingSidePanelLine.svg";
import EmployeeOnboardGraphic from "../../Assets/EmployeeOnboardGraphic.svg";
import EmployeeWelcome from "../../Assets/EmployeeWelcome.svg";
import ProfileAvatar from "../../Assets/ProfileAvatar.svg";
import OnBoard from "../../Assets/OnBoard.svg";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../Services/axios";
import { showToast } from "../../Features";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { OpenFile } from "../../Utilities";
import { TextAreaFormik } from "../../Components/FormComponent/TextAreaFormik";
import { StarRatingFormik } from "../../Components/FormComponent/StarRatingFormik";
import { LabelCustom } from "../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../Components/FormComponent/SelectForm";
import * as Yup from "yup";
import { setEmployeeData } from "../../Features/AuthSlice";
const CandidateOnboardingLanding = ({ candidateDetals, loading }) => {
  useEffect(() => {
    document.title = `PeopleSol - Employee Onboarding Landing`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="outerCont">
      <div className="CirclePink">
        <img src={CirclePink} alt="LoginPerson" />
      </div>
      <div className="canOuter">
        <img src={CanOuter} alt="LoginPerson" />
      </div>
      <div className="outerGr">
        <img src={Addonboard} alt="LoginPerson" />
      </div>
      <div className="circleCan">
        <img src={circleCan} alt="LoginPerson" />
      </div>
      <div className="innerCont">
        <div className="TraingSky">
          <img src={TraingSky} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="circlelilPur">
          <img src={circlelilPur} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="circleLittle">
          <img src={circleLittle} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="CircTraipur">
          <img src={CircTraipur} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="WelImg">
          <img
            src={EmployeeWelcome}
            style={{ width: "100%" }}
            alt="EmployeeWelcome"
          />
        </div>
        <div className="employeeProfileImgHolder">
          {loading ? (
            <Skeleton
              containerClassName="flex-1"
              circle
              height="100%"
              width="100%"
              style={{ width: "100%", height: "100%", lineHeight: 1, flex: 1 }}
            />
          ) : candidateDetals?.employee?.image ? (
            <img
              className="employeeProfileImg"
              src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${candidateDetals?.employee?.image}`}
              alt="Profile"
            />
          ) : (
            <img
              className="employeeProfileImg"
              src={ProfileAvatar}
              alt="Profile"
            />
          )}
        </div>
        <div className="flexIn">
          <div className="flexinText">
            <div className="canName">
              <div className="wel">Welcome</div>
              <div className="canUser">
                {loading ? (
                  <Skeleton width={300} />
                ) : (
                  candidateDetals?.employee?.fullName
                )}
              </div>
            </div>
            {loading ? (
              <Skeleton count={10} />
            ) : (
              <div
                className="innerCantext"
                dangerouslySetInnerHTML={{
                  __html: candidateDetals?.inductionContent?.obInductionContent,
                }}
              ></div>
            )}
            <div className="orangeCan">
              <img src={AddOrange} alt="LoginPerson" />
            </div>
            <div className="cheersC">
              Cheers
              <div className="teaes">Team AESS</div>
            </div>
          </div>
          <div className="girlImg" style={{ marginRight: "24px" }}>
            <img src={EmployeeOnboardGraphic} alt="EmployeeOnboardGraphic" />
          </div>
        </div>
        {!loading && (
          <div className="btnContt">
            <button type="submit" className="btnCand">
              Let's Get Started
            </button>
            <img src={Aesslogo} alt="LoginPerson" />
          </div>
        )}
        <div className="candText">
          <img src={OnBoard} alt="OnBoard" />
        </div>
      </div>
      <div className="rotTraingle">
        <img src={rotTraingle} alt="LoginPerson" />
      </div>
    </div>
  );
};
const EmployeeOnboardingLanding = () => {
  const [candidateDetals, setCandidateDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getCandidateDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`OnBoardingFeedbackForm/CreatePreview`);
      if (result?.data?.data) setCandidateDetails(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const createFunction = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("OnBoardingFeedbackForm/Create", values);
      if (result) {
        const result = await getRequest("Employees/GetEmployeedata");
        if (result) {
          dispatch(setEmployeeData(result?.data?.data));
          dispatch(
            showToast({ text: "Successfully Onboarded", severity: "success" })
          );
          navigate("/onboardingcompleted", { replace: true });
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCandidateDetails();
  }, []);
  const getItemList = () => {
    let returnArray = {};
    candidateDetals?.facilities?.map((item) => (returnArray[item.id] = null));
    return returnArray;
  };
  const navigate = useNavigate();
  const getFacilitiesOptions = (typeString) => {
    let tempArr = typeString.toString().replace("\r", "").split("\n");
    let listData = [];
    tempArr.map((item) => {
      if (item)
        if (item !== "" || item !== "")
          listData.push({ label: item, value: item });
      return null;
    });
    return listData;
  };
  return (
    <FormikStepper
      initialValues={{
        feedback: "",
        rating: 5,
        item: getItemList(),
      }}
      onSubmit={async (values) => {
        const formData = new FormData();
        if (values?.feedback) formData.append("feedback", values?.feedback);
        if (values?.rating) formData.append("rating", values?.rating);
        if (values?.item) {
          Object.keys(values?.item).forEach(function (object, index) {
            formData.append(`item[${index}].FacilityId`, object);
            formData.append(
              `item[${index}].Status`,
              Object.values(values?.item)[index]
            );
          });
        }
        createFunction(formData);
      }}
    >
      <FormikStep label="Greetings">
        <CandidateOnboardingLanding
          candidateDetals={candidateDetals}
          loading={loading}
        />
      </FormikStep>
      {/* <FormikStep label="Welcome">
        <div className="homeCardsHolderContainer settingCardHolder">
          <div
            className="homeDashboardCardHolder"
            style={{ height: "fit-content" }}
          >
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
          </div>
        </div>
      </FormikStep> */}
      <FormikStep label="Policy">
        <div className="homeCardsHolderContainer settingCardHolder">
          <div
            className="homeDashboardCardHolder"
            style={{ height: "fit-content" }}
          >
            {candidateDetals?.policy?.length > 0 &&
              candidateDetals?.policy?.map((policy, index) => {
                return (
                  <div className="videoCard" key={index}>
                    <div
                      className="cardContainer"
                      style={{ width: "100%", margin: "6px 0" }}
                    >
                      <svg
                        style={{ alignSelf: "center" }}
                        width="63"
                        height="75"
                        viewBox="0 0 63 75"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.93994 12.1519V62.8289"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                        />
                        <path
                          d="M61.4385 27.3701V62.861"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.8564 2.01465H36.6471"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.8564 72.999H51.5215"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                        />
                        <path
                          d="M61.4372 62.855C61.4643 67.9193 56.4791 72.9952 51.521 72.9952"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.94019 62.855C1.94019 67.9251 6.89832 72.9952 11.8564 72.9952"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.9402 12.144C1.9402 7.08128 6.89832 2.12407 11.8564 2.01116"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M61.4105 27.388L36.647 2.01465"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M36.6472 17.2188C36.6625 22.2613 41.6253 27.359 46.5635 27.359"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M36.647 17.225V2.01465"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M46.5635 27.3701H61.4379"
                          stroke="#1296B0"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.8564 62.855H26.7308"
                          stroke="#F7941D"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.8564 52.7104H36.6471"
                          stroke="#F7941D"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.8564 42.5742H26.7308"
                          stroke="#F7941D"
                          stroke-width="2.08188"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <h5
                        style={{
                          color: "var(--primary)",
                          padding: "6px 6px 3px 6px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {policy?.subject}
                      </h5>
                      <p
                        style={{
                          padding: "0 6px 3px 6px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {policy?.policyGroup}
                      </p>
                      <div
                        className="horizontalLine"
                        style={{ height: "1px" }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "6px",
                        }}
                        className="cursor"
                        onClick={() => OpenFile("Policies", policy?.attachment)}
                      >
                        <p style={{ color: "var(--primary)", fontWeight: 500 }}>
                          View
                        </p>
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="9.61847"
                            cy="9.86621"
                            r="9"
                            fill="#1296B0"
                          />
                          <path
                            d="M10.1185 6.02051C10.1185 5.74437 9.89461 5.52051 9.61847 5.52051C9.34233 5.52051 9.11847 5.74437 9.11847 6.02051L10.1185 6.02051ZM9.26492 14.0645C9.46018 14.2598 9.77676 14.2598 9.97202 14.0645L13.154 10.8825C13.3493 10.6872 13.3493 10.3707 13.154 10.1754C12.9587 9.98014 12.6422 9.98014 12.4469 10.1754L9.61847 13.0038L6.79004 10.1754C6.59478 9.98014 6.2782 9.98014 6.08294 10.1754C5.88767 10.3707 5.88767 10.6872 6.08294 10.8825L9.26492 14.0645ZM9.11847 6.02051L9.11847 13.7109L10.1185 13.7109L10.1185 6.02051L9.11847 6.02051Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </FormikStep>
      {/* <FormikStep label="Collateral">
        <div className="homeCardsHolderContainer settingCardHolder">
          <div
            className="homeDashboardCardHolder"
            style={{ height: "fit-content" }}
          >
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
            <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <label>Onboarding</label>
            </div>
          </div>
        </div>
      </FormikStep> */}
      <FormikStep
        label="Feedback"
        validationSchema={Yup.object({
          rating: Yup.string().required("Please Select Area"),
          feedback: Yup.string().required("Please Enter Feedback"),
        })}
      >
        <div
          style={{
            display: "flex",
            padding: "24px",
            flexGrow: "1",
            justifyContent: "space-between",
            backgroundColor: "var(--form-background)",
            height: "100%",
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
              alignContent: "start",
              paddingTop: "12px",
            }}
          >
            <div
              style={{
                width: "calc(50% - 12px)",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                padding: "12px",
                height: "fit-content",
              }}
            >
              <div
                className="horizontalLine"
                style={{
                  width: "20%",
                  backgroundColor: "var(--primary)",
                  height: "3px",
                }}
              ></div>
              <p>Employee Name</p>
              <h5 style={{ color: "var(--primary" }}>
                {candidateDetals?.employee?.fullName}
              </h5>
            </div>
            <div
              style={{
                width: "calc(50% - 12px)",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                padding: "12px",
                height: "fit-content",
              }}
            >
              <div
                className="horizontalLine"
                style={{
                  width: "20%",
                  backgroundColor: "var(--primary)",
                  height: "3px",
                }}
              ></div>
              <p>Department</p>
              <h5 style={{ color: "var(--primary" }}>
                {candidateDetals?.employee?.department}
              </h5>
            </div>
            <div
              style={{
                width: "calc(50% - 12px)",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                padding: "12px",
                height: "fit-content",
              }}
            >
              <div
                className="horizontalLine"
                style={{
                  width: "20%",
                  backgroundColor: "var(--primary)",
                  height: "3px",
                }}
              ></div>
              <p>Designation</p>
              <h5 style={{ color: "var(--primary" }}>
                {candidateDetals?.employee?.designation}
              </h5>
            </div>
            <div
              style={{
                width: "calc(50% - 12px)",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                padding: "12px",
                height: "fit-content",
              }}
            >
              <div
                className="horizontalLine"
                style={{
                  width: "20%",
                  backgroundColor: "var(--primary)",
                  height: "3px",
                }}
              ></div>
              <p>Role</p>
              <h5 style={{ color: "var(--primary" }}>
                {candidateDetals?.employee?.roleName}
              </h5>
            </div>
            <div
              style={{
                width: "calc(100% - 12px)",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                padding: "12px",
                height: "fit-content",
              }}
            >
              <LabelCustom labelName="Rating" mandatory={true} />
              <StarRatingFormik name="rating" />
            </div>
            <div
              style={{
                width: "calc(100% - 12px)",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                padding: "12px",
                height: "fit-content",
              }}
            >
              <LabelCustom labelName="Feedback" mandatory={true} />
              <TextAreaFormik name="feedback" />
            </div>
          </div>
          <div
            style={{
              width: "40%",
              backgroundColor: "var(--primary-hover)",
              boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.06)",
              borderRadius: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: " 24px 18px",
                height: "69px",
                maxHeight: "69px",
              }}
            >
              <h6>Facilities Provided</h6>
            </div>
            <div
              style={{
                height: "calc(100% - 69px)",
                width: "100%",
                overflow: "auto",
                padding: "24px 18px",
              }}
            >
              {/* {candidateDetals?.facilities?.length > 0 &&
                candidateDetals?.facilities?.map((facility, index) => {
                  return (
                    <div
                      style={{
                        padding: "6px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <p style={{ color: "var(--primary)" }}>
                        {facility?.name}
                      </p>
                      <YesNoFormik name={facility?.name} />
                    </div>
                  );
                })} */}
              {candidateDetals?.facilities &&
                candidateDetals?.facilities.length > 0 &&
                candidateDetals?.facilities?.map((facility, index) => {
                  return (
                    <div key={index}>
                      <LabelCustom labelName={facility.name} />
                      <SelectForm
                        name={`item[${facility.id}]`}
                        options={getFacilitiesOptions(facility.type)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </FormikStep>
    </FormikStepper>
  );
};
export function FormikStep({ children }) {
  return <>{children}</>;
}
export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === childrenArray.length - 1;
  }
  return (
    <Formik
      {...props}
      validationSchema={currentChild?.props?.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props?.onSubmit(values, helpers);
        } else {
          setStep((s) => s + 1);
          helpers?.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form style={{ height: "100%" }}>
          {step === 0 ? (
            <>{currentChild}</>
          ) : (
            <div className="outerCont">
              <div className="CirclePink">
                <img src={CirclePink} alt="LoginPerson" />
              </div>
              <div className="canOuter">
                <img src={CanOuter} alt="LoginPerson" />
              </div>
              <div className="outerGr">
                <img src={Addonboard} alt="LoginPerson" />
              </div>
              <div className="circleCan">
                <img src={circleCan} alt="LoginPerson" />
              </div>
              <div className="onboardingInnerContainer">
                <div
                  className="onboardingSidePanel"
                  style={{ background: "#8A88ED" }}
                >
                  <img
                    style={{
                      width: "100%",
                      bottom: 0,
                      position: "absolute",
                      right: 0,
                    }}
                    src={OnboardingSidePanelBackground}
                    alt="OnboardingSidePanelBackground"
                  />
                  <div className="onboardingSidePanelContainer">
                    <img
                      style={{
                        height: "100%",
                        position: "absolute",
                        left: "22px",
                        zIndex: 1,
                      }}
                      src={OnboardingSidePanelLine}
                      alt="OnboardingSidePanelLine"
                    />
                    {childrenArray.map((child, index) => (
                      <div
                        className={`onboardingSidePanelTile ${
                          index === step && "onboardingSidePanelTileActive"
                        }`}
                        key={child.props.label}
                      >
                        {index === step ? (
                          <img
                            src={EmployeeSidePanelImgActive}
                            alt="EmployeeSidePanelImg"
                          />
                        ) : (
                          <img
                            src={EmployeeSidePanelImg}
                            alt="EmployeeSidePanelImg"
                          />
                        )}
                        <label>{child.props.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="onboardingMainContainer">
                  <div className="onboardingFormContainer">{currentChild}</div>
                  <div className="onboardingFormButtonContainer">
                    <div style={{ display: "flex", gap: "12px" }}>
                      {step > 0 && (
                        <button
                          type="button"
                          className="button backButton noMargin"
                          onClick={() => setStep((s) => s - 1)}
                        >
                          {"< Back"}
                        </button>
                      )}
                      <button
                        className="button primaryButton noMargin"
                        type="submit"
                      >
                        {isLastStep() ? "Complete Onboarding " : "Next >"}
                      </button>
                    </div>
                    <img src={Aesslogo} alt="LoginPerson" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
export { EmployeeOnboardingLanding };
