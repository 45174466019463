import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";

const NoAuth = () => {
  const {
    userLogin,
    candidateLogin,
    candidateonboardingLogin,
    consultantLogin,
  } = useSelector((state) => state.auth);
  const location = useLocation();
  return !userLogin &&
    !candidateLogin &&
    !candidateonboardingLogin &&
    !consultantLogin ? (
    <Outlet />
  ) : (
    <Navigate
      state={{ from: location }}
      to={`${
        userLogin
          ? "/home"
          : candidateLogin
          ? "/candidate/profile"
          : candidateonboardingLogin
          ? "/candidateonboarding/profile"
          : "consultant/profile"
      }`}
      replace
    />
  );
};

export { NoAuth };
