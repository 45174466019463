import React, { useState } from "react";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { useEffect } from "react";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";

const CreateTravelAdvanceComp = ({ formValues }) => {
  const [advanceRequestsList, setadvanceRequestsList] = useState([]);
  const getTypeData = async () => {
    const result = await getRequest(`TravelAdvanceRequests/GetAdvanceTravelID`);
    let listData = [];
    result?.data?.results?.map((item) => {
      return listData.push({
        label: item.text,
        value: item.id ? Number(item.id) : null,
      });
    });
    setadvanceRequestsList(listData);
  };

  useEffect(() => {
    document.title = `PeopleSol - Advance Travel`;

    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    getTypeData();
  }, []);
  return (
    <div>
      <div className="formcustom twoColumn">
        {/* <div>
          <LabelCustom labelName="Travel Planned Start Date" mandatory={true} />
          <DatePickerFormiks
            name="startDate"
            minDate={new Date()}
            maxDate={formValues?.endDate}
          />
        </div>
        <div>
          <LabelCustom labelName="Travel Planned End Date" mandatory={true} />
          <DatePickerFormiks name="endDate" minDate={formValues?.startDate} />
        </div> */}
        <div>
          <LabelCustom labelName={"Travel Request ID"} mandatory={true} />
          <SelectForm
            name={"TravelID"}
            options={advanceRequestsList}
            placeholder={"Select Request ID"}
          />
        </div>
        <div>
          <LabelCustom labelName="Per Day Eligibility" />
          <InputCustomFormik name="perDayEligibilty" disabled />
        </div>
        <div>
          <LabelCustom
            labelName="Travel Advance Amount (In Thousands)"
            mandatory={true}
          />
          <InputCustomFormik
            name="advanceSalary"
            type="number"
            step="0.01"
            min={1}
            max={9999999999}
            placeholder="Enter Travel Advance Amount "
          />
        </div>
        <div>
          <LabelCustom labelName="Purpose of Advance" />
          <InputCustomFormik
            name="purposeOfAdvance"
            maxLength="500"
            placeholder="Enter Purpose of Advance"
          />
        </div>
      </div>
    </div>
  );
};

export { CreateTravelAdvanceComp };
