import React from 'react';
import { WorkLocationTab } from './WorkLocationTab';
import { useEffect } from 'react';

const WorkLocation = () => {
    useEffect(() => {
        document.title = `PeopleSol - Work Location `;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
            <div
        className="headingContainer"
        style={{ padding: "0" }}
      >
        <div
          // onClick={() => navigate(-1)}
          style={{ display: "flex" }}
          className="returnToDashboard cursor"
        >
          <div>
            {/* <svg
              width="20"
              height="8"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                fill="#1296B0"
              />
            </svg> */}
          </div>
          <label className="returnLabelHolder">{"Work locations (Based on Personal Area)"}</label>
        </div>
      </div>
                    <WorkLocationTab title="Work Location"/>
        </div>
    )
}
export { WorkLocation }