/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import GlobalsettingssystemPic from "../../Assets/GlobalsettingssystemPic.svg";
import GlobalsettingsmodulesettingsPic from "../../Assets/GlobalsettingsmodulesettingsPic.svg";
import GlobalsettingssettingsPic from "../../Assets/GlobalsettingssettingsPic.svg";
import GlobalsettingspermissionPic from "../../Assets/GlobalsettingspermissionPic.svg";
import GlobalsettingsrolesPic from "../../Assets/GlobalsettingsrolesPic.svg";
import GlobalsettingsnotificationsPic from "../../Assets/GlobalsettingsnotificationsPic.svg";
import ItsmreportsKPIPic from "../../Assets/ItsmreportsKPIPic.svg";
import "./GlobalSettingsMaster.css";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Notifications,
  System,
  ModuleSettings,
  ReportsandKPI,
  Roles,
  Permission,
} from "./Components";
import { CreateSystemRoles } from "./RolesComponents/CreateSystemRoles";
import { ModuleMasterDetailsPage } from "../HRMSMaster/Components";
import { CreateWorkflowRoles } from "./RolesComponents/CreateWorkflowRoles";
import { CreateNotiFlow } from "./NotificationsComponents/CreateNotiFlow";
import { CreateEmailTemplate } from "./NotificationsComponents/CreateEmailTemplate";
import { CreateTeamsSettings } from "./RolesComponents/CreateTeamsSettings";
import { CreateFolders } from "./ModuleSettingsComponents/CreateFolders";
import { CreateFiles } from "./ModuleSettingsComponents/CreateFiles";
import { Settings } from "./Components/Settings";
import { CreateInfo } from "./SystemComponents/CreateInfo";
import { CreateApplication } from "./SettingComponent/CreateApplication";
import { InfoView } from "./SystemComponents/InfoView";
import { CreateInfoView } from "./SystemComponents/CreateInfoView";
import { DetailsFolder } from "./ModuleSettingsComponents/DetailsFolder";
import { DetailsApplication } from "./SettingComponent/DetailsApplication";
import { DetailsWorkflow } from "./RolesComponents/DetailsWorkflow";
import { DetailsTeams } from "./RolesComponents/DetailsTeams";
import { DetailsNotificationFlow } from "./NotificationsComponents/DetailsNotificationFlow";
import { DetailsEmailTemplate } from "./NotificationsComponents/DetailsEmailTemplate";
import { DetailsFiles } from "./ModuleSettingsComponents/DetailsFiles";
import { CreateGenralWorkflowN } from "./RolesComponents/CreateGenralWorkflowN";
import CreatePermissionSet from "./PermissionComponents/CreatePermissionSet";
import ManageEmployeePermissions from "./PermissionComponents/ManageEmployeePermissions";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../Services/axios";
import { loggedOut } from "../../Features";
import { GroupBy } from "../../Utilities";
import Skeleton from "react-loading-skeleton";
const GlobalSettingsMaster = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [createInfoView, setCreateInfoView] = useState(false);
  const dispatch = useDispatch();
 
  const [subMenuList, setSubMenuList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState("system");
  const [activeFeature, setActiveFeature] = useState("backgroundservices");
  const [createActive, setCreateActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempMenu = Object.values(GroupBy(result?.data, "menuName"));
        tempMenu.map((item) => {
          try {
            if (
              item?.[0]?.menuName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase() !== "globalsettings"
            )
              return null;
            const tempGroup = GroupBy(item, "subMenuName");
            let tempSubMenu = Object.values(tempGroup);
            return setSubMenuList(tempSubMenu);
          } catch (error) {
             
          }
        });
      }
    } catch (err) {
       
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    setCreateInfoView(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("globalsettingsmaster")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex(
        (x) => x === "globalsettingsmaster"
      );
      if (splitArray?.length > submoduleIndex + 1) {
        if (subMenuList) {
          if (
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              subMenuList
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        if (subMenuList)
          navigate(
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (
          splitArray[submoduleIndex + 3] === "create" ||
          splitArray[submoduleIndex + 3] === "manage"
        ) {
          setCreateActive(true);
        } else if (splitArray[submoduleIndex + 3] === "viewinfo") {
          setCreateInfoView(true);
        }
      }
      if (splitArray?.length > submoduleIndex + 4) {
        if (splitArray[submoduleIndex + 4] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 4] === "create") {
          setCreateActive(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, subMenuList]);
  const getFeatureMenu = (submenu) => {
    if (!subMenuList) return null;
    return subMenuList?.find(
      (obj) =>
        obj?.[0]?.subMenuName
          ?.replaceAll(" ", "")
          ?.replaceAll("&", "")
          ?.toLowerCase() === submenu
    );
  };
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "system") return GlobalsettingssystemPic;
    else if (subMenuName === "modulesettings")
      return GlobalsettingsmodulesettingsPic;
    else if (subMenuName === "setting") return GlobalsettingssettingsPic;
    else if (subMenuName === "permission") return GlobalsettingspermissionPic;
    else if (subMenuName === "roles") return GlobalsettingsrolesPic;
    else if (subMenuName === "notification")
      return GlobalsettingsnotificationsPic;
    else if (subMenuName === "reportsandkpi") return ItsmreportsKPIPic;
    return GlobalsettingssystemPic;
  };

  useEffect(() => {
    document.title = `PeopleSol - Global Settings Master`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  return (
    <div className="moduleContainer">
      {activeSubmodule === "modulesettings" && (
        <>
          {activeFeature === "files" && (
            <>
              {createActive && <CreateFiles title={"Create Files"} />}
              {detailsActive && <DetailsFiles />}
            </>
          )}
          {activeFeature === "folders" && (
            <>
              {createActive && <CreateFolders title={"Create Folders"} />}
              {detailsActive && <DetailsFolder />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "settings" && (
        <>
          {activeFeature === "application" && (
            <>
              {createActive && (
                <CreateApplication title={"Create Application"} />
              )}
              {detailsActive && <DetailsApplication />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "roles" && (
        <>
          {activeFeature === "teams" && (
            <>
              {createActive && (
                <CreateTeamsSettings title={"Create Teams Settings"} />
              )}
              {detailsActive && <DetailsTeams />}
            </>
          )}
          {activeFeature === "workflowroles" && (
            <>
              {createActive && (
                <CreateWorkflowRoles title={"Create Workflow Roles"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "workflows" && (
            <>
              {createActive && (
                <CreateGenralWorkflowN title={"Create Genral Workflow"} />
              )}
              {detailsActive && <DetailsWorkflow />}
            </>
          )}
          {activeFeature === "systemroles" && (
            <>
              {createActive && (
                <CreateSystemRoles title={"Create System Roles"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "notifications" && (
        <>
          {activeFeature === "emailtemplates" && (
            <>
              {createActive && (
                <CreateEmailTemplate title={"Create Email Templates"} />
              )}
              {detailsActive && <DetailsEmailTemplate />}
            </>
          )}
          {activeFeature === "emailhistory" && (
            <>{detailsActive && <ModuleMasterDetailsPage />}</>
          )}
          {activeFeature === "notificationflows" && (
            <>
              {createActive && (
                <CreateNotiFlow title={"Create Notification Flow"} />
              )}
              {detailsActive && <DetailsNotificationFlow />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "system" && (
        <>
          {activeFeature === "infotypes" && (
            <>
              {createActive && <CreateInfo title={"Edit"} />}
              {createInfoView && (
                <>
                  {createActive && <CreateInfoView title={"Edit"} />}
                  {detailsActive && <ModuleMasterDetailsPage />}
                </>
              )}
            </>
          )}
          {activeFeature === "emailhistory" && (
            <>{detailsActive && <ModuleMasterDetailsPage />}</>
          )}
          {activeFeature === "notificationflows" && (
            <>
              {createActive && (
                <CreateNotiFlow title={"Create Notification Flow"} />
              )}
              {detailsActive && <DetailsNotificationFlow />}
            </>
          )}
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {loading ? (
            <Skeleton height={55} count={5} />
          ) : (
            <>
              {subMenuList &&
                subMenuList
                  ?.slice()
                  ?.filter((x) => x[0].isSubMenuActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a[0].subMenuOrder) -
                      parseFloat(b[0].subMenuOrder)
                  )
                  ?.map((subMenu, index) => {
                    return (
                      <div
                        key={index}
                        className={`menuOptions ${
                          activeSubmodule ===
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "menuOptionsActive"
                        }`}
                        onClick={() => {
                          setActiveSubmodule(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          );
                          navigate(
                            `/globalsettingsmaster/${subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }}
                      >
                        <div className="verticalLine"></div>
                        <img
                          src={handleMenuIcon(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          )}
                          alt={subMenu[0]?.subMenuDisplayName}
                        />
                        <label>{subMenu[0]?.subMenuDisplayName}</label>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {loading ? (
          <div className="moduleMainContainer">
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {activeSubmodule === "system" && !createInfoView ? (
              <System featureMenu={getFeatureMenu("system")} />
            ) : null}
            {activeSubmodule === "modulesettings" ? (
              <ModuleSettings featureMenu={getFeatureMenu("modulesettings")} />
            ) : null}
            {activeSubmodule === "permission" && !createActive ? (
              <Permission featureMenu={getFeatureMenu("permission")} />
            ) : null}
            {activeSubmodule === "settings" ? (
              <Settings featureMenu={getFeatureMenu("settings")} />
            ) : null}
            {activeSubmodule === "roles" ? (
              <Roles featureMenu={getFeatureMenu("roles")} />
            ) : null}

            {activeSubmodule === "notifications" ? (
              <Notifications featureMenu={getFeatureMenu("notifications")} />
            ) : null}

            {activeSubmodule === "reportsandkpi" ? (
              <ReportsandKPI featureMenu={getFeatureMenu("reportsandkpi")} />
            ) : null}
            {createInfoView ? (
              <div className="moduleMainContainer">
                <InfoView title={"Info View"} />
              </div>
            ) : null}
            {activeSubmodule === "permission" && (
              <>
                {activeFeature === "employeepermissions" && (
                  <>
                    {createActive && (
                      <div className="moduleMainContainer">
                        <ManageEmployeePermissions />
                      </div>
                    )}
                  </>
                )}
                {activeFeature === "permissionsets" && (
                  <>
                    {createActive && (
                      <div className="moduleMainContainer">
                        <CreatePermissionSet />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export { GlobalSettingsMaster };
