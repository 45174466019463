import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";

import * as Yup from "yup";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { format } from "date-fns";

const SurrenderAsset = ({ data, id, type, color, handleClose, eid }) => {
  const [Employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updetails, setupdetails] = useState(false);

  const dispatch = useDispatch();
  const getEmployee = async () => {
    try {
      const result = await getRequest(`LeaveTypes/GetEmployees`);
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployee(listData);
    } catch (error) {
    } finally {
    }
  };
  const surrender = async (val, SurrenderedToEmployeeId, Description) => {
    try {
      setLoading(true);
      let result;
      if (SurrenderedToEmployeeId) {
        result = await getRequest(
          `ItAssets/SurrenderRequest?Id=${updetails?._specifications[0]?.assetId}&SurrenderedToEmployeeId=${SurrenderedToEmployeeId}&Description=${Description}`
        );
      } else {
        result = await getRequest(
          `ItAssets/SurrenderRequest?Id=${updetails?._specifications[0]?.assetId}&Description=${Description}`
        );
      }

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };
  const getUpdetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `ItAssets/ModificationRequestsDetails?Id=${id}`
      );
      setupdetails(result?.data?.summary);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployee();
    getUpdetails();
  }, []);
  const validationSchema = Yup.object({
    SurrenderCondition: Yup.string().required(
      "Please Enter Surrender Condition"
    ),
  });
  const onSubmit = async (values) => {
    const create = {
      ...values,
      ID: id,
    };
    surrender(
      create,
      create?.SurrenderedToEmployeeId,
      create?.SurrenderCondition
    );
  };

  useEffect(() => {
    document.title = `PeopleSol - Surrenser Asset`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={{ SurrenderCondition: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div>
            <FieldArray name="Specifications">
              {(fieldArrayProps) => {
                const { form } = fieldArrayProps;
                const { values } = form;
                const { Specifications } = values;
                return (
                  <StyledTableCont>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            sx={{
                              padding: "0px !important",
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S.No"} />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              padding: "0px !important",
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Spec Name"} />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Spec Value"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {updetails?._specifications?.map((item, index) => (
                        <TableBody key={index}>
                          {/* 1st column */}
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            <InputCustomFormik
                              name={`Specifications[${index}].name`}
                              value={item?.name}
                              disabled
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {item?.name?.toLowerCase()?.includes("date") ? (
                              <DatePickerFormiks
                                name={`Specifications[${index}].Value`}
                                minDate={new Date()}
                                value={format(
                                  new Date(item.value),
                                  "dd/MM/yyyy"
                                )}
                                disabled={true}
                              />
                            ) : (
                              <InputCustomFormik
                                name={`Specifications[${index}].Value`}
                                type="text"
                                placeholder="Enter Spec Value"
                                disabled={true}
                                value={item.value}
                              />
                            )}
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  </StyledTableCont>
                );
              }}
            </FieldArray>
          </div>
          <div className="formcustom">
            {/* <div>
              <LabelCustom labelName={"Surrendered To"} mandatory={true} />
              <SelectForm name="SurrenderedToEmployeeId" options={Employee} />
            </div> */}
            <div>
              <LabelCustom labelName={"Description"} mandatory={true} />
              <TextAreaFormik
                style={{ height: "80px" }}
                name={"SurrenderCondition"}
                maxLength={500}
              />
            </div>
          </div>
          <div className="createFootCust">
            <button
              type="button"
              className="button secondaryButton"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="button primaryButton"
              type="submit"
              style={{ background: color, color: "white" }}
            >
              {type}
            </button>
          </div>
        </Form>
      </Formik>
    );
};

export { SurrenderAsset };
