import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { AllRequestApprovalAppraisalCriteria } from "../Components/AppraisalCriteriaApproval/AllRequestApprovalAppraisalCriteria";
import { PendingApprovalAppraisalCriteria } from "../Components/AppraisalCriteriaApproval/PendingApprovalAppraisalCriteria";
import { ApprovedApprovalAppraisalCriteria } from "../Components/AppraisalCriteriaApproval/ApprovedApprovalAppraisalCriteria";

const ApprovalAppraisalCriteria = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Reviewed & Approved
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalAppraisalCriteria
            title={"Appraisal Criteria"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <ApprovedApprovalAppraisalCriteria
          title={"Appraisal Criteria"}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <AllRequestApprovalAppraisalCriteria
            title={"Appraisal Criteria"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalAppraisalCriteria };
