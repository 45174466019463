import { Link, Navigate } from "react-router-dom";
import ExtractHashWords from "./ExtractHashWords";

let imgArray = [
  "png",
  "jpg",
  "jpeg",
  "jfif",
  "gif",
  "JPG",
  "PNG",
  "GIF",
  "JPEG",
  "JFIF",
];
let videoArray = ["mp4", "avi", "mkv", "mo", "MP4", "AVI", "MKV", "MO"];
let docsArray = ["pdf", "PDF"];

function isUrlValid(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return pattern.test(str);
}

const getFormattedCount = (count) => {
  if (count < 1000) {
    return `${count}`;
  } else if (count < 1000000) {
    return `${(count / 1000).toFixed(1)}K`;
  } else if (count < 1000000000) {
    return `${(count / 1000000).toFixed(1)}M`;
  } else if (count < 1000000000000) {
    return `${(count / 1000000000).toFixed(1)}B`;
  }
};

const getAttachments = (images) => {
  let img = [];
  if (images) {
    img = images?.split(",");
  }
  return img;
};
function getExtension(x) {
  if (x) {
    const parts = x?.split(".");
    const extension = parts[parts.length - 1];
    return extension;
  }
  return null;
}
const isImage = (img) => {
  let attachment = getAttachments(img);
  if (attachment) {
    let x = getExtension(attachment[0]);
    if (imgArray.includes(x)) {
      return true;
    } else {
      return false;
    }
  }
};
const isVideos = (video) => {
  let attachment = getAttachments(video);
  if (attachment) {
    let x = getExtension(attachment[0]);
    if (videoArray.includes(x)) {
      return true;
    } else {
      return false;
    }
  }
};
function getPersonNumber(nameWithPersonNumber) {
  let index = nameWithPersonNumber?.indexOf("(");
  let name = nameWithPersonNumber?.substring(index);
  return name;
}
const formatText = (text) => {
  return text.replace(/\n/g, "<br>");
};
function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + "</a>";
  });
}

const findHashtags = (text, fun) => {
  const hashtagRegex = /#\w+/g;
  if (text.match(hashtagRegex)) {
    return (
      "<span onClick={" + fun + "}>" + text.match(hashtagRegex) + "</span>"
    );
  }
  return null;
};

function emailfy(text) {
  var emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  return text.replace(emailRegex, function (url) {
    return '<a href="mailto:' + url + '" target="_blank">' + url + "</a>";
  });
}

function renderMentions(textWithMentions) {
  const mentionRegex = /@\[([^\]]+)]\(([^)]+)\)/g;
  let match;
  let lastIndex = 0;
  const jsxElements = [];

  while ((match = mentionRegex.exec(textWithMentions)) !== null) {
    const [fullMatch, displayName, userId] = match;
    const linkText = textWithMentions.substring(lastIndex, match.index);
    lastIndex = mentionRegex.lastIndex;
    jsxElements.push(
      <>
        <>{linkText}</>
        <Link to={"/home/social/userprofile/" + userId}>{displayName}</Link>
      </>
    );
  }
  const remainingText = textWithMentions.substring(lastIndex);
  jsxElements.push(<>{remainingText}</>);
  return jsxElements;
}

const patterns = [
  {
    regex: /\@\[([^\]]+)\]\((\d+)\)/g,
    replaceFunction: (_, text, userId) => {
      return `<a href="/home/social/userprofile/${userId}">${text}</a>`;
    },
  },
  {
    regex: /(https?:\/\/[^\s]+)/g,
    replaceFunction: (url) => `<a href="${url}" target="_blank">${url}</a>`,
  },
  {
    regex: /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g,
    replaceFunction: (email) =>
      `<a href="mailto:${email}" target="_blank">${email}</a>`,
  },
  {
    regex: /\n/g,
    replaceFunction: "<br>",
  },
  {
    regex: /#([^\s]+)/g,
    replaceFunction: (_, word) =>
      `<a href="/home/social/search/${word}" >#${word}</a>`,
  },
];

function parsePostContent(inputString) {
  const result = [];

  // Regular expression patterns for different types of content
  const mentionPattern = /\@\[([^\]]+)\]\((\d+)\)/;
  const hashTagPattern = /\#(\w+)/g;
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const linkPattern = /https?:\/\/[^\s]+/g;

  // Split the input string by newline characters
  const lines = inputString.split("\n");

  for (const line of lines) {
    const mentionMatch = line.match(mentionPattern);
    if (mentionMatch) {
      result.push({
        content: mentionMatch[1],
        type: "user_mention",
        userId: parseInt(mentionMatch[2]),
      });
    }

    const hashTagMatches = line.match(hashTagPattern);
    if (hashTagMatches) {
      for (const tag of hashTagMatches) {
        result.push({
          content: `${tag}`,
          type: "hash_tag",
        });
      }
    }

    const emailMatches = line.match(emailPattern);
    if (emailMatches) {
      for (const email of emailMatches) {
        result.push({
          content: email,
          type: "email",
        });
      }
    }

    const linkMatches = line.match(linkPattern);
    if (linkMatches) {
      for (const link of linkMatches) {
        result.push({
          content: link,
          type: "link",
        });
      }
    }

    const remainingText = line
      .replace(mentionPattern, "")
      .replace(hashTagPattern, "")
      .replace(emailPattern, "")
      .replace(linkPattern, "");
    if (remainingText.trim().length > 0) {
      result.push({
        content: remainingText,
        type: "normal text",
      });
    }

    result.push({
      content: "\n",
      type: "new_line",
    });
  }

  return result;
}

function processTextWithPatterns(text, patterns) {
  return patterns.reduce((processedText, pattern) => {
    const { regex, replaceFunction } = pattern;
    return processedText.replace(regex, replaceFunction);
  }, text);
}

function processExpression(text) {
  return processTextWithPatterns(text, patterns);
  // return (
  //   <div
  //     dangerouslySetInnerHTML={{
  //       __html: processTextWithPatterns(text, patterns),
  //     }}
  //   />
  // );
}

const handleMentionClick = (userId) => {};
const findshword = (text) => {
  let words = text.split(" ");
  words.forEach((d) => {
    if (d.includes("#")) {
      return <span>{d}</span>;
    } else {
      return d;
    }
  });
};

const renderClickableText = (text) => {
  const parts = text.split(/@\[[^\]]+\]\(\d+\)/);
  return parts.map((part, index) => {
    if (/@\[[^\]]+\]\(\d+\)/.test(part)) {
      const mentionMatch = part.match(/@\[([^\]]+)\]\((\d+)\)/);
      const mentionName = mentionMatch[1];
      const userId = mentionMatch[2];
      return (
        <span
          key={index}
          onClick={() => handleMentionClick(userId)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {mentionName}
        </span>
      );
    } else {
      return part;
    }
  });
};

const changeFileValues = (files, index = 0) => {
  let newFile = null;
  if (files?.length > 0) {
    for (let i = 0; i < files?.length; i++) {
      newFile = files.FileList[i] = null;
    }
  }
};

function getUserInitials(uname) {
  let nameParts = uname?.trim().split(" ");
  let initials = "";
  if (nameParts?.length > 1) {
    initials = nameParts
      .slice(0, 2)
      .map((item) => {
        return item?.charAt(0);
      })
      .join("");
  } else {
    initials = uname?.substring(0, 1);
  }
  return initials?.toUpperCase();
}

function getContentLength(text) {
  const lines = text?.split(/\r\n|\r|\n/);
  return lines?.slice(0, 4).join("\n");
}

export {
  isUrlValid,
  getAttachments,
  getExtension,
  isImage,
  isVideos,
  getPersonNumber,
  formatText,
  urlify,
  emailfy,
  findHashtags,
  getUserInitials,
  imgArray,
  videoArray,
  docsArray,
  renderMentions,
  processExpression,
  parsePostContent,
  renderClickableText,
  getFormattedCount,
  getContentLength,
};
