import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { CreateStageComp } from "../FormComp/CreateStageComp";
import { useEffect } from "react";





const CreateStage = (props) => {

    useEffect(()=>{
        document.title = `PeopleSol - Create Stage`;
        return () => {
          document.title = 'PeopleSol';
        };
      })

    const { state } = useLocation();

    const data = state?.dataEdit;

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const createPageData = async (values) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await postRequest('PipelineStage/Create', values);
            dispatch(showToast({ text: "Successfully Created", severity: "success" }));
        } catch (error) {
            dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    }
    const Edit = async (values) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await postRequest('PipelineStage/Edit', values);
            dispatch(showToast({ text: "Successfully Updated", severity: "success" }));
        } catch (error) {
            dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    }
    const initialvalue = {
        title: "",
        tatInHrs: ""
    };
    const validationSchema = Yup.object({
        title: Yup.string().max(25).required("Please enter the title"),
        tatInHrs: Yup.string().required("Please enter TAT in Hrs")
    });

    const onSubmit = (values) => {
        const edit = {
            ...values,
            Id: data?.Id
        };

        const create = {
            ...values,
            Id: 0
        }
        data ? Edit(edit) : createPageData(create);
        returnPage()
    }


    const navigate = useNavigate();

    const returnPage = () => {
        navigate("atsmaster/stage");
    };
    return (
        <FormCustomFormik FormHeading={props.title} ButtonLabel={data ? 'Update' : 'Create'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
            data
                ? {
                    ...initialvalue,
                    title: data?.Title,
                    tatInHrs: data?.TATInHrs
                }
                : initialvalue
        }
            returnPage={returnPage} propsDiv={<CreateStageComp data={data} />} />
    );
};

export { CreateStage };