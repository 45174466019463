import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { useEffect } from "react";
import { CreatePerformanceRatingSlabComp } from "./CreatePerformanceRatingSlabComp";

const CreatePerformanceRatingSlab = () => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);

  const data = state?.id;
  const [radioOptions, setRadioOptions] = useState([]);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(false);
  const [initialValues, setinitialValues] = useState({
    nameAppraisalCriteria: "",
    criteria: [
      {
        departmentSelected: [],
        // AppraisalWeightage: [
        //   { label: "Performance", Performance: "", isActive: true },
        //   { label: "Tasks", Tasks: "", isActive: true },
        //   { label: "Rewards", Rewards: "", isActive: true },
        //   { label: "L&D", ld: "", isActive: true },
        // ],
        // AppraisalWeightageTotal: "",
        appraisalSlab: [
          {
            fromWeightageRange: "",
            toWeightageRange: "",
            name: "",
            rating: "",
          },
        ],
      },
    ],
  });
  const duplicateDepartment = (values) => {
    if (!Array.isArray(values?.criteria)) {
      return false;
    }

    const numberCounts = {};

    for (const item of values?.criteria) {
      if (Array.isArray(item.departmentSelected)) {
        for (const number of item.departmentSelected) {
          if (numberCounts[number]) {
            return true; // Found a duplicate number
          } else {
            numberCounts[number] = 1;
          }
        }
      }
    }

    return false; // No duplicates found
  };

  useEffect(() => {
    if (data)
      setinitialValues(() => {
        return {
          nameAppraisalCriteria: data
            ? detailsform?.pmsAppraisalCriteria?.name
            : "",
          criteria: detailsform?.pmsAppraisalCriteria?.pmsPerformanceRatingItem
            ? detailsform?.pmsAppraisalCriteria?.pmsPerformanceRatingItem?.map(
                (item) => ({
                  departmentSelected: item?.pmsPerformanceRatingDepartment?.map(
                    (it) => it?.departmentId?.toString()
                  ),
                  appraisalSlab:
                    item?.pmsPerformanceRatingSlab?.length > 0
                      ? item?.pmsPerformanceRatingSlab
                          ?.filter((item) => item?.reviewby === "Creator")
                          ?.map((it) => ({
                            fromWeightageRange: data ? it?.weightFrom : "",
                            toWeightageRange: data ? it?.weightTo : "",
                            name: data ? it?.name : "",
                            rating: data ? it?.rating : "",
                          }))
                      : [
                          {
                            fromWeightageRange: "",
                            toWeightageRange: "",
                            name: "",
                            rating: "",
                          },
                        ],
                })
              )
            : [
                {
                  departmentSelected: [],
                  appraisalSlab: [
                    {
                      fromWeightageRange: "",
                      toWeightageRange: "",
                      name: "",
                      rating: "",
                    },
                  ],
                },
              ],
        };
      });
  }, [detailsform, radioOptions]);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `pmsPerformanceRatingSlab/Details?ID=${data}`
      );
      setDetailsform(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "pmsPerformanceRatingSlab/Create",
        values
      );
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      } else {
        dispatch(showToast({ text: result?.data?.message, severity: "error" }));
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Performance Slab`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, [data]);
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("pmsPerformanceRatingSlab/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    nameAppraisalCriteria: Yup.string()
      .max(149, "Name must be less than 150 characters")
      .required("Please enter the Name "),
    criteria: Yup.array().of(
      Yup.object().shape({
        departmentSelected: Yup.array()
          .min(1, "Please Select atleast one Department")
          .required("Please Select Department"),
        // AppraisalWeightageTotal: Yup.number()
        //   .required("Total must be 100")
        //   .equals([100], "Total must be 100"),
        appraisalSlab: Yup.array().of(
          Yup.object().shape({
            fromWeightageRange: Yup.number().required("Enter"),
            toWeightageRange: Yup.number().required("Enter"),
            name: Yup.string().required("Enter"),
            rating: Yup.number()
              .required("Enter")
              .oneOf(
                [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
                "Rating must be in steps of 0.5 and less than or equal to 5"
              ),
          })
        ),
      })
    ),
  });

  const onSubmit = (values) => {
    // if (isFilledOrNot(values)) {
    if (duplicateDepartment(values)) {
      dispatch(
        showToast({
          text: "Selected departments should be unique for each criteria",
          severity: "error",
        })
      );
    } else {
      const createValues = {
        Id: data ? detailsform?.pmsAppraisalCriteria?.id : 0,
        Name: values?.nameAppraisalCriteria,
        Items: values?.criteria?.map((item, index) => ({
          ItemName: "Criteria" + Number(index + 1),
          Departments: item?.departmentSelected?.map((it) => Number(it)),
          Slabs: item?.appraisalSlab?.map((it) => ({
            WeightFrom: Number(it?.fromWeightageRange),
            WeightTo: Number(it?.toWeightageRange),
            Name: it?.name,
            Rating: Number(it?.rating),
          })),
        })),
      };
      data ? Edit(createValues) : createPageData(createValues);
    }
    // } else
    //   dispatch(
    //     showToast({
    //       text: "Enter All Weightage Percentage",
    //       severity: "error",
    //     })
    //   );
  };

  const navigate = useNavigate();
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  console.log("object formValues", formValues);
  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={
        data ? "Edit Performance Rating Slab" : "Create Performance Rating Slab"
      }
      ButtonLabel={
        data ? "Edit Performance Rating Slab" : "Create Performance Rating Slab"
      }
      onSubmit={onSubmit}
      styles={{ width: "841px", maxWidth: "80%" }}
      validationSchema={validationSchema}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={
        <CreatePerformanceRatingSlabComp
          formValues={formValues}
          setinitialValues={setinitialValues}
          setRadioOptions={setRadioOptions}
          radioOptions={radioOptions}
        />
      }
    />
  );
};
export { CreatePerformanceRatingSlab };
