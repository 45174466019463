/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreatePersonalSubAreaComp } from "./FormComp/CreatePersonalSubAreaComp";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CreatePersonalSubArea = () => {
  const { state } = useLocation();

  const data = state?.id;
  const [detail, setDetail] = React.useState({});
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const getDetails = async () => {
    try {
      const result = await getRequest(`PersonalSub/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("PersonalSub/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Personal SubArea `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("PersonalSub/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  // //console.log('detail',detail)

  const initialvalue = {
    name: data ? detail?.location?.name : "",
    hrbp: data ? detail?.hrbp?.id : null,
    locationGroupId: data ? detail?.locationGroup?.id : null,
    securityAdminId: data ? detail?.employee?.map((item) => item.id) : null,
    isMetro: data ? (detail?.location?.isMetro === true ? "Yes" : "No") : "Yes",
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please Enter Name "),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
      isMetro: values?.isMetro === "Yes" ? true : false,
      EmployeeId: values?.securityAdminId,
      securityAdminId: null,
    };

    const create = {
      ...values,
      id: 0,
      EmployeeId: values?.securityAdminId,
      securityAdminId: null,
      isMetro: values.isMetro === "Yes" ? true : false,
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Personal Sub Area" : "Create Personal Sub Area"}
      ButtonLabel={
        data ? "Update Personal Sub Area" : "Create Personal Sub Area"
      }
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreatePersonalSubAreaComp data={detail} editID={data ? true : false} />
      }
    />
  );
};

export { CreatePersonalSubArea };
