import React from 'react'
import UserOnboardingEmployeeSelfService from "../../../../../Assets/UserOnboardingEmployeeSelfService.png";
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useEffect } from 'react';

const EmployeeSelfService = () => {

    const navigate = useNavigate();

    useEffect(() => {
      document.title = `PeopleSol - Employee Self Service`;
      return () => {
        document.title = 'PeopleSol';
      };
    }, []);

  return (
    <>
      <div className="imageHolder">

      <img style={{width:'100%', height: 'calc(100% - 10rem)'}} src={UserOnboardingEmployeeSelfService} alt={UserOnboardingEmployeeSelfService} />      
    
        </div>

        <div className="onboardInfoHolder" style={{marginLeft: '30px'}}>
           <h1 className='onboardHeading'>Employee <br/> Self Service</h1>
           <label className='onboardText' style={{marginTop: '100px'}}>An Integrated People Solution to Manage your HR, IT and Office Facilities for your Organizations.</label>
        </div>
           <div className="onboardfooterButton">
            <IconButton>
            <label className='cursor' style={{color: 'var(--primary)', fontWeight: '500', fontSize: '18px'}} onClick={() => navigate("/")}>Skip</label>
            </IconButton>

            <div className='onboardNextButtonHolder'>
                <label className='button SecondaryButton onboardButtons onboardPrev' onClick={() => navigate("/useronboarding/managerselfservice")}>Previous</label>

                <label className='button PrimaryButton onboardButtons onboardNext' onClick={() => navigate("/useronboarding/mobileappdownload")}>Next</label>

            </div>
           </div>
    </>
  )
}

export {EmployeeSelfService}
