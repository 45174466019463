import React, { useState, useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";

export const FlightCandidateComp = ({
  formValues,
  travelCategoryOption,
  setFormValues,
  detailsform,
}) => {
  const travellingOptions = [
    { label: "One Way Trip", value: "One Way Trip" },
    { label: "Round Trip", value: "Round Trip" },
    { label: "Multilocation Trip", value: "Multilocation Trip" },
  ];
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  const [employeeList, setEmployeeList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Candidate Flight`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        "Candidate/GetCandidatesListWithoutRejection"
      );
      // //console.log("result", result);
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        // style={
        //   formValues?.TravelingType ? { borderBottom: "1px solid #E6E6E6" } : {}
        // }
        className="formcustom threeColumn"
      >
        <div>
          <LabelCustom labelName={"Travel Type"} mandatory={true} />
          <SelectForm name="TravelingType" options={travellingOptions} />
        </div>
        {formValues?.TravelingType && (
          <div>
            <LabelCustom labelName={"Travel Category"} mandatory={true} />
            <SelectForm name="CategoryID" options={travelCategoryOption} />
          </div>
        )}
        {formValues?.TravelingType && (
          <div>
            <LabelCustom labelName={"Budget (In Thousands)"} mandatory={true} />
            <InputCustomFormik
              type="number"
              name="Budget"
              placeholder={" Enter Budget"}
            />
          </div>
        )}
      </div>
      {formValues?.TravelingType && (
        <div className="formcustom threeColumn">
          <div>
            <LabelCustom labelName={"Candidate"} mandatory={true} />
            <SelectForm name="CandidateId" options={employeeList} />
          </div>
          <div>
            <LabelCustom labelName={"Gender"} />
            <SelectForm name="Gender" options={genderOptions} />
          </div>
          <div>
            <LabelCustom labelName={"Age"} />
            <InputCustomFormik
              maxLength={3}
              name="Age"
              type={"number"}
              placeholder={" Enter Age"}
            />
          </div>

          {formValues?.TravelingType !== "Multilocation Trip" && (
            <>
              {" "}
              <div>
                <LabelCustom labelName={"Travelling Date"} mandatory={true} />
                <DatePickerFormiks name={"FromDate"} minDate={new Date()} />
              </div>
              <div>
                <LabelCustom labelName={"From Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="PickupLocation"
                  type={"text"}
                  placeholder={" Enter From Location"}
                />
              </div>
              <div>
                <LabelCustom labelName={"To Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="DropLocation"
                  type={"text"}
                  placeholder={" Enter To Location"}
                />
              </div>
              {formValues?.TravelingType === "Round Trip" && (
                <>
                  <div>
                    <LabelCustom labelName={"Return Date"} mandatory={true} />
                    <DatePickerFormiks
                      name={"ToDate"}
                      minDate={new Date(formValues?.FromDate)}
                    />
                  </div>
                  <div>
                    <LabelCustom labelName={"From Location"} mandatory={true} />
                    <InputCustomFormik
                      maxLength={50}
                      name="PickupLocationR"
                      type={"text"}
                      placeholder={" Enter From Location"}
                    />
                  </div>

                  <div>
                    <LabelCustom labelName={"To Location"} mandatory={true} />
                    <InputCustomFormik
                      maxLength={50}
                      name="DropLocationR"
                      type={"text"}
                      placeholder={" Enter To Location"}
                    />
                  </div>
                </>
              )}
              <div>
                <LabelCustom labelName={"Booking Note"} />
                <TextAreaFormik
                  name="Bookingnote"
                  placeholder={" Enter Booking Note"}
                  maxLength={500}
                />
              </div>
            </>
          )}
        </div>
      )}
      {formValues?.TravelingType &&
        formValues?.TravelingType === "Multilocation Trip" && (
          <div
            style={{ marginTop: "20px" }}
            className="repeatedSectionContainer"
          >
            <label className="repeatedHeading">
              Please specify your journey details
            </label>
            <FieldArray name="TravelingDetails">
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values, errors } = form;
                const { TravelingDetails } = values;
                return (
                  <StyledTableCont>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S. No."} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Travelling Date"}
                              mandatory={true}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"From Location"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"To Location"} />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Booking Note"} />{" "}
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={" Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {TravelingDetails?.map((Requestlist, index) => (
                        <TableBody key={index}>
                          {/* 1st column */}
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          {/* 2nd column */}
                          <StyledTableCell>
                            <DatePickerFormiks
                              name={`TravelingDetails[${index}].FromDate`}
                              minDate={new Date()}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={50}
                              name={`TravelingDetails[${index}].PickupLocation`}
                              type="text"
                              placeholder={" Enter From Location"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={50}
                              name={`TravelingDetails[${index}].DropLocation`}
                              type={"text"}
                              placeholder={" Enter To Location"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <TextAreaFormik
                              name={`TravelingDetails[${index}].Bookingnote`}
                              placeholder={" Enter Booking Note"}
                              maxLength={500}
                            />
                          </StyledTableCell>
                          {/* 8th column */}
                          <StyledTableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                TravelingDetails.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  FromDate: null,
                                  PickupLocation: "",
                                  DropLocation: "",
                                  Bookingnote: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  </StyledTableCont>
                );
              }}
            </FieldArray>
          </div>
        )}
    </>
  );
};
