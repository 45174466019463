import React, { useState, useEffect } from "react";
import { FieldArray, Formik } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";

const opt = [{ label: "select", value: null }];

const guestOptions = [
  { label: "Casual", value: "Casual" },
  { label: "Special", value: "Special" },
];
const foodOptions = [
  { label: "Veg", value: "Veg" },
  { label: "Non-Veg", value: "Non-Veg" },
];

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];
export const CreateSendCourierComp = ({ data, edit, formValues }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Create Send Courier`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Date"} mandatory={true} />
          <DatePickerFormiks name="date" showTimeSelect minDate={new Date()} />
        </div>
        <div>
          <LabelCustom labelName={"Courier Details"} mandatory={true} />
          <InputCustomFormik
            name="title"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter Courier Details"}
          />
        </div>
        <div>
          <LabelCustom labelName={" Address Line 1"} mandatory={true} />
          <TextAreaFormik
            name="destination"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter Address"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Address Line 2"} mandatory={true} />
          <TextAreaFormik
            name="AddressLine2"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter Address"}
          />
        </div>
        <div>
          <LabelCustom labelName={"City "} mandatory={true} />
          <InputCustomFormik
            name="City"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter City "}
          />
        </div>
        <div>
          <LabelCustom labelName={"State "} mandatory={true} />
          <InputCustomFormik
            name="State"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter State "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Country"} mandatory={true} />
          <InputCustomFormik
            name="Country"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter Country"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Zip Code"} mandatory={true} />
          <InputCustomFormik
            name="ZipCode"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter Zip Code"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Additional Info."} mandatory={true} />
          <TextAreaFormik
            name="description"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter Additional Info."}
          />
        </div>
      </div>
    </>
  );
};
