/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateNotiFlowComp } from "./FormComp/CreateNotiFlowComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateNotiFlow = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Notification Flow`;
    return () => {
      document.title = "PeopleSol";
    };
  });

  const { state } = useLocation();

  const data = state?.id;

  const [detailsform, setDetailsform] = useState(false);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`NotificationFlows/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequest("NotificationFlows/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("NotificationFlows/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  //  //console.log('detailsform',detailsform)

  const initialvalue = {
    title: "",
    areminder1: "",
    areminder2: "",
    areminder3: "",
    aescalation1: "",
    aescalation2: "",
    aescalation3: "",

    breminder1: "",
    breminder2: "",
    breminder3: "",
    bescalation1: "",
    bescalation2: "",
    bescalation3: "",

    creminder1: "",
    creminder2: "",
    creminder3: "",
    cescalation1: "",
    cescalation2: "",
    cescalation3: "",
  };
  const validationSchema = Yup.object({
    title: Yup.string()
      .max(149, "Title must be less than 150 characters")
      .required("Please Enter Title"),
    areminder1: Yup.string().required("Please Enter Value"),
    areminder2: Yup.string().required("Please Enter Value"),
    areminder3: Yup.string().required("Please Enter Value"),
    aescalation1: Yup.string().required("Please Enter Value"),
    aescalation2: Yup.string().required("Please Enter Value"),
    aescalation3: Yup.string().required("Please Enter Value"),

    breminder1: Yup.string().required("Please Enter Value"),
    breminder2: Yup.string().required("Please Enter Value"),
    breminder3: Yup.string().required("Please Enter Value"),
    bescalation1: Yup.string().required("Please Enter Value"),
    bescalation2: Yup.string().required("Please Enter Value"),
    bescalation3: Yup.string().required("Please Enter Value"),

    creminder1: Yup.string().required("Please Enter Value"),
    creminder2: Yup.string().required("Please Enter Value"),
    creminder3: Yup.string().required("Please Enter Value"),
    cescalation1: Yup.string().required("Please Enter Value"),
    cescalation2: Yup.string().required("Please Enter Value"),
    cescalation3: Yup.string().required("Please Enter Value"),
  });

  const onSubmit = (values) => {
    let edit;
    if (data) {
      edit = {
        // ...values,
        id: detailsform?.id,
        title: values?.title,
        items: [
          {
            id: detailsform?.notificationFlowsIntervals[0]?.id,
            priority: "High",
            reminder1: values?.areminder1,
            reminder2: values?.areminder2,
            reminder3: values?.areminder3,
            escalation1: values?.aescalation1,
            escalation2: values?.aescalation2,
            escalation3: values?.aescalation3,
          },

          {
            id: detailsform?.notificationFlowsIntervals[1]?.id,
            priority: "Medium",
            reminder1: values?.breminder1,
            reminder2: values?.breminder2,
            reminder3: values?.breminder3,
            escalation1: values?.bescalation1,
            escalation2: values?.bescalation2,
            escalation3: values?.bescalation3,
          },

          {
            id: detailsform?.notificationFlowsIntervals[2]?.id,
            priority: "Low",
            reminder1: values?.creminder1,
            reminder2: values?.creminder2,
            reminder3: values?.creminder3,
            escalation1: values?.cescalation1,
            escalation2: values?.cescalation2,
            escalation3: values?.cescalation3,
          },
        ],
      };
    }

    const create = {
      // ...values,
      id: 0,
      title: values?.title,
      items: [
        {
          id: 0,
          priority: "High",
          reminder1: values?.areminder1,
          reminder2: values?.areminder2,
          reminder3: values?.areminder3,
          escalation1: values?.aescalation1,
          escalation2: values?.aescalation2,
          escalation3: values?.aescalation3,
        },

        {
          id: 0,
          priority: "Medium",
          reminder1: values?.breminder1,
          reminder2: values?.breminder2,
          reminder3: values?.breminder3,
          escalation1: values?.bescalation1,
          escalation2: values?.bescalation2,
          escalation3: values?.bescalation3,
        },

        {
          id: 0,
          priority: "Low",
          reminder1: values?.creminder1,
          reminder2: values?.creminder2,
          reminder3: values?.creminder3,
          escalation1: values?.cescalation1,
          escalation2: values?.cescalation2,
          escalation3: values?.cescalation3,
        },
      ],
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Notification Flow" : "Create Notification Flow"}
      ButtonLabel={
        data ? "Update Notification Flow" : "Create Notification Flow"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        detailsform
          ? {
              title: detailsform?.title,
              areminder1:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[0]?.reminder1,
              areminder2:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[0]?.reminder2,
              areminder3:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[0]?.reminder3,
              aescalation1:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[0].escalation1,
              aescalation2:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[0].escalation2,
              aescalation3:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[0].escalation3,

              breminder1:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[1]?.reminder1,
              breminder2:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[1]?.reminder2,
              breminder3:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[1]?.reminder3,
              bescalation1:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[1].escalation1,
              bescalation2:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[1].escalation2,
              bescalation3:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[1].escalation3,

              creminder1:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[2]?.reminder1,
              creminder2:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[2]?.reminder2,
              creminder3:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[2]?.reminder3,
              cescalation1:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[2].escalation1,
              cescalation2:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[2].escalation2,
              cescalation3:
                detailsform?.notificationFlowsIntervals?.length > 0 &&
                detailsform?.notificationFlowsIntervals[2].escalation3,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateNotiFlowComp />}
    />
  );
};

export { CreateNotiFlow };
