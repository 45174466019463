import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingRequesLoan } from "../Components/LoanAdvanceApproval/PendingRequesLoan";
import { ApprovedRequestLoan } from "../Components/LoanAdvanceApproval/ApprovedRequestLoan";
import { RejectedRequestLoan } from "../Components/LoanAdvanceApproval/RejectedRequestLoan";
import { AllRequestLoan } from "../Components/LoanAdvanceApproval/AllRequestLoan";
import { useEffect } from "react";

const ApprovalLoanAdvance = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Advance Loan`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingRequesLoan
            title={"Loan"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <ApprovedRequestLoan
          title={"Loan"}
          getApprovalBadge={getApprovalBadge}
        />
      )}
      {tabIndex === 2 && (
        <>
          <RejectedRequestLoan
            title={"Loan"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <AllRequestLoan title={"Loan"} getApprovalBadge={getApprovalBadge} />
        </>
      )}
    </div>
  );
};

export { ApprovalLoanAdvance };
