import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast, isLoading } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";

import { ReqCategoryFormIt } from "./Form/ReqCategoryFormIt";
import { reqCategorySchema } from "../../HRMSMaster/HRDeskComponents/RequestCategorySchemas";
import { useEffect } from "react";

const initialvalue = {
  name: "",
};

const CreateRequestCategoryIt = (props) => {

  useEffect(() => {
    document.title = `PeopleSol - Create Request Ctegory`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const { state } = useLocation();
  
  const data = state?.selectedRecord;

  const dispatch = useDispatch();

  const postFormData = async (values) => {
    try {
      
      const result = await postRequest(
        "ItsmRequestCategories/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {}
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "ItsmRequestCategories/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }

    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  const onSubmit = async (values) => {

    const edit = {
      ...values,
      id: data?.Id,
    };

    const create = {
      ...values,
    };
    
    data ? Edit(edit) : postFormData(create);


    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
    ButtonLabel={data?'Update Request Category':'Create Request Category'}
    FormHeading={data?'Edit Request Category':'Create Request Category'}
      validationSchema={reqCategorySchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: data?.Name,
            }
          : 
          initialvalue
      }
      returnPage={returnPage}
      propsDiv={<ReqCategoryFormIt />}
    />
  );
};

export { CreateRequestCategoryIt };


