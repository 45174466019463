import React, { useEffect, useState } from "react";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../Services/axios";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { format } from "date-fns";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { Form, Formik } from "formik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";

const ReschedulingChargesTickets = ({ id, handleClose, getListData }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [selectedTravelEmployees, setSelectedTravelEmployees] = useState("");
  console.log("object formValues", formValues);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${id}`
      );
      setData(result?.data?.data);
      const resultantData = {
        travelJourney: result?.data?.data?.travellingdetails
          ?.map((person, index) => {
            if (person?.status?.toLowerCase()?.includes("charge not added")) {
              return Array?.isArray(result?.data?.data?.travelDetailNnew) &&
                result?.data?.data?.travelDetailNnew?.length > 0
                ? result?.data?.data?.travelDetailNnew
                    ?.filter((it) => person.id === it?.originalTravelDetailId)
                    ?.map((itemz) => ({
                      detailIsCancelled: person?.status
                        ?.toLowerCase()
                        ?.includes("charge not added")
                        ? true
                        : false,
                      detailIsCancel: false,
                      detailIsReschedule: false,
                      detailForm: {
                        travelId: itemz?.id,
                        fromLocation: itemz?.pickupLocation,
                        toLocation: itemz?.dropLocation,
                        travellingDate: itemz?.fromDate
                          ? new Date(itemz?.fromDate)
                          : null,
                        bookingNote: itemz?.bookingnote,
                      },
                      detailActions:
                        result?.data?.data?.new_Travel_Journey_actions
                          ?.filter((it) => it?.id === itemz?.id)
                          ?.map((item) => item),
                      detailInd: index,
                      detailElement: itemz,
                      detailArr:
                        Array?.isArray(result?.data?.data?.travelEmployeeNew) &&
                        result?.data?.data?.travelEmployeeNew?.length > 0
                          ? result?.data?.data?.travelEmployeeNew
                              ?.filter(
                                (items) =>
                                  items?.newTravellingDetailId === itemz?.id
                              )
                              ?.map((item, indexW) => ({
                                element: item,
                                ind: indexW,
                                isSelected: false,
                              }))
                          : [],
                    }))
                : //
                  [];
            } else {
              return {
                detailIsCancelled: person?.status
                  ?.toLowerCase()
                  ?.includes("charge not added")
                  ? true
                  : false,
                detailIsCancel: false,
                detailIsReschedule: false,
                detailForm: {
                  travelId: person?.id,
                  fromLocation: person?.pickupLocation,
                  toLocation: person?.dropLocation,
                  travellingDate: person?.fromDate
                    ? new Date(person?.fromDate)
                    : null,
                  bookingNote: person?.bookingnote,
                },
                detailActions: result?.data?.data?.travel_Journey_actions
                  ?.filter((it) => it?.id === person?.id)
                  ?.map((item) => item),
                detailInd: index,
                detailElement: person,
                detailArr: result?.data?.data?.travelemployees
                  ?.filter((items) => items?.travellingDetailId === person?.id)
                  ?.map((item, indexW) => ({
                    element: item,
                    ind: indexW,
                    isSelected: false,
                  })),
              };
            }
          })
          ?.flat(),
        hotelBooked: result?.data?.data?.hoteldetail
          ?.map((person, index) => {
            if (person?.status?.toLowerCase()?.includes("charge not added")) {
              return Array?.isArray(result?.data?.data?.hotelDetailsNew) &&
                result?.data?.data?.hotelDetailsNew?.length > 0
                ? result?.data?.data?.hotelDetailsNew
                    ?.filter((it) => person.id === it?.originalHotelBookinglId)
                    ?.map((itemz) => ({
                      detailIsCancelled: person?.status
                        ?.toLowerCase()
                        ?.includes("charge not added")
                        ? true
                        : false,
                      detailIsCancel: false,
                      detailIsReschedule: false,
                      detailForm: {
                        hotelCategory: itemz?.hotelCategory?.toString(),
                        location: itemz?.location,
                        checkInDate: itemz?.checkInTime
                          ? new Date(itemz?.checkInTime)
                          : null,
                        checkOutDate: itemz?.checkOutTime
                          ? new Date(itemz?.checkOutTime)
                          : null,
                        preferredHotel: itemz?.preferHotel,
                        bookingNote: itemz?.bookingNote,
                      },
                      detailInd: index,
                      detailElement: itemz,
                      detailActions:
                        result?.data?.data?.new_Hotel_Journey_actions
                          ?.filter((it) => it?.id === itemz?.id)
                          ?.map((item) => item),
                      detailArr:
                        Array?.isArray(result?.data?.data?.hotelEmployeeNew) &&
                        result?.data?.data?.hotelEmployeeNew?.length > 0
                          ? result?.data?.data?.hotelEmployeeNew
                              ?.filter(
                                (items) => items?.hotelDetailNewId === itemz?.id
                              )
                              ?.map((item, indexW) => ({
                                element: item,
                                ind: indexW,
                                isSelected: false,
                              }))
                          : [],
                    }))
                : //
                  [];
            } else {
              return {
                detailIsCancelled: person?.status
                  ?.toLowerCase()
                  ?.includes("charge not added")
                  ? true
                  : false,
                detailIsCancel: false,
                detailIsReschedule: false,
                detailForm: {
                  hotelCategory: [
                    {
                      label: result?.data?.data?.category_name_hotel,
                      value: person?.hotelCategory?.toString(),
                    },
                  ],
                  location: person?.location,
                  checkInDate: person?.checkInTime
                    ? new Date(person?.checkInTime)
                    : null,
                  checkOutDate: person?.checkOutTime
                    ? new Date(person?.checkOutTime)
                    : null,
                  preferredHotel: person?.preferHotel,
                  bookingNote: person?.bookingNote,
                },
                detailInd: index,
                detailActions: result?.data?.data?.hotel_Journey_actions
                  ?.filter((it) => it?.id === person?.id)
                  ?.map((item) => item),
                detailElement: person,
                detailArr: result?.data?.data?.hotelemployee
                  ?.filter((items) => items?.hotelDetailId === person?.id)
                  ?.map((item, indexW) => ({
                    element: item,
                    ind: indexW,
                    isSelected: false,
                  })),
              };
            }
          })
          ?.flat(),
      };
      if (
        result?.data?.data?.travelDetailNnew?.some(
          (it) => it?.originalTravelDetailId === null
        )
      ) {
        resultantData?.travelJourney?.push(
          result?.data?.data?.travelDetailNnew
            ?.filter((it) => it?.originalTravelDetailId === null)
            ?.sort((a, b) => a.id - b.id)
            ?.map((itemz) => ({
              detailIsCancelled: itemz?.status
                ?.toLowerCase()
                ?.includes("charge not added")
                ? true
                : false,
              detailIsCancel: false,
              detailIsReschedule: false,
              detailForm: {
                travelId: itemz?.id,
                fromLocation: itemz?.pickupLocation,
                toLocation: itemz?.dropLocation,
                travellingDate: itemz?.fromDate
                  ? new Date(itemz?.fromDate)
                  : null,
                bookingNote: itemz?.bookingnote,
              },
              // detailInd: index,
              detailElement: itemz,
              detailActions: result?.data?.data?.new_Travel_Journey_actions
                ?.filter((it) => it?.id === itemz?.id)
                ?.map((item) => item),

              detailArr:
                Array?.isArray(result?.data?.data?.travelEmployeeNew) &&
                result?.data?.data?.travelEmployeeNew?.length > 0
                  ? result?.data?.data?.travelEmployeeNew
                      ?.filter(
                        (items) => items?.newTravellingDetailId === itemz?.id
                      )
                      ?.map((item, indexW) => ({
                        element: item,
                        ind: indexW,
                        isSelected: false,
                      }))
                  : [],
            }))
          //
        );
        resultantData.travelJourney = resultantData?.travelJourney?.flat();
      }
      if (
        result?.data?.data?.hotelDetailsNew?.some(
          (it) => it?.originalHotelBookinglId === null
        )
      ) {
        resultantData?.hotelBooked?.push(
          result?.data?.data?.hotelDetailsNew
            ?.filter((it) => it?.originalHotelBookinglId === null)
            ?.sort((a, b) => a.id - b.id)
            ?.map((itemz) => ({
              detailIsCancelled: itemz?.status
                ?.toLowerCase()
                ?.includes("charge not added")
                ? true
                : false,
              detailIsCancel: false,
              detailIsReschedule: false,
              detailForm: {
                hotelCategory: itemz?.hotelCategory?.toString(),
                location: itemz?.location,
                checkInDate: itemz?.checkInTime
                  ? new Date(itemz?.checkInTime)
                  : null,
                checkOutDate: itemz?.checkOutTime
                  ? new Date(itemz?.checkOutTime)
                  : null,
                preferredHotel: itemz?.preferHotel,
                bookingNote: itemz?.bookingNote,
              },
              // detailInd: index,
              detailElement: itemz,
              detailActions: result?.data?.data?.new_Hotel_Journey_actions
                ?.filter((it) => it?.id === itemz?.id)
                ?.map((item) => item),
              detailArr:
                Array?.isArray(result?.data?.data?.hotelEmployeeNew) &&
                result?.data?.data?.hotelEmployeeNew?.length > 0
                  ? result?.data?.data?.hotelEmployeeNew
                      ?.filter((items) => items?.hotelDetailNewId === itemz?.id)
                      ?.map((item, indexW) => ({
                        element: item,
                        ind: indexW,
                        isSelected: false,
                      }))
                  : [],
            }))
          //
        );
        resultantData.hotelBooked = resultantData?.hotelBooked?.flat();
      }
      setSelectedTravelEmployees(resultantData);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  console.log("object selectedTravelEmployees", selectedTravelEmployees);
  useEffect(() => {
    getDetails();
  }, []);
  useEffect(() => {
    if (data) {
      setInitialValues({
        travelDetail: selectedTravelEmployees?.travelJourney
          ?.filter((it) => it?.detailIsCancelled)
          ?.map((item) => ({
            id: item?.detailElement?.id,
            cancelledTicketPrice: "",
            addCancellationCharge: "",
          })),
        hotelDetail: selectedTravelEmployees?.hotelBooked
          ?.filter((it) => it?.detailIsCancelled)
          ?.map((item) => ({
            id: item?.detailElement?.id,
            cancelledTicketPrice: "",
            addCancellationCharge: "",
          })),
      });
    }
  }, [selectedTravelEmployees]);
  const [initialValues, setInitialValues] = useState({
    travelDetail: [
      {
        cancelledTicketPrice: "",
        addCancellationCharge: "",
      },
    ],
    hotelDetail: [
      {
        cancelledTicketPrice: "",
        addCancellationCharge: "",
      },
    ],
  });
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const create = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "TravelHospitalityRequests/RescheduleCharge",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      handleClose();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.message ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getListData();
    }
  };
  const onSubmit = (values) => {
    const payload = { id: data?._request?.id };

    const HotelbookingdetailsNew = values?.hotelDetail?.map((item, index) => ({
      id: item?.id,
      Reschedule_TicketPrice: item?.cancelledTicketPrice,
      Reschedule_Price: item?.addCancellationCharge,
    }));
    if (values?.hotelDetail?.length > 0)
      payload["HotelbookingdetailsNew"] = HotelbookingdetailsNew;

    const TravelingDetailsNew = values?.travelDetail?.map((item, index) => ({
      id: item?.id,
      Reschedule_TicketPrice: item?.cancelledTicketPrice,
      Reschedule_Price: item?.addCancellationCharge,
    }));
    if (values?.travelDetail?.length > 0)
      payload["TravelingDetailsNew"] = TravelingDetailsNew;
    create(payload);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={
        formValues
          ? formValues?.travelDetail?.length > 0
            ? Yup.object({
                travelDetail: Yup.array().of(
                  Yup.object().shape({
                    cancelledTicketPrice: Yup.string().required(
                      "Ticket Price Is Required"
                    ),
                    addCancellationCharge: Yup.string().required(
                      "Reschedule Price Is Required"
                    ),
                  })
                ),
              })
            : formValues?.hotelDetail?.length > 0
            ? Yup.object({
                hotelDetail: Yup.array().of(
                  Yup.object().shape({
                    cancelledTicketPrice: Yup.string().required(
                      "Ticket Price Is Required"
                    ),
                    addCancellationCharge: Yup.string().required(
                      "Reschedule Price Is Required"
                    ),
                  })
                ),
              })
            : formValues?.travelDetail?.length > 0 &&
              formValues?.hotelDetail?.length > 0 &&
              Yup.object({
                hotelDetail: Yup.array().of(
                  Yup.object().shape({
                    cancelledTicketPrice: Yup.string().required(
                      "Ticket Price Is Required"
                    ),
                    addCancellationCharge: Yup.string().required(
                      "Reschedule Price Is Required"
                    ),
                  })
                ),
                travelDetail: Yup.array().of(
                  Yup.object().shape({
                    cancelledTicketPrice: Yup.string().required(
                      "Ticket Price Is Required"
                    ),
                    addCancellationCharge: Yup.string().required(
                      "Reschedule Price Is Required"
                    ),
                  })
                ),
              })
          : Yup.object({})
      }
      onSubmit={onSubmit}
    >
      {(formik) => {
        chooseFormValues && chooseFormValues(formik.values);
        return (
          <Form
            style={{
              padding: " 15px 15px 0 15px ",
              position: "relative",
              height: "100%",
              flexGrow: 1,
            }}
          >
            {/* {propsDiv} */}
            {/* <CreateTravelBookingAddJourneyComp /> */}
            {loading ? (
              <Loader />
            ) : (
              <>
                <div
                  style={{
                    width: "850px",
                    padding: "20px",
                    backgroundColor: "white",
                  }}
                >
                  <div className="detailInner">
                    <Details
                      detailTitle={"Request Number"}
                      Details={data?._request?.requestNumber}
                    />
                    <Details
                      detailTitle={"Request Cancelled By"}
                      Details={data?._request?.employee?.fullName}
                    />
                  </div>{" "}
                  {selectedTravelEmployees?.travelJourney?.some(
                    (it) => it?.detailIsCancelled
                  ) && (
                    <div className="headingForm" style={{ width: "100%" }}>
                      Journey Ticket Details
                    </div>
                  )}
                  {selectedTravelEmployees?.travelJourney
                    ?.filter((it) => it?.detailIsCancelled)
                    ?.map((person, index) => (
                      <>
                        <div className="detailInner">
                          <Details
                            detailTitle={" S. No."}
                            Details={index + 1}
                          />
                          <Details
                            detailTitle={"Booking Date"}
                            Details={format(
                              new Date(person?.detailElement?.fromDate),
                              "dd/MM/yy"
                            )}
                          />
                          <Details
                            detailTitle={"From Location"}
                            Details={person?.detailElement?.pickupLocation}
                          />
                          <Details
                            detailTitle={"To Location"}
                            Details={person?.detailElement?.dropLocation}
                          />
                          <Details
                            detailTitle={"Tickets"}
                            Details={
                              <a
                                href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${person?.detailElement?.finalTicket}`}
                                target="blank"
                              >
                                {person?.detailElement?.finalTicket}
                              </a>
                            }
                          />
                          <Details
                            detailTitle={"Booking Note"}
                            Details={person?.detailElement?.bookingnote}
                          />
                        </div>
                        <div
                          style={{
                            overflowY: "auto",
                          }}
                        >
                          <table
                            className="TravelTableMultiIn"
                            style={{
                              overflow: "auto",
                              width: "100%",
                              // height: "197px",
                            }}
                          >
                            <thead
                              style={{
                                backgroundColor: "var(--primary-hover)",
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  S.no{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {data?._request?.type === "Self"
                                    ? "Employee's"
                                    : data?._request?.type === "Group"
                                    ? "Employee's"
                                    : data?._request?.type === "Vendor"
                                    ? "Vendor's"
                                    : data?._request?.type === "Guest"
                                    ? "Guest's"
                                    : data?._request?.type === "Candidate" &&
                                      "Candidate's"}{" "}
                                  Name{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Phone No.{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Email{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Age{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Gender{" "}
                                </td>
                              </tr>
                            </thead>
                            {person?.detailArr?.map((item, indexW) => (
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {indexW + 1}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.name}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.phoneNumber}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.emailId}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.age}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.gender}
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                        <div className="formcustom twoColumn">
                          <div>
                            <LabelCustom
                              labelName={"Add Ticket Price"}
                              mandatory={true}
                            />
                            <InputCustomFormik
                              style={{ border: "1px solid #E6E6E6" }}
                              type="number"
                              name={`travelDetail[${index}].cancelledTicketPrice`}
                              placeholder={"Enter"}
                            />
                          </div>
                          <div>
                            <LabelCustom
                              labelName={"Add Reschedule Price"}
                              mandatory={true}
                            />
                            <InputCustomFormik
                              style={{ border: "1px solid #E6E6E6" }}
                              type="number"
                              name={`travelDetail[${index}].addCancellationCharge`}
                              placeholder={"Enter"}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  {selectedTravelEmployees?.hotelBooked?.some(
                    (it) => it?.detailIsCancelled
                  ) && (
                    <div className="headingForm" style={{ width: "100%" }}>
                      Hotel Booking Details
                    </div>
                  )}
                  {selectedTravelEmployees?.hotelBooked
                    ?.filter((it) => it?.detailIsCancelled)
                    ?.map((person, index) => (
                      <>
                        <div className="detailInner">
                          <Details
                            detailTitle={" S. No."}
                            Details={index + 1}
                          />
                          <Details
                            detailTitle={"Location"}
                            Details={person?.detailElement?.location}
                          />
                          <Details
                            detailTitle={"Check In Time"}
                            Details={format(
                              new Date(person?.detailElement?.checkInTime),
                              "dd/MM/yy hh:mm a"
                            )}
                          />
                          <Details
                            detailTitle={"Check Out Time"}
                            Details={format(
                              new Date(person?.detailElement?.checkOutTime),
                              "dd/MM/yy hh:mm a"
                            )}
                          />
                          <Details
                            detailTitle={"Preferred Hotel"}
                            Details={person?.detailElement?.preferHotel}
                          />
                          <Details
                            detailTitle={"Hotel Receipt"}
                            Details={
                              <a
                                href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${person?.detailElement?.final_ticket_upload_hotel}`}
                                target="blank"
                              >
                                {
                                  person?.detailElement
                                    ?.final_ticket_upload_hotel
                                }
                              </a>
                            }
                          />
                          <Details
                            detailTitle={"Booking Note"}
                            Details={person?.detailElement?.bookingNote}
                          />
                        </div>
                        <div
                          style={{
                            overflowY: "auto",
                          }}
                        >
                          <table
                            className="TravelTableMultiIn"
                            style={{
                              overflow: "auto",
                              width: "100%",
                              // height: "197px",
                            }}
                          >
                            <thead
                              style={{
                                backgroundColor: "var(--primary-hover)",
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  S.no{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {data?._request?.type === "Self"
                                    ? "Employee's"
                                    : data?._request?.type === "Group"
                                    ? "Employee's"
                                    : data?._request?.type === "Vendor"
                                    ? "Vendor's"
                                    : data?._request?.type === "Guest"
                                    ? "Guest's"
                                    : data?._request?.type === "Candidate" &&
                                      "Candidate's"}{" "}
                                  Name{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Phone No.{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Email{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Age{" "}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Gender{" "}
                                </td>
                              </tr>
                            </thead>
                            {person?.detailArr?.map((item, indexW) => (
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {indexW + 1}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.name}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.phoneNumber}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.emailId}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.age}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {item?.element?.gender}
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                        <div className="formcustom twoColumn">
                          <div>
                            <LabelCustom
                              labelName={"Add Ticket Price"}
                              mandatory={true}
                            />
                            <InputCustomFormik
                              style={{ border: "1px solid #E6E6E6" }}
                              type="number"
                              name={`travelDetail[${index}].cancelledTicketPrice`}
                              placeholder={"Enter"}
                            />
                          </div>
                          <div>
                            <LabelCustom
                              labelName={"Add Reschedule Price"}
                              mandatory={true}
                            />
                            <InputCustomFormik
                              style={{ border: "1px solid #E6E6E6" }}
                              type="number"
                              name={`travelDetail[${index}].addCancellationCharge`}
                              placeholder={"Enter"}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>
                <div className="createFootCust">
                  <div>
                    <div
                      onClick={handleClose}
                      className={`button secondaryButton`}
                    >
                      Cancel
                    </div>
                  </div>
                  <div>
                    <button className="button primaryButton" type="submit">
                      Upload
                    </button>
                  </div>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReschedulingChargesTickets;
