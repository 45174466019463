/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getRequest, postRequest } from "../../../../Services/axios";
import { isLoading, showToast } from "../../../../Features";
// import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
// import { useDispatch } from "react-redux";
// import { showToast, isLoading } from "../../../../Features";
// import { getRequest, postRequest } from "../../../../Services/axios";
// import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { CreateOvertimeComp } from "./FormComp/CreateOvertimeComp";
import { format } from "date-fns";

// import { CreateOvertimeComp } from "../BenefitsComponents/Form/CreateOvertimeComp";

const CreateOvertime = (props) => {
  const { state } = useLocation();

  const data = state?.id;
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();

  const [detailsform, setDetailsform] = useState(null);
  const [bannerList, setBannerList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [compList, setCompList] = useState(null);
  const [resulti, setResult] = useState(null);
  const location = useLocation();

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("OverTimeRequest/Create", values);
      setResult(result?.data);
      dispatch(
        showToast({
          text: "Overtime has been created successfully",
          severity: "success",
        })
      );
    } catch (error) {
      console.error("mistake" + error);
      console.error(resulti);
      dispatch(
        showToast({
          text: "Sorry, You are already created the request with the same date!",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  //console.log("dksjf", formValues);
  useEffect(() => {
    document.title = `PeopleSol - Create Peoplesol`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("OverTimeRequest/Edit", values);

      if (result) {
        if (result.data.isSuccess === false) {
          dispatch(showToast({ text: result.data.message, severity: "error" }));
        } else {
          dispatch(
            showToast({ text: result.data.message, severity: "success" })
          );
        }
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    }
  };

  const getProfileData = async () => {
    try {
      const result = await getRequest("Employees/GetBannerProfile");
      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  // console.log("first", compList);

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("create")) getProfileData();
  }, [location.pathname]);

  const getId = bannerList?.Id;

  const getAttendanceData = async () => {
    try {
      const result = await getRequest(
        `OverTimeRequest/GetOverTime?StartDate=${format(
          new Date(formValues?.Date),
          "yyyy-MM-dd"
        )}`
      );
      if (result?.data) {
        if (result?.data > 0) {
          formValues.OverTimeHours = result?.data;
        } else {
          formValues.OverTimeHours = null;
        }
      }
      setCompList(result?.data);
    } catch (error) {
      setCompList("");
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
    }
  };
  useEffect(() => {
    if (formValues?.Date) {
      getAttendanceData();
    }
  }, [formValues?.Date]);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`OverTimeRequest/Details?ID=${data}`);
      // console.log("CompoffRequestsrequests", result);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  // console.log("first", detailsform?.data?.requests?.date);

  const initialvalue = {
    Date: "",
    OverTimeHours: data ? detailsform?.data?.requests?.overTimeHours : "",
  };

  const validationSchema = Yup.object({
    Date: Yup.date()
      .max(
        new Date(),
        `Date field must be earlier than ${dateFormat("dd-mm-yyyy")}`
      )
      .required("Please enter the Date"),
    OverTimeHours: Yup.string().required("Selected date has no overtime"),
  });

  const onSubmit = (values) => {
    const edit = {
      id: detailsform?.data?.requests?.id,
      Date: format(new Date(values?.Date), "yyyy-MM-dd"),
      OverTimeHours: values?.OverTimeHours,
    };

    const create = {
      ...values,
      id: 0,
      Date: format(new Date(values?.Date), "yyyy-MM-dd"),
      OverTimeHours: values?.OverTimeHours,
    };

    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  return (
    <FormCustomFormik
      loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Edit Overtime Request" : "Create Overtime Request"}
      ButtonLabel={data ? "Edit Overtime Request" : "Create Overtime Request"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        detailsform
          ? {
              ...initialvalue,

              Date: detailsform?.data?.requests?.date
                ? new Date(detailsform?.data?.requests?.date)
                : "",
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreateOvertimeComp formValues={formValues} apiValues={compList} />
      }
    />
  );
};

export { CreateOvertime };
