import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import UploadImageDetail from "../../../Components/UploadDetail/UploadImageDetail";
import { format } from "date-fns";

const DetailsChapter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(false);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TrainingCourseChapters/Details/${selectedId}`
      );
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Lesson Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getEmployeeData();
    return () => { };
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Lesson Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            <DetailsStep
              detailTitle={"Course Name"}
              Details={eventtData?.categoryname}
            />
            <DetailsStep
              detailTitle={"Chapter Name"}
              Details={eventtData?.trainingCourseChapters?.course?.title}
            />
            <DetailsStep
              detailTitle={"Lesson Name"}
              Details={eventtData?.trainingCourseChapters?.title}
            />
            <DetailsStep
              detailTitle={"Lesson Description "}
              Details={eventtData?.trainingCourseChapters?.description}
            />
            <DetailsStep
              detailTitle={"Lesson Pass Percentage"}
              Details={eventtData?.trainingCourseChapters?.passPercentage}
            />
            <DetailsStep
              detailTitle={"Video Url"}
              Details={
                <a
                  href={eventtData?.trainingCourseChapters?.videoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {eventtData?.trainingCourseChapters?.videoUrl}
                </a>
              }
            />
            <DetailsStep
              detailTitle={"Video Duration (in seconds)"}
              Details={eventtData?.trainingCourseChapters?.duration}
            />
            <DetailsStep
              detailTitle={"Video Cover Image "}
              Details={
                <UploadImageDetail
                  filepath={`TrainingCourseChapters/${eventtData?.trainingCourseChapters?.coverImage}`}
                />
              }
            />
            <DetailsStep
              detailTitle={"Is Active"}
              Details={
                eventtData?.trainingCourseChapters?.isActive === true
                  ? "Yes"
                  : "No"
              }
            />{" "}
            <DetailsStep
              detailTitle={"Created On"}
              Details={
                eventtData?.trainingCourseChapters?.createdOn &&
                format(
                  new Date(eventtData?.trainingCourseChapters?.createdOn),
                  "dd/MM/yyyy hh:mm a"
                )
              }
            />
            <DetailsStep
              detailTitle={"Last Updated On"}
              Details={
                eventtData?.trainingCourseChapters?.createdOn &&
                format(
                  new Date(eventtData?.trainingCourseChapters?.lastUpdatedOn),
                  "dd/MM/yyyy hh:mm a"
                )
              }
            />
            <div>
              <h4 style={{ margin: "10px 0px" }}>Questions</h4>
            </div>
            <div style={{ width: "100%" }}>
              <table className="tabless tabless-bordered">
                <thead
                  style={{
                    backgroundColor: "var(--primary-hover)",
                  }}
                >
                  <tr>
                    <td>S.no</td>
                    <td>Question Set</td>
                    <td>Trigger Position (in Seconds)</td>
                  </tr>
                </thead>
                <tbody>
                  {eventtData?.questions?.length > 0
                    ? eventtData?.questions?.map((item, index) => {
                      return (
                        <tr className="trStyle">
                          <td>{index + 1}</td>
                          <td>{item?.questionSet?.name}</td>
                          <td>{item?.triggerPositionSecond}</td>
                        </tr>
                      );
                    })
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsChapter };
