import "../../Pages/HRMS/Components/Create.css";
import { Formik, Form } from "formik";
import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "../../Assets/CloseIcon.svg";
import { Loader } from "./Loader";
import { useEffect } from "react";
const FormCustomFormik = ({
  FormHeading,
  returnPage,
  propsDiv,
  ButtonLabel,
  validationSchema,
  initialvalue,
  onSubmit,
  ref,
  chooseFormValues,
  loading,
  styles,
  className,
  btnLabel,
  BeforeButtonComp = null,
}) => {
  return (
    <div className="createPageContainer side-navigation "  >
      <div className="createProjectContainer requsitionForm" style={styles}>
        <div className="createHeader">
          <label>{FormHeading}</label>
          <IconButton
            className="closeIconHolder"
            onClick={returnPage}
            title="Close"
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </IconButton>
        </div>
        <div className="horizontalLine createLine"></div>
        <div className="bodyContainer " style={{ overflow: "hidden", }} >
          {loading ? (
            <Loader />
          ) : (
            <Formik
              innerRef={ref}
              enableReinitialize={true}
              initialValues={initialvalue}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                chooseFormValues && chooseFormValues(formik.values);
                return (
                  <Form
                    style={{
                      position: "relative",
                      height: "100%",
                      flexGrow: 1,
                    }}
                    className={className}
                  >
                    {/* {propsDiv} */}
                    <div style={{
                      padding: " 15px 15px 0 15px ",
                      overflow: "auto",
                      flexGrow: 1,
                      maxHeight: "520px"
                    }}>
                      {React.cloneElement(propsDiv, { formik: formik })}
                    </div>
                    <div className="createFootCust" style={{
                      paddingRight: "15px",
                      marginTop: "10px",
                      display: "flex",
                      backgroundColor: "#f8f8f8f3",
                      position: "sticky",
                      bottom: "0px",
                      width: "100%",
                      zIndex: 1,
                      flexDirection: "column",
                      height: BeforeButtonComp ? "auto" : "65px",
                      // borderTop: "1px solid rgba(230, 230, 230, 1)",
                    }}>
                      {BeforeButtonComp ? React.cloneElement(BeforeButtonComp, { formik: formik }) : null}
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "100%"
                      }} >
                        <div>
                          <div
                            onClick={returnPage}
                            className={`button secondaryButton ${btnLabel}`}
                            style={{ backgroundColor: "white", borderColor: "rgba(18, 150, 176, 1)", borderStyle: "solid", borderWidth: 1, color: "rgba(18, 150, 176, 1)" }}
                          >
                            Cancel
                          </div>
                        </div>
                        <div>
                          <button className="button primaryButton" type="submit" >
                            {ButtonLabel}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};
export { FormCustomFormik };
