import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { FieldArray, Form, Formik } from "formik";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import SurveyAnswersDeleteIcon from "../../../../Assets/SurveyAnswersDeleteIcon.svg";
import { format } from "date-fns";
import { Loader } from "../../../../Components/FormComponent/Loader";

const HotelBookingAddJourney = ({
  togglePopup,
  detailsData,
  getDetails,
  isBoth,
}) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  const [hotelCategoryOptions, sethotelCategoryOptions] = useState(null);
  const [candidateList, setCandidateList] = useState([]);

  const getFormDetails = async () => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Getcategory?mode=Hotel`
      );

      let findCategory = result.data?.results?.map((d, i) => {
        return { label: d?.text, value: d?.id };
      });
      sethotelCategoryOptions(findCategory);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getFormDetails();
  }, []);

  const getCandidateData = async () => {
    try {
      const result = await getRequest(
        "Candidate/GetCandidatesListWithoutRejection"
      );
      // //console.log("result", result);
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setCandidateList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const tripInitialValuesSelf = {
    id: detailsData?._request?.id,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    Type: detailsData?._request?.type,
    TravellingType: detailsData?.hoteldetail[0]?.travellingType,
    HotelCategory: "",
    CheckInTime: null,
    CheckOutTime: null,
    Location: "",
    PreferHotel: "",
    Adults: "",
    Children: "",
    Budget: "",
    BookingNote: "",
    hotelbookingdetails: [
      {
        HotelCategory: null,
        CheckInTime: null,
        CheckOutTime: null,
        Location: "",
        PreferHotel: "",
        Adults: "",
        Children: "",
        Bookingnote: "",
      },
    ],
  };

  const tripInitialValuesGroup = {
    id: detailsData?._request?.id,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    Type: detailsData?._request?.type,
    TravellingType: detailsData?.hoteldetail[0]?.travellingType,
    HotelCategory: "",
    CheckInTime: "",
    CheckOutTime: "",
    Location: "",
    PreferHotel: "",
    Adults: "",
    Children: "",
    Budget: "",
    BookingNote: "",
    groupItemsNotMulti: [
      {
        EmployeeId: null,
        Adults: null,
        Children: null,
        PhoneNumber: "",
        EmailId: "",
      },
    ],
    groupItems: [
      {
        HotelCategory: null,
        CheckInTime: null,
        CheckOutTime: null,
        Location: "",
        PreferHotel: "",
        Bookingnote: "",

        EmployeeDetails: [
          {
            EmployeeId: null,
            Adults: null,
            Children: null,
            PhoneNumber: "",
            EmailId: "",
          },
        ],
      },
    ],
  };
  const tripInitialValuesVendor = {
    id: detailsData?._request?.id,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    Type: detailsData?._request?.type,
    TravellingType: detailsData?.hoteldetail[0]?.travellingType,
    HotelCategory: "",
    CheckInTime: "",
    CheckOutTime: "",
    Location: "",
    PreferHotel: "",
    Adults: "",
    Children: "",
    Budget: "",
    BookingNote: "",
    groupItemsNotMulti: [
      {
        Name: "",
        Adults: null,
        Children: null,
        PhoneNumber: "",
        EmailId: "",
      },
    ],
    groupItems: [
      {
        HotelCategory: null,
        CheckInTime: null,
        CheckOutTime: null,
        Location: "",
        PreferHotel: "",
        Bookingnote: "",
        EmployeeDetails: [
          {
            Name: "",
            Adults: null,
            Children: null,
            PhoneNumber: "",
            EmailId: "",
          },
        ],
      },
    ],
  };

  const tripInitialValuesCandidate = {
    id: detailsData?._request?.id,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    Type: detailsData?._request?.type,
    TravellingType: detailsData?.hoteldetail[0]?.travellingType,
    HotelCategory: "",
    CheckInTime: "",
    CheckOutTime: "",
    CandidateId: "",
    Location: "",
    PreferHotel: "",
    Adults: "",
    Children: "",
    Budget: "",
    BookingNote: "",
    hotelbookingdetails: [
      {
        HotelCategory: null,
        CheckInTime: null,
        CheckOutTime: null,
        Location: "",
        PreferHotel: "",
        Adults: "",
        Children: "",
        Bookingnote: "",
      },
    ],
  };
  useEffect(() => {
    if (detailsData?._request?.type === "Self") {
      setInitialValues(tripInitialValuesSelf);
    } else if (detailsData?._request?.type === "Group") {
      setInitialValues(tripInitialValuesGroup);
    } else if (detailsData?._request?.type === "Vendor") {
      setInitialValues(tripInitialValuesVendor);
    } else if (detailsData?._request?.type === "Guest") {
      setInitialValues(tripInitialValuesVendor);
    } else if (detailsData?._request?.type === "Candidate") {
      setInitialValues(tripInitialValuesCandidate);
    }
  }, [detailsData]);
  const [initialValues, setInitialValues] = useState({
    id: detailsData?._request?.id,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    Type: detailsData?._request?.type,
    TravellingType: detailsData?.hoteldetail[0]?.travellingType,
    Budget: "",
    groupItems: [
      {
        CategoryID: detailsData?.travellingdetails[0]?.categoryID,
        FromDate: null,
        PickupLocation: "",
        DropLocation: "",
        Bookingnote: "",
        EmployeeDetails: [
          {
            EmployeeId: null,
            Age: null,
            Gender: "",
            PhoneNumber: "",
            EmailId: "",
          },
        ],
      },
    ],
  });

  useEffect(() => {
    getEmployeeData();
    getCandidateData();
  }, []);

  console.log("object initialValues", initialValues);

  console.log("object formValues", formValues);

  const create = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "TravelHospitalityRequests/AddJourney",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      togglePopup();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.message ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getDetails();
    }
  };

  const pushEmployee = (index) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails =
        formValues?.Type === "Vendor" || formValues?.Type === "Guest"
          ? [
              ...newGroupItems[index].EmployeeDetails,
              {
                Name: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ]
          : formValues?.Type === "Group" && [
              ...newGroupItems[index].EmployeeDetails,
              {
                EmployeeId: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ];
      newGroupItems[index] = {
        ...newGroupItems[index],
        EmployeeDetails: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const popAnswer = (index, indexW) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails = newGroupItems[index].EmployeeDetails?.filter(
        (item, ind) => ind !== indexW
      );

      newGroupItems[index] = {
        ...newGroupItems[index],
        EmployeeDetails: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const flightSelfOneWaySchema = Yup.object({
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = formValues?.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Hotel."),
    Budget: Yup.string().required("Please Enter the Budget"),
  });
  const flightSelfMultiTripSchema = Yup.object({
    Budget: Yup.string().required("Please Enter Budget"),
    hotelbookingdetails: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Hotel."
        ),
      })
    ),
  });
  const flightGroupOneWaySchema = Yup.object({
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Hotel."),
    Budget: Yup.string().required("Please Enter the Budget"),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        EmployeeId: Yup.string().required("Please Select Employee"),
        PhoneNumber: Yup.string().required("Please Enter Phone Number"),
        EmailId: Yup.string().required("Please Enter Email"),
      })
    ),
  });
  const flightGroupMultiTripSchema = Yup.object({
    Budget: Yup.string().required("Please Enter Budget"),
    groupItems: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true;
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required("Please Enter the Preferred Hotel."),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            EmployeeId: Yup.string().required("Please Select Employee"),
            PhoneNumber: Yup.string().required("Please Enter Phone Number"),
            EmailId: Yup.string().required("Please Enter Email"),
          })
        ),
      })
    ),
  });
  const flightVendorOneWaySchema = Yup.object({
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Hotel."),
    Budget: Yup.string().required("Please Enter the Budget"),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required("Please Enter Name"),
        PhoneNumber: Yup.string().required("Please Enter Phone Number"),
        EmailId: Yup.string().required("Please Enter Email"),
      })
    ),
  });
  const flightVendorMultiTripSchema = Yup.object({
    Budget: Yup.string().required("Please Enter Budget"),
    groupItems: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Hotel."
        ),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            Name: Yup.string().required("Please Enter Name"),
            PhoneNumber: Yup.string().required("Please Enter Phone Number"),
            EmailId: Yup.string().required("Please Enter Email"),
          })
        ),
      })
    ),
  });
  const flightCandidateOneWaySchema = Yup.object({
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true;
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Hotel."),
    Budget: Yup.string().required("Please Enter the Budget"),
    CandidateId: Yup.string().required(" Please Select Candidate"),
  });
  const flightCandidateMultiTripSchema = Yup.object({
    CandidateId: Yup.string().required(" Please Select Candidate"),
    Budget: Yup.string().required("Please Enter Budget"),
    hotelbookingdetails: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true;
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Hotel."
        ),
      })
    ),
  });
  const getValidationSchema = () => {
    if (formValues.Type === "Self") {
      if (formValues?.TravellingType === "One Way Trip")
        return flightSelfOneWaySchema;
      else if (formValues?.TravellingType === "Multilocation Trip")
        return flightSelfMultiTripSchema;
    } else if (formValues.Type === "Group") {
      if (formValues?.TravellingType === "One Way Trip")
        return flightGroupOneWaySchema;
      else if (formValues?.TravellingType === "Multilocation Trip")
        return flightGroupMultiTripSchema;
    } else if (formValues.Type === "Vendor" || formValues.Type === "Guest") {
      if (formValues?.TravellingType === "One Way Trip")
        return flightVendorOneWaySchema;
      else if (formValues?.TravellingType === "Multilocation Trip")
        return flightVendorMultiTripSchema;
    } else {
      if (formValues?.TravellingType === "One Way Trip")
        return flightCandidateOneWaySchema;
      else if (formValues?.TravellingType === "Multilocation Trip")
        return flightCandidateMultiTripSchema;
    }
  };

  const onSubmit = (values) => {
    const payload = {
      id: values?.id,
    };
    if (values.Type === "Self" && values.TravellingType === "One Way Trip") {
      const TravelingDetailsObj = [
        {
          TravellingType: values.TravellingType,
          CheckInTime: values?.CheckInTime
            ? format(new Date(values?.CheckInTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(values?.CheckInTime), "hh:mm")
            : null,
          CheckOutTime: values?.CheckOutTime
            ? format(new Date(values?.CheckOutTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(values?.CheckOutTime), "hh:mm")
            : null,
          Location: values?.Location,
          HotelCategory: Number(values.HotelCategory),
          BookingNote: values?.BookingNote,
          PreferHotel: values?.PreferHotel,
          Budget: values?.Budget,
          HOTELBOOKINGEMPLOYEENEW: [
            {
              Adults: Number(values?.Adults),
              Children: Number(values?.Children),
            },
          ],
        },
      ];
      payload["HOTELBOOKINGDETAILSNEW"] = TravelingDetailsObj;
    }
    if (
      values.Type === "Self" &&
      values.TravellingType === "Multilocation Trip"
    ) {
      const TravelingDetailsObj = values?.hotelbookingdetails?.map(
        (item, ind) => ({
          TravellingType: values.TravellingType,
          Budget: ind === 0 ? values?.Budget : 0,
          CheckInTime: item?.CheckInTime
            ? format(new Date(item?.CheckInTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(item?.CheckInTime), "hh:mm")
            : null,
          CheckOutTime: item?.CheckOutTime
            ? format(new Date(item?.CheckOutTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(item?.CheckOutTime), "hh:mm")
            : null,
          Location: item?.Location,
          HotelCategory: Number(item?.HotelCategory),
          PreferHotel: item?.PreferHotel,
          BookingNote: item?.Bookingnote,
          HOTELBOOKINGEMPLOYEENEW: [
            {
              Adults: Number(item?.Adults),
              Children: Number(item?.Children),
            },
          ],
        })
      );
      payload["HOTELBOOKINGDETAILSNEW"] = TravelingDetailsObj;
    }
    if (values.Type === "Group" && values.TravellingType === "One Way Trip") {
      const TravelingDetailsObj = [
        {
          TravellingType: values.TravellingType,
          CheckInTime: values?.CheckInTime
            ? format(new Date(values?.CheckInTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(values?.CheckInTime), "hh:mm")
            : null,
          CheckOutTime: values?.CheckOutTime
            ? format(new Date(values?.CheckOutTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(values?.CheckOutTime), "hh:mm")
            : null,
          Location: values?.Location,
          HotelCategory: Number(values.HotelCategory),
          BookingNote: values?.BookingNote,
          PreferHotel: values?.PreferHotel,
          Budget: values?.Budget,
          HOTELBOOKINGEMPLOYEENEW: values?.groupItemsNotMulti?.map((items) => ({
            id: items?.id ?? 0,

            Name: employeeList?.filter(
              (item) => item?.value === items?.EmployeeId
            )[0]?.label,
            EmployeeId: items?.EmployeeId,
            PhoneNumber: items?.PhoneNumber?.toString(),
            EmailId: items?.EmailId,
            Adults: Number(items?.Adults),
            Children: Number(items?.Children),
          })),
        },
      ];
      payload["HOTELBOOKINGDETAILSNEW"] = TravelingDetailsObj;
    }
    if (
      values.Type === "Group" &&
      values.TravellingType === "Multilocation Trip"
    ) {
      const TravelingDetailsObj = values?.groupItems?.map((item, ind) => ({
        TravellingType: values.TravellingType,
        Budget: ind === 0 ? values?.Budget : 0,
        CheckInTime: item?.CheckInTime
          ? format(new Date(item?.CheckInTime), "yyyy-MM-dd") +
            "T" +
            format(new Date(item?.CheckInTime), "hh:mm")
          : null,
        CheckOutTime: item?.CheckOutTime
          ? format(new Date(item?.CheckOutTime), "yyyy-MM-dd") +
            "T" +
            format(new Date(item?.CheckOutTime), "hh:mm")
          : null,
        Location: item?.Location,
        PreferHotel: item.PreferHotel,
        BookingNote: item?.Bookingnote,
        HotelCategory: Number(item?.HotelCategory),
        HOTELBOOKINGEMPLOYEENEW: item?.EmployeeDetails?.map((items) => ({
          Name: employeeList?.filter(
            (item) => item?.value === items?.EmployeeId
          )[0]?.label,
          EmployeeId: items?.EmployeeId,
          PhoneNumber: items?.PhoneNumber?.toString(),
          EmailId: items?.EmailId,
          Adults: Number(items?.Adults),
          Children: Number(items?.Children),
        })),
      }));
      payload["HOTELBOOKINGDETAILSNEW"] = TravelingDetailsObj;
    }
    if (
      (values.Type === "Vendor" || values.Type === "Guest") &&
      values.TravellingType === "One Way Trip"
    ) {
      const TravelingDetailsObj = [
        {
          TravellingType: values.TravellingType,
          CheckInTime: values?.CheckInTime
            ? format(new Date(values?.CheckInTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(values?.CheckInTime), "hh:mm")
            : null,
          CheckOutTime: values?.CheckOutTime
            ? format(new Date(values?.CheckOutTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(values?.CheckOutTime), "hh:mm")
            : null,
          Location: values?.Location,
          HotelCategory: Number(values.HotelCategory),
          BookingNote: values?.BookingNote,
          PreferHotel: values?.PreferHotel,
          Budget: values?.Budget,
          HOTELBOOKINGEMPLOYEENEW: values?.groupItemsNotMulti?.map((items) => ({
            Name: items?.Name,
            PhoneNumber: items?.PhoneNumber?.toString(),
            EmailId: items?.EmailId,
            Adults: Number(items?.Adults),
            Children: Number(items?.Children),
          })),
        },
      ];
      payload["HOTELBOOKINGDETAILSNEW"] = TravelingDetailsObj;
    }
    if (
      (values.Type === "Vendor" || values.Type === "Guest") &&
      values.TravellingType === "Multilocation Trip"
    ) {
      const TravelingDetailsObj = values?.groupItems?.map((item, ind) => ({
        TravellingType: values.TravellingType,
        Budget: ind === 0 ? values?.Budget : 0,
        CheckInTime: item?.CheckInTime
          ? format(new Date(item?.CheckInTime), "yyyy-MM-dd") +
            "T" +
            format(new Date(item?.CheckInTime), "hh:mm")
          : null,
        CheckOutTime: item?.CheckOutTime
          ? format(new Date(item?.CheckOutTime), "yyyy-MM-dd") +
            "T" +
            format(new Date(item?.CheckOutTime), "hh:mm")
          : null,
        Location: item?.Location,
        PreferHotel: item.PreferHotel,
        BookingNote: item?.Bookingnote,
        HotelCategory: Number(item?.HotelCategory),
        HOTELBOOKINGEMPLOYEENEW: item?.EmployeeDetails?.map((items) => ({
          Name: items?.Name,
          PhoneNumber: items?.PhoneNumber?.toString(),
          EmailId: items?.EmailId,
          Adults: Number(items?.Adults),
          Children: Number(items?.Children),
        })),
      }));
      payload["HOTELBOOKINGDETAILSNEW"] = TravelingDetailsObj;
    }
    if (
      values.Type === "Candidate" &&
      values.TravellingType === "One Way Trip"
    ) {
      const TravelingDetailsObj = [
        {
          TravellingType: values.TravellingType,
          CheckInTime: values?.CheckInTime
            ? format(new Date(values?.CheckInTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(values?.CheckInTime), "hh:mm")
            : null,
          CheckOutTime: values?.CheckOutTime
            ? format(new Date(values?.CheckOutTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(values?.CheckOutTime), "hh:mm")
            : null,
          Location: values?.Location,
          HotelCategory: Number(values.HotelCategory),
          BookingNote: values?.BookingNote,
          PreferHotel: values?.PreferHotel,
          Budget: values?.Budget,
          HOTELBOOKINGEMPLOYEENEW: [
            {
              Adults: Number(values?.Adults),
              Children: Number(values?.Children),
            },
          ],
        },
      ];
      payload["HOTELBOOKINGDETAILSNEW"] = TravelingDetailsObj;
    }
    if (
      values.Type === "Candidate" &&
      values.TravellingType === "Multilocation Trip"
    ) {
      const TravelingDetailsObj = values?.hotelbookingdetails?.map(
        (item, ind) => ({
          TravellingType: values.TravellingType,
          Budget: ind === 0 ? values?.Budget : 0,
          CheckInTime: item?.CheckInTime
            ? format(new Date(item?.CheckInTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(item?.CheckInTime), "hh:mm")
            : null,
          CheckOutTime: item?.CheckOutTime
            ? format(new Date(item?.CheckOutTime), "yyyy-MM-dd") +
              "T" +
              format(new Date(item?.CheckOutTime), "hh:mm")
            : null,
          Location: item?.Location,
          HotelCategory: Number(item?.HotelCategory),
          PreferHotel: item?.PreferHotel,
          BookingNote: item?.Bookingnote,
          HOTELBOOKINGEMPLOYEENEW: [
            {
              Adults: Number(item?.Adults),
              Children: Number(item?.Children),
            },
          ],
        })
      );
      payload["HOTELBOOKINGDETAILSNEW"] = TravelingDetailsObj;
    }
    create(payload);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={getValidationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            chooseFormValues && chooseFormValues(formik.values);
            return (
              <Form
                style={{
                  padding: " 15px 15px 0 15px ",
                  position: "relative",
                  height: "100%",
                  flexGrow: 1,
                }}
              >
                {(detailsData?._request?.type === "Self" ||
                  detailsData?._request?.type === "Candidate") &&
                  detailsData?.hoteldetail[0]?.travellingType &&
                  detailsData?.hoteldetail[0]?.travellingType !==
                    "Multilocation Trip" && (
                    <>
                      <div className="formcustom threeColumn">
                        <div>
                          <LabelCustom
                            labelName={"Hotel Category"}
                            mandatory={true}
                          />
                          <SelectForm
                            name="HotelCategory"
                            options={hotelCategoryOptions}
                          />
                        </div>
                        {detailsData?._request?.type === "Candidate" && (
                          <div>
                            <LabelCustom
                              labelName={"Candidate"}
                              mandatory={true}
                            />
                            <SelectForm
                              name="CandidateId"
                              options={candidateList}
                            />
                          </div>
                        )}
                        <div>
                          <LabelCustom
                            labelName={"Check-In Date and Time"}
                            mandatory={true}
                          />
                          <DatePickerFormiks
                            name={"CheckInTime"}
                            minDate={new Date()}
                            showTimeSelect
                            maxDate={formValues && formValues?.CheckOutTime}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"Check-Out Date and Time"}
                            mandatory={true}
                          />
                          <DatePickerFormiks
                            name={"CheckOutTime"}
                            minDate={
                              formValues ? formValues?.CheckInTime : new Date()
                            }
                            showTimeSelect
                          />
                        </div>

                        <div>
                          <LabelCustom
                            labelName={"Location"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            maxLength={50}
                            name="Location"
                            type={"text"}
                            placeholder={"Please Enter Location"}
                          />
                        </div>

                        <div>
                          <LabelCustom
                            labelName={"Preferred Hotel"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            maxLength={150}
                            name="PreferHotel"
                            type={"text"}
                            placeholder={"Please Enter Preferred Hotel"}
                          />
                        </div>
                        <div>
                          <LabelCustom labelName={"Adults"} />
                          <InputCustomFormik
                            name="Adults"
                            type="number"
                            placeholder={" Enter "}
                          />
                        </div>
                        <div>
                          <LabelCustom labelName={"Children"} />
                          <InputCustomFormik
                            name="Children"
                            type="number"
                            placeholder={" Enter "}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"Budget (INR)"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            type={"number"}
                            name="Budget"
                            placeholder={"Please enter budget"}
                          />
                        </div>
                        <div>
                          <LabelCustom labelName={"Booking Note"} />
                          <TextAreaFormik
                            name="BookingNote"
                            placeholder={"Please Enter Booking Note"}
                            maxLength={500}
                          />
                        </div>
                      </div>
                    </>
                  )}
                {(detailsData?._request?.type === "Self" ||
                  detailsData?._request?.type === "Candidate") &&
                  detailsData?.hoteldetail[0]?.travellingType &&
                  detailsData?.hoteldetail[0]?.travellingType ===
                    "Multilocation Trip" && (
                    <>
                      <div className="formcustom threeColumn">
                        <div style={{ width: "245px" }}>
                          <LabelCustom
                            labelName={"Budget (INR)"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            type="number"
                            name="Budget"
                            placeholder={"Please enter budget"}
                          />
                        </div>
                        {detailsData?._request?.type === "Candidate" && (
                          <div>
                            <LabelCustom
                              labelName={"Candidate"}
                              mandatory={true}
                            />
                            <SelectForm
                              name="CandidateId"
                              options={candidateList}
                            />
                          </div>
                        )}
                      </div>
                      <div className="repeatedSectionContainer">
                        <FieldArray name="hotelbookingdetails">
                          {(fieldArrayProps) => {
                            const { push, remove, form } = fieldArrayProps;
                            const { values, errors } = form;
                            const { hotelbookingdetails } = values;
                            return (
                              <StyledTableCont>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom labelName={"S. No."} />
                                      </StyledTableCell>
                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"Hotel Booking Details"}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={" Add/Remove"}
                                        />
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  {hotelbookingdetails?.map(
                                    (Requestlist, index) => (
                                      <TableBody key={index}>
                                        {/* 1st column */}
                                        <StyledTableCell align="center">
                                          {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{
                                            maxWidth: "920px",
                                            overflowX: "auto",
                                          }}
                                        >
                                          <div
                                            style={{
                                              overflowX: "auto",
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexShrink: "1",
                                              }}
                                            >
                                              <div style={{ width: "245px" }}>
                                                <LabelCustom
                                                  labelName={"Hotel Category"}
                                                  mandatory={true}
                                                />
                                                <SelectForm
                                                  name={`hotelbookingdetails[${index}].HotelCategory`}
                                                  options={hotelCategoryOptions}
                                                />
                                              </div>
                                              <div style={{ width: "245px" }}>
                                                <LabelCustom
                                                  labelName={
                                                    "Check-In Date and Time"
                                                  }
                                                  mandatory={true}
                                                />
                                                <DatePickerFormiks
                                                  name={`hotelbookingdetails[${index}].CheckInTime`}
                                                  minDate={new Date()}
                                                  showTimeSelect
                                                  maxDate={
                                                    formValues?.hotelbookingdetails &&
                                                    formValues
                                                      ?.hotelbookingdetails[
                                                      index
                                                    ]?.CheckOutTime
                                                  }
                                                />
                                              </div>
                                              <div style={{ width: "245px" }}>
                                                <LabelCustom
                                                  labelName={
                                                    "Check-Out Date and Time"
                                                  }
                                                  mandatory={true}
                                                />
                                                <DatePickerFormiks
                                                  name={`hotelbookingdetails[${index}].CheckOutTime`}
                                                  minDate={
                                                    formValues?.hotelbookingdetails
                                                      ? formValues
                                                          ?.hotelbookingdetails[
                                                          index
                                                        ]?.CheckInTime
                                                      : new Date()
                                                  }
                                                  showTimeSelect
                                                />
                                              </div>
                                              <div style={{ width: "245px" }}>
                                                <LabelCustom
                                                  labelName={"Location"}
                                                  mandatory={true}
                                                />
                                                <InputCustomFormik
                                                  maxLength={50}
                                                  name={`hotelbookingdetails[${index}].Location`}
                                                  type={"text"}
                                                  placeholder={
                                                    "Please Enter Location"
                                                  }
                                                />
                                              </div>
                                              <div style={{ width: "245px" }}>
                                                <LabelCustom
                                                  labelName={"Preferred Hotel"}
                                                  mandatory={true}
                                                />
                                                <InputCustomFormik
                                                  maxLength={150}
                                                  name={`hotelbookingdetails[${index}].PreferHotel`}
                                                  type={"text"}
                                                  placeholder={
                                                    "Please Enter Preferred Hotel"
                                                  }
                                                />
                                              </div>
                                              <div style={{ width: "245px" }}>
                                                <LabelCustom
                                                  labelName={"Adults"}
                                                />
                                                <InputCustomFormik
                                                  name={`hotelbookingdetails[${index}].Adults`}
                                                  type={"number"}
                                                  placeholder={" Enter "}
                                                />
                                              </div>
                                              <div style={{ width: "245px" }}>
                                                <LabelCustom
                                                  labelName={"Children"}
                                                />
                                                <InputCustomFormik
                                                  name={`hotelbookingdetails[${index}].Children`}
                                                  type={"number"}
                                                  placeholder={" Enter "}
                                                />
                                              </div>

                                              <div style={{ width: "245px" }}>
                                                <LabelCustom
                                                  labelName={"Booking Note"}
                                                />
                                                <TextAreaFormik
                                                  name={`hotelbookingdetails[${index}].Bookingnote`}
                                                  placeholder={" Enter Note"}
                                                  maxLength={500}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </StyledTableCell>
                                        {/* 2nd column */}

                                        {/* 8th column */}
                                        <StyledTableCell
                                          sx={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="remove-btn"
                                            onClick={() =>
                                              hotelbookingdetails.length > 1
                                                ? remove(index)
                                                : dispatch(
                                                    showToast({
                                                      text: "Atleast One Item is Required ",
                                                      severity: "error",
                                                    })
                                                  )
                                            }
                                          >
                                            -
                                          </button>
                                          <button
                                            type="button"
                                            className="add-btn"
                                            onClick={() =>
                                              push({
                                                HotelCategory: null,
                                                CheckInTime: null,
                                                CheckOutTime: null,
                                                Location: "",
                                                PreferHotel: "",
                                                Adults: "",
                                                Children: "",
                                                Bookingnote: "",
                                              })
                                            }
                                          >
                                            +
                                          </button>
                                        </StyledTableCell>
                                      </TableBody>
                                    )
                                  )}
                                </Table>
                              </StyledTableCont>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </>
                  )}
                {(detailsData?._request?.type === "Group" ||
                  detailsData?._request?.type === "Vendor" ||
                  detailsData?._request?.type === "Guest") &&
                  detailsData?.hoteldetail[0]?.travellingType &&
                  detailsData?.hoteldetail[0]?.travellingType !==
                    "Multilocation Trip" && (
                    <>
                      <div className="formcustom threeColumn">
                        <div>
                          <LabelCustom
                            labelName={"Hotel Category"}
                            mandatory={true}
                          />
                          <SelectForm
                            name="HotelCategory"
                            options={hotelCategoryOptions}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"Budget (INR)"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            type="number"
                            name="Budget"
                            placeholder={"Please enter budget"}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"Check-In Date and Time"}
                            mandatory={true}
                          />
                          <DatePickerFormiks
                            name={"CheckInTime"}
                            minDate={new Date()}
                            showTimeSelect
                            maxDate={formValues && formValues?.CheckOutTime}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"Check-Out Date and Time"}
                            mandatory={true}
                          />
                          <DatePickerFormiks
                            name={"CheckOutTime"}
                            minDate={
                              formValues ? formValues?.CheckInTime : new Date()
                            }
                            showTimeSelect
                          />
                        </div>

                        <div>
                          <LabelCustom
                            labelName={"Location"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            maxLength={50}
                            name="Location"
                            type={"text"}
                            placeholder={"Please Enter Location"}
                          />
                        </div>

                        <div>
                          <LabelCustom
                            labelName={"Preferred Hotel"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            maxLength={150}
                            name="PreferHotel"
                            type={"text"}
                            placeholder={"Please Enter Preferred Hotel"}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"Budget (INR)"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            type={"number"}
                            name="Budget"
                            placeholder={"Please enter budget"}
                          />
                        </div>
                        <div>
                          <LabelCustom labelName={"Booking Note"} />
                          <TextAreaFormik
                            name="BookingNote"
                            placeholder={"Please Enter Booking Note"}
                            maxLength={500}
                          />
                        </div>
                      </div>

                      <div>
                        <label className="repeatedHeading">
                          Please specify the employee's details
                        </label>
                        <div style={{ width: "100%", overflowX: "auto" }}>
                          <FieldArray name="groupItemsNotMulti">
                            {(fieldArrayProps) => {
                              const { push, remove, form } = fieldArrayProps;
                              const { values } = form;
                              const { groupItemsNotMulti } = values;
                              return (
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom labelName={"S. No"} />
                                      </StyledTableCell>
                                      {detailsData?._request?.type ===
                                        "Group" && (
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Employee Name"}
                                            mandatory={true}
                                            style={{
                                              margin: "0px 0px 0px 5px",
                                            }}
                                          />
                                        </StyledTableCell>
                                      )}
                                      {detailsData?._request?.type ===
                                        "Vendor" && (
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Vendor Name"}
                                            mandatory={true}
                                            style={{
                                              margin: "0px 0px 0px 5px",
                                            }}
                                          />
                                        </StyledTableCell>
                                      )}
                                      {detailsData?._request?.type ===
                                        "Guest" && (
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Guest Name"}
                                            mandatory={true}
                                            style={{
                                              margin: "0px 0px 0px 5px",
                                            }}
                                          />
                                        </StyledTableCell>
                                      )}
                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"Phone No."}
                                          mandatory={true}
                                          style={{ margin: "0px 0px 0px 5px" }}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          mandatory={true}
                                          labelName={"Email"}
                                          style={{ margin: "0px 0px 0px 5px" }}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"Adults"}
                                          style={{ margin: "0px 0px 0px 5px" }}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"Children"}
                                          style={{ margin: "0px 0px 0px 5px" }}
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom labelName={"Add/Remove"} />
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  {groupItemsNotMulti?.map(
                                    (groupItemss, index) => (
                                      <TableBody key={index}>
                                        {/* 1st column */}
                                        <StyledTableCell align="center">
                                          {index + 1}
                                        </StyledTableCell>
                                        {/* 2nd column */}
                                        {detailsData?._request?.type ===
                                          "Group" && (
                                          <StyledTableCell>
                                            <SelectForm
                                              name={`groupItemsNotMulti[${index}].EmployeeId`}
                                              options={employeeList}
                                            />
                                          </StyledTableCell>
                                        )}
                                        {(detailsData?._request?.type ===
                                          "Vendor" ||
                                          detailsData?._request?.type ===
                                            "Guest") && (
                                          <StyledTableCell>
                                            <InputCustomFormik
                                              name={`groupItemsNotMulti[${index}].Name`}
                                              placeholder={" Enter Name"}
                                            />
                                          </StyledTableCell>
                                        )}

                                        {/* 3rd column */}
                                        <StyledTableCell>
                                          <InputCustomFormik
                                            type="number"
                                            step="0.01"
                                            name={`groupItemsNotMulti[${index}].PhoneNumber`}
                                            placeholder={" Enter Phone No."}
                                          />
                                        </StyledTableCell>
                                        {/* 4th column */}
                                        <StyledTableCell>
                                          <InputCustomFormik
                                            maxLength={320}
                                            type="text"
                                            name={`groupItemsNotMulti[${index}].EmailId`}
                                            placeholder={"Enter the Email"}
                                          />
                                        </StyledTableCell>
                                        {/* 5th column */}
                                        <StyledTableCell>
                                          <InputCustomFormik
                                            type="number"
                                            step="0.01"
                                            name={`groupItemsNotMulti[${index}].Adults`}
                                            placeholder={"Enter the Age"}
                                          />
                                        </StyledTableCell>
                                        {/* 6th column */}
                                        <StyledTableCell>
                                          <InputCustomFormik
                                            type="number"
                                            step="0.01"
                                            name={`groupItemsNotMulti[${index}].Children`}
                                            placeholder={"Enter the Age"}
                                          />
                                        </StyledTableCell>

                                        <StyledTableCell
                                          sx={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="remove-btn"
                                            onClick={() =>
                                              groupItemsNotMulti.length > 1
                                                ? remove(index)
                                                : dispatch(
                                                    showToast({
                                                      text: "Atleast One Item is Required ",
                                                      severity: "error",
                                                    })
                                                  )
                                            }
                                          >
                                            -
                                          </button>
                                          <button
                                            type="button"
                                            className="add-btn"
                                            onClick={() => {
                                              if (
                                                detailsData?._request?.type ===
                                                  "Vendor" ||
                                                detailsData?._request?.type ===
                                                  "Guest"
                                              ) {
                                                push({
                                                  Name: "",
                                                  PhoneNumber: "",
                                                  EmailId: "",
                                                  Adults: "",
                                                  Children: "",
                                                });
                                              } else {
                                                push({
                                                  EmployeeId: null,
                                                  PhoneNumber: "",
                                                  EmailId: "",
                                                  Adults: "",
                                                  Children: "",
                                                });
                                              }
                                            }}
                                          >
                                            +
                                          </button>
                                        </StyledTableCell>
                                      </TableBody>
                                    )
                                  )}
                                </Table>
                              );
                            }}
                          </FieldArray>
                        </div>
                      </div>
                    </>
                  )}
                {(detailsData?._request?.type === "Group" ||
                  detailsData?._request?.type === "Vendor" ||
                  detailsData?._request?.type === "Guest") &&
                  detailsData?.hoteldetail[0]?.travellingType &&
                  detailsData?.hoteldetail[0]?.travellingType ===
                    "Multilocation Trip" && (
                    <>
                      <div>
                        <div style={{ width: "245px" }}>
                          <LabelCustom
                            labelName={"Budget (INR)"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            name={`Budget`}
                            type="number"
                            placeholder={"Please enter budget"}
                          />
                        </div>
                      </div>
                      <div>
                        <FieldArray name="groupItems">
                          {(fieldArrayProps) => {
                            const { remove, insert, form } = fieldArrayProps;
                            const { values } = form;
                            const { groupItems } = values;
                            return (
                              <Table style={{ width: "100%" }}>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom labelName={"S. No."} />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom labelName={"Journey"} />
                                    </StyledTableCell>

                                    <StyledTableCell
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom labelName={"Add/Remove"} />
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                {groupItems?.map((Questions, index) => (
                                  <TableBody key={index}>
                                    <StyledTableCell
                                      sx={{ width: "96px" }}
                                      align="center"
                                    >
                                      {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        maxWidth: "920px",
                                        overflowX: "auto",
                                      }}
                                    >
                                      <div
                                        style={{
                                          overflowX: "auto",
                                          display: "flex",
                                          padding: "20px 0px 0px 0px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexShrink: "1",
                                          }}
                                        >
                                          <div style={{ width: "245px" }}>
                                            <LabelCustom
                                              labelName={"Hotel Category"}
                                              mandatory={true}
                                            />
                                            <SelectForm
                                              name={`groupItems[${index}].HotelCategory`}
                                              options={hotelCategoryOptions}
                                            />
                                          </div>
                                          <div style={{ width: "245px" }}>
                                            <LabelCustom
                                              labelName={
                                                "Check-In Date and Time"
                                              }
                                              mandatory={true}
                                            />
                                            <DatePickerFormiks
                                              name={`groupItems[${index}].CheckInTime`}
                                              minDate={new Date()}
                                              showTimeSelect
                                              maxDate={
                                                formValues?.groupItems
                                                  ? formValues?.groupItems[
                                                      index
                                                    ]?.CheckOutTime
                                                  : null
                                              }
                                            />
                                          </div>
                                          <div style={{ width: "245px" }}>
                                            <LabelCustom
                                              labelName={
                                                "Check-Out Date and Time"
                                              }
                                              mandatory={true}
                                            />
                                            <DatePickerFormiks
                                              name={`groupItems[${index}].CheckOutTime`}
                                              minDate={
                                                formValues?.groupItems
                                                  ? formValues?.groupItems[
                                                      index
                                                    ]?.CheckInTime
                                                  : new Date()
                                              }
                                              showTimeSelect
                                            />
                                          </div>

                                          <div style={{ width: "245px" }}>
                                            <LabelCustom
                                              labelName={"Location"}
                                              mandatory={true}
                                            />
                                            <InputCustomFormik
                                              maxLength={50}
                                              name={`groupItems[${index}].Location`}
                                              type={"text"}
                                              placeholder={
                                                "Please Enter Location"
                                              }
                                            />
                                          </div>
                                          <div style={{ width: "245px" }}>
                                            <LabelCustom
                                              labelName={"Preferred Hotel"}
                                              mandatory={true}
                                            />
                                            <InputCustomFormik
                                              maxLength={150}
                                              name={`groupItems[${index}].PreferHotel`}
                                              type={"text"}
                                              placeholder={
                                                "Please Enter Preferred Hotel"
                                              }
                                            />
                                          </div>
                                          <div style={{ width: "245px" }}>
                                            <LabelCustom
                                              labelName={"Booking Note"}
                                            />
                                            <TextAreaFormik
                                              name={`groupItems[${index}].Bookingnote`}
                                              placeholder={" Enter Note"}
                                              maxLength={500}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          maxHeight: "300px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        {Questions?.EmployeeDetails?.map(
                                          (item, indexW) => (
                                            <div
                                              style={{
                                                overflow: "auto",
                                                width: "100%",
                                                height: "197px",
                                              }}
                                            >
                                              <div
                                                className="formcustom sixColumn"
                                                style={{
                                                  minWidth: "1152px",
                                                  padding: "18px 0px",
                                                  position: "relative",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: "67px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <LabelCustom
                                                    labelName={"S. No."}
                                                  />

                                                  <div
                                                    style={{
                                                      padding: "21px 8px",
                                                    }}
                                                  >
                                                    {indexW + 1}
                                                  </div>
                                                </div>
                                                <div style={{ width: "264px" }}>
                                                  {detailsData?._request
                                                    ?.type === "Group" && (
                                                    <LabelCustom
                                                      labelName={
                                                        "Employee Name"
                                                      }
                                                      mandatory={true}
                                                      style={{
                                                        margin:
                                                          "0px 0px 0px 5px",
                                                      }}
                                                    />
                                                  )}
                                                  {detailsData?._request
                                                    ?.type === "Vendor" && (
                                                    <LabelCustom
                                                      labelName={"Vendor Name"}
                                                      mandatory={true}
                                                      style={{
                                                        margin:
                                                          "0px 0px 0px 5px",
                                                      }}
                                                    />
                                                  )}
                                                  {detailsData?._request
                                                    ?.type === "Guest" && (
                                                    <LabelCustom
                                                      labelName={"Guest Name"}
                                                      mandatory={true}
                                                      style={{
                                                        margin:
                                                          "0px 0px 0px 5px",
                                                      }}
                                                    />
                                                  )}
                                                  {detailsData?._request
                                                    ?.type === "Group" && (
                                                    <SelectForm
                                                      name={`groupItems[${index}].EmployeeDetails[${indexW}].EmployeeId`}
                                                      options={employeeList}
                                                    />
                                                  )}
                                                  {(detailsData?._request
                                                    ?.type === "Vendor" ||
                                                    detailsData?._request
                                                      ?.type === "Guest") && (
                                                    <InputCustomFormik
                                                      name={`groupItems[${index}].EmployeeDetails[${indexW}].Name`}
                                                      placeholder={
                                                        " Enter Name"
                                                      }
                                                    />
                                                  )}
                                                </div>
                                                <div style={{ width: "264px" }}>
                                                  <LabelCustom
                                                    labelName={"Phone No."}
                                                    mandatory={true}
                                                  />

                                                  <InputCustomFormik
                                                    maxLength={10}
                                                    type="number"
                                                    step="0.01"
                                                    name={`groupItems[${index}].EmployeeDetails[${indexW}].PhoneNumber`}
                                                    placeholder={
                                                      " Enter Phone No."
                                                    }
                                                  />
                                                </div>
                                                <div style={{ width: "264px" }}>
                                                  <LabelCustom
                                                    mandatory={true}
                                                    labelName={"Email"}
                                                  />
                                                  <InputCustomFormik
                                                    maxLength={320}
                                                    type="text"
                                                    name={`groupItems[${index}].EmployeeDetails[${indexW}].EmailId`}
                                                    placeholder={
                                                      "Enter the Email"
                                                    }
                                                  />
                                                </div>
                                                <div style={{ width: "264px" }}>
                                                  <LabelCustom
                                                    labelName={"Adults"}
                                                    // mandatory={true}
                                                  />
                                                  <InputCustomFormik
                                                    type="number"
                                                    step="0.01"
                                                    name={`groupItems[${index}].EmployeeDetails[${indexW}].Adults`}
                                                    placeholder={"Enter "}
                                                  />
                                                </div>
                                                <div style={{ width: "264px" }}>
                                                  <LabelCustom
                                                    labelName={"Children"}
                                                    // mandatory={true}
                                                  />
                                                  <InputCustomFormik
                                                    type="number"
                                                    step="0.01"
                                                    name={`groupItems[${index}].EmployeeDetails[${indexW}].Children`}
                                                    placeholder={"Enter"}
                                                  />
                                                </div>
                                              </div>
                                              {indexW ===
                                              Questions?.EmployeeDetails
                                                ?.length -
                                                1 ? (
                                                <div
                                                  className="surveyAddBtnContainer"
                                                  style={{
                                                    position: "sticky",
                                                    left: "86%",

                                                    bottom: "21px",
                                                    width: "131px",
                                                    // position: "absolute",
                                                    // right: "12px",
                                                    // bottom: "21px",
                                                  }}
                                                  onClick={() =>
                                                    pushEmployee(index)
                                                  }
                                                >
                                                  <button
                                                    type="button"
                                                    className="add-survey-btn"
                                                  >
                                                    +
                                                  </button>{" "}
                                                  Add Employee
                                                </div>
                                              ) : (
                                                <div
                                                  className="surveyAddBtnContainer"
                                                  style={{
                                                    position: "sticky",
                                                    left: "83.5%",
                                                    bottom: "21px",
                                                    width: "140px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "red",
                                                  }}
                                                  onClick={() =>
                                                    popAnswer(index, indexW)
                                                  }
                                                >
                                                  <img
                                                    // onClick={() => {
                                                    //   popAnswer(answer, answerIndex, index);
                                                    // }}
                                                    style={{
                                                      marginRight: "5px",
                                                      cursor: "pointer",
                                                    }}
                                                    src={
                                                      SurveyAnswersDeleteIcon
                                                    }
                                                    alt="SurveyAnswersDeleteIcon"
                                                  />
                                                  Remove Employee
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        width: "100px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                          groupItems.length > 1
                                            ? remove(index)
                                            : dispatch(
                                                showToast({
                                                  text: "Atleast One Item is Required ",
                                                  severity: "error",
                                                })
                                              )
                                        }
                                      >
                                        -
                                      </button>
                                      <button
                                        type="button"
                                        className="add-btn"
                                        onClick={() => {
                                          if (
                                            detailsData?._request?.type ===
                                              "Vendor" ||
                                            detailsData?._request?.type ===
                                              "Guest"
                                          ) {
                                            insert(index + 1, {
                                              HotelCategory: null,
                                              CheckInTime: null,
                                              CheckOutTime: null,
                                              Location: "",
                                              PreferHotel: "",
                                              Bookingnote: "",
                                              EmployeeDetails: [
                                                {
                                                  Name: "",
                                                  Adults: "",
                                                  Children: "",
                                                  PhoneNumber: "",
                                                  EmailId: "",
                                                },
                                              ],
                                            });
                                          } else {
                                            insert(index + 1, {
                                              HotelCategory: null,
                                              CheckInTime: null,
                                              CheckOutTime: null,
                                              Location: "",
                                              PreferHotel: "",
                                              Bookingnote: "",
                                              EmployeeDetails: [
                                                {
                                                  EmployeeId: null,
                                                  Adults: "",
                                                  Children: "",
                                                  PhoneNumber: "",
                                                  EmailId: "",
                                                },
                                              ],
                                            });
                                          }
                                        }}
                                      >
                                        +
                                      </button>
                                    </StyledTableCell>
                                  </TableBody>
                                ))}
                              </Table>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </>
                  )}

                <div className="createFootCust">
                  <div>
                    <div
                      onClick={togglePopup}
                      className={`button secondaryButton`}
                    >
                      Cancel
                    </div>
                  </div>
                  <div>
                    <button className="button primaryButton" type="submit">
                      Create
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default HotelBookingAddJourney;
