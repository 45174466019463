import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import { DetailsStep } from "../../../../../Components/FormComponent/DetailsStep";

const DetailsPagemaintenance = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const returnPage = () => {
     navigate(-1);
  };
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);

  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `facilityrequests/facilitymaintananceRequestDetails?ID=${selectedId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page Maintenance`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getDetails();
    return () => {};
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Maintenance  Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="moduleMasterDetailsPageBody">
          {/* <div className="moduleMasterDetailsBox"> */}
          <DetailsStep
            detailTitle={"Reference Number "}
            Details={data?.request?.requestNumber}
          />
          <DetailsStep
            detailTitle={"Facility ID"}
            Details={data?.request?.facilityId}
          />
          <DetailsStep
            detailTitle={"Employee Name"}
            Details={data?.request?.requestedBy?.name}
          />
          <DetailsStep
            detailTitle={"Type"}
            Details={data?.request?.type}
          />
          <DetailsStep
            detailTitle={"Status"}
            Details={
              <button
                className="button primary"
                style={{
                  backgroundColor: !data?.summary?.statusColor
                    ? "var(--secondary)"
                    : data?.summary?.statusColor,
                  color: "white",
                  cursor: "default",
                  margin: "4px",
                }}
              >
                {data?.request?.status}
              </button>
            }
          />
          <DetailsStep
            detailTitle={"Created On"}
            Details={data?.request?.createdOn?.replace("T", " ")}
          />
          <DetailsStep
            detailTitle={"Last Updated On"}
            Details={data?.request?.lastUpdatedOn?.replace("T", " ")}
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export { DetailsPagemaintenance };
