/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../Features";
import {
  postRequest,
} from "../../../Services/axios";
import { format } from "date-fns";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../Utilities/getStatusColor";
export const ServiceGoalSettings = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [originalData, setOriginalData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState([]);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);

  const [reqData, setReqData] = useState({
    cycleStartDate: null,
    cycleEndDate: null,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol - My Goals`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getActions = (actionList) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
  };
  const getFormDetails = (id) => {
    // navigate(`/hrms/transfer/deputation/details/${id}`);
  };
  const getMoreActions = (actionList) => {
    let returnActionArray = [];
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getAllListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(`Goals/AllGoalsList`, reqData);
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              actions: item?.actions,
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({
              createdBy,
              department,
              designation,
              email,
              employeeId,
              toLocation,
              toLocationName,
              ...rest
            }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              name,
              cycleStartDate,
              cycleEndDate,
              actions,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              name,
              cycleStartDate,
              cycleEndDate,
              actions,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("goalsettings")) getAllListData();
  }, [location.pathname, reqData]);
  if (loading) return <Loader />;
  else
    return (
      <div className="boxViewContainer ">
        <div className="homeCardsHolderContainer">
          <div className="homeDashboardCardHolder" key={1}>
            {tbodyData &&
              tbodyData?.map((item, index) => {
                return (
                  <div className="dashboardCard internalJobCard">
                    <div className="cardContainer cursor">
                      <div className="headingContainer">
                        <h3
                          style={{
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.name}
                        </h3>
                      </div>
                      <div
                        className="jobDetailContainerHolder"
                        style={{ padding: "7px 0px 23px 11px" }}
                      >
                        <div className="jobDetailContainer">
                          <div className="verticalLine"></div>
                          <label className="jobDesignation">
                            Cycle Start Date
                          </label>
                          <label>
                            {" "}
                            {item?.cycleEndDate &&
                              format(
                                new Date(item?.cycleStartDate),
                                "dd/MM/yyyy "
                              )}
                          </label>
                        </div>
                        <div className="jobDetailContainer">
                          <div className="verticalLine"></div>
                          <label className="jobDesignation">
                            Cycle End Date
                          </label>
                          <label>
                            {" "}
                            {item?.cycleEndDate &&
                              format(
                                new Date(item?.cycleEndDate),
                                "dd/MM/yyyy "
                              )}
                          </label>
                        </div>
                      </div>
                      <div
                        className="applyJobButtonContainer "
                        style={{ flexWrap: "wrap", amrginLeft: "12px" }}
                      >
                        {item?.actions?.map((itemData, index) => {
                          return (
                            (itemData?.type === "View My Goals" ||
                              itemData?.type === "Update Your Goals" ||
                              itemData?.type === "Set Your Goals") && (
                              <button
                                style={{
                                  background:
                                    itemData?.type === "Update Your Goals"
                                      ? "#F7941D"
                                      : itemData?.type === "View My Goals"
                                      ? "white"
                                      : "var(--primary)",
                                  color:
                                    itemData?.type === "View My Goals"
                                      ? "var(--primary)"
                                      : "white",
                                      boxShadow:'none',
                                }}
                                onClick={() =>
                                  itemData?.type === "View My Goals"
                                    ? navigate(
                                        `/pms/goals/mygoals/details/${itemData?.id}`
                                      )
                                    : navigate(
                                        `/pms/goals/mygoals/create/${itemData?.id}`,
                                        {
                                          state: { item },
                                        }
                                      )
                                }
                                className="button primaryButton"
                              >
                                {itemData?.type}
                              </button>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
};
