import React, { useEffect, useState } from "react";
import GroupIcon from "../../Assets/GroupIcon.svg";
import signalRService from "../../Services/signalRService";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import ChatSelectedCheck from "../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../Assets/ChatUnselectedCheck.svg";
const ForwardPopup = ({
  LoggedInUser,
  contactsList,
  chatList,
  setForwardData,
  forwardData,
  setViewAll,
  viewAll,
  handleClose,
  forwardMessageAction
}) => {
  // const newForwardArray = [...chatList, ...contactsList];

  const newForwardArray = [...chatList];
  const [searchContacts, setSearchContacts] = useState();
  const [selected, setSelected] = useState(true);
  const dispatch = useDispatch();
  function showStatus(onlineStatus) {
    if (onlineStatus === 1) {
      return "greenDot";
    } else if (onlineStatus === 2) {
      return "redDot";
    } else if (onlineStatus === 3) {
      return "yellowDot";
    } else if (onlineStatus === 4) {
      return "offlineDot";
    } else {
      return "offlineDot";
    }
  }
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", height: "600px" }}
      >
        <div className="chatListSearchContainer">
          <input
            type="text"
            className="chatListSearch"
            placeholder="Search"
            value={searchContacts}
            onChange={(e) => {
              setSearchContacts(e?.target?.value);
            }}
          />
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0" />
            <line
              x1="8.70711"
              y1="9"
              x2="12"
              y2="12.2929"
              stroke="#1296B0"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div
          style={{
            borderTop: "1px solid #F1F2F2",
          }}
        ></div>
        <div style={{
          height: "100%", display: "flex", flexDirection: "column",
          backgroundColor: "#D8F0F2",
        }}>
          <div style={{ overflowY: "auto", height: "75%", backgroundColor: "white" }}>
            <div style={{ paddingBottom: 50 }}>
              {newForwardArray.map((contact, index) => (
                <div
                  key={index}
                  className={`chatForwardListHover chatListContentContainer ${contact?.participatingContacts?.filter(
                    (item) => item?.myIBID !== LoggedInUser?.id
                  )[0]?.onlineStatus && "chatListContentContainerActive"
                    }  `}
                  style={
                    (contact.participateMembers && forwardData.conversationId.includes(contact._id)) || forwardData?.userId?.includes(contact?._id)
                      ? { backgroundColor: "#E9F3F4" }
                      : {}
                  }
                >
                  <div className="chatListLeftcontent">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0px 7px 0px 18px",
                      }}
                    >
                      {
                        (contact.participateMembers && forwardData.conversationId.includes(contact._id)) || (forwardData?.userId?.includes(contact?._id) && !contact.participateMembers) ? (
                          <div onClick={(e) => {
                            let a = forwardData;
                            if (contact.participateMembers) {
                              let b = a.conversationId.filter((item) => item != contact._id)
                              a.conversationId = [...b];
                            } else {
                              let b = a.userId.filter((item) => item != contact._id)
                              a.userId = [...b];
                            }
                            setForwardData({ ...a });
                            console.log("-------====->", a)

                          }}>
                            <img src={ChatSelectedCheck} />
                          </div>
                        ) : (
                          <div onClick={(e) => {
                            // {
                            //   "messageId": [12], 
                            //   "senderId": 12, 
                            //   "contactsIds": 
                            //   [
                            //     {"contactId": 1,"personalNumber": 10000001},
                            //     {"contactId": 2,"personalNumber": 10000002},
                            //     {"contactId": 4,"personalNumber": 10000004}
                            //   ], 
                            //   "conversationsIds": [3, 5, 7, 8]
                            // }
                            let a = forwardData;
                            if (contact.participateMembers) {
                              a.conversationId = [...a.conversationId, contact._id];
                            } else {
                              a.userId = [...a.userId, contact._id];
                            }
                            setForwardData({ ...a });
                          }}>
                            <img src={ChatUnselectedCheck} />
                          </div>
                        )}
                    </div>
                    <div className="activeImage">
                      <div
                        className={`activeImageContainer ${!contact.image
                          ? "customProfilePic"
                          : !contact.icon
                            ? "customProfilePic"
                            : ""
                          }`}
                        style={
                          contact.image
                            ? {
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url(${contact.image ?? contact.icon
                                })`,
                            }
                            : contact.icon
                              ? {
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundImage: `url(${contact.image ?? contact.icon
                                  })`,
                              }
                              : {}
                        }
                      >
                        {!contact.image && !contact.type === "Group"
                          ? contact?.name
                            ?.split(" ")
                            ?.slice(0, 2)
                            ?.map((item) => {
                              return item?.charAt(0)?.toUpperCase();
                            })
                            ?.join("") ??
                          contact.title
                            ?.split(" ")
                            ?.slice(0, 2)
                            ?.map((item) => {
                              return item?.charAt(0)?.toUpperCase();
                            })
                            ?.join("")
                          : !contact.icon
                            ? contact?.name
                              ?.split(" ")
                              ?.slice(0, 2)
                              ?.map((item) => {
                                return item?.charAt(0)?.toUpperCase();
                              })
                              ?.join("") ??
                            contact.title
                              ?.split(" ")
                              ?.slice(0, 2)
                              ?.map((item) => {
                                return item?.charAt(0)?.toUpperCase();
                              })
                              ?.join("")
                            : null}
                      </div>

                      {contact.type === "Contact" && (
                        <div
                          className={showStatus(
                            contact?.participatingContacts?.filter(
                              (item) => item?.myIBID !== LoggedInUser?.id
                            )[0]?.onlineStatus
                          )}
                        ></div>
                      )}

                      {contact.type === "Group" && (
                        <div className="groupDot">
                          <img src={GroupIcon} alt="group" />
                        </div>
                      )}
                    </div>
                    <div
                      className="allChatListDisplayContainer"
                      style={{ justifyContent: "start", paddingTop: "6px" }}
                    >
                      <label className="chatListName">
                        {" "}
                        {contact?.name
                          ? contact?.name?.replace(/\s\(\d+\)$/, "")?.length > 18
                            ? contact?.name
                              ?.replace(/\s\(\d+\)$/, "")
                              ?.substring(0, 18) + "..."
                            : contact?.name?.replace(/\s\(\d+\)$/, "")
                          : contact?.title?.replace(/\s\(\d+\)$/, "")?.length > 18
                            ? contact?.title
                              ?.replace(/\s\(\d+\)$/, "")
                              ?.substring(0, 18) + "..."
                            : contact?.title?.replace(/\s\(\d+\)$/, "")}
                      </label>
                      <label
                        className="chatListRole"
                        style={{ textWrap: "nowrap" }}
                      >
                        {contact.type === "Contact"
                          ? contact?.designation
                            ? contact?.designation
                            : contact?.participatingContacts?.filter(
                              (item) => item?.myIBID !== LoggedInUser?.id
                            )[0]?.designation
                          : contact?.designation}
                      </label>

                      {/* <label className="chatListlastMsg">
                {contact?.type === "Contact" &&
                  (contact?.recentMessage !== null &&
                  contact?.recentMessage?.content?.length > 18
                    ? contact?.recentMessage?.content?.substring(0, 18) + "..."
                    : contact?.recentMessage?.content)}
              </label> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {(forwardData?.userId?.length > 0 || forwardData.conversationId.length > 0) && (
            <div
              style={{
                borderTop: "1px solid #F1F2F2",
                width: "100%",
                backgroundColor: "#D8F0F2",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0px 23px",
                height: "60px",
                alignSelf: "flex-end"

              }}
            >
              <div
                style={{
                  display: "flex",
                  fontFamily: "Poppins",
                  fontSize: "13.5px",
                  fontWeight: "400",
                  textAlign: "left",
                  color: "#1296B0",
                  alignItems: "center",
                }}
              >
                {forwardData.userId.length > 0 ?
                  newForwardArray.filter((item) => item._id === forwardData.userId[0] && !item.participateMembers)[0].name + "..."
                  : forwardData.conversationId.length > 0 ?
                    newForwardArray.filter((item) => item._id === forwardData.conversationId[0] && item.participateMembers)[0].title + "..."
                    : null
                }
                <div
                  // className="viewAllButton"
                  onClick={() => setViewAll()}
                  style={{
                    cursor: "pointer",
                    border: "0.8px solid #D8F0F2",
                    backgroundColor: "white",
                    padding: "2px 5px",
                    marginLeft: "16px",
                  }}
                >
                  View All
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={() => {
                    forwardMessageAction(forwardData);
                    handleClose();
                  }}
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="18" cy="18" r="18" fill="#1296B0" />
                    <path
                      d="M27.1779 18.2254L20.7267 11.6477M27.1779 18.2254L20.7267 24.0722M27.1779 18.2254H8.5411"
                      stroke="white"
                      stroke-width="1.10154"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForwardPopup;
