import React from 'react'
import { useEffect } from 'react';

const WeeklyCardComp = ({title, date}) => {
  return (
    <div className="attendanceCardContentContainer" style={{backgroundColor:`#DBF4E2`, borderLeft: '2px solid #0DB53C'}}>
      <div className="cardDateDisplay">{date ? date : ""}</div>
        <div className='attendanceContentStatus' style={{color:"#0DB53C", fontSize: '10px', width: 'calc(100% - 3px)', lineHeight: '12px', paddingTop: '7px'}} >
            <label>{title ? title : ""}</label>
        </div>
        
        
        </div>
  )
}

export {WeeklyCardComp}
