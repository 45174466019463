import { showToast, isLoading } from "../Features";

export const CandidateLogin = async (userData, navigate, dispatch) => {
  dispatch(isLoading(true));
  try {
    const result = { user: { uid: "hdfhds782345682456shdvf" } };
    localStorage.setItem("token", result.user.uid);
    localStorage.setItem("userType", "can");
    navigate("/candidate/profile", { replace: true });
    dispatch(
      showToast({ text: "Logged in successfully", severity: "success" })
    );
  } catch (error) {
    dispatch(showToast({ text: "Login failed", severity: "error" }));
  } finally {
    dispatch(isLoading(false));
  }
};
