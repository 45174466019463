import React from "react";
import { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";

import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";
const opt = [{ label: "select", value: null }];
export const CreateITSMSettingForm = ({ data, editID }) => {
  const [optionSer, setOptionSer] = React.useState([]);

  const getOptionSer = async () => {
    try {
      const result = await getRequest("ItsmWorkflows/GetITServiceCatalog");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.categoryName, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getOptionSer();
  }, []);

  useEffect(() => {
    document.title = `PeopleSol - Create ITSM Settings Form`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Service Type for Call Tickets"} />
        <SelectForm
          values={optionSer.filter(
            (options) => options?.value === data?.itsmIvrserviceType
          )}
          options={optionSer}
          name="itsmIvrserviceType"
          placeholder={" Select "}
        />
      </div>
      <div>
        <LabelCustom labelName={"IT Director"} />

        <DropdownInfiniteScroll
          name="itsmChangeApproverEmployeeId"
          placeholder={"IT Director"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.itsmChangeApproverEmployeeId]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Problem Manager"} />

        <DropdownInfiniteScroll
          name="itsmProblemManagerEmployeeId"
          placeholder={"Select Problem Manager"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.itsmProblemManagerEmployeeId]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Maximum Ticket Threshold"} />
        <InputCustomFormik
          step=".001"
          name="itsmMaximumTicketThreshold"
          placeholder={"Enter Name "}
        />
      </div>
    </div>
  );
};
