import React, { useState, useEffect } from "react";
import TempIcon from "../../Assets/TempIcon.svg";
import JobOpeningpic from "../../Assets/JobOpeningpic.svg";
import InternalJobspic from "../../Assets/InternalJobspic.svg";
import Referralpic from "../../Assets/Referralpic.svg";
import Candidatespic from "../../Assets/Candidatespic.svg";
import Interviewspic from "../../Assets/Interviewspic.svg";
import Assessmentpic from "../../Assets/Assessmentpic.svg";
import OfferLetterpic from "../../Assets/OfferLetterpic.svg";
import JobBoardspic from "../../Assets/JobBoardspic.svg";
import Consultantspic from "../../Assets/Consultantspic.svg";
import ReportsKPIpic from "../../Assets/ReportsKPIpic.svg";


import "./ATS.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Candidate, Interviews, JobOpening, OfferLetter, InternalJobs, Referral, Assessment} from "./Components";
import ATSIcon from "../../Assets/ATSIcon.svg";
import { IconButton } from "@mui/material";

const ATS = () => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentpath = location.pathname.toLowerCase();
        if (currentpath.includes("jobopening")) {
            jobOpeningActiveHandler();
        }
        else if (currentpath.includes("internaljobs")){
            internaljobsActiveHandler();
        }
        else if (currentpath.includes("referral")){
            referralActiveHandler();
        }
        else if (currentpath.includes("candidates")) {
            candidatesActiveHandler();
            if(currentpath.includes("create"))
            {
                setCreateCandidateVisible(true);
            }
            else
            {
                setCreateCandidateVisible(false);
            }
        }
        else if (currentpath.includes("interviews")) {
            interviewsActiveHandler();
            if(currentpath.includes("create"))
            {
                setCreateInterviewVisible(true);
            }
            else
            {
                setCreateInterviewVisible(false);
            }
        }
        else if (currentpath.includes("assessment")){
            assessmentActiveHandler();
        }
        else if (currentpath.includes("offerletter")) {
            offerLetterActiveHandler();
            if(currentpath.includes("create"))
            {
                setCreateOfferLetterVisible(true);
            }
            else
            {
                setCreateOfferLetterVisible(false);
            }
        }
        else if (currentpath.includes("jobboards")) {
            jobBoardsActiveHandler();
        }
        else if (currentpath.includes("consultants")) {
            consultantsActiveHandler();
        }
        else if (currentpath.includes("kpireports")) {
            kpiReportsActiveHandler();
        }
        else {
            navigate("jobopening");
        }
    }, [location.pathname]);


    const [createCandidateVisible, setCreateCandidateVisible] = useState(false);
    const [createInterviewVisible, setCreateInterviewVisible] = useState(false);
    const [createOfferLetterVisible, setCreateOfferLetterVisible] = useState(false);

    const [jobOpeningActive, setJobOpeningActive] = useState(true);
    const [internalJobsActive, setInternalJobsActive] = useState(true);
    const [referralActive, setReferralActive] = useState(true);
    const [assessmentActive, setAssessmentActive] = useState(true);
    const [candidatesActive, setCandidatesActive] = useState(false);
    const [interviewsActive, setInterviewsActive] = useState(false);
    const [offerLetterActive, setOfferLetterActive] = useState(false);
    const [jobBoardsActive, setJobBoardsActive] = useState(false);
    const [consultantsActive, setConsultantsActive] = useState(false);
    const [kpiReportsActive, setKpiReportsActive] = useState(false);

    const jobOpeningActiveHandler = () => {
        setJobOpeningActive(true);
        setInternalJobsActive(false);
        setReferralActive(false);
        setAssessmentActive(false);
        setCandidatesActive(false);
        setInterviewsActive(false);
        setOfferLetterActive(false);
        setJobBoardsActive(false);
        setConsultantsActive(false);
        setKpiReportsActive(false);
    };
    const internaljobsActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(true);
        setReferralActive(false);
        setAssessmentActive(false);
        setCandidatesActive(false);
        setInterviewsActive(false);
        setOfferLetterActive(false);
        setJobBoardsActive(false);
        setConsultantsActive(false);
        setKpiReportsActive(false);
    };
    const referralActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(false);
        setReferralActive(true);
        setAssessmentActive(false);
        setCandidatesActive(false);
        setInterviewsActive(false);
        setOfferLetterActive(false);
        setJobBoardsActive(false);
        setConsultantsActive(false);
        setKpiReportsActive(false);
    };
    const assessmentActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(false);
        setReferralActive(false);
        setAssessmentActive(true);
        setCandidatesActive(false);
        setInterviewsActive(false);
        setOfferLetterActive(false);
        setJobBoardsActive(false);
        setConsultantsActive(false);
        setKpiReportsActive(false);
    };
    const candidatesActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(false);
        setReferralActive(false);
        setAssessmentActive(false);
        setCandidatesActive(true);
        setInterviewsActive(false);
        setOfferLetterActive(false);
        setJobBoardsActive(false);
        setConsultantsActive(false);
        setKpiReportsActive(false);
    };
    const interviewsActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(false);
        setReferralActive(false);
        setAssessmentActive(false);
        setCandidatesActive(false);
        setInterviewsActive(true);
        setOfferLetterActive(false);
        setJobBoardsActive(false);
        setConsultantsActive(false);
        setKpiReportsActive(false);
    };
    const offerLetterActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(false);
        setReferralActive(false);
        setAssessmentActive(false);
        setCandidatesActive(false);
        setInterviewsActive(false);
        setOfferLetterActive(true);
        setJobBoardsActive(false);
        setConsultantsActive(false);
        setKpiReportsActive(false);
    };
    const jobBoardsActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(false);
        setReferralActive(false);
        setAssessmentActive(false);
        setCandidatesActive(false);
        setInterviewsActive(false);
        setOfferLetterActive(false);
        setJobBoardsActive(true);
        setConsultantsActive(false);
        setKpiReportsActive(false);
    };
    const consultantsActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(false);
        setReferralActive(false);
        setAssessmentActive(false);
        setCandidatesActive(false);
        setInterviewsActive(false);
        setOfferLetterActive(false);
        setJobBoardsActive(false);
        setConsultantsActive(true);
        setKpiReportsActive(false);
    };
    const kpiReportsActiveHandler = () => {
        setJobOpeningActive(false);
        setInternalJobsActive(false);
        setReferralActive(false);
        setAssessmentActive(false);
        setCandidatesActive(false);
        setInterviewsActive(false);
        setOfferLetterActive(false);
        setJobBoardsActive(false);
        setConsultantsActive(false);
        setKpiReportsActive(true);
    };


    return (
        <div className="moduleContainer">
            
            <div className="homeEngageAside">
                <div className="homeEngageAsideMenuContainer">
                    <div className={`menuOptions ${jobOpeningActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/jobopening") }}>
                        <div className="verticalLine"></div>
                        <img src={JobOpeningpic} alt="JobOpeningpic" />
                        <label>Job Opening</label>
                    </div>
                    <div className={`menuOptions ${internalJobsActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/internaljobs") }}>
                        <div className="verticalLine"></div>
                        <img src={InternalJobspic} alt="InternalJobspic" />
                        <label>Internal Jobs</label>
                    </div>
                    <div className={`menuOptions ${referralActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/referral") }}>
                        <div className="verticalLine"></div>
                        <img src={Referralpic} alt="Referralpic" />
                        <label>Referral</label>
                    </div>
                    <div className={`menuOptions ${candidatesActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/candidates") }}>
                        <div className="verticalLine"></div>
                        <img src={Candidatespic} alt="Candidatespic" />
                        <label>Candidates</label>
                    </div>
                    <div className={`menuOptions ${interviewsActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/interviews") }}>
                        <div className="verticalLine"></div>
                        <img src={Interviewspic} alt="Interviewspic" />
                        <label>Interviews</label>
                    </div>
                    <div className={`menuOptions ${assessmentActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/assessment") }}>
                        <div className="verticalLine"></div>
                        <img src={Assessmentpic} alt="Assessmentpic" />
                        <label>Assessment</label>
                    </div>
                    <div className={`menuOptions ${offerLetterActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/offerletter") }}>
                        <div className="verticalLine"></div>
                        <img src={OfferLetterpic} alt="OfferLetterpic" />
                        <label>Offer Letter</label>
                    </div>
                    <div className={`menuOptions ${jobBoardsActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/jobboards") }}>
                        <div className="verticalLine"></div>
                        <img src={JobBoardspic} alt="JobBoardspic" />
                        <label>Job Boards</label>
                    </div>
                    <div className={`menuOptions ${consultantsActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/consultants") }}>
                        <div className="verticalLine"></div>
                        <img src={Consultantspic} alt="Consultantspic" />
                        <label>Consultants</label>
                    </div>
                    <div className={`menuOptions ${kpiReportsActive && "menuOptionsActive"}`} onClick={() => { navigate("/ats/kpireports") }}>
                        <div className="verticalLine"></div>
                        <img src={ReportsKPIpic} alt="ReportsKPIpic" />
                        <label>Reports & KPI</label>
                    </div>
                </div>
            </div>
            <div className="homeEngageMainContainer">
                {jobOpeningActive ? <JobOpening title="Job Openings"/> : null}
                {candidatesActive ? <Candidate/> : null}
                {interviewsActive ? <Interviews/> : null}
                {offerLetterActive ? <OfferLetter title="Offer Letter"/> : null}
                {internalJobsActive ? <InternalJobs title="Internal Jobs"/> : null}
                {referralActive ? <Referral title="Referral"/> : null}
                {assessmentActive ? <Assessment title="Assessment"/> : null}
            </div>


        </div>
    );
};

export { ATS };