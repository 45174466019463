import { IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";

const HouseRentComp = ({ switchActive, values }) => {
  // Options for the metro selection
  const metroOptions = [
    { label: "Metro", value: "Metro" },
    { label: "Non-Metro", value: "Non-Metro" },
  ];
  useEffect(() => {
    document.title = `PeopleSol -  House Rent`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  // Options for the month selection
  return (
    <>
      {switchActive && (
        <div className="extendedParticularDetails">
          {/* Extended form container */}
          <div
            className="extendedHeaderContainer"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* Extended form heading */}
            {/* <label className="extendedHeading">House rent Details</label> */}
            {/* Button or icon for closing the form */}
          </div>
          <div className="extendedFormContainer">
            {/* Form fields */}
          

            {/* House rent paid per month field */}

            <div className="extendedFormComp">
              {/* House rent paid label */}
              <LabelCustom labelName={"House Rent Paid Per Month"}/>
              {/* House rent paid input */}
              <div
                className="extendedInputContainer"
                style={{ flexDirection: "column" }}
              >
                <InputCustomFormik
                  style={{ border: "1px solid #E6E6E6" }}
                  placeholder="Enter house Rent Paid"
                  name={"houseRentPaid"}
                  type="number"
                  min={0}
                  max={99999999}
                />
                {/* Display total rent if houseRentPaid value exists */}
                {values?.houseRentPaid && (
                  <label
                    style={{
                      fontSize: "11px",
                      fontWeight: "400",
                      paddingLeft: "15px",
                    }}
                  >
                    {`Total Rent: ₹${values?.houseRentPaid * 12}`}
                  </label>
                )}
              </div>
            </div>

            {/* Landlord address field */}

            {/* Landlord details fields */}
            <div className="extendedFormComp">
              {/* Landlord details label */}
              <LabelCustom labelName={"Landlord Name"}  />
              {/* Landlord details inputs */}

              <InputCustomFormik
                style={{ border: "1px solid #E6E6E6" }}
                placeholder="Enter Landlord Name"
                name={"tenantName"}
              />
            </div>
          </div>

          <div className="extendedInputContainer">
            {/* Address label */}
            <div className="extendedFormComp">
            <LabelCustom labelName={"Rented House Address"} />
            {/* Address input */}
              <InputCustomFormik
                name="LandLordAddressOfProperty"
                formikStyle={"100%"}
                style={{
                  border: "1px solid #E6E6E6",
                }}
                placeholder="Enter Address"
              />
            </div>
            <div className="extendedFormComp">
            <LabelCustom labelName={"Landlord PAN"}  />

            <InputCustomFormik
              style={{ border: "1px solid #E6E6E6" }}
              placeholder="Landlord PAN"
              name={"LandlordPan"}
            />
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export { HouseRentComp };
