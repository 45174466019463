// ContactList component
import React, { act, useEffect, useState } from "react";
import GroupIcon from "../../../Assets/GroupIcon.svg";
import MarriageAnniversary from "../../../Assets/MarriageAnniversary.svg";
import WorkAnniversary from "../../../Assets/WorkAnniversary.svg";
import HappyBirthday from "../../../Assets/HappyBirthday.svg";
import NewJoining from "../../../Assets/NewJoining.svg";
import ChatSelectedCheck from "../../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../../Assets/ChatUnselectedCheck.svg";
import { format, isToday, isYesterday } from "date-fns";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import PinMessage from "../../../Assets/PinMessage.svg";
import BroadCast from "../../../Assets/Broadcast.svg"
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
const ContactList = ({
  LoggedInUser,
  chatList,
  showStatus,
  SetActiveChat,
  handleContactClick,
  handleConversationAction,
  selectedChatsArr,
  setSelectedChatsArr,
  activeChat,
  selectChat

}) => {
  const [activeChatIndex, setActiveChatIndex] = useState(null);
  const [chatListNotSelf, setChatListNotSelf] = useState([]);
  const userDetails = useSelector(state => state.chat.userDetails);

  const checkAnniversary = (dateInput, type) => {
    let date = new Date(dateInput);
    let currDate = new Date();
    if (type == "marraige" || type == "work" || type == "birthday") {
      return (date.getDate() === currDate.getDate() && date.getMonth() === currDate.getMonth())
    } else if (type == "newJoin") {
      return (date.getDate() === currDate.getDate() && date.getMonth() === currDate.getMonth() && date.getFullYear() === currDate.getFullYear())
    }
    return false;
  }
  const formattedDate = (date) => {
    if (isToday(date)) {
      return format(new Date(date), "hh:mm a");
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else {
      return format(date, "d/M/yy");
    }
  };
  useEffect(() => {
    let chatListNotSelfvar = chatList?.filter((item) => item?.type !== "Self");
    console.log("object chatListNotSelfvar", chatListNotSelfvar);

    setChatListNotSelf(chatListNotSelfvar);
  }, [chatList]);

  function formatTime(dateString) {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If `hours` is 0, set it to 12
    const formattedMinutes = minutes.toString().padStart(2, '0'); // Add leading zero to minutes if needed

    return `${hours}:${formattedMinutes} ${ampm}`;
  }
  const Contact = ({ contact, index }) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    return (
      <div
        onMouseLeave={() => { setShowDropDown(false) }} onMouseEnter={() => { setShowDropDown(true) }}
        onClick={() => {
          handleContactClick(contact?._id, null, contact);
          setActiveChatIndex(index);
          SetActiveChat(contact);
        }}
        className={` chatListContentContainer ${contact?.participatingContacts?.filter(
          (item) => item?.myIBID !== LoggedInUser?.id
        )[0]?.onlineStatus && "chatListContentContainerActive"
          } ${index === activeChatIndex && "active"} `}
        style={{ backgroundColor: activeChat._id === contact._id ? "rgba(239, 249, 250, 1)" : "white" }}
      >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "53%" }}>
          {selectChat && <div onClick={(e) => {
            if (selectedChatsArr.includes(contact._id)) setSelectedChatsArr(selectedChatsArr.filter(item => item != contact._id));
            else setSelectedChatsArr([...selectedChatsArr, contact._id])
            e.stopPropagation()
          }} style={{ marginLeft: 10 }}>
            <img src={selectedChatsArr.includes(contact._id) ? ChatSelectedCheck : ChatUnselectedCheck} />
          </div>}
          <div className="activeImage" style={{ marginLeft: 10, marginRight: window.innerWidth > 1440 ? 30 : 12, marginTop: window.innerWidth > 1440 ? 0 : 12 }}>
            {contact.type == "Broadcast" ? <img style={{ borderRadius: 25, width: window.innerWidth > 1440 ? 60 : 42, height: window.innerWidth > 1440 ? 60 : 42 }} alt="broadcast" src={BroadCast} /> :
              <div className={`activeImageContainer ${!contact.icon ? "customProfilePic" : ""}`}
                style={
                  contact.icon
                    ? {
                      width: window.innerWidth > 1440 ? 60 : 42,
                      height: window.innerWidth > 1440 ? 60 : 42,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${contact.icon})`,
                    }
                    : {
                      width: window.innerWidth > 1440 ? 60 : 42,
                      height: window.innerWidth > 1440 ? 60 : 42,
                    }
                }
              >
                {!contact.icon && contact?.title
                  ? contact?.title
                    .split(" ")
                    .slice(0, 2)
                    .map((item) => {
                      return item.charAt(0).toUpperCase();
                    })
                    .join("")
                  : null}
              </div>}

            {contact.type === "Contact" && (
              <div
                className={showStatus(
                  contact.busyStatus ? 3 : contact.dndStatus ? 2 : contact.onlineStatus ? 1 : 0
                )}
                style={{
                  height: window.innerWidth > 1440 ? 10 : 10,
                  width: 10,
                  bottom: window.innerWidth > 1440 ? -16 : 0,
                  right: window.innerWidth > 1440 ? -14 : 0,
                }}
              ></div>
            )}

            {contact.type === "Group" && (
              <div style={{
                position: "absolute",
                height: window.innerWidth > 1440 ? 0 : 10,
                width: 10,
                bottom: window.innerWidth > 1440 ? 4 : 8,
                right: window.innerWidth > 1440 ? -5 : 10,
              }}>
                <img src={GroupIcon} alt="group" />
              </div>
            )}
          </div>
          <div
            className="allChatListDisplayContainer"
            style={{ justifyContent: "start" }}
          >
            <div style={{ fontSize: window.innerWidth > 1440 ? 16 : 12, fontWeight: "700", color: activeChat._id == contact._id ? "rgba(18, 150, 176, 1)" : "rgba(85, 85, 85, 1)" }}>
              {" "}
              {contact?.title ? (contact?.title.replace(/\s\(\d+\)$/, "").length > 18
                ? contact?.title.replace(/\s\(\d+\)$/, "").substring(0, 18) +
                "..."
                : contact?.title.replace(/\s\(\d+\)$/, "")) : null}
            </div>
            <div style={{ fontSize: window.innerWidth > 1440 ? 14 : 10, marginTop: -3 }}>
              {contact.type === "Group" ?
                "You : @" + contact.participateMembers.filter(item => item.contactId != userDetails._id)[0].contactName + "..." : contact.participateMembers.filter(item => item.contactId != userDetails._id)[0].designation}
            </div>
            {contact?.recentMessage?.length > 0 ? <div style={{ fontSize: window.innerWidth > 1440 ? 14 : 10, color: "rgba(161, 161, 161, 1)", marginTop: -1.5 }}>
              {contact?.type === "Contact" ?
                (contact?.recentMessage[0]?.caption?.length > 18
                  ? contact?.recentMessage[0]?.caption?.substring(0, 18) +
                  "..."
                  : contact?.recentMessage[0]?.caption)
                :
                contact.recentMessage[0]?.senderId == userDetails._id ? "You: " + contact.recentMessage[0]?.caption
                  :
                  contact?.participateMembers?.filter(item => item.contactId == contact.recentMessage[0]?.senderId)[0]?.contactName ?
                    contact?.participateMembers?.filter(item => item.contactId == contact.recentMessage[0]?.senderId)[0]?.contactName + ": " + contact.recentMessage[0]?.caption
                    : contact.recentMessage[0]?.caption

              }
            </div> : null}
          </div>
        </div>
        <div style={{ height: "100%", width: "47%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
          <div
            style={{
              marginTop: 5,
              marginRight: 8,
            }}
          >
            {contact.type === "Contact" && <div>
              <div>
                {checkAnniversary(contact.participateMembers.filter(item => item.contactId != userDetails._id)[0].dom, "marraige")
                  &&
                  <img
                    style={{ zIndex: 1 }}
                    src={MarriageAnniversary}
                    alt="Marriage Anniversary"
                  />
                }
                {checkAnniversary(contact.participateMembers.filter(item => item.contactId != userDetails._id)[0].doj, "newJoin")
                  &&
                  <img
                    style={{ zIndex: 2, marginLeft: -25 }}
                    src={NewJoining}
                    alt="New Joining"
                  />}
                {checkAnniversary(contact.participateMembers.filter(item => item.contactId != userDetails._id)[0].doj, "work")
                  &&
                  <img
                    style={{ zIndex: 3, marginLeft: -25 }}
                    src={WorkAnniversary}
                    alt="Work Anniversary"
                  />}
                {checkAnniversary(contact.participateMembers.filter(item => item.contactId != userDetails._id)[0].dob, "birthday")
                  &&
                  <img
                    style={{ zIndex: 4, marginLeft: -25 }}
                    src={HappyBirthday}
                    alt="Happy Birthday"
                  />
                }
              </div>

            </div>}
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginRight: 10, }}>
            <div style={{ color: "rgba(191, 191, 191, 1)", fontSize: window.innerWidth > 1440 ? 14 : 12, textAlign: "end", marginRight: 10, marginBottom: 10, width: 60, }}>
              {contact?.recentMessage?.length > 0 ? formatTime(contact.recentMessage[0].createdOn) : formatTime(contact.lastUpdatedOn)}
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              {contact.unReadMsgCount > 0 || (!contact.isRead) ?
                <div style={{
                  marginRight: 5,
                  fontSize: 11,
                  fontWeight: "500",
                  color: "white",
                  backgroundColor: "#1296B0",
                  textAlign: "center",
                  borderRadius: 10,
                  paddingTop: 1, paddingBottom: 1, paddingRight: 2, paddingLeft: 2, height: "15px", minWidth: contact.unReadMsgCount == 0 ? "15px" : "30px",
                  fontSize: 9
                }}>
                  {contact.unReadMsgCount > 0 && contact.unReadMsgCount}
                </div> : null}
              {contact.isPin ? <div style={{ marginRight: 5 }}>
                <img
                  src={PinMessage}
                  alt="pinned"
                />
              </div> : null}
              {contact.isBlock && <div style={{ marginRight: 5 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                  <ellipse cx="11.6476" cy="11.2168" rx="11.0001" ry="11" fill="#1296B0" />
                  <path d="M8.13477 10.0455V8.87448C8.13477 6.93639 8.7203 5.36133 11.648 5.36133C14.5756 5.36133 15.1612 6.93639 15.1612 8.87448V10.0455M11.648 15.0225C12.0362 15.0225 12.4085 14.8683 12.683 14.5937C12.9576 14.3192 13.1118 13.9469 13.1118 13.5587C13.1118 13.1704 12.9576 12.7981 12.683 12.5236C12.4085 12.2491 12.0362 12.0949 11.648 12.0949C11.2597 12.0949 10.8874 12.2491 10.6129 12.5236C10.3384 12.7981 10.1841 13.1704 10.1841 13.5587C10.1841 13.9469 10.3384 14.3192 10.6129 14.5937C10.8874 14.8683 11.2597 15.0225 11.648 15.0225Z" stroke="white" stroke-width="1.12862" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.5755 17.0717H8.72014C6.37801 17.0717 5.79248 16.4862 5.79248 14.1441V12.973C5.79248 10.6309 6.37801 10.0454 8.72014 10.0454H14.5755C16.9176 10.0454 17.5031 10.6309 17.5031 12.973V14.1441C17.5031 16.4862 16.9176 17.0717 14.5755 17.0717Z" stroke="white" stroke-width="1.12862" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>}
              {!selectChat && <div
                style={{ width: 10, height: 10, alignSelf: "flex-end" }}
                onClick={(e) => {
                  setPopupOpen((prev) => {
                    if (prev) return false;
                    else return true
                  });
                  e.stopPropagation();
                }}
              >
                <img
                  style={{ cursor: "pointer", display: showDropDown ? "block" : "none" }}
                  src={DropdownArrowChatMesssage}
                  alt=""
                />
              </div>}
            </div>
          </div>
          <OutsideClickHandler
            key={index}
            id={index}
            onOutsideClick={(id) =>
              setPopupOpen(false)
            }
          >
            <div
              className="chatActionsPopup"
              style={
                popupOpen && showDropDown ? {
                  display: "block",
                  position: "absolute",
                  zIndex: "100",
                  marginLeft: "-100px",
                  marginTop: (contact.isArchived || contact.isBlock) && index <= 3 ? "-70px" : "-" + JSON.stringify(index * 70) + "px",
                  color: "black",
                }
                  : { display: "none" }
              }
            >
              {!contact.isBlock && <div style={{ borderRadius: "6px 6px 0px 0px" }} onClick={(e) => { e.stopPropagation(); handleConversationAction(contact?._id, contact.isArchived ? "unarchived" : "archived"); }}>{!contact.isArchived ? "Archive" : "Unarchive"}</div>}
              <div onClick={(e) => { e.stopPropagation(); handleConversationAction(contact?._id, "deleteConversation"); }}>Delete Chat</div>
              {!contact.isArchived && !contact.isBlock && <div onClick={(e) => {
                e.stopPropagation();
                if (!contact.isPin && chatListNotSelf.filter(val => val.isPin == true).length > 4) {
                  confirmAlert({
                    title: "Pin User?",
                    message: "You cannot pin more than 5 users!",
                    buttons: [
                      {
                        label: "Cancel",
                        onClick: () => { },
                      },
                      {
                        label: "Ok",
                        onClick: () => {
                        },
                      },
                    ],
                    overlayClassName: "customConfirmAlertContainer",
                  });
                } else {
                  handleConversationAction(contact?._id, contact.isPin ? "unpin" : "pin");
                }
              }}>{contact.isPin ? "Unpin Chat" : "Pin Chat"}</div>}
              {!contact.isArchived && !contact.isBlock && <div onClick={(e) => {
                e.stopPropagation();
                handleConversationAction(contact?._id, contact.isFavorite ? "unfavorite" : "favorite");
              }}>{contact.isFavorite ? "Remove From Favourites" : "Add To Favourites"}</div>}
              {!contact.isBlock && <div onClick={(e) => { e.stopPropagation(); handleConversationAction(contact?._id, ((contact.unReadMsgCount > 0) || !contact.isRead) ? "read" : "unread"); }}>{((contact.unReadMsgCount > 0) || !contact.isRead) ? "Mark as Read" : "Mark as Unread"}</div>}
              <div style={{ borderRadius: "0px 0px 6px 6px" }} onClick={(e) => {
                if (contact.isBlock) {
                  e.stopPropagation(); handleConversationAction(contact?._id, "unblock");
                } else {
                  confirmAlert({
                    title: "Block User?",
                    message:
                      "Blocked contacts will no longer be able to call you or send you messages.",
                    buttons: [
                      {
                        label: "Cancel",
                        onClick: () => { },
                      },
                      {
                        label: "Block",
                        onClick: () => {
                          e.stopPropagation(); handleConversationAction(contact?._id, "block");
                        },
                      },
                    ],
                    overlayClassName: "customConfirmAlertContainer",
                  });
                }
              }}>{contact.isBlock ? "Unblock" : "Block"}</div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    )
  }

  return (
    <div className="conversationListItem" style={{ paddingBottom: 100 }} >
      {
        chatListNotSelf.map((contact, index) => (
          <Contact contact={contact} key={index} index={index} />
        ))}
    </div>
  );
};

export default ContactList;
