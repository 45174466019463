/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { format } from "date-fns";
import dateFormat from "dateformat";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";

export const AdminCreate = ({ id, data, type, color, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [val, setVal] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  const validationSchema = Yup.object({
    FacilityID: Yup.string().required("Enter Facility Reference Number"),
    CategoryId: Yup.string().required("Select Facility Category"),
    FacilityMaterialId: Yup.string().required("Select Facility Type"),
    Description: Yup.string().required("Enter Description"),
  });
  const dispatchAdminCreate = async (value) => {
    try {
      setLoading(true);
      const result = await postRequest(
        `facilityrequests/CreateFacilities`,
        value
      );

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };
  const getCategory = async () => {
    try {
      const result = await getRequest(
        `FacilityCategories/GetFacilityCategories`
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setCategoryList(listData);
    } catch (error) {
    } finally {
    }
  };
  const getFacility = async () => {
    try {
      const result = await getRequest(
        `FacilityRequests/GetFacilityType?Id=${val.CategoryId}`
      );
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getCategory();
    getFacility();
  }, []);
  const onSubmit = async (values) => {
    dispatchAdminCreate(values);
  };
  useEffect(() => {
    if (val.CategoryId) {
      getFacility();
    }
  }, [val.CategoryId]);
  useEffect(() => {
    document.title = `PeopleSol - Admin Create`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <Formik
      initialValues={{
        FacilityID: "",
        CategoryId: "",
        FacilityMaterialId: "",
        Description: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        setVal(values);
        return (
          <Form>
            <div className="formcustom">
            <div>
              <LabelCustom labelName={"Facility Reference Number"} mandatory={true} />
              <InputCustomFormik
                name={"FacilityID"}
                placeholder="Enter Facility Reference Number"
              />
            </div>
            <div>
              <LabelCustom labelName={"Facility Category"} mandatory={true} />
              <SelectForm
                name={"CategoryId"}
                placeholder="Select Facility category"
                options={categoryList}
              />
            </div>
            <div>
              <LabelCustom labelName={"Facility Type"} mandatory={true} />
              <SelectForm
                onDropdownOpen={
                  val?.CategoryId === ""
                    ? () =>
                        dispatch(
                          showToast({
                            text: "Please select  Facility category",
                            severity: "error",
                          })
                        )
                    : () => {}
                }
                name={"FacilityMaterialId"}
                placeholder="Select Facility Type"
                options={employeeList}
              />
            </div>
            <div>
              <LabelCustom labelName={"Facility Description"} mandatory={true} />
              <TextAreaFormik
                style={{ height: "80px" }}
                name={"Description"}
                placeholder="Enter Description"
              />
            </div>
            </div>
            <div className="createFootCust">
              <button
                type="button"
                className="button secondaryButton"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton"
                type="submit"
                style={{ color: "white" }}
              >
                Create New Facility
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
