import React, { useState } from "react";
import "./BoxContentHolder.css";
import { useEffect } from "react";

const BoxContentHolder = (props) => {
 
  return (
    <div className={`boxContentHolder ${props.last}`}>
                <div className="boxTextHolder">
                    <label className="boxTextHolderHeading">{props.heading}</label>
                    <label className="boxTextHolderText">{props.text}</label>
                </div>
                <div className="boxSeparator"/>
    </div>
  );
};
export default BoxContentHolder;
