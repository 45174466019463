import React from "react";
import { useNavigate } from "react-router-dom";
import {
  getContentLength,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import {
  communityProfileImages,
  profileImages,
} from "../../../../../Utilities/profileAndUploadImages";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialNewsTag from "../../../../../Assets/SocialNewsTag.svg";
import SocialAnnouncementTag from "../../../../../Assets/SocialAnnouncementTag.svg";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import dateFormat from "dateformat";
import ContentSocialDisplay from "./ContentSocialDisplay";
import { useState } from "react";
function NestedSharedPostComponent({ d }) {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();
  const getDetailPage = (id) => {
    navigate("details/" + id);
  };
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  return (
    <div className="nestedSharedPostListContainer">
      <div className="nestedSharedPostListWrapper">
        {!d?.sharedNews && !d?.announcementTitle && (
          <div className="postHeaderContainer" style={{ boxShadow: "none" }}>
            {d?.sharedPost?.postType === "organisation" && (
              <div
                className="leftPostHeaderContainer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserProfileClick(d?.employeeId);
                }}
              >
                <div
                  className={`${
                    d?.image
                      ? "socialProfilePicContainer"
                      : "socialCustomProfilePic"
                  }`}
                >
                  {!d?.image ? (
                    getUserInitials(d?.name)
                  ) : (
                    <img
                      className="socialProfilePic"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUserProfileClick(d?.employeeId);
                      }}
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        profileImages +
                        d?.image
                      }
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = ProfileAvatar;
                      }}
                    />
                  )}
                </div>

                <div className="postMainTextContainer">
                  <label className="postMainName">
                    {getDisplayName(d?.nameWithPersonNumber)}
                  </label>
                  <label>{d?.roleName}</label>
                  <label>
                    {d?.locationName}{" "}
                    <span className="postHrTime">
                      {" "}
                      • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                    </span>
                  </label>
                </div>
              </div>
            )}
            {d?.sharedPost?.postType === "community" && (
              <div
                className="leftPostHeaderContainer"
                style={{ position: "relative" }}
              >
                <div
                  onClick={(e) => {
                    navigate(`/home/social/community/${d?.community?.id}`);
                  }}
                  className={`${
                    d?.community?.communityImage
                      ? "socialConnectProfilePicContainer"
                      : "nestedCommunityCustomProfilePic"
                  }`}
                >
                  {!d?.community?.communityImage &&
                  d?.community?.communityName ? (
                    getUserInitials(d?.community?.communityName)
                  ) : (
                    <img
                      className="socialProfilePic"
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        communityProfileImages +
                        d?.community?.communityImage
                      }
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = SocialCommunityGroupIcon;
                      }}
                    />
                  )}
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserProfileClick(d?.employeeId);
                  }}
                  className={`${
                    d?.image
                      ? "socialProfilePicContainer"
                      : "socialCustomProfilePicCommunity"
                  }`}
                >
                  {!d?.image ? (
                    getUserInitials(d?.name)
                  ) : (
                    <img
                      className={`socialProfilePic ${
                        d?.postType !== "organisation" ? "prTeamCommunity" : ""
                      }`}
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        profileImages +
                        d?.image
                      }
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = ProfileAvatar;
                      }}
                    />
                  )}
                </div>

                <div
                  className="postMainTextContainer"
                  onClick={() => {
                    navigate(`/home/social/community/${d?.community?.id}`);
                  }}
                >
                  <label className="postMainName">
                    {d?.sharedPost?.community?.communityName}
                  </label>
                  <label>
                    Post from{" "}
                    <span className="nameofposter">
                      {getDisplayName(d?.nameWithPersonNumber)}{" "}
                    </span>
                    <span className="postHrTime">
                      {" "}
                      • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                    </span>
                  </label>
                </div>
              </div>
            )}
            {d?.sharedPost?.postType === "team" && (
              <div
                className="leftPostHeaderContainer"
                style={{ position: "relative" }}
              >
                <img
                  src={SocialPostTeamIcon}
                  alt=""
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                />
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserProfileClick(d?.employeeId);
                  }}
                  className={`${
                    d?.image
                      ? "socialProfilePicContainer"
                      : "socialCustomProfilePicCommunity"
                  }`}
                >
                  {!d?.image ? (
                    getUserInitials(d?.name)
                  ) : (
                    <img
                      className={`socialProfilePic ${
                        d?.postType !== "organisation" ? "prTeamCommunity" : ""
                      }`}
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        profileImages +
                        d?.image
                      }
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = ProfileAvatar;
                      }}
                    />
                  )}
                </div>

                <div className="postMainTextContainer">
                  <label className="postMainName">Team</label>
                  <label>
                    Post from{" "}
                    <span className="nameofposter">
                      {getDisplayName(d?.nameWithPersonNumber)}{" "}
                    </span>
                    <span className="postHrTime">
                      {" "}
                      • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                    </span>
                  </label>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </div>
        )}
        {d?.announcementTitle && (
          <div className="postHeaderContainer" style={{ boxShadow: "none" }}>
            <div className="leftPostHeaderContainer">
              <div className="announcementHeaderContainer">
                <label>{d?.announcementTitle ?? "--"}</label>
                <span className="postHrTime">
                  • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="announcementTypeContainer">
                <span>Announcement</span>
                <article></article>
                <img src={SocialAnnouncementTag} alt="" />
              </div>
            </div>
          </div>
        )}
        {d?.sharedNews && (
          <div className="postHeaderContainer" style={{ boxShadow: "none" }}>
            <div className="leftPostHeaderContainer">
              <div className="socialPostTitleAndTimeContainer">
                <label>
                  {d?.sharedNews?.title?.length > 40
                    ? d?.sharedNews?.title?.substring(0, 40) + "..."
                    : d?.sharedNews?.title}
                </label>
                <span className="postHrTime">
                  • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="newsTypeContainer">
                <span>{d?.sharedNews?.category}</span>
                <article></article>
                <img src={SocialNewsTag} alt="" />
              </div>
            </div>
          </div>
        )}
        <div
          style={{ cursor: "pointer" }}
          className="postBodyContainer"
          onClick={() => {
            getDetailPage(d?.id);
          }}
        >
          <div className="postBodyTextContainer">
            {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
              <ContentSocialDisplay content={getContentLength(d?.content)} />
            ) : (
              <ContentSocialDisplay content={d?.content?.trim()} />
            )}
            {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
              <span
                onClick={() => {
                  setShowContent(true);
                }}
              >
                ...View More
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NestedSharedPostComponent;
