import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { PopupLogout } from "../Components/FormComponent/PopupLogout";
import OutsideClickHandler from "../Components/OutsideClickHandler/OutsideClickHandler";

function redirectToLogin() {
  localStorage.removeItem("token");
  localStorage.removeItem("userType");
  window.location.href = "/login";
}
function showLogoutPopup(res) {
  const urlParts = res.split("/");
  // Get the last two parts of the URL
  const lastTwoParts = urlParts.slice(-2);
  // Join the last two parts back together with a slash
  const result = lastTwoParts.join("/");
  if (result == "Settings/Logout") return <></>;
  else
    confirmAlert({
      customUI: () => {
        return (
          <div className="overlay">
            {console.log("---------- 2")}

            <OutsideClickHandler onOutsideClick={redirectToLogin}>
              <PopupLogout isOnline={true} />
            </OutsideClickHandler>
          </div>
        );
      },
    });
}
export const getAuthorizationHeader = () =>
  `Bearer ${localStorage.getItem("token")}`;
const axiosClient = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_.BASE_URL,
  headers: {
    Authorization: getAuthorizationHeader(),
    "Content-Type": "application/json",
    "Is-Token-Expired": 0,
  },
});
axiosClient.interceptors.response.use(
  (response) => {
    // Continue with the response handling
    return response; // This line allows the original response to continue
  },
  (error) => {
    // Handle the response error

    if (error.response && error.response.status === 401) {
      // Handle the 401 Unauthorized error here
      showLogoutPopup(error?.response?.request?.responseURL);
      // For example, you can redirect the user to the login page
      // or display an error message indicating unauthorized access.
    } else {
      // Handle other response errors
    }
    return Promise.reject(error);
  }
);
const axiosClientForm = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_.BASE_URL,
  headers: {
    Authorization: getAuthorizationHeader(),
    "Content-Type": "multipart/form-data",
  },
});
const axiosClientZip = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_.BASE_URL,
  headers: {
    Authorization: getAuthorizationHeader(),
    "Content-Type": "application/json",
  },
});
axiosClient.interceptors.response.use(
  (response) => {
    // do something with the response data

    return response;
  },
  (error) => {
    // handle the response error
    return Promise.reject(error);
  }
);
export function getRequest(URL) {
  return axiosClient
    .get(`/${URL}`, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}
export function getRequestWithPayload(URL, payload) {
  return axiosClient
    .get(`/${URL}`, payload, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}
export function getRequestZ(URL) {
  return axiosClientZip
    .get(`/${URL}`, {
      responseType: "arraybuffer", // Make sure to specify arraybuffer responseType
      headers: {
        Authorization: getAuthorizationHeader(),
        Accept: "application/octet-stream", // Update the Accept header
      },
    })
    .then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient
    .post(`/${URL}`, payload, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}
export function postRequestXlsx(URL, payload) {
  return axiosClient
    .post(`/${URL}`, payload, {
      responseType: "blob",
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}
export function getRequestXlsx(URL) {
  return axiosClient
    .get(`/${URL}`, {
      responseType: "blob",
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}
export function postRequestForm(URL, payload) {
  return axiosClientForm
    .post(`/${URL}`, payload, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}
export function getRequestForm(URL, payload) {
  return axiosClientForm
    .post(`/${URL}`, payload, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient
    .patch(`/${URL}`, payload, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient
    .delete(`/${URL}`, {
      headers: { Authorization: getAuthorizationHeader() },
    })
    .then((response) => response);
}
export function deleteWithPayloadRequest(URL, id) {
  return axiosClient
    .post(
      `/${URL}`,
      {
        id,
      },
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    )
    .then((response) => response);
}
