import React from "react";
import { useEffect } from "react";
import { TravelCategoryTab } from "./TravelCategoryTab";

const TravelCategory = () => {
  useEffect(() => {
    document.title = `PeopleSol - Travel Category`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <TravelCategoryTab title="Travel Category" />
    </div>
  );
};

export { TravelCategory };
