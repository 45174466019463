import * as Yup from "yup";

export const infoSchema = Yup.object({

  title:  Yup
  .string()
  .max(149," Name must be less than 150 characters")
  .required("Please Enter Name"),
 
});

export const viewSchema=Yup.object({
  name: Yup.string().max(149," Name must be less than 150 characters").required("Please Enter Name"),
  value: Yup.string().required("required"),
  infotypeId: Yup.string().required("required")
});
