import React, { useState, useEffect } from "react";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../Services/axios";
import { showToast, isLoading } from "../../../Features";
import { useDispatch } from "react-redux";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { YesNoFormik } from "../../../Components/FormComponent/YesNoFormik";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";

export const CreateBannersComp = ({ data }) => {
  const [categoryList, setCategoryList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `PeopleSol - Create Notice`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const opt = [{ label: "select", value: null }];

  const getModulesData = async () => {
    try {
      const result = await getRequest("Banners/GetModules");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.name });
      });
      setCategoryList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getModulesData();
  }, []);

  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="Title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Module"} mandatory={true} />
        <SelectForm
          placeholder={"Select Module"}
          name="Module"
          options={categoryList}
          values={
            data
              ? categoryList.filter((options) => options.value === data?.module)
              : opt
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"Request ID"} mandatory={true} />
        <InputCustomFormik
          name="RequestId"
          step=".001"
          min={1}
          max={999999}
          placeholder={"Enter Request ID"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} mandatory={true} />
        <TextAreaFormik
          maxLength={500}
          name="Description"
          placeholder={"Enter Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Button text"} />
        <InputCustomFormik
          maxLength={150}
          name="ButtonText"
          type={"text"}
          placeholder={"Enter Button text"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Footer Text"} />
        <InputCustomFormik
          maxLength={150}
          name="FooterText"
          type={"text"}
          placeholder={"Enter Footer Text"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Redirect Url"} />
        <InputCustomFormik
          name="RedirectUrl"
          type={"text"}
          placeholder={"Enter Redirect Url"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload Attachment"} mandatory={true} />
        <FileUploadFormik
          name="Upload"
          attachTextLabel={data?.image?.toString()}
        />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Size: 2 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Format: JPEG, PNG, JPG
        </span>
      </div>
      <div>
        <LabelCustom labelName={"Is Display in Mobile?"} />
        <YesNoFormik name="IsDisplayInMobile" />
      </div>
    </div>
  );
};
