import React from "react";
import { useState } from "react";
import { Table } from "../../../Components";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalTravelAd } from "../Components/TravelAdvanceApproval/PendingApprovalTravelAd";
import { AllApprovalTravelAd } from "../Components/TravelAdvanceApproval/AllApprovalTravelAd";
import { RejectedApprovalTravelAd } from "../Components/TravelAdvanceApproval/RejectedApprovalTravelAd";
import { HoldApprovalTravelAd } from "../Components/TravelAdvanceApproval/HoldApprovalTravelAd";
import { ApprovedApprovalTravelAd } from "../Components/TravelAdvanceApproval/ApprovedApprovalTravelAd";
import { useEffect } from "react";

const ApprovalTravelAdvance = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Travel Advance`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Hold
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalTravelAd
            title={"Travel Advance"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <ApprovedApprovalTravelAd
          title={"Travel Advance"}
          getApprovalBadge={getApprovalBadge}
        />
      )}
      {tabIndex === 2 && (
        <>
          <HoldApprovalTravelAd
            title={"Travel Advance"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <RejectedApprovalTravelAd
            title={"Travel Advance"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}

      {tabIndex === 4 && (
        <>
          <AllApprovalTravelAd
            title={"Travel Advance"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalTravelAdvance };
