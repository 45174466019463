import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nav: true,
}

const NavSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
        HideNav: state => {
            state.nav = false;
        },
        ShowNav: state => {
            state.nav = true;
        },
    },
})
export const { HideNav, ShowNav } = NavSlice.actions;
export default NavSlice.reducer;