import React, { useCallback, useState } from "react";
import "./Connect.css";
import SocialEventTag from "../../../../../Assets/SocialEventTag.svg";
import SocialEventCalenderIcon from "../../../../../Assets/SocialEventCalenderIcon.svg";
import SocialEventOnlineLinkIcon from "../../../../../Assets/SocialEventOnlineLinkIcon.svg";
import SocialEventAttendColorIcon from "../../../../../Assets/SocialEventAttendColorIcon.svg";
import SocialEventAttendWhiteIcon from "../../../../../Assets/SocialEventAttendWhiteIcon.svg";
import SocialEventAttendGreyIcon from "../../../../../Assets/SocialEventAttendGreyIcon.svg";
import SocialEventNotAttendGreyIcon from "../../../../../Assets/SocialEventNotAttendGreyIcon.svg";
import SocialEventNotSureGreyIcon from "../../../../../Assets/SocialEventNotSureGreyIcon.svg";
import SocialEventNotAttendWhiteIcon from "../../../../../Assets/SocialEventNotAttendWhiteIcon.svg";
import SocialEventHeaderIcon from "../../../../../Assets/SocialEventHeaderIcon.svg";
import SocialLocationIcon from "../../../../../Assets/SocialLocationIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";
import SocialNewsLinkLineIcon from "../../../../../Assets/SocialNewsLinkLineIcon.svg";
import SocialEventNotAttendColorIcon from "../../../../../Assets/SocialEventNotAttendColorIcon.svg";
import SocialEventNotSureWhiteIcon from "../../../../../Assets/SocialEventNotSureWhiteIcon.svg";
import SocialEventNotSureColorIcon from "../../../../../Assets/SocialEventNotSureColorIcon.svg";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostViewsEyeColorIcon from "../../../../../Assets/SocialPostViewsEyeColorIcon.svg";
import SocialPostNotFoundIcon from "../../../../../Assets/SocialPostNotFoundIcon.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { IconButton } from "@mui/material";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import dateFormat from "dateformat";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import HeaderComponent from "./HeaderComponent";
import EventAttenderDetails from "./EventAttenderDetails";
import ShowViewsList from "./ShowViewsList";
import EventPostDetails from "./EventPostDetails";
import { getFormattedCount } from "../../../../../Utilities/socialAllFunctions";

const Events = ({ unreadPostCount, setReRefreshProp, reRefresh }) => {
  const [eventData, setEventData] = useState([]);
  const [isLinkHovered, setIsLinkHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewsHovered, setIsViewsHovered] = useState(false);
  const navigate = useNavigate();
  const [isAttendBtnShown, setIsAttendBtnShown] = useState(false);
  const [isNotAttendBtnShown, setIsNotAttendBtnShown] = useState(false);
  const [isMayAttendBtnShown, setIsMayAttendBtnShown] = useState(false);
  const [dataFound, setDataFound] = useState(true);
  const [viewsPostId, setViewsPostId] = useState(0);
  const [viewsListActive, setViewsListActive] = useState(false);
  const [eventAttenderDetailsActive, setEventAttenderDetailsActive] =
    useState(false);
  const [eventAttenderData, setEventAttenderData] = useState();
  const dispatch = useDispatch();
  const [postDataCount, setPostDataCount] = useState(-1);
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && eventData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, eventData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  const myFunction = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => { })
      .catch((error) => { });
    dispatch(
      showToast({
        text: "copied to clipboard!",
        severity: "success",
      })
    );
  };

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || eventData?.length < postDataCount)
    ) {
      if (!reRefresh) {
        getData();
      }
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset]);

  useEffect(() => {
    setOffset(0);
  }, []);

  const eventAction = async (paramData, action) => {
    try {
      let result = null;

      if (action === "attend") {
        result = await getRequest(
          `SocialPost/Attend/${paramData?.item?.event?.id}`
        );
      } else if (action === "notAttend") {
        result = await getRequest(
          `SocialPost/NotAttend/${paramData?.item?.event?.id}`
        );
      } else if (action === "mayAttend") {
        result = await getRequest(
          `SocialPost/MayAttend/${paramData?.item?.event?.id}`
        );
      }
      getData(false, true);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured ",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getData = async (freshLoad = false, reload = false) => {
    setLoading(true);
    try {
      const result = await postRequest(`SocialPost/AllEventPostList`, {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: reload
            ? eventData?.length
            : postDataCount === -1 || offset + 2 <= postDataCount || freshLoad
              ? 2
              : postDataCount - offset,
          iDisplayStart: freshLoad || reload ? 0 : offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        setEventData([]);
      } else {
        setPostDataCount(result?.data?.summary?.allCount);
        setTotalOffset(offset);
        setCurrentIndex((currentIndex) => currentIndex + 2);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
        if (reload) {
          setEventData(result?.data?.data);
        }
        if (freshLoad) {
          document.querySelector(
            ".homeEngageMainCenterContainer"
          ).scrollTop = 0;
          setEventData(result?.data?.data);
          setOffset(0);
          setTotalOffset(0);
        }
        if (!freshLoad && !reload) {
          setEventData([...eventData, ...result?.data?.data]);
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      setReRefreshProp();
    }
  };
  useEffect(() => {
    if (reRefresh === true) {
      getData(true, false);
    }
  }, [reRefresh]);
  return (
    <>
      <div className="engageMainContentContainer" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <div className="greetingEngageHeaderContainer">
          <HeaderComponent iconSize={100} icon={SocialEventHeaderIcon} title={"Event"} />
        </div>
        <div className="homeEngageMainCenterContainer">
          <div className="homeEngagePostContainer">
            {eventData.map((d, i) => {
              return (
                <div
                  className="socialAllPostContainer"
                  key={"social-item-" + i}
                >
                  <div className="engagePostContentHolder">
                    <div>
                      <div className="postHeaderContainer">
                        <div className="leftPostHeaderContainer">
                          {/* <img
                              src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Company/${LoggedInUser?.companyCode}/Logo/${LoggedInUser?.companyLogo}`}
                              alt="Profile"
                            /> */}
                          <div className="socialPostTitleAndTimeContainer">
                            <label>{d?.item?.event?.title ?? "--"}</label>
                            <label>
                              <div className="announcementViewsContainer">
                                <span className="postHrTime">
                                  {" "}
                                  •{" "}
                                  {dateFormat(
                                    d?.item?.createdOn,
                                    "hh:MM TT | dd mmm yyyy"
                                  )}
                                </span>
                                <article
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setViewsListActive(true);
                                    setViewsPostId(d?.item.id);
                                  }}
                                  onMouseOver={() => setIsViewsHovered(d?.id)}
                                  onMouseLeave={() => setIsViewsHovered(null)}
                                >
                                  <img
                                    src={
                                      viewsHovered === d?.id
                                        ? SocialPostViewsEyeColorIcon
                                        : SocialPostViewsEyeIcon
                                    }
                                    alt=""
                                  />
                                  <span
                                    style={{ marginLeft: "5px" }}
                                    className={
                                      viewsHovered === d?.id
                                        ? "postColorViews"
                                        : `socialViewsSpanContainer`
                                    }
                                  >
                                    {d?.item?.views < 10
                                      ? "0" + d?.item?.views
                                      : getFormattedCount(d?.item?.views)}{" "}
                                    Views
                                  </span>
                                </article>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="eventTypeContainer">
                            <span>Event</span>
                            <article></article>
                            <img src={SocialEventTag} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="eventpostBodyContainer">
                        <div className="newPostBodyImgMainWrapper">
                          {d?.item?.attachment && (
                            <div
                              className="newPostBodyImgContainer"
                              onClick={() => {
                                navigate("details/" + d?.item?.id);
                              }}
                              style={{ marginTop: "20px" }}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  socialPostImages +
                                  d?.item?.attachment
                                }
                                alt=""
                                style={{
                                  padding: "0 20px",
                                  borderRadius: "20px",
                                  width: "auto",
                                  height: "auto",
                                  maxHeight: "350px",
                                  maxWidth: "640px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="eventBodyTextContainer">
                          <div className="eventBodyTextWrapper">
                            <div className="eventLinContainer">
                              <div className="eventDateContainer">
                                <div className="eventDateWrapper">
                                  <img src={SocialEventCalenderIcon} alt="" />
                                  <span>
                                    {dateFormat(
                                      d?.item?.event?.startedOn,
                                      "dd mmm, yyyy"
                                    )}
                                    , {d?.item?.event?.eventStartTime}
                                  </span>
                                </div>
                              </div>
                              <div className="eventLinkWrapper">
                                <div className="newsLink">
                                  <img
                                    src={
                                      d?.item?.event?.eventMood?.toLowerCase() ===
                                        "offline"
                                        ? SocialLocationIcon
                                        : SocialEventOnlineLinkIcon
                                    }
                                    alt=""
                                  />
                                  {d?.item?.event?.eventMood?.toLowerCase() ===
                                    "online" &&
                                    d?.item?.event?.link !== null && (
                                      <a
                                        href={d?.item?.event?.link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <span id="myInput">
                                          {d?.item?.event?.link?.length > 30
                                            ? d?.item?.event?.link?.substring(
                                              0,
                                              30
                                            ) + "..."
                                            : d?.item?.event?.link}
                                        </span>
                                      </a>
                                    )}
                                  {d?.item?.event?.eventMood === "offline" &&
                                    d?.item?.event?.link === null && (
                                      <span id="myInput">
                                        {d?.item?.event?.vanue}
                                      </span>
                                    )}
                                </div>
                                <div
                                  onMouseOver={() => setIsLinkHovered(d?.id)}
                                  onMouseLeave={() => setIsLinkHovered(null)}
                                  className="copyNewsLinkWrapper"
                                >
                                  <span>Copy</span>
                                  {isLinkHovered === d?.id && (
                                    <img
                                      onClick={() => {
                                        if (
                                          d?.item?.event?.eventMood?.toLowerCase() ===
                                          "offline"
                                        ) {
                                          myFunction(d?.item?.event?.vanue);
                                        } else if (
                                          d?.item?.event?.eventMood?.toLowerCase() ===
                                          "online"
                                        ) {
                                          myFunction(d?.item?.event?.link);
                                        }
                                      }}
                                      style={{ width: "35px" }}
                                      src={SocialNewsEndLinkIcon}
                                      alt=""
                                    />
                                  )}
                                  {isLinkHovered !== d?.id && (
                                    <img
                                      style={{ width: "35px" }}
                                      src={SocialNewsLinkLineIcon}
                                      alt=""
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* )} */}
                            <div
                              className="eventAttendContainer"
                              onClick={() => {
                                setEventAttenderDetailsActive(true);
                                setEventAttenderData(d?.item?.event);
                              }}
                            >
                              <div className="eventAttendTextContainer">
                                <div className="eventAttenders">
                                  <article>Attend</article>
                                  <span>
                                    {d?.item?.event?.attender?.length}
                                  </span>
                                </div>
                                <div className="eventVerticleLine"></div>
                                <div className="eventNotAttenders">
                                  <article>Not Attend</article>
                                  <span>
                                    {d?.item?.event?.notattender?.length}
                                  </span>
                                </div>
                                <div className="eventVerticleLine"></div>
                                <div className="eventMayAttenders">
                                  <article>May Attend</article>
                                  <span>
                                    {d?.item?.event?.mayattender?.length}
                                  </span>
                                </div>
                              </div>
                              <div className="eventAttendImgContainer">
                                <div className="eventAttendImg">
                                  {d?.item?.event?.attender?.length === 1 && (
                                    <div className="eventAttendImgList">
                                      <img
                                        src={
                                          d?.item?.event?.attender[0]?.image
                                            ? process.env.REACT_APP_.BASE_URL +
                                            profileImages +
                                            d?.item?.event?.attender[0]?.image
                                            : ProfileAvatar
                                        }
                                        alt=""
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                    </div>
                                  )}
                                  {d?.item?.event?.attender?.length === 2 && (
                                    <div className="eventAttendImgList">
                                      <img
                                        src={
                                          d?.item?.event?.attender[0]?.image
                                            ? process.env.REACT_APP_.BASE_URL +
                                            profileImages +
                                            d?.item?.event?.attender[0]?.image
                                            : ProfileAvatar
                                        }
                                        alt=""
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                      <img
                                        src={
                                          d?.item?.event?.attender[1]?.image
                                            ? process.env.REACT_APP_.BASE_URL +
                                            profileImages +
                                            d?.item?.event?.attender[1]?.image
                                            : ProfileAvatar
                                        }
                                        alt=""
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                    </div>
                                  )}
                                  {d?.item?.event?.attender?.length > 2 && (
                                    <div className="eventAttendImgList">
                                      <img
                                        src={
                                          d?.item?.event?.attender[0]?.image
                                            ? process.env.REACT_APP_.BASE_URL +
                                            profileImages +
                                            d?.item?.event?.attender[0]?.image
                                            : ProfileAvatar
                                        }
                                        alt=""
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                      <img
                                        src={
                                          d?.item?.event?.attender[1]?.image
                                            ? process.env.REACT_APP_.BASE_URL +
                                            profileImages +
                                            d?.item?.event?.attender[1]?.image
                                            : ProfileAvatar
                                        }
                                        alt=""
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                      <img
                                        src={
                                          d?.item?.event?.attender[2]?.image
                                            ? process.env.REACT_APP_.BASE_URL +
                                            profileImages +
                                            d?.item?.event?.attender[2]?.image
                                            : ProfileAvatar
                                        }
                                        alt=""
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="eventAttendCountContainer">
                                  {d?.item?.event?.attender?.length +
                                    d?.item?.event?.notattender?.length +
                                    d?.item?.event?.mayattender?.length >
                                    3 && (
                                      <span>
                                        +
                                        {d?.item?.event?.attender?.length +
                                          d?.item?.event?.notattender?.length +
                                          d?.item?.event?.mayattender?.length -
                                          3}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {d?.actions?.length > 0 && ( */}
                        <div className="eventStatusWrapper">
                          <div className="eventButtons">
                            {d?.item?.event?.respond?.length > 0 &&
                              d?.item?.event?.respond[0]?.isAttending && (
                                <div className="eventDisabledBtn">
                                  <img
                                    src={SocialEventAttendGreyIcon}
                                    alt=""
                                    className="disabledImg"
                                  />
                                  Will Attend
                                </div>
                              )}
                            {((d?.item?.event?.respond?.length > 0 &&
                              !d?.item?.event?.respond[0]?.isAttending) ||
                              d?.item?.event?.respond?.length <= 0) && (
                                <div
                                  onMouseEnter={() => setIsAttendBtnShown(true)}
                                  onMouseLeave={() => setIsAttendBtnShown(false)}
                                  className="attendBtn"
                                  onClick={() => {
                                    eventAction(d, "attend");
                                  }}
                                >
                                  {isAttendBtnShown && (
                                    <img
                                      src={SocialEventAttendWhiteIcon}
                                      alt=""
                                      className="normalImage"
                                    />
                                  )}
                                  {!isAttendBtnShown && (
                                    <img
                                      src={SocialEventAttendColorIcon}
                                      alt=""
                                      className="normalImage"
                                    />
                                  )}{" "}
                                  Will Attend
                                </div>
                              )}
                            {d?.item?.event?.respond.length > 0 &&
                              d?.item?.event?.respond[0]?.notAttending && (
                                <div className="eventDisabledBtn">
                                  <img
                                    src={SocialEventNotAttendGreyIcon}
                                    alt=""
                                    className="disabledImg"
                                  />
                                  Not Attending
                                </div>
                              )}
                            {((d?.item?.event?.respond?.length > 0 &&
                              !d?.item?.event?.respond[0]?.notAttending) ||
                              d?.item?.event?.respond?.length <= 0) && (
                                <div
                                  onMouseEnter={() =>
                                    setIsNotAttendBtnShown(true)
                                  }
                                  onMouseLeave={() =>
                                    setIsNotAttendBtnShown(false)
                                  }
                                  className="notAttendBtn"
                                  onClick={() => {
                                    eventAction(d, "notAttend");
                                  }}
                                >
                                  {isNotAttendBtnShown && (
                                    <img
                                      src={SocialEventNotAttendWhiteIcon}
                                      alt=""
                                      className="normalImage"
                                    />
                                  )}
                                  {!isNotAttendBtnShown && (
                                    <img
                                      src={SocialEventNotAttendColorIcon}
                                      alt=""
                                      className="normalImage"
                                    />
                                  )}
                                  Not Attending
                                </div>
                              )}
                            {d?.item?.event?.respond.length > 0 &&
                              d?.item?.event?.respond[0]?.mayAttending && (
                                <div className="eventDisabledBtn">
                                  <img
                                    src={SocialEventNotSureGreyIcon}
                                    alt=""
                                    className="disabledImg"
                                  />
                                  Not Sure
                                </div>
                              )}
                            {((d?.item?.event?.respond?.length > 0 &&
                              !d?.item?.event?.respond[0]?.mayAttending) ||
                              d?.item?.event?.respond?.length <= 0) && (
                                <div
                                  onMouseEnter={() =>
                                    setIsMayAttendBtnShown(true)
                                  }
                                  onMouseLeave={() =>
                                    setIsMayAttendBtnShown(false)
                                  }
                                  className="mayAttentBtn"
                                  onClick={() => {
                                    eventAction(d, "mayAttend");
                                  }}
                                >
                                  {isMayAttendBtnShown && (
                                    <img
                                      src={SocialEventNotSureWhiteIcon}
                                      alt=""
                                      className="normalImage"
                                    />
                                  )}
                                  {!isMayAttendBtnShown && (
                                    <img
                                      src={SocialEventNotSureColorIcon}
                                      alt=""
                                      className="normalImage"
                                    />
                                  )}{" "}
                                  Not Sure
                                </div>
                              )}
                          </div>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {!dataFound && eventData?.length <= 0 && (
              <div className="noPostfoundContainer">
                <div className="noPostfoundWrapper">
                  <img src={SocialPostNotFoundIcon} alt="" />
                  <h5>No Post Found</h5>
                  <p>No one has posted something in social</p>
                </div>
              </div>
            )}
          </div>
          {loading && <Loader />}
        </div>
      </div>
      {eventAttenderDetailsActive && (
        <EventAttenderDetails
          setEventAttenderDetailsActive={setEventAttenderDetailsActive}
          eventAttenderData={eventAttenderData}
        />
      )}
      {viewsListActive && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"Event"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
      <Routes>
        <Route path="details/:postid*" element={<EventPostDetails />} />
      </Routes>
    </>
  );
};
export { Events };
