/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";

import { useDeferredValue } from "react";
import { useMemo } from "react";
import { format } from "date-fns";
import backbuttonpic from "../../Assets/backbuttonpic.svg";
import { isLoading, showToast } from "../../Features";
import { getRequest, postRequest } from "../../Services/axios";
import OutsideClickHandler from "../../Components/OutsideClickHandler/OutsideClickHandler";
import { SelectCustom } from "../../Components/FormComponent/SelectCustom";
import { DatePickerCustom } from "../../Components/FormComponent/DatePickerCustom";
import { DateRangePicker } from "../../Components/FormComponent/DateRangePicker";
import { Loader } from "../../Components/FormComponent/Loader";
import { InputCustom } from "../../Components/FormComponent/InputCustom";
const PMSsubmitAppraisalTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.item?.appraisalCycleId;
  const selectedListId = location?.state?.item?.id;
  const selectedDepartmentId = location?.state?.item?.departmentId;
  const percentageChange = location?.state?.percentageChange;
  console.log("object location?.state", location?.state);
  const status = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Ticket Booked", value: "Ticket Booked" },
    { label: "Ticket Selected", value: "Ticket Selected" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Closed", value: "Closed" },
  ];

  const [loading, setLoading] = useState(true);
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [dateOfJoning, setDateOfJoining] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedMode, setSelectedMode] = useState([]);
  const [selectedRequestType, setSelectedRequestType] = useState([]);
  const [listSubmitAppraisal, setListSubmitAppraisal] = useState([
    {
      id: "",
      appraisalPercentage: "",
      appraisalAmount: "",
      updatedMonthlyCtc: "",
      monthlyCtc: "",
      isSubmit: true,
    },
  ]);

  const [listData, setListData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);

  const formatNumber = (num) => {
    const number = Number(Number(num)?.toFixed(1));
    if (number % 1 === 0) {
      return number?.toFixed(0);
    }
    return number?.toFixed(1);
  };
  console.log("object selectedRows", selectedRows);
  useEffect(() => {
    document.title = `PeopleSol - Submit Appraisal`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const SubmitUpdatedCtc = async (id, amount) => {
    try {
      await getRequest(
        `PMSAppraisalNew/Submit?Id=${id}&PurposedFinalSalary=${amount * 12}`
      );

      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setSelectedRows([]);
      // getListData();
    }
  };
  const SubmitAllUpdatedCtc = async (arr, selectedArr) => {
    const resultArr = arr
      ?.filter((it) => selectedArr?.includes(it?.id))
      ?.map((item) => ({
        Id: item?.id,
        PurposedFinalSalary: Number(
          Number(item?.updatedMonthlyCtc)?.toFixed(0)
        ),
      }));
    try {
      const result = await postRequest(`PMSAppraisalNew/SubmitAll`, resultArr);

      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setSelectedRows([]);
      // getListData();
    }
  };

  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    AppraisalCycleID: selectedId ?? selectedListId,
    DepartmentID: selectedDepartmentId,
    dataTableRequest: {
      iDisplayLength: 100000,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);

  const getCheckStatus = (data) => {
    if (
      data?.status !== "Cancelled" &&
      data?.status !== "Rejected" &&
      data?.status !== "Released" &&
      data?.status !== "Closed" &&
      data?.status !== "Resolved" &&
      data?.status !== "Ticket Booked"
    ) {
      return true;
    }
    return false;
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let result;
      if (selectedDepartmentId) {
        result = await postRequest(
          "PMSAppraisalNew/DepartmentAppraisalDetailList",
          reqData
        );
      } else {
        result = await postRequest(
          "PMSAppraisalNew/TeamAppraisalDetailList",
          reqData
        );
      }
      if (result?.data) {
        if (result?.data?.data) {
          setListData(result?.data?.data);
          setSummaryData(result?.data?.summary);
          if (selectedDepartmentId) {
            setListSubmitAppraisal(() => {
              let arr = result?.data?.data?.map((it, index) => ({
                id: it?.item?.id,
                monthlyCtc: it?.item?.monthlyCTC
                  ? Number(it?.item?.monthlyCTC)?.toFixed(1)
                  : "",
                appraisalPercentage: it?.item?.appraisalPercentage
                  ? Number(
                      Number(it?.item?.appraisalPercentage) +
                        Number(percentageChange)
                    )?.toFixed(1)
                  : "",
                appraisalAmount: formatNumber(
                  (Number(
                    Number(it?.item?.appraisalPercentage) +
                      Number(percentageChange)
                  ) /
                    100) *
                    Number(it?.item?.monthlyCTC)
                ),
                updatedMonthlyCtc: formatNumber(
                  (Number(
                    Number(it?.item?.appraisalPercentage) +
                      Number(percentageChange)
                  ) /
                    100) *
                    Number(it?.item?.monthlyCTC) +
                    Number(it?.item?.monthlyCTC)
                ),
                isSubmit: true,
              }));
              return arr;
            });
          } else {
            setListSubmitAppraisal(() => {
              let arr = result?.data?.data?.map((it, index) => ({
                id: it?.item?.id,
                monthlyCtc: it?.item?.monthlyCTC
                  ? Number(it?.item?.monthlyCTC)?.toFixed(1)
                  : "",
                appraisalPercentage: it?.item?.appraisalPercentage
                  ? Number(it?.item?.appraisalPercentage)?.toFixed(1)
                  : "",
                appraisalAmount: it?.item?.appraisalAmmount
                  ? Number(it?.item?.appraisalAmmount)?.toFixed(1)
                  : "",
                updatedMonthlyCtc: it?.item?.updatedMonthlyCTC
                  ? Number(it?.item?.updatedMonthlyCTC)?.toFixed(1)
                  : "",
                isSubmit: true,
              }));
              return arr;
            });
          }
        } else {
          setListData([]);
        }
      } else {
        setListData([]);
      }
    } catch (error) {
      setListData([]);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      location.pathname.toLowerCase().includes("teamappraisal/submitappraisal")
    )
      getListData();
  }, [location.pathname, reqData]);
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData?.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);

  const applyFilters = () => {
    setReqData({
      ...reqData,
      status: selectedStatusList[0]?.value,
      type: selectedRequestType[0]?.value,
      mode: selectedMode[0]?.value,
      date: dateOfJoning
        ? `${format(dateOfJoning, "dd/MM/yyyy")} ${format(
            dateOfJoning,
            "dd/MM/yyyy"
          )}`
        : null,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setDateOfJoining(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setSelectedStatusList([]);
    setSelectedMode([]);
    setSelectedRequestType([]);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      date: null,
      status: null,
      type: null,
      mode: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
    } else {
      const arr = listSubmitAppraisal
        ?.filter((it) => it?.isSubmit)
        ?.map((item) => item?.id ?? item?.Id);

      setSelectedRows(arr);
    }
  };

  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      let result;
      if (selectedDepartmentId) {
        result = await postRequest(
          "PMSAppraisalNew/DepartmentAppraisalDetailList",
          downloadReqData
        );
      } else {
        result = await postRequest(
          "PMSAppraisalNew/TeamAppraisalDetailList",
          downloadReqData
        );
      }
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  return (
    <div className="pmsactionconfirm">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton onClick={() => setFilterSearchToogle((prev) => !prev)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p> Status </p>
              <SelectCustom
                options={status}
                values={selectedStatusList}
                onChange={(value) => {
                  setSelectedStatusList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Travel Date</p>
              <DatePickerCustom
                selected={dateOfJoning}
                onChange={(date) => setDateOfJoining(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div
        className="tableHeaderHEad"
        style={{ justifyContent: "space-between", margin: "10px 0px" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ margin: "0px 10px 0px 20px" }}>
            <img
              src={backbuttonpic}
              alt=""
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </div>
          <div style={{ margin: "0px 10px", fontSize: "16px" }}>
            {listData[0]?.item?.name}
          </div>
        </div>
        <div>
          <div>
            {/* <button
              className="btnSelf"
              style={{ marginRight: "10px" }}
              // onClick={togglePopup}
              type="button"
            >
              Suggested Allow Slab
            </button> */}
          </div>
        </div>
      </div>
      <div
        className="tableHeaderContainer"
        style={{ alignItems: "end", height: "auto", marginBottom: "10px" }}
        // style={finalConfirm ? { padding: "0 3px 0 12px" } : {}}
      >
        {" "}
        <div className="topDiv" style={{ margin: "0px" }}>
          <div
            className="topDivPad"
            style={{ justifyContent: "start", gap: "25px" }}
          >
            <div className="topDivJoiningCTC">
              <div className="topDivJoiningCTCdiv">
                <div className="topDivJoiningCTCdivHeading">
                  Total
                  <br />
                  Employee
                </div>
                <div className="topDivJoiningCTCdivAmount">
                  {formatNumber(summaryData?.totalEmployee) ?? 0}
                </div>
              </div>
              <div className="topDivJoiningCTCdiv">
                <div className="topDivJoiningCTCdivHeading">
                  Increment
                  <br />
                  Completed{" "}
                </div>
                <div className="topDivJoiningCTCdivAmount">
                  {formatNumber(summaryData?.incrementCompleted) ?? 0}
                </div>
              </div>
              <div className="topDivJoiningCTCdiv">
                <div className="topDivJoiningCTCdivHeading">
                  Increment
                  <br />
                  Pending
                </div>
                <div className="topDivJoiningCTCdivAmount">
                  {formatNumber(summaryData?.incrementPending) ?? 0}
                </div>
              </div>
            </div>
            <div
              className="topDivJoiningDate"
              style={{ backgroundColor: "#E7F8EC" }}
              // style={{ width: "237px", justifyContent: "start" }}
            >
              <div className="topDivJoiningDatediv">
                <div className="topDivJoiningCTCdivHeading">Proposed %</div>
                <div className="topDivJoiningCTCdivAmount">
                  {formatNumber(summaryData?.proposePercentage) ?? 0}%
                </div>
              </div>
              <div className="topDivJoiningDatediv">
                <div className="topDivJoiningCTCdivHeading">
                  Proposed
                  <br />
                  Amount
                </div>
                <div
                  className="topDivJoiningCTCdivAmount"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {formatNumber(summaryData?.proposedAmmount) ?? 0}
                </div>
              </div>
            </div>
            <div
              className="topDivJoiningDate"
              // style={{ width: "237px", justifyContent: "start" }}
            >
              <div className="topDivJoiningDatediv">
                <div className="topDivJoiningCTCdivHeading">
                  Current
                  <br />
                  CTC
                </div>
                <div className="topDivJoiningCTCdivAmount">
                  {formatNumber(summaryData?.currentCTC) ?? 0}
                </div>
              </div>
              <div className="topDivJoiningDatediv">
                <div className="topDivJoiningCTCdivHeading">
                  Updated
                  <br />
                  CTC
                </div>
                <div
                  className="topDivJoiningCTCdivAmount"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {formatNumber(summaryData?.updatedCTC) ?? 0}
                </div>
              </div>
            </div>
            <div className="topDivTotalGrowth">
              <div className="topDivTotalGrowthdiv">
                <div className="topDivJoiningCTCdivHeading">Increment %</div>
                <div className="topDivJoiningCTCdivAmount">
                  {" "}
                  {formatNumber(
                    ((summaryData?.updatedCTC?.toFixed(0) -
                      summaryData?.currentCTC?.toFixed(0)) /
                      summaryData?.currentCTC?.toFixed(0)) *
                      100
                  ) ?? 0}
                  %
                </div>
              </div>
              <div className="topDivTotalGrowthdiv">
                <div className="topDivJoiningCTCdivHeading">
                  Average
                  <br />
                  Rating
                </div>
                <div className="topDivJoiningCTCdivAmount">
                  {formatNumber(
                    listData?.reduce((acc, item) => {
                      return acc + Number(item?.item?.fhodRating);
                    }, 0) / listData?.length
                  )}
                  /5
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-box">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      </div>
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div
              style={{
                overflowY: "auto",
                height: "596px",
                position: "relative",
                borderBottom: "1px solid #E6E6E6",
              }}
            >
              <table className="submitAppraisalTable">
                <thead
                  style={{
                    position: "sticky",
                    top: "0px",
                    backgroundColor: "white",
                    zIndex: "2",
                  }}
                >
                  <tr>
                    <td style={{ width: "50px" }}>
                      {listSubmitAppraisal?.some(
                        (it) => it?.isSubmit === true
                      ) && (
                        <IconButton onClick={() => selectAll()}>
                          {selectedRows?.length === listData?.length ? (
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.75"
                                y="0.75"
                                width="16.8417"
                                height="16.5"
                                rx="4.25"
                                stroke="#1296B0"
                                stroke-width="1.5"
                              />
                              <path
                                d="M4 9L7 12L14 6"
                                stroke="#1296B0"
                                stroke-width="1.5"
                                strokeLinecap="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.75"
                                y="1.48941"
                                width="16.8417"
                                height="16.5"
                                rx="4.25"
                                stroke="#BFBFBF"
                                strokeWidth="1.5"
                              />
                            </svg>
                          )}
                        </IconButton>
                      )}
                    </td>
                    <td style={{ width: "75px" }}>
                      <b> Sl. No</b>
                    </td>
                    <td>
                      <b>Employee Name</b>
                    </td>
                    <td style={{ width: "150px", textAlign: "center" }}>
                      <b>Monthly CTC</b>
                    </td>
                    <td style={{ width: "150px", textAlign: "center" }}>
                      <b>Rating</b>
                    </td>
                    <td style={{ width: "150px", textAlign: "center" }}>
                      <b>Appraisal %</b>
                    </td>
                    <td style={{ width: "150px", textAlign: "center" }}>
                      <b>Amount</b>
                    </td>
                    <td style={{ width: "150px", textAlign: "center" }}>
                      <b>
                        Updated <br /> Monthly CTC
                      </b>
                    </td>
                    <td style={{ width: "175px" }}></td>
                  </tr>
                </thead>
                <tbody>
                  {Array?.isArray(listData) &&
                    listData?.map((item, ind) => (
                      <tr>
                        <td>
                          {listSubmitAppraisal[ind]?.isSubmit && (
                            <IconButton
                              onClick={() =>
                                addToSelectedRows(
                                  item?.item?.id ?? item?.item?.Id
                                )
                              }
                            >
                              {selectedRows?.includes(
                                item?.item?.id ?? item?.item?.Id
                              ) ? (
                                <svg
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.75"
                                    y="0.75"
                                    width="16.8417"
                                    height="16.5"
                                    rx="4.25"
                                    stroke="#1296B0"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    d="M4 9L7 12L14 6"
                                    stroke="#1296B0"
                                    stroke-width="1.5"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.75"
                                    y="1.48941"
                                    width="16.8417"
                                    height="16.5"
                                    rx="4.25"
                                    stroke="#BFBFBF"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              )}
                            </IconButton>
                          )}
                        </td>
                        <td>{ind + 1}</td>
                        <td>{item?.item?.nameWithPersonNumber}</td>
                        <td style={{ textAlign: "center" }}>
                          {item?.item?.monthlyCTC}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {formatNumber(item?.item?.fhodRating)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {listSubmitAppraisal[ind]?.isSubmit ? (
                            <InputCustom
                              name={`listSubmitAppraisal[${ind}].appraisalPercentage`}
                              type={"number"}
                              style={{
                                // backgroundColor: "#F8F8F8",
                                borderRadius: "8px",
                                border: "0.75px solid #BFBFBF",
                              }}
                              classStyle="classStyleSubmitAppraisal"
                              formikWidth="100%"
                              value={
                                Array?.isArray(listSubmitAppraisal)
                                  ? listSubmitAppraisal[ind]
                                      ?.appraisalPercentage
                                  : ""
                              }
                              onChange={(e) => {
                                setListSubmitAppraisal((prev) => {
                                  console.log(" prev ", prev);
                                  const arr = prev?.map((item, index) => {
                                    if (index === ind) {
                                      return {
                                        ...item,
                                        appraisalPercentage: Number(
                                          e?.target?.value
                                        ),
                                        appraisalAmount: formatNumber(
                                          (Number(e?.target?.value) / 100) *
                                            Number(prev[ind]?.monthlyCtc)
                                        ),
                                        updatedMonthlyCtc: formatNumber(
                                          (Number(e?.target?.value) / 100) *
                                            Number(prev[ind]?.monthlyCtc) +
                                            Number(prev[ind]?.monthlyCtc)
                                        ),
                                      };
                                    } else return item;
                                  });
                                  return arr;
                                });
                              }}
                            />
                          ) : Array?.isArray(listSubmitAppraisal) ? (
                            listSubmitAppraisal[ind]?.appraisalPercentage
                          ) : (
                            ""
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {listSubmitAppraisal[ind]?.isSubmit ? (
                            <InputCustom
                              name={`listSubmitAppraisal[${ind}].appraisalAmount`}
                              type={"number"}
                              classStyle="classStyleSubmitAppraisal"
                              style={{
                                // backgroundColor: "#F8F8F8",
                                borderRadius: "8px",
                                border: "0.75px solid #BFBFBF",
                              }}
                              formikWidth="100%"
                              value={
                                Array?.isArray(listSubmitAppraisal)
                                  ? listSubmitAppraisal[ind]?.appraisalAmount
                                  : ""
                              }
                              onChange={(e) => {
                                setListSubmitAppraisal((prev) => {
                                  const arr = prev?.map((item, index) => {
                                    if (index === ind) {
                                      return {
                                        ...item,
                                        appraisalPercentage: formatNumber(
                                          Number(
                                            (Number(e?.target?.value) /
                                              Number(prev[ind]?.monthlyCtc)) *
                                              100
                                          )
                                        ),
                                        appraisalAmount: Number(
                                          e?.target?.value
                                        ),
                                        updatedMonthlyCtc: formatNumber(
                                          Number(
                                            Number(prev[ind]?.monthlyCtc) +
                                              Number(e?.target?.value)
                                          )
                                        ),
                                      };
                                    } else return item;
                                  });
                                  return arr;
                                });
                              }}
                            />
                          ) : Array?.isArray(listSubmitAppraisal) ? (
                            listSubmitAppraisal[ind].appraisalAmount
                          ) : (
                            ""
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {listSubmitAppraisal[ind].updatedMonthlyCtc}
                        </td>
                        <td style={{}}>
                          {listSubmitAppraisal[ind]?.isSubmit ? (
                            <div
                              style={{
                                margin: "auto",
                                color: "white",
                                backgroundColor: "#1296B0",
                                textAlign: "center",
                                padding: "6px 25px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                width: "fit-content",
                              }}
                              onClick={() => {
                                setListSubmitAppraisal((prev) => {
                                  console.log(" prev ", prev);
                                  const arr = prev?.map((item, index) => {
                                    if (index === ind) {
                                      return {
                                        ...item,
                                        isSubmit: !prev[ind]?.isSubmit,
                                      };
                                    } else return item;
                                  });
                                  return arr;
                                });
                                SubmitUpdatedCtc(
                                  listSubmitAppraisal[ind]?.id,
                                  listSubmitAppraisal[ind]?.updatedMonthlyCtc
                                );
                              }}
                            >
                              Submit
                            </div>
                          ) : (
                            <div
                              style={{
                                margin: "auto",
                                color: "white",
                                backgroundColor: "#1296B0",
                                textAlign: "center",
                                padding: "6px 25px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                width: "fit-content",
                              }}
                              onClick={() => {
                                setListSubmitAppraisal((prev) => {
                                  console.log(" prev ", prev);
                                  const arr = prev?.map((item, index) => {
                                    if (index === ind) {
                                      return {
                                        ...item,
                                        isSubmit: !prev[ind]?.isSubmit,
                                      };
                                    } else return item;
                                  });
                                  return arr;
                                });
                              }}
                            >
                              Update
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div
                style={
                  selectedRows?.length > 0
                    ? {
                        color: "white",
                        margin: "20px 20px",
                        backgroundColor: "#1296B0",
                        textAlign: "center",
                        padding: "6px 25px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        width: "fit-content",
                      }
                    : {
                        color: "white",
                        margin: "20px 20px",
                        backgroundColor: "#BFBFBF",
                        textAlign: "center",
                        padding: "6px 25px",
                        borderRadius: "4px",
                        cursor: "disabled",
                        width: "fit-content",
                      }
                }
                onClick={() => {
                  if (selectedRows?.length > 0) {
                    let arr;
                    setListSubmitAppraisal((prev) => {
                      arr = prev?.map((item, index) => {
                        if (selectedRows?.includes(item?.id)) {
                          return {
                            ...item,
                            isSubmit: !prev[index]?.isSubmit,
                          };
                        } else return item;
                      });
                      return arr;
                    });
                    SubmitAllUpdatedCtc(arr, selectedRows);
                  } else {
                  }
                }}
              >
                Submit All
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};
export { PMSsubmitAppraisalTab };
