import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { SelectFormikMulti } from "../../../../../Components/FormComponent/SelectFormikMulti";
import { Loader } from "../../../../../Components/FormComponent/Loader";
const option = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const optionSevere = [
  { label: "Critical", value: "Critical" },
  { label: "Moderate", value: "Moderate" },
  { label: "Low", value: "Low" },
];
export const AssignVendor = ({ id, data, handleClose,eid }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [val, setVal] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [incident, setIncident] = useState([]);
  const [dataDetails, setDataDetails] = useState(false);
  // const getDetailsProblem = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await getRequest(`problemRequests/details?ID=${val}`);
  //     setDataDetails(result?.data?.data);
  //   } catch (error) {
  //     dispatch(
  //       showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // //console.log("dataDetails", incident);
  // useEffect(() => {
  //   if (id != 0) {
  //     getDetailsProblem();
  //   }
  // }, []);
  const validationSchema = Yup.object({
    Emails: Yup.string().required("Please Enter Email"),
    Severity: Yup.string().required("Please Select Severity"),
    Priority: Yup.string().required("Please Select Priority"),
    VendorId: Yup.string().required("Please Select Vendor"),
    // incrementType: Yup.string().required("Required"),
  });
  useEffect(() => {
    document.title = `PeopleSol - Assign Vendor`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getVendor = async () => {
    try {
      const result = await getRequest("itservicevendors/GetVendors");
      let listData = [];
      result?.data?.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      // problemrequests/GetProblemRequestOwners
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getEmail = async () => {
    try {
      const result = await getRequest(
        `itsmvendorSupportTickets/FindEmails?ID=${val}`
      );
      let listData = [];
      setIncident(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const ProblemCreate = async (value) => {
    try {
      setLoading(true);
      const result = await postRequest(`itsmvendorsupporttickets/Create?TicketID=${id}&TeamId=${eid}`, value);

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };
  const ProblemEdit = async (value) => {
    try {
      setLoading(true);
      const result = await postRequest(`problemrequests/Edit`, value);

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };

  useEffect(() => {
    getVendor();
  }, []);
  useEffect(() => {
    if(val)getEmail();
  }, [val]);
  const onSubmit = async (values) => {

    const edit = {
      ...values,
      TicketId:id
    }
    ProblemCreate(edit);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        VendorId: val?val:"",
        Priority: "",
        Severity: "",
        EmailCc: incident?.emailCc?.length?incident?.emailCc?.replaceAll('[', '').replaceAll(']', ''):"",
        Emails: incident?.email?.length?incident?.email?.replaceAll('[', '').replaceAll(']', ''):"",
        TeamID: eid,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        setVal(values.VendorId)
        return (
          <Form>
            <div className="formcustom twoColumn">
              <div>
                <LabelCustom labelName={"Vendor"} mandatory={true} />
                <SelectForm
                            // onDropdownOpen={
                            //   getEmail()
                            // }
                  name="VendorId"
                  options={employeeList}
                  placeholder="Select Change Type"
                />
              </div>
              <div>
                <LabelCustom labelName={"Email(s)"} mandatory={true} />
                <InputCustomFormik
                  type="text"
                  name="Emails"
                />
              </div>
              <div>
                <LabelCustom labelName={"Email Cc"} />
                <InputCustomFormik
                  type="text"
                  name="EmailCc"
                  options={option}
                />
              </div>
              <div>
                <LabelCustom labelName={"Priority "} mandatory={true} />
                <SelectForm
                  name="Priority"
                  options={option}
                  placeholder={" Select Priority "}
                />
              </div>
              <div>
                <LabelCustom labelName={"Severity "} mandatory={true} />
                <SelectForm
                  name="Severity"
                  options={optionSevere}
                  placeholder={" Select Priority "}
                />
              </div>
            </div>

            <div className="createFootCust">
              <button
                type="button"
                className="button secondaryButton"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton"
                type="submit"
                style={{ color: "white" }}
              >
                Submit
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
