import React, {  } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { MaterialForm } from "./Form/MaterialForm";
import { materialSchema, SpecSchema } from "./Schema";
import { AssetSpecForm } from "./Form/AssetSpecForm";
import { useEffect } from "react";


const initialvalue = {
  name: "",
};

const CreateAssetSpecification = (props) => {
  const { state } = useLocation();

  const data = state?.selectedRecord;

  const dispatch = useDispatch();

  const postFormData = async (values) => {
    try {
      
      const result = await postRequest(
        "AssetSpecificationItems/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {}
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "AssetSpecificationItems/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data, severity: "error" })
    ) }
  };

  const onSubmit =  (values) => {

    const edit = {
        ...values,
        id: data?.Id,
    };
    
    const create = {
        ...values,
    };
    
    data ? Edit(edit) : postFormData(create);


    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/itsmmaster/asset/assetspecification");
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Asset Specification`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <FormCustomFormik
    ButtonLabel={data?'Update Asset Specification Items':'Create Asset Specification Items'}
      FormHeading={data?'Edit Asset Specification Items':'Create Asset Specification Items'}
      validationSchema={SpecSchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: data?.Name,
           
            }
          : 
          initialvalue
      }
      returnPage={returnPage}
      propsDiv={<AssetSpecForm data={data}/>}
    />
  );
};

export { CreateAssetSpecification };


