import * as Yup from "yup";

export const day1schema = Yup.object({
  question: Yup.string().max(299,"Question must be less than 300 characters").required("Please Enter Questions"),
  displayOrder: Yup.number().max(999, "Display Order must be less than a 999 Hr ").required("Please Enter Display Order"),
    
});

export const day7schema = Yup.object({
  question: Yup.string().max(299,"Question must be less than 300 characters").required("Please Enter Questions"),
  displayOrder: Yup.number().max(999, "Display Order must be less than a 999 Hr ").required("Please Enter Display Order"),
});