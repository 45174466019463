import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import Nodata from "../../../Assets/Nodata.svg";
import { getStatusColor } from "../../../Utilities/getStatusColor";




import { format } from "date-fns";

const DetailsRewards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  console.log("rewards location", location)
  const closeDetails = () => {
    if (location.state?.returnUrl) navigate(location.state?.returnUrl);
    else navigate(-1);
    // let currentUrl = location?.pathname;
    // navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(false);
  const [eventtData, setEventtData] = useState(false);

  const getEmployeeData = async () => {
    try {
      setLoading(true);
      // const result = await getRequest(`Awards/Details/${selectedId}`);
      const result = await getRequest(`Reward_ConfigController/Details?ID=${selectedId}`);
      setEventtData(result.data);
      console.log("sandy Quantative details:", result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = `PeopleSol -  Reward Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  useEffect(() => {
    getEmployeeData();
  }, []);

  // return (
  //   <div className="moduleMasterDetailsPage">
  //     <div
  //       className="moduleMasterDetailsBackground"
  //       onClick={() => closeDetails()}
  //     ></div>
  //     <div className="moduleMasterDetailsPageContainer">
  //       <div className="headingContainer">
  //         <h3>Details</h3>
  //         <IconButton onClick={() => closeDetails()} title="close">
  //           <svg
  //             width="13"
  //             height="13"
  //             viewBox="0 0 13 13"
  //             fill="none"
  //             xmlns="http://www.w3.org/2000/svg"
  //           >
  //             <path
  //               d="M12.7579 0.56897L0.757935 12.569"
  //               stroke="#F71D1D"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //             />
  //             <path
  //               d="M0.757935 0.56897L12.7579 12.569"
  //               stroke="#F71D1D"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //             />
  //           </svg>
  //         </IconButton>
  //       </div>
  //       {loading ? (
  //         <Loader />
  //       ) : (
  //         <div className="moduleMasterDetailsPageBody">
  //           <DetailsStep detailTitle={"Name"} Details={eventtData?.title} />
  //           <DetailsStep
  //             detailTitle={"Award Cycle"}
  //             Details={eventtData?.cycle}
  //           />
  //           <DetailsStep
  //             detailTitle={"Award For"}
  //             Details={eventtData?.awardFor}
  //           />
  //           <DetailsStep
  //             detailTitle={"Location"}
  //             Details={eventtData?.locationname}
  //           />
  //           <DetailsStep
  //             detailTitle={"No. of Winners"}
  //             Details={eventtData?.noOfWinners}
  //           />
  //           <DetailsStep
  //             detailTitle={"Nominations can be Submitted By"}
  //             Details={eventtData?.nominationsCanBeSubmittedBy}
  //           />
  //           <DetailsStep
  //             detailTitle={"Eligibilty Criteria"}
  //             Details={eventtData?.eligibilityCriteria}
  //           />
  //           <DetailsStep
  //             detailTitle={"Is Active"}
  //             Details={eventtData?.isActive === true ? "Yes" : "No"}
  //           />{" "}

  //           <DetailsStep
  //             detailTitle={"Created On"}
  //             Details={eventtData.createdOn && format(
  //               new Date(eventtData.createdOn),
  //               "dd/MM/yyyy hh:mm a"
  //             )}
  //           />
  //           <DetailsStep
  //             detailTitle={"Last Updated On"}
  //             Details={eventtData.lastUpdatedOn && format(
  //               new Date(eventtData.lastUpdatedOn),
  //               "dd/MM/yyyy hh:mm a"
  //             )}
  //           />
  //           <div>
  //             <h4 style={{ margin: "10px 0px" }}>Questions</h4>
  //           </div>
  //           <div style={{ width: "100%" }}>
  //             <table className="tabless tabless-bordered">
  //               <thead
  //                 style={{
  //                   backgroundColor: "var(--primary-hover)",
  //                 }}
  //               >
  //                 <tr>
  //                   <td>Sl. No.</td>
  //                   <td>Question</td>
  //                   <td>Is Mandatory?</td>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {eventtData?.requestlist?.length > 0
  //                   ? eventtData?.requestlist?.map((item, index) => {
  //                       return (
  //                         <tr className="trStyle">
  //                           <td>{index + 1}</td>
  //                           <td>{item.question}</td>
  //                           <td>
  //                             {item.isMandatory === true ? "Yes" : "No"}
  //                           </td>
  //                         </tr>
  //                       );
  //                     })
  //                   : "No Data Found"}
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Request Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Details
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Timeline
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Notifications
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {eventtData && (
              <>
                {tabIndex === 0 &&
                  (eventtData != null ? (
                    <div className="detailsEReq">
                      <div

                        style={{ flexDirection: "column" }}
                      >
                        <div className="headingForm" style={{ paddngBottom: "8px" }}> Request Details</div>

                        <div className="detailInner">
                          <>
                            <Details
                              detailTitle={"Rewards Title"}
                              Details={eventtData?.summary?._RewardsConfig?.title}
                            />
                            <Details
                              detailTitle={"Status"}
                              Details={<div
                                style={{
                                  backgroundColor: getStatusColor(
                                    eventtData?.summary?._RewardsConfig?.status
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    eventtData?.summary?._RewardsConfig?.status
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "3px 6px",
                                  width: "fit-content",
                                  borderRadius: "6px",
                                }}
                              >
                                {eventtData?.summary?._RewardsConfig?.status}
                              </div>}

                            />
                            <Details
                              detailTitle={"Description"}
                              Details={eventtData?.summary?._RewardsConfig?.description}
                            />
                            <Details
                              detailTitle={"Rewards Participation"}
                              Details={eventtData?.summary?._RewardsConfig?.isAlldepartment === true ? "All Department" : "Department wise"}
                            />
                            <Details
                              detailTitle={"No. of Winners"}
                              Details={eventtData?.summary?._RewardsConfig?.winnersCount}
                            />
                            <Details
                              detailTitle={"No. of Consolation"}
                              Details={eventtData?.summary?._RewardsConfig?.consolationCount}
                            />
                            <Details
                              detailTitle={"Certificate Template"}
                              Details={eventtData?.summary?._RewardsConfig?.template}
                            />
                            <Details
                              detailTitle={"Link Incentive"}
                              Details={eventtData?.summary?._RewardsConfig?.link_incentive === true ? "Yes" : "No"}
                            />
                            <Details
                              detailTitle={"Share on Social"}
                              Details={eventtData?.summary?._RewardsConfig?.share_social === "news" ? "News" : ""}
                            />
                            <Details
                              detailTitle={"Link with PMS"}
                              Details={eventtData?.summary?._RewardsConfig?.link_pms === true ? "Yes" : "No"}
                            />
                            <Details
                              detailTitle={"Created on"}
                              // Details={eventtData?.pmsAppraisalCriteria?.comments}
                              Details={
                                eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Startdate
                                  ? format(
                                    new Date(
                                      eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Startdate?.replace(
                                        "T",
                                        " "
                                      )
                                    ),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                  : "-"
                              }
                            />
                            <Details
                              detailTitle={"Last Update on"}
                              // Details={eventtData?.pmsAppraisalCriteria?.comments}
                              Details={
                                eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Startdate
                                  ? format(
                                    new Date(
                                      eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Startdate?.replace(
                                        "T",
                                        " "
                                      )
                                    ),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                  : "-"
                              }
                            />
                            {/* <Details
                              detailTitle={"items"}
                              Details={eventtData?.items}
                            /> */}


                          </>

                        </div>


                        <div>
                          <div className="headingForm" style={{ paddingBottom: "8px" }}>
                            Winner Position Details
                          </div>
                          <div className="detailInner">

                            {/* {eventtData?.summary?._RewardsConfig?.rewardsConfigWinnerposition.map(
                              (person, index) => ( */}
                            <div className="detaiTablePrice">
                              {/* {selectedId && ( 
                                    <div
                                      className="headingForm"
                                      style={{
                                        width: "100%",
                                        borderBottom: "none",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      Criteria #{index + 1}
                                    </div>
                                  )}
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "160px",
                                        }}
                                      >
                                        Departments
                                      </div>
                                      <div style={{ marginRight: "13px" }}>:</div>
                                      <div
                                        style={{
                                          color: "var(--primary)",
                                          fontWeight: "500",
                                          overflowWrap: "anywhere",
                                        }}
                                      >
                                        {person?.pmsAppraisalCriteriaDepartment
                                          ?.map((item) => item?.departmentName)
                                          ?.join(", ")}
                                      </div>
                                    </div>
                                  </div> */}


                              <div style={{ paddingRight: "12px" }}>
                                <div
                                  style={{
                                    overflowY: "auto",
                                  }}
                                >
                                  <table
                                    className="TravelTableMultiIn"
                                    style={{
                                      overflow: "auto",
                                      // width: "100%",
                                      // height: "197px",
                                    }}
                                  >
                                    {/* <thead
                                          style={{
                                            backgroundColor:
                                              "var(--primary-hover)",
                                          }}
                                        >
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                paddingLeft: "10px",
                                                textAlign: "center",
                                                width: "65px",
                                              }}
                                            >
                                              S.no{" "}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              Criteria
                                            </td>
                                            {eventtData?.reviewByPerson?.map((item) => (
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  textAlign: "center",
                                                  paddingLeft: "10px",
                                                  width: "125px",
                                                }}
                                              >
                                                {item === "Creator"
                                                  ? "Weightage (%)"
                                                  : item === "Department Director"
                                                    ? " HOD Review"
                                                    : item + " Review"}
                                              </td>
                                            ))}
                                          </tr>
                                        </thead> */}
                                    <tbody>
                                      {eventtData?.summary?._RewardsConfig?.rewardsConfigWinnerposition.filter((item) => item.isConsolation === false).map(
                                        (person, index) => (
                                          <tr key={person.id}>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                paddingLeft: "10px",
                                                color: "black",
                                                textAlign: "center",
                                                width: "65px",
                                              }}
                                            >
                                              {person?.position}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {person?.category}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                                color: "black",
                                              }}
                                            >
                                              30,000/-
                                            </td>



                                          </tr>

                                        ))}
                                      {/* <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                                color: "black",
                                              }}
                                            ></td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                                color: "#1296b0",
                                              }}
                                            >
                                              Total
                                            </td>
                                            {eventtData?.reviewByPerson?.map((it) => (
                                              <td
                                                style={{
                                                  border: "1px solid black",
                                                  textAlign: "center",
                                                  paddingLeft: "10px",
                                                  color: "#1296b0",
                                                }}
                                              >
                                                100%
                                              </td>
                                            ))}
                                          </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              {/* <div
                                    className="headingForm"
                                    style={{ borderBottom: "none" }}
                                  >
                                    Appraisal Slabs
                                  </div> */}



                            </div>
                            <Details
                              detailTitle={"Can consolation candidates receive certificates?"}
                              Details={eventtData?.summary?._RewardsConfig?.title}
                            />
                            {/* )
                            )} */}
                          </div>
                        </div>

                        {/*-------- selected department detail here----------- */}

                        <div >
                          <div className="headingForm" style={{ paddingBottom: "8px" }}> Selected Departments</div>
                          <div className="detailInner">
                            <Details
                              detailTitle={"Department"}
                              Details={eventtData?.summary?._RewardsConfig?.isAlldepartment === false ? eventtData?.summary?.departmentNames.map((item) => item) : "All Department"}
                            />
                            <Details
                              detailTitle={"Reason for Selected Departments"}
                              Details={eventtData?.summary?._RewardsConfig?.reason_selectDepartment}
                            />
                          </div>
                        </div>

                        {/*---------- removed employee detail here ------------*/}

                        <div>
                          <div className="headingForm" style={{ paddingBottom: "8px" }}>
                            Removed Employees
                          </div>
                          <div className="detailInner">

                            <div className="detaiTablePrice">
                              <div style={{ paddingRight: "12px" }}>
                                <div
                                  style={{
                                    overflowY: "auto",
                                  }}
                                >
                                  <table
                                    className="TravelTableMultiIn"
                                    style={{
                                      overflow: "auto",
                                      // width: "100%",
                                      // height: "197px",
                                    }}
                                  >
                                    <thead
                                      style={{
                                        backgroundColor:
                                          "var(--primary-hover)",
                                      }}
                                    >
                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            paddingLeft: "10px",
                                            textAlign: "center",
                                            width: "65px",
                                          }}
                                        >
                                          S.no{" "}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            textAlign: "start",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          Employee Name
                                        </td>

                                        <td
                                          style={{
                                            border: "1px solid black",
                                            textAlign: "center",
                                            paddingLeft: "10px",
                                            width: "125px",
                                          }}
                                        >
                                          Employee ID
                                        </td>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      {eventtData?.summary?._RewardsConfig?.rewardsElligible?.map(
                                        (person, index) => (
                                          <tr key={person.id}>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                paddingLeft: "10px",
                                                color: "black",
                                                textAlign: "center",
                                                width: "65px",
                                              }}
                                            >
                                              {index + 1}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {person?.employee?.name}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                                color: "black",
                                              }}
                                            >
                                              {person?.employee?.personNumber}
                                            </td>
                                          </tr>

                                        ))}

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <Details
                              detailTitle={"Reason for removed Employees"}
                              Details={eventtData?.summary?._RewardsConfig?.reason_removeEmployee
                              }
                            />
                          </div>
                        </div>

                        {/*---------- all Selected dates here ------------*/}

                        <div >
                          <div className="headingForm" style={{ paddingBottom: "8px" }}> Select Date</div>
                          <div className="detailInner">
                            <>
                              <Details
                                detailTitle={"Rewards Start Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Startdate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Startdate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Rewards End Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Enddate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Enddate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Target Setting Start Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.target_Startdate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.target_Startdate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Target Setting End Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.target_Enddate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.target_Enddate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Self Review Start Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.selfReview_Startdate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.selfReview_Startdate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Self Review End Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.selfReview_Enddate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.selfReview_Enddate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Manager Review Start Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.managerReview_Startdate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.managerReview_Startdate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Manager Review End Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.managerReview_Enddate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.managerReview_Enddate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"HOD Review Start Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.hodReview_Startdate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.hodReview_Startdate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"HOD Review End Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.hodReview_Enddate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.hodReview_Enddate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"HR Review Start Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.hrReview_Startdate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.hrReview_Startdate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"HR Review End Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.hrReview_Enddate
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.hrReview_Enddate?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Announcement Date"}
                                Details={
                                  eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.award_announcement_date
                                    ? format(
                                      new Date(
                                        eventtData?.summary?._RewardsConfig?.rewardsDates[0]?.award_announcement_date?.replace(
                                          "T",
                                          " "
                                        )
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : "-"
                                }
                              />
                            </>
                          </div>
                        </div>

                        {eventtData?.summary?.actionList?.length ? (
                          <div
                            className="createFootDetails"
                            style={{ background: "white !important" }}
                          >
                            {eventtData?.summary?.actionList?.map((item) => (
                              <div>
                                <div
                                  className="button secondaryButton"
                                  // onClick={() => {
                                  //   item.type !== "Approve" &&
                                  //   item.type !== "Release" &&
                                  //   item.type !== "Cancel"
                                  //     ? togglePopup(
                                  //         item?.id,
                                  //         item?.type,
                                  //         item?.color
                                  //       )
                                  //     : PostApRejHol(item?.type, item?.id);
                                  // }}
                                  style={{
                                    background: item?.color,
                                    color: "white",
                                  }}
                                >
                                  {item?.type}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {isOpen && (
                          <Popup
                            popupHeading={typeAction}
                            content={
                              <>
                                <Remark
                                  eventtData={PostApRejHol}
                                  type={typeAction}
                                  id={selId}
                                  color={colorAction}
                                  handleClose={togglePopup}
                                />
                              </>
                            }
                            handleClose={togglePopup}
                          />
                        )} */}
                      </div>

                    </div>

                  ) : (
                    <div className="noDatastyle">
                      <Loader />
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Data Found"}</h4>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <Timeline
                          id={selectedId}
                          module={"Rewards"}
                        // requestNumber={
                        //   data?.pmsAppraisalCriteria?.requestNumber
                        // }
                        />
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={selectedId}
                        module={"Rewards"}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsRewards };
