import React, { useRef } from "react";
import { useState } from "react";
import "./NewGroup.css";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import GroupProfileIcon from "../../../../Assets/GroupProfileIcon.svg";
import ChatSelectedCheck from "../../../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../../../Assets/ChatUnselectedCheck.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function NewGroup(props) {

  const [contactsList, setContactList] = useState(useSelector((state) => state.chat.contactList));
  const userDetails = useSelector((state) => state.chat.userDetails);

  const [chatList, setChatList] = useState(contactsList);
  const [newGroupList, setNewGroupList] = useState(true);
  const [activeGroupCreation, setActiveGroupCreation] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState([...props.selectedGroupMember]);
  const [newGroupProfilePic, setNewGroupProfilePic] = useState(
    "https://picsum.photos/40/40"
  );
  const [newGroupProfileName, setNewGroupProfileName] = useState("");
  const [totalMember, setTotalMember] = useState(selectedParticipant.length);
  const inputFile = useRef(null);

  useEffect(() => {
    document.title = `PeopleSol - New Group`;
    return () => {
      document.title = 'PeopleSol';
    };
  })


  useEffect(() => {
    // console.log(inputFile);
    let a = [];
    let tempContact = [...contactsList]
    if (props.alreadyAdded) {
      for (let i = 0; i < props.alreadyAdded.members.length; i++) {
        let item = props.alreadyAdded.members[i];
        for (let j = 0; j < tempContact.length; j++) {
          if (tempContact[j]._id != item.contactId) {
            a.push(tempContact[j]);
          }
        }
        tempContact = [...a];
        a = []
      }
      setChatList([...tempContact])
      setContactList([...tempContact])
    }
  }, []);
  // console.log(inputFile);

  // console.log(selectedParticipant);
  function showStatus(status) {
    if (status === "online") {
      return "greenDot";
    } else if (status === "busy") {
      return "redDot";
    } else if (status === "away") {
      return "yellowDot";
    }
  }


  const NewChatList = ({ setSelectedParticipant }) => {
    const Contact = ({ chat, index }) => {

      const select = selectedParticipant.filter((item) => item._id == chat._id).length > 0 ? true : false
      return (
        <div
          onClick={() => {
            setSelectedParticipant(chat, !select)
          }}
          className="newChatListContentContainer"
          style={{ backgroundColor: select ? "#E9F3F4" : "white", paddingLeft: 15, paddingRight: 15 }}
        >

          {select ?
            <img src={ChatSelectedCheck} /> : <img src={ChatUnselectedCheck} />}
          <div className="newChatActiveImage">
            {chat.image && chat.image != "" ? <img src={chat.image} alt="Profile" /> :
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 200 }}>
                <div style={{ color: "#1296B0" }}>
                  {
                    chat?.name
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.map((item) => {
                        return item?.charAt(0)?.toUpperCase();
                      })
                      .join("")
                  }
                </div>
              </div>}
            <div className={showStatus(chat.Active)}></div>
          </div>
          <div className="newChatPostMainTextContainer">
            <label className="chatListName">{chat.name}</label>
            <label className="chatListRole">{chat.role}</label>
          </div>
        </div>
      )
    }
    return (
      <div className="newChatListContainer" style={{ paddingBottom: 200 }}>
        {chatList.filter(item => item.personalNumber != userDetails.personalNumber).map((chat, index) => (
          <Contact key={index} index={index} chat={chat} />
        ))}
      </div>
    )
  }
  return (
    <div className="newGroupContainer" style={{ backgroundColor: "white", }}>
      {newGroupList && (
        <>
          <div className="newGroupSearchContainer">
            <div className="chatListSearchContainer">
              {" "}
              <input
                type="text"
                className="chatListSearch"
                placeholder="Search"
                onChange={(e) => {
                  if (e.target.value) {
                    let a = contactsList.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()) || item.role.toLowerCase().includes(e.target.value.toLowerCase()))
                    setChatList([...a])
                  } else {
                    setChatList([...contactsList])
                  }
                }}
              />
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0" />
                <line
                  x1="8.70711"
                  y1="9"
                  x2="12"
                  y2="12.2929"
                  stroke="#1296B0"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>

        </>
      )}
      <NewChatList setSelectedParticipant={(chat, select) => {
        if (select) setSelectedParticipant([...selectedParticipant, chat])
        else {
          let a = selectedParticipant.filter(item => item._id !== chat._id);
          setSelectedParticipant([...a])
        }
      }} />
      {selectedParticipant.length > 0 ? <div style={{ width: "100%", position: "absolute", bottom: 0, padding: 10, backgroundColor: "#E9F3F4", }}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", color: "#1296B0", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div>{selectedParticipant[0].name + "..."}</div>
            <div onClick={() => { props.viewSelected(true); props.setSelectedGroupMember(selectedParticipant) }} style={{
              backgroundColor: "white", padding: 5, marginLeft: 20, borderRadius: 4, fontSize: 12
            }}>View All</div>
          </div>
          <div onClick={() => {
            if (props.alreadyAdded && props.alreadyAdded.isAlready) {
              props.addNewMembers(selectedParticipant)
            } else {
              props.setSelectedGroupMember(selectedParticipant)
              props.createNewGroup();
            }
          }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
            >
              <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
              <path
                d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
                stroke="white"
                stroke-width="1.10154"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

      </div> : null
      }
    </div>
  );
}

export default NewGroup;