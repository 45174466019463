import React from "react";
import { useNavigate } from "react-router-dom";

const ContentSocialDisplay = ({ content }) => {
  const navigate = useNavigate();
  const lines = content?.split(/\r\n|\r|\n/);

  const processLine = (line) => {
    const regex = /\@\[[^\]]+\]\(\d+\)|[^\s]+/g;
    return line?.match(regex) || [];
  };

  const result = lines?.map(processLine);

  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  const handleHashProfileClick = (word) => {
    const hashVar = word.slice(1);
    navigate(`/home/social/search/${hashVar}`);
  };
  const isUrl = (string) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!urlPattern.test(string);
  };

  const isEmail = (string) => {
    const emailPattern = /\S+@\S+\.\S+/;
    return emailPattern.test(string);
  };
  const isUserTag = (string) => {
    const userTagPattern = /\@\[([^\]]+)\]\((\d+)\)/g;
    return userTagPattern.test(string);
  };
  const isHashTag = (string) => {
    const isHashTagPattern = /#([^\s]+)/g;
    return isHashTagPattern.test(string);
  };
  function extractDetails(text) {
    const pattern = /\@\[([^\]]+)\]\((\d+)\)/;
    const match = text.match(pattern);

    if (match) {
      const name = match[1].trim(); // "Akash Singh"
      const id = match[2]; // "5"
      return { name, id };
    }
    return null;
  }

  return (
    <div className="postBodySocialspans">
      {result.map((subArray, index) => {
        if (subArray?.length === 0) {
          return (
            <div key={index} className="empty-line">
              {" "}
            </div>
          );
        }

        return (
          <div key={index}>
            {subArray &&
              subArray?.map((item, subIndex) => {
                if (isUrl(item)) {
                  return (
                    <a
                      key={subIndex}
                      href={item}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      {item}
                    </a>
                  );
                } else if (isEmail(item)) {
                  return (
                    <a
                      key={subIndex}
                      href={`mailto:${item}`}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      {item}
                    </a>
                  );
                } else if (isUserTag(item)) {
                  const varData = extractDetails(item);
                  return (
                    <span
                      style={{ color: "#1296b0", cursor: "pointer" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleUserProfileClick(varData?.id);
                      }}
                      key={subIndex}
                    >
                      <label className="usertag">{varData?.name}</label>
                    </span>
                  );
                } else if (isHashTag(item)) {
                  return (
                    <span
                      style={{ color: "#1296b0", cursor: "pointer" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleHashProfileClick(item);
                      }}
                      key={subIndex}
                    >
                      <label className="usertag">{item}</label>
                    </span>
                  );
                }
                return <span key={subIndex}>{item} </span>;
              })}
          </div>
        );
      })}
    </div>
  );
};

export default ContentSocialDisplay;
