import React from "react";
import "./NoRequestsFound.css"
import { useEffect } from "react";

function NoRequestsFound({ text }) {
 
  return (
    <div className="noRequests">
      <div>
        <svg
          width="218"
          height="194"
          viewBox="0 0 218 194"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M118.082 190.149C120.801 190.149 128.775 174.2 130.134 171.844C135.028 163.417 139.377 154.627 142.911 145.475C150.886 124.814 157.048 99.8034 154.873 77.5115C153.423 63.2846 147.986 49.7826 142.549 36.5525C137.927 25.3159 133.306 14.0793 128.684 2.75217C128.322 1.75538 127.688 0.667969 126.691 0.667969C126.056 0.667969 125.422 1.12106 124.878 1.48353C120.348 5.19884 115.907 8.82353 111.376 12.5388C109.292 14.2606 102.587 21.6912 99.8681 21.6912C77.4856 41.2646 67.3364 67.9061 67.3364 97.1755C67.3364 138.135 89.4471 173.475 101.771 190.058C101.862 190.149 118.082 190.149 118.082 190.149Z"
            fill="#FFF4E6"
          />
          <path
            d="M57.9118 190.148H113.37C112.01 179.999 103.039 147.196 98.3272 131.519C94.6119 119.195 89.1748 105.602 82.6504 94.7281C75.9447 83.7634 63.8926 76.7858 52.9279 70.9863C45.9503 67.3616 38.8822 63.8275 31.7234 60.6559C25.4708 57.8468 18.5839 53.5878 11.9688 51.9566C10.3377 51.5942 6.35052 79.3231 6.16929 81.8604C5.35373 92.372 5.7162 102.974 7.34731 113.305C10.3377 132.062 17.0434 151.545 28.5518 166.678C37.0698 177.915 47.4002 185.255 57.9118 190.148Z"
            fill="#FFF4E6"
          />
          <path
            d="M215.314 60.5667C215.043 58.7544 214.771 56.8514 214.499 55.0391C207.703 61.1104 198.188 62.4697 189.579 65.4601C170.459 72.0751 152.698 87.1176 139.921 102.704C115.545 132.245 109.02 173.204 107.389 190.15H168.284C176.893 185.891 185.139 179.729 192.298 171.12C217.58 140.491 220.842 98.8073 215.314 60.5667Z"
            fill="#FFF4E6"
          />
          <path
            d="M201.367 153.172C203.789 153.172 205.763 155.146 205.763 157.568C205.763 159.99 203.789 161.964 201.367 161.964"
            stroke="#555555"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M198.406 155.325C198.406 156.491 197.419 157.478 196.253 157.478C195.086 157.478 194.1 156.491 194.1 155.325C194.1 154.159 195.086 153.172 196.253 153.172C197.419 153.172 198.406 154.159 198.406 155.325Z"
            fill="#555555"
          />
          <path
            d="M14.263 153.488L0.71582 162.639"
            stroke="#555555"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M18.3005 153.219L7.08594 160.755"
            stroke="#555555"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M28.9316 190.738H198.406"
            stroke="black"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M50.2437 193.52H152.789"
            stroke="black"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M175.561 193.52H182.64"
            stroke="black"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M36.6954 121.615L33.645 121.166L33.7347 124.486L30.4152 124.396V127.895L27.0957 127.805L27.5443 130.855"
            stroke="black"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M188.851 95.6797L177.727 107.163"
            stroke="#555555"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M189.748 91.6426L183.02 98.5507"
            stroke="#555555"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M59.3936 115.782C60.335 115.782 61.0982 115.019 61.0982 114.078C61.0982 113.136 60.335 112.373 59.3936 112.373C58.4521 112.373 57.689 113.136 57.689 114.078C57.689 115.019 58.4521 115.782 59.3936 115.782Z"
            stroke="black"
            stroke-width="0.3175"
            strokeMiterlimit="10"
          />
          <path
            d="M149.261 43.463C149.952 42.4958 150.78 41.805 151.748 41.1142C154.373 39.5944 157.551 39.0418 159.07 36.1403C159.623 35.035 159.899 33.6534 160.59 32.5481C161.281 31.4428 162.386 30.6138 163.63 30.1993C164.182 30.0611 164.735 29.923 165.288 30.0611C166.117 30.1993 166.807 30.8901 167.36 31.7191C168.604 33.7915 168.327 36.4167 167.222 38.4891C165.978 40.8379 163.353 43.1867 161.005 44.4301C158.656 45.5354 156.031 45.9499 154.235 48.0224C153.682 48.7132 153.267 49.5422 152.577 50.0948C151.333 51.062 149.123 50.6475 148.432 49.1277C148.017 48.4369 148.017 47.6079 148.155 46.7789C148.017 45.8118 148.57 44.5683 149.261 43.463Z"
            fill="#FB7158"
          />
          <path
            d="M160.728 57.416C160.175 57.416 159.484 57.5542 158.932 57.8305C157.688 58.5213 156.997 60.1793 157.136 61.5609C157.136 64.0479 159.484 71.2324 162.938 68.6072C165.84 66.3966 166.116 61.4228 163.629 58.7976C162.8 58.1068 161.695 57.5542 160.728 57.416Z"
            fill="#FFDDD4"
          />
          <path
            d="M94.2715 40.0078C94.2715 40.6986 94.2715 41.3894 94.4096 42.0802C94.5478 43.7382 94.9623 45.258 95.6531 46.6396C96.4821 48.1594 97.7256 49.541 98.969 50.7845C99.5217 51.3372 100.074 51.7517 100.765 52.1661C102.147 52.9951 103.805 52.9951 105.463 53.1333C107.95 53.1333 110.437 53.1333 112.785 52.4425C115.549 51.7517 121.075 49.2647 121.904 46.2251C122.18 45.258 122.042 44.2908 122.18 43.3237C122.319 41.8039 122.733 40.2841 123.009 38.7643C123.424 36.6918 123.424 34.6194 122.871 32.6851C121.904 29.3692 118.45 25.3624 115.272 23.9808C114.029 23.4282 112.785 23.29 111.404 23.1518C110.022 23.0137 108.64 22.8755 107.259 23.29C106.568 23.4282 105.877 23.8427 105.325 23.9808C104.634 24.2571 103.943 24.2571 103.252 24.3953C101.18 24.8098 98.6927 25.7769 97.3111 27.4349C96.2058 28.8165 95.6531 30.4745 95.2386 32.1325C94.5478 34.7576 94.2715 37.5208 94.2715 40.0078Z"
            fill="black"
          />
          <path
            d="M114.444 129.399C108.503 128.432 102.424 128.432 96.6206 129.399C92.7521 130.09 89.298 131.61 85.5676 132.991C81.2845 134.649 77.0014 135.755 72.5802 136.998C67.6063 138.38 62.7706 140.176 58.0731 142.11C55.1716 143.354 52.2702 144.597 49.5069 145.841C46.7437 147.084 43.9804 148.328 41.6316 150.124C38.0394 152.887 36.7959 155.788 37.0722 160.21C37.3486 164.493 39.5592 167.809 43.1514 170.157C47.2963 172.782 52.6847 173.335 57.5204 172.782C64.4286 172.092 71.6131 169.467 78.3831 171.954C84.7386 174.302 92.4757 176.237 99.2457 176.789C104.634 177.204 110.022 176.789 115.273 176.237C124.944 175.131 134.339 172.506 144.011 172.506C154.787 172.506 169.847 172.921 177.999 164.631C181.867 160.762 182.696 154.959 179.657 150.262C177.723 147.36 175.236 145.288 172.334 143.492C160.176 136.169 145.116 137.136 131.438 133.682C126.188 132.439 121.214 130.504 115.964 129.399C115.549 129.537 114.996 129.399 114.444 129.399Z"
            fill="#5BC4BF"
          />
          <path
            d="M120.661 74.962C117.897 73.4422 116.654 70.6789 116.239 67.7775C115.687 64.5997 115.549 61.422 115.687 58.2442C115.687 57.8297 115.825 57.4152 116.239 57.1389C119.003 54.3757 119.97 49.9544 120.384 46.224C120.523 44.4279 120.384 42.7699 120.522 40.9738C120.522 38.7632 120.522 36.4144 120.246 34.2038C120.246 33.6511 120.108 32.9603 119.832 32.4077C118.865 31.026 116.101 30.6116 114.72 29.5062C112.785 27.9864 110.437 29.5062 108.226 30.0589C105.601 30.7497 102.976 31.026 100.351 31.3024C99.9362 31.3024 99.3835 31.3024 98.969 31.5787C97.8637 32.1313 97.4492 33.513 97.4492 34.8946C97.1729 38.2105 97.1729 41.3883 97.4492 44.7042C97.7255 47.7438 98.14 50.9216 99.3835 53.6848C99.6598 54.3757 100.212 55.0665 100.627 55.8954C101.456 57.2771 101.18 58.935 101.318 60.593C101.318 62.3891 101.18 64.3234 100.765 66.1195C99.9362 69.2973 97.5874 73.304 94.1333 73.9949C97.0347 78.9687 98.5545 84.7716 99.5217 90.4363C99.798 92.0943 101.732 108.398 101.041 108.536C105.601 108.398 110.298 107.845 114.029 105.22C115.963 103.838 117.345 101.904 119.279 100.66C119.97 100.246 120.661 99.8314 121.351 99.5551C120.246 91.1271 119.97 83.1136 120.661 74.962Z"
            fill="white"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M127.706 75.9301C127.706 76.0683 123.285 75.1012 122.871 74.963C121.351 74.5485 120.246 74.4103 119.14 73.1669L119.002 73.0287C117.897 73.8577 116.653 74.4103 115.41 74.8248C112.923 75.6538 110.16 75.792 107.535 75.792C103.39 75.792 99.1066 75.3775 96.2052 72.3379C96.2052 72.3379 96.067 72.3379 96.067 72.4761C94.8235 73.1669 93.4419 73.4432 92.0603 73.8577C91.3695 74.134 90.5405 74.2722 89.8497 74.5485C89.1588 74.6867 88.3299 75.2393 87.639 75.2393C88.468 77.3118 89.9878 78.8316 91.3695 80.4895C92.7511 82.1475 93.5801 84.0818 93.5801 86.2924C93.4419 88.6412 92.8892 90.8518 91.9221 93.0624C90.8168 95.8257 89.0207 97.0691 86.8101 98.7271C84.4613 100.523 85.0139 102.458 84.8758 105.221C84.8758 109.918 84.0468 114.478 83.356 119.175C82.6652 123.182 82.9415 127.465 82.6652 131.472C82.6652 132.301 82.527 133.13 81.9743 133.682C81.698 133.959 81.2835 134.235 81.0072 134.511C80.7309 134.788 80.5927 135.479 81.0072 135.617C83.356 136.031 85.8429 136.446 87.9154 137.413C89.8497 138.38 91.3694 139.762 93.3037 140.729C96.896 142.663 101.179 143.078 105.324 142.801C109.469 142.525 113.476 141.834 117.62 141.558C120.522 141.42 123.562 141.558 126.463 140.729C128.674 140.176 130.746 138.933 132.818 137.827C134.062 137.137 135.305 136.446 136.549 135.755C137.24 135.34 137.931 134.926 138.207 134.373C138.621 133.544 137.378 131.61 137.24 130.643C137.102 128.57 136.825 126.498 136.549 124.564C136.134 122.629 135.72 120.695 135.305 118.761C134.615 114.892 134.476 111.024 134.338 107.155C134.338 104.668 134.062 101.767 133.509 99.2797C132.68 96.2401 130.746 93.2006 129.226 90.4373C128.259 88.503 127.292 86.5687 126.601 84.4963C126.048 82.9765 125.496 81.5948 126.048 80.075C125.91 78.4171 127.568 77.3118 127.706 75.9301Z"
            fill="#FB7158"
          />
          <path
            d="M111.68 33.9291C113.476 33.791 115.272 33.9291 117.069 34.3436C117.483 34.4818 118.036 34.62 118.312 34.8963C118.588 35.1726 118.726 35.5871 118.865 36.0016C119.417 38.074 119.555 40.2847 119.417 42.4953C121.628 39.5938 121.213 36.9687 121.352 33.6528C121.49 30.7514 121.213 28.5408 118.45 26.7446C116.24 25.2248 113.614 24.8104 110.989 24.3959C108.641 23.9814 106.015 25.2248 103.805 24.534C103.252 24.3959 102.838 24.1195 102.423 23.9814C101.732 23.8432 100.903 23.8432 100.074 24.1195C98.6928 24.534 97.5875 26.0538 98.002 27.4355C97.0348 27.1591 95.9295 27.021 95.3769 27.7118C95.1006 28.1263 95.1006 28.6789 94.9624 29.0934C94.9624 29.6461 94.9624 30.1987 95.3769 30.6132C95.515 30.7514 95.7914 30.8895 95.9295 31.1659C96.6204 31.8567 95.9295 33.791 95.9295 34.7581C95.7914 36.5542 95.6532 38.3504 95.7914 40.2847C95.7914 40.8373 96.2059 41.5281 96.7585 41.5281C97.4493 41.5281 97.8638 40.561 98.002 39.8702C98.1402 38.2122 98.4165 36.5542 98.5546 35.0344C102.7 35.7253 106.844 34.2055 111.128 33.9291C111.266 33.9291 111.542 33.9291 111.68 33.9291Z"
            fill="black"
          />
          <path
            d="M105.739 155.789C105.739 154.131 106.568 149.295 104.219 149.433C99.936 149.71 95.5147 149.572 91.2317 149.019C90.9553 149.019 86.2578 147.775 85.9815 147.775C85.7051 150.124 84.0472 152.749 83.6327 155.098C83.3564 156.342 88.3302 158.552 88.1921 159.796C94.1331 160.072 100.074 159.934 106.015 160.763C105.739 160.625 105.739 156.065 105.739 155.789Z"
            fill="white"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M85.153 159.657C85.4293 158.552 85.5674 157.308 85.8438 156.203C86.1201 154.407 86.5346 152.749 86.9491 151.091C87.2254 150.124 87.5017 149.157 87.5017 148.052C87.3636 147.913 87.0872 147.775 86.9491 147.637C85.2911 145.979 82.1134 143.078 79.6264 143.768C78.9356 143.907 78.3829 144.321 77.8303 144.736C72.0274 148.19 64.8429 148.19 58.6256 150.815C57.6584 151.229 56.6913 151.782 56.0005 152.473C55.3097 153.302 55.0333 154.545 55.586 155.374C56.1386 156.341 57.244 156.756 58.2111 156.756C59.3164 156.756 60.2835 156.48 61.2507 156.341C64.2903 155.927 68.8497 156.48 71.8893 157.308C75.7578 158.276 79.4882 159.381 83.495 159.795C84.0476 159.519 84.6003 159.657 85.153 159.657Z"
            fill="#A0A0CB"
          />
          <path
            d="M169.432 145.425C164.458 145.425 159.899 146.807 155.063 145.149C150.228 143.491 145.254 141.004 140.142 142.8C135.997 144.181 132.128 147.912 127.431 147.636C125.635 147.497 123.838 146.668 122.042 146.116C118.174 144.872 116.101 146.53 112.647 147.774C110.575 148.465 108.502 148.879 106.43 149.155C103.667 149.432 101.732 148.326 101.042 151.78C100.213 156.202 99.6599 160.623 98.8309 165.044C98.6927 165.873 98.4164 166.84 98.2782 167.669C98.1401 168.913 97.8637 170.018 97.7256 171.262C99.6599 171.676 101.456 171.952 103.39 172.367C115.272 174.439 127.293 176.512 139.451 176.65C151.471 176.926 164.459 176.65 175.373 170.985C179.104 169.051 181.867 165.459 183.111 161.314C183.801 158.965 184.078 156.34 183.249 153.991C182.282 151.366 179.656 149.984 177.722 148.188C175.512 146.116 172.61 145.287 169.432 145.425Z"
            fill="#5BC4BF"
          />
          <path
            d="M150.228 172.369C148.984 169.467 147.326 167.809 146.773 164.631C144.839 164.493 134.615 163.941 132.404 163.802C131.852 163.802 124.115 163.526 124.115 163.802L123.286 177.204C129.917 176.514 143.457 176.928 150.228 176.375C149.675 175.132 150.642 173.474 150.228 172.369Z"
            fill="white"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M178.413 173.473C178.413 173.335 178.275 173.059 178.275 172.921C175.511 165.736 164.735 168.638 159.761 164.355C157.826 162.697 156.583 160.21 154.096 159.657C151.885 159.104 148.293 160.486 146.359 161.591C145.254 162.282 144.563 163.526 143.596 164.216C143.457 164.355 143.319 164.355 143.043 164.493C144.286 167.118 144.563 170.157 145.115 172.783C145.392 174.302 144.977 176.789 145.254 176.789C145.944 176.651 148.293 176.789 148.984 176.789C150.642 176.651 152.3 176.513 153.958 176.375C155.892 176.237 157.965 176.513 159.899 176.513C164.596 176.513 169.294 176.789 173.992 177.204C175.373 177.342 177.031 177.342 177.86 176.237C178.689 175.546 178.689 174.441 178.413 173.473Z"
            fill="#A0A0CB"
          />
          <path
            d="M132.542 180.105C132.819 178.309 133.233 176.513 133.371 174.717C133.509 172.506 133.648 170.434 133.648 168.223C133.648 163.802 133.233 159.381 132.266 155.098C129.226 156.756 125.634 156.203 122.18 156.065C118.035 155.927 114.028 156.756 110.022 157.032C108.225 157.17 106.568 156.894 104.771 156.618C102.837 156.203 100.488 156.479 98.554 156.618C97.4487 156.618 96.6198 156.479 95.5144 156.203C94.4091 155.927 93.0275 155.374 91.9222 155.374C88.0536 154.407 84.185 153.302 80.3165 152.334C79.2112 152.058 77.9677 151.782 77.0006 151.229C74.3754 150.124 71.7503 149.019 68.9871 148.604C65.8093 148.19 62.6316 148.19 59.4538 148.466C56.1379 148.742 52.9601 148.604 49.6442 148.19C46.7428 147.775 43.565 147.775 41.2162 149.709C39.1438 151.505 38.3148 154.407 37.7622 157.032C37.4858 158.275 37.3477 159.657 37.3477 160.901C37.4858 162.006 37.7622 162.973 38.1766 164.078C39.282 166.98 40.6636 169.881 42.5979 172.506C44.5322 175.131 47.1573 175.546 50.335 176.237C62.2171 178.447 74.3754 179.138 86.3957 180.796C101.179 182.869 116.239 184.803 131.161 185.356C131.437 185.356 131.575 185.356 131.713 185.217C131.851 185.079 131.851 184.941 131.851 184.803C132.128 183.007 132.404 181.625 132.542 180.105Z"
            fill="#5BC4BF"
          />
          <path
            d="M129.499 185.292C127.981 185.292 127.684 185.292 124.367 185.038C119.272 184.649 103.049 183.666 100.953 182.943C98.8575 182.22 68.7958 179.257 65.1826 178.462C61.5694 177.668 47.8393 176.512 46.105 175.283"
            stroke="black"
            stroke-width="0.395652"
          />
          <path
            d="M105.877 51.062C106.706 51.7528 107.674 52.1673 108.641 52.0291C109.608 52.0291 110.713 51.6146 111.404 50.9238"
            stroke="#2A273D"
            stroke-width="0.628032"
            strokeMiterlimit="10"
          />
          <path
            d="M71.4746 149.02C74.376 149.71 77.0011 150.954 79.9026 151.783C84.1856 152.888 88.3305 154.132 92.6136 155.237C94.1334 155.651 95.6532 156.066 97.3112 156.342C99.5218 156.619 101.871 156.619 104.081 156.48C113.614 156.204 123.286 155.928 132.819 155.651C132.819 156.342 132.819 157.171 132.819 157.862C132.819 158.415 132.957 159.105 132.957 159.658C133.234 161.04 133.924 162.56 133.51 163.941C133.234 164.908 133.372 165.461 133.372 166.428"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M158.379 144.597C151.333 143.768 143.734 143.216 136.688 144.597C130.885 145.703 125.082 146.67 119.279 147.361C114.996 147.913 110.713 148.466 106.43 148.742C104.081 148.88 102.561 148.328 101.87 150.538C101.18 152.473 101.041 154.683 100.489 156.618"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M115.549 58.5208C115.687 58.1063 115.687 57.6918 115.963 57.2773C115.41 57.5537 114.858 57.83 114.305 58.1063C113.062 58.5208 111.818 58.659 110.575 58.7971C109.469 58.9353 108.364 59.0735 107.397 59.0735C110.022 59.9024 112.924 60.1788 115.549 59.9025C115.41 59.488 115.41 58.9353 115.549 58.5208Z"
            fill="black"
          />
          <path
            d="M70.6454 148.327C64.0135 148.051 56.1382 149.018 49.7827 151.229"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M119.693 41.8036C121.213 41.3891 122.733 42.2181 123.286 43.7379C124.115 46.0867 121.766 47.7446 119.693 47.0538"
            fill="white"
          />
          <path
            d="M119.693 41.8036C121.213 41.3891 122.733 42.2181 123.286 43.7379C124.115 46.0867 121.766 47.7446 119.693 47.0538"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M98.0024 42.0793C97.1734 41.5266 95.9299 41.6648 95.2391 42.3556C94.5483 43.0464 94.272 44.2899 94.6864 45.3952C95.1009 46.3624 95.9299 47.0532 96.8971 47.1913C97.4497 47.1913 98.1405 47.0532 98.555 46.6387"
            fill="white"
          />
          <path
            d="M98.0024 42.0793C97.1734 41.5266 95.9299 41.6648 95.2391 42.3556C94.5483 43.0464 94.272 44.2899 94.6864 45.3952C95.1009 46.3624 95.9299 47.0532 96.8971 47.1913C97.4497 47.1913 98.1405 47.0532 98.555 46.6387"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M121.767 43.4609C120.8 43.4609 119.832 43.8754 119.28 44.7044"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M95.9292 43.4609C96.62 43.4609 97.449 43.8754 97.8635 44.4281"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M77.692 133.959C77.2775 134.65 76.7248 135.34 76.1722 135.893C72.4417 139.347 67.7442 141.281 63.0466 143.078C59.0399 144.597 55.0332 146.255 50.8883 147.499C49.6448 147.913 48.4013 147.913 47.4342 148.88C45.3617 150.953 45.9144 154.96 45.7762 157.723C45.7762 158.137 45.7762 158.69 45.3617 159.105C45.0854 159.519 44.3946 159.657 44.1182 159.243C43.9801 159.105 43.9801 158.828 43.9801 158.552C43.9801 157.999 43.9801 155.374 42.7366 156.479C42.4603 156.756 42.4603 157.032 42.3221 157.447C42.0458 158.552 41.7695 159.657 41.6313 160.762C41.4931 161.591 41.2168 162.559 40.8023 163.249C40.3878 164.078 39.697 164.769 38.868 164.907C37.4864 165.322 36.1048 164.355 35.414 163.111C34.7231 161.868 34.7231 160.348 34.7231 158.966C34.9995 154.407 36.2429 148.604 39.2825 145.012C42.4603 141.005 46.8815 138.242 51.0264 135.34C53.3752 133.682 55.5858 132.024 57.9346 130.367C59.5925 129.123 62.3558 128.018 63.323 126.222C64.5664 123.873 64.5664 121.248 64.8428 118.623C65.2572 115.445 65.8099 112.267 66.3625 109.228C66.9152 106.464 67.4679 103.701 68.8495 101.352C71.6128 96.6547 77.9683 94.9967 81.146 100.385C82.6658 102.872 84.0475 104.53 83.9093 107.155C83.633 111.024 82.1132 115.583 81.5605 119.452C81.146 123.458 79.9026 130.367 77.692 133.959Z"
            fill="white"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M95.515 73.4434C92.0609 73.8578 88.4686 74.4105 85.0146 75.5158C82.8039 76.2066 80.5933 77.1738 78.5209 78.2791C76.1721 79.5225 73.9615 80.9042 72.1654 82.8385C70.2311 84.7728 68.9876 87.2597 67.8823 89.7466C66.3625 93.3389 65.2572 97.0693 64.1519 100.8C63.1848 104.254 62.0794 107.708 61.1123 111.162C62.2176 111.162 63.3229 110.886 64.4282 111.024C65.5335 111.162 66.6388 111.3 67.7441 111.438C69.4021 111.715 70.9219 111.991 72.5799 112.267C77.0011 112.958 81.6986 113.373 86.1199 112.82C86.3962 112.82 86.6725 112.82 86.8107 112.544C86.9489 112.405 86.9488 112.267 86.9488 112.129C87.6397 107.984 88.6068 103.977 90.1266 99.9707C91.6464 96.1021 93.5807 92.5099 94.686 88.5032C95.1005 87.1215 95.3768 85.7399 95.7913 84.2201C96.344 82.2858 96.7584 80.3515 96.7584 78.4172C96.7584 77.5883 96.6203 76.6211 96.2058 75.9303C95.7913 75.1013 95.2387 74.5487 95.515 73.4434Z"
            fill="#FB7158"
          />
          <path
            d="M140.971 134.234C141.386 134.925 141.938 135.478 142.629 136.03C146.498 139.208 151.333 141.004 156.031 142.524C160.176 143.906 164.183 145.149 168.327 146.255C169.571 146.531 170.953 146.531 171.782 147.36C173.025 148.465 173.439 150.261 173.578 151.781C173.716 152.61 173.716 153.439 173.716 154.406C173.716 155.373 173.992 156.341 173.992 157.446C173.992 157.722 173.992 157.998 174.13 158.137C174.407 158.551 174.959 158.689 175.236 158.275C175.65 157.86 175.65 157.308 175.65 156.755C175.65 156.202 175.512 153.577 176.755 154.683C177.032 154.959 177.17 155.235 177.17 155.65C177.446 156.755 177.723 157.86 178.137 158.966C178.413 159.795 178.69 160.624 179.104 161.453C179.519 162.143 180.209 162.834 181.177 162.972C182.558 163.249 183.94 162.282 184.631 161.038C185.322 159.795 185.183 158.275 185.045 156.893C184.493 152.334 182.973 146.669 179.795 143.215C176.479 139.485 171.782 136.859 167.637 134.096C165.288 132.576 162.939 131.057 160.452 129.537C158.794 128.432 155.893 127.464 154.787 125.668C153.406 123.319 153.268 120.694 152.853 118.207C152.3 115.03 151.61 111.99 150.781 108.95C150.09 106.325 149.399 103.562 147.879 101.213C144.84 96.6539 138.484 95.4105 135.583 100.799C134.201 103.286 132.819 105.082 133.234 107.707C133.787 111.576 135.445 115.997 136.135 119.865C136.826 124.01 138.484 130.78 140.971 134.234Z"
            fill="white"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M83.6333 113.51C84.0478 110.471 86.6729 104.53 87.2256 101.49"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M77.5534 131.473C77.277 132.854 76.4481 134.098 75.2046 134.789L77.5534 131.473Z"
            fill="white"
          />
          <path
            d="M77.5534 131.473C77.277 132.854 76.4481 134.098 75.2046 134.789"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M36.6578 164.355C36.5196 162.006 36.3815 159.657 36.5196 157.309"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M37.7627 164.632C37.7627 162.283 37.9009 159.796 38.3154 157.447"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M39.0063 164.77C39.4208 162.422 39.6972 160.073 40.1116 157.586"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M125.635 98.0357C127.293 97.6213 127.707 98.3121 128.536 99.5555C129.78 101.49 130.194 103.977 130.747 106.326C131.161 108.122 130.885 109.78 130.885 111.438C138.622 110.332 146.083 108.26 153.544 106.187C149.675 92.9237 146.083 77.0349 126.049 75.6533C124.391 75.5151 123.148 75.6533 121.904 75.377C121.352 83.2523 121.49 91.1276 122.457 98.8647C123.424 98.5884 124.529 98.3121 125.635 98.0357Z"
            fill="#FB7158"
          />
          <path
            d="M132.266 111.854C131.99 108.952 130.332 101.491 130.056 98.5898"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M180.762 162.836C180.485 160.349 180.071 157.862 179.518 155.514"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M182.282 162.973C182.143 161.454 181.867 159.934 181.729 158.414C181.591 157.171 181.453 155.927 181.038 154.684"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M183.801 162.282C183.801 159.656 183.525 157.031 182.834 154.406"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M142.076 131.887C142.076 132.992 142.767 134.097 143.734 134.65L142.076 131.887Z"
            fill="white"
          />
          <path
            d="M142.076 131.887C142.076 132.992 142.767 134.097 143.734 134.65"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M97.0347 73.165C98.5545 74.4085 100.351 75.0993 102.285 75.5138C104.219 75.9283 106.153 75.9283 108.088 75.9283C109.055 75.9283 110.022 75.9283 110.851 75.9283C113.062 75.7901 115.134 75.0993 117.068 73.994C117.345 73.8558 118.45 73.0268 118.726 72.8887"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M42.4604 165.459C42.8749 167.117 43.7039 168.499 44.8092 169.742"
            stroke="#1C1C1B"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M169.709 70.5405C170.777 70.5405 171.643 69.6744 171.643 68.6062C171.643 67.5379 170.777 66.6719 169.709 66.6719C168.641 66.6719 167.775 67.5379 167.775 68.6062C167.775 69.6744 168.641 70.5405 169.709 70.5405Z"
            fill="black"
          />
          <path
            d="M69.8166 59.213C69.8166 60.7328 68.5731 61.8381 67.1915 61.8381C65.6717 61.8381 64.5664 60.5946 64.5664 59.213C64.5664 57.6932 65.8099 56.5879 67.1915 56.5879C68.7113 56.5879 69.8166 57.6932 69.8166 59.213Z"
            fill="black"
          />
          <path
            d="M56.2767 43.5993C53.2371 44.152 50.0593 44.0138 47.0197 44.5664C43.9801 45.1191 40.8024 46.2244 38.7299 48.5732C37.3483 50.093 36.5193 52.7181 37.901 54.376C38.8681 55.6195 40.5261 55.8958 41.9077 55.6195C43.4275 55.3432 44.671 54.6524 46.0526 54.0997C49.0922 52.8562 52.4081 52.1654 55.5859 52.1654C57.1057 52.1654 58.7636 52.3036 60.2834 52.0273C63.1849 51.6128 66.2245 48.8495 65.1192 45.6718C63.8757 41.9413 59.1781 43.0466 56.2767 43.5993Z"
            fill="#DA5DB6"
          />
          <path
            d="M53.6517 70.817C56.246 70.817 58.3492 68.7138 58.3492 66.1194C58.3492 63.525 56.246 61.4219 53.6517 61.4219C51.0573 61.4219 48.9541 63.525 48.9541 66.1194C48.9541 68.7138 51.0573 70.817 53.6517 70.817Z"
            fill="#F7941D"
          />
          <path
            d="M64.705 36.2756C64.705 37.3809 63.7378 38.348 62.6325 38.348C61.5272 38.348 60.5601 37.3809 60.5601 36.2756C60.5601 35.1703 61.5272 34.2031 62.6325 34.2031C63.7378 34.2031 64.705 35.1703 64.705 36.2756Z"
            fill="#EE7273"
          />
          <path
            d="M154.511 32.8232C156.495 32.8232 158.103 31.2149 158.103 29.2309C158.103 27.247 156.495 25.6387 154.511 25.6387C152.527 25.6387 150.918 27.247 150.918 29.2309C150.918 31.2149 152.527 32.8232 154.511 32.8232Z"
            fill="#F7941D"
          />
          <path
            d="M71.7515 50.6464C74.1002 49.8174 76.7253 51.199 77.4162 53.5478C78.2451 55.8966 76.8635 58.5217 74.5147 59.2125"
            stroke="black"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
          <path
            d="M172.472 61.285C170.261 61.285 168.465 59.4888 168.465 57.2782C168.465 55.0676 170.261 53.2715 172.472 53.2715C174.683 53.2715 176.479 55.0676 176.479 57.2782"
            stroke="black"
            stroke-width="0.418732"
            strokeMiterlimit="10"
          />
        </svg>
        <p className="noRequestsText">{text}</p>
      </div>
    </div>
  );
}

export default NoRequestsFound;
