/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Popover, Tab, Tabs, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { useState } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Download from "../../../../Assets/Download.svg";

import { useDeferredValue } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { confirmAlert } from "react-confirm-alert";
import { SalaryTempLate } from "./SalaryTempLate";
import { useSelector } from "react-redux";
const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
function SalarySlip() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const returnPage = () => {
    navigate("/payroll/preparepayroll/ongoing");
  };

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (eid, month) => {
    setIsOpen((prev) => !prev);
  };
  const { LoggedInUser } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(null);
  const [Deduction, setDeduction] = useState(null);
  const [employeeList, setEmployeeList] = useState(null);
  const [tabIndex, setTabIndex] = useState("employee");
  const [count, setCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [searchParam, setSearchParam] = useState(null);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol - Prpare Payroll  `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };

  const { state } = useLocation();

  const editId = state?.id;
  useEffect(() => {
    if (editId) {
      getFormDetails();
    }
  }, []);
  useEffect(() => {
    if (detailsform?.monthPayout) {
      getEmployeeList(detailsform);
    }
  }, [reqData]);
  const getEmployeeList = async (detail) => {
    const employeeResult = await postRequest(
      `PreparePayroll/SalarySlipList/${detail?.payAreaId}/${detail?.monthPayout}`,
      reqData
    );
    if (employeeResult) {
      setEmployeeList(employeeResult?.data?.data);
      setCount(employeeResult?.data?.summary?.allCount);
      calculateTotalPages(employeeResult?.data?.summary?.allCount);
    }
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PreparePayroll/Details/${editId}`);
      if (result) {
        setDetailsform(result?.data?.data);
        if (result?.data?.data?.monthPayout) {
          getEmployeeList(result?.data?.data);
        } else {
          dispatch(
            showToast({
              text: "Some error occured. Please try again later.",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getSalaryDownload = async (employeID, month) => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PreparePayroll/DownloadPayslip/${employeID}/${month}`
      );

      const link = document.createElement("a");
      console.log(
        "res",
        `${process.env.REACT_APP_.BASE_URL}/${result?.data?.message}`
      );
      link.href = `${process.env.REACT_APP_.BASE_URL}/${result?.data?.message}`; // Ensure this URL is valid

      // Ensure the link has the 'download' attribute for initiating download
      link.setAttribute("download", "payslip");

      // Ensure the download is triggered within a user action, like a button click
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // Handle errors appropriately
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;
  else
    return (
      <div className="moduleMainContainer">
        <div className="preparePayrollTableContainer">
          <div
            className="tableHeaderContainer"
            style={{ padding: "0 0 0 12px" }}
          >
            <div
              className="returnToDashboard cursor"
              onClick={() => {
                navigate(-1);
              }}
            >
              <svg
                width="20"
                height="8"
                viewBox="0 0 20 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.243615 3.74484C0.0483524 3.9401 0.0483524 4.25668 0.243615 4.45194L3.4256 7.63392C3.62086 7.82918 3.93744 7.82918 4.1327 7.63392C4.32796 7.43866 4.32796 7.12208 4.1327 6.92682L1.30427 4.09839L4.1327 1.26996C4.32796 1.0747 4.32796 0.758117 4.1327 0.562855C3.93744 0.367593 3.62086 0.367593 3.4256 0.562855L0.243615 3.74484ZM18.624 4.59839C18.9002 4.59839 19.124 4.37453 19.124 4.09839C19.124 3.82225 18.9002 3.59839 18.624 3.59839V4.59839ZM0.597168 4.59839H18.624V3.59839H0.597168V4.59839Z"
                  fill="#1296B0"
                />
              </svg>

              <label className="returnLabelHolder">
                {`Salary Slip ${detailsform?.monthPayout}`}
              </label>
            </div>

            <div className="search-box" title="Search">
              <input
                className="search-text"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchParam(e.target.value)}
              />
              <a className="search-btn" title="Search">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                    fill="#1296B0"
                  />
                </svg>
              </a>
            </div>
            <Tooltip
              arrow
              //title={`${filterSearchToogle ? "Cancel" : "Filter"}`}
              placement="top"
            >
              <IconButton
                color="primary"
                // onClick={() => {
                //   setFilterSearchToogle((prev) => !prev);
                //   importToggle
                //     ? setImportToggle((prev) => !prev)
                //     : setImportToggle((prev) => prev);
                // }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                    stroke="#1296B0"
                    strokeWidth="1.5"
                  />
                </svg>
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              //title={`${importToggle ? "Cancel" : "Upload"}`}
              placement="top"
            >
              <IconButton
                color="primary"
                // onClick={() => {
                //   setImportToggle((prev) => !prev);
                //   filterSearchToogle
                //     ? setFilterSearchToogle((prev) => !prev)
                //     : setFilterSearchToogle((prev) => prev);
                // }}
              >
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.1949 0.95166V11.6183M10.1949 0.95166L6.63936 4.50722M10.1949 0.95166L13.7505 4.50722M1.30603 13.3961L1.85803 15.605C1.95417 15.9896 2.1761 16.331 2.48855 16.575C2.801 16.819 3.18604 16.9516 3.58247 16.9517H16.8074C17.2038 16.9516 17.5888 16.819 17.9013 16.575C18.2137 16.331 18.4357 15.9896 18.5318 15.605L19.0838 13.3961"
                    stroke="#1296B0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </div>
          <table className="attendance-table">
            <colgroup>
              <col />
              <col style={{ width: "100%" }} />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr className="tableHeaderRow" style={{ zIndex: "9" }}>
                <th>Actions</th>
                <th style={{ textAlign: "start" }}>Employee Name</th>
                <th>Status</th>
                <th>Bank Name</th>
                <th>Account Number</th>
                <th>Total Earning</th>
                <th>Total Deduction</th>
                <th>Total Reimbursement</th>
                <th>Total Net Payable</th>
                <th>Month</th>
              </tr>
            </thead>
            <tbody>
              {employeeList?.map((item) => (
                <tr key={item.item.id}>
                  <td style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      //  onClick={() => togglePopup(item?.item?.employeeId,item?.item?.month)}
                      onClick={() =>
                        confirmAlert({
                          customUI: ({ onClose }) => {
                            return (
                              <div
                                style={{
                                  backgroundColor: "white",
                                }}
                              >
                                <SalaryTempLate
                                  LoggedInUser={LoggedInUser}
                                  onClose={onClose}
                                  download={false}
                                  employeID={item?.item?.employeeId}
                                  month={item?.item?.month}
                                />
                              </div>
                            );
                          },
                        })
                      }
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.498413"
                          y="0.713379"
                          width="23.1616"
                          height="23.1616"
                          rx="5.01954"
                          fill="#D8F0F2"
                        />
                        <path
                          opacity="0.15"
                          d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
                          fill="#0DB53C"
                        />
                        <line
                          x1="14.7295"
                          y1="7.43701"
                          x2="7.72949"
                          y2="7.43701"
                          stroke="#0DB53C"
                          strokeLinecap="round"
                        />
                        <line
                          x1="5.72949"
                          y1="7.43701"
                          x2="5.61462"
                          y2="7.43701"
                          stroke="#0DB53C"
                          strokeLinecap="round"
                        />
                        <line
                          x1="14.7295"
                          y1="11.3437"
                          x2="7.72949"
                          y2="11.3437"
                          stroke="#0DB53C"
                          strokeLinecap="round"
                        />
                        <line
                          x1="5.72949"
                          y1="11.3437"
                          x2="5.61462"
                          y2="11.3438"
                          stroke="#0DB53C"
                          strokeLinecap="round"
                        />
                        <line
                          x1="14.7295"
                          y1="15.2505"
                          x2="7.72949"
                          y2="15.2505"
                          stroke="#0DB53C"
                          strokeLinecap="round"
                        />
                        <line
                          x1="5.72949"
                          y1="15.2505"
                          x2="5.61462"
                          y2="15.2505"
                          stroke="#0DB53C"
                          strokeLinecap="round"
                        />
                      </svg>
                    </IconButton>
                    <IconButton
                      //  onClick={() => togglePopup(item?.item?.employeeId,item?.item?.month)}
                      onClick={() =>
                        confirmAlert({
                          customUI: ({ onClose }) => {
                            return (
                              <div
                                style={{
                                  backgroundColor: "white",
                                }}
                              >
                                <SalaryTempLate
                                  LoggedInUser={LoggedInUser}
                                  download={true}
                                  onClose={onClose}
                                  employeID={item?.item?.employeeId}
                                  month={item?.item?.month}
                                />
                              </div>
                            );
                          },
                        })
                      }
                    >
                      <img src={Download} alt="down" />
                    </IconButton>
                    {/* <IconButton
                      //  onClick={() => togglePopup(item?.item?.employeeId,item?.item?.month)}
                      onClick={() => {}}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.679688"
                          y="0.417969"
                          width="24"
                          height="24"
                          rx="2"
                          fill="#ECECF5"
                        />
                        <path
                          d="M15.29 6.05178C15.29 5.43463 14.7851 4.92969 14.1679 4.92969H5.19119C4.57403 4.92969 4.06909 5.43463 4.06909 6.05178V12.7844C4.06909 13.4015 4.57403 13.9064 5.19119 13.9064H14.1679C14.7851 13.9064 15.29 13.4015 15.29 12.7844V6.05178ZM14.1679 6.05178L9.67957 8.85702L5.19119 6.05178H14.1679ZM14.1679 12.7844H5.19119V7.17388L9.67957 9.97911L14.1679 7.17388V12.7844Z"
                          fill="#A0A0CB"
                        />
                      </svg>
                    </IconButton> */}
                  </td>
                  <td style={{ textAlign: "start", cursor: "pointer" }}>
                    {item.item.employee}
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: getStatusColor(
                          item?.item?.paymentStatus
                        )?.split(",")?.[0],
                        color: getStatusColor(item?.item?.paymentStatus)?.split(
                          ","
                        )?.[1],
                        cursor: "default",
                        padding: "6px",
                        borderRadius: "6px",
                      }}
                    >
                      {item?.item?.paymentStatus}
                    </div>
                  </td>
                  <td>{item?.item?.bank ?? "-"}</td>
                  <td>{item?.item?.account ?? "-"}</td>
                  <td>{item.item.totalEarning}</td>
                  <td>{item.item.totalDeduction}</td>
                  <td>{item.item.totalReimbursement}</td>
                  <td>{item.item.totalNetPayable}</td>
                  <td>{item.item.month}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="horizontalLine"></div>
          <div className="tableFooterContainer">
            <label>Total Record Count: </label>
            <span>{count}</span>
            <SelectCustom
              searchable={false}
              options={noOfRecords}
              dropdownPosition="top"
              values={[recordsSelected]}
              onChange={(e) => paginationChanged(e)}
            />
            <label>Records Per Page</label>
            {currentPage > 1 ? (
              <IconButton
                onClick={() => pageChangedPrev()}
                color="primary"
                className="pageChangerIcon pageChangerIconActive"
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton disabled color="primary" className="pageChangerIcon">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
            {currentPage} - {totalPages}
            {totalPages > currentPage ? (
              <IconButton
                onClick={() => pageChangedNext()}
                color="primary"
                className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton
                disabled
                color="primary"
                className={`pageChangerIcon pageChangerIconNext`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
          </div>
        </div>
        {}
      </div>
    );
}

export default SalarySlip;
