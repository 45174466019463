import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { StyledTableCell, StyledTableCont } from "../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { SelectFormikMulti } from "../../../Components/FormComponent/SelectFormikMulti";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
export const CreatePipelineComp = ({ data }) => {
  const dispatch = useDispatch();
  const option = [
    { label: "Yes", value: true },
    { label: "No", value: false }
  ]
  const opt = [
    { label: "select", value: null }
  ]
  const [pipelineStageList, setPipelineStageList] = useState([]);
  const [pipelineStatusList, setPipelineStatusList] = useState([]);
  const [locationValuesArray, setLocationValuesArray] = React.useState([]);
  const getPipelineStages = async () => {
    try {
      const result = await getRequest('PipelineStage/GetStages');
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.title, value: item.id })
      })
      setPipelineStageList(listData);
    } catch (error) {
      dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
    } finally {
    }
  }

  useEffect(()=>{
    document.title = `PeopleSol - Create Pipeline`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  const getPipelineStatus = async () => {
    try {
      const result = await getRequest('PipelineStatus/GetStatusActionName');
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.action, value: item.displayName })
      })
      setPipelineStatusList(listData);
    } catch (error) {
      dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
    } finally {
    }
  }
  useEffect(() => {
    getPipelineStages();
    getPipelineStatus();
  }, [])
  const getLocationValues = (index) => {
    return locationValuesArray[index]
  }
  useEffect(() => {
    if (pipelineStatusList.length >= 0) {
      data?.stages?.map((item, index) => {
        let loactionIdArray = [];
        item?.statusNames?.map((loction) => {
          let tempVal = pipelineStatusList?.filter((options) => options?.value === loction)
          loactionIdArray.push(tempVal[0])
        })
        setLocationValuesArray(prev => {
          return { ...prev, [index]: loactionIdArray }
        })
      })
    }
  }, [data, pipelineStatusList])
  return (
    <div>
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
          name="title"
          type={"text"}
          placeholder={"Enter title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"TAT (in Hrs)"} mandatory={true} />
        <InputCustomFormik
          name="tatInHrs"
          step=".001"
          placeholder={"Enter TAT (in Hrs)"}
        />
      </div>
      <div style={{ display: "grid" }}>
        <LabelCustom labelName={"Stages"} />
        <FieldArray name="stages">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { stages } = values;

            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Sl. No."} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Stage"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Status"}/>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {stages?.map((stages, index) => (
                    <TableBody key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <SelectForm
                          values={
                            stages
                              ? pipelineStageList.filter(
                                (options) => options.value === stages.id
                              )
                              : opt}
                            name={`stages[${index}].id`} options={pipelineStageList}
                        />

                      </StyledTableCell>


                      <StyledTableCell>

                        <SelectFormikMulti
                          values={getLocationValues(index)}
                            name={`stages[${index}].statusNames`} options={pipelineStatusList}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {index > 0 && (
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() => remove(index)}
                          >
                            -
                          </button>
                        )}
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({ statusNames: [], id: "" })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};