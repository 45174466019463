import CRTf1 from "../Assets/certificate1.png";
import CRTf2 from "../Assets/certificate2.png";
import CRTf3 from "../Assets/certificate3.png";
import CRTf4 from "../Assets/certificate4.png";
import CRTf5 from "../Assets/certificate5.png";

export const rewardsCertificateTemplateArray = [
  { images: CRTf1, value: "CertificateTemplate1" },
  { images: CRTf2, value: "CertificateTemplate2" },
  { images: CRTf3, value: "CertificateTemplate3" },
  { images: CRTf4, value: "CertificateTemplate4" },
  { images: CRTf5, value: "CertificateTemplate5" },
];
