import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import "./Connect.css";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";

const CommentActivityContainer = forwardRef((props, ref) => {
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const updateState = () => setIsActive((prev) => !prev);
  const closeState = () => setIsActive(false);
  useImperativeHandle(ref, () => ({
    updateState,
    closeState,
  }));
  const moreActivityHandler = useCallback((e) => {
    let moreActivity = document.querySelector(
      `.socialPostActivityContainer${props.commentid}`
    );
    if (moreActivity && !moreActivity?.contains(e.target)) {
      setIsActive(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener("mouseup", moreActivityHandler);
    return () => {
      document.removeEventListener("mouseup", moreActivityHandler);
    };
  }, [moreActivityHandler]);
  const handleDelete = async () => {
    try {
      props.deleteComment(props.commentid);
      if (props.loadReply) {
        props.loadReply(props.commentid);
      }
      if (props.getPostCommentData) {
        let data = props?.allCommentData?.filter((d) => {
          if (d?.item?.id !== props.commentid) {
            return d;
          }
        });
        props.setAllCommentData(data);
      }

      setIsActive(false);
      dispatch(
        showToast({
          text: "Comment deleted successfully!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in posting comment",
          severity: "error",
        })
      );
    } finally {
    }
  };

  return (
    <div
      className={`socialPostActivityContainer socialPostActivityContainer${props.commentid}`}
      style={{ cursor: "pointer" }}
    >
      <div
        className="rightSocialPostHeaderContainer"
        onClick={() => {
          setIsActive(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="17"
          viewBox="0 0 3 17"
          fill="none"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="#D9D9D9" />
          <circle cx="1.5" cy="8.25" r="1.5" fill="#D9D9D9" />
          <circle cx="1.5" cy="15" r="1.5" fill="#D9D9D9" />
        </svg>
      </div>
      {isActive && (
        <div className="socialPostActivityHolder">
          <div className="headingFilterHeader">
            <h5
              onClick={() => {
                // navigate("edit");
                props.setCommentInputTxt(props.comment);
                props.setCommentId(props.commentid);
                props.setEditCommentActive(true);
              }}
            >
              Edit comment
            </h5>
          </div>
          <div className="headingFilterHeader">
            <h5
              onClick={() => {
                confirmAlert({
                  title: "Delete",
                  message: `Are you sure you want to delete this comment?`,
                  buttons: [
                    {
                      label: "Cancel",
                      onClick: () => setIsActive(false),
                    },
                    {
                      label: "Delete",
                      onClick: () => handleDelete(),
                    },
                  ],
                  overlayClassName: "customConfirmAlertContainer",
                });
              }}
            >
              Delete comment
            </h5>
          </div>
        </div>
      )}
    </div>
  );
});

export default CommentActivityContainer;
