/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";

export const CreateLeaveBalanceComp = ({ data, editID }) => {
  const dispatch = useDispatch();
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");
  const opt = [{ label: "select", value: null }];

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };

  useEffect(() => {
    document.title = `PeopleSol - Create Leave Balance `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    if (editID) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (editID) {
      setPage(0);
      let idArray;
      if (data?.leaveBalances?.employeeId)
        idArray = [data?.leaveBalances?.employeeId];
      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);
  const [leaveTypeList, setLeaveTypeList] = useState([]);

  const getLeaveTypeFilter = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLeaveTypeName");
      let listData = [];
      result?.data?.map((item, index) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setLeaveTypeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getLeaveTypeFilter();
  }, []);
  return (
    <div style={{ flexDirection: "row", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Employee"} mandatory={true} />
        <SelectForm
          placeholder={" Select Employee"}
          disabled={JSON.stringify(data) === "{}" ? false : true}
          name="employeeId"
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Leave Type Name"} mandatory={true} />
        <SelectForm
          placeholder={" Select Leave Type Name"}
          disabled={JSON.stringify(data) === "{}" ? false : true}
          values={
            data
              ? leaveTypeList.filter(
                (options) => options.value === data?.leaveTypeId
              )
              : opt
          }
          name="leaveTypeId"
          options={leaveTypeList}
        />
      </div>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Opening Balance"} mandatory={true} />
        <InputCustomFormik
          min="-999"
          max={999}
          name="openingBalance"
          step=".001"
          placeholder={"Enter Opening Balance"}
        />
      </div>
    </div>
  );
};
