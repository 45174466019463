import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { SelectFormikMultiString } from "../../../../Components/FormComponent/SelectFormikMultiString";
import { useEffect } from "react";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";

const weekdaysOptions = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

export const TitleComp = ({ areaOptions, data, edit }) => {
  const dispatch = useDispatch();
  const [optionArray, setOptionArray] = React.useState([]);
  useEffect(() => {
    data?.weekOffDays?.split(",").map((item) => {
      let tempVal = weekdaysOptions?.filter(
        (options) => options?.value === item
      );
      if (tempVal.length > 0) {
        setOptionArray((prev) => {
          var contains = prev.some((elem) => {
            return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
          });
          if (!contains) {
            return [...prev, tempVal[0]];
          } else {
            return prev;
          }
        });
      }
      return null;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Title `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"Title"} mandatory={true} />
          <InputCustomFormik
            maxLength={50}
            name="title"
            type={"text"}
            placeholder={"Enter Title"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Code"} mandatory={true} />
          <InputCustomFormik
            maxLength={6}
            name="shiftCode"
            type={"text"}
            placeholder={"Enter Code"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Area "} mandatory={true} />
          <SelectForm
            placeholder={"Select Area"}
            name={"areaIds"}
            options={areaOptions}
          />
        </div>
        <div>
          <LabelCustom labelName={"Week Off Days "} />
          <SelectFormikMultiString
            placeholder={"Select Week Off Days"}
            name={"weekOffDays"}
            options={weekdaysOptions}
            values={optionArray}
          />
        </div>
        <div>
          <LabelCustom labelName={"From Time "} />
          <TimeCustomFormik placeholder={"Select From Time"} name={"From"} />
        </div>
        <div>
          <LabelCustom labelName={"To Time "} />
          <TimeCustomFormik placeholder={"Select To Time"} name={"To"} />
        </div>
        {edit && (
          <div>
            <LabelCustom labelName={"Is Active?"} />
            <YesNoFormik name={"isActive"} />
          </div>
        )}
      </div>
      <div className="headingForm">Additional Week Offs</div>
      <div className="repeatedSectionContainer">
        <FieldArray name="additionalWeekoffs">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { additionalWeekoffs } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        S. No
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        Date
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        Add/Remove
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {additionalWeekoffs.map((additionalWeekoffss, index) => (
                    <TableBody key={index}>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                        <DatePickerFormiks
                          name={`additionalWeekoffs[${index}].weekOffDates`}
                        />
                      </StyledTableCell>

                      {/* 3rd column */}
                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            additionalWeekoffs.length > 1
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() => push({ weekOffDates: "" })}
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </>
  );
};
