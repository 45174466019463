import React, { act, useState } from "react";
import "./MyProfileDetails.css";
import { Switch } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { useCallback } from "react";
import { useEffect } from "react";
import { ProfileGroup } from "./ProfileGroup";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { setUserSettings } from "../../../../Features/ChatSlice";
import GroupIcon from "../../../../Assets/ChatGroupIcon.svg";
import LinkIcon from "../../../../Assets/LinkIconWhite.svg";
import ResetLinkIcon from "../../../../Assets/ResetLink.svg";
import CopyLinkIcon from "../../../../Assets/CopyLinkIcon.svg";
import ShareLink from "../../../../Assets/ShareLinkViaChatIcon.svg";
import ChatSelectedCheck from "../../../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../../../Assets/ChatUnselectedCheck.svg";
import SearchIcon from "../../../../Assets/SearchMagnifierIcon.svg";

import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";

function GroupInvitePage({ setShowGroupInvite, activeChat, newSocket, setShowLinkSetting, refresh }) {

    const userSettings = useSelector((state) => state.chat.userSettings);
    const [inviteLink, setInviteLink] = useState(null);
    const [showContact, setShowContact] = useState(false);
    const userDetails = useSelector(state => state.chat.userDetails);
    const chatList = useSelector((state) => state.chat.conversationList);
    const contactsList = useSelector((state) => state.chat.contactList);
    useEffect(() => {
        document.title = `PeopleSol - My Profile Detail`;
        return () => {
            document.title = 'PeopleSol';
        };
    })

    const dispatch = useDispatch();
    const myProfileHandler = useCallback((e) => {
        let moreActivity = document.querySelector(".myProfileDetailsMainContainer");
        if (moreActivity && !moreActivity?.contains(e.target)) {
            // setMyProfileDetails(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mouseup", myProfileHandler);
        return () => {
            document.removeEventListener("mouseup", myProfileHandler);
        };
    }, [myProfileHandler]);


    useEffect(() => {
        fetchLink();
    }, [])

    const fetchLink = () => {
        if (activeChat) {
            newSocket.emit("getInviteLink", ({ "conversationId": activeChat._id, "exipredOn": "" }), (res) => {
                newSocket.emit("getChatConfig", ({}), (resp) => {
                    setInviteLink(resp.data.prefixForInviteLink + res.data.inviteLink.substring(1, res.data.inviteLink.length));
                })
            })
        }
    }
    const handleReset = () => {
        newSocket.emit("updateInviteLink", ({ "conversationId": activeChat._id, "reqType": "reset", "exipredOn": "" }), (res) => {
            dispatch(
                showToast({ text: "Link updated successfully", severity: "success" })
            );
            fetchLink();
        })
    }

    const ContactList = () => {
        const [contactsToShow, setContactsToShow] = useState([]);
        const [copy, setCopy] = useState([]);
        const [selected, setSelected] = useState([]);

        useEffect(() => {
            getContacts()
        }, [])

        const handleSendMessagesToAllConversation = (converations) => {
            let selectedConvo = [];
            for (let i = 0; i < selected.length; i++) {
                if (selected[i] != userDetails._id) {
                    for (let j = 0; j < converations.length; j++) {
                        if (converations[j].type === "Contact") {
                            if (converations[j].participateMembers[0].contactId == selected[i] || converations[j].participateMembers[1].contactId == selected[i]) {
                                selectedConvo.push(converations[j]);
                            }
                        }
                    }
                }
            }
            sendInviteLinkToConversations(selectedConvo)
        }


        async function sendInviteLinkToConversations(selectedConvo) {
            for (let i = 0; i < selectedConvo.length; i++) {
                let obj = {
                    "conversationId": selectedConvo[i]._id,
                    "senderId": userDetails._id,
                    "personalNumber": userDetails.personalNumber,
                    "type": 14,
                    "caption": inviteLink,
                    "content": [
                        {
                            "conversationId": parseInt(inviteLink.split("/")[3]),
                            "inviteLink": ("/" + inviteLink.split("/")[3] + "/" + inviteLink.split("/")[4])
                        }
                    ]
                };

                console.log(`-------- payload ${i} ------`, JSON.stringify(obj));

                // Wait for each message to be sent before proceeding to the next
                await new Promise((resolve, reject) => {
                    newSocket.emit("addMessage", obj, (res) => {
                        dispatch(
                            showToast({ text: "Sent link successfully!", severity: "success" })
                        );
                        console.log("--- res from add link message", res);
                        resolve();  // Move to the next iteration
                    });
                });
            }

            // Once all messages are sent, show the confirmation dialog
            setShowContact(false);
            confirmAlert({
                title: "Link sent successfully!",
                message: "Link for this group is sent to the selected contacts",
                buttons: [
                    {
                        label: "Ok",
                        onClick: () => {
                            refresh(newSocket);
                        },
                    },
                ],
                overlayClassName: "customConfirmAlertContainer",
            });
        }

        const handleSendLink = () => {
            // create new conversations
            let noConversations = [];
            for (let i = 0; i < selected.length; i++) {
                let hasConvo = false;
                for (let j = 0; j < chatList.length; j++) {
                    if (chatList[j].type === "Contact") {
                        if (chatList[j].participateMembers[0].contactId == selected[i] || chatList[j].participateMembers[1].contactId == selected[i]) {
                            hasConvo = true;
                            break;
                        }
                    }
                }
                if (!hasConvo) {
                    let a = contactsList.filter(item => item._id === selected[i])[0];
                    noConversations.push({
                        "contactId": a._id,
                        "personalNumber": a.personalNumber
                    })
                }
            }
            console.log("------------> conversta", noConversations)
            createConversationsAndFetch(noConversations);
        }

        async function createConversationsAndFetch(noConversations) {
            for (let i = 0; i < noConversations.length; i++) {
                let payload = {
                    "type": "Contact",
                    "title": "",
                    "icon": "",
                    "participateMembers": [
                        {
                            "contactId": userDetails._id,
                            "personalNumber": userDetails.personalNumber
                        },
                        { ...noConversations[i] }
                    ],
                    "createdByContactId": userDetails._id
                }

                // Awaiting each conversation creation
                await new Promise((resolve, reject) => {
                    newSocket.emit("addConversation", payload, (res) => {
                        dispatch(
                            showToast({ text: "Created new conversation", severity: "success" })
                        );
                        resolve();  // Proceed to the next iteration
                    });
                });
            }

            // Fetch all conversations after the loop completes
            newSocket.emit("getMyConversations", {}, (response) => {
                handleSendMessagesToAllConversation(response.data.myConversations);
            });
        }


        const getContacts = () => {
            const onlyContact = chatList.filter(item => item.type === "Contact");
            let groupMem = [];
            for (let i = 0; i < activeChat.participateMembers.length; i++) {
                groupMem.push(activeChat.participateMembers[i].contactId);
            }
            let allContactsToInclude = [];
            for (let i = 0; i < onlyContact.length; i++) {
                if (onlyContact[i].participateMembers[0].contactId != userDetails._id && !groupMem.includes(onlyContact[i].participateMembers[0].contactId))
                    allContactsToInclude.push({
                        ...onlyContact[i].participateMembers[0],
                        name: onlyContact[i].participateMembers[0].contactName,
                        _id: onlyContact[i].participateMembers[0].contactId,
                    });
                else if (onlyContact[i].participateMembers[1].contactId != userDetails._id && !groupMem.includes(onlyContact[i].participateMembers[1].contactId))
                    allContactsToInclude.push({
                        ...onlyContact[i].participateMembers[0],
                        name: onlyContact[i].participateMembers[0].contactName,
                        _id: onlyContact[i].participateMembers[0].contactId,
                    });
            }

            for (let i = 0; i < contactsList.length; i++) {
                if (!groupMem.includes(contactsList[i]._id)) {
                    if (allContactsToInclude.filter(item => item.contactId == contactsList[i]._id).length == 0) {
                        allContactsToInclude.push(contactsList[i]);
                    }
                }
            }
            setContactsToShow([...allContactsToInclude])
            setCopy([...allContactsToInclude])
        }

        return (
            <div style={{ width: "100%", backgroundColor: "white", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div style={{ padding: 20, paddingBottom: 50, paddingTop: 60, width: "100%" }}>
                    <div style={{ position: "absolute", width: "100%", top: 70, left: 0, right: 0, }}>
                        <div style={{ backgroundColor: "rgba(216, 240, 242, 1)", padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderRadius: 6, marginLeft: 20, marginRight: 20 }}>
                            <div style={{ color: "#1296B0" }}>
                                <input placeholder="Search for " type="text" onChange={(e) => {
                                    if (e.target.value)
                                        setCopy([...contactsToShow.filter(item => (item.name.toLowerCase().includes(e.target.value.toLowerCase()) || item.designation.toLowerCase().includes(e.target.value.toLowerCase())))])
                                    else setCopy(contactsToShow)
                                }} style={{ borderStyle: "none", outline: "none", backgroundColor: "rgba(216, 240, 242, 1)", }} />
                            </div>

                            <div>
                                <img style={{ height: 15, width: 15 }} src={SearchIcon} />
                            </div>
                        </div>
                    </div>
                    {copy.map((contact, index) => (
                        <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                            <div onClick={() => {
                                if (selected.includes(contact._id)) {
                                    let a = selected.filter(item => item != (contact._id));
                                    setSelected([...a]);
                                }
                                else {
                                    setSelected([...selected, contact._id])
                                }
                            }} style={{ marginRight: 10 }} >

                                <img src={selected.includes((contact._id)) ? ChatSelectedCheck : ChatUnselectedCheck} />
                            </div>
                            {contact.image != "" ? <img style={{ height: 45, width: 45, borderRadius: 25 }} src={contact.image} />
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 45, width: 45, borderRadius: 25, backgroundColor: "rgba(216, 240, 242, 1)", color: "rgba(18, 150, 176, 1)" }}>
                                    {
                                        contact.name
                                            ?.split(" ")
                                            ?.slice(0, 2)
                                            ?.map((item) => {
                                                return item?.charAt(0)?.toUpperCase();
                                            })
                                            .join("")
                                    }
                                </div>
                            }
                            <div style={{ marginLeft: 10 }}>
                                <div style={{ color: "rgba(18, 150, 176, 1)", fontSize: 13 }}>{contact.name ? contact.name : contact.contactName}</div>
                                <div style={{ color: "grey", fontSize: 10 }}>{contact.designation}</div>
                            </div>
                        </div>
                    ))}
                </div>
                {selected.length > 0 && <div style={{ position: "absolute", width: "100%", bottom: 0 }}>
                    <div style={{ width: "100%", backgroundColor: "rgba(216, 240, 242, 1)", padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ color: "#1296B0" }}>
                            {"Selected " + selected.length}
                        </div>

                        <div onClick={() => { handleSendLink() }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="37"
                                height="37"
                                viewBox="0 0 37 37"
                                fill="none"
                            >
                                <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
                                <path
                                    d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
                                    stroke="white"
                                    stroke-width="1.10154"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
    return (
        <div className="chatContactListContainer" style={{ alignItems: 'flex-start' }}>
            <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                    <svg
                        onClick={() => {
                            setShowGroupInvite(false);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                    >
                        <path
                            d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                            fill="#F7941D"
                        />
                    </svg>
                    <span>{"Invite Link"}</span>
                </div>
            </div>
            {showContact && <Popup
                popupHeading={"Select Contact"}
                contentClassName="newPad"
                color={"#1296B0"}
                //   needPrevious={showGroupMembers || createNewGroup ? true : false}
                //   handlePrevious={() => {
                //     setShowNewGroupMember(false);
                //     setCreateNewGroupMember(false);
                //   }}
                content={
                    <>
                        <ContactList />
                    </>
                }
                handleClose={() => { setShowContact(false) }}
            />}


            <div className="myProfileDetailsMainWrapper">
                <div style={{ backgroundColor: "rgba(255, 244, 229, 1)", padding: 15, margin: 10, borderRadius: 6 }}>
                    <div style={{ color: "rgba(85, 85, 85, 1)", fontSize: 12 }}>{"Anyone can use link to join this call. Only share it with people you trust."}</div>
                    <div style={{ width: "100%", height: 1, backgroundColor: "rgba(255, 214, 164, 1)", marginTop: 20, marginBottom: 20 }}></div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ backgroundColor: "rgba(247, 148, 29, 1)", width: 70, height: 70, borderRadius: 35, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={LinkIcon} style={{ height: 30, width: 30 }} />
                        </div>
                        <div style={{ color: "rgba(25, 121, 190, 1)", fontSize: 12, width: "50%", marginLeft: 20 }}>
                            {inviteLink}
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", backgroundColor: "rgba(230, 230, 230, 1)", height: 1, marginTop: 15, }}></div>
                <div style={{ padding: 15 }}>
                    <div onClick={() => { setShowContact(true) }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>
                        <img style={{ marginRight: 10 }} src={ShareLink} />
                        <div>Send link via chat</div>
                    </div>
                    <div onClick={() => {
                        navigator.clipboard.writeText(inviteLink)
                        dispatch(
                            showToast({ text: "Link copied!", severity: "success" })
                        );
                    }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>
                        <img style={{ marginRight: 10 }} src={CopyLinkIcon} />
                        <div>Copy link</div>
                    </div>
                    <div onClick={() => {
                        confirmAlert({
                            title: "Reset Link ?",
                            message: "Are you sure want to reset the invite link for \"" + activeChat.title + "\" ? If you reset the link, no one will be able to use it to join this community.",
                            buttons: [
                                {
                                    label: "Cancel",
                                    onClick: () => { },
                                },
                                {
                                    label: "Reset Link",
                                    onClick: () => {
                                        handleReset()
                                    },
                                },
                            ],
                            overlayClassName: "customConfirmAlertContainer",
                        });
                    }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>
                        <img style={{ marginRight: 10 }} src={ResetLinkIcon} />
                        <div>Reset link</div>
                    </div>
                </div>
                <div style={{ marginTop: 100, padding: 15 }}>
                    <div style={{ fontSize: 12, color: "rgba(186, 186, 186, 1)" }}>{"This link should be active for every time. To change this "}</div>
                    <div onClick={() => { setShowLinkSetting() }} style={{ fontSize: 12, color: "rgba(18, 150, 176, 1)" }}>edit link settings</div>
                </div>
            </div>
        </div>
    );
}

export default GroupInvitePage;
