import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import {
  getContentLength,
  getFormattedCount,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import {
  communityProfileImages,
  profileImages,
} from "../../../../../Utilities/profileAndUploadImages";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import dateFormat from "dateformat";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import ContentSocialDisplay from "./ContentSocialDisplay";
import { useState } from "react";

function CommunityNestedSharedPost({ d, setViewsListActive, setViewsPostId }) {
  const [showContent, setShowContent] = useState(false);
  const { communityid } = useParams();
  const navigate = useNavigate();
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  return (
    <div className="engagePostContentHolder">
      <div className="nestedSharedPostListContainer">
        <div className="nestedSharedPostListWrapper">
          <div className="postHeaderContainer">
            {d?.sharedPost?.postType === "community" &&
            d?.sharedPost?.community?.id !== communityid ? (
              <div
                className="leftPostHeaderContainer"
                style={{ position: "relative" }}
              >
                <img
                  src={
                    d?.sharedPost?.community?.communityImage === null
                      ? SocialPostTeamIcon
                      : process.env.REACT_APP_.BASE_URL +
                        communityProfileImages +
                        d?.sharedPost?.community?.communityImage
                  }
                  alt=""
                  style={{
                    height: "50px",
                    width: "50px",
                  }}
                  onClick={() => {
                    navigate(`/home/social/community/${d?.community?.id}`);
                  }}
                />
                <div
                  onClick={() => {
                    handleUserProfileClick(d?.id);
                  }}
                  className={`${
                    d?.image
                      ? "socialProfilePicContainer"
                      : "socialCustomProfilePicCommunity"
                  }`}
                >
                  {!d?.image ? (
                    getUserInitials(d?.name)
                  ) : (
                    <img
                      className="socialProfilePic"
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        profileImages +
                        d?.image
                      }
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = ProfileAvatar;
                      }}
                    />
                  )}
                </div>

                <div
                  className="postMainTextContainer"
                  onClick={() => {
                    navigate(`/home/social/community/${d?.community?.id}`);
                  }}
                >
                  <label className="postMainName">
                    {d?.sharedPost?.community?.communityName}
                  </label>
                  <label className="dateAndViewsContainer">
                    Post from{" "}
                    <span className="nameofposter">
                      {getDisplayName(d?.nameWithPersonNumber)}{" "}
                    </span>
                    <span className="postHrTime">
                      {" "}
                      • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                    </span>
                    <article
                      onClick={(e) => {
                        e.stopPropagation();
                        setViewsListActive(true);
                        setViewsPostId(d?.id);
                      }}
                    >
                      <img
                        src={SocialPostViewsEyeIcon}
                        alt=""
                        className="socialViewsImgContainer"
                      />
                      <span className="socialViewsSpanContainer">
                        {d?.views < 10
                          ? "0" + d?.views
                          : getFormattedCount(d?.views)}{" "}
                        Views
                      </span>
                    </article>
                  </label>
                </div>
              </div>
            ) : d?.sharedPost?.postType === "team" ? (
              <div
                className="leftPostHeaderContainer"
                style={{ position: "relative" }}
                // onClick={() => {
                //   handleUserProfileClick(d?.employeeId);
                // }}
              >
                <img
                  src={SocialPostTeamIcon}
                  alt=""
                  style={{
                    height: "50px",
                    width: "50px",
                    // marginRight: "18px",
                  }}
                  onClick={() => {
                    navigate(`/home/social/community/${d?.community?.id}`);
                  }}
                />
                <div
                  onClick={() => {
                    handleUserProfileClick(d?.id);
                  }}
                  className={`${
                    d?.image
                      ? "socialProfilePicContainer"
                      : "socialCustomProfilePicCommunity"
                  }`}
                >
                  {!d?.image ? (
                    getUserInitials(d?.name)
                  ) : (
                    <img
                      className="socialProfilePic"
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        profileImages +
                        d?.image
                      }
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = ProfileAvatar;
                      }}
                    />
                  )}
                </div>

                <div className="postMainTextContainer">
                  <label className="postMainName">
                    {getDisplayName(d?.nameWithPersonNumber)}
                  </label>
                  <label>{d?.roleName}</label>
                  <label className="dateAndViewsContainer">
                    {d?.locationName}{" "}
                    <span className="postHrTime">
                      {" "}
                      • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                    </span>
                    <article
                      onClick={(e) => {
                        e.stopPropagation();
                        setViewsListActive(true);
                        setViewsPostId(d?.id);
                      }}
                    >
                      <img
                        src={SocialPostViewsEyeIcon}
                        alt=""
                        className="socialViewsImgContainer"
                      />
                      <span className="socialViewsSpanContainer">
                        {d?.views < 10
                          ? "0" + d?.views
                          : getFormattedCount(d?.views)}{" "}
                        Views
                      </span>
                    </article>
                  </label>
                </div>
              </div>
            ) : (
              <div
                style={{ position: "relative" }}
                className="leftPostHeaderContainer"
                onClick={() => {
                  handleUserProfileClick(d?.employeeId);
                }}
              >
                <div
                  onClick={() => {
                    handleUserProfileClick(d?.id);
                  }}
                  // style={{ margin: "12px 12px 12px 0" }}
                  className={`${
                    d?.image
                      ? "socialProfilePicContainer"
                      : "socialCustomProfilePic"
                  }`}
                >
                  {!d?.image ? (
                    getUserInitials(d?.name)
                  ) : (
                    <img
                      className="socialProfilePic"
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        profileImages +
                        d?.image
                      }
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = ProfileAvatar;
                      }}
                    />
                  )}
                </div>

                <div className="postMainTextContainer">
                  <label className="postMainName">
                    {getDisplayName(d?.nameWithPersonNumber)}
                  </label>
                  <label>{d?.roleName}</label>
                  <label className="dateAndViewsContainer">
                    {d?.locationName}{" "}
                    <span className="postHrTime">
                      {" "}
                      • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                    </span>
                    <article
                      onClick={(e) => {
                        e.stopPropagation();
                        setViewsListActive(true);
                        setViewsPostId(d?.id);
                      }}
                    >
                      <img
                        src={SocialPostViewsEyeIcon}
                        alt=""
                        className="socialViewsImgContainer"
                      />
                      <span className="socialViewsSpanContainer">
                        {d?.views < 10
                          ? "0" + d?.views
                          : getFormattedCount(d?.views)}{" "}
                        Views
                      </span>
                    </article>
                  </label>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="postBodyContainer"
            onClick={() => {
              navigate("details/" + d?.id);
            }}
          >
            <div className="postBodyTextContainer">
              {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
                <ContentSocialDisplay content={getContentLength(d?.content)} />
              ) : (
                <ContentSocialDisplay content={d?.content?.trim()} />
              )}
              {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
                <span
                  onClick={() => {
                    setShowContent(true);
                  }}
                >
                  ...View More
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityNestedSharedPost;
