import React from "react";
import { useNavigate } from "react-router-dom";

import { SelectCustom } from "../../../../../../../Components/FormComponent/SelectCustom";

function SurveyHeader({
  selectSurveyPostType,
  setSelectSurveyPostType,
  hType,
  updateSurveyValue,
}) {
  const navigate = useNavigate();
  const surveyList = [
    { label: "My Survey", value: "mySurvey" },
    { label: "Other Survey", value: "otherSurvey" },
  ];
  const pollList = [
    { label: "My Poll", value: "myPoll" },
    { label: "Other Poll", value: "otherPoll" },
  ];

  return (
    <div className="surveyTableHeaderContainer">
      <button
        style={{ margin: "0px" }}
        onClick={() => {
          navigate("/home/social/surveycreate", { state: { type: hType } });
        }}
        className="button primaryButton"
        type="submit"
      >
        + Create
      </button>
      <div style={{ width: "30%" }}>
        <SelectCustom
          searchable={false}
          dropdownHeight="236px"
          styles={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
          values={selectSurveyPostType}
          options={hType === "survey" ? surveyList : pollList}
          onChange={(value) => {
            setSelectSurveyPostType(value);
            updateSurveyValue(value[0]?.value);
          }}
          fullWidth={true}
          styl={{
            color: "var(--primary)",
            minHeight: "36px",
            maxHeight: "40px",
            padding: "5px 10px",
            border: "var(--primary) !important",
          }}
        />
      </div>
    </div>
  );
}

export default SurveyHeader;
