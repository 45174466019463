/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import CompPopup from "./CompPopup";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";

export const CreateOvertimeComp = ({ apiValues, formValues }) => {
  const typeOptions = [
    { label: "Weekly Off", value: "Weekly Off" },
    { label: "Holiday", value: "Holiday" },
  ];
  useEffect(() => {
    document.title = `PeopleSol - Create Overtime`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (formValues?.Date) {
      if (apiValues <= 0 && typeof apiValues !== "string") {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <CompPopup
                heading={"Sorry"}
                onClose={onClose}
                message={
                  "Overtime information unavailable for the selected date, please choose another date"
                }
              />
            );
          },
        });
      }
    }
  }, [apiValues]);

  //console.log("formValues", formValues);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Overtime Date"} mandatory={true} />
        <DatePickerFormiks name={"Date"} maxDate={new Date()} />
      </div>
      {/* <div>
        {apiValues ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <LabelCustom labelName={"Sign In Time "} />
              <label style={{ fontWeight: "600", paddingLeft: "14px" }}>
                : &nbsp; {apiValues?.starttime ? apiValues?.starttime : null}
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <LabelCustom labelName={"Sign Out Time"} />
              <label style={{ fontWeight: "600" }}>
                : &nbsp;{apiValues?.endtime ? apiValues?.endtime : null}
              </label>
            </div>
          </div>
        ) : null}
      </div> */}

      <div>
        <LabelCustom labelName={"Overtime Hours"} mandatory={true} />
        <InputCustomFormik
          name="OverTimeHours"
          type={"number"}
          value={apiValues > 0 ? apiValues?.toFixed(2) : ""}
          disabled={true}
        />
      </div>
    </div>
  );
};
