import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateItemsComp } from "./FormComp/CreateItemsComp";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { getRequest, postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";


const CreateItems = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Items`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const { state } = useLocation();

  const data = state?.dataEdit;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const createPageData = async (values) => {
    try {

      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest('FacilitySpecificationItems/Create', values);
      dispatch(showToast({ text: "Successfully Created", severity: "success" }));
      returnPage()

    } catch (error) {

      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "FacilitySpecificationItems/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage()

    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
      )
    }
  };

  const initialvalue = {
    name: "",

  };
  const validationSchema = Yup.object({
    name: Yup.string().max(149, " Name must be less than 150 characters").required("Please Enter Name "),

  });

  const onSubmit = (values) => {

    const edit = {
      ...values,
      id: data?.Id
    };

    const create = {
      ...values,
      id: 0,
      name: values.name,

      isDataActive: true,
    }
    data ? Edit(edit) : createPageData(create);

  }


  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/hrmsmaster/benefits/items");
  };

  return (
    <FormCustomFormik styles={{ width: "600px" }} FormHeading={data ? 'Edit Facility Specification Items' : 'Create  Facility Specification Items'} ButtonLabel={data ? 'Update Facility Specification Items' : 'Create Facility Specification Items'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
      data
        ? {
          ...initialvalue,
          name: data.Name,

        }
        : initialvalue
    } returnPage={returnPage} propsDiv={<CreateItemsComp />} />
  );
};

export { CreateItems };
