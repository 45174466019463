import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { format } from "date-fns";
import { useEffect } from "react";

const CompanyComp = ({ HeadText, BodyText, editActive }) => {
  const editAllowedList = [
    "organizationName",
    "contactPersonDesignation",
    "contactPersonPhoneNumber",
    "contactPersonEmailId",
    "contactPersonName",
    "companyPhoneNumber",
    "companyEmailId",
    "companyName",
    "companyAddress",
    "companyCity",
    "companyCountry",
    "companyFavIcon",
    "companyIndustry",
    "companyLogo",
    "companyState",
    "companyWebsite",
    "companyZip",
  ];
  const mandatoryList = [
    "organizationName",
    "contactPersonPhoneNumber",
    "contactPersonEmailId",
    "contactPersonName",
    "companyPhoneNumber",
    "companyEmailId",
    "companyName",
  ];
  function camelToCapital() {
    var result = HeadText.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  function canEdit() {
    return editAllowedList.includes(HeadText);
  }
  function isMandatory() {
    return mandatoryList.includes(HeadText);
  }
  function isNumber() {
    return HeadText.includes("PhoneNumber") || HeadText.includes("Zip");
  }
  function isClickable() {
    return (
      HeadText.includes("companyWebsite") ||
      HeadText.includes("companyEmailId") ||
      HeadText.includes("companyLogo") ||
      HeadText.includes("contactPersonEmailId")
    );
  }
  function isEmail() {
    return (
      HeadText.includes("companyEmailId") ||
      HeadText.includes("contactPersonEmailId")
    );
  }
  function getBodyData() {
    if (HeadText.includes("lastUpdatedOn") || HeadText.includes("createdOn"))
      return BodyText ? format(new Date(BodyText), "dd/MM/yyyy hh:mm a") : "-";
    else return BodyText ? BodyText : "-";
  }
  useEffect(() => {
    document.title = `PeopleSol - Day 1 Feedback`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Company `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="jobDetailContainer companyDetailContainer" style={{ height: "fit-content", marginBottom: 20 }}>
      {!editActive && <div className="verticalLine"></div>}
      <div style={{ marginBottom: 6 }}>
        <label>{camelToCapital()}</label>
        {isMandatory() && editActive && (
          <span style={{ color: "red" }}> *</span>
        )}
      </div>
      {editActive && canEdit() ? (
        <div style={{ paddingBottom: "12px", marginLeft: -14 }}>
          <InputCustomFormik
            name={HeadText}
            type={isNumber() ? "number" : "text"}
          />
        </div>
      ) : (
        <label className="jobDesignation companyHeadText">
          {isClickable() ? (
            <a
              href={isEmail() ? `mailto: ${getBodyData()}` : getBodyData()}
              target="blank"
            >
              {getBodyData()}
            </a>
          ) : (
            <>{getBodyData()}</>
          )}
        </label>
      )}
    </div>
  );
};

export default CompanyComp;
