import React from "react";

const ImageRepeater = ({
  number,
  total,
  imageUrl,
  imageUrlSecond,
  imgBlank,
}) => {
  const [integerPart, decimalPart] = number.toString().split(".");

  const integerVar = parseInt(integerPart, 10);
  const decimalVar = parseInt(decimalPart || "0", 10);
  const imagesArray = Array.from({ length: integerVar });
  const blankArray =
    decimalVar === 0
      ? Array.from({ length: total - integerVar })
      : Array.from({ length: total - integerVar - 1 });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {imagesArray.map((_, index) => imageUrl)}
      {decimalVar !== 0 && decimalVar === 5 && imageUrlSecond}
      {blankArray?.map((_, index) => imgBlank)}
    </div>
  );
};

export default ImageRepeater;
