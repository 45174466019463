import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";
const TeamMOnLate = () => {
  const [loading, setloading] = useState(false);
  const [monlatedata, setmonlatedata] = useState([]);

  const getapidata = async () => {
    try {
      setmonlatedata(true);
      const result = await getRequest("Settings/GetLateOnToday");
      setmonlatedata(result?.data);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getapidata();
    document.title = "PeopleSole - Team Member today Late";
    return () => (document.title = "PeopleSol");
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : monlatedata.length > 0 ? (
        <>
          <div style={{ height: "100%" }}>
            <div style={{ height: "560px" }}>
              <table className="responsive-table tableHeaderRow">
                {/* Table Header */}
                <thead style={{ position: "sticky" }}>
                  <tr>
                    <th>S. No</th>
                    <th>Request Number</th>
                    <th>Requester</th>
                    <th>Date</th>
                    <th>Module</th>
                  </tr>
                </thead>
                <tbody>
                  {monlatedata.map((item, index) => (
                    <>
                      <tr key={item?.index}>
                        <td>{index + 1}</td>
                        <td>{item?.requestNumber}</td>
                        <td>{item?.requester}</td>
                        <td>{item?.date}</td>
                        <td
                          style={{
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "300px",
                          }}
                        >
                          {item?.module}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="noDatastyle" style={{ padding: "75px 105px" }}>
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Data Found"}</h4>
        </div>
      )}
    </>
  );
};

export default TeamMOnLate;
