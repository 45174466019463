import React, { useState, useEffect } from 'react';
import { GalleryTab } from './GalleryTab';

const Gallery = () => {
    useEffect(() => {
        document.title = `PeopleSol - Gallery`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
         
            <GalleryTab title='Gallery'/>
           
        </div>
    )
}

export { Gallery }