import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../Services/axios";
import { ServiceVendors } from "./ServiceVendors";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { ServiceVendorsForm } from "./Form/ServiceVendorsForm";
import { servicevendors } from "./Schema";

const initialvalue = {
  name: "",
  phone: "",
  emailAddresses: "",
  emailCc: "",
  tatHigh: "",
  tatMedium: "",
  tatLow: "",
};


const CreateServiceVendors = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const getFormDetails = async () => {
    try {
      const result = await getRequest(`ITServiceVendors/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "some error occured", severity: "error" }));
    } finally {
    }
  };
  const postFormData = async (values) => {
    try {
      const result = await postRequest("ITServiceVendors/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
      ) 
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Service Vendor`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("ITServiceVendors/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "some error occured", severity: "error" }));
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const onSubmit = async (values) => {
    const edit = {
      ...values,
      Id: detailsform?.id,
      phone:String(values?.phone)
    };
    
    const create = {
      ...values,
      id:0,
      phone:String(values?.phone)
    };

    data?Edit(edit) :
     postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/itsmmaster/itdesk/servicevendors");
  };
  return (
    <FormCustomFormik
    FormHeading={data?'Edit Service Vendors':'Create Service Vendors'}
      validationSchema={servicevendors}
      ButtonLabel={data?'Update Service Vendors':'Create  Service Vendors'}
      onSubmit={onSubmit}
      initialvalue={
        data
        ? {
            ...initialvalue,
            name:detailsform?.name,
            phone: detailsform?.phone,
            emailAddresses: detailsform?.emailAddresses,
            tatHigh:detailsform?.tatHigh,
            tatMedium:detailsform?.tatMedium,
            tatLow:detailsform?.tatLow,
            emailCc:detailsform?.emailCc,
          }
        :
        initialvalue
      }
      returnPage={returnPage}
      propsDiv={<ServiceVendorsForm data={detailsform} />}
    />
  );
};

export { CreateServiceVendors };
