/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import * as Yup from "yup";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { Form, Formik } from "formik";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import StarRatings from "react-star-ratings";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { selfRatingObtain } from "../../../../Utilities";
import { useSelector } from "react-redux";
const SubmitSelfReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const isEdit = location?.state?.type === "Update Review";
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [formValues, setFormValues] = useState();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [starData, setStarData] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggleRow = (index) => {
    const expandedIndex = expandedRows.indexOf(index);
    const newExpandedRows = [...expandedRows];

    if (expandedIndex > -1) {
      newExpandedRows.splice(expandedIndex, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  console.log("object location?.state?", location?.state);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSPerformanceReview/ViewDetails?ID=${selectedId}&EmployeeId=${LoggedInUser?.id}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Submit Self Review `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (formValues) {
      formValues.totalWeightage = formValues?.Goalslist?.reduce(
        (sum, card) => sum + card?.Achievement,
        0
      );
    }
  }, [formValues]);
  useEffect(() => {
    if (data) {
      setInitialValues({
        Name: data?._pmsAppraisalCycles?.name,
        notes: data?.pmsReviews?.notes ?? "",
        dateSub: "",
        totalWeightage: null,
        Goalslist: getGoalslist(),
      });
    }
  }, [data]);
  const getStarDetails = async () => {
    try {
      const result = await getRequest(
        `PMSPerformanceReview/PerformanceRatingSlab?ID=${data?.pmsReviews?.performanceCycleID}&EmployeeId=${data?.pmsReviews?.employeeId}`
      );
      setStarData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    if (data?.pmsReviews?.performanceCycleID) getStarDetails();
  }, [data, LoggedInUser]);
  const getGoalslist = () => {
    let returnArray = [];
    isEdit
      ? data?._pmsAppraisalCycles?.goalslist?.map((item, index) => {
          returnArray.push({
            Name: item.name,
            Id: item.id,
            Description: item.description,
            StartDate: item.startDate ? new Date(item.startDate) : null,
            ExpectedEndDate: item.expectedEndDate
              ? new Date(item.expectedEndDate)
              : null,
            Kra: item.kra,
            Weightage: item.weightage,
            upload:
              item?.pmsPerformanceReviewFeedbacksUpdatesNew[0]
                ?.employeeAttachment,
            Achievement:
              item?.pmsPerformanceReviewFeedbacksUpdatesNew[0]
                ?.employeeAchievement,
            Remark:
              item?.pmsPerformanceReviewFeedbacksUpdatesNew[0]?.employeeRemarks,
          });
          return null;
        })
      : data?._pmsAppraisalCycles?.goalslist?.map((item, index) => {
          returnArray.push({
            Name: item.name,
            Id: item.id,
            Description: item.description,
            StartDate: item.startDate ? new Date(item.startDate) : null,
            ExpectedEndDate: item.expectedEndDate
              ? new Date(item.expectedEndDate)
              : null,
            Kra: item.kra,
            Weightage: item.weightage,
            upload: null,
            Achievement: "",
            Remark: "",
          });
          return null;
        });
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    Name: data?._pmsAppraisalCycles?.name,
    notes: data?.pmsReviews?.notes ?? "",
    dateSub: "",
    totalWeightage: null,
    Goalslist: getGoalslist(),
  });

  console.log("object initialValues", initialValues);
  console.log("object formValues", formValues);
  useEffect(() => {
    getDetails();
  }, []);
  const createFunction = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm(
        "PMSPerformanceReview/MyReview",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      navigate(-1);
      getDetails();
    }
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const onSubmit = async (values) => {
    if (
      values.Goalslist?.reduce((sum, card) => sum + card?.Achievement, 0) > 100
    ) {
      dispatch(
        showToast({
          text: "Achievement cannot be more than 100",
          severity: "error",
        })
      );
    } else {
      const formData = new FormData();
      formData.append("ReviewId", Number(data?.pmsReviews?.id));
      formData.append("Name", values?.name);
      formData.append("notes", values?.notes);
      formData.append("Id", Number(selectedId));
      formData.append("totalWeightage", String(values?.totalWeightage));

      values?.Goalslist.forEach((element, index) => {
        formData.append(`Goalslist[${index}].Id`, element?.Id);
        formData.append(`Goalslist[${index}].Name`, element?.Name);
        formData.append(
          `Goalslist[${index}].Description`,
          element?.Description
        );
        formData.append(
          `Goalslist[${index}].StartDate`,
          format(new Date(element?.StartDate), "yyyy-MM-dd") +
            "T" +
            format(new Date(element?.StartDate), "HH:mm:ss")
        );
        formData.append(
          `Goalslist[${index}].ExpectedEndDate`,
          format(new Date(element?.ExpectedEndDate), "yyyy-MM-dd HH:mm:ss")
        );
        formData.append(`Goalslist[${index}].Kra`, element?.Kra);
        formData.append(`Goalslist[${index}].Weightage`, element?.Weightage);
        formData.append(
          `Goalslist[${index}].Achievement`,
          element?.Achievement
        );
        formData.append(`Goalslist[${index}].Remark`, element?.Remark);
        formData.append(`Goalslist[${index}].EmployeeUpload`, element?.upload);
        formData.append(`Goalslist[${index}].EmployeeSendToLD`, false);
      });
      createFunction(formData);
    }
  };
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={onSubmit}
              validationSchema={Yup.object({
                Goalslist: Yup.array().of(
                  Yup.object().shape({
                    Achievement: Yup.string().required(
                      "Please enter the Achieved Percent"
                    ),
                  })
                ),
                // totalWeightage: Yup.string()
                //   .required("Please enter the Total Weightage")
                //   .test(
                //     "Is 100 percent",
                //     "Total Weightage % should be less than 120",
                //     (value) => Number(value) <= 120
                //   ),
              })}
            >
              {(formik) => {
                chooseFormValues && chooseFormValues(formik.values);
                return (
                  <Form style={{ height: "100%" }}>
                    <div className="selfParent">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={() => navigate(-1)}
                          style={{ display: "flex" }}
                          className="returnToDashboard cursor"
                        >
                          <div>
                            <svg
                              width="20"
                              height="8"
                              viewBox="0 0 20 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                                fill="#1296B0"
                              />
                            </svg>
                          </div>
                          <label className="returnLabelHolder">
                            {data?._pmsAppraisalCycles?.name}
                          </label>
                        </div>

                        <div>
                          <button
                            className="btnSelf"
                            onClick={togglePopup}
                            type="button"
                          >
                            View Rating Slab
                          </button>
                          {(data?.pmsReviews?.status ===
                            "Appraiser Review Pending" ||
                            data?.pmsReviews?.status ===
                              "Self Review Pending") && (
                            <button
                              className="btnSelf"
                              style={{ backgroundColor: "#1296B0" }}
                              type="submit"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>

                      <div
                        className="ratingSelf yz"
                        style={{ minWidth: "335px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div style={{ marignTop: "12px" }}>Self Rating :</div>
                          <div>
                            {" "}
                            <StarRatings
                              rating={selfRatingObtain(
                                formik.values.Goalslist?.reduce(
                                  (sum, card) => sum + card?.Achievement,
                                  0
                                ),
                                starData
                              )}
                              starRatedColor="#5BC4BF"
                              starSpacing="2px"
                              starEmptyColor="#FFFFFF"
                              numberOfStars={5}
                              starHoverColor="var(--primary)"
                              starDimension="28px"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div>Self Achievement % :</div>
                          <div>
                            <p
                              style={{
                                color: "#5BC4BF",
                                fontSize: "30px",
                                fontWeight: "500",
                                marginTop: "5px",
                              }}
                            >
                              {formik.values.Goalslist?.reduce(
                                (sum, card) => sum + card?.Achievement,
                                0
                              ) ?? 0}
                            </p>
                          </div>
                        </div>
                      </div>

                      {data?._pmsAppraisalCycles?.goalslist?.length > 0 ? (
                        <div style={{ width: "100%", overflow: "auto" }}>
                          <table
                            className="tabself tabself-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead
                              style={{
                                backgroundColor: "#FBFBFB",
                                top: 0,
                                position: "sticky",
                                zIndex: 2,
                              }}
                            >
                              <tr>
                                <td style={{ textAlign: "center" }}>
                                  <LabelCustom labelName={"S.no"} />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <LabelCustom labelName={"Objective"} />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <LabelCustom labelName={"Goal Name"} />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <LabelCustom labelName={"Start Date"} />
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    width: "156px",
                                  }}
                                >
                                  <LabelCustom
                                    labelName={"Expected Date of Achievement"}
                                  />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <LabelCustom labelName={"Goal Threshold"} />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <LabelCustom labelName={"Weightage %"} />
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {data?._pmsAppraisalCycles?.goalslist?.map(
                                (items, index) => {
                                  const isRowExpanded =
                                    expandedRows.includes(index);

                                  return (
                                    <React.Fragment key={index}>
                                      <tr
                                        className={`trStyles`}
                                        key={index}
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <td style={{ textAlign: "center" }}>
                                          <LabelCustom labelName={index + 1} />
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {items.name}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {items.description}

                                          <IconButton
                                            onClick={() => toggleRow(index)}
                                            sx={{
                                              display: isRowExpanded && "none",
                                              position: "absolute",
                                              bottom: "-18px",
                                              left: "50%",
                                              zIndex: "1",
                                              boxShadow:
                                                "0px 0px 10px rgba(0, 0, 0, 0.04);",
                                            }}
                                          >
                                            {!isRowExpanded && (
                                              <>
                                                <svg
                                                  width="14"
                                                  height="9"
                                                  viewBox="0 0 14 9"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M12.8765 0.898926L6.82826 6.94713L0.780053 0.898925"
                                                    stroke="#1296B0"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                  />
                                                </svg>
                                              </>
                                            )}
                                          </IconButton>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <LabelCustom
                                            labelName={
                                              items.startDate &&
                                              format(
                                                new Date(items.startDate),
                                                "dd/MM/yyyy"
                                              )
                                            }
                                          />
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <LabelCustom
                                            labelName={
                                              items.expectedEndDate &&
                                              format(
                                                new Date(items.expectedEndDate),
                                                "dd/MM/yyyy"
                                              )
                                            }
                                          />
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <LabelCustom labelName={items.kra} />
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <LabelCustom
                                            labelName={items.weightage}
                                          />
                                        </td>
                                      </tr>
                                      {isRowExpanded && (
                                        <tr>
                                          <td
                                            colSpan="7"
                                            style={{
                                              padding: "0",
                                            }}
                                          >
                                            <div className="borderDot"></div>
                                            <div className="formSelfReview">
                                              <div className="centertd">
                                                <div>Self</div>
                                                <div>Review</div>
                                              </div>
                                              <div
                                                style={{
                                                  flex: "1",
                                                  marginTop: "23px",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    paddingLeft: "12px",
                                                  }}
                                                >
                                                  {"Notes"}
                                                </label>
                                                <TextAreaFormik
                                                  styl={{ minHeight: "57px" }}
                                                  style={{
                                                    minWidth: "110px",
                                                    minHeight: "52px",
                                                    width: "329px",
                                                    height: "60px",
                                                    resize: "none",
                                                  }}
                                                  placeholder={
                                                    "Enter Description "
                                                  }
                                                  type="textarea"
                                                  name={`Goalslist[${index}].Remark`}
                                                  maxLength={500}
                                                />
                                              </div>
                                              <div
                                                style={{
                                                  flex: "1",
                                                  marginTop: "23px",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    paddingLeft: "12px",
                                                  }}
                                                >
                                                  {"Attachments"}{" "}
                                                </label>

                                                <FileUploadFormik
                                                  addPath="PmsPerformanceCycleReview/"
                                                  name={`Goalslist[${index}].upload`}
                                                />
                                                <span
                                                  style={{
                                                    paddingLeft: "12px",
                                                    color: "var(--secondary)",
                                                  }}
                                                >
                                                  Size: 2 MB
                                                </span>
                                                <span
                                                  style={{
                                                    paddingLeft: "12px",
                                                    color: "var(--secondary)",
                                                  }}
                                                >
                                                  Format: JPG, PNG, PDF , Doc
                                                  ,Docx
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  flex: "1",
                                                  marginTop: "23px",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    paddingLeft: "12px",
                                                  }}
                                                >
                                                  {"Achievement %"}
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      padding: "7px",
                                                    }}
                                                  >
                                                    {"*"}
                                                  </span>
                                                </label>
                                                <InputCustomFormik
                                                  style={{ width: "89%" }}
                                                  maxLength={150}
                                                  selectBoxOptions="Employee Onboarding;Candidate Onboarding;Finland;Germany;Mexico"
                                                  name={`Goalslist[${index}].Achievement`}
                                                  type={"number"}
                                                  placeholder={"Enter Type "}
                                                />
                                              </div>
                                            </div>
                                            <IconButton
                                              onClick={() => toggleRow(index)}
                                              sx={{
                                                left: "50%",
                                                zIndex: "9999",
                                                boxShadow:
                                                  "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                              }}
                                            >
                                              {isRowExpanded && (
                                                <>
                                                  <svg
                                                    width="15"
                                                    height="9"
                                                    viewBox="0 0 15 9"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M13.3755 7.62805L7.32728 1.57985L1.27908 7.62805"
                                                      stroke="#1296B0"
                                                      stroke-width="1.5"
                                                      stroke-linecap="round"
                                                    />
                                                  </svg>
                                                </>
                                              )}
                                            </IconButton>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <InputCustomFormik
                              name={"totalWeightage"}
                              type="hidden"
                              step="0.01"
                              disabled
                            />
                            <LabelCustom
                              style={{
                                color:
                                  formik.values.Goalslist?.reduce(
                                    (sum, card) => sum + card?.Achievement,
                                    0
                                  ) > 100
                                    ? "red"
                                    : "var(--primary)",
                              }}
                              labelName={`Total Achievement (%) : ${formik.values.Goalslist?.reduce(
                                (sum, card) => sum + card?.Achievement,
                                0
                              )}`}
                            />
                          </div>
                          <div className="reviewNotes">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                // width: "1087px",
                                gap: "17px",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className="notesSelf"
                                style={{ width: "348px" }}
                              >
                                <div className="headingSelf">
                                  <p>Self Notes</p>
                                </div>
                                <div className="detailSelf">
                                  <TextAreaFormik
                                    formikStyle="100%"
                                    name="notes"
                                    style={{
                                      minHeight: "50px",
                                      resize: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Rating Slab"}
          content={
            <>
              <InstructionSelf data={starData} />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export { SubmitSelfReview };
