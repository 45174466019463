/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "../../../../../Components/FormComponent/Index";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest, getRequestZ } from "../../../../../Services/axios";
import { Details } from "../../DetailsComp/Details";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";

import { saveAs } from "file-saver";
import { IconButton, Tooltip } from "@mui/material";


function VerifyMandatoryDocuments({ id, handleClose, getListData }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `Candidate/VerifyMandatoryDocumentsView/${id}`
      );
      setData(result?.data);
      // //console.log("result?.data?.data?.data", result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
      handleClose();
    } finally {
      setLoading(false);
    }
  };
  const exportZip = async () => {
    try {
      // dispatch(isLoading(true));
      // setLoading(true);
      const result = await getRequestZ(`Candidate/DownloadAll/${id}`, {
        responseType: "arraybuffer",
      });

      // Create a Blob object with the binary data received from the API
      const blob = new Blob([result.data], { type: "application/zip" });

      // Use FileSaver.js to trigger the download
      saveAs(blob, "Candidate Documents.zip");
    } catch (error) {


      // console.log(" error rtyruygu iuyiuy",error)
      dispatch(
        showToast({
          text: error?.data?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      // dispatch(isLoading(false));
      // setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Verify Mandatory Docs`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const verifyOnboarding = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `Candidate/VerifyMandatoryDocuments/${id}`
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      handleClose();
      getListData();
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="detailsEReq">
          <div className="detailInner">
            <Details
              detailTitle={"Name"}
              Details={data?.data?.candidate?.name}
            />
            <Details
              detailTitle={"Email Id"}
              Details={data?.data?.candidate?.email}
            />
            <Details
              detailTitle={"Phone Number"}
              Details={data?.data?.candidate?.phoneNumber}
            />
            <Details
              detailTitle={"Designation"}
              Details={
                data?.data?.candidate?.manPowerIndent?.vacanctDesigination
              }
            />
            <Details
              detailTitle={"Status"}
              Details={
                <div
                  style={{
                    backgroundColor: getStatusColor(
                      data?.summary?.status
                    )?.split(",")?.[0],
                    color: getStatusColor(data?.summary?.status)?.split(
                      ","
                    )?.[1],
                    cursor: "default",
                    padding: "6px",
                    borderRadius: "6px",
                    width: "fit-content",
                  }}
                >
                  {data?.summary?.status}
                </div>
              }
            />
            <Details
              detailTitle={"Created On"}
              Details={data?.data?.createdOn}
            />
          </div>
          <div>
            <div
              className="headingForm"
              style={{ justifyContent: "space-between" }}
            >
              Candidate Onboarding Forms{" "}
              <Tooltip
                arrow
                title="Download"
                placement="top"
                style={{ marginBottom: "5px" }}
              >
                <IconButton color="primary" onClick={exportZip}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 21 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
            <div className="detailInner">
              {data?.data?.forms?.map((item) => {
                return (
                  <Details
                    detailTitle={item?.form?.title}
                    Details={
                      <a
                        href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/CandidateOnboardingsDocuments/${item?.file}`}
                        target="blank"
                      >
                        {item?.file}
                      </a>
                    }
                  />
                );
              })}
            </div>
            <div className="headingForm">Candidate Onboarding Documents</div>
            <div className="detailInner">
              {data?.data?.documents?.map((item) => {
                return (
                  <Details
                    detailTitle={item?.document?.name}
                    Details={
                      <a
                        href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/CandidateOnboardingsDocuments/${item?.userFile}`}
                        target="blank"
                      >
                        {item?.userFile}
                      </a>
                    }
                  />
                );
              })}
            </div>
          </div>
          <div style={{ backgroundColor: "white" }} className="createFootCust">
            <button className="button secondaryButton" onClick={handleClose}>
              Cancel
            </button>
            <button className="button primaryButton" onClick={verifyOnboarding}>
              Verify
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default VerifyMandatoryDocuments;
