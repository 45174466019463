
import React, { useRef } from "react";
import { useState } from "react";
import "./NewBroadCast.css";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import GroupProfileIcon from "../../../../Assets/GroupProfileIcon.svg";
import ChatSelectedCheck from "../../../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../../../Assets/ChatUnselectedCheck.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function NewConversation(props) {

    const [contactsList, setContactList] = useState(useSelector((state) => state.chat.contactList));
    const convoList = useSelector((state) => state.chat.conversationList);

    const userDetails = useSelector((state) => state.chat.userDetails);

    const [chatList, setChatList] = useState(contactsList);
    const [newGroupList, setNewGroupList] = useState(true);
    const [selectedParticipant, setSelectedParticipant] = useState(null);

    const inputFile = useRef(null);

    useEffect(() => {
        document.title = `PeopleSol - New Group`;
        return () => {
            document.title = 'PeopleSol';
        };
    })


    useEffect(() => {
        const a = convoList.filter((item) => item.type == "Contact")

        let list = [...contactsList]
        for (let i = 0; i < a.length; i++) {
            let b = [];
            for (let j = 0; j < list.length; j++) {
                if (list[j]._id != a[i].participateMembers[0].contactId && list[j]._id != a[i].participateMembers[1].contactId) {
                    b.push(list[j]);
                }
            }
            list = [...b];
        }
        setContactList([...list])
        setChatList([...list])
    }, []);

    function showStatus(status) {
        if (status === "online") {
            return "greenDot";
        } else if (status === "busy") {
            return "redDot";
        } else if (status === "away") {
            return "yellowDot";
        }
    }

    const NewChatList = () => {
        const Contact = ({ chat, index }) => {
            return (
                <div
                    onClick={() => {
                        setSelectedParticipant(chat);
                    }}
                    className="newChatListContentContainer"
                    style={{ backgroundColor: selectedParticipant?._id == chat._id ? "#E9F3F4" : "white", paddingLeft: 15, paddingRight: 15 }}
                >

                    <div className="newChatActiveImage">
                        {chat.image != "" ? <img src={chat.image} alt="Profile" /> :
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 200 }}>
                                <div style={{ color: "#1296B0" }}>
                                    {
                                        chat?.name
                                            ?.split(" ")
                                            ?.slice(0, 2)
                                            ?.map((item) => {
                                                return item?.charAt(0)?.toUpperCase();
                                            })
                                            .join("")
                                    }
                                </div>
                            </div>}
                        <div className={showStatus(chat.Active)}></div>
                    </div>
                    <div className="newChatPostMainTextContainer">
                        <label className="chatListName">{chat.name}</label>
                        <label className="chatListRole">{chat.role}</label>
                    </div>
                </div>
            )
        }
        return (
            <div className="newChatListContainer" style={{ paddingBottom: 200 }}>
                {chatList.map((chat, index) => (
                    <Contact key={index} index={index} chat={chat} />
                ))}
            </div>
        )
    }
    return (
        <div className="newGroupContainer" style={{ backgroundColor: "white", }}>
            {newGroupList && (
                <>
                    <div className="newGroupSearchContainer">
                        <div className="chatListSearchContainer">
                            {" "}
                            <input
                                type="text"
                                className="chatListSearch"
                                placeholder="Search"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        let a = contactsList.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()) || item.role.toLowerCase().includes(e.target.value.toLowerCase()))
                                        setChatList([...a])
                                    } else {
                                        setChatList([...contactsList])
                                    }
                                }}
                            />
                            <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0" />
                                <line
                                    x1="8.70711"
                                    y1="9"
                                    x2="12"
                                    y2="12.2929"
                                    stroke="#1296B0"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                    </div>

                </>
            )}
            <NewChatList setSelectedParticipant={(chat, select) => {
                if (select) setSelectedParticipant([...selectedParticipant, chat])
                else {
                    let a = selectedParticipant.filter(item => item._id !== chat._id);
                    setSelectedParticipant([...a])
                }
            }} />
            {selectedParticipant ? <div style={{ width: "100%", position: "absolute", bottom: 0, padding: 10, }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", color: "#1296B0", justifyContent: "flex-end" }}>
                    <div onClick={() => {
                        props.createNewConvo(selectedParticipant);
                    }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="none"
                        >
                            <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
                            <path
                                d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
                                stroke="white"
                                stroke-width="1.10154"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

            </div> : null
            }
        </div>
    );
}

export default NewConversation;