import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ParticularDeclarationComp } from "../DeclarationComp/ParticularDeclarationComp";
import { ParticularDetailsComp } from "../DeclarationComp/ParticularDetailsComp";
import Nodata from "../../../Assets/Nodata.svg";
import { TaxRegimeComp } from "../DeclarationComp/TaxRegimeComp";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { Loader } from "../../../Components/FormComponent/Loader";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { format } from "date-fns";
import { Tooltip } from "@mui/material";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import UploadDetail from "../../../Components/UploadDetail/UploadDetail";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";

const TaxDeclaration = () => {
  const [declareActive, setDeclareActive] = useState(0);
  const [text, setText] = useState("");
  const [active, setActive] = useState(1);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const [id, setId] = useState(1);
  const [data, setData] = useState(false);
  const [tbodyData, setTbodyData] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxValue, setTaxValue] = useState(null);
  const [empValues, setEmpValues] = useState(null);
  const [editValues, setEditValues] = useState(null);
  const [editId, setEditId] = useState(null);
  const [detailsValues, setDetailsValues] = useState({});
  const [eaId, setEaId] = useState(null);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [val, setVal] = useState(null);
  const reqData = {
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 20,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  };
  const getEditDetails = async (id) => {
    try {
      setLoading(true);
      const result = await getRequest(`taxdeclaration/EditForm/?ID=${id}`);
      setDetailsValues(result?.data?.data?.taxDeclarartion);
      setEditValues(result?.data?.data?.taxDeclarartion);
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const CancelBack = () => {
    if (id === 2 || id === 3) {
      setId(1);
    } else {
      setDeclareActive(0);
      setId(1);
      getListData();
    }
  };
  const taxRegimeData = [
    {
      id: 1,
      heading: "Old Tax Regime",
      body: "Total Tax: ₹0.00",
    },
    {
      id: 2,
      heading: "New Tax Regime",
      body: "Total Tax: ₹0.00",
    },
  ];

  const clickHandler = (item) => {
    setActive(item);
  };
  useEffect(() => {
    document.title = `PeopleSol -  Tax Declaration`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getListData = async () => {
    try {
      setLoading(true);
      const result = await postRequest("TaxDeclaration/List", reqData);
  
        if (result.data && 
          LoggedInUser?.permissions?.includes("Is Eligible For Income Tax Declaration?") 
       ) {
        let tempData = [];
        let ogData = [];
        result?.data?.data?.map((item) => {
          return (
            <>
              {tempData.push({
                ...item.item,
                // actions: getActions(item?.actions),
                // actions: item.actions,
                status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              })}
              {ogData.push({
                ...item,
                // ...item.actions?.map(x => x),
              })}
            </>
          );
        });
        setOriginalData(ogData);
        const clippedArray = tempData?.map(
          ({ attachment, createdOn, lastUpdatedOn, ...rest }) => ({ ...rest })
        );
        const finalArray = clippedArray.map(({ id, ...rest }) => ({
          id,
          ...rest,
        }));
        setTbodyData(finalArray);
      } else {
        setData("You Don't have required permissions to view the content")
        setTbodyData(false);
      }
    } catch (error) {
      setTbodyData(false);
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("declaration")) getListData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formFields = [
    {
      id: 1,
      heading: "Annual income other than salary",
      compName: "incomeOtherThanSalary",
      body: [
        {
          id: 1,
          label: "Dividend Income",
          value: "dividendIncome",
        },
        {
          id: 2,
          label: "Interest from saving account",
          value: "interestfromsavingaccount",
        },
        {
          id: 3,
          label: "Interest from fixed Deposit",
          value: "interestfromfixeddeposit",
        },
        {
          id: 4,
          label: "Any other income(other than rent)",
          value: "otherIncome",
        },
      ],
    },
    {
      id: 2,
      heading: " Annual allowances paid as part of the salary ",
      compName: "reimbursementSalary",
      body: [
        {
          id: 1,
          label: "Car allowance",
          value: "carReimpursement",
        },
        {
          id: 2,
          label: "Driver Salary allowance",
          value: "driverSalaryReimpursement",
        },
        {
          id: 3,
          label: "Books and Periodicals",
          value: "booksAndPeriodicals",
        },
        {
          id: 4,
          label: "Gadget allowance",
          value: "gadgetReimpursement",
        },
        {
          id: 5,
          label: "Medical Expenses allowance",
          value: "medicalExpensesReimpursement",
        },
      ],
    },
    {
      noteText:
        "Declare your investments includes insuarance premium, mutual funds,housing loadn, PPF,etc.The maximum limit for this section is ₹1,50,000 ",
      id: 3,
      heading: "Investments under section 80C, 80CC (Investment)",
      compName: "investment80C",
      body: [
        {
          id: 4,
          label: "Mutual Fund",
          value: "mutualFund",
        },
        {
          id: 3,
          label: "Health insurance Premium",
          value: "insurance",
        },
        {
          id: 2,
          label: "Public Provident Fund(PPF)",
          value: "ppf",
        },
        {
          id: 1,
          label: "Housing Loan Repayment Principle",
          value: "houseLoanPrincipal",
        },
        {
          id: 5,
          label: "National Saving Scheme / Certificate",
          value: "savingScheme",
        },
        {
          id: 6,
          label: "Sukanya Samriddhi Account",
          value: "sukanyaSamriddhiAccount",
        },
        {
          id: 7,
          label: "Children Education Fees (only School tuition fees)",
          value: "educationFee",
        },
        {
          id: 8,
          label: "Allowable Term Deposit",
          value: "termDeposit",
        },
      ],
    },
    {
      noteText:
        "Declare your additional investments in NPS.The maximum limit for this section is ₹50,000",
      id: 4,
      heading: "Investments under section 80CCD (Pension Contribution)",
      compName: "investment80CCD",
      body: [
        {
          id: 1,
          label: "National Pension Scheme",
          value: "employeesContribution",
        },
      ],
      maxLimit: "₹50,000",
    },
    {
      noteText:
        "Declare medical insurance for self,spouse,children and parents. For self,spouse and children maximum limit is ₹25,000. For parents more than 60 years maximum limit is ₹50,000.",
      id: 5,
      heading: "Investments under section 80D (Medical Insurance)",
      compName: "investment80D",
      body: [
        {
          id: 1,
          label: "Medical Insurance Premium for Senior Citizen",
          value: "insurance80d",
        },
        {
          id: 2,
          label: "Expenditure on Medical Treatment for specified disease",
          value: "expenditure80ddb",
        },
        {
          id: 3,
          label:
            "Deduction in maintenance of medical treatment of a handicapped",
          value: "deduction80dd",
        },
      ],
      maxLimit: "₹25,000",
    },
    {
      noteText: "Interest paid for a duration of 8 years.",
      id: 6,
      heading: "Investments under section 80E (Education Loan Interest)",
      compName: "investment80E",
      body: [
        {
          id: 1,
          label: "Repayment of loan taken for Higher Education",
          value: "education80e",
        },
      ],
    },
    {
      id: 7,
      heading: "Any other Investment qualify for IT Deduction",
      compName: "investmentOther",
      body: [
        {
          id: 1,
          label: "Leave Travel Assistance (LTA)",
          value: "leaveTravelAssisatant",
        },
        {
          id: 2,
          label: "Permanent Physical Disability",
          value: "ppd80u",
        },
        {
          id: 3,
          label: "Any other Investment",
          value: "otherInvestmentQualify",
        },
      ],
    },
  ];
  const getInvestment80CSchema = Yup.array()
    .of(Yup.object().shape({}))
    .test({
      name: "sumValidation",
      test: function (value) {
        const totalSum = value.reduce(
          (sum, obj) => sum + Number(obj.Value || 0),
          0
        );
        return (
          totalSum <= 150000 ||
          this.createError({
            message: "Total sum exceeds the limit of 150,000",
          })
        );
      },
    });
  const getInvestment80CCDchema = Yup.array()
    .of(Yup.object().shape({}))
    .test({
      name: "sumValidation",
      test: function (value) {
        const totalSum = value.reduce(
          (sum, obj) => sum + Number(obj.Value || 0),
          0
        );
        return (
          totalSum <= 50000 ||
          this.createError({ message: "Total sum exceeds the limit of 50,000" })
        );
      },
    });
  const getInvestment80CCDDchema = Yup.array()
    .of(Yup.object().shape({}))
    .test({
      name: "sumValidation",
      test: function (value) {
        const totalSum = value.reduce(
          (sum, obj) => sum + Number(obj.Value || 0),
          0
        );
        return (
          totalSum <= 50000 ||
          this.createError({ message: "Total sum exceeds the limit of 50,000" })
        );
      },
    });
  const getReimbursementSalary = () => {
    let returnArray = [{ Title: "", Value: "" }];
    if (editValues) {
      returnArray = [];
      if (editValues?.carReimpursement) {
        returnArray.push({
          Title: "carReimpursement",
          Value: editValues?.carReimpursement,
        });
      }
      else if (editValues?.driverSalaryReimpursement) {
        returnArray.push({
          Title: "driverSalaryReimpursement",
          Value: editValues?.driverSalaryReimpursement,
        });
      }
      else if (editValues?.booksAndPeriodicals) {
        returnArray.push({
          Title: "booksAndPeriodicals",
          Value: editValues?.booksAndPeriodicals,
        });
      }
      else if (editValues?.gadgetReimpursement) {
        returnArray.push({
          Title: "gadgetReimpursement",
          Value: editValues?.gadgetReimpursement,
        });
      }
      else if (editValues?.medicalExpensesReimpursement) {
        returnArray.push({
          Title: "medicalExpensesReimpursement",
          Value: editValues?.medicalExpensesReimpursement,
        });
      }
      else {
        returnArray = [{ Title: "", Value: "" }];
      }
    }
    return returnArray;
  };
  const getInitialOtherThanSalary = () => {
    let returnArray = [{ Title: "", Value: "" }];
    if (editValues) {
      returnArray = [];
      if (editValues?.dividendIncome) {
        returnArray.push({
          Title: "dividendIncome",
          Value: editValues?.dividendIncome,
        });
      }
      else if (editValues?.interestfromsavingaccount) {
        returnArray.push({
          Title: "interestfromsavingaccount",
          Value: editValues?.interestfromsavingaccount,
        });
      }
      else if (editValues?.interestfromfixeddeposit) {
        returnArray.push({
          Title: "interestfromfixeddeposit",
          Value: editValues?.interestfromfixeddeposit,
        });
      }
      else if (editValues?.otherIncome) {
        returnArray.push({
          Title: "otherIncome",
          Value: editValues?.otherIncome,
        });
      }
      else {
        returnArray = [{ Title: "", Value: "" }];
      }
    }
    return returnArray;
  };
  const getInvestment80D = () => {
    let returnArray = [{ Title: "", Value: "" }];
    if (editValues) {
      returnArray = [];
      if (editValues?.deduction80dd) {
        returnArray.push({
          Title: "deduction80dd",
          Value: editValues?.deduction80dd,
        });
      }
    else  if (editValues?.expenditure80ddb) {
        returnArray.push({
          Title: "expenditure80ddb",
          Value: editValues?.expenditure80ddb,
        });
      }
    else  if (editValues?.insurance80d) {
        returnArray.push({
          Title: "insurance80d",
          Value: editValues?.insurance80d,
        });
      }
      else {
        returnArray = [{ Title: "", Value: "" }];
      }
    } 
    return returnArray;
  };

  const getInvestment80C = () => {
    let returnArray = [{ Title: "", Value: "" }];
    if (editValues) {
      returnArray = [];
      if (editValues?.houseLoanPrincipal) {
        returnArray.push({
          Title: "houseLoanPrincipal",
          Value: editValues?.houseLoanPrincipal,
        });
      }
      else if (editValues?.ppf) {
        returnArray.push({
          Title: "ppf",
          Value: editValues?.ppf,
        });
      }
      else if (editValues?.insurance) {
        returnArray.push({
          Title: "insurance",
          Value: editValues?.insurance,
        });
      }
      else if (editValues?.mutualFund) {
        returnArray.push({
          Title: "mutualFund",
          Value: editValues?.mutualFund,
        });
      }
      else if (editValues?.savingScheme) {
        returnArray.push({
          Title: "savingScheme",
          Value: editValues?.savingScheme,
        });
      }
      else if (editValues?.sukanyaSamriddhiAccount) {
        returnArray.push({
          Title: "sukanyaSamriddhiAccount",
          Value: editValues?.sukanyaSamriddhiAccount,
        });
      }
      else if (editValues?.educationFee) {
        returnArray.push({
          Title: "educationFee",
          Value: editValues?.educationFee,
        });
      }
      else if (editValues?.termDeposit) {
        returnArray.push({
          Title: "termDeposit",
          Value: editValues?.termDeposit,
        });
      }
      else {
        returnArray = [{ Title: "", Value: "" }];
      }
    }
    return returnArray;
  };

  const getInvestment80CCD = () => {
    let returnArray = [{ Title: "", Value: "" }];
    if (editValues) {
      returnArray = [];
      if (editValues?.employeesContribution) {
        returnArray.push({
          Title: "employeesContribution",
          Value: editValues?.employeesContribution,
        });
      } else {
        returnArray = [{ Title: "", Value: "" }];
      }
    }
    return returnArray;
  };

  const getInvestment80E = () => {
    let returnArray = [{ Title: "", Value: "" }];
    if (editValues) {
      returnArray = [];
      if (editValues?.education80e) {
        returnArray.push({
          Title: "education80e",
          Value: editValues?.education80e,
        });
      } else {
        returnArray = [{ Title: "", Value: "" }];
      }
    }
    return returnArray;
  };

  const getOtherInvestment = () => {
   
    let returnArray = [{ Title: "", Value: "" }];
    if (editValues) {
      returnArray = [];
      if (editValues?.leaveTravelAssisatant) {
        returnArray.push({
          Title: "leaveTravelAssisatant",
          Value: editValues?.leaveTravelAssisatant,
        });
      }
    else  if (editValues?.ppd80u) {
        returnArray.push({
          Title: "ppd80u",
          Value: editValues?.ppd80u,
        });
      }
    else  if (editValues?.otherInvestmentQualify) {
        returnArray.push({
          Title: "otherInvestmentQualify",
          Value: editValues?.otherInvestmentQualify,
        });
      } else {
        returnArray = [{ Title: "", Value: "" }];
      }
    }
    return returnArray;
  };

  const initialValue = {
    TaxRate: editValues ? editValues?.taxRate : "",
    investment80Ctotal: 0,
    StartDate: "", //ques comp 1
    EndDate: "",
    houseRentPaid: editValues ? editValues?.houseRentPaid : "",
    LandLordAddressOfProperty: editValues
      ? editValues?.landLordAddressOfProperty
      : "",
    tenantName: editValues ? editValues?.tenantName : "",
    isMetro: editValues ? editValues?.landlordPan : "",
    LandlordPan: editValues ? editValues?.landlordPan : "",

    HouseLoanPrincipal: editValues ? editValues?.houseLoanPrincipal : "", //ques comp 2
    HouseLoanInterest: editValues ? editValues?.houseLoanInterest : "",
    // tenantName: "",
    // TenantPan: "",

    rentReceived: editValues ? editValues?.rentReceived : "", //ques comp 3
    taxPaid: editValues ? editValues?.taxPaid : "",

    netValue: editValues ? editValues?.netValue : "",
    standardDeduction: editValues ? editValues?.standardDeduction : "",
    incomeLossfromHouseProperty: editValues
      ? editValues?.incomeLossfromHouseProperty
      : "",

    DividendIncome: editValues ? editValues?.dividendIncome : "", //investment comp start
    Interestfromsavingaccount: editValues
      ? editValues?.interestfromsavingaccount
      : "",
    Interestfromfixeddeposit: editValues
      ? editValues?.interestfromfixeddeposit
      : "",
    OtherIncome: editValues ? editValues?.otherIncome : "",
    RemarksforOtherIncome: editValues ? editValues?.remarksforOtherIncome : "",
    HouseRent: editValues ? editValues?.houseRent : "",
    // FileUploadTenantPan: (binary)

    PropertyAddress: editValues ? editValues?.propertyAddress : "",
    // FileUploadPropertyAddress: (binary)

    IncomeHouseProp: editValues ? editValues?.incomeHouseProp : "",
    HouseRentPaid: editValues ? editValues?.houseRentPaid : "",
    // FileUploadHouseRentPaid: (binary)

    // FileUploadLandlordPan: (binary)

    // FileUploadLandLordAddressOfProperty: (binary)

    // FileUploadHousingLoan: (binary)

    Ppf: editValues ? editValues?.ppf : "",
    // FileUploadPPF: (binary)

    Insurance: editValues ? editValues?.insurance : "",
    // FileUploadInsurance: (binary)

    SavingScheme: editValues ? editValues?.savingScheme : "",
    // FileUploadSavingScheme: (binary)

    MutualFund: editValues ? editValues?.mutualFund : "",
    // FileUploadMutualFund: (binary)

    SukanyaSamriddhiAccount: editValues
      ? editValues?.sukanyaSamriddhiAccount
      : "",
    // FileUploadSamriddhiAccount: (binary)

    EducationFee: editValues ? editValues?.educationFee : "",
    // FileUploadEducationFee: (binary)

    TermDeposit: editValues ? editValues?.termDeposit : "",
    // FileUploadAllowableTerm: (binary)

    incomeOtherThanSalary: getInitialOtherThanSalary(),
    reimbursementSalary: getReimbursementSalary(),
    investment80C: getInvestment80C(),
    investment80CCD: getInvestment80CCD(),
    investment80D: getInvestment80D(),
    investment80E: getInvestment80E(),
    investmentOther: getOtherInvestment(),

    EmployeesContribution: "",
    // FileUploadEmployeeContribution: (binary)

    Investment80d: "",
    Insurance80d: "",
    // FileUploadInsurance80d: (binary)

    Expenditure80ddb: "",
    // FileUploadExpenditure80ddb: (binary)

    Education80e: "",
    // FileUploadEducation80e: (binary)

    Deduction80dd: "",
    // FileUploadMaintenance: (binary)

    Ppd80u: "",
    // FileUploadPhysicalDisability: (binary)

    OtherInvestmentQualify: "",
    // FileUploadOtherInvestmentQualify: (binary)

    QualifyRemarks: "",
    CarReimpursement: editValues ? editValues?.carReimpursement : "",
    // FileUploadCarReimpursement: (binary)

    DriverSalaryReimpursement: "",
    // FileUploadDriverReimpursement: (binary)

    BooksAndPeriodicals: "",
    // FileUploadBooks: (binary)

    GadgetReimpursement: "",
    // FileUploadGadget: (binary)

    MedicalExpensesReimpursement: "",
    // FileUploadMedical:(binary)

    LeaveTravelAssisatant: "",

    // FileUploadLeave:(binary)

    Comment1: "",
    Comment2: "",

    IsAgree: "",
    TaxDeclarationSettingsId: "",
  };

  const createPageData = async (values) => {
    try {
      const result = await postRequestForm("taxdeclaration/CreateForm", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setDeclareActive(0);
      setId(1);
      getListData();
    }
  };

  const editPageData = async (values) => {
    try {
      const result = await postRequestForm(
        `taxdeclaration/EditForm/?ID=${editId}`,
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setDeclareActive(0);
      setId(1);
      getListData();
    }
  };
  const getPageData = async (id) => {
    try {
      const result = await getRequest(
        `taxdeclaration/CreateForm/?EmployeeID=${LoggedInUser?.id}&SettingsID=${id}`
      );
      setEmpValues(result?.data?.data);
    } catch {
    } finally {
    }
  };
  const onSubmit = async (values) => {
    setId(3);
    const formData = new FormData();

    if (!editValues) {
      formData.append("Id", 0);
    }

    if (editValues) {
      formData.append("ID", editId);
      formData.append(
        "taxDeclarationSettingsId",
        editValues?.taxDeclarationSettingsId
      );
      formData.append("Email", editValues?.employee?.email);
      formData.append("Pan", editValues?.employee?.panNumber);
    }

    if (empValues?.employee) {
      formData.append("Email", empValues?.employee?.email);
      formData.append("Pan", empValues?.employee?.panNumber);
      formData.append("TaxDeclarationSettingsId", empValues?.settingsId);
    }

    if (editValues) {
      formData.append("Pan", editValues?.employee?.panNumber);
    }

    if (taxValue === 1) {
      formData.append("TaxRate", "Old");
    } else if (taxValue === 2) {
      formData.append("TaxRate", "New");
    }
    if (values?.StartDate) formData.append("StartDate", values?.StartDate);

    if (values?.EndDate) formData.append("EndDate", values?.EndDate);

    if (values?.houseRentPaid)
      formData.append("houseRentPaid", values?.houseRentPaid);

    if (values?.LandLordAddressOfProperty)
      formData.append(
        "LandLordAddressOfProperty",
        values?.LandLordAddressOfProperty
      );

    if (values?.tenantName) formData.append("tenantName", values?.tenantName);

    if (values?.isMetro)
      formData.append("isMetro", values?.isMetro === "Metro" ? true : false);

    if (values?.LandlordPan)
      formData.append("LandlordPan", values?.LandlordPan);

    if (values?.HouseLoanPrincipal)
      formData.append("HouseLoanPrincipal", values?.HouseLoanPrincipal);

    if (values?.HouseLoanInterest)
      formData.append("HouseLoanInterest", values?.HouseLoanInterest);

    // formData.append("tenantName", values?.tenantName);

    // formData.append("TenantPan", values?.TenantPan);

    if (values?.rentReceived)
      formData.append("rentReceived", values?.rentReceived);

    if (values?.taxPaid) formData.append("taxPaid", values?.taxPaid);

    if (values?.taxPaid && values?.rentReceived)
      formData.append(
        "netValue",
        parseFloat(values?.rentReceived) - parseFloat(values?.taxPaid)
      );

    if (values?.taxPaid && values?.rentReceived)
      formData.append(
        "standardDeduction",
        (parseFloat(values?.rentReceived) - parseFloat(values?.taxPaid)) * 0.3
      );

    if (values?.taxPaid && values?.rentReceived)
      formData.append(
        "incomeLossfromHouseProperty",
        parseFloat(values?.rentReceived) -
          parseFloat(values?.taxPaid) -
          (parseFloat(values?.rentReceived) - parseFloat(values?.taxPaid)) * 0.3
      );

    // if (values?.OtherInvestmentList?.length > 0) {
    //   values.OtherInvestmentList.forEach((nestedArray) => {
    //     nestedArray?.forEach((item) => {
    //       formData.append(`${item.Title}`, item.Remarks);
    //     });
    //   });
    // }

    if (values?.incomeOtherThanSalary.length > 0) {
      values?.incomeOtherThanSalary?.forEach((item) => {
        formData.append(`${item?.Title}`, item?.Value);
      });
    }
    if (values?.reimbursementSalary.length > 0) {
      values?.reimbursementSalary?.forEach((item) => {
        formData.append(`${item?.Title}`, item?.Value);
      });
    }
    if (values?.investment80C.length > 0) {
      values?.investment80C?.forEach((item) => {
        formData.append(`${item?.Title}`, item?.Value);
      });
    }
    if (values?.investment80CCD.length > 0) {
      values?.investment80CCD?.forEach((item) => {
        formData.append(`${item?.Title}`, item?.Value);
      });
    }
    if (values?.investment80D.length > 0) {
      values?.investment80D?.forEach((item) => {
        formData.append(`${item?.Title}`, item?.Value);
      });
    }
    if (values?.investment80E.length > 0) {
      values?.investment80E?.forEach((item) => {
        formData.append(`${item?.Title}`, item?.Value);
      });
    }
    if (values?.investmentOther.length > 0) {
      values?.investmentOther?.forEach((item) => {
        formData.append(`${item?.Title}`, item?.Value);
      });
    }

    editValues ? editPageData(formData) : createPageData(formData);
    setText("Back To Tax Declaration List");
  };

  if (loading) return <Loader />
  else if (data) return  <div className="noDatastyle">
  <img
    src={Nodata}
    alt="No data"
    style={{ height: "150px", width: "250px" }}
  />
  <h4>{data ? data : "No Data Found"}</h4>
</div>
  else
    return (
      <div
        className="fullTableContainer"
        style={{ width: "100%", height: "100%" }}
      >
        {declareActive === 0 ? (
          <div
            className="boxViewContainer "
            style={{ width: "100%", height: "100%" }}
          >
            <div className="homeCardsHolderContainer">
              <div className="homeDashboardCardHolder" key={1}>
                {originalData &&
                  originalData?.map((item, index) => {
                    return (
                      <div className="dashboardCard internalJobCard">
                        <div className="cardContainer cursor">
                          <div className="headingContainer">
                            <h3
                              style={{
                                fontSize: "16px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.item?.title}
                            </h3>
                            {/* <span className="internalJobDateTime" style={{color:'var(--secondary)'}}>
                          Pending
                        </span> */}
                          </div>
                          <div
                            className="jobDetailContainerHolder"
                            // style={{ padding: "7px 0px 23px 11px" }}
                          >
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Start Date
                              </label>
                              <label>
                                {" "}
                                {item?.item?.startDate &&
                                  format(
                                    new Date(item?.item?.startDate),
                                    "dd/MM/yyyy "
                                  )}
                              </label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">End Date</label>
                              <label>
                                {" "}
                                {item?.item?.endDate &&
                                  format(
                                    new Date(item?.item?.endDate),
                                    "dd/MM/yyyy "
                                  )}
                              </label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">Status</label>
                              <label>
                                {" "}
                                <div
                                  style={{
                                    backgroundColor: getStatusColor(
                                      item?.item?.status
                                    )?.split(",")?.[0],
                                    color: getStatusColor(
                                      item?.item?.status
                                    )?.split(",")?.[1],
                                    cursor: "default",
                                    padding: "3px 6px",
                                    width: "fit-content",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {item?.item?.status}
                                </div>
                              </label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">Document</label>
                              {item?.item?.attachment ? (
                                <label>
                                  <UploadDetail
                                    filepath={`tax/${item?.item?.attachment}`}
                                  />
                                </label>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                          <div
                            className="applyJobButtonContainer "
                            style={{ flexWrap: "wrap" }}
                          >
                            {item?.actions?.map((itemData, index) => {
                              return (
                                <>
                                  {itemData?.type ===
                                    "Submit Tax Declaration" && (
                                    <Tooltip
                                      arrow
                                      title={"Submit Tax Declaration"}
                                      placement="top"
                                    >
                                      <button
                                        // style={{ background: itemData?.color }}
                                        onClick={async () => {
                                          setText(item?.item?.title);
                                          setDeclareActive(1);
                                          await getPageData(item?.item?.id);
                                          setType("Submit Tax Declaration");
                                          setEditValues(null);
                                        }}
                                        className="button primaryButton"
                                      >
                                        {itemData?.type}
                                      </button>
                                    </Tooltip>
                                  )}
                                  {itemData?.type ===
                                    "Update Tax Declaration" && (
                                    <Tooltip
                                      arrow
                                      title={"Update Tax Declaration"}
                                      placement="top"
                                    >
                                      <button
                                        // style={{ background: itemData?.color }}
                                        onClick={async () => {
                                          await getEditDetails(itemData?.id);
                                          setDeclareActive(1);
                                          setEditId(itemData?.id);
                                          setEaId(itemData?.id);
                                          setType("Update Tax Declaration");
                                          setText(item?.item?.title);
                                        }}
                                        className="button primaryButton"
                                      >
                                        {itemData?.type}
                                      </button>
                                    </Tooltip>
                                  )}
                                  {itemData?.type === "Details" && (
                                    <Tooltip
                                      arrow
                                      title={"Details"}
                                      placement="top"
                                    >
                                      <button
                                        // style={{ background: itemData?.color }}
                                        onClick={async () => {
                                          await getEditDetails(itemData.id);
                                          setDeclareActive(1);
                                          setId(3);
                                          setEaId(itemData.id);
                                          setText(item?.item?.title);
                                        }}
                                        className="button primaryButton"
                                      >
                                        {itemData?.type}
                                      </button>
                                    </Tooltip>
                                  )}
                                </>
                              );
                            })}
                            {(item?.actions.length === 0 && new Date(item?.item?.startDate )< new Date()) &&(
                              <Tooltip
                                arrow
                                title={"No Actions"}
                                placement="top"
                              >
                                <div className="noActionsTextContainer">
                                  <label>Expired </label>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={initialValue}
            validationSchema={Yup.object({
              houseRentPaid: Yup.number()
                .max(99999999, "Name must be less than 99999999 ")
                .nullable()
                .notRequired(),
              investment80C: getInvestment80CSchema,
              investment80CCD: getInvestment80CCDchema,
              investment80D: getInvestment80CCDDchema,
            })}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="feedbackFormFooter">
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="submitDeclareContainer">
                        <div
                          className="returnToDashboard cursor"
                          onClick={() => {
                            setDeclareActive(0);
                            setId(1);
                            getListData();
                          }}
                        >
                          <svg
                            width="20"
                            height="8"
                            viewBox="0 0 20 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.243615 3.74484C0.0483524 3.9401 0.0483524 4.25668 0.243615 4.45194L3.4256 7.63392C3.62086 7.82918 3.93744 7.82918 4.1327 7.63392C4.32796 7.43866 4.32796 7.12208 4.1327 6.92682L1.30427 4.09839L4.1327 1.26996C4.32796 1.0747 4.32796 0.758117 4.1327 0.562855C3.93744 0.367593 3.62086 0.367593 3.4256 0.562855L0.243615 3.74484ZM18.624 4.59839C18.9002 4.59839 19.124 4.37453 19.124 4.09839C19.124 3.82225 18.9002 3.59839 18.624 3.59839V4.59839ZM0.597168 4.59839H18.624V3.59839H0.597168V4.59839Z"
                              fill="#1296B0"
                            />
                          </svg>

                          <label className="returnLabelHolder">{text}</label>
                        </div>
                        <div className="taxRegimeContainer">
                          {
                            <>
                              {taxRegimeData?.map((item, index) => {
                                return (
                                  <>
                                    <TaxRegimeComp
                                      disabled={
                                        id == 2 || id == 3 ? "off" : "on"
                                      }
                                      heading={item?.heading}
                                      body={item?.body}
                                      clickHandler={clickHandler}
                                      id={item.id}
                                      active={item.id === active}
                                    />
                                    {item.id === active && setTaxValue(item.id)}
                                  </>
                                );
                              })}
                            </>
                          }
                        </div>
                        {id === 1 && (
                          <ParticularDeclarationComp
                            values={val}
                            errors={formik.errors}
                            formFields={formFields}
                            editValues={editValues}
                          />
                        )}
                        {(id === 2 || id === 3) && formik.values && (
                          <ParticularDetailsComp
                            formFields={formFields}
                            formValues={formik.values}
                            detailsValues={null}
                            id={id}
                          />
                        )}
                        <div
                          className={`createFootCust ${
                            id === 3 && "invisibleClass"
                          }`}
                        >
                          <div>
                            <div
                              // onClick={returnPage}
                              className="button secondaryButton"
                              style={{
                                color: "red",
                                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
                              }}
                              onClick={() => {
                                setId(1);
                                CancelBack();
                              }}
                            >
                              {id === 2 || id === 3 ? "Back" : "Cancel"}
                            </div>
                          </div>
                          <div>
                            {id === 1 ? (
                              <div
                                className="button primaryButton"
                                // type="button"
                                style={{
                                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
                                }}
                                onClick={() => {
                                  Object.keys(formik?.errors).length === 0 &&
                                    setId(2);
                                  setVal(formik.values);
                                }}
                              >
                                View Investment Declaration
                              </div>
                            ) : (
                              <button
                                className="button primaryButton"
                                type="submit"
                                style={{
                                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
                                }}
                              >
                                {type === "Submit Tax Declaration"
                                  ? "Submit"
                                  : "Update"}{" "}
                                Investment Declaration
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    );
};

export { TaxDeclaration };
