/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { GroupBy } from "../../../../Utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";
const deductionFrequencyOptions = [
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Quaterly",
    value: "Quaterly",
  },
  {
    label: "Half-Yearly",
    value: "Half-Yearly",
  },
];

const deductionMonths = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  }
]

const deductionAssociateOptions = [
  {
    label: "One-time deduction",
    value: "One-time deduction",
  },
  {
    label: "Recurring deduction for subsequent Payrolls",
    value: "Recurring deduction for subsequent Payrolls",
  },
];
const deductionTypeOptions = [
  { label: "Pre-Tax Deduction", value: "Pre-Tax Deduction" },
  { label: "Post-Tax Deduction", value: "Post-Tax Deduction" },
];

const calculationTypeOptions = [
  { label: "Flat Amount", value: "Flat Amount" },
  { label: "Percentage Of Basic", value: "Percentage Of Basic" },
]
const calculationModeOptions = [
  { label: "Flat Amount", value: "Flat Amount" },
  { label: "Varies based on payable days", value: "Varies based on payable days" },
]

const deductionPlanOptions = [
  { label: "Other Non-Taxable Deduction", value: "Other Non-Taxable Deduction" },
  { label: "National Pention Scheme", value: "National Pention Scheme" },
]

function CreateDeduction() {
  useEffect(() => {
    document.title = `PeopleSol - Create Deduction `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const [detailsform, setDetailsform] = useState(null);
  const { state } = useLocation();
  const editId = state?.id;
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `SalaryComponents/DeductionDetails/${editId}`
      );
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const initialValues = {
    deductionType: editId ? detailsform?.deductionType : "Pre-Tax Deduction",
    deductionFrequency: editId ? detailsform?.deductionFrequency : "",
    nameInPayslip: editId ? detailsform?.nameInPayslip : "",
    taxType: editId ? detailsform?.taxType : "Pre-Tax Deduction",
    deductionPlan: editId ? detailsform?.deductionPlan : "",
    id: 0,
    calculationType: editId ? detailsform?.calculationType : "",
    isActive: editId ? detailsform?.isActive : true,
    isProRataBasis: editId ? detailsform?.isProRataBasis : false,
    isEmployerContributionInCTC: editId ? detailsform?.isEmployerContributionInCTC : false,
    associateThisDeduction: editId ? detailsform?.associateThisDeduction : "",
    calculationValue: editId ? detailsform?.calculationValue : "",
    calculationMode: editId ? detailsform?.calculationMode : "",
    month: editId ? detailsform?.month : "",
  };
  useEffect(() => {
    if (editId) {
      getFormDetails();
    }
  }, [editId]);
  const create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "SalaryComponents/CreateDeduction",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "SalaryComponents/EditDeduction",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  const submitEditCompany = (values) => {
    let payload = {};

    if (values?.calculationType !== "Flat Amount" && (values.calculationValue > 100 || values.calculationValue < 0)) {
      dispatch(
        showToast({
          text: "Calculation value in percentage of basic calculation type should be between 0% to 100%",
          severity: "error",
        })
      );
      return;
    }
    payload["Id"] = editId ?? 0;
    payload["deductionType"] = "Pre-Tax Deduction";
    payload["deductionFrequency"] = values?.associateThisDeduction === "One-time deduction" ? "One-time deduction" : values?.deductionFrequency;
    payload["NameInPayslip"] = values?.nameInPayslip;
    payload["taxType"] = "Pre-Tax Deduction";
    payload["deductionPlan"] = values?.deductionPlan;
    payload["calculationType"] = values?.calculationType;
    if (values.month != "") payload["month"] = values?.month;
    payload["IsActive"] = values.isActive;
    payload["isProRataBasis"] = values?.isProRataBasis;
    payload["isEmployerContributionInCTC"] = values?.isEmployerContributionInCTC ? values?.isEmployerContributionInCTC : false;
    payload["associateThisDeduction"] = values?.associateThisDeduction == "" ? values?.deductionFrequency : values?.associateThisDeduction;
    payload["calculationValue"] = values?.calculationValue;
    payload["calculationMode"] = values?.calculationMode;
    editId ? edit(payload) : create(payload);
  };

  const validationSchema = Yup.object({
    associateThisDeduction: Yup.string().required("Please select associate this deduction with"),
    deductionFrequency: formValues?.associateThisDeduction === "One-time deduction" ? Yup.string() : Yup.string().required("Please select Deduction Frequency"),
    month: formValues?.associateThisDeduction !== "One-time deduction" ? Yup.string() : Yup.mixed().required("Deduction month is required"),
    nameInPayslip: Yup.string()
      .max(100, "Must be less than 100 characters")
      .required("Please Enter Name"),
    calculationType: Yup.string().required("Please select Calculation Type"),
    calculationValue: Yup.mixed()
      .required("Please Enter calculation value"),
    calculationMode: Yup.string().required("Please select Calculation Mode"),
  });

  return (
    <div className="moduleMainContainer">
      <div
        className={"companyCard"}
        style={{ overflow: "auto", backgroundColor: "var(--form-background)" }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => submitEditCompany(values)}
        >
          {(formik) => {
            setFormValues(formik?.values);
            return (
              <Form
                style={{
                  overflow: "auto",
                  height: "calc(100% - 72px)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <>
                  <div style={{ display: "flex" }}>
                    {/* <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <LabelCustom
                        labelName={"Deduction Type"}
                        mandatory={true}
                      />
                      <SelectForm
                      borderBlack={true}
                        name="deductionType"
                        options={deductionTypeOptions}
                        isDisabled={editId ? true : false}
                      />
                    </div> */}
                  </div>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  {formValues?.deductionType &&
                    !loading &&
                    formValues?.deductionType !== "" ? (
                    <div
                      className="companyCardContainer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderTop: "1px solid var(--grey)",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>

                          <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Associate this deduction with"}
                              mandatory={true}
                            />
                            <SelectForm
                              borderBlack={true}
                              name="associateThisDeduction"
                              options={deductionAssociateOptions}
                            />
                          </div>
                          {formValues.associateThisDeduction == "One-time deduction" ? <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Deduction Month"}
                              mandatory={true}
                            />
                            <SelectForm
                              borderBlack={true}
                              name="month"
                              options={deductionMonths}
                            />
                          </div> : null}
                          {formValues.associateThisDeduction != "One-time deduction" ? <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Deduction frequency"}
                              mandatory={true}
                            />
                            <SelectForm
                              borderBlack={true}
                              name="deductionFrequency"
                              options={deductionFrequencyOptions}
                            />
                          </div> : null}
                          <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Name in Payslip"}
                              mandatory={true}
                            />
                            <InputCustomFormik borderBlack={true} name="nameInPayslip" />
                          </div>
                          {/* <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Tax Type"}
                              mandatory={true}
                            />
                            <SelectForm
                            borderBlack={true}
                              name="taxType"
                              options={deductionTypeOptions}
                            />
                          </div> */}

                          {/* <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Deduction Plan"}
                              mandatory={true}
                            />
                            <SelectForm
                            borderBlack={true}
                              name="deductionPlan"
                              options={deductionPlanOptions}
                            />
                          </div> */}

                          <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Calculation Type"}
                              mandatory={true}
                            />
                            <SelectForm
                              borderBlack={true}
                              name="calculationType"
                              options={calculationTypeOptions}
                            />
                          </div>

                          <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Deduction Value"}
                              mandatory={true}
                            />
                            <InputCustomFormik borderBlack={true} name="calculationValue" type="number" />
                          </div>

                          <div style={{ width: "50%" }}>
                            <LabelCustom
                              labelName={"Computation Method"}
                              mandatory={true}
                            />
                            <SelectForm
                              borderBlack={true}
                              name="calculationMode"
                              options={calculationModeOptions}
                            />
                          </div>

                          <div style={{ width: "50%" }}>
                            <CheckboxFormiks
                              name="isEmployerContributionInCTC"
                              labelName={"Is deduction included in CTC"}
                            />
                          </div>

                          <div style={{ width: "50%" }}>
                            <CheckboxFormiks
                              name="isActive"
                              labelName={"Mark this as Active"}
                            />
                          </div>
                        </div>
                        {/* {formValues?.deductionType === "Pre-Tax Deduction" && (
                          <div
                            style={{
                              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.02)",
                              backgroundColor: "white",
                              borderRadius: "4px",
                              width: "50%",
                              padding: "12px",
                              margin: "24px 12px 12px 12px",
                              lineHeight: "22px",
                              height: "fit-content",
                            }}
                          >
                            <h5 style={{ color: "var(--primary)" }}>
                              Other Configurations
                            </h5>
                            <div>
                              <CheckboxFormiks
                                name={"isEmployerContributionInCTC"}
                                labelName={
                                  "Include employer's contribution in the CTC"
                                }
                              />
                            </div>
                            <div>
                              <CheckboxFormiks
                                name={"isProRataBasis"}
                                labelName={"Calculate on pro-rata basis"}
                              />
                              <p
                                style={{
                                  fontSize: "11px",
                                  marginLeft: "45px",
                                  marginTop: "-24px",
                                }}
                              >
                                Pay will be adjusted based on employee working
                                days.
                              </p>
                            </div>
                          </div>
                        )} */}
                      </div>
                      <div
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#FFF4E5",
                          margin: "12px",
                          padding: "12px",
                        }}
                      >
                        NOTE: Once you associate this deduction with an
                        employee, you will only be able to edit the Name in
                        Payslip. The change will be reflected in both new and
                        existing employees.
                      </div>
                    </div>
                  ) : null}
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    width: "calc(100% - 24px)",
                    bottom: "12px",
                    borderTop: "1px solid var(--secondary-text)",
                    margin: "0 12px",
                  }}
                >
                  <button
                    className="button secondaryButton"
                    type="button"
                    onClick={returnPage}
                  >
                    Cancel
                  </button>
                  <button className="button primaryButton" type="submit">
                    {editId ? "Update" : "Create"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default CreateDeduction;
