import React, { useState } from "react";
import "./SkillsCompetency.css";
import EditPencilIcon from "../../../Assets/EditPencilIcon.svg";
import AddSkill from "../../../Assets/AddSkill.svg";
import EditPencilIconOrange from "../../../Assets/EditPencilIconOrange.svg";
import AddSkillOrange from "../../../Assets/AddSkillOrange.svg";
import SkillBar from "./SkillBar";
import { postRequest } from "../../../Services/axios";
import { useEffect } from "react";
import { ProgressBarComponent } from "../../LD/Components/ProgressBarComponent";
import { IconButton } from "@mui/material";

const SkillsCompetency = () => {
  const [skillList, setSkillList] = useState();
  const [competencyList, setCompetencyList] = useState();
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 10000,
      iDisplayStart: 0,
      isExport: false,
    },
  });
  const getSkillsListData = async () => {
    try {
      const result = await postRequest(
        "SkillsEmployeeController/MyLists",
        reqData
      );
      setSkillList(result?.data?.data);
      // console.log("result.data.data", result);
    } catch (error) {
    } finally {
    }
  };
  const getCompetencyListData = async () => {
    try {
      const result = await postRequest(
        "CompetencyEmployeeController/MyLists",
        reqData
      );
      setCompetencyList(result?.data?.data);
      // console.log("result.data.data", result);
    } catch (error) {
    } finally {
    }
  };
  function capitalizeWordsIfLower(inputString) {
    if (inputString === inputString.toLowerCase()) {
      const words = inputString.split(" ");
      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return "";
        }
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      });

      const result = capitalizedWords.join(" ");

      return result;
    }
    return inputString;
  }
  useEffect(() => {
    getSkillsListData();
    getCompetencyListData();
  }, []);

  return (
    <div className="container">
      <div className="skillContainer">
        <div className="subSkillContainer">
          <div className="skillHeadingContainer">
            <label>Skills</label>
            <div>
              {/* <IconButton>
                <img
                  className="imgEditPencil"
                  src={EditPencilIcon}
                  alt="EditPencil"
                />
              </IconButton> */}
              <IconButton>
                <img src={AddSkill} alt="AddSkill" />
              </IconButton>
            </div>
          </div>
          <div className="skillLine"></div>

          <div className="skillBarsContainer">
            {skillList?.map?.((item) => {
              return (
                <>
                  {item?.item?.status === "Approved" && (
                    <div className="skillBarContainer">
                      <label>{item?.item?.skill}</label>
                      <div className="">
                        <ProgressBarComponent
                          percentage={
                            item?.item?.proficiencyLevel
                              ? Math.floor(
                                  (item?.item?.proficiencyLevel / 5) * 100
                                )
                              : 0
                          }
                          color={"#1296B0"}
                          widthTotal={"100%"}
                          style={{
                            backgroundColor: "var(--primary-hover)",
                            width: "91%",
                          }}
                          // style={{
                          //   height: "7px",
                          //   backgroundColor: "var(--primary-hover)",
                          //   width: "91%",
                          //   borderRadius: "10px",
                          // }}
                          // progressStyle={{
                          //   height: "7px",
                          //   borderRadius: "10px",
                          //   color: "#1296B0",
                          // }}
                          textStyle={{
                            color: "#1296B0",
                          }}
                        />
                        {/* <div className="skillLineBackground">
                      <div className="skillBarLine ten" />
                    </div>
                    <label>
                      {item?.item?.proficiencyLevel
                        ? Math.floor((item?.item?.proficiencyLevel / 5) * 100)
                        : 0}
                      %
                    </label> */}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>

        <div className="subSkillContainer">
          <div className="skillHeadingContainer secondary">
            <label>Competencies</label>
            <div>
              {/* <IconButton>
                <img
                  className="imgEditPencil secondary"
                  src={EditPencilIconOrange}
                  alt="EditPencil"
                />
              </IconButton> */}
              <IconButton>
                <img
                  className=" secondary"
                  src={AddSkillOrange}
                  alt="AddSkill"
                />
              </IconButton>
            </div>
          </div>
          <div className="skillLine"></div>

          <div className="skillBarsContainer">
            {competencyList?.map?.((item) => {
              return (
                <>
                  {item?.item?.status === "Approved" && (
                    <div className="skillBarContainer">
                      <label>{item?.item?.competency}</label>
                      <div className="">
                        <ProgressBarComponent
                          percentage={
                            item?.item?.proficiencyLevel
                              ? Math.floor(
                                  (item?.item?.proficiencyLevel / 5) * 100
                                )
                              : 0
                          }
                          color={"#f7941d"}
                          widthTotal={"100%"}
                          style={{
                            backgroundColor: "#fff4e5",
                            width: "91%",
                          }}
                          // style={{
                          //   height: "7px",
                          //   backgroundColor: "#fff4e5",
                          //   width: "91%",
                          //   borderRadius: "10px",
                          // }}
                          // progressStyle={{
                          //   height: "7px",
                          //   borderRadius: "10px",
                          //   backgroundColor: "#f7941d",
                          // }}
                          textStyle={{
                            color: "#f7941d",
                          }}
                        />
                        {/* <div className="skillLineBackground">
                      <div className="skillBarLine ten" />
                    </div>
                    <label>
                      {item?.item?.proficiencyLevel
                        ? Math.floor((item?.item?.proficiencyLevel / 5) * 100)
                        : 0}
                      %
                    </label> */}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { SkillsCompetency };
