import React from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import CalendarComponent from "./CalendarComponent";
import { postRequest } from "../../../Services/axios";
import { useState } from "react";
import { isLoading, showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { CalendarPopup } from "./CalendarPopup";
import { format } from "date-fns";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
function CalendarComponentList({ isList }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [filteredDatapopup, setfilteredDatapopup] = useState();
  const togglePopupDate = () => {
    setIsOpen((prev) => !prev);
  };

  const [roomAvailable, setRoomAvailable] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState();

  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 1000,
      iDisplayStart: 0,
      sSearch: "",
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  const roomidOptions = async () => {
    try {
      const result = await postRequest(
        "MeetingRoomBookings/GetMeetingRooms",
        reqData
      );
      // console.log("MeetingRoomBookings", result);
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      setRoomAvailable(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
    }
  };
// console.log("selectedRoom", selectedRoom);
  useEffect(() => {
    roomidOptions();
  }, []);
  return (
    <div className="CalendarComponentListdiv">
      <div>
        {/* <SelectForm name="showbookingfor" options={roomAvailable} /> */}
        <SelectCustom
          dropdownHeight="236px"
          styles={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}
          values={selectedRoom}
          options={roomAvailable}
          onChange={(value) => {
            // setIdActive(value?.[0]?.value);
            setSelectedRoom(value);
          }}
          fullWidth={true}
          styl={{
            backgroundColor: "var(--primary-hover)",
            margin: 0,
            minHeight: "40px",
            maxHeight: "40px",
            padding: "5px 10px",
          }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <CalendarComponent
          isList={isList}
          calendarRoomId={selectedRoom && selectedRoom[0]?.value}
          togglePopupDate={togglePopupDate}
          setfilteredDatapopup={setfilteredDatapopup}
        />
      </div>
      <div style={{ width: "100%" }}>
        {isOpen && (
          <Popup
            popupHeading={
              <section
                className="calendarPopupHeading"
                style={{
                  boxshadow: " 0px 0px 0px 0px rgba(0, 0, 0, 0.05)",
                  padding: "0px",
                }}
              >
                <div className="calendarPopupDate">
                  {filteredDatapopup?.date
                    ? format(new Date(filteredDatapopup?.date), "dd MMMM, yyyy")
                    : ""}
                </div>
                <div className="calendarPopupDay">
                  {filteredDatapopup?.date
                    ? format(new Date(filteredDatapopup?.date), "EEEE")
                    : ""}
                </div>
              </section>
            }
            secondClassName={"popupwidth"}
            contentClassName={"cancelcsspopup"}
            content={
              <CalendarPopup
                currentDate={
                  filteredDatapopup?.date ? filteredDatapopup?.date : ""
                }
                filteredData={filteredDatapopup ? filteredDatapopup : ""}
              />
            }
            handleClose={togglePopupDate}
          />
        )}
      </div>
    </div>
  );
}

export default CalendarComponentList;
