import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
// import "./Pipeline.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TTProjects } from '../../HRMS/Components/TTProjects';
import { PipelineTab } from './PipelineTab';

const Pipeline = () => {
    return (
        <div className="moduleMainContainer">
                    <PipelineTab title="Pipeline"/>
        </div>
    )
}

export { Pipeline }