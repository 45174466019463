import React from "react";

import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";

export const CreateBandComp = () => {
  useEffect(() => {
    document.title = `PeopleSol - Create Band `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"name"}
          placeholder={"Enter Name"}
        />
      </div>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Notice Period (In Days)"} mandatory={true} />
        <InputCustomFormik
          min="0"
          max="365"
          name="noticePeriod"
          step=".001"
          placeholder={"Enter Notice Period"}
        />
      </div>
      <div style={{ width: "49%" }}>
        <LabelCustom
          labelName={"Probation Period (In Days)"}
          mandatory={true}
        />
        <InputCustomFormik
          min="0"
          max="365"
          name="probationPeriod"
          step=".001"
          placeholder={"Enter Probation Period"}
        />
      </div>
    </div>
  );
};
