import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { getRequest } from "../../../../Services/axios";

const AssetCategoryForm = ({ data }) => {
  const [option, setOption] = React.useState([]);

  const getOptionReq = async () => {
    try {
      const result = await getRequest(
        "AssetSpecificationItems/GetSpecificationItem"
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Asset Category`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  let arrSpec = [];
  data?.item?.map((items) => {
    return arrSpec.push({ label: items.name, value: items.id });
  });
  let arrMaterial = [];
  data?.material?.map((items) => {
    return arrMaterial.push({ label: items.name, value: items.id });
  });
  useEffect(() => {
    getOptionReq();
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Asset Category"} mandatory={true} />
        <InputCustomFormik
          
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Asset Category"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Code"} mandatory={true} />
        <InputCustomFormik
          name={"code"}
          placeholder={" Enter Code "}
          
          maxLength={50}
        />
      </div>
      <div>
        <LabelCustom
          labelName={"Asset Category Specifications"}
          mandatory={true}
        />
        <SelectFormikMulti
          name={"assetTypeSpecificationIds"}
          options={option}
          values={arrSpec}
          placeholder={" Select Asset Category Specifications  "}
        />
      </div>
    </div>
  );
};
export { AssetCategoryForm };
