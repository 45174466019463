import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ThreeDot from "../../../../Assets/ThreeDotsIcon.svg"
import { Popover } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import GroupIcon from "../../../../Assets/ChatGroupIcon.svg"


export const ProfileGroup = ({ groups, createNewGroup, isGroup, activeChat, handleEvent, navigateTo }) => {
  const userDetails = useSelector((state) => state.chat.userDetails);
  const [open, setOpen] = useState(false)
  const [selected, setSelectedChat] = useState(false)
  const isAdmin = activeChat.participateMembers.filter(item => item.personalNumber === userDetails.personalNumber)[0].isAdmin;



  return (
    <div className="myProfileGroupWrapper">
      {!isGroup && <div className="creteNewGroup" style={{ flexDirection: "row", alignItems: "center", display: "flex" }} onClick={() => { createNewGroup() }}>
        <img src={GroupIcon} />
        <p>Create New Group</p>
      </div>}
      {!isGroup && groups.length > 0 &&
        groups?.map((item, index) => {
          return (
            <div className="goupList" key={"item" + index}>
              <div className="imageContainer">
                {item.icon && item.icon != "" ? <img src={item.icon} alt="groupProfilePic" />
                  :
                  <div style={{ backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 300, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <div style={{ color: "#13A9C7", fontSize: 20 }}>
                      {item.title
                        ?.split(" ")
                        ?.slice(0, 2)
                        ?.map((item) => {
                          return item?.charAt(0)?.toUpperCase();
                        })
                        .join("")
                      }
                    </div>
                  </div>}

              </div>
              <div className="textContainer">
                <h3>{item.title}</h3>
                {item.recentMessage.length ? <p>
                  {item.recentMessage[0].lastFromMsg}: {item.recentMessage[0].caption}
                </p> : null}
              </div>
            </div>
          );
        })}
      <Popover
        id={"simple-popover"}
        open={open}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={() => { setOpen(false) }}
      >
        <div
          className="dropdowncol"
          style={{
            fontFamily: "Poppins",
            fontSize: "12px",
          }}
        >
          <div className="taskCont" onClick={() => {
            setOpen(false)
            navigateTo(selected)
          }}>
            Message
          </div>
          <div className="taskCont" onClick={() => {
            setOpen(false)
            navigateTo(selected, true)
          }}>
            View
          </div>
          {activeChat.type != "Broadcast" && isAdmin && <div className="taskCont" onClick={(e) => {
            if (selected.contactId == activeChat.createdByContactId) {
              confirmAlert({
                title: "Access Denied",
                message: "You cannot remove the creator of this group as admin",
                buttons: [
                  {
                    label: "Cancel",
                    onClick: () => { },
                  },
                  {
                    label: "Okay",
                    onClick: () => { },
                  },
                ],
                overlayClassName: "customConfirmAlertContainer",
              });
            } else {
              confirmAlert({
                title: selected.isAdmin ? "Remove admin" : "Make admin",
                message: selected.isAdmin ? ("Do you want to remove " + selected.contactName + " as admin of the group?") : ("Do you want to mark " + selected.contactName + " as admin of the group?"),
                buttons: [
                  {
                    label: "Cancel",
                    onClick: () => { },
                  },
                  {
                    label: selected.isAdmin ? "Remove as Admin" : "Mark as admin",
                    onClick: () => {
                      handleEvent({ "conversationId": activeChat._id, "reqType": "isAdmin", "memberId": selected.personalNumber, "updateVal": selected.isAdmin ? false : true })
                      e.stopPropagation();
                    },
                  },
                ],
                overlayClassName: "customConfirmAlertContainer",
              });
            }

            setOpen(false)
          }}>
            {selected.isAdmin ? "Remove as admin" : "Make group admin"}
          </div>}
          {(activeChat.type == "Broadcast" || isAdmin) && <div className="taskCont" onClick={(e) => {


            if (selected.contactId == activeChat.createdByContactId) {
              confirmAlert({
                title: "Access Denied",
                message: "You cannot remove creator of this group!",
                buttons: [
                  {
                    label: "Cancel",
                    onClick: () => { },
                  },
                  {
                    label: "Okay",
                    onClick: () => { },
                  },
                ],
                overlayClassName: "customConfirmAlertContainer",
              });
            } else {
              confirmAlert({
                title: "Remove user from group",
                message: ("Do you want to remove " + selected.contactName + " from the group?"),
                buttons: [
                  {
                    label: "Cancel",
                    onClick: () => { },
                  },
                  {
                    label: "Remove",
                    onClick: () => {
                      handleEvent({ "conversationId": activeChat._id, "reqType": "removeMember", "memberPayload": [selected.personalNumber] }, "addOrRemove")

                      e.stopPropagation();
                    },
                  },
                ],
                overlayClassName: "customConfirmAlertContainer",
              });
            }
            setOpen(false)
          }}>
            Remove
          </div>}
        </div>
      </Popover>
      {isGroup && groups.length > 0 &&
        groups?.map((item, index) => {
          return (
            <div className="goupList" key={"item" + index} style={{ justifyContent: "space-between" }}>
              <div className="goupList" >
                <div className="imageContainer">
                  {item.image != "" ? <img src={item.image} alt="groupProfilePic" />
                    :
                    <div style={{ backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 300, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                      <div style={{ color: "#13A9C7", fontSize: 20 }}>
                        {item.contactName
                          ?.split(" ")
                          ?.slice(0, 2)
                          ?.map((item1) => {
                            return item1?.charAt(0)?.toUpperCase();
                          })
                          .join("")
                        }
                      </div>
                    </div>}

                </div>
                <div className="textContainer">
                  <h3>{item.contactName}</h3>
                  <p>
                    {item.designation}
                  </p>
                </div>
              </div>


              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {activeChat.type != "Broadcast" && (activeChat.createdByContactId === item.contactId || item.isAdmin) && <div style={{ padding: 5, borderRadius: 4, fontSize: 10, color: "#13A9C7", backgroundColor: "#E9F3F4", marginRight: 10, textAlign: "center" }}>
                  Group Admin
                </div>}
                {userDetails.personalNumber !== item.personalNumber ? <img onClick={() => { setSelectedChat(item); setOpen(true) }} alt={"menu"} src={ThreeDot} /> : null}
              </div>
            </div>
          );
        })}
    </div>
  )
}
