import React from "react";
import "./StarredMessages.css";
import { useCallback } from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Star from "../../../../Assets/starYellow.svg"
import DoubleTick from "../../../../Assets/DoubleTick.svg"
import DocIcon from "../../../../Assets/DocIconChat.svg"

function StarredMessages(props) {

  // const starredMsgHandler = useCallback((e) => {
  //   let moreActivity = document.querySelector(".starredMessagesContainer");
  //   if (moreActivity && !moreActivity?.contains(e.target)) {
  //     props.setStarredMessages(false);
  //   }
  // }, []);
  // useEffect(() => {
  //   document.addEventListener("mouseup", starredMsgHandler);
  //   return () => {
  //     document.removeEventListener("mouseup", starredMsgHandler);
  //   };
  // }, [starredMsgHandler]);

  const [starMessages, setStarMessage] = useState([]);
  const chatList = useSelector((state) => state.chat.conversationList);
  useEffect(() => {
    document.title = `PeopleSol - Starred Messages`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  useEffect(() => {
    if (props.newSocket) {
      props.newSocket.emit("getStarMessages", ({}), (res) => {
        console.log("------- get star message", res);
        if (res.status) setStarMessage([...res.data])
      })
    }
  }, [props.newSocket])



  const getTime = (date) => {
    if (!date) return "";
    let a = new Date(date).toTimeString()
    return a.substring(0, 5);
  }
  const getImage = (chat) => {
    console.log(chatList, "------> koi call kiya ?", chat)
    if (chatList && chat) {
      let a = chatList.filter((item) => item._id === chat.conversationId)[0].participateMembers.filter(item => item.contactId === chat.senderId)[0].image;
      console.log(chatList, "=========>", a)
      if (a != "") return a;
      return null;
    }

  }
  const getName = (chat) => {
    if (chatList && chat) {
      let a = chatList.filter((item) => item._id === chat.conversationId)[0].participateMembers.filter(item => item.contactId === chat.senderId)[0].contactName;
      return a;
    }
  }


  const GetMedia = ({ chat }) => {

    const audioRef = useRef(null);
    const [currDim, setCurrDim] = useState({ width: 0, height: 0 })
    useEffect(() => {
      if (chat.type == 3)
        getMeta(chat.content[0].url, (err, img) => {
          let a = { width: img?.naturalWidth, height: img?.naturalHeight };

          if (a.width > 350 || a.height > 250) {
            while (true) {
              if (a.width < 350 && a.height < 250) break;
              a.width /= 1.2;
              a.height /= 1.2;
            }
          }
          setCurrDim({ ...a })
        })
    }, [])
    const getMeta = (url, cb) => {
      const img = new Image();
      img.onload = () => cb(null, img);
      img.onerror = (err) => cb(err);
      img.src = url;
    };

    return (
      <>
        {chat.type === 3 ?
          <div style={{ width: currDim.width, height: currDim.height, overflow: "hidden" }}>
            <img style={{ width: currDim.width, height: currDim.height }} src={chat.content[0].url} />
          </div>
          : null
        }
        {chat.type === 2 ?
          <video
            src={chat.content[0].url}
            controls
            width="350"
          />
          : null
        }
        {chat.type === 1 ?
          <div>
            <audio style={{ width: "250px" }} ref={audioRef} src={chat.content[0].url} controls />
          </div> : null
        }
        {chat.type === 7 ?
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img src={DocIcon} style={{ marginRight: 10 }} />
            <div style={{ fontSize: 13, fontWeight: "900", color: "rgba(18, 150, 176, 1)" }}>
              <div>{chat.content[0].url.split("/").pop()}</div>
              {chat.content[0].url?.slice(-3) === "pdf" ? (
                <div>
                  {chat.content[0].url?.slice(-3)?.toUpperCase()}
                </div>
              ) : chat.content[0].url?.slice(-3) === "jpg" ||
                chat.content[0].url?.slice(-3) === "png" ||
                chat.content[0].url?.slice(-3) === "svg" ? (
                <div>{chat.content[0].url?.slice(-3)}</div>
              ) : chat.content[0].url?.slice(-4) === "jpeg" ||
                chat.content[0].url?.slice(-4) === "xlsx" ||
                chat.content[0].url?.slice(-4) === "docx" ? (
                <div>{chat.content[0].url?.slice(-4)?.toUpperCase()}</div>
              ) : (
                <div> File</div>
              )}
            </div>
          </div> : null
        }
      </>
    )
  }
  return (

    <div style={{ height: "100%", overflow: "auto", width: "100%", padding: 20 }}>
      {starMessages.map((chat, index) => (
        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
            <div className="profilePicContainer">
              {getImage(chat) ? <img
                style={{ width: 40, height: 40, borderRadius: 25 }}
                className="profilePic"
                src={getImage(chat)}
                alt="Profile"
              />
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 40, width: 40, borderRadius: 25, backgroundColor: "rgba(216, 240, 242, 1)", color: "rgba(18, 150, 176, 1)", fontSize: 12 }}>
                  {getName(chat)
                    ?.split(" ")
                    ?.slice(0, 2)
                    ?.map((item) => {
                      return item?.charAt(0)?.toUpperCase();
                    })
                    .join("")
                  }
                </div>
              }
            </div>
            <div style={{ color: "grey", fontSize: 10 }}>{getTime(chat.lastUpdatedOn)}</div>
          </div>
          <div style={{ backgroundColor: "rgba(216, 240, 242, 1)", padding: 10, borderRadius: "8px 0px 8px 8px" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 10 }}>
              {chat.caption ? chat.caption : " "}
              <div style={{ marginLeft: chat.caption ? 10 : 0, alignSelf: "flex-end" }}>
                <img src={Star} />
              </div>
            </div>
            {chat.type > 0 ? <GetMedia chat={chat} /> : null}

          </div>
        </div>
      ))}
    </div>
  );
}

export default StarredMessages;
