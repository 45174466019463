import React, { useEffect, useState } from "react";

import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";

const opt = [{ label: "select", value: null }];

export const CreatePersonalSubAreaComp = ({ data, editID }) => {
  const [activ, setActiv] = useState(null);

  const [employeeValuesArray, setEmployeeValuesArray] = useState([]);
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [personalList, setPersonalList] = useState([]);

  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };

  const getPersonalData = async () => {
    try {
      const result = await getRequest("Personal/GetPersonal");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });

      setPersonalList(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Personal SubArea `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getPersonalData();
  }, []);
  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    if (data) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (data) {
      setPage(0);
      let idArray = [];
      if (data?.employee && data?.hrbp?.id) {
        idArray = data?.employee?.map((item) => item.id);
        idArray.push(data?.hrbp?.id);
      } else idArray = [];
      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);
  useEffect(() => {
    if (employeeList.length >= 0) {
      data?.employee?.map((item) => {
        let tempVal = employeeList?.filter(
          (options) => options?.value === item.id
        );
        if (tempVal.length > 0) {
          setEmployeeValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
  }, [employeeList]);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Security(s)/Admin(s)"} />
        <DropdownInfiniteScroll
          multi={true}
          postMethod={false}
          name="securityAdminId"
          placeholder={"Select Security/Admin"}
          details={data}
          editID={editID}
          detailsForEdit={data?.employee?.map((item) => item.id)}
        />
      </div>
      <div>
        <LabelCustom labelName={"Personal Area"} />
        <SelectForm
          name="locationGroupId"
          placeholder={"Select Personal Area"}
          options={personalList}
          values={
            data
              ? personalList.filter(
                  (options) => options.label === data?.locationGroup?.text
                )
              : opt
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"HRBP"} />
        <DropdownInfiniteScroll
          // multi={true}
          postMethod={false}
          name="hrbp"
          placeholder={"Select HRBP"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.hrbp?.id]}
        />
        {/* <SelectForm
          name="hrbp"
          placeholder={"Select HRBP"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        /> */}
      </div>
      <div>
        <LabelCustom labelName={"Is Metro?"} />
        <YesNoFormik
          name="isMetro"
          checkedyes={activ === true}
          checkedNo={activ === false}
          onChangeyes={(e) => setActiv(e.target.value === "Yes" ? true : false)}
          onChangeNo={(e) => setActiv(e.target.value === "Yes" ? true : false)}
        />
      </div>
    </div>
  );
};
