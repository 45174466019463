import { Table } from "@mui/material";
import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { FieldArray } from "formik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
const weekdaysOptions = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];
function WeekdayOff() {
  React.useEffect(() => {
    document.title = `PeopleSol - Weekly Off `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  return (
    <FieldArray name="weekDays">
      {(fieldArrayProps) => {
        const { push, remove, form } = fieldArrayProps;
        const { values } = form;
        const { weekDays } = values;

        return (
          <StyledTableCont>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    padding="0"
                    style={{
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    <LabelCustom labelName={"S. No"} />
                  </StyledTableCell>
                  <StyledTableCell
                    padding="0"
                    style={{
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    <LabelCustom labelName={"Week Days"} mandatory={true} />
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    <LabelCustom labelName={"Title"} mandatory={true} />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    <LabelCustom labelName={"From"} mandatory={true} />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    <LabelCustom labelName={"To"} mandatory={true} />
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    <LabelCustom labelName={"Add/Remove"} />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {weekDays.map((weekDayss, index) => (
                <TableBody key={index}>
                  {/* 1st column */}
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>

                  {/* 2nd column */}
                  <StyledTableCell>
                    <SelectForm
                      placeholder="Select Week Days"
                      name={`weekDays[${index}].days`}
                      options={weekdaysOptions}
                    />
                  </StyledTableCell>

                  {/* 3rd column */}
                  <StyledTableCell>
                    <InputCustomFormik
                    
                    maxLength={50}
                      type="text"
                      name={`weekDays[${index}].title`}
                      placeholder={" Select Title "}
                    />
                  </StyledTableCell>

                  {/* 4th column */}
                  <StyledTableCell>
                    <TimeCustomFormik
                      name={`weekDays[${index}].fromTime`}
                      placeholder="Select To Time"
                    />
                  </StyledTableCell>

                  {/* 5th column */}
                  <StyledTableCell>
                    <TimeCustomFormik
                    minTime={weekDays?.fromTime}
                      name={`weekDays[${index}].toTime`}
                      placeholder="Select To Time"
                    />
                  </StyledTableCell>

                  {/* 6th column */}
                  <StyledTableCell
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <button
                      type="button"
                      className="remove-btn"
                      onClick={() =>
                        weekDays.length > 1
                          ? remove(index)
                          : dispatch(
                              showToast({
                                text: "Atleast One Item is Required ",
                                severity: "error",
                              })
                            )
                      }
                    >
                      -
                    </button>
                    <button
                      type="button"
                      className="add-btn"
                      onClick={() =>
                        push({
                          days: "",
                          title: "",
                          fromTime: "",
                          toTime: "",
                        })
                      }
                    >
                      +
                    </button>
                  </StyledTableCell>
                </TableBody>
              ))}
            </Table>
          </StyledTableCont>
        );
      }}
    </FieldArray>
  );
}

export { WeekdayOff };
