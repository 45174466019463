import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { format } from "date-fns";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import InfiniteScroll from "react-infinite-scroll-component";
import Nodata from "../../../../Assets/Nodata.svg";
import DetailsIcon from "../../../../Assets/DetailsIcon.svg";
import { getModuleDetails } from "../../../../Utilities/getModuleDetails";

export const PendingHomeRequest = ({ navigateToRoute }) => {
  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [datas, setdatas] = useState();
  // const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil((detailsform?.length || 0) / itemsPerPage);

  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Settings/GetMyOpenRequests`);
      setDetailsform(result?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFormDetails();
    document.title = `PeopleSol - Pending Home Requests`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : detailsform.length > 0 ? (
        <>
          <div style={{ height: "100%" }}>
            <div style={{ height: "560px" }}>
              <table className="responsive-table tableHeaderRow">
                {/* Table Header */}
                <thead style={{ position: "sticky" }}>
                  <tr>
                    <th>S. No</th>
                    <th></th>
                    <th>Request Number</th>
                    <th>Requester</th>
                    <th>Date</th>
                    <th>Module</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <InfiniteScroll
                dataLength={detailsform?.length}
                next={getFormDetails}
                hasMore={currentPage?.length < 20 ? false : true}
                loader={<h4> Loading...</h4>}
                height={800}
                width={500}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              > */}
                  {detailsform?.map((item, index) => (
                    <>
                      <tr key={item?.id}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            className="cursor"
                            // onClick={() => {
                            //   const moduleDetails = getModuleDetails(
                            //     item?.module,
                            //     item?.id
                            //   );
                            //   if (moduleDetails?.returnUrl) {
                            //     navigateToRoute(moduleDetails.returnUrl);
                            //   } else {
                            //   }
                            // }}
                            onClick={() => {
                              navigateToRoute(item?.url);
                            }}
                            src={DetailsIcon}
                            alt=""
                          />
                        </td>
                        <td>{item?.requestNumber}</td>
                        <td>{item?.requester}</td>
                        <td>
                          {item?.date &&
                            format(new Date(item?.date), "dd/MM/yyyy hh:mm a")}
                        </td>
                        <td
                          style={{
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "300px",
                          }}
                        >
                          {item?.module}
                        </td>
                      </tr>
                    </>
                  ))}
                  {/* </InfiniteScroll> */}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="noDatastyle" style={{ padding: "75px 105px" }}>
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Data Found"}</h4>
        </div>
      )}
    </>
  );
};
