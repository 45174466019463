import React from 'react'
import { useEffect } from 'react';

const HolidayCardComp = ({title, date}) => {
 
  return (
    <div className="attendanceCardContentContainer" style={{backgroundColor:`#F0F0FF`, borderLeft: '2px solid #8A88ED'}}>
      <div className="cardDateDisplay">{date ? date : ""}</div>
        <div className='attendanceContentStatus' style={{color:"#8A88ED", fontSize: '10px', width: 'calc(100% - 3px)', lineHeight: '12px'}} >
            <label>{title ? title : ""}</label>
        </div>
        
        
        </div>
  )
}

export {HolidayCardComp}
