import React from "react";
import dateFormat from "dateformat";
import SurveyDateTimeIcon from "../../../../../../../Assets/SurveyDateTimeIcon.svg";

function SurveyAndPollDescription({ descriptionData }) {
  return (
    <div className="surveyAndPollDescriptionContainer">
      <p>{descriptionData?.description}</p>
      <div className="surveyDateTimeContainer">
        <div className="surveyStartTimeContainer">
          <label>Survey Start Date</label>
          <div className="surveyStartTime">
            <img src={SurveyDateTimeIcon} alt="" />
            <span>{dateFormat(descriptionData?.startOn, "dd/mm/yyyy")}</span>
          </div>
        </div>
        <div className="surveyEndTimeContainer">
          <label>Survey End Date</label>
          <div className="surveyEndTime">
            <img src={SurveyDateTimeIcon} alt="" />
            <span>{dateFormat(descriptionData?.endOn, "dd/mm/yyyy")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyAndPollDescription;
