import React from 'react';

const EarningsComponent = ({ earnings ,employeeSummary}) => {
  // Calculate the total earnings
  const totalEarnings = earnings.reduce((total, earning) => total + earning.amount, 0);

  // Filter earnings by type
  const earningsByType = earnings.reduce((accumulator, earning) => {
    if (!accumulator[earning.type]) {
      accumulator[earning.type] = 0;
    }
    accumulator[earning.type] += earning.amount;
    return accumulator;
  }, {});

  return (
    <div className="paySummaryComponentContainer">
      <div className="paySummaryComponentHeader">
        <h6>EARNINGS</h6>
        <h6>AMOUNT</h6>
      </div>
      <div className="paySummaryComponentMain">
        {Object.keys(earningsByType).map((earningType, index) => (
          <div className="paySummaryComponentList" key={index}>
            <p className="empheadingSlip">{earningType}</p>
            <p className="empBodySlip">₹{earningsByType[earningType]}</p>
          </div>
        ))}
      </div>
      <div className="paySummaryComponentFooter">
        <p className='empheadingSlip'>Gross Earnings</p>
        <p className="empBodySlip">₹{employeeSummary.totalEarning}</p>
      </div>
    </div>
  );
};

export  {EarningsComponent};
