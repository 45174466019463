import React, { useState } from "react";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../Services/axios";
import { showToast, isLoading } from "../../../Features";
import { useDispatch } from "react-redux";

import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { SelectFormik } from "../../../Components/FormComponent/SelectFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { useEffect } from "react";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";

export const CreateNewsComp = () => {
  const [categoryList, setCategoryList] = useState([]);
  const dispatch = useDispatch();
  const getCategory = async () => {
    try {
      const result = await getRequest("ConfigValues/GetCategory");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.key, value: item.key });
      });
      setCategoryList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getCategory();
    document.title = `PeopleSol - Create News`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Category"} mandatory={true} />
        <SelectForm
          name={`category`}
          options={categoryList}
          placeholder={"Select Category"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload"} />
        <FileUploadFormik name={`upload`} />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>Size: 2 MB</span>
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>Format: JPEG, PNG, JPG</span>
      </div>
      <div>
        <LabelCustom labelName={"Link"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="link"
          type={"text"}
          placeholder={"Enter Link"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Headlines"} mandatory={true} />
        <TextAreaFormik
          maxLength={300}
          name="shortDescription"
          placeholder={"Enter Headlines"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} mandatory={true} />
        <TextAreaFormik
          maxLength={500}
          name="description"
          placeholder={"Enter Description"}
        />
      </div>

    </div>
  );
};
