import React, { useEffect, useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";

export const CreateSkillSetComp = ({ formValues }) => {
  const [subDepartment, setSubDepartment] = useState();
  const [department, setDepartment] = useState();
  const getSubDepartmentData = async (id) => {
    try {
      const result = await getRequest(`Department/GetSubDepartment/${id}`);
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setSubDepartment(listData);
    } catch (error) {
    } finally {
    }
  };
  const getDepartment = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetDepartments");
      // console.log("resultLocationGrp", result)
      let listData = [];
      result.data?.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setDepartment(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getDepartment();
  }, []);
  useEffect(() => {
    if (formValues?.DepartmentId) {
      getSubDepartmentData(formValues?.DepartmentId);
    }
  }, [formValues?.DepartmentId]);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Skill Set Name"} mandatory={true} />
        <InputCustomFormik
          name="SkillSetName"
          type={"text"}
          placeholder={"Please Enter Place"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Department"} mandatory={true} />
        <SelectForm name="DepartmentId" options={department} />
      </div>
      <div>
        <LabelCustom labelName={"Sub Department"} mandatory={true} />
        <SelectForm name="SubDepartmentId" options={subDepartment} />
      </div>
    </div>
  );
};
