import React, { useEffect, useRef, useState } from "react";
import "./LearningVideos.css";
import { IconButton } from "@mui/material";
import backbuttonpic from "../../../Assets/backbuttonpic.svg";
import Nodata from "../../../Assets/Nodata.svg";
import FullscreenLD from "../../../Assets/FullscreenLD.svg";
import ChapterSmallIcon from "../../../Assets/ChapterSmallIcon.svg";
import completedFlag from "../../../Assets/completedFlag.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { CertificateComp } from "./CertificateComp";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { Loader } from "../../../Components/FormComponent/Loader";
import { PopupForQuestion } from "./PopupForQuestion";
import { QuestionSet } from "./QuestionSet";
import DropDownBlueIcon from "../../../Assets/DropDownBlueIcon.svg";
import { ProgressBarComponent } from "./ProgressBarComponent";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { format } from "date-fns";
import { DetailsLD } from "../../HRMS/Components/DetailsComp/DetailsLD";
import VideoPlayerComponent from "./VideoPlayerComponent";

const LearningVideos = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [detailsVideo, setDetailsVideo] = useState();
  const [allAssignment, setAllAssignment] = useState();
  const [allAssignmentSecond, setAllAssignmentSecond] = useState();
  const [allCertificate, setAllCertificate] = useState();
  const [chapteridState, setChapteridState] = useState();
  const [courseidState, setCourseidState] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [allComments, setAllComments] = useState([]);
  const [durationfullvideo, setDurationfullvideo] = useState();
  const [active, setActive] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [videoQuestData, setVideoQuestData] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [courseToBePlayed, setCourseToBePlayed] = useState();
  const [playedSecs, setPlayedSecs] = useState();
  const [playingStatus, setPlayingStatus] = useState(false);
  const [chapterActive, setChapterActive] = useState(false);
  const [courseActive, setCourseActive] = useState(false);
  const [categoryActive, setCategoryActive] = useState(false);
  const [playingcontroller, setPlayingcontroller] = useState(false);
  const [currQuestion, setCurrQuestion] = useState({});
  const [lDOpen, setLDOpen] = useState(false);
  const [clickFullscreen, setClickFullscreen] = useState(false);
  const [groupedarrData, setGroupedarrData] = useState([]);
  const [requiredCertificate, setRequiredCertificate] = useState();
  const [lDobj, setLDobj] = useState("");
  const [lDtype, setLDtype] = useState("");
  const [viewCountResult, setViewCountResult] = useState("");
  const [chapterCoverimg, setchapterCoverimg] = useState("");
  const [lightt, setLight] = useState(true);
  const [firstChapterActive, setFirstChapterActive] = useState("");
  const [onceOnReady, setOnceOnReady] = useState(true);
  const [openLdPopup, setOpenLdPopup] = useState(true);
  const [oneTime, setOneTime] = useState(false);
  const [prevTime, setprevTime] = useState(0);
  const [lDnum, setLDnum] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const categoryId = location.state?.categoryId;
  let payload;
  payload = {
    CategoryID: categoryId,
    dataTableRequest: {
      // iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      // sSearch: searchParam,
      // sorting: sortParam,
      // sSortDir_0: sortType,
      isExport: false,
    },
  };

  function timeToSeconds(timeString) {
    const parts = timeString.split(":");
    if (parts.length !== 3) {
      // throw new Error("Invalid time format");
    }

    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }
  const secstotimeformat = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    let minutes = Math.floor(remainingSeconds / 60);
    let seconds = remainingSeconds % 60;
    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
  };
  // console.log("object allAssignment", allAssignment);
  // console.log("object allAssignmentSecond", allAssignmentSecond);
  const toggleLDPopup = (obj, type, num) => {
    setLDOpen((prev) => !prev);
    setLDobj(obj);
    setLDtype(type);
    setLDnum(num);
    getAllAssignmentSecond();
    if (type === "chapter") {
      getView1();
    }
  };

  const getAllCertificate = async () => {
    try {
      // dispatch(isLoading(true));
      // setLoading(true);
      const result = await getRequest(`LMSController/FeedbackAggregates`);
      setAllCertificate(result.data);
      groupCategoryCetificate(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      // dispatch(isLoading(false));
      // setLoading(false);
    }
  };
  const groupCategoryCetificate = (datga) => {
    const certificateItem = datga?.find(
      (item) => item?.courses[0]?.categoryid === categoryId
    );
    setRequiredCertificate(certificateItem);
  };
  const handlePlayPause = (val) => {
    // setPlayingcontroller((prev) => {
    //   if (prev === true) {
    //     return val;
    //   }
    //   return val;
    // });
    setPlayingcontroller(val);
    // console.log("first isplay", val);
    setOnceOnReady(false);
  };
  const getAllAssignmentSecond = async () => {
    try {
      const result = await postRequest(`LMSController/AllMyLearnings`, payload);

      setAllAssignmentSecond(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      // sendprogress();
    }
  };
  const getAllAssignment = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(`LMSController/AllMyLearnings`, payload);

      setAllAssignment(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      // sendprogress();
    }
  };

  const sendprogress1 = async (totalDuration) => {
    try {
      let floorCurrentTime = 0;
      if (totalDuration) {
        floorCurrentTime = Math.floor(
          totalDuration - currentTime < 5 ? totalDuration : currentTime
        );
      } else {
        floorCurrentTime = Math.floor(currentTime);
      }
      if (
        courseActive !== undefined &&
        chapterActive !== undefined &&
        categoryActive !== undefined &&
        courseActive !== false &&
        categoryActive !== false &&
        chapterActive !== false
      ) {
        await getRequest(
          `LMSController/SaveTime?Time=${floorCurrentTime}&CourseID=${courseActive}&ChapterID=${chapterActive}&CategoryId=${categoryActive}`
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Progress not saved",
          severity: "error",
        })
      );
    } finally {
      setCurrentTime(0);
    }
  };
  const getView1 = async () => {
    try {
      let result;
      if (
        courseActive !== undefined &&
        chapterActive !== undefined &&
        courseActive !== false &&
        chapterActive !== false
      ) {
        result = await getRequest(
          `LMSController/viewcount?courseid=${courseActive}&chapterid=${chapterActive}`
        );
      }
      setViewCountResult(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "View Error",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const playerRef = useRef(null);
  // console.log("object videoQuestData", videoQuestData);
  const checkQuestionDisplay = (time) => {
    // console.log("object checkQuestionDisplay called");
    // console.log("object time", time);

    if (Math.floor(time) !== prevTime) {
      for (let i = 0; i < videoQuestData.length; i++) {
        if (
          time >= videoQuestData[i]["triggerPositionSecond"] &&
          (videoQuestData[i]["isShowed"] === false ||
            videoQuestData[i]["isShowed"] === undefined)
          // &&
          // !groupedarrData[i]?.questions?.every(
          //   (question) => question.isCorrect === true
          // )
        ) {
          setShowVideo(false);
          setModalStatus(true);
          // let arr = [...videoQuestData];
          let arr = videoQuestData;
          arr[i] = { ...videoQuestData[i], isShowed: true };
          setVideoQuestData(arr);
          setCurrQuestion(arr[i]);
          return;
        } else if (time < videoQuestData[i]["triggerPositionSecond"]) {
          break;
        }
      }
    }
    setprevTime((prev) => (prev = time));
    setCurrentTime(time);
  };
  const closeModal = () => {
    setShowVideo(true);
    setModalStatus(false);
    setPlayingcontroller(true);
  };
  const combinedCategoryArray = allAssignment?.courseCompletionPercentages?.map(
    (element, index) => ({
      ...element,
      course: allAssignment?.courseDurations[index]?.course,
      shortDescription: allAssignment?.courseDurations[index]?.shortDescription,
      totalDuration: allAssignment?.courseDurations[index]?.totalDuration,
    })
  );
  // console.log("object combinedCategoryArray", combinedCategoryArray);
  const combinedCourseArray = allAssignment?._data?.map((element, index) => ({
    ...element,
    chapterduration: allAssignment?.chapterduration[index],
    chapterPercentage: allAssignment?.chapterCounts[index]?.chapterPercentage,
  }));
  // console.log("object combinedCourseArray", combinedCourseArray);
  const groupedDataArray = {};
  combinedCourseArray?.forEach((item) => {
    const courseID = item.courseid;
    if (!groupedDataArray[courseID]) {
      groupedDataArray[courseID] = [];
    }
    groupedDataArray[courseID].push(item);
  });
  const groupedByCourse = Object.values(groupedDataArray);
  // console.log("object groupedByCourse", groupedByCourse);
  const flatgroupedByCourse = () => {
    const formatgroupedByCourse = groupedByCourse?.flat();
    // console.log("object calling findCourseToBePlayed", formatgroupedByCourse);
    findCourseToBePlayed(formatgroupedByCourse);
  };

  let LDFirstid;
  const findCourseToBePlayed = (dota) => {
    let resultToBePlayed;

    for (let i = 0; i < dota.length; i++) {
      if (
        (i === 0 && dota[i]?.playbackSeconds === 0) ||
        dota[i]?.duration - dota[i]?.playbackSeconds !== 0
      ) {
        resultToBePlayed = { index: i, value: dota[i] };
        setCourseToBePlayed(resultToBePlayed);
        setChapterActive(resultToBePlayed?.value?.chapterid);
        setFirstChapterActive(resultToBePlayed?.value?.chapterid);
        setCourseActive(resultToBePlayed?.value?.courseid);
        setCategoryActive(resultToBePlayed?.value?.id);
        // console.log(
        //   "object calling getVideoPlayerDetails",
        //   resultToBePlayed?.value?.chapterid
        // );

        getVideoPlayerDetails(resultToBePlayed?.value?.chapterid);
        // getAllComments(resultToBePlayed?.value?.chapterid);
        // setelementToScrollTo(resultToBePlayed);
        LDFirstid = document.getElementById(
          `${resultToBePlayed?.value?.course.split(" ")[0]}${
            resultToBePlayed?.value?.courseid
          }chapter${resultToBePlayed?.value?.chapterid}`
        );
        // LDFirstid.scrollIntoView({
        //   behavior: "smooth", // Scroll behavior, can be "auto" or "smooth" for smooth scrolling
        //   block: "center", // Vertical alignment, can be "start", "center", "end", or "nearest"
        //   inline: "start",
        // });
        if (tabIndex === 0) {
          setTimeout(() => {
            LDFirstid.scrollIntoView({
              behavior: "smooth", // Scroll behavior, can be "auto" or "smooth" for smooth scrolling
              block: "center", // Vertical alignment, can be "start", "center", "end", or "nearest"
              inline: "start",
            });
          }, 100);
        }

        break;
      } else {
        resultToBePlayed = { index: 0, value: dota[0] };
        setCourseToBePlayed(resultToBePlayed);
        setChapterActive(resultToBePlayed?.value?.chapterid);
        setFirstChapterActive(resultToBePlayed?.value?.chapterid);
        setCourseActive(resultToBePlayed?.value?.courseid);
        setCategoryActive(resultToBePlayed?.value?.id);

        getVideoPlayerDetails(resultToBePlayed?.value?.chapterid);

        LDFirstid = document.getElementById(
          `${resultToBePlayed?.value?.course.split(" ")[0]}${
            resultToBePlayed?.value?.courseid
          }chapter${resultToBePlayed?.value?.chapterid}`
        );

        if (tabIndex === 0) {
          setTimeout(() => {
            LDFirstid.scrollIntoView({
              behavior: "smooth", // Scroll behavior, can be "auto" or "smooth" for smooth scrolling
              block: "center", // Vertical alignment, can be "start", "center", "end", or "nearest"
              inline: "start",
            });
          }, 100);
        }
        break;
      }
    }
  };

  const clickHandler = (id, idArrow, course) => {
    const dropdown = document.getElementById(id);
    const dropdownarrow = document.getElementById(idArrow);
    if (dropdown && dropdownarrow) {
      if (dropdown.classList.contains("displayblock")) {
        dropdown.classList.remove("displayblock");
        dropdown.classList.add("displaynone");
        dropdownarrow.style.transform = "rotate(0deg)";
      } else if (dropdown.classList.contains("displayblock") && course) {
        // dropdown.classList.toggle("displayblock");
        // dropdownarrow.style.transform = "rotate(0deg)";
      } else if (dropdown.classList.contains("displaynone")) {
        dropdown.classList.remove("displaynone");
        dropdown.classList.add("displayblock");
        dropdown.classList.add("dropdownBackgroundLD");
        dropdownarrow.style.transform = "rotate(180deg)";
      }
    }
  };

  // const playFunction = (a) => {
  //   setPlayingcontrollerAfter(a);
  //   if (a === false) {
  //     sendprogress1();
  //     // getView1();
  //   }
  //   // setPlayingStatus(a);
  // };
  const clickSet = (a) => {
    setTabIndex(a);
  };

  const getVideoPlayerDetails = async (apichapterid) => {
    try {
      // setLoading(true);
      const result = await getRequest(
        `LMSController/VideoPlayerFeedback?ID=${apichapterid}`
      );
      let arr = result?.data?.summary?.feedbackRequirements;
      let Groupedarr = result?.data?.summary?.groupedData;
      if (arr && arr.length) {
        arr.sort((a, b) => a.triggerPositionSecond - b.triggerPositionSecond);
      } else {
        arr = [];
      }
      if (Groupedarr && Groupedarr.length) {
        Groupedarr.sort(
          (a, b) => a.triggerPositionSecond - b.triggerPositionSecond
        );
      } else {
        Groupedarr = [];
      }
      setVideoQuestData(arr);
      setGroupedarrData(Groupedarr);
      setChapteridState(apichapterid);
      // setCourseidState(courseid);
      // setChapterForLight(result.data?.summary?.trainingCourseChapters[0]?.id);
      setDetailsVideo(result.data);
      setchapterCoverimg(
        `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TrainingCourseChapters/${result.data?.summary?.trainingCourseChapters?.coverImage}`
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - Learning Videos`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getAllAssignment();
    getAllCertificate();
    // setOneTime(false);
  }, []);
  useEffect(() => {
    if (allAssignment) flatgroupedByCourse();
  }, [allAssignment]);
  useEffect(() => {
    // handlePlayPause(false);
    // if (chapterActive === firstChapterActive) {
    setLight(true);
    // } else {
    //   setLight(false);
    // }
  }, [chapterActive]);

  useEffect(() => {
    sendprogress1();
    // getView1();
  }, [location.pathname]);
  useEffect(() => {
    setOnceOnReady(true);
    setTimeout(() => {
      sendprogress1();
      getView1();
    }, 1000);
  }, [chapterActive, courseActive, categoryActive]);
  useEffect(() => {
    if (tabIndex === 1) {
      getAllCertificate();
    }
  }, [tabIndex]);
  // useEffect(() => {
  //   setLight(true);
  // }, [detailsVideo?.summary?.trainingCourseChapters?.videoUrl]);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  // useEffect(() => {
  //   if (modalStatus === true) {
  //     setPlayingcontroller(false);
  //   } else setPlayingcontroller(true);
  // }, [modalStatus]);
  useEffect(() => {
    document.title = `PeopleSol - Learning Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    setOpenLdPopup(true);
  }, [detailsVideo?.summary?.trainingCourseChapters?.id]);

  useEffect(() => {
    if (playingcontroller === false) {
      sendprogress1();
    }
  }, [playingcontroller]);

  return (
    <>
      <div
        className="timelineContainerld candidateProfileContainer"
        style={{
          top: "12px",
          width: "calc(100% - 24px)",
          height: "calc(100% - 24px)",
          position: "absolute",
          zIndex: "4",
          overflow: "hidden",
          paddingBottom: "17px",
          margin: " 0px 12px",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="learningvidoesheading">
              <button
                onClick={() => {
                  navigate("mylearning");
                  sendprogress1();
                  // getView1();
                }}
                className="button secondaryButton"
                style={{
                  padding: "0px",
                  margin: " 0px 12px 0px 0px",
                  minWidth: "23px",
                }}
              >
                <img src={backbuttonpic} alt="Previous button" />
              </button>

              <h3 className="learnigvideoheadingfont">
                {allAssignment ? combinedCourseArray[0]?.category : " "}
              </h3>
            </div>
            <div
              className="horizontalLine"
              style={{
                height: "2px",
                margin: "3px 0px",
                width: "100%",
                boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.04)",
              }}
            >
              {" "}
            </div>
            {allAssignment?.courseDurations?.length > 0 ? (
              <div
                className="learningVideoMainContainer"
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="videoContainer">
                  <div
                    className="videoCardLD"
                    id="fullScreenIconVideoLD"
                    style={clickFullscreen ? { position: "static" } : {}}
                  >
                    <VideoPlayerComponent
                      chapterActive={chapterActive}
                      clickFullscreen={clickFullscreen}
                      playerRef={playerRef}
                      chapterCoverimg={chapterCoverimg}
                      modalStatus={modalStatus}
                      playingcontroller={playingcontroller}
                      setPlayedSecs={setPlayedSecs}
                      checkQuestionDisplay={checkQuestionDisplay}
                      detailsVideo={detailsVideo}
                      onceOnReady={onceOnReady}
                      handlePlayPause={handlePlayPause}
                      setDurationfullvideo={setDurationfullvideo}
                      getAllAssignmentSecond={getAllAssignmentSecond}
                      // playFunction={playFunction}
                      sendprogress1={sendprogress1}
                      setPlayingcontroller={setPlayingcontroller}
                    />
                    {modalStatus && (
                      <PopupForQuestion
                        clickFullscreen={clickFullscreen}
                        content={
                          <>
                            <QuestionSet
                              resubmitProp={
                                detailsVideo?.summary?.trainingCourseChapters
                                  ?.resubmit !== null
                                  ? detailsVideo?.summary
                                      ?.trainingCourseChapters?.resubmit ===
                                    true
                                    ? true
                                    : false
                                  : true
                              }
                              currQuestion={currQuestion}
                              detailsVideo={detailsVideo}
                              closeAd={closeModal}
                              chapteridState={chapteridState}
                            />
                          </>
                        }
                      />
                    )}
                    {!onceOnReady && (
                      <div
                        className={
                          clickFullscreen
                            ? detailsVideo?.summary?.trainingCourseChapters?.videoUrl?.includes(
                                `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
                              )
                              ? "transparentLayerOnVideoAfter "
                              : "transparentLayerOnVideoAfter "
                            : detailsVideo?.summary?.trainingCourseChapters?.videoUrl?.includes(
                                `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
                              )
                            ? "transparentLayerOnVideo "
                            : "transparentLayerOnVideo "
                        }
                      ></div>
                    )}{" "}
                    {!onceOnReady && (
                      <div
                        className={
                          clickFullscreen
                            ? detailsVideo?.summary?.trainingCourseChapters?.videoUrl?.includes(
                                `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
                              )
                              ? "displaynone"
                              : "peopleSolIconCoverAfter "
                            : detailsVideo?.summary?.trainingCourseChapters?.videoUrl?.includes(
                                `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
                              )
                            ? "displaynone"
                            : "peopleSolIconCover "
                        }
                      >
                        <img
                          style={{ maxWidth: "100%", objectFit: "contain" }}
                          src="/static/media/PeopleSolLogo.6d04b8532a446955ec87ff3cf1f15653.svg"
                          alt=""
                        />
                      </div>
                    )}
                    {!onceOnReady && (
                      <div
                        onClick={() => setClickFullscreen((prev) => !prev)}
                        className={
                          clickFullscreen
                            ? detailsVideo?.summary?.trainingCourseChapters?.videoUrl?.includes(
                                `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
                              )
                              ? "absoluteFullScreenDivFullFile "
                              : "absoluteFullScreenDivFull "
                            : detailsVideo?.summary?.trainingCourseChapters?.videoUrl?.includes(
                                `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
                              )
                            ? "absoluteFullScreenDivFile "
                            : "absoluteFullScreenDiv "
                        }
                      >
                        <img
                          className={
                            detailsVideo?.summary?.trainingCourseChapters?.videoUrl?.includes(
                              `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
                            )
                              ? "absoluteFullScreenFile fade-out-divLD"
                              : "absoluteFullScreen fade-out-divLD"
                          }
                          src={FullscreenLD}
                          alt="FULL SCREEN ICON"
                        />
                        <img
                          style={
                            playingcontroller === false
                              ? { opacity: "1" }
                              : { opacity: "0" }
                          }
                          className={
                            detailsVideo?.summary?.trainingCourseChapters?.videoUrl?.includes(
                              `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
                            )
                              ? "absoluteFullScreenFile"
                              : "absoluteFullScreen"
                          }
                          src={FullscreenLD}
                          alt="FULL SCREEN ICON"
                        />
                      </div>
                    )}
                    {/* {console.log("object openLdPopup", openLdPopup)} */}
                    {openLdPopup && (
                      <div className="LDvideoPopup">
                        <div
                          style={{
                            color: "#555555",
                            padding: "20px",
                            borderRadius: "5px",
                            fontSize: "17px",
                            // textAlign: "center",
                            width: "700px",
                            backgroundColor: "white",
                          }}
                        >
                          Kindly watch the video carefully. During the video
                          there will be a set of questions related to video on a
                          regular interval. <br />
                          <br />
                          Answering the questions correctly will be added to the
                          score, for you to be awarded a certificate of
                          completion at the end of the course/video by adding
                          minimum passing percentage of{" "}
                          {
                            detailsVideo?.summary?.trainingCourseChapters
                              ?.passPercentage
                          }
                          %.
                          <br /> <br />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ marginRight: 15 }}>
                              Do you want to watch the video?{" "}
                            </div>
                            <div
                              className="RedirectbuttonCover"
                              style={{
                                justifyContent: "flex-end",
                                width: "35%",
                              }}
                            >
                              <span
                                onClick={() => {
                                  navigate("mylearning");
                                }}
                                style={{
                                  backgroundColor: "#FDD2D2",
                                  color: "#F71D1D",
                                  width: 100,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "center",
                                  marginRight: -30,
                                }}
                                className="Redirectbutton cursor"
                              >
                                No
                              </span>{" "}
                              <div style={{ width: "50px" }}></div>
                              <span
                                onClick={() => setOpenLdPopup(false)}
                                className="Redirectbutton cursor"
                                style={{
                                  backgroundColor: "var(--primary)",
                                  color: "white",
                                  width: 100,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "center",
                                }}
                              >
                                Yes
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="videoDetailsHolder">
                    {commentLoading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <div>
                          <div className="videoDescriptionContainer">
                            <div className="descriptionHolder">
                              <label
                                className="countLabel"
                                style={{ fontSize: "17px" }}
                              >
                                {
                                  detailsVideo?.summary?.trainingCourseChapters
                                    ?.title
                                }
                              </label>
                            </div>
                            <div className="viewsHolder">
                              {detailsVideo?.summary?.trainingCourseChapters
                                ?.createdOn && (
                                <>
                                  {" "}
                                  <label
                                    className="countLabel viewsfont"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {!viewCountResult ? (
                                      ""
                                    ) : (
                                      <div>{viewCountResult} Views</div>
                                    )}
                                  </label>
                                  <div className="dotSeparator viewsfont"></div>
                                </>
                              )}
                              <label
                                className="countLabel viewsfont"
                                style={{ fontSize: "14px" }}
                              >
                                {detailsVideo?.summary?.trainingCourseChapters
                                  ?.createdOn && viewCountResult
                                  ? format(
                                      new Date(
                                        detailsVideo?.summary?.trainingCourseChapters?.createdOn
                                      ),
                                      "MMMM dd , yyyy"
                                    )
                                  : ""}
                              </label>
                            </div>
                          </div>
                          <div style={{ padding: "0px 21px" }}>
                            <label
                              className="commentTime "
                              style={{ alignSelf: "unset", fontSize: "15px" }}
                            >
                              {detailsVideo?.summary?.trainingCourseChapters?.description?.replace(
                                /<\/?[^>]+(>|$)/g,
                                ""
                              )}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="learningDetailsContainer">
                  <div
                    className="learningHeadingContainer"
                    style={{ width: "100%" }}
                  >
                    <div
                      className={`mainHeading ${
                        active && "mainHeadingDeactive"
                      }`}
                    >
                      <label>
                        {allAssignment ? combinedCourseArray[0]?.category : " "}{" "}
                        ({allAssignment?.coursees})
                      </label>
                    </div>
                    {/* <div
                      className={`certificateButton cursor ${
                        active && "certificateButtonActive"
                      }`}
                      onClick={() => clickHandler()}
                    >
                      <label>Certificate</label>
                    </div> */}
                  </div>

                  <div
                    className="learningDetailsBody"
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {/* {active && (
                      <div className="certificateList">
                        {allCertificate
                          ? allCertificate?.reuest?.map((item, index) => (
                              <CertificateComp
                                allCertificate={allCertificate}
                                index={index}
                                name={item?.title}
                                description={item?.shortDescription}
                              />
                            ))
                          : ""}
                      </div>
                    )} */}

                    <div
                      className="tabContainerld"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "5px",
                        border: " 0.625px solid rgba(91, 196, 191, 0.60)",
                        background: "#EBFDFC",
                        width: "278px",
                        margin: "0px 0px 20px 0px",
                        height: "44px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "124px",
                          height: "28px",
                        }}
                        onClick={() => clickSet(0)}
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            borderRadius: "5px",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            letterSpacing: "0.22px",
                            width: "113px",
                            // padding: "0px",
                          }}
                          className={`tabHeading ${
                            tabIndex === 0 && "tabActiveLD"
                          }`}
                        >
                          Course
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "124px",
                          height: "28px",
                        }}
                        onClick={() => clickSet(1)}
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            borderRadius: "5px",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            letterSpacing: "0.22px",
                            width: "113px",
                          }}
                          className={`tabHeading ${
                            tabIndex === 1 && "tabActiveLD"
                          }`}
                        >
                          Certificate
                        </span>
                      </div>

                      {/* <div className="tabContainerLine"></div> */}
                    </div>
                    <div
                      className="sideDetailsHolder"
                      style={{
                        overflowY: "auto",
                        // height: "59px",
                        height: "700px",
                        width: "100%",
                      }}
                    >
                      {tabIndex === 0 && (
                        <>
                          {combinedCategoryArray &&
                            combinedCategoryArray?.map((item, indexvalue) => {
                              return (
                                <>
                                  <div>
                                    <div
                                      className={
                                        courseActive ===
                                        groupedByCourse[indexvalue][0]?.courseid
                                          ? "dropdownHolderBackgroundLD learningDetailsCompContainer "
                                          : "learningDetailsCompContainer "
                                      }
                                      id={`${item?.course.split(" ")[0]}${
                                        groupedByCourse[indexvalue][0]?.courseid
                                      }`}
                                    >
                                      <div
                                        className="learningHeading"
                                        style={{
                                          color:
                                            "var(--people-s-ol-primery, #1296B0)",
                                          fontSize: "14px",
                                          width: "100%",
                                        }}
                                      >
                                        Chapter {indexvalue + 1} -{" "}
                                        {item?.course}
                                      </div>
                                      {/* <div className="commentOwner">{item?.shortDescription}</div> */}
                                      <div
                                        className="learningBodyDetails"
                                        style={{
                                          display: "flex",
                                          paddingTop: "6px",
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          className="learningBodyDetailsContent"
                                          style={{ width: "85px" }}
                                        >
                                          <div
                                            className="iconHolder"
                                            style={{ width: "21px" }}
                                          >
                                            <img
                                              className="courseDetailImage"
                                              src={ChapterSmallIcon}
                                              alt={ChapterSmallIcon}
                                            />
                                          </div>
                                          <div className="courseCountHolder">
                                            <label
                                              className="courseLabel"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {
                                                groupedByCourse[indexvalue]
                                                  ?.length
                                              }{" "}
                                              Lessons
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          className="learningBodyDetailsContent"
                                          style={{ width: "138px" }}
                                        >
                                          <div
                                            className="iconHolder"
                                            style={{
                                              width: "14px",
                                              marginLeft: " 17px",
                                              // margin-left: 27px;
                                              marginRight: "4px",
                                            }}
                                          >
                                            <svg
                                              style={{ marginRight: "8px" }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="10"
                                              height="11"
                                              viewBox="0 0 10 11"
                                              fill="none"
                                            >
                                              <path
                                                d="M4.57368 3.64935H5.56891V6.63506H4.57368V3.64935ZM6.56414 0.166016H3.57845V1.16125H6.56414V0.166016ZM8.56952 3.3458C9.2044 4.1377 9.55021 5.12246 9.54982 6.13744C9.54982 8.61061 7.55936 10.616 5.0713 10.616C3.88352 10.616 2.74439 10.1442 1.9045 9.30427C1.06462 8.46438 0.592773 7.32523 0.592773 6.13744C0.592773 4.94965 1.06462 3.81051 1.9045 2.97062C2.74439 2.13072 3.88352 1.65887 5.0713 1.65887C6.12624 1.65887 7.09658 2.02711 7.86788 2.65411L8.57449 1.93754C8.82828 2.15649 9.0522 2.3854 9.27613 2.63918L8.56952 3.3458ZM8.55459 6.13744C8.55459 4.21166 6.99706 2.65411 5.0713 2.65411C3.14553 2.65411 1.588 4.21166 1.588 6.13744C1.588 8.06323 3.14553 9.62078 5.0713 9.62078C6.99706 9.62078 8.55459 8.06323 8.55459 6.13744Z"
                                                fill="#1296B0"
                                              />
                                            </svg>
                                          </div>
                                          <div className="courseCountHolder">
                                            <label
                                              className="courseLabel"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {item.totalDuration}
                                            </label>
                                          </div>
                                        </div>
                                        <svg
                                          onClick={() =>
                                            // toggleLDPopup(
                                            //   groupedByCourse[indexvalue][0],
                                            //   "course"
                                            // )
                                            toggleLDPopup(
                                              item,
                                              "course",
                                              indexvalue
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15"
                                          height="15"
                                          viewBox="0 0 15 15"
                                          fill="none"
                                        >
                                          <circle
                                            cx="7.5127"
                                            cy="7.83398"
                                            r="7"
                                            fill="#1296B0"
                                          />
                                          <path
                                            d="M8.68282 6.38754L6.4122 6.67211L6.3309 7.04889L6.77709 7.13119C7.0686 7.2006 7.12611 7.3057 7.06265 7.59622L6.3309 11.0349C6.13854 11.9243 6.43501 12.3427 7.13206 12.3427C7.67244 12.3427 8.30009 12.0928 8.58466 11.7498L8.67191 11.3373C8.4736 11.5118 8.18408 11.5812 7.99172 11.5812C7.71905 11.5812 7.61989 11.3898 7.69029 11.0527L8.68282 6.38754ZM8.75223 4.31722C8.75223 4.58019 8.64776 4.83239 8.46181 5.01834C8.27586 5.20429 8.02366 5.30875 7.76069 5.30875C7.49772 5.30875 7.24552 5.20429 7.05957 5.01834C6.87362 4.83239 6.76916 4.58019 6.76916 4.31722C6.76916 4.05425 6.87362 3.80205 7.05957 3.6161C7.24552 3.43015 7.49772 3.32568 7.76069 3.32568C8.02366 3.32568 8.27586 3.43015 8.46181 3.6161C8.64776 3.80205 8.75223 4.05425 8.75223 4.31722Z"
                                            fill="white"
                                          />
                                        </svg>
                                        <div style={{ flex: "1" }}></div>
                                        <div
                                          className="dropdownIconHolder"
                                          style={{ userSelect: "none" }}
                                          onClick={() =>
                                            clickHandler(
                                              `${item?.course.split(" ")[0]}${
                                                groupedByCourse[indexvalue][0]
                                                  ?.courseid
                                              }dropdown`,
                                              `${item?.course.split(" ")[0]}${
                                                groupedByCourse[indexvalue][0]
                                                  ?.courseid
                                              }arrow`,
                                              courseActive ===
                                                groupedByCourse[indexvalue][0]
                                                  ?.courseid
                                            )
                                          }
                                        >
                                          <IconButton
                                            style={{
                                              width: "26px",
                                              height: "26px",
                                            }}
                                          >
                                            <img
                                              id={`${
                                                item?.course.split(" ")[0]
                                              }${
                                                groupedByCourse[indexvalue][0]
                                                  ?.courseid
                                              }arrow`}
                                              style={
                                                courseActive ===
                                                groupedByCourse[indexvalue][0]
                                                  ?.courseid
                                                  ? {
                                                      transform:
                                                        "rotate(180deg)",
                                                    }
                                                  : {}
                                              }
                                              src={DropDownBlueIcon}
                                              alt={DropDownBlueIcon}
                                            />
                                          </IconButton>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={
                                        {
                                          // borderBottom:
                                          //   "1px solid rgba(91, 196, 191, 0.50)",
                                        }
                                      }
                                    >
                                      <div
                                        id={`${item?.course.split(" ")[0]}${
                                          groupedByCourse[indexvalue][0]
                                            ?.courseid
                                        }dropdown`}
                                        style={{
                                          // backgroundColor: "rgb(239, 250, 251)",
                                          padding: "16px 0px 0px 0px",
                                          borderBottom:
                                            "1px solid rgba(91, 196, 191, 0.50)",
                                        }}
                                        className={
                                          courseActive ===
                                          groupedByCourse[indexvalue][0]
                                            ?.courseid
                                            ? "dropdownBackgroundLD displayblock"
                                            : "displaynone"
                                        }
                                      >
                                        {groupedByCourse &&
                                          groupedByCourse[indexvalue]?.map(
                                            (itemgroupedByCourse, index) => (
                                              <>
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                  // className="learningDetailsDDContainer cursor"
                                                  className={`${
                                                    chapterActive ===
                                                    itemgroupedByCourse?.chapterid
                                                      ? "learningDetailsDDContainerActive"
                                                      : itemgroupedByCourse.chapterPercentage ===
                                                        100
                                                      ? "learningDetailsDDContainerCompleted"
                                                      : "learningDetailsDDContainer"
                                                  }`}
                                                  id={`${
                                                    itemgroupedByCourse?.course.split(
                                                      " "
                                                    )[0]
                                                  }${
                                                    itemgroupedByCourse?.courseid
                                                  }chapter${
                                                    itemgroupedByCourse?.chapterid
                                                  }`}
                                                >
                                                  <div
                                                    style={{
                                                      width: "91%",
                                                      height: "100%",
                                                      // backgroundColor:
                                                      //   "aquamarine",
                                                      position: "absolute",
                                                      top: "0px",
                                                      zIndex: "5",
                                                      cursor: "pointer",
                                                    }}
                                                    className={
                                                      chapterActive ===
                                                      itemgroupedByCourse?.chapterid
                                                        ? "Onclickdisappear"
                                                        : ""
                                                    }
                                                    onClick={() => {
                                                      setModalStatus(false);
                                                      getVideoPlayerDetails(
                                                        itemgroupedByCourse?.chapterid
                                                      );
                                                      // getAllComments(
                                                      //   itemgroupedByCourse?.chapterid
                                                      // );
                                                      setCategoryActive(
                                                        itemgroupedByCourse?.id
                                                      );
                                                      setChapterActive(
                                                        itemgroupedByCourse?.chapterid
                                                      );
                                                      setCourseActive(
                                                        itemgroupedByCourse?.courseid
                                                      );
                                                      sendprogress1();
                                                      getView1();
                                                      // setPlayingcontroller(
                                                      //   true
                                                      // );
                                                    }}
                                                  ></div>
                                                  <div
                                                    className="learningBodyDetails"
                                                    style={{}}
                                                  >
                                                    <div
                                                      // className="learningHeading"
                                                      className={`${
                                                        chapterActive ===
                                                        itemgroupedByCourse?.chapterid
                                                          ? "learningHeadingActive"
                                                          : itemgroupedByCourse.chapterPercentage ===
                                                            100
                                                          ? "learningHeadingCompleted"
                                                          : "learningHeading"
                                                      }`}
                                                      style={{
                                                        width: "100%",
                                                        // cursor: "pointer",
                                                      }}
                                                    >
                                                      {" "}
                                                      Lesson {index + 1} -{" "}
                                                      {
                                                        itemgroupedByCourse.chapterName
                                                      }
                                                    </div>
                                                  </div>

                                                  <div
                                                    className="learningBodyDetailsContent"
                                                    style={{
                                                      flexDirection: "column",
                                                      position: "relative",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        height: "16px",
                                                      }}
                                                    >
                                                      {playingcontroller &&
                                                      chapterActive ===
                                                        itemgroupedByCourse?.chapterid
                                                        ? !onceOnReady && (
                                                            <svg
                                                              className="buttonpaly"
                                                              onClick={() =>
                                                                handlePlayPause(
                                                                  false
                                                                )
                                                              }
                                                              style={{
                                                                height: "15px",
                                                                margin:
                                                                  "0px 10px 0px 0px",
                                                              }}
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="10"
                                                              height="13"
                                                              viewBox="0 0 10 13"
                                                              fill="none"
                                                            >
                                                              <rect
                                                                x="0.303223"
                                                                y="0.525879"
                                                                width="2.5714"
                                                                height="12"
                                                                fill={`${
                                                                  chapterActive ===
                                                                  itemgroupedByCourse?.chapterid
                                                                    ? "white"
                                                                    : "#0E788D"
                                                                }`}
                                                              />
                                                              <rect
                                                                x="6.73193"
                                                                y="0.525879"
                                                                width="2.5714"
                                                                height="12"
                                                                fill={`${
                                                                  chapterActive ===
                                                                  itemgroupedByCourse?.chapterid
                                                                    ? "white"
                                                                    : "#0E788D"
                                                                }`}
                                                              />
                                                            </svg>
                                                          )
                                                        : !onceOnReady && (
                                                            <svg
                                                              className="buttonpaly"
                                                              style={{
                                                                margin:
                                                                  "0px 10px 0px 0px",
                                                              }}
                                                              onClick={() =>
                                                                handlePlayPause(
                                                                  true
                                                                )
                                                              }
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="13"
                                                              height="15"
                                                              viewBox="0 0 13 15"
                                                              fill="none"
                                                            >
                                                              <path
                                                                d="M0.544434 0.119629V14.1196L12.5243 7.11963L0.544434 0.119629Z"
                                                                fill={`${
                                                                  chapterActive ===
                                                                  itemgroupedByCourse?.chapterid
                                                                    ? "white"
                                                                    : "#0E788D"
                                                                }`}
                                                              />
                                                            </svg>
                                                          )}
                                                      {chapterActive ===
                                                      itemgroupedByCourse?.chapterid ? (
                                                        <ProgressBarComponent
                                                          percentage={
                                                            (playedSecs /
                                                              timeToSeconds(
                                                                itemgroupedByCourse.chapterduration
                                                              )) *
                                                            100?.toFixed(0)
                                                          }
                                                          color={"#0E788D"}
                                                          widthTotal={"100%"}
                                                          style={{
                                                            backgroundColor:
                                                              "#FFF",
                                                            width: "100%",
                                                          }}
                                                          showPercentage={false}
                                                        />
                                                      ) : itemgroupedByCourse.chapterPercentage ===
                                                        100 ? (
                                                        <ProgressBarComponent
                                                          percentage={
                                                            (itemgroupedByCourse?.playbackSeconds /
                                                              timeToSeconds(
                                                                itemgroupedByCourse.chapterduration
                                                              )) *
                                                            100?.toFixed(0)
                                                          }
                                                          color={"#0E788D"}
                                                          widthTotal={"100%"}
                                                          style={{
                                                            backgroundColor:
                                                              "#E6E6E6",
                                                            width: "100%",
                                                          }}
                                                          showPercentage={false}
                                                        />
                                                      ) : (
                                                        <ProgressBarComponent
                                                          percentage={
                                                            (itemgroupedByCourse?.playbackSeconds /
                                                              timeToSeconds(
                                                                itemgroupedByCourse.chapterduration
                                                              )) *
                                                            100?.toFixed(0)
                                                          }
                                                          color={"#0E788D"}
                                                          widthTotal={"100%"}
                                                          style={{
                                                            backgroundColor:
                                                              "#E6E6E6",
                                                            width: "100%",
                                                          }}
                                                          showPercentage={false}
                                                        />
                                                      )}
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginLeft: "22px",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <svg
                                                          style={{
                                                            marginRight: "7px",
                                                          }}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="10"
                                                          height="11"
                                                          viewBox="0 0 10 11"
                                                          fill="none"
                                                        >
                                                          <path
                                                            d="M4.73726 4.02376H5.68964V6.88091H4.73726V4.02376ZM6.64202 0.69043H3.78488V1.64281H6.64202V0.69043ZM8.56107 3.73329C9.16862 4.49108 9.49954 5.43344 9.49916 6.40472C9.49916 8.77138 7.5944 10.6904 5.21345 10.6904C4.07681 10.6904 2.98672 10.2389 2.18299 9.43517C1.37926 8.63145 0.927734 7.54136 0.927734 6.40472C0.927734 5.26807 1.37926 4.17799 2.18299 3.37426C2.98672 2.57053 4.07681 2.119 5.21345 2.119C6.22297 2.119 7.15154 2.47138 7.88964 3.07138L8.56583 2.38567C8.80869 2.59519 9.02297 2.81424 9.23726 3.0571L8.56107 3.73329ZM8.54678 6.40472C8.54678 4.56186 7.05631 3.07138 5.21345 3.07138C3.37059 3.07138 1.88012 4.56186 1.88012 6.40472C1.88012 8.24757 3.37059 9.73805 5.21345 9.73805C7.05631 9.73805 8.54678 8.24757 8.54678 6.40472Z"
                                                            fill={`${
                                                              chapterActive ===
                                                              itemgroupedByCourse?.chapterid
                                                                ? "white"
                                                                : itemgroupedByCourse.chapterPercentage ===
                                                                  100
                                                                ? "#1296B0"
                                                                : "#1296B0"
                                                            }`}
                                                          />
                                                        </svg>
                                                        <div
                                                          className={`${
                                                            chapterActive ===
                                                            itemgroupedByCourse?.chapterid
                                                              ? "timeFontChapterActive"
                                                              : itemgroupedByCourse.chapterPercentage ===
                                                                100
                                                              ? "timeFontChapter"
                                                              : "timeFontChapter"
                                                          }`}
                                                        >
                                                          {chapterActive ===
                                                          itemgroupedByCourse?.chapterid ? (
                                                            <>
                                                              {!isNaN(
                                                                playedSecs
                                                              )
                                                                ? `${secstotimeformat(
                                                                    playedSecs?.toFixed(
                                                                      0
                                                                    )
                                                                  )}/${
                                                                    itemgroupedByCourse.chapterduration
                                                                  }`
                                                                : `00:00:00/${itemgroupedByCourse.chapterduration}`}
                                                            </>
                                                          ) : (
                                                            <>{`${secstotimeformat(
                                                              itemgroupedByCourse?.playbackSeconds?.toFixed(
                                                                0
                                                              )
                                                            )}/${
                                                              itemgroupedByCourse.chapterduration
                                                            }`}</>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        {itemgroupedByCourse.chapterPercentage ===
                                                          100 &&
                                                          chapterActive !==
                                                            itemgroupedByCourse?.chapterid && (
                                                            <img
                                                              style={{
                                                                margin:
                                                                  "0px 10px 0px 0px",
                                                              }}
                                                              src={
                                                                completedFlag
                                                              }
                                                              alt="completed Flag"
                                                            />
                                                          )}
                                                        <svg
                                                          onClick={() =>
                                                            toggleLDPopup(
                                                              itemgroupedByCourse,
                                                              "chapter",
                                                              itemgroupedByCourse?.chapterid
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="14"
                                                          height="14"
                                                          viewBox="0 0 14 14"
                                                          fill="none"
                                                        >
                                                          <circle
                                                            cx="6.98779"
                                                            cy="7.19043"
                                                            r="6.5"
                                                            fill={`${
                                                              chapterActive ===
                                                              itemgroupedByCourse?.chapterid
                                                                ? "#D8F0F2"
                                                                : itemgroupedByCourse.chapterPercentage ===
                                                                  100
                                                                ? "#1296B0"
                                                                : "#1296B0"
                                                            }`}
                                                          />
                                                          <path
                                                            d="M8.0742 5.84706L5.96577 6.1113L5.89027 6.46117L6.30459 6.53759C6.57528 6.60204 6.62868 6.69964 6.56976 6.96941L5.89027 10.1624C5.71165 10.9883 5.98695 11.3768 6.63421 11.3768C7.13599 11.3768 7.7188 11.1448 7.98305 10.8263L8.06407 10.4432C7.87993 10.6053 7.61108 10.6697 7.43246 10.6697C7.17927 10.6697 7.08719 10.492 7.15256 10.179L8.0742 5.84706ZM8.13865 3.92462C8.13865 4.1688 8.04164 4.40299 7.86898 4.57566C7.69631 4.74832 7.46212 4.84533 7.21794 4.84533C6.97375 4.84533 6.73956 4.74832 6.5669 4.57566C6.39423 4.40299 6.29723 4.1688 6.29723 3.92462C6.29723 3.68043 6.39423 3.44624 6.5669 3.27358C6.73956 3.10091 6.97375 3.00391 7.21794 3.00391C7.46212 3.00391 7.69631 3.10091 7.86898 3.27358C8.04164 3.44624 8.13865 3.68043 8.13865 3.92462Z"
                                                            fill={`${
                                                              chapterActive ===
                                                              itemgroupedByCourse?.chapterid
                                                                ? "#1296B0"
                                                                : itemgroupedByCourse.chapterPercentage ===
                                                                  100
                                                                ? "white"
                                                                : "white"
                                                            }`}
                                                          />
                                                        </svg>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </>
                      )}
                      {tabIndex === 1 && (
                        <>
                          <CertificateComp
                            requiredCertificateProp={requiredCertificate}
                            allCertificate={allCertificate}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* {console.log("object lDobj", lDobj)} */}
                {lDOpen && (
                  <Popup
                    headStyle={{ marginLeft: 20 }}
                    popupHeading={
                      lDtype === "chapter"
                        ? "Lesson Details"
                        : "Chapter Details"
                    }
                    content={
                      lDobj ? (
                        <>
                          {lDtype === "chapter" ? (
                            <div style={{ width: "500px", padding: 20 }}>
                              {/* <DetailsLD
                                detailTitle={"Course Name"}
                                Details={lDobj?.category}
                              />
                              <DetailsLD
                                detailTitle={"Chapter Name"}
                                Details={lDobj?.course}
                              /> */}
                              <DetailsLD
                                detailTitle={"Lesson Name"}
                                Details={lDobj?.chapterName}
                              />
                              <DetailsLD
                                detailTitle={"Percentage of Lesson Completed"}
                                Details={
                                  allAssignmentSecond?.chapterCounts?.filter(
                                    (item) => item?.chapterId === lDnum
                                  )[0]?.chapterPercentage
                                }
                              />
                              <DetailsLD
                                detailTitle={"Lesson Pass Percentage"}
                                Details={lDobj?.passPercentage}
                              />
                              <DetailsLD
                                detailTitle={"Lesson Duration"}
                                Details={lDobj?.chapterduration}
                              />
                              <DetailsLD
                                detailTitle={"Lesson Description"}
                                Details={lDobj?.description}
                              />
                              <DetailsLD
                                detailTitle={"Number of Views"}
                                Details={viewCountResult}
                              />
                              {/* <DetailsLD
                                detailTitle={"Course Type"}
                                Details={lDobj?.type}
                              /> */}
                            </div>
                          ) : (
                            <div style={{ width: "500px", padding: 20 }}>
                              <DetailsLD
                                detailTitle={"Chapter Name"}
                                Details={lDobj?.course}
                              />

                              <DetailsLD
                                detailTitle={"Percentage of Chapter Completed"}
                                Details={
                                  allAssignmentSecond
                                    ?.courseCompletionPercentages[lDnum]
                                    ?.courseCompletionPercentage
                                }
                              />
                              <DetailsLD
                                detailTitle={"Chapter Duration"}
                                Details={lDobj?.totalDuration}
                              />
                              <DetailsLD
                                detailTitle={"Description of Chapter"}
                                Details={lDobj?.shortDescription}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )
                    }
                    handleClose={toggleLDPopup}
                  />
                )}
              </div>
            ) : (
              <div className="noDatastyle">
                <img
                  src={Nodata}
                  alt="No data"
                  style={{ height: "150px", width: "250px" }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export { LearningVideos };
