import React from "react";
import Comment from "../../../../../Assets/Comment.svg";
import { format } from "date-fns";
import { useEffect } from "react";

const TimeTask = ({ data }) => {
  const inputRef = React.useRef();
  useEffect(() => {
    document.title = `PeopleSol - Time Task`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="timeline-item">
      {data.map((timedata) => (
        <div
          key={timedata.id}
          className="timeline-item-content"
          style={{
            border: "1px solid var(--primary-hover)",
            boxShadow: "0px 3.56872px 17.8436px rgba(0, 0, 0, 0.06)",
          }}
        >
          <span className="circle">
            <img
              style={{ height: "100%", width: "100%" }}
              src={Comment}
              alt="com"
            />
          </span>

          <p style={{ fontWeight: "500" }}>{timedata.comment}</p>
          {`By ${timedata.employee?.nameWithPersonNumber && timedata.employee?.nameWithPersonNumber}`}
          <p
            style={{ fontWeight: "400", fontSize: "12px", textAlign: "end" }}
          ></p>
          <p style={{ fontSize: "12px", fontWeight: "500", alignSelf: "end" }}>
            On {format(new Date(timedata.createdOn), "dd/MM/yyyy hh:mm a")}
          </p>
        </div>
      ))}
    </div>
  );
};

export { TimeTask };
