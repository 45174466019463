import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import DropUp from "../../../../Assets/DropDownUpBlueIcon.svg";
import DropDown from "../../../../Assets/DropDownBlueIcon.svg";
import { getRequest, postRequest } from "../../../../Services/axios";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BoxView } from "../../../../Components";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
const HomePayrollCompany = ({ searchParam }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState({});
  const [bannerListDept, setBannerListDept] = useState({});
  const [bannerListPArea, setBannerListPArea] = useState({});
  const [payData, setPayData] = useState([]);
  const [month, setMonth] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [showPayArea, setShowPayArea] = useState(false);
  const [showDept, setShowDept] = useState(false);
  const [allDept, setAllDept] = useState([]);
  const [allPayArea, setAllPayArea] = useState([]);
  const [currArea, setCurrArea] = useState(null);
  const [currDept, setCurrDept] = useState(null)

  const [selectedRows, setSelectedRows] = useState([]);
  const [tbodyData, setTbodyData] = useState(false);
  const [reqData, setReqData] = useState({
    noticePeriod: null,
    createdOn: null,
    lastUpdatedOn: null,
    status: "Pending",
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });

  useEffect(() => {
    document.title = `PeopleSol - Dashboard `;
    getListData();
    return () => {
      document.title = "PeopleSol";
    };
  }, []);



  const getProfileData = async (payAreaId, deptId) => {
    try {
      let url = "PayrollDashboard/CompanyDashboard/Home/CoBanner";
      if (payAreaId) url = url + "?PayAreaId=" + payAreaId;
      if (deptId) url = url + "?DepartmentId=" + deptId;
      const result = await getRequest(url);
      if (payAreaId) setBannerListPArea(result.data);
      else if (deptId) setBannerListDept(result.data);
      else {
        setBannerList(result.data);
        setBannerListDept(result.data);
        setBannerListPArea(result.data);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const fetchPaySummary = async () => {
    try {
      const response = await getRequest(
        "PayrollDashboard/CompanyDashboard/Home/CPaySummary"
      );
      setMonth(response.data[0]?.monthPayout);
      setPayData(response.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "Error fetching pay summary",
          severity: "error",
        })
      );
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("payroll")) {
      getProfileData();
      fetchPaySummary();
    }
    getAllDepartments();
    getAllPayAreas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllPayAreas = async () => {
    try {
      const result = await getRequest(
        "PayArea/GetpayAreaDropDowns"
      );
      setAllPayArea([...result.data.results])
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  }

  const getAllDepartments = async () => {
    try {
      const result = await getRequest(
        "Department/GetDepartmentss"
      );
      setAllDept([...result.data.results])
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  }

  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getListData = async () => {
    try {
      setLoading(true);
      const result = await postRequest("PreparePayroll/List", reqData);
      if (result) {
        setTbodyData(result.data.data);
        console.log("---------->", result.data.data)
      } else {
        setTbodyData(false);
      }
      setCount(result.data.summary.count);
      calculateTotalPages(result.data.summary.count);
      setSelectedRows([]);
    } catch (error) {
      //dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      setTbodyData(false);
      setCount(0);
    } finally {
      setLoading(false);
    }
  };
  const getFormDetails = (id) => {
    const selectedRecord = tbodyData?.find((item) => item?.Id === id);
    navigate("/hrmsmaster/orgstructure/band/details", {
      state: { selectedRecord },
    });
  };
  const actionData = [
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8437V4.84375Z"
            fill="#0DB53C"
          />
          <line
            x1="14.7295"
            y1="7.43701"
            x2="7.72949"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="7.43701"
            x2="5.61462"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="11.3437"
            x2="7.72949"
            y2="11.3437"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="11.3437"
            x2="5.61462"
            y2="11.3438"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="15.2505"
            x2="7.72949"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="15.2505"
            x2="5.61462"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.110596"
            y="0.587402"
            width="18.4572"
            height="18.4572"
            rx="4"
            fill="#D8F0F2"
          />
          <path
            d="M9.92749 4.5127C9.92749 4.23655 9.70363 4.0127 9.42749 4.0127C9.15135 4.0127 8.92749 4.23655 8.92749 4.5127L9.92749 4.5127ZM8.92749 4.5127L8.92749 12.5518L9.92749 12.5518L9.92749 4.5127L8.92749 4.5127Z"
            fill="#1296B0"
          />
          <path
            d="M5.41748 8.9668L9.42752 12.9768L13.4376 8.9668"
            stroke="#1296B0"
            stroke-linecap="round"
          />
          <path
            d="M5.18164 15.1191H13.4969"
            stroke="#1296B0"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Download",
      clickAction: (id) => id,
    },
  ];
  return (
    <div className="fullTableContainer" style={{ overflow: "auto" }}>
      <div className="headingContainer">
        <h5>Aess solutions</h5>
      </div>
      <div className={`homeDashboardContainer`} style={{ margin: "12px" }}>
        <div
          style={{
            width: "60%",
            display: "flex",
            flexWrap: "wrap",
            gap: "12px",
            paddingLeft: "12px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "calc(50% - 6px)" }}>
            <p>{"Payout till now"}</p>
            <h2 style={{ color: "#B69FB3", fontSize: 46 }}>
              {bannerList?.payoutTillNow}
            </h2>
          </div>

          <div style={{ width: "calc(50% - 6px)" }}>
            <p>No. of Employee</p>
            <h2 style={{ color: "#5BC4BF", fontSize: 46 }}>
              {bannerList?.noOfEmployees}
            </h2>
          </div>
          <div style={{ width: "calc(50% - 6px)" }}>
            <p>{`Projected Future Payout`}</p>
            <h2 style={{ color: "#A0A0CB", fontSize: 46 }}>
              {bannerList?.projectedFuturePayout}
            </h2>
          </div>
          <div style={{ backgroundColor: "rgba(231, 248, 236, 1)", padding: 10, borderRadius: 6, fontSize: 20, color: "rgba(13, 181, 60, 1)", marginTop: 10 }}>
            {bannerList.projectedFuturePercentage + "%"}
          </div>
        </div>
        <div
          style={{
            width: "60%",
            height: "fit-content",
            borderRadius: "6px",
          }}
        >
          <div
            style={{
              padding: "12px",
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
              overflow: "auto",
              height: "calc(100% - 50px)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer tdsSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>TDS</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerList?.tds}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer esicSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>ESIC</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerList.esi}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer insuranceSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>PT</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerList.pt}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer epfSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>EPF</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerList?.epf}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer gratuitySummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>
                  Gratuity
                </p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerList?.gratuity}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer loanSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>Loan</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerList?.loan ?? "-"}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "calc(35% - 24px)",
            margin: "12px",
            height: "calc(100% - 24px)",
          }}
        ></div>
      </div>
      <h5 style={{ margin: "12px 0 0 24px" }}>Monthly Pay Out {month}</h5>
      <BoxView
        payroll={"overH"}
        tbodyData={tbodyData}
        actionData={actionData}
        headingTile="payArea"
        subTiles={["payDate", "amount", "employeesCount", "monthPayout"]}
        date="LastUpdatedOn"
      />
      {/* ///////////////////////////// */}

      <div style={{ marginTop: 30 }}>
        <div onClick={() => { setShowDept(!showDept) }} style={{ borderRadius: 4, borderStyle: "solid", borderWidth: 1, borderColor: "rgba(18, 150, 176, 1)", padding: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", color: "rgba(18, 150, 176, 1)", margin: 15, marginBottom: 0, width: 200 }}>
          <div>{currDept ? currDept.text : "Department"}</div>
          <img style={{ width: 10, height: 10 }} src={showDept ? DropUp : DropDown} />

        </div>
        {showDept && <div style={{ zIndex: 10, borderRadius: 4, backgroundColor: "white", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", width: 200, height: 140, overflow: "auto", margin: 15, marginTop: 0, padding: 10, position: "absolute" }}>
          {allDept.map((dept, index) => (
            <div onClick={() => { setShowDept(false); setCurrDept(dept); getProfileData(null, dept.id) }} key={index} style={{ marginBottom: 10, cursor: "pointer" }}>{dept.text}</div>
          ))}
        </div>}
      </div>
      <div className={`homeDashboardContainer`} style={{ margin: "12px" }}>
        <div
          style={{
            width: "60%",
            display: "flex",
            flexWrap: "wrap",
            gap: "12px",
            paddingLeft: "12px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "calc(50% - 6px)" }}>
            <p>{"Payout till now"}</p>
            <h2 style={{ color: "#B69FB3", fontSize: 46 }}>
              {bannerListDept?.payoutTillNow}
            </h2>
          </div>

          <div style={{ width: "calc(50% - 6px)" }}>
            <p>No. of Employee</p>
            <h2 style={{ color: "#5BC4BF", fontSize: 46 }}>
              {bannerListDept?.noOfEmployees}
            </h2>
          </div>
          <div style={{ width: "calc(50% - 6px)" }}>
            <p>{`Projected Future Payout`}</p>
            <h2 style={{ color: "#A0A0CB", fontSize: 46 }}>
              {bannerListDept?.projectedFuturePayout}
            </h2>
          </div>
          <div style={{ backgroundColor: "rgba(231, 248, 236, 1)", padding: 10, borderRadius: 6, fontSize: 20, color: "rgba(13, 181, 60, 1)", marginTop: 10 }}>
            {(bannerListDept.projectedFuturePercentage ? bannerListDept.projectedFuturePercentage : "0") + "%"}
          </div>
        </div>
        <div
          style={{
            width: "60%",
            height: "fit-content",
            borderRadius: "6px",
          }}
        >
          <div
            style={{
              padding: "12px",
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
              overflow: "auto",
              height: "calc(100% - 50px)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer tdsSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>TDS</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListDept?.tds}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer esicSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>ESIC</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListDept.esi}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer insuranceSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>PT</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListDept.pt}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer epfSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>EPF</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListDept?.epf}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer gratuitySummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>
                  Gratuity
                </p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListDept?.gratuity}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer loanSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>Loan</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListDept?.loan ?? "-"}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ///////////////// */}

      <div style={{ marginTop: 30 }}>
        <div onClick={() => { setShowPayArea(!showPayArea) }} style={{ borderRadius: 4, borderStyle: "solid", borderWidth: 1, borderColor: "rgba(18, 150, 176, 1)", padding: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", color: "rgba(18, 150, 176, 1)", margin: 15, marginBottom: 0, width: 200 }}>
          <div>{currArea ? currArea.text : "Pay Area"}</div>
          <img style={{ width: 10, height: 10 }} src={showPayArea ? DropUp : DropDown} />
        </div>
        {showPayArea && <div style={{ zIndex: 10, borderRadius: 4, backgroundColor: "white", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", width: 200, height: 140, overflow: "auto", margin: 15, marginTop: 0, padding: 10, position: "absolute" }}>
          {allPayArea.map((area, index) => (
            <div onClick={() => { setCurrArea(area); setShowPayArea(false); getProfileData(area.id) }} key={index} style={{ marginBottom: 10, cursor: "pointer" }}>{area.text}</div>
          ))}
        </div>}
      </div>
      {/* ///////////////////////////// */}
      <div className={`homeDashboardContainer`} style={{ margin: "12px" }}>
        <div
          style={{
            width: "60%",
            display: "flex",
            flexWrap: "wrap",
            gap: "12px",
            paddingLeft: "12px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "calc(50% - 6px)" }}>
            <p>{"Payout till now"}</p>
            <h2 style={{ color: "#B69FB3", fontSize: 46 }}>
              {bannerListPArea?.payoutTillNow}
            </h2>
          </div>

          <div style={{ width: "calc(50% - 6px)" }}>
            <p>No. of Employee</p>
            <h2 style={{ color: "#5BC4BF", fontSize: 46 }}>
              {bannerListPArea?.noOfEmployees}
            </h2>
          </div>
          <div style={{ width: "calc(50% - 6px)" }}>
            <p>{`Projected Future Payout`}</p>
            <h2 style={{ color: "#A0A0CB", fontSize: 46 }}>
              {bannerListPArea?.projectedFuturePayout}
            </h2>
          </div>
          <div style={{ backgroundColor: "rgba(231, 248, 236, 1)", padding: 10, borderRadius: 6, fontSize: 20, color: "rgba(13, 181, 60, 1)", marginTop: 10 }}>
            {(bannerListPArea.projectedFuturePercentage ? bannerListPArea.projectedFuturePercentage : "0") + "%"}
          </div>
        </div>
        <div
          style={{
            width: "60%",
            height: "fit-content",
            borderRadius: "6px",
          }}
        >
          <div
            style={{
              padding: "12px",
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
              overflow: "auto",
              height: "calc(100% - 50px)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer tdsSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>TDS</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListPArea?.tds}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer esicSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>ESIC</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListPArea.esi}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer insuranceSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>PT</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListPArea.pt}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer epfSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>EPF</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListPArea?.epf}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer gratuitySummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>
                  Gratuity
                </p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListPArea?.gratuity}
                </h5>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10 }} className="payrollSummaryContainer loanSummary">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px", fontSize: 12 }}>Loan</p>
                <h5 style={{ color: "var(--primary-text)", fontSize: 17 }}>
                  {bannerListPArea?.loan ?? "-"}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ///////////////// */}
      {/* //////////////////////////// */}
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "calc(35% - 24px)",
            margin: "12px",
            height: "calc(100% - 24px)",
          }}
        ></div>
      </div>
      <h5 style={{ margin: "12px 0 0 24px" }}>Monthly Pay Out {month}</h5>
      <BoxView
        payroll={"overH"}
        tbodyData={currArea ? tbodyData.filter((item) => item.payAreaId == currArea.id) : tbodyData}
        actionData={actionData}
        headingTile="payArea"
        subTiles={["payDate", "amount", "employeesCount", "monthPayout"]}
        date="LastUpdatedOn"
      />
    </div>
  );
};
export { HomePayrollCompany };
