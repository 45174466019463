import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../Features/badgeUtils";
import { format } from "date-fns";

const DetailsPageMeetingRoom = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) {
      navigate(state?.returnUrl);
    } else {
      navigate(-1);
    }
  };
  const [selId, setSelId] = useState("");

  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (id, type, color) => {
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Cancel" || type === "Release") {
        await postRequest(
          `MeetingRoomBookings/Cancel?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Approve") {
        await postRequest(
          `MeetingRoomBookings/MRMApprove?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Reject") {
        await postRequest(
          `MeetingRoomBookings/MRMReject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "approve") {
        await postRequest(
          `MeetingRoomBookings/MRMApprove?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "reject") {
        await postRequest(
          `MeetingRoomBookings/MRMReject?Id=${id}&Remarks=${Remark}`
        );
      } else {
        await getRequest(
          `MeetingRoomBookings/${type}?Id=${id}&Remarks=${Remark}`
        );
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `MeetingRoomBookings/Details?Id=${getIdFromDynamicUrl(currentUrl)}`
      );
      // console.log("MeetingRoomBookings/Details", result);
      setData(result.data.data);
      if (approve) {
        if (LoggedInUser?.id === value2 && result.data?.data?.actions.length) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.data?.actions[0]?.id,
              approve,
              result.data?.data?.actions[0]?.color
            );
          } else
            PostApRejHol(approve, result.data?.data?.actions[0]?.id, "Remark");
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Meeting Room Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // const PostApRejHol = async (type, id, Remark) => {
  //   try {
  //     dispatch(isLoading(true));
  //     setLoading(true);
  //     if (type === "Approve" || type === "Cancel" || type === "Release") {
  //       await postRequest(`GatePassRequestsController/${type}?Id=${id}`);
  //     } else if (type === "Mark as Closed") {
  //       await postRequest(
  //         `GatePassRequestsController/Close?Id=${id}&Remarks=${Remark}`
  //       );
  //     } else {
  //       await postRequest(
  //         `GatePassRequestsController/${type}?Id=${id}&Remarks=${Remark}`
  //       );
  //     }
  //     dispatch(showToast({ text: "Success", severity: "success" }));
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: error?.response?.data?.title ?? "Error in fetching Data",
  //         severity: "error",
  //       })
  //     );
  //   } finally {
  //     dispatch(isLoading(false));
  //     setLoading(false);
  //     setIsOpen(false);
  //     getDetails();
  //   }
  // };

  useEffect(() => {
    getDetails();
  }, [LoggedInUser.id]);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  // console.log("data", data);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>

      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Meeting Room Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Details
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Timeline
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Notifications
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {data && (
              <>
                {tabIndex === 0 &&
                  (data != null ? (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm">Request Details</div>
                      <div className="detailInner">
                        <>
                          <Details
                            detailTitle={"Request Number"}
                            Details={data?._meetingRoomsBookings?.requestNumber}
                          />
                          <Details
                            detailTitle={"Date"}
                            Details={
                              data?._meetingRoomsBookings?.date
                                ? format(
                                  new Date(data?._meetingRoomsBookings?.date),
                                  "dd/MM/yyyy"
                                )
                                : ""
                            }
                          />
                          <Details
                            detailTitle={"Meeting Title"}
                            Details={data?._meetingRoomsBookings?.title}
                          />

                          {/* <Details
                            detailTitle={"End Date"}
                            Details={
                              data?._meetingRoomsBookings?.endDate
                                ? format(
                                    new Date(
                                      data?._meetingRoomsBookings?.endDate
                                    ),
                                    "dd/MM/yyyy"
                                  )
                                : ""
                            }
                          /> */}
                          <Details
                            detailTitle={"Start Time"}
                            Details={
                              data?._meetingRoomsBookings?.startTime
                                ? format(
                                  new Date(
                                    data?._meetingRoomsBookings?.startTime
                                  ),
                                  "hh:mm a"
                                )
                                : ""
                            }
                          />
                          <Details
                            detailTitle={"Room"}
                            Details={data?._meetingRoomsBookings?.room?.title}
                          />
                          <Details
                            detailTitle={"End Time"}
                            Details={
                              data?._meetingRoomsBookings?.endTime
                                ? format(
                                  new Date(
                                    data?._meetingRoomsBookings?.endTime
                                  ),
                                  "hh:mm a"
                                )
                                : ""
                            }
                          />
                          <Details
                            detailTitle={"Additional Requests"}
                            Details={
                              data?._meetingRoomsBookings?.additionalRequests
                            }
                          />
                          <Details
                            detailTitle={"Number of Persons"}
                            Details={
                              data?._meetingRoomsBookings?.numberofPersons
                            }
                          />

                          {/* <Details
                          detailTitle={"Duration"}
                          Details={data?._meetingRoomsBookings?.endTime?.replace("T", " ")}
                        /> */}
                          <Details
                            detailTitle={"Created On"}
                            Details={data?._meetingRoomsBookings?.createdOn?.replace(
                              "T",
                              " "
                            )}
                          />
                          <Details
                            detailTitle={"Status"}
                            Details={
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    data?._statusLabel
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    data?._statusLabel
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                {data?._statusLabel}
                              </div>
                            }
                          />

                          <Details
                            detailTitle={"Last Updated On"}
                            Details={data?._meetingRoomsBookings?.lastUpdatedOn?.replace(
                              "T",
                              " "
                            )}
                          />
                        </>
                      </div>
                      <div className="headingForm"> Requester's Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={`${data?._meetingRoomsBookings?.employee?.name} ${data?._meetingRoomsBookings?.employee?.lastName}`}
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={
                            data?._meetingRoomsBookings?.employee?.rO_Name
                          }
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={
                            data?._meetingRoomsBookings?.employee?.designation
                          }
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={
                            data?._meetingRoomsBookings?.employee?.department
                          }
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?._meetingRoomsBookings?.employee?.email}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={
                            data?._meetingRoomsBookings?.employee
                              ?.personalPhoneNumber
                          }
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={
                            data?._meetingRoomsBookings?.employee?.locationName
                          }
                        />
                      </div>
                      {data?.actions?.length ? (
                        <div
                          className="createFootDetails"
                          style={{
                            background: "white !important",
                            borderTop: "1px solid rgba(230, 230, 230, 1)"
                          }}
                        >
                          {data?.actions?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                    item.type !== "Release" &&
                                    item.type !== "Cancel"
                                    ? togglePopup(
                                      item?.id,
                                      item?.type,
                                      item?.color
                                    )
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {isOpen && (
                        <Popup
                          popupHeading={typeAction}
                          content={
                            <>
                              <Remark
                                data={PostApRejHol}
                                type={typeAction}
                                id={selId}
                                color={colorAction}
                                handleClose={togglePopup}
                              />
                            </>
                          }
                          handleClose={togglePopup}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="noDatastyle">
                      <Loader />
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Data Found"}</h4>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <Timeline
                          id={getIdFromDynamicUrl(currentUrl)}
                          // module={"Meeting Room Booking"}
                          module={
                            data?._meetingRoomsBookings?.visitId
                              ? "Meeting Room Booking For Visit"
                              : "Meeting Room Booking"
                          }
                          requestNumber={
                            data?._meetingRoomsBookings?.requestNumber
                          }
                        />
                        {/* <Comments id={getIdFromDynamicUrl(currentUrl)} module={data?.data?.module} requestNumber={
                          data?.data?.manpowerIndent?.requestNumber
                        } /> */}
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={getIdFromDynamicUrl(currentUrl)}
                        module={
                          data?._meetingRoomsBookings?.visitId
                            ? "Meeting Room Booking For Visit"
                            : "Meeting Room Booking"
                        }
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsPageMeetingRoom };
