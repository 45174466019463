import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../../Features";
import { postRequest } from "../../../../../Services/axios";
import SocialNoticeBoardHeaderIcon from "../../../../../Assets/SocialNoticeBoardHeaderIcon.svg";
import "./Greetings.css";
import "./Connect.css";
import SocialNoticeBoardTag from "../../../../../Assets/SocialNoticeBoardTag.svg";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostNotFoundIcon from "../../../../../Assets/SocialPostNotFoundIcon.svg";
import SocialPostViewsEyeColorIcon from "../../../../../Assets/SocialPostViewsEyeColorIcon.svg";
import HeaderComponent from "./HeaderComponent";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { IconButton } from "@mui/material";
import dateFormat from "dateformat";
import { bannerPostImages } from "../../../../../Utilities/profileAndUploadImages";
import {
  getFormattedCount,
  isImage,
} from "../../../../../Utilities/socialAllFunctions";
import ShowViewsList from "./ShowViewsList";
import NoticeBoardPostDetails from "./NoticeBoardPostDetails";
const NoticeBoard = ({ unreadPostCount, setReRefreshProp, reRefresh }) => {
  const [viewsPostId, setViewsPostId] = useState(0);
  const [viewsListActive, setViewsListActive] = useState(false);
  const [viewsHovered, setIsViewsHovered] = useState(false);
  const [noticeboardData, setNoticeboardData] = useState([]);
  const dispatch = useDispatch();
  const [dataFound, setDataFound] = useState(true);
  const [loading, setLoading] = useState(false);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && noticeboardData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, noticeboardData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || noticeboardData?.length < postDataCount)
    ) {
      if (!reRefresh) {
        getData();
      }
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset]);

  useEffect(() => {
    setOffset(0);
  }, []);

  const getData = async (freshLoad = false) => {
    setLoading(true);
    try {
      const result = await postRequest(`Social/NoticeBoardList`, {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength:
            postDataCount === -1 || offset + 2 <= postDataCount || freshLoad
              ? 2
              : postDataCount - offset,
          iDisplayStart: freshLoad ? 0 : offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        setNoticeboardData([]);
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });

        setPostDataCount(result?.data?.summary?.allCount);
        setTotalOffset(offset);
        if (freshLoad) {
          document.querySelector(
            ".homeEngageMainCenterContainer"
          ).scrollTop = 0;
          setNoticeboardData(listData);
          setOffset(0);
          setTotalOffset(0);
        } else {
          setNoticeboardData([...noticeboardData, ...listData]);
        }

        setCurrentIndex((currentIndex) => currentIndex + 2);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      setReRefreshProp();
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    document.title = `PeopleSol - NoticeBoard`;
    return () => {
      document.title = "PeopleSol";
    };
  }, [location.pathname]);
  useEffect(() => {
    if (reRefresh === true) {
      getData(true);
    }
  }, [reRefresh]);
  return (
    <>
      <div className="engageMainContentContainer" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <div className="greetingEngageHeaderContainer">
          <HeaderComponent
            icon={SocialNoticeBoardHeaderIcon}
            title={"Notice Board"}
          />
        </div>
        <div className="homeEngageMainCenterContainer">
          <div className="homeEngagePostContainer">
            {noticeboardData.map((d, i) => {
              return (
                <div
                  className="socialAllPostContainer"
                  key={"social-item-" + i}
                >
                  <div className="engagePostContentHolder">
                    <div style={{ borderRadius: "8px" }}>
                      <div className="NoticeBoardBodyContainer">
                        <div className="NoticeBoardTitleContainer">
                          <div
                            className="postMainTextContainer"
                            style={{ marginLeft: "0" }}
                          >
                            <h5>
                              {d?.title?.length > 30
                                ? d?.title?.substring(0, 30) + "..."
                                : d?.title}
                            </h5>

                            <div className="noticeViewsContainer">
                              <label className="socialDateAndTime">
                                {dateFormat(
                                  d?.createdOn,
                                  "hh:MM TT | dd mmm yyyy"
                                )}
                              </label>
                              <article
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setViewsListActive(true);
                                  setViewsPostId(d?.id);
                                }}
                                onMouseOver={() => setIsViewsHovered(d?.id)}
                                onMouseLeave={() => setIsViewsHovered(null)}
                              >
                                <img
                                  src={
                                    viewsHovered === d?.id
                                      ? SocialPostViewsEyeColorIcon
                                      : SocialPostViewsEyeIcon
                                  }
                                  alt=""
                                  className="socialViewsImgContainer"
                                />
                                <span
                                  className={
                                    viewsHovered === d?.id
                                      ? "postColorViews"
                                      : `socialViewsSpanContainer`
                                  }
                                >
                                  {d?.views < 10
                                    ? "0" + d?.views
                                    : getFormattedCount(d?.views)}{" "}
                                  Views
                                </span>
                              </article>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="noticeBoardTypeContainer">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>Notice Board</span>
                                <article></article>
                                <img src={SocialNoticeBoardTag} alt="" />
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                {d?.module}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            navigate("details/" + d?.id);
                          }}
                        >
                          <div className="postBodyTextContainer">
                            <p>{d?.description}</p>
                          </div>
                          <div className="NoticeBoardImageContainer">
                            {isImage(d?.image) && (
                              <img
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  bannerPostImages +
                                  d?.image
                                }
                                alt="Profile"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="NoticeBoardFooterContainer">
                        <p>{d?.footerText}</p>
                        {d?.buttonText && (
                          <div className="showCommunityRightHeaderContainer">
                            <IconButton>
                              <span>
                                <a
                                  href={d?.redirectUrl}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {d?.buttonText?.length > 10
                                    ? d?.buttonText?.substring(0, 10) + "..."
                                    : d?.buttonText}
                                </a>
                              </span>
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {!dataFound && noticeboardData?.length <= 0 && (
              <div className="noPostfoundContainer">
                <div className="noPostfoundWrapper">
                  <img src={SocialPostNotFoundIcon} alt="" />
                  <h5>No Post Found</h5>
                  <p>No one has posted something in social</p>
                </div>
              </div>
            )}
          </div>
          {loading && <Loader />}
        </div>
      </div>
      {viewsListActive && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"Notice"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
      <Routes>
        <Route path="details/:postid" element={<NoticeBoardPostDetails />} />
      </Routes>
    </>
  );
};
export default NoticeBoard;
