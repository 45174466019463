import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { RadioFormik } from "../../../../../Components/FormComponent/RadioFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { useLocation } from "react-router";
import { Details } from "../../../../HRMS/Components/DetailsComp/Details";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { useEffect } from "react";
const option = [
  { label: "Approved", value: "Approved" },
  { label: "Approved with Conditions", value: "Approved with Conditions" },
  { label: "Rejected", value: "Rejected" },
  { label: "More Info", value: "More Info" },
];

const ITdirectorApproval = ({ data, getDetails }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selId, setSelId] = useState(0);
  const validationSchema = Yup.object({
    DirectorDecision: Yup.string().required("Please Select Decision"),
    DecisionExplanation: Yup.string()

      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Remarks"),
  });

  const onSubmit = (values, status) => {
    let app
    if(status==="Approved"){
        app={
        ...values,
        DirectorDecision:"Approved",
        Id: data?.itsmChangeRequest?.id,
        Status: data?.itsmChangeRequest?.status,
        ChangeType: data?.itsmChangeRequest?.changeType,
        DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
        Reason: data?.itsmChangeRequest?.reason,
        Priority: data?.itsmChangeRequest?.priority,
      }
    }
      else if(status==="Rejected"){
      app={
      ...values,
      DirectorDecision:"Rejected",
      Id: data?.itsmChangeRequest?.id,
      Status: data?.itsmChangeRequest?.status,
      ChangeType: data?.itsmChangeRequest?.changeType,
      DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
      Reason: data?.itsmChangeRequest?.reason,
      Priority: data?.itsmChangeRequest?.priority,
    }
  }
  else if(status==="More Info"){
    app={
      ...values,
      DirectorDecision:"More Info",
      Id: data?.itsmChangeRequest?.id,
      Status: data?.itsmChangeRequest?.status,
      ChangeType: data?.itsmChangeRequest?.changeType,
      DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
      Reason: data?.itsmChangeRequest?.reason,
      Priority: data?.itsmChangeRequest?.priority,
    }
  }
    const hold = {
      Id: data?.itsmChangeRequest?.id,
      Status: status,
      ChangeType: data?.itsmChangeRequest?.changeType,
      DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
      Reason: data?.itsmChangeRequest?.reason,
      Priority: data?.itsmChangeRequest?.priority,
    };
    const release = {
      Id: data?.itsmChangeRequest?.id,
      Status: "Under scheduling",
      ChangeType: data?.itsmChangeRequest?.changeType,
      DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
      Reason: data?.itsmChangeRequest?.reason,
      Priority: data?.itsmChangeRequest?.priority,
    };
    const create = {
      ...values,
      Id: data?.itsmChangeRequest?.id,
      Status: data?.itsmChangeRequest?.status,
      ChangeType: data?.itsmChangeRequest?.changeType,
      DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
      Reason: data?.itsmChangeRequest?.reason,
      Priority: data?.itsmChangeRequest?.priority,
    };
    if (status === "On-Hold") createPage(hold);
    else if (status === "release") createPage(release);
    else createPage(app);
  };
  const createPage = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "ITsmChangeRequest/DirectorApprovals",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(showToast({ text: "error", severity: "error" }));
    } finally {
      getDetails();
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - IT Director Approval`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={{
          DirectorDecision: "",
          DecisionExplanation: "",
          Conditions: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, resetForm, errors }) => {
          return (
            <div style={{ padding: "12px" ,backgroundColor:'var(--form-background)'}}>
              <Form>
                <div>
                  <div>
                    <LabelCustom labelName={"Notes"} />
                    <TextAreaFormik
                      style={{ height: "80px" }}
                      name="DecisionExplanation"
                      placeholder="Please Enter Explanation"
                      maxLength={500}
                    />
                  </div>
                </div>
                <div className="createFootCust">
                  {data?.itsmChangeRequest?.status === "On-Hold" ? (
                    <button
                      className="button secondaryButton"
                      style={{ background: "var(--secondary)", color: "white" }}
                      type="button"
                      onClick={() => onSubmit(values, "release")}
                    >
                      Release
                    </button>
                  ) : (
                    <div>
                      <button
                        className="deletebtn"
                        type="button"
                        onClick={() =>
                          onSubmit( values, "Rejected" )
                        }
                      >
                        Reject
                      </button>
                      <button
                        className="button primaryButton"
                        type="button"
                        onClick={() =>
                          onSubmit( values, "Approved" )
                        }
                      >
                        Approve
                      </button>
                      <button
                        className="button secondaryButton"
                        style={{
                          background: "var(--secondary)",
                          color: "white",
                        }}
                        type="button"
                        onClick={() => onSubmit(values, "On-Hold")}
                      >
                        Hold
                      </button>
                      <button
                        className="button secondaryButton"
                        style={{
                          background: "var(--secondary)",
                          color: "white",
                        }}
                        type="button"
                        onClick={() =>
                          onSubmit( values, "More Info" )
                        }
                      >
                        Need More Info
                      </button>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
};

export { ITdirectorApproval };
