import React, { useEffect, useRef, useState, useCallback } from "react";
import SendImageChat from "../../../../Assets/SendImageChat.svg";
import AddImage from "../../../../Assets/AddDocumentIcon.svg"
import CloseIcon from "../../../../Assets/CloseIcon.svg"
import { Loader } from "../../../../Components/FormComponent/Loader";
import NoPreview from "../../../../Assets/NoPreviewDoc.svg";
import VideoPlay from "../../../../Assets/VideoPlay.svg"
import { useSelector } from "react-redux";
import Delete from "../../../../Assets/DeleteIconWhite.svg"
const DocumentPreviewContainer = ({ close, newSocket, handleInput, updateStories, selectedFiles, setSelectedDocs, selectedFileType, activeChat, getAllSelfMessages, refresh }) => {
  const [selectedFile, setSelectedFile] = useState(selectedFiles.length ? selectedFiles[0] : {})
  const [localLoader, setLocalLoader] = useState(false);
  const [caption, setCaption] = useState("");
  const [currentHover, setCurrHover] = useState(null);
  const userDetails = useSelector(state => state.chat.userDetails);
  const docTypes = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const imageTypes = "image/jpg,image/jpeg,image/png,image/svg, video/mp4 , video/ogv ,video/webm";
  let timeOut;

  useEffect(() => {
    if (selectedFiles.length == 0)
      close()
  }, [selectedFiles])
  const sendMediaMessage = async (file, captionInput, type) => {

    if (activeChat.personal) {
      newSocket.emit("addSelfMessage", ({ "senderId": userDetails._id, "personalNumber": userDetails.personalNumber, "type": type, "caption": captionInput, "content": [file] }), (res) => {
        console.log("------ res from self message addition ", res);
        getAllSelfMessages();
        refresh(newSocket)
      })
    } else {
      newSocket.emit(activeChat.type === "Broadcast" ? "broadcastMessage" : "addMessage", (
        {
          "conversationId": activeChat._id,
          "senderId": userDetails._id,
          "personalNumber": userDetails.personalNumber,
          "type": type,
          "caption": captionInput,
          "content": [file],
        }), (res) => { refresh(newSocket); console.log("-------- added newMessage and rrefres", res) })
    }

  };


  const handleStoryUpload = async () => {
    setLocalLoader(true);
    for (const item of selectedFiles) {
      const formData = new FormData();
      formData.append("files", item);
      // Perform the API request
      const response = await fetch(
        "https://media.solunxt.com/api/v1/Storage/Save?token=5b4cbd69-2366-4708-871c-5300a4dc6f18",
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await response.json();
      if (responseData.succeeded) {
        //Media upload for audio: 1 / video: 2 / image: 3 / document: 7 / link: 8 / contact: 10 / zip: 11 / gif: 13
        sendMediaMessage(responseData.data[0], caption, isDoc(item.type) ? 7 : item.type.startsWith('video/') ? 2 : 3);
      }
    }
    close()
    setLocalLoader(false);
  }

  const isDoc = (fileType) => {
    if (
      fileType === 'application/pdf' || // PDF
      fileType === 'application/msword' ||// Word Document (.doc)
      fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || // Word Document (.docx)
      fileType === 'application/vnd.ms-excel' || // Excel (.xls)
      fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // Excel (.xlsx)
    ) return true;
    else return false
  }

  const handleUploadStory = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedDocs([...selectedFiles, file])
    }
  }



  return (
    <div style={{
      marginTop: 10,
      right: 0,
      width: "73%", height: "95%", position: "absolute", zIndex: 100
    }}>
      <input
        type="file"
        accept={selectedFileType == "document" ? docTypes : imageTypes}
        id="fileChatInput"
        style={{ display: "none" }}
        onChange={handleUploadStory}
      />
      {console.log("--------> kya hai selected files", selectedFiles)}
      {localLoader && (
        <div className="document-preview-container-uploading">
          <div
            style={{
              color: "rgb(51, 51, 51)",
              padding: "20px",
              borderRadius: "5px",
              fontSize: "17px",
              textAlign: "center",
              width: "500px",
              backgroundColor: "white",
            }}
          >
            <p>Sending your media...</p>
            <div style={{ marginTop: "0px" }}>
              {" "}
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div style={{ width: "100%", height: "90%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", backgroundColor: "rgba(239, 249, 250, 1)", }}>
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", height: "87%" }}>
          <div style={{ padding: 10, color: "white", backgroundColor: "rgba(18, 150, 176, 1)", width: "100%", marginBottom: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            {selectedFile.name}
            <div onClick={() => { close() }} >
              <img src={CloseIcon} />
            </div>
          </div>
          {isDoc(selectedFile.type) ?
            <div>
              <img src={NoPreview} />
            </div>
            :
            selectedFile.type.startsWith('video/') ?
              <div>
                <video
                  src={URL.createObjectURL(selectedFile)}
                  controls
                  width="500"
                // autoPlay
                />
              </div>
              :
              <img src={URL.createObjectURL(selectedFile)} style={{ height: "55%", width: "60%", marginTop: 30 }} />
          }
          <input onChange={(e) => {
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
              setCaption(e.target.value)
            }, 1000)
          }} type="text" placeholder="Type a message" style={{ borderStyle: "none", outline: "none", backgroundColor: "white", padding: 10, marginTop: 50, width: "50%", borderRadius: 4 }} />

        </div>
        <div style={{ backgroundColor: "white", width: "100%", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, height: "13%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "50%", overflowX: "auto", scrollbarWidth: .5, justifyContent: "center", alignItems: "center", marginTop: 20 }}>
            {selectedFiles.map((file) => (
              <div onPointerEnter={() => { setCurrHover(file) }} onPointerLeave={() => { setCurrHover(null) }} onClick={() => { setSelectedFile(file) }}>
                {currentHover == file ? <div style={{ position: "absolute", width: 55 }}>
                  <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <img onClick={() => { setSelectedDocs([...selectedFiles.filter(item => item != currentHover)]) }} width={20} height={20} alt={"delete"} src={Delete} />
                  </div>
                </div> : null}
                <img src={isDoc(file.type) ? NoPreview : file.type.startsWith('video/') ? VideoPlay : URL.createObjectURL(file)} style={{ height: 60, width: 55, marginRight: 20, marginRight: 20, backgroundColor: currentHover === file ? "rgba(0, 0, 0, .3)" : "transparent", borderStyle: "solid", borderRadius: 4, borderWidth: selectedFile === file ? 2 : 0, borderColor: "rgba(18, 150, 176, 1)" }} />
              </div>
            ))}
            <div onClick={() => { document.getElementById("fileChatInput").click() }} style={{ height: 60, width: 55, marginRight: 20, backgroundColor: "rgba(216, 240, 242, 1)", display: "flex", justifyContent: "center", alignItems: "center" }} >
              <img src={AddImage} />
            </div>
          </div>
          <div onClick={() => { handleStoryUpload() }} style={{ position: "absolute", right: 50, bottom: 65 }}>
            <img style={{ height: 44, width: 44 }} src={SendImageChat} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default DocumentPreviewContainer;
