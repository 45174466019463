import React from "react";

const Certificatesignature = ({
  firstSignatory,
  firstSignatoryDesignation,
  firstSign,
  secondSignatory,
  secondSignatoryDesignation,
  secondSign,
  thirdSignatory,
  thirdSignatoryDesignation,
  thirdSign,
  editstyle,
}) => {
  return (
    <div className="formcustomthree " style={{ ...editstyle }}>
      <div className="sign-box">
        <span>{firstSign}</span>
        <h5>{firstSignatory}</h5>
        <p>{firstSignatoryDesignation}</p>
      </div>
      <div className="sign-box">
        <span>{secondSign}</span>
        <h5>{secondSignatory}</h5>
        <p>{secondSignatoryDesignation}</p>
      </div>
      <div className="sign-box">
        <span>{thirdSign}</span>
        <h5>{thirdSignatory}</h5>
        <p>{thirdSignatoryDesignation}</p>
      </div>
    </div>
  );
};

export default Certificatesignature;
