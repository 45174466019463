import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CreateChapterComp } from "./FormComp/CreateChapterComp";
import { confirmAlert } from "react-confirm-alert";

const CreateChapter = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [changeButton, setchangeButton] = useState(false);
  const [videoFileData, setVideoFileData] = useState(false);
  const [messaged, setMessage] = useState(false);

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`TrainingCourseChapters/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleVideoError = () => {
    // if (e) {
    confirmAlert({
      customUI: () => {
        return (
          <div className="videowarningPopup">
            <div className="videowarning">Warning!!!</div>
            <div className="videowarningcontent">
              Invalid URL. Please verify it or check for Ad Blockers or Faulty
              extensions.
            </div>
          </div>
        );
      },
    });
    // }
  };
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "TrainingCourseChapters/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage1();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  function areAllValuesUnique(array) {
    const set = new Set(array);
    return set.size !== array.length;
  }
  function areLeaathanduration(array, duration) {
    const booleanArray = array?.map((item) =>
      item <= Number(duration) ? true : false
    );
    return booleanArray?.includes(false);
  }
  function differenceMorethanfifteen(array) {
    const booleanArray = array?.map((item, index) =>
      index < array.length - 1
        ? item + 15 <= array[index + 1]
          ? true
          : false
        : true
    );
    return booleanArray?.includes(false);
  }
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "TrainingCourseChapters/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage1();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Chapter`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getInitalAnswerValues = () => {
    let returnArray = [];
    data
      ? detailsform?.questions?.map((item) => {
          return returnArray.push({
            questionSetId: item.questionSetId,
            triggerPositionSecond: item.triggerPositionSecond,
          });
        })
      : (returnArray = [{ questionSetId: "", triggerPositionSecond: "" }]);

    return returnArray;
  };
  const initialvalue = {
    courseId: "",
    title: "",
    videoUrl: "",
    SelectVideo: "",
    duration: "",
    description: "",
    passPercentage: null,
    questions: getInitalAnswerValues(),
    upload: "",
    isActive: "Yes",
  };
  const isVideoSelected = () => {
    if (!formValues?.SelectVideo) {
      setMessage(true);
      return true;
    } else if (formValues?.SelectVideo === "url" && !formValues?.videoUrl) {
      setMessage(true);
      return true;
    } else if (formValues?.SelectVideo === "video" && !formValues?.files) {
      setMessage(true);
      return true;
    } else {
      setMessage(false);
      return false;
    }
  };
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const validationSchema = data
    ? Yup.object({
        title: Yup.string()
          .max(49, "Title must be less than 50 characters")
          .required("Please Enter  Title"),
        courseId: Yup.string().required("Please Select  Course"),

        // videoUrl: Yup.string()
        //   // .max(149, "Url must be less than 150 characters")
        //   // .matches(
        //   //   /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        //   //   "Enter a valid video URL with http or https"
        //   // )
        //   .required("Please Enter Video URL or Select Video File"),
        duration: Yup.string()
          // .max(4, "Duration must be less than a 9999 seconds")
          .required("Please Enter Duration"),

        passPercentage: Yup.number()
          .max(100)
          .required("Please Enter Pass Percentage"),

        description: Yup.string()
          .max(500, "Description must be less than 500 characters")
          .required("Please Enter Description"),
        questions: Yup.array().of(
          Yup.object().shape({
            questionSetId: Yup.string().required("Please Select Question Set"),
            triggerPositionSecond: Yup.string().required(
              "Please Enter Trigger Position Second"
            ),
          })
        ),
      })
    : Yup.object({
        title: Yup.string()
          .max(49, "Title must be less than 50 characters")
          .required("Please Enter Title"),
        courseId: Yup.string().required("Please Select Course"),

        // videoUrl: Yup.string()
        //   // .max(149, "Url must be less than 150 characters")
        //   // .matches(
        //   //   /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        //   //   "Enter a valid video URL with http or https"
        //   // )
        //   .required("Please Enter Video URL or Select Video File"),
        // duration: Yup.number().required("Please Enter Duration"),

        duration: Yup.number().required("Please Enter Duration"),
        passPercentage: Yup.number()
          .max(100)
          .required("Please Enter Pass Percentage"),
        upload: Yup.mixed()
          .nullable()
          .test(
            "Fichier taille",
            "File Size should be less than 2 mb",
            (value) => !value || (value && value.size <= 2 * 2048 * 2048)
          )
          .test(
            "format",
            "File Format is Incorrect",
            (value) =>
              !value || (value && SUPPORTED_FORMATS.includes(value.type))
          )
          .required("Please Upload Cover Image"),
        description: Yup.string()
          .max(499, "Description must be less than 500 characters")
          .required("Please Enter Description"),
        questions: Yup.array().of(
          Yup.object().shape({
            questionSetId: Yup.string().required("Please Select Question Set"),
            triggerPositionSecond: Yup.string().required(
              "Please Enter Trigger Position Second"
            ),
          })
        ),
      });

  const onSubmit = (values) => {
    const triggerPositions = values?.questions.map(
      (question) => question.triggerPositionSecond
    );
    const sortedTriggeredPosition = triggerPositions?.sort((a, b) => a - b);
    if (isVideoSelected()) return;
    else if (
      !values?.videoUrl?.includes("https://www.youtube.com/") &&
      !values?.videoUrl?.includes(
        `${process.env.REACT_APP_.MEDIA_BASE_URL}/`
      ) &&
      values?.videoUrl?.length > 0
    ) {
      handleVideoError();
    }
    // console.log("first --- triggerPositions", triggerPositions);
    else if (areAllValuesUnique(sortedTriggeredPosition)) {
      dispatch(
        showToast({
          text: "Each Trigger Position Seconds should be unique",
          severity: "error",
        })
      );
    } else if (areLeaathanduration(sortedTriggeredPosition, values?.duration)) {
      dispatch(
        showToast({
          text: "Trigger Position Seconds should be less than duration",
          severity: "error",
        })
      );
    } else if (differenceMorethanfifteen(sortedTriggeredPosition)) {
      dispatch(
        showToast({
          text: "Difference in Trigger Position Seconds should be atleast 15 secs",
          severity: "error",
        })
      );
    } else if (values?.SelectVideo === "video" && !changeButton) {
      dispatch(
        showToast({
          text: "Please Upload the File First",
          severity: "error",
        })
      );
    } else {
      const formData = new FormData();
      formData.append("title", values?.title);
      formData.append("Id", data ? data : 0);
      formData.append("courseId", values?.courseId);
      formData.append(
        "videoUrl",
        values?.SelectVideo === "url"
          ? values?.videoUrl
          : changeButton
          ? videoFileData?.url
          : ""
      );
      formData.append("duration", values?.duration);
      if (typeof values?.upload === "object")
        formData.append("upload", values?.upload);

      formData.append(
        "CoverImage",
        typeof values?.upload === "object"
          ? values?.upload?.name
          : detailsform?.trainingCourseChapters?.coverImage?.toString()
      );
      formData.append("passPercentage", values?.passPercentage);
      formData.append("description", values?.description);
      // formData.append("isActive", values.isActive === "Yes" ? true : false);
      if (values?.questions.length > 0) {
        values?.questions.forEach(function (object, index) {
          formData.append(
            `questions[${index}][questionSetId]`,
            object.questionSetId
          );
          formData.append(
            `questions[${index}][triggerPositionSecond]`,
            Math.floor(object.triggerPositionSecond)
          );
        });
      }
      data ? Edit(formData) : createPageData(formData);
    }
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (changeButton) {
      dispatch(
        showToast({
          text: "Please Delete the Uploaded File",
          severity: "error",
        })
      );
    } else {
      navigate(-1);
    }
  };
  const returnPage1 = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={data ? "Edit Lesson" : "Create Lesson"}
      ButtonLabel={data ? "Edit Lesson" : "Create Lesson"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      changeButton={changeButton}
      initialvalue={
        data
          ? {
              ...initialvalue,
              courseId: detailsform?.trainingCourseChapters?.courseId,
              title: detailsform?.trainingCourseChapters?.title,
              videoUrl: detailsform?.trainingCourseChapters?.videoUrl,
              duration: detailsform?.trainingCourseChapters?.duration,
              description: detailsform?.trainingCourseChapters?.description,
              passPercentage:
                detailsform?.trainingCourseChapters?.passPercentage,
              SelectVideo: detailsform ? "url" : "",
              upload: `TrainingCourseChapters/${detailsform?.trainingCourseChapters?.coverImage}`,
              isActive: detailsform?.trainingCourseChapters?.isActive
                ? "Yes"
                : "No",
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreateChapterComp
          messaged={messaged}
          formValues={formValues}
          edit={data ? true : false}
          setchangeButton={setchangeButton}
          changeButton={changeButton}
          videoFileData={videoFileData}
          setVideoFileData={setVideoFileData}
          detailsform={detailsform}
        />
      }
    />
  );
};

export { CreateChapter };
