import React from 'react'
import { FormCustomFormik } from '../../../../Components/FormComponent/FormCustomFormik'
import { useEffect } from 'react';

export const CreateAsset = () => {
  useEffect(() => {
    document.title = `PeopleSol - Create Asset`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
    <FormCustomFormik/>
   </div>
  )
}
