import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCategoryComp } from "./FormComp/CreateCategoryComp";
import { useEffect } from "react";

const CreateCategories = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState(false);
  const data = state?.id;
  const [formValues, setFormValues] = useState(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    try {
      const result = await getRequest(`TrainingCategories/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  console.log("first formValues", formValues);
  useEffect(() => {
    document.title = `PeopleSol - Create Categories`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("TrainingCategories/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequestForm("TrainingCategories/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
  ];

  const getItemValue = () => {
    let returnArray = [];
    data && detail && detail?.trainingCategories[0]?.sign
      ? detail?.trainingCategories[0]?.sign?.split(",")?.map((item, index) => {
          return returnArray.push({
            signatory:
              detail?.trainingCategories[0]?.signatory?.split(",")[index],
            sign: `TrainingCategories/${item}`,
            role: parseInt(detail?.trainingCategories[0]?.role, 10),
          });
        })
      : (returnArray = [{ signatory: "", sign: null, role: "" }]);

    return returnArray;
  };

  const initialvalue = {
    name: data ? (detail ? detail?.trainingCategories[0]?.name : "") : "",
    // parentCategoriesId: null,
    color: data
      ? detail
        ? detail?.trainingCategories[0]?.color
        : "#1296b0"
      : "#1296b0",
    type: data ? (detail ? detail?.trainingCategories[0]?.type : "") : "",

    isDisplayInCandidatePage: data
      ? detail
        ? detail?.trainingCategories[0]?.isDisplayInCandidatePage
          ? "Yes"
          : "No"
        : "Yes"
      : "Yes",
    certificateNeeded: data
      ? detail
        ? detail?.trainingCategories[0]?.certificateNeeded
          ? "Yes"
          : "No"
        : "No"
      : "No",
    upload: data
      ? detail
        ? detail?.trainingCategories[0]?.attachment
          ? `TrainingCategories/${detail?.trainingCategories[0]?.attachment}`
          : null
        : ""
      : "",
    item: getItemValue(),
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please Enter Name "),
    color: Yup.string().required("Please Select Colour"),
    type: Yup.string().required("Please Select Type"),
    upload: Yup.mixed()
      .required("Please Select File")
      .test("Fichier taille", "File Size should be less than 2 mb", (value) => {
        return (
          !value ||
          (value && value.size <= 2048 * 2048) ||
          typeof value !== "object"
        );
      })
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          (value && SUPPORTED_FORMATS.includes(value.type)) ||
          typeof value !== "object"
      ),
    item: Yup.array().when("certificateNeeded", {
      is: (val) => val === "Yes",
      then: () =>
        Yup.array().of(
          Yup.object().shape({
            role: Yup.string().required("Please Select Role"),
            signatory: Yup.string().required("Please Enter Name"),
            sign: Yup.mixed()
              .required("Please Select File")
              .test(
                "Fichier taille",
                "File Size should be less than 2 mb",
                (value) => {
                  return (
                    !value ||
                    (value && value.size <= 2048 * 2048) ||
                    typeof value !== "object"
                  );
                }
              )
              .test(
                "format",
                "File Format is Incorrect",
                (value) =>
                  !value ||
                  (value && SUPPORTED_FORMATS.includes(value.type)) ||
                  typeof value !== "object"
              ),
          })
        ),
    }),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    formData.append("color", values?.color);
    formData.append("name", values?.name);
    formData.append("type", values?.type);

    if (typeof values?.upload === "object")
      formData.append("upload", values?.upload);
    else if (typeof values?.upload === "string")
      formData.append("attachment", values?.upload?.split("/").pop());

    if (values?.item.length > 0 && values?.certificateNeeded === "Yes") {
      values?.item.forEach(function (object, index) {
        formData.append(`Signateries[${index}].Signatory`, object.signatory);
        formData.append(`Signateries[${index}].Role`, object.role);
        if (typeof values?.item[index]?.sign === "object") {
          formData.append(
            `Signateries[${index}].uploadSignatory`,
            object?.sign
          );
        } else if (typeof values?.item[index]?.sign === "string") formData.append(`Signateries[${index}].uploadSignatory`, object.sign?.split("/").pop());
      });
    }
    formData.append(
      "isDisplayInCandidatePage",
      values.isDisplayInCandidatePage === "Yes" ? true : false
    );
    formData.append(
      "certificateNeeded",
      values.certificateNeeded === "Yes" ? true : false
    );
    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/ldmaster/courses");
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={data ? "Edit Learning Course" : "Create Learning Course"}
      ButtonLabel={data ? "Edit Learning Course" : "Create Learning Course"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateCategoryComp data={data} formValues={formValues} />}
    />
  );
};

export { CreateCategories };
