import React from "react";
import { useEffect } from "react";

export const TaxSlab = ({data}) => {
console.log("data",data)
  return (
    <>
        <div style={{display:'flex',backgroundColor:'#F6F6F6'}}>

            <p className="starholderpmself" >MONTHLY GROSS SALARY (₹)</p>
            <p className="starholderpmself" > MONTHLY TAX AMOUNT (₹)</p>
        </div>
       <div style={{display:'flex',flexDirection:'column',gap:'12px'}}>
       {data.statutoryProfessionalTaxSlabs.length ? data.statutoryProfessionalTaxSlabs.map((item)=>{
        return (
            <div className="ratingCar" style={{ fontSize: "14px",backgroundColor:'#F6F6F6',gap:'10px' }}>
            <div className="starholderpmself" style={{backgroundColor: 'white'}}>
              <div style={{ flex: "1" }}>{`₹${item.startRange} - ₹${item.endRange}`}</div>
              <label
                className="jobDesignation"
                style={{ fontSize: "15px", fontWeight: "500" ,flex: "1",paddingLeft:'12px' }}
              >
                <b> {item.ptAmount}</b>
              </label>
            </div>
          </div>
        )
       }):'-'}
       </div>
    </>
  );
};
