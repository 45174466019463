/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import Nodata from "../../../../../Assets/Nodata.svg";

import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../DetailsComp/Notifications";
import { Details } from "../../DetailsComp/Details";
import { TimeLineData } from "../../TransferComponents/DetailsComp/Timeline/TimeLineData";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../../Features/badgeUtils";
const DetailsPageFacilities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [aId, setAId] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, id, color) => {
    setTypeAction(type);
    setColorAction(color);
    setAId(id);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `FacilityRequests/Details?ID=${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              result.data?.summary?.actionlist[0]?.color
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page Facilitiest`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const PostApRejHol = async (type, id, Remark) => {
    // //console.log("type", type);
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let result;
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "Confirm"
      ) {
        result = await postRequest(`FacilityRequests/${type}?Id=${id}`);
        if (result) {
          dispatch(
            showToast({ text: result?.data?.message, severity: "success" })
          );
        }
      } else if (type === "Maintenance") {
        result = await postRequest(
          `FacilityRequests/ModificationRequests?ID=${id}&&type=${type}`
        );
        if (result) {
          dispatch(
            showToast({ text: result?.data?.message, severity: "success" })
          );
        }
      } else if (type === "Accept") {
        const result = await postRequest(`FacilityRequests/Complete?Id=${id}`);
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      } else {
        const result = await postRequest(
          `FacilityRequests/${type}?Id=${id}&Remarks=${Remark}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Facilities Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data?.data != null ? (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="headingForm"> Request Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Request Number"}
                        Details={data?.data?.requestNumber}
                      />
                      <Details
                        detailTitle={"Status"}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?.data?.status
                              )?.split(",")?.[0],
                              color: getStatusColor(data?.data?.status)?.split(
                                ","
                              )?.[1],
                              cursor: "default",
                              padding: "6px",
                              borderRadius: "6px",
                            }}
                          >
                            {data?.data?.status}
                          </div>
                        }
                      />
                      <Details
                        detailTitle={"Facility "}
                        Details={data?.data?.facilityCategory?.text}
                      />
                      <Details
                        detailTitle={"Need For"}
                        Details={data?.data?.request?.type}
                      />
                      {data?.data?.request?.type !== "Permanent" && (
                        <>
                          <Details
                            detailTitle={"From Date"}
                            Details={format(
                              new Date(data?.data?.request?.fromDate),
                              "dd/MM/yyyy hh:mm a"
                            )}
                          />
                          <Details
                            detailTitle={"To Date"}
                            Details={format(
                              new Date(data?.data?.request?.toDate),
                              "dd/MM/yyyy hh:mm a"
                            )}
                          />
                        </>
                      )}
                      <Details
                        detailTitle={"Reason"}
                        Details={data?.data?.request?.reason}
                      />
                      <Details
                        detailTitle={"Created On"}
                        Details={format(
                          new Date(data?.data?.request?.createdOn),
                          "dd/MM/yyyy hh:mm a"
                        )}
                      />
                      <Details
                        detailTitle={"Last Updated On"}
                        Details={format(
                          new Date(data?.data?.request?.lastUpdatedOn),
                          "dd/MM/yyyy hh:mm a"
                        )}
                      />
                    </div>
                    <div className="headingForm"> Requester's Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Requester Name"}
                        Details={`${data?.data?.request?.employee?.name} ${data?.data?.request?.employee?.lastName}`}
                      />
                      <Details
                        detailTitle={"Requester's Manager"}
                        Details={data?.data?.request?.employee?.rO_Name}
                      />
                      <Details
                        detailTitle={"Requester Designation"}
                        Details={data?.data?.request?.employee?.designation}
                      />
                      <Details
                        detailTitle={"Requester Department"}
                        Details={data?.data?.request?.employee?.department}
                      />
                      <Details
                        detailTitle={"Requester Email"}
                        Details={data?.data?.request?.employee?.email}
                      />
                      <Details
                        detailTitle={"Contact Number"}
                        Details={
                          data?.data?.request?.employee?.personalPhoneNumber
                        }
                      />
                      <Details
                        detailTitle={"Location"}
                        Details={data?.data?.request?.employee?.locationName}
                      />
                    </div>
                    {data?.data?.actionlist?.length > 0 ? (
                      <div
                        className="createFootDetails"
                        style={{
                          background: "white !important",
                        }}
                      >
                        {data?.data?.actionlist?.map((item) => {
                          return (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                    item.type !== "Release" &&
                                    item.type !== "Cancel"
                                    ? togglePopup(
                                      item?.type,
                                      item?.color,
                                      item?.id
                                    )
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  backgroundColor: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    {isOpen && (
                      <Popup
                        popupHeading={typeAction}
                        content={
                          <>
                            <Remark
                              data={PostApRejHol}
                              type={typeAction}
                              id={aId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          </>
                        }
                        handleClose={togglePopup}
                      />
                    )}
                  </div>
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLineData
                        id={getIdFromDynamicUrl(currentUrl)}
                        url={"FacilityRequests"}
                        module={data?.data?.generalWorkflowsApprovals?.module}
                        requestNumber={data?.data?.requestNumber}
                      />
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications
                      id={getIdFromDynamicUrl(currentUrl)}
                      module={"Admin Facility Request"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageFacilities };
