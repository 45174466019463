import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { Field } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { HotelSelfComp } from "./HotelSelfComp";
import { HotelGroupComp } from "./HotelGroupComp";
import { HotelGuestComp } from "./HotelGuestComp";
import { HotelVendorComp } from "./HotelVendorComp";
import { HotelCandidateComp } from "./HotelCandidateComp";
import { useSelector } from "react-redux";
import { getRequest } from "../../../../Services/axios";

const CreateHotelRequest = ({
  formValues,
  editTrue,
  popAnswer,
  pushEmployee,
  isBoth,
  setInitialValues,
  setBudgetArr,
  budgetArr,
}) => {
  const { LoggedInUser } = useSelector((state) => state.auth);

  const getInitialTab = () => {
    if (formValues) {
      if (formValues.Type) {
        switch (formValues?.Type) {
          case "Self":
            return 0;
          case "Group":
            return 1;
          case "Vendor":
            return 2;
          case "Guest":
            return 3;
          case "Candidate":
            return 4;
          default:
            return 0;
        }
      }
    }
    return 0;
  };
  useEffect(() => {
    document.title = `PeopleSol - Hotel Request`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [tabIndex, setTabIndex] = useState(getInitialTab);
  useEffect(() => {
    if (formValues)
      if (formValues.Type)
        setTabIndex((prev) => {
          if (prev === getInitialTab()) return prev;
          else return getInitialTab();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const dispatch = useDispatch();
  const [hotelCategoryOptions, sethotelCategoryOptions] = useState(null);

  const getFormDetails = async () => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Getcategory?mode=Hotel`
      );

      let findCategory = result.data?.results?.map((d, i) => {
        return { label: d?.text, value: d?.id };
      });
      sethotelCategoryOptions(findCategory);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getFormDetails();
  }, []);
  const handleTabChange = (props, newTabIndex) => {
    if (editTrue)
      return dispatch(
        showToast({
          text: "You cannot edit an existing travel booking type and mode of travel. Please create a new travel booking",
          severity: "error",
        })
      );
    setTabIndex(newTabIndex);
    switch (newTabIndex) {
      case 0:
        return props?.form?.setFieldValue("Type", "Self");
      case 1:
        return props?.form?.setFieldValue("Type", "Group");
      case 2:
        return props?.form?.setFieldValue("Type", "Vendor");
      case 3:
        return props?.form?.setFieldValue("Type", "Guest");
      case 4:
        return props?.form?.setFieldValue("Type", "Candidate");
      default:
        return props?.form?.setFieldValue("Type", "Self");
    }
  };
  const TypeTabField = (props) => {
    return (
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={(event, newTabIndex) => handleTabChange(props, newTabIndex)}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Self
              </span>
            }
          />
          {(LoggedInUser?.isHRHead ||
            LoggedInUser?.isHRDirector ||
            LoggedInUser?.isFunctionalHOD ||
            LoggedInUser?.isReportingManager) && (
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                  Group
                </span>
              }
            />
          )}
          {(LoggedInUser?.isHRHead ||
            LoggedInUser?.isHRDirector ||
            LoggedInUser?.isFunctionalHOD ||
            LoggedInUser?.teamnames?.includes("HR Team")) && (
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                  Vendor
                </span>
              }
            />
          )}
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                Guest
              </span>
            }
          />
          {(LoggedInUser?.isHRHead ||
            LoggedInUser?.isHRDirector ||
            LoggedInUser?.teamnames?.includes("HR Team")) && (
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                  Candidate
                </span>
              }
            />
          )}
          {/* {(LoggedInUser?.isHRHead ||
            LoggedInUser?.isHRDirector ||
            LoggedInUser?.teamnames?.includes("HR Team")) && (
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                  Candidate
                </span>
              }
            />
          )} */}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
    );
  };
  return (
    <div
      className="moduleMainContainer"
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      {!isBoth && <Field name="Type" component={TypeTabField} />}

      {tabIndex === 0 && (
        <>
          <HotelSelfComp
            formValues={formValues}
            hotelCategoryOptions={hotelCategoryOptions}
            isBoth={isBoth}
            setInitialValues={setInitialValues}
            editTrue={editTrue}
            setBudgetArr={setBudgetArr}
            budgetArr={budgetArr}
          />
        </>
      )}
      {tabIndex === 1 && (
        <>
          <HotelGroupComp
            formValues={formValues}
            popAnswer={popAnswer}
            pushEmployee={pushEmployee}
            hotelCategoryOptions={hotelCategoryOptions}
            isBoth={isBoth}
          />
        </>
      )}
      {tabIndex === 2 && (
        <>
          <HotelVendorComp
            formValues={formValues}
            popAnswer={popAnswer}
            pushEmployee={pushEmployee}
            hotelCategoryOptions={hotelCategoryOptions}
            isBoth={isBoth}
          />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <HotelGuestComp
            formValues={formValues}
            popAnswer={popAnswer}
            pushEmployee={pushEmployee}
            hotelCategoryOptions={hotelCategoryOptions}
            isBoth={isBoth}
          />
        </>
      )}
      {tabIndex === 4 && (
        <>
          <HotelCandidateComp
            formValues={formValues}
            hotelCategoryOptions={hotelCategoryOptions}
            isBoth={isBoth}
          />
        </>
      )}
    </div>
  );
};

export { CreateHotelRequest };
