import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { Table } from "../../../Components/index";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { deleteRequest, deleteWithPayloadRequest, getRequest, postRequest } from "../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import { YesNoCustom } from "../../../Components/FormComponent/YesNoCustom";
import { BrowserUpdated } from "@mui/icons-material";
import { AttachmentFormik } from "../../../Components/FormComponent/AttachmentFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../Components/FormComponent/Loader";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";

const Folders = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedNotiList, setSelectedNotiList] = useState([]);
  const [optionTeam, setOptionTeam] = React.useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [importToggle, setImportToggle] = useState(false);
  const [attachText, setAttachText] = useState(null);
  const [fileValue, setFileValue] = useState(null);
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });

  const downloadtemplate = async () => {
    try {
      const result = await getRequest("Facilities/DownloadTemplate");
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title} Template`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest("Documents/FoldersList", downloadReqData);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Documents/FoldersList", reqData);
      if (result.data.count > 0) {
       
        setTbodyData(JSON.parse(result.data.data));
      } else {
        setTbodyData(false);
      }
      setCount(result.data.count);
      calculateTotalPages(result.data.count);
      setSelectedRows([]);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getFilterNoti = async () => {
    try {
      const result = await getRequest(
        "Documents/Folder/GetparentFolders"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOptionTeam(listData);
    } catch (error) {
    } finally {
    }
  };
  const handleDelete = async (selectedRows) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await deleteWithPayloadRequest(
        `Documents/DeleteMultipleFolder`,
        selectedRows
      );
       
      if(result){

          dispatch(showToast({ text: "success", severity: "success" }));
         
        }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      getListData();
      setSelectedRows([]);
      setLoading(false);
    }
  };
 
const deleteSelected = () => {
  if (selectedRows.length === 1) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeletePopup
            onClose={onClose}
            message={"Are you sure you want to delete this Folder?"}
            onClick={() => handleDelete(selectedRows)}
          />
        );
      },
    });
  } else {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeletePopup
            onClose={onClose}
            message={`Are you sure you want to delete these ${selectedRows.length} Folders?`}
            onClick={() => handleDelete(selectedRows)}
          />
        );
      },
    });
  }
};


useEffect(()=>{
  document.title = `PeopleSol - Folder`;
  return () => {
    document.title = 'PeopleSol';
  };
})

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);

  const actionData = [
    {
      img: (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.15" d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8437V4.84375Z" fill="#0DB53C"/>
<line x1="14.7295" y1="7.43701" x2="7.72949" y2="7.43701" stroke="#0DB53C" stroke-linecap="round"/>
<line x1="5.72949" y1="7.43701" x2="5.61462" y2="7.43701" stroke="#0DB53C" stroke-linecap="round"/>
<line x1="14.7295" y1="11.3437" x2="7.72949" y2="11.3437" stroke="#0DB53C" stroke-linecap="round"/>
<line x1="5.72949" y1="11.3437" x2="5.61462" y2="11.3438" stroke="#0DB53C" stroke-linecap="round"/>
<line x1="14.7295" y1="15.2505" x2="7.72949" y2="15.2505" stroke="#0DB53C" stroke-linecap="round"/>
<line x1="5.72949" y1="15.2505" x2="5.61462" y2="15.2505" stroke="#0DB53C" stroke-linecap="round"/>
</svg>
      ),
      action: "Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
            fill="#FB7158"
          />
          <path
            d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
            stroke="#FB7158"
            strokeWidth="0.698594"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      action: "Edit Form",
      clickAction: (id) => handleEdit(id),
    },
  ];
  

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const getFormDetails = (id) => {
    // const selectedRecord = tbodyData?.find((item) => item?.Id === id);

    navigate("modulesettings/folders/details", {
      state: { id },
    });
  };
  const handleEdit = (id) => {
    navigate("modulesettings/folders/create", {
      state: { id },
    });
  };
  const location = useLocation();

  useEffect(() => {if (location.pathname.toLowerCase().includes("folders")) 
  getListData();
  },
   [location.pathname, reqData]);

  const applyFilters = () => {
    let tempSelectedSerListIds = [];
      if(selectedNotiList?.length>0){
        selectedNotiList?.map((item) => {
            tempSelectedSerListIds.push(item.label);
        })}
        else{
          tempSelectedSerListIds=null
        }
    setReqData({
      ...reqData,
      parent:tempSelectedSerListIds,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      parent:null
      
    });
    setSelectedNotiList([])
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (count > recordsSelected?.value) {
      if (selectedRows?.length === recordsSelected?.value) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
       return   arr.push(item.Id);
        });
        setSelectedRows(arr);
      }
    } else {
      if (selectedRows?.length === count) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
       return   arr.push(item.Id);
        });
        setSelectedRows(arr);
      }
    }
  };

  return (
    <div className="fullTableContainer">
<OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
      <div
        className={`${
          filterSearchToogle
            ? "filterSearchContainerActive filterSearchContainer"
            : "filterSearchContainer"
        }`}
      >
        <div className="headingContainer">
          <h3>Filter</h3><IconButton
              onClick={() => setFilterSearchToogle((prev) => !prev)}
              title="close"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
        </div>
        <h5>Fields</h5>
        <div className="filterListContainer">
          <div className="filterContainer">
      <div className="filterContainer">
            <p>Parent</p>
            <SelectCustom
            placeholder={"Select Parent"}
             onDropdownOpen={() => {
              getFilterNoti();
            }}
            options={optionTeam}
            multi
            values={selectedNotiList}
            onChange={(value) => {
              setSelectedNotiList(value);
            }}
            />
          </div>
            <p>Created On</p>
            <DateRangePicker
              selectedEnd={createdOnEndDate}
              selectedStart={createdOnStartDate}
              onChangeStart={(date) => setCreatedOnStartDate(date)}
              onChangeEnd={(date) => setCreatedOnEndDate(date)}
            />
          </div>
          <div className="filterContainer">
            <p>Last Updated On</p>
            <DateRangePicker
              selectedEnd={lastUpdatedOnEndDate}
              selectedStart={lastUpdatedOnStartDate}
              onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
              onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
            />
          </div>
        </div>
        <div className="filterSearchFooterContainer">
          <button className="button secondaryButton" onClick={resetFilters}>
            Reset
          </button>
          <button className="button primaryButton" onClick={applyFilters}>
            Apply
          </button>
        </div>
      </div>

      </OutsideClickHandler>


      <div className="tableHeaderContainer">
        {selectedRows.length > 0 ? (
          <button className="deletebtn" onClick={() => deleteSelected()}>
            <span className="deletebtnflex"><p>Delete</p><span>{`(${selectedRows.length})`}</span></span>
          </button>
        ) : (
          <button
            onClick={() => {
              navigate("modulesettings/folders/create");
            }}
            className="button primaryButton"
            type="submit"
          >
            + Create {props.title}
          </button>
        )}
        <div className="search-box">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title="Filter" placement="top">

        <IconButton
          color="primary"
          onClick={() => {
            setFilterSearchToogle(prev => !prev)
            importToggle ? setImportToggle(prev => !prev) : setImportToggle(prev => prev)
          }}
        >
          {!filterSearchToogle ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                stroke="#1296B0"
                strokeWidth="1.5"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7579 1.56897L0.757935 19.569"
                stroke="#F71D1D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 1.56897L18.7579 19.569"
                stroke="#F71D1D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </IconButton>
        </Tooltip>

        
      </div>
      <>
        {loading ? (
          <div className="noDataFound"><Loader/></div>
        ) : (
          <Table
            selectAll={() => selectAll()}
            tableFilterName="formTableFilter"
            addToSelectedRows={(Id) => addToSelectedRows(Id)}
            selectedRows={selectedRows}
            tbodyData={tbodyData}
            actionData={actionData}
            headerAction={sortUpdated}
            sortParam={sortParam}
            sortType={sortType}
          />
        )}
        <div className="horizontalLine"></div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{count}</span>
          <SelectCustom
            searchable={false}
            options={noOfRecords} dropdownPosition="top"
             
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </>
    </div>
  );
};
export { Folders };
