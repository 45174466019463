import React, { useState } from 'react';

function TabbedInterface({ setActiveTab, activeTab }) {


    const handleClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="tab-container">
            <button
                className={`tab-button ${activeTab === 'chat' ? 'active' : ''}`}
                onClick={() => handleClick('chat')}
                style={{ width: "30%", fontWeight: "600", fontSize: window.innerWidth > 1440 ? 14 : 12 }}
            >
                Chat
            </button>
            <button
                className={`tab-button ${activeTab === 'updates' ? 'active' : ''}`}
                onClick={() => handleClick('updates')}
                style={{ width: "30%", fontWeight: "600", fontSize: window.innerWidth > 1440 ? 14 : 12 }}
            >
                Stories
            </button>
            <button
                className={`tab-button ${activeTab === 'calls' ? 'active' : ''}`}
                onClick={() => handleClick('calls')}
                style={{ width: "30%", fontWeight: "600", fontSize: window.innerWidth > 1440 ? 14 : 12 }}
            >
                Calls
            </button>

        </div>
    );
}

export default TabbedInterface;
