import React from "react";
import { useEffect } from "react";
import StarRatings from "react-star-ratings";

export const InstructionSelf = ({ data }) => {
  useEffect(() => {
    document.title = `PeopleSol -  Instruction Self`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // const getDetails = async () => {
  //   try {
  //     setIsLoading(true);
  //     const result = await getRequest(
  //       `PMSAppraisalReview/AppraisalRatingSlab?ID=${appraisalCycleId}&EmployeeId=${LoggedInUser?.id}`
  //     );
  //     setData(result?.data?.data);
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: error?.response?.data ?? "Error in fetching Data",
  //         severity: "error",
  //       })
  //     );
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  // console.log("object");

  // useEffect(() => {
  //   if (appraisalCycleId) getDetails();
  // }, [appraisalCycleId, LoggedInUser]);

  return (
    <>
      {Array?.isArray(data?.appraisalRatingSlab) &&
        data?.appraisalRatingSlab?.length > 0 && (
          <div style={{ background: "white", padding: "12px" }}>
            <p style={{ fontSize: "14px", padding: "0px 0px 12px 1px" }}>
              Rating
            </p>
            <div className="ratingCar">
              {data?.appraisalRatingSlab
                ?.sort((a, b) => b.rating - a.rating)
                ?.map((item) => (
                  <div className="starholderpmself">
                    <div style={{}}>
                      <StarRatings
                        starEmptyColor={"#BFBFBF"}
                        starRatedColor={"var(--secondary)"}
                        starDimension="17px"
                        starSpacing="2px"
                        rating={item?.rating}
                      />
                    </div>
                    <label
                      className="jobDesignation"
                      style={{ fontSize: "15px", fontWeight: "500" }}
                    >
                      <b>{item?.name}</b> ({item?.weightFrom}-{item?.weightTo} %
                      Achievement)
                    </label>
                  </div>
                ))}
            </div>
          </div>
        )}
    </>
  );
};
