import React from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaCustom } from "../../../Components/FormComponent/TextAreaCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import Alerticon from "../../../Assets/Alert-icon.png";
import { RadioFormik } from "../../../Components/FormComponent/RadioFormik";
import { RadioRewardFormik } from "../../../Components/FormComponent/RadioRewardFormik";
import { CheckboxFormiks } from "../../../Components/FormComponent/CheckboxFormiks";
import { CheckboxFormiksRewards } from "../../../Components/FormComponent/CheckboxFormiksRewards";

import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { rewardsCertificateTemplateArray } from "../../../Utilities/rewardsCertificateTemplateArray";

const CreateRewardSetReward = () => {
  const options = [
    { label: "All Department & All locations wise", value: "All Department" },
    { label: "For selected departments & location wise", value: "Department wise" },
  ];

  const optionsPMs = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "No" },
  ];

  return (
    <div className="P-20">
      <div className="formcustomthree setreward">
        <div>
          <LabelCustom labelName={"Reward Title"} mandatory="true" />
          <InputCustomFormik
            maxLength={150}
            name="Title"
            type={"text"}
            placeholder={"Enter Title"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Description"} mandatory="true" />
          <TextAreaFormik
            maxLength={500}
            name="Description"
            type={"text"}
            placeholder={"Enter Here"}
          />
        </div>

        <div>
          <LabelCustom labelName={""} />
          <div className="Reward-alert">
            <img src={Alerticon} alt="Alerticon" />
            <p>
              Keep the description brief; it will be the part of the reward
              certificate.(Ex. This certificate is in recognition of the
              exemplary contribution to wards Sales/Service/R&D, etc. )
            </p>
          </div>
        </div>

      </div>
      <div className="formcustomthree pb-30">

        <div className="radioFormikcustomflex ">
          <LabelCustom labelName={"RewardParticipation"} mandatory="true" />

          <RadioFormik
            editstyle={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "baseline",
            }}
            options={options}
            name="RewardParticipation"
          />
        </div>

        <div>
          <LabelCustom labelName={"Number of Winners"} mandatory="true" />
          <InputCustomFormik
            maxLength={150}
            name="WinnersCount"
            type={"text"}
            placeholder={"enter"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Number of Consolation"} mandatory="true" />
          <InputCustomFormik
            maxLength={150}
            name="ConsolationCount"
            type={"text"}
            placeholder={"enter"}
          />
        </div>

      </div>

      <hr />

      <div className="pb-30">

        <div>
          <RadioRewardFormik
            options={rewardsCertificateTemplateArray}
            name="Template"
          />
        </div>

      </div>

      <hr />
      
      <div className="formcustomthree pb-30">
        <div className="">
          <LabelCustom labelName={"Share on Social"} mandatory="true" />
          <div className="CheckFormik-Checkbox">
            <CheckboxFormiks name="News" labelName={"News"} />
            <CheckboxFormiks name="Noticeboard" labelName={"Noticeboard"} />
            <CheckboxFormiks name="social" labelName={"Social"} />
          </div>
        </div>
        <div className="radioFormikcustomflex">
          <LabelCustom labelName={"Link Incentive"} mandatory="true" />
          <RadioFormik options={optionsPMs} name="LinkIncentive" />
        </div>
        <div className="radioFormikcustomflex">
          <LabelCustom labelName={"Link With PMS"} mandatory="true" />
          <RadioFormik options={optionsPMs} name="LinkWithPMS" />
        </div>
      </div>
    </div>
  );
};

export default CreateRewardSetReward;
