import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import {
  getRequest,

  postRequestForm,
} from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import {CreateNoticePayComp} from "./CreateNoticePayComp";

const CreateNoticePay = (props) => {
  const { state } = useLocation();

  const [detailsform, setDetailsform] = useState({});

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PayrollNoticeBoard/Details?ID=${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  // // //console.log("PayrollNoticeBoard id",data)
  // // //console.log("PayrollNoticeBoard Details",detailsform)
  useEffect(() => {
    document.title = `PeopleSol - Create Notice`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("PayrollNoticeBoard/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
      // // //console.log(result)
    } catch (error) {
      // // //console.log(error)
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("PayrollNoticeBoard/Edit", values);
      dispatch(
        showToast({ text: "Successfully Updated", severity: "success" })
      );
      returnPage();
      // // //console.log(result)
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const initialValue = {
    Title: "",
    Module: "",
    RequestId: "",
    Description: "",
    ButtonText: "",
    FooterText: "",
    RedirectUrl: "",
    Image: "",
    Upload: "",
    IsDisplayInMobile: "Yes",
  };

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const validationSchema = data
    ? Yup.object({
        Title: Yup.string()
          .max(149, "Title must be less than 150 characters")
          .required("Please Enter Title"),
        Module: Yup.string().required("Please Select Module"),
        RequestId: Yup.number()
          .max(999999, "RequestId must be less than 999999 characters")
          .required("Please Enter RequestId"),
        Description: Yup.string()
          .max(499, "Description cannot be more than 500 characters")
          .notRequired("Please Enter Description"),
        ButtonText: Yup.string()
          .max(149, "Button text must be less than 150 characters")
          .notRequired("Please Enter Title"),
        FooterText: Yup.string()
          .max(149, "Footer Text must be less than 150 characters")
          .notRequired("Please Enter Title"),
        Upload: Yup.mixed()
          .nullable()
          .test(
            "Fichier taille",
            "File Size should be less than 1 mb",
            (value) => !value || (value && value.size <= 2048 * 2048)
          )
          .test(
            "format",
            "File Format is Incorrect",
            (value) =>
              !value || (value && SUPPORTED_FORMATS.includes(value.type))
          ),
        RedirectUrl: Yup.string()
          .notRequired()
          .nullable()
          .matches(
            /^https?:\/\/(.*)/,
            "website must start with http:// or https://"
          ),
      })
    : Yup.object({
        Title: Yup.string()
          .max(149, "Title must be less than 150 characters")
          .required("Please Enter Title"),
        Module: Yup.string().required("Please Select Module"),
        RequestId: Yup.number()
          .max(999999, "RequestId must be less than 999999 characters")
          .required("Please Enter RequestId"),
        Description: Yup.string()
          .max(499, "Description cannot be more than 500 characters")
          .notRequired("Please Enter Description"),
        ButtonText: Yup.string()
          .max(149, "Button text must be less than 150 characters")
          .notRequired("Please Enter Button text")
          .nullable(),
        FooterText: Yup.string()
          .max(149, "Footer Text must be less than 150 characters")
          .notRequired("Please Enter Footer Text")
          .nullable(),
        Upload: Yup.mixed()
          .nullable()
          .test(
            "Fichier taille",
            "File Size should be less than 1 mb",
            (value) => !value || (value && value.size <= 2048 * 2048)
          )
          .test(
            "format",
            "File Format is Incorrect",
            (value) =>
              !value || (value && SUPPORTED_FORMATS.includes(value.type))
          ),
        RedirectUrl: Yup.string()
          .notRequired()
          .nullable()
          .matches(
            /^https?:\/\/(.*)/,
            "website must start with http:// or https://"
          ),
      });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    formData.append("Title", values?.Title);
    formData.append("Module", values?.Module);
    formData.append("Image", values?.Upload.name);
    formData.append("Upload", values?.Upload);
    formData.append("RequestId", values?.RequestId);
    formData.append("Description", values?.Description);
    formData.append("ButtonText", values?.ButtonText);
    formData.append("FooterText", values?.FooterText);
    formData.append("RedirectUrl", values?.RedirectUrl);
    formData.append(
      "IsDisplayInMobile",
      values?.IsDisplayInMobile === "Yes" ? true : false
    );

    // formData.append("CoverImage", values?.upload !== "" ?  values?.upload?.name : detailsform?.coverImage?.toString());
    // formData.append("isActive",true);
    // // //console.log('formData',formData)
    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Notice" : "Create Notice"}
      ButtonLabel={data ? "Update Notice" : "Create Notice"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialValue,
              Title: detailsform?.title,
              Module: detailsform?.module,
              RequestId: detailsform?.requestId,
              Description: detailsform?.description,
              Image: detailsform?.image,
              ButtonText: detailsform?.buttonText,
              FooterText: detailsform?.footerText,
              RedirectUrl: detailsform?.redirectUrl,
              Upload: `banners${detailsform?.image}`,
              IsDisplayInMobile:
                detailsform?.isDisplayInMobile === true ? "Yes" : "No",
            }
          : initialValue
      }
      returnPage={returnPage}
      propsDiv={<CreateNoticePayComp data={detailsform} />}
    />
  );
};

export { CreateNoticePay };
