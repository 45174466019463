import React from 'react'
import { useEffect } from 'react';

const ServiceAppraisals = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Appraisal Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      
        
    </div>
  )
}

export {ServiceAppraisals};
