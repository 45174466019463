import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import { TransactionTable } from "../../../Components/Table/TransactionTable";
import { Loader } from "../../../Components/FormComponent/Loader";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
export const DetailsAllDeclaration = (props) => {
  const dispatch = useDispatch();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSerList, setSelectedSerList] = useState([]);
  const [selectedSerCatList, setSelectedSerCatList] = useState([]);
  const [selectedLocationList, setSelectedLocationList] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedNotiList, setSelectedNotiList] = useState([]);
  const [selectedTeamList, setSelectedTeamList] = useState([]);
  const [selectedDepartmentList, setSelectedDepartmentList] = useState([]);
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const location = useLocation();
  const data = location?.state?.data;
  //console.log("data",data)
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest(
        "TaxDeclaration/AllTaxDeclarationsList",
        reqData
      );
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  All Declaration Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    settingId: Number(selectedId),
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getFormDetails = (id, data) => {
    navigate(`/tax/alltaxes/create/${selectedId}`, {
      state: { id },
    });
  };

  const getStaticActions = (data) => {
    let returnActionArray = [];
    if (data.length > 0) {
      data.map((action) => {
        if (action.type === "Details") {
          returnActionArray.push({
            img: (
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.15"
                  d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
                  fill="#0DB53C"
                />
                <line
                  x1="14.7295"
                  y1="7.43701"
                  x2="7.72949"
                  y2="7.43701"
                  stroke="#0DB53C"
                  stroke-linecap="round"
                />
                <line
                  x1="5.72949"
                  y1="7.43701"
                  x2="5.61462"
                  y2="7.43701"
                  stroke="#0DB53C"
                  stroke-linecap="round"
                />
                <line
                  x1="14.7295"
                  y1="11.3437"
                  x2="7.72949"
                  y2="11.3437"
                  stroke="#0DB53C"
                  stroke-linecap="round"
                />
                <line
                  x1="5.72949"
                  y1="11.3437"
                  x2="5.61462"
                  y2="11.3438"
                  stroke="#0DB53C"
                  stroke-linecap="round"
                />
                <line
                  x1="14.7295"
                  y1="15.2505"
                  x2="7.72949"
                  y2="15.2505"
                  stroke="#0DB53C"
                  stroke-linecap="round"
                />
                <line
                  x1="5.72949"
                  y1="15.2505"
                  x2="5.61462"
                  y2="15.2505"
                  stroke="#0DB53C"
                  stroke-linecap="round"
                />
              </svg>
            ),
            action: "Request Details",
            clickAction: (id) => getFormDetails(action?.id),
          });
        }
        return null;
      });
    }
    return returnActionArray;
  };

  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `TaxDeclaration/AllTaxDeclarationsList`,
        reqData
      );
      if (result.data) {
        let tempData = [];
        result.data.data.map((item) => {
          return tempData.push({
            ...item.item,
            employeeName: item?.item?.employeename,
            actions: getStaticActions(item.actions),
          });
        });
        setOriginalData(tempData);
        const clippedArray = tempData.map(
          ({
            attachment,
            employeeNames,

            employeePersonNumber,

            hasAllApproved,

            hasAnyRejected,

            isPending,

            locationID,

            officalmail,

            phone,

            priority,

            reportingOfficer,
            reportingOfficerName,
            requestCategory,
            requestCategoryId,
            serviceCategoryID,
            serviceid,
            tatlastCheckedOn,
            teamId,
            statusLabel,
            statusLabel1,
            ticketTypeID,
            assigineeId,
            typeId,
            extention,
            hrHelpdeskTicketTatInformations,
            ...rest
          }) => ({ ...rest })
        );

        const finalArray = clippedArray.map(
          ({
            id,
            employeeName,
            status,
            email,
            designation,
            department,
            location,
          }) => ({
            id,
            employeeName,
            status,
            email,
            designation,
            department,
            location,
          })
        );
        setTbodyData(finalArray);
        setCount(result.data.summary.allCount);
        calculateTotalPages(result.data.summary.allCount);
      } else {
        setTbodyData(false);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    getListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    setReqData({
      ...reqData,
      ticketType: selectedNotiList[0]?.value,
      requestCategory: selectedSerList[0]?.value,
      serviceCategory: selectedSerCatList[0]?.value,
      department: selectedDepartmentList[0]?.label,
      teamId: selectedTeamList[0]?.value,
      location: selectedLocationList[0]?.value,
      status: selectedStatusList[0]?.value,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      ticketType: null,
      requestCategory: null,
      serviceCategory: null,
      location: null,
      status: null,
      teamId: null,
      department: null,
    });
    setFilterSearchToogle((prev) => !prev);
    setSelectedNotiList([]);
    setSelectedSerList([]);
    setSelectedSerCatList([]);
    setSelectedLocationList([]);
    setSelectedStatusList([]);
    setSelectedDepartmentList([]);
    setSelectedTeamList([]);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
    } else {
      let arr = [];
      tbodyData?.map((item) => {
        if (
          originalData.find((x) => (x.id ?? x.Id) === (item.id ?? item.Id))
            .actions?.length > 1
        )
          arr.push(item.Id ?? item.id);
        return null;
      });
      setSelectedRows(arr);
    }
  };

  return (
    <div className="bgCreate">
      <div className="modulecontGoal" style={{ padding:"24px" ,boxShadow:'none'}}>
        <div className="headingContainer headTAx">
          <div
            onClick={() => navigate(-1)}
            style={{ display: "flex" }}
            className="returnToDashboard cursor"
          >
            <div>
              <svg
                width="20"
                height="8"
                viewBox="0 0 20 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                  fill="#1296B0"
                />
              </svg>
            </div>
            <label className="returnLabelHolder">
             {data?.item?.emailTemplate==="Income tax declaration" ?  "All Tax Declaration Summary" :"All Tax Submission Summary"}
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <div className="search-box" title="Search">
              <input
                className="search-text"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchParam(e.target.value)}
              />
              <a className="search-btn" title="Search">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                    fill="#1296B0"
                  />
                </svg>
              </a>
            </div>

            <IconButton
              title={`${filterSearchToogle ? "Cancel" : "Filter"}`}
              color="primary"
              onClick={() => {
                setFilterSearchToogle((prev) => !prev);
              }}
            >
              {!filterSearchToogle ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                    stroke="#1296B0"
                    strokeWidth="1.5"
                  />
                </svg>
              ) : (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7579 1.56897L0.757935 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.757935 1.56897L18.7579 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </IconButton>
            <IconButton title="Export" color="primary" onClick={exportTable}>
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.1949 0.95166V11.6183M10.1949 0.95166L6.63936 4.50722M10.1949 0.95166L13.7505 4.50722M1.30603 13.3961L1.85803 15.605C1.95417 15.9896 2.1761 16.331 2.48855 16.575C2.801 16.819 3.18604 16.9516 3.58247 16.9517H16.8074C17.2038 16.9516 17.5888 16.819 17.9013 16.575C18.2137 16.331 18.4357 15.9896 18.5318 15.605L19.0838 13.3961"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <div className="fullTableContainer" style={{boxShadow: "0px 3px 10px rgb(0 0 0 / 6%)"}}>
          <OutsideClickHandler
            onOutsideClick={() => {
              setFilterSearchToogle((prev) => {
                if (prev) return false;
                else return prev;
              });
            }}
          >
            <div
              className={`${
                filterSearchToogle
                  ? "filterSearchContainerActive filterSearchContainer"
                  : "filterSearchContainer"
              }`}
            >
              <div className="headingContainer">
                <h3>Filter</h3>
                <IconButton
                  onClick={() => setFilterSearchToogle((prev) => !prev)}
                  title="close"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.7579 1.56897L0.757935 19.569"
                      stroke="#F71D1D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M0.757935 1.56897L18.7579 19.569"
                      stroke="#F71D1D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IconButton>
              </div>
              <h5>Fields</h5>
              <div className="filterListContainer">
                <div className="filterContainer">
                  <div className="filterContainer">
                    <p>Created On</p>
                    <DateRangePicker
                      selectedEnd={createdOnEndDate}
                      selectedStart={createdOnStartDate}
                      onChangeStart={(date) => setCreatedOnStartDate(date)}
                      onChangeEnd={(date) => setCreatedOnEndDate(date)}
                    />
                  </div>
                  <div className="filterContainer">
                    <p>Last Updated On</p>
                    <DateRangePicker
                      selectedEnd={lastUpdatedOnEndDate}
                      selectedStart={lastUpdatedOnStartDate}
                      onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                      onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="filterSearchFooterContainer">
                <button
                  className="button secondaryButton"
                  onClick={resetFilters}
                >
                  Reset
                </button>
                <button className="button primaryButton" onClick={applyFilters}>
                  Apply
                </button>
              </div>
            </div>
          </OutsideClickHandler>
          <>
            {loading ? (
              <div className="noDataFound">
                <Loader />
              </div>
            ) : (
              <>
                <TransactionTable
                  selectAll={selectAll}
                  tableFilterName="asadFilter"
                  tbodyData={tbodyData}
                  headerAction={sortUpdated}
                  sortParam={sortParam}
                  sortType={sortType}
                  selectedRows={selectedRows}
                  addToSelectedRows={addToSelectedRows}
                  originalData={originalData}
                  containStatus={true}
                />
              </>
            )}
            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords}
                dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
