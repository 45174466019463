import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalGatePass } from "../Components/GatePassApproval/PendingApprovalGatePass";
import { GatePassApproved } from "../Components/GatePassApproval/GatePassApproved";
import { RejectedGatePass } from "../Components/GatePassApproval/RejectedGatePass";
import { AllRequestGatePass } from "../Components/GatePassApproval/AllRequestGatePass";
import { useEffect } from "react";

const ApprovalGatePass = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Gate Pass`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalGatePass
            getApprovalBadge={getApprovalBadge}
            title={"GatePass"}
          />
        </>
      )}
      {tabIndex === 1 && (
        <GatePassApproved
          title={"GatePass"}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <RejectedGatePass
            title={"GatePass"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}

      {tabIndex === 3 && (
        <>
          <AllRequestGatePass
            title={"GatePass"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalGatePass };
