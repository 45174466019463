/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, Form } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../../Services/axios";
import { InputCustom } from "../../../../../Components/FormComponent/InputCustom";
import { useSelector } from "react-redux";

const AddAssignee = ({ data, id, color, type, typeId }) => {
  const [Employee, setEmployee] = useState([]);
  const { LoggedInUser } = useSelector((state) => state.auth);

  const [formValues, setFormValues] = useState(null);
  console.log("object formValues", formValues);
  const getEmployee = async () => {
    try {
      const result = await getRequest(
        `ItHelpdesks/GetItTeamEmployees?ID=${typeId}`
      );

      let listData = [];
      result?.data?.results?.map((item) => {
        return listData?.push({ label: item.text, value: item.id });
      });
      setEmployee(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Add Assignee`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("AssgineID", values?.AssgineID);
    formData.append("TicketId", id);

    // const value = { ...values, TicketId: id };
    data(formData);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  console.log("object Employee", Employee);
  useEffect(() => {
    getEmployee();
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={{ AssgineID: type === "Update Assignee" ? color : "" }}
      onSubmit={onSubmit}
    >
      {(formik) => {
        chooseFormValues && chooseFormValues(formik.values);
        return (
          <Form>
            <div>
              <div>
                <LabelCustom labelName={"Current Assignee"} />
                <InputCustom
                  // value={
                  //   Employee?.filter(
                  //     (it) => it?.value === formValues?.AssgineID
                  //   )[0]?.label
                  // }
                  value={
                    type === "Update Assignee"
                      ? Employee?.filter(
                          (it) => it?.value === LoggedInUser?.id
                        )[0]?.label
                      : ""
                  }
                  disabled
                />
              </div>
              <div>
                <LabelCustom labelName={"Select Assignee"} mandatory={true} />
                <SelectForm name="AssgineID" options={Employee} />
              </div>
            </div>
            <div className="createFootCust">
              <div>
                <div
                  className="button secondaryButton"
                  onClick={() =>
                    formik.resetForm({ values: { AssgineID: "" } })
                  }
                >
                  Reset
                </div>
              </div>
              <div>
                <button
                  className="button primaryButton"
                  type="submit"
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { AddAssignee };
