import React from "react";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { FieldArray } from "formik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TableBody, TableHead, TableRow, Table, Tooltip } from "@mui/material";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import SurveyAnswersDeleteIcon from "../../../../Assets/SurveyAnswersDeleteIcon.svg";

import { useEffect } from "react";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { Loader } from "../../../../Components/FormComponent/Loader";
import ReimbursementformTypes from "./ReimbursementformTypes";
import ReimbursementformTypesMulti from "./ReimbursementformTypesMulti";
const yesNoOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
const CreateReimbursementComp = ({
  formValues,
  loading,
  setInitialValues,
  getInitialValuesRepeated,
  editIDData,
  seteditIDDatafunc,
  setResultData,
  setClaimCount,
  claimCount,
  resultData,
}) => {
  const dispatch = useDispatch();
  // const [travelRequestsIdList, setTravelRequestsIdList] = useState([]);
  const [advanceRequestsList, setAdvanceRequestsList] = useState([]);

  const pushEmployee = (index) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails = [
        ...newGroupItems[index].Expenselist,
        {
          ExpenseTypes: "",
          Date: "",
          fromlocation: "",
          tolocation: "",
          categoryid: "",
          type: "",
          totalkm: "",
          cost: "",
          Amount: "",
          IsReceiptAvailable: "",
          Upload: "",
          Justification: "",
          Remarks: "",
          mode: "",
          totaltickets: "",
          totalitems: "",
          totaldays: "",
        },
      ];

      newGroupItems[index] = {
        ...newGroupItems[index],
        Expenselist: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const popAnswer = (index, indexW) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails = newGroupItems[index].Expenselist?.filter(
        (item, ind) => ind !== indexW
      );

      newGroupItems[index] = {
        ...newGroupItems[index],
        Expenselist: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  useEffect(() => {
    document.title = `PeopleSol - Reimbursement`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (formValues?.TravalRequestId) {
      let count = 0;
      formValues?.groupItems?.forEach((it) => {
        it?.Expenselist?.forEach((item) => {
          count += item?.Amount;
        });
      });
      formValues?.groupItemsHotel?.forEach((it) => {
        it?.Expenselist?.forEach((item) => {
          count += item?.Amount;
        });
      });
      setClaimCount(count);
    } else {
      let count = 0;
      formValues?.Expenselist?.forEach((item) => {
        count += item?.Amount;
      });
      setClaimCount(count);
    }
  }, [
    formValues?.Expenselist,
    formValues?.groupItems,
    formValues?.groupItemsHotel,
  ]);
  const expenseType = [
    { label: "Taxi", value: "Taxi" },
    { label: "Self Vehicle", value: "Self Vehicle" },
    { label: "Food", value: "Food" },
    { label: "Gift", value: "Gift" },
    { label: "Gadget", value: "Gadget" },
    { label: "Metro", value: "Metro" },
    { label: "Local Train", value: "Local Train" },
    { label: "Local Bus", value: "Local Bus" },
  ];

  const getadvanceRequests = async () => {
    try {
      const result = await getRequest("TravelHospitalityRequests/GettravelReq");
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setAdvanceRequestsList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  // const gettravelRequests = async () => {
  //   try {
  //     const result = await getRequest("Expenses/GetTravelRequest");
  //     let listData = [];
  //     result?.data?.map((item) => {
  //       return listData.push({ label: item.requestNumber, value: item.id });
  //     });
  //     setTravelRequestsIdList(listData);
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: "some error occured in fetching data",
  //         severity: "error",
  //       })
  //     );
  //   } finally {
  //   }
  // };
  const getTravelDetails = async (id) => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${id}`
      );
      if (!editIDData) {
        setInitialValues((prev) => {
          const newGroupItems = result?.data?.data?.travellingdetails?.map(
            (it) => ({
              FromDate: it?.fromDate ? new Date(it?.fromDate) : null,
              PickupLocation: it?.pickupLocation,
              DropLocation: it?.dropLocation,
              Receipt: it?.finalTicket,
              TravalId: it?.id,
              Expenselist: [
                {
                  // ExpenseTypeId: "",
                  ExpenseTypes: "",
                  Date: "",
                  fromlocation: "",
                  tolocation: "",
                  categoryid: "",
                  type: "",
                  totalkm: "",
                  cost: "",
                  Amount: "",
                  IsReceiptAvailable: "",
                  Upload: "",
                  Justification: "",
                  Remarks: "",
                  mode: "",
                  totaltickets: "",
                  totalitems: "",
                  totaldays: "",
                },
              ],
            })
          );
          const newGroupItemsHotel = result?.data?.data?.hoteldetail?.map(
            (it) => ({
              HotelcheckInTime: it?.checkInTime
                ? new Date(it?.checkInTime)
                : null,
              HotelcheckOutTime: it?.checkOutTime
                ? new Date(it?.checkOutTime)
                : null,
              Hotellocation: it?.location,
              HotelpreferHotel: it?.preferHotel,
              Receipt: it?.finalTicket,
              Expenselist: [
                {
                  // ExpenseTypeId: "",
                  ExpenseTypes: "",
                  Date: "",
                  fromlocation: "",
                  tolocation: "",
                  categoryid: "",
                  type: "",
                  totalkm: "",
                  cost: "",
                  Amount: "",
                  IsReceiptAvailable: "",
                  Upload: "",
                  Justification: "",
                  Remarks: "",
                  mode: "",
                  totaltickets: "",
                  totalitems: "",
                  totaldays: "",
                },
              ],
            })
          );
          return {
            ...formValues,
            groupItems: newGroupItems,
            groupItemsHotel: newGroupItemsHotel,
          };
        });
      }

      setResultData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    if (formValues?.TravalRequestId) {
      // if (formValues?.TravalRequestId && !editIDData) {
      if (formValues?.TravalRequestId) {
        getTravelDetails(formValues?.TravalRequestId);
      }
    }
  }, [formValues?.TravalRequestId]);

  useEffect(() => {
    getadvanceRequests();
    // gettravelRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        <div className="formcustom twoColumn">
          <div>
            <LabelCustom labelName={"Request ID"} />
            <SelectForm
              name={"TravalRequestId"}
              options={advanceRequestsList}
              placeholder={"Select Request ID"}
            />
          </div>
        </div>
        <div>
          <label className="repeatedHeading">Add Expenses</label>
          {!formValues?.TravalRequestId && (
            <div
              className="ReimbursementTable"
              style={{
                width: "100%",
                overflowX: "auto",
                overflowY: "hidden",
                paddingTop: "12px",
              }}
            >
              <FieldArray name="Expenselist">
                {(fieldArrayProps) => {
                  const { push, remove, insert, form } = fieldArrayProps;
                  const { values } = form;
                  const { Expenselist } = values;
                  return (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                            sx={{ width: "70px" }}
                          >
                            <LabelCustom labelName={"S. No"} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="left"
                          >
                            <LabelCustom labelName={"Add Details"} />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {Expenselist?.map((Expenselists, index) => (
                        <TableBody key={index}>
                          {/* 1st column */}
                          <StyledTableCell
                            sx={{ width: "70px" }}
                            align="center"
                          >
                            {index + 1}
                          </StyledTableCell>
                          {/* 2nd column */}
                          <StyledTableCell sx={{ maxWidth: "920px" }}>
                            <div
                              style={{
                                overflow: "auto",
                                width: "100%",
                                // height: "197px",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  borderRight: "1px solid #E4E3E3",
                                  padding: "18px 0px",
                                }}
                              >
                                <LabelCustom
                                  labelName={"Expense Type"}
                                  mandatory={true}
                                />
                                <SelectForm
                                  options={expenseType}
                                  name={`Expenselist[${index}].ExpenseTypes`}
                                  placeholder={"Select "}
                                />
                              </div>
                              <div
                                style={{
                                  // width: "calc( 100% - 475px )",
                                  overflow: "auto",
                                  display: "flex",
                                }}
                              >
                                <div
                                // style={{ minWidth: "778px", maxWidth: "100%" }}
                                >
                                  <div
                                    className="reimbursementTablein"
                                    style={{
                                      // minWidth: "1152px",
                                      padding: "18px 0px",
                                      position: "relative",
                                    }}
                                  >
                                    <ReimbursementformTypes
                                      ExpenseTypesSelected={
                                        formValues?.Expenselist[index]
                                          ?.ExpenseTypes
                                      }
                                      // employeeList={employeeList}
                                      index={index}
                                      formValues={formValues}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </StyledTableCell>
                          {/* 8th column */}
                          <StyledTableCell
                            sx={{ width: "100px", textAlign: "center" }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                Expenselist.length > 1
                                  ? remove(index)
                                  : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                insert(index + 1, {
                                  ExpenseTypes: "",
                                  Date: "",
                                  fromlocation: "",
                                  tolocation: "",
                                  categoryid: "",
                                  type: "",
                                  totalkm: "",
                                  cost: "",
                                  Amount: "",
                                  IsReceiptAvailable: "",
                                  Upload: "",
                                  Justification: "",
                                  Remarks: "",
                                  mode: "",
                                  totaltickets: "",
                                  totalitems: "",
                                  totaldays: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  );
                }}
              </FieldArray>
            </div>
          )}
          {formValues?.TravalRequestId &&
            formValues?.groupItems?.length > 0 && (
              <div
                className="ReimbursementTable"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  overflowY: "hidden",
                  paddingTop: "12px",
                }}
              >
                <FieldArray name="groupItems">
                  {(fieldArrayProps) => {
                    const { remove, insert, form } = fieldArrayProps;
                    const { values } = form;
                    const { groupItems } = values;
                    return (
                      <Table style={{ width: "100%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              padding="0"
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"S. No."} />
                            </StyledTableCell>
                            <StyledTableCell
                              padding="0"
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"Add Details"} />
                            </StyledTableCell>

                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"Add/Remove"} />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {groupItems?.map((Questions, index) => (
                          <TableBody key={index}>
                            <StyledTableCell
                              sx={{ width: "96px" }}
                              align="center"
                            >
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell sx={{ maxWidth: "920px" }}>
                              <div>
                                <div
                                  style={{ borderBottom: "1px solid #E6E6E6" }}
                                  className="formcustom fourColumn"
                                >
                                  <div>
                                    <LabelCustom
                                      labelName={"Travelling Date"}
                                      mandatory={true}
                                    />
                                    <DatePickerFormiks
                                      disabled={true}
                                      name={`groupItems[${index}].FromDate`}
                                      minDate={new Date()}
                                    />
                                  </div>
                                  <div>
                                    <LabelCustom
                                      labelName={"Form Location"}
                                      mandatory={true}
                                    />

                                    <InputCustomFormik
                                      disabled={true}
                                      maxLength={50}
                                      name={`groupItems[${index}].PickupLocation`}
                                      type="text"
                                      placeholder={" Enter From Location"}
                                    />
                                  </div>
                                  <div>
                                    <LabelCustom
                                      labelName={"To Location"}
                                      mandatory={true}
                                    />

                                    <InputCustomFormik
                                      disabled={true}
                                      maxLength={50}
                                      name={`groupItems[${index}].DropLocation`}
                                      type={"text"}
                                      placeholder={" Enter To Location"}
                                    />
                                  </div>
                                  <div>
                                    <LabelCustom labelName={"Receipt"} />
                                    <FileUploadFormik
                                      disabled={true}
                                      name={`groupItems[${index}].Receipt`}
                                    />
                                    <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                                      Size: 2 MB
                                    </span>
                                    <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                                      Format: JPG, PNG, PDF , Doc ,Docx
                                    </span>

                                  </div>
                                </div>
                                <div
                                  style={{
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                  }}
                                >
                                  {Questions?.Expenselist?.map(
                                    (item, indexW) => (
                                      <div
                                        style={{
                                          overflow: "auto",
                                          width: "100%",
                                          // height: "197px",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderRight: "1px solid #E4E3E3",
                                            borderBottom: "1px solid #E4E3E3",
                                            padding: "18px 0px",
                                            height: "221.6px ",
                                          }}
                                        >
                                          <LabelCustom
                                            labelName={"Expense Type"}
                                            mandatory={true}
                                          />
                                          <SelectForm
                                            options={expenseType}
                                            name={`groupItems[${index}].Expenselist[${indexW}].ExpenseTypes`}
                                            placeholder={"Select "}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            // width: "calc( 100% - 475px )",
                                            overflow: "auto",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                          // style={{ minWidth: "778px", maxWidth: "100%" }}
                                          >
                                            <div
                                              className="reimbursementTablein"
                                              style={{
                                                // minWidth: "1152px",
                                                padding: "18px 0px",
                                                position: "relative",
                                              }}
                                            >
                                              <ReimbursementformTypesMulti
                                                ExpenseTypesSelected={
                                                  formValues?.groupItems[index]
                                                    ?.Expenselist[indexW]
                                                    ?.ExpenseTypes
                                                }
                                                // employeeList={employeeList}
                                                groupItems={"groupItems"}
                                                index={index}
                                                indexW={indexW}
                                                formValues={formValues}
                                              />
                                            </div>
                                            {formValues?.groupItems[index]
                                              ?.Expenselist[indexW]
                                              ?.ExpenseTypes && (
                                                <div
                                                  className="surveyAddBtnContainer"
                                                  style={{
                                                    position: "sticky",
                                                    left: "79%",
                                                    /* right: 30px; */
                                                    bottom: "21px",
                                                    width: "142px",
                                                    // position: "absolute",
                                                    // right: "12px",
                                                    // bottom: "21px",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    cursor: "default",
                                                  }}
                                                // onClick={() => pushEmployee(index)}
                                                >
                                                  <div
                                                    onClick={() =>
                                                      popAnswer(index, indexW)
                                                    }
                                                  >
                                                    <img
                                                      // onClick={() => {
                                                      //   popAnswer(answer, answerIndex, index);
                                                      // }}
                                                      style={{
                                                        marginRight: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      src={
                                                        SurveyAnswersDeleteIcon
                                                      }
                                                      alt="SurveyAnswersDeleteIcon"
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      pushEmployee(index)
                                                    }
                                                  >
                                                    <button
                                                      type="button"
                                                      className="add-survey-btn"
                                                    >
                                                      +
                                                    </button>{" "}
                                                    Add Expenses
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ width: "100px", textAlign: "center" }}
                            >
                              <button
                                type="button"
                                className="remove-btn"
                                onClick={() =>
                                  groupItems.length > 1
                                    ? remove(index)
                                    : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                                }
                              >
                                -
                              </button>
                              <button
                                type="button"
                                className="add-btn"
                                onClick={() => {
                                  insert(index + 1, {
                                    FromDate: null,
                                    PickupLocation: "",
                                    DropLocation: "",
                                    Receipt: null,
                                    Expenselist: [
                                      {
                                        // ExpenseTypeId: "",
                                        ExpenseTypes: "",
                                        Date: "",
                                        fromlocation: "",
                                        tolocation: "",
                                        categoryid: "",
                                        type: "",
                                        totalkm: "",
                                        cost: "",
                                        Amount: "",
                                        IsReceiptAvailable: "",
                                        Upload: "",
                                        Justification: "",
                                        Remarks: "",
                                        mode: "",
                                        totaltickets: "",
                                        totalitems: "",
                                        totaldays: "",
                                      },
                                    ],
                                  });
                                }}
                              >
                                +
                              </button>
                            </StyledTableCell>
                          </TableBody>
                        ))}
                      </Table>
                    );
                  }}
                </FieldArray>
              </div>
            )}
          {formValues?.TravalRequestId &&
            formValues?.groupItemsHotel?.length > 0 && (
              <div
                className="ReimbursementTable"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  overflowY: "hidden",
                  paddingTop: "12px",
                }}
              >
                <FieldArray name="groupItemsHotel">
                  {(fieldArrayProps) => {
                    const { remove, insert, form } = fieldArrayProps;
                    const { values } = form;
                    const { groupItemsHotel } = values;
                    return (
                      <Table style={{ width: "100%" }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              padding="0"
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"S. No."} />
                            </StyledTableCell>
                            <StyledTableCell
                              padding="0"
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"Add Details"} />
                            </StyledTableCell>

                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"Add/Remove"} />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {groupItemsHotel?.map((Questions, index) => (
                          <TableBody key={index}>
                            <StyledTableCell
                              sx={{ width: "96px" }}
                              align="center"
                            >
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell sx={{ maxWidth: "920px" }}>
                              <div>
                                <div
                                  style={{ borderBottom: "1px solid #E6E6E6" }}
                                  className="formcustom fiveColumn"
                                >
                                  <div>
                                    <LabelCustom
                                      labelName={"Hotel Check In Time"}
                                      mandatory={true}
                                    />
                                    <DatePickerFormiks
                                      disabled={true}
                                      showTimeSelect
                                      name={`groupItemsHotel[${index}].HotelcheckInTime`}
                                      minDate={new Date()}
                                    />
                                  </div>
                                  <div>
                                    <LabelCustom
                                      labelName={"Hotel Check Out Time"}
                                      mandatory={true}
                                    />
                                    <DatePickerFormiks
                                      disabled={true}
                                      showTimeSelect
                                      name={`groupItemsHotel[${index}].HotelcheckOutTime`}
                                      minDate={new Date()}
                                    />
                                  </div>
                                  <div>
                                    <LabelCustom
                                      labelName={"Hotel Location"}
                                      mandatory={true}
                                    />

                                    <InputCustomFormik
                                      disabled={true}
                                      maxLength={50}
                                      name={`groupItemsHotel[${index}].Hotellocation`}
                                      type="text"
                                      placeholder={" Enter "}
                                    />
                                  </div>
                                  <div>
                                    <LabelCustom
                                      labelName={"Preferred Hotel"}
                                      mandatory={true}
                                    />

                                    <InputCustomFormik
                                      disabled={true}
                                      maxLength={50}
                                      name={`groupItemsHotel[${index}].HotelpreferHotel`}
                                      type={"text"}
                                      placeholder={" Enter"}
                                    />
                                  </div>
                                  <div>
                                    <LabelCustom labelName={"Receipt"} />
                                    <FileUploadFormik
                                      disabled={true}
                                      name={`groupItemsHotel[${index}].Receipt`}
                                    />
                                    <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                                      Size: 2 MB
                                    </span>
                                    <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                                      Format: JPG, PNG, PDF , Doc ,Docx
                                    </span>

                                  </div>
                                </div>
                                <div
                                  style={{
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                  }}
                                >
                                  {Questions?.Expenselist?.map(
                                    (item, indexW) => (
                                      <div
                                        style={{
                                          overflow: "auto",
                                          width: "100%",
                                          // height: "197px",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderRight: "1px solid #E4E3E3",
                                            borderBottom: "1px solid #E4E3E3",
                                            padding: "18px 0px",
                                            height: "221.6px ",
                                          }}
                                        >
                                          <LabelCustom
                                            labelName={"Expense Type"}
                                            mandatory={true}
                                          />
                                          <SelectForm
                                            options={expenseType}
                                            name={`groupItemsHotel[${index}].Expenselist[${indexW}].ExpenseTypes`}
                                            placeholder={"Select "}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            // width: "calc( 100% - 475px )",
                                            overflow: "auto",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                          // style={{ minWidth: "778px", maxWidth: "100%" }}
                                          >
                                            <div
                                              className="reimbursementTablein"
                                              style={{
                                                // minWidth: "1152px",
                                                padding: "18px 0px",
                                                position: "relative",
                                              }}
                                            >
                                              <ReimbursementformTypesMulti
                                                ExpenseTypesSelected={
                                                  formValues?.groupItemsHotel[
                                                    index
                                                  ]?.Expenselist[indexW]
                                                    ?.ExpenseTypes
                                                }
                                                // employeeList={employeeList}
                                                groupItems={"groupItemsHotel"}
                                                index={index}
                                                indexW={indexW}
                                                formValues={formValues}
                                              />
                                            </div>
                                            {formValues?.groupItemsHotel[index]
                                              ?.Expenselist[indexW]
                                              ?.ExpenseTypes && (
                                                <div
                                                  className="surveyAddBtnContainer"
                                                  style={{
                                                    position: "sticky",
                                                    left: "79%",
                                                    /* right: 30px; */
                                                    bottom: "21px",
                                                    width: "142px",
                                                    // position: "absolute",
                                                    // right: "12px",
                                                    // bottom: "21px",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    cursor: "default",
                                                  }}
                                                // onClick={() => pushEmployee(index)}
                                                >
                                                  <div
                                                    onClick={() =>
                                                      popAnswer(index, indexW)
                                                    }
                                                  >
                                                    <img
                                                      // onClick={() => {
                                                      //   popAnswer(answer, answerIndex, index);
                                                      // }}
                                                      style={{
                                                        marginRight: "5px",
                                                        cursor: "pointer",
                                                      }}
                                                      src={
                                                        SurveyAnswersDeleteIcon
                                                      }
                                                      alt="SurveyAnswersDeleteIcon"
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      pushEmployee(index)
                                                    }
                                                  >
                                                    <button
                                                      type="button"
                                                      className="add-survey-btn"
                                                    >
                                                      +
                                                    </button>{" "}
                                                    Add Expenses
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ width: "100px", textAlign: "center" }}
                            >
                              <button
                                type="button"
                                className="remove-btn"
                                onClick={() =>
                                  groupItemsHotel.length > 1
                                    ? remove(index)
                                    : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                                }
                              >
                                -
                              </button>
                              <button
                                type="button"
                                className="add-btn"
                                onClick={() => {
                                  insert(index + 1, {
                                    HotelcheckInTime: null,
                                    HotelcheckOutTime: null,
                                    Hotellocation: "",
                                    HotelpreferHotel: "",
                                    Receipt: null,
                                    Expenselist: [
                                      {
                                        // ExpenseTypeId: "",
                                        ExpenseTypes: "",
                                        Date: "",
                                        fromlocation: "",
                                        tolocation: "",
                                        categoryid: "",
                                        type: "",
                                        totalkm: "",
                                        cost: "",
                                        Amount: "",
                                        IsReceiptAvailable: "",
                                        Upload: "",
                                        Justification: "",
                                        Remarks: "",
                                        mode: "",
                                        totaltickets: "",
                                        totalitems: "",
                                        totaldays: "",
                                      },
                                    ],
                                  });
                                }}
                              >
                                +
                              </button>
                            </StyledTableCell>
                          </TableBody>
                        ))}
                      </Table>
                    );
                  }}
                </FieldArray>
              </div>
            )}
        </div>
        <div
          style={
            formValues?.TravalRequestId
              ? {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
              : {
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }
          }
        >
          {formValues?.TravalRequestId && (
            <div>
              <LabelCustom labelName={"Total Amount"} mandatory={true} />

              <InputCustomFormik
                disabled={true}
                type="number"
                value={
                  (Number(resultData?.total_final_budget) || 0) +
                  (Number(claimCount) || 0)
                }
                name={`amountTotal`}
                placeholder={"Enter"}
              />
            </div>
          )}
          {formValues?.TravalRequestId && (
            <div style={{ marginTop: "42px" }}> - </div>
          )}
          {formValues?.TravalRequestId && (
            <div>
              <LabelCustom labelName={"Paid by company"} mandatory={true} />

              <InputCustomFormik
                disabled={true}
                type="number"
                value={Number(resultData?.total_final_budget) || 0}
                name={`paidByCompany`}
                placeholder={"Enter"}
              />
            </div>
          )}
          {formValues?.TravalRequestId && (
            <div style={{ marginTop: "42px" }}> - </div>
          )}
          {formValues?.TravalRequestId && (
            <div>
              <LabelCustom labelName={"Advance Amount"} mandatory={true} />

              <InputCustomFormik
                disabled={true}
                type="number"
                name={`AmountAdvanceAdvance`}
                placeholder={"Enter"}
              />
            </div>
          )}
          {formValues?.TravalRequestId && (
            <div style={{ marginTop: "42px" }}> = </div>
          )}
          <div>
            <LabelCustom labelName={"Claim Amount"} mandatory={true} />

            <InputCustomFormik
              disabled={true}
              value={claimCount}
              type="number"
              name={`claimAmountFinal`}
              placeholder={"Enter"}
            />
          </div>
        </div>
      </>
    );
};

export { CreateReimbursementComp };
