import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";
import "./ShowFullAttachments.css";
import { socialPostImages } from "../../../../../Utilities/profileAndUploadImages";
import SocialPdfLeftArrow from "../../../../../Assets/SocialPdfLeftArrow.svg";
import SocialPdfRightArrow from "../../../../../Assets/SocialPdfRightArrow.svg";

function ShowFullAttachmentsCreate({
  fileType,
  isGreetings,
  fullAtachmentData,
  setShowFullAttachmentActive,
}) {
  const [isImages, setIsImages] = useState(false);
  const [isVideos, setIsVideos] = useState(false);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (fullAtachmentData) {
      if (fileType === "image") {
        setIsImages(true);
      } else if (fileType === "video") {
        setIsVideos(true);
      }
      setNumPages(fullAtachmentData?.length);
    }
  }, []);
  function nextSlide(e) {
    e.stopPropagation();
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  }
  function prevSlide(e) {
    e.stopPropagation();
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  }
  return (
    <div className="showFullAttachmentContainer">
      {isImages && (
        <Popup
          firstClassName="showFullAttachmentPopupBox1"
          secondClassName="showFullAttachmentPopupBox2"
          bodyClassName="showFullAttachmentPopupBox2Body"
          content={
            <div className="showFullAttachmentWrapper">
              <div
                className="showFullAttachmentListBox"
                
              >
                <img
                  src={fullAtachmentData[index]?.url}
                  alt=""
                  style={{
                    height: "auto",
                    maxHeight: "90%",
                    width: "auto",
                    maxWidth: "100%",
                  }}
                />
              </div>
              {fullAtachmentData?.length > 0 && (
                <>
                  <div className="leftPdfArrow">
                    {index === 0 || (
                      <img
                        src={SocialPdfLeftArrow}
                        alt=""
                        onClick={(e) => {
                          prevSlide(e);
                        }}
                      />
                    )}
                  </div>
                  <div className="rightPdfArrow">
                    {numPages - 1 === index || (
                      <img
                        src={SocialPdfRightArrow}
                        alt=""
                        onClick={(e) => {
                          nextSlide(e);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          }
          handleClose={() => {
            setShowFullAttachmentActive(false);
          }}
        />
      )}
      {isVideos && (
        <Popup
          firstClassName=" .showFullAttachmentCreatePopupBox1"
          secondClassName=" .showFullAttachmentCreatePopupBox2"
          content={
            <div className="showFullAttachmentWrapper">
              <div className="showFullAttachmentListBox">
                <video
                  src={
                    process.env.REACT_APP_.BASE_URL +
                    socialPostImages +
                    fullAtachmentData[index]
                  }
                  controls
                  width="600"
                  height="500"
                />
              </div>
              {fullAtachmentData?.length > 0 && (
                <>
                  <div className="leftPdfArrow">
                    {index === 0 || (
                      <img
                        src={SocialPdfLeftArrow}
                        alt=""
                        onClick={(e) => {
                          prevSlide(e);
                        }}
                      />
                    )}
                  </div>
                  <div className="rightPdfArrow">
                    {numPages - 1 === index || (
                      <img
                        src={SocialPdfRightArrow}
                        alt=""
                        onClick={(e) => {
                          nextSlide(e);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          }
          handleClose={() => {
            setShowFullAttachmentActive(false);
          }}
        />
      )}
    </div>
  );
}

export default ShowFullAttachmentsCreate;
