import React from "react";
import { useNavigate } from "react-router-dom";
import {
  docsArray,
  getAttachments,
  getContentLength,
  getExtension,
  getFormattedCount,
  getUserInitials,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import {
  communityProfileImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import SocialBirthdayTag from "../../../../../Assets/SocialBirthdayTag.svg";
import SocialWorkAnnyTag from "../../../../../Assets/SocialWorkAnnyTag.svg";
import SocialNewJoiningTag from "../../../../../Assets/SocialNewJoiningTag.svg";
import SocialKudosTag from "../../../../../Assets/SocialKudosTag.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";
import SocialTextLinkIcon from "../../../../../Assets/SocialTextLinkIcon.svg";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostViewsEyeColorIcon from "../../../../../Assets/SocialPostViewsEyeColorIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import dateFormat from "dateformat";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import PostActivityContainer from "./PostActivityContainer";
import ModeratorPostActivityContainer from "./ModeratorPostActivityContainer";
import ContentSocialDisplay from "./ContentSocialDisplay";
import ShowCarouselGreetingsImages from "./ShowCarouselGreetingsImages";
import ShowCarouselSocialImages from "./ShowCarouselSocialImages";
import PDFViewer from "./PDFViewer";

function UnSharedPostComponent({
  d,
  setIsSocialPostDeletePopupActive,
  setEditDeleteSocialPostId,
  setIsSharePosEditActive,
  setIsMyPosEditActive,
  setViewsPostId,
  setViewsListActive,
  setEditDeleteSocialPostData,
}) {
  const navigate = useNavigate();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [showContent, setShowContent] = useState(false);
  const [viewsHovered, setIsViewsHovered] = useState(false);
  const [canManageSocial, setCanManageSocial] = useState(
    LoggedInUser?.permissions?.includes("Can Manage Social?")
  );
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        className={`postHeaderContainer ${
          d?.postType !== "organisation" ? "team-community-Header" : ""
        }`}
      >
        {d?.postType === "organisation" && (
          <div
            className="leftPostHeaderContainer"
            onClick={(e) => {
              e.stopPropagation();
              handleUserProfileClick(d?.employeeId);
            }}
          >
            <div
              className={`${
                d?.image
                  ? "socialProfilePicContainer"
                  : "socialCustomProfilePic"
              }`}
            >
              {!d?.image ? (
                getUserInitials(d?.name)
              ) : (
                <img
                  className="socialProfilePic"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserProfileClick(d?.employeeId);
                  }}
                  src={
                    process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = ProfileAvatar;
                  }}
                />
              )}
            </div>

            <div className="postMainTextContainer">
              <label className="postMainName">
                {getDisplayName(d?.nameWithPersonNumber)}
              </label>
              <label>{d?.roleName}</label>
              <label className="dateAndViewsContainer">
                {d?.locationName}
                <span className="postHrTime">
                  &nbsp; • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                </span>
                <article
                  onClick={(e) => {
                    e.stopPropagation();
                    setViewsListActive(true);
                    setViewsPostId(d?.id);
                  }}
                  onMouseOver={() => setIsViewsHovered(d?.id)}
                  onMouseLeave={() => setIsViewsHovered(null)}
                >
                  <img
                    src={
                      viewsHovered === d?.id
                        ? SocialPostViewsEyeColorIcon
                        : SocialPostViewsEyeIcon
                    }
                    alt=""
                    className="socialViewsImgContainer"
                  />
                  <span
                    className={
                      viewsHovered === d?.id
                        ? "postColorViews"
                        : `socialViewsSpanContainer`
                    }
                  >
                    {d?.views < 10
                      ? "0" + d?.views
                      : getFormattedCount(d?.views)}{" "}
                    Views
                  </span>
                </article>
              </label>
            </div>
          </div>
        )}
        {d?.postType === "community" && (
          <div
            className="leftPostHeaderContainer"
            style={{ position: "relative" }}
          >
            <div
              onClick={(e) => {
                navigate(`/home/social/community/${d?.community?.id}`);
              }}
              className={`${
                d?.community?.communityImage
                  ? "socialConnectProfilePicContainer"
                  : "nestedCommunityCustomProfilePic"
              }`}
            >
              {!d?.community?.communityImage && d?.community?.communityName ? (
                getUserInitials(d?.community?.communityName)
              ) : (
                <img
                  className="socialProfilePic"
                  src={
                    process.env.REACT_APP_.BASE_URL +
                    communityProfileImages +
                    d?.community?.communityImage
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = SocialCommunityGroupIcon;
                  }}
                />
              )}
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();
                handleUserProfileClick(d?.employeeId);
              }}
              className={`${
                d?.image
                  ? "socialProfilePicContainer"
                  : "socialCustomProfilePicCommunity"
              }`}
            >
              {!d?.image ? (
                getUserInitials(d?.name)
              ) : (
                <img
                  className={`socialProfilePic ${
                    d?.postType !== "organisation" ? "prTeamCommunity" : ""
                  }`}
                  src={
                    process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = ProfileAvatar;
                  }}
                />
              )}
            </div>

            <div
              className="postMainTextContainer"
              style={{ marginLeft: "12px" }}
              onClick={() => {
                navigate(`/home/social/community/${d?.community?.id}`);
              }}
            >
              <label className="postMainName">
                {d?.community?.communityName}
              </label>
              <label className="dateAndViewsContainer">
                Post from{" "}
                <span className="nameofposter">
                  &nbsp;
                  {getDisplayName(d?.nameWithPersonNumber)}&nbsp;
                </span>
                <span className="postHrTime">
                  • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                </span>
                <article
                  onClick={(e) => {
                    e.stopPropagation();
                    setViewsListActive(true);
                    setViewsPostId(d?.id);
                  }}
                  onMouseOver={() => setIsViewsHovered(d?.id)}
                  onMouseLeave={() => setIsViewsHovered(null)}
                >
                  <img
                    src={
                      viewsHovered === d?.id
                        ? SocialPostViewsEyeColorIcon
                        : SocialPostViewsEyeIcon
                    }
                    alt=""
                    className="socialViewsImgContainer"
                  />
                  <span
                    className={
                      viewsHovered === d?.id
                        ? "postColorViews"
                        : `socialViewsSpanContainer`
                    }
                  >
                    {d?.views < 10
                      ? "0" + d?.views
                      : getFormattedCount(d?.views)}{" "}
                    Views
                  </span>
                </article>
              </label>
            </div>
          </div>
        )}
        {d?.postType === "team" && (
          <div
            className="leftPostHeaderContainer"
            style={{ position: "relative" }}
          >
            <img
              className="socialProfilePic"
              src={SocialPostTeamIcon}
              alt=""
              style={{
                marginBottom: "7px",
                // marginRight:"8px"
              }}
            />
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleUserProfileClick(d?.employeeId);
              }}
              className={`${
                d?.image
                  ? "socialProfilePicContainer"
                  : "socialCustomProfilePicCommunity"
              }`}
            >
              {!d?.image ? (
                getUserInitials(d?.name)
              ) : (
                <img
                  className={`socialProfilePic ${
                    d?.postType !== "organisation" ? "prTeamCommunity" : ""
                  }`}
                  src={
                    process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = ProfileAvatar;
                  }}
                />
              )}
            </div>

            <div className="postMainTextContainer">
              <label className="postMainName">Team</label>
              <label className="dateAndViewsContainer">
                Post from &nbsp;
                <span className="nameofposter">
                  {getDisplayName(d?.nameWithPersonNumber)}
                </span>
                <span className="postHrTime">
                  &nbsp; • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                </span>
                <article
                  onClick={(e) => {
                    e.stopPropagation();
                    setViewsListActive(true);
                    setViewsPostId(d?.id);
                  }}
                  onMouseOver={() => setIsViewsHovered(d?.id)}
                  onMouseLeave={() => setIsViewsHovered(null)}
                >
                  <img
                    src={
                      viewsHovered === d?.id
                        ? SocialPostViewsEyeColorIcon
                        : SocialPostViewsEyeIcon
                    }
                    alt=""
                    className="socialViewsImgContainer"
                  />
                  <span
                    className={
                      viewsHovered === d?.id
                        ? "postColorViews"
                        : `socialViewsSpanContainer`
                    }
                  >
                    {d?.views < 10
                      ? "0" + d?.views
                      : getFormattedCount(d?.views)}{" "}
                    Views
                  </span>
                </article>
              </label>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {d?.subType === "kudos" && (
            <div className="kudosTypeContainer">
              <span>Kudos</span>
              <article></article>
              <img src={SocialKudosTag} alt="" />
            </div>
          )}
          {d?.subType === "birthday" && (
            <div className="birthdayTypeContainer">
              <span>Birthday</span>
              <article></article>
              <img src={SocialBirthdayTag} alt="" />
            </div>
          )}
          {d?.subType === "workanniversary" && (
            <div className="workAnnyTypeContainer">
              <span>Work Anniversary</span>
              <article></article>
              <img src={SocialWorkAnnyTag} alt="" />
            </div>
          )}
          {d?.subType === "welcome" && (
            <div className="newJoiningTypeContainer">
              <span>New Joining</span>
              <article></article>
              <img src={SocialNewJoiningTag} alt="" />
            </div>
          )}
          {d?.sharedEmployee === null &&
            d?.nameWithPersonNumber === LoggedInUser.nameWithPersonNumber && (
              <IconButton>
                <PostActivityContainer
                  setIsSocialPostDeletePopupActive={
                    setIsSocialPostDeletePopupActive
                  }
                  setIsSharePosEditActive={setIsSharePosEditActive}
                  setIsMyPosEditActive={setIsMyPosEditActive}
                  isMyPosEditActive={true}
                  isSharePosEditActive={false}
                  setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                  editDeleteSocialPostId={d?.id}
                  editDeleteSocialPostData={d}
                  setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                />
              </IconButton>
            )}
          {d?.sharedEmployee === null &&
            d?.nameWithPersonNumber !== LoggedInUser.nameWithPersonNumber &&
            canManageSocial && (
              <div>
                <IconButton>
                  <ModeratorPostActivityContainer
                    setIsSocialPostDeletePopupActive={
                      setIsSocialPostDeletePopupActive
                    }
                    setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                    setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                    editDeleteSocialPostId={d?.id}
                    editDeleteSocialPostData={d}
                  />
                </IconButton>
              </div>
            )}
        </div>
      </div>
      {d?.subType !== null && (
        <>
          <div className="postBodyTextContainer">
            {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
              <ContentSocialDisplay content={getContentLength(d?.content)} />
            ) : (
              <ContentSocialDisplay content={d?.content?.trim()} />
            )}
            {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
              <span
                onClick={() => {
                  setShowContent(true);
                }}
              >
                ...View More
              </span>
            )}
          </div>
          <div
            className="postBodyContainer"
            // onClick={() => {
            //   navigate("details/" + d.id);
            // }}
          >
            <div
              className="newPostBodyImgMainWrapper"
              onClick={() => {
                navigate("details/" + d.id);
              }}
            >
              <div className="newPostBodyImgContainer ">
                {getExtension(getAttachments(d?.attachment)[0]) !== "gif" ? (
                  <ShowCarouselGreetingsImages
                    photo={getAttachments(d?.attachment) || []}
                    showModal={() => {
                      navigate("details/" + d.id);
                    }}
                  />
                ) : (
                  <ShowCarouselSocialImages
                    photo={getAttachments(d?.attachment) || []}
                    showModal={() => {
                      navigate("details/" + d.id);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {d?.subType === null && (
        <>
          <div className="postBodyTextContainer">
            {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
              <ContentSocialDisplay content={getContentLength(d?.content)} />
            ) : (
              <ContentSocialDisplay content={d?.content?.trim()} />
            )}
            {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
              <span
                onClick={() => {
                  setShowContent(true);
                }}
              >
                ...View More
              </span>
            )}
          </div>
          <div
            className="postBodyContainer"
            // onClick={() => {
            //   navigate("details/" + d.id);
            // }}
          >
            {d?.attachment?.length > 0 &&
              imgArray.includes(
                getExtension(
                  getAttachments(d?.attachment)
                    ? getAttachments(d?.attachment)[0]
                    : ""
                )
              ) && (
                <div
                  className={` ${
                    d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
                  }`}
                >
                  <div
                    className={"newPostBodyImgMainWrapper socialCarouselStylr"}
                    style={{ margin: "0" }}
                  >
                    <ShowCarouselSocialImages
                      photo={getAttachments(d?.attachment) || []}
                      showModal={() => {
                        navigate("details/" + d.id);
                      }}
                    />
                  </div>
                </div>
              )}
            {d?.attachment?.length > 0 &&
              videoArray.includes(
                getExtension(
                  getAttachments(d?.attachment)
                    ? getAttachments(d?.attachment)[0]
                    : ""
                )
              ) && (
                <div
                  className={` ${
                    d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
                  }`}
                  onClick={() => {
                    navigate("details/" + d.id);
                  }}
                >
                  <div
                    className={`${
                      d?.sharedEmployee !== null
                        ? "sharePostBodyImgMainWrapper"
                        : "newPostBodyImgMainWrapper"
                    }`}
                    style={{ margin: "0" }}
                  >
                    <div
                      className={` ${
                        d?.sharedEmployee !== null
                          ? "shareNewPostBodyImgContainer"
                          : "newPostBodyImgContainer"
                      }`}
                    >
                      <video
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          socialPostImages +
                          getAttachments(d?.attachment)[0]
                        }
                        controls
                        // width="630"
                        // height="335"
                      />
                    </div>
                  </div>
                </div>
              )}
            {d?.attachment?.length > 0 &&
              docsArray.includes(
                getExtension(
                  getAttachments(d?.attachment)
                    ? getAttachments(d?.attachment)[0]
                    : ""
                ).toLowerCase()
              ) && (
                <div
                  className={` ${
                    d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
                  }`}
                >
                  {getAttachments(d?.attachment)?.length === 1 && (
                    <div
                      className="newPostBodyImgContainer"
                      onClick={() => {
                        navigate("details/" + d?.id);
                      }}
                    >
                      <PDFViewer
                        pdfUrl={
                          process.env.REACT_APP_.BASE_URL +
                          socialPostImages +
                          getAttachments(d?.attachment)[0]
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            {d?.news?.link && (
              <div className="newsLinkContainer">
                <div className="newsLinkWrapper">
                  <div className="newsLink">
                    <img src={SocialTextLinkIcon} alt="" />{" "}
                    <a href={d?.news?.link} target="_blank" rel="noreferrer">
                      <span>{d?.news?.link}</span>
                    </a>
                  </div>
                  <img
                    style={{ width: "29px" }}
                    src={SocialNewsEndLinkIcon}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default UnSharedPostComponent;
