import React, {  } from 'react';
import "./Change.css";
import { ChangeTypes } from '../ChangeComponent/ChangeTypes';
import { useEffect } from 'react';

const Change = () => {
    useEffect(() => {
        document.title = `PeopleSol - Change`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">

                    <ChangeTypes title={"Change Types"}/>
              
        </div>
    )
}

export { Change }