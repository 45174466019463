import React, { useState, useEffect, useRef } from "react";
import "./Notifications.css";
import NotificationsClearIcon from "../../Assets/NotificationsClearIcon.svg";
import NotificationsItem from "./Components/NotificationsItem";
import { useDispatch } from "react-redux";
import { closeNoti } from "../../Features/NotiSlice";
import { isLoading, showToast } from "../../Features";
import { getRequest, postRequest } from "../../Services/axios";
import { useSelector } from "react-redux";
import { Loader } from "../../Components/FormComponent/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";

const Notifications = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notiData, setNotiData] = useState([]);
  const [presentNotiData, setPresentNotiData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(false);
  const { noti } = useSelector((state) => state.noti);
  const location = useLocation();
  let currentUrl = location?.pathname;
  const getNotifications = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Settings/Notifications?page=${page}`);
      setCount(result?.data?.data?.count);
      setNotiData((prevData) => [
        ...prevData,
        ...result?.data?.data?._Notifications,
      ]);
      setPresentNotiData(result?.data?.data?._Notifications);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   document.title = `PeopleSol - Notifications`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // }, []);
  useEffect(() => {
    if (noti) {
      setPage(1);
      setNotiData([]);
      getNotifications();
    }
  }, [noti, currentUrl]);

  const clearAllNoti = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(`Settings/NotificationMarkAllasread`);
      if (result) getNotifications();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setCount(0);
      setNotiData([]);
      setPage(1);
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const closeNotifications = () => {
    setPage(1);
    return dispatch(closeNoti());
  };
  return (
    <div className="side-navigation">
      <div className="notificationsMainContainer">
        {/* {loading ? (
          <Loader />
        ) : ( */}
        <>
          <div className="notifications-title">
            <label>{`My Notifications (${notiData?.length})`}</label>
            <img
              onClick={clearAllNoti}
              src={NotificationsClearIcon}
              alt="NotificationsClearIcon"
              className="notificationClearIcon cursor"
            />
          </div>
          <div className="notifications-content">
            <InfiniteScroll
              dataLength={notiData?.length}
              next={getNotifications}
              hasMore={presentNotiData?.length < 20 ? false : true}
              loader={<h4> Loading...</h4>}
              height={800}
              width={200}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {notiData.map((item) => {
                return (
                  <NotificationsItem
                    type={item.type}
                    key={item.id}
                    Id={item.id}
                    closeNotifications={closeNotifications}
                    heading={item?.module}
                    rId={item?.requestId}
                    imagePath={item?.profileImage}
                    redirectionUrl={item?.redirectionUrl}
                    content={item.text}
                    datetime={`On ${item.createdOn
                      ?.toString()
                      .replace("T", " ")}`}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        </>
        {/* )} */}
      </div>
      <div className="sidebarBackground" onClick={closeNotifications} />
    </div>
  );
};

export { Notifications };
