import React, { useState, useEffect } from "react";
import PayrollmydashboardPic from "../../Assets/PayrollmydashboardPic.svg";
import PayrollcompanydashboardPic from "../../Assets/PayrollcompanydashboardPic.svg";
import PayrollpreparepayrollPic from "../../Assets/PayrollpreparepayrollPic.svg";
import PayrollpayrollapprovalPic from "../../Assets/PayrollpayrollapprovalPic.svg";
import PayrollreportskpiPic from "../../Assets/PayrollreportskpiPic.svg";
import { useLocation, useNavigate } from "react-router-dom";
import PayrollIcon from "../../Assets/PayrollIcon.svg";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { MyDashboard } from "./Components/MyDashboard";
import { TaxDeclaration } from "./Components/TaxDeclaration";
import "./Tax.css";
import { TaxSubmission } from "./Components/TaxSubmission";
import { CompanyDashboard } from "./Components/CompanyDashboard";
import { TaxDeclarations } from "./Components/TaxDeclarations";
import { DetailsAllDeclaration } from "./Components/DetailsAllDeclaration";
import { TaxDeclarationAll } from "./Components/TaxDeclarationAll";
import { DetailsPageDeclaredAndSubmitted } from "./Components/DetailsPageDeclaredAndSubmitted";

const Tax = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);

  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "tax"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("tax")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "tax");
      if (splitArray?.length > submoduleIndex + 1) {
        if (submoduleMenu) {
          if (
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              submoduleMenu
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
          setCreateActive(false);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setDetailsActive(false);
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
          setCreateActive(false);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setDetailsActive(false);
          setCreateActive(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);
  const [activeSubmodule, setActiveSubmodule] = useState("decalaration");
  const [activeFeature, setActiveFeature] = useState("tax");
  const [detailsActive, setDetailsActive] = useState(false);
  const [createActive, setCreateActive] = useState(false);
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "mydashboard") return PayrollmydashboardPic;
    else if (subMenuName === "companydashboard")
      return PayrollcompanydashboardPic;
    else if (subMenuName === "alltaxes") return PayrollpreparepayrollPic;
    else if (subMenuName === "declaration") return PayrollpreparepayrollPic;
    else if (subMenuName === "submission") return PayrollpayrollapprovalPic;
    else if (subMenuName === "reportkpi") return PayrollreportskpiPic;
  };
  return (
    <div className="moduleContainer">
      {activeSubmodule === "alltaxes" && (
        <>
          {activeFeature === "tax" && (
            <>
              {detailsActive && <DetailsAllDeclaration />}
              {createActive && <DetailsPageDeclaredAndSubmitted />}
            </>
          )}
          {/* {activeFeature === "summary" && (
          )} */}
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${
                      activeSubmodule ===
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase() && "menuOptionsActive"
                    }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/tax/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>

      <div className="homeEngageMainContainer">
        {activeSubmodule === "mydashboard" ? <MyDashboard /> : null}
        {activeSubmodule === "companydashboard" ? <CompanyDashboard /> : null}
        {activeSubmodule === "declaration" ? <TaxDeclaration /> : null}
        {activeSubmodule === "alltaxes" ? <TaxDeclarations /> : null}
        {activeSubmodule === "submission" ? <TaxSubmission /> : null}
      </div>
    </div>
  );
};

export { Tax };
