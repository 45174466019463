import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../../Features";
import { postRequestForm } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { ItDeskTicketForm } from "./Form/ItDeskTicketForm";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const CreateItDeskTicket = (props) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const location = useLocation();
  const selectedId = location?.state?.selectedId;
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const initialvalue = {
    TypeId: "",
    RequestCategoryId: "",
    RequestTypeId: "",
    AreaId: LoggedInUser?.locationID,
    Description: "",
    priority: "",
    fileUpload: "",
  };
  const validationSchema = Yup.object({
    TypeId: Yup.string().required("Please select Service Type"),
    RequestTypeId: Yup.string().required(" Please select Request Type"),
    AreaId: Yup.string().required(" Please select Location"),
    priority: Yup.string().required(" Please select Priority"),
    Description: Yup.string()
      .max(499, "Must be 500 characters or less")
      .required("Please Enter Description"),
  });
  const postFormData = async (values) => {
    try {
      setLoading(true);
      if (selectedId)
        await postRequestForm(
          `ithelpdesks/Create?changeRequestID=${selectedId}`,
          values
        );
      else await postRequestForm("ithelpdesks/Create", values);

      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({ text: error.response.data.message, severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create IT Desk Ticket`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("TypeId", values?.TypeId);
    formData.append("RequestCategoryId", values?.RequestCategoryId);
    formData.append("RequestTypeId", values?.RequestTypeId);
    formData.append("AreaId", values?.AreaId);
    formData.append("Description", values?.Description);
    formData.append("Priority", values?.priority);
    formData.append("fileUpload", values?.fileUpload);
    if (selectedId) formData.append("changeRequestID", selectedId);
    postFormData(formData);
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      ButtonLabel={"Raise IT Desk Ticket"}
      FormHeading={"Raise IT Desk Ticket"}
      chooseFormValues={chooseFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<ItDeskTicketForm formValues={formValues} />}
    />
  );
};

export { CreateItDeskTicket };
