import { Form, Formik } from "formik";

import * as Yup from "yup";
import { useEffect } from "react";
import { Loader } from "@giphy/react-components";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
const SUPPORTED_FORMATS = [
  "text/csv", // CSV file format
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel file format (XLSX)
];

export const UploadPop = ({ setLoading, id, type, handleClose, loading }) => {
  const dispatch = useDispatch();
  const uploadImportFile = async (values) => {
    try {
      setLoading(true);
      let importPayload = {
        template: values.upload,
      };
      let result;
      if (type === "Salary Revise Import") {
        result = await postRequestForm(
          "PayrollEmployee/ImportSalaryRevision",
          importPayload
        );
      } else {
        result = await postRequestForm(
          "EmployeeSalaryDetails/ImportSalaryTemplate",
          importPayload
        );
      }

      dispatch(
        showToast({
          text: result?.data?.message ?? "Success",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose()
      // getEmployeeList(detailsform);
    }
  };
  const downloadtemplate = async () => {
    try {
      let result;
      if (type === "Salary Revise Import") {
        result = await getRequest(
          "PayrollEmployee/DownloadSalaryRevisionTemplate"
        );
      } else {
        result = await getRequest(
          "EmployeeSalaryDetails/DownloadTemplate"
        );
      }

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${"employee"} Template`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const validationSchema = Yup.object({
    upload: Yup.mixed()
      .required("Please Upload Hike Letter")
      .test(
        "Fichier taille",
        "File Size must be less than 2 mb",
        (value) =>
          !value ||
          (value && value.size <= 2 * 2048 * 2048) ||
          typeof value !== "object"
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          (value && SUPPORTED_FORMATS.includes(value.type)) ||
          typeof value !== "object"
      ),
  });
  const onSubmit = async (values) => {
    console.log("values", values);
    uploadImportFile(values);
  };

  // useEffect(()=>{
  //   document.title = `PeopleSol - Remark`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // })
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{ upload: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <LabelCustom labelName={"Upload File"} mandatory={true} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FileUploadFormik
                  maxLength={500}
                  name="upload"
                  style={{ width: "75%" }}
                />
                <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                  Size: 2 MB
                </span>
                <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                  Format: JPG, PNG, PDF , Doc ,Docx
                </span>

                <Tooltip title="Download Template" placement="top" arrow>
                  <button onClick={downloadtemplate} style={{ border: "none" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <rect width="20" height="20" rx="4" fill="#D8F0F2" />
                      <path
                        d="M10.5957 4.25366C10.5957 3.97752 10.3718 3.75366 10.0957 3.75366C9.81956 3.75366 9.5957 3.97752 9.5957 4.25366L10.5957 4.25366ZM9.5957 4.25366L9.5957 12.9648L10.5957 12.9648L10.5957 4.25366L9.5957 4.25366Z"
                        fill="#1296B0"
                      />
                      <path
                        d="M5.75037 9.07996L10.0956 13.4252L14.4408 9.07996"
                        stroke="#1296B0"
                        stroke-linecap="round"
                      />
                      <path
                        d="M5.49487 15.7465H14.5053"
                        stroke="#1296B0"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>
                </Tooltip>
              </div>
              <div className="createFootCust">
                <button
                  type="button"
                  className="button secondaryButton"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="button primaryButton"
                  type="submit"
                  style={{ background: "var(--primary)", color: "white" }}
                >
                  Create
                </button>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};
