import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateVehicleComp } from "../FormComp/CreateVehicleComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CreateVehicle = (props) => {
  const { state } = useLocation();

  const data = state?.dataEdit;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `PeopleSol - Create Vehicle`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Vehicles/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("Vehicles/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "success",
        })
      );
    }
  };

  const initialvalue = {
    title: "",
    vehicleNo: "",
    type: "",
    description: null,
  };
  const validationSchema = Yup.object({
    title: Yup.string()
      .max(149, "Title must be less than 150 characters")
      .required("Please Enter Title "),
    vehicleNo: Yup.string()
      .matches(/^[A-Z|a-z]{2}\s?[0-9]{1,2}\s?[A-Z|a-z]{0,3}\s?[0-9]{4}$/, {
        message: "Please Enter correct vehicle no.",
      })
      .required("Please Enter Vehicle no."),
    type: Yup.string()
      .max(149, "Type must be less than 150 characters")
      .required("Please Enter type"),
    description: Yup.string()
      .max(499, "Type must be less than 500 characters")
      .nullable(),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data?.Id,
      isActive: true,
    };

    const create = {
      ...values,
      id: 0,
      isActive: true,
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  return (
    <FormCustomFormik
      FormHeading={data ? "Edit Vehicles" : "Create Vehicles"}
      ButtonLabel={data ? "Update Vehicles" : "Create Vehicles"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              title: data?.Title,
              vehicleNo: data?.VehicleNo,
              type: data?.Type,
              description: data?.Description,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateVehicleComp />}
    />
  );
};

export { CreateVehicle };
