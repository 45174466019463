import React, { useState, useEffect } from "react";
import { FieldArray, Formik } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";

const opt = [{ label: "select", value: null }];

const guestOptions = [
  { label: "Normal", value: "Normal" },
  { label: "Special", value: "Special" },
  { label: "VIP", value: "VIP" },
];
const foodOptions = [
  { label: "Veg", value: "Veg" },
  { label: "Non-Veg", value: "Non-Veg" },
];

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];
export const CreateCompanyHospitalityComp = ({ data, edit, formValues }) => {
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [hotels, setHotels] = useState([]);

  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployees");
      let listData = [];
      result.data.results.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Company Hospitality`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getHotels = async () => {
    try {
      const result = await getRequest("Hotels/GetHotels");
      // console.log("resultGetHotels", result);
      let listData = [];
      result?.data?.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });

      setHotels(listData);
    } catch {
    } finally {
    }
  };
  useEffect(() => {
    getHotels();
    getEmployeeData();
  }, []);
  useEffect(() => {
    if (formValues?.checkinDateandTime && formValues?.checkOutDateandTime) {
      const checkinDate = new Date(formValues?.checkinDateandTime);
      const checkoutDate = new Date(formValues?.checkOutDateandTime);
      const differenceInMs = checkoutDate - checkinDate;
      const differenceInDays = Math.ceil(
        differenceInMs / (1000 * 60 * 60 * 24)
      );

      //   Formik.setFieldValue("noofDays", differenceInDays);
      formValues.noofDays = differenceInDays;
    }
  }, [formValues?.checkOutDateandTime, formValues?.checkinDateandTime]);

  return (
    <>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Guest of"} mandatory={true} />
          <SelectForm name="guestof" options={employeeList} />
        </div>
        <div>
          <LabelCustom labelName={"Guest Type"} mandatory={true} />
          <SelectForm name="guestType" options={guestOptions} />
        </div>
        <div>
          <LabelCustom labelName={"Purpose"} mandatory={true} />
          <InputCustomFormik
            maxLength={500}
            name="purpose"
            type={"text"}
            placeholder={"Please Enter Purpose"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Preferred Hotel"} mandatory={true} />
          <SelectForm name="hotelId" options={hotels} />
        </div>
        <div>
          <LabelCustom labelName={"Check-In Date and Time"} mandatory={true} />
          <DatePickerFormiks
            name="checkinDateandTime"
            minDate={new Date()}
            showTimeSelect
          />
        </div>
        <div>
          <LabelCustom labelName={"Check-Out Date and Time"} mandatory={true} />
          <DatePickerFormiks
            minDate={new Date()}
            name="checkOutDateandTime"
            showTimeSelect
            onFocus={
              formValues?.checkinDateandTime === ""
                ? () =>
                    dispatch(
                      showToast({
                        text: "Please select Check-In Date and Time",
                        severity: "error",
                      })
                    )
                : () => {}
            }
          />
        </div>
        <div>
          <LabelCustom labelName={"No of Days"} mandatory={true} />
          <InputCustomFormik
            name="noofDays"
            type={"number"}
            disabled={true}
            onFocus={
              formValues?.checkOutDateandTime === "" &&
              formValues?.checkinDateandTime === ""
                ? () =>
                    dispatch(
                      showToast({
                        text: "Please select Check-In and Check-Out Date and Time",
                        severity: "error",
                      })
                    )
                : () => {}
            }
          />
        </div>
      </div>
      <div className="gridItemVisitor" style={{ width: "100%" }}>
        <div className="repeatedSectionContainer">
          <label className="repeatedHeading">
            Please specify the guest's details
          </label>
          <FieldArray name="Items">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values, errors } = form;
              const { Items } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Full Name "}
                            mandatory={true}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Phone Number "}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Email"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Designation"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Remarks"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={" Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {Items?.map((Requestlist, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={150}
                            type="text"
                            name={`Items[${index}].name`}
                            placeholder={"Please enter Name"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            type="text"
                            maxLength={10}
                            name={`Items[${index}].phoneNumber`}
                            placeholder={"Please enter Phone Number"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            type="email"
                            name={`Items[${index}].emailId`}
                            placeholder={"Please enter emailId"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={150}
                            type="text"
                            name={`Items[${index}].designation`}
                            placeholder={"Please enter Designation"}
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <TextAreaFormik
                            maxLength={1000}
                            name={`Items[${index}].remarks`}
                            placeholder={"Please Enter Remarks"}
                          />
                        </StyledTableCell>
                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              Items.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                name: "",
                                phoneNumber: "",
                                emailId: "",
                                designation: "",
                                remarks: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      </div>
    </>
  );
};
