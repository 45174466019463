import { IconButton } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SalaryMapEmployee from "./SalaryMapEmployee";
import RevisionForm from "./RevisionForm";

export const ReviseSalary = () => {
  const navigate = useNavigate();
  let detailUrl = "preparepayroll/ongoing/prepare";
  const { state } = useLocation();
  const detailsForm = state?.id;
  const employee = state?.employee;

  if (detailsForm) return (
    <div className="moduleMainContainer">
      <div
        className="tableHeaderContainer"
        style={{
          padding: "0 12px",
          boxShadow: "0px 1px 6px rgb(0 0 0 / 5%)",
          marginBottom: "6px",
        }}
      >
        <IconButton
          onClick={
            detailUrl
              ? () => {
                navigate(-1, {
                  state: { id: detailsForm[0]?.payrollEmployeeId },
                });

              }
              : () => { }
          }
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.368615 3.35738C0.173352 3.55265 0.173352 3.86923 0.368615 4.06449L3.5506 7.24647C3.74586 7.44173 4.06244 7.44173 4.2577 7.24647C4.45296 7.05121 4.45296 6.73463 4.2577 6.53936L1.42927 3.71094L4.2577 0.88251C4.45296 0.687248 4.45296 0.370666 4.2577 0.175404C4.06244 -0.0198586 3.74586 -0.0198586 3.5506 0.175404L0.368615 3.35738ZM18.749 4.21094C19.0252 4.21094 19.249 3.98708 19.249 3.71094C19.249 3.4348 19.0252 3.21094 18.749 3.21094V4.21094ZM0.722168 4.21094H18.749V3.21094H0.722168V4.21094Z"
              fill="#1296B0"
            />
          </svg>
        </IconButton>
        <h5>{employee ?? ''}</h5>
      </div>
      <div className="preparePayrollMainContainer" style={{ background: 'var(--form-background)' }}>
        <div className="overviewContainer" style={{ background: 'none' }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <p>Annual CTC</p>
              <h3 style={{ color: "#B69FB3" }}>{` ${detailsForm?.length && detailsForm?.reduce(
                (sum, card) => sum + card?.annualAmount,
                0
              )
                } per year`}</h3>
            </div>
            <div style={{ flex: 1.04 }}>
              <p>Monthly CTC</p>
              <h3 style={{ color: "#5BC4BF" }}>{`${detailsForm?.length && detailsForm?.reduce(
                (sum, card) => sum + card?.monthlyAmount,
                0
              )
                } per month`}</h3>
            </div>
          </div>
        </div>
        <div className="horizontalLine"></div>
        <RevisionForm templateId={detailsForm[0]?.templateId} eId={detailsForm[0]?.payrollEmployeeId} detailsForm={detailsForm} />
      </div>
    </div>
  );
  else return <></>
};
