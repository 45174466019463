import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Notifications } from "../../DetailsComp/Notifications";
import { Details } from "../../DetailsComp/Details";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";

import Nodata from "../../../../../Assets/Nodata.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { TimeLineData } from "../../TransferComponents/DetailsComp/Timeline/TimeLineData";
import UploadDetail from "../../../../../Components/UploadDetail/UploadDetail";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { format } from "date-fns";

const MyDetailsResignation = ({ data, loadings }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - My Details Resignation`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="tabularMasterDetailsPageBody" style={{ maxHeight: "100%" }}>
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            style={{ minWidth: 130 }}
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Details
              </span>
            }
          />
          <Tab
            style={{ minWidth: 130 }}
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Timeline
              </span>
            }
          />
          <Tab
            style={{ minWidth: 130 }}
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Notifications
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {data && (
        <>
          {tabIndex === 0 &&
            (data != null ? (
              loadings ? (
                <div className="noDataFound">
                  <Loader />
                </div>
              ) : (
                <div
                  className="detailsEReq"
                  style={{ flexDirection: "column" }}
                >
                  <div className="headingForm">Request Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Request Number"}
                      Details={data?.request?.requestNumber}
                    />
                    <Details
                      detailTitle={"Status"}
                      Details={
                        <div
                          style={{
                            backgroundColor: getStatusColor(
                              data?.status?.replace(/<[^>]+>/g, "")
                            )?.split(",")?.[0],
                            color: getStatusColor(
                              data?.status?.replace(/<[^>]+>/g, "")
                            )?.split(",")?.[1],
                            cursor: "default",
                            padding: "6px",
                            borderRadius: "6px",
                          }}
                        >
                          {data?.status?.replace(/<[^>]+>/g, "")}
                        </div>
                      }
                    />
                    <Details
                      detailTitle={"Resigned On"}
                      Details={format(
                        new Date(data?.request?.createdOn),
                        "dd/MM/yyyy hh:mm a"
                      )}
                    />
                    <Details
                      detailTitle={"Resignation Reason"}
                      Details={data?.request?.reason ?? "-"}
                    />
                    {/* <Details
                      detailTitle={"Approved Date of Relieving"}
                      Details={data?.request?.dateOfRelieving?.replace(
                        "T",
                        " "
                      )}
                    /> */}

                    <Details
                      detailTitle={" Resignation Letter"}
                      Details={
                        data?.request?.attachement == null ? (
                          "-"
                        ) : (
                          <UploadDetail
                            filepath={`ResignationLetters/${data?.request?.attachement}`}
                          />
                        )
                      }
                    />
                    <Details
                      detailTitle={"Date of Relieving"}
                      Details={format(
                        new Date(data?.request?.dateOfRelieving),
                        "dd/MM/yyyy hh:mm a"
                      )}
                    />
                    <Details
                      detailTitle={"Seperation Type"}
                      Details={data?.request?.exitType}
                    />
                    <Details
                      detailTitle={"Last Updated On"}
                      Details={format(
                        new Date(data?.request?.lastUpdatedOn),
                        "dd/MM/yyyy hh:mm a"
                      )}
                    />
                  </div>
                  <div className="headingForm">Requester Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Requester Name"}
                      Details={`${data?.request?.employee?.name} ${data?.request?.employee?.lastName}`}
                    />
                    <Details
                      detailTitle={"Requester's Manager"}
                      Details={data?.request?.employee?.rO_Name}
                    />
                    <Details
                      detailTitle={"Requester Designation"}
                      Details={data?.request?.employee?.designation}
                    />
                    <Details
                      detailTitle={"Requester Department"}
                      Details={data?.request?.employee?.department}
                    />
                    <Details
                      detailTitle={"Requester Email"}
                      Details={data?.request?.employee?.email}
                    />
                    <Details
                      detailTitle={"Contact Number"}
                      Details={
                        data?.request?.employee?.personalPhoneNumber
                          ? data?.request?.employee?.personalPhoneNumber
                          : "-"
                      }
                    />

                    <Details
                      detailTitle={"Location"}
                      Details={data?.request?.employee?.locationName}
                    />
                  </div>
                </div>
              )
            ) : (
              <div className="noDatastyle">
                <img
                  src={Nodata}
                  alt="No data"
                  style={{ height: "150px", width: "250px" }}
                />
                <h4>{"No Data Found"}</h4>
              </div>
            ))}
          {tabIndex === 1 && (
            <>
              <div className="tabularMasterDetailsPageTabBodyContainer">
                <div style={{ display: "flex", width: "100%" }}>
                  <TimeLineData
                    url={"Resignations"}
                    id={data?.request?.id}
                    module={data?.module}
                    requestNumber={data?.request?.requestNumber}
                  />
                </div>
              </div>
            </>
          )}

          {tabIndex === 2 && (
            <>
              <div
                className="tabularMasterDetailsPageTabBodyContainer"
                style={{ flexGrow: "initial" }}
              >
                <Notifications id={data?.request?.id} module={"Exit Request"} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export { MyDetailsResignation };
