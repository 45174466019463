import React from "react";
import SurveyAndPollCommunityHeader from "./SurveyAndPollCommunityHeader";
import SurveyAndPollTeamHeader from "./SurveyAndPollTeamHeader";
import SurveyAndPollPersonHeader from "./SurveyAndPollPersonHeader";
import { useSelector } from "react-redux";

function SurveyAndPollPostHeader({ hData, type }) {
  const { LoggedInUser } = useSelector((state) => state.auth);
  return (
    <div className="surveyAndPollPostHeader">
      {hData?.categoryType === "community" && (
        <SurveyAndPollCommunityHeader hData={hData} type={type} />
      )}
      {hData?.categoryType === "team" && (
        <SurveyAndPollTeamHeader hData={hData} type={type} />
      )}
      {hData?.categoryType !== "community" &&
        hData?.categoryType !== "team" &&
        LoggedInUser?.id !== hData?.employeeId && (
          <SurveyAndPollPersonHeader hData={hData} type={type} />
        )}
    </div>
  );
}

export default SurveyAndPollPostHeader;
