/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";

import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { format } from "date-fns";

export const MaintenancePopup = ({
  id,
  data,
  type,
  color,
  handleClose,
  stringIdent = false,
  callList,
}) => {
  const [loading, setLoading] = useState(false);
  const [updetails, setupdetails] = useState(false);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(false);
  const [initialValues, setInitialValues] = useState({
    Description: "",
    Specifications: [],
  });
  const getUpdetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `ItAssets/ModificationRequestsDetails?Id=${id}`
      );
      setupdetails(result?.data?.summary);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  console.log("object stringIdent", stringIdent);
  const dispatchAdminUpdate = async (value) => {
    try {
      setLoading(true);
      console.log("object valuealue", value);
      const result = await getRequest(
        `ItAssets/ModificationRequests?ID=${updetails?._specifications[0]?.assetId}&Type=Maintenance&Description=${value?.Description}`
      );

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
      callList();
    }
  };
  const createApprove = async (value) => {
    try {
      setLoading(true);
      console.log("object valuealue", value);
      const result = await postRequest(
        `ItAssets/ModificationApprove?ID=${updetails?.modificationRequestId}`,
        value
      );

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
      callList();
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - Maintenance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getUpdetails();
  }, []);
  useEffect(() => {
    if (updetails)
      setInitialValues({
        Description: "",
        Specifications: updetails?._specifications?.map((item) => ({
          Id: item?.id,
          AssetId: item?.assetId,
          SpecificationItemId: item?.assetSpecificationItemId,
          name: item?.name,
          Value: item?.name?.toLowerCase()?.includes("date")
            ? new Date(item.value)
            : item.value,
          oldValue: item?.name?.toLowerCase()?.includes("date")
            ? new Date(item.value)
            : item.value,
        })),
      });
  }, [updetails]);
  const validationSchema = Yup.object({
    Specifications: Yup.array().of(
      Yup.object().shape({
        Value: Yup.string().required(" Enter Value "),
      })
    ),
  });
  const onSubmit = async (values) => {
    const requestArray = [];

    values?.Specifications?.map((value, index) => {
      requestArray.push({
        // Id: other[index]?.id,
        Id: value?.Id,
        AssetId: value?.AssetId,
        SpecificationItemId: value?.SpecificationItemId,
        Type: value?.name,
        NewValue: value?.Value,
        s_value: "",
        Value: value?.name?.toLowerCase()?.includes("date")
          ? format(new Date(value?.oldValue), "yyyy-MM-dd") + " 00:00:00"
          : value?.oldValue,
      });
    });

    const creatReq = {
      ...values,
    };
    if (stringIdent !== false) {
      createApprove(requestArray);
    } else dispatchAdminUpdate(creatReq);
  };

  console.log("object formValues", formValues);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => {
            setFormValues(values);
            return (
              <Form>
                {updetails?._specifications?.length > 0 && (
                  <>
                    <div className="headingForm">Asset Specifications</div>
                    <div>
                      <FieldArray name="Specifications">
                        {(fieldArrayProps) => {
                          const { form } = fieldArrayProps;
                          const { values } = form;
                          const { Specifications } = values;
                          return (
                            <StyledTableCont>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      sx={{
                                        padding: "0px !important",
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom labelName={"S.No"} />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        padding: "0px !important",
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom labelName={"Spec Name"} />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom
                                        labelName={"Spec Value"}
                                        mandatory={true}
                                      />
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                {Specifications?.map(
                                  (Specifications, index) => (
                                    <TableBody key={index}>
                                      {/* 1st column */}
                                      <StyledTableCell align="center">
                                        {index + 1}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <InputCustomFormik
                                          name={`Specifications[${index}].name`}
                                          disabled
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {formValues?.Specifications[index]?.name
                                          ?.toLowerCase()
                                          ?.includes("date") ? (
                                          <DatePickerFormiks
                                            name={`Specifications[${index}].Value`}
                                            minDate={new Date()}
                                            disabled={
                                              stringIdent === false
                                                ? true
                                                : false
                                            }
                                          />
                                        ) : (
                                          <InputCustomFormik
                                            name={`Specifications[${index}].Value`}
                                            type="text"
                                            placeholder="Enter Spec Value"
                                            disabled={
                                              stringIdent === false
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                      </StyledTableCell>
                                    </TableBody>
                                  )
                                )}
                              </Table>
                            </StyledTableCont>
                          );
                        }}
                      </FieldArray>
                      {stringIdent === false && (
                        <div className="formcustom ">
                          <div>
                            <LabelCustom labelName={"Description"} disabled />
                            <TextAreaFormik
                              maxlength="150"
                              name={"Description"}
                              placeholder="Enter Description"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className="createFootCust">
                  <button
                    type="button"
                    className="button secondaryButton"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="button primaryButton"
                    type="submit"
                    style={{ background: "var(--primary)", color: "white" }}
                  >
                    {type}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
