import React, { useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { ColorPickerFormik } from "../../../../Components/FormComponent/ColorPickerFormik";
import { useEffect } from "react";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Table } from "@mui/material";
import { FieldArray } from "formik";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
export const CreateCategoryComp = ({ data, formValues }) => {
  const [pDepartmentList, setPDepartmentList] = useState([]);
  const [options, setoptions] = useState([]);
  const dispatch = useDispatch();
  const TypeList = [
    { label: "Mandatory", value: "Mandatory" },
    { label: "Non-Mandatory", value: "Non-Mandatory" },
  ];
  const opt = [{ label: "select", value: null }];

  const getParentCategory = async () => {
    try {
      const result = await getRequest("TrainingCategories/GetCategories");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setPDepartmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getOptionsRole = async () => {
    try {
      const result = await getRequest("Role/GetRole");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setoptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getParentCategory();
    getOptionsRole();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Create Ctegory`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Name"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="name"
            type={"text"}
            placeholder={"Enter Name"}
          />
        </div>
        {/* <div>
          <LabelCustom labelName={"Parent Course"} />
          <SelectForm
            name="parentCategoriesId"
            options={pDepartmentList}
            placeholder={"Select Parent Course"}
          /> 
           </div> */}
        <div>
          <LabelCustom labelName={"Type"} mandatory={true} />
          <SelectForm
            placeholder={" Select Type  "}
            name="type"
            options={TypeList}
            values={
              data
                ? TypeList.filter((options) => options.label === data?.type)
                : opt
            }
          />
        </div>
        <div>
          <LabelCustom labelName={"Color"} mandatory={true} />
          <ColorPickerFormik name="color" />
        </div>
        <div>
          <LabelCustom labelName={"Display in Candidate Page?"} />
          <YesNoFormik name="isDisplayInCandidatePage" />
        </div>
        <div>
          <LabelCustom labelName={"Cover Image"} mandatory={true} />
          <FileUploadFormik
            name="upload"
            attachTextLabel={data?.coverImage?.toString()}
          />
          <span
            style={{
              paddingLeft: "12px",
              color: "var(--primary)",
              fontSize: "13px",
            }}
          >
            Size: 2 MB
          </span>
          <span
            style={{
              paddingLeft: "12px",
              color: "var(--primary)",
              fontSize: "13px",
            }}
          >
            Format: JPEG, PNG
          </span>
        </div>
        <div>
          <LabelCustom labelName={"Certificate Needed ? "} mandatory={true} />
          <YesNoFormik name="certificateNeeded" />
        </div>
      </div>
      {formValues?.certificateNeeded === "Yes" && (
        <div className="repeatedSectionContainer">
          <FieldArray name="item">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values } = form;
              const { item } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Signatory"}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Sign"} mandatory={true} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Role"} mandatory={true} />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {item?.map((items, index) => (
                      <TableBody key={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            type="name"
                            name={`item[${index}].signatory`}
                            placeholder={"Enter Signatory"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <FileUploadFormik name={`item[${index}].sign`} />
                          <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                            Size: 2 MB
                          </span>
                          <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                            Format: JPG, PNG, PDF , Doc ,Docx
                          </span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <SelectForm
                            options={options}
                            name={`item[${index}].role`}
                            placeholder={"Select Role"}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          {
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                item.length > 1
                                  ? remove(index)
                                  : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                              }
                            // onClick={() => remove(index)}
                            >
                              -
                            </button>
                          }
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                signatory: "",
                                sign: "",
                                role: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      )}
    </>
  );
};
