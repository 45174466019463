import React from "react";
import HomeDashboardBackground from "../../../../Assets/HomeDashboardBackground.svg";
import ProfileAvatar from "../../../../Assets/ProfileAvatar.svg";
import { format } from "date-fns";
import { useEffect } from "react";

function Overview({ bannerList }) {
  console.log('bannerList',bannerList)
  useEffect(() => {
    document.title = `PeopleSol - Overview `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="mainOverviewContainer">
      <div
        className={`homeDashboardContainer`}
        style={{ width: "100%", flexShrink: 0 }}
      >
        <div className="profileImgHolder" style={{width:'470px',marginLeft:0}}>
          <img
            src={HomeDashboardBackground}
            alt="Profile"
            className="profileImgHolderbackground"
          />
          <div className="avatarContainer">
            {bannerList?.Image ? (
              <img
                src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${bannerList?.data?.image}`}
                alt={"Profile"}
                className="imageInsideProgressBar"
              />
            ) : (
              <img
                src={ProfileAvatar}
                alt={"Profile"}
                className="imageInsideProgressBar"
              />
            )}
          </div>
        </div>

        <div className="homeGreetingContainer" style={{maxWidth:'180px',marginLeft:'18px'}}>
          <h3>{bannerList?.data?.nameWithPersonNumber}</h3>
          <h4>{bannerList?.summary?.role}</h4>
          <div className="horizontalLinePrimary"></div>
          {/* <div className="starContainer">
            <img src={RewardIconOrange} alt="RewardIconOrange" />
            <label>Star Performer of the Month</label>
          </div> */}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            marginTop:'12px',
            justifyContent: "center",
            gap: "24px",
            padding: "12px",
            flexGrow: 1,
          }}
        >
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "25%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
                heigth:'2px'
              }}
            ></div>
            <p className="dashboardServicesHeading">Department</p>
            <p className="dashboardServicesName companyDash colr" >
              {bannerList?.summary?.department ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "25%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
                heigth:'2px'
              }}
            ></div>
            <p className="dashboardServicesHeading">Gender</p>
            <p className="dashboardServicesName companyDash colr">
              {bannerList?.data?.gender ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "25%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
                heigth:'2px'
              }}
            ></div>
            <p className="dashboardServicesHeading">Date of Joining</p>
            <p className="dashboardServicesName companyDash colr">
              {bannerList?.data?.doj
                ? format(new Date(bannerList?.data?.doj), "do MMM yyyy")
                : "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "25%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
                heigth:'2px'
              }}
            ></div>
            <p className="dashboardServicesHeading">Location</p>
            <p className="dashboardServicesName companyDash colr">
              {bannerList?.data?.location ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "25%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
                heigth:'2px'
              }}
            ></div>
            <p className="dashboardServicesHeading">Mobile Number</p>
            <p className="dashboardServicesName companyDash colr">
              {bannerList?.data?.phoneNo ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "25%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
                heigth:'2px'
              }}
            ></div>
            <p className="dashboardServicesHeading">Date of Birth</p>
            <p className="dashboardServicesName companyDash colr">
              {bannerList?.data?.dob
                ? format(new Date(bannerList?.data?.dob), "do MMM yyyy")
                : "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "25%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
                heigth:'2px'
              }}
            ></div>
            <p className="dashboardServicesHeading">Father Name</p>
            <p className="dashboardServicesName companyDash colr">
              {bannerList?.data?.fatherName ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "25%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
                heigth:'2px'
              }}
            ></div>
            <p className="dashboardServicesHeading">PAN Number</p>
            <p className="dashboardServicesName companyDash colr">
              {bannerList?.data?.panNo ?? "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="subOverviewContainer" style={{marginBottom:'12px'}}>
        <div className="overviewContainer">
          <div className="headingContainer">
            <h6>Payment Information</h6>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              marginTop:'12px',
              justifyContent: "center",
              gap: "24px",
              padding: "12px",
              flexGrow: 1,
            }}
          >
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">Payment Mode</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.paymentMode ?? "-"}
              </p>
            </div>
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">Account Number</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.accountNumber ?? "-"}
              </p>
            </div>
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">IFSC Code</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.ifscCode ?? "-"}
              </p>
            </div>
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">Account Holder Name</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.accountHolderName ?? "-"}
              </p>
            </div>
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">Bank Name</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.bankName ?? "-"}
              </p>
            </div>
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">Account Type</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.accountType ?? "-"}
              </p>
            </div>
          </div>
        </div>
        <div className="overviewContainer">
          <div className="headingContainer">
            <h6>Personal Information</h6>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              marginTop:'12px',
              justifyContent: "center",
              gap: "24px",
              padding: "12px",
              flexGrow: 1,
            }}
          >
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">Personal Email</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.personalEmail ?? "-"}
              </p>
            </div>
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">Office Email</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.email ?? "-"}
              </p>
            </div>
            <div className="dashboardServicesType">
              <div
                className="horizontalLine"
                style={{
                  width: "25%",
                  backgroundColor: "var(--secondary)",
                  marginBottom: "3px",
                  heigth:'2px'
                }}
              ></div>
              <p className="dashboardServicesHeading">Residential Address</p>
              <p className="dashboardServicesName companyDash colr">
                {bannerList?.data?.residentialAddress ?? "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
