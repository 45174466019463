import React, { useState } from "react";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../Services/axios";
import { showToast, isLoading } from "../../../Features";
import { useDispatch } from "react-redux";

import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { SelectFormik } from "../../../Components/FormComponent/SelectFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { AttachmentFormik } from "../../../Components/FormComponent/AttachmentFormik";
import { FieldArray } from "formik";
import styled from "styled-components";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { Table } from "@mui/material";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { useEffect } from "react";
import { CleaningServices } from "@mui/icons-material";
export const StyledTableCell = styled(TableCell)({
  padding: "5px",
  border: "1px solid #bdbdbd5c",
});
export const StyledTableCont = styled(TableCell)({
  overflow: "revert !important",
});

export const CreateGalleryComp = ({ formValues }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Create Gallery`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const drinks = [
    {
      label: "Coffee",
      value: "button-types",
    },
    {
      label: "Tea",
      value: "button-types",
    },
  ];

  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="Name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Cover Image"} />
        <FileUploadFormik name="CoverUpload" placeholder="Attach Image" />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Size: 2 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Format: JPEG, PNG, JPG
        </span>
      </div>
      <div>
        <LabelCustom labelName={"Description"} mandatory={true} />
        <TextAreaFormik
          style={{ height: "12" }}
          maxLength={500}
          name="Description"
          placeholder={"Enter Description"}
        />
      </div>

      <div className="repeatedSectionContainer">
        <LabelCustom labelName={""} />
        <FieldArray name="AlbumsPhotoslist">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { AlbumsPhotoslist } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        S. No
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        Name
                        <span style={{ color: "red" }}>*</span>
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        Image
                        <span style={{ color: "red" }}>*</span>
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        Add/Remove
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {AlbumsPhotoslist?.map((AlbumsPhotoslists, index) => {
                    {
                      /* console.log('AlbumsPhotoslist',AlbumsPhotoslist) */
                    }

                    return (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>

                        {/* 2nd column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={150}
                            type="text"
                            name={`AlbumsPhotoslist[${index}].Description`}
                            placeholder={" Enter Name  "}
                          />
                        </StyledTableCell>

                        {/* 3rd column */}

                        <StyledTableCell>
                          <FileUploadFormik
                            name={`AlbumsPhotoslist[${index}].Upload`}
                          />
                          <span
                            style={{
                              paddingLeft: "12px",
                              color: "var(--primary)",
                            }}
                          >
                            Size: 1 MB
                          </span>
                          <span
                            style={{
                              paddingLeft: "12px",
                              color: "var(--primary)",
                            }}
                          >
                            Format: JPEG, PNG
                          </span>
                        </StyledTableCell>

                        {/* 4th column */}

                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              AlbumsPhotoslist?.length > 1
                                ? remove(index)
                                : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({ Description: "", Upload: "" })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    );
                  })}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
