import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { useState } from "react";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
export const RunPopup = ({
  handleClose,
  id,
  employeeData,
  color,
  getFormDetails,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    paidOn: Yup.string().required("Please Enter Paid On Date"),
  });
  console.log("employeeData", employeeData);

  const runPayrollConfirm = async (values) => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PreparePayroll/RunPayrollConfirm/${id}/${
          values?.sendpayslip
        }/${dateFormat(values?.paidOn, "yyyy-mm-dd")}`
      );
      if (result) {
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      handleClose();
      navigate("/payroll/runpayroll/completed");
      setLoading(false);
    }
  };
  //alistname is crucial for company Hospitality transaction dont remove
  const onSubmit = async (values) => {
    runPayrollConfirm(values);
  };
  const countPaymentModes = (employeeData) => {
    const paymentModeCount = {};

    // Count occurrences
    employeeData.forEach((item) => {
      if (item.paymentMode?.trim() !== null) {
        if (paymentModeCount[item.paymentMode?.trim()]) {
          paymentModeCount[item.paymentMode?.trim()]++;
        } else {
          paymentModeCount[item.paymentMode?.trim()] = 1;
        }
      }
    });

    return paymentModeCount;
  };
  const totalEmployees = employeeData.reduce((accumulator, currentItem) => {
    // If the 'employees' property exists in the current item, add its value to the accumulator
    if (currentItem.hasOwnProperty("employees")) {
      accumulator += currentItem.employees;
    }
    return accumulator;
  }, 0);
  let x;
  const renderPaymentModes = (employeeData) => {
    const paymentModeCount = countPaymentModes(employeeData);

    return (
      <ul>
        {employeeData.map((item) => {
          console.log("employeeData", employeeData);
          if (item.paymentMode !== null) {
            if (paymentModeCount[item.paymentMode] >= 1) {
              x = paymentModeCount[item.paymentMode];
              return (
                <li key={item.paymentMode}>
                  {`${item.paymentMode} 
                  ${paymentModeCount[item.paymentMode]}`}
                </li>
              );
            } else {
              return null; // If count is 1, don't display separately
            }
          } else {
            return <div style={{margin:'24px'}}>-</div>;
          }
        })}
      </ul>
    );
  };

  console.log("totalEmployees", totalEmployees);
  return (
    <div>
      <div className="headingForm">
        You are about to record payment for this pay run.
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{
              paidOn: "",
              employee: totalEmployees ?? 0,
              sendpayslip: false,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values }) => {
              return (
                <Form>
                  <div className="formcustom threeColumn">
                    <div>
                      <LabelCustom
                        labelName={"Employee(s) paid on"}
                        mandatory={true}
                      />
                      <DatePickerFormiks
                        type="number"
                        maxLength={500}
                        placeholder="Please Enter Days"
                        name="paidOn"
                      />
                    </div>
                    <div style={{ padding: "10px 0px 0px 36px" }}>
                      <LabelCustom
                        style={{ padding: 0 }}
                        labelName={"Payment Mode"}
                        mandatory={true}
                      />
                      {renderPaymentModes(employeeData)}
                    </div>
                    <div>
                      <LabelCustom
                        labelName={"Employee Count"}
                        mandatory={true}
                      />
                      <InputCustomFormik
                        type="number"
                        maxLength={500}
                        name="employee"
                      />
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "36px", height: "1px" }}
                    className="horizontalLine"
                  ></div>
                  <CheckboxFormiks
                    labelName={
                      "Send pay slip notification email to all employees"
                    }
                    name="sendpayslip"
                  />
                  <div className="runpayF">
                    <p>
                      An email with a link to view the payslip will be emalied
                      to portal-enable employees whereas the payslip will be
                      attached along with the email for those who don’t have the
                      employee portal enabled.
                    </p>
                    <div className="runPayYel">
                      <p style={{ marginTop: "5px" }}>
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="8.33154"
                            cy="8.4502"
                            r="8"
                            fill="#F7941D"
                          />
                          <path
                            d="M9.04774 5.79226C9.78644 5.79226 10.3853 5.19343 10.3853 4.45473C10.3853 3.71602 9.78644 3.11719 9.04774 3.11719C8.30904 3.11719 7.71021 3.71602 7.71021 4.45473C7.71021 5.19343 8.30904 5.79226 9.04774 5.79226Z"
                            fill="white"
                          />
                          <path
                            d="M10.4154 11.2198C10.3785 11.1949 10.3369 11.1777 10.2932 11.1691C10.2495 11.1605 10.2046 11.1607 10.161 11.1698C10.1174 11.1788 10.076 11.1965 10.0393 11.2217C10.0026 11.2469 9.97133 11.2792 9.94729 11.3167C9.62404 11.7954 9.22804 12.2208 8.7736 12.5774C8.66326 12.6609 8.25196 12.9786 8.07808 12.9117C7.95771 12.875 8.02793 12.6375 8.05133 12.5372L8.22856 12.0122C8.30212 11.7982 9.58281 7.99963 9.72325 7.56493C9.93057 6.9296 9.84029 6.3043 8.89398 6.45477C8.63651 6.48152 6.02496 6.81925 5.97815 6.8226C5.93424 6.82545 5.89132 6.83693 5.85184 6.85637C5.81236 6.87581 5.7771 6.90283 5.74807 6.9359C5.71904 6.96897 5.6968 7.00744 5.68264 7.0491C5.66847 7.09076 5.66265 7.1348 5.6655 7.17872C5.66835 7.22263 5.67983 7.26555 5.69927 7.30502C5.71871 7.3445 5.74574 7.37976 5.77881 7.40879C5.81188 7.43783 5.85034 7.46006 5.892 7.47423C5.93366 7.4884 5.97771 7.49422 6.02162 7.49136C6.02162 7.49136 7.02477 7.36095 7.13512 7.35092C7.19164 7.3454 7.24855 7.35636 7.29899 7.38247C7.34942 7.40857 7.39122 7.44872 7.41935 7.49805C7.47994 7.68535 7.47163 7.88813 7.39594 8.06985C7.309 8.40423 5.93468 12.2764 5.89121 12.5004C5.84459 12.6879 5.85753 12.8853 5.92825 13.0651C5.99896 13.2448 6.12393 13.3981 6.28578 13.5036C6.58957 13.7061 6.95129 13.8035 7.31569 13.7811C7.66999 13.7768 8.02039 13.7065 8.34893 13.5738C9.18155 13.2394 10.051 12.35 10.5091 11.6511C10.5485 11.5801 10.5608 11.4972 10.5435 11.4178C10.5263 11.3384 10.4808 11.268 10.4154 11.2198Z"
                            fill="white"
                          />
                        </svg>
                      </p>
                      <p>
                        Regardless of whether you choose to send payslip
                        notification email or not, the payslip will be displayed
                        in the ESS Portal once you record the payment.
                      </p>
                    </div>
                  </div>
                  <div className="createFootCust">
                    <button
                      type="button"
                      className="button secondaryButton"
                      onClick={handleClose}
                      style={{ background: "white", color: "red" }}
                    >
                      Cancel
                    </button>
                    <button
                      className="button primaryButton"
                      type="submit"
                      style={{ background: "var(--primary)", color: "white" }}
                    >
                      Confirm
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
};
