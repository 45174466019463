import React, { useState } from "react";
import "./MyApprovals.css";
import RejectIcon from "../../../../../Assets/RejectIcon.svg";
import ApproveIcon from "../../../../../Assets/ApproveIcon.svg";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { ApprovalCardRemark } from "../../../../../Components/FormComponent/PopupComponent/ApprovalCardRemark";
import { useNavigate } from "react-router-dom";
import NoRequestsFound from "./NoRequestsFound";
import Skeleton from "react-loading-skeleton";
import { HospitalityApprove } from "../../../../Office/Components/HospitalityApprove";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
const MyApprovals = ({ togglePopupHospitality, setApprovalPopup, setid }) => {
  const dispatch = useDispatch();
  const [homeList, setHomeList] = useState(null);
  const [info, setInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const moduleList = [
    {
      module: "Meeting Room Booking",
      count: 390,
    },
    {
      module: "Attendance Requests",
      count: 1,
    },
    {
      module: "Relocation Request",
      count: 34,
    },
    {
      module: "Late Coming Requests",
      count: 1,
    },
    {
      module: "Early Going Requests",
      count: 1,
    },
    {
      module: "Outdoor Requests",
      count: 2,
    },
    {
      module: "Comp off Request",
      count: 2,
    },
    {
      module: "Outdoor Duty",
      count: 8,
    },
    {
      module: "Hotel Booking",
      count: 2,
    },
    {
      module: "Travel Booking",
      count: 14,
    },
    {
      module: "Admin Facility Requests",
      count: 9,
    },
    {
      module: "HR Helpdesk",
      count: 1,
    },
    {
      module: "Leave Requests",
      count: 10,
    },
    {
      module: "Deputation Requests",
      count: 6,
    },
    {
      module: "Manpower Indent",
      count: 2,
    },
    {
      module: "Exception Request",
      count: 1,
    },
    {
      module: "Candidate Onboarding",
      count: 5,
    },
    {
      module: "Final Settlement",
      count: 1,
    },
    {
      module: "Expense Requests",
      count: 1,
    },
    {
      module: "Change Requests",
      count: 10,
    },
    {
      module: "Company Car Request",
      count: 2,
    },
    {
      module: "Gate Pass Requests",
      count: 4,
    },
    {
      module: "Exit Request",
      count: 1,
    },
    {
      module: "Loan and Advance Level 1",
      count: 1,
    },
    {
      module: "IT Helpdesk",
      count: 1,
    },
    {
      module: "Over Time Request",
      count: 1,
    },
    {
      module: "Recruitment Travel and Hospitality Level 1",
      count: 1,
    },
    {
      module: "Food Requests",
      count: 1,
    },
    {
      module: "Special Food Requests",
      count: 1,
    },
    {
      module: "Courier Services Requests",
      count: 1,
    },
    {
      module: "Hospitality Requests",
      type: "Manager",
      count: 1,
    },
    {
      module: "Hospitality Requests",
      type: "Hospitality Manager",
      count: 1,
    },
    {
      module: "Hospitality Requests",
      type: " Hotel Manager",
      count: 1,
    },
  ];

  const getHomeData = async () => {
    try {
      setLoading(true);
      const result = await getRequest("Settings/Home");
      setHomeList(result?.data?.data);
      // //console.log('result.data', result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHomeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const PostApRejHol = async (module, type, id, Remark, typed) => {
    // console.log("My Pending Approvals",module,type)
    try {
      setLoading(true);
      if (type === "Approve") {
        approveFunction(module, "Approve", id, typed);
        // await getRequest(`${module}/${type}/${id}`);   //Approve function
      } else if (type === "Reject") {
        rejectFunction(module, "Reject", id, Remark, typed); //Reject function
      } else if (type === "details") {
        detailsFunction(module, id); //Details function
      } else {
        await postRequest(`${module}/${type}?Id=${id}&Remarks=${Remark}`);
      }
      navigate("/");
      if (type === "Approve")
        dispatch(
          showToast({
            text: "Request Has Been Approved Successfully",
            severity: "success",
          })
        );
      else
        dispatch(
          showToast({
            text: "Request Has Been Rejected Successfully",
            severity: "success",
          })
        );
    } catch (error) {
      dispatch(
        showToast({
          // text: error?.response?.data ?? "Module not present in the Function",
          text: "Module not present in the Function",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const detailsFunction = (module, id) => {
    const data = module.toLowerCase().replaceAll("_", " ").split(" ")[0];
    setInfo(data);
    const anotherData = moduleList?.find(
      (x) => x.module.toLowerCase().split(" ")[0] === data
    );

    let modName = anotherData.module.toLowerCase().split(" ")[0];
    if (modName === "relocation") {
      return getRequest(`RelocationRequests/Details?ID=${id}`);
    } else if (modName === "deputation") {
      return postRequest(`DeputationRequests/${module}?Id=${id}`);
    } else if (modName === "manpower") {
      return postRequest(`ManpowerIndents/${module}?Id=${id}`);
    } else if (modName === "travel") {
      return getRequest(`TravelHospitalityRequests/${module}/${id}`);
    } else if (modName === "expense") {
      return getRequest(`Expenses/${module}/${id}`);
    } else if (modName === "late") {
      return postRequest(`LateComingRequests/${module}/${id}`);
    } else if (modName === "early") {
      return postRequest(`EarlyComingRequests/${module}/${id}`);
    } else if (modName === "company") {
      //add a condition to check car, food and hospitality
      return getRequest(`CompanyCarController/${module}?Id=${id}`);
    } else if (modName === "admin") {
      return postRequest(`FacilityRequests/${module}?Id=${id}`);
    } else if (modName === "leave") {
      return getRequest(`NewLeaveRequests/${module}/${id}`);
    } else if (modName === "comp") {
      return getRequest(`CompoffRequests/${module}/${id}`);
    } else if (modName === "exit") {
      // find this module
      return postRequest(`Resignations/${module}?Id=${id}`);
    } else if (modName === "outdoor") {
      return getRequest(`OutdoorDutyReportings/${module}/${id}`);
    } else if (modName === "candidate") {
      return getRequest(`Candidate/CompleteOnboarding/${id}`);
    } else if (modName === "recruitment") {
      return getRequest(`TravelHospitalityRequests/${module}/${id}`);
    } else if (modName === "gate") {
      return getRequest(`GatePassRequestsController/${module}?Id=${id}`);
    } else if (modName === "exception") {
      return postRequest(`ExceptionRequests/${module}?Id=${id}`);
    } else if (modName === "it") {
      return postRequest(`ItHelpdesks/${module}?Id=${id}`);
    } else if (modName === "hr") {
      return postRequest(`HrHelpdesks/${module}?Id=${id}`);
    } else if (modName === "loan") {
      return getRequest(`LoanAndAdvance/${module}/${id}`);
    } else {
      dispatch(
        showToast({
          text: "Module not present in the Database",
          severity: "error",
        })
      );
    }
  };

  const approveFunction = (module, type, id, typed) => {
    const data = module.toLowerCase().replaceAll("_", " ").split(" ")[0];
    const dataType = typed ? typed.toLowerCase().trim() : null;
    let anotherData;
    if (dataType !== null) {
      setInfo(dataType);
      anotherData = moduleList?.find(
        (x) =>
          x.module.toLowerCase().split(" ")[0] === data &&
          x.type.toLowerCase()?.trim() === dataType
      );
    } else {
      setInfo(data);

      anotherData = moduleList?.find(
        (x) => x.module.toLowerCase().split(" ")[0] === data
      );
    }
    let modName = anotherData.module.toLowerCase().split(" ")[0];
    let typeName = anotherData.type
      ? anotherData.type.toLowerCase()?.trim()
      : "";
    // console.log("first", modName);
    if (modName === "relocation") {
      return postRequest(`RelocationRequests/${type}?Id=${id}`);
    } else if (modName === "deputation") {
      return postRequest(`DeputationRequests/${type}?Id=${id}`);
    } else if (modName === "over") {
      return getRequest(`OverTimeRequest/${type}?GeneralWorkflowId=${id}`);
    } else if (modName === "attendance") {
      return getRequest(`AttendanceRegularizations/${type}/${id}`);
    } else if (modName === "manpower") {
      return postRequest(`ManpowerIndents/${type}?Id=${id}`);
    } else if (modName === "loan") {
      return getRequest(`LoanAndAdvance/${type}/${id}`);
    } else if (
      modName === "travel" &&
      module !== "Travel and Hospitality Requests"
    ) {
      return getRequest(`TravelAdvanceRequests/${type}/${id}`);
    } else if (modName === "it") {
      return postRequest(`Ithelpdesks/${type}?Id=${id}`);
    } else if (modName === "expense") {
      return getRequest(`Expenses/${type}/${id}`);
    } else if (modName === "late") {
      return postRequest(`LateComingRequests/${type}/${id}`);
    } else if (modName === "early") {
      return postRequest(`EarlyGoingRequests/${type}/${id}`);
    } else if (modName === "company") {
      //add a condition to check car, food and hospitality
      return getRequest(`CompanyCarController/Approve?Id=${id}`);
    } else if (modName === "admin") {
      return postRequest(`FacilityRequests/${type}?Id=${id}`);
    } else if (modName === "leave") {
      return getRequest(`NewLeaveRequests/${type}/${id}`);
    } else if (modName === "comp") {
      return getRequest(`CompoffRequests/${type}/${id}`);
    } else if (modName === "exit") {
      // find this module
      return postRequest(`Resignations/${type}?Id=${id}`);
    } else if (modName === "outdoor") {
      return getRequest(`OutdoorDutyReportings/${type}/${id}`);
    } else if (modName === "candidate") {
      return getRequest(`Candidate/CompleteOnboarding/${id}`);
    } else if (modName === "recruitment" || modName === "travel") {
      return getRequest(`TravelHospitalityRequests/${type}/${id}`);
    } else if (modName === "gate") {
      return getRequest(`GatePassRequestsController/${type}?Id=${id}`);
    } else if (modName === "exception") {
      return postRequest(`ExceptionRequests/${type}?Id=${id}`);
    } else if (modName === "it") {
      return postRequest(`ItHelpdesks/${type}?Id=${id}`);
    } else if (modName === "hr") {
      return postRequest(`HrHelpdesks/${type}?Id=${id}`);
    } else if (modName === "loan") {
      return getRequest(`LoanAndAdvance/${type}/${id}`);
    } else if (modName === "courier") {
      return getRequest(`CourierServicesRequests/Approve?Id=${id}`);
    } else if (modName === "meeting") {
      return postRequest(`MeetingRoomBookings/MRMApprove?Id=${id}`);
    } else if (modName === "hospitality" && typeName === "manager") {
      return getRequest(`HospitalityRequests/Approve?Id=${id}`);
    } else if (modName === "hospitality" && typeName === "hotel manager") {
      return getRequest(`HospitalityRequests/Hotelapprove?Id=${id}`);
    } else if (modName === "special" || modName === "food") {
      return postRequest(`FoodRequests/Approve?Id=${id}`);
    } else {
      dispatch(
        showToast({
          text: "Module not present in the Database",
          severity: "error",
        })
      );
    }
  };

  const rejectFunction = (module, type, id, Remark, typed) => {
    const data = module.toLowerCase().replaceAll("_", " ").split(" ")[0];
    const dataType = typed ? typed.toLowerCase().trim() : null;

    let anotherData;
    if (dataType !== null) {
      setInfo(dataType);
      anotherData = moduleList?.find(
        (x) =>
          x.module.toLowerCase().split(" ")[0] === data &&
          x.type.toLowerCase()?.trim() === dataType
      );
    } else {
      setInfo(data);
      anotherData = moduleList?.find(
        (x) => x.module.toLowerCase().split(" ")[0] === data
      );
    }
    let modName = anotherData.module.toLowerCase().split(" ")[0];
    let typeName = anotherData.type
      ? anotherData.type.toLowerCase()?.trim()
      : "";
    if (modName === "relocation") {
      // return  postRequest(`RelocationRequests/${type}?Id=${id}`);
      // //console.log("type: ", type);
      // //console.log("id: ", id);
      // //console.log("Remark: ", Remark);
      return postRequest(
        `RelocationRequests/${type}?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "deputation") {
      return postRequest(
        `DeputationRequests/${type}?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "over") {
      return getRequest(`OverTimeRequest/${type}?Id=${id}&Remarks=${Remark}`);
    } else if (modName === "manpower") {
      return postRequest(`ManpowerIndents/${type}?Id=${id}&Remarks=${Remark}`);
    } else if (
      modName === "travel" &&
      module !== "Travel and Hospitality Requests"
    ) {
      return getRequest(`TravelHospitalityRequests/${type}/${id}/${Remark}`);
    } else if (modName === "expense") {
      return getRequest(`Expenses/${type}/${id}/${Remark}`);
    } else if (modName === "late") {
      return postRequest(
        `LateComingRequests/${type}?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "early") {
      return getRequest(`EarlyGoingRequests/${type}/${id}/${Remark}`);
    } else if (modName === "company") {
      //add a condition to check car, food and hospitality
      return postRequest(
        `CompanyCarController/Reject?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "admin") {
      return postRequest(`FacilityRequests/${type}?Id=${id}&Remarks=${Remark}`);
    } else if (modName === "leave") {
      return getRequest(`NewLeaveRequests/${type}${id}/${Remark}`);
    } else if (modName === "comp") {
      return getRequest(`CompoffRequests/${type}/${id}/${Remark}`);
    } else if (modName === "exit") {
      return postRequest(`Resignations/${type}?Id=${id}&Remarks=${Remark}`);
    } else if (modName === "outdoor") {
      return getRequest(`OutdoorDutyReportings/${type}/${id}/${Remark}`);
    } else if (modName === "candidate") {
      // add a condition to check reject by company
      return getRequest(`Candidate/${type}/${id}/${Remark}`);
    } else if (modName === "recruitment" || modName === "travel") {
      return getRequest(`TravelHospitalityRequests/${type}/${id}/${Remark}`);
    } else if (modName === "gate") {
      return postRequest(
        `GatePassRequestsController/${type}?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "exception") {
      return postRequest(
        `ExceptionRequests/${type}?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "it") {
      return postRequest(`ItHelpdesks/${type}?Id=${id}&Remarks=${Remark}`);
    } else if (modName === "hr") {
      return postRequest(`HrHelpdesks/${type}?Id=${id}&Remarks=${Remark}`);
    } else if (modName === "loan") {
      return getRequest(`LoanAndAdvance/${type}/${id}/${Remark}`);
    } else if (modName === "loan") {
      return postRequest(
        `CourierServicesRequests/${type}?ID=${id}&Remarks=${Remark}`
      );
    } else if (modName === "courier") {
      return postRequest(
        `CourierServicesRequests/Reject?ID=${id}&Remarks=${Remark}`
      );
    } else if (modName === "meeting") {
      return postRequest(
        `MeetingRoomBookings/MRMReject?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "hospitality" && typeName === "manager") {
      return getRequest(
        `HospitalityRequests/Reject?Id=${id}&Remarks=${Remark}`
      );
    } else if (
      modName === "hospitality" &&
      typeName === "hospitality manager"
    ) {
      return getRequest(
        `HospitalityRequests/HospitalityManagerReject?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "hospitality" && typeName === "hotel manager") {
      return getRequest(
        `HospitalityRequests/HotelReject?Id=${id}&Remarks=${Remark}`
      );
    } else if (modName === "special" || modName === "food") {
      return postRequest(`FoodRequests/Reject?Id=${id}&Remarks=${Remark}`);
    } else {
      dispatch(
        showToast({
          text: "Module not present in the Database",
          severity: "error",
        })
      );
    }
  };
  return (
    <div className={"dashboardCardContentHolder"}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 10,
          }}
        >
          <Skeleton
            width="100%"
            height="100%"
            style={{ position: "absolute" }}
          />
        </div>
      ) : (
        <>
          {homeList && homeList?.approvals?.length > 0 ? (
            <div className="approvalFrameTabData">
              <table className="teamAttendanceCard">
                <tr className="teamAttendanceTableHeader">
                  <th style={{ padding: "5px" }}></th>
                  {/* <th style={{ width: "61px", padding: "5px" }}>Req No.</th> */}
                  <th style={{ width: "44px", padding: "5px" }}>S No.</th>
                  <th style={{ padding: "5px", width: "6rem" }}>Raised by</th>
                  <th style={{ padding: "5px" }}>Request Type</th>
                  <th
                    style={{
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Action
                  </th>
                </tr>
                {homeList?.approvals?.map((item, key) => {
                  if (
                    item?.module !== "Candidate Onboarding"
                    // &&
                    // item?.module !== "Courier Services Requests"
                  )
                    return (
                      <tr key={key} className="teamAttendanceItemContainer">
                        <td></td>
                        <td style={{ paddingLeft: "17px" }}>
                          {/* {item?.requestId ? item?.requestId : "--"} */}
                          {key + 1}
                        </td>
                        <td className="teamAttendanceNameItem">
                          {item?.requester
                            ? item?.requester.split("(")[0]
                            : item?.personNumber}
                        </td>
                        <td className="teamAttendanceItem">
                          {item?.module
                            ? `${item?.module.slice(0, 21)}..`
                            : "--"}
                        </td>
                        <td className="teamAttendanceItem">
                          <div className="approvalActionsHolder">
                            {/* <IconButton>

                    <img
                      className="cursor"
                      title="Details"
                      onClick={() =>
                        PostApRejHol(item.module, "details", item.id)
                      }
                      src={DetailsIcon}
                      alt="DetailsIcon"
                      />
                      </IconButton> */}
                            <IconButton>
                              <img
                                className="cursor"
                                title="Approve"
                                onClick={() => {
                                  if (item.type !== "Hospitality Manager") {
                                    PostApRejHol(
                                      item.module,
                                      "Approve",
                                      item.id,
                                      "remark",
                                      item.type
                                    );
                                  } else {
                                    togglePopupHospitality(item.id);
                                  }
                                }}
                                src={ApproveIcon}
                                alt="ApprovalTick"
                              />
                            </IconButton>

                            <IconButton>
                              <img
                                className="cursor"
                                title="Reject"
                                onClick={() =>
                                  confirmAlert({
                                    customUI: ({ onClose }) => {
                                      return (
                                        <div className="homeScreenPopup">
                                          <ApprovalCardRemark
                                            data={PostApRejHol}
                                            type={"Reject"}
                                            typed={item.type}
                                            module={item.module}
                                            id={item.id}
                                            color={"red"}
                                            handleClose={onClose}
                                          />
                                        </div>
                                      );
                                    },
                                  })
                                }
                                src={RejectIcon}
                                alt="RejectionCross"
                              />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                })}
              </table>
            </div>
          ) : (
            <NoRequestsFound text={"No Pending Approvals"} />
          )}
        </>
      )}
      <div></div>
    </div>
  );
};

export { MyApprovals };
