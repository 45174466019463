import React, { useRef } from "react";
import { useState } from "react";
import "./NewBroadCast.css";
import ChatSelectedCheck from "../../../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../../../Assets/ChatUnselectedCheck.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function SelectedNewBrMembers(props) {

    const contactsList = useSelector((state) => state.chat.contactList);
    const [chatList, setChatList] = useState([...props.selectedGroupMember]);
    const [newGroupList, setNewGroupList] = useState(true);
    const [selectedParticipant, setSelectedParticipant] = useState([]);

    useEffect(() => {
        document.title = `PeopleSol - New Group`;
        return () => {
            document.title = 'PeopleSol';
        };
    })
    function showStatus(status) {
        if (status === "online") {
            return "greenDot";
        } else if (status === "busy") {
            return "redDot";
        } else if (status === "away") {
            return "yellowDot";
        }
    }
    const NewChatList = ({ setSelectedParticipant }) => {
        const Contact = ({ chat, index }) => {
            return (
                <div
                    className="newChatListContentContainer"
                    style={{ backgroundColor: "white", paddingLeft: 15, paddingRight: 15 }}
                >


                    <div className="newChatActiveImage">
                        {chat.image != "" ? <img src={chat.image} alt="Profile" /> :
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 200 }}>
                                <div style={{ color: "#1296B0" }}>
                                    {
                                        chat?.name
                                            ?.split(" ")
                                            ?.slice(0, 2)
                                            ?.map((item) => {
                                                return item?.charAt(0)?.toUpperCase();
                                            })
                                            .join("")
                                    }
                                </div>
                            </div>}
                        <div className={showStatus(chat.Active)}></div>
                    </div>
                    <div className="newChatPostMainTextContainer">
                        <label className="chatListName">{chat.name}</label>
                        <label className="chatListRole">{chat.role}</label>
                    </div>
                </div>
            )
        }
        return (
            <div className="newChatListContainer" style={{ paddingBottom: 200 }}>
                {chatList.map((chat, index) => (
                    <Contact key={index} index={index} chat={chat} />
                ))}
            </div>
        )
    }
    return (
        <div className="newGroupContainer" style={{ backgroundColor: "white", }}>
            <NewChatList setSelectedParticipant={(chat, select) => {
                if (select) setSelectedParticipant([...selectedParticipant, chat])
                else {
                    let a = selectedParticipant.filter(item => item._id !== chat._id);
                    setSelectedParticipant([...a])
                }
            }} />

        </div>
    );
}

export default SelectedNewBrMembers
    ;