import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateGatePassComp } from "./FormComp/CreateGatePassComp";
import { confirmAlert } from "react-confirm-alert";
import dateFormat from "dateformat";
import { CreateMeetingRoomComp } from "./FormComp/CreateMeetingRoomComp";
import { CreateVisitorsComp } from "./FormComp/CreateVisitorsComp";
import { format } from "date-fns";

const CreateVisitors = (props) => {
  const { state } = useLocation();

  const [detailsform, setDetailsform] = useState({});
  const [formValues, setFormValues] = useState(null);
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`VisitorsController/Details?Id=${data}`);
      setDetailsform(result.data.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Visitors`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (data) getFormDetails();
  }, []);

  const Create = async (create) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("VisitorsController/Create", create);
      if (result.data.isSuccess === false) {
        dispatch(showToast({ text: result.data.message, severity: "error" }));
      } else {
        dispatch(showToast({ text: result.data.message, severity: "success" }));
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (edit) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("VisitorsController/Edit", edit);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };
  const getInitalWhoIsComing = () => {
    let returnArray = [];
    data
      ? detailsform?._visits?.visitingPeople?.map((item) => {
          return returnArray.push({
            name: item?.name,
            phoneNumber: item?.phoneNumber,
            email: item?.email,
          });
        })
      : (returnArray = [{ name: "", phoneNumber: "", email: "" }]);

    return returnArray;
  };
  const getInitalVisitorsPlan = () => {
    let returnArray = [];
    data
      ? detailsform?._visits?.visitsLocations?.map((item, index) => {
          return returnArray.push({
            locationGroupId: item?.locationGroupId,
            locationId: item?.locationId,
            employeeId: item?.employeeId,
            // visitingTime: item?.visitingTime,
            date: item?.visitingTime ? new Date(item?.visitingTime) : "",
            time: item?.visitingTime ? new Date(item?.visitingTime) : "",
            // visitingTime: format(new Date(item?.visitingTime), "dd/MM/yyyy hh:mm a"),
            visitId: item?.visitId,
          });
        })
      : (returnArray = [
          {
            visitId: 0,
            locationGroupId: "",
            locationId: "",
            employeeId: "",
            date: "",
            time: "",
          },
        ]);

    return returnArray;
  };

  const initialvalue = {
    Visitlist: getInitalWhoIsComing(),
    Visitlocationlist: getInitalVisitorsPlan(),
    purpose: data ? detailsform?._visits?.purpose : "",
    vehicleType: data ? detailsform?._visits?.vehicleType : "",
    VehicleNumber: data ? detailsform?._visits?.vehicleNumber : "",
    company: data ? detailsform?._visits?.company : "",
    location: data ? detailsform?._visits?.location : "",
    remarks: data ? detailsform?._visits?.remarks : "",
  };

  const validationSchema = Yup.object({
    Visitlist: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .max(149, "Name must be less than 150 characters")
          .required("Please Enter Name"),
        phoneNumber: Yup.number()
          .typeError("Phone Number must be a number")
          .required("Please enter the Phone Number"),
        email: Yup.string().email("Please Enter Valid Email"),
      })
    ),
    Visitlocationlist: Yup.array().of(
      Yup.object().shape({
        locationGroupId: Yup.string().required(
          "Please Select the Location Group"
        ),
        locationId: Yup.string().required("Please Select the Location"),
        employeeId: Yup.string().required("Please Select the Employee"),
        date: Yup.string().required("Please Enter the Visiting Date"),
        time: Yup.string().required("Please Enter the Visiting Time"),
      })
    ),
    VehicleNumber: Yup.string()
      .matches(/^[A-Z|a-z]{2}\s?[0-9]{1,2}\s?[A-Z|a-z]{0,3}\s?[0-9]{4}$/, {
        message: "Please Enter correct vehicle no.",
      })
      .required("Please Enter Vehicle no."),
    purpose: Yup.string().required("Please Select the Purpose"),
    vehicleType: Yup.string().required("Please Select the Vehicle Type"),
    location: Yup.string().required("Please Enter the Location"),
    remarks: Yup.string()
      .max(149, "Remarks must be less than 150 characters")
      .required("Please Enter the Remarks"),
  });

  const onSubmit = (values, error) => {
    const create = {
      ...values,
      date: format(values?.Visitlocationlist[0]?.date, "yyyy-MM-dd"),
      Visitlocationlist: values?.Visitlocationlist?.map((item) => {
        return {
          visitId: 0,
          locationGroupId: item?.locationGroupId,
          locationId: item?.locationId,
          employeeId: item?.employeeId,
          visitingTime:
            `${format(item?.date, "yyyy-MM-dd")}` +
            "T" +
            `${format(item?.time, "HH:mm:ss")}`,
        };
      }),
      Visitlist: values?.Visitlist?.map((item) => {
        return {
          ...item,
          phoneNumber: item?.phoneNumber?.toString(),
        };
      }),
    };
    // console.log("valuessgd", create);
    // if (values) {
    //     var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    //     create["visitingTime"] = new Date(values.Visitlocationlist.map((item) => item.visitingTime)
    //         - tzoffset)
    //         .toISOString()
    //         .slice(0, -1);
    // }
    const edit = {
      ...values,
      id: data,
      date: format(values?.Visitlocationlist[0]?.date, "yyyy-MM-dd"),
      Visitlocationlist: values?.Visitlocationlist?.map((item, index) => {
        return {
          id: data
            ? detailsform?.visitinglocation[index]?.id
              ? detailsform?.visitinglocation[index]?.id
              : 0
            : 0,
          locationGroupId: item?.locationGroupId,
          locationId: item?.locationId,
          employeeId: item?.employeeId,
          visitingTime:
            `${format(item?.date, "yyyy-MM-dd")}` +
            "T" +
            `${format(item?.time, "HH:mm:ss")}`,
        };
      }),
      Visitlist: values?.Visitlist?.map((item, index) => {
        return {
          ...item,
          id: data
            ? detailsform?.visitingpeople[index]?.id
              ? detailsform?.visitingpeople[index]?.id
              : 0
            : 0,
          phoneNumber: item?.phoneNumber?.toString(),
        };
      }),
    };
    // console.log("values", edit);
    data ? Edit(edit) : Create(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      styles={{ width: "90%", maxWidth: "90%" }}
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Edit Visitors Request" : "Create Visitors Request"}
      ButtonLabel={data ? "Edit" : "Submit"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateVisitorsComp
          setFormValues={setFormValues}
          formValues={formValues}
          data={detailsform}
        />
      }
    />
  );
};

export { CreateVisitors };
