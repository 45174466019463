import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalHRDesk } from "../Components/hrdeskapproval/PendingApprovalHRDesk";
import { AllRequestHRDesk } from "../Components/hrdeskapproval/AllRequestHRDesk";
import { HrdeskApproved } from "../Components/hrdeskapproval/HrdeskApproved";
import { HoldRequestHrdesk } from "../Components/hrdeskapproval/HoldRequestHrdesk";
import { RejectedHrdesk } from "../Components/hrdeskapproval/RejectedHrdesk ";
import { PendingApprovalSkills } from "../Components/SkillsApproval/PendingApprovalSkills";
import { ApprovedApprovalSkills } from "../Components/SkillsApproval/ApprovedApprovalSkills";
import { RejectedApprovalSkills } from "../Components/SkillsApproval/RejectedApprovalSkills";
import { AllRequestApprovalSkills } from "../Components/SkillsApproval/AllRequestApprovalSkills";

const ApprovalSkills = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalSkills
            title={"My Skills"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <ApprovedApprovalSkills
          title={"My Skills"}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <RejectedApprovalSkills
            title={"My Skills"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}

      {tabIndex === 3 && (
        <>
          <AllRequestApprovalSkills
            title={"My Skills"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalSkills };
