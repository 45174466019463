import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";

const SelectCustomEmployee = ({
  options,
  name,
  onChange,
  styles,
  styl,
  customClass,
  fullWidth,
  isDisabled,
  onMenuScrollToBottom,
  values,
  multi,
  ...props
}) => {
  const [clear, setClear] = useState(false);
  useEffect(() => {
    if (values) {
      if (values.length > 0) setClear(true);
      else setClear(false);
    } else setClear(false);
  }, [values]);

  return (
    <div className={`selectCustom ${customClass}`} style={styles}>
      <Select
        {...props}
        // isClearable={true}
        onMenuScrollToBottom={onMenuScrollToBottom}
        // className="react-select-container home-css-pagination"
        className="react-select-container"
        classNamePrefix="react-select"
        menuShouldBlockScroll={true}
        menuPortalTarget={document.body}
        // clearable={props?.searchable ? clear : false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--primary-hover)",
            primary: "var(--primary)",
            primary50: "var(--primary-hover)",
          },
        })}
        isMulti={multi}
        // color={"#1296b038"}
        value={values}
        options={options}
        name={name}
        onChange={onChange}
        disabled={isDisabled ?? false}
      />
    </div>
  );
};
export { SelectCustomEmployee };
