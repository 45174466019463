import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useState } from "react";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { useNavigate } from "react-router-dom";
import SalaryMapEmployee from "./SalaryMapEmployee";
import { IconButton, Tooltip } from "@mui/material";

const SalaryDetails = ({ editId, employee, empDetail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [detailsForm, setDetailsform] = useState([]);
  let detailUrl = "/payroll/employee/details/revisesalary";
  let salryUrl = "/payroll/employee/details/editsalary";
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `EmployeeSalaryDetails/Details/${editId}`
      );
      if (result?.data?.data) setDetailsform(result?.data?.data);
      else {
        setDetailsform(false);
      }
    } catch (error) {
      dispatch(
        showToast({
          text:
            error?.response?.data ==
              "There is no Record associated with given employee"
              ? "Kindly Map Salary Template "
              : error?.response?.data,
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      dispatch(isLoading(false));
    }
  };
  useEffect(() => {
    getFormDetails();
    document.title = `PeopleSol - Salary Details `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <div
        className="mainOverviewContainer"
        style={{ height: "calc(100% - 132px)", overflow: detailsForm?.length ? "auto" : "hidden" }}
      >
        {detailsForm.length ? (
          <div className="overviewContainer">
            <>
              <div className="headingContainer">
                <h6>Salary Details ({employee})</h6>
                <div>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={
                        salryUrl
                          ? () => {
                            navigate(`${salryUrl}`, {
                              state: { id: editId, data: detailsForm, employee: employee },
                            });
                          }
                          : () => { }
                      }
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.15"
                          d="M0.880371 4.60156C0.880371 2.39242 2.67123 0.601562 4.88037 0.601562H16.8804C19.0895 0.601562 20.8804 2.39242 20.8804 4.60156V16.6016C20.8804 18.8107 19.0895 20.6016 16.8804 20.6016H4.88037C2.67123 20.6016 0.880371 18.8107 0.880371 16.6016V4.60156Z"
                          fill="#FB7158"
                        />
                        <line
                          x1="11.2572"
                          y1="15.2016"
                          x2="15.3215"
                          y2="15.2016"
                          stroke="#FB7158"
                          stroke-width="0.8"
                          stroke-linecap="round"
                        />
                        <path
                          d="M11.1945 7.91611L6.03955 13.0366V15.0812H8.11668L13.2134 9.952M11.1945 7.91611L12.5152 6.60421C13.0732 6.04992 13.9744 6.05143 14.5306 6.60758V6.60758C15.0868 7.16381 15.0882 8.06518 14.5338 8.62317L13.2134 9.952M11.1945 7.91611L13.2134 9.952"
                          stroke="#FB7158"
                          stroke-width="0.8"
                        />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div
                className="overviewMainContent"
                style={{ height: "calc(100% - 83px)" }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <p>Annual CTC</p>
                    <h3 style={{ color: "#B69FB3" }}>{`${detailsForm[0]?.costToCompanyAnnual} per year`}</h3>
                  </div>
                  <div style={{ flex: 1.04 }}>
                    <p>Monthly CTC</p>
                    <h3 style={{ color: "#5BC4BF" }}>{`${detailsForm[0]?.costToCompanyMonthly} per month`}</h3>
                  </div>
                </div>
                <div
                  className="taxTableContainer"
                  style={{ height: "calc(100% - 59px)", overflow: "hidden" }}
                >
                  <div
                    className="taxTableRow"
                    style={{ backgroundColor: "var(--table-back)" }}
                  >
                    <div className="taxTableCell">Salary Components</div>
                    <div className="taxTableCell">Monthly Amount</div>
                    <div className="taxTableCell">Annual Amount</div>
                  </div>
                  {detailsForm?.map((items, index) => {
                    return (
                      <div className="taxTableRow">
                        <div className="taxTableCell" style={{ flexDirection: "row", display: "flex" }}>
                          {items?.displayName}
                          {!items.isEmployerContributionInCTC && items.salaryComponents === "Deductions" ? <div style={{ color: "red" }}>{"**"}</div> : null}
                        </div>
                        <div className="taxTableCell">
                          {items?.monthlyAmount}
                        </div>
                        <div className="taxTableCell">
                          {items?.annualAmount}
                        </div>
                      </div>
                    );
                  })}

                  <div
                    className="taxTableRow"
                    style={{
                      borderTop: "1px solid var(--grey)",
                      borderBottom: "1px solid var(--grey)",
                      marginTop: "12px",
                      color: "var(--primary)",
                      fontSize: "15px",
                    }}
                  >
                    <div className="taxTableCell" style={{ fontWeight: 500 }}>
                      Total Net Payable
                    </div>
                    <div className="taxTableCell" style={{ fontWeight: 500 }}>
                      {detailsForm[0]?.costToCompanyMonthly}
                    </div>
                    <div className="taxTableCell" style={{ fontWeight: 500 }}>
                      {detailsForm[0]?.costToCompanyAnnual}
                    </div>
                  </div>
                  <div style={{ padding: 15, borderRadius: 10, backgroundColor: "#FFF4E5", marginTop: 10, marginBottom: 10, width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ color: "red", marginRight: 5 }}>**</div>
                      <div style={{ color: "black", fontWeight: 400, fontSize: 14 }}>Note: There will be subject to deduction as per support policy on behalf of the liable benefits.</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        ) : (
          <SalaryMapEmployee eId={editId} employee={employee} empDetail={empDetail} />
        )}
        {detailsForm.length ? (
          <div className="overviewContainer" style={{ height: "30%" }}>
            <div className="headingContainer">
              <h6>Salary Revision History ({employee})</h6>
              <button
                style={{
                  background: "var(--primary)",
                  color: "white",
                  width: "8%",
                  alignSelf: "end",
                  padding: '6px',
                  margin: 0
                }}
                className="button primary"
                onClick={
                  detailUrl
                    ? () => {
                      navigate(`${detailUrl}`, {
                        state: { id: detailsForm, employee: employee },
                      });
                    }
                    : () => { }
                }
              >
                Revise
              </button>
            </div>
            <div className="overviewMainContent">
              <div
                className="taxTableRow"
                style={{ backgroundColor: "var(--table-back)" }}
              >
                <div className="taxTableCell">Previous CTC</div>
                <div className="taxTableCell">Revised CTC</div>
                <div className="taxTableCell">Effective From</div>
              </div>
              <div className="taxTableRow">
                <div className="taxTableCell">{"₹ 210,834"}</div>
                <div className="taxTableCell">{"₹ 260,834"}</div>
                <div className="taxTableCell">{"Dec 2022"}</div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
};

export { SalaryDetails };
