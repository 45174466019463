import React from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useState } from "react";
import { postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import SocialThumbLike from "../../../../../Assets/SocialThumbLike.svg";
import SocialHeartLike from "../../../../../Assets/SocialHeartLike.svg";
import SocialClapLike from "../../../../../Assets/SocialClapLike.svg";
import SocialKnowledgeLike from "../../../../../Assets/SocialKnowledgeLike.svg";
import SocialPostHateIcon from "../../../../../Assets/SocialPostHateIcon.svg";
import SocialPostBadIcon from "../../../../../Assets/SocialPostBadIcon.svg";
import SocialPostSadIcon from "../../../../../Assets/SocialPostSadIcon.svg";
import SocialPostAngryIcon from "../../../../../Assets/SocialPostAngryIcon.svg";
import SocialRoundThumbLike from "../../../../../Assets/SocialRoundThumbLike.svg";
import SocialRoundHeartLike from "../../../../../Assets/SocialRoundHeartLike.svg";
import SocialRoundClapLike from "../../../../../Assets/SocialRoundClapLike.svg";
import SocialRoundKnowledgeLike from "../../../../../Assets/SocialRoundKnowledgeLike.svg";
import SocialRoundBadLike from "../../../../../Assets/SocialRoundBadIcon.svg";
import SocialRoundSadLike from "../../../../../Assets/SocialRoundSadIcon.svg";
import SocialRoundHateLike from "../../../../../Assets/SocialRoundHateIcon.svg";
import SocialRoundAngryLike from "../../../../../Assets/SocialRoundAngryIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";

const LikeDislike = ({
  id,
  setTotalDisLikesWatching,
  totalDisLikesWatching,
  setTotalLikesWatching,
  totalLikesWatching,
}) => {
  const [seeLike, setSeeLike] = useState(totalLikesWatching);
  const [seeDislike, setSeeDislike] = useState(totalDisLikesWatching);
  const [likeListById, setlikeListById] = useState([]);
  const [disLikeListById, setDislikeListById] = useState([]);
  const [allLikeList, setAllLikeList] = useState(true);
  const [thumbLikeList, setThumbLikeList] = useState(false);
  const [clapLikeList, setclapLikeList] = useState(false);
  const [heartLikeList, setHeartLikeList] = useState(false);
  const [knowledgeLikeList, setKnowledgeLikeList] = useState(false);

  const [allDisLikeList, setAllDisLikeList] = useState(true);
  const [hateDisLikeList, setHateDisLikeList] = useState(false);
  const [sadDisLikeList, setSadDisLikeList] = useState(false);
  const [badDisLikeList, setBadDisLikeList] = useState(false);
  const [angryDisLikeList, setAngryDisLikeList] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredLike, setFillteredLike] = useState({
    heart: 0,
    thumb: 0,
    clap: 0,
    knowledge: 0,
  });
  const [filteredDisLike, setFillteredDisLike] = useState({
    angry: 0,
    sad: 0,
    hate: 0,
    bad: 0,
  });
  const getFillteredDisLikeDataById = async (dislikeId) => {
    try {
      const dislikeData = await postRequest(`SocialPost/AllDisLikeList`, {
        createdOn: null,
        postId: dislikeId,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 10,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      setFillteredDisLike({
        angry: dislikeData?.data?.summary?.counts?.angry ?? 0,
        sad: dislikeData?.data?.summary?.counts?.sad ?? 0,
        bad: dislikeData?.data?.summary?.counts?.bad ?? 0,
        hate: dislikeData?.data?.summary?.counts?.hate ?? 0,
      });
      let listData = [];
      dislikeData?.data?.data?.map((item) => {
        listData.push(item);
      });
      setDislikeListById(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getFillteredLikeDataById = async (likeId) => {
    try {
      const likeData = await postRequest(`SocialPost/AllLikeList`, {
        createdOn: null,
        postId: likeId,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 10,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      setFillteredLike({
        heart: likeData?.data?.summary?.counts?.heart ?? 0,
        thumb: likeData?.data?.summary?.counts?.thumb ?? 0,
        clap: likeData?.data?.summary?.counts?.clap ?? 0,
        knowledge: likeData?.data?.summary?.counts?.knowledge ?? 0,
      });
      let listData = [];
      likeData?.data?.data?.map((item) => {
        listData.push(item);
      });
      setlikeListById(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getFillteredDisLikeDataById(id);
    getFillteredLikeDataById(id);
  }, []);
  return (
    <div>
      {seeLike && (
        <Popup
          firstClassName="likePopupBox1"
          secondClassName="likePopupBox2"
          contentClassName={likeListById?.length > 0 ? "likePopupContent" : ""}
          popupHeading={
            <div className="likeHeaderContainer">
              <div className="likeHeaderWrapper">
                <h2>People who liked your post ({likeListById?.length})</h2>
                <div className="likeListWithImgContainer">
                  <div
                    className={`likeList ${allLikeList ? "active" : ""}`}
                    onClick={() => {
                      setAllLikeList(true);
                      setclapLikeList(false);
                      setThumbLikeList(false);
                      setHeartLikeList(false);
                      setKnowledgeLikeList(false);
                    }}
                  >
                    <p>All {likeListById?.length}</p>
                  </div>
                  <div
                    className={`likeListWithThumb ${
                      thumbLikeList ? "active" : ""
                    }`}
                    onClick={() => {
                      setAllLikeList(false);
                      setclapLikeList(false);
                      setThumbLikeList(true);
                      setHeartLikeList(false);
                      setKnowledgeLikeList(false);
                    }}
                  >
                    <img src={SocialThumbLike} alt="" />
                    <span>{filteredLike.thumb}</span>
                  </div>
                  <div
                    className={`likeListWithClap ${
                      clapLikeList ? "active" : ""
                    }`}
                    onClick={() => {
                      setAllLikeList(false);
                      setclapLikeList(true);
                      setThumbLikeList(false);
                      setHeartLikeList(false);
                      setKnowledgeLikeList(false);
                    }}
                  >
                    <img src={SocialClapLike} alt="" />
                    <span>{filteredLike.clap}</span>
                  </div>
                  <div
                    className={`likeListWithHeart ${
                      heartLikeList ? "active" : ""
                    }`}
                    onClick={() => {
                      setAllLikeList(false);
                      setclapLikeList(false);
                      setThumbLikeList(false);
                      setHeartLikeList(true);
                      setKnowledgeLikeList(false);
                    }}
                  >
                    <img src={SocialHeartLike} alt="" />
                    <span>{filteredLike.heart}</span>
                  </div>
                  <div
                    className={`likeListWithKnowledge ${
                      knowledgeLikeList ? "active" : ""
                    }`}
                    onClick={() => {
                      setAllLikeList(false);
                      setclapLikeList(false);
                      setThumbLikeList(false);
                      setHeartLikeList(false);
                      setKnowledgeLikeList(true);
                    }}
                  >
                    <img src={SocialKnowledgeLike} alt="" />
                    <span>{filteredLike.knowledge}</span>
                  </div>
                </div>
              </div>
            </div>
          }
          content={
            <div className="totalLikePopupContainer">
              {likeListById.length > 0 &&
                allLikeList &&
                likeListById?.map((d, i) => {
                  return (
                    <div className="totalLikePopupList">
                      <div
                        className="totalLikePopupImg"
                        onClick={() => {
                          navigate(
                            `/home/social/userprofile/${d?.item?.employeeId}`
                          );
                        }}
                      >
                        <div
                          style={{ margin: "0" }}
                          className={`${
                            d?.item?.image
                              ? "socialProfilePicContainer"
                              : "socialCustomProfilePic"
                          }`}
                        >
                          {!d?.item?.image ? (
                            getUserInitials(
                              getDisplayName(d?.item?.nameWithPersonNumber)
                            )
                          ) : (
                            <img
                              className="socialProfilePic"
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                profileImages +
                                d?.item?.image
                              }
                              alt="Profile"
                              onError={(e) => {
                                e.target.src = ProfileAvatar;
                              }}
                            />
                          )}
                        </div>

                        <div className="likedIcon">
                          {(d?.item?.likedType === "clap" && (
                            <img src={SocialRoundClapLike} alt="" />
                          )) ||
                            (d?.item?.likedType === "heart" && (
                              <img src={SocialRoundHeartLike} alt="" />
                            )) ||
                            (d?.item?.likedType === "knowledge" && (
                              <img src={SocialRoundKnowledgeLike} alt="" />
                            )) ||
                            (d?.item?.likedType === "thumb" && (
                              <img src={SocialRoundThumbLike} alt="" />
                            ))}
                        </div>
                      </div>
                      <div className="totalLikePopupText">
                        <h5>{getDisplayName(d?.item?.nameWithPersonNumber)}</h5>
                        <p>{d?.item?.roleName}</p>
                      </div>
                    </div>
                  );
                })}
              {likeListById.length > 0 &&
                thumbLikeList &&
                likeListById?.map((d, i) => {
                  if (d?.item?.likedType === "thumb") {
                    return (
                      <div className="totalLikePopupList">
                        <div
                          className="totalLikePopupImg"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            style={{ margin: "0" }}
                            className={`${
                              d?.item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <div className="likedIcon">
                            <img src={SocialRoundThumbLike} alt="" />
                          </div>
                        </div>
                        <div className="totalLikePopupText">
                          <h5>
                            {getDisplayName(d?.item?.nameWithPersonNumber)}
                          </h5>
                          <p>{d?.item?.roleName}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {likeListById.length > 0 &&
                clapLikeList &&
                likeListById?.map((d, i) => {
                  if (d?.item?.likedType === "clap") {
                    return (
                      <div className="totalLikePopupList">
                        <div
                          className="totalLikePopupImg"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            style={{ margin: "0" }}
                            className={`${
                              d?.item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <div className="likedIcon">
                            <img src={SocialRoundClapLike} alt="" />
                          </div>
                        </div>
                        <div className="totalLikePopupText">
                          <h5>
                            {getDisplayName(d?.item?.nameWithPersonNumber)}
                          </h5>
                          <p>{d?.item?.roleName}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {likeListById.length > 0 &&
                heartLikeList &&
                likeListById?.map((d, i) => {
                  if (d?.item?.likedType === "heart") {
                    return (
                      <div className="totalLikePopupList">
                        <div
                          className="totalLikePopupImg"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            style={{ margin: "0" }}
                            className={`${
                              d?.item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <div className="likedIcon">
                            <img src={SocialRoundHeartLike} alt="" />
                          </div>
                        </div>
                        <div className="totalLikePopupText">
                          <h5>
                            {getDisplayName(d?.item?.nameWithPersonNumber)}
                          </h5>
                          <p>{d?.item?.roleName}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {likeListById.length > 0 &&
                knowledgeLikeList &&
                likeListById?.map((d, i) => {
                  if (d?.item?.likedType === "knowledge") {
                    return (
                      <div className="totalLikePopupList">
                        <div
                          className="totalLikePopupImg"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            style={{ margin: "0" }}
                            className={`${
                              d?.item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <div className="likedIcon">
                            <img src={SocialRoundKnowledgeLike} alt="" />
                          </div>
                        </div>
                        <div className="totalLikePopupText">
                          <h5>
                            {getDisplayName(d?.item?.nameWithPersonNumber)}
                          </h5>
                          <p>{d?.item?.roleName}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {likeListById.length <= 0 && (
                <div className="noLike">
                  <h3>No Like!!</h3>
                </div>
              )}
            </div>
          }
          handleClose={() => {
            setSeeLike(false);
            setTotalLikesWatching(false);
          }}
        />
      )}
      {seeDislike && (
        <Popup
          firstClassName="likePopupBox1"
          secondClassName="likePopupBox2"
          contentClassName={
            disLikeListById?.length > 0 ? "likePopupContent" : ""
          }
          popupHeading={
            <div className="likeHeaderContainer">
              <div className="likeHeaderWrapper">
                <h2>People Dislike Reactions ({disLikeListById?.length})</h2>
                <div className="likeListWithImgContainer">
                  <div
                    className={`likeList ${allDisLikeList ? "active" : ""}`}
                    onClick={() => {
                      setAllDisLikeList(true);
                      setHateDisLikeList(false);
                      setSadDisLikeList(false);
                      setBadDisLikeList(false);
                      setAngryDisLikeList(false);
                    }}
                  >
                    <p>All {disLikeListById?.length}</p>
                  </div>
                  <div
                    className={`likeListWithBad ${
                      badDisLikeList ? "active" : ""
                    }`}
                    onClick={() => {
                      setAllDisLikeList(false);
                      setHateDisLikeList(false);
                      setSadDisLikeList(false);
                      setBadDisLikeList(true);
                      setAngryDisLikeList(false);
                    }}
                  >
                    <img src={SocialPostBadIcon} alt="" />
                    <span>{filteredDisLike.bad}</span>
                  </div>
                  <div
                    className={`likeListWithAngry ${
                      angryDisLikeList ? "active" : ""
                    }`}
                    onClick={() => {
                      setAllDisLikeList(false);
                      setHateDisLikeList(false);
                      setSadDisLikeList(false);
                      setBadDisLikeList(false);
                      setAngryDisLikeList(true);
                    }}
                  >
                    <img src={SocialPostAngryIcon} alt="" />
                    <span>{filteredDisLike.angry}</span>
                  </div>
                  <div
                    className={`likeListWithHeart ${
                      sadDisLikeList ? "active" : ""
                    }`}
                    onClick={() => {
                      setAllDisLikeList(false);
                      setHateDisLikeList(false);
                      setSadDisLikeList(true);
                      setBadDisLikeList(false);
                      setAngryDisLikeList(false);
                    }}
                  >
                    <img src={SocialPostSadIcon} alt="" />
                    <span>{filteredDisLike.sad}</span>
                  </div>
                  <div
                    className={`likeListWithKnowledge ${
                      hateDisLikeList ? "active" : ""
                    }`}
                    onClick={() => {
                      setAllDisLikeList(false);
                      setHateDisLikeList(true);
                      setSadDisLikeList(false);
                      setBadDisLikeList(false);
                      setAngryDisLikeList(false);
                    }}
                  >
                    <img src={SocialPostHateIcon} alt="" />
                    <span>{filteredDisLike.hate}</span>
                  </div>
                </div>
              </div>
            </div>
          }
          content={
            <div className="totalLikePopupContainer">
              {disLikeListById.length > 0 &&
                allDisLikeList &&
                disLikeListById?.map((d, i) => {
                  return (
                    <div className="totalLikePopupList">
                      <div
                        className="totalLikePopupImg"
                        onClick={() => {
                          navigate(
                            `/home/social/userprofile/${d?.item?.employeeId}`
                          );
                        }}
                      >
                        <div
                          style={{ margin: "0" }}
                          className={`${
                            d?.item?.image
                              ? "socialProfilePicContainer"
                              : "socialCustomProfilePic"
                          }`}
                        >
                          {!d?.item?.image ? (
                            getUserInitials(
                              getDisplayName(d?.item?.nameWithPersonNumber)
                            )
                          ) : (
                            <img
                              className="socialProfilePic"
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                profileImages +
                                d?.item?.image
                              }
                              alt="Profile"
                              onError={(e) => {
                                e.target.src = ProfileAvatar;
                              }}
                            />
                          )}
                        </div>
                        <div className="likedIcon">
                          {(d?.item?.disLikedType === "sad" && (
                            <img src={SocialRoundSadLike} alt="" />
                          )) ||
                            (d?.item?.disLikedType === "bad" && (
                              <img src={SocialRoundBadLike} alt="" />
                            )) ||
                            (d?.item?.disLikedType === "angry" && (
                              <img src={SocialRoundAngryLike} alt="" />
                            )) ||
                            (d?.item?.disLikedType === "hate" && (
                              <img src={SocialRoundHateLike} alt="" />
                            ))}
                        </div>
                      </div>

                      <div className="totalLikePopupText">
                        <h5>{getDisplayName(d?.item?.nameWithPersonNumber)}</h5>
                        <p>{d?.item?.roleName}</p>
                      </div>
                    </div>
                  );
                })}
              {disLikeListById.length > 0 &&
                sadDisLikeList &&
                disLikeListById?.map((d, i) => {
                  if (d?.item?.disLikedType === "sad") {
                    return (
                      <div className="totalLikePopupList">
                        <div
                          className="totalLikePopupImg"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            style={{ margin: "0" }}
                            className={`${
                              d?.item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <div className="likedIcon">
                            <img src={SocialRoundSadLike} alt="" />
                          </div>
                        </div>

                        <div className="totalLikePopupText">
                          <h5>
                            {getDisplayName(d?.item?.nameWithPersonNumber)}
                          </h5>
                          <p>{d?.item?.roleName}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {disLikeListById.length > 0 &&
                badDisLikeList &&
                disLikeListById?.map((d, i) => {
                  if (d?.item?.disLikedType === "bad") {
                    return (
                      <div className="totalLikePopupList">
                        <div
                          className="totalLikePopupImg"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            style={{ margin: "0" }}
                            className={`${
                              d?.item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <div className="likedIcon">
                            <img src={SocialRoundBadLike} alt="" />
                          </div>
                        </div>

                        <div className="totalLikePopupText">
                          <h5>
                            {getDisplayName(d?.item?.nameWithPersonNumber)}
                          </h5>
                          <p>{d?.item?.roleName}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {disLikeListById.length > 0 &&
                hateDisLikeList &&
                disLikeListById?.map((d, i) => {
                  if (d?.item?.disLikedType === "hate") {
                    return (
                      <div className="totalLikePopupList">
                        <div
                          className="totalLikePopupImg"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            style={{ margin: "0" }}
                            className={`${
                              d?.item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <div className="likedIcon">
                            <img src={SocialRoundHateLike} alt="" />
                          </div>
                        </div>

                        <div className="totalLikePopupText">
                          <h5>
                            {getDisplayName(d?.item?.nameWithPersonNumber)}
                          </h5>
                          <p>{d?.item?.roleName}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {disLikeListById.length > 0 &&
                angryDisLikeList &&
                disLikeListById?.map((d, i) => {
                  if (d?.item?.disLikedType === "angry") {
                    return (
                      <div className="totalLikePopupList">
                        <div
                          className="totalLikePopupImg"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            style={{ margin: "0" }}
                            className={`${
                              d?.item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <div className="likedIcon">
                            <img src={SocialRoundAngryLike} alt="" />
                          </div>
                        </div>

                        <div className="totalLikePopupText">
                          <h5>
                            {getDisplayName(d?.item?.nameWithPersonNumber)}
                          </h5>
                          <p>{d?.item?.roleName}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {disLikeListById.length <= 0 && (
                <div className="noLike">
                  <h3>No Like!!</h3>
                </div>
              )}
            </div>
          }
          handleClose={() => {
            setSeeDislike(false);
            setTotalDisLikesWatching(false);
          }}
        />
      )}
    </div>
  );
};

export default LikeDislike;
