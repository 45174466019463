import React, { useEffect, useState } from 'react'
import { FormRadioCustomReward } from '../../../Components/FormComponent/FormRadioCustomReward'
import { TextAreaCustom } from '../../../Components/FormComponent/TextAreaCustom'

const FormRewardsRadioComp = ({ firstposition, ind, detail, editID, values, initialValues, setInitialValues }) => {
    const [textValue, setTextValue] = useState("")
    console.log("object textValue", textValue)
    useEffect(() => {
        setInitialValues((prev) => {
            const objFinal = { ...values }
            objFinal.RewardsConfigWinnerposition[ind][`Position${ind + 1}textArea`] = textValue;
            return objFinal
        })
    }, [textValue])
    useEffect(() => {
        if(editID ){
            setTextValue(initialValues?.RewardsConfigWinnerposition[ind]?.[`Position${ind + 1}textArea`])
        }
        
    }, [detail])
    return (
        <div className="radioFormikcustomflex">
            <FormRadioCustomReward
                options={firstposition}
                ind={ind}
                name={`RewardsConfigWinnerposition[${ind}].Position${ind + 1}`} // Dynamic name
                // name="FirstPosistion"
                setTextValue={setTextValue}
                textValue={textValue}
                editstyle={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "baseline",
                }}
            />
            <div >
            </div>
        </div>
    )
}

export default FormRewardsRadioComp
