import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { useEffect } from "react";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { TimeTask } from "./TimeTask";
import { Form, Formik } from "formik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";

export const TimeLineTask = ({ id, details }) => {
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const getEmailNotification = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Tasks/Timeline/${id}`);
      // console.log("result?.data",result?.data)
      setData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - TimeLine Task`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const PostApRejHol = async (values) => {
    try {
      setLoading(true);
      await postRequest("Tasks/UpdateDetails", values);
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      getEmailNotification();
      setLoading(false);
    }
  };
  const onSubmit = async (values) => {
    PostApRejHol(values);
  };
  // console.log("data",data)
  useEffect(() => {
    getEmailNotification();
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <div className="timeline-container" style={{flexDirection:'row',gap:'10px'}}>
        <TimeTask data={data} />
        <div className="comentCont">
          <p className="labelCom">Comments</p>
          <Formik
            initialValues={{ Comment: "", TaskId: details?._projecttasks?.id }}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <div style={{ padding: "0px 37px 0 13px" }}>
                  <TextAreaFormik
                    name="Comment"
                    style={{ height: "200px" }}
                    placeholder="Enter comment..."
                  />
                </div>
                <div className="createBtnTime" style={{ position: "unset" }}>
                  <div>
                    <button
                      className="button secondaryButton"
                      onClick={() =>
                        formik.resetForm({ values: { Comment: "" } })
                      }
                      type="reset"
                    >
                      Reset
                    </button>
                  </div>
                  <div>
                    <button className="button primaryButton" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
};
