import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { SelectFormikMulti } from "../../../../../Components/FormComponent/SelectFormikMulti";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import DropdownInfiniteScroll from "../../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";
const option = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
export const ProblemForm = ({ id, data, type, color, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});
  const [employeeList, setEmployeeList] = useState([]);
  const [initialValues, setInitialValues] = useState({
    Description: "",
    Priority: "",
    BusinessImpact: "",
    TechnologyImpact: "",
    CostImpact: "",
    IncidentTickets: "",
    ProblemOwnerEmployeeId: "",
  });
  const [incident, setIncident] = useState([]);
  const [dataDetails, setDataDetails] = useState(false);
  const getDetailsProblem = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`problemRequests/details?ID=${id}`);
      setDataDetails(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id !== 0) {
      if (color !== "Change") getDetailsProblem();
    }
  }, []);
  //console.log("data",dataDetails)
  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        "problemrequests/GetProblemRequestOwners"
      );
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      // problemrequests/GetProblemRequestOwners
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getDescriptionData = async (id) => {
    try {
      const result = await getRequest(`ItHelpdesks/ticketdetails?ID=${id}`);
      setInitialValues((prev) => {
        const obj = { ...prev };
        obj.Description = result?.data?.data?.employeeTicket?.description;
        return obj;
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Problem`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getIncidentTicket = async () => {
    try {
      const result = await getRequest("problemrequests/GetIncidentTickets");
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      //  problemrequests/GetIncidentTickets
      setIncident(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const validationSchema = Yup.object({
    Description: Yup.string()
      .max(499, "Must be less than 500 characters")
      .required("Please Enter  Description"),
    Priority: Yup.string().required("Please Select Priority"),
    BusinessImpact: Yup.string().required("Please Select Business Impact"),
    TechnologyImpact: Yup.string().required("Please Select Technology Impact"),
    CostImpact: Yup.string().required("Please Select CostImpact"),
    IncidentTickets: Yup.string()
      // .min(1, `Please Select IncidentTickets `)
      .required(" Please Select Incident Tickets"),
    ProblemOwnerEmployeeId: Yup.string().required("Please Select Employee"),
  });
  const ProblemCreate = async (value) => {
    try {
      setLoading(true);
      if (color === "Change")
        await postRequest(
          `problemrequests/Create?changeRequestID=${id}`,
          value
        );
      else await postRequest(`problemrequests/Create`, value);
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };
  const ProblemEdit = async (value) => {
    try {
      setLoading(true);
      if (color === "Change")
        await postRequest(
          `problemrequests/Create?changeRequestID=${id}`,
          value
        );
      else await postRequest(`problemrequests/Edit`, value);

      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };

  useEffect(() => {
    getEmployeeData();
    getIncidentTicket();
  }, []);
  useEffect(() => {
    if (id !== 0) {
      setInitialValues({
        Description: dataDetails?.problemRequest?.description,
        Priority: dataDetails?.problemRequest?.priority,
        BusinessImpact: dataDetails?.problemRequest?.businessImpact,
        TechnologyImpact: dataDetails?.problemRequest?.technologyImpact,
        CostImpact: dataDetails?.problemRequest?.costImpact,
        // IncidentTickets: dataDetails?.incidentTickets?.map((item) => {
        //   return item.incidentTicketId;
        // }),
        IncidentTickets: dataDetails?.incidentTickets[0]?.incidentTicketId,
        ProblemOwnerEmployeeId:
          dataDetails?.problemRequest?.problemOwnerEmployeeId,
      });
    }
  }, [id]);
  const onSubmit = async (values) => {
    const create = {
      ...values,
      IncidentTickets: String(values?.IncidentTickets),
    };
    const edit = {
      ...values,
      id: Number(id),
      IncidentTickets: String(values?.IncidentTickets),
    };
    const edits = {
      ...values,
      changeRequestID: Number(id),
      IncidentTickets: String(values?.IncidentTickets),
    };
    if (id !== 0) {
      if (color === "Change") ProblemEdit(edits);
      else ProblemEdit(edit);
    } else ProblemCreate(create);
  };
  // let arrSpec = [];
  // // dataDetails?.incidentTickets?.map((items) => {
  // //   arrSpec.push({ label: items.text, value: items.id });
  // // });
  // dataDetails?.incidentTickets?.map((items) => {
  //   arrSpec.push({ label: items.text, value: items.id });
  // });
  console.log("object formValues", formValues);
  useEffect(() => {
    if (typeof formValues?.IncidentTickets === "number")
      getDescriptionData(formValues?.IncidentTickets);
  }, [formValues?.IncidentTickets]);

  //console.log("dataDetails?.IncidentTickets",dataDetails)
  if (loading) {
    return <Loader />;
  } else
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          setFormValues(values);
          return (
            <Form style={{ overflow: "hidden" }}>
              <div style={{ overflow: "auto", height: 500, paddingLeft: 20, paddingRight: 20, width: 700 }}>

                <div className="formcustom twoColumn">
                  <div>
                    <LabelCustom
                      labelName={"Incident Ticket(s)"}
                      mandatory={true}
                    />
                    <DropdownInfiniteScroll
                      name="IncidentTickets"
                      placeholder={"Select Incident Tickets"}
                      urlProp={"problemrequests/GetIncidentTickets"}
                    />
                  </div>
                  <div>
                    <LabelCustom labelName={"Priority"} mandatory={true} />
                    <SelectForm
                      name="Priority"
                      options={option}
                      placeholder="Select Change Type"
                    />
                  </div>
                  <div>
                    <LabelCustom labelName={"Business Impact"} mandatory={true} />
                    <SelectForm name="BusinessImpact" options={option} />
                  </div>
                  <div>
                    <LabelCustom
                      labelName={"Technology Impact"}
                      mandatory={true}
                    />
                    <SelectForm name="TechnologyImpact" options={option} />
                  </div>
                  <div>
                    <LabelCustom labelName={"Cost Impact"} mandatory={true} />
                    <SelectForm name="CostImpact" options={option} />
                  </div>

                  <div>
                    <LabelCustom labelName={"Problem Owner"} mandatory={true} />
                    <SelectForm
                      placeholder={"Employee"}
                      name="ProblemOwnerEmployeeId"
                      options={employeeList}
                    />
                  </div>
                  <div>
                    <LabelCustom
                      labelName={"Problem Description "}
                      mandatory={true}
                      maxLength={500}
                    />
                    <TextAreaFormik
                      maxlength="500"
                      style={{ height: "100px" }}
                      name="Description"
                      placeholder={" Enter Description"}
                    />
                  </div>

                </div>

              </div>
              <div className="createFootCust" style={{ paddingRight: 20 }}>
                <button
                  type="button"
                  className="button secondaryButton"
                  onClick={handleClose}
                  style={{ borderStyle: "solid", borderColor: "rgba(18, 150, 176, 1)", borderWidth: 1 }}
                >
                  Cancel
                </button>
                <button
                  className="button primaryButton"
                  type="submit"
                  style={{ color: "white" }}
                >
                  {dataDetails
                    ? "Update Problem Request"
                    : "Raise Problem Request"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
};
