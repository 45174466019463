import * as Yup from "yup";

export const materialSchema = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Asset Material"),
  assetCategoryId: Yup.string().required("Please Select Asset Category"),
});
export const SpecSchema = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Name"),
});

export const assetSchema = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Asset Category"),
  code: Yup.string()
    .max(49, "Code must be less than 50 characters")
    .required("Please Enter Code"),
  assetTypeSpecificationIds: Yup.array()
    .min(1, `Please Select Atleast One Asset Category Specification `)
    .required(" required"),
});
