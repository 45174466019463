import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  service: false,
};
const ServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    closeService: (state) => {
      state.service = false;
    },
    OpenService: (state) => {
      state.service = true;
    },
  },
});
export const { closeService, OpenService } = ServiceSlice.actions;
export default ServiceSlice.reducer;
