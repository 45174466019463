import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import UploadImageDetail from "../../../Components/UploadDetail/UploadImageDetail";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailsCourses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`TrainingCourses/Details/${selectedId}`);
      setEventtData(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Chapter Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // //console.log("eventtData", eventtData);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Chapter Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            <DetailsStep
              detailTitle={"Course Title"}
              Details={eventtData?.trainingCourses?.category?.name}
            />
            <DetailsStep
              detailTitle={"Course Type"}
              Details={eventtData?.trainingCourses?.category?.type}
            />
            <DetailsStep
              detailTitle={"Chapter Title"}
              Details={eventtData?.trainingCourses?.title}
            />
            <DetailsStep
              detailTitle={"Chapter Description"}
              Details={eventtData?.trainingCourses?.shortDescription}
            />
            <DetailsStep
              detailTitle={"Chapter Start Date"}
              Details={
                eventtData?.trainingCourses?.startDate
                  ? format(
                      new Date(eventtData?.trainingCourses?.startDate),
                      "dd/MM/yyyy"
                    )
                  : "-"
              }
            />
            <DetailsStep
              detailTitle={"Chapter End Date"}
              Details={
                eventtData?.trainingCourses?.endDate
                  ? format(
                      new Date(eventtData?.trainingCourses?.endDate),
                      "dd/MM/yyyy"
                    )
                  : "-"
              }
            />
            <DetailsStep
              detailTitle={"Cover Image"}
              Details={
                <UploadImageDetail
                  filepath={`TrainingCourses/${eventtData?.trainingCourses?.coverImage}`}
                />
              }
            />
            <DetailsStep
              detailTitle={"Recommended Locations"}
              Details={
                !eventtData?.locationnames?.length > 0 &&
                !eventtData?.departments?.length > 0 &&
                !eventtData?.levels?.length > 0 &&
                !eventtData?.recomendedfor?.length > 0
                  ? "All"
                  : eventtData?.locationnames?.length > 0
                  ? eventtData?.locationnames
                  : "None"
              }
            />
            <DetailsStep
              detailTitle={"Recommended Departments"}
              Details={
                !eventtData?.locationnames?.length > 0 &&
                !eventtData?.departments?.length > 0 &&
                !eventtData?.levels?.length > 0 &&
                !eventtData?.recomendedfor?.length > 0
                  ? "All"
                  : eventtData?.departments?.length > 0
                  ? eventtData?.departments
                  : "None"
              }
            />
            <DetailsStep
              detailTitle={"Recommended Designations"}
              Details={
                !eventtData?.locationnames?.length > 0 &&
                !eventtData?.departments?.length > 0 &&
                !eventtData?.levels?.length > 0 &&
                !eventtData?.recomendedfor?.length > 0
                  ? "All"
                  : eventtData?.levels?.length > 0
                  ? eventtData?.levels
                  : "None"
              }
            />
            <DetailsStep
              detailTitle={"Recommended Employees"}
              Details={
                !eventtData?.locationnames?.length > 0 &&
                !eventtData?.departments?.length > 0 &&
                !eventtData?.levels?.length > 0 &&
                !eventtData?.recomendedfor?.length > 0
                  ? "All"
                  : eventtData?.recomendedfor?.length > 0
                  ? eventtData?.recomendedfor
                  : "None"
              }
            />
            <DetailsStep
              detailTitle={"Is Active?"}
              Details={
                eventtData?.trainingCourses?.isActive === true ? "Yes" : "No"
              }
            />

            <DetailsStep
              detailTitle={"Created On"}
              Details={
                eventtData?.trainingCourses?.createdOn
                  ? format(
                      new Date(eventtData?.trainingCourses?.createdOn),
                      "dd/MM/yyyy hh:mm a"
                    )
                  : null
              }
            />
            <DetailsStep
              detailTitle={"Last Updated On"}
              Details={
                eventtData?.trainingCourses?.lastUpdatedOn
                  ? format(
                      new Date(eventtData?.trainingCourses?.lastUpdatedOn),
                      "dd/MM/yyyy hh:mm a"
                    )
                  : null
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsCourses };
