/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";

function Facilities({ facilitiesList }) {
  const getFacilitiesOptions = (typeString) => {
    let tempArr = typeString.toString().replace("\r", "").split("\n");
    let listData = [];
    tempArr.map((item) => {
      if (item)
        if (item !== "" || item !== "")
          listData.push({ label: item, value: item });
      return null;
    });
    return listData;
  };
  useEffect(() => {
    document.title = `PeopleSol - Facilities`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="formcustom threeColumn">
      {facilitiesList &&
        facilitiesList.length > 0 &&
        facilitiesList?.map((facility, index) => {
          return (
            <div key={index}>
              <LabelCustom labelName={facility.name} />
              <SelectForm
                name={`item[${facility.id}]`}
                options={getFacilitiesOptions(facility.type)}
              />
            </div>
          );
        })}
    </div>
  );
}
export { Facilities };
