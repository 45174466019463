import React from "react";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { RadioFormik } from "../../../../../Components/FormComponent/RadioFormik";
import { TextAreaCustom } from "../../../../../Components/FormComponent/TextAreaCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { YesNoFormik } from "../../../../../Components/FormComponent/YesNoFormik";
import { useEffect } from "react";
const Options = [
  { label: "Excellent", value: "Excellent" },
  { label: "Good", value: "Good" },
  { label: "Fair", value: "Fair" },
  { label: "Poor", value: "Poor" },
];
const option = [
  { label: "Strongly Agree", value: "Strongly Agree" },
  { label: "Agree", value: "Agree" },
  { label: "Disagree", value: "Disagree" },
  { label: "Strongly Disagree", value: "Strongly Disagree" },
];
export const ExitInterview = () => {
  useEffect(() => {
    document.title = `PeopleSol - Exit Interview`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="detailReq">
      <div
        className="createHeader"
        style={{ position: "absolute", zIndex: "999" }}
      >
        <label>{"Exit Interview"}</label>
      </div>
      <div className="horizontalLine createLine"></div>
      <div style={{ padding: "12px ", background: "var(--form-background)" }}>
        <div className="formcustom twoColumn" style={{ margin: "23px 0" }}>
          <div>
            <LabelCustom
              labelName={"How would you rate your organisation "}
              mandatory={true}
              style={{ flex: 3 }}
            />
            <RadioFormik options={Options} name="Rating" />
          </div>
          <div>
            <LabelCustom
              labelName={"Kindly specify your Reason for leaving  the organisation ?"}
              mandatory={true}
              style={{ flex: 3 }}
            />
            <TextAreaFormik
              style={{ height: "80px" }}
              maxLength="500"
              placeholder="Please Enter Reason For Leaving"
              name="ReasonForLeaving"
            />
          </div>
          <div>
            <LabelCustom
              labelName={
                "Please mention 3 things that  you  most liked about the organisation."
              }
              mandatory={true}
              style={{ flex: 3 }}
            />
            <InputCustomFormik
              placeholder="Enter Things You Liked "
              name="BestThings1"
            />
            <InputCustomFormik
              placeholder="Enter Things You Liked "
              name="BestThings2"
            />
            <InputCustomFormik
              placeholder="Enter Things You Liked "
              name="BestThings3"
            />
          </div>
          <div>
            <LabelCustom
              labelName={
                "Please mention 3 things you didn't liked about the organisation."
              }
              mandatory={true}
              style={{ flex: 3 }}
            />
            <InputCustomFormik
              placeholder="Enter Things You Didn't Liked "
              name="ThingsDoNotLike1"
            />
            <InputCustomFormik
              placeholder="Enter Things You Didn't Liked "
              name="ThingsDoNotLike2"
            />
            <InputCustomFormik
              placeholder="Enter Things You Didn't Liked "
              name="ThingsDoNotLike3"
            />
          </div>
        </div>
        <div className="headingForm">
          How would you describe communication in the organisation? 
        </div>
        <div className="formcustom twoColumn">
          <div>
            <LabelCustom
              labelName={"Throughout the organisation"}
              mandatory={true}
              style={{ flex: 3 }}
            />
            <RadioFormik options={Options} name="ThroughoutTheCompany" />
          </div>
          <div>
            <LabelCustom
              labelName={"Between managers and management "}
              mandatory={true}
              style={{ flex: 3 }}
            />
            <RadioFormik options={Options} name="BetweenManagersAndStaff" />
          </div>
          <div>
            <LabelCustom
              labelName={"Between Department"}
              mandatory={true}
              style={{ flex: 3 }}
            />
            <RadioFormik options={Options} name="BetweenDepartments" />
          </div>
          <div>
            <LabelCustom
              labelName={"Within your department"}
              mandatory={true}
              style={{ flex: 3 }}
            />
            <RadioFormik options={Options} name="WithinYourDepartment" />
          </div>
          <div>
            <LabelCustom
              labelName={
                "Would you rejoin the organisation, if given an offer in future?"
              }
            />
            <YesNoFormik name="ReJoin" />
          </div>
          <div>
            <LabelCustom labelName={"On What Terms?"} />
            <TextAreaFormik
              style={{ height: "80px" }}
              maxLength="500"
              name="OnWhatTerms"
              placeholder="Enter Description"
            />
          </div>
          <div>
            <LabelCustom
              labelName={
                "Is there anything the organisation could have done to prevent you from leaving? "
              }
            />
            <TextAreaFormik
              style={{ height: "80px" }}
              maxLength="500"
              placeholder="Enter Description"
              name="IsanythingCompanyDoneAnythingtoPreventYouFromLeaving"
            />
          </div>
        </div>
        <div className="headingForm">
          Please complete the below questions and provide feedback 
        </div>
        <div className="formcustom twoColumn">
          <div>
            <LabelCustom
              labelName={"Your orientation to the job was effective ?"}
            />
            <RadioFormik name="MyOrientationToTheJob" options={option} />
          </div>
          <div>
            <LabelCustom labelName={"Your skills have been used effectively ?"} />
            <RadioFormik name="mySkillsUsedEffectively" options={option} />
          </div>
          <div>
            <LabelCustom
              labelName={"You have been provided all useful information, on time ?"}
            />
            <RadioFormik
              name="providedAllUsefulInformationOnTime"
              options={option}
            />
          </div>
          <div>
            <LabelCustom
              labelName={"Your work schedule met your professional needs ?"}
            />
            <RadioFormik
              name="workScheduleMetmyProfessionalNeeds"
              options={option}
            />
          </div>
          <div>
            <LabelCustom
              labelName={
                "You have been given the opportunity to grow ?"
              }
            />
            <RadioFormik name="careerAdvancement" options={option} />
          </div>
          <div>
            <LabelCustom
              labelName={
                "You had a clear understanding of the tasks and expectations ?"
              }
            />
            <RadioFormik
              name="understandingOfTheTasksandExpectations"
              options={option}
            />
          </div>
          <div>
            <LabelCustom
              labelName={
                "You had the opportunity to work on different and/ or latest technologies ?"
              }
            />
            <RadioFormik
              name="opportunityToWorkonLatestTechnologies"
              options={option}
            />
          </div>
          <div>
            <LabelCustom labelName={"Your Job training was adequate ?"} />
            <RadioFormik name="jobTrainingWasAdequate" options={option} />
          </div>
        </div>
        <div>
          <LabelCustom
            labelName={"Any suggestion for the organisation"}
            mandatory={true}
            style={{ flex: 3 }}
          />
          <TextAreaFormik
            style={{ height: "80px" }}
            maxLength="500"
            name="suggestionsToDevelopAESS"
            placeholder="Please Enter Suggestion"
          />
        </div>
      </div>
    </div>
  );
};
