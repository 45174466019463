import React from "react";
import "./TeamMembersCard.css";
  import { useEffect } from "react";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
const TeamMembersCard = (props) => {
  const sendDataToParent = (id) => {
    // Call the function passed from the parent component with the data
    props.sendDataToParent(id);
  };
 
  // if (!homeList) return <Loader />;
  const togglePopup = (id) => {
    props.setIsOpens((prev) => !prev);
  };
  if (!props.homeList) return <Loader />;
  else
    return (
  <>
      <div className="teamMembersContainer">
        <div className="teamMembersContentHolder">
          {props.homeList &&
            props.homeList?.teamMembers?.map((item) => {
              return (
                <div
                  className="teamMembersCard cursor"
                  onClick={() =>{
                    sendDataToParent(item?.employeeId)
                     togglePopup(item?.employeeId)
                    }}
                >
                  {item?.profilePic ? (
                    <img
                      className="teamMembersImg"
                      src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${item?.profilePic}`}
                      alt="Profile"
                    />
                  ) : (
                    <img
                src={ProfileAvatar}
                alt={"Profile"}
                className="imageInsideProgressBar"
              />
                  )}
                  <div className="teamMembersNameId">
                    <label className="teamMemberName">
                      {item?.employeeName
                        ? item?.employeeName.split("(")[0]
                        : "-----"}
                    </label>
                    <label className="teamMemberId">
                      {item?.personNumber ? item?.personNumber : "-----"}
                    </label>
                  </div>
                  <div className="teamMembersLine"></div>
                  <label className="teamMemberRole">
                    {item?.role ? item?.role : "-----"}
                  </label>
                </div>
              );
            })}
        </div>
      </div>
    
              </>
    );
};

export { TeamMembersCard };
