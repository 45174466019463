import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateNewsComp } from "../FormComp/CreateNewsComp";
import { useEffect } from "react";

const CreateNews = (props) => {
  const { state } = useLocation();
  const [detailsform, setDetailsform] = useState({});
  const data = state?.dataEdit?.Id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`News/Details/${data}`);
      setDetailsform(result.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("News/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create News`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm("News/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      returnPage();
    }
  };

  const initialvalue = {
    title: "",
    description: "",
    shortDescription: "",
    category: "",
    upload: null,
    link: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .max(149, "Title cannot be more than 150 characters")
      .required("Please Enter Title"),
    description: Yup.string()
      .max(499, "Description cannot be more than 500  characters")
      .required("Please Enter Description"),
    shortDescription: Yup.string()
      .max(299, "Description cannot be more than 300 characters")
      .required("Please Enter Description"),
    category: Yup.string().required("Please Select Category"),
    link: Yup.string()
      .required("Please Enter Link")
      // .notRequired()
      // .nullable()
      .matches(
        /^https?:\/\/(.*)/,
        "website must start with http:// or https://"
      ),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
      id: 0,
    };

    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  //ButtonLabel={data?'Update Request Category':'Create Request Category'}
  //FormHeading={data?'Edit Request Category':'Create Request Category'}

  return (
    <FormCustomFormik
      FormHeading={data ? "Edit News" : "Create News"}
      ButtonLabel={data ? "Edit News" : "Create News"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              title: detailsform?._news?.title,
              description: detailsform?._news?.description,
              shortDescription: detailsform?._news?.shortDescription,
              category: detailsform?._news?.category,
              upload: "SocialPostImageUploads/"+detailsform?.post?.attachment,
              link: detailsform?._news?.link,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateNewsComp />}
    />
  );
};

export { CreateNews };
