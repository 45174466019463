import React from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useState } from "react";
import { postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";
import { Loader } from "../../../../../Components/FormComponent/Loader";

const ShowViewsList = ({
  viewsPostId,
  module,
  setViewsListActive,
  setViewsPostId,
}) => {
  const [sharedData, setSharedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sharedDetailAPI = async () => {
    setLoading(true);
    try {
      const result = await postRequest(`SocialPost/AllViewsList`, {
        createdOn: null,
        Module: module,
        PostId: viewsPostId,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 100,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      setSharedData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  function handleUserProfileClick(userid) {
    navigate(`/home/social/userprofile/${userid}`);
  }
  useEffect(() => {
    sharedDetailAPI();
  }, []);
  return (
    <div>
      <Popup
        firstClassName="likePopupBox1"
        secondClassName="likePopupBox2"
        contentClassName={"likePopupContent"}
        popupHeading={"People who viewed your post"}
        content={
          <div className="totalLikePopupContainer">
            {!loading &&
              sharedData?.map((d, i) => {
                return (
                  <div className="totalLikePopupList">
                    <div
                      className="totalLikePopupImg"
                      onClick={() => {
                        handleUserProfileClick(d?.item?.employeeId);
                      }}
                    >
                      <div
                        style={{ margin: "0px" }}
                        className={`${
                          d?.item?.image
                            ? "socialProfilePicContainer"
                            : "socialCustomProfilePic"
                        }`}
                      >
                        {!d?.item?.image ? (
                          getUserInitials(
                            getDisplayName(d?.item?.nameWithPersonNumber)
                          )
                        ) : (
                          <img
                            className="socialProfilePic"
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              profileImages +
                              d?.item?.image
                            }
                            alt="Profile"
                            onError={(e) => {
                              e.target.src = ProfileAvatar;
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="totalLikePopupText">
                      <h5>{getDisplayName(d?.item?.nameWithPersonNumber)}</h5>
                      <p>{d?.item?.roleName}</p>
                      <p>{d?.item?.locationName}</p>
                    </div>
                  </div>
                );
              })}
            {loading && <Loader />}
          </div>
        }
        handleClose={() => {
          setViewsPostId(0);
          setViewsListActive(false);
        }}
      />
    </div>
  );
};

export default ShowViewsList;
