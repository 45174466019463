import React, { useState, useRef, useEffect } from "react";
import CreateRewardEligibleAllEmp from "./CreateRewardEligibleAllEmp";
import CreateRewardEligibleAllLocation from "./CreateRewardEligibleAllLocation";
import CreateRewardEligibleAllDepartments from "./CreateRewardEligibleAllDepartments";
import CreateRewardEligibleAllEmpDeptWise from "./CreateRewardEligibleAllEmpDeptWise";


function CreateRewardEligibleEmp({editID,detail, values,initialValues, setInitialValues,setFormValues }) {
 const [removedEmpId, setRemovedEmpId] = useState()

  const [employeeList, setEmployeeList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeDeptWise, setEmployeeDeptWise] = useState([]);

  const [allSelectedEmpList, setAllSelectedEmpList] = useState([]);
  const [allSelectedLocations, setAllSelectedLocations] = useState([]);
  const [allSelectedDepartmentList, setAllSelectedDepartmentList] = useState([]);
  const [allSelectedEmpDeptWise, setAllSelectedEmpDeptWise] = useState([]);

 

// Function to extract empIds and store them in the new state
const storeEmpIds = () => {
  const empIds = allSelectedEmpList.map(item => item.empId);
  const locationIds = allSelectedLocations.map(item => item.locationId);
  const departmentIds = allSelectedDepartmentList.map(item => item.deptId);
  const departmentWiseEmpIds = allSelectedEmpDeptWise.map(item => item.empId);
  
  setInitialValues(prevFormvalue => ({
    ...values,
    employeesSection: {
      ...values.employeesSection,
      selectedemployeesArray: [...empIds] // Spread empIds into employeesArray
    },
    locationSection: {
      ...values.locationSection,
      selectedLocationArray: [...locationIds],
    },
    departmentSection: {
      ...values.departmentSection,
      selectedDepartmentArray: [...departmentIds],
    },
    deptWiseEmployeesSection: {
      ...values.deptWiseEmployeesSection,
      selectedemployeesArray: [...departmentWiseEmpIds],
    },
  }));
};

// Call the storeEmpIds function whenever you need to set empIds
useEffect(() => {
  storeEmpIds();
}, [allSelectedEmpList,allSelectedLocations,allSelectedDepartmentList,allSelectedEmpDeptWise]);

  console.log('sandeep eligible', values)
  return (
    <>
      {
        values?.RewardParticipation === "All Department" ?
          <>
            < CreateRewardEligibleAllEmp 
              values={values} 
              employeeList={employeeList} 
              setEmployeeList={setEmployeeList}
              allSelectedEmpList={allSelectedEmpList}
              setAllSelectedEmpList={setAllSelectedEmpList}
            />
          </>
          :
          <>
            < CreateRewardEligibleAllLocation 
              values={values} 
              editID={editID}
              detail={detail}
              locationList={locationList} 
              setLocationList={setLocationList}
              allSelectedLocations={allSelectedLocations}
              setAllSelectedLocations={setAllSelectedLocations}
            />
            < CreateRewardEligibleAllDepartments 
              values={values} 
              departmentList={departmentList} 
              setDepartmentList={setDepartmentList}
              allSelectedDepartmentList={allSelectedDepartmentList}
              setAllSelectedDepartmentList={setAllSelectedDepartmentList}
            />
            < CreateRewardEligibleAllEmpDeptWise 
              values={values} 
              employeeDeptWise={employeeDeptWise} 
              setEmployeeDeptWise={setEmployeeDeptWise}
              allSelectedLocations={allSelectedLocations}
              allSelectedDepartmentList={allSelectedDepartmentList}
              allSelectedEmpDeptWise={allSelectedEmpDeptWise}
              setAllSelectedEmpDeptWise={setAllSelectedEmpDeptWise}
            />
          </>
      }

    </>
  );
}

export default CreateRewardEligibleEmp;
