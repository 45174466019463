import React, { useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";




export const CreateItemsComp = ({ data }) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Items`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik

          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Please Enter Name"}
        />
        <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}> For multiple entries seperate Names with ';'. Eg: Name1;Name2;Name3</span>

      </div>

    </div>
  );
};
