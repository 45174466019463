import { IconButton } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SurveyPostActivityContainer = forwardRef((props, ref) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const updateState = () => setIsActive((prev) => !prev);
  const closeState = () => setIsActive(false);
  useImperativeHandle(ref, () => ({
    updateState,
    closeState,
  }));
  const moreActivityHandler = useCallback(
    (e) => {
      let moreActivity = document.querySelector(
        `.surveyPostActivityContainer${props.surveyPostData?.id}`
      );
      if (moreActivity && !moreActivity?.contains(e.target)) {
        setIsActive(false);
      }
    },
    [props.surveyPostData?.id]
  );

  useEffect(() => {
    document.addEventListener("mouseup", moreActivityHandler);
    return () => {
      document.removeEventListener("mouseup", moreActivityHandler);
    };
  }, [moreActivityHandler]);

  return (
    <div
      className={`surveyPostActivityContainer surveyPostActivityContainer${props.surveyPostData?.id}`}
    >
      <div className="rightSocialPostHeaderContainer">
        <IconButton
          style={{
            padding: "8px 15px",
          }}
          onClick={() => {
            setIsActive(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="3"
            height="17"
            viewBox="0 0 3 17"
            fill="none"
          >
            <circle cx="1.5" cy="1.5" r="1.5" fill="#D9D9D9" />
            <circle cx="1.5" cy="8.25" r="1.5" fill="#D9D9D9" />
            <circle cx="1.5" cy="15" r="1.5" fill="#D9D9D9" />
          </svg>
        </IconButton>
      </div>
      {isActive && (
        <div className="socialPostActivityHolder">
          <div
            className="headingFilterHeader"
            onClick={() => {
              setIsActive(false);
              navigate("/home/social/surveyedit/" + props.surveyPostData?.id, {
                state: { type: props.type },
              });
            }}
          >
            <h5>{props.type === "survey" ? "Edit Survey" : "Edit Poll"}</h5>
          </div>
          <div
            className="headingFilterHeader"
            onClick={() => {
              props?.setDeletePostActive(true);
              props?.setDeletePostId(props.surveyPostData?.id);
              setIsActive(false);
            }}
          >
            <h5>Delete Post</h5>
          </div>
        </div>
      )}
    </div>
  );
});

export default SurveyPostActivityContainer;
