import * as Yup from "yup";


export const onBoardingFormSchema = Yup.object({
  title: Yup.string().max(49, "Title must be less than 50 characters").required("Please Enter Title"),
  type: Yup.string().max(149, "Type must be less than 150 characters").required("Please Enter Type"),
  description: Yup.string().max(499, "Type must be less than 500 characters").nullable(),

});


