import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { TimeCustoms } from "../../../../Components/FormComponent/TimeCustoms";
import { getRequest } from "../../../../Services/axios";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { useEffect } from "react";

const opt=[
  {label:"select",value:null}
]
const ServiceTypeForm = ({data}) => {

  const [optionSer, setOptionSer] = React.useState([]);
  const [optionNoti, setOptionNoti] = React.useState([]);
  const [optionTeam, setOptionTeam] = React.useState([]);
  const [option, setOption] = React.useState([]);
  const getOptionReq = async () => {
    try {
      const result = await getRequest(
        "HRHelpdeskServiceTypes/GetHrHelpdeskRequestCategories"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionNoti = async () => {
    try {
      const result = await getRequest(
        "HRHelpdeskServiceTypes/GetNotificationFlows"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOptionNoti(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Service Type Form`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getOptionTeam = async () => {
    try {
      const result = await getRequest(
        "HRHelpdeskServiceTypes/GetHRHelpdeskServiceTypesHRTeam"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOptionTeam(listData);
    } catch (error) {
    } finally {
    }
  };

  const getOption = async () => {
    try {
      const result = await getRequest(
        "HRHelpdeskServiceTypes/GetHrHelpdeskServiceCategories?q={}"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    if(data){
      getOption();
    }
    getOptionReq();
    getOptionNoti();
    getOptionTeam();
  }, []);
  return (
 <>
  <div className="formcustom">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Request Category  "} mandatory={true} />
        <SelectForm  values={
            data
              ? option.filter(    
                  (options) => options.value === data?.requestCategoryId
                )
              : opt
          }  name={"requestCategoryId"} options={option} placeholder={" Select Request Category  "} />
      </div>
      <div>
        <LabelCustom labelName={"Service Category "} mandatory={true} />
        <SelectForm 
          values={
            data
              ? optionSer.filter(
                  (options) => options.value === data?.categoryId
                )
              : opt
          } name={"categoryId"} options={optionSer} placeholder={" Select Service Category "} />
      </div>
      <div>
        <LabelCustom labelName={"Notification Flow"}  />
        <SelectForm   name={"notificationFlowId"} options={optionNoti} placeholder={" Select Notification Flow "} />
      </div>
      <div>
        <LabelCustom labelName={"Team "} mandatory={true} />
        <SelectForm  values={
            data
              ? optionTeam.filter(
                  (options) => options.value === data?.teamId
                )
              : opt
          }  name={"teamId"} options={optionTeam} placeholder={" Select Team "} />
      </div>
      {/* <div>
            <LabelCustom labelName={"Require more Information for Asset Approval?"} />
            <YesNoFormik name={"requiremoreinformationforAssetApproval"} />
          </div> */}

      </div>
      <div className="headingForm">
       
        TAT (In Hrs)
      </div>
   <div className="formcustom">
    <div>
        <LabelCustom labelName={"High"} mandatory={true}/>
        <InputCustomFormik type='number' min={1} max={999} name={"tatHigh"} options={option} placeholder={" Enter Tat High "} />
      </div>
      <div>
        <LabelCustom labelName={"Medium "} mandatory={true}/>
        <InputCustomFormik type='number' min={1} max={999} name={"tatMedium"} options={option} placeholder={" Enter Tat Medium "} />
      </div>
      <div>
        <LabelCustom labelName={"Low"}  mandatory={true}/>
        <InputCustomFormik type='number' min={1} max={999} name={"tatLow"} options={option} placeholder={" Enter Tat Low "} />
      </div>
   </div>
 </>
  );
};
export { ServiceTypeForm };
