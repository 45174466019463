import { IconButton } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const OrganisationBox = ({ Heading, itembox }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentpath = location.pathname.toLowerCase();
  let splitArray = currentpath.split("/");
  const skillsetdesign = {};
  const clickController = () => {
    if (
      currentpath.includes("/ld/skills/organisationskills") &&
      splitArray?.length === 4
    ) {
      navigate("/ld/skills/organisationskills/skillset", {
        state: { state: itembox?.skillsetId },
      });
    }
    if (
      currentpath.includes("/ld/skills/organisationskills/skillset") &&
      splitArray?.length === 5
    ) {
      navigate("/ld/skills/organisationskills/skillset/employees", {
        state: { state: itembox?.skillid },
      });
    }
    if (
      currentpath.includes("/ld/competency/organisationcompetency") &&
      splitArray?.length === 4
    ) {
      navigate("/ld/competency/organisationcompetency/competencyset", {
        state: { state: itembox?.competencySetId },
      });
    }
    if (
      currentpath.includes(
        "/ld/competency/organisationcompetency/competencyset"
      ) &&
      splitArray?.length === 5
    ) {
      navigate(
        "/ld/competency/organisationcompetency/competencyset/employees",
        {
          state: { state: itembox?.competencyId },
        }
      );
    }
  };
  return (
    <div onClick={clickController} className="organisationBoxDesign">
      {Heading === "Skill Set" && (
        <>
          <div>
            <div className="positonStyle">{Heading}</div>
            <div className="positonStyleskill" style={skillsetdesign}>
              <div className="positonStyleskillspan">{itembox?.skillSet}</div>
            </div>
          </div>
          <div>
            <div className="positonStyle">No. of Employee</div>
            <div className="positonStylenumber">{itembox?.employeeCount}</div>
          </div>
        </>
      )}
      {Heading === "Skill Name" && (
        <>
          <div>
            <div className="positonStyle">{Heading}</div>
            <div className="positonStyleskill" style={skillsetdesign}>
              <div className="positonStyleskillspan">{itembox?.skill}</div>
            </div>
          </div>
          <div>
            <div className="positonStyle">No. of Employee</div>
            <div className="positonStylenumber">{itembox?.employeeCount}</div>
          </div>
        </>
      )}
      {Heading === "Competency Set" && (
        <>
          <div>
            <div className="positonStyle">{Heading}</div>
            <div className="positonStyleskill" style={skillsetdesign}>
              <div className="positonStyleskillspan">
                {itembox?.competencySet}
              </div>
            </div>
          </div>
          <div>
            <div className="positonStyle">No. of Employee</div>
            <div className="positonStylenumber">{itembox?.employeeCount}</div>
          </div>
        </>
      )}
      {Heading === "Competency Name" && (
        <>
          <div>
            <div className="positonStyle">{Heading}</div>
            <div className="positonStyleskill" style={skillsetdesign}>
              <div className="positonStyleskillspan">{itembox?.compentecy}</div>
            </div>
          </div>
          <div>
            <div className="positonStyle">No. of Employee</div>
            <div className="positonStylenumber">{itembox?.employeeCount}</div>
          </div>
        </>
      )}
    </div>
  );
};

export { OrganisationBox };
