import React from "react";
import { SelectFormLD } from "../../../../Components/FormComponent/SelectFormLD";
import { FileUploadFormikWebCam } from "./FileUploadFormikWebCam";
import { WebCamFormik } from "./WebCamFormik";

const WebcamOrFileFormik = ({ formValues, name, index, Visitlist }) => {
  const videoOptions = [
    { label: "File", value: "file" },
    { label: "Web Cam", value: "webcam" },
  ];
  return (
    <div className="SelectVideoLDMaster" style={{ position: "relative" }}>
      <SelectFormLD
        placeholder={"Select Source"}
        name={`Visitlist[${index}]SelectVideo`}
        options={videoOptions}
      />
      {formValues?.Visitlist[index]?.SelectVideo === "file" && (
        <div
          style={{
            position: "absolute",
            left: "2px",
            top: "-9.5px",
            width: "calc(100% - 53px)",
          }}
        >
          <FileUploadFormikWebCam
            style={{ height: "39px", boxShadow: "none" }}
            name={name}
            formValues={formValues}
          />
        </div>
      )}
      {formValues?.Visitlist[index]?.SelectVideo === "webcam" && (
        <div
          style={{
            position: "absolute",
            left: "2px",
            top: "-9.5px",
            width: "calc(100% - 53px)",
          }}
        >
          <WebCamFormik
            style={{ height: "36px", boxShadow: "none" }}
            name={name}
            formValues={formValues}
          />
        </div>
      )}
    </div>
  );
};

export default WebcamOrFileFormik;
