import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { getRequest, postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CreatePipelineComp } from "../FormComp/CreatePipelineComp";





const CreatePipeline = (props) => {

  const [detailsform, setDetailsform] = useState({})
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PipeLine/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }

  useEffect(()=>{
    document.title = `PeopleSol - Create Pipeline`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  useEffect(() => {
    if (data) {
      getFormDetails()
    }
  }, [])
  const createPageData = async (values) => {
    try {

      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest('PipeLine/Create', values);
      dispatch(showToast({ text: "Successfully Created", severity: "success" }));
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "PipeLine/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { }
  };


const getInitalAnswerValues=()=>{
  let returnArray = [];
  data ?
  detailsform?.stages?.map((item) => {
    returnArray.push({statusNames:item.statusNames, id:item.id})
  }) 
  :
  returnArray = [{statusNames:[], id:""}]

  return returnArray
}
  const initialvalue = {
    title: "",
    tatInHrs: "",
    stages:getInitalAnswerValues()
  };
  const validationSchema = Yup.object({
    title: Yup.string().max(25).required("Please enter the title"),
    tatInHrs: Yup.string().required("Please enter TAT in Hrs"),
    stages: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required("Please select stage")
      })
    )
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: detailsform?.id
    };

    const create = {
      ...values,
      Id: 0
    }
    data ? Edit(edit) : createPageData(create);
    returnPage()
  }


  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/atsmaster/pipeline");
  };
  return (
    <FormCustomFormik FormHeading={props.title} ButtonLabel={data ? 'Confirm' : 'Create'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
      data
        ? {
          ...initialvalue,
          title: detailsform?.title,
          tatInHrs: detailsform?.tatInHrs
        }
        : initialvalue
    }
      returnPage={returnPage} propsDiv={<CreatePipelineComp data={detailsform} />} />
  );
};

export { CreatePipeline };