import React from "react";
import "./OrgStructure.css";
import BoxContentHolder from "./BoxContentHolder";
import ContentHolderBack from "../../../Assets/ContentHolderBack.svg";
import { Loader } from "../../../Components/FormComponent/Loader";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getRequest } from "../../../Services/axios";
import { showToast } from "../../../Features";
import { useEffect } from "react";

const PersonalDetails = () => {
  // //console.log('data', data)

  const [profileList, setProfileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const getProfileDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Employees/Profile/${LoggedInUser?.id}`);
      setProfileList(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Personal Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("personaldetails"))
      getProfileDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <div className="container">
        <div className="subContainer">
          <div className="subContainerHeading">Contact</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Official Contact"
              text={
                profileList.officePhoneNumber
                  ? profileList.officePhoneNumber
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Official Email"
              text={profileList.email ? profileList.email : "---"}
            />
            <BoxContentHolder
              heading="Personal Contact"
              text={
                profileList.personalPhoneNumber
                  ? profileList.personalPhoneNumber
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Personal Email"
              text={
                profileList.personalEmail ? profileList.personalEmail : "---"
              }
              last="last"
            />
            {/* <BoxContentHolder heading="Office Extension" text="3214" last="last"/> */}
          </div>
        </div>
        <div className="subContainer">
          <div className="subContainerHeading">Payment</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Bank Name"
              text={profileList.branchName ? profileList.branchName : "---"}
            />
            <BoxContentHolder
              heading="Account Holder Name"
              text={
                profileList.accountHolderName
                  ? profileList.accountHolderName
                  : "---"
              }
            />
            <BoxContentHolder
              heading="IFSC Code"
              text={profileList.ifsccode ? profileList.ifsccode : "---"}
            />
            <BoxContentHolder
              heading="Account Number"
              text={
                profileList.bankAccountNumber
                  ? profileList.bankAccountNumber
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Payment Mode"
              text={profileList.paymentMode ? profileList.paymentMode : "---"}
              last="last"
            />
          </div>
        </div>
        <div className="subContainer">
          <div className="subContainerHeading">Diversity</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Language"
              text={profileList.language ? profileList.language : "---"}
            />
            <BoxContentHolder
              heading="Religion"
              text={profileList.religion ? profileList.religion : "---"}
            />
            <BoxContentHolder
              heading="Nationality"
              text={profileList.nationality ? profileList.nationality : "---"}
            />
            <BoxContentHolder
              heading="Marital Status"
              text={
                profileList.martialStatus ? profileList.martialStatus : "---"
              }
            />
            <BoxContentHolder
              heading="Gender"
              text={profileList.gender ? profileList.gender : "---"}
              last="last"
            />
          </div>
        </div>
        <div className="subContainer">
          <div className="subContainerHeading">Identification</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Aadhaar Number"
              text={
                profileList.aaadharNumber ? profileList.aaadharNumber : "---"
              }
            />
            <BoxContentHolder
              heading="PAN Number"
              text={profileList.panNumber ? profileList.panNumber : "---"}
            />
            <BoxContentHolder
              heading="UAN Number"
              text={profileList.uannumber ? profileList.uannumber : "---"}
            />
            <BoxContentHolder
              heading="Passport Number"
              text={
                profileList.passportNumber ? profileList.passportNumber : "---"
              }
            />
            {/* <BoxContentHolder heading="Blood Group" text={profileList.bloodGroup ? profileList.bloodGroup : "---"} last="last" /> */}
          </div>
        </div>
        <div className="subContainer">
          <div className="subContainerHeading">Current Address</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Address"
              text={
                profileList.currentAddress ? profileList.currentAddress : "---"
              }
            />
            <BoxContentHolder
              heading="City"
              text={
                profileList.currentAddressCity
                  ? profileList.currentAddressCity
                  : "---"
              }
            />
            <BoxContentHolder
              heading="State"
              text={
                profileList.currentAddressState
                  ? profileList.currentAddressState
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Country"
              text={
                profileList.currentAddressCountry
                  ? profileList.currentAddressCountry
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Pin Code"
              text={
                profileList.currentAddressPincode
                  ? profileList.currentAddressPincode
                  : "---"
              }
              last="last"
            />
          </div>
        </div>
        <div className="subContainer">
          <div className="subContainerHeading">Permanent Address</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Address"
              text={
                profileList.permanentAddress
                  ? profileList.permanentAddress
                  : "---"
              }
            />
            <BoxContentHolder
              heading="City"
              text={
                profileList.permanentAddressCity
                  ? profileList.permanentAddressCity
                  : "---"
              }
            />
            <BoxContentHolder
              heading="State"
              text={
                profileList.permanentAddressState
                  ? profileList.permanentAddressState
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Country"
              text={
                profileList.permanentAddressCountry
                  ? profileList.permanentAddressCountry
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Pin Code"
              text={
                profileList.permanentAddressPincode
                  ? profileList.permanentAddressPincode
                  : "---"
              }
              last="last"
            />
          </div>
        </div>
        <div className="subContainer">
          <div className="subContainerHeading">Emergency Contact</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Name"
              text={
                profileList.emergencyContactName
                  ? profileList.emergencyContactName
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Relationship"
              text={profileList.relationShip ? profileList.relationShip : "---"}
            />
            <BoxContentHolder
              heading="Contact"
              text={
                profileList.emergencyContactNumber
                  ? profileList.emergencyContactNumber
                  : "---"
              }
            />
            {/* <BoxContentHolder heading="Address" text={data.contactPersonAddress ? data.contactPersonAddress : "---"} last="last"/> */}
          </div>
          {/* <div className="contentHolder">
        <img src={ContentHolderBack} alt="ContentBackground" className="contentBackground" />
          <BoxContentHolder heading="Email ID" text="digvijay.swain@aesssolutions.com" last="last" />
        </div> */}
        </div>

        {/* <div className="subContainer">
          <div className="subContainerHeading">Families</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder heading="Father's Name" text="-------------" />
            <BoxContentHolder heading="Mother's Name" text="-----------" />
            <BoxContentHolder
              heading="Spouse Name"
              text={data.spouseName ? data.spouseName : "---"}
            />
            <BoxContentHolder
              heading="No of Children"
              text={data.numberofChildren ? data.numberofChildren : "---"}
            />
            <BoxContentHolder
              heading="Dependent"
              text={data.dependentList ? data.dependentList : "---"}
              last="last"
            />
          </div>
        </div> */}
      </div>
    );
};

export { PersonalDetails };
