import React from "react";
import Comment from "../../../../../Assets/Comment.svg";
import TicketAssign from "../../../../../Assets/TicketAssign.svg";
import { format } from "date-fns";
import { useEffect } from "react";

export const TimeLineData = ({ data }) => {
  let step = 1;
  useEffect(() => {
    document.title = `PeopleSol - Time Line Data`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="timeline-item">
      <div
        className="timeline-item-content"
        style={{
          border: `1px solid var(--primary-hover)`,
          boxShadow: "0px 3.56872px 17.8436px rgba(0, 0, 0, 0.06)",
        }}
      >
        <div>
          <p
            style={{
              color: "var(--primary)",
              fontWeight: "500",
              fontSize: "13px",
            }}
          >{`Request Created: ${data?.employeeTicket?.ticketNumber}`}</p>
          <p style={{ fontSize: "12px" }}>
            By {data?.employeeTicket?.employee?.nameWithPersonNumber}
          </p>
        </div>
        <span
          className="circle"
          style={{
            background: "var(--primary)",
          }}
        >
          {step}
        </span>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "500",
            alignSelf: "flex-end",
          }}
        >
          On{" "}
          {data?.employeeTicket?.createdOn &&
            format(
              new Date(data?.employeeTicket?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
        </p>
        {/* <div style={{ alignSelf: "flex-end" }}>
        </div> */}
      </div>
      {data?.list?.map((ticketTimelineItem, index) => {
        // //console.log("ticketTimelineItem", ticketTimelineItem);
        return (
          <div
            className="timeline-item-content"
            style={{
              border:
                ticketTimelineItem?.type === "Approvals" &&
                (ticketTimelineItem?.description === null ||
                  ticketTimelineItem?.description === "")
                  ? ticketTimelineItem?.approvalStatus
                    ? " 1px solid rgba(13, 181, 60, 0.3)"
                    : ticketTimelineItem?.approvalStatus == null
                    ? "1px solid #FDEAD2"
                    : ticketTimelineItem?.approvalStatus === false
                    ? "1px solid #FDD2D2"
                    : "1px solid var(--primary)"
                  : ticketTimelineItem?.description === "Ticket Closed"
                  ? " 1px solid rgba(13, 181, 60, 0.3)"
                  : ticketTimelineItem?.description === "Ticket Cancelled"
                  ? "1px solid #FDD2D2"
                  : ticketTimelineItem?.description === "Ticket Closure" ||
                    ((ticketTimelineItem?.description ===
                      "Assign to the HR Helpdesk Team" ||
                      ticketTimelineItem?.description ===
                        "Assigned to the IT Helpdesk Team") &&
                      ticketTimelineItem?.approvalStatus == null)
                  ? "1px solid #FDEAD2"
                  : ticketTimelineItem?.description ===
                      "Assign to the HR Helpdesk Team" ||
                    ticketTimelineItem?.description ===
                      "Assigned to the IT Helpdesk Team" ||
                    ticketTimelineItem?.description ===
                      "Assigned to the HR Helpdesk Team"
                  ? " 1px solid rgba(13, 181, 60, 0.3)"
                  : "1px solid var(--primary-hover)",
              boxShadow: "0px 3.56872px 17.8436px rgba(0, 0, 0, 0.06)",
            }}
          >
            {ticketTimelineItem?.type === "Approvals" && (
              <>
                <span
                  className="circle"
                  style={{
                    background: "#F7941D",
                    color: "white",
                  }}
                >
                  {++step}
                </span>
                {ticketTimelineItem?.approvalStatus == null && (
                  <span
                    style={{
                      background: "#F7941D",
                      color: "white",
                    }}
                    className="circle"
                  >
                    {step}
                  </span>
                )}
                {ticketTimelineItem?.approvalStatus === false && (
                  <span
                    style={{
                      background: "red",
                      color: "white",
                    }}
                    className="circle"
                  >
                    {step}
                  </span>
                )}
                {ticketTimelineItem?.approvalStatus && (
                  <span
                    style={{
                      background: "#0DB53C",
                      color: "white",
                    }}
                    className="circle"
                  >
                    {step}
                  </span>
                )}
              </>
            )}
            {ticketTimelineItem?.type === "Approvals" ? (
              <>
                {ticketTimelineItem?.description === "Ticket Closed" ? (
                  <span
                    style={{
                      background: "#0DB53C",
                      color: "white",
                    }}
                    className="circle"
                  >
                    {"✔"}
                  </span>
                ) : ticketTimelineItem?.description === "Ticket Cancelled" ? (
                  <span
                    style={{
                      background: "red",
                      color: "white",
                    }}
                    className="circle"
                  >
                    {"X"}
                  </span>
                ) : (
                  <span className="circle">{step}</span>
                )}
              </>
            ) : ticketTimelineItem?.mode === "Assigned" ? (
              <span
                className="circle"
                style={{
                  background: "#8A88ED",
                  color: "white",
                }}
              >
                <img
                  style={{ height: "60%", width: "60%" }}
                  src={TicketAssign}
                  alt="com"
                />
              </span>
            ) : (
              <span className="circle">
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={Comment}
                  alt="com"
                />
              </span>
            )}
            {ticketTimelineItem?.type === "Approvals" &&
            (ticketTimelineItem?.description === null ||
              ticketTimelineItem?.description === "") ? (
              <>
                {ticketTimelineItem?.approvalStatus === true ? (
                  <>
                    <p
                      style={{
                        fontWeight: "500",
                        color: "#0DB53C",
                        fontSize: "13px",
                      }}
                    >
                      Ticket Approved By {ticketTimelineItem?.employee}{" "}
                      {ticketTimelineItem?.employee === "Reporting Manager" &&
                        `(${data?.employeeTicket?.employee?.rO_Name})`}{" "}
                      {ticketTimelineItem?.employee === "Functional HOD" &&
                        `(${data?.employeeTicket?.employee?.fhoD_Name})`}{" "}
                      {ticketTimelineItem?.employee === "Functional Director" &&
                        `(${data?.employeeTicket?.employee?.dir_Name})`}
                    </p>
                  </>
                ) : ticketTimelineItem?.approvalStatus === false ? (
                  <p
                    style={{
                      fontWeight: "500",
                      color: "red",
                      fontSize: "13px",
                    }}
                  >
                    {`Rejected by ${ticketTimelineItem?.employee} with remarks ${ticketTimelineItem?.remarks}`}
                  </p>
                ) : (
                  <p
                    style={{
                      fontWeight: "500",
                      color: "#F7941D",
                      fontSize: "13px",
                    }}
                  >
                    Requires Approval from {ticketTimelineItem?.employee}{" "}
                    {ticketTimelineItem?.employee === "Reporting Manager" &&
                      `(${data?.employeeTicket?.employee?.rO_Name})`}{" "}
                    {ticketTimelineItem?.employee === "Functional HOD" &&
                      `(${data?.employeeTicket?.employee?.fhoD_Name})`}{" "}
                    {ticketTimelineItem?.employee === "Functional Director" &&
                      `(${data?.employeeTicket?.employee?.dir_Name})`}
                  </p>
                )}
              </>
            ) : (
              <p
                style={{
                  fontWeight: "500",
                  color:
                    ticketTimelineItem?.description === "Ticket Closed"
                      ? "#0DB53C"
                      : (ticketTimelineItem?.description ===
                          "Assign to the HR Helpdesk Team" ||
                          ticketTimelineItem?.description ===
                            "Assigned to the IT Helpdesk Team" ||
                          ticketTimelineItem?.description ===
                            "Assigned to the HR Helpdesk Team" ||
                          "Assign to the HR Helpdesk Team" ||
                          ticketTimelineItem?.description ===
                            "Assign to the IT Helpdesk Team") &&
                        ticketTimelineItem?.approvalStatus != null
                      ? "#0DB53C"
                      : (ticketTimelineItem?.approvalStatus == null &&
                          ticketTimelineItem?.description ===
                            "Assign to the HR Helpdesk Team") ||
                        ticketTimelineItem?.description === "Ticket Closure"
                      ? "#F7941D"
                      : ticketTimelineItem?.mode === "Assigned"
                      ? "rgb(138, 136, 237)"
                      : "var(--primary)",
                  fontSize: "13px",
                }}
              >
                {ticketTimelineItem?.description === "Ticket Closure"
                  ? "Pending Ticket Closure"
                  : ticketTimelineItem?.description}
                {ticketTimelineItem?.description === "Ticket Closed" && (
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    {data?.employeeTicket?.employee?.nameWithPersonNumber}
                  </p>
                )}
              </p>
            )}
            {ticketTimelineItem?.tat != null && ticketTimelineItem?.tat > 0 && (
              <div
                className="textP"
                style={{
                  border: `1px solid #0DB53C`,
                }}
              >
                <label style={{ fontSize: "12px" }}>
                  TAT: {Math.abs(ticketTimelineItem?.tat)}:00:00
                </label>
                <svg
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                  }}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6" cy="6" r="6" fill="#0DB53C" />
                  <path
                    d="M6.53721 4.00631C7.09124 4.00631 7.54036 3.55718 7.54036 3.00315C7.54036 2.44913 7.09124 2 6.53721 2C5.98318 2 5.53406 2.44913 5.53406 3.00315C5.53406 3.55718 5.98318 4.00631 6.53721 4.00631Z"
                    fill="white"
                  />
                  <path
                    d="M7.56298 8.07704C7.53526 8.0584 7.50411 8.04548 7.47133 8.03905C7.43856 8.03261 7.40483 8.03278 7.37213 8.03956C7.33942 8.04633 7.3084 8.05957 7.28088 8.0785C7.25336 8.09742 7.2299 8.12166 7.21187 8.14977C6.96943 8.50881 6.67244 8.82781 6.33161 9.09525C6.24885 9.15794 5.94038 9.39619 5.80997 9.34603C5.71968 9.31845 5.77235 9.14039 5.7899 9.06515L5.92282 8.67141C5.97799 8.51091 6.93851 5.66195 7.04385 5.33593C7.19933 4.85943 7.13162 4.39046 6.42189 4.50331C6.22878 4.52337 4.27013 4.77667 4.23502 4.77918C4.20208 4.78132 4.16989 4.78993 4.14028 4.80451C4.11068 4.81909 4.08423 4.83936 4.06246 4.86416C4.04068 4.88896 4.02401 4.91781 4.01338 4.94905C4.00276 4.9803 3.99839 5.01333 4.00053 5.04627C4.00267 5.0792 4.01128 5.11139 4.02586 5.141C4.04044 5.17061 4.06071 5.19705 4.08551 5.21883C4.11031 5.2406 4.13916 5.25728 4.17041 5.2679C4.20165 5.27853 4.23468 5.2829 4.26762 5.28075C4.26762 5.28075 5.01998 5.18295 5.10274 5.17542C5.14513 5.17129 5.18782 5.1795 5.22564 5.19908C5.26347 5.21866 5.29482 5.24877 5.31591 5.28577C5.36136 5.42624 5.35513 5.57833 5.29836 5.71462C5.23315 5.96541 4.20241 8.86954 4.16981 9.03756C4.13484 9.17817 4.14455 9.32618 4.19759 9.46102C4.25062 9.59586 4.34435 9.71082 4.46574 9.78993C4.69359 9.94179 4.96487 10.0149 5.23817 9.99808C5.5039 9.99484 5.76669 9.94211 6.01311 9.84259C6.63757 9.59181 7.28962 8.92471 7.6332 8.40056C7.66278 8.34731 7.67197 8.2851 7.65905 8.22557C7.64613 8.16604 7.61198 8.11324 7.56298 8.07704Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
            {ticketTimelineItem?.type !== "Approvals" && (
              <>
                <p style={{ fontWeight: "400", fontSize: "12px" }}>
                  By {ticketTimelineItem?.employee}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    alignSelf: "flex-end",
                  }}
                >
                  {" "}
                  On{" "}
                  {ticketTimelineItem?.time &&
                    format(
                      new Date(ticketTimelineItem?.time),
                      "dd/MM/yyyy hh:mm a"
                    )}
                </p>
              </>
            )}
            {ticketTimelineItem?.type === "Approvals" &&
              ticketTimelineItem?.approvalStatus != null && (
                <>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      alignSelf: "flex-end",
                    }}
                  >
                    On{" "}
                    {ticketTimelineItem?.time &&
                      format(
                        new Date(ticketTimelineItem?.time),
                        "dd/MM/yyyy hh:mm a"
                      )}
                  </p>
                </>
              )}
          </div>
        );
      })}
    </div>
  );
};
