import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import * as Yup from "yup";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useDispatch } from "react-redux";

const DeactivateEmployee = ({ handleClose, getListData, id, eData }) => {
  const dispatch = useDispatch();
  const styles = {
    header: {
      fontSize: "16px",
      marginBottom: "20px",
      color: "var(--primary)",
    },
    list: {
      listStyleType: "none",
      padding: 0,
    },
    completed: {
      textDecoration: "line-through",
      color: "gray",
    },
    uncompleted: {
      textDecoration: "none",
      color: "black",
    },
  };

  useEffect(() => {
    document.title = `PeopleSol - Deactivate Employee`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const initialvalue = {
    EmployeeId: "",
  };
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      if (result.data) {
        setFilteredData(result?.data?.filter((item) => item.id == id));
      }
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  let employeeIds;
  if (eData?.length) {
    employeeIds = eData?.map((employee) => employee?.id);
  } else {
    employeeIds = null;
  }
  const create = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        `Employees/ChangeReportingManger/${id}/${values?.EmployeeId}`,
        employeeIds
      );
      if (result) {
        dispatch(
          showToast({
            text: "Deactivated successfully.",
            severity: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Error in deactivating employee.",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose()
      getListData();
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);
  const onSubmit = (values) => {
    create(values);
  };
  if (loading) return <Loader />;
  else
    return (
      <div
        className="alertContainer"
        style={{ display: "flex", gap: "20px", alignItems: "center" }}
      >
        <div>
          <h1 style={styles.header}>
            {filteredData[0]?.name} is reporting manager of below employees
          </h1>
          <ul style={styles.list}>
            {eData?.map((user) => (
              <li key={user.id}>{user.nameWithPersonNumber}</li>
            ))}
          </ul>
        </div>
        <div>
          <Formik
            initialValues={initialvalue}
            validationSchema={Yup.object({
              EmployeeId: Yup.string().required("Please Select Employee"),
            })}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(formik) => {
              if (loading) return <Loader />;
              else
                return (
                  <Form className="popupFormContainer">
                    <div>
                      <div>
                        <LabelCustom
                          labelName={
                            "Select employee you want to map as new reporting manager"
                          }
                        />
                        <SelectForm name="EmployeeId" options={employeeList} />
                      </div>
                    </div>
                    <div
                      className="createFootCust"
                      style={{ backgroundColor: "white" }}
                    >
                      <button
                        className="button secondaryButton"
                        onClick={handleClose}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button className="button primaryButton" type="submit">
                        Submit
                      </button>
                    </div>
                  </Form>
                );
            }}
          </Formik>
        </div>
      </div>
    );
};

export default DeactivateEmployee;
