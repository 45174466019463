import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";

const opt = [{ label: "select", value: null }];
export const TeamsSetForm = ({ data, editID }) => {
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          disabled={JSON.stringify(data) === "{}" ? false : true}
          type={"text"}
          name="name"
          placeholder={"Enter Name "}
        />
      </div>
      <div>
        <LabelCustom labelName={"SPOC "} mandatory={true} />
        <DropdownInfiniteScroll
          postMethod={false}
          name="spoc"
          placeholder={" Select SPOC"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.spoc]}
          urlProp={"Employees/GetEmployeesDropDowns"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Team Members"} />
        <DropdownInfiniteScroll
          multi={true}
          postMethod={false}
          name="employeeIds"
          placeholder={"Select Team Members"}
          details={data}
          editID={editID}
          detailsForEdit={data?.employeeIds}
          urlProp={"Employees/GetEmployeesDropDowns"}
        />
      </div>
    </div>
  );
};
