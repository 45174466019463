/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { DetailsStep } from "../../../../Components/FormComponent/DetailsStep";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
const DetailsAllGoals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.data;
  useEffect(() => {
    document.title = `PeopleSol - All Goals Details `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const closeDetails = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `goals/details/?GoalSettingId=${selectedId?.settingsId}&&EmployeeId=${selectedId?.empid}`
      );
      setEventtData(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);
  const hasApprovedStatus =
    eventtData?._pmssettings?.pmsGoalsettingsEmployees.some(
      (obj) => obj.status === "RM Approved"
    );
  const hasRejectedStatus =
    eventtData?._pmssettings?.pmsGoalsettingsEmployees.some(
      (obj) => obj.status === "RM Rejected"
    );
  const Pending = eventtData?._pmssettings?.pmsGoalsettingsEmployees.some(
    (obj) => obj.status === "RM Approval Pending"
  );

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Goal Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          {loading ? (
            <Loader />
          ) : eventtData ? (
            <div className="detailsEReq">
              <div className="detailInner">
                <Details
                  detailTitle={"Employee"}
                  Details={eventtData?.employee}
                />
                <Details
                  detailTitle={"Goal Setting"}
                  Details={eventtData?._pmssettings?.name}
                />

                <Details
                  detailTitle={"Status"}
                  Details={
                    hasApprovedStatus ? (
                      <div className="approvedGoals">Approved</div>
                    ) : Pending ? (
                      <div className="pendingGoals" >RM Approval Pending</div>
                    ) : (
                      <div className="rejectedGoals">Rejected</div>
                    )
                  }
                />

                {hasApprovedStatus && (
                  <Details
                    detailTitle={"Approved Date"}
                    Details={
                      hasApprovedStatus && (
                        <div>
                          {eventtData?._pmssettings?.pmsGoalsettingsEmployees[0]
                            ?.lastUpdatedOn &&
                            format(
                              new Date(
                                eventtData?._pmssettings?.pmsGoalsettingsEmployees[0]?.lastUpdatedOn
                              ),
                              "dd/MM/yyyy "
                            )}
                        </div>
                      )
                    }
                  />
                )}
                {hasRejectedStatus && (
                  <Details
                    detailTitle={"Rejected Date"}
                    Details={
                      hasRejectedStatus && (
                        <div>
                          {eventtData?._pmssettings?.pmsGoalsettingsEmployees[0]
                            ?.lastUpdatedOn &&
                            format(
                              new Date(
                                eventtData?._pmssettings?.pmsGoalsettingsEmployees[0]?.lastUpdatedOn
                              ),
                              "dd/MM/yyyy "
                            )}
                        </div>
                      )
                    }
                  />
                )}
              </div>
              <div className="headingForm">Goal Details</div>
              {eventtData?._pmssettings?.goalslist?.length > 0 ? (
                <>
                  <div className="detailInner">
                    <div style={{ width: "100%" }}>
                      <table
                        className="tabless tabless-bordered"
                        style={{ width: "100%" }}
                      >
                        <thead
                          style={{
                            backgroundColor: "var(--primary-hover)",
                            borderTop: "1px solid lightgray",
                          }}
                        >
                          <tr>
                            <td>S.no</td>
                            <td>Objective</td>
                            <td>Goal Name</td>
                            <td>Start Date</td>
                            <td>Expected Date of Achievement</td>
                            <td>Goal Threshold</td>
                            <td>Weightage Percentage</td>

                            {/* <td>Duration</td> */}
                          </tr>
                        </thead>
                        <tbody>
                          {eventtData?._pmssettings?.goalslist?.map(
                            (items, index) => {
                              return (
                                <tr className="trStyle">
                                  <td>{index + 1}</td>
                                  <td>{items.name}</td>
                                  <td>{items.description}</td>
                                  <td>
                                    {" "}
                                    {items.startDate &&
                                      format(
                                        new Date(items.startDate),
                                        "dd/MM/yyyy hh:mm a"
                                      )}
                                  </td>
                                  <td>
                                    {" "}
                                    {items.expectedEndDate &&
                                      format(
                                        new Date(items.expectedEndDate),
                                        "dd/MM/yyyy hh:mm a"
                                      )}
                                  </td>
                                  <td>{items.kra}</td>
                                  <td>{items.weightage}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsAllGoals };
