/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ItsmdeskPic from "../../Assets/ItsmdeskPic.svg";
import ItsmexceptionPic from "../../Assets/ItsmexceptionPic.svg";
import ItsmchangePic from "../../Assets/ItsmchangePic.svg";
import ItsmassetPic from "../../Assets/ItsmassetPic.svg";
import "./ITSMMaster.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Asset, ITDesk, Change, Exception } from "./Components";
import { CreateRequestCategoryIt } from "./ITDeskComponents/CreateRequestCategoryIt";
import { CreateRequestTypeIt } from "./ITDeskComponents/CreateRequestTypeIt";
import { CreateServiceCategoryIt } from "./ITDeskComponents/CreateServiceCategoryIt";
import { CreateServiceTypeIt } from "./ITDeskComponents/CreateServiceTypeIt";
import { CreateTeamsIt } from "./ITDeskComponents/CreateTeamsIt";
import { CreateTeamMembersIt } from "./ITDeskComponents/CreateTeamMembersIt";
import { ModuleMasterDetailsPage } from "../HRMSMaster/Components";
import { CreateServiceVendors } from "./ITDeskComponents/CreateServiceVendors";
import { CreateAssetCategory } from "./AssetComponents/CreateAssetCategory";
import { CreateGroups } from "./ExceptionComponents/CreateGroups";
import { CreateCategories } from "./ExceptionComponents/CreateCategories";
import { CreateStatement } from "./ExceptionComponents/CreateStatement";
import { CreateChangeTypes } from "./ChangeComponent/CreateChangeTypes";
import { CreateAssetMaterial } from "./AssetComponents/CreateAssetMaterial";
import { CreateAssetSpecification } from "./AssetComponents/CreateAssetSpecification";
import { DetailsPageWorkflow } from "./ITDeskComponents/DetailsPageWorkflow";
import { DetailsTeamIt } from "./ITDeskComponents/DetailsTeamIt";
import { DetailsServiceIt } from "./ITDeskComponents/DetailsServiceIt";
import { DetailsChange } from "./ChangeComponent/DetailsChange";
import { DetailsAssetCategoryIt } from "./AssetComponents/DetailsAssetCategoryIt";
import { DetailsCataloge } from "./ExceptionComponents/DetailsCataloge";
import { CreateWorkflowItN } from "./ITDeskComponents/CreateWorkflowItN";
import { DetailAssetMaterialIt } from "./AssetComponents/DetailAssetMaterialIt";
import { GroupBy } from "../../Utilities";
import { useDispatch } from "react-redux";
import { loggedOut } from "../../Features";
import Skeleton from "react-loading-skeleton";
import { getRequest, postRequest } from "../../Services/axios";

const ITSMMaster = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const [subMenuList, setSubMenuList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState("");
  const [activeFeature, setActiveFeature] = useState("");
  const [createActive, setCreateActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempMenu = Object.values(GroupBy(result?.data, "menuName"));
        tempMenu.map((item) => {
          try {
            if (
              item?.[0]?.menuName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase() !== "itsm"
            )
              return null;
            const tempGroup = GroupBy(item, "subMenuName");
            let tempSubMenu = Object.values(tempGroup);
            return setSubMenuList(tempSubMenu);
          } catch (error) {
             
          }
        });
      }
    } catch (err) {
       
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("itsmmaster")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "itsmmaster");
      if (splitArray?.length > submoduleIndex + 1) {
        if (subMenuList) {
          if (
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              subMenuList
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        if (subMenuList)
          navigate(
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        }
      }
    }
  }, [location.pathname, subMenuList]);
  const getFeatureMenu = (submenu) => {
    if (!subMenuList) return null;
    return subMenuList?.find(
      (obj) =>
        obj?.[0]?.subMenuName
          ?.replaceAll(" ", "")
          ?.replaceAll("&", "")
          ?.toLowerCase() === submenu
    );
  };
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "itdesk") return ItsmdeskPic;
    else if (subMenuName === "asset") return ItsmassetPic;
    else if (subMenuName === "change") return ItsmchangePic;
    else if (subMenuName === "exception") return ItsmexceptionPic;
    return ItsmdeskPic;
  };

  return (
    <div className="moduleContainer">
      {activeSubmodule === "itdesk" && (
        <>
          {activeFeature === "requestcategory" && (
            <>
              {createActive && (
                <CreateRequestCategoryIt title={"Create Request Category"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "requesttype" && (
            <>
              {createActive && (
                <CreateRequestTypeIt title={"Create  Request Type"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "servicecategory" && (
            <>
              {createActive && (
                <CreateServiceCategoryIt title={"Create Service Category "} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "workflow" && (
            <>
              {createActive && (
                <CreateWorkflowItN title={"Create  Workflow "} />
              )}
              {detailsActive && <DetailsPageWorkflow />}
            </>
          )}
          {activeFeature === "teammembers" && (
            <>
              {createActive && (
                <CreateTeamMembersIt title={"Create Team Members"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "servicetype" && (
            <>
              {createActive && (
                <CreateServiceTypeIt title={"Create  Service Type"} />
              )}
              {detailsActive && <DetailsServiceIt />}
            </>
          )}
          {activeFeature === "teams" && (
            <>
              {createActive && <CreateTeamsIt title={"Create  Teams"} />}
              {detailsActive && <DetailsTeamIt />}
            </>
          )}
          {activeFeature === "servicevendors" && (
            <>
              {createActive && (
                <CreateServiceVendors title={"Create Service Vendors"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "asset" && (
        <>
          {activeFeature === "assetcategory" && (
            <>
              {createActive && (
                <CreateAssetCategory title={"Create Asset Category"} />
              )}
              {detailsActive && <DetailsAssetCategoryIt />}
            </>
          )}
          {activeFeature === "assetmaterial" && (
            <>
              {createActive && (
                <CreateAssetMaterial title={"Create Asset Material"} />
              )}
              {detailsActive && <DetailAssetMaterialIt />}
            </>
          )}
          {activeFeature === "assetspecification" && (
            <>
              {createActive && (
                <CreateAssetSpecification
                  title={"Create Asset specification "}
                />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "change" && (
        <>
          {createActive && <CreateChangeTypes title={"Create Change Types"} />}
          {detailsActive && <DetailsChange />}
        </>
      )}
      {activeSubmodule === "exception" && (
        <>
          {activeFeature === "categories" && (
            <>
              {createActive && <CreateCategories title={"Create Categories"} />}
              {detailsActive && <DetailsCataloge />}
            </>
          )}
          {activeFeature === "statements" && (
            <>
              {createActive && <CreateStatement title={"Create Statement"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "groups" && (
            <>
              {createActive && <CreateGroups title={"Create Groups"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {loading ? (
            <Skeleton height={55} count={5} />
          ) : (
            <>
              {subMenuList &&
                subMenuList
                  ?.slice()
                  ?.filter((x) => x[0].isSubMenuActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a[0].subMenuOrder) -
                      parseFloat(b[0].subMenuOrder)
                  )
                  ?.map((subMenu, index) => {
                    return (
                      <div
                        key={index}
                        className={`menuOptions ${
                          activeSubmodule ===
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "menuOptionsActive"
                        }`}
                        onClick={() => {
                          setActiveSubmodule(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          );
                          navigate(
                            `/itsmmaster/${subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }}
                      >
                        <div className="verticalLine"></div>
                        <img
                          src={handleMenuIcon(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          )}
                          alt={subMenu[0]?.subMenuDisplayName}
                        />
                        <label>{subMenu[0]?.subMenuDisplayName}</label>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {loading ? (
          <div className="moduleMainContainer">
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {activeSubmodule === "itdesk" ? (
              <ITDesk featureMenu={getFeatureMenu("itdesk")} />
            ) : null}
            {activeSubmodule === "asset" ? (
              <Asset featureMenu={getFeatureMenu("asset")} />
            ) : null}
            {activeSubmodule === "change" ? (
              <Change featureMenu={getFeatureMenu("change")} />
            ) : null}
            {activeSubmodule === "exception" ? (
              <Exception featureMenu={getFeatureMenu("exception")} />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export { ITSMMaster };
