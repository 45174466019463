import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

const WelcomeOnboard = () => {
  useEffect(() => {
    document.title = `PeopleSol - User Onboarding`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <div className="imageHolder" style={{display:'flex'}}>
        <svg width="100%" height="100%" viewBox="0 0 654 459" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M639.444 458.355C644.275 438.406 673.883 320.946 629.065 240.616C584.238 160.287 516.622 118.336 423.839 120.52C356.56 122.102 291.303 144.437 246.338 212.1C201.382 279.764 154.576 247.297 87.4088 292.097C41.6054 322.649 35.7546 402.405 60.9551 458.345H639.444V458.355Z" fill="url(#paint0_linear_1214_298)"/>
        <path d="M0.5 256.14C0.5 258.728 2.5912 260.819 5.17969 260.819H89.2738C87.2591 253.118 80.246 247.431 71.9067 247.431C70.249 247.431 68.6296 247.66 67.0995 248.081V237.574C67.0995 232.55 65.0721 227.998 61.7695 224.708C58.4797 221.405 53.9275 219.378 48.9035 219.378C38.8556 219.378 30.7076 227.526 30.7076 237.574V243.299C28.6036 241.909 26.0917 241.106 23.3884 241.106C17.0383 241.106 11.7211 245.531 10.3439 251.46H5.17969C2.5912 251.46 0.5 253.551 0.5 256.14Z" fill="#EBF7FF"/>
        <path d="M647.62 148.315C647.62 150.891 645.529 152.969 642.953 152.969H559.127C561.129 145.293 568.129 139.619 576.43 139.619C578.088 139.619 579.695 139.848 581.225 140.269V135.539C581.225 130.527 583.252 125.988 586.542 122.711C589.819 119.421 594.359 117.394 599.37 117.394C609.392 117.394 617.515 125.516 617.515 135.539V135.513C619.606 134.136 622.118 133.333 624.821 133.333C631.159 133.333 636.45 137.744 637.815 143.661H642.979C645.529 143.661 647.62 145.739 647.62 148.315Z" fill="#EBF7FF"/>
        <path d="M165.07 131.609C165.07 133.572 163.476 135.154 161.525 135.154H99.4392C100.969 129.313 106.287 124.991 112.624 124.991C113.886 124.991 115.11 125.169 116.271 125.488V122.071C116.271 118.258 117.814 114.803 120.313 112.303C122.812 109.804 126.268 108.261 130.08 108.261C137.706 108.261 143.89 114.446 143.89 122.071V121.854C145.484 120.808 147.396 120.196 149.449 120.196C154.269 120.196 158.299 123.563 159.344 128.064H161.512C163.476 128.064 165.07 129.645 165.07 131.609Z" fill="#EBF7FF"/>
        <g opacity="0.6">
        <path d="M133.121 457.134C136.054 430.024 139.586 367.607 105.514 355.047C71.4433 342.488 56.1673 376.559 70.2701 400.059C84.373 423.56 115.154 429.03 117.386 457.134H133.121Z" fill="url(#paint1_linear_1214_298)"/>
        <path d="M126.464 446.41C126.095 443.222 125.597 440.034 125.011 436.872C123.838 430.496 122.282 424.184 120.357 417.987C116.468 405.542 111.087 393.428 104.163 382.373C100.21 376.061 95.8361 369.979 90.9397 364.356C90.6847 364.062 90.5571 364.534 90.7229 364.738C98.7816 375.118 105.795 386.326 111.303 398.274C116.672 409.916 120.714 422.182 123.2 434.768C123.889 438.287 124.463 441.819 124.922 445.377C125.151 447.137 125.368 448.909 125.534 450.681C125.725 452.569 125.891 454.456 126.362 456.305C126.426 456.56 126.86 456.764 126.911 456.407C127.383 453.13 126.847 449.661 126.464 446.41Z" fill="#DEEDFE"/>
        <path d="M82.69 428.099C66.9678 429.03 68.6892 443.171 81.4914 446.371C93.1715 449.291 101.192 448.003 109.378 457.12H113.28C109.684 445.491 99.6236 427.091 82.69 428.099Z" fill="#47B2EB"/>
        <path d="M109.952 453.639C109.072 451.14 107.466 448.845 105.757 446.83C104.061 444.828 102.148 443.004 100.07 441.398C95.9384 438.236 91.1568 435.928 86.0945 434.742C83.2765 434.079 80.4203 433.811 77.5512 434.13C77.462 434.142 77.4492 434.346 77.5512 434.359C82.8175 434.729 87.9562 435.711 92.7634 437.942C97.3156 440.059 101.434 443.119 104.737 446.919C106.611 449.074 108.078 451.459 109.646 453.83C109.786 454.073 110.041 453.907 109.952 453.639Z" fill="#DEEDFE"/>
        <path d="M566.808 457.134C563.875 430.024 560.343 367.607 594.414 355.047C628.485 342.488 643.761 376.559 629.658 400.059C615.556 423.56 584.774 429.03 582.543 457.134H566.808Z" fill="url(#paint2_linear_1214_298)"/>
        <path d="M573.464 446.41C573.834 443.222 574.331 440.034 574.918 436.872C576.091 430.496 577.646 424.184 579.572 417.987C583.461 405.542 588.842 393.428 595.766 382.373C599.719 376.061 604.092 369.979 608.989 364.356C609.244 364.062 609.371 364.534 609.206 364.738C601.147 375.118 594.134 386.326 588.625 398.274C583.257 409.916 579.215 422.182 576.728 434.768C576.04 438.287 575.466 441.819 575.007 445.377C574.777 447.137 574.561 448.909 574.395 450.681C574.204 452.569 574.038 454.456 573.566 456.305C573.502 456.56 573.069 456.764 573.018 456.407C572.546 453.13 573.081 449.661 573.464 446.41Z" fill="#DEEDFE"/>
        <path d="M617.239 428.099C632.961 429.03 631.239 443.171 618.437 446.371C606.757 449.291 598.737 448.003 590.55 457.12H586.648C590.244 445.491 600.305 427.091 617.239 428.099Z" fill="#47B2EB"/>
        <path d="M589.977 453.639C590.856 451.14 592.463 448.845 594.172 446.83C595.868 444.828 597.78 443.004 599.859 441.398C603.99 438.236 608.772 435.928 613.834 434.742C616.652 434.079 619.508 433.811 622.377 434.13C622.467 434.142 622.479 434.346 622.377 434.359C617.111 434.729 611.972 435.711 607.165 437.942C602.613 440.059 598.494 443.119 595.192 446.919C593.317 449.074 591.851 451.459 590.283 453.83C590.142 454.073 589.887 453.907 589.977 453.639Z" fill="#DEEDFE"/>
        </g>
        <path d="M512.884 433.684H392.296V457.146H512.884V433.684Z" fill="#4E4E4E"/>
        <path d="M488.02 215.576C494.982 203.947 501.969 186.401 501.638 179.005C501.306 171.597 495.313 162.824 481.261 155.224C485.508 152.776 486.872 150.927 483.238 149.282C479.604 147.625 469.365 144.488 454.828 144.654C457.633 139.795 456.32 138.214 448.555 140.025C440.789 141.836 416.791 144.462 420.209 169.378C422.631 187 447.241 212.681 464.583 216.162C481.924 219.656 488.02 215.576 488.02 215.576Z" fill="#292929"/>
        <path d="M429.16 173.892C427.145 173.649 426.304 169.735 426.304 168.332C426.304 166.725 426.278 164.137 426.457 163.04C424.225 164.851 419.839 167.631 417.008 180.05C413.999 193.312 411.806 215.983 414.534 225.088C417.99 236.627 436.428 238.706 444.704 239.522C443.951 247.593 444.602 253.88 439.157 254.109C439.374 262.474 432.858 274.741 444.716 278.12C456.575 281.486 483.697 268.607 487.216 254.683C481.312 252.579 477.525 251.011 477.525 241.435C477.525 235.926 478.507 229.461 478.507 229.461C478.507 229.461 481.376 228.441 484.475 223.991C490.085 215.907 489.983 204.061 481.861 201.128C475.536 198.846 471.06 202.505 468.905 207.606C468.064 209.595 465.514 210.717 463.83 208.983C462.517 207.631 462.759 205.566 462.747 203.844C462.721 201.753 461.701 200.733 460.235 199.33C454.446 193.758 452.405 184.628 455.274 177.13C456.205 174.695 457.595 172.451 458.73 170.104C459.38 168.766 459.929 167.146 459.202 165.858C458.832 165.208 458.194 164.762 457.544 164.392C448.414 159.126 436.607 165.616 430.563 172.795C430.18 173.267 429.797 173.981 429.16 173.892Z" fill="#FBC8C7"/>
        <path d="M444.704 239.535C451.487 239.408 456.983 237.941 464.787 229.487C463.422 239.574 455.58 244.942 444.321 244.738L444.704 239.535Z" fill="url(#paint3_linear_1214_298)"/>
        <path d="M441.771 192.878C441.516 194.523 440.241 195.696 438.941 195.492C437.627 195.288 436.786 193.783 437.041 192.139C437.296 190.494 438.571 189.32 439.872 189.525C441.172 189.729 442.026 191.233 441.771 192.878Z" fill="#292929"/>
        <path d="M421.931 190.8C421.689 192.355 420.49 193.465 419.253 193.273C418.016 193.082 417.213 191.654 417.455 190.098C417.697 188.543 418.896 187.433 420.133 187.624C421.357 187.816 422.173 189.231 421.931 190.8Z" fill="#292929"/>
        <path d="M445.01 187.421C444.883 187.204 444.793 186.987 444.64 186.783C444.424 186.49 444.181 186.184 443.939 185.903C443.365 185.202 442.511 184.654 441.631 184.386C441.095 184.22 440.56 184.131 439.999 184.131C439.425 184.131 438.915 184.246 438.354 184.348C437.933 184.424 437.5 184.246 437.359 183.812C437.219 183.379 437.461 182.983 437.869 182.83C437.474 183.098 437.41 183.124 437.678 182.907C437.742 182.881 437.806 182.856 437.869 182.83C437.959 182.792 438.048 182.767 438.137 182.741C438.316 182.69 438.494 182.652 438.673 182.626C439.068 182.563 439.476 182.524 439.871 182.524C440.675 182.524 441.529 182.665 442.269 182.983C443.263 183.43 444.156 184.22 444.679 185.177C444.832 185.47 444.972 185.75 444.997 186.082C445.036 186.503 445.112 187 445.061 187.421C445.074 187.472 445.023 187.446 445.01 187.421Z" fill="#292929"/>
        <path d="M416.319 184.208C416.434 183.507 416.791 182.793 417.263 182.283C417.901 181.594 418.704 181.148 419.622 180.957C421.127 180.638 422.746 181.072 424.021 181.875C424.365 182.092 424.429 182.627 424.212 182.946C423.957 183.303 423.511 183.329 423.167 183.112C423.116 183.074 423.052 183.048 423.001 183.01C422.899 182.959 422.784 182.908 422.682 182.857C422.402 182.729 422.096 182.614 421.802 182.538C421.254 182.385 420.655 182.321 420.081 182.372C419.392 182.423 418.64 182.653 418.015 182.946C417.697 183.099 417.391 183.303 417.11 183.507C416.804 183.724 416.625 184.03 416.37 184.285C416.332 184.323 416.319 184.234 416.319 184.208Z" fill="#292929"/>
        <path d="M478.52 229.462C472.935 231.209 469.084 228.697 468.051 225.738C467.325 229.015 472.782 233.619 478.52 229.462Z" fill="#FB929C"/>
        <path d="M484.284 208.995C483.34 207.299 480.905 207.07 479.209 207.491C477.487 207.924 475.944 208.944 474.784 210.271C473.713 211.495 472.961 212.987 472.591 214.568C472.234 216.047 472.03 218.138 473.037 219.413C473.177 219.592 473.534 219.668 473.598 219.375C473.751 218.686 473.585 217.921 473.585 217.22C473.585 216.506 473.662 215.805 473.815 215.116C474.121 213.739 474.746 212.413 475.626 211.303C475.702 211.214 475.779 211.138 475.855 211.048C475.868 211.048 475.868 211.061 475.868 211.061C477.003 211.954 477.985 213.025 478.635 214.325C479.298 215.677 479.489 217.194 479.451 218.686C479.451 218.788 479.591 219.018 479.693 218.865C480.637 217.424 480.331 215.435 479.655 213.943C478.992 212.476 477.921 211.227 476.697 210.207C477.424 209.582 478.252 209.059 479.17 208.753C480.841 208.179 482.422 208.562 483.939 209.365C484.194 209.518 484.424 209.25 484.284 208.995Z" fill="#FB929C"/>
        <path d="M461.931 200.006C461.892 206.739 458.896 211.1 453.489 214.657C449.23 217.45 443.467 216.392 437.92 213.459C433.916 211.342 429.874 210.756 424.952 210.36C420.833 210.029 415.529 211.011 413.196 213.076C412.061 217.195 412.035 230.125 416.422 234.384C420.808 238.642 429.466 240.836 440.19 240.581C450.914 240.326 460.094 237.1 463.563 231.655C469.594 222.193 467.541 210.679 469.173 207.032L461.931 200.006Z" fill="#FBC8C7"/>
        <path d="M427.783 187.868C427.617 189.155 427.082 190.405 426.674 191.629C426.266 192.892 425.845 194.141 425.437 195.404C424.646 197.865 423.932 200.351 423.282 202.863C422.976 204.049 422.606 205.248 422.415 206.459C422.211 207.721 422.517 208.729 423.703 209.328C424.774 209.863 426.024 210.118 427.197 210.271C427.821 210.361 428.459 210.399 429.097 210.386C429.543 210.386 430.066 210.271 430.486 210.45C430.652 210.514 430.588 210.794 430.448 210.871C429.734 211.215 428.854 211.164 428.089 211.151C426.967 211.126 425.858 210.973 424.787 210.667C423.078 210.195 421.357 209.392 421.369 207.364C421.382 206.433 421.65 205.515 421.879 204.623C422.16 203.488 422.466 202.34 422.772 201.218C423.371 199.05 424.034 196.883 424.748 194.741C425.513 192.394 426.266 189.882 427.541 187.766C427.617 187.613 427.796 187.727 427.783 187.868Z" fill="#FB929C"/>
        <path d="M436.403 217.284C435.969 217.411 435.599 217.692 435.178 217.858C434.694 218.049 434.184 218.189 433.687 218.329C432.896 218.546 432.067 218.674 431.251 218.712C430.499 218.75 429.746 218.699 429.02 218.572C428.612 218.508 428.229 218.508 427.923 218.801C427.604 219.107 427.808 219.554 428.127 219.77C428.459 219.987 428.969 220 429.364 220.051C429.721 220.089 430.078 220.115 430.435 220.127C431.2 220.14 431.978 220.102 432.73 219.949C433.508 219.796 434.26 219.579 434.974 219.235C435.421 219.031 435.854 218.776 436.237 218.457C436.466 218.266 436.862 217.96 436.823 217.615C436.785 217.437 436.607 217.22 436.403 217.284Z" fill="#FB929C"/>
        <path d="M277.561 360.314C275.891 362.469 274.616 366.294 274.335 370.477C273.94 376.406 274.909 380.78 276.796 386.416C276.796 386.416 278.734 386.48 281.348 386.48C283.962 386.48 285.416 387.015 286.64 387.398C289.879 388.418 290.797 389.4 293.589 391.338C296.101 393.085 316.185 403.018 319.959 403.681C323.364 404.28 325.11 404.777 326.972 404.573C329.739 404.267 333.807 402.916 331.371 398.874C335.949 401.602 341.1 399.996 340.743 395.622C343.192 397.688 347.412 397.943 349.593 395.558C350.524 394.513 350.026 392.485 349.529 391.223C342.363 372.746 338.206 372.899 318.365 363.872C311.824 360.888 307.106 359.307 300.309 359.192C294.622 359.09 280.724 359.638 277.561 360.314Z" fill="#F8D8D8"/>
        <path d="M316.401 404.242C317.281 403.821 318.174 403.375 318.85 402.673C319.525 401.972 319.959 400.952 319.704 400.021C318.926 397.127 314.642 397.547 312.436 397.828C312.844 397.42 313.265 396.999 313.673 396.591C314.425 395.839 315.216 394.972 315.228 393.913C315.228 393.097 314.744 392.345 314.093 391.86C313.443 391.376 312.64 391.146 311.836 391.006C310.995 390.853 310.128 390.815 309.274 390.853C309.108 390.866 307.705 390.878 307.68 390.917C308.355 390.19 308.98 389.399 309.299 388.456C310.141 385.944 307.807 384.018 305.41 384.159C304.148 384.235 302.962 384.758 301.84 385.306C298.244 387.079 294.036 389.234 291.919 392.753C291.333 393.722 290.886 395.035 291.6 396.017C292.391 397.114 293.781 397.751 295.081 397.955C296.777 398.223 298.486 397.879 300.169 397.547C299.391 398.402 299.277 399.741 299.736 400.799C300.896 403.489 304.301 403.234 306.749 402.89C306.519 403.63 306.991 404.446 307.654 404.854C310.459 406.613 313.66 405.568 316.401 404.242Z" fill="#FBC8C7"/>
        <path d="M339.8 358.772C334.725 357.255 329.714 355.342 324.447 354.717C322.879 354.526 321.272 354.462 319.768 354.921C318.123 355.418 316.733 356.489 315.407 357.586C310.995 361.233 306.966 365.339 303.382 369.814C303.178 370.082 302.962 370.35 302.847 370.656C302.592 371.37 302.898 372.199 303.459 372.722C304.02 373.232 304.772 373.487 305.525 373.602C308.7 374.112 311.875 372.632 314.476 370.745C315.981 369.649 317.434 368.374 319.232 367.864C321.349 367.277 323.619 367.864 325.697 368.578C330.938 370.376 335.605 372.505 338.665 374.8C340.131 375.897 342.452 379.429 344.837 383.292C346.367 385.779 349.516 391.211 349.516 391.211L362.242 386.442C361.477 380.347 360.699 374.226 359.347 368.233C358.863 366.091 357.537 360.098 357.537 360.098C357.537 360.098 353.584 360.557 351.25 360.391C347.935 360.162 342.988 359.728 339.8 358.772Z" fill="#FBC8C7"/>
        <path d="M364.474 354.768L354.757 358.695L361.834 391.121L373.196 387.487L364.474 354.768Z" fill="url(#paint4_linear_1214_298)"/>
        <path d="M397.793 336.444L361.401 354.717L370.671 391.517L396.071 382.782L397.793 336.444Z" fill="#B5B5D9"/>
        <path d="M439.17 254.11L416.333 264.196C416.333 264.196 404.155 308.66 402.166 354.959C400.19 401.246 401.376 433.672 401.376 433.672C422.237 441.718 463.818 437.702 505.425 433.672V267.524L482.716 250.846C482.716 250.846 449.371 264.834 443.761 264.196C438.163 263.571 439.17 254.11 439.17 254.11Z" fill="url(#paint5_linear_1214_298)"/>
        <path d="M505.425 309.909L452.61 321.678C445.826 364.05 444.271 409.419 439.413 416.827C431.52 428.89 419.457 431.147 401.133 422.68C401.235 429.744 401.376 433.672 401.376 433.672C422.237 441.718 463.818 437.701 505.425 433.672V309.909Z" fill="#C5C5DA"/>
        <path d="M439.17 254.11C431.774 263.508 424.175 274.525 418.08 299.606C411.985 324.688 408.567 364.548 408.567 385.18C408.567 405.811 407.891 440.29 407.891 440.29L392.31 433.672C393.33 391.976 395.765 328.118 406.132 283.705C410.148 266.491 413.91 261.633 424.366 256.482C434.822 251.33 442.256 248.742 442.256 248.742L439.17 254.11Z" fill="#9E9ECC"/>
        <path d="M443.761 258.993L441.555 258.037C441.555 258.037 435.893 259.975 434.937 265.841C433.981 271.706 435.881 278.669 435.881 278.669L442.18 279.778C442.18 279.778 446.681 272.956 448.185 270.635C449.677 268.302 443.761 258.993 443.761 258.993Z" fill="#77A2C7"/>
        <path d="M435.88 278.668C429.658 296.036 414.114 356.961 414.675 366.499C415.236 376.049 418.832 408.042 423.346 408.884C427.86 409.725 437.882 377.439 438.915 370.426C439.935 363.413 442.167 279.765 442.167 279.765L435.88 278.668Z" fill="#77A2C7"/>
        <path d="M441.567 258.037C442.077 254.212 442.932 252.886 443.773 249.876C443.773 248.066 444.232 246.025 444.232 246.025C444.232 246.025 443.442 245.745 442.269 246.982C439.349 250.055 432.119 257.552 430.614 262.411C427.286 273.058 429.084 279.523 429.084 279.523C429.084 279.523 433.815 266.925 441.567 258.037Z" fill="#B5B5D9"/>
        <path d="M443.761 258.993C443.761 258.993 463.653 257.221 477.666 237.635L477.832 235.378C477.832 235.378 479.77 234.154 480.446 237.125C481.122 240.096 492.292 250.858 492.292 250.858C492.292 250.858 479.247 276.399 448.899 282.864C448.491 270.737 443.761 258.993 443.761 258.993Z" fill="#B5B5D9"/>
        <path d="M484.984 245.655C483.263 253.14 469.862 266.567 460.757 293.23C451.653 319.893 449.064 334.404 447.7 370.362C446.425 403.923 448.325 441.807 448.325 441.807L512.897 433.684C512.897 433.684 505.654 380.69 507.299 357.585C508.944 334.48 512.412 258.993 512.412 258.993C512.412 258.993 495.657 255.027 484.984 245.655Z" fill="#9E9ECC"/>
        <path d="M486.362 255.028C486.464 259.771 486.642 269.258 486.706 274.002C481.248 279.803 475.255 285.414 469.555 291.088L469.721 290.706C469.492 302.692 468.88 326.804 468.51 338.778C462.453 349.489 455.618 359.996 450.837 371.408C454.955 360.289 461.994 348.112 467.464 338.752C467.694 326.651 468.28 302.552 468.637 290.451C474.197 285.184 480.981 278.388 486.03 273.989C486.081 269.296 486.272 259.746 486.362 255.028Z" fill="#9E9ECC"/>
        <path d="M512.4 258.993C521.288 263.074 527.625 271.438 531.501 282.863C535.378 294.288 555.27 360.926 557.59 369.457C559.911 377.987 561.977 385.217 551.648 400.455C541.32 415.693 519.158 457.147 519.158 457.147H478.367C478.367 457.147 498.897 406.512 503.296 397.216C507.682 387.921 508.639 377.146 501.791 356.502C495.671 338.051 492.394 326.6 491.067 317.559C489.741 308.519 494.23 272.318 512.4 258.993Z" fill="#B5B5D9"/>
        <path d="M243.834 271.323C255.387 274.676 258.983 281.677 261.584 291.266C264.185 300.855 266.812 319.114 266.812 319.114L243.834 271.323Z" fill="#F899DC"/>
        <path d="M149.489 222.87C147.066 224.986 145.204 227.549 144.363 230.482C143.355 234.001 143.534 237.75 144.031 241.384C144.516 244.942 145.255 248.742 143.674 251.955C141.558 256.278 136.215 257.757 132.428 260.728C128.016 264.183 125.682 270.113 126.549 275.647C127.467 281.525 131.612 286.651 131.841 292.58C132.071 298.675 130.54 305.064 134.085 310.598C137.592 316.081 143.19 319.103 149.565 320.391C160.837 322.66 168.016 313.173 177.898 310.317C188.303 307.308 200.034 313.505 209.917 307.958C213.538 305.918 216.254 302.322 217.198 298.267C218.001 294.876 217.618 291.241 218.83 287.977C220.857 282.545 226.633 279.587 230.319 275.111C235.559 268.748 236.146 259.351 233.073 251.7C230 244.049 223.739 238.018 216.688 233.746C209.636 229.462 201.769 226.759 193.978 224.068C185.039 220.995 175.947 216.43 166.308 216.443C160.837 216.468 154.13 218.827 149.489 222.87Z" fill="#826D6A"/>
        <path opacity="0.3" d="M142.067 295.296C142.322 293.447 142.564 291.56 142.297 289.685C142.093 288.296 141.608 286.982 140.996 285.72C139.644 282.953 137.655 280.454 136.775 277.47C135.806 274.206 136.457 270.584 138.433 267.817C141.493 263.546 146.76 261.646 150.47 258.075C152.345 256.265 153.722 254.046 154.13 251.432C154.576 248.576 154.04 245.681 153.467 242.889C152.804 239.624 151.975 236.245 152.651 232.917C153.479 228.862 156.859 226.287 160.544 224.897C160.671 224.846 160.607 224.514 160.467 224.552C157.509 225.394 154.64 227.128 153.008 229.806C151.439 232.356 151.324 235.327 151.682 238.234C152.396 244.087 155.073 250.59 151.006 255.831C147.652 260.128 142.093 261.926 138.446 265.943C136.329 268.276 135.105 271.273 135.245 274.448C135.385 277.776 137.132 280.568 138.828 283.323C139.785 284.866 140.715 286.459 141.238 288.206C141.901 290.412 141.672 292.695 141.379 294.939C140.907 298.497 140.422 302.449 142.947 305.395C143.189 305.675 143.304 305.216 143.151 305.012C141.06 302.245 141.634 298.484 142.067 295.296ZM160.505 246.115C159.307 242.136 158.325 237.202 161.054 233.618C161.092 233.567 161.054 233.414 160.99 233.491C158.58 236.22 158.682 239.943 159.358 243.322C160.199 247.53 162.329 251.814 161.462 256.188C160.097 263.048 151.86 264.451 148.57 269.985C146.109 274.142 147.537 279.14 149.45 283.195C149.514 283.323 149.616 283.182 149.578 283.08C148.124 279.344 147.142 274.767 149.055 271.005C150.483 268.2 153.275 266.44 155.762 264.668C158.185 262.946 160.671 261.008 161.704 258.101C163.081 254.186 161.64 249.902 160.505 246.115Z" fill="#9DC4FC"/>
        <path d="M240.698 200.657C246.219 193.032 250.542 172.808 240.698 162.467C230.854 152.126 219.85 156.283 219.85 156.283C219.85 156.283 208.425 150.672 194.309 151.922C180.193 153.171 164.561 162.582 160.238 172.285C155.992 181.811 154.156 212.732 182.948 229.679L240.698 200.657Z" fill="#826D6A"/>
        <path d="M223.942 173.777C225.715 172.234 227.232 170.385 228.495 168.396C232.192 171.597 235.903 179.464 238.211 188.9C241.361 201.728 243.222 215.69 243.235 223.481C243.248 229.704 242.814 231.667 242.126 234.243C239.473 244.036 229.642 246.688 221.838 246.663C222.183 256.328 222.323 267.549 227.768 267.409C232.958 277.929 251.727 304.885 240.124 309.054C228.52 313.224 182.947 279.905 178.484 266.249C184.159 260.562 186.671 257.68 185.561 248.167C184.681 240.619 182.973 229.665 182.973 229.665C182.973 229.665 175.845 231.986 169.826 224.782C163.514 217.233 162.341 207.109 170.234 203.628C176.38 200.924 182.093 205.859 184.592 210.819C185.561 212.745 188.201 213.688 189.756 211.839C190.968 210.398 190.585 208.358 190.483 206.637C190.369 204.546 191.312 203.462 192.676 201.957C195.75 198.565 196.961 194.128 195.367 189.742C194.398 187.076 191.299 182.996 192.511 180.025C193.327 178.036 195.928 177.59 198.057 177.743C205.16 178.253 212.543 179.872 219.326 176.799C220.997 176.034 222.553 175.001 223.942 173.777Z" fill="#F8D8D8"/>
        <path d="M221.826 246.651C215.055 246.995 201.526 244.764 193.633 233.9C197.331 249.087 210.924 255.296 222.145 254.315L221.826 246.651Z" fill="#D9A9A9"/>
        <path d="M210.554 203.667C210.924 205.286 212.262 206.37 213.563 206.077C214.851 205.783 215.603 204.228 215.233 202.608C214.864 200.989 213.525 199.905 212.224 200.198C210.936 200.492 210.184 202.047 210.554 203.667Z" fill="#826D6A"/>
        <path d="M232.32 199.725C232.665 201.268 233.94 202.288 235.164 202.008C236.388 201.727 237.089 200.261 236.745 198.718C236.401 197.175 235.126 196.155 233.902 196.436C232.678 196.716 231.963 198.182 232.32 199.725Z" fill="#826D6A"/>
        <path d="M206.958 198.438C207.073 198.221 207.149 197.991 207.29 197.775C207.494 197.456 207.71 197.15 207.927 196.844C208.45 196.104 209.266 195.505 210.12 195.173C210.643 194.969 211.166 194.842 211.727 194.804C212.301 194.765 212.811 194.842 213.385 194.906C213.818 194.957 214.239 194.753 214.341 194.294C214.456 193.86 214.175 193.477 213.767 193.35C214.175 193.592 214.239 193.618 213.958 193.414C213.895 193.388 213.831 193.363 213.767 193.35C213.678 193.324 213.589 193.299 213.487 193.286C213.308 193.248 213.13 193.222 212.938 193.21C212.543 193.171 212.135 193.159 211.727 193.184C210.924 193.248 210.082 193.439 209.368 193.809C208.399 194.319 207.57 195.173 207.111 196.168C206.971 196.474 206.856 196.755 206.856 197.099C206.843 197.52 206.805 198.017 206.881 198.438C206.894 198.501 206.945 198.463 206.958 198.438Z" fill="#826D6A"/>
        <path d="M237.459 191.195C237.306 190.507 236.898 189.818 236.375 189.334C235.687 188.683 234.858 188.301 233.927 188.173C232.409 187.969 230.828 188.505 229.604 189.397C229.273 189.64 229.247 190.175 229.489 190.481C229.757 190.825 230.216 190.813 230.548 190.583C230.599 190.545 230.65 190.507 230.714 190.468C230.816 190.405 230.918 190.354 231.02 190.29C231.3 190.137 231.581 190.009 231.887 189.907C232.422 189.716 233.022 189.614 233.595 189.627C234.284 189.64 235.049 189.805 235.687 190.06C236.005 190.188 236.337 190.366 236.63 190.558C236.949 190.749 237.153 191.042 237.421 191.284C237.446 191.31 237.459 191.221 237.459 191.195Z" fill="#826D6A"/>
        <path d="M179.569 230.009C185.383 229.372 187.908 225.521 187.666 222.397C189.642 225.113 186.48 231.514 179.569 230.009Z" fill="#D9A9A9"/>
        <path d="M169.355 210.87C170.184 209.111 172.594 208.715 174.315 209.021C176.062 209.34 177.669 210.246 178.918 211.495C180.079 212.643 180.92 214.084 181.405 215.627C181.864 217.08 182.208 219.159 181.29 220.497C181.163 220.689 180.806 220.778 180.729 220.497C180.525 219.822 180.64 219.044 180.589 218.343C180.538 217.641 180.41 216.927 180.206 216.251C179.811 214.9 179.097 213.612 178.128 212.579C178.051 212.49 177.962 212.426 177.886 212.337C177.873 212.349 177.873 212.349 177.873 212.362C176.814 213.331 175.884 214.466 175.335 215.805C174.762 217.208 174.672 218.725 174.813 220.204C174.825 220.306 174.698 220.548 174.583 220.408C173.537 219.031 173.716 217.029 174.29 215.499C174.851 213.982 175.833 212.681 176.993 211.559C176.228 210.985 175.361 210.526 174.43 210.271C172.721 209.825 171.178 210.297 169.712 211.215C169.47 211.393 169.228 211.138 169.355 210.87Z" fill="#D9A9A9"/>
        <path d="M225.664 196.958C225.996 198.221 226.684 199.381 227.233 200.554C227.806 201.753 228.38 202.951 228.941 204.15C230.038 206.496 231.058 208.868 232.027 211.278C232.486 212.413 232.996 213.56 233.34 214.734C233.71 215.958 233.519 217.003 232.422 217.743C231.428 218.406 230.216 218.827 229.069 219.12C228.457 219.286 227.832 219.4 227.207 219.477C226.774 219.528 226.225 219.49 225.83 219.719C225.677 219.809 225.766 220.076 225.919 220.127C226.672 220.37 227.539 220.217 228.304 220.102C229.413 219.936 230.497 219.643 231.53 219.209C233.162 218.521 234.769 217.513 234.501 215.499C234.373 214.581 233.991 213.701 233.646 212.834C233.226 211.737 232.779 210.653 232.32 209.569C231.44 207.491 230.522 205.438 229.541 203.398C228.482 201.166 227.424 198.782 225.881 196.831C225.792 196.691 225.626 196.818 225.664 196.958Z" fill="#D9A9A9"/>
        <path d="M214.979 223.724C214.864 224.106 215.106 224.438 215.285 224.782C215.514 225.228 215.871 225.573 216.241 225.891C216.891 226.465 217.644 226.886 218.434 227.243C219.913 227.919 221.494 228.327 223.101 228.467C223.892 228.531 224.733 228.582 225.511 228.493C225.945 228.442 226.48 228.136 226.633 227.689C226.85 227.09 226.136 226.529 225.6 226.503C224.822 226.465 224.057 226.542 223.28 226.452C222.464 226.363 221.66 226.197 220.857 225.981C220.054 225.764 219.276 225.458 218.511 225.114C217.937 224.859 217.363 224.604 216.815 224.297C216.598 224.17 216.368 224.093 216.152 223.979C215.858 223.838 215.642 223.647 215.336 223.52C215.183 223.469 215.03 223.583 214.979 223.724Z" fill="#D9A9A9"/>
        <path d="M148.073 433.684C144.146 443.821 142.871 457.146 142.871 457.146H276.707V433.684H148.073Z" fill="#555555"/>
        <path d="M183.445 261.161L239.142 302.398L226.34 266.67L239.907 270.304C239.907 270.304 260.794 307.371 265.486 326.945C270.179 346.518 273.315 433.685 273.315 433.685C260.156 445.263 203.885 438.556 151.937 433.685C151.937 433.685 165.81 400.009 167.378 387.474C168.947 374.94 154.321 317.521 151.975 306.033C149.629 294.544 148.838 287.505 151.975 283.068C155.125 278.63 183.445 261.161 183.445 261.161Z" fill="url(#paint6_linear_1214_298)"/>
        <path d="M273.061 426.825C260.105 431.555 242.751 431.173 240.43 427.105C237.459 421.903 240.43 408.909 240.43 408.909L271.556 394.513C271.275 389.272 270.969 383.815 270.625 378.357L204.255 385.141L167.379 382.247C167.532 384.389 167.544 386.187 167.391 387.475C165.823 400.009 151.95 433.685 151.95 433.685C203.885 438.556 260.169 445.263 273.328 433.685C273.328 433.685 273.239 431.135 273.061 426.825Z" fill="#E9DDE5"/>
        <path d="M183.445 261.161C183.445 261.161 204.918 284.534 217.147 309.399C227.692 330.859 229.834 336.164 232.588 374.596C235.33 413.041 233.927 445.403 233.927 445.403C201.297 447.775 172.109 444.766 148.086 433.672C148.086 433.672 157.025 402.636 160.378 389.821C163.732 377.006 159.766 360.799 153.977 338.242C148.175 315.685 145.128 292.657 145.74 287.161C146.339 281.678 160.544 276.463 183.445 261.161Z" fill="#EB7ECC"/>
        <path d="M226.34 266.669C240.086 282.353 254.673 308.85 257.95 328.206C261.227 347.563 263.905 442.533 263.905 442.533C270.612 440.544 275.661 437.905 276.707 433.684C276.707 433.684 272.894 357.088 270.51 334.467C268.125 311.834 243.822 271.336 243.822 271.336L226.34 266.669Z" fill="#EB7ECC"/>
        <path d="M265.078 361.335L278.021 360.276C278.021 360.276 275.356 366.512 275.725 374.634C276.095 382.757 278.021 387.742 278.021 387.742L265.473 390.178C265.473 390.178 263.535 385.804 263.076 376.674C262.617 367.519 265.078 361.335 265.078 361.335Z" fill="url(#paint7_linear_1214_298)"/>
        <path d="M147.933 283.807C142.616 289.226 142.106 297.502 147.933 326.677C153.76 355.851 172.683 393.493 176.011 401.003C179.339 408.501 184.35 412.951 199.907 409.611C215.463 406.282 267.412 392.345 267.412 392.345C264.262 382.973 263.319 371.013 265.193 360.71L210.184 367.927C210.184 367.927 201.131 331.713 197.892 320.735C191.555 299.325 171.497 270.202 147.933 283.807Z" fill="#F899DC"/>
        <path d="M210.796 367.327C207.519 354.742 204.408 342.093 200.812 329.596C197.586 318.375 193.493 307.014 186.033 297.846C182.08 292.988 177.35 288.703 171.828 285.707C171.765 285.669 171.752 285.822 171.803 285.86C181.15 291.585 187.704 300.638 192.396 310.393C197.905 321.844 200.774 334.353 204 346.581C205.798 353.428 207.621 360.263 209.432 367.11C209.457 367.187 209.406 367.276 209.317 367.289C203.4 368.398 197.509 369.597 191.759 371.344C191.644 371.382 191.669 371.65 191.81 371.637C196.528 370.885 201.194 369.941 205.887 369.023C207.519 368.704 209.292 368.386 210.975 368.054C210.949 368.054 210.847 367.531 210.796 367.327Z" fill="#EB7ECC"/>
        <path d="M231.849 365.096C230.918 365.108 212.543 366.881 209.317 367.289C209.024 367.327 210.439 368.156 210.669 368.118C211.919 367.914 231.874 365.096 231.849 365.096Z" fill="#EB7ECC"/>
        <path d="M253.041 387.462C252.658 385.587 252.365 383.713 252.148 381.813C251.931 379.977 251.804 378.128 251.753 376.279C251.702 374.315 251.919 372.313 251.702 370.362C251.676 370.158 251.37 369.942 251.294 370.222C250.797 372.007 250.911 374.022 250.937 375.871C250.962 377.784 251.077 379.696 251.281 381.596C251.485 383.534 251.804 385.473 252.199 387.385C252.607 389.336 253.066 391.236 253.933 393.034C254.035 393.238 254.201 393.136 254.176 392.932C253.959 391.07 253.423 389.285 253.041 387.462Z" fill="#EB7ECC"/>
        <path d="M457.562 1.71143H240.59C214.759 1.71143 193.812 22.5541 193.812 48.256C193.812 65.9248 203.704 81.2984 218.282 89.1764C214.801 97.0338 207.424 101.634 202.519 103.754C209.668 104.126 221.96 102.41 228.745 93.2912C232.527 94.2734 236.496 94.8006 240.59 94.8006H296.023C294.36 100.632 290.817 105.367 288.012 108.262C293.363 106.359 301.228 102.017 305.603 94.8006H383.957C388.341 102.017 396.197 106.359 401.548 108.262C398.742 105.377 395.199 100.632 393.537 94.8006H457.552C459.007 94.8006 460.451 94.7283 461.874 94.5939C468.815 102.565 480.255 104.095 487.05 103.754C482.811 101.924 476.722 98.2537 472.899 92.2263C491.196 85.8991 504.33 68.6128 504.33 48.256C504.34 22.5541 483.393 1.71143 457.562 1.71143Z" stroke="#A2BDE0" stroke-width="1.66828" stroke-miterlimit="10" stroke-linejoin="round"/>
        <path d="M217.786 23.6571H225.854L229.583 64.6028H229.735L233.693 23.6571H242.825L246.783 64.6028H246.935L250.664 23.6571H257.895L252.491 76.9321H242.064L238.259 41.0095H238.107L234.301 76.9321H223.19L217.786 23.6571Z" fill="#6EA1F3"/>
        <path d="M268.218 23.6571H291.05V31.2678H276.59V45.3476H288.082V52.9584H276.59V69.3214H291.05V76.9321H268.218V23.6571Z" fill="#6EA1F3"/>
        <path d="M301.955 23.6571H310.327V69.3214H324.102V76.9321H301.955V23.6571Z" fill="#6EA1F3"/>
        <path d="M345.705 77.6932C341.696 77.6932 338.627 76.5516 336.496 74.2684C334.415 71.9852 333.375 68.7633 333.375 64.6028V35.9864C333.375 31.8259 334.415 28.604 336.496 26.3208C338.627 24.0376 341.696 22.896 345.705 22.896C349.713 22.896 352.757 24.0376 354.837 26.3208C356.968 28.604 358.034 31.8259 358.034 35.9864V41.6184H350.119V35.4537C350.119 32.1557 348.723 30.5067 345.933 30.5067C343.142 30.5067 341.747 32.1557 341.747 35.4537V65.2116C341.747 68.4589 343.142 70.0825 345.933 70.0825C348.723 70.0825 350.119 68.4589 350.119 65.2116V57.0681H358.034V64.6028C358.034 68.7633 356.968 71.9852 354.837 74.2684C352.757 76.5516 349.713 77.6932 345.705 77.6932Z" fill="#6EA1F3"/>
        <path d="M381.381 77.6932C377.271 77.6932 374.125 76.5262 371.944 74.1923C369.762 71.8583 368.671 68.5603 368.671 64.2983V36.2909C368.671 32.0289 369.762 28.7309 371.944 26.3969C374.125 24.063 377.271 22.896 381.381 22.896C385.491 22.896 388.637 24.063 390.818 26.3969C393 28.7309 394.091 32.0289 394.091 36.2909V64.2983C394.091 68.5603 393 71.8583 390.818 74.1923C388.637 76.5262 385.491 77.6932 381.381 77.6932ZM381.381 70.0825C384.273 70.0825 385.719 68.332 385.719 64.8311V35.7581C385.719 32.2572 384.273 30.5067 381.381 30.5067C378.489 30.5067 377.043 32.2572 377.043 35.7581V64.8311C377.043 68.332 378.489 70.0825 381.381 70.0825Z" fill="#6EA1F3"/>
        <path d="M405.841 23.6571H417.79L423.118 61.7868H423.27L428.597 23.6571H440.546V76.9321H432.631V36.5953H432.479L426.39 76.9321H419.388L413.3 36.5953H413.147V76.9321H405.841V23.6571Z" fill="#6EA1F3"/>
        <path d="M452.882 23.6571H475.714V31.2678H461.254V45.3476H472.746V52.9584H461.254V69.3214H475.714V76.9321H452.882V23.6571Z" fill="#6EA1F3"/>
        <defs>
        <linearGradient id="paint0_linear_1214_298" x1="349.833" y1="133.111" x2="349.833" y2="448.993" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="0.4125" stop-color="#FCFDFF"/>
        <stop offset="0.6906" stop-color="#F3F9FF"/>
        <stop offset="0.9285" stop-color="#E4F0FE"/>
        <stop offset="1" stop-color="#DEEDFE"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1214_298" x1="87.4169" y1="356.889" x2="121.307" y2="455.861" gradientUnits="userSpaceOnUse">
        <stop stop-color="#40C9E2"/>
        <stop offset="1" stop-color="#5586FC"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1214_298" x1="612.512" y1="356.889" x2="578.621" y2="455.861" gradientUnits="userSpaceOnUse">
        <stop stop-color="#40C9E2"/>
        <stop offset="1" stop-color="#5586FC"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1214_298" x1="458.796" y1="242.369" x2="452.692" y2="234.812" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FBC8C7"/>
        <stop offset="0.9947" stop-color="#FB929C"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1214_298" x1="365.123" y1="387.812" x2="362.765" y2="357.558" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBF7FF"/>
        <stop offset="0.9786" stop-color="white"/>
        </linearGradient>
        <linearGradient id="paint5_linear_1214_298" x1="462.739" y1="268.718" x2="451.078" y2="431.129" gradientUnits="userSpaceOnUse">
        <stop offset="0.0214" stop-color="white"/>
        <stop offset="1" stop-color="#EBF7FF"/>
        </linearGradient>
        <linearGradient id="paint6_linear_1214_298" x1="212.141" y1="272.726" x2="211.308" y2="417.646" gradientUnits="userSpaceOnUse">
        <stop offset="0.0214" stop-color="white"/>
        <stop offset="1" stop-color="#EBF7FF"/>
        </linearGradient>
        <linearGradient id="paint7_linear_1214_298" x1="263.021" y1="375.222" x2="278.02" y2="375.222" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EBF7FF"/>
        <stop offset="0.9786" stop-color="white"/>
        </linearGradient>
        </defs>
        </svg>
        </div>

        <div className="onboardInfoHolder" style={{paddingLeft: '55px', height: 'calc(100% - 50px)'}}>
           <h1 className='onboardHeading' style={{paddingTop: '45px', height:'11rem'}}>Welcome to PeopleSol</h1>
           <label className='onboardGreeting' style={{paddingTop: '15px', height:'9rem'}}>Dear <label style={{fontWeight:'600'}}> Mr./Mrs. X,</label> <br/> Greetings from PeopleSol</label>
           <label className='onboardText' style={{height:'10rem'}}>An Integrated People Solution to Manage your HR, IT and Office Facilities for your Organizations.</label>
           <div className="onboardStartButton cursor" onClick={() => navigate("/useronboarding/peoplesolfeatures")}>
            <label>No More Wait...Let's Get Started</label>
           </div>
        </div>
    </>
  )
}

export {WelcomeOnboard}
