import React, { useEffect, useState } from "react";
import StatusFeature from "./StatusFeature";
import { useSelector } from "react-redux";
import StatusCircles from "./StatusCircles";

export const StatusUpdate = ({ uploadStory, showMyStory, myStory, otherStory, setActiveStory }) => {
  const userDetails = useSelector((state) => state.chat.userDetails);
  // const [chatList, setChatList] = useState([...otherStory]);

  // useEffect(() => {
  //   setChatList([...otherStory])
  // }, [])
  const [selectedParticipant, setSelectedParticipant] = useState([]);
  const [activeChatIndex, setActiveChatIndex] = useState(0);
  function showStatus(status) {
    if (status === "online") {
      return "greenDot";
    } else if (status === "busy") {
      return "redDot";
    } else if (status === "away") {
      return "yellowDot";
    }
  }
  const [statuses, setStatuses] = useState(otherStory.map(chat => ({ id: chat.id, seen: chat.seen })));

  const handleStatusClick = (statusId) => {
    const updatedStatuses = statuses.map(status =>
      status.id === statusId ? { ...status, seen: true } : status
    );
    setStatuses(updatedStatuses);
  };
  return (
    <>

      <div>
        <p style={{ fontWeight: "500", fontSize: "14px" }} className="spacePad">
          Status
        </p>
        <div className="newChatContainer">
          <div onClick={() => { }} className="newChatListContentContainer">
            <div className="newChatActiveImage" onClick={(e) => {
              if (myStory.length) {
                showMyStory();
                setActiveStory({ type: "self", stories: myStory })
              }
            }}>
              {myStory.length ? <div style={{ position: "absolute", zIndex: 1 }}>
                <StatusCircles numOfFill={myStory.length} numOfSlice={myStory.length} />
              </div> : null}

              {userDetails.image && userDetails.image != "" ? <img src={userDetails.image} alt="Profile" />
                :
                <div style={{ backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 300, display: "flex", justifyContent: "center", alignItems: 'center', zIndex: 5 }}>
                  <div style={{ color: "#13A9C7", fontSize: 14 }}>
                    {userDetails.name
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.map((item) => {
                        return item?.charAt(0)?.toUpperCase();
                      })
                      .join("")
                    }
                  </div>
                </div>
              }

              <div style={{ zIndex: 10 }} onClick={(e) => {
                uploadStory()
                e.stopPropagation()
              }} className={"addIm"}>
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.89634" cy="9.5582" r="8.61582" fill="#1296B0" />
                  <path
                    d="M13.009 9.49642L8.70175 9.49642M8.70175 9.49642L4.39453 9.49642M8.70175 9.49642L8.70175 5.18848M8.70175 9.49642L8.70175 13.8043"
                    stroke="white"
                    stroke-width="1.17488"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="newChatPostMainTextContainer">
              <label className="chatListName">{"My Status"}</label>
              <label className="chatListRole" style={{ color: '#A1A1A1', fontSize: '10px', fontWeight: 400 }}>{"Tap to add story"}</label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p
          className="spacePad"
          style={{ color: "#555555", fontWeight: "500", fontSize: "12px" }}
        >
          Recent Stories
        </p>
        <div className="newChatContainer">
          {otherStory.map((chat, index) => (
            <div onClick={() => { showMyStory(); setActiveStory({ type: "other", ...chat }) }} key={chat.id} className="newChatListContentContainer">
              <div style={{ position: "absolute", zIndex: 1, marginLeft: 12 }}>
                <StatusCircles numOfFill={chat.stories.filter((item) => item.storyStatus == 1).length} numOfSlice={chat.stories.length} />
              </div>
              <div className="status-wrapper">
                {chat.image && chat.image != "" ? <div className={`newChatActiveImage status-item ${statuses[index]?.seen ? 'seen' : 'unseen'}`} >
                  <img src={chat.image} alt="Profile" />
                </div> : <div className={`newChatActiveImage status-item ${statuses[index]?.seen ? 'seen' : 'unseen'}`} style={{ backgroundColor: "#E9F3F4", display: "flex", justifyContent: "center", alignItems: 'center', borderStyle: "solid", borderWidth: myStory.length > 0 ? 2 : 0, borderColor: "#1296B0" }}>
                  <div style={{ color: "#13A9C7", fontSize: 14 }}>
                    {chat.name
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.map((item) => {
                        return item?.charAt(0)?.toUpperCase();
                      })
                      .join("")
                    }
                  </div>
                </div>}
              </div>
              <div className="newChatPostMainTextContainer" style={{ position: 'relative', gap: '2px' }}>
                <label className="chatListName">{chat.name}</label>
                <label className="chatListRole" style={{ fontWeight: 400, color: 'black', fontSize: '11px' }}>{chat.designation}</label>
              </div>
              {/* <label className="statusTime">{chat?.LastMsgTime}</label> */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
