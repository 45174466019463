import React from "react";
import { CoursesTab } from "./CoursesTab";
import { useEffect } from "react";

const Courses = () => {
  useEffect(() => {
    document.title = `PeopleSol - Courses`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <CoursesTab title="Learning Chapters" />
    </div>
  );
};

export { Courses };
