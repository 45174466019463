import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangeTypeForm } from "./ChangeTypeForm";
import { getRequest, postRequest } from "../../../Services/axios";
import { showToast, isLoading } from "../../../Features";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { changeSchema } from "./Schema";

const initialvalue = {
  name: "",
  employeeIDs: "",
  serviceCategoryIDs: "",
};

const CreateChangeTypes = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Change Type`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const { state } = useLocation();

  const data = state?.id;
  const [detailsform, setDetailsform] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`CRImplementationTeams/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const postFormData = async (values) => {
    try {
      const result = await postRequest("CRImplementationTeams/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
    } finally {
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("CRImplementationTeams/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      returnPage();
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const onSubmit = async (values) => {
    const edit = {
      ...values,
      id: detailsform?.id,
    };

    const create = {
      ...values,
    };

    data ? Edit(edit) : postFormData(create);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      ButtonLabel={data ? "Update Change Types" : "Create Change Types"}
      FormHeading={data ? "Edit Change Types" : "Create Change Types"}
      validationSchema={changeSchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.name,
              employeeIDs:detailsform?.employeeIDs,
              serviceCategoryIDs:detailsform?.serviceCategoryIDs,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<ChangeTypeForm data={detailsform} />}
    />
  );
};

export { CreateChangeTypes };
