import React from "react";
import { communityProfileImages } from "../../../../../../../Utilities/profileAndUploadImages";
import { getUserInitials } from "../../../../../../../Utilities/socialAllFunctions";
import SocialCommunityGroupIcon from "../../../../../../../Assets/SocialCommunityGroupIcon.svg";
import { formatTimeFromDate } from "../../SocialFunctions";
import { useSelector } from "react-redux";
import ViewReportContainer from "./ViewReportContainer";

function SurveyAndPollCommunityHeader({ hData, type }) {
  const { LoggedInUser } = useSelector((state) => state.auth);
  return (
    <div className="surveyAndPollCommunityHeaderContainer">
      <div className="surveyAndPollCommunityHeaderWrapper">
        <div className="surveyAndPollCommunityHeaderLeftContainer">
          <div className="surveyAndPollCommunityHeaderImgContainer">
            <div
              className={`${
                hData?.community?.communityImage
                  ? "socialProfilePicContainer"
                  : "surveyCustomProfilePic"
              }`}
            >
              {!hData?.community?.communityImage ? (
                getUserInitials(hData?.community?.communityName)
              ) : (
                <img
                  className="surveyProfilePic"
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleUserProfileClick(d?.employeeId);
                  }}
                  src={
                    process.env.REACT_APP_.BASE_URL +
                    communityProfileImages +
                    hData?.community?.communityImage
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = SocialCommunityGroupIcon;
                  }}
                />
              )}
            </div>
          </div>
          <div className="surveyAndPollCommunityHeaderTxtContainer">
            <h6>{hData?.community?.communityName}</h6>
            <p>
              <label>Community</label>
              <span className="surveyPostDot">.</span>
              <span className="surveyCreatedOn">
                {formatTimeFromDate(hData?.createdOn)}
              </span>
            </p>
          </div>
        </div>
        <div className="surveyAndPollCommunityHeaderRightContainer">
          {hData?.employeeId === LoggedInUser?.id && (
            <ViewReportContainer reportData={hData} type={type} />
          )}
        </div>
      </div>
    </div>
  );
}

export default SurveyAndPollCommunityHeader;
