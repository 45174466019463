/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "./CreateDesignation.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateDesignationComp } from "./FormComp/CreateDesignationComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CreateDesignation = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    try {
      const result = await getRequest(`Designation/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);
  useEffect(() => {
    document.title = `PeopleSol - Create Designation`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
       await postRequest("Designation/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("Designation/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const initialvalue = {
    name: data ? detail.name : "",
    bandId: data ? detail.bandId : "",
    position: data ? detail.position : "",
    tat: data ? detail.tat : "",
    r1time: data ? detail.r1time : "",
    r2time: data ? detail.r2time : "",
    r3time: data ? detail.r3time : "",
    e1time: data ? detail.e1time : "",
    e2time: data ? detail.e2time : "",
    e3time: data ? detail.e3time : "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(149,"Designation Name must be less than 150 characters").required("Please Enter Designation "),
    bandId: Yup.string().required("Please Select Band"),
    position: Yup.number().min(1).max(9999999).required("Please Enter Rank"),
    tat: Yup.string().required("Please Enter TAT"),
    r1time: Yup.number()
      .max(999, "Reminder must be less than a 999 Hr ")
      .required("Please Enter Reminder 1"),
    r2time: Yup.number()
      .max(999, "Reminder must be less than a 999 Hr ")
      .required("Please Enter Reminder 2"),
    r3time: Yup.number()
      .max(999, "Reminder must be less than a 999 Hr ")
      .required("Please Enter Reminder 3"),
    e1time: Yup.number()
      .max(999, "Escalation must be less than a 999 Hr ")
      .required("Please Enter Escalation 1"),
    e2time: Yup.number()
      .max(999, "Escalation must be less than a 999 Hr ")
      .required("Please Enter Escalation 2"),
    e3time: Yup.number()
      .max(999, "Escalation must be less than a 100 Hr ")
      .required("Please Enter Escalation 3"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
      position:String(values?.position)
    };

    const create = {
      ...values,
      id: 0,
      bandId: values.bandId,
      position:String(values?.position)
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Designation" : "Create Designation"}
      ButtonLabel={data ? "Update Designation" : "Create Designation"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateDesignationComp data={data} />}
    />
  );
};

export { CreateDesignation };
