import React, { useCallback, useEffect, useState } from 'react'
import "./MediaLinkDocs.css";
import { confirmAlert } from 'react-confirm-alert';
// import { Switch } from '@mui/material';
import { ProfileGroup } from '../MyProfileDetails/ProfileGroup';
import { Popup } from '../../../../Components/FormComponent/PopupComponent/Popup';
import NewGroup from '../NewGroup/NewGroup';
import SelectedNewGroupMembers from '../NewGroup/SelectedNewGroupMembers';
import SaveIcon from "../../../../Assets/SaveTickIcon.svg"
import BraodcastIcon from "../../../../Assets/Broadcast.svg"
import Delete from "../../../../Assets/DeleteIcon.svg"
import { Loader } from '../../../../Components/FormComponent/Loader';
import AddToFavourite from "../../../../Assets/AddToFavourite.svg"
import RemoveFromFavourite from "../../../../Assets/RemoveFromFavourite.svg"
import DoubleTick from "../../../../Assets/DoubleTick.svg";
import SingleTick from "../../../../Assets/SingleTick.svg";
import DoubleTickGrey from "../../../../Assets/DoubleTickGrey.svg";
import { useSelector } from 'react-redux';

import pdfDocIcon from "../../../../Assets/pdfDocIcon.svg";
import pdfDocIconRecieve from "../../../../Assets/pdfDocIconRecieve.svg";
import MediaIcon from "../../../../Assets/MediaIcon.svg";
import DocIcon from "../../../../Assets/DocIcon.svg";
import LinkIcon from "../../../../Assets/LinkIcon.svg";
import MediaIconWhite from "../../../../Assets/MediaIconWhite.svg";
import DocIconWhite from "../../../../Assets/DocIconWhite.svg";
import LinkIconWhite from "../../../../Assets/LinkIconWhite.svg";


function MediaLinkDocs({ setMessageInfo, newSocket, selectedMessage, activeChat }) {


    const [currTab, setCurrTab] = useState("media");
    const userDetails = useSelector(state => state.chat.userDetails);
    const [mediaData, setMediaData] = useState({});
    const [linkData, setLinkData] = useState({});
    const [docData, setDocData] = useState({});
    const [mediaArr, setMediaLabel] = useState([]);
    const [linkArr, setLinkLabel] = useState([]);
    const [docArr, setDocLabel] = useState([]);

    useEffect(() => {
        document.title = `PeopleSol - Media Info`;
        return () => {
            document.title = 'PeopleSol';
        };
    })

    const getDateLabel = (dateString) => {
        const imageDate = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const weekAgo = new Date(today);
        weekAgo.setDate(today.getDate() - 7);

        if (imageDate.toDateString() === today.toDateString()) {
            return "Today";
        } else if (imageDate.toDateString() === yesterday.toDateString()) {
            return "Yesterday";
        } else if (imageDate > weekAgo && imageDate < today) {
            return "This Week";
        } else if (
            imageDate.getMonth() === today.getMonth() &&
            imageDate.getFullYear() === today.getFullYear()
        ) {
            return "This Month";
        } else if (imageDate.getFullYear() === today.getFullYear()) {
            return imageDate.toLocaleString("default", { month: "long" });
        } else {
            return imageDate.getFullYear();
        }
    };
    useEffect(() => {
        newSocket.emit("getMedias", ({ "conversationId": activeChat._id }), (res) => {
            if (res.status && res?.data?.length) {
                console.log("------------------>", res.data)
                let data1 = {}, data2 = {}, data3 = {};
                let mLabel = [], dLabel = [], lLabel = [];
                for (let i = res.data[0]?.medias?.length - 1; i >= 0; i--) {
                    if (data1[getDateLabel(res.data[0].medias[i].createdOn)])
                        data1[getDateLabel(res.data[0].medias[i].createdOn)].push(res.data[0].medias[i])
                    else {
                        data1[getDateLabel(res.data[0].medias[i].createdOn)] = [res.data[0].medias[i]];
                        mLabel.push(getDateLabel(res.data[0].medias[i].createdOn))
                    }
                }
                setMediaData({ ...data1 })
                for (let i = res.data[0]?.links?.length - 1; i >= 0; i--) {

                    if (data2[getDateLabel(res.data[0].links[i].createdOn)])
                        data2[getDateLabel(res.data[0].links[i].createdOn)].push(res.data[0].links[i])
                    else {
                        data2[getDateLabel(res.data[0].links[i].createdOn)] = [res.data[0].links[i]];
                        lLabel.push(getDateLabel(res.data[0].links[i].createdOn));
                    }
                }

                setLinkData({ ...data2 })
                for (let i = res.data[0]?.docs?.length - 1; i >= 0; i--) {
                    if (data3[getDateLabel(res.data[0].docs[i].createdOn)])
                        data3[getDateLabel(res.data[0].docs[i].createdOn)].push(res.data[0].docs[i])
                    else {
                        data3[getDateLabel(res.data[0].docs[i].createdOn)] = [res.data[0].docs[i]];
                        dLabel.push(getDateLabel(res.data[0].docs[i].createdOn))
                    }
                }
                setMediaLabel(mLabel);
                setDocLabel(dLabel);
                setLinkLabel(lLabel);
                setDocData({ ...data3 })
            }
        })
    }, [activeChat])


    const getDate = (date) => {
        if (!date) return "";
        let a = JSON.stringify(new Date().toLocaleDateString());
        let b = JSON.stringify(new Date(date).toLocaleDateString());
        if (a == b) return "Today";
        if (new Date().getMonth() == new Date(date).getMonth() && new Date().getFullYear() === new Date(date).getFullYear()) {
            if ((new Date().valueOf() - new Date(date).valueOf()) < 1000 * 60 * 60 * 24) return "Yesterday";
            else return new Date(date).toLocaleDateString();
        } else {
            return new Date(date).toLocaleDateString();
        }
    }
    const getTime = (date) => {
        if (!date) return "";
        let a = new Date(date).toTimeString()
        return a.substring(0, 5);
    }


    const Tabs = () => {
        return (
            <div style={{ borderStyle: "solid", borderWidth: 1, borderColor: "rgba(216, 240, 242, 1)", borderRadius: 4, padding: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", margin: 20 }}>
                <div onClick={() => setCurrTab("media")} style={{ width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", color: currTab === "media" ? "white" : "#555555", backgroundColor: currTab === "media" ? "rgba(18, 150, 176, 1)" : "white", padding: 10, borderRadius: 4 }}>
                    <img src={currTab !== "media" ? MediaIcon : MediaIconWhite} />
                    <div style={{ marginLeft: 10 }}>{"Media"}</div>
                </div>
                <div onClick={() => setCurrTab("link")} style={{ width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", color: currTab === "link" ? "white" : "#555555", backgroundColor: currTab === "link" ? "rgba(18, 150, 176, 1)" : "white", padding: 10, borderRadius: 4 }}>
                    <img src={currTab !== "link" ? LinkIcon : LinkIconWhite} />
                    <div style={{ marginLeft: 10 }}>{"Links"}</div>
                </div>
                <div onClick={() => setCurrTab("doc")} style={{ width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", color: currTab === "doc" ? "white" : "#555555", backgroundColor: currTab === "doc" ? "rgba(18, 150, 176, 1)" : "white", padding: 10, borderRadius: 4 }}>
                    <img src={currTab !== "doc" ? DocIcon : DocIconWhite} />
                    <div style={{ marginLeft: 10 }}>{"Docs"}</div>
                </div>
            </div>
        )
    }

    const openFile = (url) => {
        // Create an anchor element dynamically
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';  // Opens in new tab if needed
        anchor.download = url.split('/').pop(); // Extracts the file name from URL

        // Append anchor to body and trigger click to start download
        document.body.appendChild(anchor);
        anchor.click();

        // Remove the anchor after triggering download
        document.body.removeChild(anchor);
    };

    const LinkTab = () => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return (
            <div style={{ width: "100%", padding: 15, height: "75%", overflow: "auto" }}>
                {linkArr.map((val, index) =>
                (
                    <div style={{ paddingBottom: 10, borderWidth: 0, borderBottomWidth: 1, borderColor: "rgba(230, 230, 230, 1)", borderStyle: "solid", marginBottom: 20 }} key={index}>
                        <div style={{ marginBottom: 15 }}>{val}</div>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            {linkData[val].map(data => (

                                <div style={{ margin: 5, backgroundColor: "rgba(18, 150, 176, 1)", padding: 10, width: "100%", color: "white", borderRadius: "6px 0px 6px 6px" }}>
                                    {data.caption.split(urlRegex).map((part, index) => {
                                        if (urlRegex.test(part)) return (
                                            <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#F7941D" }}>
                                                {part}
                                            </a>
                                        )
                                        else return <span>{part}</span>
                                    })}
                                </div>
                            ))}

                        </div>
                    </div>
                ))}
            </div>
        )

    }
    const DocTab = () => {
        return (
            <div style={{ width: "100%", padding: 15, height: "75%", overflow: "auto" }}>
                {docArr.map((val, index) =>
                (
                    <div style={{ paddingBottom: 10, borderWidth: 0, borderBottomWidth: 1, borderColor: "rgba(230, 230, 230, 1)", borderStyle: "solid", marginBottom: 20 }} key={index}>
                        <div style={{ marginBottom: 15 }}>{val}</div>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            {docData[val].map(data => (
                                <div onClick={(e) => { e.stopPropagation(); openFile(data.content[0].url) }} style={{ margin: 5, backgroundColor: "rgba(18, 150, 176, 1)", display: "flex", flexDirection: "row", padding: 10, alignItems: "center", width: "100%", color: "white", borderRadius: "6px 0px 6px 6px" }}>
                                    <img style={{ marginRight: 10, height: 30, width: 30 }} src={pdfDocIcon} />
                                    <div>
                                        <div>{data.content[0].url.split("/").pop()}</div>
                                        {data.content[0].url?.slice(-3) === "jpg" ||
                                            data.content[0].url?.slice(-3) === "png" ||
                                            data.content[0].url?.slice(-3) === "svg" ? (
                                            <div>{data.content[0].url?.slice(-3)}</div>
                                        ) : data.content[0].url?.slice(-4) === "jpeg" ||
                                            data.content[0].url?.slice(-4) === "xlsx" ||
                                            data.content[0].url?.slice(-4) === "docx" ? (
                                            <div>{data.content[0].url?.slice(-4)?.toUpperCase()}</div>
                                        ) : (
                                            <div> File</div>
                                        )}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                ))}
            </div>
        )
    }
    const MediaTab = () => {
        return (
            <div style={{ width: "100%", padding: 15, height: "75%", overflow: "auto" }}>
                {mediaArr.map((val, index) =>
                (
                    <div style={{ paddingBottom: 10, borderWidth: 0, borderBottomWidth: 1, borderColor: "rgba(230, 230, 230, 1)", borderStyle: "solid", marginBottom: 20 }} key={index}>

                        <div style={{ marginBottom: 15 }}>{val}</div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%" }}>
                            {mediaData[val]?.map(data => (
                                <div onClick={(e) => { e.stopPropagation(); openFile(data.content[0].url) }} style={{ margin: 5, height: 90, width: "30%", backgroundColor: "rgba(216, 240, 242, 1)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {data.type === 3 ?
                                        <div style={{ width: "100%", height: 90, overflow: "hidden" }}>
                                            <img style={{ width: "100%", height: 90 }} src={data.content[0].url} />
                                        </div>
                                        : null
                                    }
                                    {data.type === 2 ?
                                        <video
                                            src={data.content[0].url}
                                            // controls
                                            width="100%"
                                        />
                                        : null
                                    }
                                </div>
                            ))}

                        </div>
                    </div>
                ))}
            </div>
        )
    }
    return (
        <>
            <div className="MessageInfoMainContainer">
                <div className="MessageInfoHeaderContainer">
                    <div className="MessageInfoHeaderWrapper">
                        <span>{"Media, Link & Docs"}</span>
                        <svg onClick={() => {
                            setMessageInfo(false);
                        }}
                            xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 0.707104 -0.70711 -0.707104 20.4531 6.48962)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
                            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 22.0259 20.6318)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>

                <Tabs />
                {currTab === "media" ? <MediaTab /> : null}
                {currTab === "doc" ? <DocTab /> : null}
                {currTab === "link" ? <LinkTab /> : null}
            </div>
        </>

    );
}

export default MediaLinkDocs;