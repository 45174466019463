import React from 'react';
import { ExceptionIt } from './ExceptionComponent/ExceptionIt';
import { useEffect } from 'react';

const ExceptionTab = () => {
    useEffect(() => {
        document.title = `PeopleSol - Exception`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
                    <ExceptionIt title="Exception"/>

        </div>
    )
}

export { ExceptionTab }