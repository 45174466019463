/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { useDeferredValue } from "react";
import { format } from "date-fns";
import { Loader } from "../../../../Components/FormComponent/Loader";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
const CheckSalary = () => {
  const groupByType = (salaryComponents) =>
    salaryComponents?.reduce((acc, component) => {
      if (!acc[component.component]) {
        acc[component.component] = [];
      }
      acc[component.component].push(component);
      return acc;
    }, {});

  // Function to process each employee's data
  const processEmployeeData = (employeeData) => {
    const earning = [];
    const deduction = [];
    const reimbursement = [];

    const groupedComponents = groupByType(employeeData?.item?.component);

    // Separate components into respective arrays
    for (const [component, components] of Object.entries(groupedComponents)) {
      if (component.includes("Earnings")) {
        earning.push(...components);
      } else if (component.includes("Deductions")) {
        deduction.push(...components);
      } else if (component.includes("Reimbursements")) {
        reimbursement.push(...components);
      }
    }

    // Create a new object with grouped data
    const groupedData = {
      employee: employeeData?.item?.employee,
      earning,
      deduction,
      reimbursement,
      totalNetPayable: employeeData?.item?.totalNetPayable,
      totalEarning: employeeData?.item?.totalEarning,
      totalDeduction: employeeData?.item?.totalDeduction,
      totalReimbursement: employeeData?.item?.totalReimbursement,
    };

    return groupedData;
  };

  const [allHeadings, setAllHeading] = useState([])
  const generateTableHeadings = () => {
    const headings = [];
    allHeadings.forEach((type) => {
      headings.push(<th key={type}>{type}</th>);
    });

    return headings;
  };


  useEffect(() => {
    document.title = `PeopleSol - Check Salary `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [groupedEmployeeData, setGroupedEmployeeData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const editId = state?.id;
  const month = state?.month;
  const returnPage = () => {
    navigate(-1);
  };
  useEffect(() => {
    document.title = `PeopleSol - Check salary`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      isExport: false,
    },
  });
  let maxEarningsLength = 0;
  let maxDeductionLength = 0;
  let maxReimbursmentLength = 0;
  let maxEarningsEmployee = null;
  let maxDeductionEmployee = null;
  let maxReimbursmentEmployee = null;

  groupedEmployeeData.forEach((employee) => {
    const earningsLength = employee.earning.length;
    if (earningsLength > maxEarningsLength) {
      maxEarningsLength = earningsLength;
      maxEarningsEmployee = employee;
    }
  });
  groupedEmployeeData.forEach((employee) => {
    const DeductionLength = employee.deduction.length;
    if (DeductionLength > maxDeductionLength) {
      maxDeductionLength = DeductionLength;
      maxDeductionEmployee = employee;
    }
  });
  groupedEmployeeData.forEach((employee) => {

    const ReimbursmentLength = employee.reimbursement.length;
    if (ReimbursmentLength > maxReimbursmentLength) {
      maxReimbursmentLength = ReimbursmentLength;
      maxReimbursmentEmployee = employee;
    }
  });
  console.log('groupedEmployeeData', groupedEmployeeData)
  let maxIndex = -1;
  let maxLength = -1;

  for (let i = 0; i < groupedEmployeeData.length; i++) {
    const item = groupedEmployeeData[i];
    const totalLength = item.earning.length + item.deduction.length + item.reimbursement.length;
    if (totalLength > maxLength) {
      maxIndex = i;
      maxLength = totalLength;
    }
  }
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const applyFilters = () => {
    setReqData({
      ...reqData,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${createdOnEndDate
          ? format(createdOnEndDate, "dd/MM/yyyy")
          : format(createdOnStartDate, "dd/MM/yyyy")
        }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${lastUpdatedOnEndDate
          ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
          : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
        }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      noticePeriod: null,
      createdOn: null,
      lastUpdatedOn: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest(
        `PreparePayroll/CheckSalaryList?Month=${month}`,
        downloadReqData
      );
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", "Attendance List");
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (editId) {
      if (location.pathname.toLowerCase().includes("checksalary"))
        getListData();
    }
  }, [editId, location.pathname, reqData, month]);
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // const updateAttendance = await getRequest(
      //   `PreparePayroll/checkSalary/${month}/${editId}`
      // );
      // if (updateAttendance) {
      const result = await postRequest(
        `PreparePayroll/checkSalaryList?Month=${month}&PayAreaId=${editId}`,
        reqData
      );
      if (result) {
        console.log("--------- actuall data ->", result.data.data)
        let employeeData = result?.data?.data?.map(processEmployeeData);
        //console.log("tempGrouping", tempGrouping);
        setGroupedEmployeeData(result?.data?.data?.map(processEmployeeData));

        let allComponents = [];
        // Collect all types of components

        for (let i = 0; i < employeeData.length; i++) {
          allComponents = [...allComponents,
          ...employeeData[i].earning.map((item) => item.type),
          ...employeeData[i].deduction.map((item) => item.type),
          ...employeeData[i].reimbursement.map((item) => item.type),
          ]
        }

        // Remove duplicates
        const uniqueComponents = Array.from(new Set(allComponents));
        setAllHeading(uniqueComponents);
        setCount(result.data.summary.allCount);
        calculateTotalPages(result.data.summary.allCount);
      }
      // }
    } catch (error) {
      //console.log("error", error);
      //dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      setGroupedEmployeeData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  return (
    <div className="moduleMainContainer">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${filterSearchToogle
            ? "filterSearchContainerActive filterSearchContainer"
            : "filterSearchContainer"
            }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              title="Close"
              onClick={() => setFilterSearchToogle((prev) => !prev)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderContainer" style={{ padding: "0 12px" }}>
        <IconButton onClick={returnPage}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.368615 3.35738C0.173352 3.55265 0.173352 3.86923 0.368615 4.06449L3.5506 7.24647C3.74586 7.44173 4.06244 7.44173 4.2577 7.24647C4.45296 7.05121 4.45296 6.73463 4.2577 6.53936L1.42927 3.71094L4.2577 0.88251C4.45296 0.687248 4.45296 0.370666 4.2577 0.175404C4.06244 -0.0198586 3.74586 -0.0198586 3.5506 0.175404L0.368615 3.35738ZM18.749 4.21094C19.0252 4.21094 19.249 3.98708 19.249 3.71094C19.249 3.4348 19.0252 3.21094 18.749 3.21094V4.21094ZM0.722168 4.21094H18.749V3.21094H0.722168V4.21094Z"
              fill="#1296B0"
            />
          </svg>
        </IconButton>
        <h6>Salaries, {month}</h6>
        <div className="search-box" title="Search">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn" title="Search">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        {/* <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                stroke="#1296B0"
                strokeWidth="1.5"
              />
            </svg>
          </IconButton>
        </Tooltip> */}
        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <table className="attendance-table">
            <colgroup>
              <col style={{ width: "35px", minWidth: "35px" }} />
              <col style={{ width: "135px", minWidth: "135px" }} />
              <col style={{ width: "130px", minWidth: "130px" }} />
              <col style={{ width: "130px", minWidth: "130px" }} />
              <col style={{ width: "130px", minWidth: "130px" }} />
            </colgroup>
            <thead>
              <tr style={{ position: "sticky", top: "6px", zIndex: 11 }}>
                <th
                  className="primaryBackground"
                  style={{ left: 0, zIndex: 1, position: "sticky" }}
                >
                  Sl. No.
                </th>
                <th
                  className="primaryBackground"
                  style={{
                    textAlign: "start",
                    position: "sticky",
                    left: "35px",
                    zIndex: 1,
                  }}
                >
                  Employee
                </th>
                <th
                  className="primaryBackground primaryHead"
                  style={{
                    left: "170px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  Total Net Payable
                </th>
                <th
                  className="primaryBackground earningHead"
                  style={{
                    left: "300px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  Total Earning
                </th>
                <th
                  className="primaryBackground deductionHead"
                  style={{
                    left: "430px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  Total Deduction
                </th>
                <th
                  className="primaryBackground reimbursementHead"
                  style={{
                    left: "560px",
                    width: "100%",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  Total Reimbursement
                </th>
                {groupedEmployeeData.length > 0 && generateTableHeadings()}
              </tr>
            </thead>
            <tbody>
              {groupedEmployeeData &&
                groupedEmployeeData?.map((employee, index) => (
                  <tr key={employee.payrollEmployeeId}>
                    <td style={{ left: 0, zIndex: 1, position: "sticky" }}>
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        position: "sticky",
                        left: "35px",
                        zIndex: 1,
                      }}
                    >
                      {employee.employee}
                    </td>
                    <td
                      style={{
                        left: "170px",
                        zIndex: 1,
                        position: "sticky",
                      }}
                    >
                      <div className="dayCell primaryDayCellFixed">
                        {employee.totalNetPayable}
                      </div>
                    </td>
                    <td
                      style={{
                        left: "300px",
                        zIndex: 1,
                        position: "sticky",
                      }}
                    >
                      <div className="dayCell earningCellFixed">
                        {employee.totalEarning}
                      </div>
                    </td>
                    <td
                      style={{
                        left: "430px",
                        zIndex: 1,
                        position: "sticky",
                      }}
                    >
                      <div className="dayCell deductionCellFixed">
                        {employee.totalDeduction}
                      </div>
                    </td>
                    <td
                      style={{
                        left: "560px",
                        zIndex: 1,
                        position: "sticky",
                      }}
                    >
                      <div className="dayCell reimbursementCellFixed">
                        {employee.totalReimbursement}
                      </div>
                    </td>


                    {allHeadings.map((heading) => {
                      let earn = employee.earning.filter(item => item.type === heading);
                      let ded = employee.deduction.filter(item => item.type === heading)
                      let reim = employee.reimbursement.filter(item => item.type === heading)
                      if (earn.length) return (<td>{earn[0].amount}</td>)
                      else if (ded.length) return (<td>{ded[0].amount}</td>)
                      else if (reim.length) return (<td>{reim[0].amount}</td>)
                      else return (<td>{"0"}</td>)
                    })}
                    {/* {generateTableHeadings(employee).map((heading, index) => (
                      <td key={index}>
                        {employee.earning
                          .concat(employee.deduction)
                          .concat(employee.reimbursement)
                          .find((item) => item.type === heading.key)?.amount}
                      </td>
                    ))} */}



                  </tr>
                ))}
            </tbody>
          </table>
          <div className="horizontalLine"></div>
          <div className="tableFooterContainer">
            <label>Total Record Count: </label>
            <span>{count}</span>
            <SelectCustom
              searchable={false}
              options={noOfRecords}
              dropdownPosition="top"
              values={[recordsSelected]}
              onChange={(e) => paginationChanged(e)}
            />
            <label>Records Per Page</label>
            {currentPage > 1 ? (
              <IconButton
                onClick={() => pageChangedPrev()}
                color="primary"
                className="pageChangerIcon pageChangerIconActive"
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton disabled color="primary" className="pageChangerIcon">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
            {currentPage} - {totalPages}
            {totalPages > currentPage ? (
              <IconButton
                onClick={() => pageChangedNext()}
                color="primary"
                className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton
                disabled
                color="primary"
                className={`pageChangerIcon pageChangerIconNext`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CheckSalary;
