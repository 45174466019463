/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import "./Tasks.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import { DatePickerCustom } from "../../../../Components/FormComponent/DatePickerCustom";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import DropDownUpWhiteIcon from "../../../../Assets/DropDownUpWhiteIcon.svg";
import * as Yup from "yup";
import MoreActivityContainer from "../../../../Components/Table/MoreActivityContainer";
import { AllTasksComp } from "./TasksComp/AllTasksComp";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import SelectCustomEmployeeCreate from "../../../DropdownComponents/SelectCustomEmployeeCreate";

const Tasks = (props) => {
  const dispatch = useDispatch();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const option = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];
  const [selectedTaskOption, setSelectedTaskOption] = useState(
    localStorage.getItem("taskList") ?? "Today's Task"
  );
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [dateSelect, setDateSelect] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [data, setData] = useState([]);

  const [selectedProjectList, setSelectedProjectList] = useState([]);
  const [selectedPriorityList, setSelectedPriorityList] = useState([]);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [headingActive, setHeadingActive] = useState("Today's Task");
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const statusOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  const taskOption = [
    { label: "Today's Task", value: "Today's Task", id: 1 },
    { label: "My Task", value: "My Task", id: 2 },
    LoggedInUser.isReportingManager
      ? { label: "Team Task", value: "Team Task", id: 5 }
      : null, // Conditionally add "Team Task"
    LoggedInUser?.isHRHead ||
    LoggedInUser?.isHRDirector ||
    LoggedInUser?.teamnames?.includes("HR Team")
      ? { label: "All Task", value: "All Task", id: 3 }
      : null, // Conditionally add "All Task"
    { label: "Pending Confirmation", value: "Pending Confirmation", id: 4 },
  ].filter((option) => option !== null); // Remove null values
  // console.log("tbody", tbodyData);
  const [isActive, setIsActive] = useState(false);
  const updateFavourite = (id, defaultList) => {
    localStorage.setItem(defaultList, id);
  };
  const navigate = useNavigate();
  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      // console.log("head",heading)
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting:
            heading === " Deadline"
              ? "EndsOn"
              : heading === " Project"
              ? "ProjectName"
              : heading === "CompletedOn"
              ? "Date"
              : heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Task`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const taskoptions = async (val) => {
    localStorage.setItem("taskList", val);
    setSelectedTaskOption(val);
    setSelectedRows([]);
    setRecordsSelected(noOfRecords[0]);
    setSearchParam(null);
    setSortParam("Id");
    setSortType("descending");
    if (val === "Today's Task")
      setReqData({
        lastUpdatedOn: null,
        StartsOnDate: new Date()
          ? `${format(new Date(), "dd/MM/yyyy")} ${
              new Date()
                ? format(new Date(), "dd/MM/yyyy")
                : format(new Date(), "dd/MM/yyyy")
            }`
          : null,
        status:
          taskStatus === 2
            ? "In Progress"
            : taskStatus === 6
            ? "Yet to Start"
            : taskStatus === 3
            ? "Hold"
            : taskStatus === 4
            ? "Completed"
            : taskStatus === 5
            ? "Cancelled"
            : "All",
        dataTableRequest: {
          iDisplayLength: noOfRecords[0].value,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
    else if (val === "My Task")
      setReqData({
        lastUpdatedOn: null,
        StartsOnDate: null,
        status:
          taskStatus === 2
            ? "In Progress"
            : taskStatus === 6
            ? "Yet to Start"
            : taskStatus === 3
            ? "Hold"
            : taskStatus === 4
            ? "Completed"
            : taskStatus === 5
            ? "Cancelled"
            : "All",
        dataTableRequest: {
          iDisplayLength: noOfRecords[0].value,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
    else {
      setReqData({
        lastUpdatedOn: null,
        Employee: null,
        StartsOnDate: null,
        status:
          taskStatus === 2
            ? "In Progress"
            : taskStatus === 6
            ? "Yet to Start"
            : taskStatus === 3
            ? "Hold"
            : taskStatus === 4
            ? "Completed"
            : taskStatus === 5
            ? "Cancelled"
            : "All",
        dataTableRequest: {
          iDisplayLength: noOfRecords[0].value,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
    }

    dropDownClickHandler(val);
  };
  const defaultList = "TaskDL";
  const ActivityList = [
    {
      id: 1,
      actionTitle: "All Tasks",
      badge: "",
      favourite: (localStorage.getItem(defaultList) ?? "1") === "1",
      defaultList: defaultList,
    },
    {
      id: 2,
      actionTitle: "In Progress",
      badge: "",
      favourite: (localStorage.getItem(defaultList) ?? "1") === "2",
      defaultList: defaultList,
    },
    {
      id: 3,
      actionTitle: "Hold",
      badge: "",
      favourite: (localStorage.getItem(defaultList) ?? "1") === "3",
      defaultList: defaultList,
    },
    {
      id: 4,
      actionTitle: "Completed",
      badge: "",
      favourite: (localStorage.getItem(defaultList) ?? "1") === "4",
      defaultList: defaultList,
    },
    {
      id: 5,
      actionTitle: "Cancelled",
      badge: "",
      favourite: (localStorage.getItem(defaultList) ?? "1") === "5",
      defaultList: defaultList,
    },
    {
      id: 6,
      actionTitle: "Yet To Start",
      badge: "",
      favourite: (localStorage.getItem(defaultList) ?? "1") === "6",
      defaultList: defaultList,
    },
  ];
  const [activeList, setActiveList] = useState(
    localStorage.getItem(defaultList) ?? "1"
  );
  const getProject = async () => {
    try {
      const result = await getRequest("Projects/GetProjects");
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setProjectList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const updateState = () => setIsActive((prev) => !prev);
  function handleActiveListChange(newValue) {
    setTaskStatus(newValue);
    setActiveList(newValue.toString());
    setSelectedRows([]);
    setRecordsSelected(noOfRecords[0]);
    setSearchParam(null);
    setSortParam("Id");
    setSortType("descending");
    setReqData({
      createdOn: null,
      status:
        newValue === 2
          ? "In Progress"
          : newValue === 6
          ? "Yet to Start"
          : newValue === 3
          ? "Hold"
          : newValue === 4
          ? "Completed"
          : newValue === 5
          ? "Cancelled"
          : "All",
      StartsOnDate:
        localStorage.getItem("taskList") === "Today's Task"
          ? new Date()
            ? `${format(new Date(), "dd/MM/yyyy")} ${
                new Date()
                  ? format(new Date(), "dd/MM/yyyy")
                  : format(new Date(), "dd/MM/yyyy")
              }`
            : null
          : null,
      lastUpdatedOn: null,
      dataTableRequest: {
        iDisplayLength: noOfRecords[0].value,
        iDisplayStart: 0,
        sSearch: null,
        sorting: "Id",
        sSortDir_0: "descending",
        isExport: false,
      },
    });
  }
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    StartsOnDate:
      localStorage.getItem("taskList") === "Today's Task"
        ? new Date()
          ? `${format(new Date(), "dd/MM/yyyy")} ${
              new Date()
                ? format(new Date(), "dd/MM/yyyy")
                : format(new Date(), "dd/MM/yyyy")
            }`
          : null
        : null,
    status:
      activeList === "2"
        ? "In Progress"
        : activeList === "6"
        ? "Yet to Start"
        : activeList === "3"
        ? "Hold"
        : activeList === "4"
        ? "Completed"
        : activeList === "5"
        ? "Cancelled"
        : "All",
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });

  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const getTasksData = async () => {
    let result;
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (selectedTaskOption === "Today's Task")
        result = await postRequest("Tasks/MyTasksList", reqData);
      else if (selectedTaskOption === "My Task")
        result = await postRequest("Tasks/MyTasksList", reqData);
      else if (selectedTaskOption === "Pending Confirmation")
        result = await postRequest("Tasks/ConfirmationList", reqData);
      else if (selectedTaskOption === "All Task") {
        result = await postRequest("Tasks/AllList", reqData);
      } else if (selectedTaskOption === "Team Task")
        result = await postRequest("Tasks/List", reqData);
      if (result?.data) {
        setTbodyData(JSON.parse(result.data.data));
        setCount(JSON.parse(result.data.count));
        calculateTotalPages(result.data.count);
      }
      // else  setTbodyData(false);

      setSelectedRows([]);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest("Tasks/List", downloadReqData);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    getTasksData();
  }, [location.pathname, reqData]);

  const applyFilters = () => {
    setReqData({
      ...reqData,
      Employee: selectedEmployeeList[0]?.label,
      Project: selectedProjectList[0]?.label,
      Priority: selectedPriorityList[0]?.label,
      StartsOnDate: dateSelect
        ? `${format(dateSelect, "dd/MM/yyyy")} ${
            dateSelect
              ? format(dateSelect, "dd/MM/yyyy")
              : format(dateSelect, "dd/MM/yyyy")
          }`
        : null,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setSelectedProjectList([]);
    setSelectedEmployeeList(null);
    setSelectedPriorityList([]);
    setDateSelect(false);
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      Priority: null,
      StartsOnDate: null,
      Employee: null,
      createdOn: null,
      lastUpdatedOn: null,
      Project: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };

  const dropDownClickHandler = (item) => {
    setDropdownActive((prev) => !prev);
    setHeadingActive(item);
  };

  const childRef = useRef(null);
  function handleActivityClick() {
    childRef.current.updateState();
  }
  function handleActivityClose() {
    childRef.current.closeState();
  }

  return (
    <div className="fullTableContainer" style={props.styles}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              title="Close"
              onClick={() => setFilterSearchToogle((prev) => !prev)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p> Employee </p>
              <SelectCustomEmployeeCreate
                selectedEmployeeList={selectedEmployeeList}
                setSelectedEmployeeList={setSelectedEmployeeList}
                values={selectedEmployeeList}
              />
            </div>
            <div className="filterContainer">
              <p> Priority </p>
              <SelectCustom
                options={option}
                values={selectedPriorityList}
                onChange={(value) => {
                  setSelectedPriorityList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p> Project </p>
              <SelectCustom
                onDropdownOpen={() => {
                  getProject();
                }}
                options={projectList}
                values={selectedProjectList}
                onChange={(value) => {
                  setSelectedProjectList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Starts On Date</p>
              <DatePickerCustom
                selected={dateSelect}
                onChange={(date) => setDateSelect(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div
        className="tableHeaderContainer"
        style={{ marginBottom: "0px", position: "relative" }}
      >
        <button
          onClick={() => {
            navigate("/hrms/projecttask/task/create");
          }}
          className="button primaryButton"
          type="submit"
        >
          + Add Task
        </button>
        <OutsideClickHandler onOutsideClick={() => setDropdownActive(false)}>
          <button
            className=" btnTeamTask"
            onClick={() => setDropdownActive((prev) => !prev)}
          >
            {" "}
            <>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.546631"
                  y="0.550781"
                  width="24"
                  height="24"
                  rx="2"
                  stroke="#1296B0"
                />
                <rect
                  x="5.3396"
                  y="5.5459"
                  width="5.00512"
                  height="5.00512"
                  rx="0.5"
                  stroke="#F7941D"
                />
                <rect
                  x="14.7478"
                  y="5.5459"
                  width="5.00512"
                  height="5.00512"
                  rx="0.5"
                  stroke="#F7941D"
                />
                <rect
                  x="14.7478"
                  y="14.5488"
                  width="5.00512"
                  height="5.00512"
                  rx="0.5"
                  stroke="#F7941D"
                />
                <rect
                  x="5.3396"
                  y="14.5488"
                  width="5.00512"
                  height="5.00512"
                  rx="0.5"
                  stroke="#F7941D"
                />
              </svg>
            </>
            <label style={{ fontSize: "14px" }}>
              {selectedTaskOption || "Select Task"}{" "}
              {/* Display the selected task option value */}
            </label>
            <img
              style={{ paddingLeft: "4px", marginLeft: "5px", width: "18px" }}
              src={DropDownUpWhiteIcon}
              alt={DropDownUpWhiteIcon}
            />
          </button>
          {dropdownActive && (
            <div className="taskDDMainContainer">
              <div
                className={`dis ${dropdownActive ? "visibleClass" : null}`}
                style={{ border: "1px solid #D8F0F2" }}
              >
                {taskOption &&
                  taskOption.map((item) => {
                    return (
                      <div className="taskDDContent" key={item.value}>
                        <label
                          className="cursor"
                          style={{ width: "9.5rem", paddingLeft: "16px" }}
                          onClick={async () => {
                            // Update the selected task option value
                            await taskoptions(item.value);
                            // setTbodyData(false)
                          }}
                        >
                          {item.value}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </OutsideClickHandler>

        <OutsideClickHandler onOutsideClick={handleActivityClose}>
          <div
            className="moreActivityLabelContainer cursor not-selectable "
            style={{ border: "1px solid var(--primary)", height: "35px" }}
          >
            <MoreActivityContainer
              ref={childRef}
              ActivityList={ActivityList}
              onChange={handleActiveListChange}
              activeList={activeList}
            />
            <div
              className="moreActivityLabelHolder"
              onClick={handleActivityClick}
            >
              <label style={{ color: "var(--primary)", fontWeight: 500 }}>
                {
                  ActivityList.find((x) => x.id.toString() === activeList)
                    ?.actionTitle
                }
              </label>
            </div>
            <div
              className="badge"
              style={{ position: "relative", marginLeft: "15px" }}
            >
              {/* <label>{5}</label> */}
            </div>
          </div>
        </OutsideClickHandler>
        <div className="search-box">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      </div>

      <>
        <AllTasksComp
          selectedTaskOption={selectedTaskOption}
          taskList={tbodyData}
          statusOptions={statusOptions}
          getTasksData={getTasksData}
          setReqData={setReqData}
          reqData={reqData}
          sortType={sortType}
          sortParam={sortParam}
          headerAction={sortUpdated}
          loading={loading}
          setLoading={setLoading}
        />
      </>

      <div className="horizontalLine"></div>
      <div className="tableFooterContainer">
        <label>Total Record Count: </label>
        <span>{count}</span>
        <SelectCustom
          searchable={false}
          options={noOfRecords}
          dropdownPosition="top"
          values={[recordsSelected]}
          onChange={(e) => paginationChanged(e)}
        />
        <label>Records Per Page</label>
        {currentPage > 1 ? (
          <IconButton
            onClick={() => pageChangedPrev()}
            color="primary"
            className="pageChangerIcon pageChangerIconActive"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                stroke="#BFBFBF"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        ) : (
          <IconButton disabled color="primary" className="pageChangerIcon">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                stroke="#BFBFBF"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        )}
        {currentPage} - {totalPages}
        {totalPages > currentPage ? (
          <IconButton
            onClick={() => pageChangedNext()}
            color="primary"
            className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                stroke="#BFBFBF"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        ) : (
          <IconButton
            disabled
            color="primary"
            className={`pageChangerIcon pageChangerIconNext`}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                stroke="#BFBFBF"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        )}
      </div>
    </div>
  );
};
export { Tasks };
