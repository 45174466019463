import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
export const ConfirmPopup = ({
  data,
  id,
  type,
  color,
  handleClose,
  loading,
  locationId,
  departmentId,
  mandatory = true,
}) => {
  const [option, setOption] = useState();
  const validationSchema = Yup.object(
    mandatory
      ? {
          RMId: Yup.string().required("Please Select Reporting Manager"),
        }
      : {}
  );

  const onSubmit = async (values) => {
    data(type, id, "Remark", values.RMId);
  };
  const getDropdownData = async (ToDepartmentId, ToLocationId) => {
    try {
      let result;
      if (ToLocationId) {
        result = await getRequest(
          `DeputationRequests/RmDropdown?ToDepartmentId=${ToDepartmentId}&ToLocationId=${ToLocationId}`
        );
      } else {
        result = await getRequest(
          `DeputationRequests/RmDropdown?ToDepartmentId=${ToDepartmentId}`
        );
      }

      let listData = [];
      result.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      setOption(listData);
    } catch (error) {}
  };

  useEffect(() => {
    getDropdownData(departmentId, locationId);
  }, [departmentId, locationId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{ RMId: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <LabelCustom
                labelName={"Select Reporting Manager"}
                mandatory={mandatory ? true : false}
              />
              <SelectForm
                name={"RMId"}
                options={option}
                placeholder={" Select Reporting Manager "}
              />
              <div className="createFootCust">
                <button
                  type="button"
                  className="button secondaryButton"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="button primaryButton"
                  type="submit"
                  style={{ background: color, color: "white" }}
                >
                  {type}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};
