import React, { useEffect, useRef, useState } from "react";
import "./Chat.css";

import { useSelector } from "react-redux";
import { IconButton, Popover } from "@mui/material";
import ContactList from "./Components/ContactList";
import MyProfileDetails from "./Components/MyProfileDetails/MyProfileDetails";
import SendImageChat from "../../Assets/SendImageChat.svg";
import ScheduleMessageChat from "../../Assets/ScheduleMessageChat.svg";
import ChatEmogi from "../../Assets/ChatEmogi.svg";
import BeforeActiveThirdImg from "../../Assets/BeforeActiveThirdImg.svg";
import BeforeActiveSecondImg from "../../Assets/BeforeActiveSecondImg.svg";
import NewChat from "../../Assets/NewChat.svg";
import SearchIcon from "../../Assets/SearchMagnifierIcon.svg";
import SearchMessageIcon from "../../Assets/SearchMessageIcon.svg";

import SearchCloseIcon from "../../Assets/SelectMsgCrossIcon.svg"
import BeforeActiveFirstImg from "../../Assets/BeforeActiveFirstImg.svg";
import ChatSelectedCheck from "../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../Assets/ChatUnselectedCheck.svg";
import DropdownArrowChatMesssage from "../../Assets/DropdownArrowChatMesssage.svg";
import MySettingDetails from "./Components/Settings/MySettingDetails";
import ChatInterfaceIntro from "./Components/ChatInterfaceIntro";
import StarredMessages from "./Components/StarredMessages/StarredMessages";
import BlockLists from "./Components/BlockLists/BlockLists";
import EmpProfiledetails from "./Components/EmpProfileDetails/EmpProfileDetails";
import ChatInterface from "./ChatInterface";
import InputSectionChat from "./InputSectionChat";
import NewGroup from "./Components/NewGroup/NewGroup";
import { Popup } from "../../Components/FormComponent/PopupComponent/Popup";
import ForwardInputChat from "./ForwardInputChat";
import ForwardPopup from "./ForwardPopup";
import GroupInfo from "./Components/GroupInfo/GroupInfo";
import ForwardViewAll from "./ForwardViewAll";
import DocumentPreviewContainer from "./Components/UserAttchments/DocumentPreviewContainer";
import { confirmAlert } from "react-confirm-alert";
import CompanyBroadcast from "./Components/NewBroadCast/CompanyBroadcast";
import { Community } from "./Components/Community/Community";
import TabbedInterface from "./TabbedInterface";
import { StatusUpdate } from "./Components/Status/StatusUpdate";
import MediaViewerChat from "./MediaViewerChat";
import { useDispatch } from "react-redux";
import ChatGoBack from "../../Assets/backArrowYellow.svg";
import AudioCallIcon from "../../Assets/AudioCallIcon.svg";
import VideoCallIcon from "../../Assets/VideoCallIcon.svg";
import BroadCastIcon from "../../Assets/Broadcast.svg"

import {
  addNewActiveMessage,
  setActiveMessageList,
  setContactList,
  setConversationList,
  setUserDetails,
  setUserSettings,
  loadMessagesByConversation, addMessages, searchMessages, addConversation, searchConversationsThunk
} from "../../Features/ChatSlice";

import EmojiPicker from "emoji-picker-react";
import { current } from "@reduxjs/toolkit";
import OutsideClickHandler from "../../Components/OutsideClickHandler/OutsideClickHandler";
///////////////////////////////////

import Archive from "../../Assets/Archive.svg"
import { io } from 'socket.io-client';
import SelectedNewGroupMembers from "./Components/NewGroup/SelectedNewGroupMembers";
import CreateNewGroup from "./Components/NewGroup/CreateNewGroup";
import SelectedNewBrMembers from "./Components/NewBroadCast/SelectedNewBrMembers";
import CreateNewBroadCast from "./Components/NewBroadCast/CreateNewBroadCast";
import NewConversation from "./Components/NewBroadCast/NewConversation";
import AddImage from "../../Assets/AddDocumentIcon.svg"
import NoFavourites from "../../Assets/NoFavourites.svg"
import NoGroups from "../../Assets/NoGroups.svg"
import NoBroadcast from "../../Assets/NoBroadcast.svg"
import NoPin from "../../Assets/NoPin.svg"
import NoRead from "../../Assets/NoRead.svg"
import WelcomeToChat from "../../Assets/WelcomeToChat.svg";
import StartChatIcon from "../../Assets/StartChatBtnIcon.svg";
import NewGroupIcon from "../../Assets/NewGroupBtnIcon.svg";
import NewBroadcastIcon from "../../Assets/NewBroadcastBtnIcon.svg";

import CloseIcon from "../../Assets/CloseIcon.svg"
import { Loader } from "../../Components/FormComponent/Loader";
import { StoryPopUp } from "./Components/Status/StoryPopUp";
import { StoryUploadPreview } from "./Components/Status/StoryUploadPreview";
import MessageInfo from "./Components/MessageInfo/MessageInfo";
import { ScheduleMessage } from "./Components/ScheduleMessage/ScheduleMessage";
import MediaLinkDocs from "./Components/MediaLinkDocs/MediaLinkDocs";
import SearchMessge from "./Components/SearchMessage/SearchMessage";
import GroupInvitePage from "./Components/MyProfileDetails/GroupInvitePage";
import GroupLinkSetting from "./Components/MyProfileDetails/GroupLinkSetting";
import { PopupLogout } from "../../Components/FormComponent/PopupLogout";
import { addPendingMessageToCache, clearAllPendingMessages, getMessagesByConversation, getPendingMessagesFromCache } from "../../db";
import { message } from "antd";
import TestCall from "./TestCall";
import { showToast } from "../../Features";

export const ChatApp = () => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const chatList = useSelector((state) => state.chat.conversationList);
  const contactsList = useSelector((state) => state.chat.contactList);
  const userDetails = useSelector((state) => state.chat.userDetails);
  const userSettings = useSelector((state) => state.chat.userSettings);
  const searchResults = useSelector((state) => state?.chat?.searchResults);
  const conversationSearchResult = useSelector((state) => state?.chat?.conversationSearchResults)
  const sortedMessageList = useSelector(
    (state) => state.chat.activeMessageList
  );
  const [archivedList, setArchived] = useState([]);
  const [blockedChats, setBlockedChats] = useState([]);
  const [showArchivedList, setShowArchived] = useState(false);
  const [activeChat, SetActiveChat] = useState({});
  const [settingsActive, setSettingsActive] = useState(false);
  const [activeHoverFilter, setActiveHoverFilter] = useState("All")
  const [starredMessage, setStarredMessage] = useState(false);
  const [blocklist, setBlocklist] = useState(false);
  const [groupInfo, setGroupInfo] = useState(false);
  const [messageInfo, setMessageInfo] = useState(false);
  const [mediaInfo, setMediaInfo] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [activeTab, setActiveTab] = useState("chat");
  const [showNewConver, setShowNewConversat] = useState(false);
  const [mySelfChat, setMySelfChat] = useState(false);
  const [empProfileDetails, setEmpProfileDetails] = useState(false);
  const [myProfileDetails, setMyProfileDetails] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [showGroupMembers, setShowNewGroupMember] = useState(false);
  const [createNewGroup, setCreateNewGroupMember] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [viewAll, setViewAll] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [companyBroadcast, setCompanyBroadcast] = useState(false);
  const [community, setCommunity] = useState(false);
  const [attachmentSelected, setAttachmentSelected] = useState(false);
  const [mediaArray, setMediaArray] = useState([]);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [mediaCaption, setMediaCaption] = useState("");
  const [forwardContactList, setForwardContactList] = useState([]);
  const [selectedGroupMember, setSelectedGroupMember] = useState([]);
  const [showGlobalSearch, setShowGlobalSearch] = useState(false);
  const [activeFilter, setActiveFilter] = useState("All");
  const [scheduleMessage, setSchedule] = useState(false);
  const [scheduleMessageValue, setScheduleMessageValue] = useState({});
  const [rescheduleMessageValue, setReScheduleMessageValue] = useState(null);
  const [searchMessage, setSearchMessage] = useState(false);
  const [scrollToId, setScrollToId] = useState(null)
  const [showGroupInvite, setShowGroupInvite] = useState(false);
  const [showLinkSetting, setShowLinkSetting] = useState(false);
  const [selectChat, setSelectChat] = useState(false);
  const [selectedChatsArr, setSelectedChatsArr] = useState([]);
  const [isChatOptionOpen, setChatOptionOpen] = useState(false);
  const [showCallScreen, setShowCallScreen] = useState(false);
  const [audioCall, setAudioCall] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const activeChatRef = useRef(activeChat);
  const dispatch = useDispatch();
  function redirectToLogin() {
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    window.location.href = "/login";
  }

  useEffect(() => {

    if (!isOnline) return confirmAlert({
      customUI: () => {
        return (
          <div className="overlay">
            <OutsideClickHandler onOutsideClick={redirectToLogin} >
              <PopupLogout isOnline={isOnline} />
            </OutsideClickHandler>
          </div>
        );
      },
    });
    const handleOnline = async () => {
      connectSocketIO()
    };
    // Add event listener for online status
    window.addEventListener('online', handleOnline);
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, [])

  const pendingMessageSent = async (socket) => {
    const pending = await getPendingMessagesFromCache();
    if (pending.length == 0) return
    for (let i = 0; i < pending.length; i++) {
      await new Promise((resolve, reject) => {
        let obj = {
          "conversationId": pending[i].conversationId,
          "senderId": userDetails._id,
          "personalNumber": userDetails.personalNumber,
          "type": pending[i].caption.includes("peoplesol/chat/inviteLink") ? 14 : 0,
          "caption": pending[i].caption,
          "createdOn": pending[i].createdOn,
          "lastUpdatedOn": new Date().toISOString()
        }
        socket.emit("addMessage", ({ ...obj }), (res) => {
          resolve();
        })
      });
    }
    await clearAllPendingMessages();
    refereshConversation(socket);
    handleContactClick(activeChat._id, socket);
  }

  const handleDocsSelect = (file, type) => {

    if (!file) {
      setSelectedDocs([]);
      setAttachmentSelected(false);
    } else {
      setSelectedDocs([file]);
      setSelectedFileType(type);
      setAttachmentSelected(true);
    }
  };
  const ViewFullscreen = (array, caption, ind) => {
    setMediaArray(array);
    setMediaIndex(ind);
    setMediaCaption(caption);
  };
  const [replyData, setReplyData] = useState({
    replyId: null,
    replyContent: "",
    data: null,
  });
  const [forwardData, setForwardData] = useState({
    isActive: false,
    msgId: [],
    userId: [],
    conversationId: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const togglePop = () => {
    setSelectedGroupMember([])
    setShowNewGroupMember(false)
    setCreateNewGroupMember(false)
    setCompanyBroadcast((prev) => !prev);
  };
  const toggleNewGroup = (incMember) => {
    setNewGroup((prev) => !prev);
    setShowNewGroupMember(false)
    if (incMember) setSelectedGroupMember([incMember])
    else setSelectedGroupMember([])
    setCreateNewGroupMember(false)
    setAnchorEl(false);
  };
  const inputFile = useRef(null);

  const open = Boolean(anchorEl);
  const rightOpen = Boolean(anchor);
  const id = open ? "simple-popover" : undefined;
  const _id = rightOpen ? "simple-popover" : undefined;
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRightMoreClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchor(null);
  };

  ///////////////////////////////////////////////////starting update
  const [newSocket, setSocket] = useState(null);
  const [status, setStatus] = useState('Not connected');

  const refereshConversation = (socket, type, groupId, navigateToContactId, openUserInfo, message) => {
    socket.emit("getMyConversations", ({}), (response) => {
      let convoArr = [];
      let team = [];
      for (let i = 0; i < response.data.myConversations.length; i++) {
        let obj = { ...response.data.myConversations[i] };
        if (obj.recentMessage.length > 0) {
          obj.sortTime = obj.recentMessage[0].createdOn;
        } else {
          obj.sortTime = obj.lastUpdatedOn;
        }
        if (obj.type === "Group" && (obj.title === "My Team" || obj.title === "Team")) team.push(obj);
        else convoArr.push(obj);
      }

      let blocked = convoArr.filter((item) => item?.isBlock == true);
      let archived = convoArr.filter((item) => item?.isArchived == true && !item.isBlock);
      let unarchived = convoArr.filter((item) => !item?.isArchived && !item?.isBlock);
      let pinnedMessages = unarchived.filter((item) => item?.isPin == true);
      pinnedMessages = pinnedMessages.sort((a, b) => (new Date(b.sortTime).valueOf() - new Date(a.sortTime).valueOf()))
      let allUnpinned = unarchived.filter((item) => !item.isPin);
      let a = allUnpinned.sort((a, b) => (new Date(b.sortTime).valueOf() - new Date(a.sortTime).valueOf()))
      a = [...team, ...pinnedMessages, ...a];
      setArchived([...archived])
      setBlockedChats([...blocked])
      dispatch(setConversationList(a));
      dispatch(addConversation([...a]))
      if (message) {
        setTimeout(() => {
          let chat = null;
          for (let i = 0; i < a.length; i++) {
            if (a[i].type === "Contact") {
              if (a[i].participateMembers[0].contactId == message.senderId) {
                chat = a[i];
                break;
              } else if (a[i].participateMembers[1].contactId == message.senderId) {
                chat = a[i];
                break;
              }
            }
          }
          SetActiveChat(chat);

          handleContactClick(chat._id, newSocket, null);
          setReplyData({
            replyId: message._id,
            replyContent: message?.caption,
            data: message,
          });
        }, 1200)
      }
      if (type === "validate") {
        handleContactClick(groupId);
        SetActiveChat(a.filter(item => item._id === groupId)[0])
      }
      if (navigateToContactId) {
        setTimeout(() => {
          let allConvo = a.filter(item => item.type === "Contact");
          let hasConversation = false;
          for (let i = 0; i < allConvo.length; i++) {
            if (allConvo[i].participateMembers[0].contactId === navigateToContactId || allConvo[i].participateMembers[1].contactId == navigateToContactId) {
              hasConversation = true;
              handleContactClick(allConvo[i]._id);
              SetActiveChat(allConvo[i]);
              setGroupInfo(false);
              if (openUserInfo) {
                setEmpProfileDetails(true)
                setSearchMessage(false); setGroupInfo(false); setMediaInfo(false); setMessageInfo(false)
              }
              return
            }
          }
        }, 1500)
      }
      if (activeChat && type === "block" || type == "unblock" || type == "refresh" || type == "unfavorite" || type == "favorite") {
        let act = response.data.myConversations.filter((item) => item._id == activeChat._id);
        if (act?.length) {
          SetActiveChat(act[0]);
        } else {
          SetActiveChat(0);
          setGroupInfo(false)
        }
      }
    });
  }

  const connectSocketIO = () => {
    if (!newSocket) {
      // Initialize socket.io connection
      //TODO
      //http://connect.aesssolutions.com:4000
      //http://192.168.1.3:4000
      //http://146.190.10.234:4000
      const socket = io('http://146.190.10.234:4000', {
        transports: ['websocket', 'polling'], // Ensure WebSocket transport is enabled
        query: {
          'x-secret-key': 'dp6aIpFpO8SU9LaonOqcMCwRkJAK8zyyqJ',
          'userId': LoggedInUser.personNumber
          // 'userId': '10000001'
        }
      });

      socket.on('connect', (data) => {
        pendingMessageSent(socket)
        console.log('Connected to server:', socket);
        setSocket(socket)
        updateStories(socket);
        socket.emit("getAllContacts", ({}), (response) => {
          dispatch(setContactList(response.data));
        });
        socket.emit("getUserSetting", ({}), (res) => {
          if (res, status) {
            dispatch(setUserSettings(res.data));
          }
        })
        socket.emit("getMyConversations", ({}), (response) => {
          let convoArr = [];
          let team = [];
          for (let i = 0; i < response.data.myConversations.length; i++) {
            let obj = { ...response.data.myConversations[i] };
            if (obj.recentMessage.length > 0) {
              obj.sortTime = obj.recentMessage[0].createdOn;
            } else {
              obj.sortTime = obj.lastUpdatedOn;
            }
            if (obj.type === "Group" && (obj.title === "My Team" || obj.title === "Team")) team.push(obj);
            else convoArr.push(obj);
          }

          let blocked = convoArr.filter((item) => item?.isBlock == true);
          let archived = convoArr.filter((item) => item?.isArchived == true && !item.isBlock);
          let unarchived = convoArr.filter((item) => !item?.isArchived && !item?.isBlock);
          let pinnedMessages = unarchived.filter((item) => item?.isPin == true);
          pinnedMessages = pinnedMessages.sort((a, b) => (new Date(b.sortTime).valueOf() - new Date(a.sortTime).valueOf()))
          let allUnpinned = unarchived.filter((item) => !item.isPin);
          let a = allUnpinned.sort((a, b) => (new Date(b.sortTime).valueOf() - new Date(a.sortTime).valueOf()))
          a = [...team, ...pinnedMessages, ...a];
          setArchived([...archived])
          setBlockedChats([...blocked])
          dispatch(setConversationList(a));
          dispatch(addConversation([...a]))
          socket.emit("getContactListsForForwardMessage", ({}), (res) => {
            setForwardContactList([...a, ...archived, ...res.data[0].contacts])
          })
        });
        setSocket(socket);
      });

      socket.on('chatServerEventListener', (res) => {
        console.log("------------> changes ", res)
        if (res?.status) {
          if (res.reqType === "msgStatusChange" && res.data.conversationId.includes(activeChatRef.current?._id)) {
            refereshConversation(socket);
            handleContactClick(activeChatRef.current?._id, socket, null, false, true)
            setSocket(socket);
          }
          if (res.reqType === "userSettingChange" || res.reqType === "onlineStatusChange") {
            refereshConversation(socket);
            handleContactClick(activeChatRef.current?._id, socket)
          }
          if (res.reqType === "newMsgArrived") {
            refereshConversation(socket);
            if (res.data.conversationId === activeChatRef.current._id) {
              handleContactClick(activeChatRef.current?._id, socket, activeChatRef.current, true);
            }
            setSocket(socket);
          }
          if (res.reqType === "deleteMsg") {
            handleContactClick(res.data.conversationId, socket)
            setSocket(socket);
          }
          if (res.reqType === "myUserInfo") {
            dispatch(setUserDetails(res.data));
            setSocket(socket);
          }
        }
      });

      socket.on('disconnect', () => {
        console.log('=========Disconnected from server');
      });

      socket.on('connect_error', (err) => {
        console.log(err.message);
        // some additional description, for example the status code of the initial HTTP response
        console.log(err.description);
        // some additional context, for example the XMLHttpRequest object
        console.log(err.context);
      });
      // Save socket instance in state
      setSocket(socket);
    } else {
      setStatus('Socket.IO is already connected');
    }
  };
  const closeConnection = () => {
    if (newSocket) {
      newSocket.disconnect(); // Disconnect the socket
      setSocket(null); // Clear the socket instance
      setStatus('Disconnected from Socket.IO');
      console.log('Socket.IO connection closed');
    } else {
      setStatus('No active connection to disconnect');
    }
  }

  useEffect(() => {
    connectSocketIO();
    return (() => {
      closeConnection();
    })
  }, [newSocket])

  const getFilteredList = (list) => {
    if (activeFilter === "Unread") {
      let a = list.filter(item => item.unReadMsgCount > 0 || !item.isRead);
      return a
    } else if (activeFilter === "Pin") {
      let a = list.filter(item => item.isPin == true);
      return a
    } else if (activeFilter === "Groups") {
      let a = list.filter(item => item.type == "Group");
      return a
    } else if (activeFilter === "Broadcasts") {
      let a = list.filter(item => item.type == "Broadcast");
      return a
    } else if (activeFilter === "Favourites") {
      let a = list.filter(item => item.isFavorite == true);
      return a
    }
    else return list;

  }

  const NoListView = () => {
    const [values, setValues] = useState({
      img: NoFavourites,
      heading: "",
      subText: "",
      button: false,
      buttonText: "",
      action: () => { }
    })
    useEffect(() => {
      if (activeFilter === "Unread") {
        setValues(
          {
            img: NoRead,
            heading: "You're All Cought Up!",
            subText: "Check back later for new messages",
            button: false,
            buttonText: "",
            action: () => { }
          }
        )
      } else if (activeFilter === "Pin") {
        setValues(
          {
            img: NoPin,
            heading: "No Pinned Chats Yet!",
            subText: "Select your chats to pin them on the top",
            button: false,
            buttonText: "",
            action: () => { }
          }
        )
      } else if (activeFilter === "Groups") {
        setValues(
          {
            img: NoGroups,
            heading: "No Groups Available.",
            subText: "Create a groups to start sorting your chats according to groups.",
            button: true,
            buttonText: "Create New Group",
            buttonIcon: NewGroupIcon,
            action: () => { setNewGroup(true) }
          }
        )
      } else if (activeFilter === "Broadcasts") {
        setValues(
          {
            img: NoBroadcast,
            heading: "No Broadcasts Available.",
            subText: "Create a broadcast to start sorting your chats according to brodcasts.",
            button: true,
            buttonText: "Create New Broadcast",
            buttonIcon: NewBroadcastIcon,
            action: () => { setCompanyBroadcast(true) }
          }
        )
      } else if (activeFilter === "Favourites") {
        setValues(
          {
            img: NoFavourites,
            heading: "No Favourites Yet!",
            subText: "Mark chat as favourite to find them here.",
            button: false,
            buttonText: "",
            action: () => { }
          }
        )
      } else {
        setValues(
          {
            img: WelcomeToChat,
            heading: "Welcome To Chat",
            subText: "Stay in touch, anytime, anywhere.",
            button: true,
            buttonIcon: StartChatIcon,
            buttonText: "Start a New Chat",
            action: () => { setShowNewConversat(true) }
          }
        )
      }
    }, [activeFilter])
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 10 }}>
        <img style={{ width: 210, height: 210 }} src={values.img} />
        <div style={{ color: "rgba(85, 85, 85, 1)", fontSize: 18, marginTop: 10, marginBottom: 10, textAlign: "center" }}>{values.heading}</div>
        <div style={{ color: "rgba(161, 161, 161, 1)", fontSize: 12, textAlign: "center" }}>{values.subText}</div>
        {values.button ? <div style={{ backgroundColor: "rgba(18, 150, 176, 1)", borderRadius: 6, padding: 10, display: "flex", flexDirection: "row", alignItems: "center", margin: 20, color: "white" }} onClick={() => { values.action() }}>
          <img style={{ marginRight: 10, height: 20, width: 20 }} src={values.buttonIcon} />
          <div>{values.buttonText}</div>
        </div> : null}
      </div>
    )
  }

  const scrollRef = useRef(null);

  const getAllSelfMessages = () => {
    newSocket.emit("getSelfMessages", ({}), (res) => {
      dispatch(setActiveMessageList(res.data));
      dispatch(addMessages(res.data));
    })
  }

  const uploadProfilePic = (path, contactId) => {
    newSocket.emit("updateContact", ({ "contactId": contactId, "contactInfo": { "image": path } }), (res) => {
      dispatch(setUserDetails(res.data));
      dispatch(
        showToast({ text: "Profile updated successfully", severity: "success" })
      );
    })
  }

  function showStatus(onlineStatus) {
    if (onlineStatus === 1) {
      return "greenDot";
    } else if (onlineStatus === 2) {
      return "redDot";
    } else if (onlineStatus === 3) {
      return "yellowDot";
    } else {
      return "offlineDot";
    }
  }
  const handleAttachmentClick = () => {
    setShowOptions((prev) => !prev);
  };
  const deleteMessage = (messageId, contactId, classID) => {

    let messageClass = classID
    if (messageClass === "multiple") {
      //TODO
      let a = sortedMessageList.filter((item) => messageId.includes(item._id) && item.personalNumber != userDetails.personalNumber)
      if (a.length > 0) messageClass = "received"
      else messageClass = "sent"
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-chat-Delete">
            <h1 className="deleteMessageFont">Delete Message?</h1>
            <div>
              <button
                className="button primaryButton chatDeleteButton"
                style={{ backgroundColor: "white", color: "red" }}
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton chatDeleteButton"
                onClick={() => {
                  newSocket.emit("deleteMessage", { "messageId": classID === "multiple" ? [...messageId] : [messageId], "conversationId": activeChat._id, "reqType": "deleteForMe" }, (res) => {
                    dispatch(
                      showToast({ text: "Message deleted successfully", severity: "success" })
                    );
                  })
                  onClose();

                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                }}
              >
                Delete for me
              </button>
              {messageClass == "sent" ? <button
                className="button primaryButton chatDeleteButton"
                onClick={() => {
                  onClose();
                  newSocket.emit("deleteMessage", { "messageId": classID === "multiple" ? [...messageId] : [messageId], "conversationId": activeChat._id, "reqType": "deleteForEveryOne" }, (res) => {
                    dispatch(
                      showToast({ text: "Message deleted successfully", severity: "success" })
                    );
                  })
                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                }}
              >
                Delete for everyone
              </button> : null}
            </div>
          </div>
        );
      },
    });
  };
  const bookMarkMessage = (messageId, contactId, type) => {
    newSocket.emit("bookmarkMessage", { "messageId": messageId, "contactId": contactId, "reqType": type }, (res) => {
      handleContactClick(activeChat._id, newSocket);
      dispatch(
        showToast({ text: "Message bookmarked successfully", severity: "success" })
      );
    })
  }

  const sendAudioMessage = (data) => {
    //Media upload for audio: 1 / video: 2 / image: 3 / document: 7 / link: 8 / mixed :9  / contact: 10 / zip: 11 / replyToStory : 12 / gif: 13 / group link:  14
    if (activeChat.personal) {
      newSocket.emit("addSelfMessage", ({ "senderId": userDetails._id, "personalNumber": userDetails.personalNumber, "type": 1, "caption": "", "content": [{ ...data, "isMediaCompressed": true }] }), (res) => {
        getAllSelfMessages();
      })
    } else {
      newSocket.emit(activeChat.type === "Broadcast" ? "broadcastMessage" : "addMessage", (
        {
          "conversationId": activeChat._id,
          "senderId": userDetails._id,
          "personalNumber": userDetails.personalNumber,
          "type": 1,
          "caption": "",
          "content": [{ ...data, "isMediaCompressed": true }],
        }), (res) => { handleContactClick(activeChat._id); refereshConversation(newSocket); })
    }

  }

  const sendMessageText = async (messageInput, time) => {
    if (!navigator.onLine) {
      await addPendingMessageToCache(
        {
          "_id": new Date().valueOf(),
          "conversationId": activeChat._id,
          "senderId": userDetails._id,
          "personalNumber": userDetails.personalNumber,
          "replyingToMsgId": "",
          "type": 0,
          "time": new Date().toISOString(),
          "content": [],
          "caption": messageInput,
          "logMsgType": null,
          "msgStatus": 100,
          "isBookmarked": false,
          "deletedStatus": false,
          "deleteForMeIds": [],
          "clearStatus": false,
          "clearForMeIds": [],
          "replyingToStoryId": null,
          "forwardStatus": false,
          "isMediaCompressed": false,
          "replyMsgObj": {},
          "createdOn": new Date().toISOString(),
          "lastUpdatedOn": new Date().toISOString(),
          "isDataActive": true,
          "cleanStatus": false,
          "bubbleType": "right"
        }
      )
      getMessagesList(activeChat._id)
    } else {
      if (activeChat.personal) {
        newSocket.emit("addSelfMessage", ({ "senderId": userDetails._id, "personalNumber": userDetails.personalNumber, "type": 0, "caption": messageInput }), (res) => {
          getAllSelfMessages();
        })
      } else {
        if (replyData.replyId) {
          newSocket.emit(activeChat.type === "Broadcast" ? "broadcastMessage" : "addMessage", (
            {
              "conversationId": activeChat._id,
              "senderId": userDetails._id,
              "personalNumber": userDetails.personalNumber,
              "type": 0,
              "caption": messageInput,
              "replyingToMsgId": replyData.replyId
            }), (res) => {
              handleContactClick(res.data.conversationId, newSocket);
              refereshConversation(newSocket);
            })
        } else {
          let obj = {
            "conversationId": activeChat._id,
            "senderId": userDetails._id,
            "personalNumber": userDetails.personalNumber,
            "type": isURL(messageInput),
            "caption": messageInput,
          }
          if (obj["type"] == 14) obj["content"] = [{ "conversationId": parseInt(messageInput.split("/")[3]), "inviteLink": ("/" + messageInput.split("/")[3] + "/" + messageInput.split("/")[4]) }]
          if (time) obj["time"] = time;

          console.log(newSocket, "------------", activeChat.type === "Broadcast" ? "broadcastMessage" : "addMessage", "------------> upto here", { ...obj })
          newSocket.emit(activeChat.type === "Broadcast" ? "broadcastMessage" : "addMessage", ({ ...obj }), (res) => {
            console.log("-----------> inside add message", res)
            handleContactClick(res.data.conversationId, newSocket);
            refereshConversation(newSocket);
          })
        }
      }
    }
  };
  const isURL = (messageInput) => {
    if (messageInput.includes("peoplesol/chat/inviteLink")) return 14;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = messageInput.split(urlRegex);
    for (let i = 0; i < parts.length; i++) {
      if (urlRegex.test(parts[i])) return 8;
    }
    return 0;
  }



  const forwardMessageAction = () => {
    let contacts = [];
    for (let i = 0; i < forwardData.userId.length; i++) {
      let a = { "contactId": forwardData.userId[i], "personalNumber": forwardContactList.filter(item => item._id === forwardData.userId[i] && !item.participateMembers)[0].personalNumber }
      contacts.push(a);
    }
    let data = {
      "messageId": [...forwardData.msgId],
      "senderId": userDetails._id,
      "contactsIds": [...contacts],
      "conversationsIds": forwardData.conversationId ? [...forwardData.conversationId] : []
    }
    newSocket.emit("forwardMessage", (data), (res) => {
      refereshConversation(newSocket);
      dispatch(
        showToast({ text: "Message forwarded successfully!", severity: "success" })
      );
      setForwardData(
        {
          isActive: false,
          msgId: [],
          userId: [],
          conversationId: [],
        }
      )

    })
  }

  const startConversation = (payload, contactId, openUserInfo, message) => {
    newSocket.emit("addConversation", (payload), (res) => {
      refereshConversation(newSocket, null, null, contactId, openUserInfo, message)
      setSelectedGroupMember([]);
      dispatch(
        showToast({ text: "New conversation has started", severity: "success" })
      );
    })
  }


  function compareDate(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();

    // Reset time portion for comparison to only deal with dates
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const inputDateWithoutTime = new Date(inputDate);
    inputDateWithoutTime.setHours(0, 0, 0, 0);

    if (inputDateWithoutTime.getTime() === today.getTime()) {
      return "Today";
    } else if (inputDateWithoutTime.getTime() === yesterday.getTime()) {
      return "Yesterday";
    } else {
      // Format date as "dd/mm/yyyy"
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = inputDate.getFullYear();
      return `${day}/${month}/${year}`;
    }
  }

  const addLabels = (messages) => {
    if (!messages || messages?.length == 0) return;
    let data = {
      _id: -600,
      bubbleType: "right",
      cleanStatus: false,
      clearForMeIds: [],
      clearStatus: false,
      content: [],
      conversationId: 7,
      createdOn: "2024-10-04T10:35:54Z",
      deleteForMeIds: [],
      deletedStatus: false,
      forwardStatus: false,
      isBookmarked: false,
      isDataActive: true,
      isMediaCompressed: false,
      lastUpdatedOn: "2024-10-04T10:35:54Z",
      logMsgType: null,
      msgStatus: null,
      personalNumber: userDetails.personalNumber,
      replyMsgObj: null,
      replyingToMsgId: null,
      replyingToStoryId: null,
      senderId: 9,
      time: "2024-10-04T10:35:54Z",
    }
    let latestDate = compareDate(messages[0].time)
    let arr = [{ ...data, caption: latestDate, type: 6 }]
    for (let i = 0; i < messages.length; i++) {
      let newDate = compareDate(messages[i].time);
      if (latestDate != newDate) {
        latestDate = newDate
        arr.push({ ...data, caption: latestDate, type: 6, });
      }
      let obj = { ...messages[i] };

      if (obj.type === 6) {
        obj.bubbleType = "right";
        obj.personalNumber = userDetails.personalNumber;
      }
      arr.push(obj);
    }
    return arr;
  }
  const getMessagesList = async (conversationId, socket, contact, readCurrentChat, selfRead = false) => {
    dispatch(setActiveMessageList([]));
    setReplyData({
      replyId: null,
      replyContent: "",
      data: null,
    })
    if (navigator.onLine) {
      if (newSocket) {
        newSocket.emit("getMessages", ({ "conversationId": conversationId }), (response) => {
          const sortedMessages = addLabels(response?.data[0]?.messages);
          if (response.data && response.data.length) {
            dispatch(setActiveMessageList(sortedMessages));
            dispatch(addMessages(sortedMessages));
            console.log(activeChat, contact, "---------- msg status 1")

            newSocket.emit("updateMyConversation", { "conversationId": [conversationId], "reqType": "read" }, (res) => {
              refereshConversation(newSocket);
            })
            if (userDetails && (activeChatRef.current.unReadMsgCount > 0 || contact?.unReadMsgCount > 0 || readCurrentChat)) {
              console.log("---------- chnaging message status1")
              newSocket.emit("changeMessageStatus", { "conversationId": [conversationId], "contactId": userDetails._id, "messageStatus": 3 }, (res) => { console.log("1----====--- cb from change message status", res) })
            }
          }

        });
      } else if (socket) {
        socket.emit("getMessages", ({ "conversationId": conversationId }), (response) => {
          const sortedMessages = addLabels(response?.data[0]?.messages);
          if (response.data && response.data.length) {
            dispatch(setActiveMessageList(sortedMessages));
            dispatch(addMessages(sortedMessages));
            socket.emit("updateMyConversation", { "conversationId": [conversationId], "reqType": "read" }, (res) => {
              refereshConversation(socket);
            })
            console.log(activeChat, contact, "---------- msg status 2")
            if (userDetails && (activeChatRef.current.unReadMsgCount > 0 || contact?.unReadMsgCount > 0 || readCurrentChat) && !selfRead) {
              console.log("---------- chnaging message status2")

              socket.emit("changeMessageStatus", { "conversationId": [conversationId], "contactId": userDetails._id, "messageStatus": 3 }, (res) => { console.log("2------- cb from change message status", res) })
            }
          }
        });
      }
    } else {
      let allMsgs = await getMessagesByConversation(conversationId);
      let pendingMessages = (await getPendingMessagesFromCache()).filter(item => item.conversationId == conversationId);
      const sortedMessages = addLabels([...allMsgs, ...pendingMessages]);
      dispatch(setActiveMessageList(sortedMessages));

      // console.log((await pendingMessages).filter(item => item.conversationId == activeChat._id), "---------->", (await allMsgs));

    }
  };

  const handleContactClick = (conversationId, socket, contact, readCurrentChat, selfRead) => {
    setEmpProfileDetails(false);
    setSearchMessage(false);
    setGroupInfo(false);
    setMediaInfo(false);
    setMessageInfo(false);
    getMessagesList(conversationId, socket, contact, readCurrentChat, selfRead);
  };

  const handleConversationAction = (conversationId, type) => {
    let a;
    if (typeof conversationId == "number") a = [conversationId];
    else a = conversationId;
    newSocket.emit("updateMyConversation", { "conversationId": [...a], "reqType": type }, (res) => {
      dispatch(
        showToast({ text: "Request successfully executed", severity: "success" })
      );

      if (type == "read") {
        let curChat = chatList.filter((chat) => chat._id === conversationId);
        if (curChat[0]?.unReadMsgCount > 0) {
          if (userDetails) newSocket.emit("changeMessageStatus", { "conversationId": [conversationId], "contactId": userDetails._id, "messageStatus": 3 }, (res) => { console.log("------- cb from change message status", res) })
        }
      }
      refereshConversation(newSocket, type)
    })
  }

  const handleOptionSelect = async (option) => {
    if (option === "Settings") {
      setSettingsActive(true);
    } else if (option === "starredMessage") {
      setStarredMessage(true);
    } else if (option === "blocklist") {
      setBlocklist(true);
    } else if (option === "New Group") {
      setNewGroup(true);
    } else if (option === "Company Broadcast") {
      setCompanyBroadcast(true);
    } else if (option === "Community") {
      setCommunity(true);
    }
    handleClose();
  };

  const handleNavigationFromOtherChat = (chatData, openUserInfo) => {
    let allConvo = chatList.filter(item => item.type === "Contact");
    let hasConversation = false;
    for (let i = 0; i < allConvo.length; i++) {
      if (allConvo[i].participateMembers[0].contactId === chatData.contactId || allConvo[i].participateMembers[1].contactId == chatData.contactId) {
        hasConversation = true;
        handleContactClick(allConvo[i]._id);
        SetActiveChat(allConvo[i]);

        setGroupInfo(false);
        if (openUserInfo) {
          setEmpProfileDetails(true)
          setSearchMessage(false); setGroupInfo(false); setMediaInfo(false); setMessageInfo(false)
        }
        return
      }
    }

    if (!hasConversation) {
      startConversation({
        "type": "Contact",
        "title": "",
        "icon": "",
        "participateMembers": [
          {
            "contactId": userDetails._id,
            "personalNumber": userDetails.personalNumber
          },
          {
            "contactId": chatData.contactId,
            "personalNumber": chatData.personalNumber
          }
        ], "createdByContactId": userDetails._id
      }, chatData.contactId, openUserInfo)

    }
  }

  const handleSearchResult = (caption) => {
    dispatch(searchMessages(caption))
    dispatch(searchConversationsThunk(caption))
  }

  const [showStory, setShowStory] = useState(false);
  const [showStoryPreview, setShowStoryPreview] = useState(false);
  const [storyFiles, setStoryFiles] = useState([]);
  const [allMyStories, setAllMyStories] = useState([]);
  const [othersStories, setOthersStories] = useState([]);
  const [activeStory, setActiveStory] = useState({});
  const [search, setSearch] = useState(false)


  const updateStories = (socket) => {
    const a = (res) => {
      if (res.statusCode == 200 && res?.data[0]) {
        if (res?.data[0]?.myStory && res.data[0].myStory.length)
          setAllMyStories([...res?.data[0]?.myStory])
        if (res?.data[0]?.otherStory && res.data[0].otherStory.length)
          setOthersStories(res?.data[0].otherStory)
      }
    }
    if (socket) {
      socket.emit("getAllStory", ({}), (res) => { a(res) })
    } else {
      newSocket.emit("getAllStory", ({}), (res) => { a(res) })
    }
  }
  useEffect(() => {
    if (activeTab === "updates") {
      updateStories();
    }
  }, [activeTab])

  useEffect(() => {
    activeChatRef.current = activeChat;
  }, [activeChat]);

  const handleUploadStory = (e) => {
    const file = e.target.files[0];
    if (file) {
      setStoryFiles([...storyFiles, file]);
      setShowStoryPreview(true);
    }
  }


  const handleReplyPrivately = (message) => {
    let chat = null;
    for (let i = 0; i < chatList.length; i++) {
      if (chatList[i].type === "Contact") {
        if (chatList[i].participateMembers[0].contactId == message.senderId) {
          chat = chatList[i];
          break;
        } else if (chatList[i].participateMembers[1].contactId == message.senderId) {
          chat = chatList[i];
          break;
        }
      }
    }
    if (chat) {
      SetActiveChat(chat);
      handleContactClick(chat._id, newSocket);
      setReplyData({
        replyId: message._id,
        replyContent: message?.caption,
        data: message,
      });
    } else {
      startConversation({
        "type": "Contact",
        "title": "",
        "icon": "",
        "participateMembers": [
          {
            "contactId": userDetails._id,
            "personalNumber": userDetails.personalNumber
          },
          {
            "contactId": message.senderId,
            "personalNumber": message.personalNumber
          }
        ], "createdByContactId": userDetails._id
      }, message.senderId, null, message)
    }

  }

  const SearchPanel = () => {
    const hasRecentMessage = (chat) => {
      if (chat.recentMessage && chat.recentMessage.length && chat?.participateMembers.length) {
        let a = chat?.participateMembers.filter(item => item.contactId == chat.recentMessage[0].senderId)
        if (chat.recentMessage[0].senderId === userDetails._id) return "You: " + chat.recentMessage[0].caption;
        else if (a && a.length) return (a[0].contactName + ": ") + chat.recentMessage[0].caption
        else return ""
      } else return "";
    }
    const getDate = (date) => {
      if (!date) return "";
      let a = JSON.stringify(new Date().toLocaleDateString());
      let b = JSON.stringify(new Date(date).toLocaleDateString());
      if (a == b) return "Today";
      if (new Date().getMonth() == new Date(date).getMonth() && new Date().getFullYear() === new Date(date).getFullYear()) {
        if ((new Date().valueOf() - new Date(date).valueOf()) < 1000 * 60 * 60 * 24) return "Yesterday";
        else return new Date(date).toLocaleDateString();
      } else {
        return new Date(date).toLocaleDateString();
      }
    }

    return (
      <div style={{ width: "100%", height: "100%", padding: 15, overflow: "auto" }}>
        <div style={{ paddingBottom: 20, borderStyle: "solid", borderWidth: 0, borderBottomWidth: .4, borderColor: "light grey", }}>
          <div style={{ color: "rgba(18, 150, 176, 1)", fontSize: 16 }}>Chats</div>
          {conversationSearchResult?.filter((item) => item.type === "Contact").map(chat => (
            <div onClick={() => {
              // if (activeChat._id) {
              setScrollToId(null)
              handleContactClick(chat._id);
              SetActiveChat(chat);
              // }
            }} style={{ marginTop: 20, cursor: "pointer" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                {chat.icon && chat.icon != "" ?
                  <img style={{ height: 45, width: 45, borderRadius: 25 }} src={chat.icon} /> :
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 45, width: 45, borderRadius: 25, backgroundColor: "rgba(216, 240, 242, 1)", color: "rgba(18, 150, 176, 1)" }}>
                    {chat?.title
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.map((item) => {
                        return item?.charAt(0)?.toUpperCase();
                      })
                      .join("")
                    }
                  </div>
                }
                <div style={{ marginLeft: 10 }}>
                  <div>{chat.title}</div>
                  <div style={{ fontSize: 12, color: "grey" }}>{hasRecentMessage(chat)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div style={{ paddingBottom: 20, borderStyle: "solid", borderWidth: 0, borderBottomWidth: .4, borderColor: "light grey", marginTop: 30 }}>
          <div style={{ color: "rgba(18, 150, 176, 1)", fontSize: 16 }}>Groups</div>
          {conversationSearchResult?.filter((item) => item.type === "Group").map(chat => (
            <div onClick={() => {
              // if (activeChat._id) {
              setScrollToId(null)
              handleContactClick(chat._id);
              SetActiveChat(chat);
              // }
            }} style={{ marginTop: 20, cursor: "pointer" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {chat.icon && chat.icon != "" ?
                  <img style={{ height: 45, width: 45, borderRadius: 25 }} src={chat.icon} /> :
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 45, width: 45, borderRadius: 25, backgroundColor: "rgba(216, 240, 242, 1)", color: "rgba(18, 150, 176, 1)" }}>
                    {chat?.title
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.map((item) => {
                        return item?.charAt(0)?.toUpperCase();
                      })
                      .join("")
                    }
                  </div>
                }
                <div style={{ marginLeft: 10 }}>
                  <div>{chat.title}</div>
                  <div style={{ fontSize: 12, color: "grey" }}>{hasRecentMessage(chat)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>


        <div style={{ paddingBottom: 20, borderStyle: "solid", borderWidth: 0, borderBottomWidth: .4, borderColor: "light grey", marginTop: 30 }}>
          <div style={{ color: "rgba(18, 150, 176, 1)", fontSize: 16 }}>Messages</div>
          {searchResults.filter(item => item.type != 6).map(chat => (
            <div onClick={() => {
              // if (activeChat._id) {
              SetActiveChat(chatList.filter(item => item._id == chat.conversationId)[0]);
              handleContactClick(chat.conversationId)
              // }
              setScrollToId(chat._id)
            }} style={{ marginTop: 20, cursor: "pointer" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                  <div>{chatList.filter(item => item._id == chat.conversationId)[0]?.title}</div>
                  <div style={{ fontSize: 12, color: "grey" }}>{chat.caption}</div>
                </div>
                <div style={{ fontSize: 12, color: "grey" }}>
                  {getDate(chat.createdOn)}
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>
    )
  }
  const handleReschedule = (type, id, time) => {
    if (type == "delete") {
      newSocket.emit("updateScheduledMsg", { "messageId": id, "reqType": "delete", "time": "2024-09-28T11:14:42Z" }, (res) => {
        handleContactClick(activeChat._id, newSocket);
        dispatch(
          showToast({ text: "Message deleted successfully", severity: "success" })
        );
      })
    } else {
      setSchedule(true)
      setReScheduleMessageValue({ time: time, id: id });
    }

  }
  const reSchedule = (time, id) => {
    newSocket.emit("updateScheduledMsg", { "messageId": id, "reqType": "rescheduled", "time": time }, (res) => {
      handleContactClick(activeChat._id, newSocket);
      dispatch(
        showToast({ text: "Message updated successfully", severity: "success" })
      );
    })

  }


  const handleViewGroup = (convoId, type) => {
    if (type === "validate") {
      refereshConversation(newSocket, "validate", convoId);
      setGroupInfo(false);
      setEmpProfileDetails(false);
      setMessageInfo(false);
    } else {
      const a = chatList.filter((item) => item._id === convoId)[0]
      handleContactClick(convoId);
      SetActiveChat(a);
      setGroupInfo(false);
      setEmpProfileDetails(false);
      setMessageInfo(false);
    }
  }

  const handleStoryFromChat = (replyingToStoryId, senderId) => {
    let obj = null;
    if (userDetails._id === senderId) {
      for (let i = 0; i < allMyStories.length; i++) {
        if (allMyStories[i]._id === replyingToStoryId) {
          obj = { type: "self", stories: allMyStories, startWithIndex: i };
          setActiveStory({ type: "self", stories: allMyStories, startWithIndex: i })
          break;
        }
      }
    } else {
      let userData = othersStories.filter(item => item.id == senderId)[0]
      for (let i = 0; i < userData.stories.length; i++) {
        if (userData.stories[i]._id === replyingToStoryId) {
          obj = { type: "other", startWithIndex: i, ...userData };
          setActiveStory({ type: "other", startWithIndex: i, ...userData })
          break;
        }
      }
    }
    if (obj && Object.keys(obj).length) setShowStory(true);
    else {
      confirmAlert({
        title: "Story Not Available",
        message: "This story is no longer posted by the user",
        buttons: [
          {
            label: "Cancel",
            onClick: () => { },
          },
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
        overlayClassName: "customConfirmAlertContainer",
      });
    }
  }

  return (
    <>
      <input
        type="file"
        accept="video/*,image/*"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleUploadStory}
      />
      {scheduleMessage ? <ScheduleMessage rescheduleMessageValue={rescheduleMessageValue} reSchedule={(time, id) => { setScheduleMessageValue(null); setReScheduleMessageValue(null); setSchedule(false); reSchedule(time, id) }} setSchedule={() => { setSchedule(false); setReScheduleMessageValue(null) }} sendMessageText={(time) => { sendMessageText(scheduleMessageValue, time); setScheduleMessageValue(null); }} /> : null}
      {showStoryPreview ? <StoryUploadPreview updateStories={updateStories} newSocket={newSocket} storyFiles={storyFiles} handleInput={() => { document.getElementById("fileInput").click() }} closeStory={() => { setStoryFiles([]); setShowStoryPreview(false) }} /> : null}
      {showCallScreen ? <TestCall activeChat={activeChat} setShowCallScreen={() => { setShowCallScreen(false) }} userDetails={userDetails} audioCall={audioCall} /> : null}
      {showStory ? <StoryPopUp refresh={() => { if (activeChat) handleContactClick(activeChat._id) }} update={updateStories} newSocket={newSocket} activeStory={activeStory} closeStory={() => { setShowStory(false) }} /> : null}
      <div className="chatContainerBg">
        <div className="chatContainerBg1"></div>
        <div className="chatContainerBg2"></div>
        <div className="chatContainerBg3"></div>
        <div className="chatContainerBg4"></div>
      </div>
      <div className="chatContainer" >
        <div className="chatMainContainer">
          {showGroupInvite && (
            <GroupInvitePage
              refresh={refereshConversation}
              activeChat={activeChat}
              newSocket={newSocket}
              userDetails={userDetails}
              setShowLinkSetting={() => { setShowLinkSetting(true); setShowGroupInvite(false) }}
              toggleNewGroup={toggleNewGroup}
              setShowGroupInvite={setShowGroupInvite}
              uploadProfilePic={uploadProfilePic} />
          )}
          {showLinkSetting && (
            <GroupLinkSetting
              setShowLinkSetting={() => { setShowLinkSetting(false); setShowGroupInvite(true) }}
              activeChat={activeChat}
              newSocket={newSocket}
              userDetails={userDetails}
              setShowGroupInvite={setShowGroupInvite}
              uploadProfilePic={uploadProfilePic} />
          )
          }
          {myProfileDetails && (
            <MyProfileDetails
              newSocket={newSocket}
              userDetails={userDetails}
              toggleNewGroup={toggleNewGroup}
              setMyProfileDetails={setMyProfileDetails}
              uploadProfilePic={uploadProfilePic} />
          )}

          {settingsActive && (
            <MySettingDetails
              userDetails={userDetails}
              setAnchorEl={setAnchorEl}
              newSocket={newSocket}
              setSettingsActive={setSettingsActive}
            />
          )}
          {starredMessage && (
            <div className="chatContactListContainer">
              <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                  <svg
                    onClick={() => {
                      setStarredMessage(false);
                      setAnchorEl(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                      fill="#F7941D"
                    />
                  </svg>
                  <span>Starred message</span>
                </div>
              </div>
              <StarredMessages newSocket={newSocket} setStarredMessages={setStarredMessage} />
            </div>
          )}
          {blocklist && (
            <div className="chatContactListContainer">
              <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                  <svg
                    onClick={() => {
                      setSelectChat(false);
                      setSelectedChatsArr([]);
                      setBlocklist(false);
                      setAnchorEl(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                      fill="#F7941D"
                    />
                  </svg>
                  <span>Block List</span>
                </div>
              </div>

              {
                blocklist && !search &&
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <div onClick={() => { setSelectChat(!selectChat); setSelectedChatsArr([]) }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                    <img src={selectChat ? ChatSelectedCheck : ChatUnselectedCheck} alt="Select chat" />
                    <div style={{ marginLeft: 15, fontSize: 14, fontWeight: "500" }}>{"Select"} </div>
                  </div>
                  {selectChat && selectedChatsArr.length > 0 && <div onClick={() => { setChatOptionOpen(true) }}>
                    <img
                      style={{ cursor: "pointer", display: "block", marginRight: 30 }}
                      src={DropdownArrowChatMesssage}
                      alt=""
                    />
                  </div>}
                </div>
              }
              {blocklist &&
                <OutsideClickHandler
                  onOutsideClick={(id) =>
                    setChatOptionOpen(false)
                  }
                >
                  <div
                    className="chatActionsPopup"
                    style={
                      isChatOptionOpen ? {
                        display: "block",
                        position: "absolute",
                        zIndex: "100",
                        marginLeft: "130px",
                        marginTop: "-40px",
                        color: "black",
                      }
                        : { display: "none" }
                    }
                  >
                    <div
                      style={{ borderRadius: "6px 6px 0px 0px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        let arr = chatList.filter(val => val.type == "Group" || val.type == "Broadcast");
                        let a = false;
                        for (let i = 0; i < arr.length; i++) {
                          if (selectedChatsArr.includes(arr[i]._id)) {
                            a = true;
                            break;
                          };
                        }
                        if (a) {
                          confirmAlert({
                            title: "Deleting Groups?",
                            message: "You cannot delete groups and your braodcasts through this action, unselect all groups and broadcasts",
                            buttons: [
                              {
                                label: "Cancel",
                                onClick: () => { },
                              },
                              {
                                label: "Ok",
                                onClick: () => { },
                              },
                            ],
                            overlayClassName: "customConfirmAlertContainer",
                          });
                        } else {
                          setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false);
                          handleConversationAction(selectedChatsArr, "deleteConversation");
                        }
                      }}>Delete Chat</div>
                    <div style={{ borderRadius: "0px 0px 6px 6px" }} onClick={(e) => {
                      e.stopPropagation();
                      setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false);
                      handleConversationAction(selectedChatsArr, "unblock");
                    }}>{"Unblock"}</div>
                  </div>
                </OutsideClickHandler>}
              <div style={{ height: "calc(100% - 90px)", width: "100%" }}>
                <div className="chatListSearchContainer"></div>
                <div className="chatListContainer" style={{ overflow: "auto", height: "73%" }}>
                  {/* Show Chat Contact List Here */}
                  <ContactList
                    selectChat={selectChat}
                    selectedChatsArr={selectedChatsArr}
                    setSelectedChatsArr={setSelectedChatsArr}
                    activeChat={activeChat}
                    LoggedInUser={LoggedInUser}
                    chatList={blockedChats}
                    showStatus={showStatus}
                    SetActiveChat={(a) => {
                      SetActiveChat(a);
                    }}
                    handleContactClick={handleContactClick}
                    handleConversationAction={handleConversationAction}
                  />
                </div>
              </div>
              {/* <BlockLists setBlockContactList={setBlocklist} /> */}
            </div>
          )}
          {community && (
            <div className="chatContactListContainer">
              <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                  <svg
                    onClick={() => {
                      setCommunity(false);
                      setAnchorEl(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                      fill="#F7941D"
                    />
                  </svg>
                  <span>Community</span>
                </div>
              </div>
              <Community setCommunity={setCommunity} />
            </div>
          )}

          {!showLinkSetting &&
            !showGroupInvite &&
            !myProfileDetails &&
            !settingsActive &&
            !starredMessage &&
            !blocklist &&
            !community && (
              <div className="chatContactListContainer">
                <div className="chatHeaderContainer">
                  {showGlobalSearch ?
                    <div className="selfChatHeader">
                      <div style={{ width: "100%", padding: 10 }}>
                        <div style={{ width: "100%", padding: 10, backgroundColor: "rgba(216, 240, 242, 1)", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderRadius: 4, }}>
                          <input onChange={(e) => {
                            if (e.target.value && e.target.value != "") setSearch(true)
                            else {
                              setScrollToId(null)
                              setSearch(false);
                            }
                            handleSearchResult(e.target.value)
                          }} placeholder="Search" style={{ outline: "none", backgroundColor: "rgba(216, 240, 242, 1)", borderStyle: "none", }} />
                          <div onClick={() => { setShowGlobalSearch(false); setSearch(false); setScrollToId(false) }}>
                            <img src={SearchCloseIcon} />
                          </div>
                        </div>
                      </div>
                    </div>
                    : <div className="selfChatHeader">
                      {!showArchivedList && <div
                        className="chatListHeader"
                        onClick={() => {
                          setMyProfileDetails(true);
                        }}
                      >
                        <div className="activeImage" style={{ marginLeft: 12 }}>
                          {userDetails.image != "" ? <img
                            src={userDetails.image}
                            alt="Profile"
                          /> :
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 42, width: 42, borderRadius: 25, backgroundColor: "rgba(216, 240, 242, 1)", color: "rgba(18, 150, 176, 1)" }}>
                              {userDetails.name
                                ?.split(" ")
                                ?.slice(0, 2)
                                ?.map((item) => {
                                  return item?.charAt(0)?.toUpperCase();
                                })
                                .join("")
                              }
                            </div>}
                          <div className={showStatus(userSettings.dndStatus ? 2 : userSettings.busyStatus ? 3 : 1)}></div>
                        </div>
                        <div className="allChatListDisplayContainer">
                          <label className="postMainName" style={{ fontWeight: "bold" }}>
                            {userDetails?.name &&
                              (userDetails?.name?.replace(/\s\(\d+\)$/, "")
                                .length > 20
                                ? userDetails?.name
                                  .replace(/\s\(\d+\)$/, "")
                                  .substring(0, 20) + "..."
                                : userDetails?.name.replace(/\s\(\d+\)$/, ""))}
                          </label>
                          <label>Active</label>
                        </div>
                      </div>}
                      {showArchivedList && <div
                        className="chatListHeader"
                      // onClick={() => {
                      //   setMyProfileDetails(true);
                      // }}
                      >
                        <div onClick={() => { setShowArchived(false); setSelectedChatsArr([]); setSelectChat(false) }} style={{ marginLeft: 25, marginRight: 15, marginTop: 8, marginBottom: 5 }}>
                          <img
                            style={{ width: 25, height: 35 }}
                            src={ChatGoBack}
                            alt="goBack"
                          />
                        </div>
                        <div className="allChatListDisplayContainer">
                          <label className="postMainName">
                            {"Archived (" + archivedList.length + ")"}
                          </label>
                        </div>
                      </div>}

                      {!showArchivedList && <div className="selfChatHeaderActionsContainer">
                        <div onClick={() => {
                          setShowNewConversat(true)
                        }} style={{ marginRight: 10 }}>
                          <img src={NewChat} />
                        </div>
                        <div onClick={() => { setShowGlobalSearch(true) }} style={{ marginRight: 10 }}>
                          <img style={{ height: 18, width: 18 }} src={SearchIcon} />
                        </div>
                        <div className="newChatAndGroupContainer">
                          <IconButton
                            id={id}
                            aria-describedby={id}
                            onClick={(event) => handleMoreClick(event)}
                            title="more"
                          >
                            <svg
                              width="4"
                              height="18"
                              viewBox="0 0 4 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="1.72626"
                                cy="2.4375"
                                r="1.5"
                                fill="#1296B0"
                              />
                              <circle
                                cx="1.72626"
                                cy="9.4375"
                                r="1.5"
                                fill="#1296B0"
                              />
                              <circle
                                cx="1.72626"
                                cy="16.4375"
                                r="1.5"
                                fill="#1296B0"
                              />
                            </svg>
                          </IconButton>
                        </div>
                      </div>}

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div
                          className="dropdowncol"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                          }}
                        >
                          <div
                            className="taskCont"
                            onClick={() =>
                              handleOptionSelect("Company Broadcast")
                            }
                          >
                            Company Broadcast{" "}
                          </div>
                          <div
                            className="taskCont"
                            onClick={() => handleOptionSelect("New Group")}
                          >
                            New Group
                          </div>
                          {/* <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("Community")}
                        >
                          Community
                        </div> */}
                          <div
                            className="taskCont"
                            onClick={() => handleOptionSelect("starredMessage")}
                          >
                            Starred Message
                          </div>
                          <div
                            className="taskCont"
                            onClick={() => handleOptionSelect("blocklist")}
                          >
                            Block List
                          </div>
                          <div
                            className="taskCont"
                            onClick={() => handleOptionSelect("Settings")}
                          >
                            Settings
                          </div>
                        </div>
                      </Popover>
                      <Popover
                        id={_id}
                        open={rightOpen}
                        anchorEl={anchor}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div
                          className="dropdowncol"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                          }}
                        >
                          <div
                            className="taskCont"
                            onClick={() => {
                              setEmpProfileDetails(true)
                              setSearchMessage(false); setGroupInfo(false); setMediaInfo(false); setMessageInfo(false)
                              handleClose()
                            }}
                          >
                            View contact
                          </div>
                          <div
                            className="taskCont"
                            onClick={(e) => {
                              setGroupInfo(false);
                              setEmpProfileDetails(false);
                              setMessageInfo(false);
                              setMediaInfo(true);
                              setSearchMessage(false);
                              e.stopPropagation();
                              handleClose()
                            }}
                          >
                            Media, links and docs
                          </div>
                          <div
                            className="taskCont"
                            onClick={() => {
                              confirmAlert({
                                title: "Clear this chat ?",
                                message: "Messages will permanently deleted from this chat",
                                buttons: [
                                  {
                                    label: "Cancel",
                                    onClick: () => { },
                                  },
                                  {
                                    label: "Clear",
                                    onClick: () => {
                                      handleClose()
                                      newSocket.emit("updateMyConversation", ({ "conversationId": [activeChat._id], "reqType": "clearConversation" }), (res) => {
                                        refereshConversation(newSocket)
                                        dispatch(
                                          showToast({ text: "Request successfully executed", severity: "success" })
                                        );
                                      })

                                    },
                                  },
                                ],
                                overlayClassName: "customConfirmAlertContainer",
                              });
                            }}
                          >
                            Clear Chat
                          </div>
                          <div
                            className="taskCont"
                            onClick={() => {
                              setAnchor(false);
                              handleClose()

                              if (activeChat.isBlock) {
                                newSocket.emit("updateMyConversation", ({ "conversationId": [activeChat._id], "reqType": "unblock" }), (res) => {
                                  refereshConversation(newSocket, "block")
                                  dispatch(
                                    showToast({ text: "Request successfully executed", severity: "success" })
                                  );
                                })
                              } else {
                                confirmAlert({
                                  title: "Block User?",
                                  message: "Blocked contacts will no longer be able to call you or send you messages.",
                                  buttons: [
                                    {
                                      label: "Cancel",
                                      onClick: () => { },
                                    },
                                    {
                                      label: "Block",
                                      onClick: () => {
                                        newSocket.emit("updateMyConversation", ({ "conversationId": [activeChat._id], "reqType": "block" }), (res) => {
                                          refereshConversation(newSocket, "unblock")
                                          dispatch(
                                            showToast({ text: "Request successfully executed", severity: "success" })
                                          );
                                        })
                                      },
                                    },
                                  ],
                                  overlayClassName: "customConfirmAlertContainer",
                                });
                              }

                            }}
                          >
                            {activeChat.isBlock ? "Unblock" : "Block"}
                          </div>
                        </div>
                      </Popover>
                    </div>
                  }

                </div>
                {
                  showArchivedList && !search &&
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <div onClick={() => { setSelectChat(!selectChat); setSelectedChatsArr([]) }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                      <img src={selectChat ? ChatSelectedCheck : ChatUnselectedCheck} alt="Select chat" />
                      <div style={{ marginLeft: 15, fontSize: 14, fontWeight: "500" }}>{"Select"} </div>
                    </div>
                    {selectChat && selectedChatsArr.length > 0 && <div onClick={() => { setChatOptionOpen(true) }}>
                      <img
                        style={{ cursor: "pointer", display: "block", marginRight: 30 }}
                        src={DropdownArrowChatMesssage}
                        alt=""
                      />
                    </div>}
                  </div>
                }
                {showArchivedList &&
                  <OutsideClickHandler
                    onOutsideClick={(id) =>
                      setChatOptionOpen(false)
                    }
                  >
                    <div
                      className="chatActionsPopup"
                      style={
                        isChatOptionOpen ? {
                          display: "block",
                          position: "absolute",
                          zIndex: "100",
                          marginLeft: "130px",
                          marginTop: "-40px",
                          color: "black",
                        }
                          : { display: "none" }
                      }
                    >
                      <div style={{ borderRadius: "6px 6px 0px 0px" }} onClick={(e) => { e.stopPropagation(); handleConversationAction(selectedChatsArr, "unarchived"); setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false) }}>{"Unarchive"}</div>
                      <div onClick={(e) => {
                        e.stopPropagation();
                        let arr = chatList.filter(val => val.type == "Group" || val.type == "Broadcast");
                        let a = false;
                        for (let i = 0; i < arr.length; i++) {
                          if (selectedChatsArr.includes(arr[i]._id)) {
                            a = true;
                            break;
                          };
                        }
                        if (a) {
                          confirmAlert({
                            title: "Deleting Groups?",
                            message: "You cannot delete groups and your braodcasts through this action, unselect all groups and broadcasts",
                            buttons: [
                              {
                                label: "Cancel",
                                onClick: () => { },
                              },
                              {
                                label: "Ok",
                                onClick: () => { },
                              },
                            ],
                            overlayClassName: "customConfirmAlertContainer",
                          });
                        } else {
                          setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false);
                          handleConversationAction(selectedChatsArr, "deleteConversation");
                        }
                      }}>Delete Chat</div>
                      <div onClick={(e) => { e.stopPropagation(); handleConversationAction(selectedChatsArr, "read"); setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false) }}>{"Mark as Read"}</div>
                      <div onClick={(e) => { e.stopPropagation(); handleConversationAction(selectedChatsArr, "unread"); setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false) }}>{"Mark as Unread"}</div>
                      <div style={{ borderRadius: "0px 0px 6px 6px" }} onClick={(e) => {
                        e.stopPropagation();
                        confirmAlert({
                          title: "Block Users?",
                          message:
                            "Blocked contacts will no longer be able to call you or send you messages.",
                          buttons: [
                            {
                              label: "Cancel",
                              onClick: () => { },
                            },
                            {
                              label: "Block",
                              onClick: () => {
                                setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false);
                                handleConversationAction(selectedChatsArr, "block");
                              },
                            },
                          ],
                          overlayClassName: "customConfirmAlertContainer",
                        });

                      }}>{"Block"}</div>
                    </div>
                  </OutsideClickHandler>}
                {showArchivedList && !search && <div style={{ height: "calc(100% - 90px)", width: "100%" }}>
                  <div className="chatListSearchContainer"></div>
                  <div className="chatListContainer" style={{ overflow: "auto", height: "73%" }}>
                    {/* Show Chat Contact List Here */}
                    <ContactList
                      selectChat={selectChat}
                      selectedChatsArr={selectedChatsArr}
                      setSelectedChatsArr={setSelectedChatsArr}
                      LoggedInUser={LoggedInUser}
                      chatList={archivedList}
                      showStatus={showStatus}
                      activeChat={activeChat}
                      SetActiveChat={(a) => {
                        SetActiveChat(a);
                      }}
                      handleContactClick={handleContactClick}
                      handleConversationAction={handleConversationAction}
                    />
                  </div>
                </div>}

                {search ? <SearchPanel /> : null}
                {!showArchivedList && !search ? <TabbedInterface
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                /> : null}
                {activeTab === "chat" && !search && <div style={{ width: "100%", marginTop: -10, height: "8%", width: "100%" }}>
                  {activeTab === "chat" && !showArchivedList && <div style={{ margin: 10, display: "flex", flexDirection: "row", overflowX: "auto", scrollbarWidth: "none", height: "50%" }}>
                    <div onPointerEnter={() => { setActiveHoverFilter("All") }} onPointerLeave={() => { setActiveHoverFilter(null) }} className="filterTabs" onClick={() => setActiveFilter("All")} style={{ backgroundColor: activeFilter === "All" || activeHoverFilter === "All" ? "rgba(216, 240, 242, 1)" : "rgba(238, 238, 238, 1)", color: activeFilter === "All" || activeHoverFilter === "All" ? "rgba(18, 150, 176, 1)" : "rgba(161, 161, 161, 1)" }} >
                      All
                    </div>
                    <div onPointerEnter={() => { setActiveHoverFilter("Unread") }} onPointerLeave={() => { setActiveHoverFilter(null) }} className="filterTabs" onClick={() => setActiveFilter("Unread")} style={{ backgroundColor: activeFilter === "Unread" || activeHoverFilter === "Unread" ? "rgba(216, 240, 242, 1)" : "rgba(238, 238, 238, 1)", color: activeFilter === "Unread" || activeHoverFilter === "Unread" ? "rgba(18, 150, 176, 1)" : "rgba(161, 161, 161, 1)" }}>
                      Unread
                    </div>
                    <div onPointerEnter={() => { setActiveHoverFilter("Favourites") }} onPointerLeave={() => { setActiveHoverFilter(null) }} className="filterTabs" onClick={() => setActiveFilter("Favourites")} style={{ backgroundColor: activeFilter === "Favourites" || activeHoverFilter === "Favourites" ? "rgba(216, 240, 242, 1)" : "rgba(238, 238, 238, 1)", color: activeFilter === "Favourites" || activeHoverFilter === "Favourites" ? "rgba(18, 150, 176, 1)" : "rgba(161, 161, 161, 1)" }}>
                      Favourites
                    </div>
                    <div onPointerEnter={() => { setActiveHoverFilter("Pin") }} onPointerLeave={() => { setActiveHoverFilter(null) }} className="filterTabs" onClick={() => setActiveFilter("Pin")} style={{ backgroundColor: activeFilter === "Pin" || activeHoverFilter === "Pin" ? "rgba(216, 240, 242, 1)" : "rgba(238, 238, 238, 1)", color: activeFilter === "Pin" || activeHoverFilter === "Pin" ? "rgba(18, 150, 176, 1)" : "rgba(161, 161, 161, 1)" }}>
                      Pin
                    </div>
                    <div onPointerEnter={() => { setActiveHoverFilter("Groups") }} onPointerLeave={() => { setActiveHoverFilter(null) }} className="filterTabs" onClick={() => setActiveFilter("Groups")} style={{ backgroundColor: activeFilter === "Groups" || activeHoverFilter === "Groups" ? "rgba(216, 240, 242, 1)" : "rgba(238, 238, 238, 1)", color: activeFilter === "Groups" || activeHoverFilter === "Groups" ? "rgba(18, 150, 176, 1)" : "rgba(161, 161, 161, 1)" }}>
                      Groups
                    </div>
                    <div onPointerEnter={() => { setActiveHoverFilter("Broadcasts") }} onPointerLeave={() => { setActiveHoverFilter(null) }} className="filterTabs" onClick={() => setActiveFilter("Broadcasts")} style={{ backgroundColor: activeFilter === "Broadcasts" || activeHoverFilter === "Broadcasts" ? "rgba(216, 240, 242, 1)" : "rgba(238, 238, 238, 1)", color: activeFilter === "Broadcasts" || activeHoverFilter === "Broadcasts" ? "rgba(18, 150, 176, 1)" : "rgba(161, 161, 161, 1)" }}>
                      Broadcasts
                    </div>
                  </div>}
                </div>}
                {activeTab === "chat" && !search && !showArchivedList && (
                  <div style={{ height: "calc(100% - 90px)", width: "100%" }}>
                    <div className="chatListSearchContainer"></div>
                    <div
                      className="mySelfChatContainer"
                      onClick={() => {
                        setMySelfChat(true);
                        getAllSelfMessages()
                        SetActiveChat({
                          _id: 3,
                          personal: true,
                          createdByContactId: 9,
                          createdOn: "2024-09-26T10:11:55Z",
                          icon: userDetails.image,
                          isArchived: false,
                          isBlock: false,
                          isConversationDeleted: false,
                          isDataActive: true,
                          isDeleted: false,
                          isFavorite: false,
                          isPin: true,
                          isRead: true,
                          lastUpdatedOn: "2024-09-26T10:11:55Z",
                          participateMembers: [{
                            ...userDetails,
                            contactId: userDetails._id,
                            contactName: userDetails.name,
                            department: userDetails.department,
                            designation: userDetails.designation,
                            dob: "",
                            doj: "2023-01-03T00:00:00",
                            dom: "",
                            dow: "2023-01-03T00:00:00",
                            isAdmin: false,
                            onlineStatus: false,
                            personalNumber: userDetails.personalNumber,
                            userId: 12
                          }],
                          recentMessage: [{}],
                          title: userDetails.name,
                          type: "Contact",
                          unReadMsgCount: 0
                        }
                        )
                      }}
                    >
                      <div
                        className="chatListContentContainerActive mySelfChatBox active"
                        style={{
                          backgroundColor: "#F0F0FF",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div className="activeImage">
                            {userDetails.image != "" ? <img
                              src={userDetails.image}
                              alt="Profile"
                            />
                              :
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 40, width: 40, borderRadius: 25, backgroundColor: "rgba(216, 240, 242, 1)", color: "rgba(18, 150, 176, 1)" }}>
                                {userDetails.name
                                  ?.split(" ")
                                  ?.slice(0, 2)
                                  ?.map((item) => {
                                    return item?.charAt(0)?.toUpperCase();
                                  })
                                  .join("")
                                }
                              </div>}

                            <div className={showStatus(userSettings.dndStatus ? 2 : userSettings.busyStatus ? 3 : 1)}></div>
                          </div>
                          <div className="allChatListDisplayContainer">
                            <label
                              // className="chatListName"
                              style={{ color: "#8A88ED", fontSize: 14, fontWeight: "700" }}
                            >
                              My Self
                            </label>
                            <label style={{ fontSize: 12, color: "#a1a1a1" }}>
                              Hello,{" "}
                              {userDetails.name
                                ?.replace(/\s\(\d+\)$/, "")
                                ?.split(" ")[0]}{" "}
                              how are you
                            </label>
                          </div>
                        </div>
                        <div
                          style={{ color: "#8A88ED", paddingRight: " 14px" }}
                        >
                          {">>"}
                        </div>
                      </div>
                    </div>
                    {archivedList.length > 0 ? <div onClick={() => { setShowArchived(true) }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 15, marginTop: 10, marginBottom: 10 }}>
                      <img src={Archive} alt="archive chat" />
                      <div style={{ marginLeft: 15, fontSize: 15, fontWeight: "900" }}>{"Archived (" + archivedList.length + ")"} </div>
                    </div> : null}
                    {archivedList.length ? <div style={{ width: "100%", height: 1, backgroundColor: "rgba(216, 240, 242, 1)" }}>
                    </div> : null}
                    {getFilteredList(chatList).length > 0 ? <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                      <div onClick={() => { setSelectChat(!selectChat); setSelectedChatsArr([]) }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                        <img src={selectChat ? ChatSelectedCheck : ChatUnselectedCheck} alt="Select chat" />
                        <div style={{ marginLeft: 15, fontSize: 14, fontWeight: "500" }}>{"Select"} </div>
                      </div>
                      {selectChat && selectedChatsArr.length > 0 && <div onClick={() => { setChatOptionOpen(true) }}>
                        <img
                          style={{ cursor: "pointer", display: "block", marginRight: 30 }}
                          src={DropdownArrowChatMesssage}
                          alt=""
                        />
                      </div>}
                    </div> : null}
                    <OutsideClickHandler
                      onOutsideClick={(id) =>
                        setChatOptionOpen(false)
                      }
                    >
                      <div
                        className="chatActionsPopup"
                        style={
                          isChatOptionOpen ? {
                            display: "block",
                            position: "absolute",
                            zIndex: "100",
                            marginLeft: "200px",
                            marginTop: "-40px",
                            color: "black",
                          }
                            : { display: "none" }
                        }
                      >
                        <div style={{ borderRadius: "6px 6px 0px 0px" }} onClick={(e) => { e.stopPropagation(); handleConversationAction(selectedChatsArr, "archived"); setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false) }}>{"Archive"}</div>
                        <div onClick={(e) => {
                          e.stopPropagation();
                          let arr = chatList.filter(val => val.type == "Group" || val.type == "Broadcast");
                          let a = false;
                          for (let i = 0; i < arr.length; i++) {
                            if (selectedChatsArr.includes(arr[i]._id)) {
                              a = true;
                              break;
                            };
                          }
                          if (a) {
                            confirmAlert({
                              title: "Deleting Groups?",
                              message: "You cannot delete groups and your braodcasts through this action, unselect all groups and broadcasts",
                              buttons: [
                                {
                                  label: "Cancel",
                                  onClick: () => { },
                                },
                                {
                                  label: "Ok",
                                  onClick: () => { },
                                },
                              ],
                              overlayClassName: "customConfirmAlertContainer",
                            });
                          } else {
                            setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false);
                            handleConversationAction(selectedChatsArr, "deleteConversation");
                          }
                        }}>Delete Chat</div>
                        <div onClick={(e) => {
                          e.stopPropagation();
                          let arr = chatList.filter(val => val.isPin == true);
                          let a = 0;
                          for (let i = 0; i < arr.length; i++) {
                            if (selectedChatsArr.includes(arr[i]._id)) a++;
                          }
                          if ((arr.length + selectedChatsArr.length - a) > 4) {
                            confirmAlert({
                              title: "Pin User?",
                              message: "You cannot pin more than 5 users!",
                              buttons: [
                                {
                                  label: "Cancel",
                                  onClick: () => { },
                                },
                                {
                                  label: "Ok",
                                  onClick: () => {
                                  },
                                },
                              ],
                              overlayClassName: "customConfirmAlertContainer",
                            });
                          } else {
                            setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false);
                            handleConversationAction(selectedChatsArr, "pin");
                          }
                        }}>{"Pin Chat"}</div>
                        <div onClick={(e) => {
                          e.stopPropagation();
                          setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false);
                          handleConversationAction(selectedChatsArr, "unpin");
                        }}>{"Unpin Chat"}</div>
                        <div onClick={(e) => { e.stopPropagation(); handleConversationAction(selectedChatsArr, "read"); setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false) }}>{"Mark as Read"}</div>
                        <div onClick={(e) => { e.stopPropagation(); handleConversationAction(selectedChatsArr, "unread"); setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false) }}>{"Mark as Unread"}</div>
                        <div onClick={(e) => { e.stopPropagation(); handleConversationAction(selectedChatsArr, "favorite"); setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false) }}>{"Add to favourites"}</div>
                        <div onClick={(e) => { e.stopPropagation(); handleConversationAction(selectedChatsArr, "unfavorite"); setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false) }}>{"Remove from favourites"}</div>
                        <div style={{ borderRadius: "0px 0px 6px 6px" }} onClick={(e) => {
                          e.stopPropagation();
                          confirmAlert({
                            title: "Block Users?",
                            message:
                              "Blocked contacts will no longer be able to call you or send you messages.",
                            buttons: [
                              {
                                label: "Cancel",
                                onClick: () => { },
                              },
                              {
                                label: "Block",
                                onClick: () => {
                                  setSelectedChatsArr([]); setSelectChat(false); setChatOptionOpen(false);
                                  handleConversationAction(selectedChatsArr, "block");
                                },
                              },
                            ],
                            overlayClassName: "customConfirmAlertContainer",
                          });

                        }}>{"Block"}</div>
                      </div>
                    </OutsideClickHandler>
                    <div className="chatListContainer" style={{ overflow: "auto", height: "73%" }}>
                      {/* Show Chat Contact List Here */}
                      {getFilteredList(chatList).length ? <ContactList
                        selectChat={selectChat}
                        selectedChatsArr={selectedChatsArr}
                        setSelectedChatsArr={setSelectedChatsArr}
                        activeChat={activeChat}
                        LoggedInUser={LoggedInUser}
                        chatList={getFilteredList(chatList)}
                        showStatus={showStatus}
                        SetActiveChat={(a) => { console.log("=============> set active chat 15", a); SetActiveChat({ ...a }) }}
                        handleContactClick={handleContactClick}
                        handleConversationAction={handleConversationAction}
                      /> :
                        <NoListView />}

                    </div>
                  </div>
                )}
                {activeTab === "updates" && (
                  <div style={{ width: "100%" }}>
                    <StatusUpdate setActiveStory={(val) => { setActiveStory(val) }} myStory={allMyStories} otherStory={othersStories} showMyStory={() => { setShowStory(true) }} uploadStory={() => { document.getElementById("fileInput").click() }} />
                  </div>
                )}
                {activeTab === "calls" && (
                  <div className="tab">
                    <h2>Recent Calls</h2>
                    <ul>
                      <li>Call 1</li>
                      <li>Call 2</li>
                      <li>Call 3</li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          {Object.keys(activeChat)?.length > 0 ? (
            <div className="chatConversationContainer">
              <>
                <div
                  className="chatHeaderContainer"
                  style={{ position: "relative" }}
                >
                  <div
                    className="newChatAndGroupContainer"
                    style={{ position: "absolute", top: "14px", right: "5px" }}
                  >
                    {!activeChat.personal && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                      <img style={{ marginRight: 10 }} onClick={() => { setShowCallScreen(true); setAudioCall(false) }} src={VideoCallIcon} />
                      <img style={{ marginRight: 10 }} onClick={() => { setShowCallScreen(true); setAudioCall(true) }} src={AudioCallIcon} />
                      <img onClick={() => { setSearchMessage(true); setGroupInfo(false); setEmpProfileDetails(false); setMediaInfo(false); setMessageInfo(false) }} src={SearchMessageIcon} style={{ marginRight: 10, height: 20, width: 20 }} />
                      <IconButton
                        id={id}
                        aria-describedby={id}
                        onClick={(event) => handleRightMoreClick(event)}
                        title="more"
                      >

                        <svg
                          width="4"
                          height="18"
                          viewBox="0 0 4 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="1.72626"
                            cy="2.4375"
                            r="1.5"
                            fill="#1296B0"
                          />
                          <circle
                            cx="1.72626"
                            cy="9.4375"
                            r="1.5"
                            fill="#1296B0"
                          />
                          <circle
                            cx="1.72626"
                            cy="16.4375"
                            r="1.5"
                            fill="#1296B0"
                          />
                        </svg>
                      </IconButton>
                    </div>}

                  </div>
                  <div className="openedChatHeader">
                    <div
                      className="selfChatHeader"
                      onClick={() => {
                        setMediaInfo(false);
                        setMessageInfo(false);
                        if (activeChat?.type === undefined || activeChat?.type === "Contact") {
                          if (activeChat.personal) setMyProfileDetails(true)
                          else {
                            setEmpProfileDetails(true)
                            setSearchMessage(false); setGroupInfo(false); setMediaInfo(false); setMessageInfo(false)
                          }
                        } else {
                          setGroupInfo(true);
                          setSearchMessage(false); setEmpProfileDetails(false); setMediaInfo(false); setMessageInfo(false)
                        }
                      }}
                    >
                      <div className="activeImage">
                        <div className={`activeImageContainer ${!activeChat.icon ? "customProfilePic" : ""}`}>
                          {activeChat.type == "Broadcast" ?
                            <img src={BroadCastIcon} style={{ height: "100%", width: "100%", borderRadius: 200 }} />
                            :
                            activeChat?.icon && activeChat?.icon != "" ?
                              <img src={activeChat.icon} style={{ height: "100%", width: "100%", borderRadius: 200 }} /> :
                              <div>{
                                activeChat?.title
                                  ?.split(" ")
                                  ?.slice(0, 2)
                                  ?.map((item) => {
                                    return item?.charAt(0)?.toUpperCase();
                                  })
                                  .join("")}
                              </div>
                          }
                        </div>
                        <div
                        // className={showStatus(activeChat.onlineStatus)}
                        ></div>
                      </div>
                      <div
                        className="allChatListDisplayContainer"
                        style={{ justifyContent: "start" }}
                      >
                        <label className="postMainName">
                          {activeChat?.title}
                        </label>
                        {activeChat?.type === "Contact" && (
                          <label>
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 1 && "Active"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 2 && "Busy"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 3 && "Away"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 4 && "Offline"}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", height: attachmentSelected ? "100%" : "calc(100% - 65px)", display: "flex", flexDirection: "column", }}>
                  {attachmentSelected ? (
                    <DocumentPreviewContainer
                      refresh={(socket) => { handleContactClick(activeChat._id); refereshConversation(socket) }}
                      getAllSelfMessages={() => getAllSelfMessages()}
                      handleDocsSelect={handleDocsSelect}
                      selectedFiles={selectedDocs}
                      setSelectedDocs={setSelectedDocs}
                      selectedFileType={selectedFileType}
                      newSocket={newSocket}
                      activeChat={activeChat}
                      close={() => { setSelectedDocs([]); setAttachmentSelected(false); setSelectedFileType(null) }}
                    />
                  ) : (
                    <>
                      <div
                        ref={scrollRef}
                        className="imagePreviewContainer"
                        style={{
                          flex: " 1",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0px",
                        }}
                      >
                        <ChatInterface
                          handleStoryFromChat={handleStoryFromChat}
                          handleReplyPrivately={handleReplyPrivately}
                          setSelectedMessage={(message) => { setSearchMessage(false); setGroupInfo(false); setEmpProfileDetails(false); setSelectedMessage(message); setMediaInfo(false); setMessageInfo(true) }}
                          activeChat={activeChat}
                          deleteMessage={deleteMessage}
                          togglePopup={togglePopup}
                          replyData={replyData}
                          forwardData={forwardData}
                          setForwardData={(e) => { setForwardData(e) }}
                          LoggedInUser={LoggedInUser}
                          setReplyData={setReplyData}
                          SetActiveChat={(a) => { SetActiveChat({ ...a }); console.log("=============> set active chat 16"); }}
                          handleContactClick={handleContactClick}
                          ViewFullscreen={ViewFullscreen}
                          bookMarkMessage={bookMarkMessage}
                          handleReschedule={handleReschedule}
                          activeChatId={activeChat?.id}
                          scrollToId={scrollToId}
                          viewGroup={handleViewGroup}
                          newSocket={newSocket}
                        />
                      </div>
                      {forwardData?.isActive ? (
                        <ForwardInputChat
                          LoggedInUser={LoggedInUser}
                          deleteMessage={deleteMessage}
                          isOpen={isOpen}
                          togglePopup={togglePopup}
                          forwardData={forwardData}
                          setForwardData={setForwardData}
                        />
                      ) : (
                        <InputSectionChat
                          setScheduleMessageValue={setScheduleMessageValue}
                          setSchedule={setSchedule}
                          setReplyData={setReplyData}
                          sendAudioMessage={sendAudioMessage}
                          LoggedInUser={LoggedInUser}
                          handleDocsSelect={handleDocsSelect}
                          replyData={replyData}
                          handleAttachmentClick={handleAttachmentClick}
                          showOptions={showOptions}
                          activeChat={activeChat}
                          inputFile={inputFile}
                          setShowOptions={setShowOptions}
                          sendMessageText={sendMessageText}
                          ScheduleMessageChat={ScheduleMessageChat}
                          SendImageChat={SendImageChat}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            </div>
          ) : (
            <ChatInterfaceIntro
              userDetails={userDetails}
              LoggedInUser={LoggedInUser}
              BeforeActiveFirstImg={BeforeActiveFirstImg}
              BeforeActiveSecondImg={BeforeActiveSecondImg}
              BeforeActiveThirdImg={BeforeActiveThirdImg}
            />
          )}
          {empProfileDetails && (
            <EmpProfiledetails
              createNewGroup={(userToInclude) => {
                toggleNewGroup(userToInclude)
              }}
              toggleMedia={() => { setEmpProfileDetails(false); setMediaInfo(true); setSearchMessage(false); setGroupInfo(false); setMessageInfo(false) }}
              chatList={chatList}
              setEmpProfileDetails={setEmpProfileDetails}
              // setActiveAttachment={setActiveAttachment}
              socket={newSocket}
              handleConversationAction={handleConversationAction}
              activeChat={activeChat}
            />
          )}
          {messageInfo &&
            <MessageInfo
              activeChat={activeChat}
              setMessageInfo={() => { setMessageInfo(false); setSelectedMessage(null) }}
              newSocket={newSocket}
              selectedMessage={selectedMessage}
            />
          }
          {mediaInfo &&
            <MediaLinkDocs
              activeChat={activeChat}
              setMessageInfo={() => { setMediaInfo(false); }}
              newSocket={newSocket}
              selectedMessage={selectedMessage}
            />
          }
          {groupInfo && (
            <GroupInfo
              setShowGroupInvite={() => { setShowGroupInvite(true); setMyProfileDetails(false); setSearch(false) }}
              toggleMedia={() => { setGroupInfo(false); setMediaInfo(true); setSearchMessage(false); setEmpProfileDetails(false); setMessageInfo(false) }}
              refereshConversation={refereshConversation}
              handleConversationAction={handleConversationAction}
              chatList={chatList}
              activeChat={activeChat}
              setGroupInfo={setGroupInfo}
              socket={newSocket}
              navigateTo={handleNavigationFromOtherChat}
            // setActiveAttachment={setActiveAttachment}
            />
          )}
          {searchMessage && (
            <SearchMessge
              activeChat={activeChat}
              setSearchMessage={() => { setSearchMessage(false); }}
              newSocket={newSocket}
            />
          )}
        </div>
      </div>

      {isOpen && (
        <Popup
          noPadding={true}
          contentClassName="forwardPopup-chat"
          popupHeading={
            viewAll
              ? "(" + `${forwardData?.userId?.length + forwardData?.conversationId?.length}` + ")"
              : "Forward message to"
          }
          needPrevious={viewAll}
          handlePrevious={() => setViewAll((prev) => !prev)}
          bodyClassName="overflowStop"
          createMyHead="createHeadForward"
          content={
            !viewAll ? (
              <ForwardPopup
                handleClose={togglePopup}
                LoggedInUser={LoggedInUser}
                contactsList={contactsList}
                chatList={forwardContactList}
                setForwardData={setForwardData}
                forwardMessageAction={forwardMessageAction}
                forwardData={forwardData}
                setViewAll={() => setViewAll(true)}
                viewAll={viewAll}
              />
            ) : (
              <>
                <ForwardViewAll
                  LoggedInUser={LoggedInUser}
                  contactsList={contactsList}
                  chatList={forwardContactList}
                  forwardData={forwardData}
                  setViewAll={setViewAll}
                  viewAll={viewAll}
                  showStatus={showStatus}
                />
              </>
            )
          }
          handleClose={togglePopup}
        />
      )}

      {showNewConver && (
        <Popup
          // firstClassName="newChatPopup1"
          // secondClassName="newChatPopup2"
          // bodyClassName="newChatPopupBodyContainer"
          color={"#1296B0"}
          needPrevious={false}
          noPadding={true}
          popupHeading={"Select Your Contact"}
          content={
            <>
              <NewConversation
                setNewGroup={setNewGroup}
                selectedGroupMember={selectedGroupMember}
                setSelectedGroupMember={(e) => setSelectedGroupMember([...e])}
                handleClose={(e) => { handleClose(e); }}
                viewSelected={setShowNewGroupMember}
                createNewConvo={(selected) => {
                  setShowNewConversat(false);
                  startConversation({ "type": "Contact", "title": "", "icon": "", "participateMembers": [{ "contactId": userDetails._id, "personalNumber": userDetails.personalNumber }, { "contactId": selected._id, "personalNumber": selected.personalNumber }], "createdByContactId": userDetails._id })
                }}
              />
            </>
          }
          handleClose={() => { setShowNewConversat(false) }}
        />
      )}
      {newGroup && (
        <Popup
          // firstClassName="newChatPopup1"
          // secondClassName="newChatPopup2"
          // bodyClassName="newChatPopupBodyContainer"
          color={"#1296B0"}
          needPrevious={showGroupMembers || createNewGroup ? true : false}
          handlePrevious={() => {
            setShowNewGroupMember(false);
            setCreateNewGroupMember(false);
          }}
          noPadding={true}
          popupHeading={showGroupMembers ? ("Selected (" + selectedGroupMember.length + ")") : "Add group participants"}
          content={
            <>
              {!showGroupMembers && !createNewGroup ? <NewGroup
                setNewGroup={setNewGroup}
                chatList={chatList}
                selectedGroupMember={selectedGroupMember}
                setSelectedGroupMember={(e) => setSelectedGroupMember([...e])}
                handleClose={(e) => { handleClose(e); }}
                viewSelected={setShowNewGroupMember}
                createNewGroup={() => setCreateNewGroupMember(true)}
              /> : null}
              {showGroupMembers ? <SelectedNewGroupMembers
                selectedGroupMember={selectedGroupMember}
              /> : null}
              {createNewGroup ? <CreateNewGroup
                createGroupAPI={(data) => {
                  startConversation(data);
                  toggleNewGroup()
                }}
                setSelectedGroupMember={(e) => setSelectedGroupMember([...e])}
                selectedGroupMember={selectedGroupMember}
              /> : null}

            </>
          }
          handleClose={toggleNewGroup}
        />
      )}
      {companyBroadcast && (
        <Popup
          popupHeading={showGroupMembers || createNewGroup ? "Select Contact (" + selectedGroupMember.length + ")" : "Select Contact"}
          contentClassName="newPad"
          color={"#1296B0"}
          needPrevious={showGroupMembers || createNewGroup ? true : false}
          handlePrevious={() => {
            setShowNewGroupMember(false);
            setCreateNewGroupMember(false);
          }}
          content={
            <>
              {!showGroupMembers && !createNewGroup ? <CompanyBroadcast
                selectedGroupMember={selectedGroupMember}
                setCompanyBroadcast={setCompanyBroadcast}
                viewSelected={setShowNewGroupMember}
                setSelectedGroupMember={(e) => setSelectedGroupMember([...e])}
                createNewGroup={() => setCreateNewGroupMember(true)}
                createBroadCast={(payload) => { startConversation(payload); setCompanyBroadcast(false) }}
              /> : null}
              {showGroupMembers ? <SelectedNewBrMembers
                selectedGroupMember={selectedGroupMember}
              /> : null}
              {createNewGroup ? <CreateNewBroadCast
                createGroupAPI={(data) => {
                  startConversation(data);
                  togglePop()
                }}
                setSelectedGroupMember={(e) => setSelectedGroupMember([...e])}
                selectedGroupMember={selectedGroupMember}
              /> : null}
            </>
          }

          handleClose={togglePop}
        />
      )}
      {mediaArray?.length > 0 && (
        <MediaViewerChat
          mediaArray={mediaArray}
          setMediaArray={setMediaArray}
          mediaCaption={mediaCaption}
          mediaIndex={mediaIndex}
          setMediaCaption={setMediaCaption}
          setMediaIndex={setMediaIndex}
        />
      )}
    </>
  );
};
