import React from "react";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";

function BankFinance({
  typeofAccountOptions,
  costCentreOptions,
  paymentModeOptions,
  yesNoOptions,
  employee,
}) {
  useEffect(() => {
    document.title = `PeopleSol - Bank Finance`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="formcustom threeColumn">
      {employee && (
        <>
          <div>
            <LabelCustom labelName={"Cost Centre"} />
            <SelectForm
              name="costCentre"
              placeholder="Select Cost Centre"
              options={costCentreOptions}
            />
          </div>
          <div>
            <LabelCustom labelName={"Payroll Employee?"} />
            <SelectForm
              name="isPayroll"
              placeholder="Select Payroll Employee?"
              options={yesNoOptions}
            />
          </div>
          <div>
            <LabelCustom labelName={"Payment Mode"} />
            <SelectForm
              name="paymentMode"
              placeholder="Select Payment Mode"
              options={paymentModeOptions}
            />
          </div>
        </>
      )}
      <div>
        <LabelCustom labelName={"Account Holder Name"} />
        <InputCustomFormik
          maxLength={150}
          name="accountHolderName"
          type={"text"}
          placeholder={"Enter Account Holder Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Account Number"} />
        <InputCustomFormik
          maxLength={15}
          name="bankAccountNumber"
          type={"text"}
          placeholder={"Enter Account Number"}
        />
      </div>
      <div>
        <LabelCustom labelName={"IFSC Code"} />
        <InputCustomFormik
          minLength={11}
          maxLength={11}
          name="ifsccode"
          type={"text"}
          placeholder={"Enter IFSC Code"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Bank Name"} />
        <InputCustomFormik
          maxLength={150}
          name="branchName"
          type={"text"}
          placeholder={"Enter Bank Name"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Type Of Account"} />
        <SelectForm
          name="typeofAccount"
          placeholder="Select Type Of Account"
          options={typeofAccountOptions}
        />
      </div>
    </div>
  );
}

export { BankFinance };
