import { format } from "date-fns";
import { useSelector } from "react-redux";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import starWhite from "../../../Assets/starWhite.svg";
import starYellow from "../../../Assets/starYellow.svg";
import DropdownArrowChatMesssageWhite from "../../../Assets/DropdownArrowChatMesssageWhite.svg";
import DoubleTick from "../../../Assets/DoubleTick.svg";
import DoubleTickGrey from "../../../Assets/DoubleTickGrey.svg";
import SingleTick from "../../../Assets/SingleTick.svg";
import Forward from "../../../Assets/Forward.svg";
import ForwardBlue from "../../../Assets/ForwardBlue.svg";
import PendingMessageIcon from "../../../Assets/PendingMessageIcon.svg"
import ChatSelectedCheck from "../../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../../Assets/ChatUnselectedCheck.svg";


import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import { useEffect, useState } from "react";
import { scrollFunc } from "../ChatInterface";
import signalRService from "../../../Services/signalRService";
import MediaTypeMessageComponent from "./MediaTypeMessageComponent";
import { useDispatch } from "react-redux";
export const ChatMessage = ({
  message,
  timestamp,
  setReplyData,
  LoggedInUser,
  setForwardData,
  forwardData,
  replyData,
  handleContactClick,
  handleReplyPrivately,
  deleteMessage,
  ViewFullscreen,
  activeChat,
  bookMarkMessage,
  setSelectedMessage,
  handleReschedule,
  viewGroup,
  newSocket,
  handleStoryFromChat,
}) => {
  // const [chatWidth, setChatWidth] = useState(0);
  // useEffect(() => {
  //   if (replyData?.replyId) {
  //     setForwardData({
  //       isActive: false,
  //       msgId: [],
  //       userId: [],
  //       conversationId: [],
  //     });
  //   }
  // }, [replyData?.replyId]);
  // let divWidth;
  // useEffect(() => {
  //   divWidth = document.getElementById(`messageContent${message?.id}`);
  // }, []);

  // useEffect(() => {
  //   const width = divWidth?.offsetWidth;
  //   setChatWidth(width);
  //   console.log(`messageContent${message?.id}`, width);
  // }, [divWidth]);
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);

  const userDetails = useSelector((state) => state.chat.userDetails);
  const chatList = useSelector((state) => state.chat.conversationList);
  const myContactId = userDetails._id;
  const personalNumber = userDetails.personalNumber;
  const messageClass = message?.personalNumber === personalNumber ? "sent" : "received";
  const isScheduled = new Date().valueOf() < new Date(message.time).valueOf();
  const getRepliedName = () => {
    let a = activeChat?.participateMembers?.filter((item) => item.personalNumber === message.replyMsgObj.personalNumber);
    if (a.length) {
      let name = a[0]?.contactName;
      if (userDetails.personalNumber === a[0].personalNumber) return "You";
      if (message?.replyMsgObj && message.replyMsgObj.conversationId != activeChat._id) {
        return chatList?.filter(item => item._id == message.replyMsgObj.conversationId)[0]?.title + " . " + name;
      } else return name;
    }
  }

  const getUserImage = () => {
    let a = activeChat.participateMembers.filter((item) => item.personalNumber === message.personalNumber)
    if (a.length) {
      return a[0].image
    }
  }


  function getDate(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    const tomorrow = new Date(today);

    // Set yesterday and tomorrow by adjusting days
    yesterday.setDate(today.getDate() - 1);
    tomorrow.setDate(today.getDate() + 1);

    // Remove time information for accurate comparison
    const isSameDay = (date1, date2) =>
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();

    if (isSameDay(inputDate, today)) {
      return "Today";
    } else if (isSameDay(inputDate, yesterday)) {
      return "Yesterday";
    } else if (isSameDay(inputDate, tomorrow)) {
      return "Tomorrow";
    } else {
      // Format as dd/mm/yyyy if not Today, Yesterday, or Tomorrow
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = inputDate.getFullYear();
      return `${day}/${month}/${year}`;
    }
  }

  function formatTime(dateString) {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If `hours` is 0, set it to 12
    const formattedMinutes = minutes.toString().padStart(2, '0'); // Add leading zero to minutes if needed

    return `${hours}:${formattedMinutes} ${ampm}`;
  }



  const ScheduledMessage = () => {
    { console.log("-------------------->", message) }
    return (
      <div
        className="message-content"
        style={
          {
            display: "flex",
            borderRadius: "6px 0px 6px 6px",
            position: "relative",
            flexDirection: "column",
            backgroundColor: "rgba(240, 240, 255, 1)",
            borderStyle: "solid",
            borderWidth: 0,
            borderTopWidth: 10,
            borderColor: "rgba(138, 136, 237, 1)",
            color: "rgba(138, 136, 237, 1)"
          }

        }
      >
        <div style={{ width: "100%" }}>
          {message.caption}
        </div>
        <div style={{ marginTop: 10, backgroundColor: "white", borderRadius: 8, padding: 10 }}>
          {getDate(message.time) + " > " + formatTime(message.time)}
        </div>
        <div style={{ marginTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
          <div onClick={() => { handleReschedule("rescheduled", message._id, message.time) }} style={{ padding: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 4, backgroundColor: "rgba(138, 136, 237, 1)", color: "white", marginRight: 10 }}>Reschedule</div>
          <div onClick={() => { handleReschedule("delete", message._id) }} style={{ padding: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 4, backgroundColor: "white", color: "rgba(138, 136, 237, 1)" }}>Cancel</div>
        </div>
      </div>
    )
  }

  return (
    <>
      {messageClass !== "sent" && message.deleteForMeIds.includes(personalNumber) ? null : <div
        style={
          message.type == 6 ? {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          } :
            messageClass === "sent"
              ? {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }
              : {
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }
        }
      >

        {forwardData?.isActive && forwardData?.msgId?.includes(message?._id) && message.type != 6 ? (
          <div onClick={(e) => {
            let data = forwardData;
            let a = data.msgId.filter((id) => id != message._id);
            data.msgId = a;
            setForwardData({ ...data });
          }}>
            <img src={ChatSelectedCheck} />
          </div>
        ) : forwardData?.isActive && message.type != 6 ? (
          <div onClick={(e) => {
            let data = forwardData;
            data.msgId.push(message?._id);
            setForwardData({ ...data })
          }}>
            <img src={ChatUnselectedCheck} />
          </div>
        ) : (
          <div></div>
        )}
        <div style={
          message.type == 6 ? {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          } :
            { width: "65%" }} className={`message ${messageClass}`}>
          {message.type !== 6 && <div className="proftime">
            <div className="profile-image">

              {/* Display profile image */}
              {getUserImage() != "" ? <img
                src={getUserImage()}
                alt="Profile"
              /> :
                <div style={{ backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ color: "#1296B0" }}>{activeChat.participateMembers.filter(item => item.personalNumber === message.personalNumber)[0]?.contactName?.split(" ")
                    ?.slice(0, 2)
                    ?.map((item) => {
                      return item?.charAt(0)?.toUpperCase();
                    })
                    .join("")}</div>
                </div>}
            </div>
            <div className="timestamp">
              {message?.time && format(new Date(message?.time), "hh:mm a")}
            </div>
          </div>}
          {message?.replyMsgObj &&
            message?.replyMsgObj?.type === 0 && !message?.deletedStatus && !message?.deleteForMeIds.includes(personalNumber) ? (
            <div
              className="message-content"
              style={
                messageClass === "sent"
                  ? {
                    display: "flex",
                    borderRadius: "6px 0px 6px 6px",
                    position: "relative",
                  }
                  : {
                    display: "flex",
                    borderRadius: "0px 6px 6px 6px",
                    position: "relative",
                  }
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={
                    messageClass === "sent"
                      ? {
                        backgroundColor: "#DFECEF",
                        color: "#1296B0",
                        borderRadius: "6px 0px 6px 6px",
                        display: "flex",
                        position: "relative",
                        padding: "10px",
                        marginBottom: "5px",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        width: "100%",
                      }
                      : {
                        backgroundColor: "#DFECEF",
                        color: "#1296B0",
                        borderRadius: "0px 6px 6px 6px",
                        display: "flex",
                        position: "relative",
                        padding: "10px",
                        marginBottom: "5px",
                        textAlign: "start",
                        justifyContent: "flex-end",
                        width: "100%",
                      }
                  }
                >
                  {" "}
                  <div
                    onClick={(e) => {
                      // scrollFunc(message?.replyMsgObj?._id);
                      // e.stopPropagation();
                    }}
                    style={
                      messageClass === "sent"
                        ? {
                          width: "calc(100% - 20px)",
                          cursor: "pointer",
                        }
                        : {
                          width: "calc(100% - 20px)",
                          cursor: "pointer",
                        }
                    }
                  >
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
                      <p style={{ fontSize: 10, fontWeight: "900", marginTop: -5, marginBottom: 5 }}>
                        {getRepliedName()}
                      </p>
                      <p
                        style={
                          message?.replyingToMessage?.caption?.length > 65
                            ? { width: "100%", overflowWrap: "break-word" }
                            : { width: "100%" }
                        }
                      >
                        {message?.replyMsgObj?.caption}
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={(e) => {
                      setPopupOpen(true)
                      e.stopPropagation();
                    }}
                    style={{ width: "15px", height: "10px", cursor: "pointer" }}
                  >

                    <svg
                      className="DropdownArrowChatDesign"
                      style={
                        popupOpen.isActive &&
                          (message?.Id ?? message?.id) === popupOpen.Id
                          ? { display: "block", top: "11px" }
                          : { top: "11px" }
                      }
                      width="13"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.4584 0.766602L6.38991 5.8351L1.32141 0.766602"
                        stroke="#1296B0"
                        stroke-width="1.25251"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  style={
                    message?.caption?.length > 55
                      ? {
                        width: "98%",
                        display: "flex",
                        flexDirection: "column",
                      }
                      : {
                        width: "98%",
                        display: "flex",
                      }
                  }
                >
                  <div
                    id={`messageContent${message?.id}`}
                    style={
                      message?.caption?.length > 65
                        ? { width: "100%", overflowWrap: "break-word" }
                        : { width: "100%" }
                    }
                  >
                    {message?.caption}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={
                        message?.caption?.length > 55
                          ? {
                            display: "flex",
                            textAlign: "end",
                            width: "50px",
                            alignItems: "flex-end",
                            justifyContent: "flex-end", // backgroundColor: "blueviolet",
                          }
                          : {
                            width: "50px",
                            textAlign: "end",
                          }
                      }
                    >
                      {message?.isBookmarked === true && (
                        <img
                          src={messageClass === "sent" ? starWhite : starYellow}
                          style={{ margin: "0px 5px 0px 5px" }}
                          alt="bookmark"
                        />
                      )}
                      {messageClass === "sent" ? <img src={message.msgStatus === 3 ? DoubleTick : DoubleTickGrey} alt="read" /> :
                        message.msgStatus === 100 && messageClass === "sent" ? <img src={PendingMessageIcon} /> :
                          messageClass === "sent" ? <img src={SingleTick} alt="unread" />
                            : null}
                    </div>
                  </div>
                </div>
              </div>

              <OutsideClickHandler
                key={message?._id}
                id={message?._id}
                onOutsideClick={(id) =>
                  setPopupOpen(false)
                }
              >
                <div
                  className="chatActionsPopup"
                  style={
                    popupOpen ? {
                      display: "block",
                      position: "absolute",
                      zIndex: "6",
                      right: "-50px",
                      top: "26px",
                      color: "black",
                    }
                      : { display: "none" }
                  }
                >
                  <div
                    style={{ borderRadius: "6px 6px 0px 0px" }}
                    onClick={(e) => {
                      setReplyData({
                        replyId: message._id,
                        replyContent: message?.caption,
                        data: message,
                      });
                      setPopupOpen(false);
                      e.stopPropagation();
                    }}
                  >
                    Reply
                  </div>
                  <div>Info</div>
                  <div
                    onClick={(e) => {
                      setForwardData({
                        isActive: true,
                        msgId: [message?._id],
                        userId: [],
                        conversationId: [],
                      });
                      setPopupOpen(false);
                      e.stopPropagation();
                    }}
                  >
                    Forward Message
                  </div>
                  {activeChat.type === "Group" && messageClass != "sent" && <div
                    onClick={(e) => {
                      handleReplyPrivately(message);
                      e.stopPropagation();
                    }}
                  >
                    Reply Privately
                  </div>}
                  <div
                    onClick={() => {
                      bookMarkMessage(message._id, myContactId, message.isBookmarked ? "unstar" : "star");
                      setPopupOpen(false);
                      setForwardData({
                        isActive: false,
                        msgId: [],
                        userId: [],
                        conversationId: [],
                      });
                    }}
                  >
                    {message.isBookmarked ? "Unstar" : "Star"}
                  </div>
                  <div
                    style={{ borderRadius: "0px 0px 6px 6px" }}
                    onClick={() => {
                      deleteMessage(
                        message?._id,
                        userDetails._id,
                        messageClass
                      );
                      setPopupOpen(false);
                    }}
                  >
                    Delete Message
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          ) :
            !message?.deletedStatus && !message?.deleteForMeIds.includes(personalNumber) && (message?.type > 0 ||
              (message?.type === 0 && message?.replyMsgObj?.type > 0)) ? (
              <MediaTypeMessageComponent
                setSelectedMessage={setSelectedMessage}
                messageType={message?.type}
                message={message}
                messageClass={messageClass}
                setPopupOpen={setPopupOpen}
                handleReplyPrivately={handleReplyPrivately}
                popupOpen={popupOpen}
                setReplyData={setReplyData}
                setForwardData={setForwardData}
                bookMarkMessage={bookMarkMessage}
                deleteMessage={deleteMessage}
                LoggedInUser={LoggedInUser}
                ViewFullscreen={ViewFullscreen}
                activeChat={activeChat}
                viewGroup={viewGroup}
                newSocket={newSocket}
                handleStoryFromChat={handleStoryFromChat}
              />
            ) :
              (<>
                {isScheduled ?
                  <ScheduledMessage />
                  :
                  <div
                    className="message-content"
                    style={
                      messageClass === "sent"
                        ? {
                          display: "flex",
                          borderRadius: "6px 0px 6px 6px",
                          position: "relative",
                          flexDirection: "column"
                        }
                        : {
                          display: "flex",
                          borderRadius: "0px 6px 6px 6px",
                          position: "relative",
                          flexDirection: "column"

                        }
                    }
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {message.forwardStatus &&
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <img src={messageClass === "sent" ? Forward : ForwardBlue} alt="forward" />
                          <p style={{ fontStyle: "italic", fontSize: 9, fontWeight: "400", marginLeft: 10 }}>Forwarded</p>
                        </div>
                      }
                      {!message?.deletedStatus && !message?.deleteForMeIds.includes(personalNumber) && !activeChat.personal ? <div
                        onClick={(e) => {
                          setPopupOpen(true);
                          e.stopPropagation();
                        }}
                        style={{ width: "15px", height: "10px", cursor: "pointer" }}
                      >
                        <img
                          className="DropdownArrowChatDesign"
                          style={
                            popupOpen
                              ? { display: "block" }
                              : {}
                          }
                          src={
                            messageClass === "sent"
                              ? DropdownArrowChatMesssageWhite
                              : DropdownArrowChatMesssage
                          }
                          alt=""
                        />
                      </div> : null}
                    </div>
                    <div
                      style={
                        message?.caption?.length > 55
                          ? {
                            width: "98%",
                            display: "flex",
                            flexDirection: "column",
                          }
                          : {
                            width: "98%",
                            display: "flex",
                          }
                      }
                    >
                      <p
                        id={`messageContent${message?.id}`}
                        style={
                          message?.deletedStatus || message?.deleteForMeIds.includes(personalNumber) ? {
                            width: "100%",
                            fontStyle: "italic",
                            color: "lightGrey",

                          } :
                            message?.caption?.length > 65
                              ? { width: "100%", overflowWrap: "break-word" }
                              : { width: "100%" }
                        }
                      >
                        {message?.deletedStatus || message?.deleteForMeIds.includes(personalNumber) ?
                          messageClass !== "sent" ? "This message was deleted" :
                            "You deleted this message" :
                          message?.caption}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          style={
                            message?.caption?.length > 55
                              ? {
                                display: "flex",
                                textAlign: "end",
                                width: "50px",
                                alignItems: "flex-end",
                                justifyContent: "flex-end", // backgroundColor: "blueviolet",
                              }
                              : {
                                width: "50px",
                                textAlign: "end",
                              }
                          }
                        >
                          {message?.isBookmarked === true && !message?.deletedStatus && !message?.deleteForMeIds.includes(personalNumber) && (
                            <img
                              src={messageClass === "sent" ? starWhite : starYellow}
                              style={{ margin: "0px 5px 0px 5px" }}
                              alt="bookmark"
                            />
                          )}

                          {!message?.deletedStatus && !message?.deleteForMeIds.includes(personalNumber) ? (message.msgStatus === 3 && messageClass === "sent") ? <img src={DoubleTick} alt="read" /> : messageClass === "sent" && message.msgStatus === 100 ? <img src={PendingMessageIcon} /> : messageClass === "sent" ? <img src={SingleTick} alt="unread" /> : null : null}
                        </div>
                      </div>
                    </div>

                    <OutsideClickHandler
                      key={message?._id}
                      id={message?._id}
                      onOutsideClick={(id) =>
                        setPopupOpen(false)
                      }
                    >
                      <div
                        className="chatActionsPopup"
                        style={
                          popupOpen
                            ? {
                              display: "block",
                              position: "absolute",
                              zIndex: "6",
                              right: "-50px",
                              top: "26px",
                              color: "black",
                            }
                            : { display: "none" }
                        }
                      >
                        <div
                          style={{ borderRadius: "6px 6px 0px 0px" }}
                          onClick={(e) => {
                            setReplyData({
                              replyId: message._id,
                              replyContent: message?.caption,
                              data: message,
                            });
                            setPopupOpen(false);
                            e.stopPropagation();
                          }}
                        >
                          Reply
                        </div>
                        {messageClass == "sent" && <div
                          onClick={(e) => {
                            setSelectedMessage(message);
                            e.stopPropagation();
                            setPopupOpen(false);
                          }}
                        >Info</div>}
                        <div
                          onClick={(e) => {
                            setForwardData({
                              isActive: true,
                              msgId: [message?._id],
                              userId: [],
                              conversationId: [],
                            });
                            setPopupOpen(false);
                            e.stopPropagation();
                          }}
                        >
                          Forward Message
                        </div>
                        {activeChat.type === "Group" && messageClass != "sent" && <div
                          onClick={(e) => {
                            handleReplyPrivately(message);
                            e.stopPropagation();
                          }}
                        >
                          Reply Privately
                        </div>}

                        <div
                          onClick={() => {
                            bookMarkMessage(message._id, myContactId, message.isBookmarked ? "unstar" : "star");
                            setPopupOpen(false);
                            setForwardData({
                              isActive: false,
                              msgId: [],
                              userId: [],
                              conversationId: [],
                            });
                          }}
                        >
                          {message.isBookmarked ? "Unstar" : "Star"}
                        </div>
                        <div
                          style={{ borderRadius: "0px 0px 6px 6px" }}
                          onClick={() => {
                            deleteMessage(
                              message?._id,
                              userDetails._id,
                              messageClass
                            );
                            setPopupOpen(false);
                          }}
                        >
                          Delete Message
                        </div>
                      </div>
                    </OutsideClickHandler>
                  </div>
                }
              </>)
          }
        </div>
      </div>}
    </>
  );
};
