import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { getRequest } from "../../../Services/axios";

const PDFComponent = ({
  imageUrlProp,
  style,
  alt = "",
  setNumPages,
  setBase64BLobImg,
}) => {
  const [base64Img, setBase64Img] = useState("");

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getRequest(
          `LMSController/imageToBase64?imageUrl=${imageUrlProp}`
        );
        setBase64Img(result?.data);
        setBase64BLobImg(result?.data);
      } catch (error) {
        // setError(error);
      }
    };
    fetchData();
  }, [imageUrlProp]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  //   if (error) {
  //     return <div>Error: {error.message}</div>;
  //   }

  return (
    <div className="pdfChatViewer">
      {base64Img && (
        <Document
          file={`data:application/pdf;base64,${base64Img}`}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={""}
        >
          <Page pageNumber={1} />
        </Document>
      )}
      {/* {numPages && (
        <p>
          Page {pageNumber} of {numPages}
        </p>
      )} */}
    </div>
  );
};

export default PDFComponent;
