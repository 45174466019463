import React, { useEffect, useState } from "react";
import { FieldArray } from "formik";
import { showToast } from "../../../Features";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { useDispatch } from "react-redux";
import { AppraisalRadioFormik } from "./AppraisalRadioFormik";
import { getRequest } from "../../../Services/axios";
const CriteriaAppraisalComp = ({
  ind,
  setinitialValues,
  formValues,
  setRadioOptions,
  radioOptions,
  removeCriteria,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const getRadioOptions = async () => {
    try {
      const result = await getRequest(`Employees/GetDepartments`);
      let listdata = [];
      result?.data?.results?.map((item) =>
        listdata?.push({
          id: item?.id,
          answerText: item?.text,
        })
      );
      setRadioOptions(listdata);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getRadioOptions();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !formValues?.criteria[ind]?.AppraisalWeightage?.filter(
        (item) => item.isActive === true
      )?.every((it) => {
        if (it?.label === "Performance") {
          return it?.Performance === "";
        } else if (it?.label === "Tasks") {
          return it?.Tasks === "";
        } else if (it?.label === "Rewards") {
          return it?.Rewards === "";
        } else if (it?.label === "L&D") {
          return it?.ld === "";
        }
      })
    ) {
      let sum = 0;
      formValues?.criteria[ind]?.AppraisalWeightage?.filter(
        (item) => item.isActive === true
      )?.forEach((it) => {
        if (it?.label === "Performance") {
          sum += it?.Performance ?? 0;
        } else if (it?.label === "Tasks") {
          sum += it?.Tasks ?? 0;
        } else if (it?.label === "Rewards") {
          sum += it?.Rewards ?? 0;
        } else if (it?.label === "L&D") {
          sum += it?.ld ?? 0;
        }
      });
      // setSumWeightage(sum);
      setinitialValues(() => {
        const finalObj = { ...formValues };

        finalObj.criteria[ind].AppraisalWeightageTotal = sum;
        return finalObj;
      });
    }
  }, [formValues]);
  const isDisabled = (arr, key) => {
    if (Array.isArray(arr)) {
      const a = arr?.some((it) => it?.appraisalPercentage !== "");
      const b = arr?.some((it) => it?.appraisalValue !== "");
      if (key === "appraisalPercentage") {
        return b;
      }
      if (key === "appraisalValue") {
        return a;
      } else {
        return false;
      }
      //  setAppraisalPercentDisable(b);
      //  setAppraisalValueDisable(a);
    }
    return false;
  };
  console.log("radioOptions", radioOptions);
  return (
    <div
      style={{ backgroundColor: "#FFF", width: "100%", margin: "auto auto" }}
    >
      <div
        className="criteriaCreateStyle"
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div>Criteria {ind + 1} </div>{" "}
        <div
          style={
            formValues?.criteria?.length > 1
              ? {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  width: "54px",
                  justifyContent: "space-between",
                }
              : {}
          }
        >
          {formValues?.criteria?.length > 1 && (
            <svg
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                removeCriteria(ind);
              }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 4.8C0 2.14903 2.14903 0 4.8 0H19.2C21.851 0 24 2.14903 24 4.8V19.2C24 21.851 21.851 24 19.2 24H4.8C2.14903 24 0 21.851 0 19.2V4.8Z"
                fill="#FFDDDC"
              />
              <path
                d="M16.8002 9.32282C15.0242 9.14682 13.2375 9.05615 11.4562 9.05615C10.4002 9.05615 9.3442 9.10949 8.2882 9.21615L7.2002 9.32282"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.1338 8.7842L10.2511 8.08553C10.3365 7.57886 10.4005 7.2002 11.3018 7.2002H12.6991C13.6005 7.2002 13.6698 7.6002 13.7498 8.09086L13.8671 8.7842"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.9285 9.62402L15.3079 16.3784C15.2492 17.2158 15.2012 17.8664 13.7132 17.8664H10.2892C8.8012 17.8664 8.7532 17.2158 8.69453 16.3784L8.1709 9.62402"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.0186 14.958V12.0489"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0791 14.958V12.0489"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}

          {isOpen ? (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 7.01221L7 0.987749L13 7.01221"
                stroke="#1296B0"
                stroke-width="1.33429"
                stroke-linecap="round"
              />
            </svg>
          ) : (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 0.987793L7 7.01225L13 0.987793"
                stroke="#1296B0"
                stroke-width="1.33429"
                stroke-linecap="round"
              />
            </svg>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          style={{
            padding: "15px",
            backgroundColor: "#FFFFFF",
            width: "100%",
          }}
        >
          <div
            className="headingCriteriaCreate"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Select Department{" "}
            <div>
              <button
                onClick={() => {
                  formValues?.criteria?.length === 1 &&
                  radioOptions?.length ===
                    formValues.criteria[ind].departmentSelected?.length
                    ? setinitialValues(() => {
                        const finalObj = { ...formValues };
                        finalObj.criteria[ind].departmentSelected = [];
                        return finalObj;
                      })
                    : setinitialValues(() => {
                        let obj;
                        const finalObj = { ...formValues };
                        obj = radioOptions?.map((item) => item?.id?.toString());
                        finalObj.criteria[ind].departmentSelected = obj;
                        return finalObj;
                      });
                }}
                disabled={formValues?.criteria?.length !== 1}
                className="button primaryButton"
                style={
                  formValues?.criteria?.length === 1
                    ? { margin: "0px" }
                    : {
                        margin: "0px",
                        backgroundColor: "#BFBFBF",
                        color: "#FFFFFF",
                        cursor: "default",
                      }
                }
              >
                Select All
              </button>
            </div>
          </div>
          <div>
            <AppraisalRadioFormik
              isRow={true}
              isMultiSelect={true}
              name={`criteria[${ind}].departmentSelected`}
              options={radioOptions?.filter((it) => {
                const arr = [];
                formValues?.criteria?.forEach((item, index) => {
                  if (index !== ind)
                    arr?.push(...(item?.departmentSelected || []));
                });
                return !arr?.includes(it?.id?.toString());
              })}
            />
          </div>
          <div className="headingCriteriaCreate">
            Appraisal Classification Inputs
          </div>
          <div className="repeatedSectionContainer">
            <FieldArray name={`criteria[${ind}].AppraisalWeightage`}>
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { AppraisalWeightage } =
                  Array.isArray(values?.criteria) && values?.criteria[ind]
                    ? values.criteria[ind]
                    : {}; // console.log("object AppraisalWeightage", AppraisalWeightage);
                // console.log(
                //   "object AppraisalWeightage Filter",
                //   AppraisalWeightage?.filter((it) => it?.isActive === true)
                // );
                return (
                  <table className="AppraisalWeightageTable">
                    <thead>
                      <td
                        padding="0"
                        style={{
                          fontSize: "12px",
                          width: "62px",
                        }}
                        align="center"
                      >
                        S. No
                      </td>
                      <td
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Criteria"} />
                      </td>
                      <td
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" Weightage (%) "} />
                      </td>
                      <td
                        style={{
                          fontSize: "12px",
                          width: "94px",
                        }}
                        align="center"
                      >
                        Remove
                      </td>
                    </thead>
                    <tbody>
                      {AppraisalWeightage?.filter(
                        (it) => it?.isActive === true
                      )?.map((weight, index) => (
                        <tr key={index}>
                          <td align="center">{index + 1}</td>

                          <td style={{ paddingLeft: "15px" }}>
                            {weight?.label}
                          </td>
                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              type="number"
                              name={
                                weight?.label === "L&D"
                                  ? `criteria[${ind}].AppraisalWeightage[${index}].ld`
                                  : `criteria[${ind}].AppraisalWeightage[${index}].${weight?.label}`
                              }
                              placeholder={"Enter"}
                            />
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <div
                              // className="remove-btn"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                AppraisalWeightage.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 4.8C0 2.14903 2.14903 0 4.8 0H19.2C21.851 0 24 2.14903 24 4.8V19.2C24 21.851 21.851 24 19.2 24H4.8C2.14903 24 0 21.851 0 19.2V4.8Z"
                                  fill="#F71D1D"
                                />
                                <path
                                  d="M16.8 9.32257C15.024 9.14657 13.2373 9.05591 11.456 9.05591C10.4 9.05591 9.34395 9.10924 8.28795 9.21591L7.19995 9.32257"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.1333 8.78395L10.2506 8.08528C10.336 7.57862 10.4 7.19995 11.3013 7.19995H12.6986C13.6 7.19995 13.6693 7.59995 13.7493 8.09062L13.8666 8.78395"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M15.9273 9.62415L15.3066 16.3786C15.248 17.2159 15.2 17.8666 13.712 17.8666H10.288C8.79998 17.8666 8.75198 17.2159 8.69331 16.3786L8.16968 9.62415"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.0181 14.9575V12.0484"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.0786 14.9575V12.0484"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            {/* <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  employeeId: "",
                                  level: "",
                                  locationIds: [],
                                })
                              }
                            >
                              +
                            </button> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              }}
            </FieldArray>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: "424px",
                color: "#1296B0",
              }}
            >
              Total
            </div>{" "}
            <div style={{ marginRight: "117px", width: "120px" }}>
              {" "}
              <InputCustomFormik
                type="number"
                name={`criteria[${ind}].AppraisalWeightageTotal`}
                // value={sumWeightage}
                placeholder={"Enter"}
                disabled={true}
              />
            </div>
          </div>
          <div className="headingCriteriaCreate">Appraisal Slab</div>
          <div className="repeatedSectionContainer">
            <FieldArray name={`criteria[${ind}].appraisalSlab`}>
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { appraisalSlab } =
                  Array.isArray(values?.criteria) && values?.criteria[ind]
                    ? values.criteria[ind]
                    : {};

                return (
                  <table className="AppraisalWeightageTable">
                    <thead>
                      <tr>
                        <td
                          rowspan="2"
                          padding="0"
                          style={{
                            fontSize: "12px",
                            width: "62px",
                          }}
                          align="center"
                        >
                          S. No
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                          colspan="2"
                        >
                          <LabelCustom labelName={"Weightage Range"} />
                        </td>

                        <td
                          rowspan="2"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={" Appraisal % "} />
                        </td>
                        <td
                          rowspan="2"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={" Appraisal Value"} />
                        </td>
                        <td
                          rowspan="2"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          Add/Remove
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"From"} />
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"To"} />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {appraisalSlab?.map((weight, index) => (
                        <tr key={index}>
                          <td align="center">{index + 1}</td>

                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              type="number"
                              name={`criteria[${ind}].appraisalSlab[${index}].fromWeightageRange`}
                              placeholder={"Enter"}
                            />
                          </td>
                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              type="number"
                              name={`criteria[${ind}].appraisalSlab[${index}].toWeightageRange`}
                              placeholder={"Enter"}
                            />
                          </td>
                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              type="number"
                              name={`criteria[${ind}].appraisalSlab[${index}].appraisalPercentage`}
                              disabled={isDisabled(
                                formValues?.criteria[ind]?.appraisalSlab,
                                "appraisalPercentage"
                              )}
                              placeholder={"Enter"}
                            />
                          </td>
                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              type="number"
                              name={`criteria[${ind}].appraisalSlab[${index}].appraisalValue`}
                              disabled={isDisabled(
                                formValues?.criteria[ind]?.appraisalSlab,
                                "appraisalValue"
                              )}
                              placeholder={"Enter"}
                            />
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                appraisalSlab?.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  fromWeightageRange: "",
                                  toWeightageRange: "",
                                  appraisalPercentage: "",
                                  appraisalValue: "",
                                })
                              }
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              }}
            </FieldArray>
          </div>
        </div>
      )}
    </div>
  );
};

export default CriteriaAppraisalComp;
