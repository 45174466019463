import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";

import { reqCategorySchema } from "../../HRMSMaster/HRDeskComponents/RequestCategorySchemas";
import { GroupsForm } from "./Form/GroupsForm";
import { StatementForm } from "./Form/StatementForm";
import { stateSchema } from "./Schema";
import { useEffect } from "react";

const CreateStatement = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();
  const getDetails = async () => {
    try {
      const result = await getRequest(`ExceptionStatements/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);
  const postFormData = async (values) => {
    try {
      const result = await postRequest("ExceptionStatements/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {}
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Statement`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("ExceptionStatements/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    }
  };
  const initialvalue = {
    name: data ? detail?.name : "",
    exceptionGroupId: data ? detail?.exceptionGroupname : "",
  };
  const onSubmit = async (values) => {
    const edit = {
      ...values,
      id: data,
    };

    const create = {
      ...values,
    };

    data ? Edit(edit) : postFormData(create);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik
      ButtonLabel={
        data ? "Update Exception Statements" : "Create Exception Statements"
      }
      FormHeading={
        data ? "Edit Exception Statements" : "Create Exception Statements"
      }
      validationSchema={stateSchema}
      onSubmit={onSubmit}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<StatementForm data={data} />}
    />
  );
};

export { CreateStatement };
