/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDeferredValue } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../Services/axios";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./CreatePermissionSet.css";
import { Loader } from "../../../Components/FormComponent/Loader";
import { GroupBy } from "../../../Utilities/GroupBy";
import { CheckboxFormiks } from "../../../Components/FormComponent/CheckboxFormiks";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";

function CreatePermissionSet() {
  const [formValues, setFormValues] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [typeList, setTypeList] = useState(null);
  const [groupedPermission, setGroupedPermission] = useState(null);
  const [typeActive, setTypeActive] = useState(null);
  const [detailsform, setDetailsform] = useState({});
  const [selectedCount, setSelectedCount] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const isDetails = state?.details;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PermissionSets/Details?ID=${data}`);
      setDetailsform(result?.data?.data?._permissionSets);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(()=>{
    document.title = `PeopleSol - Create Permission`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("PermissionSets/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("PermissionSets/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate("permission/permissionsets");
  };
  const getPermissionList = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`PermissionSets/Create?q=${searchParam}`);
      if (result) {
        let grouppedList = GroupBy(result?.data?.data?.permissions, "type");
        setTypeList(Object.keys(grouppedList));
        setGroupedPermission(grouppedList);
        setTypeActive(Object.keys(grouppedList)?.[0]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    getPermissionList();
  }, [deferredText]);
  const getItemList = () => {
    let returnArray = {};
    data
      ? detailsform?.permissionSetsItems?.map((item) => {
          return (returnArray[item.permissionId] = true);
        })
      : (returnArray = {});
    return returnArray;
  };
  const initialvalue = {
    Id: data ?? 0,
    name: data ? detailsform?.name ?? "" : "",
    Items: getItemList(),
  };
  const updatePermissionCount = () => {
    if (!formValues?.Items) return;
    if (!groupedPermission) return;
    const countObject = {};
    Object.keys(formValues?.Items)
      .filter((key) => formValues?.Items[key])
      ?.forEach((id) => {
        Object.entries(groupedPermission)?.forEach(([type, ids]) => {
          ids?.map((permission) => {
            if (permission?.id === Number(id))
              countObject[type] = (countObject?.[type] || 0) + 1;
            return null;
          });
        });
      });
    setSelectedCount(countObject);
  };
  useEffect(() => {
    updatePermissionCount();
  }, [formValues, groupedPermission]);

  return (
    <div className="moduleInsideContainer">
      <div
        className="headingContainer"
        style={{ alignItems: "center", justifyContent: "flex-start" }}
      >
        <Tooltip title="Back" placement="bottom" onClick={returnPage}>
          <IconButton>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.472252 4.18746C0.27699 4.38272 0.27699 4.69931 0.472252 4.89457L3.65423 8.07655C3.84949 8.27181 4.16608 8.27181 4.36134 8.07655C4.5566 7.88129 4.5566 7.5647 4.36134 7.36944L1.53291 4.54102L4.36134 1.71259C4.5566 1.51733 4.5566 1.20074 4.36134 1.00548C4.16608 0.81022 3.84949 0.81022 3.65423 1.00548L0.472252 4.18746ZM18.8527 5.04102C19.1288 5.04102 19.3527 4.81716 19.3527 4.54102C19.3527 4.26487 19.1288 4.04102 18.8527 4.04102V5.04102ZM0.825806 5.04102H18.8527V4.04102H0.825806V5.04102Z"
                fill="#1296B0"
              />
            </svg>
          </IconButton>
        </Tooltip>
        <h3>
          {isDetails
            ? "Permission Set Details"
            : data
            ? "Edit Permission Set"
            : "Create Permission Set"}
        </h3>
      </div>
      <Formik
        initialValues={initialvalue}
        validationSchema={Yup.object({
          name: Yup.string().required("Name Is Required"),
        })}
        enableReinitialize
        onSubmit={(values) => {
          let selectedPermissionIds = Object.keys(values?.Items).filter(
            (key) => values?.Items[key] === true
          );
          let selectedPermissionIdsPayload = [];
          selectedPermissionIds?.map((selectedId) =>
            selectedPermissionIdsPayload.push({
              PermissionId: Number(selectedId),
              Checked: true,
            })
          );
          let payload = {
            Id: data ?? 0,
            name: values?.name,
            Items: selectedPermissionIdsPayload,
          };
          data ? Edit(payload) : create(payload);
        }}
      >
        {(formik) => {
          setFormValues(formik.values);
          return (
            <Form
              style={{
                flexGrow: 1,
                paddingTop: "12px",
                display: "flex",
                flexDirection: "column",
                height: "calc(100% - 60px)",
                backgroundColor: "var(--form-background)",
              }}
            >
              <div className="tableHeaderContainer" style={{ margin: 0 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <LabelCustom labelName={"Name"} mandatory={true} />
                  <label>:</label>
                  <InputCustomFormik
                    name="name"
                    placeholder="Enter Permission Set Name"
                    style={{
                      width: "300px",
                      border: "0.75px solid #E6E6E6",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.02)",
                      borderRadius: "4px",
                    }}
                    disabled={isDetails ? true : false}
                  />
                </div>
                <div className="search-box" title="Search">
                  <input
                    className="search-text"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchParam(e.target.value)}
                  />
                  <a className="search-btn" title="Search">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                        fill="#1296B0"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  height: "calc(100% - 60px)",
                }}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <div
                      style={{
                        width: "40%",
                        minWidth: "300px",
                        flexShrink: 0,
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        padding: "0 12px",
                        margin: "12px 0",
                      }}
                    >
                      {typeList?.map((item, index) => {
                        let count = selectedCount?.[item];
                        return (
                          <div
                            className={`permissionTypeContainer cursor ${
                              item === typeActive &&
                              "permissionTypeContainerActive"
                            }`}
                            key={index}
                            onClick={() => setTypeActive(item)}
                          >
                            <label>{item}</label>
                            <div style={{ display: "flex", gap: "12px" }}>
                              {count && (
                                <div className="inlineBadge">
                                  <label>{count}</label>
                                </div>
                              )}
                              <svg
                                width="10"
                                height="16"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.70337 14.5723L8.44482 7.83081L1.70337 1.08936"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ flexGrow: 1, padding: "12px" }}>
                      <div
                        style={{
                          height: isDetails ? "100%" : "calc(100% - 60px)",
                          padding: "12px",
                          backgroundColor: "var(--primary-hover)",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "var(--primary-hover)",
                            display: "flex",
                            flexWrap: "wrap",
                            overflow: "auto",
                            maxHeight: "100%",
                            contain: "content",
                          }}
                        >
                          {groupedPermission?.[typeActive]?.map((item) => {
                            return (
                              <CheckboxFormiks
                                name={`Items[${item?.id}]`}
                                labelName={item?.name}
                                key={item?.id}
                                style={{ width: "50%" }}
                                disabled={isDetails ? true : false}
                              />
                            );
                          })}
                        </div>
                      </div>
                      {!isDetails && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="button secondaryButton"
                            type="button"
                            onClick={returnPage}
                          >
                            Cancel
                          </button>
                          <button
                            className="button primaryButton"
                            type="submit"
                          >
                            {data
                              ? "Update Permission Set"
                              : "Create Permission Set"}
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreatePermissionSet;
