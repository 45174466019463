import React from "react";
import Hero from "../../Assets/Hero.svg";
import PeopleSolLogo from "../../Assets/PeopleSolLogo.svg";
import LoginPerson from "../../Assets/LoginPerson.png";
import LoginImageDash from "../../Assets/LoginImageDash.png";
import LoginImageProfile from "../../Assets/LoginImageProfile.png";
import LoginImageGraph from "../../Assets/LoginImageGraph.png";
import LoginImagePerson from "../../Assets/LoginImagePerson.png";
// import { LoginForm } from "../../Components";
import "../Styles.css";
import { ConsultantLoginForm } from "../../Components/AuthForm/ConsultantLoginForm";
import { useEffect } from "react";
// import { useDispatch } from 'react-redux';
// import { loggedOut } from '../../Features';

const ConsultantLanding = () => {
  // const dispatch = useDispatch();
  // dispatch(loggedOut());
  useEffect(() => {
    document.title = `PeopleSol - Consultant Landing`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="landing">
      <div className="loginGraphics consultantLoginGraphics">
        <label > Text Goes Here</label>
        <div className="loginGraphicsBox">
          {/* <img src={LoginPerson} alt="LoginPerson" className="loginGraphicImage"/>
          <img src={LoginImageDash} alt="LoginImageDash" className="LoginImageDash bounce"/>
          <img src={LoginImageProfile} alt="LoginImageProfile" className="LoginImageProfile bounce2"/>
          <img src={LoginImageGraph} alt="LoginImageGraph" className="LoginImageGraph bounce"/>
          <img src={LoginImagePerson} alt="LoginImagePerson" className="LoginImagePerson bounce2"/> */}
        </div>
      </div>
      <div className="loginForm">
      <img src={Hero} alt="hero" className="hero" />
        <div className="loginFormBox">
          <img src={PeopleSolLogo} alt="peopleSolLogo" className="heroLogo" />
          <ConsultantLoginForm />
          <div className="loginFooterMain">
            <div className="loginFooter">
              <img
                src={PeopleSolLogo}
                alt="peopleSolLogo"
                className="subHeroLogo"
              />
              <div className="subFooter">
                <p className="subFooterText cursor">Privacy Policy</p>
                <p className="subFooterText cursor">Terms & Conditions</p>
              </div>
            </div>
            <hr className="footerLine"/>
            <div className="footerEndLine">
            <p className="footerLineText">©2022 . All rights reserved. Made in India with</p>
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.50386 4.06238C0.50386 3.99602 0.50386 3.8633 0.50386 3.79694C0.50386 3.73057 0.50386 3.73057 0.50386 3.66421C0.50386 3.46513 0.503859 3.33241 0.57022 3.13333C0.702941 2.337 1.10111 1.67339 1.63199 1.1425C2.56104 0.21345 3.8219 -0.184715 5.08276 0.080728C5.87909 0.21345 6.60906 0.611615 7.13994 1.20886C7.13994 1.20886 7.13994 1.20886 7.2063 1.27522C7.2063 1.27522 7.2063 1.27522 7.27266 1.20886C7.93627 0.545254 8.79897 0.147089 9.72802 0.080728C10.0598 0.080728 10.3916 0.080728 10.7234 0.147089C11.5198 0.279811 12.1834 0.611615 12.7806 1.1425C13.7097 2.07155 14.1078 3.19969 13.9751 4.46054C13.9088 4.85871 13.8424 5.25687 13.6433 5.65504C13.4442 6.11956 13.1788 6.45137 12.7806 6.84953C10.9225 8.70764 9.13077 10.4994 7.27266 12.3575C7.27266 12.3575 7.27267 12.3575 7.2063 12.3575C7.2063 12.3575 7.20631 12.3575 7.13994 12.2911C5.28184 10.433 3.42374 8.57492 1.56563 6.71681C1.30019 6.45137 1.03475 6.11956 0.835664 5.7214C0.570221 5.25687 0.4375 4.72599 0.4375 4.1951C0.503861 4.12874 0.50386 4.12874 0.50386 4.06238Z" fill="#F71D1D"/>
</svg>

            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export { ConsultantLanding };
