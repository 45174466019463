import React, { useState } from "react";
import { RadioCustomRewards } from "./RadioCustomRewards";
import "./Rewards.css";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { CreateReward } from "./CreateReward";
import { useNavigate } from "react-router-dom";
import Quantitative from "../../../Assets//Quantitative.svg";
import Qualitative from "../../../Assets//Qualitative.svg";

const Rewardsubtab = ({ handleClose }) => {
  const [active, setactive] = useState(0);
  const navigate = useNavigate();

  const options = [
    {
      id: 1,
      label: "Quantitative",
      sublable:
        "Non-monetary incentive like recongnition, career growth, and work-life balance improve job satisfaction and company culture.",
      name: "radioGroup",
      disabled: false,
      images: Quantitative,
    },
    {
      id: 2,
      label: "Qualitative",
      sublable:
        "Tangible incentives like salary, bonuses, and stock opotions directly motivate through financial compensation.",
      name: "radioGroup",
      disabled: false,
      images: Qualitative,
    },
  ];

  return (
    <div>
      <RadioCustomRewards
        active={active}
        setactive={setactive}
        // value={selectedValue}
        options={options}
        // label="Select an option"
        // errorsParam={errors.radioGroup}
        // touchedParam={touched.radioGroup}
        inputName="radioGroup"
      />
      <div class="horizontalLine createLine"></div>
      <div className="createFootCust">
        <div>
          <button onClick={handleClose} className={`button CancelButtoncss`}>
            Cancel
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              handleClose();
              // console.log("state-active", active);
              // console.log(
              //   " data From next show -:",
              //   options?.filter((item) => item.id === active)[0]
              // );
              navigate("/pmsmaster/rewards/quantitative/create", {
                state: {
                  nextItem: options?.filter((item) => item.id === active)[0],
                },
              });
            }}
            className={`button GrayButtoncss ${
              active !== 0 ? "primarycolors" : ""
            }`}
            type="submit"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export { Rewardsubtab };
