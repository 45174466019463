import React, { useEffect, useState, useMemo } from "react";
import { Loader } from "../../../../Components/FormComponent/Loader";
import SendImageChat from "../../../../Assets/SendImageChat.svg";
import AddImage from "../../../../Assets/AddDocumentIcon.svg";
import CloseIcon from "../../../../Assets/CloseIcon.svg";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";

export const StoryUploadPreview = ({ closeStory, storyFiles, newSocket, handleInput, updateStories }) => {
    const [selectedFile, setSelectedFile] = useState(storyFiles[0]);
    const [localLoader, setLocalLoader] = useState(false);
    const [caption, setCaption] = useState("");
    const dispatch = useDispatch();
    const handleStoryUpload = async () => {
        setLocalLoader(true);
        const results = [];

        for (const item of storyFiles) {
            const formData = new FormData();
            formData.append("files", item);

            const response = await fetch(
                "https://media.solunxt.com/api/v1/Storage/Save?token=5b4cbd69-2366-4708-871c-5300a4dc6f18",
                { method: "POST", body: formData }
            );
            const responseData = await response.json();
            if (responseData.succeeded) {
                results.push({
                    content: responseData.data[0],
                    type: item.type.startsWith("image/") ? 3 : 2,
                    caption: caption || "",
                });
            }
        }

        newSocket.emit("addStory", results, (res) => {
            dispatch(
                showToast({ text: "Story added successfully!", severity: "success" })
            );
            updateStories();
        });

        setLocalLoader(false);
        closeStory();
    };

    // Memoized VideoComp to prevent unnecessary re-renders
    const MemoizedVideoComp = React.memo(({ file, size, controls }) => {
        const fileUrl = useMemo(() => URL.createObjectURL(file), [file]);

        useEffect(() => {
            return () => URL.revokeObjectURL(fileUrl); // Cleanup URL object
        }, [fileUrl]);

        return <video src={fileUrl} controls={controls} width={size} />;
    });

    // Memoize the URLs for the story files
    const fileUrls = useMemo(
        () => storyFiles.map((file) => ({ file, url: URL.createObjectURL(file) })),
        [storyFiles]
    );

    useEffect(() => {
        return () => {
            // Cleanup the created URLs when the component unmounts or files change
            fileUrls.forEach(({ url }) => URL.revokeObjectURL(url));
        };
    }, [fileUrls]);

    // Use useMemo to avoid re-rendering image or video when caption changes
    const renderMedia = useMemo(() => {
        if (selectedFile.type.startsWith("image/")) {
            const selectedUrl = fileUrls.find((file) => file.file === selectedFile)?.url;
            return (
                <img
                    src={selectedUrl}
                    style={{ height: "70%", width: "60%", marginTop: 50 }}
                    alt="Preview"
                />
            );
        } else {
            return (
                <div style={{ marginTop: 50 }}>
                    <MemoizedVideoComp controls={true} size={window.innerWidth > 1440 ? 700 : 550} file={selectedFile} />
                </div>
            );
        }
    }, [selectedFile, fileUrls]);

    // Memoize the video component to avoid unnecessary re-renders on caption change
    const memoizedVideoComponents = useMemo(() => {
        return fileUrls.map(({ file, url }) => (
            <div key={file.name} onClick={() => setSelectedFile(file)}>
                {file.type.startsWith("image/") ? (
                    <img
                        src={url}
                        style={{ height: 60, width: 55, marginRight: 20 }}
                        alt="Thumbnail"
                    />
                ) : (
                    <div style={{ marginRight: 20 }}>
                        <MemoizedVideoComp controls={false} size={55} file={file} />
                    </div>
                )}
            </div>
        ));
    }, [fileUrls]); // memoized to only recompute when fileUrls changes

    return (
        <div
            style={{
                marginInline: "auto",
                marginTop: 10,
                backgroundColor: "rgba(239, 249, 250, 1)",
                left: 0,
                right: 0,
                borderRadius: 20,
                width: "95%",
                height: "95%",
                position: "absolute",
                zIndex: 100,
            }}
        >
            {localLoader && (
                <div className="document-preview-container-uploading">
                    <div
                        style={{
                            color: "rgb(51, 51, 51)",
                            padding: "20px",
                            borderRadius: "5px",
                            fontSize: "17px",
                            textAlign: "center",
                            width: "500px",
                            backgroundColor: "white",
                        }}
                    >
                        <p>Sending your media...</p>
                        <div style={{ marginTop: "0px" }}>
                            <Loader />
                        </div>
                    </div>
                </div>
            )}

            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "83%",
                    }}
                >
                    {renderMedia}

                    <input
                        onChange={(e) => setCaption(e.target.value)}
                        type="text"
                        placeholder="Type a message"
                        style={{
                            borderStyle: "none",
                            outline: "none",
                            backgroundColor: "white",
                            padding: 10,
                            marginTop: 50,
                            width: "50%",
                            borderRadius: 4,
                        }}
                    />

                    <div onClick={closeStory} style={{ position: "absolute", right: 50, top: 50 }}>
                        <img src={CloseIcon} alt="Close" />
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: "white",
                        width: "100%",
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: 20,
                        height: "17%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "50%",
                            overflowX: "auto",
                            scrollbarWidth: 0.5,
                            justifyContent: "center",
                        }}
                    >
                        {memoizedVideoComponents}

                        <div
                            onClick={handleInput}
                            style={{
                                height: 60,
                                width: 55,
                                marginRight: 20,
                                backgroundColor: "rgba(216, 240, 242, 1)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img src={AddImage} alt="Add" />
                        </div>
                    </div>

                    <div onClick={handleStoryUpload} style={{ position: "absolute", right: 50 }}>
                        <img style={{ height: 44, width: 44 }} src={SendImageChat} alt="Send" />
                    </div>
                </div>
            </div>
        </div>
    );
};
