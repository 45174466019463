import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateGoalSettingComp } from "./FormComp/CreateGoalSettingComp";
import { format } from "date-fns";
import { useEffect } from "react";

const CreateGoalSetting = () => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);

  const data = state?.dataEdit;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("GoalSettings/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Goal Setting`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("GoalSettings/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const initialvalue = {
    name: "",
    cycleStartDate: "",
    cycleEndDate: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(149," Name must be less than 150 characters").required("Please enter the Name "),
    cycleStartDate: Yup.date().required("Please select start date"),
    cycleEndDate: Yup.date().required("Please select end date"),
  });

  const onSubmit = (values) => {
    const createValues = {
      ...values,
      Id: data ? data.Id : 0,
      cycleStartDate: format(values.cycleStartDate, "yyyy-MM-dd"),
      cycleEndDate: format(values.cycleEndDate, "yyyy-MM-dd"),
    };
    data ? Edit(createValues) : createPageData(createValues);
  };

  const navigate = useNavigate();
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const returnPage = () => {
    navigate("/pmsmaster/goalsetting");
  };
  return (
    <FormCustomFormik
    chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={data ? "Edit Goal Setting" : "Create Goal Setting"}
      ButtonLabel={data ? "Update Goal Setting" : "Create Goal Setting"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: data?.Name,
              cycleStartDate: data?.CycleStartDate
                ? new Date(data?.CycleStartDate)
                : null,
              cycleEndDate: data?.CycleEndDate
                ? new Date(data?.CycleEndDate)
                : null,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateGoalSettingComp formValues={formValues}/>}
    />
  );
};
export { CreateGoalSetting };
