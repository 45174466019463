import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";

import "./AuthForm.css";
import { Formik, useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, ConsultantLogin } from "../../Services";
import VisibilityOffIcon from "../../Assets/VisibilityOffIcon.svg";
import VisibilityIcon from "../../Assets/VisibilityIcon.svg";
import LoginArrow from "../../Assets/LoginArrow.svg";

import { signUpSchema } from "./ConsultantLoginFormSchemas";
import { useEffect } from "react";


const initialValues = {
  email: "",
  password: "",
};




const ConsultantLoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);
  const { loading } = useSelector((state) => state.auth);
  const [password, setPassword] = useState("password");
 
  const changeColor = theme === "light" ? "var(--black)" : "var(--white)";

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: (values, action) => {
        action.resetForm();
      },
    });

    useEffect(()=>{
      document.title = `PeopleSol - Consultant Login`;
      return () => {
        document.title = 'PeopleSol';
      };
    })
    

  return (
    
        <form
          color="primary"
          className="auth-form"
          onSubmit={handleSubmit}
        >
          <p className="loginText">Consultant Login</p>
          <input
            value={values.email}
            className="loginInput"
            label="Email" 
            name="email" 
            onChange={handleChange}
            onBlur={handleBlur} 
            placeholder="Consultant ID/Email ID"
           />
          {errors.email && touched.email ? (
            <p className="form-error">{errors.email}</p>
          ) : null}


          <div className="password-container">
            <input value={values.password} className="loginInput" label="Password" name="password"
            onChange={handleChange} onBlur={handleBlur} type={password} placeholder="Enter Password" 
            />
            {errors.password && touched.password ? (
              <p className="form-error">{errors.password}</p>
            ) : null}


            <div className="password-icons">
              {password === "password" ? (
                <IconButton color="primary" onClick={() => setPassword("text")}>
                  <img src={VisibilityIcon} alt="ShowPassword" className="visibilityIcon" />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  onClick={() => setPassword("password")}
                >
                  <img src={VisibilityOffIcon} alt="HidePassword" className="visibilityIcon" />
                </IconButton>
              )}
            </div>
          </div>
          <p className="ForgotText cursor">Forgot Password?</p>
          <button className="loginButton" type="submit" onClick={() =>
              ConsultantLogin(
                { email: "nishasen@gmail.com", password: "nisha1234" },
                navigate,
                dispatch
              )
            }>
            <p>Login to PeopleSol</p>
            <img src={LoginArrow} alt="LoginArrow" className="loginButtonArrow" />
          </button>
         
        </form>
     
  );
};

export { ConsultantLoginForm };
