import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
// import "./ReportsandKPI.css";
import { useLocation, useNavigate } from "react-router-dom";


const ReportsandKPI = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newTabIndex) => {                                       //setTabIndex(newTabIndex)
        if (newTabIndex === 0) {
            navigate("reportsandkpi/travlbookingkpi");
        }
        else if (newTabIndex === 1) {
            navigate("reportsandkpi/hotlbookingkpi");
        }
        else if (newTabIndex === 2) {
            navigate("reportsandkpi/advancekpi");
        }
        else if (newTabIndex === 3) {
            navigate("reportsandkpi/reimbursekpi");
        }
       
    }
    
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const currentpath = location.pathname.toLowerCase();
        if (currentpath.includes("travel/reportsandkpi")) {
            if (currentpath.includes("travlbookingkpi")) {
                setTabIndex(0);
            }
            else if (currentpath.includes("hotlbookingkpi")) {
                setTabIndex(1);
            }
            else if (currentpath.includes("advancekpi")) {
                setTabIndex(2);
            }
            else if (currentpath.includes("reimbursekpi")) {
                setTabIndex(3);
            }
            
            else {
                    navigate("reportsandkpi/travlbookingkpi");
            }
        }
    }, [location.pathname]);
    return (
        <div className="moduleMainContainer">
            <div className="tabContainer">
                <Tabs value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { background: "#1296B0" } }}>
                    <Tab label={<span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>Travel Booking KPI</span>} />
                    <Tab label={<span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>Hotel Booking KPI</span>} />
                    <Tab label={<span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>Travel Advance KPI</span>} />
                    <Tab label={<span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>Reimbursement KPI</span>} />
                 
                    
                </Tabs>
                <div className="tabContainerLine"></div>
            </div>
            {tabIndex === 0 && (
                <>
                    Travel Booking KPI
                </>)}
            {tabIndex === 1 && (
                <>
                    Hotel Booking KPI
                </>)}
            {tabIndex === 2 && (
                <>
                    Travel Advance KPI
                </>)}
            {tabIndex === 3 && (
                <>
                    Reimbursement KPI
                </>)}
           
            
        </div>
    )
}

export { ReportsandKPI }