import { Carousel } from "react-responsive-carousel";
import { albumPostImages } from "../../../../../Utilities/profileAndUploadImages";
import "./ShowCarouselImages.css";
import { useState } from "react";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: true,
  showIndicators: true,
  infiniteLoop: false,
  showThumbs: false,
  useKeyboardArrows: false,
  autoPlay: false,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: false,
  emulateTouch: true,
  autoFocus: true,
  selectedItem: 0,
  interval: 90000,
  transitionTime: 500,
  swipeScrollTolerance: 5,
});

const ShowCarouselGalleryImages = ({ photo, type, showModal }) => {
  const [isLikeHovered, setIsLikeHovered] = useState(null);

  return (
    <Carousel
      {...getConfigurableProps()}
      onClickItem={() => {
        showModal();
      }}
      showIndicators={photo?.length > 1 ? true : false}
      showStatus={photo?.length > 1 ? true : false}
    >
      {photo &&
        type === "gallery" &&
        photo?.map((item) => {
          return (
            <div
              className="galleryImageContainer1"
              onMouseOver={() => {
                setIsLikeHovered(item?.id);
              }}
              onMouseLeave={() => setIsLikeHovered(null)}
            >
              <div className="galleryImageWrapper1">
                <div
                  style={
                    photo?.length > 1
                      ? {
                          width: "auto",
                          height: "400px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "30px 0px",
                          // margin: "auto 0px",
                        }
                      : {
                          width: "auto",
                          height: "400px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }
                  }
                >
                  <img
                    className="socialUniversalImageContainer"
                    style={{ backgroundColor: "white" }}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      albumPostImages +
                      item?.image
                    }
                    alt=""
                  />
                </div>
              </div>
              {isLikeHovered === item?.id && (
                <div className={photo?.length > 1 ? "galleryImagesNameContainer":"gallerySingleImagesNameContainer"}>
                  <div className="galleryImagesNameWrapper">
                    <span className="galleryImageName">
                      {item?.description}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </Carousel>
  );
};

export default ShowCarouselGalleryImages;
