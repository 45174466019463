import React from "react";
import styled from "styled-components";
import bg_certificate from "../../../Assets/bg-certificate.svg";
import WrapperCrt from "./WrapperCrt";
import Certificatesignature from "./Certificatesignature";

const FirstCertificateTemplate = ({
  companyLogo,
  rewardsTitle = "Certificate Employee of the year",
  rewardsName = "Ms. Jenny James",
  rewardsDescription = "Lorem ipsum dolor sit amet consectetur. Rutrum magna placerat quisque vel quisque purus viverra tellus. Nulla tincidunt dignissim sed a. Lacus turpis.",
  firstSignatory = "Mr. Sunil Grover",
  firstSignatoryDesignation = "(Head of Department)",
  firstSign = "Sunil",
  secondSignatory = "Mr. Sunil Grover",
  secondSignatoryDesignation = "(Head of Department)",
  secondSign = "Sunil",
  thirdSignatory = "Mr. Sunil Grover",
  thirdSignatoryDesignation = "(Head of Department)",
  thirdSign = "Sunil",
}) => {
  return (
    <div className="certificatetemplate certif-bg">
      <img
        src={companyLogo}
        alt="LOGO"
        style={{ width: "355px", margin: "53px auto 27px" }}
      />
      <WrapperCrt>
        <h2>Certificate</h2>
      </WrapperCrt>
      <div className="reward-details">
        <h3>
          {rewardsTitle.toLowerCase().includes("certificate")
            ? rewardsTitle
                .toLowerCase()
                .replace("certificate", "")
                .trim()
                .charAt(0)
                .toUpperCase() +
              rewardsTitle
                .toLowerCase()
                .replace("certificate", "")
                .trim()
                .slice(1)
            : rewardsTitle.charAt(0).toUpperCase() +
              rewardsTitle.slice(1).toLowerCase()}
        </h3>
        <p>This Certificate is proudly presented to</p>
        <h1>{rewardsName}</h1>
        <p>{rewardsDescription}</p>
      </div>
      <Certificatesignature
        firstSign={firstSign}
        firstSignatory={firstSignatory}
        firstSignatoryDesignation={firstSignatoryDesignation}
        secondSignatory={secondSignatory}
        secondSignatoryDesignation={secondSignatoryDesignation}
        secondSign={secondSign}
        thirdSignatory={thirdSignatory}
        thirdSignatoryDesignation={thirdSignatoryDesignation}
        thirdSign={thirdSign}
      />
    </div>
  );
};

export default FirstCertificateTemplate;
