import React from 'react'
import { DueClearance } from '../../HRMS/Components/SeparationComponents/DueClearance';
import { useEffect } from 'react';

const ServiceDueClearance = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  DueClearance Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      <DueClearance styles={newHeight}/>
        
    </div>
  )
}

export {ServiceDueClearance};
