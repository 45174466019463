import React from "react";
import "./PremiumContentPageSocial.css"; // You can create a CSS file for styling
// import CrownIcon from "./crown-icon.png"; // Replace with your own crown icon image
import SocialPremium from "../../Assets/SocialPremium.png";
const PremiumContentPageSocial = () => {
  return (
    <div
      className="homeContainerSocial"
      style={{ flexDirection: "row", justifyContent: "space-between" }}
    >
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          paddingLeft: "66px",
          gap:'30px',
          marginBottom:'52px'
        }}
      >
        <h5 className="headPre">PeopleSol Social</h5>
        <p className="prPas">
          PeopleSol Social management module is an internal social networking
          platform for employees, designed to enhance communication and
          collaboration within an organization. It offers features such as
          instant messaging, news feeds, events calendars, and announcements,
          facilitating a more connected and engaged workforce.
        </p>
        <a
          href={`https://aesssolutions.com/pricing.html`}
          target="_blank"
          className="upgrade-linkSocial"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
          >
            <path
              d="M4.40471 18.9803L4.21373 18.0363H20.3956L20.2029 18.9803H4.40471Z"
              fill="white"
              stroke="white"
              stroke-width="0.904597"
            />
            <path
              d="M6.66202 9.88162L7.01888 9.22728L6.66202 9.88162C7.18552 10.1671 7.84109 9.98675 8.14485 9.47363L12.3165 2.42701L16.3082 9.45469C16.6056 9.97833 17.267 10.1682 17.7969 9.8821L22.732 7.2174L20.6144 15.2093H4.03654L1.8205 7.24121L6.66202 9.88162Z"
              fill="white"
              stroke="white"
              stroke-width="1.49065"
            />
          </svg>
          <div className="horizontalLine" style={{width:'1px',height:'28px'}}></div>
          <span>Upgrade to Premium</span>
        </a>
      </div>
      <div>
        <img style={{width:'900px'}} src={SocialPremium} alt="img" />
      </div>
    </div>
  );
};

export default PremiumContentPageSocial;
