/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import { CreateRelocationComp } from "./Form/CreateRelocationComp";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const CreateRelocation = (props) => {
  const [formValues, setFormValues] = useState(null);
  const { state } = useLocation();
  const [detailsform, setDetailsform] = useState({});
  const data = state?.id;
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`RelocationRequests/Details?ID=${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      await postRequestForm("RelocationRequests/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Relocation`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const EditPageData = async (values) => {
    try {
      setLoading(true);
      await postRequestForm("RelocationRequests/Edit", values);
      dispatch(
        showToast({ text: "Successfully Updated", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  const getItemValue = () => {
    let returnArray = [];
    data &&
    detailsform?.relocationRequests?.isHouseHoldGoodsTransferRequired === "Yes"
      ? detailsform?.relocationRequests?.item?.map((item) => {
          return returnArray.push({
            Id: item?.id,
            LogisticName: item?.logisticName,
            Amount: item?.amount,
            upload: `RelocationRequests/${item?.attachment}`,
          });
        })
      : (returnArray = [{ LogisticName: "", Amount: "", upload: null }]);

    return returnArray;
  };
  const initialvalue = {
    TransferType: "",
    EmployeeId: LoggedInUser?.id,
    TransferEffectiveDate: "",
    TypeOfTransfer: "",
    ToDepartment: "",
    ToLocation: "",
    IsPreTransferTripRequired: "",
    IsTravelAssistanceRequired: "",
    IsInitialAccomodationRequired: "",
    IsOneTimeRelocationRequired: "",
    IsHouseHoldGoodsTransferRequired: "",
    item: getItemValue(),
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
  ];

  const validationSchema = Yup.object({
    TransferType: Yup.string().required("Please select Transfer Type "),
    EmployeeId: Yup.string().required("Please select Employee "),
    TransferEffectiveDate: Yup.string()
      .required("Please select Date")
      .nullable(),
    TypeOfTransfer: Yup.string().required("Please select Type of Transfer "),

    ToDepartment: Yup.string().when("TypeOfTransfer", {
      is: (val) => {
        return val === "Department to Department" || val === "Both";
      },
      then: () => Yup.string().required("Please select Department").min(1),
    }),
    ToLocation: Yup.string().when("TypeOfTransfer", {
      is: (val) => {
        return val === "Location to Location" || val === "Both";
      },
      then: () => Yup.string().required("Please select Location").min(1),
    }),
    IsPreTransferTripRequired: Yup.string().required("This Field is Required "),
    IsTravelAssistanceRequired: Yup.string().required(
      "This Field is Required "
    ),
    IsInitialAccomodationRequired: Yup.string().required(
      "This Field is Required "
    ),
    IsOneTimeRelocationRequired: Yup.string().required(
      "This Field is Required "
    ),
    IsHouseHoldGoodsTransferRequired: Yup.string().required(
      "This Field is Required "
    ),
    item: Yup.array().when("IsHouseHoldGoodsTransferRequired", {
      is: (val) => val === "Yes",
      then: () =>
        Yup.array().of(
          Yup.object().shape({
            LogisticName: Yup.string().required("Please Enter Name"),
            Amount: Yup.string().required("Please Enter Amount"),
            upload: Yup.mixed()
              .nullable()
              .notRequired()
              .test(
                "Fichier taille",
                "File Size should be less than 2 mb",
                (value) => {
                  return (
                    !value ||
                    (value && value.size <= 2048 * 2048) ||
                    typeof value !== "object"
                  );
                }
              )
              .test(
                "format",
                "File Format is Incorrect",
                (value) =>
                  !value ||
                  (value && SUPPORTED_FORMATS.includes(value.type)) ||
                  typeof value !== "object"
              ),
          })
        ),
    }),
  });
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("id", data ? detailsform?.relocationRequests.id : 0);
    formData.append("TransferType", values?.TransferType);
    formData.append("EmployeeId", values?.EmployeeId);
    formData.append(
      "TransferEffectiveDate",
      `${format(values?.TransferEffectiveDate, "MM/dd/yyyy")}`
    );
    formData.append("TypeOfTransfer", values?.TypeOfTransfer);
    formData.append("ToDepartment", values?.ToDepartment);
    formData.append("ToLocation", values?.ToLocation);
    formData.append(
      "IsPreTransferTripRequired",
      values?.IsPreTransferTripRequired
    );
    formData.append(
      "IsTravelAssistanceRequired",
      values?.IsTravelAssistanceRequired
    );
    formData.append(
      "IsInitialAccomodationRequired",
      values?.IsInitialAccomodationRequired
    );
    formData.append(
      "IsOneTimeRelocationRequired",
      values?.IsOneTimeRelocationRequired
    );
    formData.append(
      "IsHouseHoldGoodsTransferRequired",
      values?.IsHouseHoldGoodsTransferRequired
    );
    if (
      values?.item.length > 0 &&
      values?.IsHouseHoldGoodsTransferRequired === "Yes"
    ) {
      values?.item.forEach(function (object, index) {
        if (detailsform?.relocationRequests?.item.length >= index + 1) {
          formData.append(
            `item[${index}].Id`,
            detailsform?.relocationRequests?.item[index].id
          );
        }
        formData.append(`item[${index}].LogisticName`, object.LogisticName);
        formData.append(`item[${index}].Amount`, object.Amount);
        if (typeof object?.upload === "object")
          formData.append(`item[${index}].Upload`, object.upload);
        else formData.append(`item[${index}].Upload`, object.upload);
      });
    }
    data ? EditPageData(formData) : createPageData(formData);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      FormHeading={"Create Relocation Request"}
      ButtonLabel={
        data ? "Update Relocation Request" : "Create Relocation Request"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              TransferType: detailsform?.relocationRequests?.transferType,
              EmployeeId: detailsform?.relocationRequests?.employeeId,
              TransferEffectiveDate: detailsform?.relocationRequests
                ?.transferEffectiveDate
                ? new Date(
                    detailsform?.relocationRequests?.transferEffectiveDate
                  )
                : null,
              TypeOfTransfer: detailsform?.relocationRequests?.typeOfTransfer,
              ToDepartment:
                detailsform?.relocationRequests?.toDepartment == null
                  ? ""
                  : detailsform?.relocationRequests?.toDepartment,
              ToLocation:
                detailsform?.relocationRequests?.toLocation == null
                  ? ""
                  : detailsform?.relocationRequests?.toLocation,
              IsPreTransferTripRequired:
                detailsform?.relocationRequests?.isPreTransferTripRequired,
              IsTravelAssistanceRequired:
                detailsform?.relocationRequests?.isTravelAssistanceRequired,
              IsInitialAccomodationRequired:
                detailsform?.relocationRequests?.isInitialAccomodationRequired,
              IsOneTimeRelocationRequired:
                detailsform?.relocationRequests?.isOneTimeRelocationRequired,
              IsHouseHoldGoodsTransferRequired:
                detailsform?.relocationRequests
                  ?.isHouseHoldGoodsTransferRequired,
            }
          : initialvalue
      }
      returnPage={returnPage}
      loading={loading}
      propsDiv={
        <CreateRelocationComp
          formValues={formValues}
          data={detailsform?.relocationRequests?.quotes}
          empId={LoggedInUser?.id}
        />
      }
    />
  );
};

export { CreateRelocation };
