import { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";

export const CandidateForm = () => {
  useEffect(() => {
    document.title = `PeopleSol - Candidate Form`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik

          maxLength={50}
          name="title"
          type={"text"}
          placeholder={"Enter title"}
        />
      </div>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Description"} placeholder={"Enter Description "} />
        <TextAreaFormik placeholder={"Enter Description "} type="textarea" name="description"

          maxLength={500} />
      </div>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Type"} mandatory={true} />
        <InputCustomFormik

          maxLength={150}
          selectBoxOptions="Employee Onboarding;Candidate Onboarding;Finland;Germany;Mexico"
          name="type"
          type={"text"}
          placeholder={"Enter Type "}
        />
      </div>
      <div style={{ width: "49%" }}>
        <LabelCustom labelName={"Is Required?"} />
        <YesNoFormik name="required" />
      </div>
    </div>
  );
};
