import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";

import { isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../Components/FormComponent/Loader";

export const HospitalityApprove = ({
  data,
  id,
  type,
  color,
  handleClose,
  listId,
  isHomepage,
}) => {
  const validationSchema = Yup.object({
    updatedHotelId: Yup.string().required("Please Select Recommended Hotel"),
  });
  const dispatch = useDispatch();
  const [detailsData, setDetailsData] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState(true);

  // console.log("id,", listId);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = isHomepage
        ? await getRequest(`HospitalityRequests/Details?ID=${id}`)
        : await getRequest(`HospitalityRequests/Details?ID=${listId.id}`);

      // console.log("result details kdsfhdifuh", result);
      setDetailsData(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Hospitality Approve`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getHotels = async () => {
    try {
      const result = await getRequest("Hotels/GetHotels");
      //  // console.log("resultGetHotels", result);
      let listData = [];
      result?.data?.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });

      setHotels(listData);
    } catch {
    } finally {
    }
  };
  useEffect(() => {
    getHotels();
    getDetails();
  }, []);

  const dataHospitality = async (
    type,
    id,
    type1,
    type2,
    type3,
    updatedHotelId
  ) => {
    try {
      await postRequest(
        `HospitalityRequests/Hospitalityapprove?Id=${id}&UpdatedHotelId=${updatedHotelId}`
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const onSubmit = async (values) => {
    if (isHomepage)
      dataHospitality(
        "type",
        id,
        "Remark",
        "GetHospitalityMangerRequests",
        "listId.id",
        values.updatedHotelId
      );
    else
      data(
        type,
        id,
        "Remark",
        "GetHospitalityMangerRequests",
        listId.id,
        values.updatedHotelId
      );
    // approveapi(listId.id, values.updatedHotelId);
    handleClose();
    setFormValues(values);
  };

  // formValues.preferredHotel = detailsData?.hotel?.name;
  // console.log("values hospitality approve ", formValues);
  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={{
          preferredHotel: detailsData?.hotel?.name,
          updatedHotelId: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <LabelCustom labelName={"Preferred Hotel"} mandatory={true} />
          <InputCustomFormik
            disabled={true}
            name="preferredHotel"
            type={"text"}
          />
          <LabelCustom labelName={"Recommended Hotel"} mandatory={true} />
          <SelectForm name="updatedHotelId" options={hotels} />

          <div className="createFootCust">
            <button
              type="button"
              className="button secondaryButton"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="button primaryButton"
              type="submit"
              style={{ background: color, color: "white" }}
            >
              {type}
            </button>
          </div>
        </Form>
      </Formik>
    );
};
