import React from "react";
import SocialLikeGreyIcon from "../../../../../Assets/SocialLikeGreyIcon.svg";
import SocialCommentIcon from "../../../../../Assets/SocialCommentIcon.svg";
import SocialShareIcon from "../../../../../Assets/SocialShareIcon.svg";
import SocialThumbLike from "../../../../../Assets/SocialThumbLike.svg";
import SocialCommentColorIcon from "../../../../../Assets/SocialCommentColorIcon.svg";
import SocialPostDislikeIcon from "../../../../../Assets/SocialPostDislikeIcon.svg";
import SocialHeartLike from "../../../../../Assets/SocialHeartLike.svg";
import SocialClapLike from "../../../../../Assets/SocialClapLike.svg";
import SocialKnowledgeLike from "../../../../../Assets/SocialKnowledgeLike.svg";
import SocialPostDislikeGreyIcon from "../../../../../Assets/SocialPostDislikeGreyIcon.svg";
import SocialPostHateIcon from "../../../../../Assets/SocialPostHateIcon.svg";
import SocialPostBadIcon from "../../../../../Assets/SocialPostBadIcon.svg";
import SocialPostSadIcon from "../../../../../Assets/SocialPostSadIcon.svg";
import SocialPostAngryIcon from "../../../../../Assets/SocialPostAngryIcon.svg";
import SocialRoundThumbLike from "../../../../../Assets/SocialRoundThumbLike.svg";
import SocialRoundHeartLike from "../../../../../Assets/SocialRoundHeartLike.svg";
import SocialRoundClapLike from "../../../../../Assets/SocialRoundClapLike.svg";
import SocialRoundKnowledgeLike from "../../../../../Assets/SocialRoundKnowledgeLike.svg";
import SocialRoundBadLike from "../../../../../Assets/SocialRoundBadIcon.svg";
import SocialRoundSadLike from "../../../../../Assets/SocialRoundSadIcon.svg";
import SocialRoundHateLike from "../../../../../Assets/SocialRoundHateIcon.svg";
import SocialRoundAngryLike from "../../../../../Assets/SocialRoundAngryIcon.svg";
import SocialShareColorIcon from "../../../../../Assets/SocialShareColorIcon.svg";
import { useState } from "react";
import { getFormattedCount } from "../../../../../Utilities/socialAllFunctions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  disLikePostClickHandler,
  disLikeRemoveClickHandler,
  likePostClickHandler,
  likeRemoveClickHandler,
} from "../../../../../Utilities/SocialLikeDislikeFunction";
import {
  disLikePost,
  likePost,
  removePostDisLike,
  removePostLike,
} from "./SocialFunctions";

function SocialLikeAndDislikeComponent({
  d,
  setSharePostData,
  setSharePostId,
  setLikeId,
  setTotalLikesWatching,
  setDislikeId,
  setTotalDisLikesWatching,
  setShareDetailsActive,
  postData,
  setPostData,
  getData,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLikeHovered, setIsLikeHovered] = useState(false);
  const [isDisLikeHovered, setIsDisLikeHovered] = useState(false);
  const [isCommentHovered, setIsCommentHovered] = useState(false);
  const [isShareHovered, setIsShareHovered] = useState(false);
  const getDetailPage = (id) => {
    navigate("details/" + id);
  };
  return (
    <>
      <div className="totalPostActivity">
        <div className="likeDislikeContainer">
          {d?.likes > 0 && (
            <div className="likes">
              <p>Like</p>
              <div
                className="postLikeIconContainer"
                onClick={() => {
                  // getLikeDataById(d?.id);
                  setLikeId(d?.id);
                  setTotalLikesWatching(true);
                }}
              >
                <div className="commentLikeIcons">
                  {d?.likedType?.length > 0 && (
                    <>
                      {d?.likedType[0] && (
                        <div className="thumbLikeRoundIcon">
                          {d?.likedType[0]?.name === "thumb" && (
                            <img src={SocialRoundThumbLike} alt="" />
                          )}
                          {d?.likedType[0]?.name === "clap" && (
                            <img src={SocialRoundClapLike} alt="" />
                          )}
                          {d?.likedType[0]?.name === "heart" && (
                            <img src={SocialRoundHeartLike} alt="" />
                          )}
                          {d?.likedType[0]?.name === "knowledge" && (
                            <img src={SocialRoundKnowledgeLike} alt="" />
                          )}
                        </div>
                      )}
                      {d?.likedType[1] && (
                        <div className="clapLikeRoundIcon">
                          {d?.likedType[1]?.name === "thumb" && (
                            <img src={SocialRoundThumbLike} alt="" />
                          )}
                          {d?.likedType[1]?.name === "clap" && (
                            <img src={SocialRoundClapLike} alt="" />
                          )}
                          {d?.likedType[1]?.name === "heart" && (
                            <img src={SocialRoundHeartLike} alt="" />
                          )}
                          {d?.likedType[1]?.name === "knowledge" && (
                            <img src={SocialRoundKnowledgeLike} alt="" />
                          )}
                        </div>
                      )}
                      {d?.likedType[2] && (
                        <div className="heartLikeRoundIcon">
                          {d?.likedType[2]?.name === "thumb" && (
                            <img src={SocialRoundThumbLike} alt="" />
                          )}
                          {d?.likedType[2]?.name === "clap" && (
                            <img src={SocialRoundClapLike} alt="" />
                          )}
                          {d?.likedType[2]?.name === "heart" && (
                            <img src={SocialRoundHeartLike} alt="" />
                          )}
                          {d?.likedType[2]?.name === "knowledge" && (
                            <img src={SocialRoundKnowledgeLike} alt="" />
                          )}
                        </div>
                      )}
                      {d?.likedType[3] && (
                        <div className="knowledgeLikeRoundIcon">
                          {d?.likedType[3]?.name === "thumb" && (
                            <img src={SocialRoundThumbLike} alt="" />
                          )}
                          {d?.likedType[3]?.name === "clap" && (
                            <img src={SocialRoundClapLike} alt="" />
                          )}
                          {d?.likedType[3]?.name === "heart" && (
                            <img src={SocialRoundHeartLike} alt="" />
                          )}
                          {d?.likedType[3]?.name === "knowledge" && (
                            <img src={SocialRoundKnowledgeLike} alt="" />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <span
                  className={
                    d?.likedType?.length === 1
                      ? "likeFirstCls"
                      : null || d?.likedType?.length === 2
                      ? "likeSecondCls"
                      : null || d?.likedType?.length === 3
                      ? "likeThirdCls"
                      : null || d?.likedType?.length === 4
                      ? "likeFourthCls"
                      : null
                  }
                >
                  {d?.likes < 10 ? 0 : null}
                  {getFormattedCount(d?.likes)}
                </span>
              </div>
            </div>
          )}
          {d?.likes > 0 && d?.disLikes > 0 && (
            <div className="socialVerticalLine"></div>
          )}
          {d?.disLikes > 0 && (
            <div className={d?.likes === 0 ? "" : "dislikes"}>
              <p>Dislike</p>
              <div
                className="postLikeIconContainer"
                onClick={() => {
                  setDislikeId(d?.id);
                  setTotalDisLikesWatching(true);
                }}
              >
                <div className="commentLikeIcons">
                  {d?.disLikedType?.length > 0 && (
                    <>
                      {d?.disLikedType[0] && (
                        <div className="thumbLikeRoundIcon">
                          {d?.disLikedType[0]?.name === "bad" && (
                            <img src={SocialRoundBadLike} alt="" />
                          )}
                          {d?.disLikedType[0]?.name === "sad" && (
                            <img src={SocialRoundSadLike} alt="" />
                          )}
                          {d?.disLikedType[0]?.name === "angry" && (
                            <img src={SocialRoundAngryLike} alt="" />
                          )}
                          {d?.disLikedType[0]?.name === "hate" && (
                            <img src={SocialRoundHateLike} alt="" />
                          )}
                        </div>
                      )}
                      {d?.disLikedType[1] && (
                        <div className="clapLikeRoundIcon">
                          {d?.disLikedType[1]?.name === "bad" && (
                            <img src={SocialRoundBadLike} alt="" />
                          )}
                          {d?.disLikedType[1]?.name === "sad" && (
                            <img src={SocialRoundSadLike} alt="" />
                          )}
                          {d?.disLikedType[1]?.name === "angry" && (
                            <img src={SocialRoundAngryLike} alt="" />
                          )}
                          {d?.disLikedType[1]?.name === "hate" && (
                            <img src={SocialRoundHateLike} alt="" />
                          )}
                        </div>
                      )}
                      {d?.disLikedType[2] && (
                        <div className="heartLikeRoundIcon">
                          {d?.disLikedType[2]?.name === "bad" && (
                            <img src={SocialRoundBadLike} alt="" />
                          )}
                          {d?.disLikedType[2]?.name === "sad" && (
                            <img src={SocialRoundSadLike} alt="" />
                          )}
                          {d?.disLikedType[2]?.name === "angry" && (
                            <img src={SocialRoundAngryLike} alt="" />
                          )}
                          {d?.disLikedType[2]?.name === "hate" && (
                            <img src={SocialRoundHateLike} alt="" />
                          )}
                        </div>
                      )}{" "}
                      {d?.disLikedType[3] && (
                        <div className="knowledgeLikeRoundIcon">
                          {d?.disLikedType[3]?.name === "bad" && (
                            <img src={SocialRoundBadLike} alt="" />
                          )}
                          {d?.disLikedType[3]?.name === "sad" && (
                            <img src={SocialRoundSadLike} alt="" />
                          )}
                          {d?.disLikedType[3]?.name === "angry" && (
                            <img src={SocialRoundAngryLike} alt="" />
                          )}
                          {d?.disLikedType[3]?.name === "hate" && (
                            <img src={SocialRoundHateLike} alt="" />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <span
                  className={
                    d?.disLikedType?.length === 1
                      ? "likeFirstCls"
                      : null || d?.disLikedType?.length === 2
                      ? "likeSecondCls"
                      : null || d?.disLikedType?.length === 3
                      ? "likeThirdCls"
                      : null || d?.disLikedType?.length === 4
                      ? "likeFourthCls"
                      : null
                  }
                >
                  {d?.disLikes < 10 ? 0 : null}
                  {getFormattedCount(d?.disLikes)}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="commentAndShare">
          {d?.commentsCount > 0 && (
            <span
              className="socialhoverComp"
              onClick={() => {
                getDetailPage(d?.id);
              }}
            >
              {getFormattedCount(d?.commentsCount)} Comments
            </span>
          )}
          {d?.shares > 0 && (
            <span
              className="socialhoverComp"
              onClick={() => {
                setShareDetailsActive(true);
                setSharePostId(d?.id);
              }}
            >
              {getFormattedCount(d?.shares)} Shares
            </span>
          )}
        </div>
      </div>
      <div className="horizontalLine"></div>
      <div className="postBottomContainer">
        <div className="postBottomLeftContainer">
          <div
            className="postLikeHolder"
            onMouseOver={() => setIsLikeHovered(d?.id)}
            onMouseLeave={() => setIsLikeHovered(null)}
          >
            {isLikeHovered === d?.id && (
              <div className="doLikeList">
                <div className="likeList">
                  <div
                    className="thumbLike"
                    onClick={async () => {
                      let newD = d;
                      if (d?.isDisLiked !== null || d?.isDisLiked?.isLike) {
                        newD = await disLikeRemoveClickHandler(
                          d?.id,
                          d,
                          postData,
                          setPostData,
                          dispatch,
                          removePostDisLike,
                          setIsDisLikeHovered
                        );
                      }
                      likePostClickHandler(
                        d?.id,
                        newD,
                        "thumb",
                        postData,
                        setPostData,
                        dispatch,
                        likePost,
                        setIsLikeHovered,
                        getData
                      );
                    }}
                  >
                    <img src={SocialThumbLike} alt="" />
                  </div>
                  <div
                    className="clapLike"
                    onClick={async () => {
                      let newD = d;
                      if (d?.isDisLiked !== null || d?.isDisLiked?.isLike) {
                        newD = await disLikeRemoveClickHandler(
                          d?.id,
                          d,
                          postData,
                          setPostData,
                          dispatch,
                          removePostDisLike,
                          setIsDisLikeHovered
                        );
                      }
                      likePostClickHandler(
                        d?.id,
                        newD,
                        "clap",
                        postData,
                        setPostData,
                        dispatch,
                        likePost,
                        setIsLikeHovered,
                        getData
                      );
                    }}
                  >
                    <img src={SocialClapLike} alt="" />
                  </div>
                  <div
                    className="knowledgeLike"
                    onClick={async () => {
                      let newD = d;
                      if (d?.isDisLiked !== null || d?.isDisLiked?.isLike) {
                        newD = await disLikeRemoveClickHandler(
                          d?.id,
                          d,
                          postData,
                          setPostData,
                          dispatch,
                          removePostDisLike,
                          setIsDisLikeHovered
                        );
                      }
                      likePostClickHandler(
                        d?.id,
                        newD,
                        "knowledge",
                        postData,
                        setPostData,
                        dispatch,
                        likePost,
                        setIsLikeHovered,
                        getData
                      );
                    }}
                  >
                    <img src={SocialKnowledgeLike} alt="" />
                  </div>
                  <div
                    className="heartLike"
                    onClick={async () => {
                      let newD = d;
                      if (d?.isDisLiked !== null || d?.isDisLiked?.isLike) {
                        newD = await disLikeRemoveClickHandler(
                          d?.id,
                          d,
                          postData,
                          setPostData,
                          dispatch,
                          removePostDisLike,
                          setIsDisLikeHovered
                        );
                      }
                      likePostClickHandler(
                        d?.id,
                        newD,
                        "heart",
                        postData,
                        setPostData,
                        dispatch,
                        likePost,
                        setIsLikeHovered,
                        getData
                      );
                    }}
                  >
                    <img src={SocialHeartLike} alt="" />
                  </div>
                </div>
              </div>
            )}
            <div
              className="likeBtn"
              onClick={async () => {
                if (d?.isLiked === null || d?.isLiked?.isLike === false) {
                  let newD = d;
                  if (d?.isDisLiked !== null || d?.isDisLiked?.isLike) {
                    newD = await disLikeRemoveClickHandler(
                      d?.id,
                      d,
                      postData,
                      setPostData,
                      dispatch,
                      removePostDisLike,
                      setIsDisLikeHovered
                    );
                  }
                  likePostClickHandler(
                    d?.id,
                    newD,
                    "thumb",
                    postData,
                    setPostData,
                    dispatch,
                    likePost,
                    setIsLikeHovered,
                    getData
                  );
                } else {
                  likeRemoveClickHandler(
                    d?.id,
                    d,
                    postData,
                    setPostData,
                    dispatch,
                    removePostLike,
                    setIsLikeHovered
                  );
                }
              }}
            >
              {(d?.isLiked === null || !d?.isLiked?.isLike) && (
                <div className="likeGreyIconContainer">
                  <img
                    style={{ width: "20px" }}
                    src={
                      isLikeHovered === d?.id
                        ? SocialThumbLike
                        : SocialLikeGreyIcon
                    }
                    alt="like"
                  />
                  <span
                    className={"unlike"}
                    style={isLikeHovered === d?.id ? { color: "#1296B0" } : {}}
                  >
                    Like
                  </span>
                </div>
              )}
              {d?.isLiked?.likeType === "thumb" && d?.isLiked?.isLike && (
                <div className="likBlueIconContainer">
                  <img src={SocialThumbLike} alt="like" />
                  <span className={"liked"}>Like</span>
                </div>
              )}
              {d?.isLiked?.likeType === "clap" && d?.isLiked?.isLike && (
                <div className="likeClapIconContainer">
                  <img src={SocialClapLike} alt="like" />
                  <span className={"clapLike"}>Clap</span>
                </div>
              )}
              {d?.isLiked?.likeType === "knowledge" && d?.isLiked?.isLike && (
                <div className="likeKnowledgeIconContainer">
                  <img src={SocialKnowledgeLike} alt="like" />
                  <span className={"kowledgeLike"}>Knowledge</span>
                </div>
              )}
              {d?.isLiked?.likeType === "heart" && d?.isLiked?.isLike && (
                <div className="likeHeartIconContainer">
                  <img src={SocialHeartLike} alt="like" />
                  <span className={"heartLike"}>Heart</span>
                </div>
              )}
            </div>
          </div>
          <div
            className="postDislikeHolder"
            onMouseOver={() => setIsDisLikeHovered(d?.id)}
            onMouseLeave={() => setIsDisLikeHovered(null)}
          >
            {isDisLikeHovered === d?.id && (
              <div className="doLikeList">
                <div className="likeList">
                  <div
                    className="thumbLike"
                    onClick={async () => {
                      let newD = d;
                      if (d?.isLiked !== null || d?.isLiked?.isLike) {
                        newD = await likeRemoveClickHandler(
                          d?.id,
                          d,
                          postData,
                          setPostData,
                          dispatch,
                          removePostLike,
                          setIsLikeHovered
                        );
                      }
                      disLikePostClickHandler(
                        d?.id,
                        newD,
                        "bad",
                        postData,
                        setPostData,
                        dispatch,
                        disLikePost,
                        setIsDisLikeHovered,
                        getData
                      );
                    }}
                  >
                    <img src={SocialPostBadIcon} alt="" />
                  </div>
                  <div
                    className="clapLike"
                    onClick={async () => {
                      let newD = d;
                      if (d?.isLiked !== null || d?.isLiked?.isLike) {
                        newD = await likeRemoveClickHandler(
                          d?.id,
                          d,
                          postData,
                          setPostData,
                          dispatch,
                          removePostLike,
                          setIsLikeHovered
                        );
                      }
                      disLikePostClickHandler(
                        d?.id,
                        newD,
                        "angry",
                        postData,
                        setPostData,
                        dispatch,
                        disLikePost,
                        setIsDisLikeHovered,
                        getData
                      );
                    }}
                  >
                    <img src={SocialPostAngryIcon} alt="" />
                  </div>
                  <div
                    className="knowledgeLike"
                    onClick={async () => {
                      let newD = d;
                      if (newD?.isLiked !== null || newD?.isLiked?.isLike) {
                        newD = await likeRemoveClickHandler(
                          d?.id,
                          d,
                          postData,
                          setPostData,
                          dispatch,
                          removePostLike,
                          setIsLikeHovered
                        );
                      }
                      disLikePostClickHandler(
                        d?.id,
                        newD,
                        "sad",
                        postData,
                        setPostData,
                        dispatch,
                        disLikePost,
                        setIsDisLikeHovered,
                        getData
                      );
                    }}
                  >
                    <img src={SocialPostSadIcon} alt="" />
                  </div>
                  <div
                    className="heartLike"
                    onClick={async () => {
                      let newD = d;
                      if (d?.isLiked !== null || d?.isLiked?.isLike) {
                        newD = await likeRemoveClickHandler(
                          d?.id,
                          d,
                          postData,
                          setPostData,
                          dispatch,
                          removePostLike,
                          setIsLikeHovered
                        );
                      }
                      disLikePostClickHandler(
                        d?.id,
                        newD,
                        "hate",
                        postData,
                        setPostData,
                        dispatch,
                        disLikePost,
                        setIsDisLikeHovered,
                        getData
                      );
                    }}
                  >
                    <img src={SocialPostHateIcon} alt="" />
                  </div>
                </div>
              </div>
            )}
            <div
              className="dislikeBtn"
              onClick={async () => {
                if (d?.isDisLiked === null || d?.isDisLiked?.isLike === false) {
                  let newD = d;
                  if (d?.isLiked !== null || d?.isLiked?.isLike) {
                    newD = await likeRemoveClickHandler(
                      d?.id,
                      d,
                      postData,
                      setPostData,
                      dispatch,
                      removePostLike,
                      setIsLikeHovered
                    );
                  }
                  disLikePostClickHandler(
                    d?.id,
                    newD,
                    "bad",
                    postData,
                    setPostData,
                    dispatch,
                    disLikePost,
                    setIsDisLikeHovered,
                    getData
                  );
                } else {
                  disLikeRemoveClickHandler(
                    d?.id,
                    d,
                    postData,
                    setPostData,
                    dispatch,
                    removePostDisLike,
                    setIsDisLikeHovered
                  );
                }
              }}
            >
              {(d?.isDisLiked === null || !d?.isDisLiked?.isLike) && (
                <div className="dislikeGreyIconContainer">
                  <img
                    style={{ width: "19px" }}
                    src={
                      isDisLikeHovered === d?.id
                        ? SocialPostDislikeIcon
                        : SocialPostDislikeGreyIcon
                    }
                    alt="like"
                  />
                  <span
                    className={"unlike"}
                    style={
                      isDisLikeHovered === d?.id ? { color: "#1296B0" } : {}
                    }
                  >
                    Dislike
                  </span>
                </div>
              )}
              {d?.isDisLiked?.likeType === "bad" && d?.isDisLiked?.isLike && (
                <div className="dislikeRedIconContainer">
                  <img src={SocialPostBadIcon} alt="like" />
                  <span className={"disliked"}>Dislike</span>
                </div>
              )}
              {d?.isDisLiked?.likeType === "sad" && d?.isDisLiked?.isLike && (
                <div className="dislikesadIconContainer">
                  <img src={SocialPostSadIcon} alt="like" />
                  <span className={"sadLike"}>Sad</span>
                </div>
              )}

              {d?.isDisLiked?.likeType === "hate" && d?.isDisLiked?.isLike && (
                <div className="dislikehateIconContainer">
                  <img src={SocialPostHateIcon} alt="like" />
                  <span className={"hateLike"}>Hate</span>
                </div>
              )}

              {d?.isDisLiked?.likeType === "angry" && d?.isDisLiked?.isLike && (
                <div className="dislikeGreyIconContainer">
                  <img src={SocialPostAngryIcon} alt="like" />
                  <span className={"angryLike"}>Angry</span>
                </div>
              )}
            </div>
          </div>
          <div
            onMouseOver={() => setIsCommentHovered(d?.id)}
            onMouseLeave={() => setIsCommentHovered(null)}
            className="postCommentHolder"
            onClick={() => {
              getDetailPage(d?.id);
            }}
          >
            <img
              src={
                isCommentHovered === d?.id
                  ? SocialCommentColorIcon
                  : SocialCommentIcon
              }
              alt="like"
              style={{ height: "19px" }}
            />
            <span
              style={isCommentHovered === d?.id ? { color: "#1296B0" } : {}}
            >
              Comment
            </span>
          </div>
        </div>
        <div className="postBottomRightContainer">
          <div
            onMouseOver={() => setIsShareHovered(d?.id)}
            onMouseLeave={() => setIsShareHovered(null)}
            className="postSaveHolder"
            onClick={() => {
              setSharePostData(d);
              setSharePostId(d?.id);
              navigate("post/share/" + d?.id);
            }}
          >
            <img
              src={
                isShareHovered === d?.id
                  ? SocialShareColorIcon
                  : SocialShareIcon
              }
              alt="like"
              style={{ height: "19px" }}
            />
            <span style={isShareHovered === d?.id ? { color: "#1296B0" } : {}}>
              Share
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialLikeAndDislikeComponent;
