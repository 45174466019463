import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
const opt = [{ label: "select", value: null }];
const type = [
  { label: "IT Infra", value: "IT Infra" },
  { label: "SAP", value: "SAP" },
  { label: "IT Application ", value: "IT Application " },
  { label: "My Assets ", value: "My Assets " },
];
const CategoryForm = ({ data, editID }) => {
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);
  const [page, setPage] = React.useState(editID ? 0 : 1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");
  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
      }
    } catch (error) {
    } finally {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };
  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    // if (editID) {
    //   setPage(0);
    // } else setPage(1);
    setListData([]);
  }, [currentUrl, data]);

  React.useEffect(() => {
    if (editID) {
      // setPage(0);
      let idArray;
      if (data?.exceptionCatalogue?.ownerEmployeeId) {
        idArray = [data?.exceptionCatalogue?.ownerEmployeeId];
      } else idArray = [];

      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);
  useEffect(() => {
    document.title = `PeopleSol - Category`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={50}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Type   "} mandatory={true} />
        <SelectForm
          values={
            data ? type.filter((options) => options.value === data?.Type) : opt
          }
          name={"type"}
          options={type}
          placeholder={" Select Type "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Service Owner"} mandatory={true} />
        <SelectForm
          name={"ownerEmployeeId"}
          placeholder={" Select Service Owner "}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
    </div>
  );
};
export { CategoryForm };
