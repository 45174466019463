import CloseIcon from "../../../Assets/CloseIcon.svg";
import React from "react";
import { IconButton } from "@mui/material";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { postRequest } from "../../../Services/axios";
import { useState } from "react";
import { useEffect } from "react";
import { Loader } from "../../../Components/FormComponent/Loader";
const OrganisationPopup = ({
  selIdprop,
  handleClose,
  content,
  selectedRows,
  type,
}) => {
  const [tdetails, setDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const details = async (id) => {
    setLoading(true);
    const result = await postRequest(
      `SkillsEmployeeController/GetEmployeeSkills?employeeId=${id}`
    );
    setDetails(result?.data);
    setLoading(false);
  };
  useEffect(() => {
    details(selIdprop);
  }, []);
  const getColors = (val) => {
    if (val == 1) return ({ trailColor: "#fff2f0", pathColor: "#fb7158" })
    else if (val == 2) return ({ trailColor: "#fff4e5", pathColor: "#f7941d" })

    else if (val == 3) return ({ trailColor: "#eaf5ff", pathColor: "#77a2c7" })

    else if (val == 4) return ({ trailColor: "#d4f2f1", pathColor: "#5bc4bf" })

    else if (val == 5) return ({ trailColor: "#D8F0F2", pathColor: "#1296b0" })
    else return ({ pathColor: "#1296B0", trailColor: "#D8F0F2" })



  }
  if (loading) return <Loader />;
  else
    return (
      <div style={{ height: "620px", overflowY: "auto", backgroundColor: "white" }}>
        {tdetails &&
          tdetails?.map((item, index) => (
            <div key={index} className="mainOrganisationPopup">
              <div className="OrganisationPopupHeading">{item?.skillset}</div>
              <div className="organisationPopupcontent">
                {item?.skills?.map((itemSkill, indexSkill) => {
                  const colors = getColors(itemSkill?.level);
                  return (
                    <div key={indexSkill} className="circleOrganisationPopup" >
                      <CircularProgressbarWithChildren
                        value={`${(itemSkill?.level / 5) * 100}`}
                        text={`${itemSkill?.level}/5`}
                        strokeWidth={4.5}
                        styles={buildStyles({
                          textColor: colors.pathColor,
                          pathColor: colors.pathColor,
                          trailColor: colors.trailColor,
                          rotation: 0,
                          strokeLinecap: "round",
                        })}
                      ></CircularProgressbarWithChildren>
                      <div className="circleOrganisationname">
                        {itemSkill?.skillName}
                      </div>
                    </div>)
                })}
              </div>
            </div>
          ))}
      </div>
    );
  //   if (loading) return "";
  //   else
  //     return (
  //     );
};

export { OrganisationPopup };
