import React, { useState, useEffect } from "react";
import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { postRequest } from "../../../../Services/axios";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { VisitorsRepeatedTable } from "../../Components/VisitorsRepeatedTable";
import { Loader } from "../../../../Components/FormComponent/Loader";

const purposeOptions = [
  { label: "Meeting", value: "Meeting" },
  { label: "For Visit", value: "For Visit" },
  { label: "Personal", value: "Personal" },
  { label: "Official", value: "Official" },
];
const vehicleOptions = [
  { label: "Two Wheeler", value: "Two Wheeler" },
  { label: "Four Wheeler", value: "Four Wheeler" },
  { label: "None", value: "None" },
];

export const CreateVisitorsComp = ({ formValues, setFormValues }) => {
  const [locationGroup, setLocationGroup] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);

  useEffect(() => {
    document.title = `PeopleSol - Create Visitor`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getOption = async () => {
    try {
      const result = await postRequest("VisitorsController/GetLocationGroups");
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setLocationGroup(listData);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getOption();
  }, []);
  const dispatch = useDispatch();
  return (
    <div className="formcustom twoColumn">
      <div className="gridItemVisitor" style={{ width: "100%" }}>
        <div className="headingForm">Who is coming?</div>
        <div className="repeatedSectionContainer">
          <FieldArray name="Visitlist">
            {(fieldArrayProps) => {
              const { insert, push, remove, form } = fieldArrayProps;
              const { values } = form;
              const { Visitlist } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Visitor's Full Name"}
                            mandatory={true}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Phone Number"}
                            mandatory={true}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Email"} />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {Visitlist?.map((_, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            name={`Visitlist[${index}].name`}
                            maxLength={150}
                            type={"text"}
                            placeholder={"Please Enter The Name"}
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <InputCustomFormik
                            placeholder={"Please Enter The Phone Number"}
                            type={"text"}
                            maxLength={10}
                            name={`Visitlist[${index}].phoneNumber`}
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <InputCustomFormik
                            name={`Visitlist[${index}].email`}
                            type="email"
                            placeholder={"Please Enter The Email"}
                          />
                        </StyledTableCell>

                        {/* 3rd column */}
                        {/* <StyledTableCell>
                          <TextAreaFormik
                              maxLength={500}
                              name={`Visitlist[${index}].Description`}
                              placeholder="Enter Role Description"
                          />
                      </StyledTableCell> */}
                        {/* 4th column */}

                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              Visitlist.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({ name: "", phoneNumber: "", email: "" })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      </div>
      <div className="gridItemVisitor" style={{ width: "100%" }}>
        <div className="headingForm">Visit Plan</div>
        <div className="repeatedSectionContainer">
          {/* <LabelCustom labelName={"Details"} /> */}
          {loadingForm ? (
            <Loader />
          ) : (
            <FieldArray name="Visitlocationlist">
              {(fieldArrayProps) => {
                const { insert, push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { Visitlocationlist } = values;
                return (
                  <StyledTableCont>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S. No."} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Location Group "}
                              mandatory={true}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Location "}
                              mandatory={true}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"To meet whom?"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Visiting date"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Visiting time"}
                              mandatory={true}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {Visitlocationlist?.map((_, index) => {
                        return (
                          <VisitorsRepeatedTable
                            formValues={formValues}
                            values={values}
                            locationGroup={locationGroup}
                            index={index}
                            setFormValues={setFormValues}
                            Visitlocationlist={Visitlocationlist}
                            remove={remove}
                            push={push}
                            insert={insert}
                            setLoadingForm={setLoadingForm}
                          />
                        );
                      })}
                    </Table>
                  </StyledTableCont>
                );
              }}
            </FieldArray>
          )}
        </div>
      </div>
      {/* )} */}

      <div>
        <LabelCustom labelName={"What is the purpose? "} mandatory={true} />
        <SelectForm
          name="purpose"
          options={purposeOptions}
          placeholder={" Select Employee"}
        />
      </div>
      <div>
        <LabelCustom labelName={"In which vehicle? "} mandatory={true} />
        <SelectForm
          name="vehicleType"
          options={vehicleOptions}
          placeholder={" Select Employee"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Vehicle Number"} mandatory={true} />
        <InputCustomFormik
          maxLength={10}
          name="VehicleNumber"
          placeholder={" Select Vehicle Number"}
        />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Eg: MP04SD0786
        </span>
      </div>
      <div>
        <LabelCustom labelName={"From which company? "} />
        <InputCustomFormik
          maxLength={150}
          name="company"
          type={"text"}
          placeholder={"Please Enter The Company"}
        />
      </div>
      <div>
        <LabelCustom labelName={"From which location? "} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="location"
          type={"text"}
          placeholder={"Please Enter The Locations"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Meeting Desciption "} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="remarks"
          type={"text"}
          placeholder={"Please Enter Remarks"}
        />
      </div>
    </div>
  );
};
