/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";

import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { Loader } from "../../../../../Components/FormComponent/Loader";

export const AdminUpdate = ({ id, data, type, color, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [updetails, setupdetails] = useState(false);
  const [spec, setSpec] = useState([]);
  const [other, setOther] = useState([]);
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const getUpdetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `facilityrequests/UpdateFacilities?ID=${id}`
      );
      setupdetails(result?.data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Admin Update`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  // //console.log("spec",updetails)
  const getSpec = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `facilityrequests/FindSpecifications?ID=${updetails?.categoryid}`
      );
      let listData = [];
      result?.data?.data?._specifications?.map((item) => {
        return  listData.push({ Id: item.facilitySpecificationItem.name, Value: "" });
      });
      setSpec(listData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const dispatchAdminUpdate = async (value) => {
    try {
      const result = await postRequest(
        `facilityrequests/UpdateFacilities`,
        value
      );

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      handleClose();
      data();
    }
  };
  const getCategory = async () => {
    try {
      const result = await getRequest(
        `FacilityCategories/GetFacilityCategories`
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOther(result.data);
      setCategoryList(listData);
    } catch (error) {
    } finally {
    }
  };
  const getFacility = async () => {
    try {
      const result = await getRequest(`FacilityTypes/GetFacilityType`);
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getCategory();
    getFacility();
    getUpdetails();
  }, []);
  const validationSchema = Yup.object({
    Description: Yup.string().required("Enter Description"),
    Specifications: Yup.array().of(
      Yup.object().shape({
        Value: Yup.string().required(" Enter Value "),
      })
    ),
  });
  const onSubmit = async (values) => {
    const requestArray = [];
    const Create = {
      Specifications: values?.Specifications?.map((value, index) => {
        requestArray.push({
          Id: other[index]?.id,
          Value: value?.Value,
        });
      }),
    };

    const creatReq = {
      ...values,
      Specifications: requestArray,
    };
    dispatchAdminUpdate(creatReq);
  };
  useEffect(() => {
    if (updetails?.categoryid) {
      getSpec();
    }
  }, [updetails?.categoryid]);
  const getValues = () => {
    let returnArray = [];
    returnArray = spec;
    return returnArray;
  };
  if(loading)return <Loader/>
  else return (
    <Formik
      enableReinitialize
      initialValues={{
        FacilityId: updetails ? updetails?.facilities?.facilityId : "",
        CategoryId: updetails ? updetails?.facilities?.categoryId : "",
        FacilityMaterialId: updetails
          ? updetails?.facilities?.facilityMaterialId
          : "",
        Id: id,
        Description: updetails ? updetails?.facilities?.description : "",
        Specifications: getValues(),
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values }) => {
        return (
          <Form>
            <div className="formcustom">
            <div>
              <LabelCustom labelName={"Facility Reference Number"} mandatory={true} />
              <InputCustomFormik
                disabled
                name={"FacilityId"}
                placeholder="Enter Facility Reference Number"
              />
            </div>
            <div>
              <LabelCustom labelName={"Facility Category"} mandatory={true} />
              <SelectForm
                disabled
                name={"CategoryId"}
                placeholder="Select Facility category"
                options={categoryList}
              />
            </div>
            <div>
              <LabelCustom labelName={"Facility Type"} mandatory={true} />
              <SelectForm
                disabled
                name={"FacilityMaterialId"}
                placeholder="Select Facility Type"
                options={employeeList}
              />
            </div>
            <div>
              <LabelCustom labelName={"Facility Description"} mandatory={true} />
              <TextAreaFormik
                maxlength="150"
                name={"Description"}
                placeholder="Enter Description"
              />
            </div>
            </div>
            <div className="headingForm">Facility Specifications</div>
            <div>
              <FieldArray name="Specifications">
                {(fieldArrayProps) => {
                  const { form } = fieldArrayProps;
                  const { values } = form;
                  const { Specifications } = values;
                  return (
                    <StyledTableCont>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              sx={{
                                padding: "0px !important",
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"S.No"}/>
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"Spec Name"} />
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              <LabelCustom labelName={"Spec Value"} mandatory={true}/>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {Specifications?.map((Specifications, index) => (
                          <TableBody key={index}>
                            {/* 1st column */}
                            <StyledTableCell align="center">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell>
                              <InputCustomFormik
                                name={`Specifications[${index}].Id`}
                                disabled
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <InputCustomFormik
                                name={`Specifications[${index}].Value`}
                                type="text"
                                placeholder="Enter Spec Value"
                              />
                            </StyledTableCell>
                          </TableBody>
                        ))}
                      </Table>
                    </StyledTableCont>
                  );
                }}
              </FieldArray>
            </div>
            <div className="createFootCust">
              <button
                type="button"
                className="button secondaryButton"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton"
                type="submit"
                style={{ background: "var(--primary)", color: "white" }}
              >
                {type}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
