import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { TeamsForm } from "./Form/TeamsForm";
import { teamsSchema } from "./TeamsSchemas";
import { useEffect } from "react";

const CreateTeams = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const [detail, setDetail] = React.useState([]);
  const dispatch = useDispatch();

  const getDetails = async () => {
    try {
      const result = await getRequest(`HRHelpdeskTeams/Details/${data}`);
      setDetail(result.data);
      console.log("datas data", result);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInitalAnswerValues = () => {
    let returnArray = [];
    data
      ? detail?.teamsMembers?.map((item) => {
        let loctionarray = [];
        item?.hrHelpdeskTeammemberlocations?.map((it) => {
          return loctionarray.push(it.locationId);
        });
        return returnArray.push({
          employeeId: item.employeeId,
          level: item.level,
          locationIds: loctionarray,
        });
      })
      : (returnArray = [{ employeeId: "", level: "", locationIds: [] }]);

    return returnArray;
  };
  const initialvalue = {
    name: "",
    teamsMembers: getInitalAnswerValues(),
  };
  const postFormData = async (values) => {
    try {
      const result = await postRequest("HRHelpdeskTeams/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
      returnPage();
    } catch (error) { }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("HRHelpdeskTeams/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const onSubmit = async (values) => {
    const edit = {
      ...values,
      id: detail?.id,
    };

    const create = {
      ...values,
    };
    data ? Edit(edit) : postFormData(create);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/hrdesk/teams");
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Team`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <FormCustomFormik
      ButtonLabel={data ? "Update HR Desk Teams" : "Create HR Desk Teams"}
      FormHeading={data ? "Edit HR Desk Teams" : "Create HR Desk Teams"}
      validationSchema={teamsSchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
            ...initialvalue,
            name: detail?.name,
          }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<TeamsForm data={detail} editID={data ? true : false} />}
    />
  );
};
export { CreateTeams };
