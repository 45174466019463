
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addMessagesToCache, getMessagesByConversation, searchMessagesByCaption, searchConversations, addConversationsToCache, searchMessagesByConversationAndCaption } from '../db';

// Load messages by conversationId
export const loadMessagesByConversation = createAsyncThunk(
  'messages/loadByConversation',
  async (conversationId) => {
    const messages = await getMessagesByConversation(conversationId);
    return messages;
  }
);

// Search messages by caption
export const searchMessages = createAsyncThunk(
  'messages/search',
  async (query) => {
    const messages = await searchMessagesByCaption(query);
    return messages;
  }
);

export const searchMessagesByConversationAndCaptionThunk = createAsyncThunk(
  'messages/searchByConversationAndCaption',
  async (data) => {
    console.log(data.conversationId, "----before search--", data.captionQuery)

    const messages = await searchMessagesByConversationAndCaption(data.conversationId, data.captionQuery);
    console.log("'''''''''''''''")
    return messages;
  }
);

export const searchConversationsThunk = createAsyncThunk(
  'conversations/search',
  async (query) => {
    const filteredConversations = await searchConversations(query);
    return filteredConversations;
  }
);

const initialState = {
  conversationList: [],
  activeMessageList: [],
  contactList: [],
  blockedContacts: [],
  bookMarkedMessages: [],
  currentChatDetails: {},
  userDetails: {},
  messages: [],
  conversations: [],
  status: 'idle',
  searchResults: [],
  searchMeassageResults: [],
  conversationSearchResults: [],
  userSettings: {}
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setConversationList: (state, action) => {
      state.conversationList = action.payload;
    },
    setActiveMessageList: (state, action) => {
      state.activeMessageList = action.payload;
    },
    addNewActiveMessage: (state, action) => {
      state.activeMessageList.push(action.payload);
    },
    setContactList: (state, action) => {
      state.contactList = action.payload;
    },
    setBlockedContacts: (state, action) => {
      state.blockedContacts = action.payload;
    },
    setBookMarkedMessages: (state, action) => {
      state.bookMarkedMessages = action.payload;
    },
    setCurrentChatDetails: (state, action) => {
      state.currentChatDetails = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    addMessages: (state, action) => {
      // Add new messages to Redux and cache
      if (action && action.payload) {
        state.messages.push(...action.payload);
        addMessagesToCache(action.payload); // Add messages to IndexedDB
      }
    },
    setUserSettings: (state, action) => {
      state.userSettings = action.payload;
    },
    addConversation: (state, action) => {
      //TODO doubtful ---------------------------------------------------------------------------
      state.conversations.push(...action.payload);
      addConversationsToCache(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMessagesByConversation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadMessagesByConversation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.messages = action.payload;
      })
      .addCase(searchMessages.fulfilled, (state, action) => {
        state.searchResults = action.payload;
      })
      .addCase(searchConversationsThunk.fulfilled, (state, action) => {
        state.conversationSearchResults = action.payload; // Results for conversation search
      })
      .addCase(searchMessagesByConversationAndCaptionThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.searchMeassageResults = action.payload; // Store the search results
      });
  },
});

export const {
  setConversationList,
  setActiveMessageList,
  addNewActiveMessage,
  setContactList,
  setUserSettings,
  setBlockedContacts,
  setBookMarkedMessages,
  setCurrentChatDetails,
  setUserDetails,
  addMessages,
  addConversation
} = ChatSlice.actions;
export default ChatSlice.reducer;
