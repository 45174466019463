import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateGatePassComp } from "./FormComp/CreateGatePassComp";
import { confirmAlert } from "react-confirm-alert";
import dateFormat from "dateformat";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const CreateGatePass = (props) => {
  const { state } = useLocation();
  const [initialValues, setInitialValues] = useState({
    place: "",
    date: "",
    contactPerson: "",
    purpose: "",
    Type: "self",
    AlternatePhnNo: "",
    employeeId: null,
    phnNo: "",
    WillReturn: "Yes",
    ReturnTime: "",
  });
  const [detailsform, setDetailsform] = useState({});

  const data = state?.id;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `GatePassRequestsController/Details?Id=${data}`
      );
      setDetailsform(result.data.data);
      setInitialValues((prev) => ({
        ...prev,
        place: result.data.data.place ? result.data.data.place : "",
        date: data
          ? result.data.data.date && new Date(result.data.data.date)
          : "",
        contactPerson: result.data.data.contactPerson
          ? result.data.data.contactPerson
          : "",
        purpose: result.data.data.purpose ? result.data.data.purpose : "",
        Type: result.data.data.type ? result.data.data.type : "self",
        AlternatePhnNo: result.data.data.alternatePhnNo
          ? result.data.data.alternatePhnNo
          : "",
        employeeId: result.data.data.employeeId
          ? result.data.data.employeeId
          : null,
        phnNo: result.data.data.phnNo ? result.data.data.phnNo : "",
        WillReturn: data
          ? result.data.data?.willReturn
            ? "Yes"
            : "No"
          : "Yes",
        ReturnTime: result.data.data.returnTime
          ? new Date(result.data.data.returnTime)
          : "",
      }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  
  useEffect(() => {
    document.title = `PeopleSol - Create Gate Pass`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    if (data) getFormDetails();
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "GatePassRequestsController/Create",
        values
      );
      if (result.data.isSuccess === false) {
        dispatch(showToast({ text: result.data.message, severity: "error" }));
      } else {
        dispatch(showToast({ text: result.data.message, severity: "success" }));
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "GatePassRequestsController/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const getFoodValues = (item) => {
    let returnArray = [];
    data
      ? item?.map((item) => {
          returnArray.push({
            guestNames: item.guestNames,
            foodPreference: item.foodPreference,
            remarks: item.remarks,
          });
        })
      : (returnArray = [{ guestNames: "", foodPreference: "", remarks: "" }]);

    return returnArray;
  };

  function extractTime(date) {
    const daate = new Date(date);
    return (
      daate.getHours() * 60 * 60 + daate.getMinutes() * 60 + daate.getSeconds()
    );
  }
  const validationSchema = Yup.object(
    formValues?.WillReturn === "Yes"
      ? formValues?.Type === "employee"
        ? {
            Type: Yup.string().required("Please Select the Type"),
            employeeId: Yup.string().required("Please Select the Employee"),
            phnNo: Yup.string().required(
              "Please Enter the Employee Phone Number"
            ),
            place: Yup.string()
              .matches(/^\S.*$/, "Place should not start with spaces")
              .min(2, "Place must be of minimum 2 characters")
              .max(149, "Place must be less than 150 characters")
              .required("Please enter the Place"),
            date: Yup.string().required("Please select the date"),
            contactPerson: Yup.string()
              .matches(/^\S.*$/, "Contact Person should not start with spaces")
              .max(149, "Place must be less than 150 characters")
              .required("Please enter Contact Person "),
            purpose: Yup.string().required("Please select the Purpose"),
            // AlternatePhnNo: Yup.string().required("Please select the Phone number"),
            AlternatePhnNo: Yup.number()
              .typeError("Phone Number must be a number")
              .required("Please enter the Phone Number"),
            ReturnTime: Yup.string()
              .required("Please enter the Return Time")
              .test(
                "is-greater",
                "Return Time must be greater than Exit Time",
                function (value) {
                  const startTime = extractTime(formValues?.date);
                  const endTime = extractTime(value);

                  if (startTime && endTime) {
                    return endTime >= startTime;
                  }

                  return true; // Let other validations pass if Date or EndDate is not defined
                }
              ),
            // PhnNo: Yup.number()
            //   .typeError("Phone Number must be a number")
            //   .required("Please enter the Phone Number"),
          }
        : {
            Type: Yup.string().required("Please Select the Type"),
            // employeeId: Yup.string().required("Please Select the Employee"),
            // phnNo: Yup.string().required("Please Enter the Employee Phone Number"),
            place: Yup.string()
              .matches(/^\S.*$/, "Place should not start with spaces")
              .min(2, "Place must be of minimum 2 characters")
              .max(149, "Place must be less than 150 characters")
              .required("Please enter the Place"),
            date: Yup.string().required("Please select the date"),
            contactPerson: Yup.string()
              .matches(/^\S.*$/, "Contact Person should not start with spaces")
              .max(149, "Place must be less than 150 characters")
              .required("Please enter Contact Person "),
            purpose: Yup.string().required("Please select the Purpose"),
            // AlternatePhnNo: Yup.string().required("Please select the Phone number"),
            AlternatePhnNo: Yup.number()
              .typeError("Phone Number must be a number")
              .required("Please enter the Phone Number"),
            ReturnTime: Yup.string()
              .required("Please enter the Return Time")
              .test(
                "is-greater",
                "Return Time must be greater than Exit Time",
                function (value) {
                  const startTime = extractTime(formValues?.date);
                  const endTime = extractTime(value);

                  if (startTime && endTime) {
                    return endTime >= startTime;
                  }

                  return true; // Let other validations pass if Date or EndDate is not defined
                }
              ),
          }
      : formValues?.Type === "employee"
      ? {
          Type: Yup.string().required("Please Select the Type"),
          employeeId: Yup.string().required("Please Select the Employee"),
          phnNo: Yup.string().required(
            "Please Enter the Employee Phone Number"
          ),
          place: Yup.string()
            .matches(/^\S.*$/, "Place should not start with spaces")
            .min(2, "Place must be of minimum 2 characters")
            .max(149, "Place must be less than 150 characters")
            .required("Please enter the Place"),
          date: Yup.string().required("Please select the date"),
          contactPerson: Yup.string()
            .matches(/^\S.*$/, "Contact Person should not start with spaces")
            .max(149, "Place must be less than 150 characters")
            .required("Please enter Contact Person "),
          purpose: Yup.string().required("Please select the Purpose"),
          // AlternatePhnNo: Yup.string().required("Please select the Phone number"),
          AlternatePhnNo: Yup.number()
            .typeError("Phone Number must be a number")
            .required("Please enter the Phone Number"),

          // PhnNo: Yup.number()
          //   .typeError("Phone Number must be a number")
          //   .required("Please enter the Phone Number"),
        }
      : {
          Type: Yup.string().required("Please Select the Type"),
          // employeeId: Yup.string().required("Please Select the Employee"),
          // phnNo: Yup.string().required("Please Enter the Employee Phone Number"),
          place: Yup.string()
            .matches(/^\S.*$/, "Place should not start with spaces")
            .min(2, "Place must be of minimum 2 characters")
            .max(149, "Place must be less than 150 characters")
            .required("Please enter the Place"),
          date: Yup.string().required("Please select the date"),
          contactPerson: Yup.string()
            .matches(/^\S.*$/, "Contact Person should not start with spaces")
            .max(149, "Place must be less than 150 characters")
            .required("Please enter Contact Person "),
          purpose: Yup.string().required("Please select the Purpose"),
          // AlternatePhnNo: Yup.string().required("Please select the Phone number"),
          AlternatePhnNo: Yup.number()
            .typeError("Phone Number must be a number")
            .required("Please enter the Phone Number"),
        }
  );

  const onSubmit = (values) => {
    const payload = {
      id: data ? detailsform?.id : 0,
    };
    if (values) {    
      if (data) payload["id"] = data;
      payload["place"] = values.place;
      payload["contactPerson"] = values.contactPerson;
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      payload["date"] = new Date(values.date - tzoffset)
        .toISOString()
        .slice(0, -1);
      payload["purpose"] = values.purpose;
      payload["WillReturn"] = values.WillReturn === "Yes" ? true : false;
      if (formValues?.WillReturn === "Yes") {
        payload["ReturnTime"] =
          format(new Date(values.date), "yyyy-MM-dd") +
          "T" +
          format(new Date(values.ReturnTime), "HH:mm:ss");
      }
      payload["Type"] = values.Type ?? "self";
      payload["AlternatePhnNo"] = values.AlternatePhnNo ?? "self";
      payload["employeeId"] = values.employeeId ?? LoggedInUser?.id;
      payload["PhnNo"] = values.phnNo ?? LoggedInUser?.personalPhoneNumber;
    }
    data ? Edit(payload) : createPageData(payload);
  };

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
 
  return (
    <FormCustomFormik
      FormHeading={"Create Gate Pass Request"}
      ButtonLabel={data ? "Edit" : "Create"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      chooseFormValues={chooseFormValues}
      loading={loading}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={
        <CreateGatePassComp
          editID={data ? true : false}
          data={detailsform}
          formValues={formValues}
          setInitialValues={setInitialValues}
        />
      }
    />
  );
};

export { CreateGatePass };
