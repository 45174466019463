/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import {
  deleteWithPayloadRequest,
  getRequest,
  postRequest,
} from "../../../Services/axios";
import CancelIcon from "../../../Assets/CancelIcon.svg";
import HoldIcon from "../../../Assets/HoldIcon.svg";
import RejectIcon from "../../../Assets/RejectIcon.svg";
import ApproveIcon from "../../../Assets/ApproveIcon.svg";
import backbuttonpic from "../../../Assets/backbuttonpic.svg";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import { useRef } from "react";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { DatePickerCustom } from "../../../Components/FormComponent/DatePickerCustom";
import { DateRangePicker } from "../../../Components/FormComponent/DateRangePicker";

import { Loader } from "../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { BoxViewPMS } from "../../../Components/BoxView/BoxViewPMS";
import { TransactionTablePMS } from "../../../Components/Table/TransactionTablePMS";
import { TransactionTablePMSConfirmAction } from "./TransactionTablePMSConfirmAction";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import ReviewAndApproved from "../../Approvals/Components/AppraisalCriteriaApproval/ReviewAndApproved";
const PMSConfirmActionTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [boxView, setBoxView] = useState(true);
  const location = useLocation();
  const selectedId = location?.state?.id;

  const status = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Ticket Booked", value: "Ticket Booked" },
    { label: "Ticket Selected", value: "Ticket Selected" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Closed", value: "Closed" },
  ];

  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [tbodyDataA, setTbodyDataA] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [count, setCount] = useState(false);
  const [finalConfirm, setFinalConfirm] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [dateOfJoning, setDateOfJoining] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedMode, setSelectedMode] = useState([]);
  const [selectedRequestType, setSelectedRequestType] = useState([]);
  const [typeAll, setTypeAll] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const [selId, setSelId] = useState(false);
  const [selDepartmentId, setSelDepartmentId] = useState(false);
  const [selcriteriaId, setSelcriteriaId] = useState(false);
  const togglePopup = (type, color, id, deptid, criteriaId) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setSelDepartmentId(deptid);
    setSelcriteriaId(criteriaId);
    setIsOpen((prev) => !prev);
  };
  const closePopup = () => {
    setIsOpen((prev) => {
      if (prev) return false;
      else return prev;
    });
  };
  console.log("object selectedRows", selectedRows);
  const togglePopupapprove = (type) => {
    setTypeAll(type);
    setIsApprovePopup((prev) => !prev);
  };
  useEffect(() => {
    document.title = `PeopleSol - Appraisal Criteria`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const clickHandlerFunction = (id, type, color, deptid, criteriaId) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Ready for Release" &&
      type !== "HRReject"
    ) {
      togglePopup(type, color, id, deptid, criteriaId);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "HRReject"
      ) {
        await getRequest(`TravelHospitalityRequests/${type}/${id}`);
      } else if (type === "Confirm Department") {
        await getRequest(
          `pmsAppraisalCriteria/DepartmentConform?ID=${id}&Comment=${Remark}`
        );
      } else if (type === "Confirm All") {
        const reqObj = {
          id: selectedRows,
          Comments: Remark,
        };
        await postRequest(
          `pmsAppraisalCriteria/DepartmentConformMultiple`,
          reqObj
        );
      } else if (type === "Ready for Release") {
        await getRequest(`TravelHospitalityRequests/HRApprove/${id}`);
      } else {
        await getRequest(`TravelHospitalityRequests/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      setSelectedRows([]);
      getListData();
    }
  };

  const { LoggedInUser } = useSelector((state) => state.auth);

  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    CriteriaId: selectedId,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getCheckStatus = (data) => {
    if (
      data?.status !== "Cancelled" &&
      data?.status !== "Rejected" &&
      data?.status !== "Released" &&
      data?.status !== "Closed" &&
      data?.status !== "Resolved" &&
      data?.status !== "Ticket Booked"
    ) {
      return true;
    }
    return false;
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "pmsAppraisalCriteria/AllDepartmentList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result?.data?.data?.map((item) =>
            tempData?.push({
              ...item?.item,
              isLinkedToHotel: item?.item?.isLinkedTravelHotel ? "Yes" : "No",
              travelMode: item?.item?.mode,
              travelDate: item?.item?.fromDate,
              requestType: item?.item?.type,
              from: item?.item?.pickupLocation,
              to: item?.item?.dropLocation,
              //   actions: getMyListActions(item),
              actions: getMyListActions(
                item,
                item?.item?.departmentId,
                item?.item?.criteriaId
              ),
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              status: item?.item?.departmentStatus,
              statusColor: item?.statusColor,
              departmentName: item?.item?.name,
              action:
                item?.actions[0]?.type === "Confirm Department" ? (
                  <div
                    style={{
                      color: "white",
                      backgroundColor: "#1296B0",
                      textAlign: "center",
                      padding: "4px",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      togglePopup(
                        "Confirm Department",
                        "color",
                        item?.actions[0]?.id
                      )
                    }
                  >
                    Confirm
                  </div>
                ) : (
                  <div></div>
                ),
              // moreActions: getMoreActions(item?.actions),
              checkActive:
                item?.actions[0]?.type === "Confirm Department" ? true : false,
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({
              checkActive,
              comment,
              department,
              employeeId,
              inTime,
              outTime,
              managerId,
              nextApprover,
              nextApproverId,
              nextRequestId,
              personNumber,
              reportingOfficer,
              statusColor,
              actions,
              moreActions,
              ...rest
            }) => ({
              ...rest,
            })
          );
          const finalArrayA = clippedArray?.map(
            ({
              id,
              actions,
              status,
              departmentName,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              actions,
              status,
              departmentName,
              createdOn,
              lastUpdatedOn,
            })
          );
          const finalArray = clippedArray?.map(
            ({
              id,
              requestNumber,
              status,
              departmentName,
              createdOn,
              lastUpdatedOn,
              action,
            }) => ({
              id,
              requestNumber,
              status,
              departmentName,
              createdOn,
              lastUpdatedOn,
              action,
            })
          );
          setTbodyData(finalArray);
          setTbodyDataA(finalArrayA);
          setCount(result?.data?.summary?.allCount);
          setFinalConfirm(result?.data?.summary?.isFinalConform);
          calculateTotalPages(result?.data?.summary?.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  console.log("object originalData", originalData);
  const handleEdit = (id) => {
    navigate("/pmsmaster/goalsetting/appraisalcriteria/create", {
      state: { id },
    });
  };
  const getMyListActions = (data, deptid, criteriaId) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(criteriaId, deptid),
      },
    ];
    if (data?.actions?.length > 0) {
      data?.actions?.map((action) => {
        if (action.type === "Approve") {
          returnActionArray.push({
            img: <img src={ApproveIcon} alt="Approve" />,
            action: "Approve Request",
            clickAction: () =>
              clickHandlerFunction(
                action?.id,
                "Approve",
                "rgb(255, 128, 0)",
                deptid,
                criteriaId
              ),
          });
        } else if (action.type === "Reject") {
          returnActionArray.push({
            img: <img src={RejectIcon} alt="Reject" />,
            action: "Reject Request",
            clickAction: () =>
              clickHandlerFunction(
                action?.id,
                "Reject",
                "red",
                deptid,
                criteriaId
              ),
          });
        } else if (action.type === "Hold") {
          returnActionArray.push({
            img: <img src={HoldIcon} alt="Hold" />,
            action: "Hold Request",
            clickAction: () =>
              clickHandlerFunction(
                action?.id,
                "Hold",
                "rgb(255, 128, 0)",
                deptid,
                criteriaId
              ),
          });
        }
        return null;
      });
    }

    return returnActionArray;
  };
  const actionData = [
    {
      img: (
        <div
          style={{
            padding: "6px 12px",
            backgroundColor: "#0DB53C",
            color: "white",
            fontSize: "13px",
            textAlign: "center",
            borderRadius: "4px",
          }}
        >
          Details{" "}
        </div>
      ),
      action: "Appraisal Criteria Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <div
          style={{
            padding: "6px 12px",
            backgroundColor: "#FB7158",
            color: "white",
            fontSize: "13px",
            textAlign: "center",
            borderRadius: "4px",
          }}
        >
          Edit{" "}
        </div>
      ),
      action: "Edit Appraisal Criteria",
      clickAction: (id) => handleEdit(id),
    },
    {
      img: (
        <div
          style={{
            padding: "6px 12px",
            backgroundColor: "#F71D1D",
            color: "white",
            fontSize: "13px",
            textAlign: "center",
            borderRadius: "4px",
          }}
        >
          Cancel{" "}
        </div>
      ),
      action: "Cancel Appraisal Criteria",
      clickAction: (id) => cancelRequest(id),
    },
    {
      img: (
        <div
          style={{
            padding: "6px 12px",
            backgroundColor: "#1296B0",
            color: "white",
            fontSize: "13px",
            textAlign: "center",
            borderRadius: "4px",
          }}
        >
          Confirm{" "}
        </div>
      ),
      action: "Cancel Appraisal Criteria",
      clickAction: (id) => cancelRequest(id),
    },
  ];

  const getMoreActions = (actionList) => {
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold" &&
          action.type !== "Cancel"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () =>
              clickHandlerFunction(action?.id, action?.type, action?.color),
          });
        }
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getAllListCheckActive = (data) => {
    if (data?.actions?.length > 0) {
      return data?.actions?.some((x) => x.type === "Approve");
    }
    return false;
  };

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("appraisalcriteria"))
      getListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData?.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData?.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData?.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData?.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData?.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const getFormDetails = (criteriaId, departmentId) => {
    navigate(`/pmsmaster/goalsetting/appraisalcriteria/details/${criteriaId}`, {
      state: { criteriaId, departmentId },
    });
  };
  const cancelRequest = (id) => {
    clickHandlerFunction(id, "Cancel", "red");
  };

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData?.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData?.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      if (heading === "departmentName") heading = "name";
      else if (heading === "requestType") heading = "type";
      else if (heading === "isLinkedToHotel") heading = "isLinkedTravelHotel";
      else if (heading === "travelMode") heading = "mode";

      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData?.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    setReqData({
      ...reqData,
      status: selectedStatusList[0]?.value,
      type: selectedRequestType[0]?.value,
      mode: selectedMode[0]?.value,
      date: dateOfJoning
        ? `${format(dateOfJoning, "dd/MM/yyyy")} ${format(
            dateOfJoning,
            "dd/MM/yyyy"
          )}`
        : null,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setDateOfJoining(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setSelectedStatusList([]);
    setSelectedMode([]);
    setSelectedRequestType([]);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      date: null,
      status: null,
      type: null,
      mode: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
    } else {
      let arr = [];
      tbodyData?.map((item) => {
        if (
          originalData?.find((x) => (x.id ?? x.Id) === (item?.id ?? item?.Id))
            .checkActive
        )
          arr.push(item?.Id ?? item?.id);
        return null;
      });
      setSelectedRows(arr);
    }
  };
  const handleDelete = async (selectedRows) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      await deleteWithPayloadRequest(
        `TravelHospitalityRequests/CancelAll`,
        selectedRows
      );
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      getListData();
    }
  };
  const handleConfirm = async (Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);

      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      getListData();
    }
  };
  const deleteSelected = () => {
    if (selectedRows.length === 1) {
      confirmAlert({
        title: "Confirm cancel",
        message: `Are you sure you want to cancel this request?`,
        buttons: [
          {
            label: "Yes, confirm cancel",
            onClick: () => handleDelete(selectedRows),
          },
          {
            label: "No, cancel cancel",
            onClick: () => setSelectedRows([]),
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Confirm cancel",
        message: `Are you sure you want to cancel these ${selectedRows.length} requests?`,
        buttons: [
          {
            label: "Yes, confirm cancel",
            onClick: () => handleDelete(selectedRows),
          },
          {
            label: "No, cancel cancel",
            onClick: () => setSelectedRows([]),
          },
        ],
      });
    }
  };
  const handleApprove = async (selectedRows, typeAll) => {
    //// //console.log("selectedRows", selectedRows);
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let tempIds = [];
      selectedRows.map((item) => {
        tempIds.push(
          originalData?.find((x) => x.Id ?? x.id === item)?.nextRequestId
        );
        return null;
      });
      await deleteWithPayloadRequest(
        `TravelHospitalityRequests/${typeAll}`,
        tempIds
      );
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      if (isApprovePopup) togglePopupapprove();
      getListData();
    }
  };
  const rejectAll = async (typeAll, selectedRows, remarks) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let tempIds = [];
      selectedRows.map((item) => {
        tempIds.push(
          originalData?.find((x) => x.Id ?? x.id === item)?.nextRequestId
        );
        return null;
      });
      await deleteWithPayloadRequest(
        `TravelHospitalityRequests/RejectAl/${remarks}`,
        tempIds
      );
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      if (isApprovePopup) togglePopupapprove();
      getListData();
    }
  };
  console.log("object tbodyDataA", tbodyDataA);
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest(
        "pmsAppraisalCriteria/AllDepartmentList",
        downloadReqData
      );
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const childRef = useRef(null);
  function handleActivityClick() {
    childRef.current.updateState();
  }
  function handleActivityClose() {
    childRef.current.closeState();
  }
  return (
    <div className="pmsactionconfirm">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton onClick={() => setFilterSearchToogle((prev) => !prev)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p> Status </p>
              <SelectCustom
                options={status}
                values={selectedStatusList}
                onChange={(value) => {
                  setSelectedStatusList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Travel Date</p>
              <DatePickerCustom
                selected={dateOfJoning}
                onChange={(date) => setDateOfJoining(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderHEad">
        <div style={{ margin: "0px 10px 0px 20px" }}>
          <img
            src={backbuttonpic}
            alt=""
            style={{
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          />
        </div>
        <div style={{ margin: "0px 10px" }}> </div>
      </div>
      <div
        className="tableHeaderContainer"
        style={finalConfirm ? { padding: "0 3px 0 12px" } : {}}
      >
        {selectedRows.length > 0 ? (
          <>
            <>
              <button
                className="deletebtn"
                style={{ backgroundColor: "#1296B0" }}
                onClick={() => togglePopup("Confirm All", "", "")}
              >
                <span className="deletebtnflex">
                  <p>Confirm</p>
                  <span>{`(${selectedRows.length})`}</span>
                </span>
              </button>
              {/* <button
                className="button primaryButton"
                onClick={() => togglePopupapprove("ApproveAll")}
              >
                <span className="deletebtnflex">
                  <p>Confirm</p>
                  <span>{`(${selectedRows.length})`}</span>
                </span>
              </button>
              <button
                className="deletebtn"
                onClick={() => togglePopupapprove("RejectAll")}
              >
                <span className="deletebtnflex">
                  <p>Review & Approve</p>
                  <span>{`(${selectedRows.length})`}</span>
                </span>
              </button> */}
            </>
          </>
        ) : (
          <div></div>
        )}

        <div className="search-box">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
        {finalConfirm ? (
          <button
            className="deletebtn"
            style={{ backgroundColor: "#1296B0", boxShadow: "none" }}
            onClick={() =>
              togglePopup(
                "Final Confirm",
                "",
                "",
                "",
                originalData[0]?.criteriaId
              )
            }
          >
            <span className="deletebtnflex" style={{ boxShadow: "none" }}>
              <p>Final Confirm</p>
            </span>
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <TransactionTablePMSConfirmAction
              selectAll={selectAll}
              tableFilterName="TravelBookingAllListFilter"
              tbodyData={tbodyData}
              headerAction={sortUpdated}
              sortParam={sortParam}
              sortType={sortType}
              selectedRows={selectedRows}
              addToSelectedRows={addToSelectedRows}
              originalData={originalData}
              containStatus={true}
              containTat={true}
            />
          </>
        )}
        <div className="horizontalLine"></div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{count}</span>
          <SelectCustom
            searchable={false}
            options={noOfRecords}
            dropdownPosition="top"
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </>
      {isOpen && (
        <Popup
          popupHeading={typeAction}
          content={
            typeAction === "Final Confirm" ? (
              <ReviewAndApproved
                PostApRejHol={PostApRejHol}
                type={typeAction}
                id={selId}
                color={colorAction}
                handleClose={togglePopup}
                DepartmentId={selDepartmentId}
                criteriaId={selcriteriaId}
                finalConfirm={true}
                getAllListData={getListData}
              />
            ) : (
              <Remark
                selectedRows={selectedRows}
                loading={loading}
                data={PostApRejHol}
                type={typeAction}
                id={selId}
                color={colorAction}
                handleClose={togglePopup}
              />
            )
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};
export { PMSConfirmActionTab };
