import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalCompetencies } from "../Components/CompetenciesApproval/PendingApprovalCompetencies";
import { ApprovedApprovalCompetencies } from "../Components/CompetenciesApproval/ApprovedApprovalCompetencies";
import { RejectedApprovalCompetencies } from "../Components/CompetenciesApproval/RejectedApprovalCompetencies";
import { AllRequestApprovalCompetencies } from "../Components/CompetenciesApproval/AllRequestApprovalCompetencies";

const ApprovalCompetencies = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalCompetencies
            title={"My Competencies"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <ApprovedApprovalCompetencies
          title={"My Competencies"}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <RejectedApprovalCompetencies
            title={"My Competencies"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}

      {tabIndex === 3 && (
        <>
          <AllRequestApprovalCompetencies
            title={"My Competencies"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalCompetencies };
