import React, { useState } from "react";
import "./Connect.css";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";
import SocialTextLinkIcon from "../../../../../Assets/SocialTextLinkIcon.svg";
import SocialKudosTag from "../../../../../Assets/SocialKudosTag.svg";
import SocialBirthdayTag from "../../../../../Assets/SocialBirthdayTag.svg";
import SocialWorkAnnyTag from "../../../../../Assets/SocialWorkAnnyTag.svg";
import SocialNewJoiningTag from "../../../../../Assets/SocialNewJoiningTag.svg";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import DetailsPostLikeDislikeComponent from "./DetailsPostLikeDislikeComponent";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import SocialAnnouncementTag from "../../../../../Assets/SocialAnnouncementTag.svg";

import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import SocialNewsTag from "../../../../../Assets/SocialNewsTag.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import ContentSocialDisplay from "./ContentSocialDisplay";
import { IconButton } from "@mui/material";
import SharePost from "./SharePost";
import CommentPost from "./CommentPost";
import LikeDislike from "./LikeDislike";
import PDFViewer from "./PDFViewer";
import dateFormat from "dateformat";
import { useLocation, useNavigate } from "react-router-dom";
import {
  docsArray,
  getAttachments,
  getContentLength,
  getExtension,
  getUserInitials,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import "./ShowFullAttachments.css";
import "./Connect.css";
import {
  albumPostImages,
  communityProfileImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import SocialPostNestedHeaderContainer from "./SocialPostNestedHeaderContainer";
import SocialDeepNestetShowFullAttachment from "./SocialDeepNestetShowFullAttachment";
import CommunityDeepNestedShowFullAttachment from "./CommunityDeepNestedShowFullAttachment";

function ShowNestedSharedPostDetails({
  isGreetings,
  getData,
  postData,
  setPostData,
  setCommentedPost,
  commentedPost,
  fullAtachmentData,
  setShowNestedSharedPostDetailActive,
}) {
  const [showContent, setShowContent] = useState(false);
  const [sharePostActive, setSharePostActive] = useState(false);
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [isImages, setIsImages] = useState(false);
  const [isVideos, setIsVideos] = useState(false);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullAttachmentActive, setShowFullAttachmentActive] =
    useState(false);
  let attachment = getAttachments(fullAtachmentData);
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
 
  useEffect(() => {
    if (attachment) {
      let x = getExtension(attachment[0]);
      if (imgArray.includes(x)) {
        setIsImages(true);
      } else if (videoArray.includes(x)) {
        setIsVideos(true);
      }
      if (x !== null) {
        setNumPages(attachment?.length);
      }
    }
  }, []);
  const nextSlide = () => {
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  };
  const prevSlide = () => {
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  };
  useEffect(() => {
    const keyHandler = (event) => {
      if (event.code === "ArrowRight") {
        nextSlide();
      } else if (event.code === "ArrowLeft") {
        prevSlide();
      }
    };
    document.addEventListener("keydown", keyHandler, false);

    return () => document.removeEventListener(keyHandler);
  }, []);
  const backToAnnouncement = () => {
    if (
      location?.pathname?.includes("community") ||
      location?.pathname?.includes("userprofile")
    ) {
      navigate(-1);
    } else {
      setCommentedPost(null);
      navigate("/home/social/social");
    }
    localStorage.removeItem("commentid");
  };
  return (
    <div className="showAttachmentsWithCommentContainer">
      <Popup
        firstClassName="showAttachmentsWithCommentPopupbox1"
        secondClassName="showAttachmentsWithCommentPopupbox2"
        createMyHead="showAttachmentsWithCommentCreateHead"
        content={
          <>
            <div
              className="showAttachmentsWithCommentWrapper"
              style={{
                border: "1px solid #f1f2f2",
                margin: "20px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  border: "none",
                  borderRadius: "10px",
                  height: "100%",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="nestedSharedPostAttachmentShower"
                onClick={() => {
                  setShowFullAttachmentActive(true);
                  setCommentedPost(commentedPost);
                }}
              >
                {commentedPost?.sharedPost?.news === null && commentedPost?.sharedPost?.announcement === null && (
                  <div
                    className="postHeaderContainer"
                    style={{ boxShadow: "none", padding: "0px" }}
                  >
                    <div
                      className={` social-details-header ${
                        commentedPost?.postType !== "organisation"
                          ? "team-community-details-Header"
                          : ""
                      }`}
                    >
                      {commentedPost?.sharedPost?.postType === "organisation" && (
                        <div
                          className="leftPostHeaderContainer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUserProfileClick(commentedPost?.employeeId);
                          }}
                        >
                          <div
                            className={`${
                              commentedPost?.parantPostEmployee?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                          >
                            {!commentedPost?.parantPostEmployee?.image ? (
                              getUserInitials(
                                commentedPost?.parantPostEmployee?.name
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleUserProfileClick(
                                    commentedPost?.employeeId
                                  );
                                }}
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  commentedPost?.parantPostEmployee?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>

                          <div className="postMainTextContainer">
                            <label className="postMainName">
                              {getDisplayName(
                                commentedPost?.parantPostEmployee
                                  ?.nameWithPersonNumber
                              )}
                            </label>
                            <label>
                              {commentedPost?.parantPostEmployee?.roleName}
                            </label>
                            <label className="dateAndViewsContainer">
                              {commentedPost?.parantPostEmployee?.locationName}
                              <span className="postHrTime">
                                &nbsp; •{" "}
                                {dateFormat(
                                  commentedPost?.createdOn,
                                  "hh:MM TT | dd mmm yyyy"
                                )}
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      {commentedPost?.sharedPost?.postType === "community" && (
                        <div
                          className="leftPostHeaderContainer"
                          style={{ position: "relative" }}
                        >
                          <div
                            onClick={(e) => {
                              navigate(
                                `/home/social/community/${commentedPost?.sharedPost?.community?.id}`
                              );
                            }}
                            className={`${
                              commentedPost?.sharedPost?.community?.communityImage
                                ? "socialConnectProfilePicContainer"
                                : "nestedCommunityCustomProfilePic"
                            }`}
                          >
                            {!commentedPost?.sharedPost?.community?.communityImage &&
                            commentedPost?.sharedPost?.community?.communityName ? (
                              getUserInitials(
                                commentedPost?.sharedPost?.community?.communityName
                              )
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  communityProfileImages +
                                  commentedPost?.sharedPost?.community?.communityImage
                                }
                                style={{ marginBottom: "8px" }}
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = SocialCommunityGroupIcon;
                                }}
                              />
                            )}
                          </div>

                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUserProfileClick(commentedPost?.employeeId);
                            }}
                            className={`${
                              commentedPost?.parantPostEmployee?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePicCommunity"
                            }`}
                          >
                            {!commentedPost?.parantPostEmployee?.image ? (
                              getUserInitials(
                                commentedPost?.parantPostEmployee?.name
                              )
                            ) : (
                              <img
                                className={`socialProfilePic ${
                                  commentedPost?.postType === "community" ||
                                  "team"
                                    ? "prTeamCommunity"
                                    : ""
                                }`}
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  commentedPost?.parantPostEmployee?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>

                          <div
                            className="postMainTextContainer"
                            style={{ marginLeft: "15px" }}
                            onClick={() => {
                              navigate(
                                `/home/social/community/${commentedPost?.sharedPost?.community?.id}`
                              );
                            }}
                          >
                            <label className="postMainName">
                              {commentedPost?.sharedPost?.community?.communityName}
                            </label>
                            <label
                            //  className="dateAndViewsContainer"
                             >
                              Post from{" "}
                              <span className="nameofposter">
                                {getDisplayName(
                                  commentedPost?.parantPostEmployee
                                    ?.nameWithPersonNumber
                                )}
                              </span>
                              <br/>
                              <span className="postHrTime">
                                •{" "}
                                {dateFormat(
                                  commentedPost?.createdOn,
                                  "hh:MM TT | dd mmm yyyy"
                                )}
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                      {commentedPost?.sharedPost?.postType === "team" && (
                        <div
                          className="leftPostHeaderContainer"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={SocialPostTeamIcon}
                            alt=""
                            style={{
                              height: "50px",
                              width: "50px",
                              marginBottom: "8px",
                            }}
                          />
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUserProfileClick(commentedPost?.employeeId);
                            }}
                            className={`${
                              commentedPost?.parantPostEmployee?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePicCommunity"
                            }`}
                          >
                            {!commentedPost?.parantPostEmployee?.image ? (
                              getUserInitials(
                                commentedPost?.parantPostEmployee?.name
                              )
                            ) : (
                              <img
                                className={`socialProfilePic ${
                                  commentedPost?.postType === "community" ||
                                  "team"
                                    ? "prTeamCommunity"
                                    : ""
                                }`}
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  commentedPost?.parantPostEmployee?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>

                          <div
                            className="postMainTextContainer"
                            style={{ marginLeft: "15px" }}
                          >
                            <label className="postMainName">Team</label>
                            <label className="dateAndViewsContainer">
                              Post from &nbsp;
                              <span className="nameofposter">
                                {getDisplayName(
                                  commentedPost?.parantPostEmployee
                                    ?.nameWithPersonNumber
                                )}
                              </span>
                              <span className="postHrTime">
                                &nbsp; •{" "}
                                {dateFormat(
                                  commentedPost?.createdOn,
                                  "hh:MM TT | dd mmm yyyy"
                                )}
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {commentedPost?.sharedPost?.subType === "kudos" && (
                        <div className="kudosTypeContainer">
                          <span>Kudos</span>
                          <article></article>
                          <img src={SocialKudosTag} alt="" />
                        </div>
                      )}
                      {commentedPost?.sharedPost?.subType === "birthday" && (
                        <div className="birthdayTypeContainer">
                          <span>Birthday</span>
                          <article></article>
                          <img src={SocialBirthdayTag} alt="" />
                        </div>
                      )}
                      {commentedPost?.sharedPost?.subType ===
                        "workanniversary" && (
                        <div className="workAnnyTypeContainer">
                          <span>Work Anniversary</span>
                          <article></article>
                          <img src={SocialWorkAnnyTag} alt="" />
                        </div>
                      )}
                      {commentedPost?.sharedPost?.subType === "welcome" && (
                        <div className="newJoiningTypeContainer">
                          <span>New Joining</span>
                          <article></article>
                          <img src={SocialNewJoiningTag} alt="" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {commentedPost?.sharedPost?.news !== null && (
                  <div
                    className="postHeaderContainer"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="leftPostHeaderContainer">
                      <div className="socialPostTitleAndTimeContainer">
                        <label>
                          {commentedPost?.sharedPost?.news?.title?.length > 40
                            ? commentedPost?.sharedPost?.news?.title?.substring(
                                0,
                                40
                              ) + "..."
                            : commentedPost?.sharedPost?.news?.title}
                        </label>
                        <span className="postHrTime">
                          •{" "}
                          {dateFormat(
                            commentedPost?.sharedPost?.news?.createdOn,
                            "hh:MM TT | dd mmm yyyy"
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="newsTypeContainer">
                        <span>{commentedPost?.sharedPost?.news?.category}</span>
                        <article></article>
                        <img src={SocialNewsTag} alt="" />
                      </div>
                    </div>
                  </div>
                )}
                {commentedPost?.sharedPost?.announcement !== null && (
                  <div
                    className="postHeaderContainer"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="leftPostHeaderContainer">
                      <div className="socialPostTitleAndTimeContainer">
                        <label>
                          {commentedPost?.sharedPost?.announcement?.title
                            ?.length > 40
                            ? commentedPost?.sharedPost?.announcement?.title?.substring(
                                0,
                                40
                              ) + "..."
                            : commentedPost?.sharedPost?.announcement?.title}
                        </label>
                        <span className="postHrTime">
                          •{" "}
                          {dateFormat(
                            commentedPost?.sharedPost?.announcement?.createdOn,
                            "hh:MM TT | dd mmm yyyy"
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="announcementTypeContainer">
                        <span>Announcement</span>
                        <article></article>
                        <img src={SocialAnnouncementTag} alt="" />
                      </div>
                    </div>
                  </div>
                )}
                  <div className="postBodyTextContainer">
                    {commentedPost?.content?.split(/\r\n|\r|\n/).length > 4 &&
                    !showContent ? (
                      <ContentSocialDisplay
                        content={getContentLength(commentedPost?.content)}
                      />
                    ) : (
                      <ContentSocialDisplay
                        content={commentedPost?.content?.trim()}
                      />
                    )}
                    {commentedPost?.content?.split(/\r\n|\r|\n/).length > 4 &&
                      !showContent && (
                        <span
                          onClick={() => {
                            setShowContent(true);
                          }}
                        >
                          ...View More
                        </span>
                      )}
                  </div>
                <div
                  className="postBodyContainer"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => {
                    setShowFullAttachmentActive(true);
                  }}
                >
                  {commentedPost?.sharedPost?.subType !== null  && 
                     (
                      <div
                        className="newPostBodyImgMainWrapper"
                        style={{ flex: "1"}}
                      >
                        <div
                          className="newPostBodyImgContainer"
                          style={{ height: "100%" }}
                        >
                          <img
                            src={
                              commentedPost?.attachment?.includes("gif") || commentedPost?.sharedPost?.news !== null || commentedPost?.sharedPost?.announcement !== null
                                ? process.env.REACT_APP_.BASE_URL +
                                  socialPostImages +
                                  commentedPost?.attachment
                                : process.env.REACT_APP_.BASE_URL +
                                  albumPostImages +
                                  commentedPost?.attachment
                            }
                            alt=""
                            className={
                              commentedPost?.sharedEmployee === null
                                ? `celebrationImg`
                                : `celebrationSharedImg`
                            }
                          />
                        </div>
                      </div>
                    )}
                  {commentedPost?.sharedPost?.subType === null && (
                    <div
                      onClick={() => {
                        setShowFullAttachmentActive(true);
                      }}
                      className="postBodyContainer"
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems:"center",
                        // background:"black"
                      }}
                    >
                      {commentedPost?.attachment?.length > 0 &&
                        imgArray.includes(
                          getExtension(
                            getAttachments(commentedPost?.attachment)
                              ? getAttachments(commentedPost?.attachment)[0]
                              : ""
                          )
                        ) && (
                          <div
                            className="postBodyImgContainer"
                            // style={{
                            //   overflow: "hidden",
                            //   borderRadius: "0 0 10px 10px",
                            // }}
                            style={{ height: "350px", width:"100%" }}
                          >
                            <div style={{ margin: "0", overflow: "hidden" }}>
                              {getAttachments(commentedPost?.attachment)
                                ?.length === 1 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    // overflow: "hidden",
                                    // borderRadius: "0 0 10px 10px",
                                    // backgroundColor: "black",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        commentedPost?.attachment
                                      )[0]
                                    }
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      // overflow: "hidden",
                                      minHeight: "190px",
                                      maxHeight: "350px",
                                      width: "auto",
                                      maxWidth: "100%",
                                      objectFit: "contain",
                                    }}
                                    alt=""
                                    className={` ${
                                      commentedPost?.sharedEmployee !== null
                                        ? "shareImgContainer"
                                        : "normalImgContainer"
                                    }`}
                                  />
                                </div>
                              )}
                              {getAttachments(commentedPost?.attachment)
                                ?.length === 2 && (
                                <div
                                  className={`newPostBodyImgContainer2 ${
                                    commentedPost?.sharedEmployee !== null
                                      ? "shareImgContainer2"
                                      : ""
                                  }`}
                                >
                                  <div className="subImage2a">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[0]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                  </div>
                                  <div className="subImage2b">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[1]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              )}
                              {getAttachments(commentedPost?.attachment)
                                ?.length === 3 && (
                                <div
                                  className={`newPostBodyImgContainer3 ${
                                    commentedPost?.sharedEmployee !== null
                                      ? "shareImgContainer3"
                                      : ""
                                  }`}
                                >
                                  <div className="subImage3a">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[0]
                                      }
                                      alt=""
                                      style={{
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                  <div className="subImage3b">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[1]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[2]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              )}
                              {getAttachments(commentedPost?.attachment)
                                ?.length === 4 && (
                                <div
                                  className={`newPostBodyImgContainer4 ${
                                    commentedPost?.sharedEmployee !== null
                                      ? "shareImgContainer4"
                                      : ""
                                  }`}
                                >
                                  <div className="subImage4a">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[0]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                  </div>
                                  <div className="subImage4b">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[1]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[2]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[3]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              )}
                              {getAttachments(commentedPost?.attachment)
                                ?.length > 4 && (
                                <div
                                  className={`newPostBodyImgContainer4 ${
                                    commentedPost?.sharedEmployee !== null
                                      ? "shareImgContainer4"
                                      : ""
                                  }`}
                                >
                                  <div className="subImage4a">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[0]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                  </div>
                                  <div className="subImage4b">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[1]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[2]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(
                                          commentedPost?.attachment
                                        )[3]
                                      }
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                    />
                                  </div>
                                  <div className={"imgHideMainBox"}>
                                    <div className="imgHideRoundBox">
                                      <span>
                                        +{" "}
                                        {getAttachments(
                                          commentedPost?.attachment
                                        )?.length - 4}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                      {commentedPost?.attachment?.length > 0 &&
                        videoArray.includes(
                          getExtension(
                            getAttachments(commentedPost?.attachment)
                              ? getAttachments(commentedPost?.attachment)[0]
                              : ""
                          )
                        ) && (
                          <div
                            className="postBodyImgContainer"
                            style={{
                              overflow: "hidden",
                              borderRadius: "0 0 10px 10px",
                            }}
                            onClick={() => {
                              setShowFullAttachmentActive(true);
                            }}
                          >
                            <div
                              className={"newPostBodyImgMainWrapper"}
                              style={{ flex: "1"}}
                            >
                              <div
                                className="newPostBodyImgContainer"
                                style={{ height: "100%" }}
                              >
                                <video
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    socialPostImages +
                                    getAttachments(commentedPost?.attachment)[0]
                                  }
                                  controls
                                  width="630"
                                  height="335"
                                />
                              </div>
                            </div>
                          </div>
                        )}

                      {commentedPost?.attachment?.length > 0 &&
                        docsArray.includes(
                          getExtension(
                            getAttachments(commentedPost?.attachment)
                              ? getAttachments(commentedPost?.attachment)[0]
                              : ""
                          ).toLowerCase()
                        ) && (
                          <div
                            className="postBodyImgContainer"
                            style={{
                              overflow: "hidden",
                              borderRadius: "0 0 10px 10px",
                            }}
                          >
                            {getAttachments(commentedPost?.attachment)
                              ?.length === 1 && (
                              <div className="newPostBodyImgContainer">
                                <PDFViewer
                                  pdfUrl={
                                    process.env.REACT_APP_.BASE_URL +
                                    socialPostImages +
                                    getAttachments(commentedPost?.attachment)[0]
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                      {commentedPost?.news?.link && (
                        <div className="newsLinkContainer">
                          <div className="newsLinkWrapper">
                            <div className="newsLink">
                              <img src={SocialTextLinkIcon} alt="" />{" "}
                              <a
                                href={commentedPost?.news?.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span>{commentedPost?.news?.link}</span>
                              </a>
                            </div>
                            <img
                              style={{ width: "29px" }}
                              src={SocialNewsEndLinkIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="showAttachmentsWithCommentListContainer">
              <div className="showAttachmentsWithCommentListWrapper">
                <div
                  className="showAttachmentsWithCommentListHeaderContainer"
                  style={{ padding: "0" }}
                >
                  <SocialPostNestedHeaderContainer
                    commentedPost={commentedPost}
                    navigate={navigate}
                  />
                  <IconButton
                    onClick={backToAnnouncement}
                    style={{ margin: "10px" }}
                  >
                    <img src={CloseIcon} alt="" />
                  </IconButton>
                </div>
                <div className="showAttachmentsWithCommentListBodyContainer">
                  <div
                    className="showAttachmentsWithCommentListBodyWrapper"
                    style={{ padding: "0px" }}
                  >
                    <div className="postBodyTextContainer">
                      <p>
                        <ContentSocialDisplay
                          content={commentedPost.sharedContent}
                        />
                      </p>
                    </div>
                  </div>
                  <div
                    className="showAttachmentsWithCommentListActivityContainer"
                    style={{ position: "relative" }}
                  >
                    <DetailsPostLikeDislikeComponent
                      commentedPost={commentedPost}
                      setSharePostData={setSharePostData}
                      setSharePostId={setSharePostId}
                      setLikeId={setLikeId}
                      setTotalLikesWatching={setTotalLikesWatching}
                      setDislikeId={setDislikeId}
                      setTotalDisLikesWatching={setTotalDisLikesWatching}
                      setCommentedPost={setCommentedPost}
                      // setShareDetailsActive={setShareDetailsActive}
                      postData={postData}
                      setPostData={setPostData}
                      getData={getData}
                      setSharePostActive={setSharePostActive}
                    />
                  </div>
                  <CommentPost
                    commentedPost={commentedPost}
                    setCommentedPost={setCommentedPost}
                    getData={getData}
                    postData={postData}
                    setPostData={setPostData}
                    shared={true}
                  />
                </div>
              </div>
            </div>
          </>
        }
        handleClose={backToAnnouncement}
      />
      {sharePostActive && (
        <SharePost
          sharePostData={sharePostData}
          sharePostId={sharePostId}
          getData={getData}
          setSharePostActive={setSharePostActive}
          getExtension={getExtension}
          getAttachments={getAttachments}
        />
      )}
      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
      {showFullAttachmentActive && !location?.pathname?.includes("community") && (
        <SocialDeepNestetShowFullAttachment
          postid={commentedPost?.sharedPostId}
          setShowFullAttachmentActive={setShowFullAttachmentActive}
        />
      )}
      {showFullAttachmentActive && location?.pathname?.includes("community") && (
        <CommunityDeepNestedShowFullAttachment
          postid={commentedPost?.sharedPostId}
          setShowFullAttachmentActive={setShowFullAttachmentActive}
        />
      )}
    </div>
  );
}

export default ShowNestedSharedPostDetails;
