import React from "react";

import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { useEffect } from "react";




export const ScheduleMessageComp = () => {

    useEffect(() => {
        document.title = `PeopleSol - Create Early Going `;
        return () => {
            document.title = 'PeopleSol';
        };
    }, []);

    return (
        <div>
            <div>You can modify or cancel this message at any time before it's sent</div>
            <div className="formcustom twoColumn">
                <div>
                    <LabelCustom labelName={"Date"} mandatory={true} />
                    <DatePickerFormiks
                        name={"date"}
                    />
                </div>
                <div>
                    <LabelCustom labelName={"Time"} mandatory={true} />
                    <TimeCustomFormik
                        name="time"
                        placeholder="Select Time"
                    />
                </div>
            </div>
        </div>
    );
};
