import React, { useState } from "react";
import { Details } from "../HRMS/Components/DetailsComp/Details";
import { DetailsStep } from "../../Components/FormComponent/DetailsStep";

function AccordionItem({ title, subTitle, content }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion-item ${isOpen ? "open" : ""}`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <div style={{ flex: "3" }} className="title">
          {title}
        </div>
        {subTitle && (
          <div style={{ flex: "1" }} className="subTitle">
            {subTitle}
          </div>
        )}
        <span className={`icon ${isOpen ? "open" : ""}`}>
          {isOpen ? (
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.12348 0.898926L7.17169 6.94713L13.2199 0.898925"
                stroke="#1296B0"
                stroke-width="1.5"
                stroke-linecap="round"
                transform="scaleX(-1)"
              />
            </svg>
          ) : (
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8765 0.898926L6.82826 6.94713L0.780053 0.898925"
                stroke="#1296B0"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          )}
        </span>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
}

function Accordion({ title, subTitle, content }) {
  const camelToCapital = (key) => {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  return (
    <div className="accordion">
      {content?.length && (
        <AccordionItem
          title={title}
          subTitle={subTitle}
          content={content?.map((item) => {
            return (
              <DetailsStep
                clas={"restClas"}
                detailTitle={camelToCapital(item?.rField)}
                Details={`${item?.percentage}%`}
              />
            );
          })}
        />
      )}
    </div>
  );
}

export default Accordion;
