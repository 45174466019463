import React, { useEffect, useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";

export const CreateSkillComp = () => {
  const [skillSet, setSkillSet] = useState();
  const [subDepartment, setSubDepartment] = useState();
  const getSkillSet = async () => {
    try {
      const result = await postRequest("SkillController/GetSkillSet");
      // console.log("resultLocationGrp", result)
      let listData = [];
      result.data?.map((item) => {
        return listData.push({ label: item.skillSetName, value: item.id });
      });
      setSkillSet(listData);
    } catch (error) {
    } finally {
    }
  };
  //   const getSubDepartmentData = async () => {
  //     try {
  //       const result = await getRequest(
  //         `Department/GetSubDepartment/${formValues?.DepartmentId}`
  //       );
  //       let listData = [];
  //       result.data.map((item) => {
  //         return listData.push({ label: item.name, value: item.id });
  //       });
  //       setSubDepartmentList(listData);
  //     } catch (error) {
  //     } finally {
  //     }
  //   };
  //   const getSubDepartment = async () => {
  //     try {
  //       const result = await getRequest("LeaveTypes/GetDepartments");
  //       // console.log("resultLocationGrp", result)
  //       let listData = [];
  //       result.data?.map((item) => {
  //         return listData.push({ label: item.name, value: item.id });
  //       });
  //       setSubDepartment(listData);
  //     } catch (error) {
  //     } finally {
  //     }
  //   };
  useEffect(() => {
    getSkillSet();
  }, []);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          name="Name"
          type={"text"}
          placeholder={"Please Enter Place"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Skill Set"} mandatory={true} />
        <SelectForm name="SkillSetId" options={skillSet} />
      </div>
    </div>
  );
};
