/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { EPFTab } from "./EPFTab";
import { ESICTab } from "./ESICTab";
import { LWFTab } from "./LWFTab";
import { PTTab } from "./PTTab";
import { IncomeTaxTab } from "./IncomeTaxTab";
import { GratuityTab } from "./GratuityTab";

const StatutoryCompliances = ({ featureMenu }) => {
  useEffect(() => {
    document.title = `PeopleSol - Statutory Compliance`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    navigate(`statutorycompliances/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("payrollmaster/statutorycompliances")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex(
        (x) => x === "statutorycompliances"
      );
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            if (featureMenu)
              setTabIndex(
                featureMenu
                  ?.slice()
                  ?.filter((x) => x.isFeatureActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                  )[0]
                  .featureName?.replaceAll(" ", "")
                  ?.replaceAll("&", "")
                  ?.toLowerCase()
              );
            navigate(
              `statutorycompliances/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `statutorycompliances/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
  }, [location.pathname, featureMenu]);
  return (
    <div className="moduleMainContainer">
      <div
        className="headingContainer"
        style={{ padding: "0" }}
      >
        <div
          // onClick={() => navigate(-1)}
          style={{ display: "flex" }}
          className="returnToDashboard cursor"
        >
          <div>
            {/* <svg
              width="20"
              height="8"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                fill="#1296B0"
              />
            </svg> */}
          </div>
          <label className="returnLabelHolder">{"Statuary Components"}</label>
        </div>
      </div>
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${tabIndex ===
                          item?.featureName
                            ?.replaceAll(" ", "")
                            ?.replaceAll("&", "")
                            ?.toLowerCase() && "tabActive"
                          }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "epf" && (
        <>
          <EPFTab />
        </>
      )}
      {tabIndex === "esi" && (
        <>
          <ESICTab />
        </>
      )}
      {tabIndex === "professionaltax" && (
        <>
          <PTTab />
        </>
      )}
      {tabIndex === "labourwelfarefund" && (
        <>
          <LWFTab />
        </>
      )}

      {tabIndex === "companytax" && (
        <>
          <IncomeTaxTab />
        </>
      )}
      {tabIndex === "gratuity" && (
        <>
          <GratuityTab />
        </>
      )}
    </div>
  );
};
export { StatutoryCompliances };
