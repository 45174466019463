import React from "react";

const ChatInterfaceIntro = ({
  LoggedInUser,
  userDetails,
  BeforeActiveFirstImg,
  BeforeActiveSecondImg,
  BeforeActiveThirdImg,
}) => {
  return (
    <div
      className="chatConversationContainerBeforeActive"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div className="BeforeActiveFontWelcome">
          Welcome,
          {" " + userDetails.name}
        </div>
        <div className="BeforeActiveFontintro">
          Let’s jump right into things
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "624px",
            height: "200px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "169px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img src={BeforeActiveFirstImg} alt="" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: " 1.86px",
                  backgroundColor: "#1296B0",
                  borderRadius: "15px",
                  marginBottom: "7px",
                }}
              ></div>
              <div style={{ textAlign: "center" }}>
                Schedule Message for group and person
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "169px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img src={BeforeActiveSecondImg} alt="" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: " 1.86px",
                  backgroundColor: "#1296B0",
                  borderRadius: "15px",
                  marginBottom: "7px",
                }}
              ></div>
              <div style={{ textAlign: "center" }}>
                Meeting via Video or Audio Call
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "169px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img src={BeforeActiveThirdImg} alt="" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: " 1.86px",
                  backgroundColor: "#1296B0",
                  borderRadius: "15px",
                  marginBottom: "7px",
                }}
              ></div>
              <div style={{ textAlign: "center" }}>
                Collaborate on projects with teams or groups
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInterfaceIntro;
