import React, { useState } from "react";
import PeopleSolLogo from "../../Assets/PeopleSolLogo.svg";
import LoginPerson from "../../Assets/LoginPerson.png";
import LoginBack from "../../Assets/LoginBack.png";
import { LoginForm } from "../../Components";
import "../Styles.css";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { format } from "date-fns";
// import { useDispatch } from 'react-redux';
// import { loggedOut } from '../../Features';

const Landing = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const dispatch = useDispatch();
  // //console.log("is",isOpen)
  useEffect(() => {
    document.title = `PeopleSol - Landing`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="landing">
      <img
        src={LoginBack}
        alt="LoginBack"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
      <div className="loginGraphics">
        <img
          src={LoginPerson}
          alt="LoginPerson"
          className="newLoginGraphicImage no-select-peoplesol"
        />
      </div>
      {isOpen ? (
        <div className="loginForm">"Forgot"</div>
      ) : (
        <div className="loginForm">
          <div className="loginFormBox">
            <img src={PeopleSolLogo} alt="peopleSolLogo" className="heroLogo" />
            <LoginForm />
            <div className="loginFooterMain">
              <div className="loginFooter">
                <img
                  src={PeopleSolLogo}
                  alt="peopleSolLogo"
                  className="subHeroLogo"
                />
                <div className="subFooter">
                  <a
                    href="https://aesssolutions.com/privacypolicy.html"
                    target="blank"
                    className="subFooterText cursor"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="https://aesssolutions.com/terms&conditions.html"
                    target="blank"
                    className="subFooterText cursor"
                  >
                    Terms & Conditions
                  </a>
                </div>
              </div>
              <hr className="footerLine" />
              <div className="footerEndLine">
                <a
                  className="footerLineText"
                  href="https://aesssolutions.com"
                  target="blank"
                >
                  ©AESS Solutions, {`${format(new Date(), "yyyy")}`}. All rights
                  reserved.
                </a>
                <p>
                  Made in India with
                  <span>
                    <Icon
                      icon="line-md:heart-filled"
                      width="16"
                      height="16"
                      color="red"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { Landing };
