import React from "react";
import ProfileAvatar from "../../../../../../../Assets/ProfileAvatar.svg";
import getDisplayName from "../../../../../../../Utilities/displayNameFormat";
import {
  getPersonNumber,
  getUserInitials,
} from "../../../../../../../Utilities/socialAllFunctions";
import { profileImages } from "../../../../../../../Utilities/profileAndUploadImages";

const SurveyNotificationsItem = ({
  item,
  selectedRecipient,
  setSelectedRecipient,
}) => {
  return (
    <>
      <div className="">
        <div className="selectedEmployeeList">
          <div className="employeeList">
            <div className="totalLikePopupImg">
              <div
                className={`${
                  item?.image
                    ? "socialProfilePicContainer"
                    : "surveyCustomProfilePic"
                }`}
                style={{
                  width: "50px",
                  height: "50px",
                  margin: "0",
                }}
              >
                {!item?.image ? (
                  getUserInitials(getDisplayName(item?.name))
                ) : (
                  <img
                    className="commentProfilePic"
                    onClick={() => {
                      // handleUserProfileClick(item?.id);
                    }}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      item?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>
            </div>
            <div className="totalLikePopupText">
              <h5>
                {getDisplayName(item?.name)}{" "}
                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                  {getPersonNumber(item?.name)}
                </span>
              </h5>
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                {item?.jobRoal}
              </p>
            </div>
          </div>
          <div
            className="socialCommonChkbox"
            onClick={() => {
              if (selectedRecipient?.includes(item)) {
                let x = selectedRecipient?.filter(
                  (emp) => emp?.id !== item?.id
                );
                setSelectedRecipient(x);
              } else {
                setSelectedRecipient((selectedRecipient) => [
                  ...selectedRecipient,
                  item,
                ]);
              }
            }}
          >
            <input
              type="checkbox"
              class="myinput large"
              name="chjsah"
              checked={selectedRecipient?.includes(item)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyNotificationsItem;
