import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";
import { getModuleDetails } from "../../../../Utilities/getModuleDetails";
import DetailsIcon from "../../../../Assets/DetailsIcon.svg";

function GetMyHoldRequests({ navigateToRoute }) {
  const [apiholddata, setapiholddata] = useState([]);
  const [loading, setLoading] = useState(false);

  const getapidata = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Settings/GetMyHoldRequests`);
      setapiholddata(result?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getapidata();
    document.title = `PeopleSol -  Hold Requests`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : apiholddata.length > 0 ? (
        <div style={{ height: "600px" }}>
          <div style={{ height: "560px" }}>
            <table className="responsive-table tableHeaderRow">
              {/* Table Header */}
              <thead style={{ position: "sticky" }}>
                <tr>
                  <th>S. No</th>
                  <th></th>
                  <th>Request Number</th>
                  <th>Requester</th>
                  <th>Date</th>
                  <th>Module</th>
                </tr>
              </thead>
              <tbody>
                {apiholddata.map((item, index) => (
                  <tr key={item?.index || index}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        className="cursor"
                        // onClick={() => {
                        //   const moduleDetails = getModuleDetails(
                        //     item?.module,
                        //     item?.id
                        //   );
                        //   if (moduleDetails?.returnUrl) {
                        //     navigateToRoute(moduleDetails.returnUrl);
                        //   } else {
                        //   }
                        // }}
                        onClick={() => {
                          navigateToRoute(item?.url);
                        }}
                        src={DetailsIcon}
                        alt=""
                      />
                    </td>
                    <td>{item?.requestNumber}</td>
                    <td>{item?.requester}</td>
                    <td>{item?.date}</td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                      }}
                    >
                      {item?.module}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="noDatastyle" style={{ padding: "75px 105px" }}>
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>No Data Found</h4>
        </div>
      )}
    </>
  );
}

export default GetMyHoldRequests;
