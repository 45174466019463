import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { FieldArray, Form, Formik } from "formik";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import SurveyAnswersDeleteIcon from "../../../../Assets/SurveyAnswersDeleteIcon.svg";
import { format } from "date-fns";

const TravelBookingAddJourney = ({ togglePopup, detailsData, getDetails }) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [candidateList, setCandidateList] = useState([]);

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  const getCandidateData = async () => {
    try {
      const result = await getRequest(
        "Candidate/GetCandidatesListWithoutRejection"
      );
      // //console.log("result", result);
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setCandidateList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    if (
      formValues?.TravelingType === "Round Trip" &&
      (formValues?.PickupLocation?.length > 0 ||
        formValues?.DropLocation?.length > 0)
    ) {
      setInitialValues(() => {
        let obj = { ...formValues };
        obj.PickupLocationR = formValues?.DropLocation;
        obj.DropLocationR = formValues?.PickupLocation;
        return obj;
      });
    }
  }, [formValues?.PickupLocation, formValues?.DropLocation]);
  const [initialValues, setInitialValues] = useState({
    id: detailsData?._request?.id,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    Type: detailsData?._request?.type,
    budget: "",
    groupItems: [
      {
        CategoryID: detailsData?.travellingdetails[0]?.categoryID,
        FromDate: null,
        PickupLocation: "",
        DropLocation: "",
        Bookingnote: "",
        EmployeeDetails: [
          {
            EmployeeId: null,
            Age: null,
            Gender: "",
            PhoneNumber: "",
            EmailId: "",
          },
        ],
      },
    ],
  });

  useEffect(() => {
    getEmployeeData();
    getCandidateData();
  }, []);

  console.log("object initialValues", initialValues);

  console.log("object formValues", formValues);

  const create = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "TravelHospitalityRequests/AddJourney",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      togglePopup();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.message ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getDetails();
    }
  };

  const pushEmployee = (index) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails =
        formValues?.Type === "Vendor" || formValues?.Type === "Guest"
          ? [
              ...newGroupItems[index].EmployeeDetails,
              {
                Name: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ]
          : [
              ...newGroupItems[index].EmployeeDetails,
              {
                EmployeeId: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ];
      newGroupItems[index] = {
        ...newGroupItems[index],
        EmployeeDetails: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const popAnswer = (index, indexW) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails = newGroupItems[index].EmployeeDetails?.filter(
        (item, ind) => ind !== indexW
      );

      newGroupItems[index] = {
        ...newGroupItems[index],
        EmployeeDetails: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const tripInitialValuesSelf = {
    id: detailsData?._request?.id,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    Type: detailsData?._request?.type,
    TravelingType: detailsData?.travellingdetails[0]?.travelingType,
    CategoryID: detailsData?.travellingdetails[0]?.categoryID,
    FromDate: null,
    ToDate: null,
    PickupLocation: "",
    DropLocation: "",
    PickupLocationR: "",
    DropLocationR: "",
    TravelHospitalityEmployeesId: 0,
    Age: "",
    Gender: "",
    Budget: "",
    Bookingnote: "",
    TravelingDetails: [
      {
        FromDate: null,
        PickupLocation: "",
        DropLocation: "",
        Bookingnote: "",
      },
    ],
  };
  const tripInitialValuesGroup = {
    id: detailsData?._request?.id,
    Type: detailsData?._request?.type,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    CategoryID: detailsData?.travellingdetails[0]?.categoryID,
    TravelingType: detailsData?.travellingdetails[0]?.travelingType,
    FromDate: null,
    ToDate: null,
    PickupLocation: "",
    DropLocation: "",
    PickupLocationR: "",
    DropLocationR: "",
    Budget: "",
    Bookingnote: "",
    groupItemsNotMulti: [
      {
        EmployeeId: null,
        Age: null,
        Gender: "",
        PhoneNumber: "",
        EmailId: "",
      },
    ],
    groupItems: [
      {
        FromDate: null,
        PickupLocation: "",
        DropLocation: "",
        Bookingnote: "",
        EmployeeDetails: [
          {
            EmployeeId: null,
            Age: null,
            Gender: "",
            PhoneNumber: "",
            EmailId: "",
          },
        ],
      },
    ],
  };
  const tripInitialValuesVendor = {
    id: detailsData?._request?.id,
    Type: detailsData?._request?.type,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    CategoryID: detailsData?.travellingdetails[0]?.categoryID,
    TravelingType: detailsData?.travellingdetails[0]?.travelingType,
    FromDate: null,
    ToDate: null,
    PickupLocation: "",
    DropLocation: "",
    PickupLocationR: "",
    DropLocationR: "",
    Budget: "",
    Bookingnote: "",
    groupItemsNotMulti: [
      {
        Name: "",
        Age: null,
        Gender: "",
        PhoneNumber: "",
        EmailId: "",
      },
    ],
    groupItems: [
      {
        FromDate: null,
        PickupLocation: "",
        DropLocation: "",
        Bookingnote: "",

        EmployeeDetails: [
          {
            Name: "",
            Age: null,
            Gender: "",
            PhoneNumber: "",
            EmailId: "",
          },
        ],
      },
    ],
  };

  const tripInitialValuesCandidate = {
    id: detailsData?._request?.id,
    IsLinkedTravelHotel: detailsData?._request?.isLinkedTravelHotel,
    Type: detailsData?._request?.type,
    TravelingType: detailsData?.travellingdetails[0]?.travelingType,
    CategoryID: detailsData?.travellingdetails[0]?.categoryID,
    CandidateId: null,
    FromDate: null,
    ToDate: null,
    PickupLocation: "",
    DropLocation: "",
    PickupLocationR: "",
    DropLocationR: "",
    Age: "",
    Gender: "",
    Budget: "",
    Bookingnote: "",
    TravelingDetails: [
      {
        FromDate: null,
        PickupLocation: "",
        DropLocation: "",
        Bookingnote: "",
      },
    ],
  };
  useEffect(() => {
    if (detailsData?._request?.type === "Self") {
      setInitialValues(tripInitialValuesSelf);
    } else if (detailsData?._request?.type === "Group") {
      setInitialValues(tripInitialValuesGroup);
    } else if (detailsData?._request?.type === "Vendor") {
      setInitialValues(tripInitialValuesVendor);
    } else if (detailsData?._request?.type === "Guest") {
      setInitialValues(tripInitialValuesVendor);
    } else if (detailsData?._request?.type === "Candidate") {
      setInitialValues(tripInitialValuesCandidate);
    }
  }, [detailsData]);
  const getValidationSchema = () => {
    if (formValues.Type === "Self") {
      if (formValues?.TravelingType === "One Way Trip")
        return flightSelfOneWaySchema;
      else if (formValues?.TravelingType === "Round Trip")
        return flightSelfRoundTripSchema;
      else if (formValues?.TravelingType === "Multilocation Trip")
        return flightSelfMultiTripSchema;
    } else if (formValues.Type === "Group") {
      if (formValues?.TravelingType === "One Way Trip")
        return flightGroupOneWaySchema;
      else if (formValues?.TravelingType === "Round Trip")
        return flightGroupRoundTripSchema;
      else if (formValues?.TravelingType === "Multilocation Trip")
        return flightGroupMultiTripSchema;
    } else if (formValues.Type === "Vendor" || formValues.Type === "Guest") {
      if (formValues?.TravelingType === "One Way Trip")
        return flightVendorOneWaySchema;
      else if (formValues?.TravelingType === "Round Trip")
        return flightVendorRoundTripSchema;
      else if (formValues?.TravelingType === "Multilocation Trip")
        return flightVendorMultiTripSchema;
    } else {
      if (formValues?.TravelingType === "One Way Trip")
        return flightCandidateOneWaySchema;
      else if (formValues?.TravelingType === "Round Trip")
        return flightCandidateRoundTripSchema;
      else if (formValues?.TravelingType === "Multilocation Trip")
        return flightCandidateMultiTripSchema;
    }
    return Yup.object({});
  };
  const flightSelfOneWaySchema = Yup.object({
    FromDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
  });
  const flightSelfRoundTripSchema = Yup.object({
    FromDate: Yup.string().required(" Please Select Date"),
    ToDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    PickupLocationR: Yup.string().required("Please Enter the Pickup Location."),
    DropLocationR: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
  });
  const flightSelfMultiTripSchema = Yup.object({
    Budget: Yup.string().required("Please Enter Budget"),
    TravelingDetails: Yup.array().of(
      Yup.object().shape({
        FromDate: Yup.string().required("Please Select Date"),
        PickupLocation: Yup.string().required("Please Enter Pickup Location"),
        DropLocation: Yup.string().required("Please Enter Drop Location"),
      })
    ),
  });
  const flightGroupOneWaySchema = Yup.object({
    FromDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),

    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        EmployeeId: Yup.string().required("Please Select Employee"),
        PhoneNumber: Yup.number()
          .typeError("Please enter a valid phone number")
          .required("Please Enter Phone Number"),
      })
    ),
  });
  const flightGroupRoundTripSchema = Yup.object({
    FromDate: Yup.string().required(" Please Select Date"),
    ToDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    PickupLocationR: Yup.string().required("Please Enter the Pickup Location."),
    DropLocationR: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),

    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        EmployeeId: Yup.string().required("Please Select Employee"),
        PhoneNumber: Yup.number()
          .typeError("Please enter a valid phone number")
          .required("Please Enter Phone Number"),
      })
    ),
  });
  const flightGroupMultiTripSchema = Yup.object({
    Budget: Yup.string().required("Please Enter Budget"),
    groupItems: Yup.array().of(
      Yup.object().shape({
        FromDate: Yup.string().required("Please Select Date"),
        PickupLocation: Yup.string().required("Please Enter Pickup Location"),
        DropLocation: Yup.string().required("Please Enter Drop Location"),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            EmployeeId: Yup.string().required("Please Select Employee"),
            PhoneNumber: Yup.number()
              .typeError("Please enter a valid phone number")
              .required("Please Enter Phone Number"),
          })
        ),
      })
    ),
  });
  const flightVendorOneWaySchema = Yup.object({
    FromDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required("Please Enter Name"),
        PhoneNumber: Yup.number()
          .typeError("Please enter a valid phone number")
          .required("Please Enter Phone Number"),
      })
    ),
  });
  const flightVendorRoundTripSchema = Yup.object({
    FromDate: Yup.string().required(" Please Select Date"),
    ToDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    PickupLocationR: Yup.string().required("Please Enter the Pickup Location."),
    DropLocationR: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required("Please Enter Name"),
        PhoneNumber: Yup.string().required("Please Enter Phone Number"),
      })
    ),
  });
  const flightVendorMultiTripSchema = Yup.object({
    Budget: Yup.string().required("Please Enter Budget"),

    groupItems: Yup.array().of(
      Yup.object().shape({
        FromDate: Yup.string().required("Please Select Date"),
        PickupLocation: Yup.string().required("Please Enter Pickup Location"),
        DropLocation: Yup.string().required("Please Enter Drop Location"),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            Name: Yup.string().required("Please Enter Name"),
            PhoneNumber: Yup.string().required("Please Enter Phone Number"),
          })
        ),
      })
    ),
  });
  const flightCandidateOneWaySchema = Yup.object({
    CandidateId: Yup.string().required(" Please Select Candidate"),
    FromDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
  });
  const flightCandidateRoundTripSchema = Yup.object({
    CandidateId: Yup.string().required(" Please Select Candidate"),
    FromDate: Yup.string().required(" Please Select Date"),
    ToDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    PickupLocationR: Yup.string().required("Please Enter the Pickup Location."),
    DropLocationR: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
  });
  const flightCandidateMultiTripSchema = Yup.object({
    CandidateId: Yup.string().required(" Please Select Candidate"),
    Budget: Yup.string().required("Please Enter Budget"),

    TravelingDetails: Yup.array().of(
      Yup.object().shape({
        FromDate: Yup.string().required("Please Select Date"),
        PickupLocation: Yup.string().required("Please Enter Pickup Location"),
        DropLocation: Yup.string().required("Please Enter Drop Location"),
      })
    ),
  });

  const onSubmit = (values) => {
    const payload = {
      id: values?.id,
    };

    if (values.Type === "Self") {
      if (values.TravelingType === "One Way Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsNew = [
          {
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocation,
            DropLocation: values?.DropLocation,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: [
              {
                id: values?.TravelHospitalityEmployeesId,
                Age: Number(values?.Age),
                Gender: values?.Gender,
              },
            ],
          },
        ];
        payload["TravelingDetailsNew"] = TravelingDetailsNew;
      }
      if (values.TravelingType === "Round Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsNew = [
          {
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocation,
            DropLocation: values?.DropLocation,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: [
              {
                id: values?.TravelHospitalityEmployeesId,
                Age: Number(values?.Age),
                Gender: values?.Gender,
              },
            ],
          },
          {
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.ToDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocationR,
            DropLocation: values?.DropLocationR,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: [
              {
                id: values?.TravelHospitalityEmployeesId,
                Age: Number(values?.Age),
                Gender: values?.Gender,
              },
            ],
          },
        ];
        payload["TravelingDetailsNew"] = TravelingDetailsNew;
      }
      if (values.TravelingType === "Multilocation Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsNew = values?.TravelingDetails?.map((item) => ({
          id: item?.id ?? 0,
          FromDate:
            format(new Date(item.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
          PickupLocation: item?.PickupLocation,
          DropLocation: item?.DropLocation,
          Bookingnote: item?.Bookingnote,
          TravelingType: values.TravelingType,
          CategoryID: values.CategoryID,
          TravelHospitalityEmployeeNew: [
            {
              id: values?.TravelHospitalityEmployeesId,
              Age: Number(values?.Age),
              Gender: values?.Gender,
            },
          ],
        }));
        payload["TravelingDetailsNew"] = TravelingDetailsNew;
      }
    } else if (values.Type === "Group") {
      if (values.TravelingType === "One Way Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsObj = [
          {
            id: values?.id ?? 0,
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocation,
            DropLocation: values?.DropLocation,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: values?.groupItemsNotMulti?.map(
              (items) => ({
                id: items?.id ?? 0,
                Name: employeeList?.filter(
                  (item) => item?.value === items?.EmployeeId
                )[0]?.label,
                EmployeeId: items?.EmployeeId,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Age: Number(items?.Age),
                Gender: items?.Gender,
              })
            ),
          },
        ];
        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
      if (values.TravelingType === "Round Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsObj = [
          {
            id: detailsData?.travellingdetails[0]?.id ?? 0,
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocation,
            DropLocation: values?.DropLocation,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: values?.groupItemsNotMulti?.map(
              (items) => ({
                id: items?.id ?? 0,
                Name: employeeList?.filter(
                  (item) => item?.value === items?.EmployeeId
                )[0]?.label,
                EmployeeId: items?.EmployeeId,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Age: Number(items?.Age),
                Gender: items?.Gender,
              })
            ),
          },
          {
            id: detailsData?.travellingdetails[1]?.id ?? 0,
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.ToDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocationR,
            DropLocation: values?.DropLocationR,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: values?.groupItemsNotMulti?.map(
              (items) => ({
                id: items?.id ?? 0,
                Name: employeeList?.filter(
                  (item) => item?.value === items?.EmployeeId
                )[0]?.label,
                EmployeeId: items?.EmployeeId,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Age: Number(items?.Age),
                Gender: items?.Gender,
              })
            ),
          },
        ];
        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
      if (values.TravelingType === "Multilocation Trip") {
        payload["Type"] = values.Type;
        payload["Mode"] = values.Mode;
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;

        const TravelingDetailsObj = values?.groupItems?.map((item) => ({
          id: item?.id ?? 0,
          TravelingType: values.TravelingType,
          CategoryID: values.CategoryID,
          FromDate:
            format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
          PickupLocation: item?.PickupLocation,
          DropLocation: item?.DropLocation,
          Bookingnote: item?.Bookingnote,
          TravelHospitalityEmployeeNew: item?.EmployeeDetails?.map((items) => ({
            id: items?.id ?? 0,
            Name: employeeList?.filter(
              (item) => item?.value === items?.EmployeeId
            )[0]?.label,
            EmployeeId: items?.EmployeeId,
            PhoneNumber: items?.PhoneNumber?.toString(),
            EmailId: items?.EmailId,
            Age: Number(items?.Age),
            Gender: items?.Gender,
          })),
        }));

        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
    } else if (values.Type === "Vendor" || values.Type === "Guest") {
      if (values.TravelingType === "One Way Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsObj = [
          {
            id: values?.id ?? 0,
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocation,
            DropLocation: values?.DropLocation,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: values?.groupItemsNotMulti?.map(
              (items) => ({
                id: items?.id ?? 0,
                Name: items?.Name,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Age: Number(items?.Age),
                Gender: items?.Gender,
              })
            ),
          },
        ];
        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
      if (values.TravelingType === "Round Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsObj = [
          {
            id: detailsData?.travellingdetails[0]?.id ?? 0,
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocation,
            DropLocation: values?.DropLocation,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: values?.groupItemsNotMulti?.map(
              (items) => ({
                id: items?.id ?? 0,
                Name: items?.Name,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Age: Number(items?.Age),
                Gender: items?.Gender,
              })
            ),
          },
          {
            id: detailsData?.travellingdetails[1]?.id ?? 0,
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.ToDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocationR,
            DropLocation: values?.DropLocationR,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: values?.groupItemsNotMulti?.map(
              (items) => ({
                id: items?.id ?? 0,
                Name: items?.Name,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Age: Number(items?.Age),
                Gender: items?.Gender,
              })
            ),
          },
        ];
        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
      if (values.TravelingType === "Multilocation Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;

        const TravelingDetailsObj = values?.groupItems?.map((item) => ({
          id: item?.id ?? 0,
          TravelingType: values.TravelingType,
          CategoryID: values.CategoryID,
          FromDate:
            format(new Date(item.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
          PickupLocation: item?.PickupLocation,
          DropLocation: item?.DropLocation,
          Bookingnote: item?.Bookingnote,
          TravelHospitalityEmployeeNew: item?.EmployeeDetails?.map((items) => ({
            id: items?.id ?? 0,
            Name: items?.Name,
            PhoneNumber: items?.PhoneNumber?.toString(),
            EmailId: items?.EmailId,
            Age: Number(items?.Age),
            Gender: items?.Gender,
          })),
        }));

        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
    } else if (values.Type === "Candidate") {
      if (values.TravelingType === "One Way Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsObj = [
          {
            id: values?.id ?? 0,
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocation,
            DropLocation: values?.DropLocation,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: [
              {
                Age: Number(values?.Age),
                Gender: values?.Gender,
                CandidateId: values.CandidateId,
              },
            ],
          },
        ];
        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
      if (values.TravelingType === "Round Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;
        const TravelingDetailsObj = [
          {
            id: detailsData?.travellingdetails[0]?.id ?? 0,
            TravelingType: values.TravelingType,
            CategoryID: values.CategoryID,
            FromDate:
              format(new Date(values.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocation,
            DropLocation: values?.DropLocation,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: [
              {
                Age: Number(values?.Age),
                Gender: values?.Gender,
                CandidateId: values.CandidateId,
              },
            ],
          },
          {
            id: detailsData?.travellingdetails[1]?.id ?? 0,
            TravelingType: values.TravelingType,
            FromDate:
              format(new Date(values.ToDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: values?.PickupLocationR,
            DropLocation: values?.DropLocationR,
            CategoryID: values.CategoryID,
            Bookingnote: values?.Bookingnote,
            TravelHospitalityEmployeeNew: [
              {
                Age: values?.Age,
                Gender: values?.Gender,
                CandidateId: values.CandidateId,
              },
            ],
          },
        ];
        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
      if (values.TravelingType === "Multilocation Trip") {
        payload["Budget"] = Number(values.Budget);
        payload["IsLinkedTravelHotel"] = false;

        const TravelingDetailsObj = values?.TravelingDetails?.map((item) => ({
          id: item?.id ?? 0,
          TravelingType: values.TravelingType,
          CategoryID: values.CategoryID,
          FromDate:
            format(new Date(item.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
          PickupLocation: item?.PickupLocation,
          DropLocation: item?.DropLocation,
          Bookingnote: item?.Bookingnote,
          TravelHospitalityEmployeeNew: [
            {
              Age: Number(values?.Age),
              Gender: values?.Gender,
              CandidateId: values.CandidateId,
            },
          ],
        }));

        payload["TravelingDetailsNew"] = TravelingDetailsObj;
      }
    }

    create(payload);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={getValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        chooseFormValues && chooseFormValues(formik.values);
        return (
          <Form
            style={{
              padding: " 15px 15px 0 15px ",
              position: "relative",
              height: "100%",
              flexGrow: 1,
            }}
          >
            {(detailsData?._request?.type === "Self" ||
              detailsData?._request?.type === "Candidate") &&
              detailsData?.travellingdetails[0]?.travelingType &&
              detailsData?.travellingdetails[0]?.travelingType !==
                "Multilocation Trip" && (
                <>
                  <div className="formcustom threeColumn">
                    {/* <div>
                      <LabelCustom
                        labelName={"Travel Category"}
                        mandatory={true}
                      />
                      <SelectForm
                        name="CategoryID"
                        options={travelCategoryOption}
                      />
                    </div> */}
                    {detailsData?._request?.type === "Candidate" && (
                      <div>
                        <LabelCustom labelName={"Candidate"} mandatory={true} />
                        <SelectForm
                          name="CandidateId"
                          options={candidateList}
                        />
                      </div>
                    )}
                    <div>
                      <LabelCustom
                        labelName={"Travelling Date"}
                        mandatory={true}
                      />
                      <DatePickerFormiks
                        name={"FromDate"}
                        minDate={new Date()}
                      />
                    </div>
                    <div>
                      <LabelCustom
                        labelName={"From Location"}
                        mandatory={true}
                      />
                      <InputCustomFormik
                        maxLength={50}
                        name="PickupLocation"
                        type={"text"}
                        placeholder={" Enter From Location"}
                      />
                    </div>
                    <div>
                      <LabelCustom labelName={"To Location"} mandatory={true} />
                      <InputCustomFormik
                        maxLength={50}
                        name="DropLocation"
                        type={"text"}
                        placeholder={" Enter To Location"}
                      />
                    </div>
                    {detailsData?.travellingdetails[0]?.travelingType ===
                      "Round Trip" && (
                      <>
                        <div>
                          <LabelCustom
                            labelName={"Return Date"}
                            mandatory={true}
                          />
                          <DatePickerFormiks
                            name={"ToDate"}
                            minDate={new Date(formValues?.FromDate)}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"From Location"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            maxLength={50}
                            name="PickupLocationR"
                            type={"text"}
                            placeholder={"Enter From Location"}
                          />
                        </div>

                        <div>
                          <LabelCustom
                            labelName={"To Location"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            maxLength={50}
                            name="DropLocationR"
                            type={"text"}
                            placeholder={"Enter To Location"}
                          />
                        </div>
                      </>
                    )}
                    <div>
                      <LabelCustom labelName={"Age"} />
                      <InputCustomFormik
                        type="number"
                        maxLength={2}
                        name="Age"
                        placeholder={"Enter Age"}
                      />
                    </div>
                    <div>
                      <LabelCustom labelName={"Gender"} />
                      <SelectForm name="Gender" options={genderOptions} />
                    </div>
                    <div>
                      <LabelCustom
                        labelName={"Budget (In Thousands)"}
                        mandatory={true}
                      />
                      <InputCustomFormik
                        // disabled={true}
                        // value={
                        //   formValues?.TravelingType === "Multilocation Trip"
                        //     ? budget * formValues?.TravelingDetails?.length
                        //     : budget
                        // }
                        type="number"
                        name="Budget"
                        placeholder={" Enter Budget"}
                      />
                    </div>
                    <div>
                      <LabelCustom labelName={"Booking Note"} />
                      <TextAreaFormik
                        name="Bookingnote"
                        placeholder={" Enter Booking Note"}
                        maxLength={500}
                      />
                    </div>
                  </div>
                </>
              )}
            {(detailsData?._request?.type === "Self" ||
              detailsData?._request?.type === "Candidate") &&
              detailsData?.travellingdetails[0]?.travelingType &&
              detailsData?.travellingdetails[0]?.travelingType ===
                "Multilocation Trip" && (
                <>
                  <div className="formcustom threeColumn">
                    <div>
                      <LabelCustom
                        labelName={"Budget (In Thousands)"}
                        mandatory={true}
                      />
                      <InputCustomFormik
                        // disabled={true}
                        // value={
                        //   formValues?.TravelingType === "Multilocation Trip"
                        //     ? budget * formValues?.TravelingDetails?.length
                        //     : budget
                        // }
                        type="number"
                        name="Budget"
                        placeholder={" Enter Budget"}
                      />
                    </div>
                    <div>
                      <LabelCustom labelName={"Age"} />
                      <InputCustomFormik
                        type="number"
                        maxLength={2}
                        name="Age"
                        placeholder={"Enter Age"}
                      />
                    </div>
                    <div>
                      <LabelCustom labelName={"Gender"} />
                      <SelectForm name="Gender" options={genderOptions} />
                    </div>
                    {detailsData?._request?.type === "Candidate" && (
                      <div>
                        <LabelCustom labelName={"Candidate"} mandatory={true} />
                        <SelectForm
                          name="CandidateId"
                          options={candidateList}
                        />
                      </div>
                    )}
                  </div>
                  <div className="repeatedSectionContainer">
                    <FieldArray name="TravelingDetails">
                      {(fieldArrayProps) => {
                        const { push, remove, form } = fieldArrayProps;
                        const { values, errors } = form;
                        const { TravelingDetails } = values;
                        return (
                          <StyledTableCont>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    padding="0"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"S. No."} />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    padding="0"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Travelling Date"}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>

                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"From Location"}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"To Location"}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"Booking Note"} />{" "}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={" Add/Remove"} />
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {TravelingDetails?.map((Requestlist, index) => (
                                <TableBody key={index}>
                                  {/* 1st column */}
                                  <StyledTableCell align="center">
                                    {index + 1}
                                  </StyledTableCell>
                                  {/* 2nd column */}
                                  <StyledTableCell>
                                    <DatePickerFormiks
                                      name={`TravelingDetails[${index}].FromDate`}
                                      minDate={new Date()}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      maxLength={50}
                                      name={`TravelingDetails[${index}].PickupLocation`}
                                      type="text"
                                      placeholder={" Enter From Location"}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      maxLength={50}
                                      name={`TravelingDetails[${index}].DropLocation`}
                                      type={"text"}
                                      placeholder={" Enter To Location"}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextAreaFormik
                                      name={`TravelingDetails[${index}].Bookingnote`}
                                      placeholder={" Enter Note"}
                                      maxLength={500}
                                    />
                                  </StyledTableCell>

                                  {/* 8th column */}
                                  <StyledTableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="remove-btn"
                                      onClick={() =>
                                        TravelingDetails.length > 1
                                          ? remove(index)
                                          : dispatch(
                                              showToast({
                                                text: "Atleast One Item is Required ",
                                                severity: "error",
                                              })
                                            )
                                      }
                                    >
                                      -
                                    </button>
                                    <button
                                      type="button"
                                      className="add-btn"
                                      onClick={() =>
                                        push({
                                          FromDate: "",
                                          PickupLocation: "",
                                          DropLocation: "",
                                          Remarks: "",
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </StyledTableCell>
                                </TableBody>
                              ))}
                            </Table>
                          </StyledTableCont>
                        );
                      }}
                    </FieldArray>
                  </div>
                </>
              )}
            {(detailsData?._request?.type === "Group" ||
              detailsData?._request?.type === "Vendor" ||
              detailsData?._request?.type === "Guest") &&
              detailsData?.travellingdetails[0]?.travelingType &&
              detailsData?.travellingdetails[0]?.travelingType !==
                "Multilocation Trip" && (
                <>
                  <div className="formcustom threeColumn">
                    <div>
                      <LabelCustom
                        labelName={"Travelling Date"}
                        mandatory={true}
                      />
                      <DatePickerFormiks
                        name={"FromDate"}
                        minDate={new Date()}
                      />
                    </div>
                    <div>
                      <LabelCustom
                        labelName={"From Location"}
                        mandatory={true}
                      />
                      <InputCustomFormik
                        maxLength={50}
                        name="PickupLocation"
                        type={"text"}
                        placeholder={" Enter from Location"}
                      />
                    </div>

                    <div>
                      <LabelCustom labelName={"To Location"} mandatory={true} />
                      <InputCustomFormik
                        maxLength={50}
                        name="DropLocation"
                        type={"text"}
                        placeholder={" Enter to Location"}
                      />
                    </div>
                    {formValues?.TravelingType === "Round Trip" && (
                      <>
                        <div>
                          <LabelCustom
                            labelName={"Return Date"}
                            mandatory={true}
                          />
                          <DatePickerFormiks
                            name={"ToDate"}
                            minDate={new Date(formValues?.FromDate)}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"From Location"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            maxLength={50}
                            name="PickupLocationR"
                            type={"text"}
                            placeholder={" Enter From Location"}
                          />
                        </div>

                        <div>
                          <LabelCustom
                            labelName={"To Location"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            maxLength={50}
                            name="DropLocationR"
                            type={"text"}
                            placeholder={" Enter To Location"}
                          />
                        </div>
                      </>
                    )}
                    <div>
                      <LabelCustom
                        labelName={"Budget (In Thousands)"}
                        mandatory={true}
                      />
                      <InputCustomFormik
                        type="number"
                        name="Budget"
                        placeholder={"Enter Budget"}
                      />
                    </div>
                    <div>
                      <LabelCustom labelName={"Booking Note"} />
                      <TextAreaFormik
                        name="Bookingnote"
                        placeholder={" Enter Booking Note"}
                        maxLength={500}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="repeatedHeading">
                      Please specify the employee's details
                    </label>
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <FieldArray name="groupItemsNotMulti">
                        {(fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps;
                          const { values } = form;
                          const { groupItemsNotMulti } = values;
                          return (
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    padding="0"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"S. No"} />
                                  </StyledTableCell>
                                  {detailsData?._request?.type === "Group" && (
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom
                                        labelName={"Employee Name"}
                                        mandatory={true}
                                        style={{
                                          margin: "0px 0px 0px 5px",
                                        }}
                                      />
                                    </StyledTableCell>
                                  )}
                                  {detailsData?._request?.type === "Vendor" && (
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom
                                        labelName={"Vendor Name"}
                                        mandatory={true}
                                        style={{
                                          margin: "0px 0px 0px 5px",
                                        }}
                                      />
                                    </StyledTableCell>
                                  )}
                                  {detailsData?._request?.type === "Guest" && (
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom
                                        labelName={"Guest Name"}
                                        mandatory={true}
                                        style={{
                                          margin: "0px 0px 0px 5px",
                                        }}
                                      />
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Phone No."}
                                      mandatory={true}
                                      style={{ margin: "0px 0px 0px 5px" }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Email"}
                                      style={{ margin: "0px 0px 0px 5px" }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Age"}
                                      style={{ margin: "0px 0px 0px 5px" }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Gender"}
                                      style={{ margin: "0px 0px 0px 5px" }}
                                    />
                                  </StyledTableCell>

                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"Add/Remove"} />
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {groupItemsNotMulti?.map((groupItemss, index) => (
                                <TableBody key={index}>
                                  {/* 1st column */}
                                  <StyledTableCell align="center">
                                    {index + 1}
                                  </StyledTableCell>
                                  {/* 2nd column */}

                                  {detailsData?._request?.type === "Group" && (
                                    <StyledTableCell>
                                      <SelectForm
                                        name={`groupItemsNotMulti[${index}].EmployeeId`}
                                        options={employeeList}
                                      />
                                    </StyledTableCell>
                                  )}
                                  {(detailsData?._request?.type === "Vendor" ||
                                    detailsData?._request?.type ===
                                      "Guest") && (
                                    <StyledTableCell>
                                      <InputCustomFormik
                                        name={`groupItemsNotMulti[${index}].Name`}
                                        placeholder={" Enter Name"}
                                      />
                                    </StyledTableCell>
                                  )}

                                  {/* 3rd column */}
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      maxLength={10}
                                      type="text"
                                      name={`groupItemsNotMulti[${index}].PhoneNumber`}
                                      placeholder={" Enter Phone No."}
                                    />
                                  </StyledTableCell>
                                  {/* 4th column */}
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      maxLength={320}
                                      type="text"
                                      name={`groupItemsNotMulti[${index}].EmailId`}
                                      placeholder={"Enter the Email"}
                                    />
                                  </StyledTableCell>
                                  {/* 5th column */}
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      type="number"
                                      name={`groupItemsNotMulti[${index}].Age`}
                                      placeholder={"Enter the Age"}
                                    />
                                  </StyledTableCell>
                                  {/* 6th column */}
                                  <StyledTableCell>
                                    <SelectForm
                                      name={`groupItemsNotMulti[${index}].Gender`}
                                      options={genderOptions}
                                    />
                                  </StyledTableCell>

                                  <StyledTableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="remove-btn"
                                      onClick={() =>
                                        groupItemsNotMulti.length > 1
                                          ? remove(index)
                                          : dispatch(
                                              showToast({
                                                text: "Atleast One Item is Required ",
                                                severity: "error",
                                              })
                                            )
                                      }
                                    >
                                      -
                                    </button>
                                    <button
                                      type="button"
                                      className="add-btn"
                                      onClick={() => {
                                        if (
                                          detailsData?._request?.type ===
                                            "Vendor" ||
                                          detailsData?._request?.type ===
                                            "Guest"
                                        ) {
                                          push({
                                            Name: "",
                                            PhoneNumber: "",
                                            EmailId: "",
                                            Adults: "",
                                            Children: "",
                                          });
                                        } else {
                                          push({
                                            EmployeeId: null,
                                            PhoneNumber: "",
                                            EmailId: "",
                                            Adults: "",
                                            Children: "",
                                          });
                                        }
                                      }}
                                    >
                                      +
                                    </button>
                                  </StyledTableCell>
                                </TableBody>
                              ))}
                            </Table>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </>
              )}
            {(detailsData?._request?.type === "Group" ||
              detailsData?._request?.type === "Vendor" ||
              detailsData?._request?.type === "Guest") &&
              detailsData?.travellingdetails[0]?.travelingType &&
              detailsData?.travellingdetails[0]?.travelingType ===
                "Multilocation Trip" && (
                <>
                  <div className="formcustom threeColumn">
                    <div>
                      <LabelCustom
                        labelName={"Budget (In Thousands)"}
                        mandatory={true}
                      />
                      <InputCustomFormik
                        type="number"
                        name="Budget"
                        placeholder={"Enter Budget"}
                      />
                    </div>
                  </div>
                  <div>
                    <FieldArray name="groupItems">
                      {(fieldArrayProps) => {
                        const { remove, insert, form } = fieldArrayProps;
                        const { values } = form;
                        const { groupItems } = values;
                        return (
                          <Table style={{ width: "100%" }}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  padding="0"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"S. No."} />
                                </StyledTableCell>
                                <StyledTableCell
                                  padding="0"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Journey"} />
                                </StyledTableCell>

                                <StyledTableCell
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Add/Remove"} />
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            {groupItems?.map((Questions, index) => (
                              <TableBody key={index}>
                                <StyledTableCell
                                  sx={{ width: "96px" }}
                                  align="center"
                                >
                                  {index + 1}
                                </StyledTableCell>
                                <StyledTableCell sx={{ maxWidth: "920px" }}>
                                  <div>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #E6E6E6",
                                      }}
                                      className="formcustom fourColumn"
                                    >
                                      <div>
                                        <LabelCustom
                                          labelName={"Travelling Date"}
                                          mandatory={true}
                                        />
                                        <DatePickerFormiks
                                          name={`groupItems[${index}].FromDate`}
                                          minDate={new Date()}
                                        />
                                      </div>
                                      <div>
                                        <LabelCustom
                                          labelName={"Form Location"}
                                          mandatory={true}
                                        />

                                        <InputCustomFormik
                                          maxLength={50}
                                          name={`groupItems[${index}].PickupLocation`}
                                          type="text"
                                          placeholder={" Enter From Location"}
                                        />
                                      </div>
                                      <div>
                                        <LabelCustom
                                          labelName={"To Location"}
                                          mandatory={true}
                                        />

                                        <InputCustomFormik
                                          maxLength={50}
                                          name={`groupItems[${index}].DropLocation`}
                                          type={"text"}
                                          placeholder={" Enter To Location"}
                                        />
                                      </div>
                                      <div>
                                        <LabelCustom
                                          labelName={"Booking Note"}
                                        />

                                        <TextAreaFormik
                                          name={`groupItems[${index}].Bookingnote`}
                                          placeholder={" Enter Comments"}
                                          maxLength={500}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      {Questions?.EmployeeDetails?.map(
                                        (item, indexW) => (
                                          <div
                                            style={{
                                              overflow: "auto",
                                              width: "100%",
                                              height: "197px",
                                            }}
                                          >
                                            <div
                                              className="formcustom sixColumn"
                                              style={{
                                                minWidth: "1152px",
                                                padding: "18px 0px",
                                                position: "relative",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  width: "67px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                <LabelCustom
                                                  labelName={"S. No."}
                                                />

                                                <div
                                                  style={{
                                                    padding: "21px 8px",
                                                  }}
                                                >
                                                  {indexW + 1}
                                                </div>
                                              </div>
                                              <div style={{ width: "264px" }}>
                                                {detailsData?._request?.type ===
                                                  "Group" && (
                                                  <LabelCustom
                                                    labelName={"Employee Name"}
                                                    mandatory={true}
                                                    style={{
                                                      margin: "0px 0px 0px 5px",
                                                    }}
                                                  />
                                                )}
                                                {detailsData?._request?.type ===
                                                  "Vendor" && (
                                                  <LabelCustom
                                                    labelName={"Vendor Name"}
                                                    mandatory={true}
                                                    style={{
                                                      margin: "0px 0px 0px 5px",
                                                    }}
                                                  />
                                                )}
                                                {detailsData?._request?.type ===
                                                  "Guest" && (
                                                  <LabelCustom
                                                    labelName={"Guest Name"}
                                                    mandatory={true}
                                                    style={{
                                                      margin: "0px 0px 0px 5px",
                                                    }}
                                                  />
                                                )}
                                                {detailsData?._request?.type ===
                                                  "Group" && (
                                                  <SelectForm
                                                    name={`groupItems[${index}].EmployeeDetails[${indexW}].EmployeeId`}
                                                    options={employeeList}
                                                  />
                                                )}
                                                {(detailsData?._request
                                                  ?.type === "Vendor" ||
                                                  detailsData?._request
                                                    ?.type === "Guest") && (
                                                  <InputCustomFormik
                                                    name={`groupItems[${index}].EmployeeDetails[${indexW}].Name`}
                                                    placeholder={" Enter Name"}
                                                  />
                                                )}
                                              </div>
                                              <div style={{ width: "264px" }}>
                                                <LabelCustom
                                                  labelName={"Phone No."}
                                                  mandatory={true}
                                                />

                                                <InputCustomFormik
                                                  maxLength={10}
                                                  type="text"
                                                  name={`groupItems[${index}].EmployeeDetails[${indexW}].PhoneNumber`}
                                                  placeholder={
                                                    " Enter Phone No."
                                                  }
                                                />
                                              </div>
                                              <div style={{ width: "264px" }}>
                                                <LabelCustom
                                                  labelName={"Email"}
                                                  mandatory={true}
                                                />
                                                <InputCustomFormik
                                                  maxLength={320}
                                                  type="text"
                                                  name={`groupItems[${index}].EmployeeDetails[${indexW}].EmailId`}
                                                  placeholder={
                                                    "Enter the Email"
                                                  }
                                                />
                                              </div>
                                              <div style={{ width: "264px" }}>
                                                <LabelCustom
                                                  labelName={"Age"}
                                                  mandatory={true}
                                                />
                                                <InputCustomFormik
                                                  type="number"
                                                  name={`groupItems[${index}].EmployeeDetails[${indexW}].Age`}
                                                  placeholder={"Enter the Age"}
                                                />
                                              </div>
                                              <div style={{ width: "264px" }}>
                                                <LabelCustom
                                                  labelName={"Gender"}
                                                  mandatory={true}
                                                />
                                                <SelectForm
                                                  name={`groupItems[${index}].EmployeeDetails[${indexW}].Gender`}
                                                  options={genderOptions}
                                                />
                                              </div>
                                            </div>
                                            {indexW ===
                                            Questions?.EmployeeDetails?.length -
                                              1 ? (
                                              <div
                                                className="surveyAddBtnContainer"
                                                style={{
                                                  position: "sticky",
                                                  left: "86%",
                                                  /* right: 30px; */
                                                  bottom: "21px",
                                                  width: "131px",
                                                  // position: "absolute",
                                                  // right: "12px",
                                                  // bottom: "21px",
                                                }}
                                                onClick={() =>
                                                  pushEmployee(index)
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  className="add-survey-btn"
                                                >
                                                  +
                                                </button>{" "}
                                                Add Employee
                                              </div>
                                            ) : (
                                              <div
                                                className="surveyAddBtnContainer"
                                                style={{
                                                  position: "sticky",
                                                  left: "83.5%",
                                                  bottom: "21px",
                                                  width: "140px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  color: "red",
                                                }}
                                                onClick={() =>
                                                  popAnswer(index, indexW)
                                                }
                                              >
                                                <img
                                                  // onClick={() => {
                                                  //   popAnswer(answer, answerIndex, index);
                                                  // }}
                                                  style={{
                                                    marginRight: "5px",
                                                    cursor: "pointer",
                                                  }}
                                                  src={SurveyAnswersDeleteIcon}
                                                  alt="SurveyAnswersDeleteIcon"
                                                />
                                                Remove Employee
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  sx={{ width: "100px", textAlign: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="remove-btn"
                                    onClick={() =>
                                      groupItems.length > 1
                                        ? remove(index)
                                        : dispatch(
                                            showToast({
                                              text: "Atleast One Item is Required ",
                                              severity: "error",
                                            })
                                          )
                                    }
                                  >
                                    -
                                  </button>
                                  <button
                                    type="button"
                                    className="add-btn"
                                    onClick={() => {
                                      if (
                                        detailsData?._request?.type ===
                                          "Vendor" ||
                                        detailsData?._request?.type === "Guest"
                                      ) {
                                        insert(index + 1, {
                                          TravelingType: "",
                                          FromDate: null,
                                          PickupLocation: "",
                                          DropLocation: "",
                                          Bookingnote: "",
                                          EmployeeDetails: [
                                            {
                                              Name: "",
                                              Age: null,
                                              Gender: "",
                                              PhoneNumber: "",
                                              EmailId: "",
                                            },
                                          ],
                                        });
                                      } else {
                                        insert(index + 1, {
                                          TravelingType: "",
                                          FromDate: null,
                                          PickupLocation: "",
                                          DropLocation: "",
                                          Bookingnote: "",
                                          EmployeeDetails: [
                                            {
                                              EmployeeId: null,
                                              Age: null,
                                              Gender: "",
                                              PhoneNumber: "",
                                              EmailId: "",
                                            },
                                          ],
                                        });
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                </StyledTableCell>
                              </TableBody>
                            ))}
                          </Table>
                        );
                      }}
                    </FieldArray>
                  </div>
                </>
              )}

            <div className="createFootCust">
              <div>
                <div onClick={togglePopup} className={`button secondaryButton`}>
                  Cancel
                </div>
              </div>
              <div>
                <button className="button primaryButton" type="submit">
                  Create
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TravelBookingAddJourney;
