import React, { useState } from "react";
import CourseIcon from "../../../Assets/CourseIcon.svg";
import CourseSmallIcon from "../../../Assets/CourseSmallIcon.svg";
import ChapterSmallIcon from "../../../Assets/ChapterSmallIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import VideoIcon from "../../../Assets/VideoIcon.svg";
import { ProgressBarComponent } from "./ProgressBarComponent";

const CourseContainer = ({
  backgroundStyles,
  styles,
  name,
  chapters,
  courses,
  categoryId,
  indexEven,
  isMandatory,
  percentage,
  isCompleted,
  remainingDuration,
  totalDuration,
  imgName,
  courseNumber,
}) => {
  const navigate = useNavigate();
  const videoImageColor = {
    backgroundColor: indexEven === "first" ? "#D8F0F2" : "#FFEEF2",
    color: indexEven === "first" ? "#1296B0" : "#DE64A1",
  };
  const videoImagejustcolor = {
    color: indexEven === "first" ? "#1296B0" : "#DE64A1",
  };
  const timeInHrs = (time) => {
    const totalSeconds = time;
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    return `${hours} Hrs ${minutes} Mins`;
  };

  return (
    <div
      onClick={() => {
        navigate("/ld/learning/mylearning/learningvideos", {
          state: { categoryId },
        });
      }}
      className="courseBlockHolder cursor"
      style={{ borderColor: `${videoImageColor?.backgroundColor}` }}
    >
      <div className="courseBlockHolderdiv ">
        <div
          style={{ width: "87px", maxHeight: "139px" }}

        // onClick={() => {
        //   navigate("/ld/learning/mylearning/learningvideos");
        // }}
        >
          {/* <img  src={VideoIcon} alt={VideoIcon} /> */}
          {/* <svg
            className="VideoIconimg"
            width="87"
            height="93"
            viewBox="0 0 87 93"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="85.9901"
              height="91.5315"
              rx="4"
              transform="matrix(-1 0 0 1 86.2637 0.545898)"
              fill={videoImageColor?.backgroundColor}
            />
            <rect
              x="24.9434"
              y="33.5771"
              width="27.087"
              height="25.4691"
              rx="4"
              fill={videoImageColor?.color}
            />
            <path
              d="M48.0879 46.3112L61.593 34.6162V58.0063L48.0879 46.3112Z"
              fill={videoImageColor?.color}
            />
          </svg> */}
          <img
            // style={{ width: "87px", maxHeight: "139px" }}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            }}
            src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TrainingCategories/${imgName}`}
            alt="Cover-img"
          />
        </div>
        <div className="courseDetails" style={{ paddingTop: 10 }}>

          <div className="courseHeadingContainer" style={{ ...styles, display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 10, paddingBottom: 5 }} >
            <div style={{ marginRight: 10 }}>
              {courseNumber}. {name}
            </div>

            {isMandatory ? <div className="mandatoryfont" style={{ marginRight: 10, marginTop: -4 }}>Mandatory</div> : ""}
          </div>
          <div className="courseDetailsContainer">
            <div className="courseMoreDetails">
              <div className="courseDetail">
                <svg
                  className="courseDetailImage"
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16373 11.2163H1.19441C0.996573 11.2161 0.806898 11.1375 0.667007 10.9976C0.527117 10.8577 0.44844 10.668 0.448242 10.4702V1.51619C0.44844 1.31835 0.527117 1.12867 0.667007 0.988785C0.806898 0.848894 0.996573 0.770217 1.19441 0.77002H7.16373C7.36157 0.770217 7.55124 0.848894 7.69113 0.988785C7.83102 1.12867 7.9097 1.31835 7.9099 1.51619V7.71608L6.04448 6.78337L4.17907 7.71608V1.51619H1.19441V10.4702H7.16373V8.97784H7.9099V10.4702C7.9096 10.668 7.83089 10.8576 7.69102 10.9975C7.55116 11.1373 7.36154 11.216 7.16373 11.2163ZM6.04448 5.94916L7.16373 6.50878V1.51619H4.92524V6.50878L6.04448 5.94916Z"
                    fill={videoImageColor?.color}
                  />
                </svg>

                <label className="courseLabel">{courses} Chapters</label>
              </div>
              <div className="courseDetail">
                {indexEven === "first" ? (
                  <img
                    className="courseDetailImage"
                    src={ChapterSmallIcon}
                    alt={ChapterSmallIcon}
                  />
                ) : (
                  <svg
                    className="courseDetailImage"
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.24268 3.79295L6.6036 0.77002L11.8653 3.79295L6.6036 6.67718L1.24268 3.79295Z"
                      stroke="#DE64A1"
                      stroke-width="0.783474"
                    />
                    <path
                      d="M3.28949 4.9751L1.24268 6.1291L6.6036 9.01332L11.8653 6.1291L9.79737 4.9751"
                      stroke="#DE64A1"
                      stroke-width="0.783474"
                    />
                    <path
                      d="M3.28949 7.17822L1.24268 8.33222L6.6036 11.2164L11.8653 8.33222L9.79737 7.17822"
                      stroke="#DE64A1"
                      stroke-width="0.783474"
                    />
                  </svg>
                )}

                <label className="courseLabel">{`${chapters} Lessons`}</label>
              </div>
            </div>
            <div className="courseMoreDetails">
              <div className="courseDetailcolumn">
                <div className="totaldurationfont"> Total Duration</div>
                <div className="totalDurationAlign">
                  {" "}
                  <svg
                    style={{ marginRight: "8px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                  >
                    <path
                      d="M4.57368 3.64935H5.56891V6.63506H4.57368V3.64935ZM6.56414 0.166016H3.57845V1.16125H6.56414V0.166016ZM8.56952 3.3458C9.2044 4.1377 9.55021 5.12246 9.54982 6.13744C9.54982 8.61061 7.55936 10.616 5.0713 10.616C3.88352 10.616 2.74439 10.1442 1.9045 9.30427C1.06462 8.46438 0.592773 7.32523 0.592773 6.13744C0.592773 4.94965 1.06462 3.81051 1.9045 2.97062C2.74439 2.13072 3.88352 1.65887 5.0713 1.65887C6.12624 1.65887 7.09658 2.02711 7.86788 2.65411L8.57449 1.93754C8.82828 2.15649 9.0522 2.3854 9.27613 2.63918L8.56952 3.3458ZM8.55459 6.13744C8.55459 4.21166 6.99706 2.65411 5.0713 2.65411C3.14553 2.65411 1.588 4.21166 1.588 6.13744C1.588 8.06323 3.14553 9.62078 5.0713 9.62078C6.99706 9.62078 8.55459 8.06323 8.55459 6.13744Z"
                      fill={videoImageColor?.color}
                    />
                  </svg>
                  <label
                    className="courseLabel timeFont"
                    style={{ color: `${videoImageColor?.color}` }}
                  >
                    {timeInHrs(totalDuration)}
                  </label>
                </div>
              </div>
              <div className="courseDetailcolumn">
                <div className="totaldurationfont">Remaining Time</div>
                <div className="totalDurationAlign">
                  {" "}
                  {/* <svg
                    style={{ marginRight: "8px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="11"
                    viewBox="0 0 12 11"
                    fill="none"
                  >
                    <path
                      d="M4.3686 3.64935H5.36383V6.63506H4.3686V3.64935ZM6.35906 0.166016H3.37338V1.16125H6.35906V0.166016ZM8.36444 3.3458C8.99933 4.1377 9.34513 5.12246 9.34474 6.13744C9.34474 8.61061 7.35428 10.616 4.86622 10.616C3.67844 10.616 2.53931 10.1442 1.69942 9.30427C0.859538 8.46438 0.387695 7.32523 0.387695 6.13744C0.387695 4.94965 0.859538 3.81051 1.69942 2.97062C2.53931 2.13072 3.67844 1.65887 4.86622 1.65887C5.92116 1.65887 6.8915 2.02711 7.66281 2.65411L8.36942 1.93754C8.6232 2.15649 8.84713 2.3854 9.07105 2.63918L8.36444 3.3458ZM8.34951 6.13744C8.34951 4.21166 6.79198 2.65411 4.86622 2.65411C2.94045 2.65411 1.38292 4.21166 1.38292 6.13744C1.38292 8.06323 2.94045 9.62078 4.86622 9.62078C6.79198 9.62078 8.34951 8.06323 8.34951 6.13744ZM10.34 3.15173V6.13744H11.3352V3.15173H10.34ZM10.34 8.12792H11.3352V7.13268H10.34V8.12792Z"
                      fill={videoImageColor?.color}
                    />
                  </svg> */}
                  <svg
                    style={{ marginRight: "8px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                  >
                    <path
                      d="M4.57368 3.64935H5.56891V6.63506H4.57368V3.64935ZM6.56414 0.166016H3.57845V1.16125H6.56414V0.166016ZM8.56952 3.3458C9.2044 4.1377 9.55021 5.12246 9.54982 6.13744C9.54982 8.61061 7.55936 10.616 5.0713 10.616C3.88352 10.616 2.74439 10.1442 1.9045 9.30427C1.06462 8.46438 0.592773 7.32523 0.592773 6.13744C0.592773 4.94965 1.06462 3.81051 1.9045 2.97062C2.74439 2.13072 3.88352 1.65887 5.0713 1.65887C6.12624 1.65887 7.09658 2.02711 7.86788 2.65411L8.57449 1.93754C8.82828 2.15649 9.0522 2.3854 9.27613 2.63918L8.56952 3.3458ZM8.55459 6.13744C8.55459 4.21166 6.99706 2.65411 5.0713 2.65411C3.14553 2.65411 1.588 4.21166 1.588 6.13744C1.588 8.06323 3.14553 9.62078 5.0713 9.62078C6.99706 9.62078 8.55459 8.06323 8.55459 6.13744Z"
                      fill={videoImageColor?.color}
                    />
                  </svg>
                  <label
                    className="courseLabel timeFont"
                    style={{ color: `${videoImageColor?.color}` }}
                  >
                    {timeInHrs(remainingDuration)}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="courseCompletionPercentage"
        style={isCompleted ? { backgroundColor: "#E7F8EC", display: "flex", flexDirection: "column", justifyContent: "center" } : { display: "flex", flexDirection: "column", justifyContent: "center" }}
      >
        {isCompleted ? (
          <div className="completionFont">Completed</div>
        ) : (
          <div className="completionFont">% of Completion</div>
        )}

        <div style={{ width: "100%" }}>
          <ProgressBarComponent
            textStyle={videoImagejustcolor}
            percentage={isCompleted ? 100 : percentage}
            color={isCompleted ? "#0DB53C" : videoImageColor?.color}
          />
        </div>
      </div>
    </div>
  );
};

export { CourseContainer };
