import React from "react";
import "./Policies.css";
import { PoliciesTab } from "./PoliciesTab";
import { useEffect } from "react";
const Policies = () => {
  useEffect(() => {
    document.title = `PeopleSol - Policies`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <PoliciesTab title="Policies" />
    </div>
  );
};
export { Policies };
