import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";

import NoticeBlue from "../../../../Assets/noticeBlue.png";

function NoticeBoard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notice, setNotice] = useState([]);
    const [reqData, setReqData] = useState({
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
            iDisplayLength: 99999,
            iDisplayStart: 0,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
        },
    });
    const getDeduction = async () => {
        try {
            const result = await postRequest("PayrollNoticeBoard/List", reqData);
            let data = result?.data?.data
            if (typeof data === "string") data = JSON.parse(data);
            let obj = {};
            for (let i = 0; i < data.length; i++) {
                if (obj[data[i].LastUpdatedOn]) obj[data[i].LastUpdatedOn].push(data[i]);
                else obj[data[i].LastUpdatedOn] = [data[i]];
            }
            setNotice(obj);
        } catch (error) {
            dispatch(
                showToast({
                    text: "some error occured in fetching data",
                    severity: "error",
                })
            );
        } finally {
        }
    };
    useEffect(() => {
        getDeduction();
    }, []);

    return (
        <div>
            <div
                style={{
                    width: "calc(100% - 24px)",
                    borderRadius: "6px",
                    overflow: "auto",
                    height: "70%",
                    margin: "12px",
                    paddingLeft: 20,
                    paddingRight: 20,

                }}
            >
                {Object.keys(notice).map((date, index1) => (
                    <div key={index1}>
                        <div style={{ color: "#555555", fontSize: 13, marginBottom: 10 }}>{new Date(date).toLocaleDateString()}</div>
                        {notice[date].map((val, index) => (
                            <div key={index} style={{ backgroundColor: index % 2 == 0 ? "#FFF2F0" : "#E7F3F5", borderRadius: 4, padding: 20, display: "flex", flexDirection: "row", marginBottom: 20, overflow: "hidden" }}>
                                <div style={{ width: "10%", height: 75 }}>
                                    <img src={NoticeBlue} style={{ width: 120, height: 120, marginLeft: -20, marginBottom: 20, marginTop: -20 }} alt="img" />
                                </div><div style={{ marginLeft: 20, width: "20%" }}>
                                    <div style={{ color: "#555555", fontSize: 11 }}>{new Date(val.LastUpdatedOn).toLocaleDateString()}</div>
                                    <div style={{ color: "#555555", fontSize: 18 }}>{val.Title}</div>
                                </div>
                                <div style={{ color: "#555555", fontSize: 9, marginTop: 20, width: "50%", marginLeft: 20 }}>{val.Description}</div>
                                <div style={{ color: "#555555", fontSize: 9, marginTop: 20, width: "20%" }}>
                                    <div onClick={() => { window.open(val.RedirectUrl) }} style={{ backgroundColor: index % 2 == 0 ? "#FB7158" : "#6AB1BF", borderRadius: 10, padding: 10, color: "white", fontSize: 14, display: "flex", alignItems: "center", justifyContent: "center", width: "80%" }}>
                                        {val.ButtonText}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}



            </div>
        </div>
    );
}

export default NoticeBoard;
