import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCompanyFoodComp } from "./FormComp/CreateCompanyFoodComp";
import { confirmAlert } from "react-confirm-alert";
import dateFormat from "dateformat";
import { format } from "date-fns";
import { CreateCompanyHospitalityComp } from "./FormComp/CreateCompanyHospitalityComp";
import { CreateSendCourierComp } from "./FormComp/CreateSendCourierComp";

const CreateSendCourier = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Send Courier`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);

  const [detailsform, setDetailsform] = useState({});

  const data1 = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `CourierServicesRequests/Details?ID=${data1}`
      );
      // console.log("CourierServicesRequestsdetails", result);
      setDetailsform(result.data.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data1) getFormDetails();
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "CourierServicesRequests/Create",
        values
      );
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("CourierServicesRequests/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const initialvalue = {
    // employeeID: detailsform?._CourierServiceRequests?.employeeId
    //   ? detailsform?._CourierServiceRequests?.employeeId
    //   : "",
    date: detailsform?._CourierServiceRequests?.date
      ? new Date(detailsform?._CourierServiceRequests?.date)
      : "",
    destination: detailsform?._CourierServiceRequests?.destination
      ? detailsform?._CourierServiceRequests?.destination
      : "",
    title: detailsform?._CourierServiceRequests?.title
      ? detailsform?._CourierServiceRequests?.title
      : "",
    description: detailsform?._CourierServiceRequests?.description
      ? detailsform?._CourierServiceRequests?.description
      : "",
    AddressLine2: detailsform?._CourierServiceRequests?.addressLine2
      ? detailsform?._CourierServiceRequests?.addressLine2
      : "",
    City: detailsform?._CourierServiceRequests?.city
      ? detailsform?._CourierServiceRequests?.city
      : "",
    State: detailsform?._CourierServiceRequests?.state
      ? detailsform?._CourierServiceRequests?.state
      : "",
    Country: detailsform?._CourierServiceRequests?.country
      ? detailsform?._CourierServiceRequests?.country
      : "",
    ZipCode: detailsform?._CourierServiceRequests?.zipCode
      ? detailsform?._CourierServiceRequests?.zipCode
      : "",
  };

  // console.log("initialvalue", initialvalue);
  const validationSchema = Yup.object({
    description: Yup.string()
      // .matches(/^\s*/, "Additional Requests should not start with spaces")
      .max(250, "Purpose must be less than 250 characters")
      .required("Please enter the Additional Info. "),
    title: Yup.string()
      .matches(/^\S.*$/, "Courier Details should not start with spaces")
      .max(250, "Courier Details must be less than 250 characters")
      .required("Please enter the Courier Details "),
    destination: Yup.string()
      // .matches(/^\S.*$/, "Destination should not start with spaces")
      .max(250, "Destination must be less than 250 characters")
      .required("Please enter the Destination "),
    AddressLine2: Yup.string()
      .max(250, "Address must be less than 250 characters")
      .required("Please enter the Address "),
    City: Yup.string()
      .max(250, "City must be less than 250 characters")
      .required("Please enter the City "),
    State: Yup.string()
      .max(250, "State must be less than 250 characters")
      .required("Please enter the State "),
    Country: Yup.string()
      .max(250, "Country must be less than 250 characters")
      .required("Please enter the Country "),
    ZipCode: Yup.string()
      .max(250, "ZipCode must be less than 250 characters")
      .required("Please enter the ZipCode "),
    date: Yup.date().required("Please select the Date and Time "),
  });
  // console.log("formVlaues", formValues);
  const onSubmit = (values) => {
    // console.log("values ohf  hospitakalao", values);
    const edit = {
      ...values,
      id: detailsform?._CourierServiceRequests?.id,
      date: format(values?.date, "yyyy-MM-dd'T'HH:mm:ss"),
    };
    // console.log("initialvalue", initialvalue);

    const create = {
      ...values,
      date: format(values?.date, "yyyy-MM-dd'T'HH:mm:ss"),
      employeeID: detailsform?._CourierServiceRequests?.employeeId,
    };
    data1 ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={
        data1 ? "Edit Send Courier Request" : "Create Send Courier Request"
      }
      ButtonLabel={
        data1 ? "Edit Send Courier Request" : "Create Send Courier Request"
      }
      onSubmit={onSubmit}
      chooseFormValues={chooseFormValues}
      styles={{ maxWidth: "80%", width: "80%" }}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateSendCourierComp
          data={detailsform}
          edit={data1}
          formValues={formValues}
        />
      }
    />
  );
};

export { CreateSendCourier };
