/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import editButton from "../../../../Assets/editButton.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { useSelector } from "react-redux";
import { selfRatingObtain } from "../../../../Utilities";
import { Form, Formik } from "formik";
import { formatNumber } from "../../../../Utilities/formatNumber";
const DetailsAppraisalSelfReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const isEdit = location?.state?.type === "Update Review";
  const isEditID = location?.state?.ID;
  const isEditObj = { ...location?.state };
  console.log("object isEditObj", isEditObj);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState();
  const [details, setDetails] = useState(false);

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSAppraisalReview/AppraisalRatingSlab?ID=${selectedId}&EmployeeId=${LoggedInUser?.id}`
      );
      const resultDetails = await getRequest(
        `PMSAppraisalReview/FinalReviewEmployee?ID=${selectedId}&EmployeeId=${LoggedInUser?.id}`
      );
      setData(result?.data?.data);
      setDetails(resultDetails?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol -  Self Review Performance Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (details && data) {
      setInitialValues({
        total: "",
        appraisalCriteriaClassification: getGoalslist(),
      });
    }
  }, [details, data]);
  const getGoalslist = () => {
    let returnArray = [];
    data
      ? data?.appraisalCriteriaClassification?.map((item, index) => {
          returnArray.push({
            criteria: item?.criteria,
            companyWeightage: item?.weightage,
            systemInputs: 0,
            selfReview: "",
            selfComment: "",
          });
          return null;
        })
      : returnArray.push({
          criteria: "",
          companyWeightage: "",
          systemInputs: "",
          selfReview: "",
          selfComment: "",
        });
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    total: "",
    appraisalCriteriaClassification: getGoalslist(),
  });
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  console.log("object initialValues", initialValues);
  console.log("object formValues", formValues);

  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={() => {}}
            >
              {(formik) => {
                chooseFormValues && chooseFormValues(formik.values);
                return (
                  <Form style={{ height: "100%" }}>
                    <div className="selfParent">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                          alignItems: "center",
                          padding: "4px 0px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton onClick={() => navigate(-1)}>
                            <svg
                              width="20"
                              height="8"
                              viewBox="0 0 20 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                                fill="#1296B0"
                              />
                            </svg>
                          </IconButton>
                          <h4> Details Review</h4>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            className="btnSelf"
                            onClick={togglePopup}
                            type="button"
                          >
                            Appraisal Criteria{" "}
                          </button>
                          {isEdit && (
                            <div style={{ height: "31px" }}>
                              <img
                                onClick={() =>
                                  navigate(
                                    `/pms/appraisalreview/myappraisalreview/create/${isEditID}`,
                                    { state: { ...isEditObj } }
                                  )
                                }
                                src={editButton}
                                style={{ height: "100%", cursor: "pointer" }}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "12px",
                        }}
                      >
                        <div
                          className="ratingSelf yz"
                          style={{ display: "block" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div style={{ marignTop: "12px" }}>
                              Self Rating :
                            </div>
                            <div>
                              {" "}
                              <StarRatings
                                rating={selfRatingObtain(
                                  Number(
                                    details?.pmsReviews
                                      ?.appraisalReviewFeedbackNews[0]
                                      ?.employeeLNDReview ?? 0
                                  ) +
                                    Number(
                                      details?.pmsReviews
                                        ?.appraisalReviewFeedbackNews[0]
                                        ?.employeePerformanceReview ?? 0
                                    ) +
                                    Number(
                                      details?.pmsReviews
                                        ?.appraisalReviewFeedbackNews[0]
                                        ?.employeeRewordsReview ?? 0
                                    ) +
                                    Number(
                                      details?.pmsReviews
                                        ?.appraisalReviewFeedbackNews[0]
                                        ?.employeeTaskReview ?? 0
                                    ),
                                  data
                                )}
                                starRatedColor="#5BC4BF"
                                starSpacing="2px"
                                starEmptyColor="#FFFFFF"
                                numberOfStars={5}
                                starHoverColor="var(--primary)"
                                starDimension="28px"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div>Self Achievement % :</div>
                            <div>
                              <p
                                style={{
                                  color: "#5BC4BF",
                                  fontSize: "30px",
                                  fontWeight: "500",
                                  marginTop: "5px",
                                }}
                              >
                                {Number(
                                  details?.pmsReviews
                                    ?.appraisalReviewFeedbackNews[0]
                                    ?.employeeLNDReview ?? 0
                                ) +
                                  Number(
                                    details?.pmsReviews
                                      ?.appraisalReviewFeedbackNews[0]
                                      ?.employeePerformanceReview ?? 0
                                  ) +
                                  Number(
                                    details?.pmsReviews
                                      ?.appraisalReviewFeedbackNews[0]
                                      ?.employeeRewordsReview ?? 0
                                  ) +
                                  Number(
                                    details?.pmsReviews
                                      ?.appraisalReviewFeedbackNews[0]
                                      ?.employeeTaskReview ?? 0
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {data?.appraisalCriteriaClassification?.length > 0 ? (
                        <div style={{ width: "100%", overflow: "auto" }}>
                          {/* <div className="borderDot"></div> */}
                          <div
                            style={{
                              marginTop: "12px",
                              background: "#d4f2f1",
                              borderRadius: "6px",
                              display: "flex",
                            }}
                          >
                            <div className="centertdtwo">
                              <div>Self</div>
                              <div>Review</div>
                            </div>
                            <div
                              style={{
                                width: "calc(100% - 120px)",
                                padding: "1px 10px",
                              }}
                            >
                              <table
                                className="appriaisalReviewTable"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr
                                    style={{
                                      borderBottom: "1px #5BC4BF solid",
                                    }}
                                  >
                                    <td style={{ width: "140px" }}>Criteria</td>
                                    <td
                                      style={{
                                        width: "119px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Company <br />
                                      Weightage
                                    </td>
                                    <td
                                      style={{
                                        width: "119px",
                                        textAlign: "center",
                                      }}
                                    >
                                      System <br />
                                      Inputs
                                    </td>
                                    <td
                                      style={{
                                        width: "119px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Self <br />
                                      Review
                                    </td>
                                    <td
                                      style={{
                                        width: "308px",
                                        // paddingLeft: "23px",
                                      }}
                                    >
                                      Self Comment
                                    </td>
                                    <td></td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.appraisalCriteriaClassification?.map(
                                    (item, index) => (
                                      <tr>
                                        <td style={{ padding: "12px 0px" }}>
                                          {item?.criteria}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {" "}
                                          {formatNumber(item?.weightage)}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {item?.criteria === "Performance"
                                            ? formatNumber(
                                                data?.systemInput?.Performance
                                              )
                                            : item?.criteria === "Tasks"
                                            ? formatNumber(
                                                data?.systemInput?.Tasks
                                              )
                                            : item?.criteria === "Rewards"
                                            ? formatNumber(
                                                data?.systemInput?.Rewards
                                              )
                                            : item?.criteria === "L&D"
                                            ? formatNumber(
                                                data?.systemInput?.[`L&D`]
                                              )
                                            : 0}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {item?.criteria === "Performance"
                                            ? details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeePerformanceReview
                                            : item?.criteria === "Tasks"
                                            ? details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeTaskReview
                                            : item?.criteria === "Rewards"
                                            ? details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeRewordsReview
                                            : item?.criteria === "L&D"
                                            ? details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeLNDReview
                                            : ""}
                                        </td>
                                        <td
                                          style={{
                                            padding: "0px 0px 0px 12px",
                                          }}
                                        >
                                          {item?.criteria === "Performance"
                                            ? details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeePerformanceComment
                                            : item?.criteria === "Tasks"
                                            ? details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeTaskComment
                                            : item?.criteria === "Rewards"
                                            ? details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeRewordsComment
                                            : item?.criteria === "L&D"
                                            ? details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeLNDComment
                                            : ""}
                                        </td>
                                        <td></td>
                                      </tr>
                                    )
                                  )}
                                  <tr
                                    style={{
                                      borderTop: "1px solid rgb(91, 196, 191)",
                                    }}
                                  >
                                    <td
                                      style={{
                                        color: "#5BC4BF",
                                        padding: "17px 0px",
                                      }}
                                    >
                                      Total
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        color: "#5BC4BF",
                                      }}
                                    >
                                      {data?.appraisalCriteriaClassification?.reduce(
                                        (accumulator, currentValue) => {
                                          return (
                                            accumulator +
                                            Number(currentValue?.weightage)
                                          );
                                        },
                                        0
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        color: "#5BC4BF",
                                      }}
                                    >
                                      {formatNumber(
                                        data?.systemInput?.Performance +
                                          data?.systemInput?.Tasks +
                                          data?.systemInput?.Rewards +
                                          data?.systemInput?.[`L&D`]
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        color: "#5BC4BF",
                                      }}
                                    >
                                      {Number(
                                        details?.pmsReviews
                                          ?.appraisalReviewFeedbackNews[0]
                                          ?.employeeLNDReview ?? 0
                                      ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeePerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeTaskReview ?? 0
                                        )}
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Rating Slab"}
          content={
            <>
              <InstructionSelf data={data} />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};
export { DetailsAppraisalSelfReview };
