import React from "react";
import { useEffect } from "react";
const DetailsLD = ({ detailTitle, Details, ...props }) => {
  useEffect(() => {
    document.title = `PeopleSol - Learning Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="detailDataLD" {...props}>
      <label>{detailTitle ?? ""}</label>
      <label>:</label>
      <label className="detailsValue">{Details ?? ""}</label>
    </div>
  );
};
export { DetailsLD };
