import React, { useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";
import { FieldArray } from "formik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { InputCustom } from "../../../../Components/FormComponent/InputCustom";

export const HotelSelfComp = ({
  formValues,
  hotelCategoryOptions,
  isBoth,
  editTrue,
  setInitialValues,
  setBudgetArr,
  budgetArr,
}) => {
  // const isBoth = true;
  // const isBoth = false;

  const travellingOptions = [
    { label: "One Way Trip", value: "One Way Trip" },
    { label: "Multilocation Trip", value: "Multilocation Trip" },
  ];
  const updateArrayAtIndex = (index, value) => {
    setBudgetArr((prevArr) => {
      const newArr = [...prevArr];
      if (index >= newArr.length) {
        newArr.length = index + 1;
      }
      newArr[index] = value;
      return newArr;
    });
  };
  const getBudget = async (catId) => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/getBudgetByCategory?mode=Hotel&categoryId=${catId}`
      );
      // setBudget(result?.data?.budget);

      if (formValues?.TravellingType === "One Way Trip")
        setInitialValues((prev) => {
          const arr = { ...formValues };
          arr.HotelCategory = formValues?.HotelCategory;
          arr.Budget = result?.data?.budget;
          return arr;
        });
    } catch (err) {}
  };
  useEffect(() => {
    if (formValues?.HotelCategory && formValues?.TravellingType) {
      getBudget(formValues?.HotelCategory);
    }
  }, [formValues?.HotelCategory, formValues?.TravellingType]);
  console.log("object budgetArr", budgetArr);
  const dispatch = useDispatch();
  const handleModeChange = async (ind, val) => {
    let result = await getBudgetMulti(val);
    updateArrayAtIndex(ind, result);
  };
  const getBudgetMulti = async (catId) => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/getBudgetByCategory?mode=Hotel&categoryId=${catId}`
      );
      return result?.data?.budget;
    } catch (err) {}
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     setInitialValues((prev) => {
  //       const arr = { ...formValues };
  //       arr.Budget = budgetArr?.reduce(
  //         (accumulator, currentValue) => accumulator + currentValue,
  //         0
  //       );
  //       return arr;
  //     });
  //   }, 3000);
  // }, [budgetArr]);
  useEffect(() => {
    document.title = `PeopleSol - Self Hotel`;

    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div
        style={
          formValues?.TravellingType && !isBoth
            ? { borderBottom: "1px solid #E6E6E6", padding: "10px 0px" }
            : {}
        }
        className="formcustom threeColumn"
      >
        {!isBoth && (
          <div>
            <LabelCustom labelName={"Travel Type"} mandatory={true} />
            <SelectForm name="TravellingType" options={travellingOptions} />
          </div>
        )}
        {!isBoth &&
          formValues?.TravellingType &&
          formValues?.TravellingType === "One Way Trip" && (
            <div>
              <LabelCustom labelName={"Hotel Category"} mandatory={true} />
              <SelectForm
                disabled={formValues?.TravellingType ? false : true}
                name="HotelCategory"
                options={hotelCategoryOptions}
              />
            </div>
          )}
        {/* {formValues?.TravellingType &&
          formValues?.TravellingType !== "One Way Trip" && (
            // !isBoth &&
            <div>
              <LabelCustom labelName={"Budget (INR)"} mandatory={true} />
              <InputCustomFormik
                type="number"
                name="Budget"
                disabled
                placeholder={"Please enter budget"}
              />
            </div>
          )} */}
      </div>
      {formValues?.TravellingType && (
        <div className="formcustom threeColumn">
          {formValues?.TravellingType !== "Multilocation Trip" && (
            <>
              {isBoth &&
                formValues?.TravellingType &&
                formValues?.TravellingType === "One Way Trip" && (
                  <div>
                    <LabelCustom
                      labelName={"Hotel Category"}
                      mandatory={true}
                    />
                    <SelectForm
                      name="HotelCategory"
                      options={hotelCategoryOptions}
                    />
                  </div>
                )}
              <div>
                <LabelCustom
                  labelName={"Check-In Date and Time"}
                  mandatory={true}
                />
                <DatePickerFormiks
                  name={"CheckInTime"}
                  minDate={new Date()}
                  showTimeSelect
                  maxDate={formValues && formValues?.CheckOutTime}
                />
              </div>
              <div>
                <LabelCustom
                  labelName={"Check-Out Date and Time"}
                  mandatory={true}
                />
                <DatePickerFormiks
                  name={"CheckOutTime"}
                  minDate={formValues ? formValues?.CheckInTime : new Date()}
                  showTimeSelect
                />
              </div>

              <div>
                <LabelCustom labelName={"Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="Location"
                  placeholder={"Please Enter Location"}
                />
              </div>

              <div>
                <LabelCustom labelName={"Preferred Hotel"} mandatory={true} />
                <InputCustomFormik
                  maxLength={150}
                  name="PreferHotel"
                  placeholder={"Please Enter Preferred Hotel"}
                />
              </div>

              <div>
                <LabelCustom labelName={"Adults"} />
                <InputCustomFormik
                  name="Adults"
                  type="number"
                  placeholder={" Enter "}
                />
              </div>
              <div>
                <LabelCustom labelName={"Children"} />
                <InputCustomFormik
                  name="Children"
                  type="number"
                  placeholder={" Enter "}
                />
              </div>
              <div>
                <LabelCustom labelName={"Budget (INR)"} mandatory={true} />
                <InputCustomFormik
                  disabled
                  type="number"
                  name="Budget"
                  placeholder={"Please enter budget"}
                />
              </div>
              <div>
                <LabelCustom labelName={"Booking Note"} />
                <TextAreaFormik
                  name="BookingNote"
                  placeholder={"Please Enter Booking Note"}
                  maxLength={500}
                />
              </div>
            </>
          )}
        </div>
      )}
      {formValues?.TravellingType === "Multilocation Trip" && (
        <div
          style={
            {
              // marginTop: "20px",
              // borderTop: "1px solid #E6E6E6",
              // padding: "20px 0px",
            }
          }
          className="repeatedSectionContainer"
        >
          {/* <label className="repeatedHeading">
            Please specify your journey details
          </label> */}
          <FieldArray name="hotelbookingdetails">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values, errors } = form;
              const { hotelbookingdetails } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Hotel Booking Details"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={" Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {hotelbookingdetails?.map((Requestlist, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ maxWidth: "920px", overflowX: "auto" }}
                        >
                          <div style={{ overflowX: "auto", display: "flex" }}>
                            <div style={{ display: "flex", flexShrink: "1" }}>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Hotel Category"}
                                  mandatory={true}
                                />
                                {/* <SelectForm
                                  name={`hotelbookingdetails[${index}].HotelCategory`}
                                  options={hotelCategoryOptions}
                                /> */}
                                <SelectForm
                                  name={`hotelbookingdetails[${index}].HotelCategory`}
                                  options={hotelCategoryOptions}
                                  onChangeProp={(e) => {
                                    form.setFieldValue(
                                      `hotelbookingdetails[${index}].HotelCategory`,
                                      e.value
                                    );
                                    handleModeChange(index, e.value);
                                  }}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Check-In Date and Time"}
                                  mandatory={true}
                                />
                                <DatePickerFormiks
                                  name={`hotelbookingdetails[${index}].CheckInTime`}
                                  minDate={new Date()}
                                  showTimeSelect
                                  maxDate={
                                    formValues?.hotelbookingdetails &&
                                    formValues?.hotelbookingdetails[index]
                                      ?.CheckOutTime
                                  }
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Check-Out Date and Time"}
                                  mandatory={true}
                                />
                                <DatePickerFormiks
                                  name={`hotelbookingdetails[${index}].CheckOutTime`}
                                  minDate={
                                    formValues?.hotelbookingdetails
                                      ? formValues?.hotelbookingdetails[index]
                                          ?.CheckInTime
                                      : new Date()
                                  }
                                  showTimeSelect
                                />
                              </div>

                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Location"}
                                  mandatory={true}
                                />
                                <InputCustomFormik
                                  maxLength={50}
                                  name={`hotelbookingdetails[${index}].Location`}
                                  type={"text"}
                                  placeholder={"Please Enter Location"}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Preferred Hotel"}
                                  mandatory={true}
                                />
                                <InputCustomFormik
                                  maxLength={150}
                                  name={`hotelbookingdetails[${index}].PreferHotel`}
                                  type={"text"}
                                  placeholder={"Please Enter Preferred Hotel"}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom labelName={"Adults"} />
                                <InputCustomFormik
                                  name={`hotelbookingdetails[${index}].Adults`}
                                  type={"number"}
                                  placeholder={" Enter "}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom labelName={"Children"} />
                                <InputCustomFormik
                                  name={`hotelbookingdetails[${index}].Children`}
                                  type={"number"}
                                  placeholder={" Enter "}
                                />
                              </div>
                              {/* {isBoth && ( */}
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Budget (INR)"}
                                  mandatory={true}
                                />
                                <InputCustom
                                  // name={`hotelbookingdetails[${index}].Budget`}
                                  disabled
                                  value={budgetArr[index]}
                                  type="number"
                                  placeholder={"Please enter budget"}
                                />
                              </div>
                              {/* )} */}
                              <div style={{ width: "245px" }}>
                                <LabelCustom labelName={"Booking Note"} />
                                <TextAreaFormik
                                  name={`hotelbookingdetails[${index}].Bookingnote`}
                                  placeholder={" Enter Note"}
                                  maxLength={500}
                                />
                              </div>
                            </div>
                          </div>
                        </StyledTableCell>
                        {/* 2nd column */}

                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() => {
                              if (hotelbookingdetails.length > 1) {
                                updateArrayAtIndex(index, 0);
                                remove(index);
                              } else {
                                dispatch(
                                  showToast({
                                    text: "At least one item is required",
                                    severity: "error",
                                  })
                                );
                              }
                            }}
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push(
                                isBoth
                                  ? {
                                      HotelCategory: null,
                                      CheckInTime: null,
                                      CheckOutTime: null,
                                      Location: "",
                                      PreferHotel: "",
                                      Adults: "",
                                      Children: "",
                                      Budget: "",
                                      Bookingnote: "",
                                    }
                                  : {
                                      HotelCategory: null,
                                      CheckInTime: null,
                                      CheckOutTime: null,
                                      Location: "",
                                      PreferHotel: "",
                                      Adults: "",
                                      Children: "",
                                      Bookingnote: "",
                                    }
                              )
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      )}
    </>
  );
};
