/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { assetSchema } from "./Schema";
import { AssetCategoryForm } from "./Form/AssetCategoryForm";
import { useEffect } from "react";
import { useState } from "react";

const initialvalue = {
  name: "",
  code: "",
  assetTypeSpecificationIds: [],
  assetTypeIds: [],
};

const CreateAssetCategory = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();

  const [detailsform, setDetailsform] = useState({});
  const getFormDetails = async () => {
    try {
      const result = await getRequest(`AssetCategories/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
    } finally {
    }
  };
  const postFormData = async (values) => {
    try {
      const result = await postRequest("AssetCategories/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("AssetCategories/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Asset Category`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: detailsform?.id,
    };

    const create = {
      ...values,
    };

    data ? Edit(edit) : postFormData(create);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      ButtonLabel={data ? "Update Asset Category" : "Create Asset Category"}
      FormHeading={data ? "Edit Asset Category" : "Create Asset Category"}
      validationSchema={assetSchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.name,
              code: detailsform?.code,
              assetTypeSpecificationIds: detailsform?.item?.map((item) => {
                return item.id;
              }),
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<AssetCategoryForm data={detailsform} />}
    />
  );
};

export { CreateAssetCategory };
