/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { Form, Formik } from "formik";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { selfRatingObtain } from "../../../../Utilities";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../../Utilities/formatNumber";
const SubmitAppraiserAppraiserReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams();
  let xy = Object.keys(id);
  const { LoggedInUser } = useSelector((state) => state.auth);

  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const selectedIds = location?.state?.item;

  const isEdit = location?.state?.type === "Update Review";
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [details, setDetails] = useState(false);
  const [formValues, setFormValues] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  console.log("object location?.state?", location?.state);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSAppraisalReview/AppraisalRatingSlab?ID=${selectedId}&EmployeeId=${selectedIds?.item?.employeeId}`
      );
      const resultDetails = await getRequest(
        `PMSAppraisalReview/FinalReviewEmployee?ID=${selectedId}&EmployeeId=${selectedIds?.item?.employeeId}`
      );
      setData(result?.data?.data);
      setDetails(resultDetails?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Submit Self Review `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    if (details && data) {
      setInitialValues({
        total: "",
        appraisalCriteriaClassification: getGoalslist(),
        appraiserReview: getappraiserReviewlist(),
      });
    }
  }, [details, data]);
  const getappraiserReviewlist = () => {
    let returnArray = [];
    data?.appraisalCriteriaClassification?.map((item, index) => {
      returnArray.push({
        criteria: item?.criteria,
        companyWeightage: item?.weightage,
        systemInputs:
          item?.criteria === "Performance"
            ? formatNumber(data?.systemInput?.Performance)
            : item?.criteria === "Tasks"
            ? formatNumber(data?.systemInput?.Tasks)
            : item?.criteria === "Rewards"
            ? formatNumber(data?.systemInput?.Rewards)
            : item?.criteria === "L&D"
            ? formatNumber(data?.systemInput?.[`L&D`])
            : 0,
        selfReview:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeePerformanceReview
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeTaskReview
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeRewordsReview
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeLNDReview
            : "",
        appraiserReview:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeePerformanceReview
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeTaskReview
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeRewordsReview
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeLNDReview
            : "",
        appraiserReviewComment: "",
      });
      return null;
    });
    return returnArray;
  };
  const getGoalslist = () => {
    let returnArray = [];
    data?.appraisalCriteriaClassification?.map((item, index) => {
      returnArray.push({
        criteria: item?.criteria,
        companyWeightage: item?.weightage,
        systemInputs: 0,
        selfReview:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeePerformanceReview
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeTaskReview
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeRewordsReview
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeLNDReview
            : "",
        selfComment:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeePerformanceComment
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeTaskComment
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeRewordsComment
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeLNDComment
            : "",
      });
      return null;
    });
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    total: "",
    appraisalCriteriaClassification: getGoalslist(),
    appraiserReview: getappraiserReviewlist(),
  });

  console.log("object initialValues", initialValues);
  console.log("object formValues", formValues);
  useEffect(() => {
    getDetails();
  }, []);
  const createFunction = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("PMSAppraisalReview/RoReviews", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      navigate(-1);
      getDetails();
    }
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const onSubmit = async (values) => {
    if (
      values?.appraiserReview?.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue?.appraiserReview);
      }, 0) > 100
    ) {
      dispatch(
        showToast({
          text: "Review cannot be more than 100",
          severity: "error",
        })
      );
    } else {
      const create = {};
      create["Id"] = Number(
        details?.pmsReviews?.appraisalReviewFeedbackNews[0]?.id
      );
      create["ReviewId"] = Number(details?.pmsReviews?.id);
      formValues?.appraiserReview?.forEach((item) => {
        if (item?.criteria === "Performance") {
          create["AppraiserPerformanceReview"] = item?.appraiserReview;
          create["AppraiserPerformanceComment"] = item?.appraiserComment;
        }
        if (item?.criteria === "Tasks") {
          create["AppraiserTaskReview"] = item?.appraiserReview;
          create["AppraiserTaskComment"] = item?.appraiserComment;
        }
        if (item?.criteria === "Rewards") {
          create["AppraiserRewordsReview"] = item?.appraiserReview;
          create["AppraiserRewordsComment"] = item?.appraiserComment;
        }
        if (item?.criteria === "L&D") {
          create["AppraiserLNDReview"] = item?.appraiserReview;
          create["AppraiserLNDComment"] = item?.appraiserComment;
        }
      });
      createFunction(create);
      // } else {
      //   dispatch(showToast({ text: "total should be 100", severity: "error" }));
      // }
    }
  };
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={onSubmit}
              validationSchema={Yup.object({
                appraiserReview: Yup.array().of(
                  Yup.object().shape({
                    appraiserReview: Yup.string().required("Enter"),
                  })
                ),
              })}
            >
              {(formik) => {
                chooseFormValues && chooseFormValues(formik.values);
                return (
                  <Form style={{ height: "100%" }}>
                    <div className="selfParent" style={{ gap: "0px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={() => navigate(-1)}
                          style={{ display: "flex" }}
                          className="returnToDashboard cursor"
                        >
                          <div>
                            <svg
                              width="20"
                              height="8"
                              viewBox="0 0 20 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                                fill="#1296B0"
                              />
                            </svg>
                          </div>
                          <label className="returnLabelHolder">
                            {details?._pmsAppraisalCycles?.name}
                          </label>
                        </div>

                        <div>
                          <button
                            className="btnSelf"
                            onClick={togglePopup}
                            type="button"
                          >
                            Appraisal Criteria{" "}
                          </button>
                        </div>
                      </div>
                      <div style={{ overflowY: "auto" }}>
                        <div style={{ display: "flex", gap: "20px" }}>
                          <div
                            className="ratingSelf"
                            style={{
                              display: "block",
                              padding: "16px",
                              width: "278px",
                              height: "149px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div style={{ marignTop: "12px" }}>
                                Self Rating :
                              </div>
                              <div>
                                {" "}
                                <StarRatings
                                  rating={selfRatingObtain(
                                    formValues?.appraisalCriteriaClassification?.reduce(
                                      (accumulator, currentValue) => {
                                        return (
                                          accumulator +
                                          Number(currentValue?.selfReview)
                                        );
                                      },
                                      0
                                    ),
                                    data
                                  )}
                                  starRatedColor="#5BC4BF"
                                  starSpacing="2px"
                                  starEmptyColor="#FFFFFF"
                                  numberOfStars={5}
                                  starHoverColor="var(--primary)"
                                  starDimension="28px"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>Self Achievement % :</div>
                              <div>
                                <p
                                  style={{
                                    color: "#5BC4BF",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                  }}
                                >
                                  {formValues?.appraisalCriteriaClassification?.reduce(
                                    (accumulator, currentValue) => {
                                      return (
                                        accumulator +
                                        Number(currentValue?.selfReview)
                                      );
                                    },
                                    0
                                  )}
                                  %
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="ratingSelf"
                            style={{
                              display: "block",
                              padding: "16px",
                              width: "278px",
                              height: "149px",
                              backgroundColor: "#FFEEF2",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div style={{ marignTop: "12px" }}>
                                Appraiser Review Rating :
                              </div>
                              <div>
                                {" "}
                                <StarRatings
                                  rating={selfRatingObtain(
                                    formValues?.appraiserReview?.reduce(
                                      (accumulator, currentValue) => {
                                        return (
                                          accumulator +
                                          Number(currentValue?.appraiserReview)
                                        );
                                      },
                                      0
                                    ),
                                    data
                                  )}
                                  starRatedColor="#DE64A1"
                                  starSpacing="2px"
                                  starEmptyColor="#FFFFFF"
                                  numberOfStars={5}
                                  starHoverColor="var(--primary)"
                                  starDimension="28px"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>Appraiser Review Achievement % :</div>
                              <div>
                                <p
                                  style={{
                                    color: "#DE64A1",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                  }}
                                >
                                  {formValues?.appraiserReview?.reduce(
                                    (accumulator, currentValue) => {
                                      return (
                                        accumulator +
                                        Number(currentValue?.appraiserReview)
                                      );
                                    },
                                    0
                                  )}
                                  %
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {data?.appraisalCriteriaClassification?.length > 0 ? (
                          <div
                            style={{
                              width: "100%",
                              overflow: "auto",
                              marginBottom: "20px",
                              marginTop: "10px",
                            }}
                          >
                            {/* <div className="borderDot"></div> */}
                            <div
                              style={{
                                marginTop: "12px",
                                background: "#d4f2f1",
                                borderRadius: "6px",
                                display: "flex",
                              }}
                            >
                              <div className="centertdtwo">
                                <div>Self</div>
                                <div>Review</div>
                              </div>
                              <div
                                style={{
                                  width: "calc(100% - 120px)",
                                  padding: "1px 10px",
                                }}
                              >
                                <table
                                  className="appriaisalReviewTableDetail"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        borderBottom: "1px #5BC4BF solid",
                                      }}
                                    >
                                      <td style={{ width: "140px" }}>
                                        Criteria
                                      </td>
                                      <td
                                        style={{
                                          width: "119px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Company <br />
                                        Weightage
                                      </td>
                                      <td
                                        style={{
                                          width: "119px",
                                          textAlign: "center",
                                        }}
                                      >
                                        System <br />
                                        Inputs
                                      </td>
                                      <td
                                        style={{
                                          width: "119px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Self <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "308px",
                                          // paddingLeft: "23px",
                                        }}
                                      >
                                        Self Comment
                                      </td>
                                      <td></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.appraisalCriteriaClassification?.map(
                                      (item, index) => (
                                        <tr>
                                          <td>{item?.criteria}</td>
                                          <td style={{ textAlign: "center" }}>
                                            {" "}
                                            {formatNumber(item?.weightage)}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {item?.criteria === "Performance"
                                              ? formatNumber(
                                                  data?.systemInput?.Performance
                                                )
                                              : item?.criteria === "Tasks"
                                              ? formatNumber(
                                                  data?.systemInput?.Tasks
                                                )
                                              : item?.criteria === "Rewards"
                                              ? formatNumber(
                                                  data?.systemInput?.Rewards
                                                )
                                              : item?.criteria === "L&D"
                                              ? formatNumber(
                                                  data?.systemInput?.[`L&D`]
                                                )
                                              : 0}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {item?.criteria === "Performance"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceReview
                                              : item?.criteria === "Tasks"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeTaskReview
                                              : item?.criteria === "Rewards"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeRewordsReview
                                              : item?.criteria === "L&D"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeLNDReview
                                              : ""}
                                          </td>
                                          <td>
                                            {item?.criteria === "Performance"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceComment
                                              : item?.criteria === "Tasks"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeTaskComment
                                              : item?.criteria === "Rewards"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeRewordsComment
                                              : item?.criteria === "L&D"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeLNDComment
                                              : ""}
                                          </td>
                                          <td></td>
                                        </tr>
                                      )
                                    )}
                                    <tr
                                      style={{
                                        borderTop:
                                          "1px solid rgb(91, 196, 191)",
                                      }}
                                    >
                                      <td style={{ color: "#5BC4BF" }}>
                                        Total
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#5BC4BF",
                                        }}
                                      >
                                        {data?.appraisalCriteriaClassification?.reduce(
                                          (accumulator, currentValue) => {
                                            return (
                                              accumulator +
                                              Number(currentValue?.weightage)
                                            );
                                          },
                                          0
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#5BC4BF",
                                        }}
                                      >
                                        {formatNumber(
                                          data?.systemInput?.Performance +
                                            data?.systemInput?.Tasks +
                                            data?.systemInput?.Rewards +
                                            data?.systemInput?.[`L&D`]
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#5BC4BF",
                                        }}
                                      >
                                        {Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeLNDReview ?? 0
                                        ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeePerformanceReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeRewordsReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeTaskReview ?? 0
                                          )}
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {data?.appraisalCriteriaClassification?.length > 0 ? (
                          <div style={{ width: "100%", overflow: "auto" }}>
                            {/* <div className="borderDot"></div> */}
                            <div
                              style={{
                                marginTop: "12px",
                                background: "#FFEEF2",
                                borderRadius: "6px",
                                display: "flex",
                              }}
                            >
                              <div className="centertdthree">
                                <div>Appraiser</div>
                                <div>Review</div>
                              </div>
                              <div
                                style={{
                                  width: "calc(100% - 120px)",
                                  padding: "1px 10px",
                                }}
                              >
                                <table
                                  className="appriaisalReviewTable"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        borderBottom: "1px #5BC4BF solid",
                                      }}
                                    >
                                      <td style={{ width: "140px" }}>
                                        Criteria
                                      </td>
                                      <td
                                        style={{
                                          width: "40px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Company <br />
                                        Weightage
                                      </td>
                                      <td
                                        style={{
                                          width: "40px",
                                          textAlign: "center",
                                        }}
                                      >
                                        System <br />
                                        Inputs
                                      </td>
                                      <td
                                        style={{
                                          width: "40px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Self <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "40px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Appraiser <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "308px",
                                          paddingLeft: "23px",
                                        }}
                                      >
                                        Appraiser Comment
                                      </td>
                                      <td></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.appraisalCriteriaClassification?.map(
                                      (item, index) => (
                                        <tr>
                                          <td>{item?.criteria}</td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type="number"
                                              disabled={true}
                                              value={item?.weightage}
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`appraiserReview[${index}].companyWeightage`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type="number"
                                              disabled={true}
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`appraiserReview[${index}].systemInputs`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type="number"
                                              disabled={true}
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`appraiserReview[${index}].selfReview`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type="number"
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`appraiserReview[${index}].appraiserReview`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <TextAreaFormik
                                              style={{ height: "80px" }}
                                              name={`appraiserReview[${index}].appraiserComment`}
                                              placeholder="Type Here"
                                              maxLength={500}
                                            />
                                          </td>
                                          <td></td>
                                        </tr>
                                      )
                                    )}
                                    <tr
                                      style={{
                                        borderTop:
                                          "1px solid rgb(91, 196, 191)",
                                      }}
                                    >
                                      <td>Total</td>
                                      <td>
                                        <InputCustomFormik
                                          type="number"
                                          disabled={true}
                                          value={formValues?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(
                                                  currentValue?.companyWeightage
                                                )
                                              );
                                            },
                                            0
                                          )}
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`companyWeightageTotal`}
                                        />
                                      </td>
                                      <td>
                                        <InputCustomFormik
                                          type="number"
                                          disabled={true}
                                          value={formValues?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(
                                                  currentValue?.systemInputs
                                                )
                                              );
                                            },
                                            0
                                          )}
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`systemInputsTotal`}
                                        />
                                      </td>
                                      <td>
                                        <InputCustomFormik
                                          type="number"
                                          disabled={true}
                                          value={formValues?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(currentValue?.selfReview)
                                              );
                                            },
                                            0
                                          )}
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`selfReviewTotal`}
                                        />
                                      </td>
                                      <td>
                                        <InputCustomFormik
                                          type="number"
                                          disabled={true}
                                          value={formValues?.appraiserReview?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(
                                                  currentValue?.appraiserReview
                                                )
                                              );
                                            },
                                            0
                                          )}
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`appraiserReviewTotal`}
                                        />
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div className="createFootCust">
                        <div>
                          <div
                            //   onClick={returnPage}
                            className={`button secondaryButton`}
                            style={{ border: "1px #1190A9 solid" }}
                          >
                            Save as draft
                          </div>
                        </div>
                        <div>
                          <button
                            className="button primaryButton"
                            type="submit"
                          >
                            Save & Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Rating Slab"}
          content={
            <>
              <InstructionSelf data={data} />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export { SubmitAppraiserAppraiserReview };
