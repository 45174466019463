import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { OrgStructure, Team, PersonalDetails, SkillsCompetency } from "./index";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../../Components/FormComponent/Loader";

const MyProfile = ({ loading, isManager, profileList }) => {
  // const [isManager, setIsManager] = useState(true)
  useEffect(() => {
    document.title = `PeopleSol -  My Profile`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    if (newTabIndex === "Position Details") {
      navigate("profile/orgstructure");
    } else if (newTabIndex === "Team") {
      navigate("profile/team");
    } else if (newTabIndex === "Rewards") {
      navigate("profile/rewards");
    } else if (newTabIndex === "Skills & Competency") {
      navigate("profile/skillscompetency");
    } else if (newTabIndex === "Documents") {
      navigate("profile/documents");
    } else if (newTabIndex === "Timeline") {
      navigate("profile/timeline");
    } else if (newTabIndex === "Personal Details") {
      navigate("profile/personaldetails");
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("services/profile")) {
      if (currentpath.includes("orgstructure")) {
        setTabIndex("Position Details");
      } else if (currentpath.includes("team")) {
        setTabIndex("Team");
      } else if (currentpath.includes("rewards")) {
        setTabIndex("Rewards");
      } else if (currentpath.includes("skillscompetency")) {
        setTabIndex("Skills & Competency");
      } else if (currentpath.includes("documents")) {
        setTabIndex("Documents");
      } else if (currentpath.includes("timeline")) {
        setTabIndex("Timeline");
      } else if (currentpath.includes("personaldetails")) {
        setTabIndex("Personal Details");
      } else {
        navigate("profile/orgstructure");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // //console.log('profileList', profileList)

  if (loading) return <Loader />;
  else
    return (
      <>
        <div
          className="moduleMainConatiner"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#1296B0" } }}
          >
            <Tab
              value="Position Details"
              label={
                <span
                  className={`tabHeading ${
                    tabIndex === "Position Details" && "tabActive"
                  }`}
                >
                  Position Details
                </span>
              }
            />
            {isManager && (
              <Tab
                value="Team"
                label={
                  <span
                    className={`tabHeading ${
                      tabIndex === "Team" && "tabActive"
                    }`}
                  >
                    Team
                  </span>
                }
              />
            )}
            <Tab
              value="Personal Details"
              label={
                <span
                  className={`tabHeading ${
                    tabIndex === "Personal Details" && "tabActive"
                  }`}
                >
                  Personal Details
                </span>
              }
            />
            <Tab
              value="Skills & Competency"
              label={
                <span
                  className={`tabHeading ${
                    tabIndex === "Skills & Competency" && "tabActive"
                  }`}
                >
                  Skills & Competency
                </span>
              }
            />
            {/* <Tab value="Skills & Competencies" label={<span className={`tabHeading ${tabIndex === "Skills & Competencies" && "tabActive"}`}>Skills & Competencies</span>} />
              <Tab value="Documents" label={<span className={`tabHeading ${tabIndex === "Documents" && "tabActive"}`}>Documents</span>} />
              <Tab value="Timeline" label={<span className={`tabHeading ${tabIndex === "Timeline" && "tabActive"}`}>Timeline</span>} />
              <Tab value="Rewards" label={<span className={`tabHeading ${tabIndex === "Rewards" && "tabActive"}`}>Rewards</span>} /> */}
          </Tabs>
          <div className="tabContainerLine"></div>
        </div>
        <div className="profileSubSection">
          {tabIndex === "Position Details" && (
            <OrgStructure profileList={profileList} loading={loading} />
          )}
          {tabIndex === "Team" && <Team loading={loading} />}
          {tabIndex === "Personal Details" && (
            <PersonalDetails loading={loading} />
          )}
          {tabIndex === "Skills & Competency" && (
            <SkillsCompetency loading={loading} />
          )}
          {/* {tabIndex === 2 && (
                <Rewards />
              )}
              {tabIndex === 3 && (
                <SkillsCompetency />
              )}
              {tabIndex === 4 && (
                <Documents />
              )}
              {tabIndex === 5 && (
                <Timeline />
              )} */}
        </div>
      </>
    );
};

export { MyProfile };
