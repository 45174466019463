import React, { useState, useEffect } from 'react';

import { BannerTab } from './BannerTab';

const Banner = () => {
    useEffect(() => {
        document.title = `PeopleSol - Notice`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
   
    return (
        <div className="moduleMainContainer">
          
           
            <BannerTab title='Notice'/>
            
            
            
        </div>
    )
}

export { Banner }