import React, { useState, useEffect } from "react";

import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { SelectFormikMulti } from "../../../../../Components/FormComponent/SelectFormikMulti";
import { useLocation } from "react-router-dom";
import DropdownInfiniteScroll from "../../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";

export const CreateInductionComp = ({ data, formValues, editID }) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Induction`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Employees"} mandatory={true} />
        {data ? (
          <DropdownInfiniteScroll
            name="EmployeeIds"
            placeholder={"IT Director"}
            details={data}
            editID={editID}
            detailsForEdit={[data?.summary?.employeeId]}
          />
        ) : (
          <DropdownInfiniteScroll
            name="EmployeeIds"
            multi={true}
            placeholder={"Select..."}
            details={data}
            editID={editID}
            detailsForEdit={[data?.summary?.employeeId]}
          />
        )}
      </div>

      <div>
        <LabelCustom labelName={"Induction Date"} mandatory={true} />
        <DatePickerFormiks name="InductionDate" minDate={new Date()} />
      </div>
      <div>
        <LabelCustom labelName={"Second Induction Date"} mandatory={true} />
        <DatePickerFormiks
          name="SecondInductionDate"
          minDate={
            formValues?.InductionDate
              ? formValues?.InductionDate !== ""
                ? new Date(formValues?.InductionDate)
                : new Date()
              : new Date()
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload Attachment"} />
        <FileUploadFormik
          name="Upload"
          attachTextLabel={data?.attachment?.toString()}
        />
        <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
          Size: 2 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
          Format: PDF, Doc
        </span>
      </div>
    </div>
  );
};
