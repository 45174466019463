import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function ViewReportContainer({ reportData, type }) {
  const navigate = useNavigate();
  return (
    <div className="viewReportBoxContainer">
      <IconButton
        onClick={() => {
          navigate("/home/social/surveydetails/" + reportData?.id);
        }}
      >
        <div className="viewReportBox">View Report</div>
      </IconButton>
    </div>
  );
}

export default ViewReportContainer;
