import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { format } from "date-fns";
import dateFormat from "dateformat";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";

export const AdminAssetCreate = ({ id, data, type, color, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [val, setVal] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  const validationSchema = Yup.object({
    AssetId: Yup.string().required("Select AssetId"),
    CategoryId: Yup.string().required("Select Asset Category"),
    AssetMaterialId: Yup.string().required("Select Asset Type"),
    Description: Yup.string().max(499,"Description must be less than 500 characters")
          .notRequired("Please Enter Description"),
  });
  const dispatchAdminCreate = async (value) => {
    try {
      setLoading(true);
      const result = await postRequest(
        `ITAssets/CreateAssets`,
        value
      );

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };
  const getCategory = async () => {
    try {
      const result = await getRequest(
        `AssetCategories/GetAssetCategories`
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setCategoryList(listData);
    } catch (error) {
    } finally {
    }
  };
  const getAsset = async () => {
    try {
      const result = await getRequest(
        `Materials/GetAssetMaterial?Id=${val.CategoryId}`
      );
      let listData = [];
      result?.data?.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getCategory();
    getAsset();
  }, []);
  const onSubmit = async (values) => {
    dispatchAdminCreate(values);
  };
  useEffect(() => {
    if (val.CategoryId) {
      getAsset();
    }
  }, [val.CategoryId]);
  useEffect(() => {
    document.title = `PeopleSol - Create Asset`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <Formik
      initialValues={{
        AssetId: "",
        CategoryId: "",
        AssetMaterialId: "",
        Description: "",
        Type:""
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        setVal(values);
        return (
          <Form>
            <div>
              <LabelCustom labelName={"Asset Id"} mandatory={true} />
              <InputCustomFormik
                name={"AssetId"}
                placeholder="Enter Asset Id"
              />
            </div>
            <div>
              <LabelCustom labelName={"Asset Category"} mandatory={true} />
              <SelectForm
                name={"CategoryId"}
                placeholder="Select Asset category"
                options={categoryList}
              />
            </div>
            <div>
              <LabelCustom labelName={"Asset Material"} mandatory={true} />
              <SelectForm
               onDropdownOpen={
                val?.CategoryId === ""
                  ? () =>
                      dispatch(
                        showToast({
                          text: "Please select  Asset category",
                          severity: "error",
                        })
                      )
                  : () => {}
              }
                name={"AssetMaterialId"}
                placeholder="Select Asset category"
                options={employeeList}
              />
            </div>
            <div>
              <LabelCustom labelName={"Asset Type"} mandatory={true} />
              <InputCustomFormik placeholder="Select Asset Type" name="Type"/>

            </div>
            <div>
              <LabelCustom labelName={"Description"} mandatory={true} />
              <TextAreaFormik 
              style={{ height: "80px" }}
              maxLength={500}
                name={"Description"}
                placeholder="Enter Description"
              />
            </div>
            <div className="createFootCust">
              <button
                type="button"
                className="button secondaryButton"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton"
                type="submit"
                style={{ color: "white" }}
              >
                {type}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
