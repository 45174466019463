import React, { useCallback, useEffect, useState } from 'react'
import "./SearchMessage.css";
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import { searchMessagesByConversationAndCaptionThunk } from '../../../../Features/ChatSlice';
import { useDispatch } from 'react-redux';
function SearchMessge({ activeChat, setSearchMessage }) {


    useEffect(() => {
        document.title = `PeopleSol - Group Info`;
        return () => {
            document.title = 'PeopleSol';
        };
    })




    const userDetails = useSelector(state => state.chat.userDetails);
    const searchResults = useSelector((state) => state.chat.searchMeassageResults);
    const dispatch = useDispatch();
    useEffect(() => {
        handleSearch("")
    }, [])
    const handleSearch = (caption) => {
        dispatch(searchMessagesByConversationAndCaptionThunk({ captionQuery: caption, conversationId: activeChat._id }));
    }

    const getDate = (date) => {
        if (!date) return "";
        let a = JSON.stringify(new Date().toLocaleDateString());
        let b = JSON.stringify(new Date(date).toLocaleDateString());
        if (a == b) return "Today";
        if (new Date().getMonth() == new Date(date).getMonth() && new Date().getFullYear() === new Date(date).getFullYear()) {
            if ((new Date().valueOf() - new Date(date).valueOf()) < 1000 * 60 * 60 * 24) return "Yesterday";
            else return new Date(date).toLocaleDateString();
        } else {
            return new Date(date).toLocaleDateString();
        }
    }
    function getTime(dateString) {
        const date = new Date(dateString);

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';

        // Convert to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // If `hours` is 0, set it to 12
        const formattedMinutes = minutes.toString().padStart(2, '0'); // Add leading zero to minutes if needed

        return `${hours}:${formattedMinutes} ${ampm}`;
    }

    return (
        <>
            <div className="MessageInfoMainContainer">
                <div className="MessageInfoHeaderContainer">
                    <div className="MessageInfoHeaderWrapper">
                        <span>{"Search Message"}</span>
                        <svg onClick={() => {
                            setSearchMessage(false);
                        }}
                            xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 0.707104 -0.70711 -0.707104 20.4531 6.48962)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
                            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 22.0259 20.6318)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>

                {/* <OneToOneInfo /> */}
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 10 }}>
                    <input type="text" placeholder='enter message' style={{ padding: 10, borderRadius: 4, outline: "none", backgroundColor: "rgba(216, 240, 242, 1)", borderStyle: "none" }} onChange={(e) => { handleSearch(e.target.value) }} />
                </div>
                <div style={{ width: "100%", height: "80%", display: "flex", marginTop: 10, flexDirection: "column", padding: 15, overflow: "auto" }}>
                    {console.log('-------=========--->', searchResults)}
                    {searchResults.filter(item => item.type != 6)?.map((item, index) => (
                        <div key={index} style={{ display: "flex", flexDirection: "column", marginTop: 30, borderStyle: "solid", borderWidth: 0, borderBottomWidth: .4, paddingBottom: 10, borderColor: "grey" }}>
                            <div style={{ color: "grey", fontSize: 10 }}>{getDate(item.createdOn) + ", " + getTime(item.createdOn)}</div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ color: "rgba(18, 150, 176, 1)", fontSize: 12, marginTop: 3 }}>{item.senderId === userDetails._id ? "You :" : activeChat?.participateMembers?.filter(val => val.contactId === item.senderId)[0]?.contactName + " :"}</div>
                                <div style={{ backgroundColor: "rgba(18, 150, 176, 1)", color: "white", borderRadius: "6px 0px 6px 6px", padding: 10, }}>
                                    {item.caption}
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>

    );
}

export default SearchMessge;