import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { getRequestForm } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import SocialPostImageDeleteIcon from "../../../../../Assets/SocialPostImageDeleteIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import { useSelector } from "react-redux";
import "./Connect.css";
import PDFViewer from "./PDFViewer";
import {
  albumPostImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import { Mention, MentionsInput } from "react-mentions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { useNavigate } from "react-router-dom";
import {
  getAttachments,
  getExtension,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import {
  setCommunityPostData,
  setSocialData,
} from "../../../../../Features/SocialSlice";

function EditMyPost({
  getData,
  setIsMyPosEditActive,
  editDeleteSocialPostData,
  setEditDeleteSocialPostData,
  editDeleteSocialPostId,
  setIsSharePosEditActive,
  isCommunityorConnect,
}) {
  const [newPostActive, setNewPostActive] = useState(true);
  const [newPostTextData, setNewPostTextData] = useState(
    editDeleteSocialPostData?.content
  );
  const [isImgSelecting, setIsImgSelecting] = useState(false);
  const [isVideoSelecting, setIsVideoSelecting] = useState(false);
  const [isDocSelecting, setIsDocSelecting] = useState(false);
  const [newPostAttachmentData, setNewPostAttachmentData] = useState([]);
  const [editedPostAttachmentData, setEditedPostAttachmentData] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const { employeeListReduxData } = useSelector((state) => state.social);
  const { socialData } = useSelector((state) => state.social);
  const { communityPostData } = useSelector((state) => state.social);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let imgArray = useMemo(() => ["png", "jpg", "jpeg", "jfif", "gif"], []);
  let videoArray = useMemo(() => ["mp4", "avi", "mkv", "mo"], []);
  let docsArray = useMemo(() => ["pdf"], []);
  const findAndWrapLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s<]+)/g;
    const processedText = text.replace(/<p>.*?<\/p>/g, (paragraph) => {
      return paragraph.replace(
        urlRegex,
        `<a href='$&' target='_blank' rel='noopener noreferrer'>$&</a>`
      );
    });

    return processedText;
  };
  const uploadNewPoat = async () => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("Id", editDeleteSocialPostId);
    bodyFormData.append("Type", editDeleteSocialPostData?.type);
    if (editedPostAttachmentData && newPostAttachmentData?.length > 0) {
      let newAttachmenets = newPostAttachmentData?.map((d) => {
        let x = d?.lastIndexOf("/");
        return d?.substring(x + 1, d?.length);
      });
      bodyFormData.append("Attachment", newAttachmenets.toString());
    } else if (
      !editedPostAttachmentData &&
      editDeleteSocialPostData?.attachment !== null
    ) {
      bodyFormData.append("Attachment", editDeleteSocialPostData?.attachment);
    }
    bodyFormData.append(
      "Content",
      findAndWrapLinks(newPostTextData?.trim()?.toString("html"))
    );
    try {
      const result = await getRequestForm(`SocialPost/Edit`, bodyFormData);
      let newAttachmenets = newPostAttachmentData?.map((d) => {
        let x = d?.lastIndexOf("/");
        return d?.substring(x + 1, d?.length);
      });
      if (isCommunityorConnect === "connect") {
        let x = socialData?.map((d) => {
          if (d?.id === editDeleteSocialPostId) {
            return {
              ...d,
              content: newPostTextData?.trim().toString("html"),
              attachment: newAttachmenets?.join(","),
            };
          } else {
            return d;
          }
        });
        dispatch(setSocialData(x));
      }
      if (isCommunityorConnect === "community") {
        let x = communityPostData?.map((d) => {
          if (d?.id === editDeleteSocialPostId) {
            return {
              ...d,
              content: newPostTextData?.trim().toString("html"),
              attachment: newAttachmenets?.join(","),
            };
          } else {
            return d;
          }
        });
        dispatch(setCommunityPostData(x));
      }
      setIsMyPosEditActive(false);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      setIsImgSelecting(false);
      setIsVideoSelecting(false);
    }
  };

  useEffect(() => {
    if (editDeleteSocialPostData?.attachment) {
      const attachments = getAttachments(editDeleteSocialPostData.attachment);

      let hasImage = false;
      let hasVideo = false;
      let hasDocument = false;

      const processedAttachments = attachments.map((attachment, i) => {
        const extension = getExtension(attachment).toLowerCase();
        if (imgArray.includes(extension)) {
          hasImage = true;
        } else if (videoArray.includes(extension)) {
          hasVideo = true;
        } else if (docsArray.includes(extension)) {
          hasDocument = true;
        }
        return process.env.REACT_APP_.BASE_URL + socialPostImages + attachment;
      });
      setNewPostAttachmentData(processedAttachments);
      if (hasImage) {
        setIsImgSelecting(true);
      } else if (hasVideo) {
        setIsVideoSelecting(true);
      } else if (hasDocument) {
        setIsDocSelecting(true);
      }
    }
  }, []);
  // useEffect(() => {
  //   const handleTextareaInput = () => {
  //     const textarea = document.getElementById("myTextarea");
  //     textarea.style.height = "auto";
  //     textarea.style.height = textarea.scrollHeight + 10 + "px";
  //   };

  //   const textarea = document.getElementById("myTextarea");
  //   textarea.addEventListener("input", handleTextareaInput);

  //   return () => {
  //     textarea.removeEventListener("input", handleTextareaInput);
  //   };
  // }, []);

  return (
    <div>
      {newPostActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          // contentClassName="newPostPopupContent"
          popupHeading={
            <div className="newPostHeaderContainer">
              <div
                className={`${
                  LoggedInUser?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePic"
                }`}
              >
                {!LoggedInUser?.image ? (
                  getUserInitials(
                    getDisplayName(LoggedInUser?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className="socialProfilePic"
                    onClick={() => {
                      navigate(`/home/social/userprofile/${LoggedInUser?.id}`);
                    }}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      LoggedInUser?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="newPostHeaderTextContainer">
                <h5>{LoggedInUser?.name}</h5>
                <p>{LoggedInUser?.designation}</p>
                <span>
                  {" "}
                  {employeeListReduxData?.map((d, i) => {
                    if (d?.id === LoggedInUser?.id) {
                      return d?.location;
                    } else {
                      return null;
                    }
                  })}
                </span>
              </div>
            </div>
          }
          content={
            <>
              {editDeleteSocialPostData?.subType !== null && (
                <div className="newPostBodyContainer">
                  <div
                    className="postBodyContainer"
                    style={{ margin: "20px 30px" }}
                  >
                    <div className="postBodyTextContainer">
                      {/* <TextAreaCustom
                        name={"content"}
                        autoFocus
                        id="myTextarea"
                        placeholder={" What do you want to talk about? "}
                        value={newPostTextData}
                        onChange={(e) => {
                          setNewPostTextData(e.currentTarget.value);
                        }}
                        className={"createPostInputText"}
                      /> */}
                      <MentionsInput
                        placeholder=" What do you want to talk about? "
                        autoFocus
                        value={newPostTextData}
                        onChange={(e) => {
                          if (e.target.value?.length > 4000) {
                            dispatch(
                              showToast({
                                text: "You have exceeded the maximum character limit, Contenyt should be less than 4000 characters!",
                                severity: "error",
                              })
                            );
                          } else {
                            setNewPostTextData(e.target.value);
                          }
                        }}
                        maxLength={4000}
                        a11ySuggestionsListLabel={
                          " What do you want to talk about? "
                        }
                        className={"createPostInputText"}
                        // style={{ border: "1px solid #f1f2f2" }}
                      >
                        <Mention
                          trigger={"@"}
                          data={employeeListReduxData?.map((d) => {
                            return {
                              id: d?.id,
                              display: getDisplayName(d?.name),
                            };
                          })}
                        />
                      </MentionsInput>
                    </div>
                    <div
                      className="newPostBodyImgMainWrapper"
                      style={{ marginLeft: "12px" }}
                    >
                      <div
                        className="editPostBodyImgContainer"
                        style={{ position: "relative" }}
                      >
                        {editDeleteSocialPostData?.attachment !== null && (
                          <>
                            <img
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                albumPostImages +
                                editDeleteSocialPostData?.attachment
                              }
                              alt=""
                              style={{ width: "100%", margin: "20px 0" }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {editDeleteSocialPostData?.subType === null && (
                <div className="newPostBodyContainer">
                  {/* <TextAreaCustom
                    name={"content"}
                    autoFocus
                    id="myTextarea"
                    placeholder={"Type something..."}
                    value={newPostTextData}
                    onChange={(e) => {
                      setNewPostTextData(e.target.value);
                    }}
                    className={"createPostInputText"}
                  /> */}
                  <MentionsInput
                    placeholder=" What do you want to talk about? "
                    autoFocus
                    maxLength={4000}
                    value={newPostTextData}
                    onChange={(e) => {
                      if (e.target.value?.length > 4000) {
                        dispatch(
                          showToast({
                            text: "You have exceeded the maximum character limit, Content should be less than 4000 characters!",
                            severity: "error",
                          })
                        );
                      } else {
                        setNewPostTextData(e.target.value);
                      }
                    }}
                    a11ySuggestionsListLabel={
                      " What do you want to talk about? "
                    }
                    className={"createPostInputText"}
                  >
                    <Mention
                      trigger={"@"}
                      data={employeeListReduxData?.map((d) => {
                        return {
                          id: d?.id,
                          display: getDisplayName(d?.name),
                        };
                      })}
                    />
                  </MentionsInput>
                  {isImgSelecting && (
                    <div
                      className={`newPostBodyImgMainWrapper`}
                      style={{ marginLeft: "12px" }}
                    >
                      {newPostAttachmentData?.length === 1 && (
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            height: "400px",
                          }}
                        >
                          <img
                            src={
                              newPostAttachmentData[0]?.url ??
                              newPostAttachmentData[0]
                            }
                            alt=""
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostAttachmentData.filter(
                                (d) => d !== newPostAttachmentData[0]
                              );
                              setNewPostAttachmentData(x);
                              setEditedPostAttachmentData(true);
                            }}
                          />
                        </div>
                      )}
                      {newPostAttachmentData?.length === 2 && (
                        <div className="editPostBodyImgContainer2">
                          <div className="subImage2a">
                            <div style={{ position: "relative" }}>
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[0]?.url ??
                                  newPostAttachmentData[0]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[0]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                          <div className="subImage2b">
                            <div style={{ position: "relative" }}>
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[1]?.url ??
                                  newPostAttachmentData[1]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[1]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {newPostAttachmentData?.length === 3 && (
                        <div className="editPostBodyImgContainer3">
                          <div
                            className="subImage3a"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="editimg"
                              src={
                                newPostAttachmentData[0]?.url ??
                                newPostAttachmentData[0]
                              }
                              alt=""
                              style={{ width: "100%", objectFit: "cover" }}
                            />
                            <img
                              src={SocialPostImageDeleteIcon}
                              alt=""
                              className="imageDeselectIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                let x = newPostAttachmentData.filter(
                                  (d) => d !== newPostAttachmentData[0]
                                );
                                setNewPostAttachmentData(x);
                                setEditedPostAttachmentData(true);
                              }}
                            />
                          </div>
                          <div className="subImage3b">
                            <div
                              style={{
                                position: "relative",
                                height: "165px",
                              }}
                            >
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[1]?.url ??
                                  newPostAttachmentData[1]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[1]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "165px",
                              }}
                            >
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[2]?.url ??
                                  newPostAttachmentData[2]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[2]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {newPostAttachmentData?.length === 4 && (
                        <div className="editPostBodyImgContainer4">
                          <div
                            className="subImage4a"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="editimg"
                              src={
                                newPostAttachmentData[0]?.url ??
                                newPostAttachmentData[0]
                              }
                              alt=""
                            />
                            <img
                              src={SocialPostImageDeleteIcon}
                              alt=""
                              className="imageDeselectIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                let x = newPostAttachmentData.filter(
                                  (d) => d !== newPostAttachmentData[0]
                                );
                                setNewPostAttachmentData(x);
                                setEditedPostAttachmentData(true);
                              }}
                            />
                          </div>
                          <div className="subImage4b">
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[1]?.url ??
                                  newPostAttachmentData[1]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[1]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[2]?.url ??
                                  newPostAttachmentData[2]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[2]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[3]?.url ??
                                  newPostAttachmentData[3]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[3]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {newPostAttachmentData?.length > 4 && (
                        <div className="editPostBodyImgContainer4">
                          <div
                            className="subImage4a"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="editimg"
                              src={
                                newPostAttachmentData[0]?.url ??
                                newPostAttachmentData[0]
                              }
                              alt=""
                            />
                            <img
                              src={SocialPostImageDeleteIcon}
                              alt=""
                              className="imageDeselectIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                let x = newPostAttachmentData.filter(
                                  (d) => d !== newPostAttachmentData[0]
                                );
                                setNewPostAttachmentData(x);
                                setEditedPostAttachmentData(true);
                              }}
                            />
                          </div>
                          <div className="subImage4b">
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[1]?.url ??
                                  newPostAttachmentData[1]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[1]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[2]?.url ??
                                  newPostAttachmentData[2]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[2]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <img
                                className="editimg"
                                src={
                                  newPostAttachmentData[3]?.url ??
                                  newPostAttachmentData[3]
                                }
                                alt=""
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[3]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                          <div className="imgHideMainBox">
                            <div className="imgHideRoundBox">
                              <span>+ {newPostAttachmentData?.length - 4}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {isVideoSelecting && (
                    <div
                      className={
                        newPostAttachmentData?.length === 1
                          ? `newPostBodyImgMainContainer`
                          : `newPostBodyImgMainWrapper`
                      }
                    >
                      {newPostAttachmentData?.length === 1 && (
                        <div
                          className="editPostBodyImgContainer"
                          style={{
                            position: "relative",
                            height: "360px",
                            width: "640px",
                          }}
                        >
                          <video
                            src={
                              newPostAttachmentData[0]?.url
                                ? newPostAttachmentData[0]?.url
                                : newPostAttachmentData[0]
                            }
                            controls
                            width="630"
                            height="335"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostAttachmentData.filter(
                                (d) => d !== newPostAttachmentData[0]
                              );
                              setNewPostAttachmentData(x);
                              setEditedPostAttachmentData(true);
                            }}
                          />
                        </div>
                      )}
                      {newPostAttachmentData?.length === 2 && (
                        <div className="editPostBodyImgContainer2">
                          <div className="subImage2a">
                            <div style={{ position: "relative" }}>
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[0]?.url
                                    ? newPostAttachmentData[0]?.url
                                    : newPostAttachmentData[0]
                                }
                                controls
                                width="315"
                                height="335"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[0]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                          <div className="subImage2b">
                            <div style={{ position: "relative" }}>
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[1]?.url
                                    ? newPostAttachmentData[1]?.url
                                    : newPostAttachmentData[1]
                                }
                                controls
                                width="315"
                                height="335"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[1]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {newPostAttachmentData?.length === 3 && (
                        <div className="editPostBodyImgContainer3">
                          <div
                            className="subImage3a"
                            style={{ position: "relative" }}
                          >
                            <video
                              className="editimg"
                              src={
                                newPostAttachmentData[0]?.url
                                  ? newPostAttachmentData[0]?.url
                                  : newPostAttachmentData[0]
                              }
                              controls
                              width="315"
                              height="335"
                            />
                            <img
                              src={SocialPostImageDeleteIcon}
                              alt=""
                              className="imageDeselectIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                let x = newPostAttachmentData.filter(
                                  (d) => d !== newPostAttachmentData[0]
                                );
                                setNewPostAttachmentData(x);
                                setEditedPostAttachmentData(true);
                              }}
                            />
                          </div>
                          <div className="subImage3b">
                            <div
                              style={{
                                position: "relative",
                                height: "165px",
                              }}
                            >
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[1]?.url
                                    ? newPostAttachmentData[1]?.url
                                    : newPostAttachmentData[1]
                                }
                                controls
                                width="215"
                                height="165"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[1]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "165px",
                              }}
                            >
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[2]?.url
                                    ? newPostAttachmentData[2]?.url
                                    : newPostAttachmentData[2]
                                }
                                controls
                                width="215"
                                height="165"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[2]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {newPostAttachmentData?.length === 4 && (
                        <div className="editPostBodyImgContainer4">
                          <div
                            className="subImage4a"
                            style={{ position: "relative" }}
                          >
                            <video
                              className="editimg"
                              src={
                                newPostAttachmentData[0]?.url
                                  ? newPostAttachmentData[0]?.url
                                  : newPostAttachmentData[0]
                              }
                              controls
                              width="315"
                              height="335"
                            />
                            <img
                              src={SocialPostImageDeleteIcon}
                              alt=""
                              className="imageDeselectIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                let x = newPostAttachmentData.filter(
                                  (d) => d !== newPostAttachmentData[0]
                                );
                                setNewPostAttachmentData(x);
                                setEditedPostAttachmentData(true);
                              }}
                            />
                          </div>
                          <div className="subImage4b">
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[1]?.url
                                    ? newPostAttachmentData[1]?.url
                                    : newPostAttachmentData[1]
                                }
                                controls
                                width="215"
                                height="110"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[1]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[2]?.url
                                    ? newPostAttachmentData[2]?.url
                                    : newPostAttachmentData[2]
                                }
                                controls
                                width="215"
                                height="110"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[2]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[3]?.url
                                    ? newPostAttachmentData[3]?.url
                                    : newPostAttachmentData[3]
                                }
                                controls
                                width="215"
                                height="110"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[3]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {newPostAttachmentData?.length > 4 && (
                        <div className="editPostBodyImgContainer4">
                          <div
                            className="subImage4a"
                            style={{ position: "relative" }}
                          >
                            <video
                              className="editimg"
                              src={
                                newPostAttachmentData[0]?.url
                                  ? newPostAttachmentData[0]?.url
                                  : newPostAttachmentData[0]
                              }
                              controls
                              width="315"
                              height="335"
                            />
                            <img
                              src={SocialPostImageDeleteIcon}
                              alt=""
                              className="imageDeselectIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                let x = newPostAttachmentData.filter(
                                  (d) => d !== newPostAttachmentData[0]
                                );
                                setNewPostAttachmentData(x);
                                setEditedPostAttachmentData(true);
                              }}
                            />
                          </div>
                          <div className="subImage4b">
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[1]?.url
                                    ? newPostAttachmentData[1]?.url
                                    : newPostAttachmentData[1]
                                }
                                controls
                                width="215"
                                height="110"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[1]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[2]?.url
                                    ? newPostAttachmentData[2]?.url
                                    : newPostAttachmentData[2]
                                }
                                controls
                                width="215"
                                height="110"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[2]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                height: "110px",
                              }}
                            >
                              <video
                                className="editimg"
                                src={
                                  newPostAttachmentData[3]?.url
                                    ? newPostAttachmentData[3]?.url
                                    : newPostAttachmentData[3]
                                }
                                controls
                                width="215"
                                height="110"
                              />
                              <img
                                src={SocialPostImageDeleteIcon}
                                alt=""
                                className="imageDeselectIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let x = newPostAttachmentData.filter(
                                    (d) => d !== newPostAttachmentData[3]
                                  );
                                  setNewPostAttachmentData(x);
                                  setEditedPostAttachmentData(true);
                                }}
                              />
                            </div>
                          </div>
                          <div className="imgHideMainBox">
                            <div className="imgHideRoundBox">
                              <span>+ {newPostAttachmentData?.length - 4}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {isDocSelecting && newPostAttachmentData && (
                    <div style={{ position: "relative" }}>
                      <PDFViewer
                        pdfUrl={
                          newPostAttachmentData[0]?.url ??
                          newPostAttachmentData[0]
                        }
                        style={{ height: "400px" }}
                      />
                      {newPostAttachmentData?.length > 0 && (
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            setNewPostAttachmentData([]);
                            setIsDocSelecting(false);
                            setEditedPostAttachmentData(true);
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className="shareBottomContainer">
                {!loading && newPostTextData !== "" && (
                  <button class="button primaryButton" onClick={uploadNewPoat}>
                    Save
                  </button>
                )}
                {loading && (
                  <span style={{ color: "#bfbfbf", padding: "20px 30px" }}>
                    Saving...
                  </span>
                )}
              </div>
            </>
          }
          handleClose={() => {
            setNewPostTextData("");
            setIsImgSelecting(false);
            setIsVideoSelecting(false);
            setIsMyPosEditActive(false);
          }}
        />
      )}
    </div>
  );
}

export default EditMyPost;
