import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import "./OrgStructure.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Department } from "../OrgStructureComponents/Department";
import { Band } from "../OrgStructureComponents/Band";
import { Role } from "../OrgStructureComponents/Role";
import { Designation } from "../OrgStructureComponents/Designation";
import { PersonalArea } from "../OrgStructureComponents/PersonalArea";
import { PersonalSubArea } from "../OrgStructureComponents/PersonalSubArea";
import { Area } from "../OrgStructureComponents/Area";
import { Company } from "../OrgStructureComponents/Company";
import { Employee } from "../OrgStructureComponents/Employee";

const OrgStructure = ({ featureMenu }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    navigate(`orgstructure/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `PeopleSol - Organization Structure`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("hrmsmaster/orgstructure")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "orgstructure");
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `orgstructure/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `orgstructure/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
  }, [location.pathname, featureMenu]);
  return (
    <div className="moduleMainContainer" style={{ position: "relative" }}>
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex ===
                            item?.featureName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "tabActive"
                        }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "company" && (
        <>
          <Company title="Company" />
        </>
      )}
      {tabIndex === "employee" && (
        <>
          <Employee title="Employee" />
        </>
      )}
      {tabIndex === "department" && (
        <>
          <Department title="Department" />
        </>
      )}
      {tabIndex === "band" && (
        <>
          <Band title="Band" />
        </>
      )}
      {tabIndex === "role" && (
        <>
          <Role title="Role" />
        </>
      )}
      {tabIndex === "designation" && (
        <>
          <Designation title="Designation" />
        </>
      )}
      {tabIndex === "personalarea" && (
        <>
          <PersonalArea title="Personal Area" />
        </>
      )}
      {tabIndex === "personalsubarea" && (
        <>
          <PersonalSubArea title="Personal Sub Area" />
        </>
      )}
      {tabIndex === "area" && (
        <>
          <Area title="Area" />
        </>
      )}
    </div>
  );
};

export { OrgStructure };
