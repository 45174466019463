import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { AppForm } from "./Form/AppForm";

const CreateApplication = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Applications/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  console.log("object formValues", formValues);
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Applications/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Applications/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Application`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg",
  ];
  const appSchema = Yup.object(
    data
      ? {
          Name: Yup.string()
            .max(149, "Name must be less than 150 characters")
            .required("Please Enter Name"),
          Description: Yup.string()
            .max(149, "Description must be less than 150 characters")
            .notRequired("Please Enter Description"),
          Url: Yup.string()
            .max(149, "Url must be less than 150 characters")
            .matches(
              /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
              "Enter a valid  URL with http or https"
            )
            .required("Please Enter  URL"),
          AppUrl: Yup.string()
            .max(149, "Url must be less than 150 characters")
            .matches(
              /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
              "Enter a valid  URL with http or https"
            )
            .nullable()
            .notRequired("Please Enter  URL"),
          Colour: Yup.string().required("Please Choose Colour"),
          upload: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
              "Fichier taille",
              "File Size should be less than 1 mb",
              (value) => {
                // //console.log("val", value);
                return (
                  !value ||
                  (value && value.size <= 2048 * 2048) ||
                  typeof value !== "object"
                );
              }
            )
            .test(
              "format",
              "File Format is Incorrect",
              (value) =>
                !value ||
                (value && SUPPORTED_FORMATS.includes(value.type)) ||
                typeof value !== "object"
            ),
        }
      : {
          Name: Yup.string()
            .max(149, "Name must be less than 150 characters")
            .required("Please Enter Name"),
          Description: Yup.string()
            .max(149, "Description must be less than 150 characters")
            .notRequired("Please Enter Description"),
          Url: Yup.string()
            .max(149, "Url must be less than 150 characters")
            .matches(
              /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
              "Enter a valid URL with http or https"
            )
            .required("Please Enter URL"),
          AppUrl: Yup.string()
            .max(149, "Url must be less than 150 characters")
            .matches(
              /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
              "Enter a valid URL with http or https"
            )
            .nullable()
            .notRequired("Please Enter URL"),
          Colour: Yup.string().required("Please Choose Colour"),
          upload: Yup.mixed()
            .nullable()
            .required("Please Attach Icon")
            .test(
              "Fichier taille",
              "File Size should be less than 1 mb",
              (value) => {
                // //console.log("val", value);
                return (
                  !value ||
                  (value && value.size <= 2048 * 2048) ||
                  typeof value !== "object"
                );
              }
            )
            .test(
              "format",
              "File Format is Incorrect",
              (value) =>
                !value ||
                (value && SUPPORTED_FORMATS.includes(value.type)) ||
                typeof value !== "object"
            ),
        }
  );

  const initialvalue = {
    Name: "",
    Colour: "black",
    AppUrl: "",
    Url: "",
    upload: "",
    Description: "",
    EmployeeIDs: "",
    IsDisplayInMobile: "",
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Name", values?.Name);
    formData.append("Id", data ? data : 0);
    formData.append("Url", values?.Url);
    formData.append("AppUrl", values?.AppUrl);
    formData.append("Description", values?.Description);
    if (typeof values?.upload == "object")
      formData.append("upload", values?.upload);
    formData.append("Colour", values?.Colour);

    // if (values?.EmployeeIDs.length > 0) {
    formData.append("EmployeeIDs", values?.EmployeeIDs);
    // }

    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  const returnPage = () => {
    navigate("settings/application");
  };
  return (
    <FormCustomFormik
      FormHeading={data ? "Edit Application" : "Create Application"}
      ButtonLabel={data ? "Update Application" : "Create Application"}
      onSubmit={onSubmit}
      loading={loading}
      validationSchema={appSchema}
      chooseFormValues={chooseFormValues}
      initialvalue={
        data
          ? {
              ...initialvalue,
              Name: detailsform?.name,
              Url: detailsform?.url,
              Colour: detailsform?.colour,
              Description: detailsform?.description,
              EmployeeIDs: detailsform?.employeeId,
              AppUrl: detailsform?.appUrl
                ? detailsform?.appUrl !== "null"
                  ? detailsform?.appUrl
                  : ""
                : "",
              IsDisplayInMobile:
                detailsform?.isDisplayInMobile === true ? "Yes" : "No",
              upload: `ApplicationIcon/${detailsform?.icon}`,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<AppForm data={detailsform} editID={data} />}
    />
  );
};

export { CreateApplication };
