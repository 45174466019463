import React, { useState, useEffect } from 'react';
import { Tabs, Tab, IconButton } from '@mui/material';
import "./InternalJobs.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "../../../Components/index";
import Select from "react-dropdown-select";
import { LeakAdd } from '@mui/icons-material';

const InternalJobs = (props) => {
    const defaultList = [{
        "JobOpeningID" : 1,
        "DatePosted" : "1 day ago",
        "Role": "App Developer",
        "Designation": "Manager",
        "Department" : "HR",
        "Experience" : "1+years",
        "Location": "Bhopal"
    },
    {
        "JobOpeningID" : 2,
        "DatePosted" : "1 day ago",
        "Role": "App Developer",
        "Designation": "Manager",
        "Department" : "HR",
        "Experience" : "1+years",
        "Location": "Bhopal"
    },
    {
        "JobOpeningID" : 3,
        "DatePosted" : "1 day ago",
        "Role": "App Developer",
        "Designation": "Manager",
        "Department" : "HR",
        "Experience" : "1+years",
        "Location": "Bhopal"
    },
    {
        "JobOpeningID" : 4,
        "DatePosted" : "1 day ago",
        "Role": "App Developer",
        "Designation": "Manager",
        "Department" : "HR",
        "Experience" : "1+years",
        "Location": "Bhopal"
    },
    {
        "JobOpeningID" : 5,
        "DatePosted" : "1 day ago",
        "Role": "App Developer",
        "Designation": "Manager",
        "Department" : "HR",
        "Experience" : "1+years",
        "Location": "Bhopal"
    },
    {
        "JobOpeningID" : 6,
        "DatePosted" : "1 day ago",
        "Role": "App Developer",
        "Designation": "Manager",
        "Department" : "HR",
        "Experience" : "1+years",
        "Location": "Bhopal"
    },
    {
        "JobOpeningID" : 7,
        "DatePosted" : "1 day ago",
        "Role": "App Developer",
        "Designation": "Manager",
        "Department" : "HR",
        "Experience" : "1+years",
        "Location": "Bhopal"
    },
    {
        "JobOpeningID" : 8,
        "DatePosted" : "1 day ago",
        "Role": "App Developer",
        "Designation": "Manager",
        "Department" : "HR",
        "Experience" : "1+years",
        "Location": "Bhopal"
    },]
    const [listOfInternalJobs, setListOfInternalJobs] = useState(defaultList);

    useEffect(()=>{
        document.title = `PeopleSol - Internal Jobs`;
        return () => {
          document.title = 'PeopleSol';
        };
      })


    return (
        <div className="moduleMainContainer">
            <div className="tableHeaderContainer">
                <label>{props.title}</label>
                <div className="chatListSearchContainer navBarSearch">
                    <input type="text" className="chatListSearch" placeholder="Search" />
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0"></circle><line x1="8.70711" y1="9" x2="12" y2="12.2929" stroke="#1296B0" strokeLinecap="round"></line>
                    </svg>
                </div>
                <IconButton color='primary'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z" stroke="#1296B0" strokeWidth="1.5" />
                    </svg>
                </IconButton>
                <IconButton color='primary'>
                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961" stroke="#1296B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                </IconButton>
                <IconButton color='primary'>
                    <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

                </IconButton>
            </div>
            <div className="moduleMainContentHolder">
                <div className="homeCardsHolderContainer">
                    <div className="homeDashboardCardHolder">
                        {listOfInternalJobs.map((item) => {
                            return(
                                <div className="dashboardCard internalJobCard" key={item.JobOpeningID}>
                            <div className="cardContainer">
                                <div className="headingContainer">
                                    <h3>{item.Role}</h3>
                                    <span className="internalJobDateTime">{item.DatePosted}</span>
                                </div>
                                <div className="jobDetailContainerHolder">

                                    <div className="jobDetailContainer">
                                        <div className="verticalLine orangeBackground"></div>
                                        <label className="jobDesignation">Designation</label>
                                        <label>{item.Designation}</label>
                                    </div>
                                    <div className="jobDetailContainer">
                                        <div className="verticalLine pinkBackground"></div>
                                        <label className="jobDesignation">Department</label>
                                        <label>{item.Department}</label>
                                    </div>
                                    <div className="jobDetailContainer">
                                        <div className="verticalLine purpleBackground"></div>
                                        <label className="jobDesignation">Experience Required</label>
                                        <label>{item.Experience}</label>
                                    </div>
                                    <div className="jobDetailContainer">
                                        <div className="verticalLine tealBackground"></div>
                                        <label className="jobDesignation">Location</label>
                                        <label>{item.Location}</label>
                                    </div>
                                </div>
                                <div className="applyJobButtonContainer">
                                    <button className='createFooterButton2 addProjectButton buttonClass'>Apply</button>
                                </div>
                            </div>
                        </div>
                            )
                        })}
                        

                    </div>
                </div>
            </div>
        </div>
    )
}

export { InternalJobs }