import React, { useState } from "react";
import "./MyProfileDetails.css";
import { Switch } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { useCallback } from "react";
import { useEffect } from "react";
import { ProfileGroup } from "./ProfileGroup";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { setUserSettings } from "../../../../Features/ChatSlice";
import GroupIcon from "../../../../Assets/ChatGroupIcon.svg"

function MyProfileDetails({ setMyProfileDetails, userDetails, uploadProfilePic, newSocket, toggleNewGroup }) {

  const userSettings = useSelector((state) => state.chat.userSettings);
  const convoList = useSelector((state) => state.chat.conversationList);
  const [isDND, setDND] = useState(userSettings.dndStatus == 0 ? false : true);
  const [isBusy, setBusy] = useState(userSettings.busyStatus == 0 ? false : true);
  useEffect(() => {
    document.title = `PeopleSol - My Profile Detail`;
    return () => {
      document.title = 'PeopleSol';
    };
  })
  const [loading, setLoading] = useState(false);


  const dispatch = useDispatch();
  const handleFileUpoad = async (selected) => {
    try {
      setLoading(true);
      const formData = new FormData();
      selected?.forEach((item) => formData.append("files", item));
      const response = await fetch(
        "https://media.solunxt.com/api/v1/Storage/Save?token=5b4cbd69-2366-4708-871c-5300a4dc6f18",
        {
          method: "POST",
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
          body: formData,
        }
      );
      const responseData = await response.json();
      if (responseData.succeeded) {
        uploadProfilePic(responseData.data[0].url, userDetails._id)
      }

    } catch (error) {
      dispatch(
        showToast({
          text: error ?? "Some error occurred in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const addImages = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept =
      "image/jpg,image/jpeg,image/png,image/svg";
    fileInput.multiple = true; // Enable multiple file selection

    fileInput.addEventListener("change", (event) => {
      const selected = event.target.files;
      console.log("Selected file:", selected);
      handleFileUpoad(selected)
    });
    fileInput.click();
  };
  function blockContact() {
    confirmAlert({
      title: `Block Jenny James`,
      message: `Blocked contacts will no longer be able to call you or send you messages. `,
      buttons: [
        {
          label: "Yes, confirm cancel",
          onClick: () => { },
        },
        {
          label: "No, cancel cancel",
          onClick: () => { },
        },
      ],
    });
  }

  const myProfileHandler = useCallback((e) => {
    let moreActivity = document.querySelector(".myProfileDetailsMainContainer");
    if (moreActivity && !moreActivity?.contains(e.target)) {
      setMyProfileDetails(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mouseup", myProfileHandler);
    return () => {
      document.removeEventListener("mouseup", myProfileHandler);
    };
  }, [myProfileHandler]);


  const handleUpdate = (payload) => {
    newSocket.emit("updateUserSetting", (payload), (res) => {
      dispatch(
        showToast({ text: "Setting successfully updated", severity: "success" })
      );
      newSocket.emit("getUserSetting", ({}), (res) => {
        if (res.status) {
          dispatch(setUserSettings(res.data));
        }
      })
    })
  }

  return (
    <div className="chatContactListContainer" style={{ alignItems: 'flex-start' }}>
      <div className="myProfileHeaderContainer">
        {console.log("---------> convo ", convoList)}
        <div className="myProfileHeaderWrapper">
          <svg
            onClick={() => {
              setMyProfileDetails(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="12"
            viewBox="0 0 19 12"
            fill="none"
          >
            <path
              d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
              fill="#F7941D"
            />
          </svg>
          <span>{userDetails.name}</span>
        </div>
      </div>
      {loading && (
        <div className="document-preview-container-uploading">
          <div
            style={{
              color: "rgb(51, 51, 51)",
              padding: "20px",
              borderRadius: "5px",
              fontSize: "17px",
              textAlign: "center",
              width: "500px",
              backgroundColor: "white",
            }}
          >
            <p>Sending your media...</p>
            <div style={{ marginTop: "0px" }}>
              {" "}
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="myProfileDetailsMainWrapper">
        <div className="myProfileImageContainer">
          <div className="myProfileImageWrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="155"
              height="154"
              viewBox="0 0 155 154"
              fill="none"
            >
              <g opacity="0.4">
                <circle
                  cx="77.1895"
                  cy="77.0684"
                  r="76.9316"
                  transform="rotate(-90 77.1895 77.0684)"
                  fill="url(#paint0_radial_60_9411)"
                />
              </g>
              <g opacity="0.4">
                <circle
                  cx="77.2061"
                  cy="77.0963"
                  r="63.3691"
                  transform="rotate(90 77.2061 77.0963)"
                  fill="url(#paint1_radial_60_9411)"
                />
              </g>
              <g opacity="0.4">
                <circle
                  cx="77.2051"
                  cy="77.0969"
                  r="46.7725"
                  transform="rotate(90 77.2051 77.0969)"
                  fill="url(#paint2_radial_60_9411)"
                />
              </g>
              <defs>
                <radialGradient
                  id="paint0_radial_60_9411"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(73.4253 78.6441) rotate(102.659) scale(101.154)"
                >
                  <stop
                    offset="0.568025"
                    stop-color="white"
                    stop-opacity="0.2"
                  />
                  <stop offset="1" stop-color="#13A9C7" />
                </radialGradient>
                <radialGradient
                  id="paint1_radial_60_9411"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(74.1055 78.3943) rotate(102.659) scale(83.3209)"
                >
                  <stop
                    offset="0.568025"
                    stop-color="white"
                    stop-opacity="0.2"
                  />
                  <stop offset="1" stop-color="#13A9C7" />
                </radialGradient>
                <radialGradient
                  id="paint2_radial_60_9411"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(74.9166 78.0549) rotate(102.659) scale(61.4988)"
                >
                  <stop
                    offset="0.531188"
                    stop-color="white"
                    stop-opacity="0.2"
                  />
                  <stop offset="1" stop-color="#13A9C7" />
                </radialGradient>
              </defs>
            </svg>
            {console.log("============", userDetails)}
            <div className="myProfileImage">
              {userDetails.image !== ""
                ?
                <img style={{ width: "100%", height: "100%", borderRadius: 200 }} src={userDetails.image} />
                :
                <div style={{ width: "100%", height: "100%", backgroundColor: "#E9F3F4", borderRadius: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ fontSize: 30, color: "#13A9C7" }}>
                    {
                      userDetails.name
                        .split(" ")
                        .slice(0, 2)
                        .map((item) => {
                          return item.charAt(0).toUpperCase();
                        })
                        .join("")
                    }
                  </div>
                </div>
              }
            </div>

            <div onClick={() => addImages()} className="cameraIcon">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
                <circle cx="14.4892" cy="14.8879" r="14.2138" fill="#1296B0" />
                <circle cx="17.842" cy="13.3865" r="0.412346" fill="white" />
                <circle cx="14.4905" cy="16.3486" r="2.99882" stroke="white" stroke-width="1.11962" />
                <path d="M10.0234 10.4073V10.9671H10.5832V10.4073H10.0234ZM18.9549 10.4073H18.3951V10.9671H18.9549V10.4073ZM10.5832 10.1871C10.5832 9.39489 11.2254 8.75265 12.0177 8.75265V7.63303C10.6071 7.63303 9.46357 8.77654 9.46357 10.1871H10.5832ZM10.5832 10.4073V10.1871H9.46357V10.4073H10.5832ZM9.24383 10.9671H10.0234V9.84746H9.24383V10.9671ZM7.31079 12.9001C7.31079 11.8325 8.17624 10.9671 9.24383 10.9671V9.84746C7.55789 9.84746 6.19117 11.2142 6.19117 12.9001H7.31079ZM7.31079 19.0909V12.9001H6.19117V19.0909H7.31079ZM9.24383 21.0239C8.17624 21.0239 7.31079 20.1585 7.31079 19.0909H6.19117C6.19117 20.7768 7.55789 22.1435 9.24383 22.1435V21.0239ZM19.7345 21.0239H9.24383V22.1435H19.7345V21.0239ZM21.6675 19.0909C21.6675 20.1585 20.8021 21.0239 19.7345 21.0239V22.1435C21.4204 22.1435 22.7871 20.7768 22.7871 19.0909H21.6675ZM21.6675 12.9001V19.0909H22.7871V12.9001H21.6675ZM19.7345 10.9671C20.8021 10.9671 21.6675 11.8325 21.6675 12.9001H22.7871C22.7871 11.2142 21.4204 9.84746 19.7345 9.84746V10.9671ZM18.9549 10.9671H19.7345V9.84746H18.9549V10.9671ZM18.3951 10.1871V10.4073H19.5147V10.1871H18.3951ZM16.9606 8.75265C17.7528 8.75265 18.3951 9.39489 18.3951 10.1871H19.5147C19.5147 8.77654 18.3712 7.63303 16.9606 7.63303V8.75265ZM12.0177 8.75265H16.9606V7.63303H12.0177V8.75265Z" fill="white" />
              </svg>
            </div>
          </div>
          <div className="imgText ">
            <h4>{userDetails.name}</h4>
            <span>{userDetails.designation}</span>
          </div>
        </div>
        <div className="myProfileStatusContainer">
          <p>Profile Status</p>
          <div className="busyStatusProfileWrapper">
            <div className="busyStatus">
              <div className="busyStatusDot"></div>
              <span>Busy</span>
            </div>
            <div className="changeStatus">
              <div onClick={() => {
                setBusy(!isBusy);
                if (!isBusy) {
                  handleUpdate({ "reqType": "dndStatus", "updateVal": 0 });
                  setDND(false);
                }
                console.log("--------->", isBusy ? 0 : 1)
                handleUpdate({ "reqType": "busyStatus", "updateVal": isBusy ? 0 : 1 })
              }} style={{ width: 20, height: 7, backgroundColor: isBusy ? "rgba(216, 240, 242, 1)" : "#BFBFBF", borderRadius: 10, display: "flex", justifyContent: isBusy ? "flex-end" : "flex-start", alignItems: "center" }}>
                <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: isBusy ? "#1296B0" : "#555555" }}></div>
              </div>
            </div>
          </div>
          <div className="dndStatusProfileWrapper">
            <div className="dndStatus">
              <div className="dndStatusDot"></div>
              <span>Do not disturb</span>
            </div>

            <div className="changeStatus">
              <div onClick={() => {
                setDND(!isDND);
                if (!isDND) {
                  handleUpdate({ "reqType": "busyStatus", "updateVal": 0 })
                  setBusy(false)
                }
                handleUpdate({ "reqType": "dndStatus", "updateVal": isDND ? 0 : 1 })
              }} style={{ width: 20, height: 7, backgroundColor: isDND ? "rgba(216, 240, 242, 1)" : "#BFBFBF", borderRadius: 10, display: "flex", justifyContent: isDND ? "flex-end" : "flex-start", alignItems: "center" }}>
                <div style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: isDND ? "#1296B0" : "#555555" }}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="myProfileAboutContainer">
          <div className="myProfileAboutWrapper">

            <div className="aboutTextWrapper1">
              <div className="empId">
                <hr></hr>
                <span>Employee ID</span>
                <p>{userDetails.personalNumber}</p>
              </div>
              <div className="department">
                <hr></hr>
                <span>Department</span>
                <p>{userDetails.department}</p>
              </div>
            </div>
            <div className="aboutTextWrapper2">
              <div className="role">
                <hr></hr>
                <span>Role</span>
                <p>{userDetails.role}</p>
              </div>
              <div className="mobileno">
                <hr></hr>
                <span>Mobile No.</span>
                <p>{userDetails.phoneNumber}</p>
              </div>
            </div>
            <div className="aboutTextWrapper3">
              <div className="email">
                <hr></hr>
                <span>Email</span>
                <p>{userDetails.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="myProfileGroupContainer" style={{ marginLeft: 2, marginBottom: 50 }}>
          <p style={{ color: "grey", fontSize: 10, }}>{"Participant in " + convoList.filter(item => item.type === "Group").length + " Groups"}</p>
          <div style={{ borderStyle: "solid", borderWidth: 1, borderRadius: 4, borderColor: "rgba(216, 240, 242, 1)", width: "100%", padding: 10 }}>
            <div onClick={() => { toggleNewGroup() }} style={{ backgroundColor: "rgba(216, 240, 242, 1)", borderRadius: 4, display: "flex", flexDirection: "row", alignItems: "center", padding: 10, fontSize: 14, }}>
              <img src={GroupIcon} />
              <div style={{ marginLeft: 10 }}>Create new group</div>
            </div>
            <div style={{ marginTop: 20 }}>
              {convoList.filter(item => item.type === "Group").map((chat) => (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 15 }}>
                  <div className="imageContainer" style={{ width: 40, height: 40, borderRadius: 20 }}>
                    {chat.icon && chat.icon != "" ? <img style={{ width: 40, height: 40, borderRadius: 20 }} src={chat.icon} alt="groupProfilePic" />
                      :
                      <div style={{ backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 300, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                        <div style={{ color: "#13A9C7", fontSize: 14 }}>
                          {chat.title
                            ?.split(" ")
                            ?.slice(0, 2)
                            ?.map((item) => {
                              return item?.charAt(0)?.toUpperCase();
                            })
                            .join("")
                          }
                        </div>
                      </div>}
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <div>{chat.title}</div>
                    <div style={{ fontSize: 12, color: "grey" }}>{"You, " + chat.participateMembers.filter(val => val.personalNumber != userDetails.personalNumber)[0].contactName + "..."}</div>
                  </div>
                </div>
              ))}
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfileDetails;
