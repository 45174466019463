import React from "react";
import { useState } from "react";
import { Table } from "../../../Components";
import { Tabs, Tab } from "@mui/material";
import { useEffect } from "react";

const tempData = [
  {
    Sno: "1",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
  {
    Sno: "2",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
  {
    Sno: "3",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
  {
    Sno: "4",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
  {
    Sno: "5",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
  {
    Sno: "6",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
  {
    Sno: "7",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
  {
    Sno: "8",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
  {
    Sno: "9",
    RefNo: "AR/48",
    Name: "Rachel Jackson (10000006)",
    Date: "06/02/2023",
    StartTime: "4:37 Pm",
    EndTime: "6:37 Pm",
    TAT: "8:18",
  },
];

const actionData = [
  {
    img: (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.15"
          d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
          fill="#0DB53C"
        />
        <path
          d="M6.93986 15.4946C6.99241 15.4421 7.7807 14.4962 8.9894 13.3926C9.09451 13.2874 9.19961 13.2874 9.35727 13.2874C11.1441 13.2874 13.7717 13.2874 15.5585 13.2874C16.0314 13.2874 16.3467 12.9721 16.3467 12.4992C16.3467 10.292 16.3467 8.0322 16.3467 5.77245C16.3467 5.35203 16.0314 4.98416 15.5585 4.98416C12.3528 4.98416 9.14706 4.98416 5.94136 4.98416C5.46839 4.98416 5.15308 5.29948 5.15308 5.77245C5.15308 7.97965 5.15308 10.2394 5.15308 12.4992C5.15308 12.9196 5.46839 13.2874 5.94136 13.2874C6.09902 13.2874 6.30923 13.2874 6.46689 13.2874C6.7822 13.2874 6.93986 13.4977 6.93986 13.7604C6.93986 14.2859 6.93986 14.7589 6.93986 15.2844C6.93986 15.337 6.93986 15.3895 6.93986 15.4946Z"
          stroke="#0DB53C"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="7.78991" cy="9.33009" r="0.729975" fill="#0DB53C" />
        <circle cx="10.7499" cy="9.33009" r="0.729975" fill="#0DB53C" />
        <circle cx="13.7098" cy="9.33009" r="0.729975" fill="#0DB53C" />
      </svg>
    ),
    action: "Approve",
  },
  {
    img: (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.15"
          d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
          fill="#FB7158"
        />
        <path
          d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
          stroke="#FB7158"
          strokeWidth="0.698594"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    action: "Hold",
  },
  {
    img: (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7579 0.56897L0.757935 12.569"
          stroke="#F71D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.757935 0.56897L12.7579 12.569"
          stroke="#F71D1D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),

    action: "Reject",
  },
];

const ApprovalAppraisals = ({ getApprovalBadge }) => {
  useEffect(() => {
    document.title = `PeopleSol - Appraisals`;
    return () => {
      document.title = "PeopleSol";
    };
  });

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="servicesContentHolder">
      <div className="moduleMainContainer">
        <div className="tabContainer" style={{ width: "100%" }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#1296B0" } }}
          >
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                  Pending
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                  Approved
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                  Discuss
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                  Rejected
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                  Cancelled
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 5 && "tabActive"}`}>
                  All
                </span>
              }
            />
          </Tabs>
          <div className="tabContainerLine"></div>
        </div>
        {tabIndex === 0 && (
          <>
            <Table
              // selectAll={() => selectAll()}
              tableFilterName="bandTableFilter"
              // addToSelectedRows={(Id) => addToSelectedRows(Id)}
              //  selectedRows={selectedRows}
              tbodyData={tempData}
              actionData={actionData}
            />
          </>
        )}
        {tabIndex === 1 && (
          <>
            <Table
              // selectAll={() => selectAll()}
              tableFilterName="bandTableFilter"
              // addToSelectedRows={(Id) => addToSelectedRows(Id)}
              //  selectedRows={selectedRows}
              tbodyData={tempData}
              actionData={actionData}
            />
          </>
        )}
        {tabIndex === 2 && (
          <>
            <Table
              // selectAll={() => selectAll()}
              tableFilterName="bandTableFilter"
              // addToSelectedRows={(Id) => addToSelectedRows(Id)}
              //  selectedRows={selectedRows}
              tbodyData={tempData}
              actionData={actionData}
            />
          </>
        )}
        {tabIndex === 3 && (
          <>
            <Table
              // selectAll={() => selectAll()}
              tableFilterName="bandTableFilter"
              // addToSelectedRows={(Id) => addToSelectedRows(Id)}
              //  selectedRows={selectedRows}
              tbodyData={tempData}
              actionData={actionData}
            />
          </>
        )}
        {tabIndex === 4 && (
          <>
            <Table
              // selectAll={() => selectAll()}
              tableFilterName="bandTableFilter"
              // addToSelectedRows={(Id) => addToSelectedRows(Id)}
              //  selectedRows={selectedRows}
              tbodyData={tempData}
              actionData={actionData}
            />
          </>
        )}
        {tabIndex === 5 && (
          <>
            <Table
              // selectAll={() => selectAll()}
              tableFilterName="bandTableFilter"
              // addToSelectedRows={(Id) => addToSelectedRows(Id)}
              //  selectedRows={selectedRows}
              tbodyData={tempData}
              actionData={actionData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export { ApprovalAppraisals };
