import React, { useEffect, useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";

export const CreateCompetencySetComp = ({ formValues }) => {
  return (
    <div className=" ">
      <div>
        <LabelCustom labelName={" Name"} mandatory={true} />
        <InputCustomFormik
          name="Name"
          type={"text"}
          placeholder={"Please Enter Place"}
        />
      </div>
    </div>
  );
};
