import React, { useEffect, useRef, useState } from "react";
import { ChatMessage } from "./Components/ChatMessage";
import { useSelector } from "react-redux";

export const scrollFunc = (replyId) => {
  const element = document.getElementById("content" + replyId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    const targetDiv = element.querySelector(".message-content");
    if (targetDiv) {
      targetDiv.style.position = "relative";
      targetDiv.classList.add("translucent-overlay");
      setTimeout(() => {
        targetDiv.classList.remove("translucent-overlay");
      }, 1100);
    }
  }
};
const ChatInterface = ({
  deleteMessage,
  setReplyData,
  LoggedInUser,
  setForwardData,
  forwardData,
  replyData,
  togglePopup,
  handleContactClick,
  ViewFullscreen,
  activeChat,
  bookMarkMessage,
  setSelectedMessage,
  handleReplyPrivately,
  handleReschedule,
  scrollToId,
  SetActiveChat,
  newSocket,
  viewGroup,
  handleStoryFromChat
}) => {
  const sortedMessageList = useSelector(
    (state) => state.chat?.activeMessageList
  );
  const chatList = useSelector((state) => state.chat.conversationList);

  const messageRefs = useRef({});
  useEffect(() => {
    if (scrollToId) {
      scrollToMessage(scrollToId);
    } else if (sortedMessageList?.length) {
      const lastMessageId = sortedMessageList[sortedMessageList.length - 1]?._id; // Get the last message's id
      if (lastMessageId && messageRefs.current[lastMessageId]) {
        messageRefs.current[lastMessageId].scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [sortedMessageList, activeChat]);

  const scrollToMessage = (messageId) => {
    if (messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  };


  return (
    <div>
      {sortedMessageList?.map((item, index) => {
        let header = null;

        return (
          <div onClick={() => {
            if (item?.replyMsgObj?._id) {
              // handleContactClick(item.re)if

              if (item?.replyMsgObj.conversationId != activeChat._id) {
                handleContactClick(item.replyMsgObj.conversationId);
                SetActiveChat(chatList.filter(val => val._id === item.replyMsgObj.conversationId)[0])
                setTimeout(() => {
                  scrollToMessage(item?.replyMsgObj?._id);
                }, 500)

              } else {
                scrollToMessage(item?.replyMsgObj?._id); // Scroll to the replied message
              }
            }
          }} ref={(el) => (messageRefs.current[item._id] = el)} key={item?._id}>
            {header && (
              <div className="message-date-header">
                <div>{header}</div>
              </div>
            )}
            <div
              id={`content${item?.id}`}
              style={
                item.type === 6 ? {
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                } :
                  forwardData?.isActive &&
                    forwardData?.msgId?.includes(item?.id)
                    ? {
                      padding: "0px 12px",
                      backgroundColor: "rgb(0 0 0 / 5%)",
                    }
                    :
                    {
                      padding: "0px 12px",
                    }
              }
            >
              <ChatMessage
                handleStoryFromChat={handleStoryFromChat}
                ViewFullscreen={ViewFullscreen}
                deleteMessage={deleteMessage}
                handleContactClick={handleContactClick}
                togglePopup={togglePopup}
                replyData={replyData}
                forwardData={forwardData}
                setForwardData={setForwardData}
                LoggedInUser={LoggedInUser}
                setReplyData={setReplyData}
                key={item?.id}
                message={item}
                newSocket={newSocket}
                viewGroup={viewGroup}
                setSelectedMessage={setSelectedMessage}
                bookMarkMessage={bookMarkMessage}
                activeChat={activeChat}
                handleReschedule={handleReschedule}
                handleReplyPrivately={handleReplyPrivately}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatInterface;
