import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../Services/axios";
import { TeamsFormIt } from "./Form/TeamsFormIt";
import { teamsSchemaIT } from "./Schema";
import { useEffect } from "react";

const CreateTeamsIt = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create IT Teams`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();

  const data = state?.id;

  const dispatch = useDispatch();
  const [detail, setDetail] = React.useState([]);

  const getDetails = async () => {
    try {
      const result = await getRequest(`ITHelpdeskTeams/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInitalAnswerValues = () => {
    let returnArray = [];

    data
      ? detail?.itsmHelpdeskTeammembers?.map((item, index) => {
          let loctionarray = [];
          item?.itsmHelpdeskTeammemberlocations?.map((it) => {
            return loctionarray.push(it.locationId);
          });
          return returnArray.push({
            employeeId: item.employeeId,
            level: item.level,
            locationIds: loctionarray,
          });
        })
      : (returnArray = [{ employeeId: "", level: "", locationIds: [] }]);

    return returnArray;
  };
  const initialvalue = {
    name: "",
    itsmHelpdeskTeammembers: getInitalAnswerValues(),
  };
  const postFormData = async (values) => {
    try {
      const result = await postRequest("ITHelpdeskTeams/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("ITHelpdeskTeams/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const onSubmit = async (values) => {
    const edit = {
      ...values,
      id: detail?.id,
    };

    const create = {
      id: 0,
      ...values,
    };

    data ? Edit(edit) : postFormData(create);

    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/itsmmaster/itdesk/teams");
  };

  return (
    <FormCustomFormik
      ButtonLabel={data ? "Update Teams" : "Create Teams"}
      FormHeading={data ? "Edit Teams" : "Create Teams"}
      validationSchema={teamsSchemaIT}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detail?.name,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<TeamsFormIt data={detail} editID={data ? true : false} />}
    />
  );
};

export { CreateTeamsIt };
