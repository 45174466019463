import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { TableBody, TableHead, TableRow, Table, Tooltip } from "@mui/material";
import ReimbursementformTypes from "./FormComp/ReimbursementformTypes";
import ReimbursementformTypesMulti from "./FormComp/ReimbursementformTypesMulti";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { StyledTableCell } from "../../../Components/FormComponent/Repeated";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { Loader } from "../../../Components/FormComponent/Loader";
const ManualEntry = ({ data, type, id, color, handleClose, listid }) => {
  const [detailsform, setDetailsform] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [formValues, setFormValues] = useState(false);
  const [isLoadingManualEntry, setisLoadingManualEntry] = useState(false);
  const [advanceRequestsList, setAdvanceRequestsList] = useState([]);
  const [claimCount, setClaimCount] = useState(0);
  const expenseType = [
    { label: "Taxi", value: "Taxi" },
    { label: "Self Vehicle", value: "Self Vehicle" },
    { label: "Food", value: "Food" },
    { label: "Gift", value: "Gift" },
    { label: "Gadget", value: "Gadget" },
    { label: "Metro", value: "Metro" },
    { label: "Local Train", value: "Local Train" },
    { label: "Local Bus", value: "Local Bus" },
  ];
  const getFormDetails = async (id) => {
    try {
      const result = await getRequest(`Expenses/Details/${listid}`);
      setDetailsform(result?.data?.data);

      if (result?.data?.data?.travalRequestId)
        getTravelDetails(result?.data?.data?.travalRequestId);
    } catch (error) {}
  };
  const getadvanceRequests = async () => {
    try {
      const result = await getRequest("TravelHospitalityRequests/GettravelReq");
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setAdvanceRequestsList(listData);
    } catch (error) {
    } finally {
    }
  };
  const getTravelDetails = async (id) => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${id}`
      );
      setResultData(result?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    setInitialValues({
      totalclaimed: detailsform?.totalclaimed,
      TravalRequestId: detailsform?.travalRequestId,
      Expenselist: groupItemsDetailsfunc(),
      groupItems: groupItemsMultiDetailsfunc(),
      groupItemsHotel: groupItemsMultiHotelDetailsfunc(),
    });
  }, [detailsform, resultData]);
  useEffect(() => {
    getFormDetails(listid);
    getadvanceRequests();
  }, []);

  const groupItemsMultiDetailsfunc = () => {
    let returnArray = [];
    resultData?.travellingdetails?.map((it, index) => {
      return returnArray.push({
        FromDate: it?.fromDate ? new Date(it?.fromDate) : null,
        PickupLocation: it?.pickupLocation,
        DropLocation: it?.dropLocation,
        Receipt: it?.finalTicket,
        TravalId: it?.id,
        Expenselist: detailsform?.expenseDetails
          ?.filter((itz) => itz?.travellingdetailsid === it?.id)
          ?.map((item, index) => {
            return {
              id: item?.id ?? null,
              ExpenseTypes: item?.expenseTypes,
              Date: new Date(item?.date),
              fromlocation: item?.fromlocation,
              tolocation: item?.tolocation,
              categoryid: item?.travellingCategoriesDetails?.category,
              type: item?.travellingCategoriesDetails?.type,
              totalkm: item?.totalkm,
              cost: item?.cost,
              Amount: item?.amount,
              IsReceiptAvailable: item?.isReceiptAvailable,
              Upload: item?.attachment,
              Justification: item?.justification,
              Remarks: item?.remarks,
              // mode: item?.mode,
              totaltickets: item?.totaltickets,
              totalitems: item?.totalitems,
              totaldays: item?.totaldays,
            };
          }),
      });
    });
    return returnArray;
  };
  const groupItemsMultiHotelDetailsfunc = () => {
    let returnArray = [];
    resultData?.hoteldetail?.map((it, index) => {
      return returnArray.push({
        HotelcheckInTime: it?.checkInTime ? new Date(it?.checkInTime) : null,
        HotelcheckOutTime: it?.checkOutTime ? new Date(it?.checkOutTime) : null,
        Hotellocation: it?.location ?? "",
        HotelpreferHotel: it?.preferHotel ?? "",
        Receipt: it?.finalTicket,
        TravalId: it?.id,
        Expenselist: detailsform?.expenseDetails
          ?.filter((itz) => itz?.travellingdetailsid === it?.id)
          ?.map((item, index) => {
            return {
              id: item?.id ?? null,
              ExpenseTypes: item?.expenseTypes,
              Date: new Date(item?.date),
              fromlocation: item?.fromlocation,
              tolocation: item?.tolocation,
              categoryid: item?.travellingCategoriesDetails?.category,
              type: item?.travellingCategoriesDetails?.type,
              totalkm: item?.totalkm,
              cost: item?.cost,
              Amount: item?.amount,
              IsReceiptAvailable: item?.isReceiptAvailable,
              Upload: item?.attachment,
              Justification: item?.justification,
              Remarks: item?.remarks,
              // mode: item?.mode,
              totaltickets: item?.totaltickets,
              totalitems: item?.totalitems,
              totaldays: item?.totaldays,
            };
          }),
      });
    });

    return returnArray;
  };
  const groupItemsDetailsfunc = () => {
    let returnArray = [];
    // detailsform
    //   ?
    detailsform?.expenseDetails?.map((item, index) => {
      return returnArray.push({
        // id: item?.id ?? null,
        ExpenseTypes: item?.expenseTypes,
        Date: new Date(item?.date),
        fromlocation: item?.fromlocation,
        tolocation: item?.tolocation,
        categoryid: item?.travellingCategoriesDetails?.category,
        type: item?.travellingCategoriesDetails?.type,
        totalkm: item?.totalkm,
        cost: item?.cost,
        Amount: item?.amount,
        IsReceiptAvailable: item?.isReceiptAvailable,
        Upload: item?.attachment,
        Justification: item?.justification,
        Remarks: item?.remarks,
        // mode: item?.mode,
        totaltickets: item?.totaltickets,
        totalitems: item?.totalitems,
        totaldays: item?.totaldays,
      });
    });
    //   : (returnArray = [
    //       {
    //         ExpenseTypes: "",
    //         Date: "",
    //         fromlocation: "",
    //         tolocation: "",
    //         categoryid: "",
    //         type: "",
    //         totalkm: "",
    //         cost: "",
    //         Amount: "",
    //         IsReceiptAvailable: "",
    //         Upload: "",
    //         Justification: "",
    //         Remarks: "",
    //         mode: "",
    //         totaltickets: "",
    //         totalitems: "",
    //         totaldays: "",
    //       },
    //     ]);
    return returnArray;
  };
  useEffect(() => {
    if (formValues?.TravalRequestId) {
      let count = 0;
      formValues?.groupItems?.forEach((it) => {
        it?.Expenselist?.forEach((item) => {
          count += item?.Amount;
        });
      });
      formValues?.groupItemsHotel?.forEach((it) => {
        it?.Expenselist?.forEach((item) => {
          count += item?.Amount;
        });
      });
      setClaimCount(count);
    } else {
      let count = 0;
      formValues?.Expenselist?.forEach((item) => {
        count += item?.Amount;
      });
      setClaimCount(count);
    }
  }, [
    formValues?.Expenselist,
    formValues?.groupItems,
    formValues?.groupItemsHotel,
  ]);

  const [initialValues, setInitialValues] = useState({
    totalclaimed: "",
    TravalRequestId: null,
    Expenselist: groupItemsDetailsfunc(),
    groupItems: [],
    groupItemsHotel: [],
  });
  console.log("object formValues", formValues);
  console.log("object initialValues", initialValues);
  const validationSchema = Yup.object({
    Remarks: Yup.string()
      .max(499, "Remarks cannot be more than 500 characters")
      .required("Please Enter Remarks"),
    Amount: Yup.string().required("Please Enter Remarks"),
  });
  const validationSchemaTwo = Yup.object({
    Remarks: Yup.string()
      .max(499, "Remarks cannot be more than 500 characters")
      .required("Please Enter Remarks"),
  });
  const onSubmit = async (values) => {
    setisLoadingManualEntry(true);

    data(type, id, values.Remarks, listid, values.Amount);
    setisLoadingManualEntry(false);
  };
  const onSubmitOne = async (values) => {
    setisLoadingManualEntry(true);

    data(type, id, values.Remarks, listid, values.Amount);
    setisLoadingManualEntry(false);
  };
  const onSubmitTwo = async (values) => {
    setisLoadingManualEntry(true);

    data(type, id, values.Remarks);
    setisLoadingManualEntry(false);
  };
  const submitUpdateReciept = async (reqData) => {
    try {
      setisLoadingManualEntry(true);
      const result = await postRequestForm("Expenses/Update_reciept", reqData);
      handleClose();
    } catch (error) {
    } finally {
      setisLoadingManualEntry(false);
    }
  };
  const makeReqdata = async () => {
    const formData = new FormData();
    formData.append(`Id`, detailsform?.id);
    if (!formValues?.TravalRequestId) {
      formValues?.Expenselist?.forEach((item, index) => {
        formData.append(
          `Expenselist[${index}].Id`,
          // detailsform?.expenseDetails[index]?.Id
          item?.id
        );

        formData.append(
          `Expenselist[${index}].IsReceiptAvailable`,
          item?.IsReceiptAvailable
        );
        formData.append(`Expenselist[${index}].Upload`, item?.Upload);
        formData.append(
          `Expenselist[${index}].Justification`,
          item?.Justification
        );
      });
    }
    if (formValues?.TravalRequestId) {
      let num = 0;

      formValues?.groupItems?.forEach((itemz, indexz) => {
        itemz?.Expenselist?.forEach((item, index) => {
          formData.append(`Expenselist[${num}].Id`, item?.id);
          formData.append(
            `Expenselist[${num}].IsReceiptAvailable`,
            item?.IsReceiptAvailable
          );
          formData.append(`Expenselist[${num}].Upload`, item?.Upload);
          formData.append(
            `Expenselist[${num}].Justification`,
            item?.Justification
          );

          num++;
        });
      });
    }

    submitUpdateReciept(formData);
  };
  const la = () => {};
  if (isLoadingManualEntry) {
    return <Loader />;
  } else
    return (
      <>
        <div style={{ width: "1100px", padding: "12px 40px" }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            // validationSchema={validationSchema}
            onSubmit={la}
          >
            {(formik) => {
              setFormValues(formik.values);
              return (
                <Form>
                  <>
                    <div
                      className="formcustom twoColumn"
                      style={{
                        borderBottom: "1px solid rgb(230, 230, 230)",
                        padding: "10px 0px",
                      }}
                    >
                      <div>
                        <LabelCustom labelName={"Request ID"} />
                        <SelectForm
                          disabled={true}
                          name={"TravalRequestId"}
                          options={advanceRequestsList}
                          placeholder={"Select Request ID"}
                        />
                      </div>
                    </div>
                    <div>
                      {formValues && !formValues?.TravalRequestId && (
                        <div
                          className="ReimbursementTable"
                          style={{
                            width: "100%",
                            overflowX: "auto",
                            overflowY: "hidden",
                            paddingTop: "12px",
                          }}
                        >
                          <FieldArray name="Expenselist">
                            {(fieldArrayProps) => {
                              const { push, remove, insert, form } =
                                fieldArrayProps;
                              const { values } = form;
                              const { Expenselist } = values;
                              return (
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                        sx={{ width: "70px" }}
                                      >
                                        <LabelCustom labelName={"S. No"} />
                                      </StyledTableCell>
                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="left"
                                      >
                                        <LabelCustom
                                          labelName={"Add Details"}
                                        />
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  {Expenselist?.map((Expenselists, index) => (
                                    <TableBody key={index}>
                                      {/* 1st column */}
                                      <StyledTableCell
                                        sx={{ width: "70px" }}
                                        align="center"
                                      >
                                        {index + 1}
                                      </StyledTableCell>
                                      {/* 2nd column */}
                                      <StyledTableCell
                                        sx={{ maxWidth: "920px" }}
                                      >
                                        <div
                                          style={{
                                            overflow: "auto",
                                            width: "100%",
                                            // height: "197px",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              borderRight: "1px solid #E4E3E3",
                                              padding: "18px 0px",
                                            }}
                                          >
                                            <LabelCustom
                                              labelName={"Expense Type"}
                                              mandatory={true}
                                            />
                                            <InputCustomFormik
                                              disabled={true}
                                              name={`Expenselist[${index}].ExpenseTypes`}
                                              placeholder={"Enter"}
                                            />
                                            {/* <SelectForm
                                        disabled={true}
                                        options={expenseType}
                                        name={`Expenselist[${index}].ExpenseTypes`}
                                        placeholder={"Select"}
                                      /> */}
                                          </div>
                                          <div
                                            style={{
                                              // width: "calc( 100% - 475px )",
                                              overflow: "auto",
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                            // style={{ minWidth: "778px", maxWidth: "100%" }}
                                            >
                                              <div
                                                className="reimbursementTablein"
                                                style={{
                                                  // minWidth: "1152px",
                                                  padding: "18px 0px",
                                                  position: "relative",
                                                }}
                                              >
                                                <ReimbursementformTypes
                                                  typeUpdtReceipt={
                                                    type === "Update Reciept"
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={true}
                                                  ExpenseTypesSelected={
                                                    formValues?.Expenselist &&
                                                    formValues?.Expenselist[
                                                      index
                                                    ]?.ExpenseTypes
                                                  }
                                                  // employeeList={employeeList}
                                                  index={index}
                                                  formValues={formValues}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </StyledTableCell>
                                      {/* 8th column */}
                                    </TableBody>
                                  ))}
                                </Table>
                              );
                            }}
                          </FieldArray>
                        </div>
                      )}
                      {formValues?.TravalRequestId &&
                        formValues?.groupItems?.length > 0 && (
                          <div
                            className="ReimbursementTable"
                            style={{
                              width: "100%",
                              overflowX: "auto",
                              overflowY: "hidden",
                              paddingTop: "12px",
                            }}
                          >
                            <FieldArray name="groupItems">
                              {(fieldArrayProps) => {
                                const { remove, insert, form } =
                                  fieldArrayProps;
                                const { values } = form;
                                const { groupItems } = values;
                                return (
                                  <Table style={{ width: "100%" }}>
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom labelName={"S. No."} />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Add Details"}
                                          />
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    {groupItems?.map((Questions, index) => (
                                      <TableBody key={index}>
                                        <StyledTableCell
                                          sx={{ width: "96px" }}
                                          align="center"
                                        >
                                          {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell
                                          sx={{ maxWidth: "920px" }}
                                        >
                                          <div>
                                            <div
                                              style={{
                                                borderBottom:
                                                  "1px solid #E6E6E6",
                                              }}
                                              className="formcustom fourColumn"
                                            >
                                              <div>
                                                <LabelCustom
                                                  labelName={"Travelling Date"}
                                                  mandatory={true}
                                                />
                                                <DatePickerFormiks
                                                  disabled={true}
                                                  name={`groupItems[${index}].FromDate`}
                                                  minDate={new Date()}
                                                />
                                              </div>
                                              <div>
                                                <LabelCustom
                                                  labelName={"Form Location"}
                                                  mandatory={true}
                                                />

                                                <InputCustomFormik
                                                  disabled={true}
                                                  maxLength={50}
                                                  name={`groupItems[${index}].PickupLocation`}
                                                  type="text"
                                                  placeholder={
                                                    " Enter From Location"
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <LabelCustom
                                                  labelName={"To Location"}
                                                  mandatory={true}
                                                />

                                                <InputCustomFormik
                                                  disabled={true}
                                                  maxLength={50}
                                                  name={`groupItems[${index}].DropLocation`}
                                                  type={"text"}
                                                  placeholder={
                                                    " Enter To Location"
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <LabelCustom
                                                  labelName={"Receipt"}
                                                />
                                                <FileUploadFormik
                                                  disabled={true}
                                                  name={`groupItems[${index}].Receipt`}
                                                />
                                                <span
                                                  style={{
                                                    paddingLeft: "12px",
                                                    color: "var(--secondary)",
                                                  }}
                                                >
                                                  Size: 2 MB
                                                </span>
                                                <span
                                                  style={{
                                                    paddingLeft: "12px",
                                                    color: "var(--secondary)",
                                                  }}
                                                >
                                                  Format: JPG, PNG, PDF , Doc
                                                  ,Docx
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                maxHeight: "300px",
                                                overflowY: "auto",
                                              }}
                                            >
                                              {Questions?.Expenselist?.map(
                                                (item, indexW) => (
                                                  <div
                                                    style={{
                                                      overflow: "auto",
                                                      width: "100%",
                                                      // height: "197px",
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        borderRight:
                                                          "1px solid #E4E3E3",
                                                        borderBottom:
                                                          "1px solid #E4E3E3",
                                                        padding: "18px 0px",
                                                        height: "221.6px ",
                                                      }}
                                                    >
                                                      <LabelCustom
                                                        labelName={
                                                          "Expense Type"
                                                        }
                                                        mandatory={true}
                                                      />
                                                      <SelectForm
                                                        disabled={true}
                                                        options={expenseType}
                                                        name={`groupItems[${index}].Expenselist[${indexW}].ExpenseTypes`}
                                                        placeholder={"Select "}
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        // width: "calc( 100% - 475px )",
                                                        overflow: "auto",
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <div
                                                      // style={{ minWidth: "778px", maxWidth: "100%" }}
                                                      >
                                                        <div
                                                          className="reimbursementTablein"
                                                          style={{
                                                            // minWidth: "1152px",
                                                            padding: "18px 0px",
                                                            position:
                                                              "relative",
                                                          }}
                                                        >
                                                          <ReimbursementformTypesMulti
                                                            typeUpdtReceipt={
                                                              type ===
                                                              "Update Reciept"
                                                                ? true
                                                                : false
                                                            }
                                                            disabled={true}
                                                            ExpenseTypesSelected={
                                                              formValues
                                                                ?.groupItems[
                                                                index
                                                              ]?.Expenselist[
                                                                indexW
                                                              ]?.ExpenseTypes
                                                            }
                                                            // employeeList={employeeList}
                                                            groupItems={
                                                              "groupItems"
                                                            }
                                                            index={index}
                                                            indexW={indexW}
                                                            formValues={
                                                              formValues
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </StyledTableCell>
                                      </TableBody>
                                    ))}
                                  </Table>
                                );
                              }}
                            </FieldArray>
                          </div>
                        )}
                      {formValues?.TravalRequestId &&
                        formValues?.groupItemsHotel?.length > 0 && (
                          <div
                            className="ReimbursementTable"
                            style={{
                              width: "100%",
                              overflowX: "auto",
                              overflowY: "hidden",
                              paddingTop: "12px",
                            }}
                          >
                            <FieldArray name="groupItemsHotel">
                              {(fieldArrayProps) => {
                                const { remove, insert, form } =
                                  fieldArrayProps;
                                const { values } = form;
                                const { groupItemsHotel } = values;
                                return (
                                  <Table style={{ width: "100%" }}>
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom labelName={"S. No."} />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Add Details"}
                                          />
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    {groupItemsHotel?.map(
                                      (Questions, index) => (
                                        <TableBody key={index}>
                                          <StyledTableCell
                                            sx={{ width: "96px" }}
                                            align="center"
                                          >
                                            {index + 1}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            sx={{ maxWidth: "920px" }}
                                          >
                                            <div>
                                              <div
                                                style={{
                                                  borderBottom:
                                                    "1px solid #E6E6E6",
                                                }}
                                                className="formcustom fiveColumn"
                                              >
                                                <div>
                                                  <LabelCustom
                                                    labelName={
                                                      "Hotel Check In Time"
                                                    }
                                                    mandatory={true}
                                                  />
                                                  <DatePickerFormiks
                                                    disabled={true}
                                                    showTimeSelect
                                                    name={`groupItemsHotel[${index}].HotelcheckInTime`}
                                                    minDate={new Date()}
                                                  />
                                                </div>
                                                <div>
                                                  <LabelCustom
                                                    labelName={
                                                      "Hotel Check Out Time"
                                                    }
                                                    mandatory={true}
                                                  />
                                                  <DatePickerFormiks
                                                    disabled={true}
                                                    showTimeSelect
                                                    name={`groupItemsHotel[${index}].HotelcheckOutTime`}
                                                    minDate={new Date()}
                                                  />
                                                </div>
                                                <div>
                                                  <LabelCustom
                                                    labelName={"Hotel Location"}
                                                    mandatory={true}
                                                  />

                                                  <InputCustomFormik
                                                    disabled={true}
                                                    maxLength={50}
                                                    name={`groupItemsHotel[${index}].Hotellocation`}
                                                    type="text"
                                                    placeholder={" Enter "}
                                                  />
                                                </div>
                                                <div>
                                                  <LabelCustom
                                                    labelName={
                                                      "Preferred Hotel"
                                                    }
                                                    mandatory={true}
                                                  />

                                                  <InputCustomFormik
                                                    disabled={true}
                                                    maxLength={50}
                                                    name={`groupItemsHotel[${index}].HotelpreferHotel`}
                                                    type={"text"}
                                                    placeholder={" Enter"}
                                                  />
                                                </div>
                                                <div>
                                                  <LabelCustom
                                                    labelName={"Receipt"}
                                                  />
                                                  <FileUploadFormik
                                                    disabled={true}
                                                    name={`groupItemsHotel[${index}].Receipt`}
                                                  />
                                                  <span
                                                    style={{
                                                      paddingLeft: "12px",
                                                      color: "var(--secondary)",
                                                    }}
                                                  >
                                                    Size: 2 MB
                                                  </span>
                                                  <span
                                                    style={{
                                                      paddingLeft: "12px",
                                                      color: "var(--secondary)",
                                                    }}
                                                  >
                                                    Format: JPG, PNG, PDF , Doc
                                                    ,Docx
                                                  </span>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  maxHeight: "300px",
                                                  overflowY: "auto",
                                                }}
                                              >
                                                {Questions?.Expenselist?.map(
                                                  (item, indexW) => (
                                                    <div
                                                      style={{
                                                        overflow: "auto",
                                                        width: "100%",
                                                        // height: "197px",
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          borderRight:
                                                            "1px solid #E4E3E3",
                                                          borderBottom:
                                                            "1px solid #E4E3E3",
                                                          padding: "18px 0px",
                                                          height: "221.6px ",
                                                        }}
                                                      >
                                                        <LabelCustom
                                                          labelName={
                                                            "Expense Type"
                                                          }
                                                          mandatory={true}
                                                        />
                                                        <SelectForm
                                                          disabled={true}
                                                          options={expenseType}
                                                          name={`groupItemsHotel[${index}].Expenselist[${indexW}].ExpenseTypes`}
                                                          placeholder={
                                                            "Select "
                                                          }
                                                        />
                                                      </div>
                                                      <div
                                                        style={{
                                                          // width: "calc( 100% - 475px )",
                                                          overflow: "auto",
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <div
                                                        // style={{ minWidth: "778px", maxWidth: "100%" }}
                                                        >
                                                          <div
                                                            className="reimbursementTablein"
                                                            style={{
                                                              // minWidth: "1152px",
                                                              padding:
                                                                "18px 0px",
                                                              position:
                                                                "relative",
                                                            }}
                                                          >
                                                            <ReimbursementformTypesMulti
                                                              typeUpdtReceipt={
                                                                type ===
                                                                "Update Reciept"
                                                                  ? true
                                                                  : false
                                                              }
                                                              disabled={true}
                                                              ExpenseTypesSelected={
                                                                formValues
                                                                  ?.groupItemsHotel[
                                                                  index
                                                                ]?.Expenselist[
                                                                  indexW
                                                                ]?.ExpenseTypes
                                                              }
                                                              // employeeList={employeeList}
                                                              groupItems={
                                                                "groupItemsHotel"
                                                              }
                                                              index={index}
                                                              indexW={indexW}
                                                              formValues={
                                                                formValues
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </StyledTableCell>
                                        </TableBody>
                                      )
                                    )}
                                  </Table>
                                );
                              }}
                            </FieldArray>
                          </div>
                        )}
                    </div>
                    <div
                      style={
                        formValues?.TravalRequestId
                          ? {
                              padding: "28px 0px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }
                          : {
                              padding: "12px 0px",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }
                      }
                    >
                      {formValues?.TravalRequestId && (
                        <div>
                          <LabelCustom
                            labelName={"Total Amount"}
                            mandatory={true}
                          />

                          <InputCustomFormik
                            disabled={true}
                            value={
                              (Number(resultData?.total_final_budget) || 0) +
                              (Number(claimCount) || 0)
                            }
                            type="number"
                            name={`amountTotal`}
                            placeholder={"Enter"}
                          />
                        </div>
                      )}
                      {formValues?.TravalRequestId && (
                        <div style={{ marginTop: "42px" }}> - </div>
                      )}
                      {formValues?.TravalRequestId && (
                        <div>
                          <LabelCustom
                            labelName={"Paid by company"}
                            mandatory={true}
                          />

                          <InputCustomFormik
                            disabled={true}
                            value={Number(resultData?.total_final_budget) || 0}
                            type="number"
                            name={`paidByCompany`}
                            placeholder={"Enter"}
                          />
                        </div>
                      )}
                      {formValues?.TravalRequestId && (
                        <div style={{ marginTop: "42px" }}> - </div>
                      )}
                      {formValues?.TravalRequestId && (
                        <div>
                          <LabelCustom
                            labelName={"Advance Amount"}
                            mandatory={true}
                          />

                          <InputCustomFormik
                            disabled={true}
                            type="number"
                            name={`AmountAdvanceAdvance`}
                            placeholder={"Enter"}
                          />
                        </div>
                      )}
                      {formValues?.TravalRequestId && (
                        <div style={{ marginTop: "42px" }}> = </div>
                      )}
                      <div>
                        <LabelCustom
                          labelName={"Claim Amount"}
                          mandatory={true}
                        />

                        <InputCustomFormik
                          disabled={true}
                          value={claimCount}
                          type="number"
                          name={`claimAmountFinal`}
                          placeholder={"Enter"}
                        />
                      </div>
                    </div>
                  </>
                </Form>
              );
            }}
          </Formik>

          <Formik
            enableReinitialize={true}
            initialValues={{ Amount: "", Remarks: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <>
                <div
                  style={{
                    borderBottom: "1px solid rgb(230, 230, 230)",
                    padding: "10px 0px",
                    width: "100%",
                  }}
                >
                  <label className="repeatedHeading">
                    Report Manager Review
                  </label>
                </div>
                <div className="formcustom threeColumn">
                  <div>
                    <LabelCustom
                      labelName={"Updated Claim Amount"}
                      mandatory={true}
                    />
                    {type !== "Manual Entry" && (
                      <InputCustomFormik
                        disabled={true}
                        value={Number(detailsform?.amountrm) || 0}
                        type="number"
                        name={`Amount`}
                        placeholder={"Enter"}
                      />
                    )}
                    {type === "Manual Entry" && (
                      <InputCustomFormik
                        type="number"
                        name={`Amount`}
                        placeholder={"Enter"}
                      />
                    )}
                  </div>{" "}
                  <div>
                    <LabelCustom labelName={"Remarks"} mandatory={true} />
                    {type !== "Manual Entry" && (
                      <TextAreaFormik
                        disabled={true}
                        value={detailsform?.remarksrm}
                        name={`Remarks`}
                        placeholder={"Enter Remarks"}
                      />
                    )}
                    {type === "Manual Entry" && (
                      <TextAreaFormik
                        name={`Remarks`}
                        placeholder={"Enter Remarks"}
                      />
                    )}
                  </div>
                </div>
                {type === "Manual Entry" && (
                  <div className="createFootCust">
                    <button
                      type="button"
                      className="button secondaryButton"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="button primaryButton"
                      type="submit"
                      style={{ color: "white" }}
                    >
                      Update
                    </button>
                  </div>
                )}
              </>
            </Form>
          </Formik>

          {(type === "Manual Entry By HOD" ||
            type === "Resubmit Receipt" ||
            type === "Update Reciept") && (
            <Formik
              enableReinitialize={true}
              initialValues={{ Amount: "", Remarks: "" }}
              validationSchema={validationSchema}
              onSubmit={onSubmitOne}
            >
              <Form>
                <>
                  <div
                    style={{
                      borderBottom: "1px solid rgb(230, 230, 230)",
                      padding: "10px 0px",
                      width: "100%",
                    }}
                  >
                    <label className="repeatedHeading">
                      Functional HOD Review
                    </label>
                  </div>
                  <div className="formcustom threeColumn">
                    <div>
                      <LabelCustom
                        labelName={"Updated Claim Amount"}
                        mandatory={true}
                      />

                      {type === "Manual Entry By HOD" && (
                        <InputCustomFormik
                          type="number"
                          name={`Amount`}
                          placeholder={"Enter"}
                        />
                      )}
                      {type !== "Manual Entry By HOD" && (
                        <InputCustomFormik
                          disabled={true}
                          value={Number(detailsform?.amounthod) || 0}
                          type="number"
                          name={`Amount`}
                          placeholder={"Enter"}
                        />
                      )}
                    </div>{" "}
                    <div>
                      <LabelCustom labelName={"Remarks"} mandatory={true} />
                      {type === "Manual Entry By HOD" && (
                        <TextAreaFormik
                          name={`Remarks`}
                          placeholder={"Enter Remarks"}
                        />
                      )}
                      {type !== "Manual Entry By HOD" && (
                        <TextAreaFormik
                          disabled={true}
                          value={detailsform?.remarkshod}
                          name={`Remarks`}
                          placeholder={"Enter Remarks"}
                        />
                      )}
                    </div>
                  </div>
                  {type === "Manual Entry By HOD" && (
                    <div className="createFootCust">
                      <button
                        type="button"
                        className="button secondaryButton"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="button primaryButton"
                        type="submit"
                        style={{ color: "white" }}
                      >
                        Update
                      </button>
                    </div>
                  )}
                </>
              </Form>
            </Formik>
          )}
          {(type === "Resubmit Receipt" || type === "Update Reciept") && (
            <Formik
              enableReinitialize={true}
              initialValues={{ Remarks: "" }}
              validationSchema={validationSchemaTwo}
              onSubmit={onSubmitTwo}
            >
              <Form>
                <>
                  <div
                    style={{
                      borderBottom: "1px solid rgb(230, 230, 230)",
                      padding: "10px 0px",
                      width: "100%",
                    }}
                  >
                    <label className="repeatedHeading">Audit Team Review</label>
                  </div>
                  <div className="formcustom threeColumn">
                    <div>
                      <LabelCustom labelName={"Remarks"} mandatory={true} />

                      {type === "Resubmit Receipt" && (
                        <TextAreaFormik
                          name={`Remarks`}
                          placeholder={"Enter Remarks"}
                        />
                      )}
                      {type === "Update Reciept" && (
                        <TextAreaFormik
                          disabled={true}
                          value={detailsform?.auditRemarks}
                          name={`Remarks`}
                          placeholder={"Enter Remarks"}
                        />
                      )}
                    </div>
                  </div>
                  {type === "Resubmit Receipt" && (
                    <div className="createFootCust">
                      <button
                        type="button"
                        className="button secondaryButton"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="button primaryButton"
                        type="submit"
                        style={{ color: "white" }}
                      >
                        Update
                      </button>
                    </div>
                  )}
                </>
              </Form>
            </Formik>
          )}
          {type === "Update Reciept" && (
            <div className="createFootCust">
              <button
                type="button"
                className="button secondaryButton"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton"
                onClick={makeReqdata}
                style={{ color: "white" }}
              >
                Re Submit
              </button>
            </div>
          )}
        </div>
      </>
    );
};

export default ManualEntry;
