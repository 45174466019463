import React from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../Features";
import { useEffect } from "react";

function ColorDisplay({ color }) {
  const dispatch = useDispatch();

  // useEffect(()=>{
  //   document.title = `PeopleSol - Color Display`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // })
  

  return (
    <div
      className="cursor"
      title={`${color}, click to copy`}
      onClick={() => {
        navigator.clipboard.writeText(color);
        dispatch(
          showToast({ text: "Color copied to clipboard.", severity: "success" })
        );
      }}
      style={{
        padding: "12px",
        height: "40px",
        borderRadius: "4px",
        backgroundColor: color,
        maxWidth: "150px",
      }}
    ></div>
  );
}

export default ColorDisplay;
