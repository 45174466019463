import React from 'react'
import UserOnboardingMobileAppDownload from "../../../../../Assets/UserOnboardingMobileAppDownload.png";
import AppQrCode from "../../../../../Assets/AppQrCode.svg";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const MobileAppDownload = () => {

    const navigate = useNavigate();

    useEffect(() => {
      document.title = `PeopleSol - Mobile App Download`;
      return () => {
        document.title = 'PeopleSol';
      };
    }, []);
  return (
    <>
      <div className="imageHolder">

      <img style={{width:'100%', height: 'calc(100% - 3rem)'}} src={UserOnboardingMobileAppDownload} alt={UserOnboardingMobileAppDownload} />     
    
        </div>

        <div className="onboardInfoHolder" style={{paddingLeft:'5rem'}}>
           <h1 className='onboardHeading' style={{paddingTop: '7rem'}}>Mobile App <br/> Download</h1>
           <div className="qrHolderContainer" style={{display:'flex', height:'17rem'}}>

           <div className='qrHolder' style={{display:'flex', alignItems:"flex-end"}}>
           <img src={AppQrCode} alt={AppQrCode} />
           </div>
           <label className='scanText'>Scan to <br/> Download App</label>
          
           </div>
        </div>
           <div className="onboardfooterButton">
            {/* <label className='cursor' style={{color: 'var(--primary)', fontWeight: '500'}} onClick={() => navigate("/")}>Skip</label> */}
            <div className='onboardNextButtonHolder'>
                {/* <label className='onboardButtons cursor onboardPrev' onClick={() => navigate("/useronboarding/employeeselfservice")}>Previous</label> */}
                <label className='button PrimaryButton onboardButtons onboardNext' onClick={() => navigate("/")}>Finish</label>
            </div>
           </div>
    </>
  )
}

export {MobileAppDownload}
