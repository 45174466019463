import { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "./PDFViewer.css";

import SocialPdfLeftArrow from "../../../../../Assets/SocialPdfLeftArrow.svg";
import SocialPdfRightArrow from "../../../../../Assets/SocialPdfRightArrow.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PDFViewer({ pdfUrl }) {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function nextSlide(e) {
    e.stopPropagation();
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  }
  function prevSlide(e) {
    e.stopPropagation();
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  }

  return (
    <div className="inputPdfContainer">
      <div className="inputPdfWrapper">
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          {/* {Array.from(new Array(numPages), (el, index) => (
        ))} */}
        </Document>
      </div>
      {pdfUrl?.length > 0 &&  (
        <>
          {index > 0 && (
            <div className="leftPdfArrow">
              <img
                src={SocialPdfLeftArrow}
                alt=""
                onClick={(e) => {
                  prevSlide(e);
                }}
              />
            </div>
          )}
          {index === numPages - 1 || (
            <div className="rightPdfArrow">
              <img
                src={SocialPdfRightArrow}
                alt=""
                onClick={(e) => {
                  nextSlide(e);
                }}
              />
            </div>
          )}
        </>
      )}
      <div className="inputPdfBottomContainer">
        <p>
          {pageNumber} / {numPages}
        </p>
        <div className="pdfLine"></div>
      </div>
    </div>
  );
}
export default PDFViewer;
