import React, { useState, useEffect } from 'react';
import { Tabs, Tab, IconButton } from '@mui/material';
import "./CandidateJobsPage.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "../../Components/index";
import Select from "react-dropdown-select";
import { CandidateJobs } from './Components/CandidateJobs';

const CandidateJobsPage = () => {
    useEffect(()=>{
        document.title = `PeopleSol - Candidate Jobs`;
        return () => {
          document.title = 'PeopleSol';
        };
      })


    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newTabIndex) => {
        if (newTabIndex === 0) {
            navigate("openjobs");
        }
        else if (newTabIndex === 1) {
            navigate("applied");
        }
    }
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const currentpath = location.pathname.toLowerCase();
        if (currentpath.includes("candidate/jobs")) {
            if (currentpath.includes("openjobs")) {
                setTabIndex(0);
            }
            else if (currentpath.includes("applied")) {
                setTabIndex(1);
            }
            else {
                navigate("openjobs");
            }
        }
    }, [location.pathname]);
    
    return (
        <div className="homeEngageMainContainer candidateJobsContainer">
        <div className="moduleMainContainer">
            <div className="tabContainer">
                <Tabs value={tabIndex}
                
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { background: "#1296B0" } }}>
                    <Tab label={<span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>Jobs</span>} />
                    <Tab label={<span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>Applied Jobs</span>} />
                </Tabs>
                <div className="tabContainerLine"></div>
            </div>
            {tabIndex === 0 && (
                <>
                    <CandidateJobs/>
                </>)}
            {tabIndex === 1 && (
                <>
                 <CandidateJobs/>
            </>)}
        </div>
        </div>
    )
}

export { CandidateJobsPage }