import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useEffect } from "react";

const ManualOnboarding = ({ id, handleClose, getListData }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const manualOnboarding = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Candidate/CompleteOnboarding/${id}`);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      handleClose();
      getListData();
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Manual Onboarding`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="smallHeightPopup">
          <label>
            Are you sure you want to manully onboard this candidate?
          </label>
          <div className="smallContentfooterButtonHolder">
            <button
              className="button secondaryButton"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="button primaryButton"
              type="submit"
              onClick={manualOnboarding}
            >
              Yes, Manually Onboard
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ManualOnboarding;
