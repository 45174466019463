import { IconButton } from '@mui/material';
import React from 'react'
import "./DateRange.css"
import { useEffect } from 'react';

const DateRange = () => {
  useEffect(() => {
    document.title = `PeopleSol - Date Range`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className='dateRangeMainContainer'>
      <IconButton> 
      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.03354 0.36084V5.32279V10.2847L0.0715877 5.32279L5.03354 0.36084Z" fill="#1296B0"/>
      </svg>
      </IconButton>
      
      <div className="dateRangeContainer">16 Nov - 23 Nov, 2022</div>
      <IconButton>
      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.844055 0.36084V5.32279V10.2847L5.80601 5.32279L0.844055 0.36084Z" fill="#1296B0"/>
        </svg>

      </IconButton>
      
    </div>
  )
}

export  {DateRange};
