import React, { useState } from "react";
import "./PayslipTemplateDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import AESSCompanyLogo from "../../../../Assets/AESSCompanyLogo.png";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const PayslipTemplateDetails = () => {
  const [pdfLoading, setPdfLoading] = useState(false);

  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <div
        className=""
        id="pdf-content"
       
      >
        <div className="payslipHeaderContainer">
          <div className="payslipHeadingTextContainer">
            <h4 style={{ color: "var(--primary)", fontFamily: "system-ui" }}>
              AESS SOLUTIONS PRIVATE LIMITED
            </h4>
            <p>
              12 Noor Mahal, Opposite Taj Market, Bhopal, Madhya Pradesh, 462001
            </p>
            <p>CIN: U72900MP2022PTC060562</p>
          </div>
          <img
            src={AESSCompanyLogo}
            alt="logo"
            className="payslipCompanyLogo"
          />
        </div>
        <div className="payslipMainContainer" style={{ background: "white" }}>
          <div className="payslipMainHeaderContainer">
            <div className="payslipHeadingTextContainer">
              <h4 style={{ color: "var(--primary)" }}>Payslip - Aug, 2023</h4>
              <h4>₹84797/-</h4>
            </div>
          </div>
          <div className="payslipEmployeeSummaryContainer">
            <h5>Employee Summary</h5>
            <div className="payslipEmployeeMainSummaryContainer">
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Employee Name</p>
                <h6 className="empBodySlip">Vishal Jadhav</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Employee Code</p>
                <h6 className="empBodySlip">10000037</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Employee Role</p>
                <h6 className="empBodySlip">Front-end Developer</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Employee Designation</p>
                <h6 className="empBodySlip">Asst. Manager</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Date of Joining</p>
                <h6 className="empBodySlip">6th Dec 2022</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Location</p>
                <h6 className="empBodySlip">Bhopal</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Pay Period</p>
                <h6 className="empBodySlip">Aug 2023</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Payble Days</p>
                <h6 className="empBodySlip">31 Days</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Leave Days</p>
                <h6 className="empBodySlip">0 Days</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Paid Days</p>
                <h6 className="empBodySlip">31 Days</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">UAN</p>
                <h6 className="empBodySlip">NA</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">PAN</p>
                <h6 className="empBodySlip">EYSPD4593K</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Bank Name</p>
                <h6 className="empBodySlip">SBI Bank</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">Account Number</p>
                <h6 className="empBodySlip">20300702078</h6>
              </div>
              <div className="employeeSummaryCell">
                <div className="horizontalLine" />
                <p className="empheadingSlip">IFSC</p>
                <h6 className="empBodySlip">SBIN0000468</h6>
              </div>
            </div>
          </div>
          <div className="payslipEmployeeSummaryContainer">
            <h5>Pay Summary</h5>
            <div className="paySummaryContainer">
              <div className="paySummaryComponentContainer">
                <div className="paySummaryComponentHeader">
                  <h6>EARNINGS</h6>
                  <h6>AMOUNT</h6>
                </div>
                <div className="paySummaryComponentMain">
                  <div className="paySummaryComponentList">
                    <p className="earningHeadSlip">Basic</p>
                    <p className="earningBodySlip">₹48999</p>
                  </div>
                  <div className="paySummaryComponentList">
                    <p className="earningHeadSlip">H R A</p>
                    <p className="earningBodySlip">₹8267</p>
                  </div>

                  <div className="paySummaryComponentList">
                    <p className="earningHeadSlip">L T A</p>
                    <p className="earningBodySlip">₹1757</p>
                  </div>
                  <div className="paySummaryComponentList">
                    <p className="earningHeadSlip">Special Allowance</p>
                    <p className="earningBodySlip">₹6477</p>
                  </div>
                  <div className="paySummaryComponentList">
                    <p className="earningHeadSlip">Uniform Allowance</p>
                    <p className="earningBodySlip">₹2000</p>
                  </div>
                </div>
                <div className="paySummaryComponentFooter">
                  <p>Gross Earnings</p>
                  <p>₹85000</p>
                </div>
              </div>
              <div className="paySummaryComponentContainer">
                <div className="paySummaryComponentHeader">
                  <h6>DEDUCTIONS</h6>
                  <h6>AMOUNT</h6>
                </div>
                <div className="paySummaryComponentMain">
                  <div className="paySummaryComponentList">
                    <p className="earningHeadSlip">Professional Tax</p>
                    <p className="earningBodySlip">₹203</p>
                  </div>
                </div>
                <div className="paySummaryComponentFooter">
                  <p>Total Deductions</p>
                  <p>₹203</p>
                </div>
              </div>
            </div>
            <div
              className="paySummaryComponentContainer"
              style={{ marginTop: "24px" }}
            >
              <div className="paySummaryComponentHeader">
                <h6>REIMBURSEMENTS</h6>
                <h6>AMOUNT</h6>
              </div>
              <div className="paySummaryComponentMain">
                {/* <div className="paySummaryComponentList">
                  <p>Internet Bill</p>
                  <p>₹1400</p>
                </div> */}
              </div>
              <div className="paySummaryComponentFooter">
                <p>Total Reimbursement</p>
                <p>₹0</p>
              </div>
            </div>
          </div>
          <div
            className="payslipEmployeeSummaryContainer"
            style={{ marginLeft: "12px" }}
          >
            <h5 style={{ color: "var(--primary)" }}>
              <span style={{ fontSize: "18px", color: "black" }}>
                {" "}
                Total Net Payable
              </span>
              : ₹84797.00{" "}
              <span style={{ fontSize: "11px" }}>
                (Eighty Four Thousand Seven Hundred Ninety-seven Only.)
              </span>
            </h5>
            <p style={{ fontSize: "11px", fontWeight: 500 }}>
              Total Net Payable = (Gross Earnings - Total Deductions) +
              Reimbursements
            </p>
          </div>
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: 500,
            margin: "auto",
            textAlign: "center",
          }}
        >
          This is system generated pay slip no signature required-
        </div>
      </div>
    </div>
  );
};

export { PayslipTemplateDetails };
