import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { RejectedApprovalLateComing } from "../Components/LateComingApproval/RejectedApprovalLateComing";
import { PendingApprovalLateComing } from "../Components/LateComingApproval/PendingApprovalLateComing";
import { ApprovedApprovalLateComing } from "../Components/LateComingApproval/ApprovedApprovalLateComing";
import { AllApprovalLateComing } from "../Components/LateComingApproval/AllApprovalLateComing";
import { useEffect } from "react";

const ApprovalLateComing = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Late Comimg`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalLateComing
            title={"Late Coming"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <>
          <ApprovedApprovalLateComing title={"Late Coming"} />
        </>
      )}
      {tabIndex === 2 && (
        <>
          <RejectedApprovalLateComing title={"Late Coming"} />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <AllApprovalLateComing
            title={"Late Coming"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalLateComing };
