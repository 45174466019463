import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import dateFormat from "dateformat";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useEffect } from "react";

export const HodReview = ({ data, getDetails }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const createPage = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("ITsmChangeRequest/HodApprove", values);
      if (result) {
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(showToast({ text: "error", severity: "error" }));
    } finally {
      getDetails();
      setLoading(false);
    }
  };
  const validationSchema = Yup.object({
    HodFinalRemarks: Yup.string()

      .max(499, "Remarks cannot be more than 500 characters")
      .required("Please Enter Remarks"),
  });
  const onSubmit = async (values, rej) => {
    const create = {
      ...values,
      Priority: data?.itsmChangeRequest?.priority,
      ChangeType: data?.itsmChangeRequest?.changeType,
      Id: data?.itsmChangeRequest?.id,
      HodFinalApproval: rej === "rejected" ? false : true,
      Reason: data?.itsmChangeRequest?.reason,
      DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
    };
    createPage(create);
  };
  useEffect(() => {
    document.title = `PeopleSol - Hod Review`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <div
        style={{ padding: "12px", backgroundColor: "var(--form-background)" }}
      >
        <Formik
          enableReinitialize
          initialValues={{ HodFinalRemarks: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values }) => {
            return (
              <Form>
                <LabelCustom labelName={"Implementation HOD Review Notes"} mandatory={true} />
                <TextAreaFormik
                  maxLength={500}
                  placeholder="Please Enter Remarks"
                  style={{ height: "80px" }}
                  name={"HodFinalRemarks"}
                />
                <div className="createFootCust">
                  <button
                    className="deletebtn"
                    type="reset"
                    onClick={() => onSubmit(values, "rejected")}
                  >
                    Reject
                  </button>
                  <button className="button primaryButton" type="submit">
                    Approve
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
};
