/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { CreateDeputationComp } from "./Form/CreateDeputationComp";
import { addMonths } from "date-fns";
import { useSelector } from "react-redux";

const CreateDeputation = (props) => {
  const [formValues, setFormValues] = useState(null);
  const { state } = useLocation();
  const [lastDate, setLastDate] = useState(null);
  const data = state?.id;
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [detailsform, setDetailsform] = useState({});
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`DeputationRequests/Details?ID=${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const EditPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest("DeputationRequests/Edit", values);
      dispatch(
        showToast({ text: "Successfully Updated", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Deputaion`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest("DeputationRequests/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      formValues?.transferEffectiveDate !== null &&
      formValues?.transferEffectiveDate !== "" &&
      formValues?.transferEffectiveDate !== undefined
    ) {
      setLastDate(new Date(addMonths(formValues?.transferEffectiveDate, 3)));
    }
  }, [formValues?.transferEffectiveDate]);

  const initialvalue = {
    transferType: "",
    employeeId: LoggedInUser?.id,
    toLocation: "",
    transferEffectiveDate: "",
    reason: "",
  };
  const validationSchema = Yup.object({
    transferType: Yup.string().required("Please select Transfer Type "),
    employeeId: Yup.string().required("Please select Employee "),
    toLocation: Yup.string().required("Please select Location "),
    transferEffectiveDate: Yup.string()
      .required("Please select Date ")
      .nullable(),
    reason: Yup.string()
      .max(499, "Must be 500 characters or less")
      .required("Please Enter  Reason"),
  });

  const onSubmit = (values) => {
    const payload = {
      id: data ? detailsform?.id : 0,
    };
    if (values) {
      if (data) payload["id"] = data;
      payload["transferType"] = values.transferType;
      payload["employeeId"] = values.employeeId;
      payload["toLocation"] = values.toLocation;
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      payload["transferEffectiveDate"] = new Date(
        values.transferEffectiveDate - tzoffset
      )
        .toISOString()
        .slice(0, -1);
      payload["endDate"] = new Date(lastDate - tzoffset)
        .toISOString()
        .slice(0, -1);
      payload["reason"] = values.reason;
    }
    data ? EditPageData(payload) : createPageData(payload);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
//console.log("forms",formValues)
  return (
    <FormCustomFormik
      loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={"Create Deputation Request"}
      ButtonLabel={
        data ? "Update Deputation Request" : "Create Deputation Request"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              transferType: detailsform?.deputationRequests?.transferType,
              employeeId: detailsform?.deputationRequests?.employeeId,
              transferEffectiveDate: detailsform?.deputationRequests
                ?.transferEffectiveDate
                ? new Date(
                    detailsform?.deputationRequests?.transferEffectiveDate
                  )
                : "",
              toLocation: detailsform?.deputationRequests?.toLocation,
              reason: detailsform?.deputationRequests?.reason,
              endDate: detailsform?.deputationRequests?.endDate
                ? new Date(detailsform?.deputationRequests?.endDate)
                : null,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreateDeputationComp
          formValues={formValues}
          loading={loading}
          lastDate={lastDate}
        />
      }
    />
  );
};

export { CreateDeputation };
