import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { useFormikContext } from "formik";
import { IconButton } from "@mui/material";
import CloseIcon from "../../../../Assets/CloseIcon.svg";

const WebcamCapture = ({
  handleCloseWebcam,
  field,
  setClearable,
  attachText,
  setAttachText,
  hiddenFileInput,
  setFieldType,
  formValues,
  clearValue,
}) => {
  const webcamRef = React.useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const { setFieldValue } = useFormikContext();

  const fileName = "webcam.jpeg";
  let fileObject;

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const base64ToBlob = (base64, mimeType) => {
      const byteString = atob(base64?.split(",")[1]);
      const ab = new ArrayBuffer(byteString?.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString?.length; i++) {
        ia[i] = byteString?.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeType });
    };

    const base64ToFile = (base64String, fileName) => {
      const blob = base64ToBlob(base64String, "image/jpeg");
      const file = new File([blob], fileName, {
        type: "image/jpeg",
        lastModified: new Date().getTime(),
      });

      return file;
    };
    fileObject = imageSrc && base64ToFile(imageSrc, fileName);

    setImageSrc(fileObject);

    setFieldValue(field.name, fileObject);
    setAttachText(field?.value?.name);
    handleCloseWebcam();
  }, [webcamRef, setFieldValue]);

  return (
    <div className="webcamdiv">
      <div className="webcamHeader">
        <IconButton onClick={handleCloseWebcam} title="Close">
          <img className="close-icon" src={CloseIcon} alt="close" />
        </IconButton>
      </div>
      <Webcam
        audio={false}
        height={"80%"}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={"98%"}
        videoConstraints={{ width: 300, height: 300, facingMode: "user" }}
      />
      <div
        className="button "
        style={{ background: "var(--primary)", color: "white" }}
        onClick={capture}
      >
        Capture
      </div>
      {/* {imageSrc && <img src={imageSrc} alt="Captured" />} */}
    </div>
  );
};
export { WebcamCapture };
