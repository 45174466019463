import React from "react";
import getDisplayName from "../../../../../../../Utilities/displayNameFormat";
import { profileImages } from "../../../../../../../Utilities/profileAndUploadImages";
import ProfileAvatar from "../../../../../../../Assets/ProfileAvatar.svg";
import { getUserInitials } from "../../../../../../../Utilities/socialAllFunctions";
import { formatTimeFromDate } from "../../SocialFunctions";
import { useSelector } from "react-redux";
import ViewReportContainer from "./ViewReportContainer";

function SurveyAndPollPersonHeader({ hData }) {
  const { LoggedInUser } = useSelector((state) => state.auth);
  return (
    <div className="surveyAndPollPersonHeaderContainer">
      <div className="surveyAndPollPersonHeaderWrapper">
        <div className="surveyAndPollPersonHeaderLeftContainer">
          <div className="surveyAndPollPersonHeaderImgContainer">
            <div
              className={`${
                hData?.image
                  ? "socialProfilePicContainer"
                  : "surveyCustomProfilePic"
              }`}
            >
              {!hData?.image ? (
                getUserInitials(getDisplayName(hData?.nameWithPersonNumber))
              ) : (
                <img
                  className="surveyProfilePic"
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleUserProfileClick(hData?.employeeId);
                  }}
                  src={
                    process.env.REACT_APP_.BASE_URL +
                    profileImages +
                    hData?.image
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = ProfileAvatar;
                  }}
                />
              )}
            </div>
          </div>
          <div className="surveyAndPollPersonHeaderTxtContainer">
            <h6>{getDisplayName(hData?.nameWithPersonNumber)}</h6>
            <p>
              <label>{hData?.roleName}</label>
              <span className="surveyPostDot">.</span>
              <span className="surveyCreatedOn">
                {formatTimeFromDate(hData?.createdOn)}
              </span>
            </p>
          </div>
        </div>
        <div className="surveyAndPollPersonHeaderRightContainer">
          {hData?.employeeId === LoggedInUser?.id && (
            <ViewReportContainer reportData={hData} />
          )}
        </div>
      </div>
    </div>
  );
}

export default SurveyAndPollPersonHeader;
