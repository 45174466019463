import React from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { useEffect } from "react";

export const CreateOutdoorComp = ({formValues}) => {
  useEffect(() => {
    document.title = `PeopleSol - Create OutDoor`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Location"} mandatory={true} />
        <InputCustomFormik
          
          maxLength={150}
          name={"location"}
          type={"text"}
          placeholder={"Enter the Location"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Outdoor From Date"} mandatory={true} />
        <DatePickerFormiks name={"fromDate"} maxDate={formValues?.toDate} />
      </div>
      <div>
        <LabelCustom labelName={"Outdoor To Date"} mandatory={true} />
        <DatePickerFormiks name={"toDate"} minDate={formValues?.fromDate}/>
      </div>
      <div>
        <LabelCustom labelName={"Outdoor Reason"} mandatory={true} />
        <TextAreaFormik
          
          maxLength={500}
          name="description"
          placeholder={"Enter the reason"}
        />
      </div>
    </div>
  );
};
