import React, { useState, useEffect, useRef } from 'react';
import EndCall from "../../Assets/EndCallIcon.svg"
import CallMikeIcon from "../../Assets/CallMikeIcon.svg"
import DisableVideoIcon from "../../Assets/DisableVideoIcon.svg"
import ScreenShareIcon from "../../Assets/ScreenShareIcon.svg"
import BGChangeIcon from "../../Assets/BGChangeIcon.svg"
import RecordIcon from "../../Assets/RecordIcon.svg"
import AddMember from "../../Assets/AddMembersIcon.svg"
import EnableCallIcon from "../../Assets/EnableVideoIcon.svg"
import UnMuteIcon from "../../Assets/UnMuteIcon.svg"
import { useSelector } from 'react-redux';





const SERVER_URL = 'ws://192.168.1.11:8080';

let peer = null;

const TestCall = ({ stream, toggleCamera, toggleSpeaker, share, recordAudio, recordScreen, setShowCallScreen, userDetails, audioCall, activeChat }) => {
    const [localStream, setLocalStream] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);
    const [screenSharingStream, setScreenSharingStream] = useState(null);
    const [pc, setPc] = useState(null);
    const [ws, setWs] = useState(null);
    const [id, setId] = useState(1); // example ID for user identification
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const [mute, setMute] = useState(false);
    const [disconnect, setDisconnect] = useState(false);
    const [showVideo, setShowVideo] = useState(true);
    const [switchView, setSwitchView] = useState(false);
    const [callTiming, setCallTiming] = useState(0);
    let callInterval = null;
    const peerConfiguration = {
        iceServers: [
            { urls: ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302'] },
        ],
    };
    const getTime = (time) => {

        let min = parseInt(time / 60);
        let sec = time % 60;
        if (min < 10) min = "0" + min;
        if (sec < 10) sec = "0" + sec;
        return min + ":" + sec;
    }

    useEffect(() => {
        if (disconnect) {
            setShowCallScreen()
            peer?.close();
            pc?.close();
            setRemoteStream(null);
            setLocalStream(null);
            ws?.send(JSON.stringify({ type: 'disconnect', recipientId: id === 1 ? 2 : 1, userId: id }));
        }
    }, [disconnect]);

    useEffect(() => {
        const initWebSocket = () => {
            const websocket = new WebSocket(SERVER_URL);
            websocket.onopen = () => console.log('WebSocket connected');
            websocket.onmessage = (message) => handleMessage(message, websocket);
            setWs(websocket);
        };

        initWebSocket();
        return () => ws?.close();
    }, []);

    useEffect(() => {
        if (localStream) {
            localStream?.getAudioTracks().forEach(track => {
                track.enabled = !track.enabled; // Toggle audio track state
            });
            // Update peer connection with the modified local stream
            localStream?.getTracks().forEach(track => {
                const sender = peer?.getSenders()?.find(s => s.track.kind === track.kind);
                sender?.replaceTrack(track);
            });
        }
    }, [mute])
    useEffect(() => {
        if (localStream) {
            localStream?.getVideoTracks().forEach(track => {
                track.enabled = !track.enabled; // Toggle audio track state
            });
            // Update peer connection with the modified local stream
            localStream?.getTracks().forEach(track => {
                const sender = peer?.getSenders()?.find(s => s.track.kind === track.kind);
                sender?.replaceTrack(track);
            });
        }
    }, [showVideo])

    useEffect(() => {
        if (remoteStream && audioCall) {
            callInterval = setInterval(() => { setCallTiming(callTiming + 1) }, 1000)
        }
        return (() => clearInterval(callInterval))
    }, [callTiming])

    const handleMessage = async (message, websocket) => {
        const data = JSON.parse(message.data);
        if (data.type === 'offer') {
            await handleOffer(data.offer, websocket);
        } else if (data.type === 'answer') {
            await handleAnswer(data.answer);
        } else if (data.type === 'candidate') {
            await handleCandidate(data.candidate);
        } else if (data.type === 'disconnect') {
            peer?.close();
            pc?.close();
            setRemoteStream(null);
            setLocalStream(null);
            websocket.send(JSON.stringify({ type: 'disconnect', userId: id }));
        }
    };

    const startCall = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: audioCall ? false : true });
        setLocalStream(stream);
        localVideoRef.current.srcObject = stream;

        const peerConnection = new RTCPeerConnection(peerConfiguration);
        peer = peerConnection;
        setPc(peerConnection);

        stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));

        peerConnection.ontrack = (event) => {
            if (event.streams[0]) {
                setRemoteStream(event.streams[0]);
                remoteVideoRef.current.srcObject = event.streams[0];
                setCallTiming(1);
            }
        };

        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                ws?.send(JSON.stringify({ type: 'candidate', candidate: event.candidate, recipientId: id === 1 ? 2 : 1 }));
            }
        };

        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        ws?.send(JSON.stringify({ type: 'offer', offer, recipientId: id === 1 ? 2 : 1 }));
    };

    const handleOffer = async (offer, websocket) => {
        const peerConnection = new RTCPeerConnection(peerConfiguration);
        peer = peerConnection;
        setPc(peerConnection);

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        setLocalStream(stream);
        localVideoRef.current.srcObject = stream;

        stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));

        peerConnection.ontrack = (event) => {
            if (event.streams[0]) {
                setRemoteStream(event.streams[0]);
                remoteVideoRef.current.srcObject = event.streams[0];
                setCallTiming(1);
            }
        };

        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                websocket.send(JSON.stringify({ type: 'candidate', candidate: event.candidate, recipientId: id === 1 ? 2 : 1 }));
            }
        };
        await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));

        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);
        websocket.send(JSON.stringify({ type: 'answer', answer, recipientId: id === 1 ? 2 : 1 }));
    };

    const handleAnswer = async (answer) => {
        await peer.setRemoteDescription(new RTCSessionDescription(answer));
    };

    const handleCandidate = async (candidate) => {
        await peer.addIceCandidate(new RTCIceCandidate(candidate));
    };

    const startScreenSharing = async () => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
            setScreenSharingStream(stream);
            stream.getTracks().forEach(track => {
                const sender = peer?.getSenders()?.find(s => s.track.kind === 'video');
                sender?.replaceTrack(track);
            });
        } catch (error) {
            console.error('Error starting screen sharing:', error);
        }
    };

    const stopScreenSharing = () => {
        screenSharingStream?.getTracks().forEach(track => track.stop());
        setScreenSharingStream(null);
    };

    return (
        <div
            style={{
                marginInline: "auto",
                marginTop: 10,
                backgroundColor: "rgba(250, 250, 250, 1)",
                left: 0,
                right: 0,
                borderRadius: 20,
                width: "90%",
                height: "93%",
                position: "absolute",
                zIndex: 100,
            }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", backgroundColor: "white", width: "100%", height: "100%" }}>
                <div onClick={() => { setSwitchView(!switchView) }} style={audioCall ? { display: "none" } : switchView ? { width: "100%", height: '100%', borderRadius: 10 } : { width: '200px', height: '150px', position: "absolute", top: 0, right: 0, borderRadius: 10, zIndex: 5 }}>
                    <video ref={localVideoRef} autoPlay muted style={switchView ? { width: "100%", height: '100%', borderRadius: 10 } : { width: '200px', height: '150px', position: "absolute", top: 0, right: 0, borderRadius: 10, zIndex: 5 }} />
                </div>
                {audioCall && localStream && <div>
                    {activeChat.participateMembers.filter((item) => item.personalNumber != userDetails.personalNumber).map((item) => (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            {item.image != "" ? <img style={{ height: 130, width: 130, borderRadius: 70, }} src={item.image} /> :

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 130, width: 130, borderRadius: 70, backgroundColor: "rgba(216, 240, 242, 1)", color: "rgba(18, 150, 176, 1)", fontSize: 26 }}>
                                    {item?.contactName
                                        ?.split(" ")
                                        ?.slice(0, 2)
                                        ?.map((item) => {
                                            return item?.charAt(0)?.toUpperCase();
                                        })
                                        .join("")
                                    }
                                </div>}
                            <div style={{ marginTop: 10, fontSize: 24, color: "rgba(18, 150, 176, 1)", textAlign: "center" }}>
                                {item.contactName}
                            </div>
                            <div style={{ marginTop: 10, color: "rgba(161, 161, 161, 1)", textAlign: "center" }}>
                                {remoteStream ? getTime(callTiming) : "Calling..."}
                            </div>

                        </div>
                    ))}
                </div>}
                {!showVideo &&
                    <div style={{ position: "absolute", top: 0, right: 0, borderRadius: 10, zIndex: 10 }}>
                        <div style={{ width: '200px', height: '150px', backgroundColor: "black", borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            {userDetails.image ?
                                <img style={{ height: 45, width: 45, borderRadius: 25, }} src={userDetails.image} /> :

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 45, width: 45, borderRadius: 25, backgroundColor: "rgba(216, 240, 242, 1)", color: "rgba(18, 150, 176, 1)" }}>
                                    {userDetails?.name
                                        ?.split(" ")
                                        ?.slice(0, 2)
                                        ?.map((item) => {
                                            return item?.charAt(0)?.toUpperCase();
                                        })
                                        .join("")
                                    }
                                </div>
                            }
                            <div style={{ marginTop: 10, color: "white", textAlign: "center" }}>
                                {userDetails?.name}
                            </div>
                        </div>
                    </div>
                }
                <div onClick={() => { setSwitchView(!switchView) }} style={audioCall ? { display: "none" } : switchView ? { width: '200px', height: '150px', position: "absolute", top: 0, right: 0, borderRadius: 10, zIndex: 5 } : { width: "100%", height: '100%', borderRadius: 10 }}>
                    <video ref={remoteVideoRef} autoPlay style={switchView ? { width: '200px', height: '150px', position: "absolute", top: 0, right: 0, borderRadius: 10, zIndex: 5 } : { width: "100%", height: '100%', borderRadius: 10 }} />
                </div>


                {!localStream ? <button onClick={startCall}>Start Call</button> : null}
                {/* <button onClick={startScreenSharing}>Share Screen</button> */}
                {/* <button onClick={stopScreenSharing}>Stop Screen Sharing</button> */}
                {localStream && <div style={{ position: "absolute", bottom: 20, width: "300px" }}>
                    <div style={{ backgroundColor: "white", borderRadius: 15, padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", borderWidth: audioCall ? 1 : 0, borderStyle: "solid", borderColor: "rgba(216, 240, 242, 1)" }}>
                        <div onClick={() => { }}><img src={AddMember} /></div>
                        <div onClick={() => { }}><img src={RecordIcon} /></div>
                        {audioCall ? null : <div onClick={() => { }}><img src={BGChangeIcon} /></div>}
                        {audioCall ? null : <div onClick={() => { }}><img src={ScreenShareIcon} /></div>}
                        {audioCall ? null : <div onClick={() => { setShowVideo(!showVideo) }}><img src={showVideo ? DisableVideoIcon : EnableCallIcon} /></div>}
                        <div onClick={() => { setMute(!mute) }}><img src={mute ? CallMikeIcon : UnMuteIcon} /></div>
                        <div onClick={() => { setDisconnect(true) }}><img src={EndCall} /></div>
                    </div>
                </div>}
                {!localStream ? <button onClick={() => { ws?.send(JSON.stringify({ newid: 1 })); setId(1) }} >ID1</button> : null}
                {!localStream ? <button onClick={() => { ws?.send(JSON.stringify({ newid: 2 })); setId(2) }} >ID2</button> : null}
            </div>
        </div>
    );
};

export default TestCall;
