
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest, postRequestForm } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";

import { useEffect } from "react";
import { CreateTaxStructureComp } from "./CreateTaxStructureComp";

const CreateTaxStructure = (props) => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);
  const data = state?.id;
  const [editFormData, seEditFormData] = useState(null);
  const getDetails = async () => {
    try {
      const result = await getRequest(
        `IncomeTaxStructureSettings/Details/${data}`
      );
      seEditFormData(result?.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Tax`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "IncomeTaxStructureSettings/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "IncomeTaxStructureSettings/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Edited", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getInitalAnswerValues = () => {
    let returnArray = [];
    // data
    //   ? editFormData?.displayName?.map((item) => {
    //     return returnArray.push({
    //       EmployeeId: item.employeeId,
    //       RoleId: item.roleId,
    //       Description: item.description,
    //     });
    //   })
    //   :
    (returnArray = [
      {
        ComponentName: "Annual income other than salary",
        LimitAmount: "",
        SubDisplayName: [
          { SubComponentName: 'Dividend Income', SubLimitAmount: "" },
          {
            SubComponentName: 'Interest from fixed deposit',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Interest from saving account',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Any other income (other than rent)',
            SubLimitAmount: "",
          },
        ],
      },
      {
        ComponentName: "Annual allowances paid as part of the salary",
        LimitAmount: "",
        SubDisplayName: [
          { SubComponentName: 'Driver salary allowance', SubLimitAmount: "" },
          {
            SubComponentName: 'Car allowance',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Books & Periodical',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Gadget allowance',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Medical Expenses allowance',
            SubLimitAmount: "",
          },


        ],
      },
      {
        ComponentName: "Investments under section 80C, 80CC (Investment)",
        LimitAmount: "",
        SubDisplayName: [
          { SubComponentName: 'Mutual funds', SubLimitAmount: "" },
          {
            SubComponentName: 'Health insurance premium',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Public provident fund (PPF)',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Housing loan repayment principle',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'National saving scheme / Certificate',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Sukanya samriddhi account',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Children education fees only school tuition fees)',
            SubLimitAmount: "",
          },

          {
            SubComponentName: 'Allowable term deposit',
            SubLimitAmount: "",
          },


        ],
      },
      {
        ComponentName: "Investments under section 80CCD (Pension Contribution)",
        LimitAmount: "",
        SubDisplayName: [
          { SubComponentName: 'National pension scheme', SubLimitAmount: "" },
        ],
      },
      {
        ComponentName: "Investments under section 80D (Medical Insurance)",
        LimitAmount: "",
        SubDisplayName: [
          { SubComponentName: 'Medical insurance premium for senior citizen', SubLimitAmount: "" },
          {
            SubComponentName: 'Expenditure on medical treatment for specified disease ',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Deduction in maintenance of medical treatment of a handicapped',
            SubLimitAmount: "",
          },
        ],
      },
      {
        ComponentName: "Investments under section 80E (Education Loan Interest)",
        LimitAmount: "",
        SubDisplayName: [
          { SubComponentName: 'Repayment of loan taken for higher education', SubLimitAmount: "" },
        ],
      },
      {
        ComponentName: "Any other Investment qualify for IT Deduction",
        LimitAmount: "",
        SubDisplayName: [
          { SubComponentName: 'Permanent physical disability', SubLimitAmount: "" },
          {
            SubComponentName: 'Leave travel assistance (LTA)',
            SubLimitAmount: "",
          },
          {
            SubComponentName: 'Any other investment',
            SubLimitAmount: "",
          },
        ],
      },
    ]);

    return returnArray;
  };

  const [initialValues, setInitialValues] = useState({
    TaxYear: data ? editFormData?.taxYear ?? "" : "",
    TypeOfRegime: data ? editFormData?.TypeOfRegime ?? "" : "",
    Description: data ? editFormData?.description : null,
    DisplayName: getInitalAnswerValues(),
  });



  const validationSchema = Yup.object({
    TaxYear: Yup.string().max(149, " Name must be less than 150 characters").required("Please Enter Title "),

  });

  const onSubmit = (values) => {
    let data = {
      ...values, FinancialYear: "2024-2025", TaxStructureComponent: values.DisplayName,
      "FinancialYear": "2024-2025",
      "TaxYear": "2025",
      "TypeOfRegime": "Old Regime",
      "Description": "Income tax structure for the financial year 2024-2025"
    };
    create(data);
    console.log('values', data)
  };
  const create = async (data) => {
    const result = await postRequest("IncomeTaxDeclarationSettings/CreateStructure", data);
    console.log("--------------->", result);
    returnPage();
  }

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Edit Tax Structure " : "Create Tax Structure "}
      ButtonLabel={data ? "Update Tax Structure " : "Create Tax Structure "}
      onSubmit={onSubmit}
      // validationSchema={validationSchema}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={<CreateTaxStructureComp setInitialValues={setInitialValues} formValues={formValues} />}
    />
  );
};

export { CreateTaxStructure };
