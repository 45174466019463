import { Formik, Form } from "formik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { InputCustom } from "../../../../../Components/FormComponent/InputCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { showToast } from "../../../../../Features";
import { useEffect } from "react";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";

const Proioroption = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const status = [
  { label: "Yet to Start", value: "Yet to Start" },
  { label: "In Progress", value: "In Progress" },
  { label: "Hold", value: "Hold" },
  { label: "Completed", value: "Completed" },
  { label: "Cancelled", value: "Cancelled" },
];
const UpdateProjectStatus = ({ data, id, prestatus, handleClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const PostApRejHol = async (values) => {
    try {
      setLoading(true);

      await postRequest(
        `Projects/UpdateStatus`, values
      );

      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {

      setLoading(false);
      data()
      handleClose()

    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Update Project Status`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const onSubmit = async (values) => {
    const value = {
      ...values,
      id: id,
      Name: prestatus?.name,
      Remark: values?.Remark
    };
    PostApRejHol(value);
  };
  return (
    <Formik
      initialValues={{
        status: prestatus?.status,
        Remark: "",
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div>
            <div>
              <LabelCustom labelName={"Current Status"} />
              <InputCustom disabled value={prestatus?.status} />
            </div>
            <div>
              <LabelCustom labelName={"Update Status"} mandatory={true} />
              <SelectForm
                name="status"
                options={status}
                placeholder="Select Status"
              />
            </div>
            {formik.values?.status === "In Progress" && (
              <>
                <LabelCustom labelName={"Progress (%)"} />
                <InputCustomFormik
                  name={"Percentage"}
                  type="number"
                  step="0.01"
                  placeholder="Please Enter Progress (%)"
                />
              </>
            )}
            <div>
              <LabelCustom labelName={"Remark"} />
              <TextAreaFormik maxLength={500} placeholder="Please Enter Remarks" style={{ height: "80px" }} name="Remark" />
            </div>
          </div>
          <div className="createFootCust">
            <div>
              <div
                className="button secondaryButton"
                style={{ borderStyle: "solid", borderColor: "rgba(18, 150, 176, 1)", borderWidth: 1 }}
                onClick={() =>
                  formik.resetForm({ values: { status: "", Priority: "" } })
                }
              >
                Reset
              </div>
            </div>
            <div>
              <button style={{ borderStyle: "solid", borderColor: "rgba(18, 150, 176, 1)", borderWidth: 1 }} className="button primaryButton" type="submit">
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { UpdateProjectStatus };
