import React from "react";
import { useEffect } from "react";

const CompPopup = ({ onClose, message, heading }) => {
  useEffect(() => {
    document.title = `PeopleSol - Comp Popup`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="alertContainer">
      <h2 style={{ fontFamily: "Poppins" }}>
        {heading}
      </h2>
      <p style={{ fontFamily: "Poppins", fontSize: "14px" }}>{message}</p>
      <div className="createFootCust" style={{ backgroundColor: "white" }}>
        <button
          className="button secondaryButton"
          onClick={onClose}
          style={{
            border: "1px solid var(--primary)",
            borderRadius: "4px",
          }}
        >
          Close
        </button>
        
      </div>
    </div>
  );
};

export default CompPopup;
