import React from 'react'
import { useEffect } from 'react';

const WeeklyOffComp = ({date, title, desc}) => {
  // useEffect(() => {
  //   document.title = `PeopleSol - Weekly Off`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // }, []);
  return (
    <div className="attendanceContentContainer" style={{backgroundColor:`#DBF4E2`}}>
       <div className="dateDisplay">{date?date:""}</div>
     <div className="horizontalLinePrimary calendarLine" style={{backgroundColor:`#0DB53C`}}></div>

        <div className='attendanceContentStatus' style={{color:`#0DB53C`}} >{title?title:""}</div>
        <div style={{display:'flex'}}>
           
        <label className='attendanceIn' style={{color:'black', fontSize:'13px'}}>{desc?desc:""}</label>
            </div>
        
        </div>
  )
}

export {WeeklyOffComp}
