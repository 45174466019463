/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Popover, Tab, Tabs, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { useState } from "react";
import Nodata from "../../../../Assets/Nodata.svg";

import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import "./PayrollPrepare.css";
import { useDeferredValue } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { AttachmentFormik } from "../../../../Components/FormComponent/AttachmentFormik";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
function PrepareIncentive(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const returnPage = () => {
    navigate("/payroll/preparepayroll/ongoing");
  };
  const [attachText, setAttachText] = useState(null);
  const [boxView, setBoxView] = useState(false);
  const [fileValue, setFileValue] = useState(null);
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const [importToggle, setImportToggle] = useState(false);
  const [key, setKey] = useState(0);
  const downloadtemplate = async () => {
    try {
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest(`PreparePayroll/BonusList?Month=${detailsform?.monthPayout}&PayAreaId=${detailsform?.payAreaId}`, downloadReqData);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title} Template`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const uploadImportFile = async () => {
    try {
      if (!fileValue || !attachText) return null;
      setLoading(true);
      let importPayload = {
        template: fileValue,
      };
      const result = await postRequestForm(
        "PreparePayroll/ImportBonus",
        importPayload
      );

      setImportToggle((prev) => !prev);
      dispatch(showToast({ text: result?.data?.message ?? 'Success', severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      setAttachText('')
      getEmployeeList(detailsform);
    }
  };
  const handlethisChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFileValue(fileUploaded);
    setAttachText(fileUploaded ? fileUploaded.name : ""); // Update the attachText state with the file name or an empty string
  };
  const handleCancelClick = () => {
    setAttachText(""); // Clear the attachText state
    setKey((prevKey) => prevKey + 1); // Change the key to remount the AttachmentFormik component
    setImportToggle((prev) => !prev);
  };
  const exportTable = async () => {
    try {
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest(`PreparePayroll/BonusExportList?Month=${detailsform?.monthPayout}&PayAreaId=${detailsform?.payAreaId}`, downloadReqData);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [count, setCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [searchParam, setSearchParam] = useState(null);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol - Prpare Payroll  `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (detailsform?.monthPayout) {
      getEmployeeList(detailsform);
    }
  }, [reqData]);
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const { state,pathname } = useLocation();

  const editId = state?.id;
  useEffect(() => {
    
    if(pathname.includes('ongoing/incentive'))getFormDetails();
  }, []);
  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`PreparePayroll/Details/${editId}`);
      if (result) {
        setDetailsform(result?.data?.data);
        if (result?.data?.data) {
          getEmployeeList(result?.data?.data);
        } else {
          dispatch(
            showToast({
              text: "Some error occured. Please try again later.",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getEmployeeList = async (detail) => {
    const employeeResult = await postRequest(
      `PreparePayroll/BonusList?Month=${detail?.monthPayout}&PayAreaId=${detail?.payAreaId}`,
      reqData
    );
    if (employeeResult?.data?.data) { 
      setEmployeeList(employeeResult?.data?.data);
      setCount(employeeResult.data.summary.allCount);
      calculateTotalPages(employeeResult.data.summary.allCount);
    }
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  const CheckAttendanceClickHandler = (payId) => {

      navigate("/payroll/preparepayroll/ongoing/prepare", {
        state: { id:payId },
      });
 
  };
  const hasBonus = employeeList.some(item => item.item.bonus > 0);
  console.log('hasBonus',hasBonus)
  if (loading) return <Loader />;
  else
    return (
      <div className="moduleMainContainer">
        <OutsideClickHandler
          onOutsideClick={() => {
            setImportToggle((prev) => {
              if (prev) {
                handleCancelClick();
                return false;
              } else return prev;
            });
          }}
        >
          <div
            className={`${
              importToggle
                ? "filterSearchContainer importContainerActive"
                : "filterSearchContainer"
            }`}
            style={{ zIndex: 999 }}
          >
            <div className="headingContainer">
              <h3>Import</h3>
              <IconButton
                title="Close"
                onClick={() => {
                  handleCancelClick();
                  setImportToggle(false);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7579 1.56897L0.757935 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.757935 1.56897L18.7579 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>

            <LabelCustom labelName={"Upload Files"} mandatory={true} />
            <div className="importContainerbody">
              <div style={{ flexGrow: "1" }}>
                <AttachmentFormik
                  key={key}
                  name="upload"
                  onChange={handlethisChange}
                  attachText={attachText}
                  setAttachText={setAttachText}
                />
              </div>
              <Tooltip title="Download Template" placement="top" arrow>
                <IconButton
                  onClick={downloadtemplate}
                  style={{ marginRight: "12px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect width="20" height="20" rx="4" fill="#D8F0F2" />
                    <path
                      d="M10.5957 4.25366C10.5957 3.97752 10.3718 3.75366 10.0957 3.75366C9.81956 3.75366 9.5957 3.97752 9.5957 4.25366L10.5957 4.25366ZM9.5957 4.25366L9.5957 12.9648L10.5957 12.9648L10.5957 4.25366L9.5957 4.25366Z"
                      fill="#1296B0"
                    />
                    <path
                      d="M5.75037 9.07996L10.0956 13.4252L14.4408 9.07996"
                      stroke="#1296B0"
                      stroke-linecap="round"
                    />
                    <path
                      d="M5.49487 15.7465H14.5053"
                      stroke="#1296B0"
                      stroke-linecap="round"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
            <div className="filterSearchFooterContainer">
              <div
                className="button secondaryButton"
                onClick={handleCancelClick}
              >
                Cancel
              </div>
              <button
                className="button primaryButton"
                onClick={uploadImportFile}
              >
                Upload
              </button>
            </div>
          </div>
        </OutsideClickHandler>
        <div className="tableHeaderContainer" style={{ padding: "0 12px" }}>
          <IconButton onClick={returnPage}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.368615 3.35738C0.173352 3.55265 0.173352 3.86923 0.368615 4.06449L3.5506 7.24647C3.74586 7.44173 4.06244 7.44173 4.2577 7.24647C4.45296 7.05121 4.45296 6.73463 4.2577 6.53936L1.42927 3.71094L4.2577 0.88251C4.45296 0.687248 4.45296 0.370666 4.2577 0.175404C4.06244 -0.0198586 3.74586 -0.0198586 3.5506 0.175404L0.368615 3.35738ZM18.749 4.21094C19.0252 4.21094 19.249 3.98708 19.249 3.71094C19.249 3.4348 19.0252 3.21094 18.749 3.21094V4.21094ZM0.722168 4.21094H18.749V3.21094H0.722168V4.21094Z"
                fill="#1296B0"
              />
            </svg>
          </IconButton>
          <h6>Prepare Payroll with Incentive, {detailsform?.monthPayout}</h6>
        </div>

        <div className="preparePayrollMainContainer">
          <div className="preparePayrollTableContainer">
            <div
              className="tableHeaderContainer"
              style={{ padding: "0 0 0 12px" }}
            >
              <button
              style={{background:hasBonus?'rgb(13, 181, 60)':'grey',cursor:hasBonus?'pointer':'not-allowed'}}
                className="button primaryButton"
                onClick={() =>
                  hasBonus ? CheckAttendanceClickHandler(editId):{}
                }
              >
             {"Update Incentives" }   
              </button>
              <div className="search-box" title="Search">
                <input
                  className="search-text"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchParam(e.target.value)}
                />
                <a className="search-btn" title="Search">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                      fill="#1296B0"
                    />
                  </svg>
                </a>
              </div>
              <Tooltip arrow title="Upload" placement="top">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setImportToggle((prev) => !prev);
                    filterSearchToogle
                      ? setFilterSearchToogle((prev) => !prev)
                      : setFilterSearchToogle((prev) => prev);
                  }}
                >
                  {!importToggle ? (
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.1949 0.95166V11.6183M10.1949 0.95166L6.63936 4.50722M10.1949 0.95166L13.7505 4.50722M1.30603 13.3961L1.85803 15.605C1.95417 15.9896 2.1761 16.331 2.48855 16.575C2.801 16.819 3.18604 16.9516 3.58247 16.9517H16.8074C17.2038 16.9516 17.5888 16.819 17.9013 16.575C18.2137 16.331 18.4357 15.9896 18.5318 15.605L19.0838 13.3961"
                        stroke="#1296B0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.7579 1.56897L0.757935 19.569"
                        stroke="#F71D1D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M0.757935 1.56897L18.7579 19.569"
                        stroke="#F71D1D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Download" placement="top">
                <IconButton color="primary" onClick={exportTable}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 21 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
            {employeeList?.length > 0 ? (
              <table className="attendance-table">
                <colgroup>
                  <col />
                  <col style={{ width: "100%" }} />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr className="tableHeaderRow" style={{ zIndex: "9" }}>
                    <th style={{ textAlign: "start" }}>Employee Name</th>
                    <th>Account Number</th>
                    <th>Added Bonus</th>
                    <th>Incentive</th>
                    <th>Bank Name</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeList?.map((item) => (
                    <tr key={item?.item?.id}>
                      <td style={{ textAlign: "start", cursor: "pointer" }}>
                        {item?.item?.employee}
                      </td>
                      <td>{item?.item?.accountNumber}</td>
                      <td>{item?.item?.bonus}</td>
                      <td>{item?.item?.incentive ?? "-"}</td>
                      <td>{item?.item?.bankName ?? "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="noDatastyle">
                <img
                  src={Nodata}
                  alt="No data"
                  style={{ height: "150px", width: "250px" }}
                />
                <h4>{"No Data Found"}</h4>
              </div>
            )}
            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords}
                dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default PrepareIncentive;
