import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalHRDesk } from "../Components/hrdeskapproval/PendingApprovalHRDesk";
import { AllRequestHRDesk } from "../Components/hrdeskapproval/AllRequestHRDesk";
import { HrdeskApproved } from "../Components/hrdeskapproval/HrdeskApproved";
import { HoldRequestHrdesk } from "../Components/hrdeskapproval/HoldRequestHrdesk";
import { RejectedHrdesk } from "../Components/hrdeskapproval/RejectedHrdesk ";
import { useEffect } from "react";

const ApprovalHRDesk = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - HR Desk`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Hold
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalHRDesk
            title={"Hrdesk"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && <HrdeskApproved />}
      {tabIndex === 2 && (
        <>
          <HoldRequestHrdesk title={"Hrdesk"} />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <RejectedHrdesk title={"Hrdesk"} />
        </>
      )}

      {tabIndex === 4 && (
        <>
          <AllRequestHRDesk
            title={"Hrdesk"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalHRDesk };
