/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LabelCustom,
  SelectForm,
} from "../../../../Components/FormComponent/Index";
import { getRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { useEffect } from "react";
import { SelectFormikMultiString } from "../../../../Components/FormComponent/SelectFormikMultiString";

const Applicable = (props) => {
  const { data } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Application `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const employmentOptions = [
    { label: "Regular", value: "Regular" },
    { label: "Contract", value: "Contract" },
    { label: "Intern", value: "Intern" },
  ];
  const onboardingOptions = [
    { label: "Triggered", value: "Triggered" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
  ];
  const hireOptions = [
    { label: "Direct", value: "Direct" },
    { label: "Referral", value: "Referral" },
    { label: "Newspaper", value: "Newspaper" },
    { label: "Advertisement", value: "Advertisement" },
  ];
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];

  const marriageOptions = [
    { label: "Married", value: "Married" },
    { label: "Single", value: "Single" },
    { label: "Divorced", value: "Divorced" },
    { label: "N/A", value: "N/A" },
  ];
  const [option, setOption] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [departmentValuesArray, setDepartmentValuesArray] = useState([]);
  const [designationValuesArray, setDesignationValuesArray] = useState([]);
  const [areaValuesArray, setAreaValuesArray] = useState([]);
  const [roleValuesArray, setRoleValuesArray] = useState([]);
  const [empTypeValuesArray, setEmpTypeValuesArray] = useState([]);
  const [hireValuesArray, setHireValuesArray] = useState([]);
  const [onboardingValuesArray, setOnboardingValuesArray] = useState([]);
  const [employeeValuesArray, setEmployeeValuesArray] = useState([]);

  const navigate = useNavigate();

  const opt = [{ label: "select", value: null }];

  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.name });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getDepartmentData = async () => {
    try {
      const result = await getRequest("Leavetypes/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.name });
      });
      setOption(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getDesignationData = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetDesignations");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.name });
      });
      setDesignationList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getAreaData = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.name });
      });

      setAreaList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getRoleData = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetRoles ");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.level, value: item.level });
      });
      setRoleList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getRoleData();
    getAreaData();
    getDesignationData();
    getDepartmentData();
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log('data', data)

  useEffect(() => {
    if (option.length >= 0) {
      data?.leaveTypes?.applicable?.department?.split(",").map((item) => {
        let tempVal = option?.filter((options) => options?.label == item);
        if (tempVal.length > 0) {
          setDepartmentValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
    if (designationList.length >= 0) {
      data?.leaveTypes?.applicable?.designation?.split(",").map((item) => {
        let tempVal = designationList?.filter(
          (options) => options?.label == item
        );
        if (tempVal.length > 0) {
          setDesignationValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
    if (areaList.length >= 0) {
      data?.leaveTypes?.applicable?.locationName?.split(",").map((item) => {
        let tempVal = areaList?.filter((options) => options?.label == item);
        if (tempVal.length > 0) {
          setAreaValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
    if (roleList.length >= 0) {
      data?.leaveTypes?.applicable?.role?.split(",").map((item) => {
        let tempVal = roleList?.filter((options) => options?.label == item);
        if (tempVal.length > 0) {
          setRoleValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
    if (employmentOptions.length >= 0) {
      data?.leaveTypes?.applicable?.employmentType?.split(",").map((item) => {
        let tempVal = employmentOptions?.filter(
          (options) => options?.label == item
        );
        if (tempVal.length > 0) {
          setEmpTypeValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
    if (hireOptions.length >= 0) {
      data?.leaveTypes?.applicable?.sourceOfHire?.split(",").map((item) => {
        let tempVal = hireOptions?.filter((options) => options?.label == item);
        if (tempVal.length > 0) {
          setHireValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
    if (onboardingOptions.length >= 0) {
      data?.leaveTypes?.applicable?.onboardingStatus?.split(",").map((item) => {
        let tempVal = onboardingOptions?.filter(
          (options) => options?.label == item
        );
        if (tempVal.length > 0) {
          setOnboardingValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
    if (employeeList.length >= 0) {
      data?.leaveTypes?.applicable?.employee?.split(",").map((item) => {
        let tempVal = employeeList?.filter((options) => options?.label == item);
        if (tempVal.length > 0) {
          setEmployeeValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
  }, [
    designationList,
    option,
    areaList,
    roleList,
    employmentOptions,
    hireOptions,
    onboardingOptions,
    employeeList,
  ]);

  const returnPage = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"Gender"} />
          <SelectForm
            placeholder={" Select Gender "}
            values={
              data
                ? genderOptions.filter(
                    (options) => options.label === data?.applicable?.gender
                  )
                : opt
            }
            name="gender"
            options={genderOptions}
          />
        </div>
        <div>
          <LabelCustom labelName={"Marital Status"} />
          <SelectForm
            placeholder={" Select Marital Status "}
            values={
              data
                ? marriageOptions.filter(
                    (options) =>
                      options.label === data?.applicable?.maritalStatus
                  )
                : opt
            }
            name="maritalStatus"
            options={marriageOptions}
          />
        </div>
        <div>
          <LabelCustom labelName={"Department"} />

          <SelectFormikMultiString
            values={departmentValuesArray}
            name={"department"}
            options={option}
            placeholder={" Select Department "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Designation"} />

          <SelectFormikMultiString
            values={designationValuesArray}
            name={"designation"}
            options={designationList}
            placeholder={" Select Designation "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Location"} />

          <SelectFormikMultiString
            values={areaValuesArray}
            name={"locationName"}
            options={areaList}
            placeholder={" Select Location "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Role"} />

          <SelectFormikMultiString
            values={roleValuesArray}
            name={"role"}
            options={roleList}
            placeholder={" Select Role "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Employment Type"} />

          <SelectFormikMultiString
            values={empTypeValuesArray}
            name={"employmentType"}
            options={employmentOptions}
            placeholder={" Select Employment Type"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Source Of Hire"} />

          <SelectFormikMultiString
            values={hireValuesArray}
            name={"sourceOfHire"}
            options={hireOptions}
            placeholder={" Select Source Of Hire "}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LabelCustom labelName={"Onboarding Status"} />

          <SelectFormikMultiString
            values={onboardingValuesArray}
            name={"onboardingStatus"}
            options={onboardingOptions}
            placeholder={" Select Status "}
          />

          <span style={{ alignSelf: "flex-end", paddingTop: "30px" }}>
            (OR)
          </span>
        </div>
      </div>
      <div className="formcustom" style={{ paddingTop: "0" }}>
        <div>
          <LabelCustom labelName={"Employee"} />

          <SelectFormikMultiString
            menuPlacement={"bottom"}
            values={employeeValuesArray}
            name={"employee"}
            options={employeeList}
            placeholder={" Select Employee "}
          />
        </div>
      </div>
    </>
  );
};

export { Applicable };
