import React from 'react';

// Function to create the story lines
const StatusCircles = ({ numOfSlice, numOfFill }) => {

    const slice = (numOfSlice, numOfFill) => {
        let slices = [];
        const borderWidth = 0.16;
        const strokeWidth = 0.08; // Adjust strokeWidth as needed

        // Setting up slice colors
        for (let i = 0; i < numOfSlice; i++) {
            let tempColor = i <= numOfFill - 1 ? "rgba(18, 150, 176, 1)" : "rgba(191, 191, 191, 1)";
            slices.push({ percent: 1 / numOfSlice, color: tempColor });
        }

        let cumulativePercent = 0;

        // Function to get coordinates for the SVG circle
        function getCoordinatesForPercent(percent) {
            const x = Math.cos(2 * Math.PI * percent);
            const y = Math.sin(2 * Math.PI * percent);
            return [x, y];
        }

        return slices.map((slice, index) => {
            const startPercent = cumulativePercent;
            cumulativePercent += slice.percent;

            const [startX, startY] = getCoordinatesForPercent(startPercent);
            const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

            const largeArcFlag = slice.percent > 0.5 ? 1 : 0;
            const pathData = [
                `M ${startX} ${startY}`, // Move
                `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
                `L ${endX * (1 - borderWidth)} ${endY * (1 - borderWidth)}`, // Line
                `A ${1 - borderWidth} ${1 - borderWidth} 0 ${largeArcFlag} 0 ${startX * (1 - borderWidth)} ${startY * (1 - borderWidth)}`, // Arc
                'Z', // Close path
            ].join(' ');

            const outerRadius = 1;
            const innerRadius = 1 - borderWidth;
            const pathData1 = `
                M ${outerRadius} 0
                A ${outerRadius} ${outerRadius} 0 1 0 ${-outerRadius} 0
                A ${outerRadius} ${outerRadius} 0 1 0 ${outerRadius} 0
                M ${innerRadius} 0
                A ${innerRadius} ${innerRadius} 0 1 1 ${-innerRadius} 0
                A ${innerRadius} ${innerRadius} 0 1 1 ${innerRadius} 0
                Z
            `;

            return (
                <svg
                    key={index}
                    height="45"
                    width="45"
                    viewBox="-1 -1 2 2"
                    style={{
                        position: 'absolute',
                        transform: 'rotate(-90deg)'
                    }}
                >
                    <path
                        d={numOfSlice === 1 ? pathData1 : pathData}
                        fill={slice.color}
                        stroke="white"
                        strokeWidth={strokeWidth}
                    />
                </svg>
            );
        });
    }

    return (
        <div style={{ width: 45, height: 45 }}>
            {slice(numOfSlice, numOfFill)}
        </div>
    );
}

export default StatusCircles;
