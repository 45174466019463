import React from 'react'
import { useEffect } from 'react';

const BlankComp = ({date, title, desc}) => {
  useEffect(() => {
    document.title = `PeopleSol - Bank`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="attendanceContentContainer" style={{backgroundColor:`white`}}>
       <div className="dateDisplay">{date ? date : ""}</div>
     {/* <div className="horizontalLinePrimary calendarLine" style={{backgroundColor:`black`}}></div> */}

        <div className='attendanceContentStatus' style={{color:`black`}} >{title ? title : ""}</div>
        <div style={{display:'flex'}}>
           
        <label className='attendanceIn' style={{color:'black', fontSize:'13px'}}>{desc ? desc : ""}</label>
            </div>
        
        </div>
  )
}

export {BlankComp}
