import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { format } from "date-fns";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";
import { getModuleDetails } from "../../../../Utilities/getModuleDetails";
import DetailsIcon from "../../../../Assets/DetailsIcon.svg";

export const GetOpenTask = ({ navigateToRoute }) => {
  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil((detailsform?.length || 0) / itemsPerPage);

  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest("Settings/GetOpenTask");
      setDetailsform(result?.data?.data?.task);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFormDetails();
    document.title = `PeopleSol - Open Task`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const slicedData = detailsform?.slice(startIndex, endIndex);
  return (
    <>
      {loading ? (
        <Loader />
      ) : detailsform.length > 0 ? (
        <div style={{ height: "100%" }}>
          <div style={{ height: "560px" }}>
            <table className="responsive-table tableHeaderRow">
              {/* Table Header */}
              <thead style={{ position: "sticky" }}>
                <tr>
                  <th>S no.</th>
                  <th style={{ width: "90px" }}></th>
                  <th>Request Number</th>
                  <th>Description</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Deadline</th>
                  <th>Priority</th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody className="scrollable-body">
                {detailsform.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        className="cursor"
                        // onClick={() => {
                        //   const moduleDetails = getModuleDetails(
                        //     "Work & Tasks",
                        //     item?.id
                        //   );
                        //   if (moduleDetails?.returnUrl) {
                        //     navigateToRoute(moduleDetails.returnUrl);
                        //   } else {
                        //   }
                        // }}
                        onClick={() => {
                          navigateToRoute(item?.url);
                        }}
                        src={DetailsIcon}
                        alt=""
                      />
                    </td>
                    <td>{item?.requestNumber}</td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                      }}
                    >
                      {item?.description || "-"}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                      }}
                    >
                      {item?.title || "-"}
                    </td>
                    <td>
                      <div
                        style={{
                          backgroundColor: getStatusColor(item?.status)?.split(
                            ","
                          )?.[0],
                          color: getStatusColor(item?.status)?.split(",")?.[1],
                          cursor: "default",
                          padding: "2px 6px",
                          borderRadius: "4px",
                        }}
                      >
                        {item?.status}
                      </div>
                    </td>
                    <td>
                      {item?.endsOn
                        ? format(new Date(item?.endsOn), "dd/MM/yyyy")
                        : "-"}
                    </td>
                    <td>{item?.priority || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="noDatastyle" style={{ padding: "75px 105px" }}>
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>No Data Found</h4>
        </div>
      )}
    </>
  );
};
