import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateReimbursementComp } from "./FormComp/CreateReimbursementComp";
import { format } from "date-fns";
import { Receipt } from "@mui/icons-material";

const CreateReimbursement = () => {
  const { state } = useLocation();
  const data = state?.id;
  const [claimCount, setClaimCount] = useState(0);
  useEffect(() => {
    document.title = `PeopleSol - Create Reimbursement`;

    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [formValues, setFormValues] = useState(null);
  const [editIDData, seteditIDData] = useState(false);
  const [detailsform, setDetailsform] = useState({});
  const [resultData, setResultData] = useState([]);
  // const getInitialValues = (item) => {
  //   let returnArray = [
  //     {
  //       Date: "",
  //       ExpenseTypes: "",
  //       Amount: "",
  //       IsReceiptAvailable: "Yes",
  //       Upload: "",
  //       Remarks: "",
  //     },
  //   ];
  //   if (data && item) {
  //     if (item.length > 0) {
  //       returnArray = [];
  //       item?.map((items) => {
  //         return returnArray.push({
  //           Id: items.id,
  //           Date: items.date ? new Date(items.date) : null,
  //           ExpenseTypeId: items.expenseTypeId,
  //           Amount: items.amount,
  //           IsReceiptAvailable: items.isReceiptAvailable,
  //           Upload: items.expenseTypeId
  //             ? items.isReceiptAvailable === "Yes"
  //               ? items.attachment
  //                 ? `TravelBookings/${items.attachment}`
  //                 : ""
  //               : items.justification ?? ""
  //             : items.isReceiptAvailable === "Yes"
  //             ? items.attachment
  //               ? `Expenses/${items.attachment}`
  //               : ""
  //             : items.justification ?? "",

  //           Remarks: items?.remarks,
  //         });
  //       });
  //     }
  //   }
  //   return returnArray;
  // };
  // const getInitialValuesRepeated = (item, date, budget, bookinglist) => {
  //   let returnArray = [
  //     {
  //       Date: "",
  //       ExpenseTypeId: "",
  //       Amount: "",
  //       IsReceiptAvailable: "Yes",
  //       Upload: "",
  //       Remarks: "",
  //     },
  //   ];
  //   if (item) {
  //     if (item.length > 0) {
  //       returnArray = [];
  //       item?.map((item) => {
  //         return returnArray.push({
  //           Date: date ? new Date(date) : null,
  //           ExpenseTypeId: "",
  //           Amount: budget ? budget : "",
  //           IsReceiptAvailable: bookinglist[0]?.attachment ? "Yes" : "No",
  //           Upload: bookinglist[0]?.attachment
  //             ? `TravelBookings/${bookinglist[0]?.attachment}`
  //             : "",
  //           Remarks: "",
  //         });
  //       });
  //     }
  //   }
  //   return returnArray;
  // };
  const groupItemsMultiDetailsfunc = () => {
    let returnArray = [];
    data
      ? resultData?.travellingdetails?.map((it, index) => {
          return returnArray.push({
            FromDate: it?.fromDate ? new Date(it?.fromDate) : null,
            PickupLocation: it?.pickupLocation,
            DropLocation: it?.dropLocation,
            Receipt: it?.finalTicket,
            TravalId: it?.id,
            Expenselist: detailsform?.expenseDetails
              ?.filter((itz) => itz?.travellingdetailsid === it?.id)
              ?.map((item, index) => {
                return {
                  id: item?.id ?? null,
                  ExpenseTypes: data ? item?.expenseTypes : "",
                  Date: data ? new Date(item?.date) : "",
                  fromlocation: data ? item?.fromlocation : "",
                  tolocation: data ? item?.tolocation : "",
                  categoryid: data ? item?.categoryid : "",
                  type: data ? item?.type : "",
                  totalkm: data ? item?.totalkm : "",
                  cost: data ? item?.cost : "",
                  Amount: data ? item?.amount : "",
                  IsReceiptAvailable: data ? item?.isReceiptAvailable : "",
                  Upload: data ? item?.attachment : "",
                  Justification: data ? item?.justification : "",
                  Remarks: data ? item?.remarks : "",
                  // mode: data ? item?.mode : "",
                  totaltickets: data ? item?.totaltickets : "",
                  totalitems: data ? item?.totalitems : "",
                  totaldays: data ? item?.totaldays : "",
                };
              }),
          });
        })
      : (returnArray = [
          // {
          //   FromDate: null,
          //   PickupLocation: "",
          //   DropLocation: "",
          //   Receipt: null,
          //   Expenselist: [
          //     {
          //       // ExpenseTypeId: "",
          //       ExpenseTypes: "",
          //       Date: "",
          //       fromlocation: "",
          //       tolocation: "",
          //       categoryid: "",
          //       type: "",
          //       totalkm: "",
          //       cost: "",
          //       Amount: "",
          //       IsReceiptAvailable: "",
          //       Upload: "",
          //       Justification: "",
          //       Remarks: "",
          //       mode: "",
          //       totaltickets: "",
          //       totalitems: "",
          //       totaldays: "",
          //     },
          //   ],
          // },
        ]);
    return returnArray;
  };
  const groupItemsMultiHotelDetailsfunc = () => {
    let returnArray = [];
    data
      ? resultData?.hoteldetail?.map((it, index) => {
          return returnArray.push({
            HotelcheckInTime: it?.checkInTime
              ? new Date(it?.checkInTime)
              : null,
            HotelcheckOutTime: it?.checkOutTime
              ? new Date(it?.checkOutTime)
              : null,
            Hotellocation: it?.location ?? "",
            HotelpreferHotel: it?.preferHotel ?? "",
            Receipt: it?.finalTicket,
            TravalId: it?.id,
            Expenselist: detailsform?.expenseDetails
              ?.filter((itz) => itz?.travellingdetailsid === it?.id)
              ?.map((item, index) => {
                return {
                  id: item?.id ?? null,
                  ExpenseTypes: item?.expenseTypes,
                  Date: new Date(item?.date),
                  fromlocation: item?.fromlocation,
                  tolocation: item?.tolocation,
                  categoryid: item?.travellingCategoriesDetails?.category,
                  type: item?.travellingCategoriesDetails?.type,
                  totalkm: item?.totalkm,
                  cost: item?.cost,
                  Amount: item?.amount,
                  IsReceiptAvailable: item?.isReceiptAvailable,
                  Upload: item?.attachment,
                  Justification: item?.justification,
                  Remarks: item?.remarks,
                  // mode: item?.mode,
                  totaltickets: item?.totaltickets,
                  totalitems: item?.totalitems,
                  totaldays: item?.totaldays,
                };
              }),
          });
        })
      : (returnArray = [
          // {
          //   HotelcheckInTime: null,
          //   HotelcheckOutTime: null,
          //   Hotellocation: "",
          //   HotelpreferHotel: "",
          //   Receipt: null,
          //   Expenselist: [
          //     {
          //       // ExpenseTypeId: "",
          //       ExpenseTypes: "",
          //       Date: "",
          //       fromlocation: "",
          //       tolocation: "",
          //       categoryid: "",
          //       type: "",
          //       totalkm: "",
          //       cost: "",
          //       Amount: "",
          //       IsReceiptAvailable: "",
          //       Upload: "",
          //       Justification: "",
          //       Remarks: "",
          //       mode: "",
          //       totaltickets: "",
          //       totalitems: "",
          //       totaldays: "",
          //     },
          //   ],
          // },
        ]);
    return returnArray;
  };
  const groupItemsDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.expenseDetails?.map((item, index) => {
          return returnArray.push({
            id: item?.id ?? null,

            ExpenseTypes: data ? item?.expenseTypes : "",
            Date: data ? new Date(item?.date) : "",
            fromlocation: data ? item?.fromlocation : "",
            tolocation: data ? item?.tolocation : "",
            categoryid: data ? item?.categoryid : "",
            type: data ? item?.type : "",
            totalkm: data ? item?.totalkm : "",
            cost: data ? item?.cost : "",
            Amount: data ? item?.amount : "",
            IsReceiptAvailable: data ? item?.isReceiptAvailable : "",
            Upload: data ? item?.attachment : "",
            Justification: data ? item?.justification : "",
            Remarks: data ? item?.remarks : "",
            // mode: data ? item?.mode : "",
            totaltickets: data ? item?.totaltickets : "",
            totalitems: data ? item?.totalitems : "",
            totaldays: data ? item?.totaldays : "",
          });
        })
      : (returnArray = [
          {
            ExpenseTypes: "",
            Date: "",
            fromlocation: "",
            tolocation: "",
            categoryid: "",
            type: "",
            totalkm: "",
            cost: "",
            Amount: "",
            IsReceiptAvailable: "",
            Upload: "",
            Justification: "",
            Remarks: "",
            mode: "",
            totaltickets: "",
            totalitems: "",
            totaldays: "",
          },
        ]);
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    totalclaimed: "",
    TravalRequestId: null,
    Expenselist: groupItemsDetailsfunc(),
    groupItems: [],
    groupItemsHotel: [],
  });
  useEffect(() => {
    if (editIDData) {
      setInitialValues({
        // totalclaimed: data ? detailsform?.totalclaimed : 34,
        TravalRequestId: data ? detailsform?.travalRequestId : null,
        Expenselist: groupItemsDetailsfunc(),
        groupItems: groupItemsMultiDetailsfunc(),
        groupItemsHotel: groupItemsMultiHotelDetailsfunc(),
      });
    }
  }, [detailsform, resultData]);
  console.log("object formValues", formValues);
  console.log("object initialValues", initialValues);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async (id) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Expenses/Details/${id}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    data ? seteditIDData(true) : seteditIDData(false);
    if (data) getFormDetails(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Expenses/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Expenses/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getExpenseSchema = () => {
    return Yup.object().shape({
      ExpenseTypes: Yup.string().required("Expense Type Is required"),
      Date: Yup.string().required("Expense Date Is required"),
      fromlocation: Yup.string().when("ExpenseTypes", {
        is: (value) =>
          [
            "Taxi",
            "Self Vehicle",
            "Metro",
            "Local Train",
            "Local Bus",
          ].includes(value),
        then: Yup.string().required("Location Is required"),
        otherwise: Yup.string().notRequired(),
      }),
      tolocation: Yup.string().when("ExpenseTypes", {
        is: (value) =>
          [
            "Taxi",
            "Self Vehicle",
            "Metro",
            "Local Train",
            "Local Bus",
          ].includes(value),
        then: Yup.string().required("Location Is required"),
        otherwise: Yup.string().notRequired(),
      }),
      categoryid: Yup.string().required("Category Is required"),
      type: Yup.string().when("ExpenseTypes", {
        is: (value) => ["Taxi", "Self Vehicle"].includes(value),
        then: Yup.string().required("Type Is required"),
        otherwise: Yup.string().notRequired(),
      }),
      totalkm: Yup.string().when("ExpenseTypes", {
        is: (value) => ["Taxi", "Self Vehicle"].includes(value),
        then: Yup.string().required("Total KM Is required"),
        otherwise: Yup.string().notRequired(),
      }),
      totaltickets: Yup.string().when("ExpenseTypes", {
        is: (value) => ["Metro", "Local Train", "Local Bus"].includes(value),
        then: Yup.string().required("Total Tickets Is required"),
        otherwise: Yup.string().notRequired(),
      }),
      totalitems: Yup.string().when("ExpenseTypes", {
        is: (value) => ["Gift", "Gadget"].includes(value),
        then: Yup.string().required("Total Items Is required"),
        otherwise: Yup.string().notRequired(),
      }),
      totaldays: Yup.string().when("ExpenseTypes", {
        is: "Food",
        then: Yup.string().required("Total Days Is required"),
        otherwise: Yup.string().notRequired(),
      }),
      cost: Yup.string().required("Cost Is required"),
      Amount: Yup.string().required("Claim Amount Is required"),
      IsReceiptAvailable: Yup.string().required("Required"),
      Upload: Yup.string().when("IsReceiptAvailable", {
        is: "Yes",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
      Justification: Yup.string().required("Please Enter Reason"),
      Remarks: Yup.string().required("Please Enter Remarks"),
    });
  };

  // const getValidationSchema = (formValues) => {
  //   if (!formValues?.TravalRequestId) {
  //     return Yup.object({
  //       TravalRequestId: Yup.string().required("Location Is required"),
  //       Expenselist: Yup.array().of(getExpenseSchema()),
  //     });
  //   }

  //   if (formValues?.TravalRequestId && formValues?.groupItems?.length > 0) {
  //     return Yup.object({
  //       TravalRequestId: Yup.string().required("Location Is required"),
  //       groupItems: Yup.array().of(
  //         Yup.object().shape({
  //           Expenselist: Yup.array().of(getExpenseSchema()),
  //         })
  //       ),
  //     });
  //   }

  //   if (
  //     formValues?.TravalRequestId &&
  //     formValues?.groupItemsHotel?.length > 0
  //   ) {
  //     return Yup.object({
  //       TravalRequestId: Yup.string().required("Location Is required"),
  //       groupItemsHotel: Yup.array().of(
  //         Yup.object().shape({
  //           Expenselist: Yup.array().of(getExpenseSchema()),
  //         })
  //       ),
  //     });
  //   }

  //   if (
  //     formValues?.TravalRequestId &&
  //     formValues?.groupItemsHotel?.length > 0 &&
  //     formValues?.groupItems?.length > 0
  //   ) {
  //     return Yup.object({
  //       TravalRequestId: Yup.string().required("Location Is required"),
  //       groupItems: Yup.array().of(
  //         Yup.object().shape({
  //           Expenselist: Yup.array().of(getExpenseSchema()),
  //         })
  //       ),
  //       groupItemsHotel: Yup.array().of(
  //         Yup.object().shape({
  //           Expenselist: Yup.array().of(getExpenseSchema()),
  //         })
  //       ),
  //     });
  //   }

  //   return Yup.object(); // Default schema if none of the conditions match
  // };

  // // Usage
  // const validationSchema = getValidationSchema(formValues);

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    formData.append("totalclaimed", claimCount);

    if (!values?.TravalRequestId) {
      values?.Expenselist?.forEach((item, index) => {
        formData.append(
          `Expenselist[${index}].ExpenseTypes`,
          item?.ExpenseTypes
        );
        if (
          item?.ExpenseTypes === "Taxi" ||
          item?.ExpenseTypes === "Self Vehicle"
        ) {
          formData.append(
            `Expenselist[${index}].Date`,
            format(new Date(item?.Date), "yyyy-MM-dd")
          );
          formData.append(
            `Expenselist[${index}].fromlocation`,
            item?.fromlocation
          );
          formData.append(`Expenselist[${index}].tolocation`, item?.tolocation);
          formData.append(`Expenselist[${index}].categoryid`, item?.categoryid);
          formData.append(`Expenselist[${index}].type`, item?.type);
          formData.append(`Expenselist[${index}].totalkm`, item?.totalkm);
          formData.append(`Expenselist[${index}].cost`, item?.cost);
          formData.append(`Expenselist[${index}].Amount`, item?.Amount);
          formData.append(
            `Expenselist[${index}].IsReceiptAvailable`,
            item?.IsReceiptAvailable
          );
          formData.append(`Expenselist[${index}].Upload`, item?.Upload);
          formData.append(
            `Expenselist[${index}].Justification`,
            item?.Justification
          );
          formData.append(`Expenselist[${index}].Remarks`, item?.Remarks);
        }
        if (item?.ExpenseTypes === "Food" || item?.ExpenseTypes === "Gadget") {
          formData.append(
            `Expenselist[${index}].Date`,
            format(new Date(item?.Date), "yyyy-MM-dd")
          );
          formData.append(`Expenselist[${index}].categoryid`, item?.categoryid);
          if (item?.ExpenseTypes === "Food") {
            formData.append(`Expenselist[${index}].totaldays`, item?.totaldays);
          } else {
            formData.append(
              `Expenselist[${index}].totalitems`,
              item?.totalitems
            );
          }
          formData.append(`Expenselist[${index}].cost`, item?.cost);
          formData.append(`Expenselist[${index}].Amount`, item?.Amount);
          formData.append(
            `Expenselist[${index}].IsReceiptAvailable`,
            item?.IsReceiptAvailable
          );
          formData.append(`Expenselist[${index}].Upload`, item?.Upload);
          formData.append(
            `Expenselist[${index}].Justification`,
            item?.Justification
          );
          formData.append(`Expenselist[${index}].Remarks`, item?.Remarks);
        }
        if (
          item?.ExpenseTypes === "Metro" ||
          item?.ExpenseTypes === "Local Train" ||
          item?.ExpenseTypes === "Local Bus"
        ) {
          formData.append(
            `Expenselist[${index}].Date`,
            format(new Date(item?.Date), "yyyy-MM-dd")
          );
          formData.append(
            `Expenselist[${index}].fromlocation`,
            item?.fromlocation
          );
          formData.append(`Expenselist[${index}].tolocation`, item?.tolocation);
          formData.append(`Expenselist[${index}].categoryid`, item?.categoryid);
          formData.append(
            `Expenselist[${index}].totaltickets`,
            item?.totaltickets
          );
          formData.append(`Expenselist[${index}].cost`, item?.cost);
          formData.append(`Expenselist[${index}].Amount`, item?.Amount);
          formData.append(
            `Expenselist[${index}].IsReceiptAvailable`,
            item?.IsReceiptAvailable
          );
          formData.append(`Expenselist[${index}].Upload`, item?.Upload);
          formData.append(
            `Expenselist[${index}].Justification`,
            item?.Justification
          );
          formData.append(`Expenselist[${index}].Remarks`, item?.Remarks);
        }
      });
    }
    if (values?.TravalRequestId) {
      let num = 0;
      formData.append("TravalRequestId", values?.TravalRequestId);

      values?.groupItems?.forEach((itemz, indexz) => {
        itemz?.Expenselist?.forEach((item, index) => {
          formData.append(
            `Expenselist[${num}].travellingdetailsid`,
            itemz?.TravalId
          );
          formData.append(
            `Expenselist[${num}].ExpenseTypes`,
            item?.ExpenseTypes
          );
          if (
            item?.ExpenseTypes === "Taxi" ||
            item?.ExpenseTypes === "Self Vehicle"
          ) {
            formData.append(
              `Expenselist[${num}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(
              `Expenselist[${num}].fromlocation`,
              item?.fromlocation
            );
            formData.append(
              `Expenselist[${num}].travellingdetailsid`,
              item?.tolocation
            );
            formData.append(`Expenselist[${num}].tolocation`, item?.tolocation);
            formData.append(`Expenselist[${num}].categoryid`, item?.categoryid);
            formData.append(`Expenselist[${num}].type`, item?.type);
            formData.append(`Expenselist[${num}].totalkm`, item?.totalkm);
            formData.append(`Expenselist[${num}].cost`, item?.cost);
            formData.append(`Expenselist[${num}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${num}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            formData.append(`Expenselist[${num}].Upload`, item?.Upload);
            formData.append(
              `Expenselist[${num}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
          }
          if (
            item?.ExpenseTypes === "Food" ||
            item?.ExpenseTypes === "Gadget"
          ) {
            formData.append(
              `Expenselist[${num}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(`Expenselist[${num}].categoryid`, item?.categoryid);
            if (item?.ExpenseTypes === "Food") {
              formData.append(`Expenselist[${num}].totaldays`, item?.totaldays);
            } else {
              formData.append(
                `Expenselist[${num}].totalitems`,
                item?.totalitems
              );
            }
            formData.append(`Expenselist[${num}].cost`, item?.cost);
            formData.append(`Expenselist[${num}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${num}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            formData.append(`Expenselist[${num}].Upload`, item?.Upload);
            formData.append(
              `Expenselist[${num}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
          }
          if (
            item?.ExpenseTypes === "Metro" ||
            item?.ExpenseTypes === "Local Train" ||
            item?.ExpenseTypes === "Local Bus"
          ) {
            formData.append(
              `Expenselist[${num}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(
              `Expenselist[${num}].fromlocation`,
              item?.fromlocation
            );
            formData.append(`Expenselist[${num}].tolocation`, item?.tolocation);
            formData.append(`Expenselist[${num}].categoryid`, item?.categoryid);
            formData.append(
              `Expenselist[${num}].totaltickets`,
              item?.totaltickets
            );
            formData.append(`Expenselist[${num}].cost`, item?.cost);
            formData.append(`Expenselist[${num}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${num}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            formData.append(`Expenselist[${num}].Upload`, item?.Upload);
            formData.append(
              `Expenselist[${num}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
          }
          num++;
        });
      });
      values?.groupItemsHotel?.forEach((itemz, indexz) => {
        itemz?.Expenselist?.forEach((item, index) => {
          formData.append(
            `Expenselist[${num}].travellingdetailsid`,
            itemz?.TravalId
          );
          formData.append(
            `Expenselist[${num}].ExpenseTypes`,
            item?.ExpenseTypes
          );
          if (
            item?.ExpenseTypes === "Taxi" ||
            item?.ExpenseTypes === "Self Vehicle"
          ) {
            formData.append(
              `Expenselist[${num}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(
              `Expenselist[${num}].fromlocation`,
              item?.fromlocation
            );
            formData.append(
              `Expenselist[${num}].travellingdetailsid`,
              item?.tolocation
            );
            formData.append(`Expenselist[${num}].tolocation`, item?.tolocation);
            formData.append(`Expenselist[${num}].categoryid`, item?.categoryid);
            formData.append(`Expenselist[${num}].type`, item?.type);
            formData.append(`Expenselist[${num}].totalkm`, item?.totalkm);
            formData.append(`Expenselist[${num}].cost`, item?.cost);
            formData.append(`Expenselist[${num}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${num}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            formData.append(`Expenselist[${num}].Upload`, item?.Upload);
            formData.append(
              `Expenselist[${num}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
          }
          if (
            item?.ExpenseTypes === "Food" ||
            item?.ExpenseTypes === "Gadget"
          ) {
            formData.append(
              `Expenselist[${num}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(`Expenselist[${num}].categoryid`, item?.categoryid);
            if (item?.ExpenseTypes === "Food") {
              formData.append(`Expenselist[${num}].totaldays`, item?.totaldays);
            } else {
              formData.append(
                `Expenselist[${num}].totalitems`,
                item?.totalitems
              );
            }
            formData.append(`Expenselist[${num}].cost`, item?.cost);
            formData.append(`Expenselist[${num}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${num}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            formData.append(`Expenselist[${num}].Upload`, item?.Upload);
            formData.append(
              `Expenselist[${num}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
          }
          if (
            item?.ExpenseTypes === "Metro" ||
            item?.ExpenseTypes === "Local Train" ||
            item?.ExpenseTypes === "Local Bus"
          ) {
            formData.append(
              `Expenselist[${num}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(
              `Expenselist[${num}].fromlocation`,
              item?.fromlocation
            );
            formData.append(`Expenselist[${num}].tolocation`, item?.tolocation);
            formData.append(`Expenselist[${num}].categoryid`, item?.categoryid);
            formData.append(
              `Expenselist[${num}].totaltickets`,
              item?.totaltickets
            );
            formData.append(`Expenselist[${num}].cost`, item?.cost);
            formData.append(`Expenselist[${num}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${num}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            formData.append(`Expenselist[${num}].Upload`, item?.Upload);
            formData.append(
              `Expenselist[${num}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
          }
          num++;
        });
      });
    }

    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      styles={{ minWidth: "80%" }}
      loading={loading}
      // styles={{ minWidth: "75%", maxWidth: "75%" }}
      FormHeading={data ? "Edit Reimbursement" : "Create Reimbursement"}
      ButtonLabel={data ? "Edit Reimbursement" : "Create Reimbursement"}
      onSubmit={onSubmit}
      // validationSchema={validationSchema}
      chooseFormValues={chooseFormValues}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={
        <CreateReimbursementComp
          setResultData={setResultData}
          editIDData={editIDData}
          seteditIDDatafunc={() => seteditIDData(false)}
          formValues={formValues}
          loading={loading}
          setInitialValues={setInitialValues}
          setClaimCount={setClaimCount}
          claimCount={claimCount}
          resultData={resultData}
          // getInitialValuesRepeated={getInitialValuesRepeated}
        />
      }
    />
  );
};

export { CreateReimbursement };
