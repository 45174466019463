import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../Services/axios";
import { useLocation } from "react-router-dom";
import { SelectCustomEmployee } from "./SelectCustomEmployee";

function SelectCustomEmployeeCreate({
  setSelectedEmployeeList,
  selectedEmployeeList,
  multi = false,
  postMethod = false, //true if api is of post method
  urlProp = "Employees/GetEmployeesDropDowns",
  optionLabelName = "text", // label name for dropdowns
  optionValueName = "id", // value name for dropdowns
}) {
  const [listData, setListData] = useState([]);
  const [option, setOption] = useState([]);
  const [page, setPage] = useState(1);
  const [totalListLength, setTotalListLength] = useState(1);
  const [searchParamemployee, setSearchParamemployee] = useState("");
  const [countNonExist, setCountNonExist] = useState(0);
  const getOption = async (idArr, searchString, stringName) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        if (postMethod) {
          result = await postRequest(`${urlProp}?q=${searchString}`);
        } else result = await getRequest(`${urlProp}?q=${searchString}`);
        let searchData = [];
        setTotalListLength(result?.data?.total);

        result.data.results?.map((item) => {
          return searchData.push({
            label: item[optionLabelName],
            value: item[optionValueName],
          });
        });

        setOption(searchData);
      } else {
        if (idArr?.length > 0) {
          const text = idArr?.join(",");
          if (postMethod) {
            result = await postRequest(`${urlProp}?i=${page}&Id=${text}`);
          } else result = await getRequest(`${urlProp}?i=${page}&Id=${text}`);
        } else {
          if (postMethod) {
            result = await postRequest(`${urlProp}?i=${page}`);
          }
          result = await getRequest(`${urlProp}?i=${page}`);
        }
        setTotalListLength(result?.data?.total);
        let newItems = [];

        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item[optionValueName]
          );
          if (!exists) {
            newItems.push({
              label: item[optionLabelName],
              value: item[optionValueName],
            });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };
  useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);
  useEffect(() => {
    getOption([]);
  }, []);
  useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);
  return (
    <div>
      <SelectCustomEmployee
        values={selectedEmployeeList}
        onChange={(value) => {
          setSelectedEmployeeList([value]);
        }}
        multi={multi}
        onMenuScrollToBottom={handleScrollToBottom}
        options={searchParamemployee ? option : listData}
        onInputChange={(e) => setSearchParamemployee(e)}
      />
    </div>
  );
}

export default SelectCustomEmployeeCreate;
