
import { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
const ReqCategoryForm = () => {
  useEffect(() => {
    document.title = `PeopleSol - Request Category`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div >
      <LabelCustom labelName={"Name"} mandatory={true} />
      <InputCustomFormik

        maxLength={150}
        name="name"
        type={"text"}
        placeholder={"Enter Name"}
      />

    </div>
  );
};
export { ReqCategoryForm }