import React, { useEffect } from "react";
import { useState } from "react";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useSelector } from "react-redux";
import "./Connect.css";
import SocialNewPostBottomImageIcon from "../../../../../Assets/SocialNewPostBottomImageIcon.svg";
import SocialNewPostBottomImageGreyIcon from "../../../../../Assets/SocialNewPostBottomImageGreyIcon.svg";
import SocialInputVideoGreyIcon from "../../../../../Assets/SocialInputVideoGreyIcon.svg";
import SocialInputDocumentGreyIcon from "../../../../../Assets/SocialInputDocumentGreyIcon.svg";
import SocialNewPostBottomGreetingOptionsIcon from "../../../../../Assets/SocialNewPostBottomGreetingOptionsIcon.svg";
import SocialNpWelcomeIcon from "../../../../../Assets/SocialNpWelcomeIcon.svg";
import SocialNpWorkAnnyIcon from "../../../../../Assets/SocialNpWorkAnnyIcon.svg";
import SocialNpBirthdayIcon from "../../../../../Assets/SocialNpBirthdayIcon.svg";
import ScoialNpGiveKudosIcon from "../../../../../Assets/SocialNpGiveKudosIcon.svg";
import SocialNewPostBottomGreetingOptionsGreyIcon from "../../../../../Assets/SocialNewPostBottomGreetingOptionsGreyIcon.svg";
import SocialPostImageDeleteIcon from "../../../../../Assets/SocialPostImageDeleteIcon.svg";
import SocialInputVideoIcon from "../../../../../Assets/SocialInputVideoIcon.svg";
import SocialInputDcoumentIcon from "../../../../../Assets/SocialInputDcoumentIcon.svg";
import SearchMagnifierIcon from "../../../../../Assets/SearchMagnifierIcon.svg";
import ArrowBackButton from "../../../../../Assets/ArrowBackButton.svg";
import SocialPostGifIcon from "../../../../../Assets/SocialPostGifIcon.svg";
import SocialPostGifGreyIcon from "../../../../../Assets/SocialPostGifGreyIcon.svg";
import SocialNewPostBottomSendGreyIcon from "../../../../../Assets/SocialNewPostBottomSendGreyIcon.svg";
import SocialNewPostBottomSendIcon from "../../../../../Assets/SocialNewPostBottomSendIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import PDFViewer from "./PDFViewer";
import { IconButton } from "@mui/material";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import {
  albumPostImages,
  profileImages,
} from "../../../../../Utilities/profileAndUploadImages";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import {
  getPersonNumber,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import ShowFullAttachmentsCreate from "./ShowFullAttachmentsCreate";
import { useNavigate, useParams } from "react-router-dom";
import { Mention, MentionsInput } from "react-mentions";
import GifLibrary from "./GifLibrary";
import { setEmployeeListReduxData } from "../../../../../Features/SocialSlice";
import InfiniteScroll from "react-infinite-scroll-component";

function CreateCommunityPost({ getData }) {
  const [createPostActive, setCreatePostActive] = useState(true);
  const [allGreetingsImages, setAllGreetingsImages] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [employeeSelectionActive, setEmployeeSelectionActive] = useState(false);
  const [newPostActive, setNewPostActive] = useState(createPostActive);
  const [selectedGreetings, setSelectedGreetings] = useState("");
  const [selectPostTypeActive, setSelectPostTypeActive] = useState(false);
  const [newPostGreetingsActive, setNewPostGreetingsActive] = useState(false);
  const [welcomeOptionGreetingsActive, setWelcomeOptionGreetingsActive] =
    useState(false);
  const [page, setPage] = useState(1);
  const [SubType, setSubType] = useState("");
  const [newPostTextData, setNewPostTextData] = useState("");
  const [isImgSelecting, setIsImgSelecting] = useState(null);
  const [isGifSelecting, setIsGifSelecting] = useState(null);
  const [isGreetingSelecting, setIsGreetingSelecting] = useState(null);
  const [isDocSelecting, setIsDocSelecting] = useState(null);
  const [isVideoSelecting, setIsVideoSelecting] = useState(null);
  const [newPostImg, setNewPostImg] = useState(null);
  const [newPostVideo, setNewPostVideo] = useState(null);
  const [newPostDoc, setNewPostDoc] = useState(null);
  const [newPostAttachmentData, setNewPostAttachmentData] = useState(null);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [count, setCount] = useState();
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [presentEmployeeData, setPresentEmployeeData] = useState([]);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [fileType, setFileType] = useState("");
  const [allCommunityList, setAllCommunityList] = useState();
  const [chatList, setChatList] = useState();
  const [loading, setLoading] = useState(false);
  const [isGreetings, setIsGreetings] = useState(false);
  const [showFullAttachmentActive, setShowFullAttachmentActive] =
    useState(false);
  const [gifLibActive, setGifLibActive] = useState(false);
  const [gifLibActiveForSocial, setGifLibActiveForSocial] = useState(false);
  const [gifForSocial, setGifForSocial] = useState(null);
  const [gif, setGif] = useState(null);
  const { communityid } = useParams();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const getCommunityData = async () => {
    try {
      const result = await postRequest("Community/AllList", {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 100,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      setAllCommunityList(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        `Social/GetEmployeesImageDropDown?i=${page}`
      );
      let x = result?.data?.textfilters?.filter((d) => {
        if (d?.id !== LoggedInUser?.id) {
          return d;
        }
      });
      setAllEmployeeList([...allEmployeeList, ...x]);
      setSelectEmployee([...selectEmployee, ...x]);
      setPresentEmployeeData(result?.data?.textfilters);
      setPage((prev) => prev + 1);
      setCount(result?.data?.allCount);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  function searchChatters(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(selectEmployee));
      tempChatters = tempChatters.filter((d, i) =>
        d.Name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
      );
      setChatList(tempChatters);
    } else {
      setChatList(selectEmployee);
    }
  }
  const getAllImages = async (subtype) => {
    setLoading(true);
    try {
      const result = await getRequest(
        `SocialPost/GetCelibrationPhoto?subType=${subtype}`
      );
      setAllGreetingsImages(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const uploadNewPost = async () => {
    setLoading(true);
    var bodyFormData = new FormData();
    let response = null;
    if (SubType !== "") {
      bodyFormData.append("SubType", SubType);
      if (selectedGreetings) {
        bodyFormData.append("Attachment", selectedGreetings);
      } else if (gif) {
        response = await fetch(gif?.image?.url);
        const image = await response.blob();
        const imageFile = new File([image], gif?.name, {
          type: "image/gif",
        });
        bodyFormData.append("Upload", imageFile);
      }
      bodyFormData.append("MentionEmployeeId", selectedRecipient.id);
    } else {
      if (newPostAttachmentData) {
        for (let i = 0; i < newPostAttachmentData.length; i++) {
          const originalFile = newPostAttachmentData[i];
          const newName = originalFile.name.replace(/,/g, "-").trim();
          const updatedFile = new File([originalFile], newName, {
            type: originalFile.type,
          });
          bodyFormData.append("Upload", updatedFile);
        }
      } else if (gifForSocial) {
        response = await fetch(gifForSocial?.image?.url);
        const image = await response.blob();
        const imageFile = new File([image], gifForSocial?.name, {
          type: "image/gif",
        });
        bodyFormData.append("Upload", imageFile);
      }
    }
    bodyFormData.append("CommunityId", communityid);
    bodyFormData.append("Content", newPostTextData?.trim());
    bodyFormData.append("PostType", "community");
    bodyFormData.append("Type", "Social");
    try {
      const result = await postRequestForm(`SocialPost/Create`, bodyFormData);
      if (result?.status === 200) {
        if (!window.location.pathname.includes("userprofile")) {
          getData(true);
        }
        setNewPostImg(null);
        setNewPostVideo(null);
        setCreatePostActive(false);
        setSelectPostTypeActive(false);
        // navigate(-1);
      }
    } catch (error) {
      if (error?.status === 400) {
        dispatch(
          showToast({
            text: "Some error occured please try again!",
            severity: "error",
          })
        );
        return;
      }
      dispatch(
        showToast({
          text: "Some error occured please try again!",
          severity: "error",
        })
      );
    } finally {
      setIsImgSelecting(false);
      setIsVideoSelecting(false);
      setNewPostActive(false);
      setLoading(false);
      setSelectPostTypeActive(false);
      navigate(-1);
    }
  };
  useEffect(() => {
    getCommunityData();
    getEmployeeData();
    // const handleTextareaInput = () => {
    //   const textarea = document.getElementById("myTextarea");
    //   textarea.style.height = "auto";
    //   textarea.style.height = textarea.scrollHeight + 10 + "px";
    // };

    // const textarea = document.getElementById("myTextarea");
    // textarea.addEventListener("input", handleTextareaInput);

    // return () => {
    //   textarea.removeEventListener("input", handleTextareaInput);
    // };
  }, []);

  return (
    <div>
      {newPostActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          popupHeading={
            <div className="newPostHeaderContainer">
              <div
                className={`${
                  LoggedInUser?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePic"
                }`}
                style={{ width: "50px", height: "50px", margin: "0" }}
              >
                {!LoggedInUser?.image ? (
                  getUserInitials(getDisplayName(LoggedInUser?.name))
                ) : (
                  <img
                    className="commentProfilePic"
                    onClick={() => {
                      // handleUserProfileClick(d?.id);
                    }}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      LoggedInUser?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="newPostHeaderTextContainer">
                <h5>{LoggedInUser?.name}</h5>
                <p>{LoggedInUser?.designation}</p>
                <span>{LoggedInUser?.area}</span>
              </div>
            </div>
          }
          content={
            <div className="newPostBodyContainer">
              <InfiniteScroll
                dataLength={allEmployeeList?.length}
                next={getEmployeeData}
                hasMore={presentEmployeeData?.length < 20 ? false : true}
                height={300}
                width={200}
              >
                <MentionsInput
                  placeholder=" What do you want to talk about? "
                  autoFocus
                  value={newPostTextData}
                  onChange={(e) => {
                    if (e.target.value?.length > 4000) {
                      dispatch(
                        showToast({
                          text: "You have exceeded the maximum character limit, Contenyt should be less than 4000 characters!",
                          severity: "error",
                        })
                      );
                    } else {
                      setNewPostTextData(e.target.value);
                    }
                  }}
                  maxLength={4000}
                  a11ySuggestionsListLabel={" What do you want to talk about? "}
                  className={"createPostInputText"}
                >
                  <Mention
                    trigger={"@"}
                    data={allEmployeeList?.map((d) => {
                      return { id: d?.id, display: getDisplayName(d?.name) };
                    })}
                  />
                </MentionsInput>
              </InfiniteScroll>

              {(selectedGreetings !== "" || gif) && (
                <div style={{ position: "relative" }}>
                  <img
                    src={
                      gif && gif?.type !== "gif"
                        ? gif?.image?.url
                        : process.env.REACT_APP_.BASE_URL +
                          albumPostImages +
                          selectedGreetings
                    }
                    alt=""
                    style={{ marginLeft: "20px", width: "635px" }}
                  />
                  <img
                    src={SocialPostImageDeleteIcon}
                    alt=""
                    style={{ marginRight: "15px" }}
                    className="imageDeselectIcon"
                    onClick={() => {
                      setSelectedGreetings("");
                      setIsDocSelecting(null);
                      setIsGreetingSelecting(null);
                      setIsImgSelecting(null);
                      setIsVideoSelecting(null);
                    }}
                  />
                </div>
              )}
              {isGifSelecting && gifForSocial && (
                <div
                  style={{
                    position: "relative",
                    height: "400px",
                    widows: "600px",
                  }}
                >
                  <img
                    src={gifForSocial?.image?.url}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                  <img
                    src={SocialPostImageDeleteIcon}
                    alt=""
                    style={{ marginRight: "15px" }}
                    className="imageDeselectIcon"
                    onClick={() => {
                      setNewPostAttachmentData(null);
                      setIsDocSelecting(null);
                      setIsGreetingSelecting(null);
                      setIsImgSelecting(null);
                      setIsVideoSelecting(null);
                      setIsGifSelecting(null);
                    }}
                  />
                </div>
              )}
              {isImgSelecting && (
                <div
                  className={
                    newPostImg?.length === 1
                      ? `newPostBodyImgMainContainer`
                      : `newPostBodyImgMainWrapper`
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setNewPostAttachmentData(newPostImg);
                    setShowFullAttachmentActive(true);
                    setIsGreetings(false);
                    // getPostCommentData(d?.id);
                  }}
                >
                  {newPostImg?.length === 1 && (
                    <div
                      className="newPostBodyImgContainer"
                      style={{
                        position: "relative",
                        height: "360px",
                        width: "640px",
                      }}
                    >
                      <img src={newPostImg[0]?.url} alt="" />
                      <img
                        src={SocialPostImageDeleteIcon}
                        alt=""
                        className="imageDeselectIcon"
                        onClick={() => {
                          let x = newPostImg.filter(
                            (d) => d.url !== newPostImg[0]?.url
                          );
                          setNewPostImg(x);
                          setIsDocSelecting(null);
                          setIsGreetingSelecting(null);
                          setIsImgSelecting(null);
                          setIsVideoSelecting(null);
                        }}
                      />
                    </div>
                  )}
                  {newPostImg?.length === 2 && (
                    <div className="newPostBodyImgContainer2">
                      <div className="subImage2a">
                        <div style={{ position: "relative" }}>
                          <img src={newPostImg[0]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[0]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                      <div className="subImage2b">
                        <div style={{ position: "relative" }}>
                          <img src={newPostImg[1]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[1]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostImg?.length === 3 && (
                    <div className="newPostBodyImgContainer3">
                      <div
                        className="subImage3a"
                        style={{ position: "relative" }}
                      >
                        <img src={newPostImg[0]?.url} alt="" />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostImg.filter(
                              (d) => d.url !== newPostImg[0]?.url
                            );
                            setNewPostImg(x);
                          }}
                        />
                      </div>
                      <div className="subImage3b">
                        <div style={{ position: "relative", height: "165px" }}>
                          <img src={newPostImg[1]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[1]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "165px" }}>
                          <img src={newPostImg[2]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[2]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostImg?.length === 4 && (
                    <div className="newPostBodyImgContainer4">
                      <div
                        className="subImage4a"
                        style={{ position: "relative" }}
                      >
                        <img src={newPostImg[0]?.url} alt="" />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostImg.filter(
                              (d) => d.url !== newPostImg[0]?.url
                            );
                            setNewPostImg(x);
                          }}
                        />
                      </div>
                      <div className="subImage4b">
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[1]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[1]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[2]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[2]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[3]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[3]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostImg?.length > 4 && (
                    <div className="newPostBodyImgContainer4">
                      <div
                        className="subImage4a"
                        style={{ position: "relative" }}
                      >
                        <img src={newPostImg[0]?.url} alt="" />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostImg.filter(
                              (d) => d.url !== newPostImg[0]?.url
                            );
                            setNewPostImg(x);
                          }}
                        />
                      </div>
                      <div className="subImage4b">
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[1]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[1]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[2]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[2]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[3]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[3]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                      <div className="imgHideMainBox">
                        <div className="imgHideRoundBox">
                          <span>+ {newPostImg?.length - 4}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isVideoSelecting && (
                <div
                  className={
                    newPostVideo?.length === 1
                      ? `newPostBodyImgMainContainer`
                      : `newPostBodyImgMainWrapper`
                  }
                >
                  {newPostVideo?.length === 1 && (
                    <div
                      className="newPostBodyImgContainer"
                      style={{
                        position: "relative",
                        height: "360px",
                        width: "640px",
                      }}
                    >
                      <video
                        src={newPostVideo[0]?.url}
                        controls
                        width="630"
                        height="335"
                      />
                      <img
                        src={SocialPostImageDeleteIcon}
                        alt=""
                        className="imageDeselectIcon"
                        onClick={() => {
                          let x = newPostVideo.filter(
                            (d) => d.url !== newPostVideo[0]?.url
                          );
                          setNewPostVideo(x);
                          setIsDocSelecting(null);
                          setIsGreetingSelecting(null);
                          setIsImgSelecting(null);
                          setIsVideoSelecting(null);
                        }}
                      />
                    </div>
                  )}
                  {newPostVideo?.length === 2 && (
                    <div className="newPostBodyImgContainer2">
                      <div className="subImage2a">
                        <div style={{ position: "relative" }}>
                          <video
                            src={newPostVideo[0]?.url}
                            controls
                            width="315"
                            height="335"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[0]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                      <div className="subImage2b">
                        <div style={{ position: "relative" }}>
                          <video
                            src={newPostVideo[1]?.url}
                            controls
                            width="315"
                            height="335"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[1]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostVideo?.length === 3 && (
                    <div className="newPostBodyImgContainer3">
                      <div
                        className="subImage3a"
                        style={{ position: "relative" }}
                      >
                        <video
                          src={newPostVideo[0]?.url}
                          controls
                          width="315"
                          height="335"
                        />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostVideo.filter(
                              (d) => d.url !== newPostVideo[0]?.url
                            );
                            setNewPostVideo(x);
                          }}
                        />
                      </div>
                      <div className="subImage3b">
                        <div style={{ position: "relative", height: "165px" }}>
                          <video
                            src={newPostVideo[1]?.url}
                            controls
                            width="215"
                            height="165"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[1]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "165px" }}>
                          <video
                            src={newPostVideo[2]?.url}
                            controls
                            width="215"
                            height="165"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[2]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostVideo?.length === 4 && (
                    <div className="newPostBodyImgContainer4">
                      <div
                        className="subImage4a"
                        style={{ position: "relative" }}
                      >
                        <video
                          className="vdo"
                          src={newPostVideo[0]?.url}
                          controls
                          width="315"
                          height="335"
                        />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostVideo.filter(
                              (d) => d.url !== newPostVideo[0]?.url
                            );
                            setNewPostVideo(x);
                          }}
                        />
                      </div>
                      <div className="subImage4b">
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[1]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[1]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[2]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[2]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[3]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[3]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostVideo?.length > 4 && (
                    <div className="newPostBodyImgContainer4">
                      <div
                        className="subImage4a"
                        style={{ position: "relative" }}
                      >
                        <video
                          className="vdo"
                          src={newPostVideo[0]?.url}
                          controls
                          width="315"
                          height="335"
                        />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostVideo.filter(
                              (d) => d.url !== newPostVideo[0]?.url
                            );
                            setNewPostVideo(x);
                          }}
                        />
                      </div>
                      <div className="subImage4b">
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[1]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[1]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[2]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[2]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[3]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[3]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                      <div className="imgHideMainBox">
                        <div className="imgHideRoundBox">
                          <span>+ {newPostVideo?.length - 4}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {newPostDoc?.url && (
                <div style={{ position: "relative" }}>
                  <PDFViewer pdfUrl={newPostDoc?.url} />
                  <img
                    src={SocialPostImageDeleteIcon}
                    alt=""
                    className="imageDeselectIcon"
                    onClick={() => {
                      setNewPostDoc(null);
                      setIsImgSelecting(null);
                      setIsDocSelecting(null);
                      setIsGreetingSelecting(null);
                      setIsVideoSelecting(null);
                      setNewPostActive(true);
                    }}
                  />
                </div>
              )}

              <div className="newPostBottomContainer">
                <div className="newPostBottomWrapper">
                  <div className="activityIconContainer">
                    <div
                      className="imgIconContainer"
                      onClick={() => {
                        if (isImgSelecting !== false) {
                          document.querySelector("#newImagePost").click();
                        }
                      }}
                    >
                      {/* <img src={Object.keys(newPostData).length > 0? `${SocialNewPostBottomImageGreyIcon}`:`${SocialNewPostBottomImageIcon}`} alt=""/> */}
                      <img
                        src={
                          isImgSelecting === true || isImgSelecting === null
                            ? SocialNewPostBottomImageIcon
                            : SocialNewPostBottomImageGreyIcon
                        }
                        alt=""
                      />
                      <input
                        type="file"
                        id="newImagePost"
                        onChange={(e) => {
                          setFileType("image");
                          setIsImgSelecting(true);
                          setIsVideoSelecting(false);
                          setIsDocSelecting(false);
                          setIsGreetingSelecting(false);
                          setNewPostGreetingsActive(false);
                          setNewPostVideo(null);
                          if (e.currentTarget.files.length > 0) {
                            const selectedImagesToShow = [];
                            const selectedImagesToSend = [];
                            for (
                              let i = 0;
                              i < e.currentTarget.files.length;
                              i++
                            ) {
                              const imgFile = e.currentTarget.files[i];
                              selectedImagesToSend.push(imgFile);
                              selectedImagesToShow.push({
                                url: URL.createObjectURL(imgFile),
                                data: imgFile,
                              });
                            }
                            setNewPostImg(selectedImagesToShow);
                            setNewPostAttachmentData(selectedImagesToSend);
                          } else {
                            setNewPostAttachmentData([]);
                          }
                        }}
                        style={{ display: "none" }}
                        multiple
                        accept=".jpg,.png,.gif,.jpeg,.jfif"
                      />

                      <p>
                        <span>+</span> Photo
                      </p>
                    </div>
                    <div
                      className="videoIconContainer"
                      onClick={() => {
                        if (isVideoSelecting !== false) {
                          document.querySelector("#newVideoPost").click();
                        }
                      }}
                    >
                      <img
                        src={
                          isVideoSelecting === true || isVideoSelecting === null
                            ? SocialInputVideoIcon
                            : SocialInputVideoGreyIcon
                        }
                        alt=""
                      />
                      <input
                        type="file"
                        id="newVideoPost"
                        onChange={(e) => {
                          setFileType("video");
                          setNewPostImg(null);
                          setIsImgSelecting(false);
                          setIsVideoSelecting(true);
                          setIsDocSelecting(false);
                          setIsGreetingSelecting(false);
                          if (e.currentTarget.files.length > 0) {
                            const selectedVideoToSend = [];
                            const selectedVideoToShow = [];
                            for (
                              let i = 0;
                              i < e.currentTarget.files.length;
                              i++
                            ) {
                              const videoFile = e.currentTarget.files[i];
                              selectedVideoToSend.push(videoFile);
                              selectedVideoToShow.push({
                                url: URL.createObjectURL(videoFile),
                                data: videoFile,
                              });
                            }

                            setNewPostVideo(selectedVideoToShow);
                            setNewPostAttachmentData(selectedVideoToSend);
                          } else {
                            setNewPostAttachmentData([]);
                          }
                        }}
                        style={{ display: "none" }}
                        multiple
                        accept=".mp4, .avi, .mkv, .mo"
                      />
                      <p>
                        <span>+</span> Video
                      </p>
                    </div>
                    <div
                      className="documentIconContainer"
                      onClick={() => {
                        if (isDocSelecting !== false) {
                          document.querySelector("#newDocPost").click();
                        }
                      }}
                    >
                      <img
                        src={
                          isDocSelecting === true || isDocSelecting === null
                            ? SocialInputDcoumentIcon
                            : SocialInputDocumentGreyIcon
                        }
                        alt=""
                      />
                      <input
                        type="file"
                        id="newDocPost"
                        onChange={(e) => {
                          setIsImgSelecting(false);
                          setIsVideoSelecting(false);
                          setIsDocSelecting(true);
                          setIsGreetingSelecting(false);
                          setNewPostVideo(null);
                          setNewPostImg(null);
                          setNewPostAttachmentData(null);
                          if (e.currentTarget.files.length > 0) {
                            let selectedDocToSend = [];
                            let selectedDocToShow = [];

                            const docFile = e.currentTarget.files[0];
                            selectedDocToSend.push(docFile);
                            selectedDocToShow = {
                              url: URL.createObjectURL(docFile),
                              data: docFile,
                            };
                            setNewPostDoc(selectedDocToShow);
                            setNewPostAttachmentData(selectedDocToSend);
                          } else {
                            setNewPostAttachmentData([]);
                          }
                        }}
                        style={{ display: "none" }}
                        multiple
                        accept=".pdf"
                      />
                      <p>
                        <span>+</span> Docs
                      </p>
                    </div>
                    <div
                      className="gifIconContainer"
                      onClick={() => {
                        setGifLibActiveForSocial(true);
                      }}
                    >
                      <img
                        style={{ height: "32px" }}
                        src={
                          isGifSelecting === true || isGifSelecting === null
                            ? SocialPostGifIcon
                            : SocialPostGifGreyIcon
                        }
                        alt=""
                      />
                      <p>
                        <span>+</span> Gif
                      </p>
                    </div>
                    <div
                      className="greetingsIconContainer"
                      onClick={() => {
                        if (isGreetingSelecting !== false) {
                          setIsImgSelecting(false);
                          setIsVideoSelecting(false);
                          setIsDocSelecting(false);
                          setIsGreetingSelecting(true);
                          setNewPostGreetingsActive(true);
                          setNewPostActive(false);
                        }
                      }}
                    >
                      <img
                        src={
                          isGreetingSelecting === true ||
                          isGreetingSelecting === null
                            ? SocialNewPostBottomGreetingOptionsIcon
                            : SocialNewPostBottomGreetingOptionsGreyIcon
                        }
                        alt=""
                      />
                      <p>Celebrate</p>
                    </div>
                  </div>
                  <div
                    className="msgSendIconContainer"
                    onClick={() => {
                      if (newPostTextData === "") {
                        dispatch(
                          showToast({
                            text: "Please write someting!",
                            severity: "error",
                          })
                        );
                        return;
                      }
                      uploadNewPost();
                      // setSelectPostTypeActive(true);
                      // setNewPostActive(false);
                    }}
                  >
                    <img
                      src={
                        newPostTextData.length > 0
                          ? `${SocialNewPostBottomSendIcon}`
                          : `${SocialNewPostBottomSendGreyIcon}`
                      }
                      alt=""
                    />

                    <p>Publish</p>
                  </div>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setNewPostTextData("");
            setNewPostAttachmentData(null);
            setNewPostImg(null);
            setNewPostVideo(null);
            setIsImgSelecting(false);
            setIsVideoSelecting(false);
            // setCreatePostActive(false);
            setNewPostActive(false);
            navigate(-1);
          }}
        />
      )}
      {newPostGreetingsActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2 greetingPostPopupBox2"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setNewPostActive(true);
                  setNewPostGreetingsActive(false);
                  setIsImgSelecting(null);
                  setIsVideoSelecting(null);
                  setIsDocSelecting(null);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select Your Celebrate Option</h5>
            </div>
          }
          content={
            <div className="greetingsOptionsContainer">
              <div
                className="createPostWelcomeContainer"
                onClick={() => {
                  getAllImages("welcome");
                  setSubType("welcome");
                  setWelcomeOptionGreetingsActive(true);
                  setNewPostGreetingsActive(false);
                }}
              >
                <div className="createPostWelcomeImgContainer">
                  <img src={SocialNpWelcomeIcon} alt="" />
                </div>
                <div className="createPostWelcomeTextContainer">
                  <span>Welcome</span>
                  <p>
                    I would like to share another piece of work from Unikorns
                    recent case study – Shapescape. The full case is under the
                    link below.
                  </p>
                </div>
              </div>
              <div
                className="createPostKudosContainer"
                onClick={() => {
                  getAllImages("kudos");
                  setSubType("kudos");
                  setWelcomeOptionGreetingsActive(true);
                  setNewPostGreetingsActive(false);
                }}
              >
                <div className="createPostKudosImgContainer">
                  <img src={ScoialNpGiveKudosIcon} alt="" />
                </div>
                <div className="createPostKudosTextContainer">
                  <span>Give Kudos</span>
                  <p>
                    I would like to share another piece of work from Unikorns
                    recent case study – Shapescape. The full case is under the
                    link below.
                  </p>
                </div>
              </div>
              <div
                className="createPostWorkAnniContainer"
                onClick={() => {
                  getAllImages("workanniversary");
                  setSubType("workanniversary");
                  setWelcomeOptionGreetingsActive(true);
                  setNewPostGreetingsActive(false);
                }}
              >
                <div className="createPostWorkAnniImgContainer">
                  <img src={SocialNpWorkAnnyIcon} alt="" />
                </div>
                <div className="createPostWorkAnniTextContainer">
                  <span>Work Anniversary</span>
                  <p>
                    I would like to share another piece of work from Unikorns
                    recent case study – Shapescape. The full case is under the
                    link below.
                  </p>
                </div>
              </div>
              <div
                className="createPostBirthdayContainer"
                onClick={() => {
                  getAllImages("birthday");
                  setSubType("birthday");
                  setWelcomeOptionGreetingsActive(true);
                  setNewPostGreetingsActive(false);
                }}
              >
                <div className="createPostBirthdayImgContainer">
                  <img src={SocialNpBirthdayIcon} alt="" />
                </div>
                <div className="createPostBirthdayTextContainer">
                  <span>Birthday</span>
                  <p>
                    I would like to share another piece of work from Unikorns
                    recent case study – Shapescape. The full case is under the
                    link below.
                  </p>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setNewPostGreetingsActive(false);
            // setCreatePostActive(false);
            navigate(-1);
          }}
        />
      )}
      {welcomeOptionGreetingsActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          contentClassName="newPostPopupContent"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setNewPostGreetingsActive(true);
                  setWelcomeOptionGreetingsActive(false);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Welcome Your New Teammate</h5>
            </div>
          }
          content={
            <div className="welcomeOptionGreetingsContainer">
              {loading && <Loader />}
              {!loading && (
                <>
                  <div className="welcomeImgContainer">
                    <div
                      className="gifComponentContainer"
                      onClick={() => {
                        setGifLibActive(true);
                        setWelcomeOptionGreetingsActive(false);
                      }}
                    >
                      <div className="gifComponentWrapper">
                        <p>Gif</p>
                      </div>
                    </div>
                    {allGreetingsImages?.map((d, i) => {
                      return (
                        <div className="welcomeImg">
                          <img
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              albumPostImages +
                              d
                            }
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedGreetings(d);
                            }}
                          />
                          <svg
                            className={
                              d === selectedGreetings
                                ? "selectedGreetings"
                                : "notSelectedGreetings"
                            }
                            onClick={() => {
                              setSelectedGreetings("");
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="200"
                            height="38"
                            viewBox="0 0 190 33"
                            fill="white"
                          >
                            <line
                              x1="182.175"
                              y1="6.68274"
                              x2="172.474"
                              y2="16.3837"
                              stroke="white"
                              stroke-width="2.03493"
                              stroke-linecap="round"
                              fill="white"
                            />
                            <line
                              x1="0.517466"
                              y1="-0.517466"
                              x2="14.2367"
                              y2="-0.517466"
                              transform="matrix(0.707107 0.707107 0.707107 -0.707107 173.058 5.95093)"
                              stroke="white"
                              stroke-width="2.03493"
                              stroke-linecap="round"
                              fill="white"
                            />
                          </svg>
                        </div>
                      );
                    })}
                  </div>
                  <div className="welcomeOptionBottomContainer">
                    <div
                      className={`welcomeOptionBottomWrapper ${
                        selectedGreetings.length > 0 ? "active" : ""
                      }`}
                    >
                      <span
                        className={`${
                          selectedGreetings.length > 0 ? "active" : ""
                        }`}
                        onClick={() => {
                          setWelcomeOptionGreetingsActive(false);
                          setEmployeeSelectionActive(true);
                        }}
                      >
                        Next
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          }
          handleClose={() => {
            setWelcomeOptionGreetingsActive(false);
            // setCreatePostActive(false);
            navigate(-1);
          }}
        />
      )}
      {employeeSelectionActive && (
        <Popup
          firstClassName="newPostForEmployeePopup1"
          secondClassName="newPostForEmployeePopup2"
          contentClassName="newPostForEmployeePopupContent"
          bodyClassName="hideScrollBarContainer"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setEmployeeSelectionActive(false);
                  setWelcomeOptionGreetingsActive(true);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select New Teammate</h5>
            </div>
          }
          content={
            <div className="selectEmployeeContainer">
              <div className="selectEmployeeWrapper">
                <div className="commonSearchListContainer">
                  <input
                    type="text"
                    className="commonSearchList"
                    placeholder="Search"
                    onChange={searchChatters}
                  />

                  <img src={SearchMagnifierIcon} alt="" />
                </div>
                <div className="totalEmployeeSelectedContainer">
                  <InfiniteScroll
                    dataLength={allEmployeeList?.length}
                    next={getEmployeeData}
                    hasMore={presentEmployeeData?.length < 20 ? false : true}
                    height={600}
                    width={200}
                  >
                    {allEmployeeList?.map((d, i) => {
                      return (
                        <div className="selectedEmployeeList">
                          <div className="employeeList">
                            <div className="totalLikePopupImg">
                              <div
                                className={`${
                                  d?.image
                                    ? "socialProfilePicContainer"
                                    : "socialCustomProfilePic"
                                }`}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  margin: "0",
                                }}
                              >
                                {!d?.image ? (
                                  getUserInitials(getDisplayName(d?.name))
                                ) : (
                                  <img
                                    className="commentProfilePic"
                                    onClick={() => {
                                      // handleUserProfileClick(d?.id);
                                    }}
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      profileImages +
                                      d?.image
                                    }
                                    alt="Profile"
                                    onError={(e) => {
                                      e.target.src = ProfileAvatar;
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="totalLikePopupText">
                              <h5>
                                {getDisplayName(d?.name)}{" "}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {getPersonNumber(d?.name)}
                                </span>
                              </h5>
                              <p
                                style={{ fontSize: "14px", fontWeight: "500" }}
                              >
                                {d?.jobRoal}
                              </p>
                            </div>
                          </div>
                          <div
                            className="socialCommonChkbox"
                            onClick={() => {
                              setSelectedRecipient(d);
                            }}
                          >
                            <input
                              type="checkbox"
                              class="myinput large"
                              name="chjsah"
                              checked={selectedRecipient === d}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </div>
              <div className="welcomeOptionBottomContainer">
                <div
                  className={`welcomeOptionBottomWrapper ${
                    selectedRecipient !== null ? "active" : ""
                  }`}
                >
                  <span
                    className={`${selectedRecipient !== null ? "active" : ""}`}
                    onClick={() => {
                      setEmployeeSelectionActive(false);
                      setNewPostActive(true);
                      if (SubType === "welcome") {
                        setNewPostTextData(
                          `Welcome to the team. @[${getDisplayName(
                            selectedRecipient.name
                          )}](${selectedRecipient.id})`
                        );
                      }
                      if (SubType === "kudos") {
                        setNewPostTextData(
                          `Well Done. @[${getDisplayName(
                            selectedRecipient.name
                          )}](${selectedRecipient.id})`
                        );
                      }
                      if (SubType === "workanniversary") {
                        setNewPostTextData(
                          `Congratulations on another year of great work! @[${getDisplayName(
                            selectedRecipient.name
                          )}](${selectedRecipient.id})`
                        );
                      }
                      if (SubType === "birthday") {
                        setNewPostTextData(
                          `Happy Birthday!. @[${getDisplayName(
                            selectedRecipient.name
                          )}](${selectedRecipient.id})`
                        );
                      }
                    }}
                  >
                    Next
                  </span>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setEmployeeSelectionActive(false);
            // setCreatePostActive(false);
            navigate(-1);
          }}
        />
      )}
      {showFullAttachmentActive && (
        <ShowFullAttachmentsCreate
          fileType={fileType}
          isGreetings={isGreetings}
          fullAtachmentData={newPostAttachmentData}
          setShowFullAttachmentActive={setShowFullAttachmentActive}
        />
      )}
      {gifLibActiveForSocial && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          contentClassName="newPostPopupContent"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setGifLibActiveForSocial(false);
                  // setNewPostActive(true);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select Gif</h5>
            </div>
          }
          content={
            <div style={{ width: "100%" }}>
              <GifLibrary setGif={setGifForSocial} />
              <div className="welcomeOptionBottomContainer">
                <div
                  className={`welcomeOptionBottomWrapper ${
                    gif ? "active" : ""
                  }`}
                >
                  <span
                    className={`${gifForSocial ? "active" : ""}`}
                    onClick={() => {
                      if (gifForSocial) {
                        setNewPostImg(gifForSocial?.image?.url);
                        setIsGifSelecting(true);
                        setNewPostVideo(null);
                        setNewPostDoc(null);
                        setIsImgSelecting(false);
                        setIsVideoSelecting(false);
                        setIsDocSelecting(false);
                        setIsGreetingSelecting(false);
                        setGifLibActiveForSocial(false);
                      }
                    }}
                  >
                    Next
                  </span>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setGifForSocial(null);
            setNewPostAttachmentData(null);
            setNewPostImg(null);
            setNewPostVideo(null);
            setIsImgSelecting(null);
            setIsVideoSelecting(null);
            setIsGifSelecting(null);
            setGifLibActiveForSocial(false);
          }}
        />
      )}
      {gifLibActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          contentClassName="newPostPopupContent"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setGifLibActive(false);
                  setSelectedGreetings("");
                  setWelcomeOptionGreetingsActive(true);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select Gif</h5>
            </div>
          }
          content={
            <div style={{ width: "100%" }}>
              <GifLibrary setGif={setGif} />
              <div className="welcomeOptionBottomContainer">
                <div
                  className={`welcomeOptionBottomWrapper ${
                    gif ? "active" : ""
                  }`}
                >
                  <span
                    className={`${gif ? "active" : ""}`}
                    onClick={() => {
                      if (gif) {
                        setGifLibActive(false);
                        setEmployeeSelectionActive(true);
                      }
                    }}
                  >
                    Next
                  </span>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setGifLibActive(false);
            setWelcomeOptionGreetingsActive(true);
            setSelectedGreetings("");
          }}
        />
      )}
    </div>
  );
}

export default CreateCommunityPost;
