
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { useEffect } from "react";



const ServiceVendorsForm = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Service Vendors`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom
        
        labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
         placeholder={" Enter Name "} 
          name="name"
        
          />
          </div>
      <div>
        <LabelCustom labelName={"Phone"}  />
        <InputCustomFormik
        minLength={10}
        maxLength={10}
          placeholder={" Enter Phone "} 
          name="phone"
          type="number"
                           step="0.01"
// eslint-disable-next-line no-script-url
oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />
          </div>
      <div>
        <LabelCustom labelName={"Email(s)"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={320}
        placeholder={" Enter Email(s) "} 
          name="emailAddresses"
        
          />
             <div style={{color:"var(--primary)",fontSize:"14px",marginLeft:"13px"}}> Please enter a comma or space to separate emails.</div>

         
          </div>
      <div>
        <LabelCustom labelName={"Email Cc"}  />
        <InputCustomFormik
        
        maxLength={320}
         placeholder={" Enter Email Cc(s) "} 
          name="emailCc"
        
          />
           <div style={{color:"var(--primary)",fontSize:"14px",marginLeft:"13px",marginBottom:"15px"}}> Please enter a comma or space to separate emails.</div>
          </div>

    </div>
     <div className="headingForm">
     TAT (In Hrs)
   </div>
<div className="formcustom">
 <div>
     <LabelCustom labelName={"High"} mandatory={true}/>
     <InputCustomFormik type='number' name={"tatHigh"}  placeholder={" Enter High "} />
   </div>
   <div>
     <LabelCustom labelName={"Medium "} mandatory={true}/>
     <InputCustomFormik type='number' name={"tatMedium"}  placeholder={" Enter Medium "} />
   </div>
   <div>
     <LabelCustom labelName={"Low"}  mandatory={true}/>
     <InputCustomFormik type='number' name={"tatLow"}  placeholder={" Enter Low "} />
   </div>
</div>
</>
  );
};

export  {ServiceVendorsForm}


