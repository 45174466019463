import React from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useState } from "react";
import { postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";

const ShareDetails = ({
  sharePostId,
  setSharePostId,
  setShareDetailsActive,
}) => {
  const [sharedData, setSharedData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sharedDetailAPI = async (id) => {
    try {
      const result = await postRequest(`SocialPost/AllShareList`, {
        createdOn: null,
        postId: id,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 10,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      setSharedData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  function handleUserProfileClick(userid) {
    navigate(`/home/social/userprofile/${userid}`);
  }
  useEffect(() => {
    sharedDetailAPI(sharePostId);
  }, []);
  return (
    <div>
      <Popup
        firstClassName="likePopupBox1"
        secondClassName="likePopupBox2"
        contentClassName={"likePopupContent"}
        popupHeading={"People who shared your post"}
        content={
          <div className="totalLikePopupContainer">
            {sharedData?.map((d, i) => {
              return (
                <div className="totalLikePopupList">
                  <div
                    className="totalLikePopupImg"
                    onClick={() => {
                      handleUserProfileClick(d?.item?.employeeId);
                    }}
                  >
                    <div
                    style={{margin:"0"}}
                      className={`${
                        d?.item?.sharedEmployee?.image
                          ? "socialProfilePicContainer"
                          : "socialCustomProfilePic"
                      }`}
                    >
                      {!d?.item?.sharedEmployee?.image ? (
                        getUserInitials(
                          getDisplayName(
                            d?.item?.sharedEmployee?.nameWithPersonNumber
                          )
                        )
                      ) : (
                        <img
                          className="socialProfilePic"
                          src={
                            process.env.REACT_APP_.BASE_URL +
                            profileImages +
                            d?.item?.sharedEmployee?.image
                          }
                          alt="Profile"
                          onError={(e) => {
                            e.target.src = ProfileAvatar;
                          }}
                        />
                      )}
                    </div>
                   
                  </div>
                  <div className="totalLikePopupText">
                    <h5>
                      {getDisplayName(
                        d?.item?.sharedEmployee?.nameWithPersonNumber
                      )}
                    </h5>
                    <p>{d?.item?.sharedEmployee?.roleName}</p>
                    <p>{d?.item?.sharedEmployee?.locationName}</p>
                  </div>
                </div>
              );
            })}
          </div>
        }
        handleClose={() => {
          setShareDetailsActive(false);
          setSharePostId(0);
        }}
      />
    </div>
  );
};

export default ShareDetails;
