import React, { useCallback, useEffect, useState, useRef } from 'react'
import "./MessageInfo.css";
import { confirmAlert } from 'react-confirm-alert';
// import { Switch } from '@mui/material';
import { ProfileGroup } from '../MyProfileDetails/ProfileGroup';
import { Popup } from '../../../../Components/FormComponent/PopupComponent/Popup';
import NewGroup from '../NewGroup/NewGroup';
import SelectedNewGroupMembers from '../NewGroup/SelectedNewGroupMembers';
import SaveIcon from "../../../../Assets/SaveTickIcon.svg"
import BraodcastIcon from "../../../../Assets/Broadcast.svg"
import Delete from "../../../../Assets/DeleteIcon.svg"
import { Loader } from '../../../../Components/FormComponent/Loader';
import AddToFavourite from "../../../../Assets/AddToFavourite.svg"
import RemoveFromFavourite from "../../../../Assets/RemoveFromFavourite.svg"
import DoubleTick from "../../../../Assets/DoubleTick.svg";
import SingleTick from "../../../../Assets/SingleTick.svg";
import DoubleTickGrey from "../../../../Assets/DoubleTickGrey.svg";
import pdfDocIcon from "../../../../Assets/pdfDocIcon.svg";
import { useSelector } from 'react-redux';
function MessageInfo({ setMessageInfo, newSocket, selectedMessage, activeChat }) {


    useEffect(() => {
        document.title = `PeopleSol - Group Info`;
        return () => {
            document.title = 'PeopleSol';
        };
    })


    const userDetails = useSelector(state => state.chat.userDetails);
    const [deliverData, setDeliver] = useState([]);

    const audioRef = useRef(null);
    const [currDim, setCurrDim] = useState({ width: 0, height: 0 })
    const getMeta = (url, cb) => {
        const img = new Image();
        img.onload = () => cb(null, img);
        img.onerror = (err) => cb(err);
        img.src = url;
    };

    useEffect(() => {
        newSocket.emit("getMessageStatus", ({ "conversationId": selectedMessage.conversationId, "messageId": selectedMessage._id }), (res) => {
            setDeliver(res.data)
            if (selectedMessage.type != 6 && selectedMessage.type != 8) {
                let url = null;
                if (selectedMessage.type === 0 && selectedMessage.replyMsgObj && selectedMessage.replyMsgObj.type === 3) url = selectedMessage.replyMsgObj.content[0].url;
                else if (selectedMessage.type === 3) url = selectedMessage.content[0].url;
                if (url) {
                    getMeta(url, (err, img) => {
                        let a = { width: img?.naturalWidth, height: img?.naturalHeight };
                        if ((selectedMessage.type === 0 && (a.width > 60 || a.height > 50)) || (selectedMessage.type > 0 && (a.width > 250 || a.height > 200))) {
                            while (true) {
                                if ((selectedMessage.type === 0 && a.width < 60 && a.height < 50) || (selectedMessage.type > 0 && a.width < 250 && a.height < 200)) break;
                                a.width /= 1.2;
                                a.height /= 1.2;
                            }
                        }
                        setCurrDim({ ...a })
                    })
                }
            }
        })

    }, [selectedMessage])


    const getDate = (date) => {
        if (!date) return "";
        let a = JSON.stringify(new Date().toLocaleDateString());
        let b = JSON.stringify(new Date(date).toLocaleDateString());
        if (a == b) return "Today";
        if (new Date().getMonth() == new Date(date).getMonth() && new Date().getFullYear() === new Date(date).getFullYear()) {
            if ((new Date().valueOf() - new Date(date).valueOf()) < 1000 * 60 * 60 * 24) return "Yesterday";
            else return new Date(date).toLocaleDateString();
        } else {
            return new Date(date).toLocaleDateString();
        }
    }
    const getTime = (date) => {
        if (!date) return "";
        let a = new Date(date).toTimeString()
        return a.substring(0, 5);
    }
    const openFile = (url) => {
        // Create an anchor element dynamically
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';  // Opens in new tab if needed
        anchor.download = url.split('/').pop(); // Extracts the file name from URL

        // Append anchor to body and trigger click to start download
        document.body.appendChild(anchor);
        anchor.click();

        // Remove the anchor after triggering download
        document.body.removeChild(anchor);
    };

    const MessageContent = ({ inStyle = {} }) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return (
            <div style={{ ...inStyle, padding: 10, color: "rgba(255, 255, 255, 1)", backgroundColor: "rgba(18, 150, 176, 1)", margin: 20, borderWidth: 0, borderColor: "rgba(106, 188, 204, 1)", borderStyle: "solid", borderTopWidth: 5, borderRadius: 10, borderTopRightRadius: 0, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                <div>
                    {selectedMessage.type === 7 ?

                        <div onClick={(e) => { e.stopPropagation(); openFile(selectedMessage.content[0].url) }}>
                            <img style={{ marginRight: 10, height: 30, width: 30 }} src={pdfDocIcon} />
                            <div>
                                <div>{selectedMessage.content[0].url.split("/").pop()}</div>
                                {selectedMessage.content[0].url?.slice(-3) === "jpg" ||
                                    selectedMessage.content[0].url?.slice(-3) === "png" ||
                                    selectedMessage.content[0].url?.slice(-3) === "svg" ? (
                                    <div>{selectedMessage.content[0].url?.slice(-3)}</div>
                                ) : selectedMessage.content[0].url?.slice(-4) === "jpeg" ||
                                    selectedMessage.content[0].url?.slice(-4) === "xlsx" ||
                                    selectedMessage.content[0].url?.slice(-4) === "docx" ? (
                                    <div>{selectedMessage.content[0].url?.slice(-4)?.toUpperCase()}</div>
                                ) : (
                                    <div> File</div>
                                )}
                            </div>
                        </div>


                        : null}
                    <div>
                        {selectedMessage.type == 14 ?
                            <div>
                                <div style={{ width: "100%", borderRadius: 6, backgroundColor: "rgba(217, 239, 243, 1)", color: "rgba(18, 150, 176, 1)", display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                    {selectedMessage.content[0].groupIcon && selectedMessage.content[0].groupIcon != "" ? <img style={{ borderRadius: 20, width: 40, height: 40 }} src={selectedMessage.content[0].groupIcon} />
                                        :
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 45, width: 45, borderRadius: 25, backgroundColor: "rgba(18, 150, 176, 1)", color: "white" }}>
                                            {selectedMessage.content[0].groupTitle
                                                ?.split(" ")
                                                ?.slice(0, 2)
                                                ?.map((item) => {
                                                    return item?.charAt(0)?.toUpperCase();
                                                })
                                                .join("")
                                            }
                                        </div>
                                    }
                                    <div style={{ marginLeft: 15 }}>
                                        <div style={{ fontSize: 14, fontWeight: "500" }}>{selectedMessage.content[0].groupTitle}</div>
                                        <div style={{ fontSize: 10 }}>{"Group chat invite "}</div>
                                    </div>
                                </div>

                                <div style={{ color: "#F7941D" }}>
                                    {selectedMessage.caption}
                                </div>
                                {/* <div style={{ color: messageClass === "sent" ? "white" : "rgba(85, 85, 85, 1)", marginTop: 10 }}>
                                    Follow this link to join the group
                                </div>
                            
                                <div onClick={() => { handleViewGroup() }} style={{ width: "100%", borderRadius: 6, backgroundColor: "rgba(217, 239, 243, 1)", color: "rgba(18, 150, 176, 1)", display: "flex", flexDirection: "row", padding: 10, alignItems: "center", justifyContent: "center", marginTop: 12 }}>
                                    <img src={ViewGroupIcon} />
                                    <div style={{ marginLeft: 10 }}>View Group</div>
                                </div> */}

                            </div>

                            :

                            selectedMessage.type == 8 ? selectedMessage.caption.split(urlRegex).map((part, index) => {
                                if (urlRegex.test(part)) return (
                                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#F7941D" }}>
                                        {part}
                                    </a>
                                )
                                else return <span>{part}</span>
                            }) :
                                <div>{selectedMessage.caption}</div>}
                    </div>
                    {selectedMessage.type === 3 ?
                        <div onClick={(e) => { e.stopPropagation(); openFile(selectedMessage.content[0].url) }} style={{ width: currDim.width, height: currDim.height, overflow: "hidden" }}>
                            <img style={{ width: currDim.width, height: currDim.height }} src={selectedMessage.content[0].url} />
                        </div>
                        : null
                    }
                    {selectedMessage.type === 2 ?
                        <video
                            src={selectedMessage.content[0].url}
                            controls
                            width="250"
                        />
                        : null
                    }
                    {selectedMessage.type === 1 ?
                        <div>
                            <audio ref={audioRef} src={selectedMessage.content[0].url} controls />
                        </div> : null
                    }

                </div>
                <img src={selectedMessage.msgStatus == 1 ? SingleTick : selectedMessage.msgStatus == 3 ? DoubleTick : DoubleTickGrey} />
            </div>
        )
    }
    const OneToOneInfo = () => {
        return (
            <div>
                <MessageContent />
                <div style={{ width: "100%", height: 1, backgroundColor: "rgba(230, 230, 230, 1)", marginTop: 10 }}></div>
                {selectedMessage.msgStatus > 2 &&
                    <div style={{ display: "flex", justifyContent: "space-between", padding: 20, alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ width: 30, height: 30, backgroundColor: "rgba(18, 150, 176, 1)", borderRadius: 4, borderTopRightRadius: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img src={DoubleTick} />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <div style={{ fontSize: 14, color: "rgba(85, 85, 85, 1)" }}>{"Read"}</div>
                                <div style={{ fontSize: 10, color: "rgba(85, 85, 85, 1)" }}>{getDate(deliverData[0]?.lastUpdatedOn)}</div>
                            </div>
                        </div>
                        <div style={{ fontSize: 10, color: "rgba(161, 161, 161, 1)" }}> {getTime(deliverData[0]?.lastUpdatedOn)}</div>
                    </div>
                }
                {selectedMessage.msgStatus > 2 &&
                    <div style={{ width: "100%", height: 1, backgroundColor: "rgba(230, 230, 230, 1)", marginTop: 10 }}></div>}
                {selectedMessage.msgStatus > 1 &&
                    <div style={{ display: "flex", justifyContent: "space-between", padding: 20, alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ width: 30, height: 30, backgroundColor: "rgba(18, 150, 176, 1)", borderRadius: 4, borderTopRightRadius: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img src={DoubleTickGrey} />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <div style={{ fontSize: 14, color: "rgba(85, 85, 85, 1)" }}>{"Delivered"}</div>
                                <div style={{ fontSize: 10, color: "rgba(85, 85, 85, 1)" }}>{"Today"}</div>
                            </div>
                        </div>
                        <div style={{ fontSize: 10, color: "rgba(161, 161, 161, 1)" }}> {"12/12/2024"}</div>
                    </div>}
                {selectedMessage.msgStatus > 1 && <div style={{ width: "100%", height: 1, backgroundColor: "rgba(230, 230, 230, 1)", marginTop: 10 }}></div>}

                <div style={{ display: "flex", justifyContent: "space-between", padding: 20, alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ width: 30, height: 30, backgroundColor: "rgba(18, 150, 176, 1)", borderRadius: 4, borderTopRightRadius: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={SingleTick} />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            <div style={{ fontSize: 14, color: "rgba(85, 85, 85, 1)" }}>{"Sent"}</div>
                            <div style={{ fontSize: 10, color: "rgba(85, 85, 85, 1)" }}>{getDate(deliverData[0]?.createdOn)}</div>
                        </div>
                    </div>
                    <div style={{ fontSize: 10, color: "rgba(161, 161, 161, 1)" }}> {getTime(deliverData[0]?.createdOn)}</div>
                </div>
            </div>
        )
    }

    const GroupInfo = () => {
        return (
            <div style={{ overflow: "auto", height: "88%" }}>
                <MessageContent inStyle={{ marginBottom: 0 }} />
                <div style={{ color: "rgba(161, 161, 161, 1)", textAlign: "end", fontSize: 10, margin: 20, marginTop: 5 }}>
                    {getDate(deliverData[0]?.createdOn) + ", " + getTime(deliverData[0]?.createdOn)}
                </div>
                <div style={{ width: "100%", height: 1, backgroundColor: "rgba(230, 230, 230, 1)", marginTop: 10 }}></div>
                <div style={{ height: 220, borderStyle: "solid", borderColor: "rgba(216, 240, 242, 1)", borderWidth: 1, borderRadius: 10, margin: 20 }}>
                    <div style={{ padding: 10, color: "rgba(255, 255, 255, 1)", backgroundColor: "rgba(18, 150, 176, 1)", borderRadius: 10, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                        Read By
                    </div>
                    <div style={{ padding: 13, overflow: "auto", height: "80%" }}>
                        {deliverData?.filter((item) => item.status == 3).map((data, index) => (
                            <div style={{ borderStyle: "solid", borderWidth: 0, borderColor: "rgba(230, 230, 230, 1)", borderBottomWidth: 1, paddingBottom: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 20, }} key={index}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div>
                                        {data.image && data.image != "" ?
                                            <img src={data.image} style={{ height: 40, width: 40, borderRadius: 20 }} />
                                            :
                                            <div style={{ color: "rgba(18, 150, 176, 1)", height: 40, width: 40, borderRadius: 20, backgroundColor: "rgba(216, 240, 242, 1)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {
                                                    data?.name
                                                        ?.split(" ")
                                                        ?.slice(0, 2)
                                                        ?.map((item) => {
                                                            return item?.charAt(0)?.toUpperCase();
                                                        })
                                                        .join("")
                                                }
                                            </div>}
                                    </div>
                                    <div style={{ color: "rgba(85, 85, 85, 1)", marginLeft: 10 }}>
                                        <div style={{ fontSize: 12 }}>{data.name}</div>
                                        <div style={{ fontSize: 10 }}>{data.designation}</div>
                                    </div>
                                </div>
                                <div style={{ color: "rgba(161, 161, 161, 1)", fontSize: 9 }}>
                                    {getDate(data.lastUpdatedOn) + ", " + getTime(data.lastUpdatedOn)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div style={{ height: 220, borderStyle: "solid", borderColor: "rgba(216, 240, 242, 1)", borderWidth: 1, borderRadius: 10, margin: 20 }}>
                    <div style={{ padding: 10, color: "rgba(255, 255, 255, 1)", backgroundColor: "rgba(18, 150, 176, 1)", borderRadius: 10, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                        Delevered To
                    </div>
                    <div style={{ padding: 13, overflow: "auto", height: "80%" }}>
                        {deliverData?.map((data, index) => (
                            <div style={{ borderStyle: "solid", borderWidth: 0, borderColor: "rgba(230, 230, 230, 1)", borderBottomWidth: 1, paddingBottom: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 20, }} key={index}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div>
                                        {data.image && data.image != "" ?
                                            <img src={data.image} style={{ height: 40, width: 40, borderRadius: 20 }} />
                                            :
                                            <div style={{ color: "rgba(18, 150, 176, 1)", height: 40, width: 40, borderRadius: 20, backgroundColor: "rgba(216, 240, 242, 1)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {
                                                    data?.name
                                                        ?.split(" ")
                                                        ?.slice(0, 2)
                                                        ?.map((item) => {
                                                            return item?.charAt(0)?.toUpperCase();
                                                        })
                                                        .join("")
                                                }
                                            </div>}
                                    </div>
                                    <div style={{ color: "rgba(85, 85, 85, 1)", marginLeft: 10 }}>
                                        <div style={{ fontSize: 12 }}>{data.name}</div>
                                        <div style={{ fontSize: 10 }}>{data.designation}</div>
                                    </div>
                                </div>
                                <div style={{ color: "rgba(161, 161, 161, 1)", fontSize: 9 }}>
                                    {getDate(data.lastUpdatedOn) + ", " + getTime(data.lastUpdatedOn)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        )
    }

    return (
        <>
            <div className="MessageInfoMainContainer">
                <div className="MessageInfoHeaderContainer">
                    <div className="MessageInfoHeaderWrapper">
                        <span>{"Message Info"}</span>
                        <svg onClick={() => {
                            setMessageInfo(false);
                        }}
                            xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 0.707104 -0.70711 -0.707104 20.4531 6.48962)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
                            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 22.0259 20.6318)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>
                {activeChat.type === "Group" ?
                    <GroupInfo />
                    :
                    <OneToOneInfo />
                }
            </div>
        </>

    );
}

export default MessageInfo;