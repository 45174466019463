/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { getRequest, postRequest } from "../../../Services/axios";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { teamsSchema } from "./Schemas/Index";
import { TeamsSetForm } from "./Form/TeamsSetForm";

const initialvalue = {
  name: "",
  spoc: "",
  employeeIds: [],
};
const CreateTeamsSettings = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();

  const [detailsform, setDetailsform] = useState({});
  const getFormDetails = async () => {
    try {
      const result = await getRequest(`Teams/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Teams Settings`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  const postFormData = async (values) => {
    try {
      const result = await postRequest("Teams/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("Teams/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const teamsSchemaV = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please Enter Name"),
    spoc: Yup.string().required("Please Select SPOC"),
    employeeIds: Yup.array()
      .min(1, "Please Select Team Members")
      .required("Please Select Team Members"),
  });
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  const onSubmit = async (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
    };
    data ? Edit(edit) : postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/globalsettingsmaster/roles/teams");
  };

  return (
    <FormCustomFormik
      ButtonLabel={data ? "Update Teams" : "Create Teams"}
      FormHeading={data ? "Edit Teams" : "Create Teams"}
      validationSchema={teamsSchemaV}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.name,
              spoc: detailsform?.spoc,
              employeeIds: detailsform?.employeeIds,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <TeamsSetForm data={detailsform} editID={data ? true : false} />
      }
    />
  );
};

export { CreateTeamsSettings };
