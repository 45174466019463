import { Form, Formik } from "formik";
import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "../../Assets/CloseIcon.svg";
import "./FormikMultiStepForm.css";
import { Loader } from "../FormComponent/Loader";
export function FormikMultiStepFormStep({ children }) {
  return <div style={{ paddingLeft: 40, paddingRight: 40 }}>{children}</div>;
}
export function FormikMultiStepForm({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const headerClicked = async (clickedStep) => {
    if (props.loading) return null;
    if (clickedStep < step) {
      setStep(clickedStep);
    }
  };
  function isLastStep() {
    return step === childrenArray.length - 1;
  }
  return (
    <Formik
      enableReinitialize
      {...props}
      validationSchema={currentChild?.props?.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props?.onSubmit(values, helpers);
        } else {
          setStep((s) => s + 1);
          helpers?.setTouched({});
        }
      }}
    >
      {({ isSubmitting, values, errors, helpers }) => {
        props?.chooseFormValues && props?.chooseFormValues(values);
        return (
          <Form style={{ height: "100%" }}>

            <div className="createPageContainer">

              <div className="createFormCont" style={props.styles}>
                <div className="createHeader">
                  <label>{props.heading}</label>
                  <IconButton
                    className="closeIconHolder"
                    onClick={props?.returnPage}
                  >
                    <img src={CloseIcon} alt="CloseIcon" title="Close" />
                  </IconButton>
                </div>
                <div className="multiStepFormHeader">
                  {childrenArray.map((child, index) => (
                    <div
                      className={`multiStepHeaderTile ${index < step && "cursor"
                        }`}
                      onClick={() => headerClicked(index)}
                    >
                      <span
                        className={`connectLine ${index === 0 && "connectLineFirstStep"
                          } ${index === childrenArray.length - 1 &&
                          "connectLineLastStep"
                          }`}
                      ></span>
                      {index === step ? (
                        <div className="headerStepContainer activeStepContainer">
                          <div className="activeStep">{index + 1}</div>
                          <label style={{ textAlign: "center" }}>
                            {child.props.label}
                          </label>
                        </div>
                      ) : (
                        <>
                          {index < step ? (
                            <div className="headerStepContainer completedStepContainer">
                              <div className="completedStep">
                                <svg
                                  width="17"
                                  height="11"
                                  viewBox="0 0 17 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.06494 3.15632L5.82751 9.10953L15.3526 1.37036"
                                    stroke="white"
                                    stroke-width="1.38613"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </div>
                              <label style={{ textAlign: "center" }}>
                                {child.props.label}
                              </label>
                            </div>
                          ) : (
                            <div className="headerStepContainer inActiveStepContainer">
                              <div className="inActiveStep">{index + 1}</div>
                              <label style={{ textAlign: "center" }}>
                                {child.props.label}
                              </label>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div
                  className="multiStepFormBody"
                  style={{
                    justifyContent: `${props.loading ? "center" : "flex-start"
                      }`,
                  }}
                >
                  {props.loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="multiStepFormBodyContainer">
                        {currentChild}
                      </div>
                      <div className="multiStepFormButtonContainer">
                        {step > 0 && (
                          <button
                            type="button"
                            className="button backButton noMargin"
                            onClick={() => setStep((s) => s - 1)}
                          >
                            {"< Back"}
                          </button>
                        )}
                        <button
                          className="button primaryButton noMargin"
                          type="submit"
                        >
                          {isLastStep() ? `${props.submitText}` : "Next >"}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
