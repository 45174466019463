import React from "react";
import "./Completed.css";
import TableContent from "./TableContent";

const ProgressBarComponent = ({
  percentage,
  widthTotal, //send in string
  height,
  color,
  style,
  progressStyle,
  textStyle,
  showPercentage,
}) => {
  let percentageshow;
  if (showPercentage === false) {
    percentageshow = showPercentage;
  } else {
    percentageshow = true;
  }
  // console.log("percentage--------", percentage);
  const progressBarStyle = {
    display: "flex",
    alignItems: "center",
    width: widthTotal ? widthTotal : "100%",
  };
  const progressWidth = {
    ...progressStyle,
    width: `${percentage}%`,
    backgroundColor: `${color}`,
  };
  const textddesign = {
    ...textStyle,
  };

  return (
    <div style={{ ...progressBarStyle }}>
      <div className="progressBar" style={style}>
        <div className="progressBarCompleted" style={progressWidth}></div>
      </div>
      {percentageshow ? (
        <div
          className="progressBarPercentage"
          style={textddesign}
        >{`${percentage}%`}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export { ProgressBarComponent };
