import React, { useState } from "react";
import "./MyProfileDetails.css";
import { Switch } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { useCallback } from "react";
import { useEffect } from "react";
import { ProfileGroup } from "./ProfileGroup";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { setUserSettings } from "../../../../Features/ChatSlice";
import GroupIcon from "../../../../Assets/ChatGroupIcon.svg";
import LinkIcon from "../../../../Assets/LinkIconWhite.svg";
import ResetLinkIcon from "../../../../Assets/ResetLink.svg";
import CopyLinkIcon from "../../../../Assets/CopyLinkIcon.svg";
import ShareLink from "../../../../Assets/ShareLinkViaChatIcon.svg";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";

function GroupLinkSetting({ activeChat, userDetails, uploadProfilePic, newSocket, setShowLinkSetting }) {


    const [setting, setSetting] = useState("");
    const [timeSetting, setTime] = useState(false)
    const [hours, setHours] = useState(null);
    const [min, setMinutes] = useState(null);
    const dispatch = useDispatch();


    useEffect(() => {
        document.title = `PeopleSol - My Profile Detail`;
        return () => {
            document.title = 'PeopleSol';
        };
    })



    useEffect(() => {
        fetchLink();
    }, [])

    const fetchLink = () => {
        if (activeChat) {
            newSocket.emit("getInviteLink", ({ "conversationId": activeChat._id }), (res) => {
                if (res.data.expiredOn === "") setSetting("everyTime")
                else if (res.data.expiredOn === null) setSetting("oneTime")
                else setSetting("duration")
            })
        }
    }
    const handleSetting = (val) => {
        newSocket.emit("updateInviteLink", ({ "conversationId": activeChat._id, "reqType": "expireTime", "exipredOn": val }), (res) => {
            console.log({ "conversationId": activeChat._id, "reqType": "expireTime", "exipredOn": val }, "---------> setting expire time res", res);
            dispatch(
                showToast({ text: "Invite link successfully updated", severity: "success" })
            );
            fetchLink();
        })
    }

    const handleSumbit = () => {
        console.log(hours, min)
        if ((hours != null && hours > 0) || (min != null && min > 0)) {
            let a = new Date().valueOf();
            if (hours != null && hours > 0) a += (hours * 60 * 60 * 1000);
            if (min != null && min > 0) a += (min * 60 * 1000);
            handleSetting(new Date(a).toISOString());
            setTime(false);
        }
        setHours(null);
        setMinutes(null)
    }

    return (
        <div className="chatContactListContainer" style={{ alignItems: 'flex-start' }}>
            <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                    <svg
                        onClick={() => {
                            setShowLinkSetting();
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                    >
                        <path
                            d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                            fill="#F7941D"
                        />
                    </svg>
                    <span>{"Link Settings"}</span>
                </div>
            </div>
            <div style={{ padding: 20 }}>
                <div>This link should be active for</div>
                <div>
                    <div onClick={() => { setSetting("everyTime"); handleSetting("") }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>
                        <div style={{ marginRight: 15, width: 18, height: 18, borderRadius: 10, borderStyle: "solid", borderWidth: 1, borderColor: "rgba(18, 150, 176, 1)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "60%", height: "60%", backgroundColor: setting == "everyTime" ? "rgba(18, 150, 176, 1)" : "white", borderRadius: 20 }}></div>
                        </div>
                        <div>Every time</div>
                    </div>
                    <div onClick={() => { setSetting("oneTime"); handleSetting(null) }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>
                        <div style={{ marginRight: 15, width: 18, height: 18, borderRadius: 10, borderStyle: "solid", borderWidth: 1, borderColor: "rgba(18, 150, 176, 1)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "60%", height: "60%", backgroundColor: setting == "oneTime" ? "rgba(18, 150, 176, 1)" : "white", borderRadius: 20 }}></div>
                        </div>
                        <div>Only one time</div>
                    </div>
                    <div onClick={() => { setTime(true) }} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>
                        <div style={{ marginRight: 15, width: 18, height: 18, borderRadius: 10, borderStyle: "solid", borderWidth: 1, borderColor: "rgba(18, 150, 176, 1)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "60%", height: "60%", backgroundColor: setting == "duration" ? "rgba(18, 150, 176, 1)" : "white", borderRadius: 20 }}></div>
                        </div>
                        <div>Specific duration</div>
                    </div>
                </div>
            </div>

            {timeSetting && <Popup
                contentClassName="forwardPopup-chat"
                popupHeading={"Set duration"}
                handleClose={() => { setTime(false) }}
                bodyClassName="overflowStop"
                createMyHead="createHeadForward"
                content={
                    <div style={{ width: 350, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: 200 }}>
                                <div style={{ width: "50%", marginLeft: 10 }}>Hr</div>
                                <div style={{ width: "50%", marginLeft: 10 }}>Min</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 0, borderRadius: 6, borderStyle: "solid", borderWidth: 1, borderColor: "rgba(216, 240, 242, 1)", justifyContent: "space-between", width: 200 }}>
                                <input onChange={(e) => { setHours(e.target.value) }} type="number" style={{ borderStyle: "none", outline: "none", padding: 10, width: "48%" }} />
                                <div style={{ height: 30, width: 1, backgroundColor: "rgba(216, 240, 242, 1)", marginLeft: 10, marginRight: 10 }}></div>
                                <input onChange={(e) => { setMinutes(e.target.value) }} type="number" style={{ borderStyle: "none", outline: "none", padding: 10, width: "48%" }} />
                            </div>
                        </div>
                        <div onClick={() => {
                            handleSumbit()
                        }} style={{ padding: 10, borderRadius: 6, backgroundColor: "rgba(18, 150, 176, 1)", textAlign: "center", marginTop: 35, color: "white" }}>
                            Done
                        </div>
                    </div>
                } />}
        </div>
    );
}

export default GroupLinkSetting;
