import { Check } from "@mui/icons-material";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckBoxCustom } from "../../../../Components/FormComponent/CheckBoxCustom";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Load, Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { isLoading, showToast } from "../../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../Services/axios";
import { DetailsStep } from "../../../../Components/FormComponent/DetailsStep";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
export const Verify = ({ id, handleClose, getListData }) => {
  const { state } = useLocation();

  const [detailsform, setDetailsform] = useState(false);
  useEffect(() => {
    document.title = `PeopleSol - Verify`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const data = id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Expenses/Details/${data}`);
      setDetailsform(result.data.data);
      // //console.log("result.data", result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
  }, []);

  const create = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Expenses/Verify", values);
      if (result)
        dispatch(
          showToast({
            text: "Tickets successfully uploaded.",
            severity: "success",
          })
        );
      handleClose();
      getListData();
    } catch (error) {
      dispatch(
        showToast({ text: "Error in uploading tickets.", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) getFormDetails();
  }, [data]);
  const getInitalValues = () => {
    let returnArray = [
      {
        Id: 0,
        Date: null,
        ExpenseTypeId: null,
        Amount: null,
        ApprovedAmount: null,
        Reason: "",
        Receipt: null,
        Remarks: null,
      },
    ];
    if (detailsform)
      if (detailsform.expenselist)
        if (detailsform.expenselist.length > 0) {
          returnArray = [];
          detailsform?.expenselist?.map((item) => {
            return returnArray.push({
              Id: item.id,
              Date: item.date,
              ExpenseTypeId: item.expenseType.title,
              Amount: item.amount,
              ApprovedAmount: item.approvedAmount,
              Reason: item.reason,
              Receipt: item.attachment,
              Remarks: item.remarks,
            });
          });
        }
    return returnArray;
  };
  const initialvalue = {
    Expenselist: getInitalValues(),
  };
  const [remark, setRemark] = useState("remark set here");
  const updateAmount = async (Id, Amount,reason) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `Expenses/SaveAmount/${Id}/${reason}/${Amount}`
      );
      if (result)
        dispatch(
          showToast({
            text: "Amount successfully updated.",
            severity: "success",
          })
        );
      getFormDetails();
    } catch (error) {
      dispatch(
        showToast({ text: "Error in updating amount.", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getClaimedAmount = () => {
    let sum = 0;
    detailsform?.expenselist?.map((item) => {
      return (sum = sum + Number(item.amount));
    });
    return sum;
  };
  const getApprovedSettledAmount = () => {
    let sum = 0;
    detailsform?.expenselist?.map((item) => {
      return (sum = sum + Number(item.approvedAmount));
    });
    return sum;
  };
  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={initialvalue}
        enableReinitialize
        onSubmit={(values) => {
          const reqValues = {
            Id: data,
            SettledAmount: getApprovedSettledAmount(),
            ApproveAmount: getApprovedSettledAmount(),
          };
          create(reqValues);
        }}
      >
        {(formik) => {
          setFormValues(formik.values);
          return (
            <Form>
              <div style={{ width: "100%" }}>
                <div style={{ display: "grid" }}>
                  <div className="formcustom twoColumn">
                    <DetailsStep
                      detailTitle={"Employee Name"}
                      Details={detailsform?.employee?.fullName}
                    />
                    <DetailsStep
                      detailTitle={"Designation"}
                      Details={detailsform?.employee?.designation}
                    />
                    <DetailsStep
                      detailTitle={"Department"}
                      Details={detailsform?.employee?.department}
                    />
                    <DetailsStep
                      detailTitle={"Band"}
                      Details={detailsform?.employee?.band?.name}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      overflowX: "auto",
                      overflowY: "hidden",
                      paddingTop: "12px",
                    }}
                  >
                    <FieldArray name="Expenselist">
                      {(fieldArrayProps) => {
                        const { form } = fieldArrayProps;
                        const { values } = form;
                        const { Expenselist } = values;
                        return (
                          <StyledTableCont sx={{ maxWidth: "100%" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    padding="0"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"Expense Date"} />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"Expense Type"} />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Claimed Amount (Rs)"}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Approved Amount (Rs)"}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"Reason"} />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"Receipt"} />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom labelName={"Remarks"} />
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {Expenselist?.map((Expenselist, index) => (
                                <TableBody key={index}>
                                  <StyledTableCell>
                                    <LabelCustom
                                      labelName={Expenselist?.Date}
                                    />
                                  </StyledTableCell>
                                  {/* 3rd column */}
                                  <StyledTableCell>
                                    <LabelCustom
                                      labelName={Expenselist?.ExpenseTypeId}
                                    />
                                  </StyledTableCell>
                                  {/* 4th column */}
                                  <StyledTableCell>
                                    <LabelCustom
                                      labelName={Expenselist?.Amount}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <InputCustomFormik
                                        type="number"
                                        step="0.01"
                                        name={`Expenselist[${index}].ApprovedAmount`}
                                      />
                                      <button
                                        type="button"
                                        className="button primaryButton"
                                        onClick={() =>
                                          updateAmount(
                                            formik.values.Expenselist[index].Id,
                                            formik.values.Expenselist[index]
                                              .ApprovedAmount,
                                            formik.values.Expenselist[index]
                                              .Reason
                                          )
                                        }
                                      >
                                        Update Amount
                                      </button>
                                    </div>
                                  </StyledTableCell>
                                  {/* 5th column */}
                                  <StyledTableCell>
                                    <TextAreaFormik
                                      type="number"
                                      step="0.01"
                                      name={`Expenselist[${index}].Reason`}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <LabelCustom
                                      labelName={
                                        Expenselist?.Receipt != null ? (
                                          <UploadDetail
                                            filepath={`Expenses/${Expenselist?.Receipt}`}
                                          />
                                        ) : (
                                          "-"
                                        )
                                      }
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <LabelCustom
                                      labelName={Expenselist?.Remarks}
                                    />
                                  </StyledTableCell>
                                </TableBody>
                              ))}
                            </Table>
                          </StyledTableCont>
                        );
                      }}
                    </FieldArray>
                  </div>
                  <div style={{ padding: "12px" }}>
                    <DetailsStep
                      detailTitle={"Claimed Amount"}
                      Details={getClaimedAmount()}
                    />
                    <DetailsStep
                      detailTitle={"Approved Amount"}
                      Details={getApprovedSettledAmount()}
                    />
                  </div>
                </div>
                <div className="createFootCust">
                  <div>
                    <button className="button primaryButton" type="submit">
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
};
