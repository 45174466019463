import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { getRequest, postRequestForm } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useSelector } from "react-redux";
import "./Connect.css";
import SocialNewPostBottomImageIcon from "../../../../../Assets/SocialNewPostBottomImageIcon.svg";
import SocialNewPostBottomImageGreyIcon from "../../../../../Assets/SocialNewPostBottomImageGreyIcon.svg";
import SocialInputVideoGreyIcon from "../../../../../Assets/SocialInputVideoGreyIcon.svg";
import SocialInputDocumentGreyIcon from "../../../../../Assets/SocialInputDocumentGreyIcon.svg";
import SocialNewPostBottomGreetingOptionsIcon from "../../../../../Assets/SocialNewPostBottomGreetingOptionsIcon.svg";
import SocialNpWelcomeIcon from "../../../../../Assets/SocialNpWelcomeIcon.svg";
import SocialNpWorkAnnyIcon from "../../../../../Assets/SocialNpWorkAnnyIcon.svg";
import SocialNpBirthdayIcon from "../../../../../Assets/SocialNpBirthdayIcon.svg";
import ScoialNpGiveKudosIcon from "../../../../../Assets/SocialNpGiveKudosIcon.svg";
import SocialPostGifIcon from "../../../../../Assets/SocialPostGifIcon.svg";
import SocialPostGifGreyIcon from "../../../../../Assets/SocialPostGifGreyIcon.svg";
import SocialNewPostBottomGreetingOptionsGreyIcon from "../../../../../Assets/SocialNewPostBottomGreetingOptionsGreyIcon.svg";
import SocialPostImageDeleteIcon from "../../../../../Assets/SocialPostImageDeleteIcon.svg";
import SocialInputVideoIcon from "../../../../../Assets/SocialInputVideoIcon.svg";
import SocialInputDcoumentIcon from "../../../../../Assets/SocialInputDcoumentIcon.svg";
import SearchMagnifierIcon from "../../../../../Assets/SearchMagnifierIcon.svg";
import ArrowBackButton from "../../../../../Assets/ArrowBackButton.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import SocialNewPostBottomSendGreyIcon from "../../../../../Assets/SocialNewPostBottomSendGreyIcon.svg";
import SocialNewPostBottomSendIcon from "../../../../../Assets/SocialNewPostBottomSendIcon.svg";
import SocialPostOrganizationIcon from "../../../../../Assets/SocialPostOrganizationIcon.svg";
import SocialPostTeamsIcon from "../../../../../Assets/SocialPostTeamsIcon.svg";
import SocialPostComunityIcon from "../../../../../Assets/SocialPostComunityIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import PDFViewer from "./PDFViewer";
import { IconButton } from "@mui/material";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import {
  albumPostImages,
  communityProfileImages,
  profileImages,
} from "../../../../../Utilities/profileAndUploadImages";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import {
  getExtension,
  getPersonNumber,
  getUserInitials,
  imgArray,
} from "../../../../../Utilities/socialAllFunctions";
import ShowFullAttachmentsCreate from "./ShowFullAttachmentsCreate";
import { useCallback } from "react";
import imageCompression from "browser-image-compression";
import { Mention, MentionsInput } from "react-mentions";
import { useNavigate } from "react-router-dom";
import GifLibrary from "./GifLibrary";
import { setRedDotSocialData } from "../../../../../Features/SocialSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import NotificationsItem from "./SurveyComp/Components/SurveyNotificationsItem";
import SocialNotificationItem from "./SocialNotificationItem";

function CreatePost({ getData }) {
  const [createPostActive, setCreatePostActive] = useState(true);
  const [allGreetingsImages, setAllGreetingsImages] = useState(false);
  useState(false);
  useState(false);
  const [communityListActive, setCommunityListActive] = useState(false);
  const [fileInputDisabled, setFileInputDisabled] = useState(false);
  const [gifLibActive, setGifLibActive] = useState(false);
  const [gifLibActiveForSocial, setGifLibActiveForSocial] = useState(false);
  const [gifForSocial, setGifForSocial] = useState(null);
  const [gif, setGif] = useState(null);
  const [newPostCommunityId, setNewPostCommunityId] = useState(0);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [allEmployeeList, setAllEmplyeeList] = useState([]);
  const [presentEmployeeData, setPresentEmployeeData] = useState([]);
  const [employeeSelectionActive, setEmployeeSelectionActive] = useState(false);
  const [newPostActive, setNewPostActive] = useState(createPostActive);
  const [selectedGreetings, setSelectedGreetings] = useState("");
  const [selectPostTypeActive, setSelectPostTypeActive] = useState(false);
  const [newPostGreetingsActive, setNewPostGreetingsActive] = useState(false);
  const [welcomeOptionGreetingsActive, setWelcomeOptionGreetingsActive] =
    useState(false);
  const [SubType, setSubType] = useState("");
  const [newPostTextData, setNewPostTextData] = useState("");
  const [PostSubType, setPostSubType] = useState("immediate");
  const [newPostType, setNewPostType] = useState("organization");
  const [isImgSelecting, setIsImgSelecting] = useState(null);
  const [isGifSelecting, setIsGifSelecting] = useState(null);
  const [isGreetingSelecting, setIsGreetingSelecting] = useState(null);
  const [isDocSelecting, setIsDocSelecting] = useState(null);
  const [isVideoSelecting, setIsVideoSelecting] = useState(null);
  const [selectPostSubTypeActive, setSelectPostSubTypeActive] = useState(false);
  const [newPostImg, setNewPostImg] = useState(null);
  const [newPostVideo, setNewPostVideo] = useState(null);
  const [newPostDoc, setNewPostDoc] = useState(null);
  const [newPostAttachmentData, setNewPostAttachmentData] = useState(null);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [fileType, setFileType] = useState("");
  const [allCommunityList, setAllCommunityList] = useState();
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [page, setPage] = useState(1);
  const [isGreetings, setIsGreetings] = useState(false);
  const [showFullAttachmentActive, setShowFullAttachmentActive] =
    useState(false);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const moreActivityHandler = useCallback((e) => {
    let moreActivity = document.querySelector(`.emplistWithSymbolContainer`);
    if (moreActivity && !moreActivity?.contains(e.target)) {
      setIsActive(false);
      setShowEmployeeList(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mouseup", moreActivityHandler);
    return () => {
      document.removeEventListener("mouseup", moreActivityHandler);
    };
  }, [moreActivityHandler]);
  const fileInputRef = useRef(null);

  function clearTarget() {
    document.getElementById("newImagePost").value = "";
  }

  const getCommunityData = async () => {
    try {
      const result = await getRequest("SocialPost/GetCommunity");
      setAllCommunityList(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getEmployeeData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(
        `Social/GetEmployeesImageDropDown?i=${page}`
      );
      setAllEmplyeeList([...allEmployeeList, ...result?.data.textfilters]);
      setSelectEmployee([...selectEmployee, ...result?.data.textfilters]);
      setPresentEmployeeData(result?.data.textfilters);
      setPage((prev) => prev + 1);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  function searchChatters(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(selectEmployee));
      tempChatters = tempChatters.filter((d, i) =>
        d?.name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
      );
      setAllEmplyeeList(tempChatters);
    } else {
      setAllEmplyeeList(selectEmployee);
    }
  }

  const getAllImages = async (subtype) => {
    setLoading(true);
    try {
      const result = await getRequest(
        `SocialPost/GetCelibrationPhoto?subType=${subtype}`
      );
      setAllGreetingsImages(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const uploadNewPost = async () => {
    setLoading(true);
    var bodyFormData = new FormData();
    let response = null;
    if (SubType !== "") {
      bodyFormData.append("SubType", SubType);
      if (selectedGreetings) {
        bodyFormData.append("Attachment", selectedGreetings);
      } else if (gif) {
        response = await fetch(gif?.image?.url);
        const image = await response.blob();
        const imageFile = new File([image], gif?.name, {
          type: "image/gif",
        });
        bodyFormData.append("Upload", imageFile);
      }
      bodyFormData.append("MentionEmployeeId", selectedRecipient.id);
    } else {
      if (newPostAttachmentData) {
        for (let i = 0; i < newPostAttachmentData.length; i++) {
          const originalFile = newPostAttachmentData[i];
          const newName = originalFile.name.replace(/,/g, "-").trim();
          const updatedFile = new File([originalFile], newName, {
            type: originalFile.type,
          });
          bodyFormData.append("Upload", updatedFile);
        }
      } else if (gifForSocial) {
        response = await fetch(gifForSocial?.image?.url);
        console.log("object gifForSocial", gifForSocial);
        const image = await response.blob();
        const imageFile = new File([image], gifForSocial?.name, {
          type: "image/gif",
        });
        console.log("object imageFile", imageFile);
        bodyFormData.append("Upload", imageFile);
      }
    }
    if (newPostType === "community") {
      bodyFormData.append("CommunityId", newPostCommunityId);
    }
    if (newPostType === "team") {
      bodyFormData.append("PostSubType", PostSubType);
    }
    bodyFormData.append("Content", newPostTextData?.trim()?.toString("html"));
    bodyFormData.append("PostType", newPostType);
    bodyFormData.append("Type", "Social");
    try {
      const result = await postRequestForm(`SocialPost/Create`, bodyFormData);
      setRedDotSocialData(1);
      if (result?.status === 200) {
        if (!window.location.pathname.includes("userprofile")) {
          await getData(true);
        }
        setNewPostImg(null);
        setNewPostVideo(null);
        setGif(null);
        navigate("/home/social/social");
        setSelectPostTypeActive(false);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occured please try again!",
          severity: "error",
        })
      );
    } finally {
      setIsImgSelecting(false);
      setIsVideoSelecting(false);
      setNewPostActive(false);
      setLoading(false);
      setIsGifSelecting(false);
    }
  };

  useEffect(() => {
    getCommunityData();
    getEmployeeData();
    // const handleTextareaInput = () => {
    //   const textarea = document.getElementById("myTextarea");
    //   textarea.style.height = "auto";
    //   textarea.style.height = textarea.scrollHeight + 10 + "px";
    // };

    // const textarea = document.getElementById("myTextarea");
    // textarea.addEventListener("input", handleTextareaInput);

    // return () => {
    //   textarea.removeEventListener("input", handleTextareaInput);
    // };
  }, []);
  return (
    <div>
      {newPostActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          popupHeading={
            <div className="newPostHeaderContainer">
              <div
                onClick={() => {
                  navigate(`/home/social/userprofile/${LoggedInUser?.id}`);
                }}
                className={`${
                  LoggedInUser?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePic"
                }`}
                style={{ width: "50px", height: "50px", margin: "0" }}
              >
                {!LoggedInUser?.image ? (
                  getUserInitials(
                    getDisplayName(LoggedInUser?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className="commentProfilePic"
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      LoggedInUser?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="newPostHeaderTextContainer">
                <h5>{getDisplayName(LoggedInUser?.name)}</h5>
                <p>{LoggedInUser?.designation}</p>
                <p>
                  {allEmployeeList?.map((d, i) => {
                    if (d?.id === LoggedInUser?.id) {
                      return d?.location;
                    } else {
                      return null;
                    }
                  })}
                </p>
              </div>
            </div>
          }
          content={
            <div className="newPostBodyContainer">
              {!loading && (
                <InfiniteScroll
                  dataLength={allEmployeeList?.length}
                  next={getEmployeeData}
                  hasMore={presentEmployeeData?.length < 20 ? false : true}
                  height={300}
                  width={200}
                >
                  <MentionsInput
                    placeholder=" What do you want to talk about? "
                    autoFocus
                    value={newPostTextData}
                    onChange={(e) => {
                      if (e.target.value?.length > 4000) {
                        dispatch(
                          showToast({
                            text: "You have exceeded the maximum character limit, Contenyt should be less than 4000 characters!",
                            severity: "error",
                          })
                        );
                      } else {
                        setNewPostTextData(e.target.value);
                      }
                    }}
                    maxLength={4000}
                    a11ySuggestionsListLabel={
                      " What do you want to talk about? "
                    }
                    className={"createPostInputText"}
                  >
                    <Mention
                      trigger={"@"}
                      data={allEmployeeList?.map((d) => {
                        return { id: d?.id, display: getDisplayName(d?.name) };
                      })}
                    />
                  </MentionsInput>
                </InfiniteScroll>
              )}
              {loading && <Loader />}
              {(selectedGreetings !== "" || gif) && (
                <div style={{ position: "relative" }}>
                  <img
                    src={
                      gif && gif?.type !== "gif"
                        ? gif?.image?.url
                        : process.env.REACT_APP_.BASE_URL +
                          albumPostImages +
                          selectedGreetings
                    }
                    alt=""
                    style={{ marginLeft: "20px", width: "635px" }}
                  />
                  <img
                    src={SocialPostImageDeleteIcon}
                    alt=""
                    style={{ marginRight: "15px" }}
                    className="imageDeselectIcon"
                    onClick={() => {
                      setSelectedGreetings("");
                      setIsDocSelecting(null);
                      setIsGreetingSelecting(null);
                      setIsImgSelecting(null);
                      setIsVideoSelecting(null);
                      setIsGifSelecting(null);
                    }}
                  />
                </div>
              )}
              {isGifSelecting && gifForSocial && (
                <div
                  style={{
                    position: "relative",
                    height: "400px",
                    widows: "600px",
                  }}
                >
                  <img
                    src={gifForSocial?.image?.url}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                  <img
                    src={SocialPostImageDeleteIcon}
                    alt=""
                    style={{ marginRight: "15px" }}
                    className="imageDeselectIcon"
                    onClick={() => {
                      setNewPostAttachmentData(null);
                      setIsDocSelecting(null);
                      setIsGreetingSelecting(null);
                      setIsImgSelecting(null);
                      setIsVideoSelecting(null);
                      setIsGifSelecting(null);
                    }}
                  />
                </div>
              )}
              {isImgSelecting && (
                <div
                  className={"newPostBodyImgMainWrapper"}
                  onClick={() => {
                    setNewPostAttachmentData(newPostImg);
                    setShowFullAttachmentActive(true);
                    setIsGreetings(false);
                  }}
                >
                  {newPostImg?.length === 1 && (
                    <div
                      className="newPostBodyImgContainer"
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <img
                        src={newPostImg[0]?.url}
                        alt=""
                        style={{
                          width: "auto",
                          height: "auto",
                          maxHeight: "500px",
                          maxWidth: "450px",
                        }}
                      />
                      <img
                        src={SocialPostImageDeleteIcon}
                        alt=""
                        className="imageDeselectIcon"
                        onClick={(e) => {
                          e.stopPropagation();
                          let x = newPostImg.filter(
                            (d) => d.url !== newPostImg[0]?.url
                          );
                          clearTarget();
                          setNewPostImg(x);
                          setIsDocSelecting(null);
                          setIsGreetingSelecting(null);
                          setIsImgSelecting(null);
                          setIsVideoSelecting(null);
                          setIsGifSelecting(null);
                        }}
                      />
                    </div>
                  )}
                  {newPostImg?.length === 2 && (
                    <div className=" createPostImg2">
                      <div className="createSubImage2a">
                        <div style={{ position: "relative" }}>
                          <img src={newPostImg[0]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[0]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                      <div className="createSubImage2b">
                        <div style={{ position: "relative" }}>
                          <img src={newPostImg[1]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[1]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostImg?.length === 3 && (
                    <div className=" createPostImg3">
                      <div
                        className="createSubImage3a"
                        style={{ position: "relative" }}
                      >
                        <img src={newPostImg[0]?.url} alt="" />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            let x = newPostImg.filter(
                              (d) => d.url !== newPostImg[0]?.url
                            );
                            setNewPostImg(x);
                          }}
                        />
                      </div>
                      <div className="createSubImage3b">
                        <div style={{ position: "relative", height: "165px" }}>
                          <img src={newPostImg[1]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[1]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "165px" }}>
                          <img src={newPostImg[2]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[2]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostImg?.length === 4 && (
                    <div className=" createPostImg4">
                      <div
                        className="createSubImage4a"
                        style={{ position: "relative" }}
                      >
                        <img src={newPostImg[0]?.url} alt="" />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            let x = newPostImg.filter(
                              (d) => d.url !== newPostImg[0]?.url
                            );
                            setNewPostImg(x);
                          }}
                        />
                      </div>
                      <div className="createSubImage4b">
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[1]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[1]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[2]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[2]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[3]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[3]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostImg?.length > 4 && (
                    <div className=" createPostImg4">
                      <div
                        className="createSubImage4a"
                        style={{ position: "relative" }}
                      >
                        <img src={newPostImg[0]?.url} alt="" />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            let x = newPostImg.filter(
                              (d) => d.url !== newPostImg[0]?.url
                            );
                            setNewPostImg(x);
                          }}
                        />
                      </div>
                      <div className="createSubImage4b">
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[1]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[1]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[2]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[2]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <img src={newPostImg[3]?.url} alt="" />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              let x = newPostImg.filter(
                                (d) => d.url !== newPostImg[3]?.url
                              );
                              setNewPostImg(x);
                            }}
                          />
                        </div>
                      </div>
                      <div className="imgHideMainBox">
                        <div className="imgHideRoundBox">
                          <span>+ {newPostImg?.length - 4}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isVideoSelecting && (
                <div
                  className={
                    newPostVideo?.length === 1
                      ? `newPostBodyImgMainContainer`
                      : `newPostBodyImgMainWrapper`
                  }
                >
                  {newPostVideo?.length === 1 && (
                    <div
                      className="newPostBodyImgContainer"
                      style={{
                        position: "relative",
                        height: "360px",
                        width: "640px",
                        marginTop: "20px",
                      }}
                    >
                      <video
                        src={newPostVideo[0]?.url}
                        controls
                        width="630"
                        height="335"
                      />
                      <img
                        src={SocialPostImageDeleteIcon}
                        alt=""
                        className="imageDeselectIcon"
                        onClick={() => {
                          let x = newPostVideo.filter(
                            (d) => d.url !== newPostVideo[0]?.url
                          );
                          setNewPostVideo(x);
                          setIsDocSelecting(null);
                          setIsGreetingSelecting(null);
                          setIsImgSelecting(null);
                          setIsVideoSelecting(null);
                          setIsGifSelecting(null);
                        }}
                      />
                    </div>
                  )}
                  {newPostVideo?.length === 2 && (
                    <div className="createPostImg2">
                      <div className="createSubImage2a">
                        <div style={{ position: "relative" }}>
                          <video
                            src={newPostVideo[0]?.url}
                            controls
                            width="315"
                            height="335"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[0]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                      <div className="createSubImage2b">
                        <div style={{ position: "relative" }}>
                          <video
                            src={newPostVideo[1]?.url}
                            controls
                            width="315"
                            height="335"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[1]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostVideo?.length === 3 && (
                    <div className="createPostImg3">
                      <div
                        className="createSubImage3a"
                        style={{ position: "relative" }}
                      >
                        <video
                          src={newPostVideo[0]?.url}
                          controls
                          width="315"
                          height="335"
                        />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostVideo.filter(
                              (d) => d.url !== newPostVideo[0]?.url
                            );
                            setNewPostVideo(x);
                          }}
                        />
                      </div>
                      <div className="createSubImage3b">
                        <div style={{ position: "relative", height: "165px" }}>
                          <video
                            src={newPostVideo[1]?.url}
                            controls
                            width="215"
                            height="165"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[1]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "165px" }}>
                          <video
                            src={newPostVideo[2]?.url}
                            controls
                            width="215"
                            height="165"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[2]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostVideo?.length === 4 && (
                    <div className="createPostImg4">
                      <div
                        className="createSubImage4a"
                        style={{ position: "relative" }}
                      >
                        <video
                          className="vdo"
                          src={newPostVideo[0]?.url}
                          controls
                          width="315"
                          height="335"
                        />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostVideo.filter(
                              (d) => d.url !== newPostVideo[0]?.url
                            );
                            setNewPostVideo(x);
                          }}
                        />
                      </div>
                      <div className="createSubImage4b">
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[1]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[1]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[2]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[2]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[3]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[3]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {newPostVideo?.length > 4 && (
                    <div className="createPostImg4">
                      <div
                        className="createSubImage4a"
                        style={{ position: "relative" }}
                      >
                        <video
                          className="vdo"
                          src={newPostVideo[0]?.url}
                          controls
                          width="315"
                          height="335"
                        />
                        <img
                          src={SocialPostImageDeleteIcon}
                          alt=""
                          className="imageDeselectIcon"
                          onClick={() => {
                            let x = newPostVideo.filter(
                              (d) => d.url !== newPostVideo[0]?.url
                            );
                            setNewPostVideo(x);
                          }}
                        />
                      </div>
                      <div className="createSubImage4b">
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[1]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[1]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[2]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[2]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", height: "110px" }}>
                          <video
                            src={newPostVideo[3]?.url}
                            controls
                            width="215"
                            height="110"
                          />
                          <img
                            src={SocialPostImageDeleteIcon}
                            alt=""
                            className="imageDeselectIcon"
                            onClick={() => {
                              let x = newPostVideo.filter(
                                (d) => d.url !== newPostVideo[3]?.url
                              );
                              setNewPostVideo(x);
                            }}
                          />
                        </div>
                      </div>
                      <div className="imgHideMainBox">
                        <div className="imgHideRoundBox">
                          <span>+ {newPostVideo?.length - 4}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {newPostDoc?.url && (
                <div style={{ position: "relative" }}>
                  <PDFViewer pdfUrl={newPostDoc?.url} />
                  <img
                    src={SocialPostImageDeleteIcon}
                    alt=""
                    className="imageDeselectIcon"
                    onClick={() => {
                      setNewPostDoc(null);
                      setIsImgSelecting(null);
                      setIsGifSelecting(null);
                      setIsDocSelecting(null);
                      setIsGreetingSelecting(null);
                      setIsVideoSelecting(null);
                      setNewPostActive(true);
                    }}
                  />
                </div>
              )}

              <div className="newPostBottomContainer">
                <div className="newPostBottomWrapper">
                  <div className="activityIconContainer">
                    <div
                      className="imgIconContainer"
                      onClick={() => {
                        if (isImgSelecting !== false) {
                          document.querySelector("#newImagePost").click();
                        }
                      }}
                    >
                      <img
                        src={
                          isImgSelecting === true || isImgSelecting === null
                            ? SocialNewPostBottomImageIcon
                            : SocialNewPostBottomImageGreyIcon
                        }
                        alt=""
                      />
                      <input
                        type="file"
                        id="newImagePost"
                        alt="Loading..."
                        multiple
                        // accept=".jpg,.png,.gif,.jpeg,.jfif,.JPG,.PNG,.GIF,.JPEG,.JFIF"
                        accept="*"
                        ref={fileInputRef}
                        disabled={fileInputDisabled}
                        onChange={async (event) => {
                          let x = [];

                          if (event.target.files.length > 0) {
                            if (event.target.files.length > 8) {
                              event.preventDefault(); // Prevent further processing
                              // setFileInputDisabled(true);
                              event.target.value = "";
                              setNewPostAttachmentData([]);
                              setNewPostImg([]);
                              dispatch(
                                showToast({
                                  text: "Sorry, you can only select up to 8 photos at a time!",
                                  severity: "error",
                                })
                              );
                              // setFileType("");
                              setIsImgSelecting(null);
                              setIsVideoSelecting(null);
                              setIsDocSelecting(null);
                              setIsGreetingSelecting(null);
                              setNewPostGreetingsActive(null);
                              setNewPostVideo(null);
                              setIsGifSelecting(null);
                              return;
                            }

                            for (
                              let i = 0;
                              i < event.currentTarget.files.length;
                              i++
                            ) {
                              if (
                                event.currentTarget.files[0].size >= 20971520
                              ) {
                                // 15728640
                                dispatch(
                                  showToast({
                                    text: "Extra large size image not supported!",
                                    severity: "error",
                                  })
                                );
                                return;
                              }
                            }
                            for (
                              let i = 0;
                              i < event.target.files.length;
                              i++
                            ) {
                              let y = imgArray.includes(
                                getExtension(event.target.files[i].name)
                              );
                              if (!y) {
                                x.push(0);
                              }
                            }
                            if (x.length > 0) {
                              x = [];
                              event.target.value = "";
                              dispatch(
                                showToast({
                                  text: "Unsupported media type!",
                                  severity: "error",
                                })
                              );
                            } else {
                              let flag = false;
                              setFileType("image");
                              setIsImgSelecting(true);
                              setIsVideoSelecting(false);
                              setIsDocSelecting(false);
                              setIsGifSelecting(false);
                              setIsGreetingSelecting(false);
                              setNewPostGreetingsActive(false);
                              setNewPostVideo(null);
                              if (event.target.files.length > 0) {
                                const selectedImagesToShow = [];
                                const selectedImagesToSend = [];
                                const options = {
                                  maxSizeMB: 2,
                                  useWebWorker: true,
                                };

                                let fileCompressPromise = [];
                                for (
                                  let i = 0;
                                  i < event?.target?.files?.length;
                                  i++
                                ) {
                                  const imgFile = event.target.files[i];
                                  selectedImagesToShow.push({
                                    url: URL.createObjectURL(imgFile),
                                    data: imgFile,
                                  });
                                  setNewPostImg(selectedImagesToShow);
                                  try {
                                    const compressedFile = imageCompression(
                                      imgFile,
                                      options
                                    );
                                    fileCompressPromise.push(compressedFile);
                                  } catch (error) {}
                                }
                                let compressedFiles = await Promise.all(
                                  fileCompressPromise
                                );
                                compressedFiles.forEach((d) => {
                                  const fileFromBlob = new File([d], d?.name, {
                                    type: "image/jpeg",
                                  });
                                  selectedImagesToSend.push(fileFromBlob);
                                });

                                setNewPostAttachmentData(selectedImagesToSend);
                                if (flag) {
                                  event.target.value = "";

                                  setFileType("");
                                  setIsImgSelecting(null);
                                  setIsVideoSelecting(null);
                                  setIsDocSelecting(null);
                                  setIsGifSelecting(null);
                                  setIsGreetingSelecting(null);
                                  setNewPostGreetingsActive(null);
                                  setNewPostVideo(null);
                                }
                              } else {
                                setNewPostAttachmentData([]);
                                // event.target.value = "";
                              }
                            }
                          }
                        }}
                        style={{ display: "none" }}
                      />

                      <p>
                        <span>+</span> Photo
                      </p>
                    </div>
                    <div
                      className="videoIconContainer"
                      onClick={() => {
                        if (isVideoSelecting !== false) {
                          document.querySelector("#newVideoPost").click();
                        }
                      }}
                    >
                      <img
                        src={
                          isVideoSelecting === true || isVideoSelecting === null
                            ? SocialInputVideoIcon
                            : SocialInputVideoGreyIcon
                        }
                        alt=""
                      />
                      <input
                        type="file"
                        id="newVideoPost"
                        alt="Lodding..."
                        onChange={async (e) => {
                          let flag = false;
                          setFileType("video");
                          setNewPostImg(null);
                          setIsImgSelecting(false);
                          setIsGifSelecting(false);
                          setIsVideoSelecting(true);
                          setIsDocSelecting(false);
                          setIsGreetingSelecting(false);
                          if (e.currentTarget.files.length > 0) {
                            const selectedVideoToSend = [];
                            const selectedVideoToShow = [];

                            for (
                              let i = 0;
                              i < e.currentTarget.files.length;
                              i++
                            ) {
                              if (e.currentTarget.files[i].size >= 134217728) {
                                dispatch(
                                  showToast({
                                    text: "Video size should be less than 128 MB",
                                    severity: "error",
                                  })
                                );
                                flag = true;
                              } else {
                                const videoFile = e.currentTarget.files[i];
                                selectedVideoToSend.push(videoFile);
                                selectedVideoToShow.push({
                                  url: URL.createObjectURL(videoFile),
                                  data: videoFile,
                                });
                                setNewPostVideo(selectedVideoToShow);
                                setNewPostAttachmentData(selectedVideoToSend);
                              }
                            }

                            // if (e.currentTarget.files[0].size >= 134217728) {
                            //   dispatch(
                            //     showToast({
                            //       text: "Video size should be less than 128 MB",
                            //       severity: "error",
                            //     })
                            //   );
                            //   flag = true;
                            // } else {
                            //   const videoFile = e.currentTarget.files[0];
                            //   selectedVideoToSend.push(videoFile);
                            //   selectedVideoToShow.push({
                            //     url: URL.createObjectURL(videoFile),
                            //     data: videoFile,
                            //   });
                            // await handleVideoUpoad(e.currentTarget.files);
                            //   setNewPostVideo(selectedVideoToShow);
                            //   setNewPostAttachmentData(selectedVideoToSend);
                            // }

                            if (flag) {
                              setFileType("");
                              setIsImgSelecting(null);
                              setIsVideoSelecting(null);
                              setIsDocSelecting(null);
                              setIsGifSelecting(null);
                              setIsGreetingSelecting(null);
                              setNewPostGreetingsActive(null);
                              setNewPostVideo(null);
                            }
                          } else {
                            setNewPostAttachmentData([]);
                          }
                        }}
                        style={{ display: "none" }}
                        // multiple
                        accept=".mp4, .avi, .mkv, .mo, .MP4, .AVI, .MKV, .MO"
                      />
                      <p>
                        <span>+</span> Video
                      </p>
                    </div>
                    <div
                      className="documentIconContainer"
                      onClick={() => {
                        if (isDocSelecting !== false) {
                          document.querySelector("#newDocPost").click();
                        }
                      }}
                    >
                      <img
                        src={
                          isDocSelecting === true || isDocSelecting === null
                            ? SocialInputDcoumentIcon
                            : SocialInputDocumentGreyIcon
                        }
                        alt=""
                      />
                      <input
                        type="file"
                        id="newDocPost"
                        onChange={(e) => {
                          let flag = false;
                          setIsImgSelecting(false);
                          setIsVideoSelecting(false);
                          setIsGifSelecting(false);
                          setIsDocSelecting(true);
                          setIsGreetingSelecting(false);
                          setNewPostVideo(null);
                          setNewPostImg(null);
                          setNewPostAttachmentData(null);
                          if (e.currentTarget.files.length > 0) {
                            let selectedDocToSend = [];
                            let selectedDocToShow = [];
                            const docFile = e.currentTarget.files[0];
                            if (e.currentTarget.files[0].size >= 2097152) {
                            }
                            selectedDocToSend.push(docFile);
                            selectedDocToShow = {
                              url: URL.createObjectURL(docFile),
                              data: docFile,
                            };
                            setNewPostDoc(selectedDocToShow);
                            setNewPostAttachmentData(selectedDocToSend);
                          } else {
                            setNewPostAttachmentData([]);
                          }
                        }}
                        style={{ display: "none" }}
                        multiple
                        accept=".pdf,.PDF"
                      />
                      <p>
                        <span>+</span> Docs
                      </p>
                    </div>
                    <div
                      className="gifIconContainer"
                      onClick={() => {
                        setGifLibActiveForSocial(true);
                      }}
                    >
                      <img
                        style={{ height: "32px" }}
                        src={
                          isGifSelecting === true || isGifSelecting === null
                            ? SocialPostGifIcon
                            : SocialPostGifGreyIcon
                        }
                        alt=""
                      />
                      <p>
                        <span>+</span> Gif
                      </p>
                    </div>
                    <div
                      className="greetingsIconContainer"
                      onClick={() => {
                        if (isGreetingSelecting !== false) {
                          setIsImgSelecting(false);
                          setIsVideoSelecting(false);
                          setIsDocSelecting(false);
                          setIsGreetingSelecting(true);
                          setNewPostGreetingsActive(true);
                          setNewPostActive(false);
                        }
                      }}
                    >
                      <img
                        src={
                          isGreetingSelecting === true ||
                          isGreetingSelecting === null
                            ? SocialNewPostBottomGreetingOptionsIcon
                            : SocialNewPostBottomGreetingOptionsGreyIcon
                        }
                        alt=""
                      />
                      <p>Celebrate</p>
                    </div>
                  </div>
                  {newPostTextData === "" && (
                    <div
                      className="msgSendGreyIconContainer"
                      onClick={() => {
                        if (newPostTextData !== "") {
                          setSelectPostTypeActive(true);
                          setNewPostActive(false);
                        }
                      }}
                    >
                      <img src={SocialNewPostBottomSendGreyIcon} alt="" />

                      <p>Publish</p>
                    </div>
                  )}
                  {newPostTextData !== "" && (
                    <div
                      className="msgSendIconContainer"
                      onClick={() => {
                        if (newPostTextData !== "") {
                          setSelectPostTypeActive(true);
                          setNewPostActive(false);
                        }
                      }}
                    >
                      <img src={SocialNewPostBottomSendIcon} alt="" />

                      <p>Publish</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setNewPostTextData("");
            setNewPostAttachmentData(null);
            setNewPostImg(null);
            setNewPostVideo(null);
            setIsImgSelecting(false);
            setIsVideoSelecting(false);
            setNewPostActive(false);
            navigate("/home/social/social");
          }}
        />
      )}
      {gifLibActiveForSocial && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          contentClassName="newPostPopupContent"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setGifLibActiveForSocial(false);
                  // setNewPostActive(true);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select Gif</h5>
            </div>
          }
          content={
            <div style={{ width: "100%" }}>
              <GifLibrary setGif={setGifForSocial} />
              <div className="welcomeOptionBottomContainer">
                <div
                  className={`welcomeOptionBottomWrapper ${
                    gif ? "active" : ""
                  }`}
                >
                  <span
                    className={`${gifForSocial ? "active" : ""}`}
                    onClick={() => {
                      if (gifForSocial) {
                        setNewPostImg(gifForSocial?.image?.url);
                        setIsGifSelecting(true);
                        setNewPostVideo(null);
                        setNewPostDoc(null);
                        setIsImgSelecting(false);
                        setIsVideoSelecting(false);
                        setIsDocSelecting(false);
                        setIsGreetingSelecting(false);
                        setGifLibActiveForSocial(false);
                      }
                    }}
                  >
                    Next
                  </span>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setGifForSocial(null);
            setNewPostAttachmentData(null);
            setNewPostImg(null);
            setNewPostVideo(null);
            setIsImgSelecting(null);
            setIsVideoSelecting(null);
            setIsGifSelecting(null);
            setGifLibActiveForSocial(false);
          }}
        />
      )}
      {selectPostTypeActive && (
        <Popup
          firstClassName="selectPostTypePopupBox1"
          secondClassName="selectPostTypePopupBox2"
          // contentClassName="newPostPopupContent"
          popupHeading="Who can see your post?"
          content={
            <div className="selectPostTypeContainer">
              <div className="selectPostTypeWrapper">
                <div className="selectPostOrganizationContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img src={SocialPostOrganizationIcon} alt="" />
                    </article>
                    <span>Organisation</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={newPostType === "organization" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setNewPostType("organization");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostTeamContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostTeamsIcon}
                        alt=""
                        style={{ height: "20px", paddingTop: "3px" }}
                      />
                    </article>
                    <span>Team</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setNewPostType("team");
                        }}
                        checked={newPostType === "team" ? true : false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostCommunityContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostComunityIcon}
                        alt=""
                        style={{ height: "22px", paddingTop: "5px" }}
                      />
                    </article>
                    <span>Community</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setNewPostType("community");
                        }}
                        checked={newPostType === "community" ? true : false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              {!loading && (
                <div
                  className="socialPostDeleteBtn"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    onClick={() => {
                      if (newPostType === "community") {
                        setCommunityListActive(true);
                        setSelectPostTypeActive(false);
                      } else if (newPostType === "team") {
                        setSelectPostSubTypeActive(true);
                        setSelectPostTypeActive(false);
                      } else {
                        uploadNewPost();
                      }
                    }}
                  >
                    Post
                  </button>
                  <button
                    onClick={() => {
                      setNewPostActive(true);
                      setSelectPostTypeActive(false);
                    }}
                    style={{ border: "1px solid #1296B0", color: "#1296B0" }}
                  >
                    Back
                  </button>
                </div>
              )}
              {loading && (
                <span
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                    color: "#bfbfbf",
                  }}
                >
                  Posting...
                </span>
              )}
            </div>
          }
          handleClose={() => {
            setSelectPostTypeActive(false);
            setNewPostTextData("");
            setNewPostAttachmentData(null);
            setNewPostImg(null);
            setNewPostVideo(null);
            setIsImgSelecting(false);
            setIsVideoSelecting(false);
            // setCreatePostActive(false);
            navigate("/home/social/social");
          }}
        />
      )}
      {selectPostSubTypeActive && (
        <Popup
          firstClassName="selectPostTypePopupBox1 selectCommunityTypePopupBox1"
          secondClassName="selectPostTypePopupBox2 selectCommunityTypePopupBox2"
          popupHeading="Select Post SubType"
          content={
            <div className="selectPostTypeContainer">
              <div className="selectPostTypeWrapper">
                <div className="selectPostOrganizationContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img src={SocialPostOrganizationIcon} alt="" />
                    </article>
                    <span>Immediate</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={PostSubType === "immediate" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setPostSubType("immediate");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostTeamContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostTeamsIcon}
                        alt=""
                        style={{ height: "20px", paddingTop: "4px" }}
                      />
                    </article>
                    <span>All Report</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={PostSubType === "allreporte" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setPostSubType("allreporte");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              {!loading && (
                <div
                  className="socialPostDeleteBtn"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    onClick={() => {
                      uploadNewPost();
                    }}
                  >
                    Post
                  </button>
                  <button
                    onClick={() => {
                      setSelectPostSubTypeActive(false);
                      setSelectPostTypeActive(true);
                    }}
                    style={{ border: "1px solid #1296B0", color: "#1296B0" }}
                  >
                    Back
                  </button>
                </div>
              )}
              {loading && (
                <span
                  className="posting-socialpost"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "10px",
                  }}
                >
                  Posting...
                </span>
              )}
            </div>
          }
          handleClose={() => {
            setSelectPostSubTypeActive(false);
            setSelectPostTypeActive(true);
            setCommunityListActive(false);
            setNewPostTextData("");
            setNewPostAttachmentData(null);
            setNewPostImg(null);
            setNewPostVideo(null);
            setIsImgSelecting(false);
            setIsVideoSelecting(false);
            // setCreatePostActive(false);
            navigate("/home/social/social");
          }}
        />
      )}
      {communityListActive && (
        <Popup
          firstClassName="newPostForEmployeePopup1 CreatePostSelectCommunity"
          secondClassName="newPostForEmployeePopup2"
          popupHeading="Select Community"
          content={
            <div className="selectPostTypeContainer">
              <div className="communityBodyContainer">
                {allCommunityList?.length > 0 &&
                  allCommunityList.map((d, i) => {
                    return (
                      <div className="communityListCreateWrapper">
                        <div className="communityListContainer">
                          <div className="communityListWrapper">
                            <div className="communityListImgContainer">
                              <div
                                onClick={(e) => {
                                  // handleUserProfileClick(LoggedInUser?.id);
                                }}
                                className={`${
                                  d?.communityImage
                                    ? "socialConnectProfilePicContainer"
                                    : "socialCommunityCustomProfilePic"
                                }`}
                              >
                                {!d?.communityImage && d?.communityName ? (
                                  getUserInitials(d?.communityName)
                                ) : (
                                  <img
                                    className="socialProfilePic"
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      marginRight: "15px",
                                    }}
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      communityProfileImages +
                                      d?.communityImage
                                    }
                                    alt="Profile"
                                    onError={(e) => {
                                      e.target.src = SocialCommunityGroupIcon;
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="communityListTextContainer">
                              <h5>{d?.communityName}</h5>
                              <p>{d?.communityType}</p>
                            </div>
                          </div>
                          <div className="CommunityListDateTimeWrapper">
                            <div
                              className="forwardMsgChkbox"
                              onClick={() => {
                                setNewPostCommunityId(d?.id);
                              }}
                            >
                              <input
                                type="checkbox"
                                class="myinput large"
                                checked={newPostCommunityId === d?.id}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {!loading && (
                <div
                  className="socialPostDeleteBtn commClass"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    onClick={() => {
                      if (newPostCommunityId === 0) {
                        dispatch(
                          showToast({
                            text: "Please select community to proceed!",
                            severity: "error",
                          })
                        );
                        return;
                      }
                      uploadNewPost();
                    }}
                  >
                    Post
                  </button>
                  <button
                    onClick={() => {
                      setCommunityListActive(false);
                      setSelectPostTypeActive(true);
                    }}
                    style={{ border: "1px solid #1296B0", color: "#1296B0" }}
                  >
                    Back
                  </button>
                </div>
              )}
              {loading && (
                <span
                  className="posting-socialpost"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "10px",
                  }}
                >
                  Posting...
                </span>
              )}
            </div>
          }
          handleClose={() => {
            setCommunityListActive(false);
            setNewPostTextData("");
            setNewPostAttachmentData(null);
            setNewPostImg(null);
            setNewPostVideo(null);
            setIsImgSelecting(false);
            setIsVideoSelecting(false);
            // setCreatePostActive(false);
            navigate("/home/social/social");
          }}
        />
      )}
      {newPostGreetingsActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2 greetingPostPopupBox2"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setNewPostActive(true);
                  setNewPostGreetingsActive(false);
                  setIsImgSelecting(null);
                  setIsVideoSelecting(null);
                  setIsDocSelecting(null);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select Your Celebrate Option</h5>
            </div>
          }
          content={
            <div className="greetingsOptionsContainer">
              <div
                className="createPostWelcomeContainer"
                onClick={() => {
                  getAllImages("welcome");
                  setSubType("welcome");
                  setWelcomeOptionGreetingsActive(true);
                  setNewPostGreetingsActive(false);
                }}
              >
                <div className="createPostWelcomeImgContainer">
                  <img src={SocialNpWelcomeIcon} alt="" />
                </div>
                <div className="createPostWelcomeTextContainer">
                  <span>Welcome</span>
                  <p>
                    I would like to share another piece of work from Unikorns
                    recent case study – Shapescape. The full case is under the
                    link below.
                  </p>
                </div>
              </div>
              <div
                className="createPostKudosContainer"
                onClick={() => {
                  getAllImages("kudos");
                  setSubType("kudos");
                  setWelcomeOptionGreetingsActive(true);
                  setNewPostGreetingsActive(false);
                }}
              >
                <div className="createPostKudosImgContainer">
                  <img src={ScoialNpGiveKudosIcon} alt="" />
                </div>
                <div className="createPostKudosTextContainer">
                  <span>Give Kudos</span>
                  <p>
                    I would like to share another piece of work from Unikorns
                    recent case study – Shapescape. The full case is under the
                    link below.
                  </p>
                </div>
              </div>
              <div
                className="createPostBirthdayContainer"
                onClick={() => {
                  getAllImages("birthday");
                  setSubType("birthday");
                  setWelcomeOptionGreetingsActive(true);
                  setNewPostGreetingsActive(false);
                }}
              >
                <div className="createPostBirthdayImgContainer">
                  <img src={SocialNpBirthdayIcon} alt="" />
                </div>
                <div className="createPostBirthdayTextContainer">
                  <span>Birthday</span>
                  <p>
                    I would like to share another piece of work from Unikorns
                    recent case study – Shapescape. The full case is under the
                    link below.
                  </p>
                </div>
              </div>
              <div
                className="createPostWorkAnniContainer"
                onClick={() => {
                  getAllImages("workanniversary");
                  setSubType("workanniversary");
                  setWelcomeOptionGreetingsActive(true);
                  setNewPostGreetingsActive(false);
                }}
              >
                <div className="createPostWorkAnniImgContainer">
                  <img src={SocialNpWorkAnnyIcon} alt="" />
                </div>
                <div className="createPostWorkAnniTextContainer">
                  <span>Work Anniversary</span>
                  <p>
                    I would like to share another piece of work from Unikorns
                    recent case study – Shapescape. The full case is under the
                    link below.
                  </p>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setNewPostGreetingsActive(false);
            // setCreatePostActive(false);
            navigate("/home/social/social");
          }}
        />
      )}
      {welcomeOptionGreetingsActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          contentClassName="newPostPopupContent"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setNewPostGreetingsActive(true);
                  setWelcomeOptionGreetingsActive(false);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Welcome Your New Teammate</h5>
            </div>
          }
          content={
            <div className="welcomeOptionGreetingsContainer">
              {loading && <Loader />}
              {!loading && (
                <>
                  <div className="welcomeImgContainer">
                    <div
                      className="gifComponentContainer"
                      onClick={() => {
                        setGifLibActive(true);
                        setWelcomeOptionGreetingsActive(false);
                      }}
                    >
                      <div className="gifComponentWrapper">
                        <p>Gif</p>
                      </div>
                    </div>

                    {allGreetingsImages?.map((d, i) => {
                      return (
                        <div className="welcomeImg">
                          <div
                            style={{
                              position: "relative",
                              borderRadius: "8px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                albumPostImages +
                                d
                              }
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedGreetings(d);
                              }}
                            />
                            {d === selectedGreetings && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage:
                                    "linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.6))",
                                }}
                              >
                                <svg
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                  }}
                                  onClick={() => {
                                    setSelectedGreetings("");
                                  }}
                                  width="15"
                                  height="16"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.7579 0.56897L0.757935 12.569"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M0.757935 0.56897L12.7579 12.569"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="welcomeOptionBottomContainer">
                    <div
                      className={`welcomeOptionBottomWrapper ${
                        selectedGreetings !== "" ? "active" : ""
                      }`}
                    >
                      <span
                        className={`${
                          selectedGreetings !== "" ? "active" : ""
                        }`}
                        onClick={() => {
                          if (selectedGreetings !== "") {
                            setWelcomeOptionGreetingsActive(false);
                            setEmployeeSelectionActive(true);
                          }
                        }}
                      >
                        Next
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          }
          handleClose={() => {
            setWelcomeOptionGreetingsActive(false);
            navigate("/home/social/social");
          }}
        />
      )}
      {gifLibActive && (
        <Popup
          firstClassName="newPostPopup1"
          secondClassName="newPostPopup2"
          contentClassName="newPostPopupContent"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setGifLibActive(false);
                  setSelectedGreetings("");
                  setWelcomeOptionGreetingsActive(true);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select Gif</h5>
            </div>
          }
          content={
            <div style={{ width: "100%" }}>
              <GifLibrary setGif={setGif} />
              <div className="welcomeOptionBottomContainer">
                <div
                  className={`welcomeOptionBottomWrapper ${
                    gif ? "active" : ""
                  }`}
                >
                  <span
                    className={`${gif ? "active" : ""}`}
                    onClick={() => {
                      if (gif) {
                        setGifLibActive(false);
                        setEmployeeSelectionActive(true);
                      }
                    }}
                  >
                    Next
                  </span>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setGifLibActive(false);
            setWelcomeOptionGreetingsActive(true);
            setSelectedGreetings("");
          }}
        />
      )}
      {employeeSelectionActive && (
        <Popup
          firstClassName="newPostForEmployeePopup1"
          secondClassName="newPostForEmployeePopup2"
          contentClassName="newPostForEmployeePopupContent"
          bodyClassName="hideScrollBarContainer"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setEmployeeSelectionActive(false);
                  setWelcomeOptionGreetingsActive(true);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select New Teammate</h5>
            </div>
          }
          content={
            <div className="selectEmployeeContainer">
              <div className="selectEmployeeWrapper">
                <div className="commonSearchListContainer">
                  <input
                    type="text"
                    className="commonSearchList"
                    placeholder="Search"
                    onChange={searchChatters}
                  />

                  <img src={SearchMagnifierIcon} alt="" />
                </div>
                <div className="totalEmployeeSelectedContainer">
                  <InfiniteScroll
                    dataLength={allEmployeeList?.length}
                    next={getEmployeeData}
                    hasMore={presentEmployeeData?.length < 20 ? false : true}
                    loader={<h4 style={{ padding: "30px" }}> Loading...</h4>}
                    height={800}
                    width={200}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                  >
                    {allEmployeeList?.map((item) => {
                      return (
                        <SocialNotificationItem
                          item={item}
                          selectedRecipient={selectedRecipient}
                          setSelectedRecipient={setSelectedRecipient}
                        />
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </div>
              <div className="welcomeOptionBottomContainer">
                <div
                  className={`welcomeOptionBottomWrapper ${
                    selectedRecipient !== null ? "active" : ""
                  }`}
                >
                  <span
                    className={`${selectedRecipient !== null ? "active" : ""}`}
                    onClick={() => {
                      setEmployeeSelectionActive(false);
                      setNewPostActive(true);
                      if (SubType === "welcome") {
                        setNewPostTextData(
                          `Welcome to the team. @[${getDisplayName(
                            selectedRecipient.name
                          )}](${selectedRecipient.id})`
                        );
                      }
                      if (SubType === "kudos") {
                        setNewPostTextData(
                          `Well Done. @[${getDisplayName(
                            selectedRecipient.name
                          )}](${selectedRecipient.id})`
                        );
                      }
                      if (SubType === "workanniversary") {
                        setNewPostTextData(
                          `Congratulations on another year of great work! @[${getDisplayName(
                            selectedRecipient.name
                          )}](${selectedRecipient.id})`
                        );
                      }
                      if (SubType === "birthday") {
                        setNewPostTextData(
                          `Happy Birthday!. @[${getDisplayName(
                            selectedRecipient.name
                          )}](${selectedRecipient.id})`
                        );
                      }
                    }}
                  >
                    Next
                  </span>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setEmployeeSelectionActive(false);
            navigate("/home/social/social");
          }}
        />
      )}
      {showFullAttachmentActive && (
        <ShowFullAttachmentsCreate
          fileType={fileType}
          isGreetings={isGreetings}
          fullAtachmentData={newPostAttachmentData}
          setShowFullAttachmentActive={setShowFullAttachmentActive}
        />
      )}
    </div>
  );
}

export default CreatePost;
