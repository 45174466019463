import React from 'react';
import "./ApprovalsContent.css";
import { useEffect } from 'react';
const ApprovalsContent = (props) => {
  useEffect(()=>{
    document.title = `PeopleSol - Approval`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  return (
    <>
      <div className="flex-dashboardApprovalDetail">
              
              <div className="dashboardTotalApproval">
                <p className="dashboardApprovalPercent">{props.percent}</p>
                <p className="dashboardApprovalRedPercent">{props.redpercent}</p>
                <p className="dashboardApprovalAmount">{props.amount}</p>
              </div>
              <div className="verticalLineGray" />
              <div className="ApprovalReason">{props.reason}</div>
            </div>
    </>
  )
}

export default ApprovalsContent
