import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { useEffect } from "react";
import { CreateAppraisalCycleComp } from "./FormComp/CreateAppraisalCycleComp";

const CreateAppraisalCycle = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const [formValues, setFormValues] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dataTwo = state?.performanceId;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [performanceCycleList, setPerformanceCycleList] = useState([]);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`AppraisalCycleNew/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  console.log("object formValues", formValues);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("AppraisalCycleNew/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const publish = async (id) => {
    try {
      await getRequest(`PerformanceCycleNew/Publish/${id}`);
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      getPerformanceCycle();
    }
  };

  useEffect(() => {
    document.title = `PeopleSol -  Create Performance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("AppraisalCycleNew/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  console.log("object  dataTwo", dataTwo);
  
  useEffect(() => {
    if (dataTwo) publish(dataTwo);
  }, [dataTwo]);

  const initialvalue = {
    name: "",
    type: "",
    performanceCycleId: dataTwo,
    appraisalCriteriaId: "",
    description: null,
    appraisalCycleStartDate: null,
    appraisalCycleEndData: null,
    processStartDate: "",
    processEndDate: "",
    selfReviewStartDate: "",
    selfReviewEndDate: "",
    appraisalReviewStartDate: "",
    appraisalReviewEndDate: "",
    reviewersReviewStartDate: "",
    reviewersReviewEndDate: "",
    upload: null,
    departmentIds: "",
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please enter name"),
    type: Yup.string().required("Please select type"),
    performanceCycleId: Yup.string().required(
      "Please select performance cycle"
    ),
    appraisalCriteriaId: Yup.string().required(
      "Please select appraisal criteria"
    ),
    description: Yup.string()
      .max(499, "Description must be less than 500 characters")
      .nullable(),
    upload: Yup.mixed()
      .test(
        "Fichier taille",
        "File Size should be less than 2 mb",
        (value) =>
          !value ||
          (value && value.size <= 2048 * 2048) ||
          typeof value !== "object"
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          typeof value !== "object" ||
          (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .notRequired("Please Attach File"),
    appraisalCycleStartDate: Yup.date().required(
      "Please select process start date"
    ),
    appraisalCycleEndData: Yup.date()
      .required("Please select process end date")
      .test(
        "is-greater",
        "End Date must be greater than Start Date",
        function (value) {
          const startDate = formValues?.appraisalCycleStartDate;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    processStartDate: Yup.date().required("Please select process start date"),
    processEndDate: Yup.date()
      .required("Please select process end date")
      .test(
        "is-greater",
        "End Date must be greater than Start Date",
        function (value) {
          const startDate = formValues?.processStartDate;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    selfReviewStartDate: Yup.date().required(
      "Please select self review start date"
    ),
    selfReviewEndDate: Yup.date()
      .required("Please select self review end date")
      .test(
        "is-greater",
        "End Date must be greater than Start Date",
        function (value) {
          const startDate = formValues?.selfReviewStartDate;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    appraisalReviewStartDate: Yup.date().required(
      "Please select appraiser review start date"
    ),
    appraisalReviewEndDate: Yup.date()
      .required("Please select appraiser review end date")
      .test(
        "is-greater",
        "End Date must be greater than Start Date",
        function (value) {
          const startDate = formValues?.appraisalReviewStartDate;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    reviewersReviewStartDate: Yup.date().required(
      "Please select reviewer review start date"
    ),
    reviewersReviewEndDate: Yup.date()
      .required("Please select reviewer review end date")
      .test(
        "is-greater",
        "End Date must be greater than Start Date",
        function (value) {
          const startDate = formValues?.reviewersReviewStartDate;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    formData.append("name", values?.name);
    formData.append("type", values?.type);
    formData.append("performanceCycleId", values?.performanceCycleId);
    formData.append("appraisalCriteriaId", values?.appraisalCriteriaId);
    formData.append("description", values?.description);
    formData.append(
      "appraisalCycleStartDate",
      `${format(values?.appraisalCycleStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "appraisalCycleEndDate",
      `${format(values?.appraisalCycleEndData, "MM/dd/yyyy")}`
    );

    formData.append(
      "processStartDate",
      `${format(values?.processStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "processEndDate",
      `${format(values?.processEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "selfReviewStartDate",
      `${format(values?.selfReviewStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "selfReviewEndDate",
      `${format(values?.selfReviewEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "appraisalReviewStartDate",
      `${format(values?.appraisalReviewStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "appraisalReviewEndDate",
      `${format(values?.appraisalReviewEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "reviewersReviewStartDate",
      `${format(values?.reviewersReviewStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "reviewersReviewEndDate",
      `${format(values?.reviewersReviewEndDate, "MM/dd/yyyy")}`
    );
    // console.log("object formData", formData);
    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    if (
      Array.isArray(values?.departmentIds) &&
      values?.departmentIds?.length > 0
    )
      values?.departmentIds?.forEach((item, index) => {
        formData.append(`_departmentIds[${index}]`, item);
      });
    if (typeof values?.upload === "object")
      formData.append("upload", values?.upload);
    else
      formData.append(
        "guideLineDocument",
        detailsform?.guideLineDocument
          ? detailsform?.guideLineDocument.toString()
          : null
      );

    data ? Edit(formData) : createPageData(formData);
  };

  const getPerformanceCycle = async () => {
    try {
      const result = await getRequest(
        "PerformanceCycleNew/GetPerformanceCycleNew"
      );
      let listData = [];
      result.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      setPerformanceCycleList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={props.title}
      chooseFormValues={chooseFormValues}
      ButtonLabel={data ? "Update" : "Create"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.name,
              type: detailsform?.type,
              performanceCycleId: detailsform?.performanceCycleId,
              appraisalCriteriaId: detailsform?.appraisalCriteriaId,
              description: detailsform?.description,
              appraisalCycleStartDate: new Date(
                detailsform?.appraisalCycleStartDate ?? null
              ),
              appraisalCycleEndData: new Date(
                detailsform?.appraisalCycleEndData ?? null
              ),
              processStartDate: new Date(detailsform?.processStartDate ?? null),
              processEndDate: new Date(detailsform?.processEndDate ?? null),
              selfReviewStartDate: new Date(
                detailsform?.selfReviewStartDate ?? null
              ),
              selfReviewEndDate: new Date(
                detailsform?.selfReviewEndDate ?? null
              ),
              appraisalReviewStartDate: new Date(
                detailsform?.appraisalReviewStartDate ?? null
              ),
              appraisalReviewEndDate: new Date(
                detailsform?.appraisalReviewEndDate ?? null
              ),
              reviewersReviewStartDate: new Date(
                detailsform?.reviewersReviewStartDate ?? null
              ),
              reviewersReviewEndDate: new Date(
                detailsform?.reviewersReviewEndDate ?? null
              ),
              upload: `PmsAppraisalCycle/${detailsform?.guideLineDocument}`,
              departmentIds: detailsform?.excludedDepartments
                ? detailsform?.excludedDepartments?.split(",")?.map(Number)
                : "",
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreateAppraisalCycleComp
          data={detailsform}
          editID={data ? true : false}
          getPerformanceCycle={getPerformanceCycle}
          performanceCycleList={performanceCycleList}
        />
      }
    />
  );
};

export { CreateAppraisalCycle };
