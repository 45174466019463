import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../../Features";
import { postRequest } from "../../../../../Services/axios";
import { Form, Formik } from "formik";
import React from "react";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { TimeLineData } from "./TimeLineData";

 const TimelineHrDesk = ({ id,url }) => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const getTimeline = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `${url}/Timeline?ID=${id}`
      );
      setData(result.data?.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Time Line HR Desk`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getTimeline();
  }, []);
  const initial = { Description: "" };
  const PostApRejHol = async (values) => {
    try {
      setLoading(true);
      await postRequest(`${url}/UpdateDetails`, values);
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      getTimeline();
      setLoading(false);
    }
  };
  const onSubmit = async (values) => {
    const post = { ...values, TicketId: Number(id),  };
    PostApRejHol(post);
  };
  return (
    <>
      {loading ? (
        <div className="noDataFound">
          <Loader />
        </div>
      ) : data!=null? (
        <>
          <div className="timeline-container">
            <TimeLineData data={data} />
          </div>
          <div className="comentCont">
            <p className="labelCom">Comments</p>
            <Formik initialValues={initial} onSubmit={onSubmit}>
              {(formik) => (
                <Form>
                  <div style={{ padding: "0px 37px 0 13px" }}>
                    <TextAreaFormik
                      name="Description"
                      style={{ height: "200px" }}
                    />
                  </div>
                  <div className="createBtnTime" style={{position:'unset'}}>
                    <div>
                      <button
                        style={{ padding: " 5px 21px" }}
                        className="btnTime"
                        onClick={() =>
                          formik.resetForm({ values: { Description: "" } })
                        }
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                    <div>
                      <button className="butn primaryButton" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Data Found"}</h4>
        </div>
      )}
    </>
  );
};

export {TimelineHrDesk} 

