import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import "./BoxView.css";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import Nodata from "../../Assets/Nodata.svg";
import { useEffect } from "react";
import { getStatusColor } from "../../Utilities/getStatusColor";
function BoxView({
  tbodyData,
  actionData,
  moreActions,
  moreActionList,
  headingTile,
  headingEmp,
  subTiles,
  date,
  detailUrl,
  originalData,
  payroll,
  styles,
  flexStyles,
}) {
  tbodyData = tbodyData ? (tbodyData.length > 0 ? tbodyData : false) : false;
  const [moreActionPopup, setMoreActionPopup] = useState({
    isActive: false,
    Id: 0,
  });
  console.log('headingTile', headingTile, headingEmp)
  const camelToCapital = (key) => {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  // useEffect(()=>{
  //   document.title = `PeopleSol - Box View`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // })

  const navigate = useNavigate();
  const getTableDataValue = (data) => {
    let parsedDate = Date.parse(data);
    if (data === true) {
      return "Yes";
    } else if (data === false) {
      return "No";
    } else if (!Number.isNaN(parsedDate)) {
      try {
        if (data.includes("T") && data.includes(":")) {
          let date = new Date(data);
          return `${format(date, "dd/MM/yyyy")}`;
        } else {
          return data;
        }
      } catch {
        return data;
      }
    } else return data;
  };
  function actionClickHandler(e, itemData, item) {
    e.stopPropagation();
    if (itemData.clickAction) itemData.clickAction(item.employeeId ?? item.Id ?? item.id, item);
  }
  console.log("first subTiles", subTiles);
  if (tbodyData) {
    return (
      <div className={`boxViewContainer ${payroll && "payrollBoxView"}`}>
        <div className="homeCardsHolderContainer">
          <div className="homeDashboardCardHolder">
            {tbodyData?.map((item, index) => {
              console.log("item", item[headingTile[0]]);
              return (
                <div
                  className="dashboardCard internalJobCard"
                  key={index}
                  style={styles}
                >
                  <div
                    className="cardContainer cursor"
                    onClick={
                      detailUrl
                        ? () => {
                          navigate(`${detailUrl}`, {
                            state: { id: item.employeeId ?? item.id ?? item.Id },
                          });
                        }
                        : () => { }
                    }
                  >
                    {typeof headingTile == "object" ? (
                      <>
                        <div className="headingContainer">
                          <h3
                            style={{
                              fontSize: "16px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item[headingTile[0]] ? item[headingTile[0]] : ""}
                          </h3>

                          <span
                            className="internalJobDateTime"
                            style={{ color: "var(--secondary)" }}
                          >
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  item[headingTile[1]]
                                )?.split(",")?.[0],
                                color: getStatusColor(
                                  item[headingTile[1]]
                                )?.split(",")?.[1],
                                cursor: "default",
                                padding: "3px 6px",
                                width: "fit-content",
                                borderRadius: "6px",
                              }}
                            >
                              {item[headingTile[1]] ?? "Pending"}
                            </div>
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="headingContainer">
                        <h3
                          title={item[headingTile] ?? ""}
                          style={{
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item[headingTile] ? item[headingTile] : ""}
                        </h3>
                        {/* <h3
                            style={{
                              fontSize: "16px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item[headingEmp] ?getTableDataValue(item[headingEmp]) : ""}
                          </h3> */}
                        <span className="internalJobDateTime">
                          {getTableDataValue(item[headingEmp])}
                        </span>
                      </div>
                    )}
                    <div className="jobDetailContainerHolder">
                      {subTiles?.map((subItem, subIndex) => {
                        return (
                          <div className="jobDetailContainer" key={subIndex}>
                            <div className="verticalLine"></div>
                            <label className="jobDesignation">
                              {camelToCapital(subItem)}
                            </label>
                            <label
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "12px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={getTableDataValue(item[subItem]) ?? ""}
                            >
                              {getTableDataValue(item[subItem]) === 0
                                ? "0"
                                : getTableDataValue(item[subItem])
                                  ? getTableDataValue(item[subItem])
                                  : "-"}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="applyJobButtonContainer" style={flexStyles}>
                      <>
                        {actionData?.map((itemData, index) => {
                          // console.log('itemData',itemData.action)
                          if (itemData?.button)
                            return (
                              <button
                                className={`button ${itemData?.action === "Add incentive"
                                  ? "grC"
                                  : "primaryButton"
                                  }`}
                                onClick={(e) =>
                                  actionClickHandler(e, itemData, item)
                                }
                              >
                                {itemData?.action}
                              </button>
                            );
                          else
                            return (
                              <Tooltip
                                arrow
                                title={itemData.action}
                                placement="top"
                              >
                                <IconButton
                                  key={index}
                                  color="primary"
                                  onClick={(e) =>
                                    actionClickHandler(e, itemData, item)
                                  }
                                >
                                  {itemData.img}
                                </IconButton>
                              </Tooltip>
                            );
                        })}
                        {originalData
                          ?.find((x) => (x.id ?? x.Id) === (item.employeeId ?? item.id ?? item.Id))
                          ?.actions?.map((itemData, index) => {
                            return (
                              <Tooltip
                                arrow
                                title={itemData.action}
                                placement="top"
                              >
                                <IconButton
                                  key={index}
                                  color="primary"
                                  onClick={(e) =>
                                    actionClickHandler(e, itemData, item)
                                  }
                                >
                                  {itemData.img}
                                </IconButton>
                              </Tooltip>
                            );
                          })}
                        {moreActions && (
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                            }}
                          >
                            <Tooltip
                              arrow
                              title={"More Actions"}
                              placement="top"
                            >
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  setMoreActionPopup((prev) => {
                                    if (prev.isActive) {
                                      if (prev.Id === (item.employeeId ?? item.Id ?? item.id)) {
                                        return { isActive: false, Id: 0 };
                                      } else {
                                        return {
                                          isActive: true,
                                          Id: item.employeeId ?? item.Id ?? item.id,
                                        };
                                      }
                                    } else {
                                      return {
                                        isActive: true,
                                        Id: item.employeeId ?? item.Id ?? item.id,
                                      };
                                    }
                                  })
                                }
                              >
                                <svg
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.264709"
                                    y="0.744141"
                                    width="20"
                                    height="20"
                                    rx="4"
                                    fill="#E4E4FD"
                                  />
                                  <circle
                                    cx="15.0649"
                                    cy="10.7439"
                                    r="1.2"
                                    transform="rotate(90 15.0649 10.7439)"
                                    fill="#8A88ED"
                                  />
                                  <circle
                                    cx="10.2649"
                                    cy="10.7439"
                                    r="1.2"
                                    transform="rotate(90 10.2649 10.7439)"
                                    fill="#8A88ED"
                                  />
                                  <circle
                                    cx="5.4649"
                                    cy="10.7439"
                                    r="1.2"
                                    transform="rotate(90 5.4649 10.7439)"
                                    fill="#8A88ED"
                                  />
                                </svg>
                              </IconButton>
                            </Tooltip>
                            {moreActionPopup.isActive &&
                              moreActionPopup.Id === (item.employeeId ?? item.Id ?? item.id) && (
                                <div className="moreActionContainer">
                                  <div className="headingFilterHeader">
                                    <h5 style={{ width: "120px" }}>Actions</h5>
                                    <IconButton
                                      onClick={() =>
                                        setMoreActionPopup({
                                          isActive: false,
                                          Id: 0,
                                        })
                                      }
                                    >
                                      <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 13 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M12.7579 0.56897L0.757935 12.569"
                                          stroke="#F71D1D"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M0.757935 0.56897L12.7579 12.569"
                                          stroke="#F71D1D"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </IconButton>
                                  </div>
                                  {moreActionList?.map((itemData, index) => {
                                    return (
                                      <div
                                        className="moreActionTile cursor"
                                        onClick={
                                          itemData.clickAction
                                            ? () =>
                                              itemData.clickAction(
                                                item.employeeId ?? item.Id ?? item.id
                                              )
                                            : () => { }
                                        }
                                      >
                                        <label style={{ whiteSpace: "nowrap" }}>
                                          {itemData.actionTitle}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="noDatastyle">
        <img
          src={Nodata}
          alt="No data"
          style={{ height: "150px", width: "250px" }}
        />
        <h4>{"No Data Found"}</h4>
      </div>
    );
  }
}

export { BoxView };
