import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateContactDirectoryComp } from "../FormComp/CreateContactDirectoryComp";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CreateContactDirectory = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequest("ContactDirectory/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Contact Directory`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("ContactDirectory/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const initialvalue = {
    employeeId: data ? detail?.employeeId : null,
    contact: data ? detail?.contact : null,
    email: data ? detail?.email : null,
    description: data ? detail.description : null,
    epabx: data ? detail?.epabx : "",
    mobile: data ? detail?.mobile : null,
  };
  const validationSchema = Yup.object({
    employeeId: Yup.string().required("Please Select Employee ").nullable(),
    contact: Yup.string()
      .max(149, "Designation must be less than 150 characters")
      .notRequired("Please enter Designetion")
      .nullable(),
    description: Yup.string()
      .max(499, "Description must be less than 500 characters")
      .notRequired("Please enter description")
      .nullable(),
  });

  const getDetails = async () => {
    try {
      const result = await getRequest(`ContactDirectory/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
      id: 0,

      isDataActive: true,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Contact Directory" : "Create Contact Directory"}
      ButtonLabel={
        data ? "Update Contact Directory" : "Create  Contact Directory"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateContactDirectoryComp data={data} />}
    />
  );
};

export { CreateContactDirectory };
