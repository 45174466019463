import React from 'react'
import { useEffect } from 'react';


const MonthDetailComp = ({color, days, type}) => {
  useEffect(() => {
    document.title = `PeopleSol - Month Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className={`monthDetailHolder`}  >

        <div className="monthLeftBox monthCount" style={{color:`${color}`, fontSize:'28px'}}>{days ? days : ""}</div>
        <div className="monthRightBox">
            <div className="horizontalLinePrimary" style={{backgroundColor:`${color}`, marginTop:'7px'}}></div>
            <label className='monthLabel'>{type ? type : ""}</label>
            
        </div>
      
    </div>
  )
}

export {MonthDetailComp}
