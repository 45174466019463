import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import "./Greetings.css";
import BirthdayHeaderIcon from "../../../../../Assets/SocialBirthdayHeaderIcon.svg";
import WeddingHeaderIcon from "../../../../../Assets/SocialWeddingHeaderIcon.svg";
import SocialWorkAnniHeaderIcon from "../../../../../Assets/SocialWorkAnniHeaderIcon.png"
import NewJoiningHeaderIcon from "../../../../../Assets/SocialNewJoiningHeaderIcon.svg";
import HeaderComponent from "./HeaderComponent";
import { useEffect } from "react";
import { Birthday } from "./Birthday";
import { MarriageAnniversary } from "./MarriageAnniversary";
import { WorkAnniversary } from "./WorkAnniversary";
import { NewJoinig } from "./newJoining";
const Greetings = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  useEffect(() => {
    if (tabIndex === 0) {
      document.title = `PeopleSol - Birthday`;
      return () => {
        document.title = "PeopleSol";
      };
    }
    if (tabIndex === 1) {
      document.title = `PeopleSol - Marriage Anniversary`;
      return () => {
        document.title = "PeopleSol";
      };
    }
    if (tabIndex === 2) {
      document.title = `PeopleSol - Work Anniversary`;
      return () => {
        document.title = "PeopleSol";
      };
    }
    if (tabIndex === 3) {
      document.title = `PeopleSol - New Joining`;
      return () => {
        document.title = "PeopleSol";
      };
    }
  });

  return (
    <>
      <div className="engageMainContentContainer" style={{ paddingLeft: 30, paddingRight: 30 }}>
        {tabIndex === 0 && (
          <div className="greetingEngageHeaderContainer activeHeader">
            <HeaderComponent icon={BirthdayHeaderIcon} title={"Birthday"} />
          </div>
        )}
        {tabIndex === 1 && (
          <div className="greetingEngageHeaderContainer activeHeader">
            <HeaderComponent
              icon={WeddingHeaderIcon}
              title={"Marriage Anniversary"}
            />
          </div>
        )}
        {tabIndex === 2 && (
          <div className="greetingEngageHeaderContainer activeHeader">
            <HeaderComponent
              icon={SocialWorkAnniHeaderIcon}
              title={"Work Anniversary"}
            />
          </div>
        )}
        {tabIndex === 3 && (
          <div className="greetingEngageHeaderContainer activeHeader">
            <HeaderComponent
              icon={NewJoiningHeaderIcon}
              title={"New Joining"}
            />
          </div>
        )}
        <div className="greetingsTab">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#F7941D" } }}
          >
            <Tab
              label={
                <span
                  className={`tabHeading ${tabIndex === 0 && "tabActive tabActiveSecondary"
                    }`}
                >
                  Birthday
                </span>
              }
            />
            <Tab
              label={
                <span
                  className={`tabHeading ${tabIndex === 1 && "tabActive tabActiveSecondary"
                    }`}
                >
                  Marriage Anniversary
                </span>
              }
            />
            <Tab
              label={
                <span
                  className={`tabHeading ${tabIndex === 2 && "tabActive tabActiveSecondary"
                    }`}
                >
                  Work Anniversary
                </span>
              }
            />
            <Tab
              label={
                <span
                  className={`tabHeading ${tabIndex === 3 && "tabActive tabActiveSecondary"
                    }`}
                >
                  New Joining
                </span>
              }
            />
          </Tabs>
        </div>
        <div className="greetingsContainer">
          {tabIndex === 0 && <Birthday />}
          {tabIndex === 1 && <MarriageAnniversary />}
          {tabIndex === 2 && <WorkAnniversary />}
          {tabIndex === 3 && <NewJoinig />}
        </div>
      </div>
    </>
  );
};
export { Greetings };
