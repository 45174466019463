import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CreateResetPasswordComp } from "./FormComp/CreateResetPasswordComp";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useEffect } from "react";
import "./CreateResetPassword.css";

const CreateResetPassword = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);

  const resetPassword = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("ResetPassword/sendEmail", values);
      if(result?.status === 200 && result?.statusText === "OK"){
        dispatch(
          showToast({ text: "Password Reset Successfully", severity: "success" })
        );
      }
      
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const initialvalue = {
    id: "",
    password: "",
    isSelected: "",
  };
  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };
  const validationSchema = Yup.object({
    id: Yup.array().required("Please select a employee"),
    isSelected: Yup.string().required("Please select send email"),
    password: Yup.string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
  });

  const onSubmit = (values) => {
    if(values.isSelected === false){
      dispatch(
        showToast({ text: "Please select send email", severity: "error" })
      );
      return;
    }
    const create = {
      ...values,
    };
    resetPassword(create);
    returnPage();
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Reset Password `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      FormHeading={"Reset Password"}
      ButtonLabel={"Reset"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateResetPasswordComp />}
      styles={{
        maxHeight:"70%",
        minHeight: "60%",
        minWidth:"800px",
        maxWidth:"1000px"
      }}
      className={"resetBottomContainer"}
      btnLabel={"resetPwdCancelBtn"}
    />
  );
};

export { CreateResetPassword };
