import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";
import SocialTextLinkIcon from "../../../../../Assets/SocialTextLinkIcon.svg";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import SocialKudosTag from "../../../../../Assets/SocialKudosTag.svg";
import SocialBirthdayTag from "../../../../../Assets/SocialBirthdayTag.svg";
import SocialWorkAnnyTag from "../../../../../Assets/SocialWorkAnnyTag.svg";
import SocialNewJoiningTag from "../../../../../Assets/SocialNewJoiningTag.svg";
import SocialPdfLeftArrow from "../../../../../Assets/SocialPdfLeftArrow.svg";
import SocialPdfRightArrow from "../../../../../Assets/SocialPdfRightArrow.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import { IconButton } from "@mui/material";
import SharePost from "./SharePost";
import CommentPost from "./CommentPost";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import LikeDislike from "./LikeDislike";
import { useLocation, useNavigate } from "react-router-dom";
import PDFViewer from "./PDFViewer";
import ContentSocialDisplay from "./ContentSocialDisplay";
import DetailsPostLikeDislikeComponent from "./DetailsPostLikeDislikeComponent";
import {
  docsArray,
  getAttachments,
  getExtension,
  getUserInitials,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import "./ShowFullAttachments.css";
import "./Connect.css";
import dateFormat from "dateformat";
import {
  albumPostImages,
  communityProfileImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";

function SocialShowFullAttachment({
  getData,
  postData,
  setPostData,
  setCommentedPost,
  commentedPost,
  fullAtachmentData,
}) {
  const [sharePostActive, setSharePostActive] = useState(false);
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [isImages, setIsImages] = useState(false);
  const [isVideos, setIsVideos] = useState(false);
  const [isDocs, setIsDocs] = useState(false);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  let attachment = getAttachments(fullAtachmentData);
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
 

  useEffect(() => {
    if (attachment) {
      let x = getExtension(attachment[0]);
      if (imgArray.includes(x)) {
        setIsImages(true);
      } else if (videoArray.includes(x)) {
        setIsVideos(true);
      } else if (docsArray.includes(x)) {
        setIsDocs(true);
      }
      if (x !== null) {
        setNumPages(attachment?.length);
      }
    }
  }, []);
  const nextSlide = (e) => {
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  };
  const prevSlide = (e) => {
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  };
  useEffect(() => {
    const keyHandler = (event) => {
      if (event.code === "ArrowRight") {
        nextSlide();
      } else if (event.code === "ArrowLeft") {
        prevSlide();
      }
    };
    document.addEventListener("keydown", keyHandler, false);

    return () => document.removeEventListener(keyHandler);
  }, []);

  const backToAnnouncement = () => {
    if (
      location?.pathname?.includes("community") ||
      location?.pathname?.includes("userprofile") ||
      location?.pathname?.includes("greetings")
    ) {
      localStorage.removeItem("commentid");
      navigate(-1);
    } else {
      localStorage.removeItem("commentid");
      setCommentedPost(null);
      navigate("/home/social/social");
    }
  };
  return (
    <div className="showAttachmentsWithCommentContainer">
      <Popup
        firstClassName="showAttachmentsWithCommentPopupbox1"
        secondClassName="showAttachmentsWithCommentPopupbox2"
        createMyHead="showAttachmentsWithCommentCreateHead"
        content={
          <>
            <div className="showAttachmentsWithCommentWrapper">
              {isImages && (
                <div className="showAttachmentsWithCommentImgContainer">
                  <div
                  // className="showAttachmentSigngleSharedImages"
                  >
                    {commentedPost?.subType &&
                      getExtension(attachment[0]) !== "gif" && (
                        <img
                          src={
                            process.env.REACT_APP_.BASE_URL +
                            albumPostImages +
                            attachment[index]
                          }
                          alt=""
                        />
                      )}
                    {(!commentedPost?.subType ||
                      getExtension(attachment[0]) === "gif") && (
                      <img
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          socialPostImages +
                          attachment[index]
                        }
                        alt=""
                      />
                    )}
                  </div>
                </div>
              )}
              {isVideos && (
                <div
                  className="showFullAttachmentListBox"
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <video
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      socialPostImages +
                      attachment[index]
                    }
                    controls
                    style={{ padding: "12px", width: "100%", height: "89%" }}
                  />
                </div>
              )}
              {isDocs && (
                <div
                  className="showFullAttachmentListBox"
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PDFViewer
                    pdfUrl={
                      process.env.REACT_APP_.BASE_URL +
                      socialPostImages +
                      attachment[index]
                    }
                  />
                </div>
              )}
              {!isImages && !isVideos && !isDocs && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    color: "#bfbfbf",
                  }}
                >
                  No Attachment Found!
                </div>
              )}
              {fullAtachmentData !== null && fullAtachmentData?.length > 0 && (
                <>
                  <div className="leftPdfArrow">
                    {index === 0 || (
                      <img
                        src={SocialPdfLeftArrow}
                        alt=""
                        onClick={(e) => {
                          prevSlide(e);
                        }}
                      />
                    )}
                  </div>
                  <div className="rightPdfArrow">
                    {numPages - 1 === index || (
                      <img
                        src={SocialPdfRightArrow}
                        alt=""
                        onClick={(e) => {
                          nextSlide(e);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="showAttachmentsWithCommentListContainer">
              <div className="showAttachmentsWithCommentListWrapper">
                {commentedPost?.type !== "announcement" &&
                  commentedPost?.type !== "news" && (
                    <div
                      className="showAttachmentsWithCommentListHeaderContainer"
                      style={{ padding: "0" }}
                    >
                      <div
                        className={` social-details-header ${
                          commentedPost?.postType !== "organisation"
                            ? "team-community-details-Header"
                            : ""
                        }`}
                      >
                        {commentedPost?.postType === "organisation" && (
                          <div
                            className="leftPostHeaderContainer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUserProfileClick(commentedPost?.employeeId);
                            }}
                          >
                            <div
                              className={`${
                                commentedPost?.parantPostEmployee?.image
                                  ? "socialProfilePicContainer"
                                  : "socialCustomProfilePic"
                              }`}
                            >
                              {!commentedPost?.parantPostEmployee?.image ? (
                                getUserInitials(
                                  commentedPost?.parantPostEmployee?.name
                                )
                              ) : (
                                <img
                                  className="socialProfilePic"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleUserProfileClick(
                                      commentedPost?.employeeId
                                    );
                                  }}
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    profileImages +
                                    commentedPost?.parantPostEmployee?.image
                                  }
                                  alt="Profile"
                                  onError={(e) => {
                                    e.target.src = ProfileAvatar;
                                  }}
                                />
                              )}
                            </div>

                            <div className="postMainTextContainer">
                              <label className="postMainName">
                                {getDisplayName(
                                  commentedPost?.parantPostEmployee
                                    ?.nameWithPersonNumber
                                )}
                              </label>
                              <label>
                                {commentedPost?.parantPostEmployee?.roleName}
                              </label>
                              <label className="dateAndViewsHeaderContainer">
                                {
                                  commentedPost?.parantPostEmployee
                                    ?.locationName
                                }
                                <span className="postHrTime">
                                  &nbsp; •{" "}
                                  {dateFormat(
                                    commentedPost?.createdOn,
                                    "hh:MM TT | dd mmm yyyy"
                                  )}
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                        {commentedPost?.postType === "community" && (
                          <div
                            className="leftPostHeaderContainer"
                            style={{ position: "relative" }}
                          >
                            <div
                              onClick={(e) => {
                                navigate(
                                  `/home/social/community/${commentedPost?.community?.id}`
                                );
                              }}
                              className={`${
                                commentedPost?.community?.communityImage
                                  ? "socialConnectProfilePicContainer"
                                  : "nestedCommunityCustomProfilePic"
                              }`}
                            >
                              {!commentedPost?.community?.communityImage &&
                              commentedPost?.community?.communityName ? (
                                getUserInitials(
                                  commentedPost?.community?.communityName
                                )
                              ) : (
                                <img
                                  className="socialProfilePic"
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    communityProfileImages +
                                    commentedPost?.community?.communityImage
                                  }
                                  style={{ marginBottom: "8px" }}
                                  alt="Profile"
                                  onError={(e) => {
                                    e.target.src = SocialCommunityGroupIcon;
                                  }}
                                />
                              )}
                            </div>

                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUserProfileClick(
                                  commentedPost?.employeeId
                                );
                              }}
                              className={`${
                                commentedPost?.parantPostEmployee?.image
                                  ? "socialProfilePicContainer"
                                  : "socialCustomProfilePicCommunity"
                              }`}
                            >
                              {!commentedPost?.parantPostEmployee?.image ? (
                                getUserInitials(
                                  commentedPost?.parantPostEmployee?.name
                                )
                              ) : (
                                <img
                                  className={`socialProfilePic ${
                                    commentedPost?.postType === "community" ||
                                    "team"
                                      ? "prTeamCommunity"
                                      : ""
                                  }`}
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    profileImages +
                                    commentedPost?.parantPostEmployee?.image
                                  }
                                  alt="Profile"
                                  onError={(e) => {
                                    e.target.src = ProfileAvatar;
                                  }}
                                />
                              )}
                            </div>

                            <div
                              className="postMainTextContainer"
                              style={{ marginLeft: "15px" }}
                              onClick={() => {
                                navigate(
                                  `/home/social/community/${commentedPost?.community?.id}`
                                );
                              }}
                            >
                              <label className="postMainName">
                                {commentedPost?.community?.communityName}
                              </label>
                              <label
                              // className="dateAndViewsHeaderContainer"
                              >
                                Post from{" "}
                                <span className="nameofposter">
                                  &nbsp;
                                  {getDisplayName(
                                    commentedPost?.parantPostEmployee
                                      ?.nameWithPersonNumber
                                  )}
                                </span>
                                <br />
                                <span className="postHrTime">
                                  •{" "}
                                  {dateFormat(
                                    commentedPost?.createdOn,
                                    "hh:MM TT | dd mmm yyyy"
                                  )}
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                        {commentedPost?.postType === "team" && (
                          <div
                            className="leftPostHeaderContainer"
                            style={{ position: "relative" }}
                          >
                            <img
                              src={SocialPostTeamIcon}
                              alt=""
                              style={{
                                height: "50px",
                                width: "50px",
                                marginBottom: "8px",
                              }}
                            />
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUserProfileClick(
                                  commentedPost?.employeeId
                                );
                              }}
                              className={`${
                                commentedPost?.parantPostEmployee?.image
                                  ? "socialProfilePicContainer"
                                  : "socialCustomProfilePicCommunity"
                              }`}
                            >
                              {!commentedPost?.parantPostEmployee?.image ? (
                                getUserInitials(
                                  commentedPost?.parantPostEmployee?.name
                                )
                              ) : (
                                <img
                                  className={`socialProfilePic ${
                                    commentedPost?.postType === "community" ||
                                    "team"
                                      ? "prTeamCommunity"
                                      : ""
                                  }`}
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    profileImages +
                                    commentedPost?.parantPostEmployee?.image
                                  }
                                  alt="Profile"
                                  onError={(e) => {
                                    e.target.src = ProfileAvatar;
                                  }}
                                />
                              )}
                            </div>

                            <div
                              className="postMainTextContainer"
                              style={{ marginLeft: "15px" }}
                            >
                              <label className="postMainName">Team</label>
                              <label
                              // className="dateAndViewsHeaderContainer"
                              >
                                Post from &nbsp;
                                <span className="nameofposter">
                                  {getDisplayName(
                                    commentedPost?.parantPostEmployee
                                      ?.nameWithPersonNumber
                                  )}
                                </span>
                                <br />
                                <span className="postHrTime">
                                  {dateFormat(
                                    commentedPost?.createdOn,
                                    "hh:MM TT | dd mmm yyyy"
                                  )}
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          paddingRight: "10px",
                          paddingTop: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingRight: "10px",
                          }}
                        >
                          {commentedPost?.subType === "kudos" && (
                            <div className="kudosTypeContainer">
                              <span>Kudos</span>
                              <article></article>
                              <img src={SocialKudosTag} alt="" />
                            </div>
                          )}
                          {commentedPost?.subType === "birthday" && (
                            <div className="birthdayTypeContainer">
                              <span>Birthday</span>
                              <article></article>
                              <img src={SocialBirthdayTag} alt="" />
                            </div>
                          )}
                          {commentedPost?.subType === "workanniversary" && (
                            <div className="workAnnyTypeContainer">
                              <span>Work Anniversary</span>
                              <article></article>
                              <img src={SocialWorkAnnyTag} alt="" />
                            </div>
                          )}
                          {commentedPost?.subType === "welcome" && (
                            <div className="newJoiningTypeContainer">
                              <span>New Joining</span>
                              <article></article>
                              <img src={SocialNewJoiningTag} alt="" />
                            </div>
                          )}
                        </div>
                        <IconButton onClick={backToAnnouncement}>
                          <img
                            src={CloseIcon}
                            alt=""
                            // onClick={backToAnnouncement}
                            className="detailsPostHeaderCloseIcon"
                          />
                        </IconButton>
                      </div>
                    </div>
                  )}
                {commentedPost?.type === "announcement" && (
                  <div
                    className="showAttachmentsWithCommentListHeaderContainer"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="announcementHeaderContainer">
                      <label>{commentedPost?.announcementTitle ?? "--"}</label>
                      <span className="postHrTime">
                        •{" "}
                        {dateFormat(
                          commentedPost?.createdOn,
                          "hh:MM TT | dd mmm yyyy"
                        )}
                      </span>
                    </div>
                    <IconButton onClick={backToAnnouncement}>
                      <img src={CloseIcon} alt="" />
                    </IconButton>
                  </div>
                )}
                {commentedPost?.type === "news" && (
                  <div
                    className="showAttachmentsWithCommentListHeaderContainer"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="socialPostTitleAndTimeContainer">
                      <label>
                        {commentedPost?.news?.title?.length > 40
                          ? commentedPost?.news?.title?.substring(0, 40) + "..."
                          : commentedPost?.news?.title}
                      </label>
                      <span className="postHrTime">
                        •{" "}
                        {dateFormat(
                          commentedPost?.createdOn,
                          "hh:MM TT | dd mmm yyyy"
                        )}
                      </span>
                    </div>
                    <IconButton onClick={backToAnnouncement}>
                      <img src={CloseIcon} alt="" />
                    </IconButton>
                  </div>
                )}
                <div className="showAttachmentsWithCommentListBodyContainer">
                  <div className="showAttachmentsWithCommentListBodyWrapper">
                    <div className="">
                      <p>
                        <ContentSocialDisplay content={commentedPost.content} />
                      </p>
                    </div>
                    {commentedPost?.news && (
                      <div
                        className="newsLinkContainer"
                        style={{ margin: "20px 0" }}
                      >
                        <div className="newsLinkWrapper">
                          <div className="newsLink">
                            <img src={SocialTextLinkIcon} alt="" />{" "}
                            <a
                              href={commentedPost?.news?.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                {" "}
                                {commentedPost?.news?.link?.length > 25
                                  ? commentedPost?.news?.link?.substring(
                                      0,
                                      25
                                    ) + "..."
                                  : commentedPost?.news?.link}
                              </span>
                            </a>
                          </div>
                          <img
                            style={{ width: "29px" }}
                            src={SocialNewsEndLinkIcon}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="showAttachmentsWithCommentListActivityContainer"
                    style={{ position: "relative" }}
                  >
                    <DetailsPostLikeDislikeComponent
                      commentedPost={commentedPost}
                      setSharePostData={setSharePostData}
                      setSharePostId={setSharePostId}
                      setLikeId={setLikeId}
                      setTotalLikesWatching={setTotalLikesWatching}
                      setDislikeId={setDislikeId}
                      setTotalDisLikesWatching={setTotalDisLikesWatching}
                      //   setShareDetailsActive={setShareDetailsActive}
                      postData={postData}
                      setPostData={setPostData}
                     
                      getData={getData}
                      setSharePostActive={setSharePostActive}
                    />
                  </div>
                  <CommentPost
                    commentedPost={commentedPost}
                    setCommentedPost={setCommentedPost}
                    getData={getData}
                    postData={postData}
                    setPostData={setPostData}
                  />
                </div>
              </div>
            </div>
          </>
        }
        handleClose={backToAnnouncement}
      />
      {sharePostActive && (
        <SharePost
          sharePostData={sharePostData}
          sharePostId={sharePostId}
          getData={getData}
          setSharePostActive={setSharePostActive}
          getExtension={getExtension}
          getAttachments={getAttachments}
        />
      )}
      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
    </div>
  );
}

export default SocialShowFullAttachment;
