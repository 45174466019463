import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MyReviews } from "./Components/Performance Review/MyReviews";
import { AppraisalCriteriaTransaction } from "./AppraisalCriteriaTransaction";
import { AllAppraisalReviews } from "./AllAppraisalReviews";
import { MyAppraisalReviews } from "./MyAppraisalReviews";
import { TeamAppraisalReviews } from "./TeamAppraisalReviews";

const AppraisalReviewTab = () => {
  const [tabIndex, setTabIndex] = useState("allreview");
  const handleTabChange = (event, newTabIndex) => {
    navigate(`appraisalreview/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [featureMenu, setFeatureMenu] = useState(null);
  const { featureMenuList } = useSelector((state) => state.menu);
  useEffect(() => {
    if (featureMenuList) {
      featureMenuList.map((menu) => {
        if (
          menu[0]?.subMenuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "appraisalreview" &&
          menu[0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "pms"
        )
          setFeatureMenu(menu);
        return null;
      });
    }
  }, [featureMenuList]);
  useEffect(() => {
    document.title = `PeopleSol -  Performance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("pms/appraisalreview")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "appraisalreview");
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `appraisalreview/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `appraisalreview/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, featureMenu]);
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer">
        {featureMenu && (
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#1296B0" } }}
          >
            {featureMenu &&
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )
                ?.map((item, index) => {
                  return (
                    <Tab
                      key={index}
                      value={item?.featureName
                        ?.replaceAll(" ", "")
                        ?.replaceAll("&", "")
                        ?.toLowerCase()}
                      label={
                        <span
                          className={`tabHeading ${
                            tabIndex ===
                              item?.featureName
                                ?.replaceAll(" ", "")
                                ?.replaceAll("&", "")
                                ?.toLowerCase() && "tabActive"
                          }`}
                        >
                          {item?.featureDisplayName}
                        </span>
                      }
                    />
                  );
                })}
          </Tabs>
        )}
        <div className="tabContainerLine"></div>
      </div>
      {featureMenu ? (
        <>
          {tabIndex === "allappraisalreview" && (
            <>
              <AllAppraisalReviews title={"All Goals"} />
            </>
          )}
          {tabIndex === "myappraisalreview" && (
            <>
              <MyAppraisalReviews title={"Reviews"} />
            </>
          )}
          {tabIndex === "teamappraisalreview" && (
            <>
              <TeamAppraisalReviews title={"MyGoals"} />
            </>
          )}
          {tabIndex === "appraisalcriteria" && (
            <>
              <AppraisalCriteriaTransaction title={"MyGoals"} />
            </>
          )}
        </>
      ) : (
        <MyReviews featureMenu={featureMenu} title={"My Reviews"} />
      )}
    </div>
  );
};

export { AppraisalReviewTab };
