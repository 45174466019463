import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { BoxView, Table } from "../../../Components/index";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import {
  getRequest,
  postRequest,
  deleteWithPayloadRequest,
} from "../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import { Loader } from "../../../Components/FormComponent/Loader";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import { TransactionTable } from "../../../Components/Table/TransactionTable";

const ContactDirectoryTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [boxView, setBoxView] = useState(true);
  const [originalData, setOriginalData] = useState(false);

  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [selectedDepartmentList, setSelectedDepartmentList] = useState([]);
  const [selectedAreaList, setSelectedAreaList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reqData, setReqData] = useState({
    department: null,
    employees: null,
    location: null,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol - Contact Directory`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const handleDelete = async (selectedRows) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await deleteWithPayloadRequest(
        `ContactDirectory/DeleteMultiple`,
        selectedRows
      );

      if (result) {
        dispatch(showToast({ text: "success", severity: "success" }));
        getListData();
        setSelectedRows([]);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const deleteSelected = () => {
    if (selectedRows.length === 1) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <DeletePopup
              onClose={onClose}
              message={
                "Are you sure you want to delete this Contact Directory?"
              }
              onClick={() => handleDelete(selectedRows)}
            />
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <DeletePopup
              onClose={onClose}
              message={`Are you sure you want to delete these ${selectedRows.length}  Contact Directories?`}
              onClick={() => handleDelete(selectedRows)}
            />
          );
        },
      });
    }
  };

  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);

      const result = await postRequest("ContactDirectory/List", reqData);
      // console.log("result.data.data", result.data.data);
      if (
        result.data
      ) {
        let tempData = [];
        result.data.data.map((item) =>
          // // console.log('item', item)
          tempData.push({
            ...item.item,
            checkActive: false,
            status: item.statusLabel,
            statusColor: item.statusColor,
            // actions: getStaticActions(item),
            // moreActions: getMoreActions(item),
            extensionNumber: item.item.extensionNo,
          })
        );
        setOriginalData(tempData);
        const clippedArray = tempData.map(
          ({
            checkActive,
            comment,
            employeeId,
            inTime,
            outTime,
            managerId,
            name,
            nameWithPersonNumber,
            nextApprover,
            nextApproverId,
            nextRequestId,
            personNumber,
            reportingOfficer,
            statusColor,
            actions,
            moreActions,
            ...rest
          }) => ({
            ...rest,
          })
        );
        const finalArray = clippedArray.map(
          ({
            id,
            employee,
            designation,
            department,
            location,
            extensionNumber,
            mobile,
            createdOn,
            lastUpdatedOn,
          }) => ({
            id,
            employee,
            designation,
            department,
            location,
            extensionNumber,
            mobile,
            createdOn,
            lastUpdatedOn,
          })
        );
        setTbodyData(finalArray);
      } else {
        setTbodyData(false);
      }
      setCount(result.data.summary.allCount);
      calculateTotalPages(result.data.summary.allCount);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getEmployeeFilter = async () => {
    try {
      const result = await getRequest("Employees/GetEmployees");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.name });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getDepartmentFilter = async () => {
    try {
      const result = await getRequest("Department/GetDepartments");
      let listData = [];

      result.data.map((item) => {
        listData.push({ label: item.name, value: item.name });
      });
      setDepartmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getAreaFilter = async () => {
    try {
      const result = await getRequest("Area/GetAreas");
      let listData = [];
      result.data.map((item) => {
      return  listData.push({ label: item.name, value: item.name });
      });
      setAreaList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest(
        "ContactDirectory/List",
        downloadReqData
      );
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("contactdirectory"))
      getListData();
  }, [location.pathname, reqData]);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const updateBandStatus = () => {};

  const getFormDetails = (id) => {
    const selectedRecord = tbodyData?.find((item) => item?.Id === id);
    navigate("/homemaster/contactdirectory/details", {
      state: { selectedRecord },
    });
  };

  const handleEdit = (id) => {
    // const dataEdit= tbodyData?.find((item)=>item?.Id===id)
    navigate("/homemaster/contactdirectory/create", { state: { id } });
  };

  const actionData = [
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
            fill="#0DB53C"
          />
          <line
            x1="14.7295"
            y1="7.43701"
            x2="7.72949"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="7.43701"
            x2="5.61462"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="11.3437"
            x2="7.72949"
            y2="11.3437"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="11.3437"
            x2="5.61462"
            y2="11.3438"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="15.2505"
            x2="7.72949"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="15.2505"
            x2="5.61462"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Contact Directory Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
            fill="#FB7158"
          />
          <path
            d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
            stroke="#FB7158"
            strokeWidth="0.698594"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      action: "Edit Contact Directory",
      clickAction: (id) => handleEdit(id),
    },
  ];

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    // let tempSelectedAreaListIds = [];
    // selectedAreaList.map((item) => {
    // return  tempSelectedAreaListIds.push(item.value);
    // });
    let tempSelectedEmployeeListIds = [];
    selectedEmployeeList.map((item) => {
    return  tempSelectedEmployeeListIds.push(item.value);
    });
    let tempSelectedDepartmentListIds = [];
    selectedDepartmentList.map((item) => {
    return  tempSelectedDepartmentListIds.push(item.value);
    });
    setReqData({
      ...reqData,
      employees:
        tempSelectedEmployeeListIds.length <= 0
          ? null
          : tempSelectedEmployeeListIds,
      department:
        tempSelectedDepartmentListIds.length <= 0
          ? null
          : tempSelectedDepartmentListIds,
      // location:
      //   tempSelectedAreaListIds.length <= 0 ? null : tempSelectedAreaListIds,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setSelectedEmployeeList([]);
    setSelectedDepartmentList([]);
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      employees: null,
      department: null,
      // location: null,
      createdOn: null,
      lastUpdatedOn: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (count > recordsSelected?.value) {
      if (selectedRows?.length === recordsSelected?.value) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
          return arr.push(item.Id);
        });
        setSelectedRows(arr);
      }
    } else {
      if (selectedRows?.length === count) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
          return arr.push(item.Id);
        });
        setSelectedRows(arr);
      }
    }
  };
  return (
    <div className="fullTableContainer noTab">
      {/* Filter Right Data */}

      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              onClick={() => setFilterSearchToogle((prev) => !prev)}
              title="close"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p>Employee</p>
              <SelectCustom
                placeholder={"Select Employee "}
                onDropdownOpen={() => {
                  getEmployeeFilter();
                }}
                options={employeeList}
                multi
                values={selectedEmployeeList}
                onChange={(value) => {
                  setSelectedEmployeeList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Department</p>
              <SelectCustom
                placeholder={"Select Department "}
                onDropdownOpen={() => {
                  getDepartmentFilter();
                }}
                options={departmentList}
                multi
                values={selectedDepartmentList}
                onChange={(value) => {
                  setSelectedDepartmentList(value);
                }}
              />
            </div>
            {/* <div className="filterContainer">
              <p>Location</p>
              <SelectCustom
                placeholder={"Select Location "}
                onDropdownOpen={() => {
                  getAreaFilter();
                }}
                options={areaList}
                multi
                values={selectedAreaList}
                onChange={(value) => {
                  setSelectedAreaList(value);
                }}
              />
            </div> */}

            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderContainer">
        {selectedRows.length > 0 ? (
          <button className="deletebtn" onClick={() => deleteSelected()}>
            Delete
          </button>
        ) : (
          <button
            onClick={() => {
              navigate("/homemaster/contactdirectory/create");
            }}
            className="createFooterButton2 addProjectButton buttonClass"
            type="submit"
          >
            + Create {props.title}
          </button>
        )}
        <div className="search-box" >
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn" >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title="Filter" placement="top">

        <IconButton
       
          color="primary"
          onClick={() => {
            setFilterSearchToogle((prev) => !prev);
          }}
        >
          {!filterSearchToogle ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                stroke="#1296B0"
                strokeWidth="1.5"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7579 1.56897L0.757935 19.569"
                stroke="#F71D1D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 1.56897L18.7579 19.569"
                stroke="#F71D1D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download" placement="top">

        <IconButton color="primary" onClick={exportTable}>
        <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
        </IconButton>
        </Tooltip>
        <Tooltip arrow title={boxView ? "List View" : "Tile View"} placement="top">

        <svg
          style={{ scale: "1.3", marginLeft: "6px" }}
          width="37"
          className="cursor"
          height="21"
          viewBox="0 0 37 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setBoxView((prev) => !prev)}
        >
          {!boxView ? (
            <>
              <rect
                x="0.700684"
                y="0.71875"
                width="36"
                height="20"
                rx="3"
                fill="#D8F0F2"
              />
              <rect
                x="2.04883"
                y="2.29004"
                width="33.3027"
                height="16.8572"
                rx="2"
                fill="white"
              />
              <path
                d="M35.3516 4.29004C35.3516 3.18547 34.4561 2.29004 33.3516 2.29004H18.475V19.1473H33.3516C34.4561 19.1473 35.3516 18.2518 35.3516 17.1473V4.29004Z"
                fill="#1296B0"
              />
              <rect
                x="5.58203"
                y="5.96558"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="#1296B0"
                stroke-width="0.75"
              />
              <rect
                x="11.6382"
                y="5.96558"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="#1296B0"
                stroke-width="0.75"
              />
              <rect
                x="5.58203"
                y="11.718"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="#1296B0"
                stroke-width="0.75"
              />
              <rect
                x="11.6382"
                y="11.718"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="#1296B0"
                stroke-width="0.75"
              />
              <line
                x1="22.3384"
                y1="6.34375"
                x2="32.2264"
                y2="6.34375"
                stroke="white"
                stroke-width="0.75"
                stroke-linecap="round"
              />
              <line
                x1="22.3384"
                y1="10.7202"
                x2="32.2264"
                y2="10.7202"
                stroke="white"
                stroke-width="0.75"
                stroke-linecap="round"
              />
              <line
                x1="22.3384"
                y1="15.0969"
                x2="32.2264"
                y2="15.0969"
                stroke="white"
                stroke-width="0.75"
                stroke-linecap="round"
              />
            </>
          ) : (
            <>
              <rect
                x="0.604492"
                y="0.71875"
                width="36"
                height="20"
                rx="3"
                fill="#D8F0F2"
              />
              <rect
                x="1.95264"
                y="2.29004"
                width="33.3027"
                height="16.8572"
                rx="2"
                fill="white"
              />
              <path
                d="M1.95264 4.29004C1.95264 3.18547 2.84807 2.29004 3.95264 2.29004H18.8292V19.1473H3.95264C2.84807 19.1473 1.95264 18.2518 1.95264 17.1473V4.29004Z"
                fill="#1296B0"
              />
              <rect
                x="5.48584"
                y="5.96558"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="white"
                stroke-width="0.75"
              />
              <rect
                x="11.542"
                y="5.96558"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="white"
                stroke-width="0.75"
              />
              <rect
                x="5.48584"
                y="11.718"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="white"
                stroke-width="0.75"
              />
              <rect
                x="11.542"
                y="11.718"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="white"
                stroke-width="0.75"
              />
              <line
                x1="22.2422"
                y1="6.34375"
                x2="32.1302"
                y2="6.34375"
                stroke="#1296B0"
                stroke-width="0.75"
                stroke-linecap="round"
              />
              <line
                x1="22.2422"
                y1="10.7202"
                x2="32.1302"
                y2="10.7202"
                stroke="#1296B0"
                stroke-width="0.75"
                stroke-linecap="round"
              />
              <line
                x1="22.2422"
                y1="15.0969"
                x2="32.1302"
                y2="15.0969"
                stroke="#1296B0"
                stroke-width="0.75"
                stroke-linecap="round"
              />
            </>
          )}
        </svg>
        </Tooltip>

      </div>
      <>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            {boxView ? (
              <BoxView
                tbodyData={tbodyData}
                actionData={actionData}
                headingTile="Employee"
                subTiles={["Employee", "department", "Designation", "email"]}
                date="LastUpdatedOn"
              />
            ) : (
              <>
              <TransactionTable
             
                selectAll={selectAll}
                tableFilterName="outdoorFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
              />
              </>
            )}
            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords}
                dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
};
export { ContactDirectoryTab };
