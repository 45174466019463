/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { ServiceDash } from "./ServiceDash";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { useSelector } from "react-redux";
export const ReviewAppraisalDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(false);
  const [resultData, setResultData] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const { state, pathname } = useLocation();
  const items = state?.item;
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  console.log("object items", items);
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const reqData = {
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 10000,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSAppraisalReview/ReviewDashboard?ID=${selectedId}`,
        reqData
      );
      const resultData = await getRequest(
        `PMSAppraisalReview/AppraisalRatingSlab?ID=${selectedId}&EmployeeId=${LoggedInUser?.id}`
      );
      setData(result?.data?.data);
      setResultData(resultData?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Review Dashboard`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);
  //console.log("items",items)
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal" style={{ overflow: "auto" }}>
          <div className="headingContainer">
            <div style={{ display: "flex" }}>
              <IconButton onClick={() => navigate(-1)}>
                <svg
                  width="20"
                  height="8"
                  viewBox="0 0 20 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                    fill="#1296B0"
                  />
                </svg>
              </IconButton>
              <h4 style={{ fontSize: "16px", marginLeft: "10px" }}>
                {items?.pmsName}
              </h4>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <button className="btnSelf" onClick={togglePopup} type="button">
              Appraisal Criteria              </button> */}
            </div>
          </div>
          <div
            className="tabularMasterDetailsPageBody"
            style={{ background: "white", padding: 0 }}
          >
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Dashboard
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Summary
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {tabIndex === 0 && (
              <div>
                <div
                  style={{
                    flexDirection: "row",
                    boxShadow: "none",
                    padding: "24px",
                  }}
                >
                  <h5>Review Pending Status</h5>
                </div>
                <div className="prpstatus" style={{ justifyContent: "center" }}>
                  <div className="prrevdash ltblue" style={{ flex: 1 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="prreviewBox">
                        <div className="headtexts">
                          <div className="horizontalLinePrimary"></div>
                          <div>Self Review</div>
                          <div>Pending</div>
                        </div>
                        <div>
                          <div className="heademp">No. of Employee</div>
                          <div className="valheademp">
                            {data?.reviewSummery?.pendingSelfReview}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frameCountHolders">
                      <div className="leaveProgressContainer">
                        <CircularProgressbarWithChildren
                          className="BessHolderas"
                          maxVal={100}
                          value={
                            data?.reviewSummery?.pendingSelfReviewPercentage ??
                            0
                          }
                          styles={buildStyles({
                            pathColor: "var(--primary)",
                            fontSize: "15px",
                            textColor: "var(--primary)",

                            trailColor: "white",
                          })}
                          minVal={0}
                          sWidth={10}
                          text={`${
                            data?.reviewSummery?.pendingSelfReviewPercentage ??
                            0
                          }%`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="prrevdash ltpink" style={{ flex: 1 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="prreviewBox">
                        <div className="headtexts">
                          <div
                            className="horizontalLinePrimary"
                            style={{
                              backgroundColor: "rgba(222, 100, 161, 1)",
                            }}
                          ></div>
                          <div className="headtexts">Appraiser Review </div>
                          <div>Pending</div>
                        </div>
                        <div>
                          <div className="heademp">No. of Employee</div>
                          <div className="valheademp">
                            {data?.reviewSummery?.pendingAppraiserReview}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frameCountHolders">
                      <div className="leaveProgressContainer">
                        <CircularProgressbarWithChildren
                          className="BessHolderas"
                          maxVal={100}
                          value={
                            data?.reviewSummery
                              ?.pendingAppraiserReviewPercentage ?? 0
                          }
                          styles={buildStyles({
                            pathColor: "rgba(222, 100, 161, 1)",
                            textColor: "rgba(222, 100, 161, 1)",
                            trailColor: "white",
                          })}
                          minVal={0}
                          sWidth={5}
                          text={`${
                            data?.reviewSummery
                              ?.pendingAppraiserReviewPercentage ?? 0
                          }%`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="prrevdash ltpurple" style={{ flex: 1 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="prreviewBox">
                        <div className="headtexts">
                          <div
                            className="horizontalLinePrimary"
                            style={{
                              backgroundColor: "rgba(138, 136, 237, 1)",
                            }}
                          ></div>
                          <div>Final Review</div>
                          <div>Pending</div>
                        </div>
                        <div>
                          <div className="heademp">No. of Employee</div>
                          <div className="valheademp">
                            {data?.reviewSummery?.pendingReviewerReview}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frameCountHolders">
                      <div className="leaveProgressContainer">
                        <CircularProgressbarWithChildren
                          className="BessHolderas"
                          maxVal={100}
                          value={
                            data?.reviewSummery
                              ?.pendingReviewerReviewPercentage ?? 0
                          }
                          styles={buildStyles({
                            pathColor: "rgba(138, 136, 237, 1)",
                            textColor: "rgba(138, 136, 237, 1)",
                            trailColor: "white",
                          })}
                          minVal={0}
                          sWidth={6}
                          text={`${
                            data?.reviewSummery
                              ?.pendingReviewerReviewPercentage ?? 0
                          }%`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="prrevdash ltpeach" style={{ flex: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div className="prreviewBox">
                        <div className="headtexts">
                          <div
                            className="horizontalLinePrimary "
                            style={{ backgroundColor: "rgba(251, 113, 88, 1)" }}
                          ></div>
                          <div> Review</div>
                          <div>Completed</div>
                        </div>
                        <div>
                          <div className="heademp">No. of Employee</div>
                          <div className="valheademp">
                            {data?.reviewSummery?.reviewCompleted}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frameCountHolders">
                      <div className="leaveProgressContainer">
                        <CircularProgressbarWithChildren
                          className="BessHolderas"
                          maxVal={100}
                          value={
                            data?.reviewSummery?.reviewCompletedPercentage ?? 0
                          }
                          styles={buildStyles({
                            pathColor: "rgba(251, 113, 88, 1)",
                            textColor: "rgba(251, 113, 88, 1)",
                            trailColor: "white",
                          })}
                          minVal={0}
                          sWidth={5}
                          text={`${
                            data?.reviewSummery?.reviewCompletedPercentage ?? 0
                          }%`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    boxShadow: "none",
                    padding: "24px",
                  }}
                >
                  <h5>Rating Status</h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="prpstatus"
                    style={{ justifyContent: "center", width: "30%" }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: "12px",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          padding: "0px 0px 12px 1px",
                        }}
                      >
                        Self Review Rating
                      </p>
                      <div className="ratingCar">
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={5}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {data?.reviewSummery?.selfReviewRating?.["5star"]}
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={4}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {data?.reviewSummery?.selfReviewRating?.["4star"]}
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={3}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {data?.reviewSummery?.selfReviewRating?.["3star"]}
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={2}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {data?.reviewSummery?.selfReviewRating?.["2star"]}
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={1}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {data?.reviewSummery?.selfReviewRating?.["1star"]}
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="prpstatus"
                    style={{ justifyContent: "center", width: "30%" }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: "12px",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          padding: "0px 0px 12px 1px",
                        }}
                      >
                        Appraiser Review
                      </p>
                      <div className="ratingCar">
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={5}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.appraiserReviewRating?.[
                                  "5star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={4}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.appraiserReviewRating?.[
                                  "4star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={3}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.appraiserReviewRating?.[
                                  "3star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={2}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.appraiserReviewRating?.[
                                  "2star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={1}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.appraiserReviewRating?.[
                                  "1star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="prpstatus"
                    style={{ justifyContent: "center", width: "30%" }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: "12px",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          padding: "0px 0px 12px 1px",
                        }}
                      >
                        Final Review Rating
                      </p>
                      <div className="ratingCar">
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={5}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.finalReviewRating?.[
                                  "5star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={4}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.finalReviewRating?.[
                                  "4star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={3}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.finalReviewRating?.[
                                  "3star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={2}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.finalReviewRating?.[
                                  "2star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                        <div
                          className="starholderpmself"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div style={{}}>
                            <StarRatings
                              starEmptyColor={"#BFBFBF"}
                              starRatedColor={"var(--secondary)"}
                              starDimension="17px"
                              starSpacing="2px"
                              rating={1}
                            />
                          </div>
                          <label
                            className="jobDesignation"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#279FB7",
                            }}
                          >
                            <b>
                              {
                                data?.reviewSummery?.finalReviewRating?.[
                                  "1star"
                                ]
                              }
                            </b>{" "}
                            Employee
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    flexDirection: "row",
                    boxShadow: "none",
                    padding: "26px",
                  }}
                >
                  <h5>Rating Status</h5>
                </div>

                <div style={{ display: "flex" }}>
                  <div className="cardRatingEmp">
                    <div
                      className="starholderpms"

                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="18px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                  </div>
                  <div className="cardRatingEmp">
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                  </div>
                  <div className="cardRatingEmp">
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>{" "}
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>{" "}
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                  </div>
                  <div className="cardRatingEmp">
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>{" "}
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>{" "}
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                  </div>
                </div> */}
                <div
                  style={{
                    padding: "24px",
                    margin: "24px",
                    boxShadow: "rgba(0, 0, 0, 0.04) 0px 1.89445px 10px",
                  }}
                >
                  <table className="tableNoti tabless ">
                    <thead
                      className="headNoti"
                      style={{ borderBottom: "1px solid #1296b0 !important" }}
                    >
                      <tr>
                        <td style={{ textAlign: "center" }}>Department Name</td>
                        <td style={{ textAlign: "center" }}>Self Review</td>
                        <td style={{ textAlign: "center" }}>
                          Appraiser Review
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          Review Completed
                        </td>
                        <td style={{ textAlign: "center" }}>Final Review</td>
                        <td style={{ textAlign: "center" }}>Total Completed</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.departmentWiseReviewSummery?.map((item, index) => {
                        return (
                          <tr className="trStyle">
                            <td style={{ textAlign: "center" }}>
                              {item?.department}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {" "}
                                  <b>
                                    {item.pendingSelfReview}/{item?.total}{" "}
                                  </b>
                                </div>{" "}
                                <CircularProgressbarWithChildren
                                  className="BessHolder"
                                  maxVal={100}
                                  value={item.pendingReviewerReviewPercentage}
                                  styles={buildStyles({
                                    pathColor: "var(--primary)",
                                    textColor: "var(--primary)",
                                    trailColor: "white",
                                  })}
                                  minVal={0}
                                  sWidth={3}
                                  text={`${
                                    item.pendingReviewerReviewPercentage ?? 0
                                  }%`}
                                />
                              </div>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {" "}
                                  <b>
                                    {item.pendingAppraiserReview}/{item?.total}{" "}
                                  </b>
                                </div>{" "}
                                <CircularProgressbarWithChildren
                                  className="BessHolder"
                                  maxVal={100}
                                  value={item.pendingAppraiserReviewPercentage}
                                  styles={buildStyles({
                                    pathColor: "rgba(222, 100, 161, 1)",
                                    textColor: "rgba(222, 100, 161, 1)",
                                    trailColor: "white",
                                  })}
                                  minVal={0}
                                  sWidth={3}
                                  text={`${
                                    item.pendingAppraiserReviewPercentage ?? 0
                                  }%`}
                                />
                              </div>{" "}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {" "}
                                  <b>
                                    {item.pendingReviewerReview}/{item?.total}{" "}
                                  </b>
                                </div>{" "}
                                <CircularProgressbarWithChildren
                                  className="BessHolder"
                                  maxVal={100}
                                  value={item.pendingReviewerReviewPercentage}
                                  styles={buildStyles({
                                    pathColor: "rgba(138, 136, 237, 1)",
                                    textColor: "rgba(138, 136, 237, 1)",
                                    trailColor: "white",
                                  })}
                                  minVal={0}
                                  sWidth={3}
                                  text={`${
                                    item.pendingReviewerReviewPercentage ?? 0
                                  }%`}
                                />
                              </div>{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {" "}
                                  <b>
                                    {item.pendingFinalReview ?? 0}/{item?.total}{" "}
                                  </b>
                                </div>{" "}
                                <CircularProgressbarWithChildren
                                  className="BessHolder"
                                  maxVal={100}
                                  value={item.pendingFinalReviewPercentage}
                                  styles={buildStyles({
                                    pathColor: "rgba(138, 136, 237, 1)",
                                    textColor: "rgba(138, 136, 237, 1)",
                                    trailColor: "white",
                                  })}
                                  minVal={0}
                                  sWidth={5}
                                  text={`${
                                    item?.pendingFinalReviewPercentage ?? 0
                                  }%`}
                                />
                              </div>{" "}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <b>{item?.reviewCompleted}</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {isOpen && (
                  <Popup
                    popupHeading={"Rating Slab"}
                    content={
                      <>
                        <InstructionSelf data={resultData} />
                      </>
                    }
                    handleClose={togglePopup}
                  />
                )}
              </div>
            )}
            {tabIndex === 1 && (
              <>
                <div>
                  <ServiceDash id={selectedId} title={"Summary"} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
