/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { Form, Formik } from "formik";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { selfRatingObtain } from "../../../../Utilities";
import { useSelector } from "react-redux";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { formatNumber } from "../../../../Utilities/formatNumber";
const SubmitAppraiserReviewerReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams();
  let xy = Object.keys(id);
  const { LoggedInUser } = useSelector((state) => state.auth);

  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const selectedIds = location?.state?.item;

  const isEdit = location?.state?.type === "Update Review";
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [details, setDetails] = useState(false);
  const [formValues, setFormValues] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [newDesignationOptions, setNewDesignationOptions] = useState([]);
  const [newRoleOptions, setNewRoleOptions] = useState([]);
  const [newBandOptions, setNewBandOptions] = useState([]);

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  console.log("object location?.state?", location?.state);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSAppraisalReview/AppraisalRatingSlab?ID=${selectedId}&EmployeeId=${selectedIds?.item?.employeeId}`
      );
      const resultDetails = await getRequest(
        `PMSAppraisalReview/FinalReviewEmployee?ID=${selectedId}&EmployeeId=${selectedIds?.item?.employeeId}`
      );
      setData(result?.data?.data);
      setDetails(resultDetails?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getDropdowns = async () => {
    try {
      const resultBands = await getRequest(`Bands/GetBandss`);
      const resultGetDesignationss = await getRequest(
        `LeaveTypes/GetDesignationss`
      );
      const resultGetRoless = await getRequest(`LeaveTypes/GetRoless`);

      setNewBandOptions(
        resultBands?.data?.results?.map((item) => ({
          label: item.text,
          value: item.text,
        }))
      );
      setNewRoleOptions(
        resultGetRoless?.data?.results?.map((item) => ({
          label: item.text,
          value: item.text,
        }))
      );
      setNewDesignationOptions(
        resultGetDesignationss?.data?.results?.map((item) => ({
          label: item.text,
          value: item.text,
        }))
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Submit Self Review `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    if (details && data) {
      setInitialValues({
        total: "",
        appraisalCriteriaClassification: getGoalslist(),
        appraiserReview: getappraiserReviewlist(),
        reviewerReview: getreviewerReviewlist(),
        common: getCommonlist(),
      });
    }
  }, [details, data]);
  // useEffect(() => {
  //   if (
  //     details &&
  //     data &&
  //     formValues?.reviewerReview?.every(
  //       (it) => typeof it?.reviewerReview === "number"
  //     )
  //   ) {
  //     const reviewerTotal = formValues?.reviewerReview?.reduce(
  //       (accumulator, currentValue) => {
  //         return accumulator + Number(currentValue?.reviewerReview || 0);
  //       },
  //       0 || 0
  //     );

  //     if (!isNaN(reviewerTotal)) {
  //       setInitialValues((prev) => {
  //         const arr = {
  //           ...formValues,
  //           common: {
  //             ...formValues?.common,
  //             revisedSalary: getRevisedSalary(
  //               formValues?.common?.currentSalary
  //             ),
  //           },
  //         };
  //         return arr;
  //       });
  //     }
  //   }
  // }, [formValues?.reviewerReview]);
  const getreviewerReviewlist = () => {
    let returnArray = [];
    data?.appraisalCriteriaClassification?.map((item, index) => {
      returnArray.push({
        criteria: item?.criteria,
        companyWeightage: item?.weightage,
        systemInputs:
          item?.criteria === "Performance"
            ? formatNumber(data?.systemInput?.Performance)
            : item?.criteria === "Tasks"
            ? formatNumber(data?.systemInput?.Tasks)
            : item?.criteria === "Rewards"
            ? formatNumber(data?.systemInput?.Rewards)
            : item?.criteria === "L&D"
            ? formatNumber(data?.systemInput?.[`L&D`])
            : "",
        selfReview:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeePerformanceReview
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeTaskReview
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeRewordsReview
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeLNDReview
            : "",
        appraiserReview:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.appraiserPerformanceReview
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.appraiserTaskReview
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.appraiserRewordsReview
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.appraiserLNDReview
            : "",
        reviewerReview:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.appraiserPerformanceReview
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.appraiserTaskReview
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.appraiserRewordsReview
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.appraiserLNDReview
            : "",
        reviewerComment: "",
      });
      return null;
    });
    return returnArray;
  };
  const getappraiserReviewlist = () => {
    let returnArray = [];
    data?.appraisalCriteriaClassification?.map((item, index) => {
      returnArray.push({
        criteria: item?.criteria,
        companyWeightage: item?.weightage,
        systemInputs: 0,
        selfReview:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerPerformanceReview
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerTaskReview
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerRewordsReview
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerLNDReview
            : "",
        appraiserReview: "",
        appraiserReviewComment:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerPerformanceComment
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerTaskComment
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerRewordsComment
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerLNDComment
            : "",
      });
      return null;
    });
    return returnArray;
  };
  const getGoalslist = () => {
    let returnArray = [];
    data?.appraisalCriteriaClassification?.map((item, index) => {
      returnArray.push({
        criteria: item?.criteria,
        companyWeightage: item?.weightage,
        systemInputs:
          item?.criteria === "Performance"
            ? data?.systemInput?.Performance
            : item?.criteria === "Tasks"
            ? data?.systemInput?.Tasks
            : item?.criteria === "Rewards"
            ? data?.systemInput?.Rewards
            : item?.criteria === "L&D"
            ? data?.systemInput?.[`L&D`]
            : 0,
        selfReview:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeePerformanceReview
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeTaskReview
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeRewordsReview
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeLNDReview
            : "",
        selfComment:
          item?.criteria === "Performance"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeePerformanceComment
            : item?.criteria === "Tasks"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeTaskComment
            : item?.criteria === "Rewards"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeRewordsComment
            : item?.criteria === "L&D"
            ? details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.employeeLNDComment
            : "",
      });
      return null;
    });
    return returnArray;
  };
  const selfValuePercentObtain = (total, starData) => {
    return {
      val: Number(
        starData?.appraisalCriteriaSlab
          ?.sort((a, b) => a.weightFrom - b.weightFrom)
          ?.find(
            (item) =>
              Number(total) >= item?.weightFrom &&
              Number(total) <= item?.weightTo
          )?.appraisalValue
      ),
      perct: Number(
        starData?.appraisalCriteriaSlab
          ?.sort((a, b) => a.weightFrom - b.weightFrom)
          ?.find(
            (item) =>
              Number(total) >= item?.weightFrom &&
              Number(total) <= item?.weightTo
          )?.appraisalPercentage
      ),
    };
  };
  const getRevisedSalary = useCallback(
    (ctc) => {
      const { val, perct } = selfValuePercentObtain(
        formValues?.reviewerReview?.reduce((accumulator, currentValue) => {
          return accumulator + Number(currentValue?.reviewerReview);
        }, 0),
        data
      );
      const valFinalSalary = Number(ctc) + Number(val);
      const perctFinalSalary =
        Number(ctc) + Number(ctc) * (Number(perct) / 100);

      if (val) {
        return valFinalSalary;
      } else {
        return perctFinalSalary;
      }
    },
    [
      formValues?.reviewerReview?.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue?.reviewerReview);
      }, 0),
      data,
    ] // Dependencies
  );
  const getCommonlist = () => {
    let returnArray = {
      finalWeightage: "",
      currentSalary: details?.pmsReviews?.lastCTC,
      revisedSalary: getRevisedSalary(details?.pmsReviews?.lastCTC),
      purposedFinalSalary: "",
      currentDesignation: details?.pmsReviews?.employee?.designation,
      newDesignation: "",
      currentRole: details?.pmsReviews?.employee?.roleName,
      newRole: "",
      currentBand: details?.pmsReviews?.employee?.bandName,
      newBand: "",
    };
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    total: "",
    appraisalCriteriaClassification: getGoalslist(),
    appraiserReview: getappraiserReviewlist(),
    reviewerReview: getreviewerReviewlist(),
    common: getCommonlist(),
  });

  console.log("object initialValues", initialValues);
  console.log("object formValues", formValues);
  useEffect(() => {
    getDetails();
    getDropdowns();
  }, []);
  const createFunction = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "PMSAppraisalReview/ReviewerReview",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      navigate(-1);
      getDetails();
    }
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const onSubmit = async (values) => {
    if (
      values?.reviewerReview?.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue?.reviewerReview);
      }, 0) > 100
    ) {
      dispatch(
        showToast({
          text: "Review cannot be more than 100",
          severity: "error",
        })
      );
    } else {
      const create = {};
      create["Id"] = Number(
        details?.pmsReviews?.appraisalReviewFeedbackNews[0]?.id
      );
      create["ReviewId"] = Number(details?.pmsReviews?.id);
      create["NewDesignation"] = values?.common?.newDesignation;
      create["NewRole"] = values?.common?.newRole;
      create["NewBand"] = values?.common?.newBand;
      create["PurposedFinalSalery"] = values?.common?.purposedFinalSalary;
      values?.reviewerReview?.forEach((item) => {
        if (item?.criteria === "Performance") {
          create["ReviewerPerformanceReview"] = item?.reviewerReview;
          create["ReviewerPerformanceComment"] = item?.reviewerComment;
        }
        if (item?.criteria === "Tasks") {
          create["ReviewerTaskReview"] = item?.reviewerReview;
          create["ReviewerTaskComment"] = item?.reviewerComment;
        }
        if (item?.criteria === "Rewards") {
          create["ReviewerRewordsReview"] = item?.reviewerReview;
          create["ReviewerRewordsComment"] = item?.reviewerComment;
        }
        if (item?.criteria === "L&D") {
          create["ReviewerLNDReview"] = item?.reviewerReview;
          create["ReviewerLNDComment"] = item?.reviewerComment;
        }
      });
      createFunction(create);
    }
  };
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={onSubmit}
              validationSchema={Yup.object({
                reviewerReview: Yup.array().of(
                  Yup.object().shape({
                    reviewerReview: Yup.string().required("Enter"),
                  })
                ),
              })}
            >
              {(formik) => {
                chooseFormValues && chooseFormValues(formik.values);
                return (
                  <Form style={{ height: "100%" }}>
                    <div className="selfParent" style={{ gap: "0px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={() => navigate(-1)}
                          style={{ display: "flex" }}
                          className="returnToDashboard cursor"
                        >
                          <div>
                            <svg
                              width="20"
                              height="8"
                              viewBox="0 0 20 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                                fill="#1296B0"
                              />
                            </svg>
                          </div>
                          <label className="returnLabelHolder">
                            {details?._pmsAppraisalCycles?.name}
                          </label>
                        </div>

                        <div>
                          <button
                            className="btnSelf"
                            onClick={togglePopup}
                            type="button"
                          >
                            Appraisal Criteria{" "}
                          </button>
                        </div>
                      </div>
                      <div style={{ overflowY: "auto" }}>
                        <div style={{ display: "flex", gap: "20px" }}>
                          <div
                            className="ratingSelf"
                            style={{
                              display: "block",
                              padding: "16px",
                              width: "278px",
                              height: "149px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div style={{ marignTop: "12px" }}>
                                Self Rating :
                              </div>
                              <div>
                                {" "}
                                <StarRatings
                                  rating={selfRatingObtain(
                                    formValues?.appraisalCriteriaClassification?.reduce(
                                      (accumulator, currentValue) => {
                                        return (
                                          accumulator +
                                          Number(currentValue?.selfReview)
                                        );
                                      },
                                      0
                                    ),
                                    data
                                  )}
                                  starRatedColor="#5BC4BF"
                                  starSpacing="2px"
                                  starEmptyColor="#FFFFFF"
                                  numberOfStars={5}
                                  starHoverColor="var(--primary)"
                                  starDimension="28px"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>Self Achievement % :</div>
                              <div>
                                <p
                                  style={{
                                    color: "#5BC4BF",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                  }}
                                >
                                  {formValues?.appraisalCriteriaClassification?.reduce(
                                    (accumulator, currentValue) => {
                                      return (
                                        accumulator +
                                        Number(currentValue?.selfReview)
                                      );
                                    },
                                    0
                                  )}
                                  %
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="ratingSelf"
                            style={{
                              display: "block",
                              padding: "16px",
                              width: "278px",
                              height: "149px",
                              backgroundColor: "#FFEEF2",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div style={{ marignTop: "12px" }}>
                                Appraiser Review Rating :
                              </div>
                              <div>
                                {" "}
                                <StarRatings
                                  rating={selfRatingObtain(
                                    Number(
                                      details?.pmsReviews
                                        ?.appraisalReviewFeedbackNews[0]
                                        ?.appraiserLNDReview ?? 0
                                    ) +
                                      Number(
                                        details?.pmsReviews
                                          ?.appraisalReviewFeedbackNews[0]
                                          ?.appraiserPerformanceReview ?? 0
                                      ) +
                                      Number(
                                        details?.pmsReviews
                                          ?.appraisalReviewFeedbackNews[0]
                                          ?.appraiserRewordsReview ?? 0
                                      ) +
                                      Number(
                                        details?.pmsReviews
                                          ?.appraisalReviewFeedbackNews[0]
                                          ?.appraiserTaskReview ?? 0
                                      ),
                                    data
                                  )}
                                  starRatedColor="#DE64A1"
                                  starSpacing="2px"
                                  starEmptyColor="#FFFFFF"
                                  numberOfStars={5}
                                  starHoverColor="var(--primary)"
                                  starDimension="28px"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>Appraiser Review Achievement % :</div>
                              <div>
                                <p
                                  style={{
                                    color: "#DE64A1",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                  }}
                                >
                                  {Number(
                                    details?.pmsReviews
                                      ?.appraisalReviewFeedbackNews[0]
                                      ?.appraiserLNDReview ?? 0
                                  ) +
                                    Number(
                                      details?.pmsReviews
                                        ?.appraisalReviewFeedbackNews[0]
                                        ?.appraiserPerformanceReview ?? 0
                                    ) +
                                    Number(
                                      details?.pmsReviews
                                        ?.appraisalReviewFeedbackNews[0]
                                        ?.appraiserRewordsReview ?? 0
                                    ) +
                                    Number(
                                      details?.pmsReviews
                                        ?.appraisalReviewFeedbackNews[0]
                                        ?.appraiserTaskReview ?? 0
                                    )}
                                  %
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="ratingSelf"
                            style={{
                              display: "block",
                              padding: "16px",
                              width: "278px",
                              height: "149px",
                              backgroundColor: "#FFF2F0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div style={{ marignTop: "12px" }}>
                                Reviewer Review Rating :
                              </div>
                              <div>
                                {" "}
                                <StarRatings
                                  rating={selfRatingObtain(
                                    formValues?.reviewerReview?.reduce(
                                      (accumulator, currentValue) => {
                                        return (
                                          accumulator +
                                          Number(currentValue?.reviewerReview)
                                        );
                                      },
                                      0
                                    ),
                                    data
                                  )}
                                  starRatedColor="#FB7158"
                                  starSpacing="2px"
                                  starEmptyColor="#FFFFFF"
                                  numberOfStars={5}
                                  starHoverColor="var(--primary)"
                                  starDimension="28px"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>Reviewer Review Achievement % :</div>
                              <div>
                                <p
                                  style={{
                                    color: "#FB7158",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                  }}
                                >
                                  {formValues?.reviewerReview?.reduce(
                                    (accumulator, currentValue) => {
                                      return (
                                        accumulator +
                                        Number(currentValue?.reviewerReview)
                                      );
                                    },
                                    0
                                  )}
                                  %
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {data?.appraisalCriteriaClassification?.length > 0 ? (
                          <div
                            style={{
                              width: "100%",
                              overflow: "auto",
                              margin: "10px 0px 20px 0px",
                            }}
                          >
                            {/* <div className="borderDot"></div> */}
                            <div
                              style={{
                                marginTop: "12px",
                                background: "#d4f2f1",
                                borderRadius: "6px",
                                display: "flex",
                              }}
                            >
                              <div className="centertdtwo">
                                <div>Self</div>
                                <div>Review</div>
                              </div>
                              <div
                                style={{
                                  width: "calc(100% - 120px)",
                                  padding: "1px 10px",
                                }}
                              >
                                <table
                                  className="appriaisalReviewTableDetail"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        borderBottom: "1px #5BC4BF solid",
                                      }}
                                    >
                                      <td style={{ width: "140px" }}>
                                        Criteria
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Company <br />
                                        Weightage
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        System <br />
                                        Inputs
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Self <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "308px",
                                          // paddingLeft: "23px",
                                        }}
                                      >
                                        Self Comment
                                      </td>
                                      <td></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.appraisalCriteriaClassification?.map(
                                      (item, index) => (
                                        <tr>
                                          <td>{item?.criteria}</td>
                                          <td style={{ textAlign: "center" }}>
                                            {" "}
                                            {item?.weightage}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {item?.criteria === "Performance"
                                              ? formatNumber(
                                                  data?.systemInput?.Performance
                                                )
                                              : item?.criteria === "Tasks"
                                              ? formatNumber(
                                                  data?.systemInput?.Tasks
                                                )
                                              : item?.criteria === "Rewards"
                                              ? formatNumber(
                                                  data?.systemInput?.Rewards
                                                )
                                              : item?.criteria === "L&D"
                                              ? formatNumber(
                                                  data?.systemInput?.[`L&D`]
                                                )
                                              : ""}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {item?.criteria === "Performance"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceReview
                                              : item?.criteria === "Tasks"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeTaskReview
                                              : item?.criteria === "Rewards"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeRewordsReview
                                              : item?.criteria === "L&D"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeLNDReview
                                              : 0}
                                          </td>
                                          <td>
                                            {item?.criteria === "Performance"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceComment
                                              : item?.criteria === "Tasks"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeTaskComment
                                              : item?.criteria === "Rewards"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeRewordsComment
                                              : item?.criteria === "L&D"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeLNDComment
                                              : 0}
                                          </td>
                                          <td></td>
                                        </tr>
                                      )
                                    )}
                                    <tr
                                      style={{
                                        borderTop:
                                          "1px solid rgb(91, 196, 191)",
                                      }}
                                    >
                                      <td style={{ color: "#5BC4BF" }}>
                                        Total
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#5BC4BF",
                                        }}
                                      >
                                        {data?.appraisalCriteriaClassification?.reduce(
                                          (accumulator, currentValue) => {
                                            return (
                                              accumulator +
                                              Number(currentValue?.weightage)
                                            );
                                          },
                                          0
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#5BC4BF",
                                        }}
                                      >
                                        {formatNumber(
                                          data?.systemInput?.Performance +
                                            data?.systemInput?.Tasks +
                                            data?.systemInput?.Rewards +
                                            data?.systemInput?.[`L&D`]
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#5BC4BF",
                                        }}
                                      >
                                        {Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeLNDReview ?? 0
                                        ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeePerformanceReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeRewordsReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeTaskReview ?? 0
                                          )}
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {data?.appraisalCriteriaClassification?.length > 0 ? (
                          <div
                            style={{
                              width: "100%",
                              overflow: "auto",
                              margin: "10px 0px 20px 0px",
                            }}
                          >
                            {/* <div className="borderDot"></div> */}
                            <div
                              style={{
                                marginTop: "12px",
                                background: "#FFEEF2",
                                borderRadius: "6px",
                                display: "flex",
                              }}
                            >
                              <div className="centertdthree">
                                <div>Appraiser</div>
                                <div>Review</div>
                              </div>
                              <div
                                style={{
                                  width: "calc(100% - 120px)",
                                  padding: "1px 10px",
                                }}
                              >
                                <table
                                  className="appriaisalReviewTableDetail"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        borderBottom: "1px #E06DA6 solid",
                                      }}
                                    >
                                      <td style={{ width: "140px" }}>
                                        Criteria
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Company <br />
                                        Weightage
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        System <br />
                                        Inputs
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Self <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Appraiser <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "308px",
                                          paddingLeft: "23px",
                                        }}
                                      >
                                        Appraiser Comment
                                      </td>
                                      <td></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.appraisalCriteriaClassification?.map(
                                      (item, index) => (
                                        <tr>
                                          <td>{item?.criteria}</td>
                                          <td style={{ textAlign: "center" }}>
                                            {item?.weightage}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {item?.criteria === "Performance"
                                              ? formatNumber(
                                                  data?.systemInput?.Performance
                                                )
                                              : item?.criteria === "Tasks"
                                              ? formatNumber(
                                                  data?.systemInput?.Tasks
                                                )
                                              : item?.criteria === "Rewards"
                                              ? formatNumber(
                                                  data?.systemInput?.Rewards
                                                )
                                              : item?.criteria === "L&D"
                                              ? formatNumber(
                                                  data?.systemInput?.[`L&D`]
                                                )
                                              : 0}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {item?.criteria === "Performance"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceReview
                                              : item?.criteria === "Tasks"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeTaskReview
                                              : item?.criteria === "Rewards"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeRewordsReview
                                              : item?.criteria === "L&D"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeeLNDReview
                                              : ""}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {item?.criteria === "Performance"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserPerformanceReview
                                              : item?.criteria === "Tasks"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserTaskReview
                                              : item?.criteria === "Rewards"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserRewordsReview
                                              : item?.criteria === "L&D"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserLNDReview
                                              : ""}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "start",
                                              paddingLeft: "26px",
                                            }}
                                          >
                                            {item?.criteria === "Performance"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserPerformanceComment
                                              : item?.criteria === "Tasks"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserTaskComment
                                              : item?.criteria === "Rewards"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserRewordsComment
                                              : item?.criteria === "L&D"
                                              ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserLNDComment
                                              : ""}
                                          </td>
                                          <td></td>
                                        </tr>
                                      )
                                    )}
                                    <tr
                                      style={{
                                        borderTop: "1px solid #DE64A1",
                                      }}
                                    >
                                      <td>Total</td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#E06DA6",
                                        }}
                                      >
                                        {data?.appraisalCriteriaClassification?.reduce(
                                          (accumulator, currentValue) => {
                                            return (
                                              accumulator +
                                              Number(currentValue?.weightage)
                                            );
                                          },
                                          0
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#E06DA6",
                                        }}
                                      >
                                        {formatNumber(
                                          data?.systemInput?.Performance +
                                            data?.systemInput?.Tasks +
                                            data?.systemInput?.Rewards +
                                            data?.systemInput?.[`L&D`]
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#E06DA6",
                                        }}
                                      >
                                        {Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeLNDReview ?? 0
                                        ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeePerformanceReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeRewordsReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeTaskReview ?? 0
                                          )}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#E06DA6",
                                        }}
                                      >
                                        {" "}
                                        {Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.appraiserLNDReview ?? 0
                                        ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.appraiserPerformanceReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.appraiserRewordsReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.appraiserTaskReview ?? 0
                                          )}
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {data?.appraisalCriteriaClassification?.length > 0 ? (
                          <div
                            style={{
                              width: "100%",
                              overflow: "auto",
                              margin: "10px 0px 20px 0px",
                            }}
                          >
                            {/* <div className="borderDot"></div> */}
                            <div
                              style={{
                                marginTop: "12px",
                                background: "#FFF2F0",
                                borderRadius: "6px",
                                display: "flex",
                              }}
                            >
                              <div className="centertdfour">
                                <div>Reviewer</div>
                                <div>Review</div>
                              </div>
                              <div
                                style={{
                                  width: "calc(100% - 120px)",
                                  padding: "1px 10px",
                                }}
                              >
                                <table
                                  className="appriaisalReviewTable"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        borderBottom: "1px #FB7158 solid",
                                      }}
                                    >
                                      <td style={{ width: "140px" }}>
                                        Criteria
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Company <br />
                                        Weightage
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        System <br />
                                        Inputs
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Self <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Appraiser <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Reviewer <br />
                                        Review
                                      </td>
                                      <td
                                        style={{
                                          width: "308px",
                                          paddingLeft: "23px",
                                        }}
                                      >
                                        Reviewer Comment
                                      </td>
                                      <td></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.appraisalCriteriaClassification?.map(
                                      (item, index) => (
                                        <tr>
                                          <td>{item?.criteria}</td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type={"number"}
                                              disabled={true}
                                              value={item?.weightage}
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`reviewerReview[${index}].companyWeightage`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type={"number"}
                                              disabled={true}
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`reviewerReview[${index}].systemInputs`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type={"number"}
                                              disabled={true}
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`reviewerReview[${index}].selfReview`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type={"number"}
                                              disabled={true}
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`reviewerReview[${index}].appraiserReview`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <InputCustomFormik
                                              type={"number"}
                                              className={
                                                "appriaisalReviewTableinput"
                                              }
                                              name={`reviewerReview[${index}].reviewerReview`}
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <TextAreaFormik
                                              style={{ height: "80px" }}
                                              name={`reviewerReview[${index}].reviewerComment`}
                                              placeholder="Type Here"
                                              maxLength={500}
                                            />
                                          </td>
                                          <td></td>
                                        </tr>
                                      )
                                    )}
                                    <tr
                                      style={{
                                        borderTop: "1px solid #FB7158",
                                      }}
                                    >
                                      <td style={{ color: "#FB7158" }}>
                                        Total
                                      </td>
                                      <td>
                                        <InputCustomFormik
                                          disabled={true}
                                          value={formValues?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(
                                                  currentValue?.companyWeightage
                                                )
                                              );
                                            },
                                            0
                                          )}
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`companyWeightageTotal`}
                                        />
                                      </td>
                                      <td>
                                        <InputCustomFormik
                                          disabled={true}
                                          value={formValues?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(
                                                  currentValue?.systemInputs
                                                )
                                              );
                                            },
                                            0
                                          )}
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`systemInputsTotal`}
                                        />
                                      </td>
                                      <td>
                                        <InputCustomFormik
                                          disabled={true}
                                          value={formValues?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(currentValue?.selfReview)
                                              );
                                            },
                                            0
                                          )}
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`selfReviewTotal`}
                                        />
                                      </td>
                                      <td>
                                        <InputCustomFormik
                                          disabled={true}
                                          value={
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserLNDReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserPerformanceReview ??
                                                0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserTaskReview ?? 0
                                            )
                                          }
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`appraiserReviewTotal`}
                                        />
                                      </td>
                                      <td>
                                        <InputCustomFormik
                                          disabled={true}
                                          value={formValues?.reviewerReview?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(
                                                  currentValue?.reviewerReview
                                                )
                                              );
                                            },
                                            0
                                          )}
                                          className={
                                            "appriaisalReviewTableinput"
                                          }
                                          name={`reviewerReviewTotal`}
                                        />
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div>
                          <div style={{ display: "flex", margin: "50px 0px" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "50%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  Final Weightage
                                </div>
                                :{" "}
                                <div>
                                  <InputCustomFormik
                                    value={Number(
                                      formValues?.reviewerReview?.reduce(
                                        (accumulator, currentValue) => {
                                          return (
                                            accumulator +
                                            Number(currentValue?.reviewerReview)
                                          );
                                        },
                                        0
                                      ) ?? 0
                                    )}
                                    formikWidth={"229px"}
                                    disabled={true}
                                    name={`common.finalWeightage`}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  Current Salary
                                </div>
                                :{" "}
                                <div>
                                  <InputCustomFormik
                                    formikWidth={"229px"}
                                    disabled={true}
                                    name={`common.currentSalary`}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  Revised Salary <br />
                                  as per Final weightage 
                                </div>
                                :{" "}
                                <div>
                                  <InputCustomFormik
                                    formikWidth={"229px"}
                                    disabled={true}
                                    value={getRevisedSalary(
                                      details?.pmsReviews?.lastCTC
                                    )}
                                    name={`common.revisedSalary`}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  Proposed Final Salary
                                </div>
                                :{" "}
                                <div>
                                  <InputCustomFormik
                                    style={{ border: "0.75px solid #BFBFBF" }}
                                    formikWidth={"229px"}
                                    name={`common.purposedFinalSalary`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "50%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  Current Designation
                                </div>
                                :{" "}
                                <div>
                                  <InputCustomFormik
                                    formikWidth={"229px"}
                                    disabled={true}
                                    name={`common.currentDesignation`}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  New Designation
                                </div>
                                :{" "}
                                <div className="formikPMSBorder">
                                  <SelectForm
                                    formikWidth={"229px"}
                                    name={`common.newDesignation`}
                                    options={newDesignationOptions}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  Current Role
                                </div>
                                :{" "}
                                <div>
                                  <InputCustomFormik
                                    formikWidth={"229px"}
                                    disabled={true}
                                    name={`common.currentRole`}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  New Role
                                </div>
                                :{" "}
                                <div className="formikPMSBorder">
                                  <SelectForm
                                    formikWidth={"229px"}
                                    name={`common.newRole`}
                                    options={newRoleOptions}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  Current Band
                                </div>
                                :{" "}
                                <div>
                                  <InputCustomFormik
                                    formikWidth={"229px"}
                                    disabled={true}
                                    name={`common.currentBand`}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    width: "172px",
                                  }}
                                >
                                  New Band
                                </div>
                                :{" "}
                                <div className="formikPMSBorder">
                                  <SelectForm
                                    formikWidth={"229px"}
                                    name={`common.newBand`}
                                    options={newBandOptions}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="createFootCust">
                        <div>
                          <div
                            //   onClick={returnPage}
                            className={`button secondaryButton`}
                            style={{ border: "1px #1190A9 solid" }}
                          >
                            Save as draft
                          </div>
                        </div>
                        <div>
                          <button
                            className="button primaryButton"
                            type="submit"
                          >
                            Save & Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Rating Slab"}
          content={
            <>
              <InstructionSelf data={data} />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export { SubmitAppraiserReviewerReview };
