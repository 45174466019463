/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { BoxView } from "../../../../Components/index";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import "react-confirm-alert/src/react-confirm-alert.css";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";

const Loan = ({ empId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParam, setSearchParam] = useState(null);
  const [bandList, setBandList] = useState([]);
  const [probationList, setProbationList] = useState([]);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedBandList, setSelectedBandList] = useState([]);
  const [selectedProbationList, setSelectedProbationList] = useState([]);
  const [reqData, setReqData] = useState({
    noticePeriod: null,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      isExport: false,
    },
  });
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Loan payroll `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `PreparePayroll/LoanList/${empId}`,
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              checkActive: false,
              status: item?.status,
              requiredByDate: item.item.requiredBydate,
              statusColor: item?.statusColor,
            })
          );
          const clippedArray = tempData.map(
            ({ projectDuration, moreActions, projectDelayed, ...rest }) => ({
              ...rest,
            })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              requestNumber,
              installMentAmount,
              amountRepaid,
              percentage,
              loanAmount,
              remainingAmount,
              remainingInstallment,
              nextInstallment,
              reason,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              installMentAmount,
              amountRepaid,
              percentage,
              loanAmount,
              remainingAmount,
              remainingInstallment,
              nextInstallment,
              reason,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getBandListFilter = async () => {
    try {
      const result = await getRequest("Bands/GetNoticePeriod");
      let listData = [];
      result.data.map((item) => {
        return listData.push({
          label: item.noticePeriod,
          value: item.noticePeriod,
        });
      });
      setBandList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getProbationListFilter = async () => {
    try {
      const result = await getRequest("Bands/GetProbationPeriod");
      let listData = [];
      result.data.map((item) => {
        return listData.push({
          label: item.probationPeriod,
          value: item.probationPeriod,
        });
      });
      setProbationList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const location = useLocation();

  useEffect(() => {
    if (empId)
      if (location.pathname.toLowerCase().includes("employee/details"))
        getListData();
  }, [reqData, empId]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredText]);

  const getFormDetails = (id) => {
    navigate("/payroll/employee/details", {
      state: { empId },
    });
  };
  const applyFilters = () => {
    let tempSelectedBandListIds = [];
    selectedBandList.map((item) => {
      return tempSelectedBandListIds.push(item.value);
    });
    let tempSelectedProbationBandListIds = [];
    selectedProbationList.map((item) => {
      return tempSelectedProbationBandListIds.push(item.value);
    });
    setReqData({
      ...reqData,
      noticePeriod:
        tempSelectedBandListIds.length <= 0 ? null : tempSelectedBandListIds,
      probationPeriod:
        tempSelectedProbationBandListIds.length <= 0
          ? null
          : tempSelectedProbationBandListIds,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setSelectedBandList([]);
    setSelectedProbationList([]);
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      noticePeriod: null,
      createdOn: null,
      lastUpdatedOn: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest(
        `PreparePayroll/LoanList/${empId}`,
        downloadReqData
      );
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `LoanDetail_EmpId${empId}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  let detailUrl = "/payroll/employee/details/loan";
  let percentage = "50%";
  return (
    <div className="fullTableContainer">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              title="Close"
              onClick={() => setFilterSearchToogle((prev) => !prev)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p>Notice Period</p>
              <SelectCustom
                placeholder={"Select Notice Period"}
                onDropdownOpen={() => {
                  getBandListFilter();
                }}
                options={bandList}
                multi
                values={selectedBandList}
                onChange={(value) => {
                  setSelectedBandList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Probation Period</p>
              <SelectCustom
                placeholder={"Select Probation Period"}
                onDropdownOpen={() => {
                  getProbationListFilter();
                }}
                options={probationList}
                multi
                values={selectedProbationList}
                onChange={(value) => {
                  setSelectedProbationList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderContainer">
        <h5>Ongoing Loan(s)</h5>
        <div className="search-box" title="Search">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="search-btn" title="Search">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip
          arrow
          title={`${filterSearchToogle ? "Cancel" : "Filter"}`}
          placement="top"
        >
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      </div>
      <>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            <div className="boxViewContainer">
              <div className="homeCardsHolderContainer">
                <div className="homeDashboardCardHolder">
                  {tbodyData.length ? (
                    tbodyData?.map((item, index) => {
  
                      return (
                        <div
                          className="dashboardCard internalJobCard"
                          key={index}
                        >
                          <div
                            className="cardContainer cursor"
                            onClick={
                              detailUrl
                                ? () => {
                                    navigate(`${detailUrl}/${item.id}`);
                                  }
                                : () => {}
                            }
                          >
                            <div className="headingContainer">
                              <h3
                                title={item?.reason ?? ""}
                                style={{
                                  fontSize: "16px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.reason ? item?.reason : ""}
                                {/* {"House"} */}
                              </h3>
                              <span className="internalJobDateTime">
                                <p style={{ paddingBottom: "5px" }}>
                                  {`${item?.percentage} completed`}
                                </p>
                              </span>
                            </div>
                            <div className="jobDetailContainerHolder" style={{width:'100%'}}>
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  {"Loan Amount"}
                                </label>
                                <label
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "12px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.loanAmount}
                                </label>
                              </div>
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  {"Installment Amount"}
                                </label>
                                <label
                                  style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "12px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.installMentAmount}
                                </label>
                              </div>
                              <div className="jobDetailContainerHolder" style={{width:'100%'}}>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    {"Amount Repaid"}
                                  </label>
                                  <label
                                    style={{
                                      whiteSpace: "nowrap",
                                      fontSize: "12px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      color: "var(--notification-green)",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item?.amountRepaid}
                                  </label>
                                </div>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    {"Remaining Amount"}
                                  </label>
                                  <label
                                    style={{
                                      whiteSpace: "nowrap",
                                      fontSize: "12px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      color: "var(--notification-red)",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item?.remainingAmount}
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "10px",
                                  gap: "2px",
                                  padding: "12px",
                                  alignItems: "start",
                                }}
                              >
                                <svg
                                  style={{ marginTop: "1px" }}
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="6.13184"
                                    cy="5.64844"
                                    r="5.5"
                                    fill="#F7941D"
                                  />
                                  <path
                                    d="M6.62464 3.82154C7.13249 3.82154 7.54419 3.40984 7.54419 2.90198C7.54419 2.39412 7.13249 1.98242 6.62464 1.98242C6.11678 1.98242 5.70508 2.39412 5.70508 2.90198C5.70508 3.40984 6.11678 3.82154 6.62464 3.82154Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M7.56489 7.55321C7.53949 7.53612 7.51093 7.52428 7.48088 7.51838C7.45084 7.51248 7.41992 7.51263 7.38994 7.51884C7.35996 7.52505 7.33153 7.53719 7.3063 7.55454C7.28108 7.57189 7.25957 7.5941 7.24305 7.61988C7.02081 7.949 6.74856 8.24141 6.43613 8.48656C6.36027 8.54403 6.07751 8.76243 5.95796 8.71645C5.8752 8.69116 5.92348 8.52794 5.93957 8.45897L6.06141 8.09805C6.11199 7.95092 6.99247 5.33938 7.08902 5.04052C7.23155 4.60373 7.16948 4.17384 6.51889 4.27729C6.34188 4.29568 4.54644 4.52787 4.51426 4.53016C4.48407 4.53213 4.45456 4.54002 4.42742 4.55338C4.40028 4.56675 4.37604 4.58533 4.35608 4.60806C4.33612 4.6308 4.32083 4.65724 4.31109 4.68588C4.30135 4.71453 4.29735 4.74481 4.29931 4.775C4.30128 4.80519 4.30916 4.83469 4.32253 4.86183C4.3359 4.88897 4.35448 4.91322 4.37721 4.93318C4.39995 4.95314 4.42639 4.96842 4.45503 4.97816C4.48368 4.9879 4.51396 4.99191 4.54415 4.98994C4.54415 4.98994 5.23381 4.90029 5.30968 4.89339C5.34853 4.8896 5.38766 4.89713 5.42233 4.91508C5.45701 4.93302 5.48575 4.96062 5.50508 4.99454C5.54674 5.12331 5.54103 5.26272 5.48899 5.38765C5.42922 5.61754 4.48437 8.27966 4.45449 8.43368C4.42243 8.56258 4.43133 8.69825 4.47995 8.82185C4.52857 8.94545 4.61448 9.05084 4.72576 9.12335C4.93461 9.26256 5.18329 9.32957 5.43382 9.31416C5.6774 9.31118 5.9183 9.26285 6.14417 9.17163C6.7166 8.94174 7.31431 8.33023 7.62926 7.84977C7.65638 7.80095 7.6648 7.74393 7.65296 7.68936C7.64111 7.63478 7.60981 7.58639 7.56489 7.55321Z"
                                    fill="white"
                                  />
                                </svg>
                                <p>
                                  <strong>{item?.remainingInstallment}</strong>{" "}
                                  Instalment(s) Remaining | Next Instalment
                                  Date:{" "}
                                  <strong>
                                    {format(
                                      new Date(item.nextInstallment),
                                      "dd/MM/yyyy"
                                    )}
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="noDatastyle">
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Active Loans"}</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords}
                dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
};
export { Loan };
