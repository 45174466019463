import React, { useEffect, useState } from "react";
import GroupIcon from "../../Assets/GroupIcon.svg";

const ForwardViewAll = ({
  chatList,
  forwardData,
  LoggedInUser,
  showStatus,
}) => {

  const [newForwardArray, setForwardArr] = useState([]);
  useEffect(() => {
    console.log(" forward data", forwardData)
    let a = chatList.filter((item) => (item.participateMembers && forwardData.conversationId.includes(item._id)) || (!item.parparticipateMembers && forwardData.userId.includes(item._id)))
    setForwardArr([...a])
  }, [forwardData])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "600px",
        margin: " 10px 0px",
        overflow: "auto",
      }}
    >
      {console.log("--------------- list", newForwardArray)}
      {newForwardArray?.map((contact) => (
        <div>
          <div className="chatListLeftcontent" style={{ display: "flex" }}>
            <div className="activeImage">
              <div
                className={`activeImageContainer ${!contact.image
                  ? "customProfilePic"
                  : !contact.icon
                    ? "customProfilePic"
                    : ""
                  }`}
                style={
                  contact.image
                    ? {
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${contact.image ?? contact.icon
                        })`,
                    }
                    : contact.icon
                      ? {
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${contact.image ?? contact.icon
                          })`,
                      }
                      : {}
                }
              >
                {!contact.image && !contact.type === "Group"
                  ? contact?.name
                    ?.split(" ")
                    ?.slice(0, 2)
                    ?.map((item) => {
                      return item?.charAt(0)?.toUpperCase();
                    })
                    ?.join("") ??
                  contact.title
                    ?.split(" ")
                    ?.slice(0, 2)
                    ?.map((item) => {
                      return item?.charAt(0)?.toUpperCase();
                    })
                    ?.join("")
                  : !contact.icon
                    ? contact?.name
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.map((item) => {
                        return item?.charAt(0)?.toUpperCase();
                      })
                      ?.join("") ??
                    contact.title
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.map((item) => {
                        return item?.charAt(0)?.toUpperCase();
                      })
                      ?.join("")
                    : null}
              </div>

              {contact.type === "Contact" && (
                <div
                  className={showStatus(
                    contact?.participatingContacts?.filter(
                      (item) => item?.myIBID !== LoggedInUser?.id
                    )[0]?.onlineStatus
                  )}
                ></div>
              )}

              {contact.type === "Group" && (
                <div className="groupDot">
                  <img src={GroupIcon} alt="group" />
                </div>
              )}
            </div>
            <div
              className="allChatListDisplayContainer"
              style={{ justifyContent: "start", paddingTop: "6px" }}
            >
              <label className="chatListName">
                {" "}
                {contact?.name
                  ? contact?.name?.replace(/\s\(\d+\)$/, "")?.length > 18
                    ? contact?.name
                      ?.replace(/\s\(\d+\)$/, "")
                      ?.substring(0, 18) + "..."
                    : contact?.name?.replace(/\s\(\d+\)$/, "")
                  : contact?.title?.replace(/\s\(\d+\)$/, "")?.length > 18
                    ? contact?.title
                      ?.replace(/\s\(\d+\)$/, "")
                      ?.substring(0, 18) + "..."
                    : contact?.title?.replace(/\s\(\d+\)$/, "")}
              </label>
              <label
                className="chatListRole"
                style={{ textWrap: "nowrap" }}
              >
                {contact.type === "Contact"
                  ? contact?.designation
                    ? contact?.designation
                    : contact?.participatingContacts?.filter(
                      (item) => item?.myIBID !== LoggedInUser?.id
                    )[0]?.designation
                  : contact?.designation}
              </label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ForwardViewAll;
