import React, { useState } from "react";
import { useEffect } from "react";

const WhatsNewContent = ({ heading, sideText, sideColor, data }) => {
  const [idActive, setIdActive] = useState(1);
  if(sideText === "Enhancement"){
    sideColor="#5BC4BF";
  }
  else if(sideText === "Fetures"){
    sideColor="#FB7158";
  }
  useEffect(() => {
    document.title = `PeopleSol - Whats New`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="newContentMainHolder">
        <div className="verticalNewGreyLine"></div>
      <div className="rightTop" style={{ display: "flex", position: 'relative' }}>
      
        {/* <span style={{color:'lightgray'}}>•</span> */}
        <label className="lineBesideHeading"><svg width="29" height="7" viewBox="0 0 29 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.5619 3.48975C23.5619 4.88285 24.6912 6.01219 26.0843 6.01219C27.4774 6.01219 28.6068 4.88285 28.6068 3.48975C28.6068 2.09664 27.4774 0.967305 26.0843 0.967305C24.6912 0.967305 23.5619 2.09664 23.5619 3.48975ZM0.402344 3.9627H26.0843V3.01679H0.402344V3.9627Z" fill="#BFBFBF"/>
        </svg>
        </label>

        <h4>Features: {heading}</h4>

        <span className="sideEnhanceText" style={{backgroundColor: `${sideColor}`}}>{sideText}</span>
      </div>

      <div className="rightBottom">
        {/* <label>• {body}</label> */}
        <label>
          <ul>
            <li>{data}</li>
          </ul>
          {/* {idActive === 1 ? data?.slice(0,4).map((item) => {
            return (
              
              <ul>
                <li>{item}</li>
              </ul>
            );
          }) : data.map((item)=>{return <ul><li>{item}</li></ul>}) } */}
        </label>
        {idActive === 1 ? (
          <label className="cursor" style={{ color: "var(--primary)" }} onClick={() => {setIdActive(2)}}>View More</label>
        ) : (
          <label className="cursor" style={{ color: "var(--primary)" }} onClick={() => {setIdActive(1)}}>Less</label>
        )}
      </div>
      </div>
  );
};

export { WhatsNewContent };

