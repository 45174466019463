import { Form, Formik } from "formik";

import * as Yup from "yup";

import { useEffect } from "react";
import { Loader } from "../../../Components/FormComponent/Loader";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
export const FeedbackHouseKeeping = ({
  data,
  id,
  type,
  color,
  handleClose,
  loading,
  alistname,
}) => {
  const validationSchema = Yup.object({
    feedback: Yup.string()
      .max(499, "Feedback cannot be more than 500 characters")
      .required("Please Enter Feedback"),
  });
  //alistname is crucial for company Hospitality transaction dont remove
  const onSubmit = async (values) => {
    data(type, id, "Remark", values.feedback);
  };

  // useEffect(()=>{
  //   document.title = `PeopleSol - Remark`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // })
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{ feedback: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <LabelCustom labelName={"Feedback"} mandatory={true} />
              <TextAreaFormik
                maxLength={500}
                placeholder="Please Enter Feedback"
                style={{ height: "80px" }}
                name="feedback"
              />
              <div className="createFootCust">
                <button
                  type="button"
                  className="button secondaryButton"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="button primaryButton"
                  type="submit"
                  style={{ background: color, color: "white" }}
                >
                  Submit Feedback
                </button>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};
