import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { StepsHierarchy } from '../../../Components/FormComponent/StepsHierarchy';
import { WorkflowStepsComp } from '../../../Components/FormComponent/WorkflowStepsComp';
import { isLoading, showToast } from '../../../Features';
import { getRequest } from '../../../Services/axios';
import {DetailsStep} from '../../../Components/FormComponent/DetailsStep'
import { Loader } from '../../../Components/FormComponent/Loader';
import { format } from 'date-fns';

const DetailsAnnouncement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")))
  }
  const [loading, setLoading] = useState(true);
  const [announcementData, setAnnouncementData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Announcements/Details/${selectedId}`);
      setAnnouncementData(result.data)
      // console.log("announcementData",result?.data);
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }
  useEffect(() => {
    getEmployeeData();
    return () => {
    }
  }, [])
  useEffect(() => {
    document.title = `PeopleSol - Details Annoucements`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
 // //console.log("AnnouncementData",announcementData)
    return (
      <div className="moduleMasterDetailsPage">
        <div className="moduleMasterDetailsBackground" onClick={() => closeDetails()}></div>
        <div className="moduleMasterDetailsPageContainer">
          <div className="headingContainer">
            <h3>Details</h3>
            <IconButton onClick={() => closeDetails()} title="close">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.7579 0.56897L0.757935 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M0.757935 0.56897L12.7579 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </IconButton>
          </div>
          {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
                  {/* <div className="moduleMasterDetailsBox"> */}
                <DetailsStep detailTitle={"Name"} Details={announcementData?._announcements?.title}/>
                <DetailsStep detailTitle={"Description"} Details={announcementData?._announcements?.description}/>
                <DetailsStep detailTitle={"Location(s)"} Details={announcementData?._announcements?.location}/>
                <DetailsStep detailTitle={"Department(s)"} Details={announcementData?._announcements?.department}/>
                <DetailsStep
            detailTitle={"Created On"}
            Details={format(
              new Date(announcementData?._announcements?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
                <DetailsStep
            detailTitle={"Upload"}
            Details={format(
              new Date(announcementData?._announcements?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
          <DetailsStep
            detailTitle={"Last Updated On"}
            Details={format(
              new Date(announcementData?._announcements?.lastUpdatedOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
         {/* </div> */}
          </div>
        )}
        </div>
      </div>
    )
  
}

export { DetailsAnnouncement }