import React from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { useEffect } from "react";

const currencyOptions = [
  { label: "INR", value: "INR" },
  { label: "USD", value: "USD" },
  { label: "Other", value: "Other" },
];
const countryOptions = [
  { label: "India", value: "India" },
  { label: "Other", value: "Other" },
];

export const CreateCustomerComp = ({ data }) => {
  const opt = [{ label: "select", value: null }];
  useEffect(() => {
    document.title = `PeopleSol - Create Customer`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Customer Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="customerName"
          type={"text"}
          placeholder={"Enter Customer Name"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Billing Currency"} mandatory={true} />
        <SelectForm
          placeholder={" Select Billing Currency"}
          values={
            data
              ? currencyOptions.filter(
                  (options) => options.label === data?.Currency
                )
              : opt
          }
          name="BillingCurrency"
          options={currencyOptions}
          iid="currency"
        />
      </div>
      <div>
        <LabelCustom labelName={"Company Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="companyName"
          type={"text"}
          placeholder={"Enter Company Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"First Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="firstName"
          type={"text"}
          placeholder={"Enter First Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Last Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="lastName"
          type={"text"}
          placeholder={"Enter Last Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Email Id"} mandatory={true} />
        <InputCustomFormik
          name="emailID"
          type={"email"}
          placeholder={"Enter Email ID"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Mobile No."} mandatory={true} />
        <InputCustomFormik
        
        maxLength={10}
          name="mobileNo"
          step=".001"
          placeholder={"Enter Mobile No."}
        />
      </div>
      <div>
        <LabelCustom labelName={"Street"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="street"
          type={"text"}
          placeholder={"Enter Street"}
        />
      </div>
      <div>
        <LabelCustom labelName={"City"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="city"
          type={"text"}
          placeholder={"Enter City"}
        />
      </div>
      <div>
        <LabelCustom labelName={"State"} mandatory={true} />
        <InputCustomFormik
        maxLength={150}
          name="state"
          type={"text"}
          placeholder={"Enter State"}
        />
      </div>
      <div>
        <LabelCustom labelName={"ZIP/PIN"} mandatory={true} />
        <InputCustomFormik
        min={6}
        max={999999}
          name="zip"
          step=".001"
          placeholder={"Enter ZIP/PIN"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Country"} mandatory={true} />
        <SelectForm
          values={
            data
              ? countryOptions.filter(
                  (options) => options.label === data?.Country
                )
              : opt
          }
          name="country"
          options={countryOptions}
        />
      </div>
      <div>
        <LabelCustom labelName={"Company Type"} />
        <InputCustomFormik
        
        maxLength={150}
          name="companyType"
          type={"text"}
          placeholder={"Enter Company Type"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Company Size"} />
        <InputCustomFormik
          name="companySize"
          step=".001"
          placeholder={"Enter Company Size"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Tax Id"} />
        <InputCustomFormik
          name="taxID"
          type={"text"}
          placeholder={"Enter Tax ID"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Tag Name"} />
        <InputCustomFormik
        
        maxLength={150}
          name="tagName"
          type={"text"}
          placeholder={"Enter Tag Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} />
        <TextAreaFormik name="description" placeholder={"Enter Description"}
        
        maxLength={500}
        />
      </div>
    </div>
  );
};
