import React, { useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { getRequest, postRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const opt = [{ label: "select", value: null }];

export const CreateVendorComp = ({ formValues, data, editID }) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [name, setName] = useState(false);
  const dispatch = useDispatch();
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(editID ? 0 : 1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };

  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    // if (editID) {
    //   setPage(0);
    // } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (editID) {
      // setPage(0);
      let idArray;
      if (data?._request?.guestOf) {
        idArray = [data?._request?.guestOf];
      } else {
        idArray = [];
      }
      getOption(idArray);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);
  useEffect(() => {
    document.title = `PeopleSol - Create Company Car`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const personCountOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ];
  const typeOptions = [
    { label: "Self", value: "Self" },
    // { label: "Vendor", value: "Vendor" },
    { label: "Guest", value: "Guest" },
  ];
  const purposeOptions = [
    { label: "Official", value: "Official" },
    { label: "Personal", value: "Personal" },
  ];

  const guestOptions = [
    { label: "Casual", value: "Casual" },
    { label: "Special", value: "Special" },
  ];
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div className="formcustomthreecar">
      <div>
        <LabelCustom labelName={"Type"} mandatory={true} />
        <SelectForm
          // values={
          //   data
          //     ? personCountOptions.filter(
          //       (options) => options.label === data?.numberOfPeople
          //     )
          //     : opt
          // }
          name="type"
          options={typeOptions}
        />
      </div>
      <div>
        <LabelCustom labelName={"Full Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Please enter Name"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Designation"} />
        <InputCustomFormik
          maxLength={150}
          name="designation"
          type={"text"}
          placeholder={"Please enter Designation"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Phone Number"} mandatory={true} />
        <InputCustomFormik
          type="text"
          maxLength={10}
          name="phoneNumber"
          step=".001"
          placeholder={"Please enter Phone Number"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Email"} />
        <InputCustomFormik
          name="email"
          type={"text"}
          maxLength={150}
          placeholder={"Please enter Email"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Date"} mandatory={true} />
        <DatePickerFormiks name={"date"} minDate={new Date()} />
      </div>

      <div>
        <LabelCustom labelName={"Pickup Time"} mandatory={true} />
        <TimeCustomFormik name="time" placeholder="Select Time" />
      </div>

      <div>
        <LabelCustom labelName={"Pick Up Location"} mandatory={true} />
        <InputCustomFormik
          name="pickupLocation"
          type={"text"}
          maxLength={150}
          placeholder={"Please enter Pick Up From"}
        />
      </div>
      <div>
        <LabelCustom labelName={"No. Of People"} mandatory={true} />
        <SelectForm
          // values={
          //   data
          //     ? personCountOptions.filter(
          //       (options) => options.label === data?.numberOfPeople
          //     )
          //     : opt
          // }
          name="numberOfPeople"
          options={personCountOptions}
        />
      </div>

      <div>
        <LabelCustom labelName={"Drop Time"} mandatory={true} />
        <TimeCustomFormik name="dropTime" placeholder="Select Drop Time" />
      </div>
      <div>
        <LabelCustom labelName={"Drop Location"} mandatory={true} />
        <InputCustomFormik
          name="dropLocation"
          maxLength={150}
          type={"text"}
          placeholder={"Please Enter Drop Location"}
        />
      </div>

      {formValues?.type === "Guest" && (
        <div>
          <LabelCustom labelName={"Guest Type"} mandatory={true} />
          <SelectForm
            // values={
            //   data
            //     ? guestOptions.filter(
            //       (options) => options.label === data?.guestType
            //     )
            //     : opt
            // }
            name="guestType"
            options={guestOptions}
          />
        </div>
      )}
      {formValues?.type === "Guest" && (
        <div>
          <LabelCustom labelName={"Guest Of Whom"} mandatory={true} />
          <SelectForm
            // values={
            //   data
            //     ? guestOptions.filter(
            //       (options) => options.label === data?.guestOfWhom
            //     )
            //     : opt
            // }
            name="guestOf"
            onMenuScrollToBottom={handleScrollToBottom}
            options={searchParamemployee ? option : listData}
            onInputChange={(e) => setSearchParamemployee(e)}
          />
        </div>
      )}
      {(formValues?.type === "Guest" || formValues?.type === "Vendor") && (
        <div>
          <LabelCustom labelName={"Company"} mandatory={true} />
          <InputCustomFormik
            name="company"
            type={"text"}
            maxLength={150}
            placeholder={"Please Enter Company Name"}
          />
        </div>
      )}

      <div>
        <LabelCustom labelName={"Purpose"} mandatory={true} />
        <SelectForm
          // values={
          //   data
          //     ? purposeOptions.filter(
          //       (options) => options.label === data?.purpose
          //     )
          //     : opt
          // }
          name="purpose"
          options={purposeOptions}
        />
      </div>
      <div>
        <LabelCustom labelName={"Requires Driver"} mandatory={true} />
        <YesNoFormik name="isDriverRequired" />
      </div>

      <div>
        <LabelCustom labelName={"Remarks"} mandatory={false} />
        <TextAreaFormik
          name="remarks"
          maxLength={500}
          placeholder={"Please Enter Remarks"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Description"} mandatory={false} />
        <TextAreaFormik
          name="description"
          maxLength={500}
          placeholder={"Please Enter Description"}
        />
      </div>

    </div>
  );
};
