import { IconButton } from "@mui/material";
import React from "react";

const ForwardInputChat = ({
  forwardData,
  setForwardData,
  isOpen,
  togglePopup,
  deleteMessage,
  LoggedInUser,
}) => {
  console.log("object forwardData", forwardData);
  return (
    <div className="forwardInputChat">
      <div className="forwardInputChatDiv1">
        <IconButton
          style={{ marginRight: "8px" }}
          onClick={() => {
            setForwardData({
              isActive: false,
              msgId: [],
              userId: [],
              conversationId: [],
            });
          }}
        >
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="16.2524"
              y1="6.18416"
              x2="6.35295"
              y2="16.0837"
              stroke="#F7941D"
              stroke-linecap="round"
            />
            <line
              x1="16.725"
              y1="16.0837"
              x2="6.82553"
              y2="6.18424"
              stroke="#F7941D"
              stroke-linecap="round"
            />
          </svg>
        </IconButton>
        <div>{forwardData?.msgId?.length} Selected</div>
      </div>
      <div className="forwardInputChatDiv2">
        {forwardData?.msgId?.length > 0 && (
          <IconButton
            onClick={() => {
              deleteMessage(
                forwardData.msgId,
                LoggedInUser?.personNumber,
                "multiple"
              );
            }}
          >
            <svg
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.2915 5.55371L3.49786 14.8232C3.57579 15.422 4.08591 15.8701 4.68978 15.8701H10.712C11.3159 15.8701 11.826 15.422 11.904 14.8232L13.1103 5.55371"
                stroke="#F7941D"
                stroke-linecap="round"
              />
              <line
                x1="1.40381"
                y1="3.55933"
                x2="13.9993"
                y2="3.55933"
                stroke="#F7941D"
                stroke-linecap="round"
              />
              <path
                d="M5.38623 3.58507V2.07209C5.38623 1.40826 5.92437 0.870117 6.5882 0.870117H8.81582C9.47965 0.870117 10.0178 1.40826 10.0178 2.07209V3.58507"
                stroke="#F7941D"
              />
            </svg>
          </IconButton>
        )}
        {forwardData?.msgId?.length > 0 && (
          <IconButton onClick={togglePopup}>
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8531 4.22367C16.0483 4.02841 16.0483 3.71183 15.8531 3.51656L12.6711 0.334583C12.4758 0.139321 12.1592 0.139321 11.964 0.334583C11.7687 0.529845 11.7687 0.846428 11.964 1.04169L14.7924 3.87012L11.964 6.69854C11.7687 6.89381 11.7687 7.21039 11.964 7.40565C12.1592 7.60091 12.4758 7.60091 12.6711 7.40565L15.8531 4.22367ZM-0.000488281 11.6928C-0.000488281 11.9689 0.223369 12.1928 0.499512 12.1928C0.775654 12.1928 0.999512 11.9689 0.999512 11.6928H-0.000488281ZM15.4995 3.37012H8.17711V4.37012H15.4995V3.37012ZM8.17711 3.37012C5.16074 3.37012 3.0811 4.253 1.76888 5.80008C0.470176 7.33123 -0.000488281 9.42509 -0.000488281 11.6928H0.999512C0.999512 9.53928 1.45015 7.72181 2.5315 6.44693C3.59934 5.18797 5.35849 4.37012 8.17711 4.37012V3.37012Z"
                fill="#F7941D"
              />
            </svg>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ForwardInputChat;
