/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { useEffect } from "react";

const opt = [{ label: "select", value: null }];

export const CreateDesignationComp = ({ data }) => {
  const dispatch = useDispatch();
  const [option, setOption] = React.useState([]);

  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/hrmsmaster/orgstructure/designation");
  };

  const getBandListFilter = async () => {
    try {
      const result = await getRequest("Bands/GetBands");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
      returnPage();
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Designation `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  React.useEffect(() => {
    getBandListFilter();
  }, []);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Designation"} mandatory={true} />
        <InputCustomFormik
          minLength={1}
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Designation"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Band"} mandatory={true} />
        <SelectForm
          placeholder={"Select Band"}
          name="bandId"
          options={option}
          values={
            data
              ? option.filter((options) => options.label === data?.Band)
              : opt
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"Rank"} mandatory={true} />
        <InputCustomFormik
          min={1}
          max={9999999}
          name="position"
          step=".001"
          placeholder={"Enter Rank"}
        />
      </div>
      <div>
        <LabelCustom labelName={"TAT (In Hrs)"} mandatory={true} />
        <InputCustomFormik
          min={1}
          max={999}
          name="tat"
          step=".001"
          placeholder={"Enter TAT"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Reminder 1 (In Hrs)"} mandatory={true} />
        <InputCustomFormik
          min={1}
          max={999}
          name="r1time"
          step=".001"
          placeholder={"Enter Reminder 1"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Reminder 2 (In Hrs)"} mandatory={true} />
        <InputCustomFormik
          min={1}
          max={999}
          name="r2time"
          step=".001"
          placeholder={"Enter Reminder 2"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Reminder 3 (In Hrs)"} mandatory={true} />
        <InputCustomFormik
          min={1}
          max={999}
          name="r3time"
          step=".001"
          placeholder={"Enter Reminder 3"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Escalation 1 (In Hrs)"} mandatory={true} />
        <InputCustomFormik
          min={1}
          max={999}
          name="e1time"
          step=".001"
          placeholder={"Enter Escalation 1"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Escalation 2 (In Hrs)"} mandatory={true} />
        <InputCustomFormik
          min={1}
          max={999}
          name="e2time"
          step=".001"
          placeholder={"Enter Escalation 2"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Escalation 3 (In Hrs)"} mandatory={true} />
        <InputCustomFormik
          min={1}
          max={999}
          name="e3time"
          step=".001"
          placeholder={"Enter Escalation 3"}
        />
      </div>
    </div>
  );
};
