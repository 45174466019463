/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { BoxView, Table } from "../../../../Components/index";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import {
  postRequest,
  deleteWithPayloadRequest,
} from "../../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Loader } from "../../../../Components/FormComponent/Loader";
import DeletePopup from "../../../../Components/DeletePopup/DeletePopup";

const CompletedPreparePayroll = ({searchParam}) => {
  useEffect(() => {
    document.title = `PeopleSol - Complete Prepare Payroll`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reqData, setReqData] = useState({
    status: "Completed",
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });

  const handleDelete = async (selectedRows) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await deleteWithPayloadRequest(
        `SalaryComponents/DeleteMultiple`,
        selectedRows
      );
      if (result) dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      getListData();
    }
  };

  const deleteSelected = () => {
    if (selectedRows.length === 1) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <DeletePopup
              onClose={onClose}
              message={"Are you sure you want to delete this earnings?"}
              onClick={() => handleDelete(selectedRows)}
            />
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <DeletePopup
              onClose={onClose}
              message={`Are you sure you want to delete these ${selectedRows.length} bands?`}
              onClick={() => handleDelete(selectedRows)}
            />
          );
        },
      });
    }
  };
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("PreparePayroll/List", reqData);
      if (result) {
        setTbodyData(result.data.data);
      } else {
        setTbodyData(false);
      }
      setCount(result.data.summary.count);
      calculateTotalPages(result.data.summary.count);
      setSelectedRows([]);
    } catch (error) {
      //dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("completed")) getListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredText]);

  const getFormDetails = (id) => {
    const selectedRecord = tbodyData?.find(
      (item) => (item?.Id ?? item.id) === id
    );
    //console.log("selectedRecord", selectedRecord);
    navigate("/payrollmaster/payrollcomponents/deductions/details", {
      state: { selectedRecord },
    });
  };

  const handleEdit = (id) => {
    navigate("/payrollmaster/payrollcomponents/deductions/create", {
      state: { id },
    });
  };
  const actionData = [
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8437V4.84375Z"
            fill="#0DB53C"
          />
          <line
            x1="14.7295"
            y1="7.43701"
            x2="7.72949"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="7.43701"
            x2="5.61462"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="11.3437"
            x2="7.72949"
            y2="11.3437"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="11.3437"
            x2="5.61462"
            y2="11.3438"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="15.2505"
            x2="7.72949"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="15.2505"
            x2="5.61462"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Earning Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
            fill="#FB7158"
          />
          <path
            d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
            stroke="#FB7158"
            strokeWidth="0.698594"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      action: "Edit Earning",
      clickAction: (id) => handleEdit(id),
    },
  ];

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    setReqData({
      ...reqData,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      noticePeriod: null,
      createdOn: null,
      lastUpdatedOn: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (count > recordsSelected?.value) {
      if (selectedRows?.length === recordsSelected?.value) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
          return arr.push(item.Id);
        });
        setSelectedRows(arr);
      }
    } else {
      if (selectedRows?.length === count) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
          return arr.push(item.Id);
        });
        setSelectedRows(arr);
      }
    }
  };
  return (
    <div className="fullTableContainer">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              title="Close"
              onClick={() => setFilterSearchToogle((prev) => !prev)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
   
      <>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            <BoxView
              tbodyData={tbodyData}
              actionData={[]}
              headingTile="payArea"
              subTiles={["payDate", "amount", "employeesCount", "monthPayout"]}
              date="LastUpdatedOn"
            />

            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords}
                dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
};
export { CompletedPreparePayroll };
