import React from "react";
import { useEffect } from "react";

const HalfDayCardComp = ({ time, title, date, source }) => {
  return (
    <div className="attendanceCardContentContainer">
      <div
        className="cardDateDisplay"
        style={{ justifyContent: "space-between" }}
      >
        {date}
        <label
          style={{
            fontSize: "8px",
            color: "var(--secondary)",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </label>{" "}
      </div>

      <div
        className="attendanceCardPresentCell"
        style={{
          background: "#D8F0F2",
          borderLeft: "2px solid var(--primary)",
        }}
      >
        <label
          className="attendanceIn"
          style={{ fontSize: "8px", paddingLeft: "2px" }}
        >
          {time ? time[0] : ""}
        </label>
      </div>

      <div
        className="attendanceCardPresentCell"
        style={{
          background: "#FDEAD2",
          borderLeft: "2px solid var(--secondary)",
        }}
      >
        <label
          className="attendanceOut"
          style={{ fontSize: "8px", paddingLeft: "2px" }}
        >
          {time ? time[1] : ""}
        </label>
      </div>
    </div>
  );
};

export { HalfDayCardComp };
