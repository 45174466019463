import { openDB } from 'idb';

// Open the IndexedDB database
const dbPromise = openDB('chat-app-db', 1, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('messages')) {
            const store = db.createObjectStore('messages', { keyPath: '_id' });
            store.createIndex('conversationId', 'conversationId', { unique: false });
            store.createIndex('caption', 'caption', { unique: false });
        }

        if (!db.objectStoreNames.contains('conversations')) {
            const conversationStore = db.createObjectStore('conversations', { keyPath: '_id' });
            conversationStore.createIndex('title', 'title', { unique: false });
            conversationStore.createIndex('participateMembers.contactName', 'participateMembers.contactName', { unique: false, multiEntry: true });
        }

        if (!db.objectStoreNames.contains('pendingMessages')) {
            const store = db.createObjectStore('pendingMessages', { keyPath: '_id' });
            store.createIndex('conversationId', 'conversationId', { unique: false });
            store.createIndex('caption', 'caption', { unique: false });
        }
    },
});


// Store pending messages in IndexedDB when offline
export const addPendingMessageToCache = async (message) => {
    const db = await dbPromise;
    const tx = db.transaction('pendingMessages', 'readwrite');
    const store = tx.objectStore('pendingMessages');
    await store.add(message);
    await tx.done;
};

// Fetch all pending messages
export const getPendingMessagesFromCache = async () => {
    const db = await dbPromise;
    const tx = db.transaction('pendingMessages', 'readonly');
    const store = tx.objectStore('pendingMessages');
    const pendingMessages = await store.getAll();
    await tx.done;
    return pendingMessages;
};

export const clearAllPendingMessages = async () => {
    const db = await dbPromise;
    const tx = db.transaction('pendingMessages', 'readwrite');
    const store = tx.objectStore('pendingMessages');
    await store.clear(); // This will remove all pending messages at once
    await tx.done;
};

// Add an array of messages to IndexedDB
export const addMessagesToCache = async (messages) => {
    const db = await dbPromise;
    const tx = db.transaction('messages', 'readwrite');
    const store = tx.objectStore('messages');

    messages.forEach((message) => {
        store.put(message);
    });

    await tx.done;
};

const clearConversations = async () => {
    const db = await dbPromise;
    const tx = db.transaction('conversations', 'readwrite');
    const store = tx.objectStore('conversations');
    await store.clear(); // Clear all conversation records
    await tx.done;
};
export const addConversationsToCache = async (conversations) => {
    await clearConversations();

    const db = await dbPromise;
    const tx = db.transaction('conversations', 'readwrite');
    const store = tx.objectStore('conversations');

    conversations.forEach((conversation) => {
        store.put(conversation);
    });

    await tx.done;
};


// Get all messages by conversationId
export const getMessagesByConversation = async (conversationId) => {
    const db = await dbPromise;
    const tx = db.transaction('messages', 'readonly');
    const store = tx.objectStore('messages');
    const index = store.index('conversationId');

    const messages = await index.getAll(conversationId);
    return messages;
};

// Search messages by caption substring
export const searchMessagesByCaption = async (query) => {
    const db = await dbPromise;
    const tx = db.transaction('messages', 'readonly');
    const store = tx.objectStore('messages');
    const allMessages = await store.getAll();
    // Filter messages where caption contains the search query (case-insensitive)
    const filteredMessages = allMessages.filter(message =>
        message.caption.toLowerCase().includes(query.toLowerCase())
    );

    return filteredMessages;
};

export const searchMessagesByConversationAndCaption = async (conversationId, captionQuery) => {
    const db = await dbPromise;
    const tx = db.transaction('messages', 'readonly');
    const store = tx.objectStore('messages');
    const index = store.index('conversationId');

    console.log("00------ yahan  aaya", captionQuery)

    // Get all messages that belong to  the conversationId
    const conversationMessages = await index.getAll(conversationId);

    // Filter messages where the caption contains the provided substring
    const filteredMessages = conversationMessages.filter(message =>
        message.caption.toLowerCase().includes(captionQuery.toLowerCase())
    );
    console.log("00------ yahan tak aaya", filteredMessages)

    // await tx.done;
    return filteredMessages;
};

export const searchConversations = async (query) => {
    const db = await dbPromise;
    const tx = db.transaction('conversations', 'readonly');
    const store = tx.objectStore('conversations');
    const allConversations = await store.getAll();

    // Filter conversations by title or any contactName in participateMembers
    const filteredConversations = allConversations.filter(conversation =>
        conversation.title.toLowerCase().includes(query.toLowerCase()) ||
        conversation.participateMembers.some(member =>
            member.contactName.toLowerCase().includes(query.toLowerCase())
        )
    );

    return filteredConversations;
};
