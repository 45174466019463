/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import { ScheduleMessageComp } from "./ScheduleMessageComp";

const ScheduleMessage = ({ setSchedule, sendMessageText, rescheduleMessageValue, reSchedule }) => {
    useEffect(() => {
        document.title = `PeopleSol - Create Early Going`;
        return () => {
            document.title = 'PeopleSol';
        };
    }, []);


    const initialvalue = {
        date: "",
        time: "",
    };

    const validationSchema = Yup.object({
        date: Yup.string().required("Please enter Date"),
        time: Yup.string().required("Please enter Time"),
    });

    const onSubmit = (values) => {
        // Combine date and time into a single string in ISO format (without timezone)
        const combinedDateTimeString = `${dateFormat(values?.date, "yyyy-mm-dd")} ${dateFormat(values?.time, "h:MM TT")}`;

        // Create a new Date object from the combined string
        const combinedDateTime = new Date(combinedDateTimeString);

        // Convert the combined date-time to ISO 8601 format (UTC) and append 'Z'
        const isoString = combinedDateTime.toISOString();

        if (!rescheduleMessageValue) {
            sendMessageText(isoString)
        } else {
            reSchedule(isoString, rescheduleMessageValue.id)
        }
        setSchedule(false);
    };


    return (
        <FormCustomFormik
            FormHeading={!rescheduleMessageValue ? "Schedule Message" : "Reschedule Message"}
            ButtonLabel={!rescheduleMessageValue ? "Schedule Message" : "Reschedule Message"}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialvalue={
                rescheduleMessageValue
                    ? {
                        ...initialvalue,

                        date: rescheduleMessageValue
                            ? new Date(rescheduleMessageValue.time)
                            : "",
                        time: rescheduleMessageValue
                            ? new Date(rescheduleMessageValue.time)
                            : "",
                    }
                    : initialvalue
            }
            returnPage={() => { setSchedule(false) }}
            propsDiv={<ScheduleMessageComp />}
        />
    );
};

export { ScheduleMessage };
