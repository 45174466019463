import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateCategoryComp } from "./FormComp/CreateCategoryComp";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { getRequest, postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";


const CreateCategory = (props) => {

  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false);

    const createPageData = async (values) => {
      try {
          
          dispatch(isLoading(true));
          setLoading(true);
          // eslint-disable-next-line no-unused-vars
          const result = await postRequest('FacilityCategories/Create', values);
          dispatch(showToast({ text: "Successfully Created", severity: "success" }));
          returnPage()
      } catch (error) {

          dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      } finally {
          dispatch(isLoading(false));
          setLoading(false);
      }
  }
    
  const getDetails = async () => {
    try {
      const result = await getRequest(`FacilityCategories/Details/${data}`);
      result.data.map((item)=>{
        
        setDetail(item);
      })
      
    } catch (error) {        dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) 
    } finally {
    }
  };
  React.useEffect(() => {
    if(data){
      getDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "FacilityCategories/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage()
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  useEffect(() => {
    document.title = `PeopleSol - Create Category`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const initialvalue = {

    

    name: data?detail.name:"",
    code: data?detail.code:"",
    _facilityTypeSpecificationIds: data?detail.specificationsItemId:[], 
    // _facilityTypeIds: [], 
    
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(49, "Name must be less than 50 characters").required("Please Enter Facility Category"), 
    code: Yup.string().max(5," Code must be less than 6 characters").required("Please Enter Facility Code"), 
    _facilityTypeSpecificationIds:  Yup
    .array()
    .min(1, `Please Select Category Specification `).required(' required'),
    
  });



  const onSubmit = (values) => {
 
 const edit = {
  ...values,
  id:  data,
    // _facilityTypeSpecificationIds: detail?.FacilityCategorySpecifications,
    // _facilityTypeIds: detail?.FacilityMaterials,
};

const create={
 ...values,
 id:0,

}
data ? Edit(edit) : createPageData(create);

 }
 

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  return (
   <FormCustomFormik loading={loading} FormHeading={data?" Edit  Facility Category":'Create  Facility Category'} ButtonLabel={data?'Update Facility Category':'Create Facility Category'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={

     
      initialvalue
  } returnPage={returnPage} propsDiv={<CreateCategoryComp data={detail} />} />
  );
};

export { CreateCategory };