
import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { getRequest } from "../../../../Services/axios";
const opt=[
    {label:"select",value:null}
  ]
const type=[
    {label:"IT Infra",value:"IT Infra"},
    {label:"SAP",value:"SAP"},
    {label:"IT Application ",value:"IT Application "},
    {label:"My Assets ",value:"My Assets "},
  ]
 const StatementForm = ({data}) => {
  const [option, setOption] = React.useState([]);
  const getOptionReq = async () => {
    try {
      const result = await getRequest(
        "ExceptionStatements/GetExceptionGroup"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Statement`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getOptionReq()
  }, []);
  return (
    <div >
      <div>
        <LabelCustom labelName={"Exception Group   "} mandatory={true} />
        <SelectForm  
            name={"exceptionGroupId"} options={option} placeholder={" Select Exception Group  "} />
      </div>
      <div>
        <LabelCustom
       
         labelName={"Statement "} mandatory={true} />
         <TextAreaFormik style={{ height: "80px" }}
         
         maxLength={300}
         placeholder={" Enter Statement  "}
         name="name"/>
         
      </div>
    </div>
  );
};
export {StatementForm}