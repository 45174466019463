import React, {  useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { getRequest, postRequest } from "../../../Services/axios";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { ReqTypeForm } from "./Form/ReqTypeForm";
import { requestTypeSchema } from "./RequestTypeSchemas";
import { useEffect } from "react";


const CreateRequestType = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [detail, setDetail] = React.useState([]);
  useEffect(() => {
    document.title = `PeopleSol - Create Request Type`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getDetails = async () => {

    try {
    
    const result = await getRequest(`HRHelpdeskRequestTypes/Details/${data}`);
    
    setDetail(result.data);
    
     } catch (error) { dispatch(
    
    showToast({ text: error.response.message, severity: "error" })
    
    )
    
    } finally {
    
    }
    
     };
    
   React.useEffect(() => {
    
    if(data){
    
    getDetails();
    
    }
    
   }, [data]);
  const postFormData = async (values) => {
    try {
      const result = await postRequest(
        "HRHelpdeskRequestTypes/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
      returnPage();
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  const initialvalue = {
    name: data?detail.name:"",
    requestCategoryId: data?detail.requestCategoryId:"",
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "HRHelpdeskRequestTypes/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  
  const onSubmit = async (values) => {
    
    const edit = {
      ...values,
      Id: data,
      
      
     
    };
    
    const create = {
      ...values,
    };

    data?Edit(edit) :
     postFormData(create);
    
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/hrdesk/requesttype");
  };

  return (
    <FormCustomFormik
      FormHeading={data?'Update Request Type':'Create Request Type'}
      validationSchema={requestTypeSchema}
      ButtonLabel={data?'Update Request Type':'Create Request Type'}
      onSubmit={onSubmit}
      initialvalue={
       
         initialvalue}
      returnPage={returnPage}
      propsDiv={<ReqTypeForm data={data}/>}
    />
  );
};

export { CreateRequestType}