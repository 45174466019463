import React from "react";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";
import {
  communityProfileImages,
  profileImages,
} from "../../../../../Utilities/profileAndUploadImages";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import dateFormat from "dateformat";

function SocialPostNestedHeaderContainer({ commentedPost, navigate }) {
  return (
    <div
      className={` social-details-header ${
        commentedPost?.postType !== "organisation"
          ? "team-community-details-Header"
          : ""
      }`}
    >
      {commentedPost?.postType === "organisation" && (
        <div
          className="leftPostHeaderContainer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={`${
              commentedPost?.parantPostEmployee?.image
                ? "socialProfilePicContainer"
                : "socialCustomProfilePic"
            }`}
          >
            {!commentedPost?.parantPostEmployee?.image ? (
              getUserInitials(commentedPost?.parantPostEmployee?.name)
            ) : (
              <img
                className="socialProfilePic"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                src={
                  process.env.REACT_APP_.BASE_URL +
                  profileImages +
                  commentedPost?.parantPostEmployee?.image
                }
                alt="Profile"
                onError={(e) => {
                  e.target.src = ProfileAvatar;
                }}
              />
            )}
          </div>

          <div className="postMainTextContainer">
            <label className="postMainName">
              {getDisplayName(
                commentedPost?.parantPostEmployee?.nameWithPersonNumber
              )}
            </label>
            <label>{commentedPost?.parantPostEmployee?.roleName}</label>
            <label className="dateAndViewsHeaderContainer">
              {commentedPost?.parantPostEmployee?.locationName}
              <span className="postHrTime">
                &nbsp; •{" "}
                {dateFormat(commentedPost?.createdOn, "hh:MM TT | dd mmm yyyy")}
              </span>
            </label>
          </div>
        </div>
      )}
      {commentedPost?.postType === "community" && (
        <div
          className="leftPostHeaderContainer"
          style={{ position: "relative" }}
        >
          <div
            onClick={(e) => {
              navigate(
                `/home/social/community/${commentedPost?.community?.id}`
              );
            }}
            className={`${
              commentedPost?.community?.communityImage
                ? "socialConnectProfilePicContainer"
                : "nestedCommunityCustomProfilePic"
            }`}
          >
            {!commentedPost?.community?.communityImage &&
            commentedPost?.community?.communityName ? (
              getUserInitials(commentedPost?.community?.communityName)
            ) : (
              <img
                className="socialProfilePic"
                src={
                  process.env.REACT_APP_.BASE_URL +
                  communityProfileImages +
                  commentedPost?.community?.communityImage
                }
                style={{ marginBottom: "8px" }}
                alt="Profile"
                onError={(e) => {
                  e.target.src = SocialCommunityGroupIcon;
                }}
              />
            )}
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={`${
              commentedPost?.parantPostEmployee?.image
                ? "socialProfilePicContainer"
                : "socialCustomProfilePicCommunity"
            }`}
          >
            {!commentedPost?.parantPostEmployee?.image ? (
              getUserInitials(commentedPost?.parantPostEmployee?.name)
            ) : (
              <img
                className={`socialProfilePic ${
                  commentedPost?.postType === "community" || "team"
                    ? "prTeamCommunity"
                    : ""
                }`}
                src={
                  process.env.REACT_APP_.BASE_URL +
                  profileImages +
                  commentedPost?.parantPostEmployee?.image
                }
                alt="Profile"
                onError={(e) => {
                  e.target.src = ProfileAvatar;
                }}
              />
            )}
          </div>

          <div
            className="postMainTextContainer"
            style={{ marginLeft: "15px" }}
            onClick={() => {
              navigate(
                `/home/social/community/${commentedPost?.community?.id}`
              );
            }}
          >
            <label className="postMainName">
              {commentedPost?.community?.communityName}
            </label>
            <label className="dateAndViewsHeaderContainer">
              Post from{" "}
              <span className="nameofposter">
                &nbsp;
                {getDisplayName(
                  commentedPost?.parantPostEmployee?.nameWithPersonNumber
                )}
                &nbsp;
              </span>
              <br />
              <span className="postHrTime">
                •{" "}
                {dateFormat(commentedPost?.createdOn, "hh:MM TT | dd mmm yyyy")}
              </span>
            </label>
          </div>
        </div>
      )}
      {commentedPost?.postType === "team" && (
        <div
          className="leftPostHeaderContainer"
          style={{ position: "relative" }}
        >
          <img
            src={SocialPostTeamIcon}
            alt=""
            style={{
              height: "50px",
              width: "50px",
              marginBottom: "8px",
            }}
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={`${
              commentedPost?.parantPostEmployee?.image
                ? "socialProfilePicContainer"
                : "socialCustomProfilePicCommunity"
            }`}
          >
            {!commentedPost?.parantPostEmployee?.image ? (
              getUserInitials(commentedPost?.parantPostEmployee?.name)
            ) : (
              <img
                className={`socialProfilePic ${
                  commentedPost?.postType === "community" || "team"
                    ? "prTeamCommunity"
                    : ""
                }`}
                src={
                  process.env.REACT_APP_.BASE_URL +
                  profileImages +
                  commentedPost?.parantPostEmployee?.image
                }
                alt="Profile"
                onError={(e) => {
                  e.target.src = ProfileAvatar;
                }}
              />
            )}
          </div>

          <div className="postMainTextContainer" style={{ marginLeft: "15px" }}>
            <label className="postMainName">Team</label>
            <label className="dateAndViewsHeaderContainer">
              Post from &nbsp;
              <span className="nameofposter">
                {getDisplayName(
                  commentedPost?.parantPostEmployee?.nameWithPersonNumber
                )}
              </span>
              <span className="postHrTime">
                &nbsp; •{" "}
                {dateFormat(commentedPost?.createdOn, "hh:MM TT | dd mmm yyyy")}
              </span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default SocialPostNestedHeaderContainer;
