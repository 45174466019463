// approvalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const approvalSlice = createSlice({
  name: 'approval',
  initialState: 0, // Initial approval count
  reducers: {
    setApprovalCount: (state, action) => {
      return action.payload; // Set the approval count to the payload value
    },  
  },
});

export const { setApprovalCount } = approvalSlice.actions;
export default approvalSlice.reducer;
