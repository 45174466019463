import React, { useRef } from "react";
import { useState } from "react";
import "./NewGroup.css";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import GroupProfileIcon from "../../../../Assets/GroupProfileIcon.svg";
import ChatSelectedCheck from "../../../../Assets/ChatSelectedCheck.svg";
import ChatUnselectedCheck from "../../../../Assets/ChatUnselectedCheck.svg";
import UploadImage from "../../../../Assets/UploadGroupImage.svg"
import ProfileBack from "../../../../Assets/ProfileBackground.svg"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../../../Components/FormComponent/Loader";


function CreateNewGroup(props) {
    const contactsList = useSelector((state) => state.chat.contactList);
    const userDetails = useSelector((state) => state.chat.userDetails);
    const [loading, setLoading] = useState(false);

    const [chatList, setChatList] = useState(contactsList);
    const [newGroupList, setNewGroupList] = useState(true);
    const [activeGroupCreation, setActiveGroupCreation] = useState(false);
    const [newGroupProfilePic, setNewGroupProfilePic] = useState(
        "https://picsum.photos/40/40"
    );
    const [newGroupProfileName, setNewGroupProfileName] = useState("");
    const [profileNameInputActive, setProfileNameInputActive] = useState(false);
    const [totalMember, setTotalMember] = useState(props.selectedGroupMember.length);
    const [profileImg, setProfileImg] = useState(null);

    useEffect(() => {
        document.title = `PeopleSol - New Group`;
        return () => {
            document.title = 'PeopleSol';
        };
    })

    const [selectedImage, setSelectedImage] = useState(null);

    // Function to handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };
    function newGroupCreated() {
        props.createNewGroupChat({
            userId: "1234567258",
            Name: newGroupProfileName,
            Img: newGroupProfilePic,
            Active: "online",
            type: "group",
            totalMember: totalMember,
            participants: props.selectedGroupMember,
            msgs: [],
        });
        props.setNewGroup(false);
        setActiveGroupCreation(false);
        setNewGroupList(false);
    }
    const createGroup = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append("files", selectedImage);

        // Perform the API request
        const response = await fetch(
            "https://media.solunxt.com/api/v1/Storage/Save?token=5b4cbd69-2366-4708-871c-5300a4dc6f18",
            {
                method: "POST",
                body: formData,
            }
        );
        // Await the JSON response and store it
        const responseData = await response.json();
        setLoading(false)
        if (responseData.succeeded) {
            let a = [{ "contactId": userDetails._id, "personalNumber": userDetails.personalNumber }];
            for (let i = 0; i < props.selectedGroupMember.length; i++) {
                a.push({ "contactId": props.selectedGroupMember[i]._id, "personalNumber": props.selectedGroupMember[i].personalNumber })
            }
            let data = {
                "type": "Group",
                "title": newGroupProfileName,
                "icon": responseData.data[0].url,
                "image": responseData.data[0].url,
                "participateMembers": [...a],
                "createdByContactId": userDetails._id
            }
            props.createGroupAPI(data)
        }

    }


    return (
        <div className="newGroupContainer" style={{ backgroundColor: "white", height: "800px" }}>
            {loading && (
                <div className="document-preview-container-uploading">
                    <div
                        style={{
                            color: "rgb(51, 51, 51)",
                            padding: "20px",
                            borderRadius: "5px",
                            fontSize: "17px",
                            textAlign: "center",
                            width: "500px",
                            backgroundColor: "white",
                        }}
                    >
                        <div style={{ marginTop: "0px" }}>
                            {" "}
                            <Loader />
                        </div>
                    </div>
                </div>
            )}
            <div className="newGroupCreationContainer" >

                <div className="newGroupImageWrapper" >
                    <div className="profileIconImg" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ margin: 30 }} onClick={() => { document.getElementById("fileInputGroup").click() }}>
                            {!selectedImage ? <img src={UploadImage} alt="upload images" />
                                :
                                <div style={{ zIndex: 1 }}>
                                    <img style={{ zIndex: 1, marginLeft: -40 }} src={ProfileBack} alt="upload images" />
                                    <img style={{ height: 90, width: 90, borderRadius: 45, marginLeft: -133, marginBottom: 41, zIndex: 1 }} src={URL.createObjectURL(selectedImage)} alt="group image" />
                                </div>}
                            <input
                                type="file"
                                accept="image/*"
                                id="fileInputGroup"
                                style={{ display: "none" }}
                                onChange={handleImageChange}
                            />
                        </div>
                        <div>
                            <span>
                                <input
                                    style={{ borderStyle: "none", outline: "none", color: "#1296B0" }}
                                    type="text"
                                    placeholder="Type Group Name"
                                    onChange={(e) => {
                                        setNewGroupProfileName(e.currentTarget.value);
                                    }}
                                    readOnly={profileNameInputActive}
                                />
                                {profileNameInputActive &&
                                    <svg
                                        onClick={() => { setProfileNameInputActive(false) }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13"
                                        height="13"
                                        viewBox="0 0 13 13"
                                        fill="none"
                                    >
                                        <line
                                            x1="7.0243"
                                            y1="12.1146"
                                            x2="12.0204"
                                            y2="12.1146"
                                            stroke={newGroupProfileName === "" ? "grey" : "#1296B0"}
                                            stroke-width="1.00856"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M6.93619 3.13129L0.572876 9.45211V11.9761H3.13693L9.4284 5.64444M6.93619 3.13129L8.56652 1.51185C9.25534 0.82763 10.3679 0.829495 11.0544 1.51602V1.51602C11.741 2.20263 11.7428 3.3153 11.0583 4.0041L9.4284 5.64444M6.93619 3.13129L9.4284 5.64444"
                                            stroke={newGroupProfileName === "" ? "grey" : "#1296B0"}
                                            stroke-width="1.00856"
                                        />
                                    </svg>}
                                {!profileNameInputActive && <svg
                                    onClick={() => { setProfileNameInputActive(false) }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                >
                                    <line
                                        x1="7.0243"
                                        y1="12.1146"
                                        x2="12.0204"
                                        y2="12.1146"
                                        stroke={newGroupProfileName === "" ? "grey" : "#1296B0"}
                                        stroke-width="1.00856"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M6.93619 3.13129L0.572876 9.45211V11.9761H3.13693L9.4284 5.64444M6.93619 3.13129L8.56652 1.51185C9.25534 0.82763 10.3679 0.829495 11.0544 1.51602V1.51602C11.741 2.20263 11.7428 3.3153 11.0583 4.0041L9.4284 5.64444M6.93619 3.13129L9.4284 5.64444"
                                        stroke={newGroupProfileName === "" ? "grey" : "#1296B0"}
                                        stroke-width="1.00856"
                                    />
                                </svg>}
                            </span>
                            <hr ></hr>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", margin: 20, color: "grey" }}>
                    <p>{props.selectedGroupMember.length + 1} Participants</p>
                </div>
                <div className="newGroupParticipantListContainer" style={{ paddingRight: 10 }}>
                    {[userDetails, ...props.selectedGroupMember]?.map((item, index) => {
                        return (
                            <div key={index} className="newGroupLstWithDeleteIcon">
                                <div className="newGroupListRowContainer">
                                    <div

                                        onClick={() => { }}
                                        className="newChatListContentContainer"
                                    >
                                        <div className="newChatActiveImage">
                                            {item.image != "" ? <img src={item.image} alt="Profile" /> :
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 200 }}>
                                                    <div style={{ color: "#1296B0" }}>
                                                        {
                                                            item?.name
                                                                ?.split(" ")
                                                                ?.slice(0, 2)
                                                                ?.map((val) => {
                                                                    return val?.charAt(0)?.toUpperCase();
                                                                })
                                                                .join("")
                                                        }
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className="newChatPostMainTextContainer">
                                            <label className="chatListName">{item.name}</label>
                                            <label className="chatListRole">{item.role}</label>
                                        </div>
                                    </div>
                                    {index != 0 && <div
                                        onClick={() => {
                                            let a = props.selectedGroupMember.filter(val => val != item);
                                            props.setSelectedGroupMember(a);
                                        }}>
                                        <svg

                                            xmlns="http://www.w3.org/2000/svg"
                                            width="27"
                                            height="27"
                                            viewBox="0 0 27 27"
                                            fill="none"
                                        >
                                            <circle
                                                cx="13.9741"
                                                cy="13.4956"
                                                r="13"
                                                fill="#FFF2F0"
                                            />
                                            <path
                                                d="M9.35889 11.0924L10.3124 18.4194C10.4222 19.2631 11.141 19.8944 11.9919 19.8944H15.9566C16.8074 19.8944 17.5262 19.2631 17.636 18.4194L18.5896 11.0924"
                                                stroke="#F71D1D"
                                                stroke-linecap="round"
                                            />
                                            <line
                                                x1="8.67432"
                                                y1="9.31686"
                                                x2="19.274"
                                                y2="9.31686"
                                                stroke="#F71D1D"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M11.998 9.41261V8.78978C11.998 7.85444 12.7563 7.09619 13.6916 7.09619H14.2561C15.1915 7.09619 15.9497 7.85444 15.9497 8.78978V9.41261"
                                                stroke="#F71D1D"
                                            />
                                        </svg>
                                    </div>}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {selectedImage && newGroupProfileName !== "" && newGroupProfileName != null ? <div className="groupCreationNxtBtn" onClick={() => { createGroup() }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                >
                    <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
                    <path
                        d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
                        stroke="white"
                        stroke-width="1.10154"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div> : null}
        </div>
    );
}

export default CreateNewGroup;