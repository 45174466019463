import React from 'react'
import { useEffect } from 'react';

const AbsentCardComp = ({title, date}) => {
  return (
    <div className="attendanceCardContentContainer" style={{backgroundColor:`#FDD2D2`, borderLeft: '2px solid rgb(247, 29, 29)'}}>
      <div className="cardDateDisplay">{date ? date : ""}</div>
        <div className='attendanceContentStatus' style={{color:"#F71D1D", fontSize: '12px', padding: '3px'}} >
            <label>{title ? title : ""}</label>
        </div>
        
        
        </div>
  )
}

export {AbsentCardComp}
