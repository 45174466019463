import React, { useState } from "react";
import { ParticularQuestionComp } from "./ParticularQuestionComp";
import { InvestmentComp } from "./InvestmentComp";
import { Form, Formik } from "formik";
import { useEffect } from "react";

const ParticularDeclarationComp = ({errors, values, formFields, editValues }) => {
  const quesOptions = [
    {
      id: 1,
      text: "Are you staying in a rented house?",
    },
    {
      id: 2,
      text: "Are you repaying home loan ?",
    },
    // {
    //   id: 3,
    //   text: "Is the employee receiving rental income?",
    // },
  ];
  useEffect(() => {
    document.title = `PeopleSol -  Particular Declaration`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="particularDetailsContainer">
      <div className="particularDetailsHolder" style={{ width: "100%" }}>
        {quesOptions?.map((item) => {
          return (
            <ParticularQuestionComp
              key={item.id}
              editValues={editValues}
              values={values}
              quesText={item.text}
              id={item.id}
            />
          );
        })}
      </div>
      <div className="investmentListContainer">
        {formFields?.map((item, index) => (
          <InvestmentComp
          errors={errors}
            key={index}
            heading={item.heading}
            noteText={item.noteText}
            body={item.body}
            maxLimit={item.maxLimit}
            values={values}
            sortId={index}
            editValues={editValues}
            compName={item?.compName}
          />
        ))}
      </div>
    </div>
  );
};

export { ParticularDeclarationComp };
