import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadBlobChat } from "./Components/chatServices";
const MediaViewerChat = ({
  mediaIndex,
  mediaCaption,
  mediaArray,
  setMediaArray,
  setMediaIndex,
  setMediaCaption,
}) => {
  const [selectedFileIndex, setSelectedFileIndex] = useState(mediaIndex);
  return (
    <div className="popupMedia">
      <div
        style={{
          height: "80px",
          width: "100%",
          //   textAlign: "end",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "0px 15px",
          //  backgroundColor: "black"
        }}
      >
        <FileDownloadIcon
          style={{
            color: "white",
            fontSize: "27px",
            cursor: "pointer",
            margin: "0px 10px",
          }}
          onClick={() => {
            downloadBlobChat(
              "",
              mediaArray[selectedFileIndex]?.slice(-3) === "jpg" ||
                mediaArray[selectedFileIndex]?.slice(-3) === "png" ||
                mediaArray[selectedFileIndex]?.slice(-3) === "svg" ||
                mediaArray[selectedFileIndex]?.slice(-3) === "ogv" ||
                mediaArray[selectedFileIndex]?.slice(-3) === "mp4"
                ? mediaArray[selectedFileIndex]?.slice(-3)
                : mediaArray[selectedFileIndex]?.slice(-4) === "jpeg" ||
                  mediaArray[selectedFileIndex]?.slice(-4) === "webm"
                ? mediaArray[selectedFileIndex]?.slice(-4)
                : "",
              mediaArray[selectedFileIndex],
              mediaArray[selectedFileIndex].split("/").pop()
            );
          }}
        />
        <CloseIcon
          style={{
            color: "white",
            fontSize: "31px",
            cursor: "pointer",
            margin: "0px 10px",
          }}
          onClick={() => {
            setMediaArray([]);
            setMediaCaption("");
            setMediaIndex(0);
          }}
        />
      </div>
      <div className="popupMediaPopup">
        {mediaArray[selectedFileIndex]?.slice(-3) === "mp4" ||
        mediaArray[selectedFileIndex]?.slice(-4) === "webm" ||
        mediaArray[selectedFileIndex]?.slice(-3) === "ogv" ? (
          <>
            <span
              style={{
                position: "relative",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            >
              <video
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
                src={mediaArray[selectedFileIndex]}
                controls={true}
              ></video>
            </span>
          </>
        ) : (
          <>
            <span
              style={{
                backgroundColor: "white",
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
                display: "flex",
              }}
            >
              <img
                style={{
                  maxHeight: "570px",
                  maxWidth: "925px",
                  objectFit: "contain",
                  // backgroundColor: "white",
                }}
                src={mediaArray[selectedFileIndex]}
                alt="Message"
              />
            </span>
          </>
        )}
      </div>
      <div style={{ color: "white", margin: "10px 0px 0px 0px" }}>
        {mediaCaption}
      </div>

      <div
        style={{
          display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          height: "101px",
          overflowX: "auto",
          overflowY: "hidden",
          maxWidth: "calc(100% - 60px)",
          margin: "30px 0px",
          //       display: flex;
          // height: 117px;
          // overflow: auto hidden;
          // max-width: calc(100% - 60px);
          // margin: 25px 0px;
        }}
      >
        {mediaArray?.map((item, indexx) => {
          if (
            item?.slice(-3) === "mp4" ||
            item?.slice(-4) === "webm" ||
            item?.slice(-3) === "ogv"
          ) {
            return (
              <>
                <div
                  style={
                    selectedFileIndex === indexx
                      ? { border: "1px solid white" }
                      : {}
                  }
                  className="mediaViewerChat cursor"
                  onClick={() => {
                    setSelectedFileIndex(indexx);
                  }}
                >
                  <span
                    style={{
                      position: "relative",
                      maxHeight: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    <video
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                      }}
                      src={item}
                      controls={false}
                    ></video>
                  </span>
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  style={
                    selectedFileIndex === indexx
                      ? { border: "1px solid white" }
                      : {}
                  }
                  className="mediaViewerChat cursor"
                  onClick={() => {
                    setSelectedFileIndex(indexx);
                  }}
                >
                  <img
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "contain",
                      backgroundColor: "white",
                    }}
                    src={item}
                    alt="Message"
                  />
                </div>
              </>
            );
          }
        })}
      </div>
    </div>
  );
};

export default MediaViewerChat;
