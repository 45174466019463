import React, { useState } from "react";
import RadioButtonComponent from "./RadioButtonComponent";
import { IconButton } from "@mui/material";

function QuestionAnswerContainer({
  questionData,
  setSelectedData,
  selectedData,
  responseSurvey,
}) {
  const [selected, setSelected] = useState([]);
  const [selectedQuestionsId, setSelectedQuestionsId] = useState([]);
  const selectOptions = (ansData, queData) => {
    if (selectedQuestionsId?.includes(queData?.id)) {
      if (!queData?.allowMultipleAnswer) {
        if (selected.includes(ansData?.id)) {
          let x = selected?.filter((dt) => dt !== ansData?.id);
          setSelected(x);
          let y = selectedData?.filter((dtt) => dtt?.AnswerId !== ansData?.id);
          setSelectedData(y);
          let z = selectedQuestionsId?.filter((dttt) => dttt !== queData?.id);
          setSelectedQuestionsId(z);
        } else {
          let x = selected?.filter((dt) => {
            return !queData?.answerOption?.some((ansd) => ansd?.id === dt);
          });
          setSelected(x);
          let y = selectedData?.filter(
            (dtt) => dtt?.QuestionId !== queData?.id
          );
          setSelectedData(y);
          setSelected([...x, ansData?.id]);
          setSelectedData([
            ...y,
            {
              Id: 0,
              SurveyId: questionData?.id,
              QuestionId: queData?.id,
              AnswerId: ansData?.id,
            },
          ]);
        }
      } else {
        setSelected((prev) => [...prev, ansData?.id]);
        setSelectedData((prev) => [
          ...prev,
          {
            Id: 0,
            SurveyId: questionData?.id,
            QuestionId: queData?.id,
            AnswerId: ansData?.id,
          },
        ]);
      }
    } else {
      setSelected((prev) => [...prev, ansData?.id]);
      setSelectedQuestionsId((prev) => [...prev, queData?.id]);
      setSelectedData((prev) => [
        ...prev,
        {
          Id: 0,
          SurveyId: questionData?.id,
          QuestionId: queData?.id,
          AnswerId: ansData?.id,
        },
      ]);
    }
  };

  const findSelectedOptions = (id, respondAnswer) => {
    return (
      respondAnswer?.some((element) => {
        return element?.answerId === id;
      }) || false
    );
  };

  return (
    <div className="questionAnswerContainer">
      <div className="questionAnswerWrapper">
        {questionData?.questions?.map((queData, queDataIndex) => {
          return (
            <div className="questionAnswerList" key={queDataIndex}>
              {queData?.allowMultipleAnswer && (
                <div className="surveyNote">
                  <h5>Note</h5>
                  <p>Multiple Selection Allowed.</p>
                </div>
              )}

              <label>{queData?.questionText}</label>
              {queData?.allowMultipleAnswer ? (
                <RadioButtonComponent
                  findSelectedOptions={findSelectedOptions}
                  queData={queData}
                  selectOptions={selectOptions}
                  selected={selected}
                  questionData={questionData}
                />
              ) : (
                <RadioButtonComponent
                  findSelectedOptions={findSelectedOptions}
                  queData={queData}
                  selectOptions={selectOptions}
                  selected={selected}
                  id={"xcvbh"}
                  questionData={questionData}
                />
              )}

              {questionData?.questions?.length - 2 > queDataIndex && (
                <div className="surevyQuestionSeperator"></div>
              )}
            </div>
          );
        })}
        {selectedData[0]?.SurveyId === questionData?.id && (
          <div className="submitPollAndSurveyResponse">
            <IconButton
              onClick={() => {
                responseSurvey();
              }}
              style={{
                padding: "8px 20px",
                borderRadius: "5px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#fff",
                background: "#1296b0",
              }}
            >
              <article>Submit</article>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionAnswerContainer;
