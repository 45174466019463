import React, { useState } from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import Upward from "../../../../src/Assets/upward.svg";
import Downward from "../../../../src/Assets/downward.svg";

const Rewardemployeenomination = () => {
  const [isopen, setclose] = useState(true);

  const toggleAccordion = () => {
    setclose(!isopen);
  };

  const Nominifirst = [
    { label: "xyz", value: "xyz" },
    { label: "PQR", value: "PQR" },
  ];
  const Nominisecond = [
    { label: "xyz", value: "xyz" },
    { label: "pqr", value: "pqr" },
  ];
  const Nominithird = [
    { label: "xyz", value: "xyz" },
    { label: "pqr", value: "pqr" },
  ];
  const nomicationconsolation = [
    { label: "xyz", value: "xyz" },
    { label: "xyz", value: "xyz" },
  ];

  return (
    <div className="accordions setawards">
      <div className="accordions-headers">
        <h6>Award 1</h6>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            onClick={toggleAccordion}
            src={isopen ? Upward : Downward}
            alt={isopen ? "Open" : "close"}
          />
        </div>
      </div>
      <div
        className="accordions-body"
        style={{ display: isopen ? "block" : "none", transition: "0.5s" }}
      >
        <div
          className="formcustom"
          style={{ display: "grid", columnGap: "9%" }}
        >
          <div>
            <LabelCustom
              labelName={"Nomination for 1st Position"}
              mandatory="true"
            />
            <SelectForm
              name={"Nominifirst"}
              placeholder={"Enter"}
              options={Nominifirst}
            />
          </div>
          <div>
            <LabelCustom
              labelName={"Nomination for 2nd Position"}
              mandatory="true"
            />
            <SelectForm
              name={"Nominisecond"}
              placeholder={"Enter"}
              options={Nominisecond}
            />
          </div>
        </div>

        <div
          className="formcustom"
          style={{ display: "grid", columnGap: "9%" }}
        >
          <div>
            <LabelCustom
              labelName={"Nomination for 3rd Position"}
              mandatory="true"
            />
            <SelectForm
              name={"Nominithird"}
              placeholder={"Enter"}
              options={Nominithird}
            />
          </div>
          <div>
            <LabelCustom
              labelName={"Nomination for Consolation"}
              mandatory="true"
            />
            <SelectForm
              name={"nomicationconsolation"}
              placeholder={"Enter"}
              options={nomicationconsolation}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewardemployeenomination;
