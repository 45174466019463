import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Loader } from "../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../Features/badgeUtils";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { AssignRemark } from "../../../Components/FormComponent/PopupComponent/AssignRemark";
import { format } from "date-fns";

const DetailsPageVehicle = () => {
  useEffect(() => {
    document.title = `PeopleSol - Vehicle Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const dataForPopup = state?.dataForPopup;
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isAssignOpen, setIsAssignOpen] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [selId, setSelId] = useState("");

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (id, type, color) => {
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const togglePopupAssign = (type, id, color) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsAssignOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const PostApRejHol = async (type, id, Remark) => {
    try {
      // console.log("called")
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Cancel" || type === "Release") {
        await getRequest(`CompanyCarController/${type}?ID=${id}`);
      } else if (type === "Approve") {
        await getRequest(`CompanyCarController/Approve?Id=${id}`);
      } else if (type === "approve") {
        await getRequest(`CompanyCarController/Approve?Id=${id}`);
      } else if (type === "Reject") {
        await postRequest(
          `CompanyCarController/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "reject") {
        await postRequest(
          `CompanyCarController/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Reject By Transport Lead") {
        await postRequest(
          `CompanyCarController/RejectByTransportLead?ID=${id}&Remarks=${Remark}`
        );
      } else {
        await getRequest(`CompanyCarController/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `CompanyCarController/Details?Id=${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data.data);
      if (approve) {
        if (LoggedInUser?.id === value2 && result.data?.data?.actions.length) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.data?.actions[0]?.id,
              approve,
              result.data?.data?.actions[0]?.color
            );
          } else
            PostApRejHol(approve, result.data?.data?.actions[0]?.id, "Remark");
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Company Car Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Details
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Timeline
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Notifications
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {data && (
              <>
                {tabIndex === 0 &&
                  (data != null ? (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm"> Request Details</div>
                      <div className="detailInner">
                        <>
                          <Details
                            detailTitle={"Request Number"}
                            Details={data?.requestNumber}
                          />

                          <Details
                            detailTitle={"Name"}
                            Details={data?._request?.name}
                          />
                          <Details
                            detailTitle={"Designation"}
                            Details={data?._request?.designation}
                          />
                          <Details
                            detailTitle={"Phone Number"}
                            Details={data?._request?.phoneNumber}
                          />
                          <Details
                            detailTitle={"Email"}
                            Details={data?._request?.email}
                          />
                          <Details
                            detailTitle={"Type"}
                            Details={data?._request?.type}
                          />
                          {data?._request?.type === "Guest" ? (
                            <Details
                              detailTitle={"Guest Type"}
                              Details={data?._request?.guestType}
                            />
                          ) : (
                            ""
                          )}
                          <Details
                            detailTitle={"Date"}
                            Details={
                              data?._request?.date
                                ? format(
                                  new Date(data?._request?.date),
                                  "dd-MM-yyyy"
                                )
                                : ""
                            }
                          />
                          <Details
                            detailTitle={"Purpose"}
                            Details={data?._request?.purpose}
                          />

                          <Details
                            detailTitle={"No. Of People"}
                            Details={data?._request?.numberOfPeople}
                          />
                          <Details
                            detailTitle={"Requires Driver?"}
                            Details={
                              data?._request?.isDriverRequired === true
                                ? "Yes"
                                : "No"
                            }
                          />
                          <Details
                            detailTitle={"Pick-up Location"}
                            Details={data?._request?.pickupLocation}
                          />

                          <Details
                            detailTitle={"Pick-up Time"}
                            Details={data?._request?.time}
                          />
                          <Details
                            detailTitle={"Drop Location"}
                            Details={data?._request?.dropLocation}
                          />
                          <Details
                            detailTitle={"Drop Time"}
                            Details={data?._request?.dropTime}
                          />
                          <Details
                            detailTitle={"Description"}
                            Details={data?._request?.description}
                          />
                          <Details
                            detailTitle={"Remarks"}
                            Details={data?._request?.remarks}
                          />
                          <Details
                            detailTitle={"Created On"}
                            Details={data?._request?.createdOn.replace(
                              "T",
                              " "
                            )}
                          />
                          <Details
                            detailTitle={"Status"}
                            Details={
                              <button
                                className="button primary"
                                style={{
                                  margin: "0",
                                  backgroundColor: getStatusColor(
                                    // data?._request?.status
                                    data?._statusLabel
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    // data?._request?.status
                                    data?._statusLabel
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  textAlign: "left",
                                }}
                              >
                                {data?._statusLabel}
                              </button>
                            }
                          />

                          <Details
                            detailTitle={"Last Updated On"}
                            Details={data?._request?.lastUpdatedOn.replace(
                              "T",
                              " "
                            )}
                          />
                        </>
                      </div>
                      <div className="headingForm"> Requester's Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={`${data?._request?.employee?.name} ${data?._request?.employee?.lastName}`}
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={data?._request?.employee?.rO_Name}
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={data?._request?.employee?.designation}
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={data?._request?.employee?.department}
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?._request?.employee?.email}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={
                            data?._request?.employee?.personalPhoneNumber
                          }
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={data?._request?.employee?.locationName}
                        />
                      </div>
                      {data?.actions?.length ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.actions?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  if (
                                    item.type !== "Approve" &&
                                    item.type !== "Release" &&
                                    item.type !== "Cancel" &&
                                    item.type !== "Reject" &&
                                    item.type !== "Reject By Transport Lead"
                                  ) {
                                    togglePopupAssign(
                                      item.type,
                                      item?.id,
                                      item?.color
                                    );
                                  } else if (
                                    item.type === "Reject" ||
                                    item.type === "Reject By Transport Lead"
                                  ) {
                                    togglePopup(
                                      item?.id,
                                      item?.type,
                                      item?.color
                                    );
                                  } else {
                                    PostApRejHol(item?.type, item?.id);
                                  }
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {isAssignOpen && (
                        <Popup
                          popupHeading={
                            typeAction === "Assign" ? "Assign" : "Re-Assign"
                          }
                          content={
                            <>
                              <AssignRemark
                                data={PostApRejHol}
                                dataForPopup={dataForPopup}
                                seltype={typeAction}
                                selId={selId}
                                color={"red"}
                                handleClose={togglePopupAssign}
                                showDriver={data?._request?.isDriverRequired}
                                isDetails={true}
                                detailsData={data}
                              // getAllListDatafunc={getAllListData}
                              />
                              {/* ) : (
                  <RejectRemark
                    setLoading={setLoading}
                    setIsAssignOpen={setIsAssignOpen}
                    data={PostApRejHol}
                    type={typeAll}
                    id={selId}
                    color={"red"}
                    handleClose={togglePopupapprove}
                    activeList={activeList}
                    getAllListData={getAllListData}
                    getMyTeamListData={getMyTeamListData}
                    getListData={getListData}
                  />
                )} */}
                            </>
                          }
                          handleClose={togglePopupAssign}
                        />
                      )}
                      {isOpen && (
                        <Popup
                          popupHeading={typeAction}
                          content={
                            <>
                              <Remark
                                data={PostApRejHol}
                                type={typeAction}
                                id={selId}
                                color={colorAction}
                                handleClose={togglePopup}
                              />
                            </>
                          }
                          handleClose={togglePopup}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="noDatastyle">
                      <Loader />
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Data Found"}</h4>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <Timeline
                          id={getIdFromDynamicUrl(currentUrl)}
                          module={"Company Car Request"}
                          requestNumber={data?.requestNumber}
                        />
                        {/* <Comments id={getIdFromDynamicUrl(currentUrl)} module={data?.data?.module} requestNumber={
                          data?.data?.manpowerIndent?.requestNumber
                        } /> */}
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={getIdFromDynamicUrl(currentUrl)}
                        module={"Company Car Request"}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsPageVehicle };
