import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalMeetingRoom } from "../Components/MeetingRoomApproval/PendingApprovalMeetingRoom";
import { MeetingRoomApproved } from "../Components/MeetingRoomApproval/MeetingRoomApproved";
import { RejectedMeetingRoom } from "../Components/MeetingRoomApproval/RejectedMeetingRoom";
import { AllRequestMeetingRoom } from "../Components/MeetingRoomApproval/AllRequestMeetingRoom";
import { useEffect } from "react";

const ApprovalMeetingRoom = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Meeting Room`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Accepted
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalMeetingRoom
            title={"MeetingRoom"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <MeetingRoomApproved
          title={"MeetingRoom"}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <RejectedMeetingRoom
            title={"MeetingRoom"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}

      {tabIndex === 3 && (
        <>
          <AllRequestMeetingRoom
            title={"MeetingRoom"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalMeetingRoom };
