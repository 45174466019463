import React, { useEffect } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { InputCustom } from "../../../../../Components/FormComponent/InputCustom";

const Typeoption = [
  { label: "Employee Loan", value: "Employee Loan" },
  { label: "Term Advance", value: "Term Advance" },
  { label: "Salary Advance", value: "Salary Advance" },
];
const type = [
  { label: "Term Advance", value: "Term Advance" },
  { label: "Salary Advance", value: "Salary Advance" },
];
export const CreateLoanAdvanceComp = ({ formValues }) => {
  // //console.log(
  //   "formValues",
  //   formValues?.totalRecoveryMonth !== "" && formValues?.amount !== ""
  // );
  const diff = () => {
    return (
      formValues?.amount / Number(formValues?.totalRecoveryMonth)
    ).toFixed(2);
  };

  useEffect(() => {
    document.title = `PeopleSol -Create Loan Advance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
        alignItems: "start",
      }}
    >
      <div style={{ flex: 5 }}>
        <div>
          <LabelCustom labelName={"Loan Or Advance Type "} mandatory={true} />
          <SelectForm
            name={"type"}
            options={formValues?.time >= 6 ? type : Typeoption}
            placeholder={" Select Loan Or Advance Type"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Loan Or Advance Amount"} mandatory={true} />
          <InputCustomFormik
            maxLength={9}
            name={"amount"}
            type="number"
            step="0.01"
            placeholder={"Enter Loan Or Advance Amount "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Loan Or Advance Reason "} mandatory={true} />
          <TextAreaFormik
            maxlength="500"
            style={{ height: "100px" }}
            name="reason"
            placeholder={"Enter Loan Or Advance Reason"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Required by Date "} mandatory={true} />
          <DatePickerFormiks
            minDate={new Date()}
            style={{ height: "100px" }}
            name="requiredBydate"
            placeholder={"Select Date "}
          />
        </div>
        <div>

          <LabelCustom labelName={"Total Recovery Month "} mandatory={true} />
          <InputCustomFormik
            maxLength={3}
            type="number"
            step="0.01"
            name={"totalRecoveryMonth"}
            placeholder={"Enter No. of Month "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Per Month Deduction "} />
          <InputCustom
            disabled
            value={
              formValues?.totalRecoveryMonth !== "" && formValues?.amount !== ""
                ? (
                    formValues?.amount / Number(formValues?.totalRecoveryMonth)
                  ).toFixed(2)

                : ""
            }
            name={"perMonthDeduction"}
            type="number"
            step="0.01"
            placeholder={"Enter Per Month Deduction "}
          />
        </div>
      </div>
      <div
        style={{
          flex: 5,
          marginTop: "50px",
          background: "var(--primary-hover)",
          padding: "24px",
          lineHeight: "36px",
        }}
      >
        <h4>Loan Policy</h4>
        <div>
          <ul>
            <li>
              An employee can avail up to INR 20,000/- only, under{" "}
              <strong>Salary Advance</strong>
            </li>
            <li>
              The employee should be associated with PeopleSol for at least 6
              months in order to be eligible for <strong>Salary Advance</strong>{" "}
              and <strong>Term Advance</strong>
            </li>
            <li>
              An employee can avail from INR 20,000/- up to INR 50,000/- only,
              under <strong>Term Advance</strong>
            </li>
            <li>
              The employee should be associated with PeopleSol for at least 3
              years in order to be eligible for <strong>Employee Loan</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
