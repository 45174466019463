import React, { useState, useEffect } from "react";
import { Loader } from "../../../../../Components/FormComponent/Loader";

const MyImageComponent = ({
  src,
  alt,
  setLoading,
  setLoadedCount,
  loading,
}) => {
  useEffect(() => {
    setLoading(true);
  }, []);

  const handleImageLoaded = () => {
    setLoading(false);
    setLoadedCount((prevCount) => prevCount + 1);
  };

  const handleImageError = () => {
    setLoading(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={
          !loading
            ? { opacity: "0", visibility: "hidden", position: "absolute" }
            : {
                opacity: "1",
                visibility: "visible",
                width: "100%",
                height: "40vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }
        }
      >
        <Loader />{" "}
      </div>
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoaded}
        onError={handleImageError}
        style={
          loading
            ? { opacity: "0", visibility: "hidden" }
            : {
                opacity: "1",
                visibility: "visible",
                width: "100%",
                height: "auto",
                display: "block",
              }
        }
      />
    </div>
  );
};

export default MyImageComponent;
