import React from 'react'
import './WhatsNew.css'
import { useState } from 'react'
import { Tab, Tabs } from '@mui/material';
import { AllWhatsNew } from './AllWhatsNew';
import { EnhancementWhatsNew } from './EnhancementWhatsNew';
import { FeaturesWhatsNew } from './FeaturesWhatsNew';
import { useEffect } from 'react';

const WhatsNew = () => {

  const [idActive, setIdActive] = useState(1);
  const [textActive, setTextActive] = useState(3);

  const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newTabIndex) => {  
        setTabIndex(newTabIndex); 
    }

    useEffect(() => {
        document.title = `PeopleSol - Whats New`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);

  return (
    <div className="container candidateContainer knowledgeBackground">
       <div className="whatsNewMainContainer">
        {/* <div className="newHeadingContainer">
            <h4 style={{color:"var(--primary)"}}>What's New</h4>
            <label>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit, magnam.</label>
            <label>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit, magnam.</label>
        </div> */}
        <div className="tabContainer whatsTabContainer">
                <Tabs value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { background: "#1296B0" } }}>
                    <Tab label={<span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>All</span>} />
                    <Tab label={<span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>Enhancement</span>} />
                    <Tab label={<span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>Features</span>} />
                   
                 
                </Tabs>
                
            </div>
            <div className="tabContentHolder" style={{height: 'calc(100% - 111px)', borderTop: '1px solid lightgray'}}>
            {tabIndex === 0 && (
                <>
                    <AllWhatsNew/>
                </>)}
            {tabIndex === 1 && (
                <>
                    <EnhancementWhatsNew/>
                </>)}
            {tabIndex === 2 && (
                <>
                    <FeaturesWhatsNew/>
                </>)}
            </div>

       </div>
    </div>
  )
}

export {WhatsNew}
