import React, { useState } from "react";
import Select from "react-select";

const CustomSelectSingle = (props) => {
  const { field, form, options, onDropdownOpen, disabled, onChangeProp, borderBlack } =
    props;

  const [selected, setSelected] = useState(false);
  return (
    <Select
      {...props}
      isDisabled={disabled}
      onMenuOpen={onDropdownOpen}
      menuShouldBlockScroll={true}
      menuPortalTarget={document.body}
      color={"#1296b038"}
      className={borderBlack && !selected ? "react-select-container1" : "react-select-container"}
      classNamePrefix="react-select"
      options={options}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "var(--primary-hover)",
          primary: "var(--primary)",
          primary50: "var(--primary-hover)",
        },
      })}
      onFocus={() => { setSelected(true) }}
      isSearchable
      name={field.name}
      onBlur={(e) => { setSelected(false); field.onBlur(e); }}
      values={options?.filter((option) => option?.value === field?.value)}
      value={
        field.value !== "" &&
        options?.find((option) => option?.value === field?.value)
      }
      onChange={(option) =>
        onChangeProp
          ? onChangeProp(option)
          : form?.setFieldValue(field.name, option?.value)
      }
    />
  );
};

export { CustomSelectSingle };
