import React, { useState } from "react";
import { Popup } from "../../../../../../../Components/FormComponent/PopupComponent/Popup";

function RadioButtonComponent({
  queData,
  selectOptions,
  selected,
  findSelectedOptions,
  questionData,
}) {
  const [showPopup, setShowhowPopup] = useState(false);
  const [oldDate, setOldDate] = useState(false);
  const [newDate, setNewDate] = useState(false);
  return (
    <div className="surveyRadioButtonComponent">
      {queData?.answerOption?.map((ansData) => {
        return (
          <div className="answerOptionsList">
            {queData?.respondAnswer?.length <= 0 && (
              <article
                onClick={() => {
                  if (
                    new Date(questionData?.endOn).toLocaleDateString() >=
                      new Date().toLocaleDateString() &&
                    new Date(questionData?.startOn).toLocaleDateString() <=
                      new Date().toLocaleDateString() &&
                    queData?.respondAnswer.length <= 0
                  ) {
                    selectOptions(ansData, queData);
                  }
                  if (
                    new Date(questionData?.endOn).toLocaleDateString() <
                    new Date().toLocaleDateString()
                  ) {
                    setShowhowPopup(true);
                    setOldDate(true);
                  }
                  if (
                    new Date(questionData?.startOn).toLocaleDateString() >
                    new Date().toLocaleDateString()
                  ) {
                    setShowhowPopup(true);
                    setNewDate(true);
                  }
                }}
              >
                {selected.includes(ansData?.id) ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                    />
                    <circle cx="8" cy="8" r="5" fill="#1296B0" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#BFBFBF"
                      strokeWidth="1.5"
                    />
                    <circle
                      cx="8.00036"
                      cy="8.00036"
                      r="5.30993"
                      fill="white"
                    />
                  </svg>
                )}
              </article>
            )}
            {queData?.respondAnswer?.length > 0 && (
              <article>
                {findSelectedOptions(ansData?.id, queData?.respondAnswer) ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                    />
                    <circle cx="8" cy="8" r="5" fill="#1296B0" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#BFBFBF"
                      strokeWidth="1.5"
                    />
                    <circle
                      cx="8.00036"
                      cy="8.00036"
                      r="5.30993"
                      fill="white"
                    />
                  </svg>
                )}
              </article>
            )}
            <span className="answerOption">{ansData?.answerText}</span>
          </div>
        );
      })}
      {showPopup && (
        <Popup
          firstClassName="surveyConfirmBox1"
          secondClassName="surveyConfirmBox2"
          popupHeading={questionData?.surveyTitle}
          content={
            <>
              <div className="socialPostDeleteBody">
                {oldDate && (
                  <p>
                    Sorry, you can't respond to this survey because it has
                    either ended or is no longer available.
                  </p>
                )}
                {newDate && (
                  <p>
                    Apologies, you can't respond to this survey at the moment as
                    it hasn't started yet.
                  </p>
                )}
              </div>
              <div className="socialPostDeleteBtn">
                {/* <button onClick={deleteSocialPostById}>Delete</button> */}
                <button
                  onClick={() => {
                    setShowhowPopup(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setShowhowPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default RadioButtonComponent;
