import React from 'react'
import { useEffect } from 'react';

const LeaveCardComp = ({title, date}) => {
 
  return (
    <div className="attendanceCardContentContainer" style={{backgroundColor:`#F4EAF3`, borderLeft: '2px solid #B69FB3'}}>
      <div className="cardDateDisplay">{date ? date : ""}</div>
        <div className='attendanceContentStatus' style={{color:"#B69FB3", fontSize: '10px', width: 'calc(100% - 3px)', lineHeight: '12px'}} >
            <label>{title ? title : ""}</label>
        </div>
        
        
        </div>
  )
}

export {LeaveCardComp}
