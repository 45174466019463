import React, { useState } from 'react'
import "./EmpProfileDetails.css";
import { confirmAlert } from 'react-confirm-alert';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { ProfileGroup } from '../MyProfileDetails/ProfileGroup';
import { useSelector } from 'react-redux';
import AddToFavourite from "../../../../Assets/AddToFavourite.svg"
import RemoveFromFavourite from "../../../../Assets/RemoveFromFavourite.svg"
import MediaIcon from "../../../../Assets/MediaIcon.svg"
import LinkIcon from "../../../../Assets/LinkIcon.svg"
import DocIcon from "../../../../Assets/DocIcon.svg"

function EmpProfiledetails(props) {
  const userDetails = useSelector((state) => state.chat.userDetails);
  const [userInfo, setUserInfo] = useState(false);
  const [commonGroups, setCommonGroup] = useState(false);

  useEffect(() => {
    let b = [];
    let groups = props.chatList.filter(item => item.type == "Group");
    let a = props.activeChat.participateMembers.filter(item => item.contactId != userDetails._id)

    for (let i = 0; i < groups.length; i++) {
      let arr = groups[i].participateMembers.filter(item => item.contactId == userDetails._id || item.contactId == a[0].contactId)
      if (arr.length == 2) b.push(groups[i]);
    }
    setCommonGroup([...b])
    props.socket.emit("findContactById", ({ "contactId": a[0].contactId }), (res) => { setUserInfo(res.data); console.log("res from get contact ===", res) })
  }, [props.chatList])


  const Tabs = () => {
    return (
      <div onClick={() => props.toggleMedia()} style={{ borderStyle: "solid", borderWidth: 1, borderColor: "rgba(216, 240, 242, 1)", borderRadius: 4, padding: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", margin: 20 }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#555555", backgroundColor: "white", padding: 10, borderRadius: 4 }}>
          <img src={MediaIcon} />
          <div style={{ marginLeft: 10 }}>{"Media"}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#555555", backgroundColor: "white", padding: 10, borderRadius: 4 }}>
          <img src={LinkIcon} />
          <div style={{ marginLeft: 10 }}>{"Links"}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#555555", backgroundColor: "white", padding: 10, borderRadius: 4 }}>
          <img src={DocIcon} />
          <div style={{ marginLeft: 10 }}>{"Docs"}</div>
        </div>
      </div>
    )
  }
  return (
    <>
      {/* {!activeAttachment && */}
      <div className="empProfileDetailsMainContainer">
        <div className="empProfileHeaderContainer">
          <div className="empProfileHeaderWrapper">
            <span>{userInfo.name + " Info"}</span>
            <svg onClick={() => {
              props.setEmpProfileDetails(false);
            }}
              xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
              <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 0.707104 -0.70711 -0.707104 20.4531 6.48962)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
              <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 22.0259 20.6318)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          </div>
        </div>
        <div className="empProfileDetailsMainWrapper">
          <div className="empProfileImageContainer">
            <div className="empProfileImageWrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="155"
                height="154"
                viewBox="0 0 155 154"
                fill="none"
              >
                <g opacity="0.4">
                  <circle
                    cx="77.1895"
                    cy="77.0684"
                    r="76.9316"
                    transform="rotate(-90 77.1895 77.0684)"
                    fill="url(#paint0_radial_60_9411)"
                  />
                </g>
                <g opacity="0.4">
                  <circle
                    cx="77.2061"
                    cy="77.0963"
                    r="63.3691"
                    transform="rotate(90 77.2061 77.0963)"
                    fill="url(#paint1_radial_60_9411)"
                  />
                </g>
                <g opacity="0.4">
                  <circle
                    cx="77.2051"
                    cy="77.0969"
                    r="46.7725"
                    transform="rotate(90 77.2051 77.0969)"
                    fill="url(#paint2_radial_60_9411)"
                  />
                </g>
                <defs>
                  <radialGradient
                    id="paint0_radial_60_9411"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(73.4253 78.6441) rotate(102.659) scale(101.154)"
                  >
                    <stop
                      offset="0.568025"
                      stop-color="white"
                      stop-opacity="0.2"
                    />
                    <stop offset="1" stop-color="#13A9C7" />
                  </radialGradient>
                  <radialGradient
                    id="paint1_radial_60_9411"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(74.1055 78.3943) rotate(102.659) scale(83.3209)"
                  >
                    <stop
                      offset="0.568025"
                      stop-color="white"
                      stop-opacity="0.2"
                    />
                    <stop offset="1" stop-color="#13A9C7" />
                  </radialGradient>
                  <radialGradient
                    id="paint2_radial_60_9411"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(74.9166 78.0549) rotate(102.659) scale(61.4988)"
                  >
                    <stop
                      offset="0.531188"
                      stop-color="white"
                      stop-opacity="0.2"
                    />
                    <stop offset="1" stop-color="#13A9C7" />
                  </radialGradient>
                </defs>
              </svg>
              <div className="empProfileImage">
                {userInfo.image != "" ?
                  <img style={{ width: "100%", height: "100%", borderRadius: 300 }} src={userInfo.image} />
                  : <div style={{ backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 300, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <div style={{ color: "#13A9C7", fontSize: 20 }}>
                      {userInfo.name
                        ?.split(" ")
                        ?.slice(0, 2)
                        ?.map((item) => {
                          return item?.charAt(0)?.toUpperCase();
                        })
                        .join("")
                      }
                    </div>
                  </div>}
              </div>
            </div>
            <div className="imgText ">
              <h4>{props?.activeChat?.name}</h4>
              <span>{props?.activeChat?.designation}</span>
            </div>
          </div>
          {/* <div className="EmpProfileAttachContainer">
            <div className="EmpProfileAttachWrapper">
              <div className="media"
              //  onClick={()=>{props.setActiveAttachment(true); props.setEmpProfileDetails(false);}}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
                  <path d="M9.56459 7.79475C10.0411 7.79475 10.4275 8.18108 10.4275 8.65764C10.4275 9.1342 10.0411 9.52052 9.56459 9.52052C9.08803 9.52052 8.70171 9.1342 8.70171 8.65764C8.70171 8.18108 9.08803 7.79475 9.56459 7.79475Z" stroke="#DE64A1" stroke-width="0.920806" />
                  <rect x="1.40181" y="4.9059" width="13.8079" height="10.8659" rx="0.897738" stroke="#DE64A1" stroke-width="0.920806" />
                  <path d="M3.28731 4.98451L3.59287 2.56491C3.68637 1.82457 4.35965 1.29833 5.10064 1.38643L17.0389 2.80569C17.7837 2.89424 18.3158 3.56983 18.2272 4.31467L17.151 13.3676C17.0646 14.0943 16.418 14.6218 15.6888 14.5607L15.4222 14.5383" stroke="#DE64A1" stroke-width="0.920806" />
                  <path d="M15.3327 10.3379L10.429 13.0134C10.0076 13.1604 9.32388 13.528 8.50064 12.7753C7.4716 11.8345 6.91293 10.4849 5.63021 10.4849C4.66689 10.4849 1.5303 12.7753 1.3833 12.7753" stroke="#DE64A1" stroke-width="0.920806" />
                </svg>
                <span>Media</span>
              </div>
              <div className="links"
              //  onClick={()=>{props.setActiveAttachment(true); props.setEmpProfileDetails(false);}}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                  <path d="M2.10165 7.72816C1.48184 6.98633 1.14223 6.0504 1.14209 5.08372C1.14209 2.80194 3.00831 0.928162 5.29764 0.928162H9.07542C11.3572 0.928162 13.231 2.80194 13.231 5.08372C13.231 7.3655 11.3648 9.23927 9.07542 9.23927H7.18653" stroke="#1296B0" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.2934 7.72815C15.9132 8.46998 16.2528 9.40592 16.2529 10.3726C16.2529 12.6544 14.3867 14.5282 12.0974 14.5282H8.31962C6.03784 14.5282 4.16406 12.6544 4.16406 10.3726C4.16406 8.09082 6.03028 6.21704 8.31962 6.21704H10.2085" stroke="#1296B0" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>Links</span>
              </div>
              <div className="docs" onClick={() => { props.setActiveAttachment(true); props.setEmpProfileDetails(false); }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path d="M15.7078 6.39963V10.0121C15.7078 13.6246 14.2628 15.0696 10.6503 15.0696H6.31531C2.70281 15.0696 1.25781 13.6246 1.25781 10.0121V5.67713C1.25781 2.06463 2.70281 0.619629 6.31531 0.619629H9.92781" stroke="#8A88ED" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M4.87012 8.56713H9.20512M4.87012 11.4571H7.76012M15.7076 6.39963H12.8176C10.6501 6.39963 9.92762 5.67713 9.92762 3.50963V0.619629L15.7076 6.39963Z" stroke="#8A88ED" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>Docs</span>
              </div>
            </div>
          </div> */}

          <Tabs />
          <div className="empProfileAboutContainer">
            <div className="empProfileAboutWrapper">
              <h5>About</h5>
              <div className="aboutTextWrapper1">
                <div className="empId">
                  <hr></hr>
                  <span>Employee ID</span>
                  <p>{userInfo.personalNumber}</p>
                </div>
                <div className="department">
                  <hr></hr>
                  <span>Department</span>
                  <p>{userInfo.department}</p>
                </div>
              </div>
              <div className="aboutTextWrapper2">
                <div className="role">
                  <hr></hr>
                  <span>Role</span>
                  <p>{userInfo.role}</p>
                </div>
                <div className="mobileno">
                  <hr></hr>
                  <span>Mobile No.</span>
                  <p>{userInfo.phoneNumber}</p>
                </div>
              </div>
              <div className="aboutTextWrapper3">
                <div className="email">
                  <hr></hr>
                  <span>Email</span>
                  <p>{userInfo.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="empProfileGroupContainer">
            <p>{commonGroups.length} Groups in common</p>
            <ProfileGroup groups={commonGroups} activeChat={props.activeChat} createNewGroup={() => props.createNewGroup(userInfo)} />
          </div>
          <div className="empProfileBlockContainer">
            <div onClick={(e) => {
              props.handleConversationAction(props.activeChat._id, props.activeChat.isFavorite ? "unfavorite" : "favorite");
              e.stopPropagation();
            }} className="empProfileBlockWrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                <img style={{ height: 20, width: 20 }} alt="add favourite" src={props.activeChat.isFavorite ? RemoveFromFavourite : AddToFavourite} />
              </div>
              <div style={{ marginLeft: 10 }}>{props.activeChat.isFavorite ? "Remove from favourites" : "Add to favourites"}</div>
            </div>
            <div className="empProfileBlockWrapper">
              {!props.activeChat.isBlock && <div className="blockImg">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                  <path d="M2.96777 4.37585L14.9436 14.3173" stroke="#F71D1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <circle cx="9.28223" cy="9.01721" r="8" stroke="#F71D1D" stroke-width="1.5" />
                </svg>
              </div>}
              <div className="blockText"
                style={{ color: !props.activeChat.isBlock ? "#F71D1D" : "#13A9C7" }}
                onClick={(e) => {
                  if (props.activeChat.isBlock) {
                    props.handleConversationAction(props.activeChat._id, props.activeChat.isBlock ? "unblock" : "block");
                    e.stopPropagation();
                  } else {
                    confirmAlert({
                      title: "Block User?",
                      message: "Blocked contacts will no longer be able to call you or send you messages.",
                      buttons: [
                        {
                          label: "Cancel",
                          onClick: () => { },
                        },
                        {
                          label: "Block",
                          onClick: () => {
                            props.handleConversationAction(props.activeChat._id, props.activeChat.isBlock ? "unblock" : "block");
                            e.stopPropagation();
                          },
                        },
                      ],
                      overlayClassName: "customConfirmAlertContainer",
                    });
                  }

                }}
              >{(props.activeChat.isBlock ? "Unblock " : "Block ") + userInfo.name}</div>
            </div>
          </div>
        </div>


      </div>
      {/* } */}

    </>

  );
}

export default EmpProfiledetails