
import { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";

const ReqCategoryFormIt = () => {
  useEffect(() => {
    document.title = `PeopleSol - Request IT Category`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />

    </div>
  );
};

export  {ReqCategoryFormIt}
