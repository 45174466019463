import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateMeetingRoomComp } from "../FormComp/CreateMeetingRoomComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateMeetingRoom = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Meeting Room`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const { state } = useLocation();

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [detailsform, setDetailsform] = useState({});

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`MeetingRooms/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequest("MeetingRooms/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("MeetingRooms/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const initialvalue = {
    locationId: "",
    title: "",
    colour: "",
    seatingCapacity: "",
    facilities: "",

    isEnabled: "Yes",
  };
  const validationSchema = Yup.object({
    title: Yup.string()
      .max(149, "Title must be less than 150 characters")
      .required("Please Enter Title"),
    locationId: Yup.string().required("Please Select Location"),
    colour: Yup.string().required("Please Select Colour"),
    seatingCapacity: Yup.string()
      .max(4, "Seating Capacity must be less than a 5 digit number")
      .required("Please Enter seating capacity"),
    facilities: Yup.string()
      .max(299, "Facilities must be less than 300 characters")
      .required("Please Enter facilities"),
    isEnabled: Yup.string().required("Please Select Is Enabled"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: data,
      // locationId: detailsform?.locationId,

      isEnabled: values?.isEnabled === "Yes" ? true : false,
    };

    const create = {
      ...values,
      id: 0,

      isEnabled: values.isEnabled === "Yes" ? true : false,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Meeting Room" : "Create Meeting Room"}
      ButtonLabel={data ? "Update Meeting Room" : "Create Meeting Room"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              title: detailsform?._meetingRooms?.title,
              locationId: detailsform?._meetingRooms?.locationId,
              colour: detailsform?._meetingRooms?.colour,
              facilities: detailsform?._meetingRooms?.facilities,
              seatingCapacity: detailsform?._meetingRooms?.seatingCapacity,

              isEnabled:
                detailsform?._meetingRooms?.isEnabled === true ? "Yes" : "No",
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateMeetingRoomComp data={detailsform} />}
    />
  );
};

export { CreateMeetingRoom };
