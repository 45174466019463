import React, { useEffect, useState } from "react";
import LoginPerson from "../../Assets/LoginPerson.png";
import LoginBack from "../../Assets/LoginBack.png";
import "../Styles.css";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router";
import OtpInput from "react18-input-otp";
import { useDispatch } from "react-redux";
import { postRequest } from "../../Services/axios";
import { isLoading, showToast } from "../../Features";
import axios from "axios";

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const [searchParam, setSearchParam] = useSearchParams();
  // console.log(searchParam.has("email") && searchParam.get("email"));
  // console.log(searchParam.has("email"));
  // useEffect(() => {
  //   setSearchParam({ email: "email" , });
  // }, []);
  useEffect(() => {
    document.title = `PeopleSol - Verify OTP`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const controller = new AbortController();
  const emailParam = location.state.email;
  const idParam = location.state.id;
  const otpParam = otp;

  const validateOtp = async () => {
    setloading(true);
    try {
      const result = await postRequest(
        `SubmitCandidate/ValidateOTP?email=${emailParam}&otp=${otpParam}&ID=${idParam}`
      );

      if (
        result.status === 200 &&
        result.statusText === "OK" &&
        result.data === "Invalid OTP or OTP has expired."
      ) {
        dispatch(
          showToast({
            text: "Invalid OTP or OTP has expired. Please try again or resnd OTP!",
            severity: "error",
          })
        );
        return;
      }
      if (
        result.status === 200 &&
        result.statusText === "OK" &&
        result.data === "OTP validation successful!"
      ) {
        navigate("/newpassword", {
          state: { ...location.state, otp: otp },
        });
        dispatch(
          showToast({
            text: "OTP validation successful!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          showToast({
            text: "Error in OTP validation! Please try again or resnd OTP!",
            severity: "error",
          })
        );
        return;
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setloading(false);
    }
  };

  const reSendOTP = async () => {
    setOtp("");
    dispatch(isLoading(true));
    await axios("SubmitCandidate/Checkemail", {
      params: {
        email: emailParam,
      },
      baseURL: process.env.REACT_APP_.BASE_URL,
      method: "POST",
    })
      .then(({ data }) => {
        dispatch(
          showToast({
            text: "Otp has been sent to your email id",
            severity: "success",
          })
        );
        navigate("/verifyotp", {
          state: { email: emailParam, id: data.otpRecordId },
        });
      })
      .catch((error) => {
        dispatch(
          showToast({
            text: "There was an issue forgotting your password. Please try again later ",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };

  return (
    <div className="landing">
      <img
        src={LoginBack}
        alt="LoginBack"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
      <div className="loginGraphics">
        <img
          src={LoginPerson}
          alt="LoginPerson"
          className="newLoginGraphicImage"
        />
      </div>

      <div className="loginForm">
        <div className="loginFormBox">
          <div className="backtol" onClick={() => navigate("/login")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="8"
              viewBox="0 0 18 8"
              fill="none"
            >
              <path
                d="M16.5762 4.6748C16.8523 4.6748 17.0762 4.45095 17.0762 4.1748C17.0762 3.89866 16.8523 3.6748 16.5762 3.6748V4.6748ZM0.484619 3.82125C0.289358 4.01651 0.289358 4.3331 0.484619 4.52836L3.6666 7.71034C3.86186 7.9056 4.17844 7.9056 4.37371 7.71034C4.56897 7.51508 4.56897 7.19849 4.37371 7.00323L1.54528 4.1748L4.37371 1.34638C4.56897 1.15112 4.56897 0.834533 4.37371 0.639271C4.17844 0.444009 3.86186 0.444009 3.6666 0.639271L0.484619 3.82125ZM16.5762 3.6748L0.838173 3.6748V4.6748L16.5762 4.6748V3.6748Z"
                fill="#F7941D"
              />
            </svg>
            <span>Back to Login</span>
          </div>
          <div className="mainiconf">
            <div style={{ marginTop: "100px" }}>
              <svg
                width="153"
                height="153"
                viewBox="0 0 153 153"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="76.4401" cy="76.8" r="76" fill="#E3FDFC" />
                <path
                  d="M38.0612 66.4842L76.4459 39.6223L114.831 66.4842L76.4459 94.7837L38.0612 66.4842Z"
                  fill="#34B6CF"
                />
                <rect
                  x="52.1346"
                  y="39.6223"
                  width="48.623"
                  height="55.1616"
                  rx="1.77668"
                  fill="#FFDBA6"
                />
                <path
                  d="M38.0612 66.4839L75.5205 89.3392C76.0887 89.6859 76.8031 89.6859 77.3713 89.3392L114.831 66.4839V112.2C114.831 113.182 114.035 113.977 113.054 113.977H39.8378C38.8566 113.977 38.0612 113.182 38.0612 112.2V66.4839Z"
                  fill="#1296B0"
                />
                <path
                  d="M38.9385 113.973C38.4479 113.973 38.0502 113.576 38.0502 113.085L38.0502 108.548L76.446 82.2542L114.828 108.602V113.085C114.828 113.576 114.43 113.973 113.939 113.973H38.9385Z"
                  fill="#34B6CF"
                />
                <line
                  x1="62.4249"
                  y1="49.071"
                  x2="90.4663"
                  y2="49.071"
                  stroke="#F7941D"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <line
                  x1="62.4249"
                  y1="56.6799"
                  x2="90.4663"
                  y2="56.6799"
                  stroke="#F7941D"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <line
                  x1="62.4249"
                  y1="64.2903"
                  x2="76.5165"
                  y2="64.2903"
                  stroke="#F7941D"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="forgheaddiv">
              <p className="forgotHead">Verify Your Email</p>
              <p className="headingTxt">
                To reset your password, please enter the
              </p>
              <p className="headingTxt">6 digit pin sent to your email</p>
            </div>
            <div style={{ width: "100%" }}>
              <Formik initialValues={{ email: "" }}>
                <Form>
                  <div className="forgotForm" style={{ marginTop: "40px" }}>
                    <OtpInput
                      containerStyle={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      className="otpdiv"
                      inputStyle="input-otp"
                      value={otp}
                      onChange={(e) => setOtp(e)}
                      numInputs={6}
                      separator={<span className="otpSeparator">-</span>}
                    />
                    <div className="resendCode">
                      <p className="verifyOTPHeading">
                        If you didn’t receive the code!
                      </p>
                      <p
                        className="inheading"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={reSendOTP}
                      >
                        Resend Code
                      </p>
                      <button
                        className="btnfor primaryButton"
                        onClick={validateOtp}
                        disabled={loading ? true : false}
                      >
                        Verify OTP
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { VerifyOtp };
