import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { TextError } from "./TextError";
import { MyInput } from "./MyInput";
import { useEffect } from "react";

export const InputCustomFormik = (props) => {
  const { name, style, className, disabled, formikWidth, value, borderBlack } = props;
  const [selected, setSelected] = useState(false);

  return (
    <div className="formikComponentContainer" style={{ width: formikWidth }}>
      <Field
        {...props}
        name={name}
        value={value}
        component={MyInput}
        onSelect={() => { setSelected(true) }}
        onBlurCapture={() => { setSelected(false) }}
        style={{ ...style, minWidth: "120px" }}
        className={`${borderBlack && !selected ? "input-shorttext1" : "input-shorttext"} ${className} ${disabled && "disabledInput"
          }`}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
