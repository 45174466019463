import React from "react";

import { AnnouncementsTab } from "./AnnouncementsTab";
import { useEffect } from "react";

const Announcements = () => {
  useEffect(() => {
    document.title = `PeopleSol - Announcements`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <div className="moduleMainContainer">
      <AnnouncementsTab title="Announcements" />
    </div>
  );
};

export { Announcements };
