import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";
export const CreateQuestionSetComp = ({ data }) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Questions Set`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
    </div>
  );
};