import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";


const opt=[
  {label:"select",value:null}
]
const SystemForm = ({ data }) => {
  const [option, setOption] = React.useState([]);
  const getOption = async () => {
    try {
      const result = await getRequest("PermissionSets/GetPermissionSets");
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(()=>{
    document.title = `PeopleSol - System Form`;
    return () => {
      document.title = 'PeopleSol';
    };
  })
  useEffect(() => {
    getOption();
  }, []);
  return (
    <div >
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          type={"text"}
          name="name"
          placeholder={"Enter Name "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Permission Set"} mandatory={true} />
        <SelectForm
         
          values={
            data
              ? option.filter(
                  (options) => options.value === data?.PermissionSetId
                )
              : opt
          }
          options={option}
          name="permissionSetId"
          placeholder={" Select Permission Set"}
        />
      </div>
    </div>
  );
};
export { SystemForm };

