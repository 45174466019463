import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { YesNoFormik } from "../../../../../Components/FormComponent/YesNoFormik";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
const ReasonForVacancyOptions = [
  { label: "Resignation", value: "Resignation" },
  { label: "Termination", value: "Termination" },
  { label: "New Position", value: "New Position" },
  { label: "Other", value: "Other" },
];

function VacancyDetails({ formValues, employeeList }) {
  useEffect(() => {
    document.title = `PeopleSol - Vacancy Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Reason for Vacancy"} mandatory={true} />
          <SelectForm
            name="ReasonForVacancy"
            options={ReasonForVacancyOptions}
          />
        </div>
        {(formValues?.ReasonForVacancy === "Resignation" ||
          formValues?.ReasonForVacancy === "Termination") && (
            <div>
              <LabelCustom labelName={"Resigned/Terminated Employee"} />
              <SelectForm name="ResignatingEmployeeId" options={employeeList} />
            </div>
          )}
        <div>
          <LabelCustom labelName={"Joining Buddy Employee"} />
          <SelectForm name="JoiningBuddy" options={employeeList} />
        </div>
      </div>
      <div className="headingForm">Internal Reference & Assignment</div>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom
            labelName={"Internal Reference Acceptable?"}
            mandatory={true}
          />
          <YesNoFormik name="IntenalArrangementNeeded" />
        </div>
        <div>
          <LabelCustom labelName={"Assessment Required?"} mandatory={true} />
          <YesNoFormik name="IsAssessmentRequired" />
        </div>
      </div>

    </>
  );
}

export { VacancyDetails };
