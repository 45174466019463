import React from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { useEffect } from "react";
import { useState } from "react";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { TimeCustomFormik } from "../../../Components/FormComponent/TimeCustomFormik";

export const CreateEventsComp = ({ formValue }) => {
  const [typeList, setTypeList] = useState([
    { label: "Online", value: "online" },
    { label: "Offline", value: "offline" },
  ]);
  useEffect(() => {
    document.title = `PeopleSol - Create Events`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} mandatory={true} />
        <TextAreaFormik
          maxLength={500}
          name="description"
          placeholder={"Enter Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Start Date"} mandatory={true} />
        <DatePickerFormiks
          name={"startTime"}
          minDate={new Date()}
          placeholder={"Select Start Date"}
        />
      </div>
      <div>
        <LabelCustom labelName={"End Date"} mandatory={true} />
        <DatePickerFormiks
          name={"endTime"}
          minDate={new Date()}
          placeholder={"Select End Date"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Event Start Time"} mandatory={true} />
        <TimeCustomFormik
          name={"eventStartTime"}

          autoComplete="off"
        />
      </div>
      <div>
        <LabelCustom labelName={"Event End Time"} mandatory={true} />
        <TimeCustomFormik
          name={"eventEndTime"}

          autoComplete="off"
        />
      </div>
      <div>
        <LabelCustom labelName={"Type"} mandatory={true} />
        <SelectForm
          name={`type`}
          options={typeList}
          placeholder={"Select Type"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload"} />
        <FileUploadFormik name={`upload`} />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Size: 2 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Format: JPEG, PNG, JPG
        </span>
      </div>
      {formValue?.type === "offline" && (
        <div>
          <LabelCustom labelName={"Venue"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="vanue"
            type={"text"}
            placeholder={"Enter Venue"}
          />
        </div>
      )}
      {formValue?.type === "online" && (
        <div>
          <LabelCustom labelName={"Link"} mandatory={true} />
          <InputCustomFormik
            maxLength={250}
            name="link"
            type={"text"}
            placeholder={"Enter Link"}
          />
        </div>
      )}
    </div>
  );
};
