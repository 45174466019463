import React, { useState, useEffect, useRef } from "react";

import "./DesignPrint.css";
import { format } from "date-fns";
export const CalendarPopup = ({
  loading,
  Loader,
  currentDate,
  filteredData,
}) => {
  // console.log("currentDate", currentDate);
  // console.log("filteredData", filteredData);
  let dateClicked;
  let dayClicked;
  if (currentDate) {
    dateClicked = new Date(currentDate)
      ? format(new Date(currentDate), "dd MMMM, yyyy")
      : "";
    dayClicked = new Date(currentDate)
      ? format(new Date(currentDate), "EEEE")
      : "";
  }
  useEffect(() => {
    document.title = `PeopleSol - Calender Popup`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      {/* <section className="calendarPopupHeading">
          <div className="calendarPopupDate">{dateClicked}</div>
          <div className="calendarPopupDay">{dayClicked}</div>
        </section> */}

      <section
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "Column",
          justifyContent: "start",
          alignItems: "center",
          flexWrap: "nowrap",
          height: "500px",
          overflowY: "scroll",
          paddingTop: "20px",
        }}
      >
        {filteredData?._rooms ? (
          filteredData?._rooms?.map((item) => (
            <>
              <div className="tableHeading">
                <div>{item?.title ? item?.title : "-"}</div>
                <div>
                  {format(new Date(item?.startTime), "hh:mm a")} -{" "}
                  {format(new Date(item?.endTime), "hh:mm a")}
                </div>
              </div>

              <div className="printdiv1cover" style={{ width: "60%" }}>
                <div className="printdiv">
                  <p className="calendarpopupfont printFirstp1">Attendees</p>
                  <b style={{ fontSize: "14px", marginRight: "40px" }}>:</b>
                  <p className="calendarpopupfont">{item?.numberofPersons}</p>
                </div>

                <div className="printdiv">
                  <p className=" calendarpopupfont printFirstp1">Booked By</p>
                  <b style={{ fontSize: "14px", marginRight: "40px" }}>:</b>
                  <p className="calendarpopupfont">
                    {item?.employee?.fullName}
                  </p>
                </div>
                <div className="printdiv">
                  <p className=" calendarpopupfont printFirstp1">Department</p>
                  <b style={{ fontSize: "14px", marginRight: "40px" }}>:</b>
                  <p className="calendarpopupfont">
                    {item?.employee?.department}
                  </p>
                </div>

                {/* <div className="printdiv">
                  <p className="calendarpopupfont printFirstp1">Host Name</p>
                  <b style={{ fontSize: "15px", marginRight: "40px" }}>:</b>
                  <p className="calendarpopupfont">{"varundfhs"}</p>
                </div> */}
              </div>
            </>
          ))
        ) : (
          <>
            <div className="tableHeading">
              <div>{filteredData?.title}</div>
              <div>
                {filteredData?.startTime &&
                  format(new Date(filteredData?.startTime), "hh:mm a")}{" "}
                -{" "}
                {filteredData?.endTime &&
                  format(new Date(filteredData?.endTime), "hh:mm a")}
              </div>
            </div>

            <div className="printdiv1cover" style={{ width: "60%" }}>
              <div className="printdiv">
                <p className="calendarpopupfont printFirstp1">Attendees</p>
                <b style={{ fontSize: "14px", marginRight: "40px" }}>:</b>
                <p className="calendarpopupfont">
                  {filteredData?.numberofPersons}
                </p>
              </div>
              <div className="printdiv">
                <p className=" calendarpopupfont printFirstp1">Booked By</p>
                <b style={{ fontSize: "14px", marginRight: "40px" }}>:</b>
                <p className="calendarpopupfont">
                  {filteredData?.employee?.fullName}
                </p>
              </div>
              <div className="printdiv">
                <p className=" calendarpopupfont printFirstp1">Department</p>
                <b style={{ fontSize: "14px", marginRight: "40px" }}>:</b>
                <p className="calendarpopupfont">
                  {filteredData?.employee?.department}
                </p>
              </div>

              {/* <div className="printdiv">
                  <p className="calendarpopupfont printFirstp1">Host Name</p>
                  <b style={{ fontSize: "14px", marginRight: "40px" }}>:</b>
                  <p className="calendarpopupfont">{"varundfhs"}</p>
                </div> */}
            </div>
          </>
        )}
      </section>
    </>
  );
};
