import React, { useEffect, useState } from "react";
import { TaxRegimeComp } from "../../Tax/DeclarationComp/TaxRegimeComp";
import { useLocation, useNavigate } from "react-router-dom";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { FieldArray, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../Components/FormComponent/Repeated";
import * as Yup from "yup";
import {
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { getRequest, postRequest } from "../../../Services/axios";
const taxRegimeData = [
  {
    id: 2,
    heading: "Old Tax Regime",
    body: "Total Tax: ₹0.00",
  },
  {
    id: 1,
    heading: "New Tax Regime",
    body: "Total Tax: ₹0.00",
  },
];

export const TaxTDSCreate = () => {
  const navigate = useNavigate();
  const clickHandler = (item) => {
    setActive(item);
  };
  const [detailsform, setDetailsform] = useState({});
  const { state, pathname } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);

  const data = state?.id;
  const [active, setActive] = useState(1);

  const getFormDetails = async () => {
    if (!data) return
    try {
      const result = await getRequest(`IncomeTax/Details/${data}`);
      console.log("-------------->", result.data.data)
      setDetailsform(result.data.data);
      setActive(result.data.data.title == "New" ? 1 : 2)
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getInitalAnswerValues = () => {
    let returnArray = [];
    data
      ? detailsform?.taxTypeSlabs?.map((item) => {
        return returnArray.push({
          ctcFrom: item.ctcFrom,
          ctcTo: item.ctcTo,
          taxDeductionPercentage: item.taxDeductionPercentage,
        });
      })
      : (returnArray = [
        { ctcFrom: "", ctcTo: "", taxDeductionPercentage: "" },
      ]);

    return returnArray;
  };
  const getInitaladditionalRangeChargesValues = () => {
    let returnArray = [];
    data
      ? detailsform?.additionalRangeCharges?.map((item) => {
        return returnArray.push({
          name: item.name,
          start: item.start,
          end: item.end,
          taxPercentage: item.taxPercentage,
        });
      })
      : (returnArray = [{ start: "", end: "", taxPercentage: "", name: "" }]);

    return returnArray;
  };
  const returnPage = () => {
    navigate(-1);
  };
  const getInitaladditionalFixChargesValues = () => {
    let returnArray = [];
    data
      ? detailsform?.additionalFixCharges?.map((item) => {
        return returnArray.push({
          name: item.name,

          taxPercentage: item.taxPercentage,
        });
      })
      : (returnArray = [{ name: "", taxPercentage: "" }]);

    return returnArray;
  };
  const postFormData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("IncomeTax/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }

      returnPage();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("IncomeTax/edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }

      returnPage();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  //   const tdsSchema = Yup.object({
  //     standardDeduction: Yup.number()
  //       .required("Please Enter Standard Deduction Amount")
  //       .nullable(),
  //     minimumTaxableAmount: Yup.number().required(
  //       "Please Enter Minimum Taxable Amount "
  //     ),
  //     taxTypeSlabs: Yup.array().of(
  //       Yup.object().shape({
  //         ctcFrom: Yup.number().required("Please Enter ctcFrom"),
  //         ctcTo: Yup.number().required("Please Enter ctcTo"),
  //         taxDeductionPercentage: Yup.number().required(
  //           "Please Enter Tax Deduction Percentage"
  //         ),
  //       })
  //     ),
  //   });
  const initialValue = {
    standardDeduction: data ? detailsform?.standardDeduction : "",
    minimumTaxableAmount: data ? detailsform?.minimumTaxableAmount : "",
    taxTypeSlabs: getInitalAnswerValues(),
    additionalFixCharges: getInitaladditionalFixChargesValues(),
    additionalRangeCharges: getInitaladditionalRangeChargesValues(),
  };
  const onSubmit = async (values) => {
    console.log('values?.taxTypeSlabs[0].ctcFrom!==""', values?.taxTypeSlabs[0].ctcFrom !== "")
    const edit = {
      ...values,
      title: active === 2 ? "Old" : "New",
      standardDeduction: values?.standardDeduction === "" ? 0 : values?.standardDeduction,
      minimumTaxableAmount: values?.minimumTaxableAmount === "" ? 0 : values?.minimumTaxableAmount,
      taxTypeSlabs: values?.taxTypeSlabs[0].ctcFrom !== "" ? values?.taxTypeSlabs : null,
      additionalFixCharges: values?.additionalFixCharges[0].name !== "" ? values?.additionalFixCharges : null,
      additionalRangeCharges: values?.additionalRangeCharges[0].start !== "" ? values?.additionalRangeCharges : null,
      id: data,
    };

    const create = {
      ...values,
      title: active === 2 ? "Old" : "New",
      standardDeduction: values?.standardDeduction === "" ? 0 : values?.standardDeduction,
      minimumTaxableAmount: values?.minimumTaxableAmount === "" ? 0 : values?.minimumTaxableAmount,
      taxTypeSlabs: values?.taxTypeSlabs[0].ctcFrom !== "" ? values?.taxTypeSlabs : null,
      additionalFixCharges: values?.additionalFixCharges[0].name !== "" ? values?.additionalFixCharges : null,
      additionalRangeCharges: values?.additionalRangeCharges[0].start !== "" ? values?.additionalRangeCharges : null,

    };
    data ? Edit(edit) : postFormData(create);
  };
  //   console.log(active);
  useEffect(() => {
    getFormDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);
  return (
    <div className="moduleMainContainer">
      <div className="headingContainer" style={{ padding: "0" }}>
        <div
          onClick={() => navigate(-1)}
          style={{ display: "flex" }}
          className="returnToDashboard cursor"
        >
          <div>
            <svg
              width="20"
              height="8"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                fill="#1296B0"
              />
            </svg>
          </div>
          <label className="returnLabelHolder">{"Add TDS"}</label>
        </div>
        {pathname?.includes?.("details") && (
          <IconButton
            className="returnIconButtonHolder"
            onClick={() =>
              navigate("/taxmaster/tdsconfig/create", {
                state: { id: active },
              })
            }
          >
            {" "}
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.15"
                d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
                fill="#FB7158"
              />
              <path
                d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
                stroke="#FB7158"
                strokeWidth="0.698594"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        )}
      </div>
      <div style={{ background: "var(--form-background)", height: "100%" }}>
        <div className="taxRegimeContainer">
          {
            <>
              {taxRegimeData?.map((item, index) => {
                return (
                  <>
                    <TaxRegimeComp
                      heading={item?.heading}
                      clickHandler={clickHandler}
                      id={item.id}
                      active={item.id === active}
                    />
                  </>
                );
              })}
            </>
          }
        </div>
        <div style={{ padding: "12px", height: "calc(100% - 108px)" }}>
          <div
            style={{ height: "1px" }}
            className="horizontalLine createLine"
          ></div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={tdsSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form
                  style={{
                    overflow: "auto",
                    height: "calc(100% - 135px)",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "60%" }}>
                      <div className="formcustom twoColumn">
                        <div>
                          <LabelCustom
                            labelName={"Standard Deduction (₹)"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            disabled={pathname?.includes?.("details")}
                            name="standardDeduction"
                            placeholder="Enter Standard Deduction Percentage"
                            type="number"
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={"Minimum Taxable Income (₹)"}
                            mandatory={true}
                          />
                          <InputCustomFormik
                            disabled={pathname?.includes?.("details")}
                            name="minimumTaxableAmount"
                            placeholder="Enter Minimum Taxable Income"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "45%",
                        marginTop: "42px",
                        padding: "12px",
                        height: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#FFF4E5",
                          padding: "12px",
                        }}
                      >
                        Note: Minimum Taxable Amount = CTC - Standard Deduction
                        Amount (50,000)
                      </div>
                    </div>
                  </div>
                  <div
                    className="repeatedSectionContainer"
                    style={{ marginTop: "24px" }}
                  >
                    <FieldArray name="taxTypeSlabs">
                      {(fieldArrayProps) => {
                        const { push, remove, form } = fieldArrayProps;
                        const { values } = form;
                        const { taxTypeSlabs } = values;

                        return (
                          <StyledTableCont>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    padding="0"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    S. No
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={` ${active == 1
                                        ? " Old Tax Regime "
                                        : "New Tax Regime "
                                        } Deduction Slabs`}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Deductions %"}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    Add/Remove
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>

                              {taxTypeSlabs?.map((Itemss, index) => (
                                <TableBody key={index}>
                                  {/* 1st column */}
                                  <StyledTableCell align="center">
                                    {index + 1}
                                  </StyledTableCell>
                                  {/* 2nd column */}
                                  <StyledTableCell>
                                    {/* Displaying From CTC and To CTC in a single line separated by a hyphen */}
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <InputCustomFormik
                                        disabled={pathname?.includes?.(
                                          "details"
                                        )}
                                        maxLength={50}
                                        type="text"
                                        name={`taxTypeSlabs[${index}].ctcFrom`}
                                        placeholder={"From CTC"}
                                      />
                                      <span> - </span>
                                      <InputCustomFormik
                                        disabled={pathname?.includes?.(
                                          "details"
                                        )}
                                        name={`taxTypeSlabs[${index}].ctcTo`}
                                        placeholder={"To CTC"}
                                      />
                                    </div>
                                  </StyledTableCell>
                                  {/* 3rd column */}
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      disabled={pathname?.includes?.("details")}
                                      name={`taxTypeSlabs[${index}].taxDeductionPercentage`}
                                      placeholder={
                                        "Select taxDeductionPercentage"
                                      }
                                    />
                                  </StyledTableCell>
                                  {/* 4th column */}
                                  <StyledTableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {taxTypeSlabs.length > 1 && (
                                      <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                          taxTypeSlabs.length > 1
                                            ? remove(index)
                                            : dispatch(
                                              showToast({
                                                text: "At least One Item is Required ",
                                                severity: "error",
                                              })
                                            )
                                        }
                                      >
                                        -
                                      </button>
                                    )}
                                    <button
                                      type="button"
                                      className="add-btn"
                                      onClick={() =>
                                        push({
                                          ctcFrom: "",
                                          ctcTo: "",
                                          taxDeductionPercentage: "",
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </StyledTableCell>
                                </TableBody>
                              ))}
                            </Table>
                          </StyledTableCont>
                        );
                      }}
                    </FieldArray>
                  </div>
                  <div className="repeatedSectionContainer">
                    <LabelCustom
                      style={{ color: "var(--primary)" }}
                      labelName={"Additional Levis on tax value"}
                    />
                    <FieldArray name="additionalFixCharges">
                      {(fieldArrayProps) => {
                        const { push, remove, form } = fieldArrayProps;
                        const { values } = form;
                        const { additionalFixCharges } = values;

                        return (
                          <StyledTableCont>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    padding="0"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    S. No
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Name"}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Tax Percentage"}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    Add/Remove
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>

                              {additionalFixCharges?.map((item, index) => (
                                <TableBody key={index}>
                                  {/* 1st column */}
                                  <StyledTableCell align="center">
                                    {index + 1}
                                  </StyledTableCell>
                                  {/* 2nd column */}
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      disabled={pathname?.includes?.("details")}
                                      maxLength={50}
                                      type="text"
                                      name={`additionalFixCharges[${index}].name`}
                                      placeholder={"Enter Name"}
                                    />
                                  </StyledTableCell>
                                  {/* 3rd column */}
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      disabled={pathname?.includes?.("details")}
                                      name={`additionalFixCharges[${index}].taxPercentage`}
                                      placeholder={"Enter Tax Percentage"}
                                    />
                                  </StyledTableCell>
                                  {/* 4th column */}
                                  <StyledTableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="remove-btn"
                                      onClick={() =>
                                        additionalFixCharges.length > 1
                                          ? remove(index)
                                          : dispatch(
                                            showToast({
                                              text: "At least One Item is Required ",
                                              severity: "error",
                                            })
                                          )
                                      }
                                    >
                                      -
                                    </button>
                                    <button
                                      type="button"
                                      className="add-btn"
                                      onClick={() =>
                                        push({ name: "", taxPercentage: "" })
                                      }
                                    >
                                      +
                                    </button>
                                  </StyledTableCell>
                                </TableBody>
                              ))}
                            </Table>
                          </StyledTableCont>
                        );
                      }}
                    </FieldArray>
                  </div>
                  <div className="repeatedSectionContainer">
                    <LabelCustom
                      style={{ color: "var(--primary)" }}
                      labelName={
                        "Additional Levis on tax value as per taxable income range"
                      }
                    />
                    <FieldArray name="additionalRangeCharges">
                      {(fieldArrayProps) => {
                        const { push, remove, form } = fieldArrayProps;
                        const { values } = form;
                        const { additionalRangeCharges } = values;

                        return (
                          <StyledTableCont>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    padding="0"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    S. No
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Name"}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={"Tax Percentage"}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    <LabelCustom
                                      labelName={` ${active == 1
                                        ? " Old Tax Regime "
                                        : "New Tax Regime "
                                        } Deduction Slabs`}
                                      mandatory={true}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontSize: "12px",
                                    }}
                                    align="center"
                                  >
                                    Add/Remove
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>

                              {additionalRangeCharges?.map((item, index) => (
                                <TableBody key={index}>
                                  {/* 1st column */}
                                  <StyledTableCell align="center">
                                    {index + 1}
                                  </StyledTableCell>
                                  {/* 2nd column */}
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      disabled={pathname?.includes?.("details")}
                                      maxLength={50}
                                      type="text"
                                      name={`additionalRangeCharges[${index}].name`}
                                      placeholder={"Enter Name"}
                                    />
                                  </StyledTableCell>
                                  {/* 3rd column */}
                                  <StyledTableCell>
                                    <InputCustomFormik
                                      disabled={pathname?.includes?.("details")}
                                      name={`additionalRangeCharges[${index}].taxPercentage`}
                                      placeholder={"Enter Tax Percentage"}
                                    />
                                  </StyledTableCell>
                                  {/* 4th column */}
                                  <StyledTableCell>
                                    {/* Displaying From CTC and To CTC in a single line separated by a hyphen */}
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <InputCustomFormik
                                        disabled={pathname?.includes?.(
                                          "details"
                                        )}
                                        maxLength={50}
                                        type="text"
                                        name={`additionalRangeCharges[${index}].start`}
                                        placeholder={"From CTC"}
                                      />
                                      <span> - </span>
                                      <InputCustomFormik
                                        disabled={pathname?.includes?.(
                                          "details"
                                        )}
                                        name={`additionalRangeCharges[${index}].end`}
                                        placeholder={"To CTC"}
                                      />
                                    </div>
                                  </StyledTableCell>
                                  {/* 6th column */}
                                  <StyledTableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="remove-btn"
                                      onClick={() =>
                                        additionalRangeCharges.length > 1
                                          ? remove(index)
                                          : dispatch(
                                            showToast({
                                              text: "At least One Item is Required ",
                                              severity: "error",
                                            })
                                          )
                                      }
                                    >
                                      -
                                    </button>
                                    <button
                                      type="button"
                                      className="add-btn"
                                      onClick={() =>
                                        push({
                                          name: "",
                                          taxPercentage: "",
                                          start: "",
                                          end: "",
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </StyledTableCell>
                                </TableBody>
                              ))}
                            </Table>
                          </StyledTableCont>
                        );
                      }}
                    </FieldArray>
                  </div>
                  {pathname?.includes?.("details") ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        width: "calc(100% - 24px)",
                        bottom: "0px",
                        margin: "0 12px",
                      }}
                    >
                      <button
                        className="button secondaryButton"
                        type="button"
                      //   onClick={() => setEditActive((prev) => !prev)}
                      >
                        Cancel
                      </button>
                      <button
                        className="button primaryButton"
                        type="submit"
                        style={{ bottom: 0, border: "none" }}
                      >
                        {data ? "Update" : "Create"}
                      </button>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
