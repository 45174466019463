import React from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { useEffect } from "react";

export const CreateVehicleComp = () => {
  useEffect(() => {
    document.title = `PeopleSol - Create Vehicle `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
    
      <div>
        <LabelCustom labelName={"Vehicle No."} mandatory={true}  />
        <InputCustomFormik
        
        maxLength={10}
          name="vehicleNo"
          type={"text"}
          placeholder={"Enter Vehicle No."}
        />
        <span style={{paddingLeft:"12px",color:"var(--primary)"}}>Eg: MP04SD0786</span>
      </div>
      <div>
        <LabelCustom labelName={"Type"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="type"
          type={"text"}
          placeholder={"Enter Type"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"}  />
        <TextAreaFormik
        
        maxLength={500}
          name="description"
          placeholder={"Enter Description"}
        />
      </div>
      
    </div>
  );
};
