import React, { useState, useEffect } from "react";
// import Categoriespic from "../../Assets/Categoriespic.svg";
// import Coursespic from "../../Assets/Coursespic.svg";
// import Questionpic from "../../Assets/Questionpic.svg";
// import Chapterspic from "../../Assets/Chapterspic.svg";
import "./LDMaster.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Categories } from "./Components/Categories";
import { Courses } from "./Components/Courses";
import { Questions } from "./Components/Questions";
import { CreateCategories } from "./Components/CreateCategories";
import { CreateCourses } from "./Components/CreateCourses";
import { CreateQuestionSet } from "./Components/CreateQuestionSet";
import { Chapters } from "./Components/Chapters";
import { CreateQuestion } from "./Components/CreateQuestion";
import { CreateChapter } from "./Components/CreateChapter";
import { DetailsCategory } from "./Components/DetailsCategory";
import { DetailsChapter } from "./Components/DetailsChapter";
import { DetailsQuestions } from "./Components/DetailsQuestions";
import { DetailsCourses } from "./Components/DetailsCourses";
import { DetailsQuestionSets } from "./Components/DetailsQuestionSets";
import { GroupBy } from "../../Utilities";
import { useDispatch } from "react-redux";
import { loggedOut } from "../../Features";
import Skeleton from "react-loading-skeleton";
import { getRequest, postRequest } from "../../Services/axios";
import { Skill } from "./Components/Skill";
import { Competency } from "./Components/Competency";
import { CreateSkill } from "./Components/CreateSkill";
import { CreateSkillset } from "./Components/CreateSkillset";
import { CreateCompetency } from "./Components/CreateCompetency";
import { CreateCompetencySet } from "./Components/CreateCompetencySet";
import { DetailsSkill } from "./Components/DetailsSkill";
import { DetailsSkillsets } from "./Components/DetailsSkillsets";
import { DetailsCompetency } from "./Components/DetailsCompetency";
import { DetailsCompetencySets } from "./Components/DetailsCompetencySets";
import Categoriespic from "../../Assets/Categoriespic.svg";
import Coursespic from "../../Assets/Coursespic.svg";
import Questionpic from "../../Assets/Questionpic.svg";
import Chapterspic from "../../Assets/Chapterspic.svg";
import Competencypic from "../../Assets/Competencypic.svg";
import Skillspic from "../../Assets/Skillspic.svg";

const LDMaster = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [subMenuList, setSubMenuList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState("");
  const [activeFeature, setActiveFeature] = useState("");
  const [createActive, setCreateActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempMenu = Object.values(GroupBy(result?.data, "menuName"));
        // console.log("tempMenu-===00", tempMenu);
        tempMenu.map((item) => {
          try {
            if (
              item?.[0]?.menuName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase() !== "ld"
            )
              return null;
            const tempGroup = GroupBy(item, "subMenuName");
            let tempSubMenu = Object.values(tempGroup);
            return setSubMenuList(tempSubMenu);
          } catch (error) {}
        });
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  // console.log("subMenuList-===00", subMenuList);

  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("ldmaster")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "ldmaster");
      if (splitArray?.length > submoduleIndex + 1) {
        if (subMenuList) {
          if (
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              subMenuList
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        if (subMenuList)
          navigate(
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        }
      }
    }
  }, [location.pathname, subMenuList]);
  const getFeatureMenu = (submenu) => {
    if (!subMenuList) return null;
    return subMenuList?.find(
      (obj) =>
        obj?.[0]?.subMenuName
          ?.replaceAll(" ", "")
          ?.replaceAll("&", "")
          ?.toLowerCase() === submenu
    );
  };
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "courses") return Categoriespic;
    else if (subMenuName === "chapters") return Coursespic;
    else if (subMenuName === "question") return Questionpic;
    else if (subMenuName === "lessons") return Chapterspic;
    else if (subMenuName === "skills") return Skillspic;
    else if (subMenuName === "competency") return Competencypic;
    return Categoriespic;
  };

  return (
    <div className="moduleContainer">
      {activeSubmodule === "question" && (
        <>
          {activeFeature === "questions" && (
            <>
              {createActive && <CreateQuestion title={"Create Question"} />}
              {detailsActive && <DetailsQuestions />}
            </>
          )}
          {activeFeature === "questionsets" && (
            <>
              {createActive && (
                <CreateQuestionSet title={"Create Question Set"} />
              )}
              {detailsActive && <DetailsQuestionSets />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "courses" && (
        <>
          {createActive && <CreateCategories title={"Create Course"} />}
          {detailsActive && <DetailsCategory />}
        </>
      )}
      {activeSubmodule === "lessons" && (
        <>
          {createActive && <CreateChapter title={"Create lessons"} />}
          {detailsActive && <DetailsChapter />}
        </>
      )}
      {activeSubmodule === "chapters" && (
        <>
          {createActive && <CreateCourses title={"Create Chapter"} />}
          {detailsActive && <DetailsCourses />}
        </>
      )}
      {activeSubmodule === "skills" && (
        <>
          {activeFeature === "skill" && (
            <>
              {createActive && <CreateSkill title={"Create Skill"} />}
              {detailsActive && <DetailsSkill />}
            </>
          )}
          {activeFeature === "skillset" && (
            <>
              {createActive && <CreateSkillset title={"Create Skill Set"} />}
              {detailsActive && <DetailsSkillsets />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "competency" && (
        <>
          {activeFeature === "competency" && (
            <>
              {createActive && <CreateCompetency title={"Create Competency"} />}
              {detailsActive && <DetailsCompetency />}
            </>
          )}
          {activeFeature === "competencyset" && (
            <>
              {createActive && (
                <CreateCompetencySet title={"Create Competency Set"} />
              )}
              {detailsActive && <DetailsCompetencySets />}
            </>
          )}
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {loading ? (
            <Skeleton height={55} count={5} />
          ) : (
            <>
              {subMenuList &&
                subMenuList
                  ?.slice()
                  ?.filter((x) => x[0].isSubMenuActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a[0].subMenuOrder) -
                      parseFloat(b[0].subMenuOrder)
                  )
                  ?.map((subMenu, index) => {
                    return (
                      <div
                        key={index}
                        className={`menuOptions ${
                          activeSubmodule ===
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "menuOptionsActive"
                        }`}
                        onClick={() => {
                          setActiveSubmodule(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          );
                          navigate(
                            `/ldmaster/${subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }}
                      >
                        <div className="verticalLine"></div>
                        <img
                          src={handleMenuIcon(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          )}
                          alt={subMenu[0]?.subMenuDisplayName}
                        />
                        <label>{subMenu[0]?.subMenuDisplayName}</label>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {loading ? (
          <div className="moduleMainContainer">
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {activeSubmodule === "courses" ? (
              <Categories featureMenu={getFeatureMenu("courses")} />
            ) : null}
            {activeSubmodule === "chapters" ? (
              <Courses featureMenu={getFeatureMenu("chapters")} />
            ) : null}
            {activeSubmodule === "lessons" ? (
              <Chapters featureMenu={getFeatureMenu("lessons")} />
            ) : null}
            {activeSubmodule === "question" ? (
              <Questions featureMenu={getFeatureMenu("question")} />
            ) : null}
            {activeSubmodule === "skills" ? (
              <Skill featureMenu={getFeatureMenu("skills")} />
            ) : null}
            {activeSubmodule === "competency" ? (
              <Competency featureMenu={getFeatureMenu("competency")} />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export { LDMaster };
