import React, { useCallback, useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SocialUserProfileBackBtnIcon from "../../../../../Assets/SocialUserProfileBackBtnIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialUserProfileRoleIcon from "../../../../../Assets/SocialUserProfileRoleIcon.svg";
import SocialUserProfileCompanyExperienceIcon from "../../../../../Assets/SocialUserProfileCompanyExperienceIcon.svg";
import SocialUserProfileDepartmentIcon from "../../../../../Assets/SocialUserProfileDepartmentIcon.svg";
import SocialUserProfileDesignationIcon from "../../../../../Assets/SocialUserProfileDesignationIcon.svg";
import SocialUserProfileLocationIcon from "../../../../../Assets/SocialUserProfileLocationIcon.svg";
import SocialUserProfileOverallExperienceIcon from "../../../../../Assets/SocialUserProfileOverallExperienceIcon.svg";
import SocialPostNotFoundIcon from "../../../../../Assets/SocialPostNotFoundIcon.svg";
import "./UserProfile.css";
import "./Connect.css";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { IconButton } from "@mui/material";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import CreatePost from "./CreatePost";

import { useSelector } from "react-redux";

import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import SharePost from "./SharePost";
import EditSharePost from "./EditSharePost";
import EditMyPost from "./EditMyPost";
import LikeDislike from "./LikeDislike";
import ShareDetails from "./ShareDetails";
import { setSocialData } from "../../../../../Features/SocialSlice";
import ShowViewsList from "./ShowViewsList";
import SharedEmployeeComponent from "./SharedEmployeeComponent";
import UnSharedPostComponent from "./UnSharedPostComponent";
import SharedPostComponent from "./SharedPostComponent";
import NestedSharedPostComponent from "./NestedSharedPostComponent";
import SocialLikeAndDislikeComponent from "./SocialLikeAndDislikeComponent";
import SocialPostDetails from "./SocialPostDetails";

function UserProfile({ unreadPostCount }) {
  const [viewsPostId, setViewsPostId] = useState(0);
  const [viewsListActive, setViewsListActive] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const { userid } = useParams();
  const [dataFound, setDataFound] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const { socialData } = useSelector((state) => state.social);
  const [isSocialPostDeletePopupActive, setIsSocialPostDeletePopupActive] =
    useState(false);
  const [editDeleteSocialPostId, setEditDeleteSocialPostId] = useState(0);
  const [editDeleteSocialPostData, setEditDeleteSocialPostData] = useState();
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });
  const [shareDetailsActive, setShareDetailsActive] = useState(false);

  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [isSharePosEditActive, setIsSharePosEditActive] = useState(false);
  const [isMyPosEditActive, setIsMyPosEditActive] = useState(false);

  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const dispatch = useDispatch();

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".socialUserProfileContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".socialUserProfileContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && socialData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, socialData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".socialUserProfileContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || socialData?.length < postDataCount)
    ) {
      getData();
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset]);

  useEffect(() => {
    setOffset(0);
  }, []);

  const deleteSocialPostById = async () => {
    try {
      const result = await getRequest(
        `SocialPost/Delete/?Id=${editDeleteSocialPostId}`
      );
      getData(true);
      setIsSocialPostDeletePopupActive(false);
      setEditDeleteSocialPostId(0);
      dispatch(
        showToast({
          text: "Post Deleted Successfully",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in deleting post",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getData = async (freshLoad = false, reload = false) => {
    setLoading(true);
    try {
      const result = await postRequest(`SocialPost/EmployeePostList`, {
        createdOn: null,
        employeeId: Number(userid),
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: reload
            ? socialData?.length
            : postDataCount === -1 || offset + 2 <= postDataCount || freshLoad
            ? 2
            : postDataCount - offset,
          iDisplayStart: freshLoad || reload ? 0 : offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        setSocialData([]);
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });
        dispatch(setSocialData(listData));
        setPostDataCount(result?.data?.summary?.allCount);
        setTotalOffset(offset);
        if (freshLoad) {
          document.querySelector(
            ".homeEngageMainCenterContainer"
          ).scrollTop = 0;
          dispatch(setSocialData(listData));
          setOffset(0);
          setTotalOffset(0);
        } else {
          dispatch(setSocialData([...socialData, ...listData]));
        }
        setCurrentIndex((currentIndex) => currentIndex + 2);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getEmployeeData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(
        `SocialPost/EmployeeProfile?EmployeeId=${userid}`
      );
      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);
  useEffect(() => {
    return () => dispatch(setSocialData([]));
  }, []);

  return (
    <>
      <div className="socialUserProfileContainer">
        <div className="socialUserProfileHeaderContainer">
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={SocialUserProfileBackBtnIcon} alt="" />
          </IconButton>
          <span style={{ marginLeft: "7px" }}>Profile</span>
        </div>
        <div className="socialUserProfileWrapper">
          <div className="socialUserProfileBodyContainer">
            <div className="socialUserProfileBodyImgContainer">
              <div className="socialUserProfileBodyImgWrapper">
                <div className="socialAvatarBadge">
                  {bannerList?.CompleteSelfProfilePercentage}%
                  {/* <img
                    src={SocialUserProfileInfoIcon}
                    alt="Info"
                    className="infoIcon"
                  /> */}
                </div>
                {/* <div>
                <img src={SocialUserProfileRoundBadgeIcon} alt="" className="roundBadgeIcon" />
                </div> */}
                <div className="avatarContainer">
                  <CircularProgressbarWithChildren
                    value={bannerList?.CompleteSelfProfilePercentage}
                    text={`${bannerList?.CompleteSelfProfilePercentage}%`}
                    strokeWidth={5}
                    styles={buildStyles({
                      textColor: "transparent",
                      pathColor: "#1296B0",
                      trailColor: "#D8F0F2",
                      rotation: 0.45,
                      strokeLinecap: "round",
                    })}
                  >
                    <img
                      src={
                        bannerList?.Image
                          ? process.env.REACT_APP_.BASE_URL +
                            profileImages +
                            bannerList?.Image
                          : ProfileAvatar
                      }
                      onError={(e) => {
                        e.target.src = ProfileAvatar;
                      }}
                      alt={"Profile"}
                      className="imageInsideProgressBar"
                    />
                  </CircularProgressbarWithChildren>
                </div>
              </div>
              <div className="socialUserProfileBodyTextWrapper">
                <h5>Hey,</h5>
                <h5>{bannerList?.Name}</h5>
                <p>{bannerList?.Role}</p>
              </div>
              <div className="socialUserProfileBodyBadgeWrapper">
                {/* <img src={SocialUserProfileBadgeIcon} alt="" />
                <p>Star Performer of the Month</p> */}
              </div>
            </div>
            <div className="socialUserProfileBodyRoleContainer">
              <div className="socialUserProfileBodyRoleWrapper">
                <div className="role">
                  <img src={SocialUserProfileRoleIcon} alt="" />
                  <h6></h6>
                  <div>
                    <p>Role</p>
                    <span>{bannerList?.Role}</span>
                  </div>
                </div>
                <div className="designation">
                  <img src={SocialUserProfileDesignationIcon} alt="" />
                  <h6></h6>
                  <div>
                    <p>Designation</p>
                    <span>{bannerList?.Designations}</span>
                  </div>
                </div>
                <div className="department">
                  <img src={SocialUserProfileDepartmentIcon} alt="" />
                  <h6></h6>
                  <div>
                    <p>Department</p>
                    <span>{bannerList?.Department}</span>
                  </div>
                </div>
              </div>
              <div className="socialUserProfileBodyRoleWrapper">
                <div className="companyExp">
                  <img src={SocialUserProfileCompanyExperienceIcon} alt="" />
                  <h6></h6>
                  <div>
                    <p>Company Experience</p>
                    <span>{bannerList?.CompanyExperience}</span>
                  </div>
                </div>
                <div className="overallExp">
                  <img src={SocialUserProfileOverallExperienceIcon} alt="" />
                  <h6></h6>
                  <div>
                    <p>Overall Experience</p>
                    <span>{bannerList?.OverallExperience}</span>
                  </div>
                </div>
                <div className="location">
                  <img src={SocialUserProfileLocationIcon} alt="" />
                  <h6></h6>
                  <div>
                    <p>Location</p>
                    <span>{bannerList?.Location}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="socialUserProfileBodyActivityContainer">
            <div className="socialUserProfileBodyActivityWrapper">
              <p>Activity</p>
              {LoggedInUser?.id === Number(userid) && (
                <button
                  className="button"
                  onClick={() => {
                    // setCreatePostActive(true);
                    navigate("post/create");
                  }}
                >
                  Create a Post
                </button>
              )}
            </div>
            <div className="homeEngagePostContainer">
              {socialData?.map((d, i) => {
                return (
                  <div
                    className="socialAllPostContainer"
                    key={"social-item-" + i}
                  >
                    <div className="engagePostContentHolder">
                      {d?.sharedEmployee !== null && (
                        <SharedEmployeeComponent
                          d={d}
                          getData={getData}
                          setIsSocialPostDeletePopupActive={
                            setIsSocialPostDeletePopupActive
                          }
                          setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                          setIsSharePosEditActive={setIsSharePosEditActive}
                          setIsMyPosEditActive={setIsMyPosEditActive}
                          setViewsPostId={setViewsPostId}
                          setViewsListActive={setViewsListActive}
                          setEditDeleteSocialPostData={
                            setEditDeleteSocialPostData
                          }
                        />
                      )}

                      {d?.sharedPost === null && (
                        <UnSharedPostComponent
                          d={d}
                          getData={getData}
                          setIsSocialPostDeletePopupActive={
                            setIsSocialPostDeletePopupActive
                          }
                          setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                          setIsSharePosEditActive={setIsSharePosEditActive}
                          setIsMyPosEditActive={setIsMyPosEditActive}
                          setViewsPostId={setViewsPostId}
                          setViewsListActive={setViewsListActive}
                          setEditDeleteSocialPostData={
                            setEditDeleteSocialPostData
                          }
                        />
                      )}
                      {d?.sharedPost && !d?.isNasted && (
                        <SharedPostComponent
                          d={d}
                          getData={getData}
                          setIsSocialPostDeletePopupActive={
                            setIsSocialPostDeletePopupActive
                          }
                          setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                          setIsSharePosEditActive={setIsSharePosEditActive}
                          setIsMyPosEditActive={setIsMyPosEditActive}
                          setViewsPostId={setViewsPostId}
                          setViewsListActive={setViewsListActive}
                          setEditDeleteSocialPostData={
                            setEditDeleteSocialPostData
                          }
                        />
                      )}
                      {d?.sharedPost && d?.isNasted && (
                        <NestedSharedPostComponent d={d} />
                      )}
                      <SocialLikeAndDislikeComponent
                        d={d}
                        setSharePostData={setSharePostData}
                        setSharePostId={setSharePostId}
                        setLikeId={setLikeId}
                        setTotalLikesWatching={setTotalLikesWatching}
                        setDislikeId={setDislikeId}
                        setTotalDisLikesWatching={setTotalDisLikesWatching}
                        setShareDetailsActive={setShareDetailsActive}
                        postData={socialData}
                        setPostData={setSocialData}
                        getData={getData}
                      />
                    </div>
                  </div>
                );
              })}
              {!dataFound && socialData?.length <= 0 && (
                <div className="noPostfoundContainer">
                  <div className="noPostfoundWrapper">
                    <img src={SocialPostNotFoundIcon} alt="" />
                    <h5>No Post Found</h5>
                    <p>No one has posted something in social</p>
                  </div>
                </div>
              )}
            </div>
            {loading && <Loader />}
          </div>
        </div>
      </div>

      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}

      <Routes>
        <Route
          path="/post/create"
          element={
            <CreatePost
              getData={getData}
              unreadPostCount={unreadPostCount}
              // getInitialData={getInitialData}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/post/share/:postid"
          element={
            <SharePost
              sharePostData={sharePostData}
              sharePostId={sharePostId}
              getData={getData}
              unreadPostCount={unreadPostCount}
            />
          }
        />
      </Routes>
      {isSocialPostDeletePopupActive && (
        <Popup
          firstClassName="deletePostPopupBox1"
          secondClassName="deletePostPopupBox2"
          popupHeading={`Delete Post`}
          content={
            <>
              <div className="socialPostDeleteBody">
                <p>Are you sure want to Permanently remove this Post?</p>
              </div>
              <div className="socialPostDeleteBtn">
                <button onClick={deleteSocialPostById}>Delete</button>
                <button
                  onClick={() => {
                    setIsSocialPostDeletePopupActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setIsSocialPostDeletePopupActive(false);
          }}
        />
      )}
      {isSharePosEditActive && (
        <EditSharePost
          editDeleteSocialPostData={editDeleteSocialPostData}
          editDeleteSocialPostId={editDeleteSocialPostId}
          getData={getData}
          setIsSharePosEditActive={setIsSharePosEditActive}
        />
      )}
      {isMyPosEditActive && (
        <EditMyPost
          editDeleteSocialPostData={editDeleteSocialPostData}
          editDeleteSocialPostId={editDeleteSocialPostId}
          setEditDeleteSocialPostData={setEditDeleteSocialPostData}
          getData={getData}
          setIsMyPosEditActive={setIsMyPosEditActive}
        />
      )}
      <Routes>
        <Route path="details/:postid" element={<SocialPostDetails />} />
      </Routes>
      {shareDetailsActive && (
        <ShareDetails
          setShareDetailsActive={setShareDetailsActive}
          sharePostId={sharePostId}
          setSharePostId={setSharePostId}
        />
      )}
      {viewsListActive && viewsPostId > 0 && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"Social"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
    </>
  );
}

export default UserProfile;
