import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
// import "./ReportsandKPI.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TTProjects } from "../../HRMS/Components/TTProjects";
import { PoliciesTab } from "./PoliciesTab";
import { OnboardingKPI } from "./ReportsAndKPIComponents/OnboardingKPI";
import { OrgKPI } from "./ReportsAndKPIComponents/OrgKPI";
import { TimeKPI } from "./ReportsAndKPIComponents/TimeKPI";
import { ProjectTaskKPI } from "./ReportsAndKPIComponents/ProjectTaskKPI";
import { HRDeskKPI } from "./ReportsAndKPIComponents/HRDeskKPI";
import { BenefitsKPI } from "./ReportsAndKPIComponents/BenefitsKPI";
import { TransferKPI } from "./ReportsAndKPIComponents/TransferKPI";
import { SeparationKPI } from "./ReportsAndKPIComponents/SeparationKPI";
import { useSelector } from "react-redux";

const ReportsandKPI = () => {
  const [tabIndex, setTabIndex] = useState("manpowerplanning");
  const handleTabChange = (event, newTabIndex) => {
    navigate(`reportskpi/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [featureMenu, setFeatureMenu] = useState(null);
  const { featureMenuList } = useSelector((state) => state.menu);
  useEffect(() => {
    if (featureMenuList) {
      featureMenuList.map((menu) => {
        if (
          menu[0]?.subMenuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "reportskpi" &&
          menu[0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "hrms"
        )
          setFeatureMenu(menu);
        return null;
      });
    }
  }, [featureMenuList]);

  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("hrms/reportskpi")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "reportskpi");
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `reportskpi/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `reportskpi/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, featureMenu]);
 
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex ===
                            item?.featureName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "tabActive"
                        }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "onboardingkpi" && (
        <>
          <OnboardingKPI title={"Onboarding KPI"} />
        </>
      )}
      {tabIndex === "orgkpi" && (
        <>
          <OrgKPI title={"Org KPI"} />
        </>
      )}
      {tabIndex === "timekpi" && (
        <>
          <TimeKPI title={"Time KPI"} />
        </>
      )}
      {tabIndex === "projecttaskkpi" && (
        <>
          <ProjectTaskKPI title={"Project & Task KPI"} />
        </>
      )}
      {tabIndex === "hrdeskkpi" && (
        <>
          <HRDeskKPI title={"HR Desk KPI"} />
        </>
      )}
      {tabIndex === "benefitskpi" && (
        <>
          <BenefitsKPI title={"Benefits KPI"} />
        </>
      )}
      {tabIndex === "transferkpi" && (
        <>
          <TransferKPI title={"Transfer KPI"} />
        </>
      )}
      {tabIndex === "separationkpi" && (
        <>
          <SeparationKPI title={"Separation KPI"} />
        </>
      )}
    </div>
  );
};

export { ReportsandKPI };
