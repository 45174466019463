import React from "react";
import { useEffect } from "react";

export const SlabOldTax = () => {
  useEffect(() => {
    document.title = `PeopleSol -  Old Slab Tax`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div style={{ background: "white", padding: "12px" }}>
      <div className="ratingCar" style={{ fontSize: "14px" }}>
        <div className="starholderpmself">
          <div style={{ flex: "1" }}>₹0 - ₹2,50,000</div>
          <label
            className="jobDesignation"
            style={{ fontSize: "15px", fontWeight: "500" }}
          >
            <b> NIL</b>
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ flex: "1" }}>₹2,50,000 - ₹5,00,000</div>
          <label
            className="jobDesignation"
            style={{ fontSize: "15px", fontWeight: "500" }}
          >
            <b> 5% </b>
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ flex: "1" }}>₹5,00,000 - ₹10,00,000</div>
          <label
            className="jobDesignation"
            style={{ fontSize: "15px", fontWeight: "500" }}
          >
            <b> 20%</b>
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ flex: "1" }}>Above ₹10,00,000 </div>
          <label
            className="jobDesignation"
            style={{ fontSize: "15px", fontWeight: "500" }}
          >
            <b>30%</b>
          </label>
        </div>
      </div>
    </div>
  );
};
