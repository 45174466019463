import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCustomerComp } from "./CreateCustomerComp";
import { useEffect } from "react";

const CreateCustomer = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const getDetails = async () => {
    try {
      const result = await getRequest(`Customer/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Customer/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Customer`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("Customer/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const initialValue = {
    customerId: 0,
    customerName: data ? detail?.customerName : "",
    BillingCurrency: data ? detail?.billingCurrency : "",
    companyName: data ? detail?.companyName : "",
    firstName: data ? detail?.firstName : "",
    lastName: data ? detail?.lastName : "",
    emailID: data ? detail?.emailID : "",
    mobileNo: data ? detail?.mobileNo : "",
    street: data ? detail?.street : "",
    city: data ? detail?.city : "",
    state: data ? detail?.state : "",
    zip: data ? detail?.zip : "",
    country: data ? detail?.country : "",
    companyType: data ? detail?.companyType : null,
    companySize: data ? detail?.companySize : "",
    taxID: data ? detail?.taxID : null,
    tagName: data ? detail?.tagName : null,
    description: data ? detail?.description : null,
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object({
    customerName: Yup.string().max(149," Name must be less than 150 characters").required("Please Enter Customer Name"),
    BillingCurrency: Yup.string().required("Please Select Billing Currency"),
    companyName: Yup.string().max(149,"Company  Name must be less than 150 characters").required("Please Enter Company Name"),
    firstName: Yup.string().max(149,"First  Name must be less than 150 characters").required("Please Enter First Name"),
    lastName: Yup.string().max(149,"Last  Name must be less than 150 characters").required("Please Enter Last Name"),
    emailID: Yup.string()
      .email("Enter a valid Email Id")
      .required("Please Enter Email Id"),
    mobileNo: Yup.string().min(5)
      .max(15, "Enter valid Mobile Number")
      .matches(phoneRegExp, "Enter valid Mobile Number")
      .required("Please Enter Mobile Number"),
    street: Yup.string().max(149,"Street must be less than 150 characters").required("Please Enter Street"),
    city: Yup.string().max(149,"City must be less than 150 characters").required("Please Enter City"),
    state: Yup.string().max(50,"State must be less than 50 characters").required("Please Enter State"),
    zip: Yup.string().min(6).max(6,"Enter valid ZIP/PIN").required("Please Enter ZIP/PIN"),
    country: Yup.string().required("Please Select Country"),
    tagName: Yup.string().max(149,"Tag  Name must be less than 150 characters").nullable(),
    companyType: Yup.string().max(149,"Company Type must be less than 150 characters").nullable(),
    description: Yup.string().max(499,"Description must be less than 500 characters").nullable(),
  });

  const onSubmit = (values) => {
    const payload = {
      ...values,
      customerId: data ? data : 0,
      companySize:String(values?.companySize),
      mobileNo: String(values?.mobileNo),
      zip: String(values?.zip),
    };
    data ? Edit(payload) : createPageData(payload);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/hrmsmaster/projecttasks/customer");
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Customer" : "Create Customer"}
      ButtonLabel={data ? "Update Customer" : "Create Customer"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialValue}
      returnPage={returnPage}
      propsDiv={<CreateCustomerComp data={data} />}
    />
  );
};

export { CreateCustomer };
