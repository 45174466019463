/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import Nodata from "../../Assets/Nodata.svg";
import "react-confirm-alert/src/react-confirm-alert.css";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { isLoading } from "../../Features";
import { postRequest } from "../../Services/axios";
import { Loader } from "../../Components/FormComponent/Loader";
import { getStatusColor } from "../../Utilities/getStatusColor";
import { SelectCustom } from "../../Components/FormComponent/SelectCustom";

const TeamAppraisal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const [loading, setLoading] = useState(false);
  const [tbodyData, setTbodyData] = useState([]);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  //   const [isRestricted, setIsRestricted] = useState(false);
  const [reqData, setReqData] = useState({
    cycleStartDate: null,
    cycleEndDate: null,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  const { LoggedInUser } = useSelector((state) => state.auth);
  useEffect(() => {
    document.title = `PeopleSol - All Review `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `PMSAppraisalNew/TeamAppraisalList`,
        reqData
      );
      if (result?.data) {
        // if (
        //   LoggedInUser?.isHRHead ||
        //   LoggedInUser?.isHRDirector ||
        //   LoggedInUser?.hrbpDepartmentIds?.length ||
        //   LoggedInUser?.hrbpLocationIds?.length ||
        //   LoggedInUser?.teamnames?.includes("PMS")
        // ) {
        let tempData = [];
        result.data.data.map((item) => {
          return tempData.push({
            ...item.item,
            actions: item?.actions,
            status: item.status,
          });
        });
        const clippedArray = tempData.map(
          ({
            createdBy,
            department,
            designation,
            email,
            employeeId,
            toLocation,
            toLocationName,
            ...rest
          }) => ({ ...rest })
        );
        const finalArray = clippedArray.map(
          ({
            id,
            appraisalCriteriaName,
            name,
            numberOfEmployee,
            ratingSlabName,
            processStartDate,
            processEndDate,
            actions,
            createdOn,
            lastUpdatedOn,
            ...rest
          }) => ({
            id,
            appraisalCriteriaName,
            name,
            numberOfEmployee,
            ratingSlabName,
            processStartDate,
            processEndDate,
            actions,
            createdOn,
            lastUpdatedOn,
            ...rest,
          })
        );
        setTbodyData(finalArray);
        setCount(result.data.summary.allCount);
        calculateTotalPages(result.data.summary.allCount);
        // } else {
        //   setTbodyData(false);
        //   setCount(0);
        // //   setIsRestricted(true)
        // }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("appraisal/teamappraisal"))
      getListData();
  }, [location.pathname, reqData]);
  console.log("data tbodyData", tbodyData);
  if (loading) return <Loader />;
  else
    return (
      <div
        className="fullTableContainer"
        style={{ width: `${props.width}`, height: `${props.height}` }}
      >
        <div className="boxViewContainer ">
          <div className="homeCardsHolderContainer">
            <div className="homeDashboardCardHolder" key={1}>
              {tbodyData?.length ? (
                tbodyData?.map((item, index) => {
                  return (
                    <div className="dashboardCard internalJobCard">
                      <div
                        className="cardContainer cursor"
                        style={{ minHeight: "187px" }}
                      >
                        <div className="headingContainer">
                          <h3
                            style={{
                              fontSize: "16px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item?.name}
                          </h3>
                          <span
                            className="internalJobDateTime"
                            style={{ color: "var(--secondary)" }}
                          >
                            {item.status && (
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    item.status
                                  )?.split(",")?.[0],
                                  color: getStatusColor(item.status)?.split(
                                    ","
                                  )?.[1],
                                  cursor: "default",
                                  padding: "3px 6px",
                                  width: "fit-content",
                                  borderRadius: "6px",
                                }}
                              >
                                {item.status}
                              </div>
                            )}
                          </span>
                        </div>
                        <div
                          className="jobDetailContainerHolder"
                          style={{ padding: "7px 0px 23px 11px" }}
                        >
                          <div className="jobDetailContainer">
                            <div className="verticalLine"></div>
                            <label className="jobDesignation">
                              Process Start Date
                            </label>
                            <label>
                              {item?.processStartDate &&
                                format(
                                  new Date(item?.processStartDate),
                                  "dd/MM/yyyy "
                                )}
                            </label>
                          </div>
                          <div className="jobDetailContainer">
                            <div className="verticalLine"></div>
                            <label className="jobDesignation">
                              Process End Date
                            </label>
                            <label>
                              {item?.processEndDate &&
                                format(
                                  new Date(item?.processEndDate),
                                  "dd/MM/yyyy "
                                )}
                            </label>
                          </div>
                        </div>
                        <div className="applyJobButtonContainer">
                          <div>
                            {/* <button
                            onClick={() => {}}
                            className="button primaryButton"
                            style={{
                              backgroundColor: "white",
                              color: "red",
                              boxShadow:
                                "inset 0px 1px 10px rgba(0, 0, 0, 0.05)",
                              borderRadius: "3px",
                            }}
                          >
                            Cancel
                          </button> */}

                            {item?.actions?.length > 0 && (
                              <button
                                onClick={() => {
                                  if (
                                    item?.actions[0]?.type ===
                                    "Submit Appraisal"
                                  ) {
                                    navigate(
                                      `/pms/appraisal/teamappraisal/submitappraisal/${item?.id}`,
                                      {
                                        state: { item },
                                      }
                                    );
                                  }
                                }}
                                className="button primaryButton"
                              >
                                {item?.actions[0]?.type}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noDatastyle">
                  <img
                    src={Nodata}
                    alt="No data"
                    style={{ height: "150px", width: "250px" }}
                  />
                  <h4>"No Data Found"</h4>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{count}</span>
          <SelectCustom
            searchable={false}
            options={noOfRecords}
            dropdownPosition="top"
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </div>
    );
};
export { TeamAppraisal };
