import React from "react";
import { useEffect } from "react";

const PresentCardComp = ({ time, title, date, source }) => {
  // useEffect(() => {
  //   document.title = `PeopleSol - Present Card Comp`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // }, []);

  return (
    <div className="attendanceCardContentContainer">
      <div
        className="dateContainer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          className={`regularisedIconContainerDashboard ${
            source?.includes("Regular") ? null : "rIconClass"
          }`}
        >
          {source?.includes("Regular") ? "R" : null}
        </div>
        <div className="cardDateDisplayRegularise">{date ? date : ""}</div>
      </div>

      <div
        className="attendanceCardPresentCell"
        style={{
          background: "#D8F0F2",
          borderLeft: "2px solid var(--primary)",
        }}
      >
        <label
          className="attendanceIn"
          style={{ fontSize: "8px", paddingLeft: "2px" }}
        >
          {time ? time[0] : "--"}
        </label>
      </div>

      <div
        className="attendanceCardPresentCell"
        style={{
          background: "#FDEAD2",
          borderLeft: "2px solid var(--secondary)",
        }}
      >
        <label
          className="attendanceOut"
          style={{ fontSize: "8px", paddingLeft: "2px" }}
        >
          {time ? time[1] : "--"}
        </label>
      </div>
    </div>
  );
};

export { PresentCardComp };
