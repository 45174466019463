import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MyTaxDashboard } from "../MyDashBoardComp/MyTaxDashboard";
// import { HomePayroll } from "./DashboardComp/HomePayroll";
// import Deduction from "./DashboardComp/Deduction";

const MyDashboard = () => {
 
  const handleTabChange = (event, newTabIndex) => {
    navigate(`mydashboard/${newTabIndex}`);
  };

  // useEffect(() => {
  //   document.title = `PeopleSol -  Dashboard Services`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const [featureMenu, setFeatureMenu] = useState(null);
  const { featureMenuList } = useSelector((state) => state.menu);
  useEffect(() => {
    if (featureMenuList) {
      featureMenuList.map((menu) => {
        if (
          menu[0]?.subMenuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "mydashboard" &&
          menu[0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "tax"
        )
          setFeatureMenu(menu);
        return null;
      });
    }
  }, [featureMenuList]);


  return (
    <div className="moduleMainContainer">
      
      { <MyTaxDashboard />}
      {/* {tabIndex === "deduction" && <Deduction />} */}
    </div>
  );
};

export { MyDashboard };
