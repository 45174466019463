import React from "react";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";

const EditSubmissionComp = ({
  formFields,
  detailsform,
  setDeclareActive,
  getListData,
  formValues,
}) => {
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  useEffect(() => {
    document.title = `PeopleSol -  Edit Submission`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  function compareValues(formFields, details) {
    const matchingValues = {};

    formFields.forEach((field) => {
      if (field.compName && field.body) {
        const compName = field.compName;
        if (!matchingValues[compName]) {
          matchingValues[compName] = [];
        }
        field.body.forEach((item) => {
          const value = details[item?.value];
          if (value !== null && value !== undefined) {
            matchingValues[compName].push({
              label: item.label,
              value: value,
            });
          }
        });
      }
    });
    return matchingValues;
  }
  return (
    <>
      {formValues?.HouseRentPaid && (
        <div
          className="particularDetailsViewer"
          style={{ borderBottom: "1px solid #e6e6e6" }}
        >
          <div className="detailsHeadingViewer">
            <label style={{ fontWeight: "500", paddingRight: "8px" }}>
              House Rent Details
            </label>
          </div>

          <>
            <div className="detailsBodyViewer" style={{ padding: "0" }}>
              <label style={{ width: "49%", flex: "4", paddingRight: "8px" }}>
                House Rent Amount
              </label>
              <label
                style={{
                  width: "12%",
                  lineHeight: "16px",
                  paddingBottom: "8px",
                  flex: "2",
                }}
              >
                {detailsform?.houseRentPaid}
              </label>
              <label
                style={{
                  width: "14%",
                  flex: "2",
                  alignItems: "center",
                }}
              >
                <FileUploadFormik name={`fileUploadHouseRentPaid`} />
                <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                  Size: 2 MB
                </span>
                <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                  Format: JPG, PNG, PDF , Doc ,Docx
                </span>
              </label>
              <label style={{ flex: "2" }}>
                <InputCustomFormik
                  // value= {formValues?.HouseRentPaid}
                  placeholder={`Enter Actual Amount`}
                  name={`HouseRentPaid`}
                />
              </label>
            </div>
          </>
        </div>
      )}
      {formValues?.HouseLoanInterest && (
        <div
          className="particularDetailsViewer"
          style={{ borderBottom: "1px solid #e6e6e6" }}
        >
          <div className="detailsHeadingViewer">
            <label style={{ fontWeight: "500", paddingRight: "8px" }}>
              House Loan Details
            </label>
          </div>

          <>
            <div className="detailsBodyViewer" style={{ padding: "0" }}>
              <label style={{ width: "49%", flex: "4", paddingRight: "8px" }}>
                Interest paid on Home Loan
              </label>
              <label
                style={{
                  width: "12%",
                  lineHeight: "16px",
                  paddingBottom: "8px",
                  flex: "2",
                }}
              >
                {detailsform?.houseLoanInterest}
              </label>
              <label
                style={{
                  width: "14%",
                  flex: "2",
                  alignItems: "center",
                }}
              >
                {""}
              </label>
              <label style={{ flex: "2" }}>
                <InputCustomFormik
                  // value= {formValues?.HouseRentPaid}
                  placeholder={`Enter Actual Amount`}
                  name={`HouseLoanInterest`}
                />
              </label>
            </div>
          </>
        </div>
      )}
      {formFields?.map((x) => {
        const matchingValues = compareValues(formFields, detailsform);
        const compName = x.compName;
        const values = matchingValues[compName];
        if (values && values.length > 0) {
          return (
            <div
              className="particularDetailsViewer"
              style={{ borderBottom: "1px solid #e6e6e6" }}
            >
              <div className="detailsHeadingViewer">
                <label style={{ fontWeight: "500", paddingRight: "8px" }}>
                  {x?.heading}
                </label>
              </div>
              {x?.body?.map((item) => {
                const matchingValue = values.find(
                  (value) => value.label === item.label
                );
                if (matchingValue) {
                  return (
                    <div className="detailsBodyViewer" style={{ padding: "0" }}>
                      <label
                        style={{ width: "49%", flex: "4", paddingRight: "8px" }}
                      >
                        {item.label}
                      </label>
                      <label
                        style={{
                          width: "12%",
                          lineHeight: "16px",
                          paddingBottom: "8px",
                          flex: "2",
                        }}
                      >
                        ₹{matchingValue?.value}
                      </label>
                      <label
                        style={{
                          width: "14%",
                          flex: "2",
                          alignItems: "center",
                        }}
                      >
                        {item.fileUpload && (
                          <FileUploadFormik name={`${item.fileUpload}`} />
                        )}
                      </label>
                      <label style={{ flex: "2" }}>
                        <InputCustomFormik
                          // value={matchingValue?.value}
                          placeholder={`Enter Actual Amount`}
                          name={
                            item?.value === "leaveTravelAssisatant"
                              ? item?.value
                              : capitalizeFirstLetter(item?.value)
                          }
                        />
                      </label>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <LabelCustom labelName={"Total Amount"} />
                <LabelCustom labelName={":"} />
                <LabelCustom
                  labelName={
                    <span
                      style={{ color: "var(--primary)", fontWeight: "500" }}
                    >
                      {matchingValues[compName]
                        ?.reduce((sum, card) => sum + Number(card.value), 0)
                        .toFixed(2)}
                    </span>
                  }
                />
              </div> */}
            </div>
          );
        } else {
          return null;
        }
      })}
      <div
        className="computeTaxContainer"
        style={{ justifyContent: "flex-end" }}
      >
        <div
          // onClick={returnPage}
          className="button secondaryButton"
          style={{
            color: "red",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
          }}
          onClick={() => {
            setDeclareActive(0)
            getListData();
          }}
        >
          Cancel
        </div>
        <button
          className="button primaryButton"
          type="submit"
          style={{
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export { EditSubmissionComp };
