import React from "react";
import { useState } from "react";
import { getRequest } from "../../../../../Services/axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { useEffect } from "react";
import SocialShowFullAttachment from "./SocialShowFullAttachment";
import ShowNestedSharedPostDetails from "./ShowNestedSharedPostDetails";
import { useSelector } from "react-redux";
import { setCommunityPostData } from "../../../../../Features/SocialSlice";

function SocialPostDetails() {
  const [
    showNestedSharedPostDetailActive,
    setShowNestedSharedPostDetailActive,
  ] = useState(false);
  const [showFullAttachmentActive, setShowFullAttachmentActive] =
    useState(false);
  const [commentedPost, setCommentedPost] = useState();
  const [attachment, setAttachment] = useState(false);
  const [isGreetings, setIsGreetings] = useState(false);
  const [loading, setLoading] = useState(false);
  const { postid } = useParams();
  const dispatch = useDispatch();
  const { communityPostData } = useSelector((state) => state.social);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`SocialPost/Details?PostId=${postid}`);
      const { data, summary } = result?.data;
      const mergedObject = { ...data, ...summary };
      setCommentedPost(mergedObject);
      setAttachment(result?.data?.data?.attachment);

      if (mergedObject?.npost !== null) {
        setShowNestedSharedPostDetailActive(true);
      } else {
        setShowFullAttachmentActive(true);
      }
      if (mergedObject?.subType !== null) {
        setIsGreetings(true);
      } else {
        setIsGreetings(false);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {showNestedSharedPostDetailActive &&  !loading && (
        <ShowNestedSharedPostDetails
          isGreetings={isGreetings}
          getData={getData}
          postData={communityPostData}
          setPostData={setCommunityPostData}
          setCommentedPost={setCommentedPost}
          commentedPost={commentedPost}
          fullAtachmentData={attachment}
          setShowNestedSharedPostDetailActive={
            setShowNestedSharedPostDetailActive
          }
        />
      )}{" "}
      {showFullAttachmentActive && !loading && (
        <SocialShowFullAttachment
          isGreetings={isGreetings}
          getData={getData}
          postData={communityPostData}
          setPostData={setCommunityPostData}
          setCommentedPost={setCommentedPost}
          commentedPost={commentedPost}
          fullAtachmentData={attachment}
          setShowFullAttachmentActive={setShowFullAttachmentActive}
          // redirection={true}
        />
      )}{" "}
    </>
  );
}

export default SocialPostDetails;
