import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalPerformanceSlab } from "../Components/PerformanceSlabApproval.jsx/PendingApprovalPerformanceSlab";
import { ApprovedApprovalPerformanceSlab } from "../Components/PerformanceSlabApproval.jsx/ApprovedApprovalPerformanceSlab";
import { AllRequestApprovalPerformanceSlab } from "../Components/PerformanceSlabApproval.jsx/AllRequestApprovalPerformanceSlab";
// import { AllRequestApprovalPerformanceSlab } from "../Components/PerformanceSlabApproval/AllRequestApprovalPerformanceSlab";
// import { PendingApprovalPerformanceSlab } from "../Components/PerformanceSlabApproval/PendingApprovalPerformanceSlab";
// import { ApprovedApprovalPerformanceSlab } from "../Components/PerformanceSlabApproval/ApprovedApprovalPerformanceSlab";

const ApprovalPerformanceSlab = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Reviewed & Approved
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalPerformanceSlab
            title={""}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <ApprovedApprovalPerformanceSlab
          title={""}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <AllRequestApprovalPerformanceSlab
            title={""}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalPerformanceSlab };
