import React from "react";
import SocialPostTeamIcon from "../../../../../../../Assets/SocialPostTeamIcon.svg";
import { formatTimeFromDate } from "../../SocialFunctions";
import { useSelector } from "react-redux";
import ViewReportContainer from "./ViewReportContainer";

function SurveyAndPollTeamHeader({ hData }) {
  const { LoggedInUser } = useSelector((state) => state.auth);
  return (
    <div className="surveyAndPollTeamHeaderContainer">
      <div className="surveyAndPollTeamHeaderWrapper">
        <div className="surveyAndPollTeamHeaderLeftContainer">
          <div className="surveyAndPollTeamHeaderImgContainer">
            <img src={SocialPostTeamIcon} alt="" />
          </div>
          <div className="surveyAndPollTeamHeaderTxtContainer">
            <h6>Team</h6>
            <p>
              <span className="surveyPostDot">.</span>
              <span className="surveyCreatedOn">
                {formatTimeFromDate(hData?.createdOn)}
              </span>
            </p>
          </div>
        </div>
        <div className="surveyAndPollTeamHeaderRightContainer">
          {hData?.employeeId === LoggedInUser?.id && (
            <ViewReportContainer reportData={hData}/>
          )}
        </div>
      </div>
    </div>
  );
}

export default SurveyAndPollTeamHeader;
