import React, { useEffect, useState } from "react";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { getRequest } from "../../../../Services/axios";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import Nodata from "../../../../Assets/Nodata.svg";
import DetailsIcon from "../../../../Assets/DetailsIcon.svg";
import { getModuleDetails } from "../../../../Utilities/getModuleDetails";

const GetDelaytask = ({ navigateToRoute }) => {
  const [loading, setloading] = useState(false);
  const [delaydata, setdelaydata] = useState([]);

  const getapidata = async () => {
    try {
      setloading(true);
      const result = await getRequest(`Settings/GetDelayTask`);
      setdelaydata(
        Array.isArray(result?.data?.data?.task) ? result?.data?.data?.task : []
      );
      console.log("delayed data :=", result?.data?.data?.task);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getapidata();
    document.title = `PeopleSol - Delayed Task`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : delaydata.length > 0 ? (
        <div style={{ height: "100%" }}>
          <div style={{ height: "560px" }}>
            <table className="responsive-table tableHeaderRow">
              <thead style={{ position: "sticky" }}>
                <tr>
                  <th>S. No</th>
                  <th style={{ width: "90px" }}></th>

                  <th>Request Number</th>
                  <th>Description</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Deadlines</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {delaydata.map((item, index) => (
                  <tr key={item?.requestNumber || index}>
                    {" "}
                    {/* Use a unique key */}
                    <td>{index + 1}</td>
                    <td>
                      <img
                        className="cursor"
                        // onClick={() => {
                        //   const moduleDetails = getModuleDetails(
                        //     "Work & Tasks",
                        //     item?.id
                        //   );
                        //   if (moduleDetails?.returnUrl) {
                        //     navigateToRoute(moduleDetails.returnUrl);
                        //   } else {
                        //   }
                        // }}
                        onClick={() => {
                          navigateToRoute(item?.url);
                        }}
                        src={DetailsIcon}
                        alt=""
                      />
                    </td>
                    <td>{item?.requestNumber}</td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "220px",
                      }}
                    >
                      {item?.description}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "220px",
                      }}
                    >
                      {item?.title}
                    </td>
                    <td>
                      <div
                        style={{
                          backgroundColor: getStatusColor(item?.status)?.split(
                            ","
                          )?.[0],
                          color: getStatusColor(item?.status)?.split(",")?.[1],
                          cursor: "default",
                          padding: "2px 6px",
                          borderRadius: "4px",
                        }}
                      >
                        {item?.status}
                      </div>
                    </td>
                    <td>
                      {item?.endsOn
                        ? format(new Date(item?.endsOn), "dd/MM/yyyy")
                        : "-"}
                    </td>
                    <td>{item?.priority}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="noDatastyle" style={{ padding: "75px 105px" }}>
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>No Data Found</h4>
        </div>
      )}
    </>
  );
};

export default GetDelaytask;
