import React from "react";
import { useState } from "react";
import { Table } from "../../../Components";
import { Tabs, Tab } from "@mui/material";
import { AllRequestDeputation } from "../Components/DeputaionApproval/AllRequestDeputation";
import { RejectedDeputation } from "../Components/DeputaionApproval/RejectedDeputation";
import { HoldRequestDeputation } from "../Components/DeputaionApproval/HoldRequestDeputation";
import { DeputationApproved } from "../Components/DeputaionApproval/DeputationApproved";
import { PendingApprovalDeputation } from "../Components/DeputaionApproval/PendingApprovalDeputation";
import { useEffect } from "react";
const ApprovalDeputation = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Deputation`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalDeputation
            title={"Deputation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <DeputationApproved
          title={"Deputation"}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <RejectedDeputation
            title={"Deputation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}

      {tabIndex === 3 && (
        <>
          <AllRequestDeputation
            title={"Deputation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalDeputation };
