/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "./LeaveBalanceCard.css";
import "./LeaveBalanceProgress.css";
import "react-circular-progressbar/dist/styles.css";
import { getRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SelectCustom } from "../../../../../Components/FormComponent/SelectCustom";
import { showToast } from "../../../../../Features";
import Skeleton from "react-loading-skeleton";
import NoRequestsFound from "./NoRequestsFound";
import SelectCustomEmployeeCreateAttendance from "../../../../HRMS/Components/Time & Attendance/SelectCustomEmployeeCreateAttendance";

const LeaveBalanceCard = ({ isManager, navigate, teamList, hRTeam }) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [leaveList, setLeaveList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([
    { label: "Myself", value: LoggedInUser?.id },
  ]);
  const [idActive, setIdActive] = useState(LoggedInUser?.id);
  const getTeamLeaveBalance = async (id) => {
    if (!id) return;
    try {
      setLoading(true);
      const result = await getRequest(
        `LeaveTypes/GetTeamLeaveTypeWithBalance/${id}`
      );
      setLeaveList(result?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (idActive) getTeamLeaveBalance(idActive);
  }, [idActive]);
  useEffect(() => {
    if (LoggedInUser) {
      setIdActive(LoggedInUser?.id);
    }
  }, [LoggedInUser]);
  const colors = [
    "#77A2C7",
    "#DA5DB6",
    "#5BC4BF",
    "#8A88ED",
    "#A0A0CB",
    "#FB7158",
  ];

  return (
    <>
      <div className="headingContainer cursor headingWithDropdown">
        <h3
          className="cursor"
          onClick={() => navigate("/hrms/timeattendance/leave")}
        >
          Leave Balance
        </h3>
        {(isManager || hRTeam) && (
          <div style={{ width: "48%" }}>
            <SelectCustomEmployeeCreateAttendance
              styles={{
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
              }}
              selectedEmployeeList={selectedEmployee}
              setSelectedEmployeeList={setSelectedEmployee}
              values={selectedEmployee}
              setIdActive={setIdActive}
              urlProp={"Employees/GetEmployeesForAttendance"}
              lgid={LoggedInUser?.id}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
              deepStyle={{ backgroundColor: "var(--primary-hover)" }}
            />
            {/* <SelectCustom
              dropdownHeight="236px"
              styles={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
              values={selectedEmployee}
              options={teamList}
              onChange={(value) => {
                setIdActive(value?.[0]?.value);
                setSelectedEmployee(value);
              }}
              fullWidth={true}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
            /> */}
          </div>
        )}
      </div>
      <div className="dashboardCardContentHolder">
        {loading ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
          >
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <div className="ProgressBars">
            {leaveList?.length ? (
              leaveList?.map((item, index) => {
                return (
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[index % colors.length] }}
                    >
                      {item?.balance?.toFixed(2) ?? 0}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{
                        background: colors[index % colors.length],
                      }}
                    ></div>
                    <label className="leaveNameHolder">{item?.leaveName}</label>
                  </div>
                );
              })
            ) : (
              <NoRequestsFound text={"Leave Balance Not Updated"} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export { LeaveBalanceCard };
