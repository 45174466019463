/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch } from "react-redux";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { useEffect } from "react";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";

const opt = [{ label: "select", value: null }];
const options = [
  { label: "Allocate", value: "Allocate" },
  { label: "Maintenance", value: "Maintenance" },
  { label: "Surrender", value: "Surrender" },
];
const ServiceTypeFormIT = ({ formValues, data, editID }) => {
  const [optionSer, setOptionSer] = React.useState([]);
  const [optionAsset, setOptionAsset] = React.useState([]);
  const [optionNoti, setOptionNoti] = React.useState([]);
  const [optionTeam, setOptionTeam] = React.useState([]);
  const [optionEmployee, setOptionEmployee] = React.useState([]);

  const [option, setOption] = React.useState([]);
  const getOptionReq = async () => {
    try {
      const result = await getRequest(
        "ItsmRequestCategories/GetItsmRequestCategories"
      );
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Service Type`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getOptionNoti = async () => {
    try {
      const result = await getRequest("NotificationFlows/GetNotificationFlow");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.title, value: item.id });
      });
      setOptionNoti(listData);
    } catch (error) {
    } finally {
    }
  };

  const getOptionTeam = async () => {
    try {
      const result = await getRequest("ItsmTeamMappings/GetItsmTeam");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionTeam(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionEmployee = async () => {
    try {
      const result = await getRequest(
        `ServiceTypes/GetItsmTeamMembers/${formValues.teamId}`
      );
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.employeeId });
      });
      setOptionEmployee(listData);
    } catch (error) {
    } finally {
    }
  };

  const getOption = async () => {
    try {
      const result = await getRequest(
        "ItsmRequestCategories/GetTicketCategories"
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionAsset = async () => {
    try {
      const result = await getRequest("AssetCategories/GetAssetCategories");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionAsset(listData);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getOption();
    getOptionReq();
    getOptionNoti();
    getOptionTeam();
    getOptionAsset();
  }, []);
  React.useEffect(() => {
    if (formValues?.teamId !== "") {
      getOptionEmployee();
    }
  }, [formValues?.teamId]);
  const dispatch = useDispatch();
  return (
    <>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"Name"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="name"
            type={"text"}
            placeholder={"Enter Name"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Service Category "} mandatory={true} />
          {/* <SelectForm
            values={
              data
                ? optionSer.filter(
                    (options) => options.value === data?.categoryID
                  )
                : opt
            }
            name={"categoryId"}
            options={optionSer}
            placeholder={" Select Service Category "}
          /> */}
          <DropdownInfiniteScroll
            postMethod={false}
            name="categoryId"
            placeholder={" Select Service Category"}
            details={data}
            editID={editID}
            detailsForEdit={[data?.categoryID]}
            urlProp={"ItsmRequestCategories/GetTicketCategories"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Request Category "} mandatory={true} />
          <SelectForm
            values={
              data
                ? option.filter(
                    (options) => options.value === data?.requestCategoryID
                  )
                : opt
            }
            name={"requestCategoryId"}
            options={option}
            placeholder={" Select Request Category "}
          />
        </div>
        {formValues?.requestCategoryId === 3 && (
          <div>
            <LabelCustom labelName={"Asset Category "} />
            <SelectForm
              values={
                data
                  ? optionSer.filter(
                      (options) => options.value === data?.assetCategoryID
                    )
                  : opt
              }
              name={"assetCategoryID"}
              options={optionAsset}
              placeholder={" Select Asset Category "}
            />
          </div>
        )}
        <div>
          <LabelCustom labelName={"Notification Flow"} />
          <SelectForm
            values={
              data
                ? optionNoti.filter(
                    (options) => options.value === data?.notificationFlowID
                  )
                : opt
            }
            name={"notificationFlowId"}
            options={optionNoti}
            placeholder={" Select Notification Flow "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Team"} mandatory={true} />
          <SelectForm
            values={
              data
                ? optionTeam.filter((options) => options.value === data?.teamID)
                : opt
            }
            name={"teamId"}
            options={optionTeam}
            placeholder={" Select Team "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Employee "} />
          <SelectForm
            onMenuOpen={
              formValues?.teamId === ""
                ? () =>
                    dispatch(
                      showToast({
                        text: "Please select team first",
                        severity: "error",
                      })
                    )
                : getOptionEmployee
            }
            values={
              data
                ? optionEmployee.filter(
                    (options) =>
                      options.value === data?.assignTicketsToEmployeeID
                  )
                : opt
            }
            name={"assignTicketsToEmployeeId"}
            options={optionEmployee}
            placeholder={" Select Employee "}
          />
        </div>
        {formValues?.requestCategoryId === 3 && (
          <div>
            <LabelCustom labelName={" Asset Request Type "} mandatory={true} />
            <SelectForm
              name={"assetRequestType"}
              options={options}
              placeholder={" Select Asset Request Type "}
            />
          </div>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <LabelCustom
          labelName={"  Require more Information for Asset Approval?"}
        />
        :
        <YesNoFormik name="requiremoreinformationforAssetApproval" />
      </div>

      <div className="headingForm">TAT (In Hrs)</div>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"High"} mandatory={true} />
          <InputCustomFormik
            type="number"
            step="0.01"
            name={"tatHigh"}
            options={option}
            placeholder={" Enter High "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Medium "} mandatory={true} />
          <InputCustomFormik
            type="number"
            step="0.01"
            name={"tatMedium"}
            options={option}
            placeholder={" Enter Medium  "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Low"} mandatory={true} />
          <InputCustomFormik
            type="number"
            step="0.01"
            name={"tatLow"}
            options={option}
            placeholder={" Enter Low "}
          />
        </div>
      </div>
    </>
  );
};
export { ServiceTypeFormIT };
