import StatusFeature from "./StatusFeature";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
// import "./Chat.css";

import SendImageChat from "../../../../Assets/SendImageChat.svg";
import ChatEmogi from "../../../../Assets/ChatEmogi.svg";

import LeftArrowChat from "../../../../Assets/LeftArrowChat.svg";
import RightArrowChat from "../../../../Assets/RightArrowChat.svg";
import CrossChat from "../../../../Assets/CrossChat.svg";
import ViewIcon from "../../../../Assets/ViewIcon.svg";
import DeleteIcon from "../../../../Assets/DeleteIcon.svg"
import StoryView from "../../../../Assets/StoryView.svg"
import EmojiPicker from "emoji-picker-react";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
export const StoryPopUp = ({ closeStory, activeStory, newSocket, update, refresh }) => {
    var myInterval;
    const stories = [...activeStory?.stories]
    const videoRef = useRef(null);
    const dispatch = useDispatch();
    const [currentImage, setCurImg] = useState(activeStory.startWithIndex ? stories[activeStory.startWithIndex] : stories[0]);
    const [percent, setPercent] = useState(0);
    const [currentIndex, setCurInd] = useState(activeStory.startWithIndex ? activeStory.startWithIndex : 0);
    const [reply, setReply] = useState("");
    const myDetails = useSelector((state) => state.chat.userDetails);
    const [userDetails, setUserDetails] = useState(useSelector((state) => state.chat.userDetails))
    const [viewStories, setViewStories] = useState({ [stories[(activeStory.startWithIndex ? activeStory.startWithIndex : 0)]._id]: true });
    const [showEmogiPicker, setEmogiPicker] = useState(false);
    const [showViewBy, setShowViewBy] = useState(false);
    const [currentDim, setCurrDim] = useState({ height: 0, width: 0 })
    const [loading, setLoading] = useState(false);
    const getMeta = (url, cb) => {
        const img = new Image();
        img.onload = () => cb(null, img);
        img.onerror = (err) => cb(err);
        img.src = url;
    };


    useEffect(() => {
        setLoading(true)
        if (currentImage.type == 3) {
            let url = currentImage.content.url;
            if (currentImage.content.fileUrl) url = currentImage.content.fileUrl;
            getMeta(url, (err, img) => {
                if (img) {
                    let a = { width: img.naturalWidth, height: img.naturalHeight };

                    if (a.width > (window.innerWidth > 1440 ? 900 : 700) || a.height > (window.innerWidth > 1440 ? 700 : 550)) {
                        while (true) {
                            if (a.width < (window.innerWidth > 1440 ? 900 : 700) && a.height < (window.innerWidth > 1440 ? 700 : 550)) break;
                            a.width /= 1.2;
                            a.height /= 1.2;
                        }
                    }
                    setCurrDim({ ...a })
                }
            })
        }
    }, [currentImage])
    useEffect(() => {
        if (activeStory.type !== "self") {
            setUserDetails({ ...activeStory })
            newSocket.emit("updateStoryStatus", { "storyId": stories[0]._id }, (res) => {
                console.log("------- res from view story", res)
            })
        }
    }, [])

    useEffect(() => {
        if (!loading) setPercent(percent);
    }, [loading])

    useEffect(() => {
        if (showViewBy || loading) {
            if (showViewBy) {
                try {
                    videoRef?.current?.pause()
                } catch (error) {
                    console.log(error)
                }
            }

            clearInterval(myInterval);
        } else {
            if (currentImage.type == 3) {
                myInterval = setInterval(() => {
                    setPercent(percent + .5);
                }, 20)
            } else {
                if (!loading && videoRef && videoRef?.current && videoRef?.current?.paused) {
                    try {
                        videoRef.current.play();
                    } catch (error) {
                        console.log(error)
                    }
                }
                myInterval = setInterval(() => {
                    setPercent(videoRef?.current?.currentTime / videoRef?.current?.duration * 100);
                }, 500)
            }
        }
        if (!showViewBy && percent >= 100) {
            if (currentIndex < stories.length - 1) {
                setCurImg(stories[currentIndex + 1]);
                if (activeStory.type !== "self" && !viewStories[stories[currentIndex + 1]._id]) {
                    setViewStories({ ...viewStories, [stories[currentIndex + 1]._id]: true })
                    newSocket.emit("updateStoryStatus", { "storyId": stories[currentIndex + 1]._id }, (res) => {
                        console.log("------- res from view story", res)
                    })
                }
                setCurInd(currentIndex + 1);
                setPercent(0);
                setReply("");
            } else closeStory()
            clearInterval(myInterval)
        }

        return (() => { clearInterval(myInterval) })
    }, [percent, showViewBy, loading])

    const ViewBy = ({ currStory }) => {
        const [view, setView] = useState([]);

        useEffect(() => {
            newSocket.emit("whoViewMyStory", ({ "storyId": currStory._id }), (res) => {
                if (res.statusCode == 200) {
                    setView([...res.data]);
                }
            })
        }, [])

        function getTime(dateString) {
            const date = new Date(dateString);
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            const isSameDay = (date1, date2) =>
                date1.getDate() === date2.getDate() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getFullYear() === date2.getFullYear();

            let dateLabel;
            if (isSameDay(date, today)) {
                dateLabel = "Today";
            } else if (isSameDay(date, yesterday)) {
                dateLabel = "Yesterday";
            } else {
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                const year = date.getFullYear();
                dateLabel = `${day}/${month}/${year}`;
            }

            // Format the time in 12-hour format with am/pm
            let hours = date.getHours();
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12 || 12; // Convert 0 hours to 12 in 12-hour format

            return `${dateLabel} at ${hours}:${minutes} ${ampm}`;
        }
        return (
            <>
                <Popup
                    contentClassName="forwardPopup-chat"
                    bodyClassName="overflowStop"
                    createMyHead="createHeadForward"
                    color={"#1296B0"}
                    backgroundColor={"white"}
                    noPadding={true}
                    popupHeading={"Viewed By"}
                    content={
                        <div>
                            {view.map((item) => (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: 15 }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        {item?.image && item.image != "" ? <img style={{ width: 45, height: 45, borderRadius: 25 }} src={item.image} /> :
                                            <div style={{ width: 45, height: 45, borderRadius: 25, backgroundColor: "#E9F3F4", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                <div style={{ color: "#13A9C7", fontSize: 16, fontWeight: "900" }}>
                                                    {item?.contactName
                                                        ?.split(" ")
                                                        ?.slice(0, 2)
                                                        ?.map((item1) => {
                                                            return item1?.charAt(0)?.toUpperCase();
                                                        })
                                                        .join("")
                                                    }
                                                </div>
                                            </div>}
                                        <div style={{ marginLeft: 10 }}>
                                            <div style={{ fontSize: 14, fontWeight: "900" }}>{item.contactName}</div>
                                            <div style={{ fontSize: 12, fontWeight: "400", color: "rgba(186, 186, 186, 1)" }}>{getTime(item.lastUpdatedOn)}</div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <img src={ViewIcon}></img>
                                        <div style={{ marginLeft: 10 }}>{item.viewCount}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    handleClose={() => { setShowViewBy(false) }}
                />
            </>
        )
    }

    return (
        <div style={{ backgroundColor: "rgba(0, 0, 0, .5)", width: "100%", height: "100%", position: "absolute", zIndex: 100 }}>
            <div style={{ display: "block", position: "absolute", zIndex: 101, right: 20, top: 20 }}>
                <div onClick={() => {
                    closeStory();
                }} style={{ backgroundColor: "rgba(85, 85, 85)", width: 50, height: 50, borderRadius: 25, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={CrossChat} alt="close" />
                </div>
            </div>
            {showViewBy ? <ViewBy currStory={currentImage} /> : null}
            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%", justifyContent: "space-evenly", alignItems: "center" }}>
                <div onClick={() => {
                    if (currentIndex > 0) {
                        setPercent(0);
                        setCurImg(stories[currentIndex - 1]);
                        if (activeStory.type !== "self" && !viewStories[stories[currentIndex - 1]._id]) {
                            setViewStories({ ...viewStories, [stories[currentIndex - 1]._id]: true })
                            newSocket.emit("updateStoryStatus", { "storyId": stories[currentIndex - 1]._id }, (res) => {
                                console.log("------- res from view story", res)
                            })
                        }
                        setCurInd(currentIndex - 1);
                    }
                }} style={{ backgroundColor: "rgba(85, 85, 85)", width: 50, height: 50, borderRadius: 25, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={LeftArrowChat} alt="left" />
                </div>

                <div style={{ display: "flex", height: "100%" }}>

                    <div style={{ backgroundColor: "white", height: "100%", minWidth: "450px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "space-between", paddingBottom: 20 }}>
                        <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ paddingLeft: 20, paddingRight: 20, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: 30 }}>

                                {
                                    stories.map((image, ind) => (
                                        <div style={{ width: (JSON.stringify((100 / stories.length) - 1) + "%"), borderRadius: 10, height: 4, backgroundColor: "#E1E1E1" }}>
                                            <div style={{ height: "100%", width: ind == currentIndex ? (JSON.stringify(percent) + "%") : ind < currentIndex ? "100%" : "0%", backgroundColor: "rgba(161, 161, 161, 1)", borderRadius: 10, }}></div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div style={{ width: "100%", margin: 20, marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: 5, paddingLeft: 20, paddingRight: 20 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {userDetails.image != "" ? <img style={{ width: 45, height: 45, borderRadius: 25 }} src={userDetails.image} /> :
                                        <div style={{ width: 45, height: 45, borderRadius: 25, backgroundColor: "#E9F3F4", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                            <div style={{ color: "#13A9C7", fontSize: 16, fontWeight: "900" }}>
                                                {userDetails?.name
                                                    ?.split(" ")
                                                    ?.slice(0, 2)
                                                    ?.map((item) => {
                                                        return item?.charAt(0)?.toUpperCase();
                                                    })
                                                    .join("")
                                                }
                                            </div>
                                        </div>}
                                    <div style={{ marginLeft: 10 }}>
                                        <div style={{ fontSize: 14, fontWeight: "900" }}>{userDetails.name}</div>
                                        <div style={{ fontSize: 12, fontWeight: "400" }}>{userDetails.designation}</div>
                                    </div>
                                </div>
                                {activeStory.type == "self" ?
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div onClick={() => { setShowViewBy(true) }} style={{ height: 30, width: 30, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 5, backgroundColor: "rgba(216, 240, 242, 1)", marginRight: 10 }}>
                                            <img src={ViewIcon} />
                                        </div>
                                        <div onClick={() => {
                                            newSocket.emit("removeStory", ({ "storyId": 29 }), (res) => {
                                                dispatch(
                                                    showToast({ text: "Your story has been removed!", severity: "success" })
                                                );
                                                update();
                                            })
                                        }} style={{ height: 30, width: 30, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 5, backgroundColor: "rgba(255, 221, 220, 1)" }}>
                                            <img src={DeleteIcon} />
                                        </div>
                                    </div>
                                    : null}
                                {/* <div style={{ fontSize: 12, fontWeight: "400", margin: 20 }}>14:30</div> */}
                            </div>

                            {
                                loading ?
                                    <Loader /> : null
                            }
                            {
                                currentImage.type == 2 ?
                                    <video onCanPlay={() => { setLoading(false) }} style={{ display: loading ? 'none' : 'block' }} ref={videoRef} src={currentImage.content.url ? currentImage.content.url : currentImage.content.fileUrl} autoPlay={!loading} width={window.innerWidth > 1440 ? 980 : 780} />
                                    : <img onLoad={() => { setLoading(false) }} style={{ width: currentDim.width, height: currentDim.height, display: loading ? 'none' : 'block' }} src={currentImage.content.url ? currentImage.content.url : currentImage.content.fileUrl} alt="placeholder" />
                            }
                            {currentImage.caption && currentImage.caption != "" ? <div style={{ position: "absolute", color: "white", backgroundColor: "rgba(0, 0, 0, 0.4)", padding: 10, textAlign: "center", width: currentImage.type == 2 && !loading ? (window.innerWidth > 1440 ? 980 : 780) : currentDim.width < 450 ? "450px" : currentDim.width, textWrap: "wrap", bottom: 100 }}>
                                {currentImage.caption}
                            </div> : null}

                        </div>
                        {activeStory.type != "self" ? <OutsideClickHandler onOutsideClick={() => {
                            if (!showEmogiPicker) {
                                setPercent(percent + .5);
                                if (videoRef?.current) videoRef.current.play();
                            }
                        }}>
                            <div style={{ display: "flex", justifyContent: "space", flexDirection: "row", alignItems: "center", marginTop: 30, width: "100%", paddingLeft: 20, paddingRight: 20, }}>
                                {showEmogiPicker ? <OutsideClickHandler onFocus={() => { clearInterval(myInterval); if (videoRef?.current) videoRef.current.pause(); }} onOutsideClick={() => { setEmogiPicker(false); }}>
                                    <EmojiPicker
                                        onEmojiClick={(e) => { setReply(reply + e.emoji) }}
                                    />
                                </OutsideClickHandler> : null
                                }
                                <div on onClick={() => { setEmogiPicker(true) }}>
                                    <img src={ChatEmogi} alt="" style={{ width: 30, height: 30 }} />
                                </div>
                                <input onFocus={() => { clearInterval(myInterval); if (videoRef?.current) videoRef.current.pause(); }} style={{ marginLeft: 10, marginRight: 10, borderRadius: 8, padding: 5, borderStyle: "solid", borderColor: "#BABABA", borderWidth: .3, outline: "none", width: "400px" }} placeholder="Type a message" type="text" value={reply} onInput={(e) => { setReply(e.target.value) }}></input>
                                <img src={SendImageChat} onClick={() => {
                                    if (reply && reply != "")
                                        newSocket.emit("replyOnStory", { "storyId": currentImage._id, "senderId": myDetails._id, "type": currentImage.type, "caption": reply }, (res) => {
                                            setPercent(percent + .5);
                                            setReply("");
                                            refresh()
                                            dispatch(
                                                showToast({ text: "Reply sent successfully!", severity: "success" })
                                            );
                                        })
                                }} alt="" />
                            </div>
                        </OutsideClickHandler> : null}
                    </div>
                </div>
                <div onClick={() => {
                    if (currentIndex < stories.length - 1) {
                        setPercent(0);
                        setCurImg(stories[currentIndex + 1]);
                        if (activeStory.type !== "self" && !viewStories[stories[currentIndex + 1]._id]) {
                            setViewStories({ ...viewStories, [stories[currentIndex + 1]._id]: true })
                            newSocket.emit("updateStoryStatus", { "storyId": stories[currentIndex + 1]._id }, (res) => {
                                console.log("------- res from view story", res)
                            })
                        }
                        setCurInd(currentIndex + 1);
                    }
                }} style={{ backgroundColor: "rgba(85, 85, 85)", width: 50, height: 50, borderRadius: 25, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={RightArrowChat} alt="right" />
                </div>
            </div>
        </div>
    )
}