import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";

import {
  docsArray,
  getAttachments,
  getExtension,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import "./ShowFullAttachments.css";
import "./Connect.css";

import dateFormat from "dateformat";
import {
  albumPostImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import SocialPdfLeftArrow from "../../../../../Assets/SocialPdfLeftArrow.svg";
import SocialPdfRightArrow from "../../../../../Assets/SocialPdfRightArrow.svg";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { useNavigate, useParams } from "react-router-dom";
import PDFViewer from "./PDFViewer";
import ContentSocialDisplay from "./ContentSocialDisplay";

function EventPostDetails() {
  const [commentedPost, setCommentedPost] = useState(null);
  const [isImages, setIsImages] = useState(false);
  const [isVideos, setIsVideos] = useState(false);
  const [isDocs, setIsDocs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);
  const [attachment, setAttachment] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postid } = useParams();
  const getData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`SocialPost/Details?PostId=${postid}`);
      const { data, summary } = result?.data;
      const mergedObject = { ...data, ...summary };
      setCommentedPost(mergedObject);
      setAttachment(getAttachments(result?.data?.data?.attachment));
      findAttach(getAttachments(result?.data?.data?.attachment));
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const backToAnnouncement = () => {
    setAttachment(null);
    setCommentedPost(null);
    navigate("/home/social/event");
  };
  const findAttach = (attachment) => {
    if (attachment) {
      let x = getExtension(attachment[0]);
      if (imgArray.includes(x)) {
        setIsImages(true);
      } else if (videoArray.includes(x)) {
        setIsVideos(true);
      } else if (docsArray.includes(x)) {
        setIsDocs(true);
      }
      if (x !== null) {
        setNumPages(attachment?.length);
      }
    }
  };
  const nextSlide = (e) => {
    // e.stopPropagation();
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  };
  const prevSlide = (e) => {
    // e.stopPropagation();
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  };
  useEffect(() => {
    const keyHandler = (event) => {
      if (event.code === "ArrowRight") {
        nextSlide();
      } else if (event.code === "ArrowLeft") {
        prevSlide();
      }
    };
    document.addEventListener("keydown", keyHandler, false);
    return () => document.removeEventListener(keyHandler);
  }, []);
  useEffect(() => {
    getData();
  }, [postid]);
  useEffect(() => {
    return () => {
      setCommentedPost(null);
    };
  }, [postid]);
  return (
    <div className="showAttachmentsWithCommentContainer">
      {!loading && commentedPost && (
        <Popup
          firstClassName="showAttachmentsWithCommentPopupbox1"
          secondClassName="showAttachmentsWithCommentPopupbox2"
          createMyHead="showAttachmentsWithCommentCreateHead"
          content={
            <>
              <div className="showAttachmentsWithCommentContainer">
                <Popup
                  firstClassName="showAttachmentsWithCommentPopupbox1"
                  secondClassName="showAttachmentsWithCommentPopupbox2"
                  createMyHead="showAttachmentsWithCommentCreateHead"
                  content={
                    <>
                      <div className="showAttachmentsWithCommentWrapper">
                        {isImages && (
                          <div className="showAttachmentsWithCommentImgContainer">
                          <div >
                            {commentedPost?.subType &&
                              getExtension(attachment[0]) !== "gif" && (
                                <img
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    albumPostImages +
                                    attachment[index]
                                  }
                                  alt=""
                                />
                              )}
                            {(!commentedPost?.subType ||
                              getExtension(attachment[0]) === "gif") && (
                              <img
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  socialPostImages +
                                  attachment[index]
                                }
                                alt=""
                              />
                            )}
                          </div>
                          </div>
                        )}
                        {isVideos && (
                          <div
                            className="showFullAttachmentListBox"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <video
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                socialPostImages +
                                attachment[index]
                              }
                              controls
                              style={{
                                padding: "12px",
                                width: "100%",
                                height: "89%",
                              }}
                            />
                          </div>
                        )}
                        {isDocs && (
                          <div
                            className="showFullAttachmentListBox"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <PDFViewer
                              pdfUrl={
                                process.env.REACT_APP_.BASE_URL +
                                socialPostImages +
                                attachment[index]
                              }
                            />
                          </div>
                        )}
                        {!isImages && !isVideos && !isDocs && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                              color: "#bfbfbf",
                            }}
                          >
                            No Attachment Found!
                          </div>
                        )}
                        {attachment !== null && attachment?.length > 0 && (
                          <>
                            <div className="leftPdfArrow">
                              {index === 0 || (
                                <img
                                  src={SocialPdfLeftArrow}
                                  alt=""
                                  onClick={(e) => {
                                    prevSlide(e);
                                  }}
                                />
                              )}
                            </div>
                            <div className="rightPdfArrow">
                              {numPages - 1 === index || (
                                <img
                                  src={SocialPdfRightArrow}
                                  alt=""
                                  onClick={(e) => {
                                    nextSlide(e);
                                  }}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="showAttachmentsWithCommentListContainer">
                        <div className="showAttachmentsWithCommentListWrapper">
                          <div className="showAttachmentsWithCommentListHeaderContainer">
                            <div className="leftPostHeaderContainer">
                              <div className="socialPostTitleAndTimeContainer">
                                <label>
                                  {commentedPost?.events?.title ?? "--"}
                                </label>
                                <label>
                                  <div className="announcementViewsContainer">
                                    <span className="postHrTime">
                                      {" "}
                                      •{" "}
                                      {dateFormat(
                                        commentedPost?.item?.createdOn,
                                        "hh:MM TT | dd mmm yyyy"
                                      )}
                                    </span>
                                    {/* <article
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setViewsListActive(true);
                                        setViewsPostId(commentedPost?.item.id);
                                      }}
                                    >
                                      <img
                                        src={SocialPostViewsEyeIcon}
                                        alt=""
                                      />
                                      <span style={{ marginLeft: "5px" }}>
                                        {commentedPost?.item?.views < 10
                                          ? "0" + commentedPost?.item?.views
                                          : commentedPost?.item?.views}{" "}
                                        Views
                                      </span>
                                    </article> */}
                                  </div>
                                </label>
                              </div>
                            </div>
                            <IconButton onClick={backToAnnouncement}>
                              <img src={CloseIcon} alt="" />
                            </IconButton>
                          </div>

                          <div className="showAttachmentsWithCommentListBodyContainer">
                            <div className="showAttachmentsWithCommentListBodyWrapper">
                              <div className="">
                                <p>
                                  <ContentSocialDisplay
                                    content={commentedPost?.events?.description}
                                  />
                                </p>
                              </div>
                            </div>
                            <div
                              className="showAttachmentsWithCommentListActivityContainer"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="totalPostActivity"
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "space-between",
                                  fontSize: "12px",
                                  color: "#bfbfbf",
                                  padding: "10px 20px",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  handleClose={backToAnnouncement}
                />
              </div>
            </>
          }
          handleClose={backToAnnouncement}
        />
      )}
    </div>
  );
}

export default EventPostDetails;
