import React, { useEffect, useRef, useState } from "react";
import "./CreateBand.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateBandComp } from "./FormComp/CreateBandComp";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";

import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { getRequest, postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";


const CreateBand = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Band `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const { state } = useLocation();

  const data = state?.dataEdit;

  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(true);

    const createPageData = async (values) => {
      try {
          
          dispatch(isLoading(true));
          setLoading(true);
          const result = await postRequest('Bands/Create', values);
          dispatch(showToast({ text: "Successfully Created", severity: "success" }));
          
      } catch (error) {

          dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      } finally {
          dispatch(isLoading(false));
          setLoading(false);
      }
  }

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "Bands/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };


  const initialvalue = {
    name: "",
    noticePeriod: "",
    probationPeriod: "",

    
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(149, " Name must be less than 150 characters").required("Please Enter Name "),
    noticePeriod: Yup.number().min(1).max(365, "Notice Period must be less than 365 days").required("Please Enter Notice Period"),
    probationPeriod:Yup.number().min(1).max(365, "Probation Period must be less than 365 days").required("Please Enter Probation Period"),
    
  });


  const onSubmit = (values) => {
 
 const edit = {
  ...values,
  id:  data?.Id
};

const create={
 ...values,
}
data ? Edit(edit) : createPageData(create);
returnPage()
 }
 

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
   <FormCustomFormik FormHeading={data?'Edit Band':'Create Band'} ButtonLabel={data?'Update Band':'Create Band'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
    data
      ? {
          ...initialvalue,
          name: data?.Name,
          noticePeriod: data?.NoticePeriod,
          probationPeriod: data?.ProbationPeriod,
        }
      : initialvalue
  } returnPage={returnPage} propsDiv={<CreateBandComp />} />
  );
};

export { CreateBand };