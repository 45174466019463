import React from "react";
import FirstCertificateTemplate from "./FirstCertificateTemplate";
import "./certificatescss.css";
import AessLogos from "../../../Assets/Aess_logo_full.svg";
import ThirdCertificateTemplate from "./ThirdCertificateTemplate";
import FourthCertificateTemplate from "./FourthCertificateTemplate";
import FifthCertificateTemplate from "./FifthCertificateTemplate";
import SecondCertificateTemplate from "./SecondCertificateTemplate";
import SixCertificateTemplate from "./SixCertificateTemplate";

const RewardsTemplateParent = () => {
  return (
    <div className="moduleMasterDetailsPage" style={{ zIndex: "17" }}>
      <FirstCertificateTemplate companyLogo={AessLogos} />
      {/* <ThirdCertificateTemplate companyLogo={AessLogos} /> */}
      {/* <FourthCertificateTemplate companyLogo={AessLogos} /> */}
      {/* <FifthCertificateTemplate companyLogo={AessLogos} /> */}
      {/* <SecondCertificateTemplate companyLogo={AessLogos} />  */}
      {/* <SixCertificateTemplate companyLogo={AessLogos} /> */}
    </div>
  );
};

export default RewardsTemplateParent;
