import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { showToast, isLoading } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const opt = [{ label: "select", value: null }];

export const CreateAreaComp = ({ data }) => {
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [personalSubList, setPersonalSubList] = useState([]);
  useEffect(() => {
    document.title = `PeopleSol - Create Area `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };
  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    if (data) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (data) {
      setPage(0);
      let idArray;
      if (data?.taEmployee?.id || data?.hrbp?.id) {
        idArray = [data?.taEmployee?.id, data?.hrbp?.id];
      } else idArray = [];

      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);

  const getSubAreaData = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });

      setPersonalSubList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getSubAreaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"ERP ID"} mandatory={true} />
        <InputCustomFormik
          name="sapid"
          type={"text"}
          placeholder={"Enter SAP ID"}
        />
      </div>
      <div>
        <LabelCustom labelName={"HRBP"} />
        <SelectForm
          name="hrbp"
          placeholder={"Select HRBP"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div>
        <LabelCustom labelName={"TA"} />
        <SelectForm
          name="taEmployeeId"
          placeholder={"Select TA"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div>
        <LabelCustom labelName={"Personal Sub Area"} />
        <SelectForm
          name="subAreaId"
          options={personalSubList}
          placeholder={"Select Personal Sub Area"}
          values={
            data
              ? personalSubList.filter(
                  (options) => options.value === data?.subAreaId
                )
              : opt
          }
        />
      </div>
    </div>
  );
};
