import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCompanyFoodComp } from "./FormComp/CreateCompanyFoodComp";
import { confirmAlert } from "react-confirm-alert";
import dateFormat from "dateformat";
import { format } from "date-fns";
import { CreateCompanyHospitalityComp } from "./FormComp/CreateCompanyHospitalityComp";

const CreateCompanyHospitality = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Company Hospitality`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);

  const [detailsform, setDetailsform] = useState({});

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`HospitalityRequests/Details?ID=${data}`);
      // console.log("hospitalitydetails", result);
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    // getFormDetails();
    if (data) getFormDetails();
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("HospitalityRequests/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("HospitalityRequests/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const getItems = (item) => {
    let returnArray = [];
    data
      ? item?.map((item) => {
          returnArray.push({
            name: item.name,
            phoneNumber: item.phoneNumber,
            emailId: item.emailId,
            designation: item.designation,
            remarks: item.remarks,
          });
        })
      : (returnArray = [
          {
            name: "",
            phoneNumber: "",
            emailId: "",
            designation: "",
            remarks: "",
          },
        ]);

    return returnArray;
  };

  const initialvalue = {
    guestof: detailsform?.guestOf ? detailsform?.guestOf : "",
    guestType: detailsform?.guestType ? detailsform?.guestType : "",
    purpose: detailsform?.purpose ? detailsform?.purpose : "",
    hotelId: detailsform?.hotel?.name ? detailsform?.hotel?.id : "",
    checkinDateandTime: detailsform?.checkinDateandTime
      ? new Date(detailsform?.checkinDateandTime)
      : "",
    checkOutDateandTime: detailsform?.checkOutDateandTime
      ? new Date(detailsform?.checkOutDateandTime)
      : "",
    noofDays: detailsform ? detailsform?.noofDays : "",

    Items: getItems(detailsform?.items),
  };

  // console.log("initialvalue", initialvalue);
  const validationSchema = Yup.object({
    guestof: Yup.string().required("Please select the field"),
    guestType: Yup.string().required("Please select the Guest Type"),
    purpose: Yup.string()
      .matches(/^\S.*$/, "Purpose should not start with spaces")
      .min(2, "Purpose must be of minimum 2 characters")
      .max(499, "Purpose must be less than 500 characters")
      .required("Please enter the Purpose "),
    hotelId: Yup.string().required("Please select the Preferred Hotel"),
    checkinDateandTime: Yup.date().required(
      "Please select the Check-in Date and Time "
    ),
    checkOutDateandTime: Yup.date()
      .required("Please select the Check-out Date and Time ")
      .test(
        "is-greater",
        "Check-out Date and Time must be greater than Check-in Date and Time",
        function (value) {
          const startDate = formValues?.checkinDateandTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Items: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^\S.*$/, "Name should not start with spaces")
          .min(2, "Name must be of minimum 2 characters")
          .max(149, "Name must be less than 150 characters")
          .required("Please enter the Name"),
        phoneNumber: Yup.number()
          .typeError("Phone Number must be a number")
          .required("Please enter the Phone Number"),
        emailId: Yup.string()
          .matches(/^\S.*$/, "Email should not start with spaces")
          .email("Invalid email address")
          .notRequired()
          .nullable(),
        designation: Yup.string()
          .matches(/^\S.*$/, "Designation should not start with spaces")
          .max(149, "Designation must be less than 150 characters")
          .notRequired()
          .nullable(),
        remarks: Yup.string()
          // .matches(/^\S.*$/, "Remarks should not start with spaces")
          .max(999, "Remarks must be less than 1000 characters")
          .notRequired()
          .nullable(),
      })
    ),
  });
  // console.log("formVlaues", formValues);
  const onSubmit = (values) => {
    // console.log("values ohf  hospitakalao", values);
    const edit = {
      ...values,
      id: detailsform?.id,
      checkinDateandTime: format(
        values?.checkinDateandTime,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      checkOutDateandTime: format(
        values?.checkOutDateandTime,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
    };
    // console.log("initialvalue", initialvalue);

    const create = {
      ...values,
      checkinDateandTime: format(
        values?.checkinDateandTime,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      checkOutDateandTime: format(
        values?.checkOutDateandTime,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      FormHeading={
        data
          ? "Edit Company Hospitality Request"
          : "Create Company Hospitality Request"
      }
      ButtonLabel={
        data
          ? "Edit Company Hospitality Request"
          : "Create Company Hospitality Request"
      }
      onSubmit={onSubmit}
      loading={loading}
      chooseFormValues={chooseFormValues}
      styles={{ maxWidth: "80%", width: "80%" }}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateCompanyHospitalityComp
          data={detailsform}
          edit={data}
          formValues={formValues}
        />
      }
    />
  );
};

export { CreateCompanyHospitality };
