import React, { useState, useEffect } from "react";
import { FieldArray, Formik } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { useLocation } from "react-router-dom";

const opt = [{ label: "select", value: null }];

const guestOptions = [
  { label: "Casual", value: "Casual" },
  { label: "Special", value: "Special" },
];
const foodOptions = [
  { label: "Veg", value: "Veg" },
  { label: "Non-Veg", value: "Non-Veg" },
];

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];
export const CreateReceiveCourierComp = ({
  data,
  edit,
  formValues,
  editID,
}) => {
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState();
  const status = [
    { label: "Ready For Dispatch", value: "Ready for Dispatch" },
    { label: "Dispatched", value: "Dispatched" },
    { label: "Delivered", value: "Delivered" },
  ];
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(editID ? 0 : 1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };
  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    // if (editID) {
    //   setPage(0);
    // } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (editID) {
      // setPage(0);
      let idArray;
      if (data?._courierinrequest?.employeeId)
        idArray = [data?._courierinrequest?.employeeId];
      else idArray = [];

      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);

  useEffect(() => {
    document.title = `PeopleSol - Create Receive Courier`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Courier to"} mandatory={true} />
          <SelectForm
            name="employeeID"
            placeholder={"Select Employee"}
            onMenuScrollToBottom={handleScrollToBottom}
            options={searchParamemployee ? option : listData}
            onInputChange={(e) => setSearchParamemployee(e)}
          />
        </div>
        <div>
          <LabelCustom labelName={"Date"} mandatory={true} />
          <DatePickerFormiks name="date" minDate={new Date()} showTimeSelect />
        </div>
        {edit && (
          <div>
            <LabelCustom labelName={"Status"} mandatory={true} />
            <SelectForm
              name="status"
              options={status}
              placeholder={"Select Status"}
            />
          </div>
        )}

        <div>
          <LabelCustom labelName={"Description"} mandatory={true} />
          <TextAreaFormik
            name="description"
            maxLength={"250"}
            type={"text"}
            placeholder={"Please Enter Additional Info."}
          />
        </div>
      </div>
    </>
  );
};
