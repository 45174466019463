import React, { useCallback, useEffect, useState } from "react";
import { Popup } from "../../../../../../../Components/FormComponent/PopupComponent/Popup";
import SocialPostOrganizationIcon from "../../../../../../../Assets/SocialPostOrganizationIcon.svg";
import SocialPostTeamsIcon from "../../../../../../../Assets/SocialPostTeamsIcon.svg";
import SocialPostComunityIcon from "../../../../../../../Assets/SocialPostComunityIcon.svg";
import SocialCommunityGroupIcon from "../../../../../../../Assets/SocialCommunityGroupIcon.svg";
import ArrowBackButton from "../../../../../../../Assets/ArrowBackButton.svg";
import SearchMagnifierIcon from "../../../../../../../Assets/SearchMagnifierIcon.svg";
import ProfileAvatar from "../../../../../../../Assets/ProfileAvatar.svg";
import CloseIcon from "../../../../../../../Assets/CloseIcon.svg";
import dateFormat from "dateformat";
import { isLoading, showToast } from "../../../../../../../Features";
import {
  getRequest,
  postRequestForm,
} from "../../../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getUserInitials } from "../../../../../../../Utilities/socialAllFunctions";
import { communityProfileImages } from "../../../../../../../Utilities/profileAndUploadImages";
import { IconButton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import SurveyNotificationsItem from "./SurveyNotificationsItem";
// import NotificationsItem from "./SurveyNotificationsItem";

function SelectCategory({ setIsOpen, daata, type, setReRefreshProp }) {
  const [selectPostTypeActive, setSelectPostTypeActive] = useState(true);
  const [PostSubType, setPostSubType] = useState("immediate");
  const [newPostType, setNewPostType] = useState("organisation");
  const [allCommunityList, setAllCommunityList] = useState();
  const [searchAllCommunityList, setSearchAllCommunityList] = useState();
  const [selectPostSubTypeActive, setSelectPostSubTypeActive] = useState(false);
  const [communityListActive, setCommunityListActive] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [allEmployeeList, setAllEmplyeeList] = useState([]);
  const [presentEmployeeData, setPresentEmployeeData] = useState([]);
  const [employeeSelectionActive, setEmployeeSelectionActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPostCommunityId, setNewPostCommunityId] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postDataCount, setPostDataCount] = useState(-1);

  const getEmployeeData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(
        `Social/GetEmployeesImageDropDown?i=${page}`
      );
      setAllEmplyeeList([...allEmployeeList, ...result?.data.textfilters]);
      setSelectEmployee([...selectEmployee, ...result?.data.textfilters]);
      setPresentEmployeeData([...allEmployeeList, ...result?.data.textfilters]);
      setPostDataCount(result?.data.allCount);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getCommunityData = async () => {
    try {
      const result = await getRequest("SocialPost/GetCommunity");
      setAllCommunityList(result?.data);
      setSearchAllCommunityList(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  function searchChatters(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(selectEmployee));
      tempChatters = tempChatters.filter((d, i) =>
        d?.name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
      );
      setAllEmplyeeList(tempChatters);
    } else {
      setAllEmplyeeList(selectEmployee);
    }
  }

  function searchCommunity(event) {
    if (event.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(searchAllCommunityList));
      tempChatters = tempChatters.filter((d, i) =>
        d?.communityName
          ?.toLowerCase()
          ?.includes(event.currentTarget.value?.toLowerCase())
      );
      setAllCommunityList(tempChatters);
    } else {
      setAllCommunityList(searchAllCommunityList);
    }
  }

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Survey/Create", values);
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        setReRefreshProp(true);
        navigate(-1);
      } else {
        dispatch(
          showToast({
            text: result?.data?.message ?? "Error in fetching Data",
            severity: "error",
          })
        );
        return;
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const onSubmit = () => {
    const formdata = new FormData();
    formdata.append("Category", type);
    formdata.append("CategoryType", newPostType);
    formdata.append("SurveyTitle", daata?.SurveyTitle);
    formdata.append("Description", daata?.Description);
    formdata.append("EndOn", dateFormat(daata?.EndOn, "yyyy-mm-dd "));
    formdata.append("StartOn", dateFormat(daata?.StartOn, "yyyy-mm-dd "));
    if (newPostType === "team") {
      formdata.append("SubCategoryType", PostSubType);
    }

    if (newPostType === "community") {
      formdata.append("CommunityId", newPostCommunityId);
    }
    if (newPostType === "individual") {
      for (let i = 0; i < selectedRecipient?.length; i++) {
        formdata.append(`_EmployeesId[${i}]`, selectedRecipient[i]?.id);
      }
    }
    for (let i = 0; i < daata?.SurveyQuestion?.length; i++) {
      formdata.append(
        `SurveyQuestion[${i}].QuestionText`,
        daata?.SurveyQuestion[i]?.QuestionText
      );
      formdata.append(
        `SurveyQuestion[${i}].AllowMultipleAnswer`,
        daata?.SurveyQuestion[i]?.AllowMultipleAnswer
      );

      for (let j = 0; j < daata?.SurveyQuestion[i]?.Answers?.length; j++) {
        formdata.append(
          `SurveyQuestion[${i}].Answers[${j}].AnswerText`,
          daata?.SurveyQuestion[i]?.Answers[j]?.AnswerText
        );
      }
    }
    formdata.append("Upload", daata?.Upload);
    
    createPageData(formdata);
  };

  useEffect(() => {
    getCommunityData();
    getEmployeeData();
  }, []);

  return (
    <div className="selectCategoryListContainer">
      {selectPostTypeActive && (
        <Popup
          firstClassName="selectSurveyPostTypePopupBox1"
          secondClassName="selectSurveyPostTypePopupBox2"
          popupHeading="Who can see your post?"
          content={
            <div className="selectPostTypeContainer">
              <div className="selectPostTypeWrapper">
                <div className="selectPostOrganizationContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img src={SocialPostOrganizationIcon} alt="" />
                    </article>
                    <span>Organisation</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={newPostType === "organisation" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setNewPostType("organisation");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostTeamContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostTeamsIcon}
                        alt=""
                        style={{ height: "20px", paddingTop: "3px" }}
                      />
                    </article>
                    <span>Team</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setNewPostType("team");
                        }}
                        checked={newPostType === "team" ? true : false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostCommunityContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostComunityIcon}
                        alt=""
                        style={{ height: "22px", paddingTop: "5px" }}
                      />
                    </article>
                    <span>Community</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setNewPostType("community");
                        }}
                        checked={newPostType === "community" ? true : false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostCommunityContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostComunityIcon}
                        alt=""
                        style={{ height: "22px", paddingTop: "5px" }}
                      />
                    </article>
                    <span>Individual</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setNewPostType("individual");
                        }}
                        checked={newPostType === "individual" ? true : false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              {!loading && (
                <div
                  className="socialPostDeleteBtn"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    onClick={() => {
                      if (newPostType === "community") {
                        setCommunityListActive(true);
                        setSelectPostTypeActive(false);
                      } else if (newPostType === "team") {
                        setSelectPostSubTypeActive(true);
                        setSelectPostTypeActive(false);
                      } else if (newPostType === "individual") {
                        setEmployeeSelectionActive(true);
                        setSelectPostTypeActive(false);
                      } else {
                        onSubmit();
                      }
                    }}
                  >
                    Post
                  </button>
                  <button
                    onClick={() => {
                      //   setNewPostActive(true);
                      setSelectPostTypeActive(false);
                    }}
                    style={{ border: "1px solid #1296B0", color: "#1296B0" }}
                  >
                    Back
                  </button>
                </div>
              )}
              {loading && (
                <span
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                    color: "#bfbfbf",
                  }}
                >
                  Posting...
                </span>
              )}
            </div>
          }
          handleClose={() => setIsOpen(false)}
        />
      )}
      {selectPostSubTypeActive && (
        <Popup
          firstClassName="selectPostTypePopupBox1 selectCommunityTypePopupBox1"
          secondClassName="selectPostTypePopupBox2 selectCommunityTypePopupBox2"
          popupHeading="Select Post SubType"
          content={
            <div className="selectPostTypeContainer">
              <div className="selectPostTypeWrapper">
                <div className="selectPostOrganizationContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img src={SocialPostOrganizationIcon} alt="" />
                    </article>
                    <span>Immediate</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={PostSubType === "immediate" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setPostSubType("immediate");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostTeamContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostTeamsIcon}
                        alt=""
                        style={{ height: "20px", paddingTop: "4px" }}
                      />
                    </article>
                    <span>All Report</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={PostSubType === "allreporte" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setPostSubType("allreporte");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              {!loading && (
                <div
                  className="socialPostDeleteBtn"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    onClick={() => {
                      onSubmit();
                    }}
                  >
                    Post
                  </button>
                  <button
                    onClick={() => {
                      setSelectPostSubTypeActive(false);
                      setSelectPostTypeActive(true);
                    }}
                    style={{ border: "1px solid #1296B0", color: "#1296B0" }}
                  >
                    Back
                  </button>
                </div>
              )}
              {loading && (
                <span
                  className="posting-socialpost"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "10px",
                  }}
                >
                  Posting...
                </span>
              )}
            </div>
          }
          handleClose={() => {
            setSelectPostSubTypeActive(false);
            setSelectPostTypeActive(true);
          }}
        />
      )}
      {communityListActive && (
        <Popup
          firstClassName="newPostForEmployeePopup1 CreatePostSelectCommunity"
          secondClassName="newPostForEmployeePopup2 surveynewPostForEmployeePopup2"
          popupHeading="Select Community"
          content={
            <div className="selectPostTypeContainer">
              <div className="commonSearchListContainer surveyCommunitySerach">
                <input
                  type="text"
                  className="commonSearchList"
                  placeholder="Search"
                  onChange={searchCommunity}
                />

                <img src={SearchMagnifierIcon} alt="" />
              </div>
              <div
                className="communityBodyContainer"
                style={{ height: "calc(100vh - 371px)", marginBottom: "67px" }}
              >
                {allCommunityList?.length > 0 &&
                  allCommunityList.map((d, i) => {
                    return (
                      <div className="communityListCreateWrapper">
                        <div className="communityListContainer">
                          <div
                            className="communityListWrapper"
                            style={{ justifyContent: "unset" }}
                          >
                            <div className="communityListImgContainer">
                              <div
                                onClick={(e) => {
                                  // handleUserProfileClick(LoggedInUser?.id);
                                }}
                                className={`${
                                  d?.communityImage
                                    ? "socialConnectProfilePicContainer"
                                    : "socialCommunityCustomProfilePic"
                                }`}
                              >
                                {!d?.communityImage && d?.communityName ? (
                                  getUserInitials(d?.communityName)
                                ) : (
                                  <img
                                    className="socialProfilePic"
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      marginRight: "15px",
                                    }}
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      communityProfileImages +
                                      d?.communityImage
                                    }
                                    alt="Profile"
                                    onError={(e) => {
                                      e.target.src = SocialCommunityGroupIcon;
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="communityListTextContainer">
                              <h5>{d?.communityName}</h5>
                              <p>{d?.communityType}</p>
                            </div>
                          </div>
                          <div className="CommunityListDateTimeWrapper">
                            <div
                              className="forwardMsgChkbox"
                              onClick={() => {
                                setNewPostCommunityId(d?.id);
                              }}
                            >
                              <input
                                type="checkbox"
                                class="myinput large"
                                checked={newPostCommunityId === d?.id}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {!loading && (
                <div
                  className="socialPostDeleteBtn commClass"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    onClick={() => {
                      if (newPostCommunityId === 0) {
                        dispatch(
                          showToast({
                            text: "Please select community to proceed!",
                            severity: "error",
                          })
                        );
                        return;
                      }
                      onSubmit();
                    }}
                  >
                    Post
                  </button>
                  <button
                    onClick={() => {
                      setCommunityListActive(false);
                      setNewPostCommunityId(0);
                      setSelectPostTypeActive(true);
                    }}
                    style={{ border: "1px solid #1296B0", color: "#1296B0" }}
                  >
                    Back
                  </button>
                </div>
              )}
              {loading && (
                <span
                  className="posting-socialpost"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "10px",
                  }}
                >
                  Posting...
                </span>
              )}
            </div>
          }
          handleClose={() => {
            setCommunityListActive(false);
            setSelectPostTypeActive(true);
          }}
        />
      )}
      {employeeSelectionActive && (
        <div className="employeeListInfinitScroll">
          <InfiniteScroll
            dataLength={allEmployeeList?.length}
            next={getEmployeeData}
            hasMore={presentEmployeeData?.length < 20 ? false : true}
            loader={<h4 style={{ padding: "30px" }}> Loading...</h4>}
            height={800}
            width={200}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div style={{ position: "relative", paddingTop:"65px" }}>
              <div className="employeeListInfinitScrollHeader">
                <div className="employeeListInfinitScrollHeaderBackBtn">
                  <IconButton
                    style={{ padding: "10px 7px" }}
                    onClick={() => {
                      setEmployeeSelectionActive(false);
                      setSelectedRecipient([]);
                      setSelectPostTypeActive(true);
                    }}
                  >
                    <img src={ArrowBackButton} alt="" />
                  </IconButton>
                  <p>Select</p>
                </div>
                <IconButton
                  onClick={() => {
                    setEmployeeSelectionActive(false);
                    setSelectedRecipient([]);
                  }}
                >
                  <img src={CloseIcon} alt="" />
                </IconButton>
              </div>
              {allEmployeeList.map((item) => {
                return (
                  <SurveyNotificationsItem
                    item={item}
                    selectedRecipient={selectedRecipient}
                    setSelectedRecipient={setSelectedRecipient}
                  />
                );
              })}
              <div
                style={{
                  width: "30%",
                  position: "fixed",
                  bottom: "7.4%",
                  background: "white",
                  paddingBottom: "20px",
                  borderRadius:"0 0 5px 5px "
                }}
              >
                {!loading && (
                  <div className="socialPostDeleteBtn commClass">
                    <button
                      onClick={() => {
                        if (selectedRecipient === 0) {
                          dispatch(
                            showToast({
                              text: "Please select community to proceed!",
                              severity: "error",
                            })
                          );
                          return;
                        }
                        onSubmit();
                      }}
                    >
                      Post
                    </button>
                    <button
                      onClick={() => {
                        setEmployeeSelectionActive(false);
                        setSelectedRecipient([]);
                        setSelectPostTypeActive(true);
                      }}
                      style={{
                        border: "1px solid #1296B0",
                        color: "#1296B0",
                      }}
                    >
                      Back
                    </button>
                  </div>
                )}
                {loading && (
                  <span
                    className="posting-socialpost"
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "10px",
                    }}
                  >
                    Posting...
                  </span>
                )}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

export default SelectCategory;
