import React from "react";

const CheckHotelConnection = ({
  isEdit,
  onClose,
  navigate,
  setShowTravelPopup,
}) => {
  const isBoth = true;
  const returnUrl = "travel/travelbooking";
  return (
    // <div style={{ width: "326px" }}>
    <div style={{ width: "100%" }}>
      <div
        className="detailInner"
        style={{ fontFamily: "Poppins", fontSize: "15px", marginTop: "0px" }}
      >
        Do you want to create hotel booking request against travel booking
        request ?
      </div>
      <div className="createFootCust" style={{ backgroundColor: "white" }}>
        <div>
          <div
            onClick={() => {
              navigate("/travel/hotelbooking/create");
              onClose();
            }}
            className={`button secondaryButton `}
          >
            No
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              onClose();
              setShowTravelPopup(true);
            }}
            className="button primaryButton"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckHotelConnection;
