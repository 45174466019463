import React, { useState } from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { FormRadioCustomReward } from "../../../Components/FormComponent/FormRadioCustomReward";
import Upward from "../../../../src/Assets/upward.svg";
import Downward from "../../../../src/Assets/downward.svg";
import Trash from "../../../../src/Assets/trash.svg";

function CreateSetAward() {
  const [isopen, setclose] = useState(true);
  const [trash, trashset] = useState(false);

  const trashaccordions = () => {
    trashset(!trash);
  };

  const toggleAccordion = () => {
    setclose(!isopen);
  };

  const DepartmentOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const LocationOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const firstposition = [
    { label: "Prize money with Memento", value: "Prize money with Memento" },
    { label: "Prize money only", value: "Prize money only" },
    { label: "Memento only", value: "Memento only" },
    { label: "Others", value: "Others" },
  ];

  const addAward = () => {};

  return (
    <>
      <div
        className="accordions setawards"
        style={{ display: trash ? "none" : "", transition: "2s" }}
      >
        <div className="accordions-headers">
          <h6>Award 1</h6>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              onClick={trashaccordions}
              className="trash-icon"
              src={isopen ? "" : Trash}
              alt={isopen ? "" : "Delete"}
            />
            <img
              onClick={toggleAccordion}
              src={isopen ? Upward : Downward}
              alt={isopen ? "Open" : "close"}
            />
          </div>
        </div>
        <div
          className="accordions-body"
          style={{ display: isopen ? "block" : "none", transition: "2s" }}
        >
          <div>
            <LabelCustom labelName={"Award Title"} mandatory="true" />
            <InputCustomFormik
              maxLength={150}
              name="title"
              type={"text"}
              placeholder={"Enter"}
            />
          </div>
          <div>
            <LabelCustom labelName={"Description"} mandatory="true" />
            <TextAreaFormik
              maxLength={500}
              placeholder="Type Here"
              style={{ height: "80px" }}
              name={"Description"}
            />
          </div>
          <div
            className="formcustom"
            style={{ display: "grid", columnGap: "9%" }}
          >
            <div>
              <LabelCustom labelName={"Departments"} mandatory="true" />
              <SelectForm
                name={"Departments"}
                placeholder={"Enter"}
                options={DepartmentOptions}
              />
            </div>
            <div>
              <LabelCustom labelName={"Location"} mandatory="true" />
              <SelectForm
                name={"Location"}
                placeholder={"Enter"}
                options={LocationOptions}
              />
            </div>
          </div>

          <div className="winndercategory-bg">
            <h5 className="text-center">Winnder Category</h5>
            <div className="formcustom">
              <div>
                <h5>
                  1<sup>st</sup> Position
                </h5>{" "}
                <hr />
                <div>
                  <div className="radioFormikcustomflex ">
                    <FormRadioCustomReward
                      options={firstposition}
                      name="FirstPosistion"
                      editstyle={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "baseline",
                      }}
                      flexstyle={"directiondown"}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h5>
                  2<sup>nd</sup> Position
                </h5>{" "}
                <hr />
                <div>
                  <div className="radioFormikcustomflex ">
                    <FormRadioCustomReward
                      options={firstposition}
                      name="SecondPosistion"
                      editstyle={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "baseline",
                      }}
                      flexstyle={"directiondown"}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h5>
                  3<sup>st</sup> Position
                </h5>
                <hr />
                <div>
                  <div className="radioFormikcustomflex ">
                    <FormRadioCustomReward
                      options={firstposition}
                      name="ThirdPosistion"
                      editstyle={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "baseline",
                      }}
                      flexstyle={"directiondown"}
                    />
                  </div>
                </div>
              </div>

              <div>
                <h5>Consolation</h5>
                <hr />
                <div>
                  <div className="radioFormikcustomflex ">
                    <FormRadioCustomReward
                      options={firstposition}
                      name="Consolation"
                      editstyle={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "baseline",
                      }}
                      flexstyle={"directiondown"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="buttonaddaward">
        <button
          onClick={{ addAward }}
          className="button primaryButton noMargin"
        >
          &#43; Add Award
        </button>
      </div>
    </>
  );
}

export default CreateSetAward;
