import { FieldArray } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { YesNoFormik } from "../../../../../Components/FormComponent/YesNoFormik";
import { getRequest } from "../../../../../Services/axios";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Table } from "@mui/material";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { Loader } from "../../../../../Components/FormComponent/Loader";

const transfer = [
  { label: "Organization Requirement", value: "Business" },
  { label: "Self Nomination", value: "Self Nomination" },
];
const transferType = [
  { label: "Inter-department", value: "Department to Department" },
  { label: "Department and Location", value: "Both" },
  { label: "Location Change", value: "Location to Location" },
];
const CreateRelocationComp = ({ formValues }) => {
  const [option, setOption] = useState([]);
  const [location, setLocation] = useState([]);
  const getLocation = async () => {
    try {
      const result = await getRequest("RelocationRequests/GetAreas");
      let listData = [];
      result?.data?.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
    }
  };

  const getOption = async () => {
    try {
      const result = await getRequest("RelocationRequests/GetDepartments");
      let listData = [];

      result?.data?.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getOption();
    getLocation();
  }, []);

  useEffect(() => {
    document.title = `PeopleSol - Create Relocation`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"Transfer For "} mandatory={true} />
          <SelectForm
            name={"TransferType"}
            options={transfer}
            placeholder={" Select Transfer For"}
          />
        </div>
        {/* <div>
          <LabelCustom labelName={"Employee"} mandatory={true} />
          <SelectForm
            name={"EmployeeId"}
            options={employeeList}
            placeholder={" Select Employee "}
          />
        </div> */}
        <div>
          <LabelCustom
            labelName={"Transfer Effective Date "}
            mandatory={true}
          />
          <DatePickerFormiks
            name={"TransferEffectiveDate"}
            placeholder={" Select Date "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Type of Transfer "} mandatory={true} />
          <SelectForm
            name={"TypeOfTransfer"}
            options={transferType}
            placeholder={" Select Type of Transfer  "}
          />
        </div>
        {(formValues?.TypeOfTransfer === "Department to Department" ||
          formValues?.TypeOfTransfer === "Both") && (
            <div>
              <LabelCustom labelName={"To Department  "} mandatory={true} />
              <SelectForm
                name={"ToDepartment"}
                options={option}
                placeholder={" Select Department "}
              />
            </div>
          )}
        {(formValues?.TypeOfTransfer === "Location to Location" ||
          formValues?.TypeOfTransfer === "Both") && (
            <div>
              <LabelCustom labelName={"To Location  "} mandatory={true} />
              <SelectForm
                name={"ToLocation"}
                options={location}
                placeholder={" Select Location"}
              />
            </div>
          )}
        <div>
          <LabelCustom labelName={"Need Pre Transfer Trip?"} mandatory={true} />
          <YesNoFormik name="IsPreTransferTripRequired" />
        </div>
        <div>
          <LabelCustom
            labelName={"Need Travel Assistance ?"}
            mandatory={true}
          />
          <YesNoFormik name="IsTravelAssistanceRequired" />
        </div>
        <div>
          <LabelCustom
            labelName={"Need Initial Accomodation ?"}
            mandatory={true}
          />
          <YesNoFormik name="IsInitialAccomodationRequired" />
        </div>
        <div>
          <LabelCustom
            labelName={"Need One-time Relocation Allowance ?"}
            mandatory={true}
          />
          <YesNoFormik name="IsOneTimeRelocationRequired" />
        </div>
        <div>
          <LabelCustom
            labelName={"Need Goods Transfer Assistance ? "}
            mandatory={true}
          />
          <YesNoFormik name="IsHouseHoldGoodsTransferRequired" />
        </div>
      </div>
      {formValues?.IsHouseHoldGoodsTransferRequired === "Yes" && (
        <div style={{ display: "grid" }}>
          <LabelCustom labelName={"Details"} />
          <div className="repeatedSectionContainer">
            <FieldArray name="item">
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { item } = values;
                return (
                  <StyledTableCont>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S. No."} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Transporter Name"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Amount (Rs)"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Quotation"} />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {item.map((item, index) => (
                        <TableBody key={index}>
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            <InputCustomFormik
                              type="name"
                              name={`item[${index}].LogisticName`}
                              placeholder={"Enter LogisticName "}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <InputCustomFormik
                              type="number"
                              step="0.01"
                              name={`item[${index}].Amount`}
                              placeholder={" Select  Amount"}
                            />
                            {/* <div className='form-error'>{errors?.item?.length>0?(errors?.item[index].locationIds):null} </div> */}
                          </StyledTableCell>
                          <StyledTableCell>
                            <FileUploadFormik name={`item[${index}].upload`} />
                            <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                              Size: 2 MB
                            </span>
                            <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                              Format: JPG, PNG, PDF , Doc ,Docx
                            </span>

                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {index > 0 && (
                              <button
                                type="button"
                                className="remove-btn"
                                onClick={() => remove(index)}
                              >
                                -
                              </button>
                            )}
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  LogisticName: "",
                                  Amount: "",
                                  upload: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  </StyledTableCont>
                );
              }}
            </FieldArray>
          </div>
        </div>
      )}
    </>
  );
};

export { CreateRelocationComp };
