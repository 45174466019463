import React, { useState, useEffect } from "react";
import { getRequest } from "../../../../Services/axios";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import ResetPasswordIcon from "../../../../Assets/ResetPasswordIcon.svg";

import "./Style.css";
const opt = [{ label: "select", value: null }];

export const CreateResetPasswordComp = ({ formik }) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Reset Password `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const dispatch = useDispatch();

  const [employeeList, setEmployeeList] = useState([]);

  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <div style={{ padding: "0px" }}>
      <div className="resetPwdContainer">
        <div className="resetPwdImgContainer">
          <div className="resetPwdImgWrapper">
            <img src={ResetPasswordIcon} alt="" />
          </div>
          <div className="resetPwdPolicy">
            <h2>Password Policy</h2>
            <p>1. Should be atleast 8 characters long</p>
            <p>2. Should contain atleast 1 uppercase letter</p>
            <p>3. Should contain atleast 1 lowercase letter</p>
            <p>4. Should contain atleast 1 digit</p>
          </div>
        </div>
        <div className="resetPwdFormContainer" style={{ marginTop: -140 }}>
          <div>
            <LabelCustom labelName={"Employee(s)"} mandatory={true} />
            <SelectFormikMulti
              name={"id"}
              options={employeeList}
              placeholder={" Select Employee "}
              className="selectEmpForResetPwd"
            />
          </div>
          <div>
            <LabelCustom labelName={"New Password"} mandatory={true} />
            <InputCustomFormik
              name="password"
              type={"text"}
              placeholder={"Enter Password"}
            />
          </div>
          <div className="checkBoxMandatory">
            <CheckboxFormiks
              name="isSelected"
              labelName="Send Email"
              mandatory={true}
              onChange={(e) => {
                formik.setFieldValue("isSelected", !e.currentTarget.checked);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
