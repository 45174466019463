import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OrangeInfoIcon from "../../../Assets/OrangeInfoIcon.svg";
import { EditSubmissionComp } from "../SubmissionComp/EditSubmissionComp";
import { format } from "date-fns";
import Nodata from "../../../Assets/Nodata.svg";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import { Loader } from "../../../Components/FormComponent/Loader";
import { TaxNew } from "../DeclarationComp/TaxNew";
import { SubmissionDetails } from "./SubmissionDetails";
import * as Yup from "yup";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import UploadDetail from "../../../Components/UploadDetail/UploadDetail";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { SlabNewTax } from "../DeclarationComp/SlabNewTax";
import { SlabOldTax } from "../DeclarationComp/SlabOldTax";
import { useSelector } from "react-redux";
const TaxSubmission = () => {
  const [editId, setEditId] = useState(null);
  const [id, setId] = useState(1);
  const [values, setValues] = useState(null);
  const [taxId, setTaxId] = useState(null);
  const navigate = useNavigate();
  const [declareActive, setDeclareActive] = useState(0);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [tbodyData, setTbodyData] = useState(false);
  const [data, setData] = useState(false);
  const [detailsform, setDetailsform] = useState(false);
  const [Text, setText] = useState(null);
  const [type, setType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [slabs, setSlabs] = useState("");
  const togglePoppup = (data) => {
    setIsOpen((prev) => !prev);
    setSlabs(data);
  };
  const { LoggedInUser } = useSelector((state) => state.auth);

  const clickHandler = (item) => {
    setActive(item);
  };
  useEffect(() => {
    document.title = `PeopleSol - Submission`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg",
    "application/pdf",
    "application/doc",
  ];
  const formFields = [
    {
      id: 1,
      heading: "Income from sources other than salary",
      compName: "incomeOtherThanSalary",
      body: [
        {
          id: 1,
          label: "Dividend Income",
          value: "dividendIncome",
          fileUpload: "",
        },
        {
          id: 2,
          label: "Interest from saving account",
          value: "interestfromsavingaccount",
          fileUpload: "",
        },
        {
          id: 3,
          label: "Interest from fixed Deposit",
          value: "interestfromfixeddeposit",
          fileUpload: "",
        },
        {
          id: 4,
          label: "Any other income(other than rent)",
          value: "otherIncome",
          fileUpload: "",
        },
      ],
    },
    {
      id: 2,
      heading: "Reimpursement forming part of salary",
      compName: "ReimpursementSalary",
      body: [
        {
          id: 1,
          label: "Car Reimpursement",
          value: "carReimpursement",
          fileUpload: "FileUploadCarReimpursement",
        },
        {
          id: 2,
          label: "Driver Salary Reimpursement",
          value: "driverSalaryReimpursement",
          fileUpload: "FileUploadDriverReimpursement",
        },
        {
          id: 3,
          label: "Books and Periodicals",
          value: "booksAndPeriodicals",
          fileUpload: "FileUploadBooks",
        },
        {
          id: 4,
          label: "Gadget Reimpursement",
          value: "gadgetReimpursement",
          fileUpload: "FileUploadGadget",
        },
        {
          id: 5,
          label: "Medical Expenses Reimpursement",
          value: "medicalExpensesReimpursement",
          fileUpload: "FileUploadMedical",
        },
      ],
    },
    {
      noteText:
        "This section contains the list of investments including LIC schemes, mutual funds and PPF.The maximum limit for this section is ₹1,50,000.00 ",
      id: 3,
      heading: "INVESTMENTS U/S 80C, 80CC",
      compName: "investment80C",
      body: [
        {
          id: 1,
          label: "Housing Loan Repayment Principle",
          value: "houseLoanPrincipal",
          fileUpload: "FileUploadHousingLoan",
        },
        {
          id: 2,
          label: "Public Provident Fund(PPF)",
          value: "ppf",
          fileUpload: "FileUploadPPF",
        },
        {
          id: 3,
          label: "Insurance Premium",
          value: "insurance",
          fileUpload: "FileUploadInsurance",
        },
        {
          id: 4,
          label: "Mutual Fund",
          value: "mutualFund",
          fileUpload: "FileUploadMutualFund",
        },
        {
          id: 5,
          label: "National Saving Scheme / Certificate",
          value: "savingScheme",
          fileUpload: "FileUploadSavingScheme",
        },
        {
          id: 6,
          label: "Sukanya Samriddhi Account",
          value: "sukanyaSamriddhiAccount",
          fileUpload: "FileUploadSamriddhiAccount",
        },
        {
          id: 7,
          label: "Children Education Fees (only School tuition fees)",
          value: "educationFee",
          fileUpload: "FileUploadEducationFee",
        },
        {
          id: 8,
          label: "Allowable Term Deposit",
          value: "termDeposit",
          fileUpload: "FileUploadAllowableTerm",
        },
      ],
    },
    {
      noteText:
        "This section contains the list of investments including LIC schemes, mutual funds and PPF.The maximum limit for this section is ₹1,50,000.00 ",
      id: 4,
      heading: "INVESTMENTS U/S 80CCD",
      compName: "investment80CCD",
      body: [
        {
          id: 1,
          label: "National Pension Scheme",
          value: "employeesContribution",
          fileUpload: "FileUploadEmployeeContribution",
        },
      ],
      maxLimit: "₹50,000",
    },
    {
      noteText:
        "This section contains the list of investments including LIC schemes, mutual funds and PPF.The maximum limit for this section is ₹1,50,000.00 ",
      id: 5,
      heading: "INVESTMENTS U/S 80D",
      compName: "investment80D",
      body: [
        {
          id: 1,
          label: "Medical Insurance Premium for Senior Citizen",
          value: "insurance80d",
          fileUpload: "FileUploadinsurance80d",
        },
        {
          id: 2,
          label: "Expenditure on Medical Treatment for specified disease",
          value: "expenditure80ddb",
          fileUpload: "FileUploadExpenditure80ddb",
        },
        {
          id: 3,
          label:
            "Deduction in maintenance of medical treatment of a handicapped",
          value: "deduction80dd",
          fileUpload: "FileUploadMaintenance",
        },
      ],
      maxLimit: "₹25,000",
    },
    {
      noteText:
        "This section contains the list of investments including LIC schemes, mutual funds and PPF.The maximum limit for this section is ₹1,50,000.00 ",
      id: 6,
      heading: "INVESTMENTS U/S 80E",
      compName: "investment80E",
      body: [
        {
          id: 1,
          label: "Repayment of loan taken for Higher Education",
          value: "education80e",
          fileUpload: "FileUploadEducation80e",
        },
      ],
    },
    {
      id: 7,
      heading: "Any other Investment qualify for IT Deduction",
      compName: "investmentOther",
      body: [
        {
          id: 1,
          label: "Leave Travel Assistance (LTA)",
          value: "leaveTravelAssisatant",
          fileUpload: "FileUploadLeave",
        },
        {
          id: 2,
          label: "Permanent Physical Disability",
          value: "ppd80u",
          fileUpload: "FileUploadPhysicalDisability",
        },
        {
          id: 3,
          label: "Any other Investment",
          value: "otherInvestmentQualify",
          fileUpload: "FileUploadotherInvestmentQualify",
        },
      ],
    },
  ];

  const getTaxDetails = async (val) => {
    try {
      setLoading(true);
      const result = await getRequest(`taxdeclaration/EditForm/?ID=${val}`);
      setValues(result?.data?.data?.taxDeclarartion);
      setTaxId(result?.data?.data?.taxDeclarartion?.taxDeclarationSettings?.id);
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const reqData = {
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 20,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  };

  const getListData = async () => {
    try {
      setLoading(true);
      setLoading(true);
      const result = await postRequest(
        "TaxDeclaration/SubmissionList",
        reqData
      );
      if (
        result.data &&
        LoggedInUser?.permissions?.includes(
          "Is Eligible For Income Tax Declaration?"
        )
      ) {
        let tempData = [];
        let ogData = [];
        result?.data?.data?.map((item) => {
          return (
            <>
              {tempData.push({
                ...item.item,
                status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              })}
              {ogData.push({
                ...item,
                // ...item.actions?.map(x => x),
              })}
            </>
          );
        });
        setOriginalData(ogData);
        const clippedArray = tempData?.map(
          ({ attachment, createdOn, lastUpdatedOn, ...rest }) => ({ ...rest })
        );
        const finalArray = clippedArray.map(({ id, ...rest }) => ({
          id,
          ...rest,
        }));
        setTbodyData(finalArray);
      } else {
        setData("You Don't have required permissions to view the content");
        setTbodyData(false);
      }
    } catch (error) {
      setTbodyData(false);
    } finally {
      setLoading(false);
    }
  };

  const getEditDetails = async (id) => {
    try {
      setLoading(true);
      const result = await getRequest(`TaxDeclaration/GetDeclaredDetails`);
      setDetailsform(result?.data?.summary?._taxDeclarartion);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("submission")) getListData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initialValue = {
    TaxRate:
      type === "Update Tax Declaration"
        ? values?.taxRate
        : detailsform?.taxRate,
    tenantName:
      type === "Update Tax Declaration"
        ? values?.tenantName
        : detailsform?.tenantName,
    LandlordPan:
      type === "Update Tax Declaration"
        ? values?.landlordPan
        : detailsform?.landlordPan,
    leaveTravelAssisatant:
      type === "Update Tax Declaration"
        ? values?.leaveTravelAssisatant
        : detailsform?.leaveTravelAssisatant,
    HouseLoanPrincipal:
      type === "Update Tax Declaration"
        ? values?.houseLoanPrincipal
        : detailsform?.houseLoanPrincipal,
    HouseLoanInterest:
      type === "Update Tax Declaration"
        ? values?.houseLoanInterest
        : detailsform?.houseLoanInterest,
    EmployeesContribution:
      type === "Update Tax Declaration"
        ? values?.employeesContribution
        : detailsform?.employeesContribution,
    rentReceived:
      type === "Update Tax Declaration"
        ? values?.rentReceived
        : detailsform?.rentReceived,
    taxPaid:
      type === "Update Tax Declaration"
        ? values?.taxPaid
        : detailsform?.taxPaid,
    netValue:
      type === "Update Tax Declaration"
        ? values?.netValue
        : detailsform?.netValue,
    standardDeduction:
      type === "Update Tax Declaration"
        ? values?.standardDeduction
        : detailsform?.standardDeduction,
    incomeLossfromHouseProperty:
      type === "Update Tax Declaration"
        ? values?.incomeLossfromHouseProperty
        : detailsform?.incomeLossfromHouseProperty,
    DividendIncome:
      type === "Update Tax Declaration"
        ? values?.dividendIncome
        : detailsform?.dividendIncome,
    Interestfromsavingaccount:
      type === "Update Tax Declaration"
        ? values?.interestfromsavingaccount
        : detailsform?.interestfromsavingaccount,
    Interestfromfixeddeposit:
      type === "Update Tax Declaration"
        ? values?.interestfromfixeddeposit
        : detailsform?.interestfromfixeddeposit,
    OtherIncome:
      type === "Update Tax Declaration"
        ? values?.otherIncome
        : detailsform?.otherIncome,
    RemarksforOtherIncome:
      type === "Update Tax Declaration"
        ? values?.remarksforOtherIncome
        : detailsform?.remarksforOtherIncome,
    PropertyAddress:
      type === "Update Tax Declaration"
        ? values?.propertyAddress
        : detailsform?.propertyAddress,
    IncomeHouseProp:
      type === "Update Tax Declaration"
        ? values?.incomeHouseProp
        : detailsform?.incomeHouseProp,
    HouseRentPaid:
      type === "Update Tax Declaration"
        ? values?.houseRentPaid
        : detailsform?.houseRentPaid,
    Ppf: type === "Update Tax Declaration" ? values?.ppf : detailsform?.ppf,
    Insurance:
      type === "Update Tax Declaration"
        ? values?.insurance
        : detailsform?.insurance,
    SavingScheme:
      type === "Update Tax Declaration"
        ? values?.savingScheme
        : detailsform?.savingScheme,
    MutualFund:
      type === "Update Tax Declaration"
        ? values?.mutualFund
        : detailsform?.mutualFund,
    SukanyaSamriddhiAccount:
      type === "Update Tax Declaration"
        ? values?.sukanyaSamriddhiAccount
        : detailsform?.sukanyaSamriddhiAccount,
    EducationFee:
      type === "Update Tax Declaration"
        ? values?.educationFee
        : detailsform?.educationFee,
    TermDeposit:
      type === "Update Tax Declaration"
        ? values?.termDeposit
        : detailsform?.termDeposit,
    Investment80d:
      type === "Update Tax Declaration"
        ? values?.investment80d
        : detailsform?.investment80d,
    Insurance80d:
      type === "Update Tax Declaration"
        ? values?.insurance80d
        : detailsform?.insurance80d,
    Expenditure80ddb:
      type === "Update Tax Declaration"
        ? values?.expenditure80ddb
        : detailsform?.expenditure80ddb,
    Education80e:
      type === "Update Tax Declaration"
        ? values?.education80e
        : detailsform?.education80e,
    Deduction80dd:
      type === "Update Tax Declaration"
        ? values?.deduction80dd
        : detailsform?.deduction80dd,
    Ppd80u:
      type === "Update Tax Declaration" ? values?.ppd80u : detailsform?.ppd80u,
    OtherInvestmentQualify:
      type === "Update Tax Declaration"
        ? values?.otherInvestmentQualify
        : detailsform?.otherInvestmentQualify,
    QualifyRemarks:
      type === "Update Tax Declaration"
        ? values?.qualifyRemarks
        : detailsform?.qualifyRemarks,
    CarReimpursement:
      type === "Update Tax Declaration"
        ? values?.carReimpursement
        : detailsform?.carReimpursement,
    DriverSalaryReimpursement:
      type === "Update Tax Declaration"
        ? values?.driverSalaryReimpursement
        : detailsform?.driverSalaryReimpursement,
    BooksAndPeriodicals:
      type === "Update Tax Declaration"
        ? values?.booksAndPeriodicals
        : detailsform?.booksAndPeriodicals,
    GadgetReimpursement:
      type === "Update Tax Declaration"
        ? values?.gadgetReimpursement
        : detailsform?.gadgetReimpursement,
    MedicalExpensesReimpursement:
      type === "Update Tax Declaration"
        ? values?.medicalExpensesReimpursement
        : detailsform?.medicalExpensesReimpursement,
    LeaveTravelAssistant:
      type === "Update Tax Declaration"
        ? values?.leaveTravelAssistant
        : detailsform?.leaveTravelAssistant,
    FileUploadPropertyAddress:
      type === "Update Tax Declaration"
        ? values?.fileUploadPropertyAddress
          ? `tax/${values?.fileUploadPropertyAddress}`
          : ""
        : "",
    fileUploadHouseRentPaid:
      type === "Update Tax Declaration"
        ? values?.uploadHouseRentPaid
          ? `tax/${values?.uploadHouseRentPaid}`
          : ""
        : "",
    FileUploadLandlordPan:
      type === "Update Tax Declaration"
        ? values?.fileUploadLandlordPan
          ? `tax/${values?.fileUploadLandlordPan}`
          : ""
        : "",
    FileUploadLandLordAddressOfProperty:
      type === "Update Tax Declaration"
        ? values?.fileUploadLandLordAddressOfProperty
          ? `tax/${values?.fileUploadLandLordAddressOfProperty}`
          : ""
        : "",
    FileUploadHousingLoan:
      type === "Update Tax Declaration"
        ? values?.housingLoanUpload
          ? `tax/${values?.housingLoanUpload}`
          : ""
        : "",
    FileUploadPPF:
      type === "Update Tax Declaration"
        ? values?.ppfupload
          ? `tax/${values?.ppfupload}`
          : ""
        : "",
    FileUploadInsurance:
      type === "Update Tax Declaration"
        ? values?.insuranceUpload
          ? `tax/${values?.insuranceUpload}`
          : ""
        : "",
    FileUploadSavingScheme:
      type === "Update Tax Declaration"
        ? values?.uploadSavingScheme
          ? `tax/${values?.uploadSavingScheme}`
          : ""
        : "",
    FileUploadMutualFund:
      type === "Update Tax Declaration"
        ? values?.uploadMutualFund
          ? `tax/${values?.uploadMutualFund}`
          : ""
        : "",
    FileUploadSamriddhiAccount:
      type === "Update Tax Declaration"
        ? values?.samriddhiAccountUpload
          ? `tax/${values?.samriddhiAccountUpload}`
          : ""
        : "",
    FileUploadEducationFee:
      type === "Update Tax Declaration"
        ? values?.uploadEducationFee
          ? `tax/${values?.uploadEducationFee}`
          : ""
        : "",
    FileUploadAllowableTerm:
      type === "Update Tax Declaration"
        ? values?.allowableTermUpload
          ? `tax/${values?.allowableTermUpload}`
          : ""
        : "",
    FileUploadEmployeeContribution:
      type === "Update Tax Declaration"
        ? values?.employeeContribution80ccdUpload
          ? `tax/${values?.employeeContribution80ccdUpload}`
          : ""
        : "",
    FileUploadinsurance80d:
      type === "Update Tax Declaration"
        ? values?.uploadInsurance80d
          ? `tax/${values?.uploadInsurance80d}`
          : ""
        : "",
    FileUploadExpenditure80ddb:
      type === "Update Tax Declaration"
        ? values?.uploadExpenditure80ddb
          ? `tax/${values?.uploadExpenditure80ddb}`
          : ""
        : "",
    FileUploadEducation80e:
      type === "Update Tax Declaration"
        ? values?.uploadEducation80e
          ? `tax/${values?.uploadEducation80e}`
          : ""
        : "",
    FileUploadMaintenance:
      type === "Update Tax Declaration"
        ? values?.maintenanceUpload
          ? `tax/${values?.maintenanceUpload}`
          : ""
        : "",
    FileUploadPhysicalDisability:
      type === "Update Tax Declaration"
        ? values?.physcialDisabiliyUpload
          ? `tax/${values?.physcialDisabiliyUpload}`
          : ""
        : "",
    FileUploadotherInvestmentQualify:
      type === "Update Tax Declaration"
        ? values?.otherInvestmentQualifyUpload
          ? `tax/${values?.otherInvestmentQualifyUpload}`
          : ""
        : "",
    FileUploadCarReimpursement:
      type === "Update Tax Declaration"
        ? values?.carReimpursementUpload
          ? `tax/${values?.carReimpursementUpload}`
          : ""
        : "",
    FileUploadDriverReimpursement:
      type === "Update Tax Declaration"
        ? values?.driverSalaryUpload
          ? `tax/${values?.driverSalaryUpload}`
          : ""
        : "",
    FileUploadBooks:
      type === "Update Tax Declaration"
        ? values?.booksandPeriodicalsUpload
          ? `tax/${values?.booksandPeriodicalsUpload}`
          : ""
        : "",
    FileUploadGadget:
      type === "Update Tax Declaration"
        ? values?.gadgetUpload
          ? `tax/${values?.gadgetUpload}`
          : ""
        : "",
    FileUploadMedical:
      type === "Update Tax Declaration"
        ? values?.medicalReimpursementUpload
          ? `tax/${values?.medicalReimpursementUpload}`
          : ""
        : "",
    FileUploadLeave:
      type === "Update Tax Declaration"
        ? values?.leaveUpload
          ? `tax/${values?.leaveUpload}`
          : ""
        : "",
    Comment1: type === "Update Tax Declaration" ? values?.comment1 : "",
    Comment2: type === "Update Tax Declaration" ? values?.comment2 : "",
    IsAgree: type === "Update Tax Declaration" ? values?.isAgree : "",
  };
  const createPageData = async (values) => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequestForm(`taxdeclaration/CreateForm`, values);
    } catch {
    } finally {
      setDeclareActive(3);
      getEditDetails();
      setLoading(false);
    }
  };
  const editPageData = async (values) => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequestForm(
        `taxdeclaration/EditForm/?ID=${editId}`,
        values
      );
    } catch {
    } finally {
      setLoading(false);
      setDeclareActive(3);
      getTaxDetails(editId);
    }
  };
  const onSubmit = async (values) => {
    //   setId(3);
    const formData = new FormData();
    // if(values?.carReimpursement)
    // formData.append("carReimpursement", values?.carReimpursement);
    if (detailsform || values) {
      if (taxId) formData.append("TaxDeclarationSettingsId", taxId);
      else {
        formData.append("TaxDeclarationSettingsId", editId);
      }
      formData.append("Id", editId);
      formData.append(
        "Email",
        detailsform?.employee?.email ?? values?.employee?.email
      );
      formData.append(
        "Pan",
        detailsform?.employee?.panNumber ?? values?.employee?.panNumber
      );
    }

    for (const property in values) {
      if (values.hasOwnProperty(property)) {
        const value = values[property];
        if (value !== null && value !== "") {
          formData.append(property, value);
        }
      }
    }

    type === "Update Tax Declaration"
      ? editPageData(formData)
      : createPageData(formData);
  };
  if (loading) return <Loader />;
  else if (data)
    return (
      <div className="noDatastyle">
        <img
          src={Nodata}
          alt="No data"
          style={{ height: "150px", width: "250px" }}
        />
        <h4>{data ? data : "No Data Found"}</h4>
      </div>
    );
  else
    return (
      <div
        className="fullTableContainer"
        style={{ overflow: "auto", width: "100%", height: "100%" }}
      >
        {declareActive === 0 && (
          <div
            className="boxViewContainer "
            style={{ width: "100%", height: "100%" }}
          >
            <div className="homeCardsHolderContainer">
              <div className="homeDashboardCardHolder" key={1}>
                {originalData &&
                  originalData?.map((item, index) => {
                    return (
                      <div className="dashboardCard internalJobCard">
                        <div className="cardContainer cursor">
                          <div className="headingContainer">
                            <h3
                              style={{
                                fontSize: "16px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.item?.title}
                            </h3>
                          </div>
                          <div
                            className="jobDetailContainerHolder"
                            // style={{ padding: "7px 0px 23px 11px" }}
                          >
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Start Date
                              </label>
                              <label>
                                {" "}
                                {item?.item?.startDate &&
                                  format(
                                    new Date(item?.item?.startDate),
                                    "dd/MM/yyyy "
                                  )}
                              </label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">End Date</label>
                              <label>
                                {" "}
                                {item?.item?.endDate &&
                                  format(
                                    new Date(item?.item?.endDate),
                                    "dd/MM/yyyy "
                                  )}
                              </label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">Status</label>
                              <label>
                                {" "}
                                <div
                                  style={{
                                    backgroundColor: getStatusColor(
                                      item?.item?.status
                                    )?.split(",")?.[0],
                                    color: getStatusColor(
                                      item?.item?.status
                                    )?.split(",")?.[1],
                                    cursor: "default",
                                    padding: "3px 6px",
                                    width: "fit-content",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {item?.item?.status}
                                </div>
                              </label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">Document</label>
                              {item?.item?.attachment ? (
                                <label>
                                  <UploadDetail
                                    filepath={`tax/${item?.item?.attachment}`}
                                  />
                                </label>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                          <div
                            className="applyJobButtonContainer "
                            style={{ flexWrap: "wrap" }}
                          >
                            {item?.actions?.map((itemData, index) => {
                              return (
                                <>
                                  {itemData?.type ===
                                    "Update Investment Proof" && (
                                    <Tooltip
                                      arrow
                                      title={"Update Investment Proof"}
                                      placement="top"
                                    >
                                      <button
                                        // style={{ background: itemData?.color }}
                                        onClick={() => {
                                          setDeclareActive(1);
                                          getTaxDetails(itemData?.id);
                                          setText(item?.item?.title);
                                          setType("Update Tax Declaration");
                                          setEditId(itemData?.id);
                                        }}
                                        className="button primaryButton"
                                      >
                                        {itemData?.type}
                                      </button>
                                    </Tooltip>
                                  )}
                                  {itemData?.type ===
                                    "Submit Investment Proof" && (
                                    <Tooltip
                                      arrow
                                      title={"Submit Investment Proof"}
                                      placement="top"
                                    >
                                      <button
                                        // style={{ background: itemData?.color }}
                                        onClick={() => {
                                          setDeclareActive(1);
                                          getEditDetails(itemData?.id);
                                          setType("Submit Tax Declaration");
                                          setText(item?.item?.title);
                                          setEditId(itemData?.id);
                                        }}
                                        className="button primaryButton"
                                      >
                                        {itemData?.type}
                                      </button>
                                    </Tooltip>
                                  )}
                                  {itemData?.type === "Details" && (
                                    <Tooltip
                                      arrow
                                      title={"Details"}
                                      placement="top"
                                    >
                                      <button
                                        // style={{ background: itemData?.color }}
                                        onClick={() => {
                                          setDeclareActive(3);
                                          getTaxDetails(itemData?.id);
                                          setId(3);
                                          setText(item?.item?.title);
                                        }}
                                        className="button primaryButton"
                                      >
                                        {itemData?.type}
                                      </button>
                                    </Tooltip>
                                  )}
                                </>
                              );
                            })}
                            {item?.actions?.length === 0 && (
                              <Tooltip
                                arrow
                                title={"No Actions"}
                                placement="top"
                              >
                                <div className="noActionsTextContainer">
                                  <label>No Actions for this Declaration</label>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {declareActive === 1 && (
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
              fileUploadHouseRentPaid:
                values?.houseRentPaid &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",
                    "File Size should be less than 1 mb",
                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )
                  .test(
                    "format",
                    "File Format is Incorrect",
                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),
              FileUploadDriverReimpursement:
                values?.driverSalaryReimpursement &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadCarReimpursement:
                values?.carReimpursement &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadBooks:
                values?.booksAndPeriodicals &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadGadget:
                values?.gadgetReimpursement &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadMedical:
                values?.medicalExpensesReimpursement &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadHousingLoan:
                values?.houseLoanPrincipal &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadPPF:
                values?.ppf &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadInsurance:
                values?.insurance &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadMutualFund:
                values?.mutualFund &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadSavingScheme:
                values?.savingScheme &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadSamriddhiAccount:
                values?.sukanyaSamriddhiAccount &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadEducationFee:
                values?.educationFee &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadAllowableTerm:
                values?.termDeposit &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadEmployeeContribution:
                values?.employeesContribution &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadinsurance80d:
                values?.insurance80d &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadExpenditure80ddb:
                values?.expenditure80ddb &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",
                    "File Size should be less than 1 mb",
                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadMaintenance:
                values?.deduction80dd &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadEducation80e:
                values?.education80e &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadLeave:
                values?.leaveTravelAssisatant &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadPhysicalDisability:
                values?.ppd80u &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),

              FileUploadotherInvestmentQualify:
                values?.otherInvestmentQualify &&
                Yup.mixed("Please upload file")
                  .required("Please upload file")
                  .test(
                    "Fichier taille",

                    "File Size should be less than 1 mb",

                    (value) => {
                      return (
                        !value ||
                        (value && value.size <= 2048 * 2048) ||
                        typeof value !== "object"
                      );
                    }
                  )

                  .test(
                    "format",

                    "File Format is Incorrect",

                    (value) =>
                      !value ||
                      (value && SUPPORTED_FORMATS.includes(value.type)) ||
                      typeof value !== "object"
                  ),
            })}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="feedbackFormFooter">
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="submitDeclareContainer">
                        <div
                          className="returnToDashboard cursor"
                          onClick={() => {
                            setDeclareActive(0);
                            getListData();
                          }}
                        >
                          <svg
                            width="20"
                            height="8"
                            viewBox="0 0 20 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.243615 3.74484C0.0483524 3.9401 0.0483524 4.25668 0.243615 4.45194L3.4256 7.63392C3.62086 7.82918 3.93744 7.82918 4.1327 7.63392C4.32796 7.43866 4.32796 7.12208 4.1327 6.92682L1.30427 4.09839L4.1327 1.26996C4.32796 1.0747 4.32796 0.758117 4.1327 0.562855C3.93744 0.367593 3.62086 0.367593 3.4256 0.562855L0.243615 3.74484ZM18.624 4.59839C18.9002 4.59839 19.124 4.37453 19.124 4.09839C19.124 3.82225 18.9002 3.59839 18.624 3.59839V4.59839ZM0.597168 4.59839H18.624V3.59839H0.597168V4.59839Z"
                              fill="#1296B0"
                            />
                          </svg>

                          <label className="returnLabelHolder">{Text}</label>
                        </div>

                        <div
                          className="taxRegimeContainer"
                          style={{ height: "fit-content" }}
                        >
                          {id === 1 ? (
                            <div
                              className="computeTaxContainer"
                              style={{ background: "#FFF4E5" }}
                            >
                              <IconButton
                                onClick={() => {
                                  togglePoppup();
                                }}
                              >
                                <img
                                  src={OrangeInfoIcon}
                                  alt={OrangeInfoIcon}
                                />
                              </IconButton>
                              <label
                                style={{
                                  fontWeight: "500",
                                  paddingLeft: "7px",
                                }}
                              >
                                Tax Regime{" "}
                                <span
                                  className="cursor"
                                  style={{
                                    color: "var(--secondary)",
                                    fontWeight: "400",
                                  }}
                                  onClick={() => setId(2)}
                                >
                                  :{" "}
                                  {type === "Update Tax Declaration"
                                    ? values?.taxRate
                                    : detailsform?.taxRate}
                                </span>{" "}
                              </label>
                            </div>
                          ) : (
                            <>
                              <TaxNew detailsform={detailsform} />
                            </>
                          )}
                        </div>
                        <div
                          className="headingForm"
                          style={{
                            borderBottom: "1.75px solid #1296b0",
                            height: "30px",
                            width: "97%",
                            alignSelf: "center",
                            marginBottom: "18px",
                          }}
                        >
                          <label style={{ flex: "4" }}>PARTICULARS</label>
                          <label style={{ flex: "2" }}>DECLARED AMOUNT</label>
                          <label style={{ flex: "2" }}>INVESTMENT PROOFS</label>
                          <label style={{ flex: "2" }}>INVESTED AMOUNT</label>
                        </div>
                        {!detailsform && !values ? (
                          <div className="noDatastyle">
                            <img
                              src={Nodata}
                              alt="No data"
                              style={{ height: "150px", width: "250px" }}
                            />
                            <h4>{"Please Declare Tax Before Submission"}</h4>
                          </div>
                        ) : (
                          <EditSubmissionComp
                            getListData={getListData}
                            setDeclareActive={setDeclareActive}
                            formValues={formik.values}
                            formFields={formFields}
                            detailsform={
                              type === "Update Tax Declaration"
                                ? values
                                : detailsform
                            }
                            declareActive={declareActive}
                            active={active}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
        {declareActive === 3 && (
          <>
            {loading ? (
              <Loader />
            ) : (
              <div className="submitDeclareContainer">
                <div
                  className="returnToDashboard cursor"
                  onClick={() => {
                    setDeclareActive(0);
                    getListData();
                  }}
                >
                  <svg
                    width="20"
                    height="8"
                    viewBox="0 0 20 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.243615 3.74484C0.0483524 3.9401 0.0483524 4.25668 0.243615 4.45194L3.4256 7.63392C3.62086 7.82918 3.93744 7.82918 4.1327 7.63392C4.32796 7.43866 4.32796 7.12208 4.1327 6.92682L1.30427 4.09839L4.1327 1.26996C4.32796 1.0747 4.32796 0.758117 4.1327 0.562855C3.93744 0.367593 3.62086 0.367593 3.4256 0.562855L0.243615 3.74484ZM18.624 4.59839C18.9002 4.59839 19.124 4.37453 19.124 4.09839C19.124 3.82225 18.9002 3.59839 18.624 3.59839V4.59839ZM0.597168 4.59839H18.624V3.59839H0.597168V4.59839Z"
                      fill="#1296B0"
                    />
                  </svg>
                  <label className="returnLabelHolder">{Text}</label>
                </div>
                <div
                  className="headingForm"
                  style={{
                    borderBottom: "1.75px solid #1296b0",
                    height: "30px",
                    width: "97%",
                    alignSelf: "center",
                  }}
                >
                  <label style={{ flex: "4" }}>PARTICULARS</label>
                  <label style={{ flex: "3" }}> AMOUNT</label>
                  <label style={{ flex: "3" }}>PROOFS</label>
                </div>
                <SubmissionDetails
                  formFields={formFields}
                  detailsform={
                    type === "Submit Tax Declaration" ? detailsform : values
                  }
                  declareActive={declareActive}
                />
                <div className="horizontalLine"></div>
              </div>
            )}
          </>
        )}
        {isOpen && (
          <Popup
            handleClose={togglePoppup}
            popupHeading={
              slabs === "New Tax Regime"
                ? "New Tax Regime Deduction Slab"
                : "Old Tax Regime Deduction Slab"
            }
            content={
              slabs === "New Tax Regime" ? (
                <SlabNewTax handleClose={togglePoppup} />
              ) : (
                <SlabOldTax handleClose={togglePoppup} />
              )
            }
          />
        )}
      </div>
    );
};

export { TaxSubmission };
