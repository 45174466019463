import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { StepsHierarchy } from "../../../Components/FormComponent/StepsHierarchy";
import { WorkflowStepsComp } from "../../../Components/FormComponent/WorkflowStepsComp";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailsApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState([]);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Applications/Details/${selectedId}`);
      // //console.log("id", result.data);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Details Application`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  // //console.log("eventtData", eventtData);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
        <div className="moduleMasterDetailsPageBody">
          <DetailsStep detailTitle={"Name"} Details={eventtData?.name} />
          <DetailsStep
            detailTitle={"URL"}
            Details={
              <a href={eventtData?.url} target="blank">
                {eventtData?.url}
              </a>
            }
          />
          <DetailsStep
            detailTitle={"App Link"}
            Details={
              <a href={eventtData?.appUrl} target="blank">
                {eventtData?.appUrl}
              </a>
            }
          />
          <DetailsStep
            detailTitle={"Description"}
            Details={eventtData?.description}
          />
          <DetailsStep
            detailTitle={"Employees"}
            Details={eventtData?.employeeName}
          />
          <DetailsStep detailTitle={"Colour"} Details={eventtData?.colour} />
          <DetailsStep
            detailTitle={"Icon"}
            Details={
              <a
                href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ApplicationIcon/${eventtData?.icon}`}
                target="blank"
              >
                {eventtData?.icon}
              </a>
            }
          />
          {/* <DetailsStep
            detailTitle={"Created On"}
            Details={eventtData?.createdOn?.replace("T", " ")}
          />
          <DetailsStep
            detailTitle={"Last Updated On"}
            Details={eventtData?.lastUpdatedOn?.replace("T", " ")}
          /> */}

          <DetailsStep
            detailTitle={"Created On"}
            Details={format(
              new Date(eventtData?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
          <DetailsStep
            detailTitle={"Last Updated On"}
            Details={format(
              new Date(eventtData?.lastUpdatedOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
        </div>
        )}
      </div>
    </div>
  );
};

export { DetailsApplication };
