import * as Yup from "yup";

export const teamsSchema = Yup.object({
  name: Yup.string().max(149," Name must be less than 150 characters").required(
    " Please Enter Name"
  ),
  teamsMembers: Yup.array().of(
    Yup.object().shape({
      employeeId: Yup.string().required("Please Enter EmployeeId"),
      level: Yup.string().required("Please Select Level"),
      locationIds: Yup
      .array()
      .min(1, `Please Select Atleast One Location `).required(' required')
    })
  )
})
  
  


