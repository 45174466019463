import React, { useCallback, useEffect, useState } from 'react'
import "./GroupInfo.css";
import { confirmAlert } from 'react-confirm-alert';
// import { Switch } from '@mui/material';
import { ProfileGroup } from '../MyProfileDetails/ProfileGroup';
import { Popup } from '../../../../Components/FormComponent/PopupComponent/Popup';
import NewGroup from '../NewGroup/NewGroup';
import SelectedNewGroupMembers from '../NewGroup/SelectedNewGroupMembers';
import SaveIcon from "../../../../Assets/SaveTickIcon.svg"
import BraodcastIcon from "../../../../Assets/Broadcast.svg"
import Delete from "../../../../Assets/DeleteIcon.svg"
import { Loader } from '../../../../Components/FormComponent/Loader';
import AddToFavourite from "../../../../Assets/AddToFavourite.svg"
import RemoveFromFavourite from "../../../../Assets/RemoveFromFavourite.svg"
import MediaIcon from "../../../../Assets/MediaIcon.svg"
import LinkIcon from "../../../../Assets/LinkIcon.svg"
import DocIcon from "../../../../Assets/DocIcon.svg"
import GroupInviteIcon from "../../../../Assets/GroupInviteIcon.svg"


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../../Features';
function GroupInfo(props) {


    useEffect(() => {
        document.title = `PeopleSol - Group Info`;
        return () => {
            document.title = 'PeopleSol';
        };
    })
    const dispatch = useDispatch();

    // useEffect(() => {
    //     document.addEventListener("mouseup", groupInfoHandler);
    //     return () => {
    //         document.removeEventListener("mouseup", groupInfoHandler);
    //     };
    // }, [groupInfoHandler]);
    const groupInfoHandler = useCallback((e) => {
        let moreActivity = document.querySelector(".groupInfoMainContainer");
        if (moreActivity && !moreActivity?.contains(e.target)) {
            props.setGroupInfo(false);
        }
    }, []);

    const [addMembers, setShowMembers] = useState(false)
    const [showGroupMembers, setShowNewGroupMember] = useState(false)
    const [selectedGroupMember, setSelectedGroupMember] = useState([])
    const [isMuted, setMuted] = useState(props?.activeChat?.isMute)
    const [editName, setEditName] = useState(false)
    const userDetails = useSelector(state => state.chat.userDetails);
    const [name, setNewName] = useState("");
    const isAdmin = props.activeChat.participateMembers.filter((item) => (item.isAdmin && userDetails._id == item.contactId)).length > 0 ? true : false
    const [loading, setLoading] = useState(false)
    const toggleNewGroup = () => {
        console.log("-------- setting to zero")
        setShowMembers(false)
        setSelectedGroupMember([])
    }


    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true)
            const formData = new FormData();
            formData.append("files", file);

            // Perform the API request
            const response = await fetch(
                "https://media.solunxt.com/api/v1/Storage/Save?token=5b4cbd69-2366-4708-871c-5300a4dc6f18",
                {
                    method: "POST",
                    body: formData,
                }
            );
            const responseData = await response.json();
            setLoading(false)
            if (responseData.succeeded) {
                handleEvent({ "conversationId": props?.activeChat?._id, "reqType": "updateProfilePic", "updateVal": responseData.data[0].url })
            }

        }
    };

    const handleEvent = (data, type) => {
        console.log("=========== calling event", data);
        if (props.socket) {

            if (type === "addOrRemove") {
                props.socket.emit("modifiedGroupMember", (data), (res) => {
                    props.refereshConversation(props.socket, "refresh")
                    setShowMembers(false)
                    setSelectedGroupMember([])
                    dispatch(
                        showToast({ text: "Group updated successfully!", severity: "success" })
                    );
                })
            } else if (type === "updateConvo") {
                props.socket.emit("updateMyConversation", (data), (res) => {
                    props.refereshConversation(props.socket, "refresh")
                    dispatch(
                        showToast({ text: "Request successfully executed!", severity: "success" })
                    );
                })
            } else if (type === "exit") {
                props.socket.emit("exitFromGroup", data, (res) => {
                    props.refereshConversation(props.socket, "refresh")
                    dispatch(
                        showToast({ text: "Request successfully executed!", severity: "success" })
                    );
                })

            } else {
                props.socket.emit("updateGroupSetting", (data), (res) => {
                    props.refereshConversation(props.socket, "refresh")
                    dispatch(
                        showToast({ text: "Request successfully executed!", severity: "success" })
                    );
                })
            }
        }
    }
    const handleAddNewMembers = (members) => {
        let a = [];
        for (let i = 0; i < members.length; i++) {
            a.push(members[i].personalNumber);
        }
        handleEvent({ "conversationId": props?.activeChat?._id, "reqType": "addMember", "memberPayload": [...a] }, "addOrRemove")
    }

    const Tabs = () => {
        return (
            <div onClick={() => props.toggleMedia()} style={{ borderStyle: "solid", borderWidth: 1, borderColor: "rgba(216, 240, 242, 1)", borderRadius: 4, padding: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", margin: 20 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#555555", backgroundColor: "white", padding: 10, borderRadius: 4 }}>
                    <img src={MediaIcon} />
                    <div style={{ marginLeft: 10 }}>{"Media"}</div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#555555", backgroundColor: "white", padding: 10, borderRadius: 4 }}>
                    <img src={LinkIcon} />
                    <div style={{ marginLeft: 10 }}>{"Links"}</div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#555555", backgroundColor: "white", padding: 10, borderRadius: 4 }}>
                    <img src={DocIcon} />
                    <div style={{ marginLeft: 10 }}>{"Docs"}</div>
                </div>
            </div>
        )
    }
    return (
        <>
            {loading && (
                <div className="document-preview-container-uploading">
                    <div
                        style={{
                            color: "rgb(51, 51, 51)",
                            padding: "20px",
                            borderRadius: "5px",
                            fontSize: "17px",
                            textAlign: "center",
                            width: "500px",
                            backgroundColor: "white",
                        }}
                    >
                        <div style={{ marginTop: "0px" }}>
                            {" "}
                            <Loader />
                        </div>
                    </div>
                </div>
            )}
            {/* {!activeAttachment && */}
            <div className="groupInfoMainContainer">
                <div className="groupInfoHeaderContainer">
                    <div className="groupInfoHeaderWrapper">
                        <span>{"Group Info"}</span>
                        <svg onClick={() => {
                            props.setGroupInfo(false);
                        }}
                            xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 0.707104 -0.70711 -0.707104 20.4531 6.48962)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
                            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 22.0259 20.6318)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>
                <input
                    type="file"
                    accept="image/*"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                />
                {
                    addMembers && (
                        <Popup
                            color={"#1296B0"}
                            needPrevious={showGroupMembers ? true : false}
                            handlePrevious={() => {
                                setShowNewGroupMember(false);
                            }}
                            noPadding={true}
                            popupHeading={showGroupMembers ? ("Selected (" + selectedGroupMember.length + ")") : "Add group participants"}
                            content={
                                <>
                                    {!showGroupMembers ? <NewGroup
                                        alreadyAdded={{ isAlready: true, members: [...props?.activeChat?.participateMembers] }}
                                        selectedGroupMember={selectedGroupMember}
                                        setSelectedGroupMember={(e) => {
                                            console.log("------- setting selected group", e)
                                            setSelectedGroupMember([...e])
                                        }}
                                        viewSelected={setShowNewGroupMember}
                                        addNewMembers={handleAddNewMembers}
                                    /> : null}
                                    {showGroupMembers ? <SelectedNewGroupMembers
                                        selectedGroupMember={selectedGroupMember}
                                    /> : null}

                                </>
                            }
                            handleClose={toggleNewGroup}
                        />
                    )}

                <div className="groupInfoDetailsMainWrapper">
                    <div className="groupInfoImageContainer">
                        <div className="groupInfoImageWrapper">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="155"
                                height="154"
                                viewBox="0 0 155 154"
                                fill="none"
                            >
                                <g opacity="0.4">
                                    <circle
                                        cx="77.1895"
                                        cy="77.0684"
                                        r="76.9316"
                                        transform="rotate(-90 77.1895 77.0684)"
                                        fill="url(#paint0_radial_60_9411)"
                                    />
                                </g>
                                <g opacity="0.4">
                                    <circle
                                        cx="77.2061"
                                        cy="77.0963"
                                        r="63.3691"
                                        transform="rotate(90 77.2061 77.0963)"
                                        fill="url(#paint1_radial_60_9411)"
                                    />
                                </g>
                                <g opacity="0.4">
                                    <circle
                                        cx="77.2051"
                                        cy="77.0969"
                                        r="46.7725"
                                        transform="rotate(90 77.2051 77.0969)"
                                        fill="url(#paint2_radial_60_9411)"
                                    />
                                </g>
                                <defs>
                                    <radialGradient
                                        id="paint0_radial_60_9411"
                                        cx="0"
                                        cy="0"
                                        r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(73.4253 78.6441) rotate(102.659) scale(101.154)"
                                    >
                                        <stop
                                            offset="0.568025"
                                            stop-color="white"
                                            stop-opacity="0.2"
                                        />
                                        <stop offset="1" stop-color="#13A9C7" />
                                    </radialGradient>
                                    <radialGradient
                                        id="paint1_radial_60_9411"
                                        cx="0"
                                        cy="0"
                                        r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(74.1055 78.3943) rotate(102.659) scale(83.3209)"
                                    >
                                        <stop
                                            offset="0.568025"
                                            stop-color="white"
                                            stop-opacity="0.2"
                                        />
                                        <stop offset="1" stop-color="#13A9C7" />
                                    </radialGradient>
                                    <radialGradient
                                        id="paint2_radial_60_9411"
                                        cx="0"
                                        cy="0"
                                        r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(74.9166 78.0549) rotate(102.659) scale(61.4988)"
                                    >
                                        <stop
                                            offset="0.531188"
                                            stop-color="white"
                                            stop-opacity="0.2"
                                        />
                                        <stop offset="1" stop-color="#13A9C7" />
                                    </radialGradient>
                                </defs>
                            </svg>
                            {props?.activeChat?.type != "Broadcast" ? <div className="groupInfoProfileImage">
                                {props?.activeChat?.icon && props?.activeChat?.icon != "" ?
                                    <img style={{ width: "100%", height: "100%", borderRadius: 300 }} src={props?.activeChat?.icon} />
                                    : <div style={{ backgroundColor: "#E9F3F4", width: "100%", height: "100%", borderRadius: 300, display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                        <div style={{ color: "#13A9C7", fontSize: 20 }}>
                                            {props?.activeChat?.title
                                                ?.split(" ")
                                                ?.slice(0, 2)
                                                ?.map((item) => {
                                                    return item?.charAt(0)?.toUpperCase();
                                                })
                                                .join("")
                                            }
                                        </div>
                                    </div>}
                            </div> :
                                <div className="groupInfoProfileImage">
                                    <img style={{ height: "100%", width: "100%" }} alt={"broadcast"} src={BraodcastIcon} />
                                </div>
                            }
                            {props?.activeChat?.type != "Broadcast" && isAdmin && <div onClick={() => { document.getElementById("fileInput").click() }} className="groupInfoCameraIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
                                    <circle cx="14.4892" cy="14.8879" r="14.2138" fill="#1296B0" />
                                    <circle cx="17.842" cy="13.3865" r="0.412346" fill="white" />
                                    <circle cx="14.4905" cy="16.3486" r="2.99882" stroke="white" stroke-width="1.11962" />
                                    <path d="M10.0234 10.4073V10.9671H10.5832V10.4073H10.0234ZM18.9549 10.4073H18.3951V10.9671H18.9549V10.4073ZM10.5832 10.1871C10.5832 9.39489 11.2254 8.75265 12.0177 8.75265V7.63303C10.6071 7.63303 9.46357 8.77654 9.46357 10.1871H10.5832ZM10.5832 10.4073V10.1871H9.46357V10.4073H10.5832ZM9.24383 10.9671H10.0234V9.84746H9.24383V10.9671ZM7.31079 12.9001C7.31079 11.8325 8.17624 10.9671 9.24383 10.9671V9.84746C7.55789 9.84746 6.19117 11.2142 6.19117 12.9001H7.31079ZM7.31079 19.0909V12.9001H6.19117V19.0909H7.31079ZM9.24383 21.0239C8.17624 21.0239 7.31079 20.1585 7.31079 19.0909H6.19117C6.19117 20.7768 7.55789 22.1435 9.24383 22.1435V21.0239ZM19.7345 21.0239H9.24383V22.1435H19.7345V21.0239ZM21.6675 19.0909C21.6675 20.1585 20.8021 21.0239 19.7345 21.0239V22.1435C21.4204 22.1435 22.7871 20.7768 22.7871 19.0909H21.6675ZM21.6675 12.9001V19.0909H22.7871V12.9001H21.6675ZM19.7345 10.9671C20.8021 10.9671 21.6675 11.8325 21.6675 12.9001H22.7871C22.7871 11.2142 21.4204 9.84746 19.7345 9.84746V10.9671ZM18.9549 10.9671H19.7345V9.84746H18.9549V10.9671ZM18.3951 10.1871V10.4073H19.5147V10.1871H18.3951ZM16.9606 8.75265C17.7528 8.75265 18.3951 9.39489 18.3951 10.1871H19.5147C19.5147 8.77654 18.3712 7.63303 16.9606 7.63303V8.75265ZM12.0177 8.75265H16.9606V7.63303H12.0177V8.75265Z" fill="white" />
                                </svg>
                            </div>}
                        </div>
                        <div className="imgText ">
                            {editName ?
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderStyle: "solid", borderWidth: 0, borderBottomWidth: 1, padding: 5, marginBottom: 20 }}>
                                    <input style={{ borderStyle: "none", outline: "none" }} type='text' placeholder='Enter group name' defaultValue={props?.activeChat?.title} onChange={(e) => { setNewName(e.target.value) }} />
                                    <img onClick={() => {
                                        if (name && name != "") {
                                            setEditName(false);
                                            handleEvent({ "conversationId": props?.activeChat?._id, "reqType": "updateGroupTitle", "updateVal": name })
                                        }

                                    }} alt={"save"} src={SaveIcon} />
                                </div>
                                : <h4>{props?.activeChat?.title}
                                    {isAdmin && <span onClick={() => { setEditName(true) }} style={{ marginLeft: 10 }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                            <line x1="7.0243" y1="12.1146" x2="12.0204" y2="12.1146" stroke="#1296B0" stroke-width="1.00856" stroke-linecap="round" />
                                            <path d="M6.93619 3.13129L0.572876 9.45211V11.9761H3.13693L9.4284 5.64444M6.93619 3.13129L8.56652 1.51185C9.25534 0.82763 10.3679 0.829495 11.0544 1.51602V1.51602C11.741 2.20263 11.7428 3.3153 11.0583 4.0041L9.4284 5.64444M6.93619 3.13129L9.4284 5.64444" stroke="#1296B0" stroke-width="1.00856" />
                                        </svg>
                                    </span>}
                                </h4>}
                            {!editName && <hr className='groupInfoHrLine' style={editName ? { width: "80%" } : {}}></hr>}
                            <p style={{ fontSize: 12, color: "rgba(161, 161, 161, 1)" }}>{props?.activeChat?.type == "Broadcast" ? "Broadcast List . " : ""}{props?.activeChat?.participateMembers.length} Members</p>
                        </div>
                    </div>
                    <Tabs />
                    <div className="groupInfoAttachContainer">

                        {props?.activeChat?.type != "Broadcast" ? <div className="groupInfoMuteNotifications">
                            <div className="busyStatus">
                                <div className="busyStatusDot"></div>
                                <span>Mute Notifications</span>
                            </div>
                            <div onClick={() => {
                                handleEvent({ "conversationId": props?.activeChat?._id, "reqType": "isMute", "updateVal": !isMuted })
                                setMuted(!isMuted)
                            }} className="changeStatus">
                                <div style={{ width: 30, height: 10, backgroundColor: isMuted ? "rgba(216, 240, 242, 1)" : "#BFBFBF", borderRadius: 10, display: "flex", justifyContent: isMuted ? "flex-end" : "flex-start", alignItems: "center" }}>
                                    <div style={{ width: 15, height: 15, borderRadius: 15, backgroundColor: isMuted ? "#1296B0" : "#555555" }}></div>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                    <div className="groupInfoMemberContainer">
                        <div className="groupInfoMemberWrapper">
                            {isAdmin && <div onClick={() => { setShowMembers(true) }} className="creteNewGroup">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                                    <path d="M10.775 11.8865C13.8167 11.8865 16.3781 9.36185 16.3781 6.36388C16.3781 3.36591 13.8167 0.841309 10.775 0.841309C7.73335 0.841309 5.17194 3.36591 5.17194 6.36388C5.17194 9.51964 7.73335 11.8865 10.775 11.8865Z" stroke="#1296B0" stroke-width="1.5" />
                                    <path d="M1.49054 21.8487C1.49054 16.7995 5.65282 11.8873 10.7756 11.8873C12.9707 11.8873 14.9893 12.7892 16.5798 14.2172" stroke="#1296B0" stroke-width="1.5" stroke-linecap="round" />
                                    <line x1="20.5407" y1="14.0549" x2="20.5407" y2="22.797" stroke="#F7941D" stroke-width="1.5" stroke-linecap="round" />
                                    <line x1="24.7928" y1="18.6798" x2="15.9014" y2="18.6798" stroke="#F7941D" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                <p>Add Participants</p>
                            </div>}
                            {isAdmin && <div onClick={() => { props.setShowGroupInvite(true) }} className="creteNewGroup">
                                <img src={GroupInviteIcon} />
                                <p>Invite to group via link</p>
                            </div>}
                            <span>{props?.activeChat?.participateMembers.length} Members in this {props?.activeChat?.type != "Broadcast" ? "Group" : "Broadcast"}</span>
                            <ProfileGroup navigateTo={props.navigateTo} handleEvent={handleEvent} activeChat={props.activeChat} isGroup={true} groups={[...props?.activeChat?.participateMembers]} />
                        </div>
                        {props?.activeChat?.type != "Broadcast" && <div style={{ marginTop: 30, marginLeft: 10 }}>

                            <div onClick={(e) => {
                                props.handleConversationAction(props.activeChat._id, props.activeChat.isFavorite ? "unfavorite" : "favorite");
                                e.stopPropagation();
                            }} className="empProfileBlockWrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10, cursor: "pointer" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                    <img style={{ height: 25, width: 25 }} alt="add favourite" src={props.activeChat.isFavorite ? RemoveFromFavourite : AddToFavourite} />
                                </div>
                                <div style={{ marginLeft: 10 }}>{props.activeChat.isFavorite ? "Remove from favourites" : "Add to favourites"}</div>
                            </div>
                        </div>}
                    </div>

                    <div className="groupInfoExitContainer">
                        {props?.activeChat?.type === "Broadcast" ?
                            <div className="groupInfoExitWrapper">

                                <div className="groupInfoExitImg">

                                    <img style={{ width: 20, height: 20 }} alt={"delete"} src={Delete} />
                                </div>
                                <div className="groupInfoExitText"
                                    onClick={() => {
                                        confirmAlert({
                                            title: "Delete broadcast list?",
                                            message: "This will delete your broadcast service wih all the added members.",
                                            buttons: [
                                                {
                                                    label: "Cancel",
                                                    onClick: () => { },
                                                },
                                                {
                                                    label: "delete",
                                                    onClick: () => {
                                                        handleEvent({ "conversationId": [props?.activeChat?._id], "reqType": "deleteConversation" }, "updateConvo")
                                                    },
                                                },
                                            ],
                                            overlayClassName: "customConfirmAlertContainer",
                                        });
                                    }}
                                >Delete broadcast list</div>
                            </div> :
                            <div className="groupInfoExitWrapper" style={{ cursor: "pointer" }}>
                                <div className="groupInfoExitImg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                                        <path d="M0.657593 9.67413V3.31848C0.657593 2.1193 1.73685 1.04004 2.93603 1.04004H21.8831C23.0822 1.04004 24.1615 2.1193 24.1615 3.31848V22.8651C24.1615 24.0643 23.0822 25.1435 21.8831 25.1435H2.93603C1.73685 25.1435 0.657593 24.0643 0.657593 22.8651V16.3895" stroke="#F71D1D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M0.657593 13.032H17.566" stroke="#F71D1D" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.249 8.59473L17.9258 13.0317L13.249 17.3487L17.4461 13.5114L17.9258 13.0317L17.4461 12.552L13.249 8.59473Z" fill="black" />
                                        <path d="M17.9258 13.0317L13.249 8.59473L17.4461 12.552L17.9258 13.0317ZM17.9258 13.0317L13.249 17.3487L17.4461 13.5114L17.9258 13.0317Z" stroke="#F71D1D" />
                                    </svg>
                                </div>
                                <div className="groupInfoExitText"
                                    onClick={() => {
                                        let a = props.activeChat.participateMembers.filter((item) => item.isAdmin);
                                        confirmAlert({
                                            title: "Exit Group?",
                                            message: a.length == 1 && a[0].contactId === userDetails._id ? "Since you are the only admin in this group, you have to mark atleast one group member as group admin before leaving" : "Only group admin will be notified that you left the group.",
                                            buttons: [
                                                {
                                                    label: "Cancel",
                                                    onClick: () => { },
                                                },
                                                {
                                                    label: a.length == 1 ? "OK" : "Exit",
                                                    onClick: () => {
                                                        if (a.length > 1 || a[0].contactId != userDetails._id) {
                                                            handleEvent({ "conversationId": props.activeChat._id }, "exit")
                                                        }
                                                    },
                                                },
                                            ],
                                            overlayClassName: "customConfirmAlertContainer",
                                        });
                                    }}
                                >Exit Group</div>
                            </div>}
                    </div>
                </div>
            </div>
            {/* } */}

        </>

    );
}

export default GroupInfo;