// employeeSlice.js

import { createSlice } from '@reduxjs/toolkit';

const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    employeeId: null, // Initial value is null or you can set it to a default value
  },
  reducers: {
    setEmployeeId: (state, action) => {
      state.employeeId = action.payload;
    },
  },
});

export const { setEmployeeId } = employeeSlice.actions;

export default employeeSlice.reducer;
