import React , {useState, useEffect} from "react";
import { deleteRequest, getRequest, postRequest } from '../../../../Services/axios';
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { showToast, isLoading } from '../../../../Features';
import { useDispatch } from 'react-redux';

const opt=[
  {label:"select",value:null}
]

const options = [
  { label: "dropdown 1", value: "dropdown 1" },
  { label: "dropdown 2", value: "dropdown 2" },
  { label: "dropdown 3", value: "dropdown 3" },
];


export const CreateRoleComp = ({data}) => {

  useEffect(() => {
    document.title = `PeopleSol - Create Role `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [activ, setActiv] = useState(null);

  const dispatch = useDispatch();
  const [roleList, setRoleList] = useState([]);
  const getDesignation = async () => {
    try {
        
        const result = await getRequest('Designation/GetDesignations');
        let listData = [];
        result.data.map((item) => { 
            listData.push({ label: item.name, value: item.id })
        })
        setRoleList(listData);
      
    } catch (error) {
        dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
    } finally {
    }
  }

  useEffect(() => {
    getDesignation();
  }, []);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Role Name"} mandatory={true} />
        <InputCustomFormik
         
         maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Role Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Grade"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={5}
          name="grade"
          type={"text"}
          placeholder={"Enter Grade"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Designation"} mandatory={true} />
        
        <SelectForm 
            name="designationID"
            placeholder={"Select Designation"}
            options={roleList} 
            values={
              data
                ? roleList.filter(
                    (options) => options.label === data?.Designation  
                  )
                : opt
            }
            />
      </div>
      <div>
        <LabelCustom labelName={"Is Kpi Template Added?"} mandatory={true} /> 
        <YesNoFormik   
        name="isKpiTemplateAdded"
            checkedyes={activ === true}
            checkedNo={activ === false}
            onChangeyes={(e) => setActiv(e.target.value=='Yes'?true:false)}
            onChangeNo={(e) => setActiv(e.target.value=='Yes'?true:false)}
            />
      </div>
      
    </div>
  );
};
