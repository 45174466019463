import { IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "../../Assets/CloseIcon.svg";
import { ProgressbarProf } from "../LD/Components/ProgressbarProf";
import Accordion from "./Accordian";
import { Loader } from "../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ProfilePictureUpload from "../../Components/FormComponent/ProfilePictureUpload";
import { useState } from "react";
export const ProfileDetails = ({
  bannerList,
  getProfileData,
  profilePercentage,
  ProfileAvatar,
  employeeProfile,
  organisationDetails,
  currentAddress,
  emergencyContact,
  permanentAddress,
  handleClose,
  educationDetailsData,
  experienceDetails,
  loading,
  personalDetails,
}) => {
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(bannerList?.Image);
  // Function to handle form submission

  const { LoggedInUser } = useSelector((state) => state.auth);
  return (
    <>
      <div className="topHeader">
        <div className="closeIcon">
          <IconButton onClick={handleClose} title="Close">
            <img className="close-icon" src={CloseIcon} alt="close" />
          </IconButton>
        </div>

        <div className="cameraPro">
          <ProfilePictureUpload
            getProfileData={getProfileData}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
          />
        </div>

        <div
          className="avatarPro"
          style={{ border: "1px solid var(--primary-hover)" }}
        >
          {bannerList?.Image ? (
            <img
              src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${bannerList?.Image}`}
              alt={"Profile"}
              className="imageInsideProgressBar"
            />
          ) : (
            <img
              src={ProfileAvatar}
              alt={"Profile"}
              className="imageInsideProgressBar"
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <div className="emName">{LoggedInUser?.name}</div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="progrssPercent">Profile Completion % </div>
            <div>
              <ProgressbarProf
                bgcolor={`var(--primary)`}
                progress={profilePercentage}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: "65px", height: "1px" }}
        className="horizontalLine"
      ></div>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "100%", overflow: "auto", padding: "6px 12px" }}>
          {employeeProfile?.length ? (
            <div style={{ borderBottom: "1px solid #D8F0F2" }}>
              <Accordion
                title={"Employee Profile"}
                subTitle={
                  <>
                    <span
                      className={`countLabel profper`}
                    >{`${employeeProfile?.reduce(
                      (sum, card) => sum + card?.percentage,
                      0
                    )}%`}</span>
                  </>
                }
                content={employeeProfile}
              />
            </div>
          ) : (
            ""
          )}
          {organisationDetails?.length ? (
            <div style={{ borderBottom: "1px solid #D8F0F2" }}>
              <Accordion
                title={"Organisation Details "}
                subTitle={
                  <>
                    <span
                      className={`countLabel profper`}
                    >{`${organisationDetails?.reduce(
                      (sum, card) => sum + card?.percentage,
                      0
                    )}%`}</span>
                  </>
                }
                content={organisationDetails}
              />
            </div>
          ) : (
            ""
          )}
          {educationDetailsData?.length ? (
            <div style={{ borderBottom: "1px solid #D8F0F2" }}>
              <Accordion
                title={"Education Details"}
                subTitle={
                  <>
                    <span
                      className={`countLabel profper`}
                    >{`${educationDetailsData?.reduce(
                      (sum, card) => sum + card?.percentage,
                      0
                    )}%`}</span>
                  </>
                }
                content={educationDetailsData}
              />
            </div>
          ) : (
            ""
          )}
          {personalDetails?.length ? (
            <div style={{ borderBottom: "1px solid #D8F0F2" }}>
              <Accordion
                title={"Personal Details"}
                subTitle={
                  <>
                    <span
                      className={`countLabel profper`}
                    >{`${personalDetails?.reduce(
                      (sum, card) => sum + card?.percentage,
                      0
                    )}%`}</span>
                  </>
                }
                content={personalDetails}
              />
            </div>
          ) : (
            ""
          )}
          {experienceDetails?.length ? (
            <div style={{ borderBottom: "1px solid #D8F0F2" }}>
              <Accordion
                title={"Experience Details"}
                subTitle={
                  <>
                    <span
                      className={`countLabel profper`}
                    >{`${experienceDetails?.reduce(
                      (sum, card) => sum + card?.percentage,
                      0
                    )}%`}</span>
                  </>
                }
                content={experienceDetails}
              />
            </div>
          ) : (
            ""
          )}
          {emergencyContact?.length ? (
            <div style={{ borderBottom: "1px solid #D8F0F2" }}>
              <Accordion
                title={"Emergency Contact "}
                subTitle={
                  <>
                    <span
                      className={`countLabel profper`}
                    >{`${emergencyContact?.reduce(
                      (sum, card) => sum + card?.percentage,
                      0
                    )}%`}</span>
                  </>
                }
                content={emergencyContact}
              />
            </div>
          ) : (
            ""
          )}
          {permanentAddress?.length ? (
            <div style={{ borderBottom: "1px solid #D8F0F2" }}>
              <Accordion
                title={"Permanent Address"}
                subTitle={
                  <>
                    <span
                      className={`countLabel profper`}
                    >{`${permanentAddress?.reduce(
                      (sum, card) => sum + card?.percentage,
                      0
                    )}%`}</span>
                  </>
                }
                content={permanentAddress}
              />
            </div>
          ) : (
            ""
          )}
          {currentAddress?.length ? (
            <div style={{ borderBottom: "1px solid #D8F0F2" }}>
              <Accordion
                title={"Current  Address"}
                subTitle={
                  <>
                    <span
                      className={`countLabel profper`}
                    >{`${currentAddress?.reduce(
                      (sum, card) => sum + card?.percentage,
                      0
                    )}%`}</span>
                  </>
                }
                content={currentAddress}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <div
        style={{ marginTop: "12px", height: "1px" }}
        className="horizontalLine"
      ></div>
      {/* <div className="createFootCust" >
        <button
          className="button primaryButton"
          onClick={() =>
            navigate("/services/profile/create", {
              state: { id: LoggedInUser?.id },
            })
          }
        >
          {"Edit Profile"}
        </button>
      </div> */}
    </>
  );
};
