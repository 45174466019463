import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateEventsComp } from "../FormComp/CreateEventsComp";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import dateFormat from "dateformat";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateEvents = (props) => {
  const { state } = useLocation();

  const data = state?.id;

  const [detailsform, setDetailsform] = useState({});
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [formValue, setFormValue] = useState();

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Events/Details/${data}`);
      setDetailsform(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
      document.title = `PeopleSol - Create Events`;
      return () => {
        document.title = "PeopleSol";
      };
    }
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Events/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequestForm("Events/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    }
  };
  // console.log("formValue", formValue);
  const initialvalue = {
    title: "",
    description: "",
    startTime: "",
    endTime: "",
    type: null,
    vanue: "",
    upload: null,
    link: null,
    eventStartTime: "",
    eventEndTime: "",
  };
  const validationSchema = Yup.object({
    title: Yup.string()
      .max(149, "Title cannot be more than 150 characters")
      .required("Please Enter Title"),
    description: Yup.string()
      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
    startTime: Yup.string().required("Please Select  Start Date"),
    endTime: Yup.string().required("Please Select End Date"),
    type: Yup.string().required("Please Enter Type"),
    // vanue: Yup.string().required("Please Enter Vanue"),
    link: Yup.string().matches(
      /^https?:\/\/(.*)/,
      "website must start with http:// or https://"
    ),
    eventStartTime: Yup.string().required("Please Select Event Start Time"),
    eventEndTime: Yup.string().required("Please Select Event End Time"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: data,
      eventStartTime: dateFormat(values?.eventStartTime, "hh:MM"),
      eventEndTime: dateFormat(values?.eventEndTime, "hh:MM"),
    };

    const create = {
      ...values,
      id: 0,
      eventStartTime: dateFormat(values?.eventStartTime, "hh:MM"),
      eventEndTime: dateFormat(values?.eventEndTime, "hh:MM"),
      startTime: new Date(values?.startTime + " UTC"),
      endTime: new Date(values?.endTime + " UTC"),
      isDataActive: true,
    };
    data ? Edit(edit) : createPageData(create);
    // returnPage();
  };
  const chooseFormValues = (values) => {
    setFormValue(values);
  };
  const navigate = useNavigate();

  // const returnPage = () => {
  //   navigate("/homemaster/events");
  // };
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Edit Events" : "Create Events"}
      ButtonLabel={data ? "Update Events" : "Create Events"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        detailsform
          ? {
              ...initialvalue,

              title: detailsform?._events?.title,
              description: detailsform?._events?.description,
              startTime: detailsform?._events?.startTime
                ? new Date(detailsform?._events?.startTime)
                : "",
              endTime: detailsform?._events?.endTime
                ? new Date(detailsform?._events?.endTime)
                : "",
              type: detailsform?._events?.type,
              vanue: detailsform?._events?.vanue,
              upload: detailsform?.post?.attachment,
              link: detailsform?.post?.link,
              eventStartTime: detailsform?._events?.eventStartTime
                ? new Date(`12/12/12 ${detailsform?._events?.eventStartTime}`)
                : "",
              eventEndTime: detailsform?._events?.eventEndTime
                ? new Date(`12/12/12 ${detailsform?._events?.eventEndTime}`)
                : "",
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateEventsComp formValue={formValue} />}
    />
  );
};

export { CreateEvents };
