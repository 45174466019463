import React, { useState, useEffect } from "react";
import CcIcon from "../../../Assets/CcIcon.svg";
import CInIcon from "../../../Assets/CInIcon.svg";
import CeIcon from "../../../Assets/CeIcon.svg";
import CwIcon from "../../../Assets/CwIcon.svg";
import CAddIcon from "../../../Assets/CAddIcon.svg";
import EditPencilIcon from "../../../Assets/EditPencilIcon.svg";
import LeftRainlogo from "../../../Assets/LeftRainlogo.svg";
import CNameIcon from "../../../Assets/CNameIcon.svg";
import * as Yup from "yup";
import { IconButton, Tooltip } from "@mui/material";
import "./Company.css";
import CompanyComp from "./FormComp/CompanyComp";
import Nodata from "../../../Assets/Nodata.svg";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { Form, Formik } from "formik";

import { Loader } from "../../../Components/FormComponent/Loader";
import CompanyCompUpload from "./FormComp/CompanyCompUpload";
import { useSelector } from "react-redux";
const Company = (props) => {
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState(false);
  const [tlistDataKeys, setTlistDataKeys] = useState(false);
  const [tlistDataValues, setTlistDataValues] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState(false);
  const [Error, setError] = useState("");
  const getPageData = async () => {
    try {
      // dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest("Company/CompanyDetails");
      setInitialData(result.data);
      setCompanyProfile(Object.values(result.data).slice(-1));
      setTlistDataKeys(Object.keys(result.data).slice(1, -1));
      setTlistDataValues(Object.values(result.data).slice(1, -1));
      setCompanyName(result.data.companyName);
    } catch (error) {
      setError(error?.response?.data);
      //   dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      // dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Company `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getPageData();
    if (props?.header) getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function editCompany() {
    setEditActive((prev) => !prev);
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  const initialValues = {
    companyZip: initialData?.companyProfile?.companyZip,
    companyWebsite: initialData?.companyProfile?.companyWebsite,
    companyState: initialData?.companyProfile?.companyState,
    companyLogo: initialData?.companyProfile?.companyLogo
      ? `Company/${initialData?.companyProfile?.companyLogo}`
      : "",
    companyIndustry: initialData?.companyProfile?.companyIndustry,
    companyFavIcon: initialData?.companyProfile?.companyFavIcon
      ? `Company/${initialData?.companyProfile?.companyFavIcon}`
      : null,
    companyCountry: initialData?.companyProfile?.companyCountry,
    companyCity: initialData?.companyProfile?.companyCity,
    companyAddress: initialData?.companyProfile?.companyAddress,
    companyName: initialData?.companyName,
    companyEmailId: initialData?.companyEmailId,
    companyPhoneNumber: initialData?.companyPhoneNumber,
    contactPersonName: initialData?.contactPersonName,
    contactPersonEmailId: initialData?.contactPersonEmailId,
    contactPersonPhoneNumber: initialData?.contactPersonPhoneNumber,
    contactPersonDesignation: initialData?.contactPersonDesignation,
    organizationName: initialData?.organizationName,
  };
  async function submitEditCompany(values) {
    const formData = new FormData();
    if (initialData?.id) formData.append("id", initialData?.id);
    if (initialData?.companyProfile?.companyCode)
      formData.append("companyCode", initialData?.companyProfile?.companyCode);
    if (values?.companyName)
      formData.append("companyName", values?.companyName);
    if (values?.organizationName)
      formData.append("organizationName", values?.organizationName);
    if (values?.companyEmailId)
      formData.append("companyEmailId", values?.companyEmailId);
    if (values?.companyPhoneNumber)
      formData.append("companyPhoneNumber", values?.companyPhoneNumber);
    if (values?.contactPersonName)
      formData.append("contactPersonName", values?.contactPersonName);
    if (values?.contactPersonPhoneNumber)
      formData.append(
        "contactPersonPhoneNumber",
        values?.contactPersonPhoneNumber
      );
    if (values?.contactPersonEmailId)
      formData.append("contactPersonEmailId", values?.contactPersonEmailId);
    if (values?.contactPersonDesignation)
      formData.append(
        "contactPersonDesignation",
        values?.contactPersonDesignation
      );
    if (initialData?.companyProfile?.id)
      formData.append("companyProfile.id", initialData?.companyProfile?.id);
    if (initialData?.companyProfile?.companyCode)
      formData.append(
        "companyProfile.companyCode",
        initialData?.companyProfile?.companyCode
      );
    if (typeof values?.companyLogo === "object")
      formData.append("companyProfile.UploadLogo", values?.companyLogo);
    if (typeof values?.companyFavIcon === "object")
      formData.append("companyProfile.UploadFavIcon", values?.companyFavIcon);
    if (values?.companyIndustry)
      formData.append(
        "companyProfile.companyIndustry",
        values?.companyIndustry
      );
    if (values?.companyWebsite)
      formData.append("companyProfile.companyWebsite", values?.companyWebsite);
    if (values?.companyAddress)
      formData.append("companyProfile.companyAddress", values?.companyAddress);
    if (values?.companyCity)
      formData.append("companyProfile.companyCity", values?.companyCity);
    if (values?.companyState)
      formData.append("companyProfile.companyState", values?.companyState);
    if (values?.companyCountry)
      formData.append("companyProfile.companyCountry", values?.companyCountry);
    if (values?.companyZip)
      formData.append("companyProfile.companyZip", values?.companyZip);
    try {
      // dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Company/EditCompany", formData);
      if (result) getPageData();
    } catch (error) {
      dispatch(
        showToast({
          text:
            error?.response?.data ??
            "Some error occured in updating company data",
          severity: "error",
        })
      );
    } finally {
      // dispatch(isLoading(false));
      setLoading(false);
      setEditActive(false);
    }
  }

  //const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  //console.log('companyD',initialData)
  return (
    <div className="moduleMainContentHolder companyContentHolder">
      {initialData ? (
        <div className="companyCard">
          <div
            className="homeDashboardContainer"
            style={{
              height: "fit-content",
              position: "relative",
              padding: "24px 0 24px 0",
            }}
          >
            <img
              src={LeftRainlogo}
              alt="CompanyBg"
              style={{
                height: "100%",
                width: "200px",
                position: "absolute",
                left: "-40px",
                top: 0,
                zIndex: 0,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "space-around",
                marginLeft: "20px",
                flexGrow: 1,
                zIndex: 1,
              }}
            >
              <div>
                <p style={{ whiteSpace: "nowrap", marginBottom: 10, marginLeft: 10 }}>Company Logo</p>
                <img
                  style={{ maxHeight: "60px", maxWidth: "200px" }}
                  src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Company/${initialData?.companyProfile?.companyCode}/Logo/${initialData?.companyProfile.companyLogo}`}
                  alt="LogoCompany"
                />
                <a href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Company/${initialData?.companyProfile?.companyCode}/Logo/${initialData?.companyProfile.companyLogo}`} />
              </div>
            </div>
            <div className="dashboardRights">
              <div className="dashboardServicess" style={{ zIndex: 10 }}>
                <div className="dashboardServicesContainers" style={{ width: "30%" }}>
                  <img
                    src={CNameIcon}
                    alt="Role"
                    className="dashboardServicesIcon"
                  />
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">Company Name</p>
                    <p className="dashboardServicesNameee companyDash">
                      {initialData?.companyName}
                    </p>
                  </div>
                </div>

                <div className="dashboardServicesContainers" style={{ width: "30%" }}>
                  <img
                    src={CcIcon}
                    alt="Designation"
                    className="dashboardServicesIcon"
                  />
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">Company Code</p>
                    <p className="dashboardServicesNameee companyDash">
                      {initialData?.companyProfile?.companyCode}
                    </p>
                  </div>
                </div>

                <div className="dashboardServicesContainers" style={{ width: "30%" }}>
                  <img
                    src={CAddIcon}
                    alt="Department"
                    className="dashboardServicesIcon"
                  />
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">Company Address</p>
                    <p className="dashboardServicesNameee companyDash">
                      {initialData?.companyProfile.companyAddress}
                    </p>
                  </div>
                </div>
              </div>
              <div className="dashboardServicess">
                <div className="dashboardServicesContainers" style={{ width: "30%" }}>
                  <img
                    src={CwIcon}
                    alt="Location"
                    className="dashboardServicesIcon"
                  />
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">Company Website</p>
                    <p className="dashboardServicesNameee companyDash">
                      {initialData?.companyProfile.companyWebsite}
                    </p>
                  </div>
                </div>

                <div className="dashboardServicesContainers" style={{ width: "30%" }}>
                  <img
                    src={CInIcon}
                    alt="CompanyExperience"
                    className="dashboardServicesIcon"
                  />
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">Company Industry</p>
                    <p className="dashboardServicesNameee companyDash">
                      {initialData?.companyProfile.companyIndustry}
                    </p>
                  </div>
                </div>

                <div className="dashboardServicesContainers" style={{ width: "30%" }}>
                  <img
                    src={CeIcon}
                    alt="Experience"
                    className="dashboardServicesIcon"
                  />
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">Company Email Id</p>
                    <p className="dashboardServicesNameee companyDash">
                      {initialData?.companyEmailId}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="companyCardContainer">
            <div className="headingContainer">
              <h3>{companyName}</h3>
              {LoggedInUser.permissions.includes("Can Edit Company Details?") && !editActive &&
                <Tooltip arrow
                  title={editActive ? "Cancel Edit" : "Edit Company"}
                  placement="left"
                >
                  <IconButton
                    onClick={() => editCompany()}
                    className={`${editActive && "pencilBackground"}`}
                  >
                    <img
                      src={EditPencilIcon}
                      alt="Edit Pencil"
                      style={{ height: 30, width: 30 }}
                      className="editPencilIcon"
                    />
                  </IconButton>
                </Tooltip>
              }

            </div>
            <div
              className={`jobDetailContainerHolder companyDetailsContainerHolder ${editActive && "editActive"
                }`}
            >
              {initialData && loading ? (
                <div className="noDataFound">
                  <Loader />
                </div>
              ) : (
                <>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={submitEditCompany}
                    validationSchema={Yup.object({
                      // companyLogo: Yup.mixed()
                      //   .nullable()
                      //   .string()
                      //   .test(
                      //     "Fichier taille",
                      //     "File Size should be less than 500 kb",
                      //     (value) =>
                      //       !value || (value && value.size <= 500 * 1024)
                      //   )
                      //   .test(
                      //     "format",
                      //     "File Format is Incorrect",
                      //     (value) =>
                      //       !value ||
                      //       (value && SUPPORTED_FORMATS.includes(value.type))
                      //   )
                      //   .required("Please Upload Company logo"),
                      companyEmailId: Yup.string()
                        .email("Please Enter Valid Email")
                        .required("Please Enter Company Email"),
                      companyName: Yup.string().required(
                        "Please Enter Company Name"
                      ),
                      companyPhoneNumber: Yup.string()
                        .min(5, "Please Enter Valid Phone No.")
                        .max(15, "Please Enter Valid Phone No.")
                        .required("Please Enter Company Phone"),
                      contactPersonName: Yup.string().required(
                        "Please Enter Contact Person's Name"
                      ),
                      contactPersonPhoneNumber: Yup.string()
                        .min(5, "Please Enter Valid Phone No.")
                        .max(15, "Please Enter Valid Phone No.")
                        .required("Please Enter Contact Person's Phone"),
                      contactPersonEmailId: Yup.string()
                        .email("Please Enter Valid Email")
                        .required("Please Enter Contact Person's Email"),
                      organizationName: Yup.string().required(
                        "Please Enter Organization Name"
                      ),
                      companyWebsite: Yup.string()
                        .notRequired()
                        .nullable()
                        .matches(
                          /^https?:\/\/(.*)/,
                          "website must start with http:// or https://"
                        ),
                    })}
                  >
                    <Form
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        paddingBottom: "60px",
                      }}
                    >
                      {companyProfile && (
                        <>
                          <CompanyComp
                            editActive={editActive}
                            HeadText="companyCountry"
                            BodyText={companyProfile[0].companyCountry}
                          />
                          {/* <CompanyCompUpload
                            editActive={editActive}
                            HeadText="companyFavIcon"
                            BodyText={companyProfile[0].companyFavIcon}
                          /> */}
                          <CompanyComp
                            editActive={editActive}
                            HeadText="companyIndustry"
                            BodyText={companyProfile[0].companyIndustry}
                          />
                          <CompanyCompUpload
                            editActive={editActive}
                            HeadText="companyLogo"
                            BodyText={companyProfile[0].companyLogo}
                          />
                          <CompanyComp
                            editActive={editActive}
                            HeadText="companyState"
                            BodyText={companyProfile[0].companyState}
                          />
                          <CompanyComp
                            editActive={editActive}
                            HeadText="companyWebsite"
                            BodyText={companyProfile[0].companyWebsite}
                          />
                          <CompanyComp
                            editActive={editActive}
                            HeadText="companyZip"
                            BodyText={companyProfile[0].companyZip}
                          />
                        </>
                      )}
                      {tlistDataKeys &&
                        tlistDataKeys.map((item, index) => {
                          return (
                            <CompanyComp
                              editActive={editActive}
                              key={index}
                              HeadText={item}
                              BodyText={tlistDataValues[index]}
                            />
                          );
                        })}
                      {editActive && (
                        <div
                          className="multiStepFormButtonContainer"
                          style={{ width: "calc(100% - 29px)" }}
                        >
                          <button
                            className="button secondaryButton"
                            type="button"
                            style={{ border: "1px solid rgba(18, 150, 176, 1)" }}
                            onClick={() => setEditActive((prev) => !prev)}
                          >
                            Cancel
                          </button>
                          <button
                            className="button primaryButton"
                            type="submit"
                            style={{ border: "1px solid rgba(18, 150, 176, 1)" }}
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </Form>
                  </Formik>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>
            {Error === "You Do not have a permissions"
              ? "You don't have required access to view the content"
              : Error}
          </h4>
        </div>
      )}
    </div>
  );
};

export { Company };
