import * as Yup from 'yup'
export const CategorySchema = Yup.object({
  name:  Yup
  .string()
  .max(49," Name must be less than 50 characters")
  .required("Please Enter  Name"),
    type:Yup.string().required("Please Select type"),
    ownerEmployeeId:Yup.string().required("Please Select Owner")
  });
export const stateSchema = Yup.object({
  exceptionGroupId:Yup.string().required("Please Select Exception Group"),
  name:  Yup
  .string()
  .max(299,"Name must be less than 300 characters")
  .required('Please enter valid Statement')
  });