/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import Nodata from "../../Assets/Nodata.svg";
import "react-confirm-alert/src/react-confirm-alert.css";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { isLoading } from "../../Features";
import { postRequest } from "../../Services/axios";
import { Loader } from "../../Components/FormComponent/Loader";
import { getStatusColor } from "../../Utilities/getStatusColor";
import { SelectCustom } from "../../Components/FormComponent/SelectCustom";

const AllAppraisalReviews = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const [loading, setLoading] = useState(false);
  const [tbodyData, setTbodyData] = useState([]);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  //   const [isRestricted, setIsRestricted] = useState(false);
  const [searchParam, setSearchParam] = useState(null);
  const [reqData, setReqData] = useState({
    cycleStartDate: null,
    cycleEndDate: null,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  const { LoggedInUser } = useSelector((state) => state.auth);
  useEffect(() => {
    document.title = `PeopleSol - All Review `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `PMSAppraisalReview/AllAppraisalList`,
        reqData
      );
      if (result?.data) {
        // if (
        //   LoggedInUser?.isHRHead ||
        //   LoggedInUser?.isHRDirector ||
        //   LoggedInUser?.hrbpDepartmentIds?.length ||
        //   LoggedInUser?.hrbpLocationIds?.length ||
        //   LoggedInUser?.teamnames?.includes("PMS")
        // ) {
        let tempData = [];
        result.data.data.map((item) => {
          return tempData.push({
            ...item.item,
            actions: item?.actions,
            status: item.status,
          });
        });
        const clippedArray = tempData.map(
          ({
            createdBy,
            department,
            designation,
            email,
            employeeId,
            toLocation,
            toLocationName,
            ...rest
          }) => ({ ...rest })
        );
        const finalArray = clippedArray.map(
          ({
            id,
            appraisalCriteriaName,
            name,
            numberOfEmployee,
            ratingSlabName,
            processStartDate,
            processEndDate,
            actions,
            createdOn,
            lastUpdatedOn,
            ...rest
          }) => ({
            id,
            appraisalCriteriaName,
            name,
            numberOfEmployee,
            ratingSlabName,
            processStartDate,
            processEndDate,
            actions,
            createdOn,
            lastUpdatedOn,
            ...rest,
          })
        );
        setTbodyData(finalArray);
        setCount(result.data.summary.allCount);
        calculateTotalPages(result.data.summary.allCount);
        // } else {
        //   setTbodyData(false);
        //   setCount(0);
        // //   setIsRestricted(true)
        // }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname
        .toLowerCase()
        .includes("appraisalreview/allappraisalreview")
    )
      getListData();
  }, [location.pathname, reqData]);
  console.log("data tbodyData", tbodyData);
  if (loading) return <Loader />;
  else
    return (
      <div
        className="fullTableContainer"
        style={{ width: `${props.width}`, height: `${props.height}` }}
      >
        <div className="boxViewContainer ">
          <div className="homeCardsHolderContainer">
            <div className="homeDashboardCardHolder" key={1}>
              {tbodyData?.length ? (
                tbodyData?.map((item, index) => {
                  return (
                    <div className="dashboardCard internalJobCard">
                      <div
                        className="cardContainer cursor"
                        style={{ minHeight: "187px" }}
                      >
                        <div className="headingContainer">
                          <h3
                            style={{
                              fontSize: "16px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item?.name}
                          </h3>
                          <span
                            className="internalJobDateTime"
                            style={{ color: "var(--secondary)" }}
                          >
                            {
                              <div
                                style={{
                                  // backgroundColor: getStatusColor(
                                  //   item.status
                                  // )?.split(",")?.[0],
                                  color: "#1296b0",
                                  width: "75px",
                                  display: "flex",
                                  gap: "7px",
                                  // cursor: "default",
                                  // padding: "3px 6px",
                                  // width: "fit-content",
                                  // borderRadius: "6px",
                                }}
                              >
                                <svg
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.33886 8.09721C6.63392 8.09721 7.72975 7.00138 7.72975 5.70632C7.72975 4.41125 6.63392 3.31543 5.33886 3.31543C4.04379 3.31543 2.94797 4.41125 2.94797 5.70632C2.94797 7.00138 3.94417 8.09721 5.33886 8.09721Z"
                                    stroke="#1296B0"
                                    stroke-width="0.909954"
                                  />
                                  <path
                                    d="M9.32384 12.6792H1.35422V12.0815C1.35422 9.88985 3.14738 8.09668 5.33903 8.09668C7.53068 8.09668 9.32384 9.88985 9.32384 12.0815V12.6792Z"
                                    stroke="#1296B0"
                                    stroke-width="0.909954"
                                  />
                                  <path
                                    d="M19.556 8.09721C20.851 8.09721 21.9469 7.00138 21.9469 5.70632C21.9469 4.41125 20.851 3.31543 19.556 3.31543C18.2609 3.31543 17.1651 4.41125 17.1651 5.70632C17.1651 7.00138 18.1613 8.09721 19.556 8.09721Z"
                                    stroke="#1296B0"
                                    stroke-width="0.909954"
                                  />
                                  <path
                                    d="M23.541 12.6792H15.5713V12.0815C15.5713 9.88985 17.3645 8.09668 19.5561 8.09668C21.7478 8.09668 23.541 9.88985 23.541 12.0815V12.6792Z"
                                    stroke="#1296B0"
                                    stroke-width="0.909954"
                                  />
                                  <path
                                    d="M12.4476 6.69281C14.1396 6.69281 15.5713 5.26109 15.5713 3.56906C15.5713 1.87703 14.1396 0.445312 12.4476 0.445312C10.7555 0.445312 9.32381 1.87703 9.32381 3.56906C9.32381 5.26109 10.6254 6.69281 12.4476 6.69281Z"
                                    fill="white"
                                    stroke="#1296B0"
                                    stroke-width="0.909954"
                                  />
                                  <path
                                    d="M17.6538 12.6796H7.2413V11.8986C7.2413 9.03519 9.58411 6.69238 12.4475 6.69238C15.311 6.69238 17.6538 9.03519 17.6538 11.8986V12.6796Z"
                                    fill="white"
                                    stroke="#1296B0"
                                    stroke-width="0.909954"
                                  />
                                </svg>
                                {item.numberOfEmployee}
                              </div>
                            }
                          </span>
                        </div>
                        <div
                          className="jobDetailContainerHolder"
                          style={{ padding: "7px 0px 23px 11px" }}
                        >
                          <div className="jobDetailContainer">
                            <div className="verticalLine"></div>
                            <label className="jobDesignation">
                              Process Start Date
                            </label>
                            <label>
                              {item?.processStartDate &&
                                format(
                                  new Date(item?.processStartDate),
                                  "dd/MM/yyyy "
                                )}
                            </label>
                          </div>
                          <div className="jobDetailContainer">
                            <div className="verticalLine"></div>
                            <label className="jobDesignation">
                              Process End Date
                            </label>
                            <label>
                              {item?.processEndDate &&
                                format(
                                  new Date(item?.processEndDate),
                                  "dd/MM/yyyy "
                                )}
                            </label>
                          </div>
                        </div>
                        <div className="applyJobButtonContainer">
                          <div>
                            {/* <button
                            onClick={() => {}}
                            className="button primaryButton"
                            style={{
                              backgroundColor: "white",
                              color: "red",
                              boxShadow:
                                "inset 0px 1px 10px rgba(0, 0, 0, 0.05)",
                              borderRadius: "3px",
                            }}
                          >
                            Cancel
                          </button> */}

                            <button
                              onClick={() => {
                                navigate(
                                  `/pms/appraisalreview/allappraisalreview/create/${item.id}`,
                                  {
                                    state: { item },
                                  }
                                );
                              }}
                              className="button primaryButton"
                            >
                              View Appraisal Review
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noDatastyle">
                  <img
                    src={Nodata}
                    alt="No data"
                    style={{ height: "150px", width: "250px" }}
                  />
                  <h4>"No Data Found"</h4>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{count}</span>
          <SelectCustom
            searchable={false}
            options={noOfRecords}
            dropdownPosition="top"
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </div>
    );
};
export { AllAppraisalReviews };
