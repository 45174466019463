import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import dateFormat from "dateformat";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useEffect } from "react";

export const PIreview = ({ data, getDetails, handleClose }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const createPage = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("ITsmChangeRequest/Approve", values);
      if (result) {
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(showToast({ text: "error", severity: "error" }));
    } finally {
      getDetails();
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - PL Review`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const validationSchema = Yup.object({
    FinalRemarks: Yup.string()
      
      .max(499, "FinalRemarks cannot be more than 500 characters")
      .required("Please Enter Final Remarks"),
  });
  const onSubmit = async (values, rej) => {
    const create = {
      ...values,
      Priority: data?.itsmChangeRequest?.priority,
      ChangeType: data?.itsmChangeRequest?.changeType,
      Id: data?.itsmChangeRequest?.id,
      CoFinalApproval: rej === "rejected" ? false : true,
      Reason: data?.itsmChangeRequest?.reason,
      DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
    };
    createPage(create);
  };

  if (loading) return  <Loader/>;
  else
    return (
      <div style={{ padding: "12px" ,backgroundColor:'var(--form-background)'}}>
      <Formik
      enableReinitialize
        initialValues={{ FinalRemarks: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values,validateField }) => {
        return  <Form>
            <LabelCustom labelName={"Post Implementation Review Notes"} mandatory={true} />
            <TextAreaFormik
              maxLength={500}
              placeholder="Please Enter Final Remarks"
              style={{ height: "80px" }}
              name={"FinalRemarks"}
            />
            <div className="createFootCust">
              <button
                className="deletebtn"
                type="submit"
                onClick={() => {
                  onSubmit(values, "rejected")
                  validateField(Yup.object({
                    FinalRemarks: Yup.string()
                      
                      .max(499, "Remarks cannot be more than 500 characters")
                      .required("Please Enter Remarks"),
                  }))
              }}
              >
                Reject
              </button>
              <button className="button primaryButton" type="submit">
                {"Approve"}
              </button>
            </div>
          </Form>;
        }}
      </Formik>
      </div>
    );
};
