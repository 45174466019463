import React, { useEffect, useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
const opt = [{ label: "select", value: null }];
const TeamMemberForm = ({ data ,id}) => {
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [optionSer, setOptionSer] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(0);
 // console.log("totalPages", totalPages);
  const loadMore = async (url,setState) => {
    if (page < totalPages) {
      try {
        const result = await getRequest(
          url
        );
        // Update the options and page
        setState((prevOptions) => [
          ...prevOptions,
          ...transformData(result.data),
        ]);
        setPage(page + 1);
      } catch (error) {
        // Handle error
      }
    }
  };
  const transformData = (data) => {
    // Transform the data into the desired format
    return data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };

  const getOption = async () => {
    try {
      setLoading(true)
      const result = await getRequest(`HRHelpdeskTeams/GetTeamName?&q=&i=${page}`);
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setTotalPages(result.data?.length);
      setPage(page + 1);
      setOption(listData);
    } catch (error) {
    } finally {
      setLoading(false)
    }
  };
  // "ExceptionRequests/GetExceptionNames"
  const getOptionSer = async () => {
    try {
      const result = await getRequest(
        `HrHelpdesks/GetTicketTypes?&q=&i=${page}`
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setTotalPages(result.data?.length);
      setPage(page + 1);
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getOption();
    getOptionSer();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Team Member`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Service Type "} mandatory={true} />
        <SelectForm
          disabled={JSON.stringify(data) === "{}" ? false : true}
          onMenuScrollToBottom={()=>loadMore(`HrHelpdesks/GetTicketTypes?&q=&i=${page}`,setOptionSer)}
          options={optionSer}
          name={"ticketTypeId"}
          placeholder={" Select Service Type  "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Helpdesk Team"} mandatory={true} />
        <SelectForm
          options={option}
          onMenuScrollToBottom={()=>loadMore(`HRHelpdeskTeams/GetTeamName?&q=&i=${page}`,setOption)}
          name={"teamId"}
          placeholder={" Select Helpdesk Team "}
        />
      </div>
    </div>
  );
};

export { TeamMemberForm };
