import React, { useEffect } from "react";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
const emailTemplateList = [
  { label: "Investment Declaration", value: "Income tax declaration" },
  { label: "Proof Submission", value: "Document Submission" },
  // { label: "Tax Finalisation", value: "Tax Finalisation" },
];
const taxTypeList = [
  { label: "Tax Declaration", value: "Tax Declaration" },
  { label: "Tax Submission", value: "Tax Submission" },
];


export const CreateTaxComp = ({ formValues }) => {
  useEffect(() => {
    document.title = `PeopleSol -  Create Tax `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Financial Year"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Declaration/Submission"} mandatory={true} />
        <SelectForm
          placeholder={"Select Email Template"}
          name="emailTemplate"
          options={emailTemplateList}
        />
      </div>
      <div>
        <LabelCustom labelName={"Start Date for Initiating"} mandatory={true} />
        <DatePickerFormiks name="startDate" />
      </div>
      {formValues?.startDate ? (
        formValues?.startDate !== "" ? (
          <div>
            <LabelCustom labelName={"Deadline for Filing"} mandatory={true} />
            <DatePickerFormiks
              name="endDate"
              minDate={
                formValues?.startDate
                  ? formValues?.startDate !== ""
                    ? new Date(formValues?.startDate)
                    : null
                  : null
              }
            />
          </div>
        ) : null
      ) : null}
      <div>
        <LabelCustom labelName={"Description"} />
        <TextAreaFormik
        
        maxLength={500}
          name="description"
          type={"text"}
          placeholder={"Enter Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload Attachment"} />
        <FileUploadFormik name="attachment" />
        <span style={{paddingLeft:"12px",color:"var(--primary)"}}>Size: 2 MB</span>
       <span style={{paddingLeft:"12px",color:"var(--primary)"}}>Format: JPEG, PNG, PDF</span>
      </div>
      {/* <div>
        <LabelCustom labelName={"Tax Type"} />
        <SelectForm
          placeholder={"Select Tax Type"}
          name="taxType"
          options={taxTypeList}
        />
      </div> */}
    </div>
  );
};
