import React from "react";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import {
  getPersonNumber,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";

const SocialNotificationItem = ({
  item,
  selectedRecipient,
  setSelectedRecipient,
}) => {
  return (
    <>
      <div className="selectedEmployeeList">
        <div className="employeeList">
          <div className="totalLikePopupImg">
            <div
              className={`${
                item?.image
                  ? "socialProfilePicContainer"
                  : "socialCustomProfilePic"
              }`}
              style={{
                width: "50px",
                height: "50px",
                margin: "0",
              }}
            >
              {!item?.image ? (
                getUserInitials(getDisplayName(item?.name))
              ) : (
                <img
                  className="commentProfilePic"
                  onClick={() => {
                    // handleUserProfileClick(item?.id);
                  }}
                  src={
                    process.env.REACT_APP_.BASE_URL +
                    profileImages +
                    item?.image
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = ProfileAvatar;
                  }}
                />
              )}
            </div>
          </div>
          <div className="totalLikePopupText">
            <h5>
              {getDisplayName(item?.name)}{" "}
              <span style={{ fontSize: "14px", fontWeight: "400" }}>
                {getPersonNumber(item?.name)}
              </span>
            </h5>
            <p style={{ fontSize: "14px", fontWeight: "500" }}>
              {item?.jobRoal}
            </p>
          </div>
        </div>
        <div
          className="socialCommonChkbox"
          onClick={() => {
            setSelectedRecipient(item);
          }}
        >
          <input
            type="checkbox"
            class="myinput large"
            name="chjsah"
            checked={selectedRecipient === item}
          />
        </div>
      </div>
    </>
  );
};

export default SocialNotificationItem;
