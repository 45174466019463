import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  noti: false,
};

const NotiSlice = createSlice({
  name: "noti",
  initialState,
  reducers: {
    closeNoti: (state) => {
      state.noti = false;
    },
    OpenNoti: (state) => {
      state.noti = true;
    },
  },
});
export const { closeNoti, OpenNoti } = NotiSlice.actions;
export default NotiSlice.reducer;
