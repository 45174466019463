import React, { useCallback, useState } from "react";
import "./Connect.css";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useSelector } from "react-redux";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import CreatePost from "./CreatePost";
import SharePost from "./SharePost";
import EditSharePost from "./EditSharePost";
import EditMyPost from "./EditMyPost";
import { Route, Routes } from "react-router-dom";
import LikeDislike from "./LikeDislike";
import ShareDetails from "./ShareDetails";
import {
  setEmployeeListReduxData,
  setSocialData,
} from "../../../../../Features/SocialSlice";
import ShowViewsList from "./ShowViewsList";
import SocialHeaderComponent from "./SocialHeaderComponent";
import SharedEmployeeComponent from "./SharedEmployeeComponent";
import UnSharedPostComponent from "./UnSharedPostComponent";
import SharedPostComponent from "./SharedPostComponent";
import NestedSharedPostComponent from "./NestedSharedPostComponent";
import SocialLikeAndDislikeComponent from "./SocialLikeAndDislikeComponent";
import SocialPostDetails from "./SocialPostDetails";
import SocialPostNotFoundIcon from "../../../../../Assets/SocialPostNotFoundIcon.svg";

const Connect = ({ unreadPostCount, setReRefreshProp, socialReRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [dataFound, setDataFound] = useState(true);
  const { socialData } = useSelector((state) => state.social);
  const [isSocialPostDeletePopupActive, setIsSocialPostDeletePopupActive] =
    useState(false);
  const [editDeleteSocialPostId, setEditDeleteSocialPostId] = useState(0);
  const [editDeleteSocialPostData, setEditDeleteSocialPostData] = useState();

  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [viewsPostId, setViewsPostId] = useState(0);
  const [viewsListActive, setViewsListActive] = useState(false);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });
  const [shareDetailsActive, setShareDetailsActive] = useState(false);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [isSharePosEditActive, setIsSharePosEditActive] = useState(false);
  const [isMyPosEditActive, setIsMyPosEditActive] = useState(false);
  const dispatch = useDispatch();
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [page, setPage] = useState(1);
  const [initialCall, setInitialCall] = useState(true);

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && socialData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, socialData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || socialData?.length < postDataCount)
    ) {
      if (initialCall === false && socialReRefresh === false) {
        getData();
      }
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset]);

  useEffect(() => {
    setOffset(0);
    return () => {
      dispatch(setSocialData([]));
      setReRefreshProp(true);
    };
  }, []);

  const getEmployeeData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(
        `Social/GetEmployeesImageDropDown?i=${page}`
      );
      dispatch(setEmployeeListReduxData(result?.data.textfilters));
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };




  const deleteSocialPostById = async () => {
    setLoading(true);
    try {
      const result = await getRequest(
        `SocialPost/Delete/?Id=${editDeleteSocialPostId}`
      );
      let x = socialData?.filter((d) => {
        if (d?.id !== editDeleteSocialPostId) {
          return d;
        }
      });
      dispatch(setSocialData(x));
      setIsSocialPostDeletePopupActive(false);
      setEditDeleteSocialPostId(0);
      dispatch(
        showToast({
          text: "Post Deleted Successfully",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in deleting post",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getData = async (freshLoad = false, reload = false) => {
    if (!reload) setLoading(true);
    try {
      const result = await postRequest(`SocialPost/AllPostList`, {
        createdOn: null,
        type: "social",
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength:
            postDataCount === -1 || offset + 2 <= postDataCount || freshLoad
              ? 2
              : postDataCount - offset,
          iDisplayStart: freshLoad || reload ? 0 : offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        setSocialData([]);
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });
        if (!reload) {
          setPostDataCount(result?.data?.summary?.allCount);
          setTotalOffset(offset);
        }
        if (freshLoad) {
          document.querySelector(
            ".homeEngageMainCenterContainer"
          ).scrollTop = 0;
          dispatch(setSocialData(listData));
          setOffset(0);
          setTotalOffset(0);
        } else {
          dispatch(setSocialData([...socialData, ...listData]));
        }
        setCurrentIndex((currentIndex) => currentIndex + 2);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Something went wrong!",
          severity: "error",
        })
      );
      return;
    } finally {
      setInitialCall(false);
      setLoading(false);
      setReRefreshProp(false);
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  useEffect(() => {
    if (socialReRefresh) {
      getData(true);
    }
  }, [socialReRefresh]);
  return (
    <>
      <div className="homeEngageMainCenterContainer" style={{ overflow: "hidden" }}>
        <SocialHeaderComponent />
        <div className="homeEngagePostContainer" style={{ marginTop: "90px", width: "90%", overflow: "auto", scrollbarWidth: "none" }}>
          {socialData?.length > 0 &&
            socialData.map((d, i) => {
              return (
                <div
                  className="socialAllPostContainer"
                  style={{ width: "100%" }}
                  key={"social-item-" + i}
                >
                  <div className="engagePostContentHolder" >
                    {d?.sharedEmployee !== null && (
                      <SharedEmployeeComponent
                        d={d}
                        getData={getData}
                        setIsSocialPostDeletePopupActive={
                          setIsSocialPostDeletePopupActive
                        }
                        setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                        setIsSharePosEditActive={setIsSharePosEditActive}
                        setIsMyPosEditActive={setIsMyPosEditActive}
                        setViewsPostId={setViewsPostId}
                        setViewsListActive={setViewsListActive}
                        setEditDeleteSocialPostData={
                          setEditDeleteSocialPostData
                        }
                      />
                    )}

                    {d?.sharedPost === null && (
                      <UnSharedPostComponent
                        d={d}
                        getData={getData}
                        setIsSocialPostDeletePopupActive={
                          setIsSocialPostDeletePopupActive
                        }
                        setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                        setIsSharePosEditActive={setIsSharePosEditActive}
                        setIsMyPosEditActive={setIsMyPosEditActive}
                        setViewsPostId={setViewsPostId}
                        setViewsListActive={setViewsListActive}
                        setEditDeleteSocialPostData={
                          setEditDeleteSocialPostData
                        }
                      />
                    )}
                    {d?.sharedPost && !d?.isNasted && (
                      <SharedPostComponent
                        d={d}
                        getData={getData}
                        setIsSocialPostDeletePopupActive={
                          setIsSocialPostDeletePopupActive
                        }
                        setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                        setIsSharePosEditActive={setIsSharePosEditActive}
                        setIsMyPosEditActive={setIsMyPosEditActive}
                        setViewsPostId={setViewsPostId}
                        setViewsListActive={setViewsListActive}
                        setEditDeleteSocialPostData={
                          setEditDeleteSocialPostData
                        }
                      />
                    )}
                    {d?.sharedPost && d?.isNasted && (
                      <NestedSharedPostComponent d={d} />
                    )}
                    <SocialLikeAndDislikeComponent
                      d={d}
                      setSharePostData={setSharePostData}
                      setSharePostId={setSharePostId}
                      setLikeId={setLikeId}
                      setTotalLikesWatching={setTotalLikesWatching}
                      setDislikeId={setDislikeId}
                      setTotalDisLikesWatching={setTotalDisLikesWatching}
                      setShareDetailsActive={setShareDetailsActive}
                      postData={socialData}
                      setPostData={setSocialData}
                      getData={getData}
                    />
                  </div>
                </div>
              );
            })}
          {!dataFound && socialData?.length <= 0 && (
            <div className="noPostfoundContainer">
              <div className="noPostfoundWrapper">
                <img src={SocialPostNotFoundIcon} alt="" />
                <h5>No Post Found</h5>
                <p>No one has posted something in social</p>
              </div>
            </div>
          )}
        </div>
        {/* {loading && <Loader />} */}
      </div>

      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}

      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}

      {isSocialPostDeletePopupActive && (
        <Popup
          firstClassName="deletePostPopupBox1"
          secondClassName="deletePostPopupBox2"
          popupHeading={`Delete Post`}
          content={
            <>
              <div className="socialPostDeleteBody">
                <p>Are you sure want to Permanently remove this Post?</p>
              </div>
              {!loading && (
                <div className="socialPostDeleteBtn">
                  <button onClick={deleteSocialPostById}>Delete</button>
                  <button
                    onClick={() => {
                      setIsSocialPostDeletePopupActive(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {loading && (
                <span
                  className="posting-socialpost"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "10px",
                  }}
                >
                  Deleting...
                </span>
              )}
            </>
          }
          handleClose={() => {
            setIsSocialPostDeletePopupActive(false);
          }}
        />
      )}

      {isSharePosEditActive && (
        <EditSharePost
          editDeleteSocialPostData={editDeleteSocialPostData}
          editDeleteSocialPostId={editDeleteSocialPostId}
          getData={getData}
          setIsSharePosEditActive={setIsSharePosEditActive}
          isCommunityorConnect="connect"
        />
      )}

      {isMyPosEditActive && (
        <EditMyPost
          editDeleteSocialPostData={editDeleteSocialPostData}
          editDeleteSocialPostId={editDeleteSocialPostId}
          setEditDeleteSocialPostData={setEditDeleteSocialPostData}
          getData={getData}
          setIsMyPosEditActive={setIsMyPosEditActive}
          isCommunityorConnect="connect"
        />
      )}

      <Routes>
        <Route
          path="/post/create"
          element={
            <CreatePost
              getData={getData}
              unreadPostCount={unreadPostCount}
            // getInitialData={getInitialData}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/post/share/:postid"
          element={
            <SharePost
              sharePostData={sharePostData}
              sharePostId={sharePostId}
              getData={getData}
              unreadPostCount={unreadPostCount}
            />
          }
        />
      </Routes>

      <Routes>
        <Route path="details/:postid" element={<SocialPostDetails />} />
      </Routes>

      {shareDetailsActive && (
        <ShareDetails
          setShareDetailsActive={setShareDetailsActive}
          sharePostId={sharePostId}
          setSharePostId={setSharePostId}
        />
      )}

      {viewsListActive && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"Social"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
    </>
  );
};
export { Connect };
