/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "./HomeDashboard.css";
import HomeDashboardBackground from "../../../../Assets/HomeDashboardBackground.svg";
import HomeDashboardFrameBackground from "../../../../Assets/HomeDashboardFrameBackground.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Icon } from "@iconify/react";
import {
  TodaysTime,
  MyApprovals,
  AttendanceCard,
  HolidayCard,
  LeaveBalanceCard,
  RequestsCard,
  GoalsCard,
  OrgCompCard,
  QuickAccessCard,
  TeamMembersCard,
  ProjectTasksCard,
} from "./Components/index";
import { getRequest } from "../../../../Services/axios";
import { closePopup, openPopup, showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Day30Form } from "./FeedbackComp/Day30Form";
import { useSelector } from "react-redux";
import ProfileAvatar from "../../../../Assets/ProfileAvatar.svg";
import { format } from "date-fns";
import StarRatings from "react-star-ratings";
import { TimePunchualityCard } from "./Components/TimePunchualityCard";
import { confirmAlert } from "react-confirm-alert";
import { PendingHomeRequest } from "./PendingHomeRequest";
import { GetOpenTask } from "./GetOpenTask";
import { PopupTable } from "../../../../Components/FormComponent/PopupComponent/PopupTable";
import { TeamMemberDetails } from "./Components/TeamCardComp/TeamMemberDetails";
import { PopProfile } from "../../../../Components/FormComponent/PopupComponent/PopProfile";
import { ProfileDetails } from "../../../Services/ProfileDetails";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { HospitalityApprove } from "../../../Office/Components/HospitalityApprove";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import GetMyHoldRequests from "./GetMyHoldRequests";
import GetDelaytask from "./GetDelaytask";
import GetTeamRequest from "./GetTeamRequest";
import TeamRequesthold from "./TeamRequesthold";
import PendingSelfapprovals from "./PendingSelfapprovals";
import PendingTeamApproval from "./PendingTeamApproval";
import Teamtaskdelay from "./Teamtaskdelay";
import GetTeamTaskTodayDeadline from "./GetTeamTaskTodayDeadline";
import TeamMOnLate from "./TeamMOnLate";
import TeamMOnleave from "./TeamMOnleave";

const HomeDashboard = ({ margin }) => {
  let performanceFlag = false;
  const navigate = useNavigate();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const employeeId = useSelector((state) => state.employee.employeeId);
  const [day30FormVisible, setDay30FormVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenss, setIsOpenss] = useState(false);
  const [isOpens, setIsOpens] = useState(false);
  const [heading, setHeading] = useState(false);
  const { featureMenuList } = useSelector((state) => state.menu);
  const [idt, setid] = useState("");
  const [approvalPopup, setApprovalPopup] = useState(false);
  // const { featureMenuList } = useSelector((state) => state.menu);
  const location = useLocation();
  const isAllTaskPresent = featureMenuList.some((item) => {
    return item.some((subItem) => {
      return subItem.featureName === "Task";
    });
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = `PeopleSol - Home`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const navigateToRoute = (path) => {
    navigate("/" + path?.toLowerCase());
  };
  const isAllGoalsPresent = featureMenuList.some((item) => {
    return item.some((subItem) => {
      return subItem.featureName === "My Goals";
    });
  });
  const [profileData, setTeamProfileData] = useState([]);
  const getTeamProfileData = async () => {
    try {
      setLoading(true);

      const result = await getRequest(
        `Settings/GetProfileCompletionData?Id=${employeeId ?? LoggedInUser?.id}`
      );
      setTeamProfileData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (typeof employeeId == "number") getTeamProfileData();
  }, [employeeId]);
  const educationDetailsData = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Education Details"
  );
  const educationDetailsDataP = profileData?.profilePercentage?.filter(
    (item) => item?.section === "Education Details"
  );
  const employeeProfile = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Employee Profile"
  );
  const experienceDetails = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Experience Details"
  );
  const personalDetails = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Personal Details"
  );
  const currentAddress = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Current Address"
  );
  const permanentAddress = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Permanent Address"
  );
  const emergencyContact = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Emergency Contact Details"
  );
  const organisationDetails = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Organisation Details"
  );
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("home")) {
      getProfileData();
      getHomeData();
      getTeamCardData();
    } else if (currentpath.includes("feedback")) {
      if (currentpath.includes("form")) {
        setDay30FormVisible(true);
      } else {
        setDay30FormVisible(false);
      }
    }
    // Update the page title when the component mounts
    document.title = `PeopleSol - Home`;

    // Optionally, you can also reset the page title when the component unmounts
    return () => {
      document.title = "PeopleSol";
    };
  }, [location.pathname]);
  useEffect(() => {
    getTeamList();
    isReportingManager();
    getFeedbackStatus();
  }, [LoggedInUser]);
  const [childData, setChildData] = useState(null);
  // Define a function to receive data from the child component
  const receiveDataFromChild = (data) => {
    setChildData(data);
  };

  const togglePopupHospitality = (id) => {
    setApprovalPopup((prev) => !prev);
    setid(id);
  };

  const defaultList = [
    "Today’s Time",
    "Attendance",
    "Time & Punctuality",
    "Goals",
    "Project & Tasks",
    "My Pending Approvals",
    "My Team",
    "Holiday",
    "Leave Balance",
    // "Quick Access",
    // "My Requests",
    // "Org. Compliances",
  ];

  const updateList = [
    "Today’s Time",
    "Attendance",
    "Time & Punctuality",
    "Goals",
    "Project & Tasks",
    "My Pending Approvals",
    "Holiday",
    "Leave Balance",
    // "Quick Access",
    // "My Requests",
    // "Org. Compliances",
  ];
  const dispatch = useDispatch();
  const [editDashboardActive, setEditDashboardActive] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [homeList, setHomeList] = useState([]);
  const [teamCardData, setTeamCardData] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [hRTeam, setHRTeam] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const getProfileData = async () => {
    try {
      setLoading(true);
      const result = await getRequest("Employees/GetBannerProfile");
      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const togglePopup = (type) => {
    setHeading(type);
    setIsOpen((prev) => !prev);
  };
  const togglePopups = (type) => {
    setHeading(type);
    setIsOpens((prev) => !prev);
  };
  const togglePopupss = () => {
    setIsOpenss((prev) => !prev);
  };
  const handlePopup = () => {
    confirmAlert({
      title: "Request",
      message: <PendingHomeRequest />,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            // Handle OK button click
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            // Handle Cancel button click
          },
        },
      ],
    });
  };
  const getHomeData = async () => {
    try {
      setLoading(true);
      const result = await getRequest("Settings/Home");
      setHomeList(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getTeamCardData = async () => {
    try {
      setLoading(true);
      const result = await getRequest("Settings/GetBannerData");
      setTeamCardData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getTeamList = async () => {
    if (!LoggedInUser?.id) return;
    try {
      setLoading(true);
      const result = await getRequest(`Employees/GetEmployeesForAttendance`);
      let listData = [{ label: "Myself", value: LoggedInUser?.id }];
      result?.data?.results?.map((item) => {
        return listData.push({
          label: item.text,
          value: item.id,
        });
      });
      setTeamList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getFeedbackStatus = () => {
    // if (LoggedInUser?.shouldRestPassword === true) {
    if (LoggedInUser?.hasOnBoardingCompleted === true) {
      if (LoggedInUser.isFirstDayfeedbackCreated === false) {
        dispatch(openPopup());
      } else if (LoggedInUser.isFirstSevenDayfeedbackCreated === false) {
        dispatch(openPopup());
      } else if (LoggedInUser.isFirstMonthfeedbackCreated === false) {
        dispatch(openPopup());
      } else {
        dispatch(closePopup());
      }
    }
    // }
  };

  const isReportingManager = () => {
    if (LoggedInUser?.isReportingManager) {
      setIsManager(true);
    }
    if (LoggedInUser?.teamnames?.includes("HR Team")) {
      setHRTeam(true);
    }
  };
  return (
    <div className="mainHomeDashboardContainer" style={{ margin: margin }}>
      {day30FormVisible ? <Day30Form /> : null}
      <div
        className={`editDashboardContainer ${
          editDashboardActive && "editDashboardContainerActive"
        }`}
      >
        <h4>Customize Layout</h4>
        <div className="horizontalLine"></div>
        <div className="editDashboardListContainer">
          <div className="editDashboardListItemContainer"></div>
        </div>
      </div>
      <div
        className="homeDashboardContainer homeDashboardMargin"
        style={{ height: "174px" }}
      >
        <div className="profileImgHolder">
          <img
            src={HomeDashboardBackground}
            alt="Profile"
            className="profileImgHolderbackground"
          />
          <div className="avatarBadge">
            {teamCardData?.selfProfileCompletionPercentage}%
            {/* <img src={InfoIcon} alt="Info" className="infoIcon" /> */}
          </div>
          <div
            className="avatarContainer"
            onClick={async () => {
              await getTeamProfileData();
              await togglePopupss("Profile");
            }}
            style={{ cursor: "pointer" }}
          >
            <CircularProgressbarWithChildren
              value={teamCardData?.selfProfileCompletionPercentage}
              text={`${teamCardData?.selfProfileCompletionPercentage}%`}
              strokeWidth={5}
              styles={buildStyles({
                textColor: "transparent",
                pathColor: "#1296B0",
                trailColor: "#D8F0F2",
                rotation: 0.37,
                strokeLinecap: "round",
              })}
            >
              {bannerList?.Image ? (
                <img
                  src={`${
                    process.env.REACT_APP_.BASE_URL
                  }/Core/Uploads/ProfileImageUploads/${
                    bannerList?.Image ?? LoggedInUser?.image
                  }`}
                  alt={"Profile"}
                  className="imageInsideProgressBar"
                />
              ) : (
                <img
                  src={ProfileAvatar}
                  alt={"Profile"}
                  className="imageInsideProgressBar"
                />
              )}
            </CircularProgressbarWithChildren>
          </div>
        </div>

        <div className="homeGreetingContainer">
          <div className="morningGreetingContainer">
            {format(new Date(), "HH:mm") >= "00:00" &&
            format(new Date(), "HH:mm") < "12:00" ? (
              <>
                <Icon
                  icon="line-md:sun-rising-twotone-loop"
                  width="27"
                  height="27"
                  color="#f7941d"
                  style={{ position: "absolute", left: "-30px" }}
                />
                <h3>Good Morning,</h3>
              </>
            ) : null}
            {format(new Date(), "HH:mm") >= "12:00" &&
            format(new Date(), "HH:mm") < "17:00" ? (
              <>
                <Icon
                  icon="line-md:sunny-outline-twotone-loop"
                  width="27"
                  height="27"
                  color="#f7941d"
                  style={{ position: "absolute", left: "-30px" }}
                />
                <h3>Good Afternoon,</h3>
              </>
            ) : null}
            {format(new Date(), "HH:mm") >= "17:00" &&
            format(new Date(), "HH:mm") <= "23:59" ? (
              <>
                <Icon
                  icon="line-md:moon-rising-twotone-alt-loop"
                  width="27"
                  height="27"
                  style={{ position: "absolute", left: "-30px" }}
                />
                <h3>Good Evening,</h3>
              </>
            ) : null}
          </div>
          <h3>{bannerList?.Name ?? "--"}</h3>
          <h4>{bannerList?.Role ?? "--"}</h4>
          <div className="horizontalLinePrimary"></div>
          {/* <div className="starContainer">
            <img src={RewardIconOrange} alt="RewardIconOrange" />
            <label>Star Performer of the Month</label>
          </div> */}
        </div>

        <div className="homeDashboardFrameContainer">
          <div className="homeDashboardFrame">
            <div className="horizontalLine"></div>
            <img
              src={HomeDashboardFrameBackground}
              alt="HomeDashboardFrameBackground"
            />
            <label>Request</label>
            <div className="homeDashboardCountHolder">
              <div
                className="frameCountHolder"
                onClick={
                  () => togglePopup("Open Request")
                  // teamCardData?.openMyRequestsCount > 0
                  //   ? togglePopup("Open Request")
                  //   : {}
                }
              >
                <label>Open Request</label>
                <label
                  className="homeDashFrameCount"
                  style={{
                    cursor:
                      teamCardData?.openMyRequestsCount > 0 ? "pointer" : "",
                  }}
                >
                  {teamCardData?.openMyRequestsCount}
                </label>
              </div>
              <div
                className="frameCountHolder"
                onClick={
                  () => togglePopup("On Hold")
                  // teamCardData?.holdMyRequestsCount > 0
                  //   ? togglePopup("On Hold")
                  //   : {}
                }
              >
                <label>On Hold</label>
                <label
                  className="homeDashFrameCount"
                  style={{
                    cursor:
                      teamCardData?.openMyRequestsCount > 0 ? "pointer" : "",
                  }}
                >
                  {teamCardData?.holdMyRequestsCount}
                </label>
              </div>
            </div>
          </div>
          <div className="homeDashboardFrame purple">
            <div className="horizontalLine"></div>
            <img
              src={HomeDashboardFrameBackground}
              alt="HomeDashboardFrameBackground"
            />
            <label>Task</label>
            <div className="homeDashboardCountHolder">
              <div
                className="frameCountHolder"
                onClick={
                  () => togglePopup("Open Task")
                  // teamCardData?.openTaskNo > 0 ? togglePopup("Open Task") : {}
                }
              >
                <label>Open Task</label>
                <label
                  className="homeDashFrameCount "
                  style={{
                    cursor:
                      teamCardData?.openTaskNo > 0 ? "pointer" : "default",
                  }}
                >
                  {teamCardData?.openTaskNo}
                </label>
              </div>
              <div
                className="frameCountHolder"
                onClick={() =>
                  teamCardData?.delayTaskNo > 0
                    ? togglePopup("Delayed Task")
                    : {}
                }
              >
                <label>Delayed Task</label>
                <label
                  className="homeDashFrameCount"
                  style={{
                    cursor: teamCardData?.delayTaskNo > 0 ? "pointer" : "",
                  }}
                >
                  {teamCardData?.delayTaskNo ?? "--"}
                </label>
              </div>
            </div>
          </div>
        </div>
        {performanceFlag ? (
          <div
            className="homeDashboardFrameContainer"
            style={{
              flexDirection: "column",
              marginLeft: "12px",
              width: "420px",
            }}
          >
            <div className="homeDashboardFrameHorizontal coral">
              <div className="horizontalLine"></div>
              <img
                src={HomeDashboardFrameBackground}
                alt="HomeDashboardFrameBackground"
              />
              <label>Performance</label>
              <div className="homeDashboardCountHolder">
                <div className="secondFrameCountHolder">
                  <label>Goal Acheivement</label>
                  <label className="homeSecondDashFrameCount">120</label>
                </div>
                <div className="secondFrameCountHolder">
                  <label>Performance Rating</label>

                  <StarRatings
                    rating={5}
                    starRatedColor="white"
                    numberOfStars={5}
                    starDimension="14px"
                    starSpacing="1px"
                    starEmptyColor="#5bc4bf"
                  />
                </div>
              </div>
            </div>
            <div className="homeDashboardFrameHorizontal pink">
              <div className="horizontalLine"></div>
              <img
                src={HomeDashboardFrameBackground}
                alt="HomeDashboardFrameBackground"
              />
              <label> Manager</label>
              <div className="homeDashboardCountHolder">
                <div className="secondFrameCountHolder">
                  <label>Manager</label>
                  <label className="homeSecondDashFrameCount">
                    {LoggedInUser?.reportingmanager ?? "--"}
                  </label>
                </div>
                <div className="secondFrameCountHolder">
                  <label>HOD</label>
                  <label className="homeSecondDashFrameCount">
                    {LoggedInUser?.hod ?? "--"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="homeDashboardFrameContainer"
            style={{
              flexDirection: "column",
              marginLeft: "12px",
              width: "310px",
            }}
          >
            <div
              className="homeDashboardFrameHorizontal coral"
              style={{ justifyContent: "flex-start", gap: "6px" }}
            >
              <img
                src={HomeDashboardFrameBackground}
                alt="HomeDashboardFrameBackground"
              />
              {LoggedInUser && (
                <div className="horizontalImageContainer">
                  {LoggedInUser?.reportingmanagerImage ? (
                    <img
                      src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.reportingmanagerImage}`}
                      alt={"Profile"}
                      style={{
                        // borderRadius: "50%",
                        // maxHeight: "100%",
                        // maxWidth: "100%",
                        height: 53,
                        width: 53,
                        borderRadius: 30,
                        marginTop: -3,
                      }}
                    />
                  ) : (
                    <img
                      src={ProfileAvatar}
                      alt={"Profile"}
                      style={{
                        height: 53,
                        width: 53,
                        borderRadius: 30,
                        marginTop: -3,
                      }}
                      className="imageInsideProgressBar"
                    />
                  )}
                </div>
              )}
              <div className="horizontalDetailContainer">
                <h6>{LoggedInUser?.reportingmanager}</h6>
                <p>Reporting Manager</p>
              </div>
            </div>
            <div
              className="homeDashboardFrameHorizontal pink"
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <img
                src={HomeDashboardFrameBackground}
                alt="HomeDashboardFrameBackground"
              />
              {LoggedInUser && (
                <div className="horizontalImageContainer">
                  <img
                    src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.hodImage}`}
                    alt={"Profile"}
                    style={{
                      height: 53,
                      width: 53,
                      borderRadius: 30,
                      marginTop: -3,
                      // borderRadius: "50%",
                      // maxHeight: "100%",
                      // maxWidth: "100%",
                    }}
                  />
                </div>
              )}
              <div className="horizontalDetailContainer">
                <h6>{LoggedInUser?.hod}</h6>
                <p>Department Head</p>
              </div>
            </div>
          </div>
        )}
      </div>
      {isManager && (
        <div className="homeSecondaryContainer">
          <div className="homeSecondaryDash" style={{ flexGrow: 1 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "12px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "12px",
                  justifyContent: "space-around",
                }}
              >
                <div
                  className="teamActionContainer"
                  style={{
                    color: "#77A2C7",
                    backgroundColor: "#EAF5FF",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    togglePopup("Team Members on Leave Today");
                  }}
                >
                  <p className="teamCardTodayTextHolder">
                    Team members on leave today
                  </p>
                  <p className="secondaryCountHolder">
                    {teamCardData?.teamOnLeave ?? "--"}
                  </p>
                </div>
                <div
                  className="teamActionContainer"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => togglePopup("Team Task Today Deadline")}
                >
                  <p className="teamCardTodayTextHolder">
                    Team task with today's deadline
                  </p>
                  <p className="secondaryCountHolder">
                    {teamCardData?.teamTodayTaskNo ?? "--"}
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "12px",
                  justifyContent: "space-around",
                }}
              >
                <div
                  className="teamActionContainer"
                  style={{
                    color: "#FB7158",
                    backgroundColor: "#FFF2F0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    togglePopup("Team Members Came Late Today");
                  }}
                >
                  <p className="teamCardTodayTextHolder">
                    Team members came late today
                  </p>
                  <p className="secondaryCountHolder">
                    {teamCardData?.teamOnLate ?? "--"}
                  </p>
                </div>
                {/* <div
                className="teamActionContainer"
                style={{ color: "#8A88ED", backgroundColor: "#F0F0FF" }}
              >
                <p className="teamCardTodayTextHolder">
                  Request missed your approval TAT
                </p>
                <p className="secondaryCountHolder">{"--"}</p>
              </div> */}
                <div
                  className="teamActionContainer"
                  style={{
                    color: "#DA5DB6",
                    backgroundColor: "#FFEEF2",
                    cursor:
                      teamCardData?.teamDelayTaskNo > 0 ? "pointer" : "default",
                  }}
                  onClick={() =>
                    teamCardData?.teamDelayTaskNo > 0
                      ? togglePopup("Team Task Today Delay")
                      : {}
                  }
                >
                  <p className="teamCardTodayTextHolder">
                    Team task missed deadlines
                  </p>
                  <p className="secondaryCountHolder">
                    {teamCardData?.teamDelayTaskNo ?? "--"}
                  </p>
                </div>
              </div>
              {/* <div
                className="teamActionContainer"
                style={{ color: "#0DB53C", backgroundColor: "#E7F8EC" }}
              >
                <p className="teamCardTodayTextHolder">
                  Request approaching missing TAT
                </p>
                <p className="secondaryCountHolder">{"--"}</p>
              </div> */}
            </div>
          </div>
          <div className="homeSecondaryDash secondaryFrameContainer">
            <div
              className="homeDashboardFrame secondaryOne"
              style={{ width: "32%" }}
            >
              <div className="horizontalLine"></div>
              <img
                src={HomeDashboardFrameBackground}
                alt="HomeDashboardFrameBackground"
              />
              <label>Pending Approvals</label>
              <div className="homeDashboardCountHolder">
                <div
                  className="frameCountHolder"
                  onClick={
                    () => togglePopup("Self Approval")
                    //   teamCardData?.selfApproval > 0
                    //     ? togglePopup("Self Approval")
                    //     : {}
                  }
                >
                  <label>Self</label>
                  <label
                    className="homeDashFrameCount"
                    style={{
                      cursor:
                        teamCardData?.selfApproval > 0 ? "pointer" : "default",
                    }}
                  >
                    {teamCardData?.selfApproval ?? "--"}
                  </label>
                </div>
                <div
                  className="frameCountHolder"
                  onClick={
                    () => togglePopup("Team Approval")

                    // teamCardData?.teamApproval > 0
                    //   ? togglePopup("Team Approval")
                    //   : {}
                  }
                >
                  <label>Team</label>
                  <label
                    className="homeDashFrameCount"
                    style={{
                      cursor:
                        teamCardData?.teamApproval > 0 ? "pointer" : "default",
                    }}
                  >
                    {teamCardData?.teamApproval ?? "--"}
                  </label>
                </div>
              </div>
            </div>
            <div
              className="homeDashboardFrame secondaryThree"
              style={{ width: "32%" }}
            >
              <div className="horizontalLine"></div>
              <img
                src={HomeDashboardFrameBackground}
                alt="HomeDashboardFrameBackground"
              />
              <label>Team Request</label>
              <div className="homeDashboardCountHolder">
                <div
                  className="frameCountHolder"
                  onClick={
                    () => togglePopup("Team Request")
                    // teamCardData?.openTeamRequestsCount > 0
                    //   ? togglePopup("Team Request")
                    //   : {}
                  }
                >
                  <label>Open</label>
                  <label
                    className="homeDashFrameCount"
                    style={{
                      cursor:
                        teamCardData?.openTeamRequestsCount > 0
                          ? "pointer"
                          : "default",
                    }}
                  >
                    {teamCardData?.openTeamRequestsCount}
                  </label>
                </div>
                <div
                  className="frameCountHolder"
                  onClick={
                    () => togglePopup("Team Hold Requests")
                    // teamCardData?.holdTeamRequestsCount > 0
                    //   ? togglePopup("Team Hold Requests")
                    //   : {}
                  }
                >
                  <label>Hold</label>
                  <label
                    className="homeDashFrameCount"
                    style={{
                      cursor:
                        teamCardData?.holdTeamRequestsCount > 0
                          ? "pointer"
                          : "default",
                    }}
                  >
                    {teamCardData?.holdTeamRequestsCount}
                  </label>
                </div>
              </div>
            </div>
            <div
              className="homeDashboardFrame secondaryFour"
              style={{ width: "32%" }}
            >
              <div className="horizontalLine"></div>
              <img
                src={HomeDashboardFrameBackground}
                alt="HomeDashboardFrameBackground"
              />
              <label>Profile Completion</label>
              <div className="homeDashboardCountHolder">
                <div className="frameCountHolder">
                  <label>Self</label>
                  <label className="homeDashFrameCount">
                    {teamCardData?.selfProfileCompletionPercentage
                      ? `${teamCardData?.selfProfileCompletionPercentage.toFixed(
                          0
                        )}%`
                      : "--"}
                  </label>
                </div>
                <div className="frameCountHolder">
                  <label>Team</label>
                  <label className="homeDashFrameCount">
                    {teamCardData?.teamProfileCompletionPercentage
                      ? `${teamCardData?.teamProfileCompletionPercentage.toFixed(
                          0
                        )}%`
                      : "--"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="homeCardsHolderContainer">
        <div className="homeDashboardCardHolder">
          {(isManager ? defaultList : updateList).map((item, index) => {
            // Define a variable to check if UI content is present
            let hasUIContent = false;

            let cardContent = null;

            if (item === "Attendance") {
              cardContent = (
                <AttendanceCard
                  hRTeam={hRTeam}
                  isManager={isManager}
                  navigate={navigate}
                  teamList={teamList}
                />
              );
              hasUIContent = true;
            } else if (item === "Time & Punctuality") {
              cardContent = (
                <TimePunchualityCard
                  hRTeam={hRTeam}
                  teamList={teamList}
                  navigate={navigate}
                  isManager={isManager}
                />
              );
              hasUIContent = true;
            } else if (item === "Holiday") {
              cardContent = (
                <>
                  <div
                    className="headingContainer cursor"
                    onClick={() => navigate("/hrms/timeattendance/holiday")}
                  >
                    <h3>{item}</h3>
                  </div>
                  <HolidayCard />
                </>
              );
              hasUIContent = true;
            } else if (item === "Leave Balance") {
              cardContent = (
                <LeaveBalanceCard
                  navigate={navigate}
                  isManager={isManager}
                  hRTeam={hRTeam}
                  teamList={teamList}
                />
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } else if (item === "Today’s Time") {
              cardContent = (
                <TodaysTime
                  isManager={isManager}
                  hRTeam={hRTeam}
                  homeList={homeList}
                />
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } else if (item === "My Pending Approvals") {
              cardContent = (
                <>
                  <div
                    className="headingContainer cursor"
                    onClick={() => navigate("/approvals/requisition")}
                  >
                    <h3>
                      {item}
                      {homeList?.approvals &&
                        ` (${
                          homeList?.approvals?.length > 0
                            ? homeList?.approvals?.length
                            : "0"
                        })`}
                    </h3>
                  </div>
                  <MyApprovals
                    togglePopupHospitality={togglePopupHospitality}
                    setApprovalPopup={setApprovalPopup}
                    setid={setid}
                  />
                </>
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } else if (item === "My Team" && isManager) {
              cardContent = (
                <>
                  <div
                    className="headingContainer cursor"
                    onClick={() => navigate("/services/profile/team")}
                  >
                    <h3>
                      {item}
                      {homeList?.teamMembers &&
                        ` (${
                          homeList?.teamMembers?.length > 0
                            ? homeList?.teamMembers?.length
                            : "--"
                        })`}
                    </h3>
                  </div>
                  <TeamMembersCard
                    setIsOpens={setIsOpens}
                    homeList={homeList}
                    sendDataToParent={receiveDataFromChild}
                  />
                </>
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } else if (item === "Project & Tasks" && isAllTaskPresent) {
              cardContent = (
                <>
                  {/* <div
            className="headingContainer cursor"
            onClick={() => navigate("/hrms/projecttask/customer")}
          >
            <h3>{item}</h3>
          </div> */}
                  <ProjectTasksCard
                    navigate={navigate}
                    hRTeam={hRTeam}
                    isManager={isManager}
                    teamList={teamList}
                  />
                </>
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } else if (item === "My Requests") {
              cardContent = (
                <>
                  <div className="headingContainer">
                    <h3>{item}</h3>
                  </div>
                  <RequestsCard />
                </>
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } else if (item === "Goals" && isAllGoalsPresent) {
              cardContent = (
                <>
                  <GoalsCard
                    hRTeam={hRTeam}
                    isManager={isManager}
                    navigate={navigate}
                    teamList={teamList}
                  />
                </>
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } else if (item === "Org. Compliances") {
              cardContent = (
                <>
                  <div className="headingContainer">
                    <h3>{item}</h3>
                  </div>
                  <OrgCompCard />
                </>
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } else if (item === "Quick Access") {
              cardContent = (
                <>
                  <div className="headingContainer">
                    <h3>{item}</h3>
                  </div>
                  <QuickAccessCard />
                </>
              );
              hasUIContent = true; // Set hasUIContent to true since there is data
            } // Add more conditions for other cards

            // Render the card if it has UI content
            return hasUIContent ? (
              <div className="dashboardCard" key={index}>
                <div className="cardContainer">
                  <div className="cardDataContainer">{cardContent}</div>
                </div>
              </div>
            ) : null;
          })}
        </div>
      </div>
      {approvalPopup && (
        <Popup
          popupHeading={"Approve by hospitality manager"}
          content={
            <HospitalityApprove
              data={"PostApRejHol"}
              // listId={listId}
              type={"Approve by Hospitality Manager"}
              id={idt}
              color={"red"}
              handleClose={togglePopupHospitality}
              isHomepage={true}
            />
          }
          handleClose={togglePopupHospitality}
        />
      )}
      {isOpen && (
        <div className="parentContainers">
          <PopupTable
            popupHeading={heading}
            handleClose={togglePopup}
            contentClassName={"hiddens"}
            content={
              heading === "Open Task" ? (
                <div className="tableContainers">
                  <GetOpenTask navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Delayed Task" ? (
                <div className="tableContainers">
                  <GetDelaytask navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "On Hold" ? (
                <div className="tableContainers">
                  <GetMyHoldRequests navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Team Request" ? (
                <div className="tableContainers">
                  <GetTeamRequest navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Team Hold Requests" ? (
                <div className="tableContainers">
                  <TeamRequesthold navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Self Approval" ? (
                <div className="tableContainers">
                  <PendingSelfapprovals navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Team Approval" ? (
                <div className="tableContainers">
                  <PendingTeamApproval navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Team Task Today Delay" ? (
                <div className="tableContainers">
                  <Teamtaskdelay navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Team Task Today Deadline" ? (
                <div className="tableContainers">
                  <GetTeamTaskTodayDeadline navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Team Members Came Late Today" ? (
                <div className="tableContainers">
                  <TeamMOnLate navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Team Members on Leave Today" ? (
                <div className="tableContainers">
                  <TeamMOnleave navigateToRoute={navigateToRoute} />
                </div>
              ) : heading === "Open Request" ? (
                <div className="tableContainers">
                  <PendingHomeRequest navigateToRoute={navigateToRoute} />
                </div>
              ) : (
                <div className="tableContainers">
                  <PendingHomeRequest navigateToRoute={navigateToRoute} />
                </div>
              )
            }
          />
        </div>
      )}
      {isOpens && (
        <div className="parentContainers">
          <PopProfile
            secondClassName={"employteam"}
            contentClassName={"contentClassName"}
            popupHeading={"Details"}
            content={
              <TeamMemberDetails
                handleClose={togglePopups}
                selfProfileCompletionPercentage={
                  teamCardData?.selfProfileCompletionPercentage
                }
                selectedId={childData}
                image={bannerList?.Image}
              />
            }
          />
        </div>
      )}
      {isOpenss && (
        <OutsideClickHandler onOutsideClick={togglePopupss}>
          <PopProfile
            content={
              <>
                <ProfileDetails
                  loading={loading}
                  getProfileData={getProfileData}
                  minHeigh={"400px"}
                  educationDetailsData={educationDetailsData}
                  personalDetails={personalDetails}
                  currentAddress={currentAddress}
                  permanentAddress={permanentAddress}
                  organisationDetails={organisationDetails}
                  emergencyContact={emergencyContact}
                  employeeProfile={employeeProfile}
                  handleClose={togglePopupss}
                  experienceDetails={experienceDetails}
                  bannerList={bannerList}
                  ProfileAvatar={ProfileAvatar}
                  profilePercentage={
                    teamCardData?.selfProfileCompletionPercentage
                  }
                />
              </>
            }
          />
        </OutsideClickHandler>
      )}
    </div>
  );
};
export { HomeDashboard };
