export const OpenFile = (module, file) => {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/${module}/${file}`
  );
  element.setAttribute("target", "blank");
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
