import React from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";

function Identification(props) {
  useEffect(() => {
    document.title = `PeopleSol - Identification`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="formcustom threeColumn">
      <div>
        <LabelCustom labelName={"Aadhar Number"} />
        <InputCustomFormik
          name="aadhaarNumber"
          step=".001"
          placeholder={"Enter Aadhar Number"}
        />
      </div>
      <div>
        <LabelCustom labelName={"PAN Number"} />
        <InputCustomFormik
          name="panNumber"
          type={"text"}
          placeholder={"Enter PAN Number"}
          style={{ textTransform: "uppercase" }}
        />
      </div>
      <div>
        <LabelCustom labelName={"UAN Number"} />
        <InputCustomFormik
          name="uannumber"
          step=".001"
          placeholder={"Enter UAN Number"}
        />
      </div>

      {/* <div>
        <LabelCustom labelName={"Provident Fund Number"} />
        <InputCustomFormik
          name="pfnumber"
          type={"text"}
          placeholder={"Enter Provident Fund Number"}
        />
      </div> */}
      <div>
        <LabelCustom labelName={"Passport Number"} />
        <InputCustomFormik
          name="passportNumber"
          type={"text"}
          placeholder={"Enter Passport Number"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Passport Issue Date"} />
        <DatePickerFormiks
          name="passportIssueDate"
          placeholder={"Select Passport Issue Date"}
          maxDate={new Date()}
        />
      </div>
      <div>
        <LabelCustom labelName={"Passport Expiry date"} />
        <DatePickerFormiks
          name="passportExpiryDate"
          placeholder={"Select Passport Expiry date"}
          minDate={new Date()}
        />
      </div>
    </div>
  );
}

export { Identification };
