import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";

import { reqCategorySchema } from "../../HRMSMaster/HRDeskComponents/RequestCategorySchemas";
import { GroupsForm } from "./Form/GroupsForm";
import { useEffect } from "react";



const CreateGroups = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Create Goups`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const postFormData = async (values) => {
    try {
      
      const result = await postRequest(
        "ExceptionGroups/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {}
  };
  const getDetails = async () => {
    try {
      const result = await getRequest(`ExceptionGroups/Details/${data}`);
      setDetail(result.data);
    } catch (error) {        dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) 
    } finally {
    }
  };
  React.useEffect(() => {
    if(data){
      getDetails();
    }
  }, [data]);
 const initialvalue = {
  name: data?detail?.name:"",
};
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "ExceptionGroups/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }

    } catch (error) { dispatch(
      showToast({ text: error?.response?.data, severity: "error" })
    ) }
  };

  const onSubmit = async (values) => {

    const edit = {
      ...values,
      id: data,
    };

    const create = {
      ...values,
    };
    
    data ? Edit(edit) : postFormData(create);


    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/itsmmaster/exception/groups");
  };

  return (
    <FormCustomFormik
    ButtonLabel={data?'Update Exception Group':'Create Exception Group'}
      FormHeading={data?'Edit Exception Group':'Create Exception Group'}
      validationSchema={reqCategorySchema}
      onSubmit={onSubmit}
      initialvalue={
        
          initialvalue
      }
      returnPage={returnPage}
      propsDiv={<GroupsForm  />}
    />
  );
};

export { CreateGroups };

