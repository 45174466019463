import React, { useState, useEffect } from "react";
import { getRequest } from "../../../Services/axios";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";

import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";

const opt = [{ label: "select", value: null }];

export const CreateContactDirectoryComp = ({ data }) => {
  const [categoryList, setCategoryList] = useState([]);
  const dispatch = useDispatch();

  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployees");
      let listData = [];
      result?.data?.results?.map((item) => {
        listData?.push({ label: item?.text, value: item?.id });
      });
      setCategoryList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Contact Directory`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Designation"} />
        <InputCustomFormik
          maxLength={150}
          name="contact"
          type={"text"}
          placeholder={"Enter Designation"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Employee"} mandatory={true} />
        <SelectForm
          name="employeeId"
          placeholder={"Select Employee"}
          options={categoryList}
          values={
            data
              ? categoryList.filter(
                  (options) => options.label === data?.Employee
                )
              : opt
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"Email"} />
        <InputCustomFormik
          name="email"
          type={"text"}
          placeholder={"Enter Email"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} />
        <TextAreaFormik
          maxLength={500}
          name="description"
          placeholder={"Enter Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Extension No."} />
        <InputCustomFormik
          name="epabx"
          step=".001"
          placeholder={"Enter Extension No."}
        />
      </div>
      <div>
        <LabelCustom labelName={"Mobile"} />
        <InputCustomFormik
          name="mobile"
          type={"text"}
          placeholder={"Enter Mobile"}
        />
      </div>
    </div>
  );
};
