import React, { useState } from "react";
import "./OrgStructure.css";
import BoxContentHolder from "./BoxContentHolder";
import ContentHolderBack from "../../../Assets/ContentHolderBack.svg";
import dateFormat from "dateformat";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getRequest } from "../../../Services/axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const OrgStructure = ({ profileList }) => {
  // const [profileList, setProfileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  // const getProfileDetails = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await getRequest(`Employees/Profile/${LoggedInUser?.id}`);
  //     setProfileList(result?.data?.data);
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: "some error occured in fetching data",
  //         severity: "error",
  //       })
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    document.title = `PeopleSol -  Organisation Structure`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // useEffect(() => {
  //   if (location.pathname.toLowerCase().includes("orgstructure"))
  //     getProfileDetails();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  if (loading) return <Loader />;
  else
    return (
      <div className="container">
        <div className="subContainer">
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Department"
              text={profileList?.department ? profileList?.department : "---"}
            />
            <BoxContentHolder
              heading="Sub Department"
              text={
                profileList?.subDepartment ? profileList?.subDepartment : "---"
              }
            />
            <BoxContentHolder
              heading="Personal Area"
              text={
                profileList?.personalArea ? profileList?.personalArea : "---"
              }
            />
            <BoxContentHolder
              heading="Personal Subarea"
              text={
                profileList?.locationName ? profileList?.locationName : "---"
              }
            />
            <BoxContentHolder
              heading="Area"
              text={profileList?.area ? profileList?.area : "---"}
              last="last"
            />
          </div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Role"
              text={profileList?.roleName ? profileList?.roleName : "---"}
            />
            <BoxContentHolder
              heading="Designation"
              text={profileList?.designation ? profileList?.designation : "---"}
            />
            <BoxContentHolder
              heading="Band"
              text={profileList?.bandName ? profileList?.bandName : "---"}
            />
            <BoxContentHolder
              heading="Grade"
              text={profileList?.grade ? profileList?.grade : "---"}
            />
            <BoxContentHolder
              heading="Employment Status"
              text={
                profileList?.probationStatus
                  ? profileList?.probationStatus
                  : "---"
              }
              last="last"
            />
          </div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Employee ID"
              text={
                profileList?.personNumber ? profileList?.personNumber : "---"
              }
            />
            <BoxContentHolder
              heading="Reporting Manager"
              text={profileList?.rO_Name ? profileList?.rO_Name : "---"}
            />
            <BoxContentHolder
              heading="Department HOD"
              text={profileList?.fhoD_Name ? profileList?.fhoD_Name : "---"}
            />
            <BoxContentHolder
              heading="Shift"
              text={profileList?.shift ? profileList?.shift.title : "---"}
            />
            <BoxContentHolder
              heading="Cost Centre"
              text={profileList?.costCentre ? profileList?.costCentre : "---"}
              last="last"
            />
          </div>
        </div>
        <div className="subContainer">
          <div className="subContainerHeading">Calendar</div>
          <div className="contentHolder">
            <img
              src={ContentHolderBack}
              alt="ContentBackground"
              className="contentBackground"
            />
            <BoxContentHolder
              heading="Date of Joining"
              text={
                profileList?.doj
                  ? dateFormat(profileList?.doj, "dd/mm/yyyy")
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Probation End Date"
              text={
                profileList?.probationEndDate
                  ? dateFormat(profileList?.probationEndDate, "dd/mm/yyyy")
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Current Experience"
              text={
                profileList?.currentExperience
                  ? `${profileList?.currentExperience} years`
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Birthday"
              text={
                profileList?.dob
                  ? dateFormat(profileList?.dob, "dd/mm/yyyy")
                  : "---"
              }
            />
            <BoxContentHolder
              heading="Anniversary"
              text={
                profileList?.dom
                  ? dateFormat(profileList?.dom, "dd/mm/yyyy")
                  : "---"
              }
              last="last"
            />
          </div>
        </div>
        <div className="subContainer">
          {profileList?.team ? (
            <div className="subContainerHeading">Org Team</div>
          ) : null}
          {profileList?.team ? (
            <div className="orgTeamHolder">
              <div className="orgTeamData">HR Team</div>

              {/* <div className="orgTeamData">
          Offboarding Team
          </div>
          <div className="orgTeamData">
          Payroll
          </div>
          <div className="orgTeamData">
          PMS
          </div>
          <div className="orgTeamData">
          Expense
          </div>
          <div className="orgTeamData">
          Driver
          </div>
          <div className="orgTeamData">
          BMS
          </div>
          <div className="orgTeamData">
          Trainers
          </div>
          <div className="orgTeamData">
          Transfer Management
          </div>
          <div className="orgTeamData">
          Rewards & Recognition
          </div>
          <div className="orgTeamData">
          Recruitment Team
          </div> */}
            </div>
          ) : null}
        </div>
      </div>
    );
};

export { OrgStructure };
