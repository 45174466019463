import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { CreateProjectsComp } from "./FormComp/CreateProjectsComp";

const CreateProjects = (props) => {
  const { state } = useLocation();

  const data = state?.id;

  const [detailsform, setDetailsform] = useState(null);

  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(null);

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Projects/Details?ID=${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Projects`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("object formValues", formValues);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequestForm("Projects/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequestForm("Projects/Edit", values);
      dispatch(
        showToast({ text: "Successfully Updated", severity: "success" })
      );
    } catch (error) {
      // console.log("err",error)
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      returnPage();
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getInitalAnswerValues = () => {
    let returnArray = [];
    data
      ? detailsform?._project?.projectTeams?.map((item) => {
          return returnArray.push({
            EmployeeId: item.employeeId,
            RoleId: item.roleId,
            Description: item.description,
          });
        })
      : (returnArray = [{ EmployeeId: "", RoleId: "", Description: "" }]);

    return returnArray;
  };

  const initialValue = {
    name: data ? detailsform?._project?.name : "",
    code: data ? detailsform?._project?.code : "",
    type: data ? detailsform?._project?.projectType : "",
    isBillable: data
      ? detailsform?._project?.isBillable === true
        ? "Billable"
        : "Non-Billable"
      : "",
    customerId: data ? detailsform?._project?.customerId : 0,
    department: data
      ? detailsform?.projectdepartments?.map((item) => item?.id)
      : [],
    location: data
      ? detailsform?.projectlocations?.map((item) => item?.id)
      : [],
    attachments: data
      ? detailsform?._project?.attachments &&
        `projects/${detailsform?._project?.attachments}`
      : "",
    tagName: data ? detailsform?._project?.tagName : "",
    description: data ? detailsform?._project?.description : "",
    startDate: data
      ? detailsform?._project?.startsOn &&
        new Date(detailsform?._project?.startsOn)
      : "",
    endDate: data
      ? detailsform?._project?.endsOn && new Date(detailsform?._project?.endsOn)
      : "",
    estimatedHours: data ? detailsform?._project?.estimatedHours : "",
    costEstimation: data ? detailsform?._project?.costEstimation : "",
    billingSchedule: data ? detailsform?._project?.billingSchedule : "",
    BillingTypeId: data ? detailsform?._project?.billingTypeId : "",
    billingRate: data ? detailsform?._project?.billingRate : "",
    ProjectTeam: getInitalAnswerValues(),
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Name"),
    // tagName: Yup.string().required("Please Enter Tag Name"),
    // estimatedHours: Yup.string().required("Please Enter Estimated Hours"),
    // costEstimation: Yup.string().required("Please Enter Estimated Cost"),
    // description: Yup.string().required("Please Enter Estimated Hour"),
    // customerId: Yup.string().required("Please Select Customer Name"),
    // code: Yup.string().required("Please Enter Code"),
    startDate: Yup.string().required("Please Select Start Date"),
    endDate: Yup.string().required("Please Select End Date"),
    // billingRate: Yup.string().required("Please Enter Billing Rate "),
    // BillingTypeId: Yup.string().required("Please Select Billing Type"),
  });

  // work on onSubmit after the api is developed

  const onSubmit = (values) => {
    const formData = new FormData();

    if (data) formData.append("id", data);
    formData.append("name", values?.name ?? "");
    formData.append("code", values?.code ?? "");
    formData.append("StartsOn", `${format(values?.startDate, "MM/dd/yyyy")}`);
    formData.append("EndsOn", `${format(values?.endDate, "MM/dd/yyyy")}`);
    formData.append("projectType", values?.type);
    formData.append(
      "isBillable",
      values?.isBillable === "Non-Billable" ? false : true
    );
    if (values?.billingRate)
      formData.append("billingRate", values?.billingRate);
    if (values?.customerId) formData.append("customerId", values?.customerId);
    // formData.append("departments", values?.department);
    values?.location?.forEach((locations, index) => {
      formData.append(`locations[${index}]`, locations);
    });
    values?.department?.forEach((departments, index) => {
      formData.append(`departments[${index}]`, departments);
    });
    if (typeof values.attachments === "object")
      formData.append("upload", values?.attachments);
    formData.append("tagName", values?.tagName ?? "");
    formData.append("description", values?.description ?? "");
    formData.append("estimatedHours", values?.estimatedHours);
    if (values?.costEstimation)
      formData.append("costEstimation", values?.costEstimation);
    if (values?.BillingTypeId)
      formData.append("BillingTypeId", values?.BillingTypeId);
    formData.append("Status", "Yet to Start");
    if (values?.billingSchedule)
      formData.append("billingSchedule", values?.billingSchedule);
    values?.ProjectTeam.forEach(function (object, index) {
      if (object.EmployeeId)
        formData.append(`ProjectTeam[${index}].EmployeeId`, object.EmployeeId);
      if (object.RoleId)
        formData.append(`ProjectTeam[${index}].RoleId`, object.RoleId);
      if (object.Description)
        formData.append(
          `ProjectTeam[${index}].Description`,
          object.Description
        );
    });

    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      styles={{ maxWidth: "75%", width: "100%" }}
      FormHeading={!data ? "Create Project" : "Update Project"}
      ButtonLabel={data ? "Update Project" : "Create Project"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialValue}
      returnPage={returnPage}
      propsDiv={
        <CreateProjectsComp
          data={detailsform}
          editID={data ? true : false}
          formValues={formValues}
          id={data}
        />
      }
    />
  );
};

export { CreateProjects };
