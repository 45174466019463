import CloseIcon from "../../../Assets/CloseIcon.svg";
import React from "react";
import "./popup.css";
import { IconButton } from "@mui/material";
const PopupTable = ({
  handleClose,
  content,
  popupHeading,
  firstClassName,
  secondClassName,
  createMyHead,
  bodyClassName,
  contentClassName,
}) => {
  return (
    <div className={"popup-box " + (firstClassName ?? "")}>
      <div
        className={"box " + (secondClassName ?? "")}
        style={{ width: "fit-content" }}
      >
        <div className={`${createMyHead ?? "createHead"}`}>
          <label>{popupHeading}</label>
          <IconButton onClick={handleClose} title="Close">
            <img className="close-icon" src={CloseIcon} alt="close" />
          </IconButton>
        </div>
        <div className="horizontalLine createLine"></div>
        <div className={"bodyContainer " + (bodyClassName ?? "")}>
          <div className={"popupContentHolder " + (contentClassName ?? "")}>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export { PopupTable };
