import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";

const opt = [{ label: "select", value: null }];
const WorkFlowRolesForm = ({ data, editID }) => {
  useEffect(() => {
    document.title = `PeopleSol - Workflow Role Form`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={51}
          type={"text"}
          name="name"
          placeholder={"Enter Name "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Associated Employee"} mandatory={true} />
        {/* <SelectForm
          values={
            data
              ? option.filter(
                  (options) => options.label === data?.AssociatedEmployee
                )
              : opt
          }
          name="associatedEmployeeId"
          placeholder={" Select Associated Employee"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        /> */}
        <DropdownInfiniteScroll
          name="associatedEmployeeId"
          placeholder={" Select Associated Employee"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.associatedEmployeeId]}
        />
      </div>
    </div>
  );
};
export { WorkFlowRolesForm };
