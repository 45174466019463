import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    popup: false,
}

const PopupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        closePopup: state => {
            state.popup = false;
        },
        openPopup: state => {
            state.popup = true; 
        },
    },
})
export const { closePopup, openPopup } = PopupSlice.actions;
export default PopupSlice.reducer;