import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { DesignPrint } from "../Components/DesignPrint";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { MarkEntryComp } from "../CompanyCarComponents/FormComp/MarkEntryComp";
import { getBadgeCount } from "../../../Features/badgeUtils";
import { NonceProvider } from "react-select";
import { format } from "date-fns";
import { TimelineHospitality } from "../../HRMS/Components/DetailsComp/TimelineHospitality";

const DetailsPageVisitors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [listided, setlistided] = useState(false);
  const [qrCodeState, setQrCode] = useState(false);
  const [selId, setSelId] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"
      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (id, type, color, listid) => {
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setlistided(listid);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  useEffect(() => {
    document.title = `PeopleSol - Visitors`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `VisitorsController/Details?Id=${getIdFromDynamicUrl(currentUrl)}`
      );
      // console.log("result VisitorsController", result);
      setData(result.data.summary);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.action.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.summary?.action[0]?.id,
              approve,
              result.data?.summary?.action[0]?.color,
              result.data?.summary?._visits?.id
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.action[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Cancel" || type === "Release") {
        await postRequest(`VisitorsController/Cancel?Id=${id}`);
      } else if (type === "Mark Entry") {
        await postRequest(`VisitorsController/MarkEntry?Id=${id}`);
      } else if (type === "Mark Exit") {
        await postRequest(`VisitorsController/SaveOutTime?pk=${id}`);
      }
      // else {
      //   await getRequest(
      //     `MeetingRoomBookings/${type}?Id=${id}&Remarks=${Remark}`
      //   );
      // }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getInitalMarkEntryForm = () => {
    let returnArray = [];
    data?._visits?.visitingPeople?.map((item) => {
      return returnArray.push({
        name: item?.name ? item?.name : "",
        phoneNumber: item?.phoneNumber ? item?.phoneNumber : "",
        bloodGroup: item?.bloodGroup ? item?.bloodGroup : "",
        upload: null,
        photoUpload: null,
      });
    });

    return returnArray;
  };
  const getInitalVisitlocationlistForm = () => {
    let returnArray = [];
    data?._visits?.visitsLocations?.map((item) => {
      return returnArray.push({
        inTime: item?.inTime ? new Date() : "",
      });
    });

    return returnArray;
  };

  const initialvalue = {
    // inTime: "",
    inTime: data?._visits?.visitsLocations?.[0]?.inTime
      ? new Date(data?.visitsLocations?.[0]?.inTime)
      : "",
    vehicleNumber: data?._visits?.vehicleNumber
      ? data?._visits?.vehicleNumber
      : "",
    Visitlist: getInitalMarkEntryForm(),
    Visitlocationlist: getInitalVisitlocationlistForm(),
  };

  // console.log("datary", data);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Visitor Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data != null ? (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    {/* <div
                      className="headingForm"
                      style={{ borderBottom: "none" }}
                    >
                      Request Details
                    </div> */}
                    <h4 className="headingForm">Who is Coming?</h4>
                    <div className="detailInner" style={{ width: "100%" }}>
                      {/* <table style={{ width: "100%", border: '1px solid black', }}> */}
                      <table
                        className="tabless tabless-bordered"
                        style={{
                          overflowX: "none",
                          fontSize: "13px",
                          color: "#4e5e6a",
                          // margin: "0px 18px 0 18px",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "var(--primary-hover)",
                          }}
                        >
                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              Full Name{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              Phone No.{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              Email{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              ID Proof{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              Photo{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              Blood Group{" "}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              Actions{" "}
                            </td>
                          </tr>
                        </thead>
                        {data?._visits?.visitingPeople?.map((person, index) => (
                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              {person?.name ? person?.name : "-"}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              {person?.phoneNumber ? person?.phoneNumber : "-"}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              {person?.email ? person?.email : "-"}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              {person?.attachement ? person?.attachement : "-"}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              {person?.photo ? person?.photo : "-"}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                              }}
                            >
                              {person?.bloodGroup ? person?.bloodGroup : "-"}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                                paddingLeft: "10px",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <div
                                style={{ backgroundColor: "rgba(18, 150, 176, 1)", color: "white", paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, borderRadius: 6, textAlign: "center", display: "block", width: "150px" }}
                                onClick={() => {
                                  confirmAlert({
                                    customUI: () => {
                                      return (
                                        <div
                                          style={{
                                            backgroundColor: "white",
                                            border: " 1px solid grey",
                                          }}
                                        >
                                          <DesignPrint
                                            personid={person?.id}
                                            visitid={data?._visits?.id}
                                            QrCode={
                                              data?.visitingpeople[index]
                                                ?.qrcode
                                            }
                                            LoggedInUser={LoggedInUser}
                                            data={data}
                                            index={index}
                                          />
                                        </div>
                                      );
                                    },
                                  });
                                }}
                              >
                                Print Visitor Card
                              </div>
                            </td>
                          </tr>
                        ))}
                      </table>

                      <>
                        <Details
                          detailTitle={"Request Number"}
                          Details={data?._visits?.requestNumber}
                        />
                        <Details
                          detailTitle={"What is the purpose?"}
                          Details={data?._visits?.purpose}
                        />
                        <Details
                          detailTitle={"From which company?"}
                          Details={data?._visits?.company}
                        />
                        <Details
                          detailTitle={"From which location?:"}
                          Details={data?._visits?.location}
                        />

                        <Details
                          detailTitle={"In which vehicle?:"}
                          Details={data?._visits?.vehicleType}
                        />
                        <Details
                          detailTitle={"Vehicle Number:"}
                          Details={data?._visits?.vehicleNumber}
                        />
                        <Details
                          detailTitle={"Created By"}
                          Details={
                            data?._visits?.employee?.nameWithPersonNumber
                          }
                        />
                        <Details
                          detailTitle={"Remarks"}
                          Details={data?._visits?.remarks}
                        />
                        <Details
                          detailTitle={"Created On"}
                          Details={data?._visits?.createdOn?.replace("T", " ")}
                        />
                        <Details
                          detailTitle={"Status"}
                          Details={
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  // data?._visits?.status
                                  data?._statusLabel
                                )?.split(",")?.[0],
                                color: getStatusColor(
                                  // data?._visits?.status
                                  data?._statusLabel
                                )?.split(",")?.[1],
                                cursor: "default",
                                padding: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              {data?._statusLabel}
                            </div>
                          }
                        />

                        <Details
                          detailTitle={"Last Updated On"}
                          Details={data?._visits?.lastUpdatedOn?.replace(
                            "T",
                            " "
                          )}
                        />
                      </>
                      <div style={{ width: "100%" }}>
                        <h4
                          style={{ margin: "12px 12px 6px 0px" }}
                          className="headingForm"
                        >
                          {" "}
                          Visit Plan
                        </h4>
                      </div>
                      <div className="" style={{ width: "100%" }}>
                        <table
                          className="tabless tabless-bordered"
                          style={{
                            fontSize: "13px",
                            color: "#4e5e6a",
                            // margin: "0px 18px 0 18px",
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "var(--primary-hover)",
                            }}
                          >
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Location{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                To meet whom?{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Visiting date & time{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Security/Admin{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                In Time{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Out Time{" "}
                              </td>
                              {/* <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Status{" "}
                              </td> */}
                              {/* <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Actions{" "}
                              </td> */}
                            </tr>
                          </thead>
                          {data?._visits?.visitsLocations?.map(
                            (time, index) => (
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {data?.locationname
                                    ? data?.locationname
                                    : "-"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {data?.tomeet[index]}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {time?.visitingTime
                                    ? format(
                                      new Date(time?.visitingTime),
                                      "dd/MM/yyyy hh:mm a"
                                    )
                                    : "-"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {data?._visits?.visitsLocations[0]
                                    ?.securityAdmin
                                    ? data?._visits?.visitsLocations[0]
                                      ?.securityAdmin?.name
                                    : "-"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {data?._visits?.visitsLocations[0]?.inTime
                                    ? format(
                                      new Date(
                                        data?._visits?.visitsLocations[0]?.inTime
                                      ),
                                      "hh:mm a"
                                    )
                                    : "-"}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {data?._visits?.visitsLocations[0]?.outTime
                                    ? format(
                                      new Date(
                                        data?._visits?.visitsLocations[0]?.outTime
                                      ),
                                      "hh:mm a"
                                    )
                                    : "-"}
                                </td>
                                {/* <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: getStatusColor(
                                        data?._visits?.status
                                      )?.split(",")?.[0],
                                      color: getStatusColor(
                                        data?._visits?.status
                                      )?.split(",")?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {data?._visits?.status}
                                  </div>
                                </td> */}
                                {/* <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "start",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  -
                                </td> */}
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                      {data?.meetingvisitdetails[0]?.room?.title && (
                        <>
                          {" "}
                          <div style={{ width: "100%" }}>
                            <h4
                              style={{
                                width: "210px",
                                margin: "12px 12px 6px 0px",
                              }}
                              className="headingForm"
                            >
                              {" "}
                              Meeting Room Details
                            </h4>
                          </div>
                          <Details
                            detailTitle={"Meeting Room"}
                            Details={data?.meetingvisitdetails[0]?.room?.title}
                          />
                          <Details
                            detailTitle={"Meeting Date"}
                            Details={
                              data?.meetingvisitdetails[0]?.date
                                ? format(
                                  new Date(
                                    data?.meetingvisitdetails[0]?.date
                                  ),
                                  "dd/MM/yyyy"
                                )
                                : ""
                            }
                          />
                          <Details
                            detailTitle={"Meeting Start Time"}
                            Details={
                              data?.meetingvisitdetails[0]?.startTime
                                ? format(
                                  new Date(
                                    data?.meetingvisitdetails[0]?.startTime
                                  ),
                                  "hh:mm a"
                                )
                                : ""
                            }
                          />
                          <Details
                            detailTitle={"Meeting End Time"}
                            Details={
                              data?.meetingvisitdetails[0]?.endTime
                                ? format(
                                  new Date(
                                    data?.meetingvisitdetails[0]?.endTime
                                  ),
                                  "hh:mm a"
                                )
                                : ""
                            }
                          />
                        </>
                      )}
                    </div>
                    <div className="headingForm"> Requester's Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Requester Name"}
                        Details={`${data?._visits?.employee?.name} ${data?._visits?.employee?.lastName}`}
                      />
                      <Details
                        detailTitle={"Requester's Manager"}
                        Details={data?._visits?.employee?.rO_Name}
                      />
                      <Details
                        detailTitle={"Requester Designation"}
                        Details={data?._visits?.employee?.designation}
                      />
                      <Details
                        detailTitle={"Requester Department"}
                        Details={data?._visits?.employee?.department}
                      />
                      <Details
                        detailTitle={"Requester Email"}
                        Details={data?._visits?.employee?.email}
                      />
                      <Details
                        detailTitle={"Contact Number"}
                        Details={data?._visits?.employee?.personalPhoneNumber}
                      />

                      <Details
                        detailTitle={"Location"}
                        Details={data?._visits?.employee?.locationName}
                      />
                    </div>

                    {data?.action?.length ? (
                      <div
                        className="createFootDetails"
                        style={{ background: "white !important" }}
                      >
                        {data?.action?.map((item) => (
                          <div>
                            <div
                              className="button secondaryButton"
                              onClick={() => {
                                item.type !== "Approve" &&
                                  item.type !== "Release" &&
                                  item.type !== "Cancel"
                                  ? togglePopup(
                                    item?.id,
                                    item?.type,
                                    item?.color,
                                    data?._visits?.id
                                  )
                                  : PostApRejHol(item?.type, item?.id);
                              }}
                              style={{
                                background: item?.color,
                                color: "white",
                              }}
                            >
                              {item?.type}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {isOpen &&
                      (typeAction === "Mark Entry" ? (
                        <Popup
                          popupHeading={"Mark Entry"}
                          content={
                            <MarkEntryComp
                              selId={selId}
                              listid={listided}
                              //   initialvalue={initialvalue}
                              //   detailsform={detailsformForMarkEntry}
                              //   formValues={formValues}
                              //   handleClose={togglePopup}
                              //   data={getFormDetailsForMarkEntry}
                              //   getManageVisitorsData={getManageVisitorsData}
                              loading={loading}
                            />
                          }
                          handleClose={togglePopup}
                        />
                      ) : (
                        confirmAlert({
                          title: "Mark Exit",
                          message: `Are you sure you want to mark exit?`,
                          buttons: [
                            {
                              label: "Yes, confirm ",
                              onClick: () => togglePopup("_", "Mark Exit"),
                            },
                            {
                              label: "No, cancel ",
                              onClick: () => navigate(-1),
                            },
                          ],
                        })
                      ))} */}
                    {isOpen && typeAction === "Mark Entry" && (
                      <Popup
                        popupHeading={"Mark Entry"}
                        secondClassName={"webcampopup"}
                        content={
                          <MarkEntryComp
                            selId={selId}
                            listid={listided}
                            initialvalue={initialvalue}
                            isDetails={true}
                            detailsform={data}
                            getDetails={getDetails}
                            //   formValues={formValues}
                            handleClose={togglePopup}
                            //   data={getFormDetailsForMarkEntry}
                            //   getManageVisitorsData={getManageVisitorsData}
                            loading={loading}
                          />
                        }
                        handleClose={togglePopup}
                      />
                    )}
                    {isOpen && typeAction === "Mark Exit" && (
                      <Popup
                        secondClassName={"markExitHeight"}
                        popupHeading={"Mark Exit"}
                        content={
                          <div className="popupBodyContainer">
                            {loading ? (
                              <Loader />
                            ) : (
                              <>
                                <div className="popApproveLabel">
                                  <>
                                    <label>
                                      Are you sure you want to mark exit?
                                    </label>
                                  </>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <button
                                    className="button"
                                    onClick={() => {
                                      PostApRejHol("Mark Exit", selId);
                                    }}
                                  >
                                    Confirm
                                  </button>
                                  <button
                                    onClick={() => togglePopup()}
                                    className="button"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        }
                        handleClose={togglePopup}
                      />
                    )}
                  </div>
                ) : (
                  <div className="noDatastyle">
                    <Loader />
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimelineHospitality
                        id={getIdFromDynamicUrl(currentUrl)}
                        // module={"Company Car Controller"}
                        module={"Visitors"}
                        requestNumber={data?._visits?.requestNumber}
                      />
                      {/* <Comments id={getIdFromDynamicUrl(currentUrl)} module={data?.data?.module} requestNumber={
                          data?.data?.manpowerIndent?.requestNumber
                        } /> */}
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications
                      id={getIdFromDynamicUrl(currentUrl)}
                      module={"Visitors"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageVisitors };
