// import React from "react";
// import { RewardsTab } from "./RewardsTab";
// import { useEffect } from "react";

// const Rewards = () => {
//   useEffect(() => {
//     document.title = `PeopleSol -  Rewards`;
//     return () => {
//       document.title = "PeopleSol";
//     };
//   }, []);
//   return (
//     <div className="moduleMainContainer module-reward">
//       <RewardsTab title="Rewards" />
//     </div>
//   );
// };

// export { Rewards };

import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { RewardsTab } from "./RewardsTab";
import { LearningVideos } from "../../LD/Components/LearningVideos";
import { Details } from "@mui/icons-material";


const Rewards = ({ featureMenu }) => {
  const [activeSubmodule, setActiveSubmodule] = useState("rewards");
  const [activeFeature, setActiveFeature] = useState("quantitative");


  useEffect(() => {
    document.title = `PeopleSol -  Rewards`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    navigate(`rewards/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("pmsmaster/rewards")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex(
        (x) => x === "rewards"
      );
      console.log("object featureMenu", featureMenu);
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            if (featureMenu)
              setTabIndex(
                featureMenu
                  ?.slice()
                  ?.filter((x) => x.isFeatureActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                  )[0]
                  .featureName?.replaceAll(" ", "")
                  ?.replaceAll("&", "")
                  ?.toLowerCase()
              );
            navigate(
              `rewards/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `rewards/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
  }, [location.pathname, featureMenu]);

  return (
    <div className="moduleMainContainer">
      {
        // activeSubmodule === "rewards" && (
        <>
          {/* {activeFeature === "quantitative" && (
            <>
              {location.pathname.includes("viewstatus") 
              && <Details />} */}
   
          {/* {location.pathname.includes("certificate") &&
                certificateActive && <CertificateViewer />} */}

          {/* {location.pathname.includes("certicomp") && certicompActive && (
                <CertificateTempViewer />
              )} */}
          {/* </> */}
          {/* // )} */}
          {/* {activeFeature === "teamlearning"} */}
        </>
        // )
      }
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${tabIndex ===
                          item?.featureName
                            ?.replaceAll(" ", "")
                            ?.replaceAll("&", "")
                            ?.toLowerCase() && "tabActive"
                          }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "quantitative" && (
        <div className="moduleMainContainer module-reward">
          <RewardsTab title="Rewards" />
        </div>
      )}
      {tabIndex === "qualitative" && (
        <>
          <div className="moduleMainContainer module-reward">
            {/* <RewardsQualitativeTab title="Rewards" /> */}
          </div>
        </>
      )}
    </div>
  );
};

export { Rewards };

