import { getRequest } from "../../../../../Services/axios";
import dateFormat from "dateformat";

export const removePostLike = async (postId) => {
  try {
    const result = await getRequest(`SocialPost/RemoveLike?PostId=${postId}`);
  } catch (error) {
  } finally {
  }
};
export const removePostDisLike = async (postId) => {
  try {
    const result = await getRequest(
      `SocialPost/RemoveDisLike?PostId=${postId}`
    );
  } catch (error) {
  } finally {
  }
};
export const likePost = async (postId, likedType) => {
  try {
    const result = await getRequest(
      `SocialPost/Like?PostId=${postId}&LikedType=${likedType}`
    );
  } catch (error) {
  } finally {
  }
};

export const disLikePost = async (postId, disLikedType) => {
  try {
    const result = await getRequest(
      `SocialPost/DisLike?PostId=${postId}&DisLikedType=${disLikedType}`
    );
  } catch (error) {
  } finally {
  }
};

export const formatTimeFromDate = (mydate) => {
  const date = new Date(mydate);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  if (formattedHours < 1 && formattedMinutes < 1 && formattedSeconds < 60) {
    return "Just now";
  } else if (formattedHours < 1 && formattedMinutes < 60) {
    return `${formattedMinutes} mins`;
  } else if (formattedHours < 24 && formattedHours > 1) {
    return `${formattedHours} hrs`;
  } else if (formattedHours < 48 && formattedHours > 24) {
    return "Yesterday";
  } else {
    return dateFormat(mydate, "hh:MM TT | dd mmm yyyy");
  }
};
