import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";

import { isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";

export const CompanyFoodAssign = ({
  data,
  id,
  type,
  color,
  handleClose,
  listId,
}) => {
  const validationSchema = Yup.object({
    Assignee: Yup.string().required("Please Select Assignee"),
    Description: Yup.string()
      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
  });
  const dispatch = useDispatch();
  const [detailsData, setDetailsData] = useState(false);
  const [hotels, setHotels] = useState([
    {
      label: "no option",
      value: "no option",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(true);

  // console.log("id,", listId);
  // const getDetails = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await getRequest(
  //       `HospitalityRequests/Details?ID=${listId.id}`
  //     );
  //     // console.log("result details kdsfhdifuh", result);
  //     setDetailsData(result.data.data);
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: error?.response?.data?.title ?? "Error in fetching Data",
  //         severity: "error",
  //       })
  //     );
  //   } finally {
  //     dispatch(isLoading(false));
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    document.title = `PeopleSol - Company Food Assign`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getHousekeepingTeamEmployee = async () => {
    try {
      const result = await getRequest(
        "HousekeepingTicket/GetHousekeepingTeamEmployee"
      );
      //  // console.log("resultGetHotels", result);
      let listData = [];
      result?.data?.map((item) => {
        listData.push({ label: item.name, value: item.memberId });
      });

      setHotels(listData);
    } catch {
    } finally {
    }
  };
  useEffect(() => {
    getHousekeepingTeamEmployee();
    // getDetails();
  }, []);

  const onSubmit = async (values) => {
    data("Assign", id, "Remark", id, values.Assignee, values.Description);
    // approveapi(listId.id, values.updatedHotelId);
    handleClose();
    setFormValues(values);
  };

  // formValues.preferredHotel = detailsData?.hotel?.name;
  // console.log("values hospitality approve ", formValues);
  return (
    <Formik
      initialValues={{
        Assignee: "",
        Description: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <LabelCustom labelName={"Assignee"} mandatory={true} />
        <SelectForm name="Assignee" options={hotels} />

        <LabelCustom labelName={"Description"} mandatory={true} />
        <TextAreaFormik
          maxLength={500}
          name="Description"
          placeholder={"Enter Description"}
        />

        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ background: color, color: "white" }}
          >
            Assign
          </button>
        </div>
      </Form>
    </Formik>
  );
};
