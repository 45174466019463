import React from 'react'
import { Projects } from '../../HRMS/Components/ProjectTaskComponents/Projects';
import { useEffect } from 'react';

const ServiceProject = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Project Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      <Projects styles={newHeight}/>
        
    </div>
  )
}

export {ServiceProject};
