import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { showToast } from "../../../../Features";
import { useEffect } from "react";
function ExperienceDetails(props) {
  const { dispatch } = props;
  useEffect(() => {
    document.title = `PeopleSol - Experience Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <div>
        <FieldArray name="employeeExperiences">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { employeeExperiences } = values;

            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"S. No"} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Job Role"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Job Position"} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Company"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Duration (in Years)"} />
                      </StyledTableCell>
                      {/* <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Currently Work Here?"} />
                      </StyledTableCell> */}

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {employeeExperiences?.map((employeeExperiencess, index) => (
                    <TableBody key={index}>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          
                          maxLength={150}
                          type="text"
                          name={`employeeExperiences[${index}].occupation`}
                          placeholder={"Enter Role"}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputCustomFormik
                          
                          maxLength={500}
                          name={`employeeExperiences[${index}].summary`} //  Not present in the  list
                          placeholder={"Enter Position"}
                        />
                      </StyledTableCell>
                      {/* 3rd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          
                          maxLength={150}
                          type="text"
                          name={`employeeExperiences[${index}].companyName`}
                          placeholder={"Enter Company"}
                        />
                      </StyledTableCell>

                      {/* 4th column */}

                      {/* 5th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          minLength={1}
                          maxLength={2}
                          type="number"
                           step="0.01"
                          // eslint-disable-next-line no-script-url
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          name={`employeeExperiences[${index}].duration`} //  Not present in the  list
                          placeholder={"Enter Duration"}
                        />
                      </StyledTableCell>

                      {/* 6th column */}
                      {/* <StyledTableCell>
                        <SelectForm
                          name={`employeeExperiences[${index}].currentlyWorkHere`} //  Not present in the  list
                          options={yesNoOptions}
                        />
                      </StyledTableCell> */}

                      {/* 7th column */}
                      <StyledTableCell sx={{ textAlign: "center" }}>
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            employeeExperiences.length > 1
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({
                              occupation: "",
                              companyName: "",
                              summary: "",
                              duration: "",
                              currentlyWorkHere: "",
                            })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </>
  );
}

export { ExperienceDetails };
