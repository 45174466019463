import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import React from "react";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { showToast } from "../../../../Features";
import { useEffect } from "react";
function PersonalDetails({
  genderOptions,
  maritalOptions,
  yesNoOptions,
  relationshipOptions,
  formValues,
  dispatch,
}) {
  useEffect(() => {
    document.title = `PeopleSol - Personal Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const addYears = (date, years) => {
    date.setFullYear(date.getFullYear() + years);

    return date;
  };
  return (
    <>
      <div className="headingForm">Personal Details</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Personal Email Id"} />
          <InputCustomFormik
            borderBlack={true}

            name="personalEmail"
            type={"email"}
            placeholder={"Enter Personal Email"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Personal Mobile Number"} mandatory={true} />
          <InputCustomFormik
            borderBlack={true}

            maxLength={10}
            name="personalPhoneNumber"
            step=".001"
            placeholder={"Enter Personal Mobile Number"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Date Of Birth"} />
          <DatePickerFormiks
            borderBlack={true}

            name="dob"
            placeholder={"Select Date Of Birth"}
            maxDate={addYears(new Date(), -18)}
          />
        </div>
        <div>
          <LabelCustom labelName={"Gender"} />
          <SelectForm
            borderBlack={true}
            name="gender"
            options={genderOptions}
            placeholder={"Select Gender"}
          />
        </div>

        {/* <div>
          <LabelCustom labelName={"Birth Country"} />
          <InputCustomFormik
            borderBlack={true}

          
          maxLength={150}
            name="birthCountry"
            type={"text"}
            placeholder={"Enter Birth Country"}
          />
        </div> */}
        <div>
          <LabelCustom labelName={"Religion"} />
          <InputCustomFormik
            borderBlack={true}

            maxLength={150}
            name="religion"
            type={"text"}
            placeholder={"Enter Religion"}
          />
        </div>
        {/* <div>
          <LabelCustom labelName={"State"} />
          <SelectForm
            borderBlack={true}
           name="state" options={stateOptions} />
        </div> */}
        <div>
          <LabelCustom labelName={"Nationality"} />
          <InputCustomFormik
            borderBlack={true}

            maxLength={150}
            name="nationality"
            type={"text"}
            placeholder={"Enter Nationality"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Language"} />
          <InputCustomFormik
            borderBlack={true}

            maxLength={150}
            name="language"
            type={"text"}
            placeholder={"Enter Language"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Marital Status"} />
          <SelectForm
            borderBlack={true}
            name="martialStatus"
            options={maritalOptions}
            placeholder={" Select  "}
          />
        </div>
        {formValues?.martialStatus === "Married" && (
          <div>
            <LabelCustom labelName={"Wedding Date"} />
            <DatePickerFormiks
              borderBlack={true}

              name="dom"
              placeholder={"Select Wedding Date"}
              maxDate={new Date()}
            />
          </div>
        )}
        {/* <div>
          <LabelCustom labelName={"Highest Qualification"} />
          <InputCustomFormik
            borderBlack={true}

          
          maxLength={150}
            name="highestQualification"
            type={"text"}
            placeholder={"Enter Highest Qualification"}
          />
        </div> */}

        <div>
          <LabelCustom labelName={"Are you physically challenged ?"} />
          <SelectForm placeholder={"Select yes or no"} name="ruPhysicallyChallenged" options={
            [
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]
          } />
        </div>
        <div>
          <LabelCustom labelName={"Overall Experience ( in years )"} />
          <InputCustomFormik
            borderBlack={true}

            maxLength={2}
            name="currentExperience"
            step=".001"
            placeholder={"Enter Overall Experience (e.g. 1.5)"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Current Annual Salary ( in Lakhs )"} />
          <InputCustomFormik
            borderBlack={true}

            minLength={1}
            maxLength={5}
            name="cuurentSalary"
            step=".001"
            placeholder={"Enter Current Annual Salary (e.g. 12)"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Group Health Insurance"} />
          <SelectForm
            borderBlack={true}
            name={"groupHealthInsurance"}
            options={yesNoOptions}
            placeholder={" Select Group Health Insurance "}
          />
        </div>

        {/* <div>
          <LabelCustom labelName={"Additional Information"} />
          <TextAreaFormik
          
          maxLength={500}
            name={"additionalInformation"}
            placeholder={" Enter Additional Information "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Skill Set"} />
          <TextAreaFormik name={"skillSet"} placeholder={" Enter Skill Set "}
          
          maxLength={500}
          />
        </div> */}
        <div>
          <LabelCustom labelName={"Profile Photo"} />
          <FileUploadFormik name="upload" />
          <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
            Size: 1 MB
          </span>
          <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
            Format: JPEG, PNG
          </span>
        </div>
        <div>
          <LabelCustom labelName={"Offer Letter"} />
          <FileUploadFormik name="offerletterupload" />
          <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
            Size: 2 MB
          </span>
          <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
            Format: PDF, Doc
          </span>
        </div>
      </div>
      {formValues?.martialStatus === "Married" && (
        <>
          <div className="headingForm">Spouse Details</div>
          <div className="formcustom threeColumn">
            <div>
              <LabelCustom labelName={"Spouse Name"} />
              <InputCustomFormik
                borderBlack={true}

                maxLength={150}
                name="spouseName"
                type={"text"}
                placeholder={"Enter Spouse Name"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Spouse Mobile Number"} />
              <InputCustomFormik
                borderBlack={true}

                maxLength={10}
                name="spouseMobileNumber"
                step=".001"
                placeholder={"Enter Spouse Mobile Number"}
              />
            </div>
            {/* <div>
              <LabelCustom labelName={"Spouse Working?"} />
              <SelectForm
            borderBlack={true}
                name={"spouseWorking"}
                options={yesNoOptions}
                placeholder={" Select options for Spouse Working? "}
              />
            </div>
            <div>
              <LabelCustom labelName={"Occupation"} />
              <InputCustomFormik
            borderBlack={true}

                
                maxLength={150}
                name="occupation"
                type={"text"}
                placeholder={"Enter Occupation"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Company Name"} />
              <InputCustomFormik
            borderBlack={true}

                
                maxLength={150}
                name="companyName"
                type={"text"}
                placeholder={"Enter Company Name"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Office Number"} />
              <InputCustomFormik
            borderBlack={true}

                
                maxLength={10}
                name="officePhoneNumber"
                step=".001"
                placeholder={"Enter Office Number"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Office Address"} />
              <TextAreaFormik
                maxLength={500}
                name={"officeAddress"}
                placeholder={" Enter Office Address "}
              />
            </div> */}
            <div>
              <LabelCustom labelName={"No. Of Children "} />
              <InputCustomFormik
                borderBlack={true}

                minLength={1}
                maxLength={2}
                name={"numberofChildren"}
                type="number"
                step="0.01"
                // eslint-disable-next-line no-script-url
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                placeholder={"Enter number of children"}
              />
            </div>
          </div>
        </>
      )}
      <div className="headingForm">Emergency Contact Details</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Person Name"} />
          <InputCustomFormik
            borderBlack={true}

            maxLength={150}
            name="emergencyContactName"
            type={"text"}
            placeholder={"Enter Name"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Relationship"} />
          <SelectForm
            borderBlack={true}
            options={relationshipOptions}
            name="relationShip"
            placeholder="Select Relationship"
          />
        </div>

        <div>
          <LabelCustom labelName={"Emergency Mobile Number"} />
          <InputCustomFormik
            borderBlack={true}

            maxLength={10}
            name="emergencyContactNumber"
            step=".001"
            placeholder={"Enter Mobile Number"}
          />
        </div>
        {/* <div>
          <LabelCustom labelName={"House Phone Number"} />
          <InputCustomFormik
            borderBlack={true}

            
            maxLength={10}
            name="houseNumber"
            step=".001"
            placeholder={"Enter House Phone Number"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Office Number"} />
          <InputCustomFormik
            borderBlack={true}

            
            maxLength={10}
            name="contactPersonOfficePhonenumber"
            step=".001"
            placeholder={"Enter Office Number"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Address"} />
          <TextAreaFormik
            
            maxLength={500}
            name={"contactPersonAddress"}
            placeholder={" Enter Office Address "}
          />
        </div> */}
      </div>

      <div className="headingForm">Dependent Details</div>

      <div className="repeatedSectionContainer">
        <FieldArray name="dependentList">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { dependentList } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"S. No"} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Name"} />
                      </StyledTableCell>

                      {/* <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"House Address"} />
                      </StyledTableCell> */}
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Date Of Birth"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Relationship"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Gender"} />
                      </StyledTableCell>
                      {/* <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Marital Status"} />
                      </StyledTableCell> */}
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {dependentList?.map((dependentLists, index) => (
                    <TableBody key={index}>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      {/* 2nd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          borderBlack={true}

                          maxLength={150}
                          type="text"
                          name={`dependentList[${index}].name`}
                          placeholder={"Enter dependent name"}
                        />
                      </StyledTableCell>
                      {/* 3rd column */}
                      {/* <StyledTableCell>
                        <TextAreaFormik
                          
                          maxLength={500}
                          name={`dependentList[${index}].permanentAddress`}
                          placeholder={"Enter House Address"}
                        />
                      </StyledTableCell> */}
                      {/* 4th column */}
                      <StyledTableCell>
                        <DatePickerFormiks
                          borderBlack={true}

                          maxDate={new Date()}
                          name={`dependentList[${index}].dob`}
                        />
                      </StyledTableCell>
                      {/* 5th column */}
                      <StyledTableCell>
                        <SelectForm
                          borderBlack={true}
                          menuPlacement="top"
                          options={relationshipOptions}
                          name={`dependentList[${index}].relationship`}
                          dropdownPosition="top"
                        />
                      </StyledTableCell>
                      {/* 6th column */}
                      <StyledTableCell>
                        <SelectForm
                          borderBlack={true}
                          menuPlacement="top"
                          name={`dependentList[${index}].gender`}
                          options={genderOptions}
                        />
                      </StyledTableCell>
                      {/* 7th column */}
                      {/* <StyledTableCell>
                        <SelectForm
            borderBlack={true}
                          name={`dependentList[${index}].martialStatus`}
                          options={maritalOptions}
                        />
                      </StyledTableCell> */}
                      {/* 8th column */}
                      <StyledTableCell sx={{ textAlign: "center" }}>
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            dependentList.length > 1
                              ? remove(index)
                              : dispatch(
                                showToast({
                                  text: "Atleast One Item is Required ",
                                  severity: "error",
                                })
                              )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({
                              name: "",
                              permanentAddress: "",
                              dob: "",
                              relationship: "",
                              gender: "",
                              martialStatus: "",
                            })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </>
  );
}

export { PersonalDetails };
