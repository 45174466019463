/* eslint-disable no-undef */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { DetailsStep } from "../../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../Utilities/getStatusColor";

const DetailPageSurrenderAsset = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [employee, setOptionEmployee] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const returnPage = () => {
     navigate(-1);
  };
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);

  const getDetails = async () => {
    try {
      setLoading(true);
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `ItAssets/PendingDetails?ID=${selectedId}`
      );
      setData(result?.data?.data);
      
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Asset Surrender Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);


  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Surrender Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            {/* <div className="moduleMasterDetailsBox"> */}
            <DetailsStep
              detailTitle={"Reference Number "}
              Details={data?._assetSurrenderRequests?.requestNumber}
            />
                    <DetailsStep
            detailTitle={"TicketNumber"}
            Details={data?._assetSurrenderRequests?.ticket?.ticketNumber}
          />
            <DetailsStep
              detailTitle={"Asset ID"}
              Details={data?._assetSurrenderRequests?.assetId}
            />
            <DetailsStep
              detailTitle={"Employee Name"}
              Details={data?._assetSurrenderRequests?.employee?.name}
            />
            <DetailsStep
              detailTitle={"Surrender Condition"}
              Details={data?._assetSurrenderRequests?.surrenderCondition}
            />
            <DetailsStep
              detailTitle={"Surrender To"}
              Details={data?._assetSurrenderRequests?.surrenderedToEmployeeId}
            />
            <DetailsStep
              detailTitle={"Status"}
              Details={
                <div
                  style={{
                    backgroundColor: getStatusColor(
                      data?._assetSurrenderRequests?.status
                    )?.split(",")?.[0],
                    color: getStatusColor(
                      data?._assetSurrenderRequests?.status
                    )?.split(",")?.[1],
                    cursor: "default",
                    padding: "6px",
                    borderRadius: "6px",
                    textAlign: "center",
                    width: "50%"
                  }}
                >
                  {data?._assetSurrenderRequests?.status}
                </div>
              }
            />
            <DetailsStep
              detailTitle={"Created On"}
              Details={data?._assetSurrenderRequests?.createdOn?.replace("T", " ")}
            />
            <DetailsStep
              detailTitle={"Last Updated On"}
              Details={data?._assetSurrenderRequests?.lastUpdatedOn?.replace("T", " ")}
            />
            {/* </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailPageSurrenderAsset };
