import React from 'react';
import { ChangeIt } from './ChangeComponent/ChangeIt';
import { useEffect } from 'react';

const ChangeTab = () => {
    useEffect(() => {
        document.title = `PeopleSol - Change `;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
                    <ChangeIt title="Change"/>

        </div>
    )
}

export { ChangeTab }