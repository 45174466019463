import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const opt = [{ label: "select", value: null }];

export const CreateDepartmentComp = ({ data, editID }) => {
  const [pDepartmentList, setPDepartmentList] = useState([]);
  const [hODList, setHODList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [businessPersonList, setBusinessPersonList] = useState([]);
  const [sPOCList, setSPOCList] = useState([]);
  const dispatch = useDispatch();
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };
  // const getEmployeeData = async () => {
  //   try {

  //       const result = await getRequest('Employees/GetEmployeesDropDown');
  //       let listData = [];
  //       result.data.map((item) => {
  //         return  listData.push({ label: item.name, value: item.id })
  //       })
  //       setHODList(listData);
  //       setDirectorList(listData);
  //       setBusinessPersonList(listData);
  //       setSPOCList(listData);
  //   } catch (error) {
  //       dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
  //   } finally {
  //   }
  // }
  const getDepartmentData = async () => {
    try {
      const result = await getRequest("Department/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setPDepartmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Department `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDepartmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    if (editID) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (editID) {
      setPage(0);
      let idArray;
      if (
        data?.hodemployeeId ||
        data?.directorEmployeeId ||
        data?.businessPerson ||
        data?.spoc
      )
        idArray = [
          data?.hodemployeeId,
          data?.directorEmployeeId,
          data?.businessPerson,
          data?.spoc,
        ];
      else idArray = [];

      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Department Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={50}
          name="name"
          type={"text"}
          placeholder={"Enter Department"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Parent Department"} />
        <SelectForm
          name="parentDepartmentId"
          options={pDepartmentList}
          placeholder={"Select Parent Department"}
          values={
            data
              ? pDepartmentList.filter(
                  (options) => options.label === data?.ParentDepartment
                )
              : opt
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"HOD"} />
        <SelectForm
          name="hodemployeeId"
          // options={hODList}
          placeholder={"Select HOD"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div>
        <LabelCustom labelName={"Director"} />
        <SelectForm
          name="directorEmployeeId"
          // options={directorList}
          placeholder={"Select Director"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div>
        <LabelCustom labelName={"HR Business Partner"} />
        <SelectForm
          name="businessPerson"
          // options={businessPersonList}
          placeholder={"Select Business Partner"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div>
        <LabelCustom labelName={"NOC SPOC"} />
        <SelectForm
          name="spoc"
          // options={sPOCList}
          placeholder={"Select NOC SPOC"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div>
        <LabelCustom labelName={"Require No Dues Clearance"} />
        <YesNoFormik name="requireNoDuesClearance" />
      </div>
    </div>
  );
};
