import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { format } from "date-fns";
import { Loader } from "../../../../Components/FormComponent/Loader";
export const ViewGoals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const data = state?.item;
  const [editFormData, seEditFormData] = useState([]);
  const [ro, setRo] = useState([]);

  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Goals/RoReviews?ID=${data.id}`);
      seEditFormData(result?.data?.data.employeeGoals);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - View Goals `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);

  const count = editFormData.filter(
    (item) => item.status === "RM Approval Pending"
  ).length;
  const countA = editFormData.filter(
    (item) => item.status === "RM Approved"
  ).length;
  const countR = editFormData.filter(
    (item) => item.status === "RM Rejected"
  ).length;
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal" style={{ padding: "24px" }}>
          <div
            className="headingContainer"
            style={{ marginLeft: "12px", width: "98%",padding:'0' }}
          >
            <div
              onClick={() => navigate(-1)}
              style={{ display: "flex" }}
              className="returnToDashboard cursor"
            >
              <div>
                <svg
                  width="20"
                  height="8"
                  viewBox="0 0 20 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                    fill="#1296B0"
                  />
                </svg>
              </div>
              <label className="returnLabelHolder">{data?.name}</label>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="gridPend">
              <div
                style={{
                  display: "block",
                }}
              >
                <div className="button primary goalTeam">
                  <span>Pending</span>
                  <span
                    style={{
                      border: "0.75px solid var(--secondary)",
                      borderRadius: "4px",
                      fontSize: "11px",
                      padding: "0 3px",
                    }}
                  >
                    {count}
                  </span>
                </div>
                <div>
                  {editFormData &&
                    editFormData?.map((item, index) => {
                      const apiDateObject = new Date(item?.lastUpdatedOn);
                      const today = new Date();
                      const currentDate = new Date();
                      currentDate.setDate(today.getDate() - 1);
                      const differenceInMilliseconds =
                        apiDateObject - currentDate;

                      // Step 3: Convert milliseconds to days
                      const differenceInDays = Math.floor(
                        differenceInMilliseconds / (1000 * 60 * 60 * 24)
                      );
                      if (item.status === "RM Approval Pending") {
                        return (
                          <div
                            className="internalJobCard"
                            key={item.id}
                            onClick={(e) =>
                              navigate(
                                `/pms/goals/teamgoals/create/${item?.goalId}`,
                                {
                                  state: { item },
                                }
                              )
                            }
                          >
                            <div className="cardContainer cursor">
                              <div className="headingContainer">
                                <h3
                                  style={{
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.employee?.nameWithPersonNumber}
                                </h3>
                              </div>
                              <div
                                className="jobDetailContainerHolder"
                                style={{ padding: "7px 0px 23px 11px" }}
                              >
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    Submitted Date
                                  </label>
                                  <label>
                                    {" "}
                                    {item?.createdOn &&
                                      format(
                                        new Date(item?.createdOn),
                                        "dd/MM/yyyy "
                                      )}
                                  </label>
                                </div>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    Pending Days
                                  </label>
                                  <label>{differenceInDays}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
              <div>
                <div
                  className="button primary goalTeam"
                  style={{
                    background: "#E7F8EC",
                    color: "rgba(13, 181, 60, 1)",
                  }}
                >
                  <span>Approved</span>
                  <span
                    style={{
                      border: "0.75px solid green",
                      borderRadius: "4px",
                      fontSize: "11px",
                      padding: "0 3px",
                    }}
                  >
                    {countA}
                  </span>
                </div>

                {editFormData &&
                  editFormData?.map((item, index) => {
                    if (item.status === "RM Approved") {
                      return (
                        <div
                          className="internalJobCard"
                          key={item.id}
                          onClick={(e) =>
                            navigate(
                              `/pms/goals/teamgoals/create/${item?.goalId}`,
                              {
                                state: { item },
                              }
                            )
                          }
                        >
                          <div className="cardContainer cursor">
                            <div className="headingContainer">
                              <h3
                                style={{
                                  fontSize: "16px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.employee?.nameWithPersonNumber}
                              </h3>
                              {/* <span className="internalJobDateTime" style={{color:'var(--secondary)'}}>
                        Pending
                      </span> */}
                            </div>
                            <div
                              className="jobDetailContainerHolder"
                              style={{ padding: "7px 0px 23px 11px" }}
                            >
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Submitted Date
                                </label>
                                <label>
                                  {" "}
                                  {item?.createdOn &&
                                    format(
                                      new Date(item?.createdOn),
                                      "dd/MM/yyyy "
                                    )}
                                </label>
                              </div>
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Approved Date
                                </label>
                                <label>{item?.lastUpdatedOn &&
                                    format(
                                      new Date(item?.lastUpdatedOn),
                                      "dd/MM/yyyy "
                                    )}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
              <div>
                <div
                  className="button primary goalTeam"
                  style={{ background: "rgba(255, 242, 240, 1)", color: "red" }}
                >
                  <span>Rejected</span>
                  <span
                    style={{
                      border: "0.75px solid  red",
                      boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
                      borderRadius: "4px",
                      fontSize: "11px",
                      padding: "0 3px",
                    }}
                  >
                    {countR}
                  </span>
                </div>
                {editFormData &&
                  editFormData?.map((item, index) => {
                    if (item.status === "RM Rejected") {
                      return (
                        <div
                          className="internalJobCard"
                          key={item.id}
                          onClick={(e) =>
                            navigate(
                              `/pms/goals/teamgoals/create/${item?.goalId}`,
                              {
                                state: { item },
                              }
                            )
                          }
                        >
                          <div className="cardContainer cursor">
                            <div className="headingContainer">
                              <h3
                                style={{
                                  fontSize: "16px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.employee?.nameWithPersonNumber}
                              </h3>
                              {/* <span className="internalJobDateTime" style={{color:'var(--secondary)'}}>
                        Pending
                      </span> */}
                            </div>
                            <div
                              className="jobDetailContainerHolder"
                              style={{ padding: "7px 0px 23px 11px" }}
                            >
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Submitted Date
                                </label>
                                <label>
                                  {" "}
                                  {item?.createdOn &&
                                    format(
                                      new Date(item?.createdOn),
                                      "dd/MM/yyyy "
                                    )}
                                </label>
                              </div>
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Rejected Date
                                </label>
                                <label>{item?.lastUpdatedOn &&
                                    format(
                                      new Date(item?.lastUpdatedOn),
                                      "dd/MM/yyyy "
                                    )}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
