import styled from "styled-components";

const WrappeCrt = styled.div`
  display: block;
  background: rgb(25, 79, 120);
  background: linear-gradient(
    90deg,
    rgba(25, 79, 120, 1) 0%,
    rgba(200, 27, 116, 1) 100%
  );
  padding: 10px;
`;

export default WrappeCrt;
