import { getRequest } from "../Services/axios";
import { setApprovalCount } from "./approvalSlice";
import { setNotiCount } from "./notifiSlice";

export const getBadgeCount = async (dispatch) => {
  try {
    // Make an API request to fetch the badge counts
    const result = await getRequest("Settings/ApprovalsCount");

    // Update the approval count in the Redux store
    dispatch(setApprovalCount(result?.data?.data?.approvals || 0));

    // Update the notification count in the Redux store
    dispatch(setNotiCount(result?.data?.data?.generalNotifications || 0));
  } catch (error) {
  }
};