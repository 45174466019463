import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";
import SocialNewsLinkLineIcon from "../../../../../Assets/SocialNewsLinkLineIcon.svg";
import NewsHeaderIcon from "../../../../../Assets/SocialNewsHeaderIcon.svg";
import SocialNewsTag from "../../../../../Assets/SocialNewsTag.svg";
import HeaderComponent from "./HeaderComponent";
import "./Greetings.css";
import { useSelector } from "react-redux";
import SocialTextLinkIcon from "../../../../../Assets/SocialTextLinkIcon.svg";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostViewsEyeColorIcon from "../../../../../Assets/SocialPostViewsEyeColorIcon.svg";
import SocialPostNotFoundIcon from "../../../../../Assets/SocialPostNotFoundIcon.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import SharePost from "./SharePost";
import dateFormat from "dateformat";
import { socialPostImages } from "../../../../../Utilities/profileAndUploadImages";
import LikeDislike from "./LikeDislike";
import { setNewsData } from "../../../../../Features/SocialSlice";
import {
  getAttachments,
  getContentLength,
  getExtension,
  getFormattedCount,
  imgArray,
} from "../../../../../Utilities/socialAllFunctions";
import ShareDetails from "./ShareDetails";
import ShowViewsList from "./ShowViewsList";
import NewsPostDetails from "./NewsPostDetails";
import ContentSocialDisplay from "./ContentSocialDisplay";
import NewsLikeAndDislikeComponent from "./NewsLikeAndDislikeComponent";

const News = ({ unreadPostCount, setReRefreshProp, reRefresh }) => {
  const [showContent, setShowContent] = useState(false);
  const [viewsPostId, setViewsPostId] = useState(0);
  const [isLinkHovered, setIsLinkHovered] = useState(false);
  const [viewsHovered, setIsViewsHovered] = useState(false);
  const [viewsListActive, setViewsListActive] = useState(false);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [shareDetailsActive, setShareDetailsActive] = useState(false);
  const dispatch = useDispatch();
  const [dataFound, setDataFound] = useState(true);
  const { newsData } = useSelector((state) => state.social);
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && newsData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, newsData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || newsData?.length < postDataCount)
    ) {
      if (!reRefresh) {
        getData();
      }
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset]);

  useEffect(() => {
    setOffset(0);
    return () => dispatch(setNewsData([]));
  }, []);

  const getData = async (freshLoad = false) => {
    setLoading(true);
    try {
      const result = await postRequest(`SocialPost/AllNewsPostList`, {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength:
            postDataCount === -1 || offset + 2 <= postDataCount || freshLoad
              ? 2
              : postDataCount - offset,
          iDisplayStart: freshLoad ? 0 : offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });

      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        dispatch(setNewsData([]));
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });

        setPostDataCount(result?.data?.summary?.allCount);
        setTotalOffset(offset);
        dispatch(setNewsData([...newsData, ...listData]));

        if (freshLoad) {
          document.querySelector(
            ".homeEngageMainCenterContainer"
          ).scrollTop = 0;
          dispatch(setNewsData(listData));
          setOffset(0);
          setTotalOffset(0);
        }

        setCurrentIndex((currentIndex) => currentIndex + 2);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      setReRefreshProp();
    }
  };

  const myFunction = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => { })
      .catch((error) => { });
    dispatch(
      showToast({
        text: "copied to clipboard!",
        severity: "success",
      })
    );
  };

  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    document.title = `PeopleSol - News`;
    return () => {
      document.title = "PeopleSol";
    };
  }, [location.pathname]);
  useEffect(() => {
    if (reRefresh === true) {
      getData(true);
    }
  }, [reRefresh]);
  return (
    <>
      <div className="engageMainContentContainer" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <div className="greetingEngageHeaderContainer">
          <HeaderComponent iconSize={130} icon={NewsHeaderIcon} title={"News"} />
        </div>
        <div className="homeEngageMainCenterContainer">
          <div className="homeEngagePostContainer">
            {newsData.map((d, i) => {
              return (
                <div
                  className="socialAllPostContainer"
                  key={"social-item-" + i}
                >
                  <div className="engagePostContentHolder">
                    <div
                      className={
                        d?.sharedEmployee !== null
                          ? `sharedSocialPostDataListing`
                          : ""
                      }
                    >
                      <div className="postHeaderContainer">
                        <div className="leftPostHeaderContainer">
                          <div className="socialPostTitleAndTimeContainer">
                            <label>
                              {d?.news?.title?.length > 40
                                ? d?.news?.title?.substring(0, 40) + "..."
                                : d?.news?.title}
                            </label>

                            <div className="announcementViewsContainer">
                              <span className="postHrTime">
                                •{" "}
                                {dateFormat(
                                  d?.createdOn,
                                  "hh:MM TT | dd mmm yyyy"
                                )}
                              </span>
                              <article
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setViewsListActive(true);
                                  setViewsPostId(d?.id);
                                }}
                                onMouseOver={() => setIsViewsHovered(d?.id)}
                                onMouseLeave={() => setIsViewsHovered(null)}
                              >
                                <img
                                  src={
                                    viewsHovered === d?.id
                                      ? SocialPostViewsEyeColorIcon
                                      : SocialPostViewsEyeIcon
                                  }
                                  alt=""
                                />
                                <span
                                  style={{ marginLeft: "5px" }}
                                  className={
                                    viewsHovered === d?.id
                                      ? "postColorViews"
                                      : `socialViewsSpanContainer`
                                  }
                                >
                                  {d?.views < 10
                                    ? "0" + d?.views
                                    : getFormattedCount(d?.views)}{" "}
                                  Views
                                </span>
                              </article>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="newsTypeContainer">
                            <span>{d?.news?.category}</span>
                            <article></article>
                            <img src={SocialNewsTag} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="newspostBodyContainer">
                        {d?.attachment?.length > 0 &&
                          imgArray.includes(
                            getExtension(
                              getAttachments(d?.attachment)
                                ? getAttachments(d?.attachment)[0]
                                : ""
                            )
                          ) && (
                            <div
                              className={` ${d?.sharedEmployee !== null
                                ? "postBodyImgContainer"
                                : ""
                                }`}
                              onClick={() => {
                                navigate("details/" + d?.id);
                              }}
                            >
                              <div
                                className={`${d?.sharedEmployee !== null
                                  ? "sharePostBodyImgMainWrapper"
                                  : "newPostBodyImgMainWrapper"
                                  }`}
                                style={{ margin: "0" }}
                              >
                                <div
                                  className="newPostBodyImgContainer"
                                  style={{ marginTop: "20px" }}
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(d?.attachment)[0]
                                    }
                                    alt=""
                                    className="normalImgContainer"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="postBodyTextContainer">
                          <h5
                            style={{
                              marginBottom: "2px",
                              textTransform: "capitalize",
                            }}
                          >
                            {d?.news?.shortDescription}
                          </h5>

                          {d?.content?.split(/\r\n|\r|\n/).length > 4 &&
                            !showContent ? (
                            <ContentSocialDisplay
                              content={getContentLength(d?.content)}
                            />
                          ) : (
                            <ContentSocialDisplay
                              content={d?.content?.trim()}
                            />
                          )}
                          {d?.content?.split(/\r\n|\r|\n/).length > 4 &&
                            !showContent && (
                              <span
                                onClick={() => {
                                  setShowContent(true);
                                }}
                              >
                                ...View More
                              </span>
                            )}
                        </div>
                        {d?.news?.link && (
                          <div className="newsLinkContainer">
                            <div className="newsLinkWrapper">
                              <div className="newsLink">
                                <img src={SocialTextLinkIcon} alt="" />{" "}
                                <a
                                  href={d?.news?.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span>
                                    {" "}
                                    {d?.news?.link?.length > 50
                                      ? d?.news?.link?.substring(0, 50) + "..."
                                      : d?.news?.link}
                                  </span>
                                </a>
                              </div>

                              <div
                                onMouseOver={() => setIsLinkHovered(d?.id)}
                                onMouseLeave={() => setIsLinkHovered(null)}
                                className="copyNewsLinkWrapper"
                              >
                                <span>Copy</span>
                                {isLinkHovered === d?.id && (
                                  <img
                                    onClick={() => {
                                      myFunction(d?.news?.link);

                                    }}
                                    style={{ width: "35px" }}
                                    src={SocialNewsEndLinkIcon}
                                    alt=""
                                  />
                                )}
                                {isLinkHovered !== d?.id && (
                                  <img
                                    style={{ width: "35px" }}
                                    src={SocialNewsLinkLineIcon}
                                    alt=""
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <NewsLikeAndDislikeComponent
                      d={d}
                      setSharePostData={setSharePostData}
                      setSharePostId={setSharePostId}
                      setLikeId={setLikeId}
                      setTotalLikesWatching={setTotalLikesWatching}
                      setDislikeId={setDislikeId}
                      setTotalDisLikesWatching={setTotalDisLikesWatching}
                      setShareDetailsActive={setShareDetailsActive}
                      postData={newsData}
                      setPostData={setNewsData}
                      getData={getData}
                    />
                  </div>
                </div>
              );
            })}
            {!dataFound && newsData?.length <= 0 && (
              <div className="noPostfoundContainer">
                <div className="noPostfoundWrapper">
                  <img src={SocialPostNotFoundIcon} alt="" />
                  <h5>No Post Found</h5>
                  <p>No one has posted something in social</p>
                </div>
              </div>
            )}
          </div>
          {loading && <Loader />}
        </div>
      </div>
      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
      <Routes>
        <Route
          path="/post/share/:postid"
          element={
            <SharePost
              sharePostData={sharePostData}
              sharePostId={sharePostId}
              getData={getData}
            />
          }
        />
      </Routes>
      {shareDetailsActive && (
        <ShareDetails
          setShareDetailsActive={setShareDetailsActive}
          sharePostId={sharePostId}
          setSharePostId={setSharePostId}
        />
      )}
      {viewsListActive && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"News"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
      <Routes>
        <Route path="/details/:postid" element={<NewsPostDetails />} />
      </Routes>
    </>
  );
};
export { News };
