import React, { useEffect } from "react";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";

const opt=[
    {label:"select",value:null}
  ]
 const TeamMemberFormIt = ({data}) => {
  const [option, setOption] = React.useState([]);
  const [optionSer, setOptionSer] = React.useState([]);
  const getOption = async () => {
    try {
      const result = await getRequest(
        "ItsmTeamMappings/GetItsmTeam"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionSer = async () => {
    try {
      const result = await getRequest(
        "ItsmWorkflows/GetITServiceCatalog"
      );
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.categoryName, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - IT Team Member`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getOption();
    getOptionSer();
  }, []);

  return (
    <div  style={{margin:"25px"}}>
      <div>
      <LabelCustom labelName={"Service Type "} mandatory={true} />
        <SelectForm 
           disabled={JSON.stringify(data)==="{}"?false:true}
              values={
              data
                ? optionSer.filter(
                    (options) => options?.value === data?.ticketTypeId
                  )
                : opt
            }
          options={optionSer}
          name={"ticketTypeId"}
          placeholder={" Select ticketTypeId  "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Helpdesk Team"} mandatory={true} />
        <SelectForm 
          
              values={
              data
                ? option.filter((options) => options?.value === data?.teamId)
                : opt
            }
          options={option}
          name={"teamId"}
          placeholder={" Select Helpdesk Team  "}
        />
      </div>
    </div>
  );
};

export  {TeamMemberFormIt}
