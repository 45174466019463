import { async } from 'q';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getRequest } from '../../../../Services/axios';
import { showToast } from '../../../../Features';

export const TaxDeduction = ({Id,PayMonth}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(null);
    const getForm=async()=>{
    try {
        setLoading(true);
        const result = await getRequest(`PreparePayroll/TaxAndDeduction/${Id}/${PayMonth}`);
        if (result) {
          setDetailsform(result?.data?.data);
        }
      } catch (error) {
        dispatch(
          showToast({
            text: error?.response?.data ?? "Error in fetching Data",
            severity: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    }
    useEffect(() => {
        getForm()
    }, [])
  return (
    <div className="preparePayrollTableContainer">
    <div className="tableHeaderContainer" style={{ padding: "0" }}>
      <h5>Tax Details</h5>
    </div>
    <div className="taxTableContainer">
      <div
        className="taxTableRow"
        style={{ backgroundColor: "var(--table-back)" }}
      >
        <div className="taxTableCell">Tax Name</div>
        <div className="taxTableCell">Paid by Employer</div>
        <div className="taxTableCell">Paid by Employee</div>
      </div>
      <div className="taxTableRow">
        <div className="taxTableCell">Income Tax</div>
        <div className="taxTableCell">₹12,343</div>
        <div className="taxTableCell">₹61,656</div>
      </div>
      <div className="taxTableRow">
        <div className="taxTableCell">
          AD Professional Tax (Head Office)
        </div>
        <div className="taxTableCell">₹12,343</div>
        <div className="taxTableCell">₹61,656</div>
      </div>
      <div
        className="taxTableRow"
        style={{
          borderTop: "1px solid var(--grey)",
          borderBottom: "1px solid var(--grey)",
          marginTop: "12px",
        }}
      >
        <div className="taxTableCell" style={{ fontWeight: 500 }}>
          Total
        </div>
        <div className="taxTableCell" style={{ fontWeight: 500 }}>
          ₹24,686
        </div>
        <div className="taxTableCell" style={{ fontWeight: 500 }}>
          ₹123,312
        </div>
      </div>
    </div>
  </div>
  )
}
