import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { YesNoFormik } from "../../../Components/FormComponent/YesNoFormik";
import { useLocation } from "react-router-dom";

const opt = [{ label: "select", value: null }];

export const CreateHotelsComp = ({ data, editID }) => {
  const dispatch = useDispatch();
  const [option, setOption] = React.useState([]);
  const [activ, setActiv] = React.useState(null);

  const [listData, setListData] = React.useState([]);

  const [page, setPage] = React.useState(editID ? 0 : 1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };
  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    // if (editID) {
    //   setPage(0);
    // } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (editID) {
      // setPage(0);
      let idArray;
      if (data?.managerId) idArray = [data?.managerId];
      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);
  useEffect(() => {
    document.title = `PeopleSol - Create Hotels `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          name="name"
          maxLength={150}
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Manager"} mandatory={true} />
        <SelectForm
          placeholder={" Select Manager"}
          name="managerId"
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div>
        <LabelCustom labelName={"Is Enabled?"} />
        <YesNoFormik
          name="isEnabled"
          checkedyes={activ === true}
          checkedNo={activ === false}
          onChangeyes={(e) => setActiv(e.target.value === "Yes" ? true : false)}
          onChangeNo={(e) => setActiv(e.target.value === "Yes" ? true : false)}
        />
      </div>
    </div>
  );
};
