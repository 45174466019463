import React from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaCustom } from "../../../Components/FormComponent/TextAreaCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import Alerticon from "../../../Assets//Alert-icon.png";
import { RadioFormik } from "../../../Components/FormComponent/RadioFormik";
import { CheckboxFormiks } from "../../../Components/FormComponent/CheckboxFormiks";
import { CheckboxFormiksRewards } from "../../../Components/FormComponent/CheckboxFormiksRewards";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";

const CreateRewardQualitative = () => {
  const optionsPMs = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "No" },
  ];

  return (
    <div className="P-40 qualitativeset">
      <div className="formcustom">
        <div>
          <LabelCustom
            labelName={"Reward Title quantitaive"}
            mandatory="true"
          />
          <InputCustomFormik
            maxLength={150}
            name="title"
            type={"text"}
            placeholder={"Enter Title"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Description"} mandatory="true" />
          <TextAreaCustom
            maxLength={500}
            name="Desciption"
            type={"text"}
            placeholder={"Enter Here"}
          />
        </div>
      </div>

      <div className="Reward-alert-change">
        <img src={Alerticon} alt="Alerticon" />
        <p>
          Keep the description brief; it will be the part of the reward
          certificate.(Ex. This certificate is in recognition of the exemplary
          contribution to wards Sales/Service/R&D, etc. )
        </p>
      </div>

      <div className="formcustom pb-30">
        <div>
          <LabelCustom labelName={"Number of Winners"} mandatory="true" />
          <InputCustomFormik
            maxLength={150}
            name="numberwinners"
            type={"text"}
            placeholder={"enter"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Number of Consolation"} mandatory="true" />
          <InputCustomFormik
            maxLength={150}
            name="numberconsolation"
            type={"text"}
            placeholder={"enter"}
          />
        </div>
      </div>
      <div className="formcustom pb-30">
        <div>
          <LabelCustom labelName={"Announcement Date"} mandatory={true} />
          <DatePickerFormiks minDate={new Date()} name="AnnouncementDate" />
        </div>
      </div>

      <div className="pb-30">
        <h5>Certificate Templates</h5> <hr />
        <div className="CheckFormik-Checkbox">
          <CheckboxFormiksRewards name="certificate1" />
          <CheckboxFormiksRewards name="certificate2" />
          <CheckboxFormiksRewards name="certificate3" />
          <CheckboxFormiksRewards name="certificate4" />
          <CheckboxFormiksRewards name="certificate5" />
        </div>
      </div>
      <hr />
      <div className="formcustom pb-30">
        <div className="radioFormikcustomflex">
          <LabelCustom labelName={"Link Incentive"} mandatory="true" />
          <RadioFormik options={optionsPMs} name="linkincentive" />
        </div>
        <div className="radioFormikcustomflex">
          <LabelCustom labelName={"Link With PMS"} mandatory="true" />
          <RadioFormik options={optionsPMs} name="Linkwithpms" />
        </div>
      </div>

      <div className="">
        <LabelCustom labelName={"Share on Social "} mandatory="true" />
        <div className="CheckFormik-Checkbox">
          <CheckboxFormiks name="News" labelName={"News"} />
          <CheckboxFormiks name="Noticeboard" labelName={"Noticeboard"} />
          <CheckboxFormiks name="Social" labelName={"Social"} />
        </div>
      </div>
    </div>
  );
};

export default CreateRewardQualitative;
