import { ErrorMessage, Field } from "formik";
import React from "react";
import { TextError } from "../../../../Components/FormComponent/TextError";
import { CustomAttachFormikWebCam } from "./CustomAttachFormikWebCam";

const FileUploadFormikWebCam = (props) => {
  const { name, setInfos, style, changeButton, formValues } = props;

  return (
    <div className="formikComponentContainer">
      <Field
        {...props}
        // className="custom-select"
        style={{ ...style, minWidth: "220px" }}
        name={name}
        setInfos={setInfos}
        changeButton={changeButton}
        formValues={formValues}
        component={CustomAttachFormikWebCam}
      />
      <ErrorMessage name={name} styles={props.styling} component={TextError} />
    </div>
  );
};

export { FileUploadFormikWebCam };
