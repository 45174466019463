/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { ServiceTypeForm } from "./Form/ServiceTypeForm";
import { serviceTypeSchema } from "./ServiceTypeSchemas";
import { useEffect } from "react";

const CreateServiceType = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`HRHelpdeskServiceTypes/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);
  const postFormData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("HRHelpdeskServiceTypes/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("HRHelpdeskServiceTypes/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Service Type`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const initialvalue = {
    name: data ? detail?.name : "",
    notificationFlowId: data ? detail?.notificationFlowId : null,
    requestCategoryId: data ? detail?.requestCategoryId : "",
    categoryId: data ? detail?.categoryId : "",
    teamId: data ? detail?.teamId : "",
    requiremoreinformationforAssetApproval:data?detail?.requiremoreinformationforAssetApproval===true?"Yes":"No":"No",
    tatHigh: data ? detail?.tatHigh : "",
    tatMedium: data ? detail?.tatMedium : "",
    tatLow: data ? detail?.tatLow : "",
  };
  const onSubmit = async (values) => {
    const edit = {
      ...values,
      id: data,
      isActive:true,
      _hrHelpdeskTeamMappings: {},
      requiremoreinformationforAssetApproval:values?.requiremoreinformationforAssetApproval==="Yes"?true:false
    };

    const create = {
      ...values,
      _hrHelpdeskTeamMappings: {},
      requiremoreinformationforAssetApproval:values?.requiremoreinformationforAssetApproval==="Yes"?true:false
    };

    data ? Edit(edit) : postFormData(create);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/hrdesk/serviceType");
  };

  return (
    <FormCustomFormik
      loading={loading}
      ButtonLabel={data ? "Update service Type" : "Create service Type"}
      FormHeading={data ? "Edit service Type" : "Create service Type"}
      validationSchema={serviceTypeSchema}
      onSubmit={onSubmit}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<ServiceTypeForm data={data} />}
    />
  );
};

export { CreateServiceType };
