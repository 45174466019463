import React from 'react'
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { DatePickerFormiks } from '../../../Components/FormComponent/DatePickerFormiks';



function CreateRewardSelectDate() {

  return (
     <div className="P-20">
      <div className="formcustomthree">
        <div>
          <LabelCustom labelName={"Reward start date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="Reward_Startdate" />
        </div>
        <div>
          <LabelCustom labelName={"Reward end date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()} name="Reward_Enddate" />
        </div>
        <div>
          <LabelCustom labelName={"Award announcement date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="Award_announcement_date" />
        </div>
        <div>
          <LabelCustom labelName={"Target Setting start date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="Target_Startdate" />
        </div>
        <div>
          <LabelCustom labelName={"Target Setting end date "} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="Target_Enddate" />
        </div>
        <div>
          <LabelCustom labelName={"Self review start date "} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="SelfReview_Startdate" />
        </div>
        <div>
          <LabelCustom labelName={"Self review end date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="SelfReview_Enddate" />
        </div>
        <div>
          <LabelCustom labelName={"Manager review start date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="ManagerReview_Startdate" />
        </div>
        <div>
          <LabelCustom labelName={"Manager review end date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="ManagerReview_Enddate" />
        </div>
        <div>
          <LabelCustom labelName={"HOD review start date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="HodReview_Startdate" />
        </div>
        <div>
          <LabelCustom labelName={"HOD review end date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="HodReview_Enddate" />
        </div>
        <div>
          <LabelCustom labelName={"HR review start date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="HRReview_Startdate" />
        </div>
        <div>
          <LabelCustom labelName={"HR review end date"} mandatory={true} />
          <DatePickerFormiks  minDate={new Date()}  name="HRReview_Enddate" />
        </div>
      </div>
    </div>
  )
}

export default CreateRewardSelectDate
