import React, { useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { SelectFormikMultiString } from "../../../../Components/FormComponent/SelectFormikMultiString";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
const opt = [{ label: "select", value: null }];
export const CreateCoursesComp = ({ data, formValues }) => {
  const [pDepartmentList, setPDepartmentList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [locationValuesArray, setLocationValuesArray] = useState([]);
  const [departmentValuesArray, setDepartmentValuesArray] = useState([]);
  const [designationValuesArray, setDesignationValuesArray] = useState([]);
  const [employeeValuesArray, setEmployeeValuesArray] = useState([]);
  const dispatch = useDispatch();
  const getParentCategory = async () => {
    try {
      const result = await getRequest("TrainingCategories/GetCategories");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setPDepartmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Cources`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getlocations = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setLocationList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getDesignations = async () => {
    try {
      const result = await getRequest("Employees/GetDesignation");
      let listData = [];
      result.data.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setDesignationList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getDepartments = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setDepartmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getEmployees = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetEmployees");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const TypeList = [
    { label: "Mandatory", value: "Mandatory" },
    { label: "Non-Mandatory", value: "Non-Mandatory" },
  ];
  useEffect(() => {
    getParentCategory();
    getlocations();
    getDesignations();
    getDepartments();
    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (locationList.length >= 0) {
      data?.trainingCourses?.locations?.split(",").map((item) => {
        let tempVal = locationList?.filter(
          (options) => options?.value === Number(item)
        );
        if (tempVal.length > 0) {
          setLocationValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
        return null;
      });
    }
    if (departmentList.length >= 0) {
      data?.trainingCourses?.departments?.split(",").map((item) => {
        let tempVal = departmentList?.filter(
          (options) => options?.value === Number(item)
        );
        if (tempVal.length > 0) {
          setDepartmentValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
        return null;
      });
    }
    if (designationList.length >= 0) {
      data?.trainingCourses?.levels?.split(",").map((item) => {
        let tempVal = designationList?.filter(
          (options) => options?.value === item
        );
        if (tempVal.length > 0) {
          setDesignationValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
        return null;
      });
    }
    if (employeeList.length >= 0) {
      data?.trainingCourses?.employeeIds?.split(",").map((item) => {
        let tempVal = employeeList?.filter(
          (options) => options?.value === item
        );
        if (tempVal.length > 0) {
          setEmployeeValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
        return null;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Course"} mandatory={true} />
        <SelectForm
          placeholder={" Select Course"}
          name="categoryId"
          options={pDepartmentList}
          values={
            data
              ? pDepartmentList.filter(
                  (options) => options.value === data?.categoryId
                )
              : opt
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
          maxLength={50}
          name="title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
      {/* <div>
        <LabelCustom labelName={"Type"} mandatory={true} />
        <SelectForm
          placeholder={" Select Type  "}
          name="type"
          options={TypeList}
          values={
            data
              ? TypeList.filter((options) => options.label === data?.type)
              : opt
          }
        />
      </div> */}
      <div>
        <LabelCustom labelName={"Short Description"} mandatory={true} />
        <InputCustomFormik
          maxLength={300}
          name="shortDescription"
          type={"text"}
          placeholder={"Enter a Short Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Cover Image"} mandatory={true} />
        <FileUploadFormik
          name="upload"
          attachTextLabel={data?.coverImage?.toString()}
        />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Size: 2 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Format: JPEG, PNG
        </span>
      </div>
      <div>
        <LabelCustom labelName={"Chapter Start Date"} />
        <DatePickerFormiks name="startDate" maxDate={formValues?.endDate} />
      </div>
      <div>
        <LabelCustom labelName={"Chapter End Date"} />
        <DatePickerFormiks name="endDate" minDate={formValues?.startDate} />
      </div>

      {/* TO BE DONE FROM DETAILS */}

      <div>
        <LabelCustom labelName={"Location(s)"} />
        <SelectFormikMulti
          // values={detailsNamesMulti?.length > 0 ? employeeArray : []}
          name={"locations"}
          placeholder={"Select Location(s)"}
          options={locationList}
        />
        {/* <SelectFormikMultiString
          name="locations"
          multi
          options={locationList}
          placeholder={"Select Location(s)"}
          values={locationValuesArray}
        /> */}
      </div>
      <div>
        <LabelCustom labelName={"Department(s)"} />
        <SelectFormikMulti
          // values={detailsNamesMulti?.length > 0 ? employeeArray : []}
          name={"departments"}
          placeholder={"Select Department(s)"}
          options={departmentList}
        />
        {/* <SelectFormikMultiString
          placeholder={"Select Department(s)"}
          name="departments"
          multi
          options={departmentList}
          values={departmentValuesArray}
        /> */}
      </div>
      <div>
        <LabelCustom labelName={"Designation(s)"} />
        <SelectFormikMulti
          // values={detailsNamesMulti?.length > 0 ? employeeArray : []}
          name={"levels"}
          placeholder={"Select Designation(s)"}
          options={designationList}
        />
        {/* <SelectFormikMultiString
          name="levels"
          placeholder={"Select Designation(s)"}
          multi
          options={designationList}
          values={designationValuesArray}
        /> */}
      </div>
      <div>
        <LabelCustom labelName={"Employees(s)"} />
        <SelectFormikMulti
          // values={detailsNamesMulti?.length > 0 ? employeeArray : []}
          name={"RecommendedFor"}
          placeholder={"Select Employees(s)"}
          options={employeeList}
        />
        {/* <SelectFormikMultiString
          placeholder={"Select Employees(s)"}
          name="RecommendedFor"
          multi
          options={employeeList}
          values={employeeValuesArray}
        /> */}
      </div>
    </div>
  );
};
