import React, { useState, useEffect } from "react";
import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";

const opt = [{ label: "select", value: null }];

const guestOptions = [
  { label: "Normal", value: "Normal" },
  { label: "Special", value: "Special" },
  { label: "VIP", value: "VIP" },
];
const foodOptions = [
  { label: "Veg", value: "Veg" },
  { label: "Non-Veg", value: "Non-Veg" },
];

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];
export const CreateCompanyFoodComp = ({ data }) => {
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);

  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.name });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Create Company Food`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Date"} mandatory={true} />
          <DatePickerFormiks name={"Date"} minDate={new Date()} />
        </div>
        <div>
          <LabelCustom labelName={"Time"} mandatory={true} />
          <TimeCustomFormik name="Time" placeholder="Select Time" />
        </div>
        <div>
          <LabelCustom labelName={"Guest Type"} mandatory={true} />
          <SelectForm
            values={
              data
                ? guestOptions.filter(
                    (options) => options.label === data?.GuestType
                  )
                : opt
            }
            name="GuestType"
            options={guestOptions}
          />
        </div>
        <div>
          <LabelCustom labelName={"Guest Of"} mandatory={true} />
          <SelectForm
            name="GuestOf"
            options={employeeList}
            // values={
            //   data
            //     ? guestOptions.filter(
            //       (options) => options.label === data?.guestOfWhom
            //     )
            //     : opt
            // }
          />
        </div>

        <div className="gridItemVisitor" style={{ width: "100%" }}>
          <div className="repeatedSectionContainer">
            {/* <label className="repeatedHeading" >Please specify the guest's details</label> */}
            <FieldArray name="Requestlist">
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values, errors } = form;
                const { Requestlist } = values;
                return (
                  <StyledTableCont>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S. No."} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"  Guest's Full Name "}
                              mandatory={true}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={" Food Preference  "}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Remarks"} />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={" Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {Requestlist?.map((Requestlist, index) => (
                        <TableBody key={index}>
                          {/* 1st column */}
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          {/* 2nd column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={150}
                              type="text"
                              name={`Requestlist[${index}].GuestNames`}
                              placeholder={"Please enter the Guest Name"}
                            />
                          </StyledTableCell>
                          {/* 6th column */}
                          <StyledTableCell>
                            <SelectForm
                              values={
                                data
                                  ? foodOptions.filter(
                                      (options) =>
                                        options.label === data?.FoodPreferences
                                    )
                                  : opt
                              }
                              name={`Requestlist[${index}].FoodPreferences`}
                              options={foodOptions}
                            />
                          </StyledTableCell>
                          {/* 7th column */}
                          <StyledTableCell>
                            <TextAreaFormik
                              maxLength={250}
                              name={`Requestlist[${index}].Remark`}
                              placeholder={"Please Enter Remarks"}
                            />
                          </StyledTableCell>
                          {/* 8th column */}
                          <StyledTableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                Requestlist.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  GuestNames: "",
                                  FoodPreferences: "",
                                  Remark: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  </StyledTableCont>
                );
              }}
            </FieldArray>
          </div>
        </div>
      </div>
    </>
  );
};
