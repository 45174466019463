import React from "react";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Load, Loader } from "../../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { useEffect } from "react";

export const DetailsPageFinalSettlement = ({ data, loading }) => {
  var converter = require("number-to-words"); //
  function toTitleCase(str) {
    return str
      .toLowerCase()
      .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
  }
  useEffect(() => {
    document.title = `PeopleSol - Details Page Final Settlement`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="detailsEReq">
      {loading ? (
        <Loader />
      ) : !data ? (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"Full and Final Settlement is Not Initiated"}</h4>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "15px",
          }}
        >
          <table
            className="tabless tabless-bordered tabl"
            style={{ width: "100%" }}
          >
            <tr>
              <th>Name</th>
              <td>{data?.employee?.name ?? "-"}</td>
              <th>Employee ID</th>
              <td>{data?.employee?.personNumber ?? "-"}</td>
            </tr>
            <tr>
              <th>Role</th>
              <td>{data?.employee?.roleName ?? "-"}</td>
              <th>Designation</th>
              <td>{data?.employee?.designation ?? "-"}</td>
            </tr>
            <tr>
              <th>Department</th>
              <td>{data?.employee?.department ?? "-"}</td>
              <th>Sub Department</th>

              <td>{data?.employee?.subDepartment ?? "-"}</td>
            </tr>
            <tr>
              <th>Location</th>
              <td>{data?.employee?.locationName ?? "-"}</td>
              <th>Official. Mail ID</th>

              <td>{data?.employee?.email ?? "-"}</td>
            </tr>
            <tr>
              <th>Date of Joining</th>
              <td>
                {data?.employee?.doj != null
                  ? format(new Date(data?.employee?.doj), "dd/MM/yyyy ")
                  : "-"}
              </td>
              <th>Resignation Date</th>
              <td>
                {format(new Date(data?.resignations?.createdOn), "dd/MM/yyyy ")}
              </td>
            </tr>
            <tr>
              <th>Relieving date</th>
              <td>
                {format(
                  new Date(data?.resignations?.dateOfRelieving),
                  "dd/MM/yyyy "
                )}
              </td>
              <th></th>
              <td></td>
            </tr>
          </table>

          <div className="headingForm" style={{ margin: " 28px 0px" }}>
            Final Settlement Details
          </div>

          <table
            className="tabless-bordered tabless"
            style={{ overflow: "auto" }}
          >
            <thead style={{ backgroundColor: "var(--primary-hover)" }}>
              <tr>
                <th colspan="2" style={{ textAlign: "center" }}>
                  ATTENDANCE
                </th>
                <th colspan="2" style={{ textAlign: "center" }}>
                  NORMAL&nbsp;EARNINGS
                </th>
                <th colspan="2" style={{ textAlign: "center" }}>
                  EARNINGS
                </th>
                <th colspan="2" style={{ textAlign: "center" }}>
                  DEDUCTION
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DAYS OF WORK</td>
                <td>{data?.request?.dayofWork ?? "-"}</td>

                <td>BASIC</td>
                <td>{data?.request?.normalBasic ?? "-"}</td>
                <td>BASIC</td>
                <td>{data?.request?.earningBasic ?? "-"}</td>
                <td>PF</td>
                <td>{data?.request?.deductionPf ?? "-"}</td>
              </tr>
              <tr>
                <td>WEEKLY OFF</td>
                <td>{data?.request?.weekoff ?? "-"}</td>
                <td>HRA</td>
                <td>{data?.request?.normalHra ?? "-"}</td>
                <td>HRA</td>
                <td>{data?.request?.earningHra ?? "-"}</td>
                <td>ESIC DEDUCTION</td>
                <td>{data?.request?.escideduction ?? "-"}</td>
              </tr>
              <tr>
                <td>Leave</td>
                <td>{data?.request?.leave ?? "-"}</td>
                <td>MEDICAL ALLOWANCE</td>
                <td>{data?.request?.normalMed ?? "-"}</td>
                <td>MEDICAL ALLOWANCE</td>
                <td>{data?.request?.earningMed ?? "-"}</td>
                <td> TRUST</td>
                <td>{data?.request?.azizTrust ?? "-"}</td>
              </tr>
              <tr>
                <td>PUBLIC HOLIDAYS</td>
                <td>{data?.request?.ph ?? "-"}</td>
                <td>SPECIAL ALLOWANCE</td>
                <td>{data?.request?.normalConv ?? "-"}</td>
                <td>SPECIAL ALLOWANCE</td>
                <td>{data?.request?.earningConv ?? "-"}</td>
                <td>NOTICE PAY RECOVERY</td>
                <td>{data?.request?.noticepayRecovery ?? "-"}</td>
              </tr>
              <tr>
                <td>ABSENT</td>
                <td>{data?.request?.absent ?? "-"}</td>
                <td>UNIFORM ALLOWANCE</td>
                <td>{data?.request?.normalUni ?? "-"}</td>
                <td>UNIFORM ALLOWANCE</td>
                <td>{data?.request?.earningUni ?? "-"}</td>
                <td>DEBIT BALANCE</td>
                <td>{data?.request?.debitBalance ?? "-"}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>LEAVE & TRAVEL ALLOWANCE</td>
                <td>{data?.request?.normalLta ?? "-"}</td>
                <td>LEAVE & TRAVEL ALLOWANCE</td>
                <td>{data?.request?.earningLta ?? "-"}</td>
                <td>ARREAR DEDUCTION</td>
                <td>{data?.request?.arrearDeduction ?? "-"}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>TRANSPORT ALLOWNACE</td>
                <td>{data?.request?.normalCar ?? "-"}</td>
                <td>TRANSPORT ALLOWNACE</td>
                <td>{data?.request?.earningCar ?? "-"}</td>
                <td>SHIFTING CHARGES</td>
                <td>{data?.request?.shiftingCharges ?? "-"}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>BOOKS & PERIODICALS</td>
                <td>{data?.request?.normalBooksAndPeriodicals ?? "-"}</td>
                <td>BOOKS & PERIODICALS</td>
                <td>{data?.request?.earningBooksAndPeriodicals ?? "-"}</td>
                <td>JOINING BONUS</td>
                <td>{data?.request?.joiningBonus ?? "-"}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>GIFT VOUCHERS</td>
                <td>{data?.request?.normalGiftVouchers ?? "-"}</td>
                <td>GIFT VOUCHERS</td>
                <td>{data?.request?.earningGiftVouchers ?? "-"}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>MEAL ALLOWANCE</td>
                <td>{data?.request?.normalMealAll ?? "-"}</td>
                <td>MEAL ALLOWANCE</td>
                <td>{data?.request?.earningMealAll ?? "-"}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>GADGET REIMBURSMENT</td>
                <td>{data?.request?.normalGadgetReimb ?? "-"}</td>
                <td>GADGET REIMBURSMENT</td>
                <td>{data?.request?.earningGadgetReimb ?? "-"}</td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>TOTAL</b>
                </td>
                <td>{data?.request?.normalTotal ?? "-"}</td>
                <td>
                  <b>TOTAL EARNING</b>
                </td>
                <td>{data?.request?.totalEarning ?? "-"}</td>
                <td>
                  <b>TOTAL DEDUCTION</b>
                </td>
                <td>{data?.request?.totalDeductionAmount ?? "-"}</td>
              </tr>

              <tr>
                <td style={{ width: "15%" }}>
                  <b>PAYABLE DAYS</b>
                </td>
                <td>{data?.request?.payableDays ?? "-"}</td>
                <td>LEAVE BALANCE</td>
                <td>{data?.request?.leaveBalance ?? "-"}</td>
                <td>
                  <b>AMOUNT OF LEAVE</b>
                </td>
                <td>{data?.request?.amountofLeave ?? "-"}</td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>
                  <b>TOTAL DAYS</b>
                </td>
                <td>{data?.request?.totalDays ?? "-"}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <table
            class="tabless"
            style={{ width: "60%", border: "none !important" }}
          >
            <tr>
              <th class="no_border">TOTAL EARNINGS</th>
              <td>{data?.request?.totalEarning ?? "-"}</td>
              <td></td>
            </tr>
            <tr style={{ border: "none" }}>
              <th>ADDITIONAL PAYMENT</th>
              <td>{data?.request?.additionalPayment ?? "-"}</td>
              <td>{data?.request?.addirionalPaymentRemarks ?? "-"}</td>
            </tr>
            <tr style={{ border: "none" }}>
              <th>CREDIT AMOUNT</th>
              <td>{data?.request?.creditBalance ?? "-"}</td>
              <td>{data?.request?.creditBalanceRemarks ?? "-"}</td>
            </tr>
            <tr style={{ border: "none" }}>
              <th>BONUS</th>
              <td>{data?.request?.bonus ?? "-"}</td>
              <td>{data?.request?.bonusRemarks ?? "-"}</td>
            </tr>
            <tr style={{ border: "none" }}>
              <th>LEAVE</th>
              <td>{data?.request?.leaveAmount ?? "-"}</td>
              <td>{data?.request?.leaveRemarks ?? "-"}</td>
            </tr>
            <tr style={{ border: "none" }}>
              <th>SALARY PAYABLE</th>
              <td>{data?.request?.holdSalary ?? "-"}</td>
              <td>{data?.request?.salaryPayableRemarks ?? "-"}</td>
            </tr>
            <tr style={{ border: "none" }}>
              <th>TOTAL DEDUCTION</th>
              <td>{data?.request?.totalDeductionAmount ?? "-"}</td>
              <td></td>
            </tr>
            <tr style={{ border: "none" }}>
              <th>NET EARNING PAYABLE</th>
              <td>{data?.request?.netEarningPayable ?? "-"}</td>
              <th>
                <span className="NetearningPayableInWords"></span>
              </th>
            </tr>
            <tr style={{ border: "none" }}>
              <th>NET EARNING PAYABLE</th>
              <td>{data?.request?.netEarningPayable ?? "-"}</td>
              <td>
                {data?.request?.netEarningPayable && toTitleCase(converter?.toWords(
                  isNaN(data?.request?.netEarningPayable)
                    ? 0
                    : data?.request?.netEarningPayable
                ))}
              </td>
              <th>
                <span className="NetearningPayableInWords"></span>
              </th>
            </tr>
          </table>
        </div>
      )}
    </div>
  );
};
