export const getStatusColor = (status) => {
  let positive = "#E7F8EC,#0DB53C";
  let negative = "#FDD2D2,#F71D1D";
  let primary = "#D8F0F2,#1296B0";
  let hold = "#FFF4E5,#F7941D";
  let newRequest = "#EAF5FF,#77A2C7";
  let yellowStatus = "#FFB703,#F6EFDD";
  if (status) {
    if (status?.toLowerCase()?.includes("new")) return newRequest;
    if (status?.toLowerCase()?.includes("rm approval pending")) return primary;
    if (status?.toLowerCase()?.includes("submission pending")) return hold;
    if (
      status?.toLowerCase()?.includes("hold") ||
      status?.toLowerCase()?.includes("warning") ||
      status?.toLowerCase()?.includes("pending") ||
      status?.toLowerCase()?.includes("charge added") ||
      status?.toLowerCase()?.includes("charge not added") ||
      status?.toLowerCase()?.includes("edited") ||
      status?.toLowerCase()?.includes("updated") ||
      status?.toLowerCase()?.includes("target not set") ||
      status?.toLowerCase()?.includes("open") ||
      status?.toLowerCase()?.includes("probation") ||
      status?.toLowerCase()?.includes("final settlement initated") ||
      status?.toLowerCase()?.includes("initiated") ||
      status?.toLowerCase()?.includes("prepared") ||
      status?.toLowerCase()?.includes("ready to be released") ||
      status?.toLowerCase()?.includes("maintenance") ||
      status?.toLowerCase()?.includes("onboarding pending") ||
      status?.toLowerCase()?.includes("on hold") ||
      status?.toLowerCase()?.includes("sent for correction")
    )
      return hold;
    if (
      status?.toLowerCase()?.includes("approved") ||
      status?.toLowerCase()?.includes("approve") ||
      status?.toLowerCase()?.includes("submitted") ||
      status?.toLowerCase()?.includes("closed") ||
      status?.toLowerCase()?.includes("completed") ||
      status?.toLowerCase()?.includes("verified") ||
      status?.toLowerCase()?.includes("assigned") ||
      status?.toLowerCase()?.includes("confirmed") ||
      status?.toLowerCase()?.includes("paid") ||
      status?.toLowerCase()?.includes("received") ||
      status?.toLowerCase()?.includes("released") ||
      status?.toLowerCase()?.includes("resolved") ||
      status?.toLowerCase()?.includes("ticket selected") ||
      status?.toLowerCase()?.includes("ticket booked") ||
      status?.toLowerCase()?.includes("accepted") ||
      status?.toLowerCase()?.includes("reviewed") ||
      status?.toLowerCase()?.includes("awarded") ||
      status?.toLowerCase()?.includes("dispatched") ||
      status?.toLowerCase()?.includes("allocated") ||
      status?.toLowerCase()?.includes("arrived") ||
      status?.toLowerCase()?.includes("driver assigned") ||
      status?.toLowerCase()?.includes("granted") ||
      status?.toLowerCase()?.includes("pre-approved") ||
      status?.toLowerCase()?.includes("revoked") ||
      status?.toLowerCase()?.includes("partially awarded") ||
      status?.toLowerCase()?.includes("success") ||
      status?.toLowerCase()?.includes("settled") ||
      status?.toLowerCase()?.includes("candidate onboarding completed") ||
      status?.toLowerCase()?.includes("employee created") ||
      status?.toLowerCase()?.includes("waiting for arrival") ||
      status?.toLowerCase()?.includes("outtime marked") ||
      status?.toLowerCase()?.includes("waiting for exit") ||
      status?.toLowerCase()?.includes("waiting for visitor arrival") ||
      status?.toLowerCase()?.includes("waiting for visitor to exit") ||
      status?.toLowerCase()?.includes("ready for dispatch") ||
      status?.toLowerCase()?.includes("delivered") ||
      status?.toLowerCase()?.includes("deputation ended") ||
      status?.toLowerCase()?.includes("intime marked") ||
      status?.toLowerCase()?.includes("published")
    )
      return positive;
    if (
      status?.toLowerCase()?.includes("rejected") ||
      status?.toLowerCase()?.includes("deactivated") ||
      status?.toLowerCase()?.includes("cancelled") ||
      status?.toLowerCase()?.includes("disabled") ||
      status?.toLowerCase()?.includes("deallocated") ||
      status?.toLowerCase()?.includes("exited") ||
      status?.toLowerCase()?.includes("reopened") ||
      status?.toLowerCase()?.includes("extended") ||
      status?.toLowerCase()?.includes("probation extended") ||
      status?.toLowerCase()?.includes("expired - pending revocation") ||
      status?.toLowerCase()?.includes("link expired") ||
      status?.toLowerCase()?.includes("under probation") ||
      status?.toLowerCase()?.includes("absconded") ||
      status?.toLowerCase()?.includes("terminated") ||
      status?.toLowerCase()?.includes("danger") ||
      status?.toLowerCase()?.includes("resigned") ||
      status?.toLowerCase()?.includes("expired - pending revocation")
    )
      return negative;
    if (
      status?.toLowerCase()?.includes("due") ||
      status?.toLowerCase()?.includes("request") ||
      status?.toLowerCase()?.includes("progress") ||
      status?.toLowerCase()?.includes("pending acceptance by hotel manager") ||
      status
        ?.toLowerCase()
        ?.includes("approval pending by hospitality manager") ||
      status?.toLowerCase()?.includes("surrender initiated") ||
      status?.toLowerCase()?.includes("in progress") ||
      status?.toLowerCase()?.includes("verify") ||
      status?.toLowerCase()?.includes("initiate onboarding") ||
      status?.toLowerCase()?.includes("manual onboarding") ||
      status?.toLowerCase()?.includes("onboarding initiated") ||
      status?.toLowerCase()?.includes("new implementation") ||
      status?.toLowerCase()?.includes("information requested") ||
      status?.toLowerCase()?.includes("request for procurement") ||
      status?.toLowerCase()?.includes("yet to start")
    )
      return primary;
    if (status?.toLowerCase()?.includes("yellowstatus")) return yellowStatus;
  }
  return "#d8f0f2,#1296b0";
};
