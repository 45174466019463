import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  socialData: [],
  redDotSocialData: 0,
  newsData: [],
  announcementData: [],
  birthdayData: [],
  workAnniversaryData: [],
  marriageAnniversaryData: [],
  newJoiningData: [],
  communityData: [],
  communityPostData: [],
  employeeListReduxData: [],
  nestedSharedPostData: [],
  surveyData: [],
};

const SocialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {
    setCommunityData: (state, action) => {
      state.communityData = action.payload;
    },
    setCommunityPostData: (state, action) => {
      state.communityPostData = action.payload;
    },
    setSocialData: (state, action) => {
      state.socialData = action.payload;
    },
    setRedDotSocialData: (state, action) => {
      state.redDotSocialData = action.payload;
    },

    setNewsData: (state, action) => {
      state.newsData = action.payload;
    },
    setAnnouncementData: (state, action) => {
      state.announcementData = action.payload;
    },
    setBirthdayData: (state, action) => {
      state.birthdayData = action.payload;
    },
    setWorkAnniversayData: (state, action) => {
      state.workAnniversaryData = action.payload;
    },
    setMarriageAnniversaryData: (state, action) => {
      state.marriageAnniversaryData = action.payload;
    },
    setNewJoiningData: (state, action) => {
      state.newJoiningData = action.payload;
    },
    setEmployeeListReduxData: (state, action) => {
      state.employeeListReduxData = action.payload;
    },
    setNestedSharedPostData: (state, action) => {
      state.nestedSharedPostData = action.payload;
    },
    setSurveyData: (state, action) => {
      state.surveyData = action.payload;
    },
    updatePostCommentCount: (state, action) => {
      state.socialData = state.socialData.map((d) => {
        if (d?.id === action.payload.postId) {
          d.commentsCount = action.payload.commCount;
        }
        return d;
      });
    },
  },
});

export const {
  setSocialData,
  updatePostCommentCount,
  setNewsData,
  setAnnouncementData,
  setBirthdayData,
  setWorkAnniversayData,
  setMarriageAnniversaryData,
  setNewJoiningData,
  setCommunityData,
  setCommunityPostData,
  setEmployeeListReduxData,
  setNestedSharedPostData,
  setRedDotSocialData,
  setSurveyData,
} = SocialSlice.actions;
export default SocialSlice.reducer;
